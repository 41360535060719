<ng-container *ngIf="menuItem.isNotDropMenu; else dropDownTemplate">
  <button
    (click)="menuItem.item.action()"
    class="menu-item-button flex px-1 lg:px-2 h-full justify-center items-center font-bold text-sm opacity-70 group-hover:opacity-100"
  >
    <app-icon size="1.25rem" [name]="menuItem.icon" class="w-5 h-5 lg:mr-1"></app-icon>
    <div *ngIf="!menuItem.isOffNav" class="hidden lg:inline-flex flex-row items-center">
      <span>{{ i18nService._(menuItem.label) }}</span>
    </div>
  </button>
</ng-container>
<ng-template #dropDownTemplate>
  <button
    (click)="toggleMenu()"
    class="menu-item-button flex px-1 lg:px-2 h-full justify-center items-center font-bold text-sm opacity-70 group-hover:opacity-100"
  >
    <app-icon [name]="menuItem.icon" size="1.25rem" class="w-5 h-5 lg:mr-1"></app-icon>
    <!-- add feature for altIcon if action = active -->
    <!-- <app-icon [name]="menuItem.altIcon" class="w-5 h-5 lg:mr-1 text-orange-600"></app-icon> -->

    <div *ngIf="!menuItem.isOffNav" class="hidden lg:inline-flex flex-row items-center">
      <span>{{ i18nService._(menuItem.label) }}</span>
      <app-icon class="text-black fill-black w-4 h-4 ml-1" name="ChevronDown" type="solid"></app-icon>
    </div>
  </button>
  <div
    [ngClass]="{ hidden: !this.open, flex: this.open }"
    class="menu-item-dropdown bg-white rounded-10 absolute top-full lg:hidden lg:group-hover:flex flex-col transition-all delay-150 {{
      navClass
    }}"
  >
    <div class="triangle-with-shadow"></div>
    <ng-container *ngFor="let dropItem of menuItem.dropItems">
      <!-- to adapt for in-app routes -->
      <ng-container *ngIf="dropItem.hasAction === false">
        <span class="info-item">
          <app-icon *ngIf="dropItem.icon" [name]="dropItem.icon"></app-icon>
          <span class="font-semibold text-sm" ngClass="{{ dropItem.twTxtColor }}"> {{ i18nService._(dropItem.label) }}</span>
        </span>
      </ng-container>
      <ng-container *ngIf="dropItem.hasAction !== false && dropItem.externalLink && !dropItem.mustBeHidden">
        <a
          (click)="subItemClick(dropItem)"
          [attr.href]="dropItem.externalLink"
          class="menu-subitem cursor-pointer flex items-center border-b last:border-b-0 border-gray-300 w-full whitespace-nowrap py-2 hover:text-orange-500"
          target="_blank"
        >
          <span class="font-semibold text-sm" ngClass="{{ dropItem.twTxtColor }}"> {{ i18nService._(dropItem.label) }}</span>
          <app-icon *ngIf="dropItem.externalLink" class="text-gray-400 w-4 h-4 ml-1" name="ArrowTopRightOnSquare" type="outline"></app-icon>
        </a>
      </ng-container>
      <ng-container *ngIf="dropItem.hasAction !== false && dropItem.path && !dropItem.mustBeHidden">
        <a
          (click)="subItemClick(dropItem)"
          [routerLink]="dropItem.path"
          class="menu-subitem cursor-pointer flex items-center border-b last:border-b-0 border-gray-300 w-full whitespace-nowrap py-2 hover:text-orange-500"
        >
          <span class="font-semibold text-sm" ngClass="{{ dropItem.twTxtColor }}"> {{ i18nService._(dropItem.label) }}</span>
          <app-icon *ngIf="dropItem.externalLink" class="text-gray-400 w-4 h-4 ml-1" name="ArrowTopRightOnSquare" type="outline"></app-icon>
        </a>
      </ng-container>
      <ng-container *ngIf="dropItem.hasAction !== false && !dropItem.path && dropItem.action && !dropItem.mustBeHidden">
        <div
          (click)="subItemClick(dropItem)"
          class="menu-subitem cursor-pointer flex items-center border-b last:border-b-0 border-gray-300 w-full whitespace-nowrap py-2 hover:text-orange-500"
        >
          <span class="font-semibold text-sm" ngClass="{{ dropItem.twTxtColor }}"> {{ i18nService._(dropItem.label) }}</span>
          <app-icon *ngIf="dropItem.externalLink" class="text-gray-400 w-4 h-4 ml-1" name="ArrowTopRightOnSquare" type="outline"></app-icon>
        </div>
      </ng-container>

      <ng-container *ngIf="dropItem.contacts">
        <app-interlocutor-contacts *ngIf="isContractorOrDeveloperOrIndependant"></app-interlocutor-contacts>

        <app-commercial-contact></app-commercial-contact>

        <!-- A REMETTRE QUAND CORRIGé coté back -->
        <!-- <div  *ngFor="let contact of dropItem.contacts"
              class="contact items-center border-b last:border-b-0 border-gray-300 uppercase font-semibold text-sm py-2 {{isContractorOrDeveloperOrIndependant ? 'first:border-t' : ''}}">
          <h3 class="text-xxs text-cyan-700 font-bold uppercase">{{i18nService._(contact.label)}}</h3>
          <p class="py-1">{{contact.phone}}</p>
          <a href="mailto:{{contact.email}}">{{contact.email}}</a>
        </div> -->
      </ng-container>
    </ng-container>
  </div>
</ng-template>
