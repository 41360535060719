/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps, FormlyModule } from '@ngx-formly/core';
import { FormlyFieldSelectProps, FormlySelectModule } from '@ngx-formly/core/select';
import { isObservable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

interface SelectProps extends FormlyFieldProps, FormlyFieldSelectProps {
  showClear?: boolean;
  optionLabel?: string;
  optionValue?: string;
  options?: any[];
  dropdownIcon?: string;
}

@Component({
  selector: 'app-formly-dropdown',
  templateUrl: './formly-dropdown.component.html',
  styleUrls: ['./formly-dropdown.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DropdownModule, FormsModule, ReactiveFormsModule, FormlyModule, AsyncPipe, FormlySelectModule],
})
export class FormlyDropdownComponent extends FieldType<FieldTypeConfig<SelectProps>> {
  isObservable = isObservable;
}
