import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { DateTime } from 'luxon';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgIf } from '@angular/common';

import { AbstractProgramPageLot } from '../abstract-program-page-lot';

import { FeatureFlagService } from './../../../../../feature-flag/feature-flag.service';
import { TakeOptionDialogComponent } from './../../../../../dialog/components/take-option-dialog/take-option-dialog.component';
import { SelectDialogProperties } from '../../../../../dialog/models/SelectDialogProperties';
import { ProgramService } from './../../../../services/program.service';
import { PageLotService } from '../../../../../lot/service/page-lot.service';
import { PreReservationSubmitionDialogComponent } from '../../../../../dialog/components/pre-reservation-submition-dialog/pre-reservation-submition-dialog.component';
import { ProspectAssociationDialogComponent } from '../../../../../dialog/components/prospect-association-dialog/prospect-association-dialog.component';
import { QuestionDialogComponent } from '../../../../../dialog/components/question-dialog/question-dialog.component';
import { PreReservationSubmitionDialogData } from '../../../../../dialog/models/PreReservationSubmitionDialogData';
import { ProspectAssociation } from '../../../../../dialog/models/ProspectAssociation';
import { QuestionDialogData } from '../../../../../dialog/models/QuestionDialogData';
import { ProspectAssociationService } from '../../../../../dialog/services/prospect-association.service';
import { LotAction } from '../../../../../lot/model/lot-action';
import { ActionOnOptionResponse } from '../../../../../utils/models/ActionOnOptionResponse';
import { SnackbarMessageType } from '../../../../../utils/models/enums/snackbar-message-type.enum';
import { LotDetailResponse } from '../../../../../utils/models/LotDetailResponse';
import { PoseoptionResponse } from '../../../../../utils/models/PoseoptionResponse';
import { Sitemap } from '../../../../../utils/models/Sitemap';
import { SnackbarMessage } from '../../../../../utils/models/SnackbarMessage';
import { GoogleTagManagerService } from '../../../../../utils/services/google-tag-manager.service';
import { I18nService } from '../../../../../utils/services/i18n.service';
import { PoseOptionsService } from '../../../../../utils/services/pose-options.service';
import { SnackbarService } from '../../../../../utils/services/snackbar.service';
import { SpinnerWithBackdropService } from '../../../../../utils/services/spinner-with-backdrop.service';
import { AppConfigService } from '../../../../../utils/services/app-config.service';
import { PageProgramHelperService } from '../../page-program-helper.service';
import { ProgramDetail } from '../../../../../utils/models/ProgramDetail';
import { ProspectSelectDialogComponent } from '../../../../../dialog/components/prospect-select-dialog/prospect-select-dialog.component';

@Component({
  selector: 'app-program-page-lot-option',
  templateUrl: './program-page-lot-option.component.html',
  styleUrls: ['./program-page-lot-option.component.scss'],
  standalone: true,
  imports: [NgIf, MatButtonModule, NgClass],
})
export class ProgramPageLotOptionComponent extends AbstractProgramPageLot implements OnInit, OnDestroy {
  private isDossierProspectEnabled: boolean;
  public lotAction: LotAction = new LotAction();
  public ongoingCall: boolean;
  @Output() readonly returnInfo: EventEmitter<LotAction> = new EventEmitter();
  public message: boolean;
  program: ProgramDetail;
  companyId: number;

  constructor(
    private readonly _poseOptionService: PoseOptionsService,
    private readonly _dialog: MatDialog,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly _prospectAssociationService: ProspectAssociationService,
    private readonly _snackbarService: SnackbarService,
    private readonly _spinnerWithBackdropService: SpinnerWithBackdropService,
    private readonly _i18nService: I18nService,
    private readonly PageLotService: PageLotService,
    private readonly appConfigService: AppConfigService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly _programService: ProgramService,
    private readonly pageProgramHelperService: PageProgramHelperService,
    private readonly _router: Router,
    public i18nService: I18nService,
  ) {
    super();

    this.isDossierProspectEnabled = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.program = this.pageProgramHelperService.program;
    this._prospectAssociationService.lotIdUpdateToOptionObservable.pipe(takeUntil(this.$d)).subscribe((lotIdUpdate) => {
      if (lotIdUpdate) {
        if (!lotIdUpdate.isAlert) {
          this.lot.status = lotIdUpdate.labelStatus;
        }
        this._programService.setSelectedLotDetail(this.lot);
      }
    });

    this.featureFlagService.isEnabled('dossier-prospect').then((res) => {
      this.isDossierProspectEnabled = res;
    });
  }

  ngOnDestroy() {
    this._programService.setSelectedLotDetail(undefined);
    super.ngOnDestroy();
  }

  initData(lot: LotDetailResponse) {
    if (lot && this.isAuthorizedToManageOption) {
      this._poseOptionService.getActionOnOption(lot.lotId).subscribe((actionOnOption: ActionOnOptionResponse) => {
        this.lotAction = this._pageLotService.getLotActions(actionOnOption, this.isAuthorizedToManageOnlyReservation);
        this.message = this._alertMessage();
        this.returnInfo.emit(this.lotAction);
      });
    }
  }

  initDataEmpty() {
    this.lotAction.hide = true;
  }

  public openPreReservationPopin(): void {
    const data: MatDialogConfig<PreReservationSubmitionDialogData> = {
      autoFocus: false,
      data: {
        lotId: this.lot.lotId,
        lotNumber: this.lot.lotNumber,
        programName: this.program.programName,
      },
    };

    const preReservationPopin = this._dialog.open(PreReservationSubmitionDialogComponent, data);
    preReservationPopin
      .afterClosed()
      .pipe(takeUntil(this.$d))
      .subscribe((success: boolean) => {
        if (success) {
          this.program.nbLotsOptionned--;
          this.program.nbLotsNotAvailable++;
          this._router.navigate([Sitemap.dashboards.preReservations.path]);
        }
      });
  }

  public checkValidity(): void {
    this._googleTagManagerService.pushTag({ event: 'click_option' });
    this._poseOptionService.checkValidity(this.lot.lotId).subscribe((response) => {
      if (response) {
        this.openDialogModal();
      } else {
        this._snackbarService.sendErrorOccured();
      }
    });
  }

  openDialogModal(): void {
    this.companyId = this.program.companyId;
    const dialogData: ProspectAssociation = this.getDialogData();
    let dialogRef;
    if (this.isDossierProspectEnabled) {
      dialogRef = this._dialog.open(TakeOptionDialogComponent, {
        autoFocus: false,
        data: dialogData,
      });
    } else {
      dialogRef = this._dialog.open(ProspectAssociationDialogComponent, {
        autoFocus: false,
        data: dialogData,
      });
    }
    // Reload Component according to response
    this.subscribeToClosedDialogForOptionnedLot(dialogRef);
  }

  getDialogData(): ProspectAssociation {
    return {
      lotId: this.lot.lotId,
      lotNumber: this.lot.lotNumber,
      lotPrice: this.formattedLot.price,
      title: this.lotAction.checkIfOptionCanBeAutomaticallyTaken
        ? 'Title_ProspectAssociationPopin_PopinTitle'
        : 'Title_ProspectAssociationPopin_PopinTitleAsk',
      programId: this.program.programId,
      companyId: this.companyId,
    };
  }

  getModalProperties(): SelectDialogProperties {
    return {
      title: 'Title_OptionModal_take_option',
      message: 'Lorem ipsum',
      options: [
        {
          label: 'Txt_DossierProspectAssociationPopin_ExistingDossier',
          closingLabel: 'existing-dossier',
          action: () => {
            this.onSelectExistingDossier();
          },
          icon: 'Folder',
        },
        {
          label: 'Txt_DossierProspectAssociationPopin_NewDossier',
          closingLabel: 'new-dossier',
          action: () => {
            this.onSelectNewDossier();
          },
          icon: 'FolderPlus',
        },
      ],
    };
  }

  onSelectExistingDossier() {
    const data: any = {
      title: 'Title_OptionModal_take_option',
      message: 'Txt_Option_Lorem',
      imageSource: '/assets/img/icon/building.svg',
      button: 'Txt_OptionModal_take_option_button',
      lotId: this.lot.lotId,
      lotNumber: this.lot.lotNumber,
      lotPrice: this.formattedLot.price,
      requireOptionTake: true,
    };

    const dialogRef = this._dialog.open(ProspectSelectDialogComponent, {
      autoFocus: false,
      data: data,
      width: '40vw',
      panelClass: 'dialog-container-new',
    });

    this.subscribeToClosedDialogForOptionnedLot(dialogRef);
  }

  private subscribeToClosedDialogForOptionnedLot(dialogRef) {
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.$d))
      .subscribe((lotIdUpdateToOptionned: number) => {
        if (lotIdUpdateToOptionned) {
          this._googleTagManagerService.pushTag({
            event: 'validation_option',
          });
          this.program.nbLotsOptionned++;
          this.program.nbLotsAvailable--;
          this._prospectAssociationService.setLotIdUpdateToOption({
            lotIdUpdateStatus: lotIdUpdateToOptionned,
            isOptionned: true,
            labelStatus: this._appConfigService.getAppConfig().statusLotLabels.optioned,
          });
        }
      });
  }

  onSelectNewDossier() {
    this._router.navigate([Sitemap.dossierProspects.paternity.path.replace(':existingProspect', 'false')], {
      queryParams: {
        lotId: this.lot.lotId.toString(),
        programId: this.program.programId.toString(),
        companyId: this.companyId.toString(),
      },
    });
    this._googleTagManagerService.pushTag({ event: 'prospect_declaration' });
  }

  public callRemoveOption(): void {
    this._googleTagManagerService.pushTag({ event: 'cancel_option' });
    const dialogData: QuestionDialogData = {
      message: this.i18nService._('Txt_Popin_Remove_Option', [this.lot.lotNumber, this.program.programName]),
      title: this.i18nService._('Txt_Popin_Remove_Option_Title'),
    };

    const dialogRef = this._dialog.open(QuestionDialogComponent, {
      data: dialogData,
    });

    // Add in form and update company status
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.$d))
      .subscribe((result) => {
        if (result.answer) {
          this._googleTagManagerService.pushTag({
            event: 'cancel_option_validation',
          });
          this._poseOptionService.removeOption(this.lot.lotId).subscribe(
            (response: PoseoptionResponse) => {
              this._responseUpdateOption(response, this._appConfigService.getAppConfig().statusLotLabels.free);
            },
            (error: HttpErrorResponse) => {
              this._errorUpdateOption(error, this.i18nService._('Error_SnackBar_UnavailableRemoveOption'));
            },
          );
        }
      });
  }

  callExtendedOption(): void {
    this.ongoingCall = true;
    this._spinnerWithBackdropService.show();
    this._poseOptionService.extendOption(this.lot.lotId).subscribe(
      (response: PoseoptionResponse) => {
        this._responseUpdateOption(response, this._appConfigService.getAppConfig().statusLotLabels.optioned);
        this.ongoingCall = false;
        this._spinnerWithBackdropService.hide();
      },
      (error: HttpErrorResponse) => {
        this._errorUpdateOption(error, this.i18nService._('Error_SnackBar_UnavailableExtendOption'));
        this.ongoingCall = false;
        this._spinnerWithBackdropService.hide();
      },
    );
  }

  public takeAlert(): void {
    this._lotService.createAlert(this.lot.lotId).subscribe(() => {
      this._snackbarService.infoI18n('Success_SnackBar_AlertTaken');
      this._prospectAssociationService.setLotIdUpdateToOption({
        lotIdUpdateStatus: this.lot.lotId,
        isAlert: true,
        labelStatus: this._appConfigService.getAppConfig().statusLotLabels.optioned,
      });
    });
  }

  private _responseUpdateOption(response: PoseoptionResponse, nextStatus: string): void {
    const message: SnackbarMessage = {
      text: response.libelleRetour,
      type: SnackbarMessageType.Info,
    };
    this._snackbarService.sendMessage(message);
    if (response.codeRetour === 200) {
      if (nextStatus === this._appConfigService.getAppConfig().statusLotLabels.free) {
        this.program.nbLotsOptionned--;
        this.program.nbLotsAvailable++;
      }
      this._prospectAssociationService.setLotIdUpdateToOption({
        lotIdUpdateStatus: response.option.lotId,
        isOptionned: false,
        labelStatus: nextStatus,
      });
    }
  }

  private _errorUpdateOption(error: HttpErrorResponse, text: string): void {
    if (error && (error.status === 502 || error.status === 504)) {
      const message: SnackbarMessage = {
        type: SnackbarMessageType.Error,
        text,
      };
      this._snackbarService.sendMessage(message);
    }
  }

  private _alertMessage(): boolean {
    if (this.lot.quotaReach) {
      return false;
    }

    const lotAction = this.PageLotService.getLotActions(this.lotAction, this.isAuthorizedToManageOnlyReservation);

    const shouldDisplayAlertMessage =
      !this.lotAction.checkIfAlertCanBeTaken &&
      !(
        this.lotAction.checkIfOptionCanBePreReserved ||
        this.lotAction.checkIfOptionCanBeTaken ||
        this.lotAction.checkIfOptionCanOnlyBeRemoved
      );
    if (shouldDisplayAlertMessage) {
      const timeZoneDefault = this.appConfigService.getAppConfig().timeZone;
      const timeLeftFormated = DateTime.fromISO(lotAction.checkRemainingTimeForOptionOtherOwner)
        .setZone(timeZoneDefault)
        .toFormat(`dd/MM/yyyy 'à' HH:mm`);

      this.lotAction.userHasOptionUntil = this.lotAction.userHasAlreadyTakenAlert
        ? this._i18nService._('Txt_Detail_Lot_Alert_Message', [timeLeftFormated])
        : this._i18nService._('Txt_Detail_Lot_Option_Pending');
    }

    return shouldDisplayAlertMessage;
  }
}
