import { Observable, catchError, firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SummaryPaternityResponse } from '@commons-dto/prospect';

import { PaternityLegacyCreate } from '../../utils/models/PaternityLegacyCreate';
import { PaternityResponse } from '../../utils/models/PaternityResponse';
import { PaternityLegacyApiService } from '../../adapters/paternity-legacy-api.service';
import { PaternityApiService } from '../../adapters/paternity-api.service';
import { PaternityFormData } from '../../dossier-prospect/model/forms/paternity-form-data.model';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';
import { SnackbarService } from '../../utils/services/snackbar.service';
import { PaternityCreate } from '../../dossier-prospect/model/paternity-create.model';

@Injectable({
  providedIn: 'root',
})
export class PaternityService {
  /**
   * Creates an instance of PaternityService.
   * @param {PaternityApiService} paternityLegacyApiService
   * @memberof ProspectService
   */
  constructor(
    private readonly paternityLegacyApiService: PaternityLegacyApiService,
    private readonly paternityApiService: PaternityApiService,
    private readonly _snackBar: SnackbarService,
    private readonly _errorHandler: ErrorHandlerService,
  ) {}

  getByPaternitiesIds(paternitiesIds: number[]): Observable<SummaryPaternityResponse[]> {
    return this.paternityApiService.getByPaternitiesIds(paternitiesIds);
  }

  public async createPaternityLegacy(paternityLegacyCreate: PaternityLegacyCreate): Promise<PaternityResponse> {
    return firstValueFrom(this.paternityLegacyApiService.create(paternityLegacyCreate));
  }

  create(paternitiesform: PaternityFormData[]) {
    return this.paternityApiService.create(PaternityCreate.fromForm(paternitiesform)).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 409) {
          this._snackBar.errorI18n({ title: 'Prospect_Paternity_Form_Duplicate_Title', text: 'Prospect_Paternity_Form_Duplicate_Text' });
        }
        throw this._errorHandler.handleError('DossierProspectService', 'addPaternity');
      }),
    );
  }
}
