/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { LotResponse } from './../../utils/models/LotResponse';
import { SharedProgramService } from './../../utils/services/shared-program.service';
import { TableLoader } from '../../table/interfaces/DataLoader';

@Injectable({
  providedIn: 'root',
})
export class AlotmentSelectionTableService implements TableLoader<LotResponse> {
  constructor(private readonly sharedProgramService: SharedProgramService) {}

  public async getAlotment(id: number): Promise<any> {
    const response = await lastValueFrom(this.sharedProgramService.getProgramLots(id, true));
    return response;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getById(id: number): Promise<any> {
    return null;
  }
}
