import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DossierResponse } from '@commons-dto/dossier-prospect';

import { DossierProspectService } from './dossier-prospect.service';

import { sanitize } from '../../utils/services/utils/file-utils';
import { AutoCompleteService } from '../../common/form/auto-complete.service';

@Injectable({
  providedIn: 'root',
})
export class DossierProspectSearch implements AutoCompleteService {
  dossiers: { label: string; id: number }[];

  constructor(private dossierProspect: DossierProspectService) {
    this.dossierProspect.dossiersForSearchingSub.subscribe((dossiers) => {
      if (dossiers) {
        this.dossiers = dossiers.map((dossier) => {
          return { id: dossier.id, label: this.createLabel(dossier) };
        });
      }
    });
  }

  createLabel(dossier: Partial<DossierResponse>): string {
    if (dossier.prospects.length > 1) {
      return `${dossier.prospects[0].firstName} ${dossier.prospects[0].lastName} & ${dossier.prospects[1].firstName} ${dossier.prospects[1].lastName}`;
    } else {
      const prospect = dossier.prospects[0];
      if (prospect.companyName) {
        return `${prospect.companyName}${prospect.SIRET ? ` (SIRET: ${prospect.SIRET})}` : ''} —
        ${prospect.firstName} ${prospect.lastName}`;
      } else {
        return `${prospect.firstName} ${prospect.lastName} (${prospect.email})`;
      }
    }
  }

  findData(filter: string): Observable<{ label: string; id: number }[]> {
    filter = sanitize(filter);
    let results;
    if (this.dossiers) {
      results = this.dossiers.filter((dossier) => sanitize(dossier.label).match(`${filter}`));
    }
    return of(results);
  }
}
