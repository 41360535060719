<div class="comments bg-white rounded-10 shadow-custom px-8 py-6 h-full flex flex-col">
  <h2 class="mb-3 text-xl font-bold">{{ i18nService._('txt_placeholder_comment') }}</h2>
  <textarea
    class="w-full bg-gray-100 rounded-10 py-2 px-4 text-sm flex-1"
    placeholder="{{ i18nService._('txt_commentaire_daskboard') }}"
    rows="6"
    (change)="updateComment(comment)"
    [(ngModel)]="comment"
  >
  </textarea>
</div>
