export enum SearchCriteria {
  PROGRAM_LOCATION = 'PROGRAM_LOCATION',

  LOT_MIN_PRICE = 'LOT_MIN_PRICE',
  LOT_MAX_PRICE = 'LOT_MAX_PRICE',
  LOT_TAXATION = 'LOT_TAXATION',
  LOT_TAX_ZONE = 'LOT_TAX_ZONE',

  LOT_PROGRAM_TYPE = 'LOT_PROGRAM_TYPE',
  LOT_ROOMS = 'LOT_ROOMS',
  LOT_FLOORS = 'LOT_FLOORS',
  LOT_AREA_TYPE = 'LOT_AREA_TYPE',
  PARKING_SECONDARY_LOT = 'PARKING_SECONDARY_LOT',
  STORAGE_SECONDARY_LOT = 'STORAGE_SECONDARY_LOT',
  LOT_ORIENTATION = 'LOT_ORIENTATION',

  LOT_MIN_DELIVERY_DATE = 'LOT_MIN_DELIVERY_DATE',
  LOT_ACTABLE = 'LOT_ACTABLE',
  PROGRAM_HAS_SPECIAL_OFFER = 'PROGRAM_HAS_SPECIAL_OFFER',
  PROGRAM_NEW = 'PROGRAM_NEW',
}
