<div class="flex items-center flex-col w-[600px]">
  <button class="closing-dialog" (click)="closeDialog()">
    <app-icon name="XMark"></app-icon>
  </button>
  <div *ngIf="data.imageSource">
    <img [src]="data.imageSource" />
  </div>
  <h2 class="text-center" mat-dialog-title *ngIf="data.title">
    {{ _i18nService._(data.title) }}
  </h2>
  <div mat-dialog-content>
    <p *ngIf="hasHTMLMessage; else translateMessage" [innerHTML]="_i18nService._(data.message) | safe: 'html'"></p>
    <ng-template #translateMessage>
      <p>{{ _i18nService._(data.message) }}</p>
    </ng-template>
    <div class="flex flex-col items-stretch">
      <button
        *ngFor="let option of data.options"
        (click)="chosen(option)"
        class="mt-3 rounded-10 min-h-[112px] w-full p-6 flex justify-center items-center hover:bg-grey-background border border-solid border-grey-background"
      >
        <app-icon type="solid" class="mr-9 text-grey-dark text-lg block" size="1.5rem" name="{{ option.icon }}"></app-icon>
        <span class="text-black text-lg font-bold">{{ _i18nService._(option.title) }}</span>
      </button>
    </div>
  </div>
</div>
