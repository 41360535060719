import { Component, Input } from '@angular/core';
import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';

import { LotComparatorDetail } from '../../../../lot/model/lot-comparator-detail';
import { ComparatorMeta, ComparatorMetaWrapper } from '../../../models/comparator-meta';
import { ComparatorMetaPartial } from '../../../models/comparator-meta-partial';
import { AbstractComparatorComponent } from '../../abstract-comparator-component';
import { AsPipe } from '../../../../utils/pipes/as.pipe';

@Component({
  selector: 'app-lot-comparator-meta-line',
  templateUrl: './lot-comparator-meta-line.component.html',
  styleUrls: ['./lot-comparator-meta-line.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, NgClass, NgTemplateOutlet, AsPipe],
})
export class LotComparatorMetaLineComponent extends AbstractComparatorComponent {
  @Input() withoutTitle: boolean;
  public formattedValues = {};
  ComparatorMetaPartial = ComparatorMetaPartial;

  private _lotDetailToCompare: LotComparatorDetail[];

  get lotDetailToCompare(): LotComparatorDetail[] {
    return this._lotDetailToCompare;
  }

  @Input() set lotDetailToCompare(value: LotComparatorDetail[]) {
    this._lotDetailToCompare = value;
    if (this._lotMetaWrapper && this._lotDetailToCompare) {
      this.processFormatLots(this._lotDetailToCompare, this._lotMetaWrapper);
    }
  }

  private _lotMetaWrapper: ComparatorMetaWrapper;

  get lotMetaWrapper(): ComparatorMetaWrapper {
    return this._lotMetaWrapper;
  }

  @Input() set lotMetaWrapper(value: ComparatorMetaWrapper) {
    this._lotMetaWrapper = value;
    if (this._lotMetaWrapper && this._lotDetailToCompare) {
      this.processFormatLots(this._lotDetailToCompare, this._lotMetaWrapper);
    }
  }

  processFormatLots(lots: LotComparatorDetail[], meta: ComparatorMetaWrapper) {
    if (meta.titles) {
      lots.forEach((lot) => {
        if (lot) {
          this.formattedValues[lot.id] = {};
          meta.titles.forEach((lotMetaTitle) => {
            if (Object.getOwnPropertyDescriptor(meta, lotMetaTitle)) {
              const metaElement = meta[lotMetaTitle] as ComparatorMeta;
              this.formattedValues[lot.id][lotMetaTitle] = metaElement.format ? metaElement.format(lot) : lot[metaElement.field];
            }
          });
        }
      });
    }
  }
}
