/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, Observable, tap } from 'rxjs';

import { LotResponse } from '../models/LotResponse';
import { ProgramLotsInformationResponse } from '../models/ProgramLotsInformations';
import { ProgramResponse } from '../models/ProgramResponse';
import { SecondaryLotResponse } from '../models/SecondaryLotResponse';
import { AppConfigService } from './app-config.service';
import { CacheService } from './cache.service';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class SharedProgramService {
  /**
   * programLots attribute for cache handler
   *
   * @private
   * @type {Array<LotResponse>}
   * @memberof SharedProgramService
   */
  private programLots: Array<LotResponse>;
  private readonly cacheNames = {
    getProgramsLotsInformation: 'SharedProgramService.getProgramsLotsInformation',
  };

  /**
   * Creates an instance of SharedProgramService.
   * @param {HttpClient} http
   * @param {ErrorHandlerService} errorHandlerService
   * @param {AppConfigService} appConfig
   * @param _cacheService
   * @memberof SharedProgramService
   */
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
    private readonly _cacheService: CacheService,
  ) {
    this.programLots = [];
  }

  /**
   * getProgram method, programId in param (string)
   *
   * @param {string} programId
   * @returns {Observable<ProgramResponse>}
   * @memberof SharedProgramService
   */
  getProgram(programId: string): Observable<ProgramResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Programs/${programId}`;

    return this.http
      .get<ProgramResponse>(url)
      .pipe(catchError(this.errorHandlerService.handleError<ProgramResponse>('sharedProgramService', 'getProgram')));
  }

  /**
   * List of program's lots
   *
   * @param programId
   * @returns {Observable<LotResponse[]>}
   * @memberof SharedProgramService
   */
  getProgramLotsInformation(programId: string): Observable<ProgramLotsInformationResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Lots/tracking-tables/${programId}`;
    const params = new HttpParams().set('isMandat', 'false');

    return this.http.get<ProgramLotsInformationResponse>(url, { params }).pipe(
      tap((response) => this.programlotsResponseHandler(response)),
      catchError(this.errorHandlerService.handleError<ProgramLotsInformationResponse>('sharedProgramService', 'getProgramLotsInformation')),
    );
  }

  /**
   * List of program's lots
   *
   * @returns {Observable<LotResponse[]>}
   * @memberof SharedProgramService
   * @param data
   */
  getProgramsLotsInformation(data: {
    programIds: Array<string>;
    endDateSpecialOffer: string;
    startDateSpecialOffer: string;
    specialOfferId: number;
    responseFields: Array<string>;
  }): Observable<ProgramLotsInformationResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Lots/tracking-tables/programs`;
    const params = new HttpParams().set('isSO', 'true');

    return this.http.post<ProgramLotsInformationResponse>(url, data, { params }).pipe(
      tap((response) => {
        return this.programlotsResponseHandler(response);
      }),
      catchError(this.errorHandlerService.handleError<ProgramLotsInformationResponse>('sharedProgramService', 'getProgramLotsInformation')),
    );
  }

  getProgramSecondaryLotsInformation(lotId: string): Observable<Array<SecondaryLotResponse>> {
    const filter = {
      where: {
        lotId,
      },
    };
    const url = `${this.appConfig.getLoopbackApiUrl()}/SecondaryLots`;
    const params = new HttpParams().set('filter', JSON.stringify(filter));

    return this.http
      .get<Array<SecondaryLotResponse>>(url, { params })
      .pipe(
        catchError(
          this.errorHandlerService.handleError<Array<SecondaryLotResponse>>('sharedProgramService', 'getProgramSecondaryLotsInformation'),
        ),
      );
  }

  programlotsResponseHandler(response): Observable<ProgramLotsInformationResponse> {
    const { lots, error } = response;

    if (error) {
      this.errorHandlerService.handleError<ProgramLotsInformationResponse>('sharedProgramService', 'getProgramLots');
    }
    this.programLots = lots;

    return response;
  }

  /**
   * List of program's lots for mandate strategies selection lot table
   *
   * @param programId
   * @returns {Observable<LotResponse[]>}
   * @memberof SharedProgramService
   */
  getProgramLots(programId: number, refreshCacheData = true): Observable<ProgramLotsInformationResponse | LotResponse[]> {
    if (!this.programLots || refreshCacheData) {
      const url = `${this.appConfig.getLoopbackApiUrl()}/Lots/tracking-tables/${programId}`;
      const params = new HttpParams().set('isMandat', 'true');

      return this.http.get<ProgramLotsInformationResponse>(url, { params }).pipe(
        tap((response) => this.programlotsResponseHandler(response)),
        catchError(this.errorHandlerService.handleError<ProgramLotsInformationResponse>('sharedProgramService', 'getProgramLots')),
      );
    }
  }

  /**
   * Program informations with currency
   *
   * @param programId
   * @returns {Observable<ProgramResponse>}
   * @memberof SharedProgramService
   */
  getProgramWithCompanyCurrency(programId: number): Observable<ProgramResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Programs/${programId}`;
    const params = new HttpParams().set('flag', 'get-company-currency');

    return this.http
      .get<ProgramResponse>(url, { params })
      .pipe(catchError(this.errorHandlerService.handleError<ProgramResponse>('sharedProgramService', 'getProgramWithCompanyCurrency')));
  }

  /**
   * getHisPrograms method
   *
   * @returns {Observable<any>}
   * @memberof SharedProgramService
   */
  getHisPrograms(): Observable<any> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Programs/get-his-programs`;

    return this.http.get<any>(url).pipe(catchError(this.errorHandlerService.handleError<any>('sharedProgramService', 'getPrograms')));
  }

  /**
   * Publish program
   *
   * @param programId
   * @returns {Observable<any>}
   * @memberof SharedProgramService
   */
  publishProgram(programId: number): Observable<any> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Programs/${programId}/publish`;

    return this.http.patch(url, {}).pipe(
      catchError((httpError) => {
        // Process in component error not generic
        if (httpError && httpError.error && httpError.error.error && httpError.error.error.code === 'ERROR_PROGRAM_INCOMPLETE_LOTS') {
          return throwError(httpError);
        }

        return this.errorHandlerService.handleError<any>('sharedProgramService', 'publishProgram')(httpError);
      }),
    );
  }

  public getProgramOnlyProgramStatusAndUnpublicationOrigin(programId: number): Observable<any> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Programs/${programId}`;
    const params = new HttpParams().set('flag', 'get-status-unpublication');

    return this.http
      .get<ProgramResponse>(url, { params })
      .pipe(catchError(this.errorHandlerService.handleError<any>('sharedProgramService', 'getProgramProgramStatusAndUnpublicationOrigin')));
  }

  /**
   * Publish or unpublish program
   *
   * @param {number} programId
   * @returns {Observable<ProgramResponse>}
   * @memberof SharedProgramService
   */
  public publishUnpublishProgram(programId: number, publish: boolean): Observable<boolean> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Programs/publishUnpublish`;

    const data = {
      programId,
      publish,
    };

    return this.http
      .post<boolean>(url, data)
      .pipe(catchError(this.errorHandlerService.handleError<boolean>('sharedProgramService', 'publishUnpublish')));
  }

  planningPublishProgram(programId: number, datePlanning: Date): Observable<any> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Programs/${programId}/planning`;

    return this.http
      .patch(url, { datePlanning })
      .pipe(catchError(this.errorHandlerService.handleError<any>('sharedProgramService', 'planningProgram')));
  }

  unscheduleProgramPublication(programId: number): Observable<any> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Programs/${programId}/unscheduledProgramPublication`;

    return this.http.patch(url, {}).pipe(catchError(this.errorHandlerService.handleError<any>('sharedProgramService', 'planningProgram')));
  }
}
