<div class="grid grid-cols-2 gap-16">
  <app-dropdown-list-populated
    class="col-span-2 lg:col-span-1"
    [parentForm]="parentForm"
    [referenceTable]="referenceTable"
    [required]="required"
    [searchable]="searchable"
    [placeholder]="placeholder"
    [fieldName]="fieldName"
    [isSubmit]="isSubmit"
    (selectedItemChange)="dropdownValueChanged($event)"
    [dataToInit]="dataToInit"
  >
  </app-dropdown-list-populated>
  <mat-form-field class="col-span-2 lg:col-span-1" *ngIf="isOther">
    <mat-label>{{ i18nService._('Txt_Page_Form_RegisterCompany_Other') }}</mat-label>
    <input type="text" name="fieldNameOther" [formControl]="otherControl" matInput maxlength="255" required />
    <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
  </mat-form-field>
</div>
