import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { NgFor, NgIf } from '@angular/common';

import { StepContextComponent } from '../step-context-component';

import { I18nService } from '../../../../../../utils/services/i18n.service';
import { FormResponse } from '../../../../../../signature/models/FormResponse';
import { SignatureService } from '../../../../../../signature/services/signature.service';
import { FileSystemService } from '../../../../../../utils/services/file-system.service';
import { DocumentResponse } from '../../../../../../utils/models/DocumentResponse';

@Component({
  selector: 'app-lot-detail-step-context',
  templateUrl: './lot-detail-step-context.component.html',
  styleUrls: ['./lot-detail-step-context.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor],
})
export class LotDetailStepContextComponent implements StepContextComponent, OnInit {
  dossierId: number;
  form: FormResponse;
  lotPlanDocument: DocumentResponse;
  private readonly _unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly renderer: Renderer2,
    private elRef: ElementRef,
    public readonly i18nService: I18nService,
    private readonly route: ActivatedRoute,
    private readonly _signatureService: SignatureService,
    private readonly fileSystemService: FileSystemService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.dossierId = this.route.snapshot.params.signatureId;
    this._signatureService.formDossier$.pipe(takeUntil(this._unsubscribe$)).subscribe((form) => {
      this.form = form;
      this.setLotPlanDocument(form);
    });
  }

  setLotPlanDocument(form: FormResponse) {
    if (!form.contextData.lot.documents) {
      this.lotPlanDocument = {
        container: 'lotDocuments',
        codeLabel: 'LP',
        type: { code: 'LP' },
        fileName: '',
        title: '',
      };
      return;
    }

    let documentsArray;
    if (typeof form.contextData.lot.documents === 'string') {
      try {
        documentsArray = JSON.parse(form.contextData.lot.documents);
      } catch (error) {
        console.error('Error parsing documents JSON:', error);
        return;
      }
    } else {
      documentsArray = form.contextData.lot.documents;
    }

    this.lotPlanDocument = {
      container: 'lotDocuments',
      codeLabel: 'LP',
      type: { code: 'LP' },
      fileName: documentsArray[0].fileName,
      title: documentsArray[0].title,
      mimeType: documentsArray[0].mimeType,
    };
  }

  setClass(myClass: string) {
    const classes = myClass.split(' ');
    classes.forEach((c) => this.renderer.addClass(this.elRef.nativeElement, c));
  }
}
