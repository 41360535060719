<div [ngClass]="isDisabled ? 'fileDrop-disabled' : 'fileDrop-enabled'" class="file-drop-container relative mt-2 mr-5 mb-4">
  <!-- Placeholder -->
  <div class="pb-1">
    <span
      class="text-base font-semibold"
      [ngClass]="{
        'txt-error': attachmentControl.errors && (firstLoad || isSubmit)
      }"
      >{{ attachmentName }}</span
    >
    <span *ngIf="required"> *</span>
    <span *ngIf="tooltip" class="icon-info txt-gray pl-2" matSuffix matTooltip="{{ tooltip }}"> </span>
    <span *ngIf="helper" class="block text-xs">{{ helper }}</span>
  </div>

  <!-- File drop area -->
  <ngx-file-drop id="my-file-drop" class="file-drop" (onFileDrop)="fileDropped($event)">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <div *ngIf="attachmentControl.value" class="file-dropped">
        <div
          *ngIf="initialValue"
          class="pl-3 text-xs truncate cursor-pointer file-browser"
          (click)="fileSystemService.openDocumentInBrowser(initialValue, initialValue.fileName)"
        >
          {{ initialValue.title }}
        </div>
        <div *ngIf="!initialValue" class="pl-3 text-xs truncate">
          {{ attachmentControl.value.name }}
        </div>
        <div class="px-3 ml-auto cursor-pointer" [hidden]="isDisabled" (click)="removeAttachment()">
          <span class="icon-cancel text-xxs font-bold"></span>
        </div>
      </div>

      <div class="ml-auto pl-2">
        <label for="{{ fieldNameKey }}" *ngIf="browserAvailable" [hidden]="initialValue">
          <div class="txt-orange cursor-pointer" title="{{ i18nService._('Txt_Button_Add_File') }}">
            <span class="icon-plus text-sm font-bold"></span>
          </div>

          <input
            #fileInput
            attr.data-cy="{{ fieldNameKey }}"
            id="{{ fieldNameKey }}"
            name="{{ fieldNameKey }}"
            class="hidden"
            type="file"
            accept="{{ splitTypes }}"
            (change)="fileAdded($any($event.target).files)"
          />
        </label>
      </div>

      <!-- Info tooltip -->
      <div class="absolute file-drop-tooltip">
        <span *ngIf="hoveringText" class="icon-info txt-gray cursor-pointer" [matTooltip]="hoveringText"></span>
      </div>
    </ng-template>
  </ngx-file-drop>

  <!-- Error msg -->
  <div class="text-xs">
    <mat-error *ngIf="attachmentControl.errors && (firstLoad || isSubmit)">
      <span *ngIf="attachmentControl.errors.required">{{ i18nService._(fileRequiredErrorI18nToken) }}</span>
      <span *ngIf="attachmentControl.errors.fileType && !attachmentControl.errors.required">
        {{ i18nService._(fileFormatErrorI18nToken, [extErrorMessage]) }}
      </span>
      <span *ngIf="!attachmentControl.errors.fileType && attachmentControl.errors.fileSize && !attachmentControl.errors.required">
        {{ i18nService._(fileSizeErrorI18nToken, [sizeErrorMessage]) }}
      </span>
    </mat-error>
  </div>
</div>
