/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs';

import { MandateStrategyCreate } from '../../utils/models/MandateStrategyCreate';
import { MandatStrategyResponse } from '../../utils/models/MandatStrategyResponse';
import { AppConfigService } from '../../utils/services/app-config.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class MandateStrategyService {
  /**
   * Creates an instance of MandateStrategyService.
   * @param {HttpClient} http
   * @param {ErrorHandlerService} errorHandlerService
   * @param {AppConfigService} appConfig
   * @memberof MandateStrategyService
   */
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
  ) {}

  /**
   * getMandateStrategiesRecap method, programId in argument
   *
   * @param {number} programId
   * @returns {Observable<MandatStrategyResponse[]>}
   * @memberof MandateStrategyService
   */
  getMandateStrategiesRecap(programId: number): Observable<Array<MandatStrategyResponse>> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Programs/${programId}/mandateStrategiesRecap`;

    return this.http
      .get<Array<MandatStrategyResponse>>(url)
      .pipe(
        catchError(
          this.errorHandlerService.handleError<Array<MandatStrategyResponse>>('mandateStrategyService', 'getMandateStrategiesRecap'),
        ),
      );
  }

  /**
   * deleteMandateStrategy method, mandateStrategyId in argument
   *
   * @param {string} mandateStrategyId
   * @returns
   * @memberof MandateStrategyService
   */
  deleteMandateStrategy(mandateStrategyId: string): Observable<any> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/MandatStrategies/${mandateStrategyId}`;

    return this.http.delete(url).pipe(catchError(this.errorHandlerService.handleError('mandateStrategyService', 'deleteMandateStrategy')));
  }

  /**
   * createOrUpdateMandateStrategy method, mandateStrategyForm and mandateStrategyId in argument
   *  if no mandateStrategyIdToUpdate, creation, else update
   *
   * @param {MandateStrategyCreate} mandateStrategyForm
   * @param {number} mandateStrategyId
   * @returns {Observable<MandateStrategyCreate>}
   * @memberof MandateStrategyService
   */
  createOrUpdateMandateStrategy(mandateStrategyForm: MandateStrategyCreate, mandateStrategyId: number): Observable<MandatStrategyResponse> {
    let url = `${this.appConfig.getLoopbackApiUrl()}/MandatStrategies`;
    if (mandateStrategyId) {
      url = `${url}/${mandateStrategyId}`;

      return this.http
        .put<MandateStrategyCreate>(url, mandateStrategyForm)
        .pipe(catchError(this.errorHandlerService.handleError<any>('mandatStrategyService', 'createOrUpdate')));
    }

    return this.http
      .post<MandateStrategyCreate>(url, mandateStrategyForm)
      .pipe(catchError(this.errorHandlerService.handleError<any>('mandatStrategyService', 'createOrUpdate')));
  }
}
