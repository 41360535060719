/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ColDef } from 'ag-grid-community';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, lastValueFrom, catchError, debounceTime, distinctUntilChanged } from 'rxjs';
import { ValoRole } from '@commons-dto/valo-back';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgClass } from '@angular/common';

import { GridTrackingTableComponent } from '../grid-tacking-table/grid-tracking-table.component';

import { GoogleTagManagerService } from './../../../utils/services/google-tag-manager.service';
import { PreReservationSubmitionDialogComponent } from '../../../dialog/components/pre-reservation-submition-dialog/pre-reservation-submition-dialog.component';
import { QuestionDialogComponent } from '../../../dialog/components/question-dialog/question-dialog.component';
import { PreReservationSubmitionDialogData } from '../../../dialog/models/PreReservationSubmitionDialogData';
import { QuestionDialogData } from '../../../dialog/models/QuestionDialogData';
import { QuestionDialogResponse } from '../../../dialog/models/QuestionDialogResponse';
import { ReservationService } from '../../../reservations/services/reservation.service';
import { DocumentResponse } from '../../../utils/models/DocumentResponse';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { Sitemap } from '../../../utils/models/Sitemap';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { UserRoleService } from '../../../utils/services/user-role.service';
import { ZipFileService } from '../../../utils/services/zip-file.service';
import { AbstractTrackingTable } from '../../abstract-tracking-table';
import { PreReservationsTrackingTableItem } from '../../models/PreReservationsTrackingTableItem';
import { PreReservationsTrackingTableService } from '../../services/pre-reservations-tracking-table.service';
import { StatusTagColor } from '../../../design-system/model/status-tag-color.enum';
import { PreReservationStatusEnum } from '../../../utils/enums/statusEnums/pre-reservation-status.enum';
import { FilterEventOutput } from '../../models/filter/filter-event-output';
import { PriceRendererComponent } from '../../renderers/price-renderer/price-renderer.component';
import { StatusTagsRendererComponent } from '../../renderers/status-tags-renderer/status-tags-renderer.component';
import { DateRendererComponent } from '../../renderers/date-renderer/date-renderer.component';
import { ActionRendererComponent } from '../../renderers/action-renderer/action-renderer.component';
import { InformationRendererComponent } from '../../renderers/information/information-renderer.component';
import { SignatureService } from '../../../signature/services/signature.service';
import { Dossier } from '../../../signature/models/Dossier';
import { FeatureFlagService } from '../../../feature-flag/feature-flag.service';
import { PreReservationDossierProspectSubmitionDialogComponent } from '../../../dialog/components/pre-reservation-dossier-prospect-submition-dialog/pre-reservation-dossier-prospect-submition-dialog.component';
import { DownloadUtils } from '../../../common/services/download.utils';
import { ToggleButtonComponent } from '../../../design-system/component/toggle-button/toggle-button.component';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';
import { LotDetailsPanelComponent } from '../../../utils/components/lot-details-panel/lot-details-panel.component';

class StatusFilter {
  code: string;
  label: string;
  phase?: Array<string>;
  status?: Array<string>;
}

const tagTypeByPreReservationStatus: { [key in PreReservationStatusEnum]: StatusTagColor } = {
  PENDING: StatusTagColor.IN_PROGRESS,
  PRERESERVATION_REJECTED: StatusTagColor.CRITICAL,
  PRERESERVATION_CANCELED: StatusTagColor.CRITICAL,
  PRERESERVED: StatusTagColor.SUCCESS,
};

// eslint-disable-next-line max-classes-per-file
@Component({
  selector: 'app-pre-reservations-tracking-table',
  templateUrl: './pre-reservations-tracking-table.component.html',
  styleUrls: ['./pre-reservations-tracking-table.component.scss'],
  standalone: true,
  imports: [
    MatSidenavModule,
    LotDetailsPanelComponent,
    StickyHeaderFormComponent,
    NgIf,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    FormsModule,
    NgClass,
    MatBadgeModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ToggleButtonComponent,
    GridTrackingTableComponent,
  ],
})
export class PreReservationsTrackingTableComponent extends AbstractTrackingTable implements OnInit, OnDestroy {
  columnDefs: ColDef[];
  filterUrlParam: FilterEventOutput;
  queryParamMap: any;
  serviceUrl: string;
  filterValue: string;

  @ViewChild(GridTrackingTableComponent)
  gridTrackingTable: GridTrackingTableComponent;

  statusFilterList = [
    { label: 'PRERESERVATION_PENDING', code: 'PENDING' },
    { label: 'PRERESERVATION_PRERESERVATION_CANCELED', code: 'PRERESERVATION_CANCELED' },
    { label: 'PRERESERVATION_PRERESERVATION_REJECTED', code: 'PRERESERVATION_REJECTED' },
    { label: 'PRERESERVATION_PRERESERVED', code: 'PRERESERVED' },
  ];
  pageSize = 10;
  noResultMessageI18nToken = 'Txt_PreReservationsTrackingTable_No_Result';

  /**
   * isLoadingAction : private flag when waiting action result
   *
   * @type {boolean}
   * @memberof PreReservationsTrackingTableComponent
   */
  public isLoadingAction = false;

  @ViewChild('drawer', { static: true }) drawer: MatDrawer;

  public lotId: number;

  public optionLotPriceId: number;

  public prereservationId: number;

  public columns;

  /**
   * isSignaturesButtonVisible attribute
   *
   * @type {boolean}
   * @memberof PreReservationsTrackingTableComponent
   */
  public isSignaturesButtonVisible = false;

  /**
   * isManualPreservationButtonVisible attribute
   *
   * @type {boolean}
   * @memberof PreReservationsTrackingTableComponent
   */
  public isManualPreservationButtonVisible = false;

  /**
   * statusFilters attribute
   *
   * @type {object}
   * @memberof PreReservationsTrackingTableComponent
   */
  public readonly statusFilters: { [key: string]: StatusFilter };

  /**
   * statusFilter attribute
   *
   * @type {object}
   * @memberof PreReservationsTrackingTableComponent
   */
  public statusFilter: StatusFilter;

  /**
   * count attribute
   *
   * @type {object}
   * @memberof PreReservationsTrackingTableComponent
   */
  public count = {
    all: 0,
    ongoing: 0,
    closed: 0,
    transformed: 0,
  };
  displayTeamButton: boolean;
  displayPartnerButton: boolean;
  isPartnerParam: boolean;
  isTeamMemberParam: boolean;
  isDossierProspectEnabled: boolean;
  private readonly _filter: string;
  private readonly $destroy: Subject<boolean> = new Subject<boolean>();
  textFilterSubject = new Subject<string>();
  textFilterValue: string;

  /**
   * Creates an instance of PreReservationsTrackingTableComponent.
   * @param {PreReservationsTrackingTableService} preReservationsTrackingTableService
   * @param {I18nService} i18nService
   * @param {AppConfigService} appConfigService
   * @param {MatDialog} dialog
   * @param {ZipFileService} zipFileService
   * @param {ReservationService} reservationService
   * @param {SnackbarService} snackbarService
   * @param userRoleService
   * @param {Router} router
   * @param route
   * @memberof PreReservationsTrackingTableComponent
   */
  // eslint-disable-next-line
  constructor(
    public readonly preReservationsTrackingTableService: PreReservationsTrackingTableService,
    public readonly i18nService: I18nService,
    private readonly appConfigService: AppConfigService,
    private readonly dialog: MatDialog,
    private readonly zipFileService: ZipFileService,
    private readonly reservationService: ReservationService,
    private readonly snackbarService: SnackbarService,
    private readonly userRoleService: UserRoleService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly signatureService: SignatureService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly downloadUtils: DownloadUtils,
  ) {
    super(Sitemap.dashboards.preReservations.path, router);

    this.isDossierProspectEnabled = false;

    this.columnDefs = [
      {
        field: 'lotNumber',
        headerName: this.i18nService._('Title_TrackingTable_Column_LotNumber'),
        minWidth: 80,
        flex: 2,
        cellRenderer: InformationRendererComponent,
        cellRendererParams: {
          picto: {
            iconName: 'informations',
            i18nTooltip: 'Title_TrackingTable_Column_LotNumber_Picto_Tooltip',
            class: 'text-sm',
          },
          action: (reservation: PreReservationsTrackingTableItem) => {
            if (reservation.actions?.canShowLotDetails) {
              this.showLotDetails(reservation);
            }
          },
        },
      },
      {
        field: 'statusLabel',
        headerName: this.i18nService._('Title_TrackingTable_Column_StatusLabel'),
        cellRenderer: StatusTagsRendererComponent,
        minWidth: 156,
        flex: 2,
        cellRendererParams: {
          tagsList: tagTypeByPreReservationStatus,
          outline: true,
        },
        valueFormatter: (params) => {
          return this.statusFilterList.find((status) => status.code === params.value)?.label ?? params.value;
        },
      },
      {
        field: 'programDetails',
        headerName: this.i18nService._('Title_TrackingTable_Column_ProgramDetails'),
        minWidth: 150,
        flex: 5,
      },
      {
        field: 'globalSellingPrice',
        headerName: this.i18nService._('Title_TrackingTable_Column_GlobalSellingPrice'),
        minWidth: 100,
        flex: 2,
        cellRenderer: PriceRendererComponent,
      },
      {
        field: 'prospectFullName',
        headerName: this.i18nService._('Title_TrackingTable_Column_ProspectFullName'),
        minWidth: 150,
        flex: 5,
      },
      {
        field: 'accountFullname',
        headerName: this.i18nService._('Title_TrackingTable_Column_AccountFullname'),
        minWidth: 150,
        flex: 5,
      },
      {
        field: 'companyTradingName',
        headerName: this.i18nService._('Title_TrackingTable_Column_CompanyTradingName'),
        minWidth: 150,
        flex: 5,
        hide: !this.userRoleService.isValo(),
        suppressColumnsToolPanel: true,
      },
      {
        field: 'createdDate',
        headerName: this.i18nService._('Title_TrackingTable_Column_CreatedDate'),
        cellRenderer: DateRendererComponent,
        sort: 'desc',
        minWidth: 80,
        flex: 2,
        cellClass: (params) => {
          const reservation = params.data;
          if (!reservation) return '';

          // set date to check if reservation date is 7 days old or more
          const sevenDaysOld = new Date();
          sevenDaysOld.setDate(sevenDaysOld.getDate() - 7);
          sevenDaysOld.setHours(0, 0, 0, 0);

          // set date to check if reservation date is 4 days old
          const fourDaysOld = new Date();
          fourDaysOld.setDate(fourDaysOld.getDate() - 4);
          fourDaysOld.setHours(0, 0, 0, 0);

          // set reservation date
          const reservationDate = new Date(reservation.createdDate);
          reservationDate.setHours(0, 0, 0, 0);

          if (reservation.statusLabel === PreReservationStatusEnum.PENDING) {
            if (reservationDate <= sevenDaysOld) {
              return 'bg-red-400';
            } else if (reservationDate <= fourDaysOld) {
              return 'bg-orange-300';
            }
          }
          return '';
        },
        cellRendererParams: {
          format: 'DD/MM/yyyy HH:mm',
        },
      },
      {
        field: 'rcvFullname',
        headerName: this.i18nService._('Title_ResaTrackingTable_Column_ResponsableCommercial'),
        minWidth: 150,
        flex: 5,
      },
      {
        field: 'actions',
        headerName: this.i18nService._('Title_TrackingTable_Column_Actions'),
        minWidth: 60,
        maxWidth: 60,
        flex: 2,
        sortable: false,
        cellStyle: { paddingRight: '10px' },
        cellRenderer: ActionRendererComponent,
        cellRendererParams: {
          actionItems: async (reservation: any) => {
            return await this.getActionItems(reservation);
          },
        },
      },
    ];

    this.statusFilters = {
      all: {
        code: 'all',
        label: 'all',
        status: [],
      },
      ongoing: {
        code: 'ongoing',
        label: 'inProgress',
        phase: [this.appConfigService._('reservationPhase', 'prereservation')],
        status: [this.appConfigService._('reservationStatus', 'pending'), this.appConfigService._('reservationStatus', 'prereserved')],
      },
      closed: {
        code: 'closed',
        label: 'closed',
        phase: [this.appConfigService._('reservationPhase', 'prereservation')],
        status: [
          this.appConfigService._('reservationStatus', 'prereservationCanceled'),
          this.appConfigService._('reservationStatus', 'prereservationRejected'),
        ],
      },
      transformed: {
        code: 'transformed',
        label: 'transformed',
        phase: [
          this.appConfigService._('reservationPhase', 'reservation'),
          this.appConfigService._('reservationPhase', 'sold'),
          this.appConfigService._('reservationPhase', 'desisted'),
        ],
      },
    };
  }

  ngOnInit(): void {
    this.serviceUrl = this.preReservationsTrackingTableService.url;

    const valoMarketer = [ValoRole.valoMarketerSimple, ValoRole.valoMarketerSuper];
    const valoDeveloper = [ValoRole.valoDevSimple, ValoRole.valoDevSuper];
    this.isSignaturesButtonVisible = !(this.userRoleService.isInRoles(valoDeveloper) || this.userRoleService.isInRoles(valoMarketer));

    this.isManualPreservationButtonVisible = this.userRoleService.isInRoles([
      ValoRole.valoAdminSimple,
      ValoRole.valoAdminSuper,
      ValoRole.valoRcvSuper,
      ValoRole.valoRcvSimple,
      ValoRole.valoBoSimple,
      ValoRole.valoBoSuper,
    ]);

    this.displayTeamButton = this.userRoleService.isInRoles([ValoRole.valoRcvSuper]);
    this.displayPartnerButton = this.userRoleService.isInRoles([ValoRole.valoRcvSimple]);

    this.featureFlagService.isEnabled('dossier-prospect').then((res) => {
      this.isDossierProspectEnabled = res;
    });

    this.setFilterValue();
    super.filterEvent(this.filterUrlParam);

    this.onTextFilterChange();

    this.updateCountPrereservations();
  }

  setFilterValue() {
    const partner = JSON.parse(this.route.snapshot.queryParamMap.get('isPartner'));
    this.isPartnerParam = Boolean(this.displayPartnerButton && partner === null ? true : partner);
    this.isTeamMemberParam = Boolean(JSON.parse(this.route.snapshot.queryParamMap.get('isTeamMember')));
    this.filterValue = this.route.snapshot.queryParamMap.get('filter');
    this.textFilterValue = this.route.snapshot.queryParamMap.get('filter');
    const statusFilterParams = this.route.snapshot.queryParamMap.get('statusFilter');
    this.statusFilter = statusFilterParams ? this.statusFilters[statusFilterParams] : this.statusFilters.all;

    this.filterUrlParam = {
      statusFilter: statusFilterParams,
      filter: this.filterValue,
      isPartner: this.isPartnerParam,
      isTeamMember: this.isTeamMemberParam,
    };
  }

  onTextFilterChange() {
    this.textFilterSubject.pipe(debounceTime(this.textFilterDebounceTimer), distinctUntilChanged()).subscribe((value) => {
      this.filterUrlParam.filter = value;
      super.filterEvent(this.filterUrlParam);
      this.textFilterValue = value;
    });
  }

  async getActionItems(reservation: PreReservationsTrackingTableItem) {
    const actions = [];

    // TODO: faire l'appel depuis le back vers demat plutot qu'ici
    if (reservation?.actions?.canOnlineSignValo || reservation?.actions?.canContinueOnlineSignValo) {
      const dossiers: Dossier[] = await lastValueFrom(this.signatureService.findDossier({ reservationId: reservation.id }, true));
      if (dossiers.length === 1) {
        reservation.signatureId = dossiers[0].id;
        reservation.actions.canOnlineSignValo = false;
        reservation.actions.canContinueOnlineSignValo = true;
      } else if (dossiers.length === 0) {
        reservation.actions.canContinueOnlineSignValo = false;
      }
    }

    // Cancel
    if (reservation?.actions?.canCancel) {
      actions.push({
        label: 'Txt_Button_Cancel',
        action: () => {
          const data: MatDialogConfig<QuestionDialogData> = {
            data: {
              title: this.i18nService._('Title_PreReservation_Cancelation'),
              message: this.i18nService._('Txt_PreReservationCancelationPopin_InformationText'),
              buttonCancel: this.i18nService._('Txt_Button_No'),
              buttonConfirm: this.i18nService._('Txt_Button_Yes'),
            },
          };

          const preReservationCancelationPopin = this.dialog.open(QuestionDialogComponent, data);
          preReservationCancelationPopin.afterClosed().subscribe((result: QuestionDialogResponse) => {
            if (result?.answer) {
              this.reservationService.cancelPreReservation(reservation.id).subscribe(() => {
                if (reservation.signatureId) {
                  this.signatureService
                    .cancelDossierSignature(reservation.signatureId, this.i18nService._('Reason_Void_Signature_After_Resa_Canceled'))
                    .pipe(
                      catchError((err) => {
                        this.snackbarService.errorI18n('Failed_Cancel_Signature_of_Resa');
                        return err;
                      }),
                    )
                    .subscribe();
                }
                const message: SnackbarMessage = {
                  text: this.i18nService._('Success_SnackBar_ReservationCanceled'),
                  type: SnackbarMessageType.Info,
                };
                this.snackbarService.sendMessage(message);
                this.gridTrackingTable.updateSingleDataRow(reservation.id);
              });
            }
          });
        },
      });
    }

    // Download contract bundle
    if (reservation?.actions?.canDownloadContractualBundle) {
      actions.push({
        label: 'Txt_Tooltip_DownloadReservationContrat',
        action: ($event, reservationElement: any) => {
          $event.stopPropagation();
          reservationElement.downloading = true;
          this.reservationService.getContractualDocumentPackage(reservation.id).subscribe((documents: Array<DocumentResponse>) => {
            this.downloadUtils.downloadAndZipContractualDocuments(documents, reservation);
          });
        },
        isDownload: true,
      });
    }

    // online sign
    if (reservation?.actions?.canOnlineSign) {
      actions.push({
        label: 'Txt_Tooltip_OnlineSign',
        action: () => {
          this.isLoadingAction = true;
          this.reservationService.signOnline(reservation.id).subscribe(
            ({ url }) => {
              this.isLoadingAction = false;
              window.open(url, '_blank');
            },
            () => {
              this.isLoadingAction = false;
            },
          );
        },
      });
    }

    // Continue online sign
    if (reservation?.actions?.canContinueOnlineSign) {
      actions.push({
        label: 'Txt_Tooltip_ContinueOnlineSign',
        action: () => {
          this.accessElectronicSignatures();
        },
      });
    }

    // online sign valo
    if (reservation?.actions?.canOnlineSignValo) {
      actions.push({
        label: 'Txt_Tooltip_OnlineSign',
        action: () => {
          this.isLoadingAction = true;
          this._googleTagManagerService.pushTag({ event: 'esignature_start_process' });
          this.signatureService
            .createDossier({
              reservationId: reservation.id,
              prescriptorId: reservation.accountId,
            })
            .subscribe((dossier) => {
              this.router.navigate([Sitemap.signature.dashboard.path.replace(/:signatureId/, dossier.id.toString())]);
            });
        },
      });
    }

    if (reservation?.actions?.canContinueOnlineSignValo) {
      actions.push({
        label: 'Txt_Tooltip_ContinueOnlineSign',
        action: () => {
          this.isLoadingAction = true;
          this.router.navigate([Sitemap.signature.dashboard.path.replace(/:signatureId/, reservation.signatureId.toString())]);
        },
      });
    }

    // Show pre reservation
    if (reservation?.actions?.canShowPreReservation) {
      actions.push({
        label: 'Txt_Tooltip_ShowPreReservation',
        action: () => {
          const data: MatDialogConfig<PreReservationSubmitionDialogData> = {
            autoFocus: false,
            data: {
              reservationId: reservation.id.toString(),
            },
          };

          const preReservationPopin = this.isDossierProspectEnabled
            ? this.dialog.open(PreReservationDossierProspectSubmitionDialogComponent, { ...data, panelClass: `dialog-container-new` })
            : this.dialog.open(PreReservationSubmitionDialogComponent, data);

          preReservationPopin.afterClosed().subscribe((success: boolean) => {
            if (success) {
              // Reload the data
              this.gridTrackingTable.updateSingleDataRow(reservation.id);
            }
          });
        },
      });
    }

    // Show reservation
    if (reservation?.actions?.canGotoReservation) {
      actions.push({
        label: 'Txt_Tooltip_ShowReservation',
        action: () => {
          // Route to page of the reservation
          this.router.navigate([Sitemap.reservations.edit.path.replace(/:reservationId/, reservation.id.toString())]);
        },
      });
    }

    // Show offer resume
    if (reservation?.actions?.canShowLotDetails) {
      actions.push({
        label: 'Txt_Tooltip_ShowOfferResume',
        action: () => {
          this.showLotDetails(reservation);
        },
      });
    }

    return actions;
  }

  updateCountPrereservations(): void {
    this.preReservationsTrackingTableService.countPreReservation().subscribe((result) => {
      this.count = {
        all: result.nbStatus.all,
        ongoing: result.nbStatus.ongoing,
        closed: result.nbStatus.closed,
        transformed: result.nbStatus.transformed,
      };
    });
  }

  /**
   * accessElectronicSignatures method
   *
   * @memberof PreReservationsTrackingTableComponent
   */
  public accessElectronicSignatures(): void {
    this.isLoadingAction = true;
    this.reservationService.accessElectronicSignatures().subscribe(
      ({ url }) => {
        this.isLoadingAction = false;
        window.open(url, '_blank');
      },
      () => {
        this.isLoadingAction = false;
      },
    );
  }

  /**
   * statusFilterChange method to update filter on child advancedTrackingTable
   *
   * @memberof PreReservationsTrackingTableComponent
   */
  public statusFilterChange(): void {
    this.filterUrlParam.statusFilter = this.statusFilter.code;
    super.filterEvent(this.filterUrlParam);
  }

  public showLotDetails(reservation: PreReservationsTrackingTableItem): void {
    this.lotId = reservation.lotId;
    this.prereservationId = reservation.id;
    if (this.drawer) {
      this.drawer.open();
    }
  }

  gotToDossierSignature(): any {
    this._googleTagManagerService.pushTag({ event: 'esignature_access_signatures' });
    return this.router.navigate([Sitemap.dashboards.dossier.path]);
  }

  goToManualPreservation(): any {
    return this.router.navigate([Sitemap.preReservations.create.path]);
  }

  changeTeamFilter(event: boolean): void {
    this.isTeamMemberParam = event;
    this.filterUrlParam.isTeamMember = this.isTeamMemberParam;
    super.filterEvent(this.filterUrlParam);
  }

  changePartnerFilter(event: boolean): void {
    this.isPartnerParam = event;
    this.filterUrlParam.isPartner = this.isPartnerParam;
    super.filterEvent(this.filterUrlParam);
  }

  ngOnDestroy(): void {
    this.$destroy.next(false);
    this.$destroy.complete();
  }
}
