import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { SecondaryLotTypeEnum } from '@commons-dto/valo-back';

import { DataLayerApiService } from './data-layer-api.service';
import { SecondaryLotTypeResponse } from '../utils/models/SecondaryLotTypeResponse';

@Injectable({ providedIn: 'root' })
export class SecondaryLotTypeApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Get SecondaryLotTypes
   *
   * @returns {SecondaryLotType[]}
   * @memberof DataLayerApiService
   */
  async getSecondaryLotTypes(): Promise<SecondaryLotTypeResponse[]> {
    const path = this.BASE_URL + 'SecondaryLotTypes';
    const res: Observable<SecondaryLotTypeResponse[]> = await this.get(path);
    return await lastValueFrom(res);
  }

  public async getSecondaryLotTypesByAnnexeType(
    annexeType: SecondaryLotTypeEnum.PARKING | SecondaryLotTypeEnum.STORAGE,
  ): Promise<SecondaryLotTypeResponse[]> {
    const path = this.BASE_URL + `SecondaryLotTypes/get-by-annexe-type/${annexeType}`;
    const res: Observable<SecondaryLotTypeResponse[]> = this.get(path);
    return await lastValueFrom(res);
  }
}
