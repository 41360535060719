import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { IconComponent } from '../../../common/standalone/icon/icon.component';
import { AppIconsName } from '../../../common/standalone/icon/utils/app-icon-names';
import { PipesModule } from '../../../utils/pipes/pipes.module';

@Component({
  selector: 'app-dashboard-card',
  standalone: true,
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
  imports: [CommonModule, IconComponent, PipesModule],
})
export class DashboardCardComponent {
  @Input()
  title: string;

  @Input()
  icon: AppIconsName;

  @Input()
  complete: boolean;
}
