import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SecondaryLotTypeEnum } from '@commons-dto/valo-back';
import { NgIf } from '@angular/common';

import { SearchFilterInputComponent } from '../search-filter-input/search-filter-input.component';
import { SearchFilterTagsComponent } from '../search-filter-tags/search-filter-tags.component';
import { SearchFilterActableComponent } from '../search-filter-actable/search-filter-actable.component';
import { SearchFilterDelivryDateComponent } from '../search-filter-delivry-date/search-filter-delivry-date.component';
import { SearchFilterAnnexesComponent } from '../search-filter-annexes/search-filter-annexes.component';
import { SearchFilterOrientationComponent } from '../search-filter-orientation/search-filter-orientation.component';
import { SearchFilterOutsideComponent } from '../search-filter-outside/search-filter-outside.component';
import { SearchFilterProgramTypeComponent } from '../search-filter-program-type/search-filter-program-type.component';
import { SearchFilterBudgetComponent } from '../search-filter-budget/search-filter-budget.component';
import { SearchFilterFloorComponent } from '../search-filter-floor/search-filter-floor.component';
import { SearchRoomsComponent } from '../search-rooms/search-rooms.component';
import { SearchLocalisationComponent } from '../search-localisation/search-localisation.component';
import { SearchFilterDeveloperCompanyComponent } from '../search-filter-developer-company/search-filter-developer-company.component';

import { TranslatePipe } from '../../../../utils/pipes/translate.pipe';
import { AccountUtilsService } from '../../../../accounts/services/account-utils.service';
import { SearchFilterOption } from '../../../model/search-filter-option';
import { UserRoleService } from '../../../../utils/services/user-role.service';
import { SnackbarService } from '../../../../utils/services/snackbar.service';
import { AccountService } from '../../../../utils/services/account.service';
import { FEES_ITEMS, MIN_PROFITABILITY_ITEMS } from '../../../../utils/models/app-constant';

@Component({
  selector: 'app-search-filter-content',
  templateUrl: './search-filter-content.component.html',
  styleUrls: ['./search-filter-content.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    SearchFilterDeveloperCompanyComponent,
    SearchLocalisationComponent,
    SearchRoomsComponent,
    SearchFilterFloorComponent,
    SearchFilterBudgetComponent,
    SearchFilterProgramTypeComponent,
    SearchFilterOutsideComponent,
    SearchFilterOrientationComponent,
    SearchFilterAnnexesComponent,
    SearchFilterDelivryDateComponent,
    SearchFilterActableComponent,
    SearchFilterTagsComponent,
    SearchFilterInputComponent,
    TranslatePipe,
  ],
})
export class SearchFilterContentComponent implements OnInit, OnDestroy {
  public selectOptions: { [key: string]: SearchFilterOption };
  public isContractor: boolean;
  public isFeesHidden = false;
  protected readonly $d = new Subject();
  public loggedUserIsValo: boolean;
  public secondaryLotTypeEnum = SecondaryLotTypeEnum;

  constructor(
    private readonly _userRoleService: UserRoleService,
    private readonly accountService: AccountService,
    private readonly accountUtilsService: AccountUtilsService,
    private readonly snackbarService: SnackbarService,
  ) {
    this.selectOptions = {
      taxation: {
        type: 'select',
        mustInitCheckDisable: true,
        referenceTable: {
          filter(item): boolean {
            return !!item.taxationType;
          },
          referenceTableNames: 'Taxations',
        },
        placeholder: 'Title_Form_SearchForm_Taxation',
        fieldName: 'taxation',
      },
      taxationZone: {
        type: 'select',
        referenceTable: {
          filter(): boolean {
            return true;
          },
          referenceTableNames: 'TaxZones',
        },
        placeholder: 'Title_Form_SearchForm_TaxZone',
        fieldName: 'taxationZone',
      },
      profitability: {
        type: 'select',
        placeholder: 'Title_Form_SearchForm_MinProfitability',
        fieldName: 'profitability',
        items: MIN_PROFITABILITY_ITEMS,
        tooltip: {
          icon: 'info',
          text: 'Txt_search_filter_profitability_disable_tooltip',
        },
        disabled: (where) => !where.taxation || where.taxation === 2,
      },
      rent: {
        type: 'input',
        inputType: 'number',
        placeholder: 'Title_Form_SearchForm_Rent',
        fieldName: 'rent',
        tooltip: {
          icon: 'info',
          text: 'Txt_search_filter_rent_disable_tooltip',
        },
        suffixIcon: 'euro',
        transformOutputValue: (value) => {
          return {
            minRent: 0,
            maxRent: Number(value),
          };
        },
        transformInputValue: (where) => (where.rent ? where.rent.maxRent : undefined),
        disabled: (where) => !where.taxation || where.taxation === 2,
      },
      fees: {
        type: 'select',
        placeholder: 'Title_Form_SearchForm_Fees',
        fieldName: 'fees',
        items: FEES_ITEMS,
      },
    };
  }

  ngOnInit() {
    this.loggedUserIsValo = this.accountUtilsService.getParentAndLoggedUserInfos().loggedUserIsValo;
    this.isContractor = this._userRoleService.isContractor();
    if (this.isContractor) {
      this.accountService.checkFeesCantBeSeen().subscribe(
        (isFeesHidden: boolean) => {
          this.isFeesHidden = isFeesHidden;
        },
        () => {
          this.snackbarService.sendErrorOccured();
        },
      );
    }
  }

  ngOnDestroy() {
    this.$d.next(true);
    this.$d.complete();
  }
}
