<div class="px-4 xl-min-vw-63 lg-min-vw-63 md-min-vw-45 sm-min-vw-32">
  <h2 class="mb-5 text-center" mat-dialog-title>
    {{ i18nService._('Title_Desktop_Publishing_Dialog') }}
  </h2>

  <div mat-dialog-content>
    <div *ngIf="!errorOccured; else elseBlock" class="mb-4 w-full">
      <div class="pb-1">
        <span class="text-base font-semibold"> {{ i18nService._('Txt_Desktop_Publishing_downloadYourFile') }}</span>
        <span> *</span>
      </div>
      <form [formGroup]="form">
        <div class="fileDrop-enabled file-drop-container relative mt-2 mr-5 max-w-full">
          <!-- File drop area -->
          <ngx-file-drop id="my-file-drop" class="file-drop" (onFileDrop)="fileDropped($event)">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              <div *ngIf="fileControl.value" class="file-dropped">
                <div class="pl-3 text-xs truncate">
                  {{ fileControl.value.name }}
                </div>
                <div class="px-3 ml-auto cursor-pointer" [hidden]="isDisabled" (click)="removeAttachment()">
                  <span class="icon-cancel text-xxs font-bold"></span>
                </div>
              </div>
              <div class="ml-auto pl-2">
                <label for="docxFile" *ngIf="browserAvailable">
                  <div class="txt-orange cursor-pointer" title="{{ i18nService._('Txt_Button_Add_File') }}">
                    <span class="icon-plus text-sm font-bold"></span>
                  </div>
                  <input
                    #fileInput
                    id="docxFile"
                    class="hidden"
                    type="file"
                    accept="{{ splitTypes }}"
                    (change)="fileAdded($event.target.files)"
                  />
                </label>
              </div>
            </ng-template>
          </ngx-file-drop>
          <!-- Error msg -->
          <div class="text-xs">
            <mat-error *ngIf="fileControl.errors && alreadyLoad">
              <span *ngIf="fileControl.errors.required">{{ i18nService._('Error_Field_Document') }}</span>
              <span *ngIf="fileControl.errors.fileType && !fileControl.errors.required">
                {{ i18nService._('Error_Field_DocumentFormat', [extErrorMessage]) }}
              </span>
              <span *ngIf="!fileControl.errors.fileType && fileControl.errors.fileSize && !fileControl.errors.required">
                {{ i18nService._('Error_Field_DocumentSize', [maxFileSizeMo]) }}
              </span>
              <span *ngIf="!fileControl.errors.fileType && fileControl.errors.fileEmpty && !fileControl.errors.required">
                {{ i18nService._('Error_Field_DocumentEmpty') }}
              </span>
            </mat-error>
          </div>
        </div>
      </form>
    </div>
    <ng-template #elseBlock>
      <p class="text-center txt-red font-semibold mb-6" [innerHTML]="i18nService._('txt_Desktop_Publishing_Error') | safe: 'html'"></p>
    </ng-template>
  </div>
  <div class="justify-center text-center" mat-dialog-actions>
    <div class="flex flex-col flex-wrap justify-center items-center">
      <button
        *ngIf="!errorOccured"
        mat-flat-button
        class="mb-4"
        color="primary"
        type="submit"
        title="{{ i18nService._('Txt_Desktop_Publishing_generateDocument') }}"
        (click)="submit()"
      >
        <span class="hidden lg:inline">
          {{ i18nService._('Txt_Desktop_Publishing_generateDocument') }}
        </span>
        <span class="lg:hidden">
          {{ i18nService._('Txt_Desktop_Publishing_generateDocument') | ellipsis: 20 }}
        </span>
      </button>
      <button mat-flat-button class="mt-3 mb-4" color="primary" title="{{ i18nService._('Txt_Button_Exit') }}" (click)="close()">
        {{ i18nService._('Txt_Button_Exit') }}
      </button>
    </div>
  </div>
</div>
