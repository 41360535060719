import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/internal/operators/tap';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs';

import { SpecialOfferType } from '../model/special-offer-type';

import { AppConfigService } from '../../utils/services/app-config.service';
import { CacheService } from '../../utils/services/cache.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class SpecialOfferTypeService {
  private readonly cacheName = 'SpecialOfferTypeList';

  constructor(
    private readonly _http: HttpClient,
    private readonly _appConfig: AppConfigService,
    private readonly _cacheService: CacheService,
    private readonly _errorHandlerService: ErrorHandlerService,
  ) {}

  getSpecialOfferType(): Observable<Array<SpecialOfferType>> {
    const data = this._cacheService.getData(this.cacheName);
    if (data) {
      return of(data).pipe(take(1));
    }
    const url = `${this._appConfig.getLoopbackApiUrl()}/SpecialOfferTypes`;

    return this._http.get<Array<SpecialOfferType>>(url).pipe(
      tap((value) => {
        this._cacheService.addToCache(this.cacheName, value);
      }),
      catchError(this._errorHandlerService.handleError<Array<SpecialOfferType>>('SpecialOfferTypeService', 'getSpecialOfferType')),
    );
  }
}
