import { Injectable } from '@angular/core';
import { PaternityCreateRequest, SummaryPaternityResponse } from 'libs/commons-dto/src/lib/dossier-prospect';
import { PaternityCreateResponse } from '@commons-dto/dossier-prospect';
import { Observable } from 'rxjs';

import { DataLayerApiService } from './data-layer-api.service';

@Injectable({ providedIn: 'root' })
export class PaternityApiService extends DataLayerApiService {
  BASE_URL = 'prospect/';

  getByPaternitiesIds(paternitiesIds: number[]): Observable<SummaryPaternityResponse[]> {
    const url = `${this.BASE_URL}paternities?paternitiesIds=${paternitiesIds}`;
    return this.get<SummaryPaternityResponse[]>(url);
  }

  create(paternities: PaternityCreateRequest[]) {
    const url = `${this.BASE_URL}paternities/create`;
    return this.post<PaternityCreateResponse>(url, paternities);
  }
}
