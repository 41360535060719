<div *ngFor="let fieldGroup of field.fieldGroup">
  OK
  <div class="pl-2 relative flex items-center">
    <span
      [ngClass]="{
        'flex-1 text-xl leading-7': fieldGroup.props['isHead'],
        'text-base leading-[26px]': fieldGroup.props['isSubHead'],
        'text-sm leading-6': !fieldGroup.props['isHead'] && !fieldGroup.props['isSubHead']
      }"
    >
      {{ i18nService._(fieldGroup.props.label) }}
    </span>
    <app-icon
      *ngIf="isValid(fieldGroup) && !fieldGroup.props['isHead'] && !fieldGroup.props['isSubHead']"
      name="Check"
      class="text-green-400 ml-4"
    ></app-icon>
    <div *ngIf="fieldGroup.props['isHead']" class="justify-self-end text-xl leading-7">
      <app-icon [name]="fieldGroup.props['open'] ? 'ChevronUp' : 'ChevronDown'" class="justify-self-end right-0"></app-icon>
    </div>
  </div>
</div>
