/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs';

import { AppConfigService } from '../../utils/services/app-config.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';
import { ServiceLocator } from '../../utils/services/service-locator';

@Injectable({
  providedIn: 'root',
})
export abstract class AbstractAdvancedTrackingTableNoBackPageService<T> {
  protected serviceName = 'AbstractAdvancedTrackingTableService';
  protected url: string;
  public readonly appConfig: AppConfigService;
  public readonly errorHandlerService: ErrorHandlerService;
  public readonly http: HttpClient;

  constructor() {
    this.appConfig = ServiceLocator.injector.get(AppConfigService);
    this.errorHandlerService = ServiceLocator.injector.get(ErrorHandlerService);
    this.http = ServiceLocator.injector.get(HttpClient);
  }

  /**
   * Get all items for tracking table
   *
   * @returns {Observable<T>}
   * @memberof AbstractAdvancedTrackingTableService
   */
  getItems(serviceParams?: string): Observable<T> {
    return this.http
      .get<T>([this.appConfig.getLoopbackApiUrl(), this.url, serviceParams].filter(Boolean).join('/'))
      .pipe(catchError(this.errorHandlerService.handleError<any>(this.serviceName, 'get')));
  }

  /**
   * Get single item to update in tracking table
   *
   * @param {number} id
   * @returns {Observable<T>}
   * @memberof AbstractAdvancedTrackingTableService
   */
  getSingleItem(id: number): Observable<T> {
    return this.http
      .get<T>(`${this.appConfig.getLoopbackApiUrl()}/${this.url}`, {
        params: new HttpParams().set('id', id.toString()),
      })
      .pipe(catchError(this.errorHandlerService.handleError<any>(this.serviceName, 'get')));
  }
}
