import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DataLayerApiService } from './data-layer-api.service';
import { Item } from '../tracking-tables/models/Item';

@Injectable({ providedIn: 'root' })
export class ValoRoleTypeApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Get valoRoleTypes
   *
   * @returns {Observable<Array<Item>}
   * @memberof DataLayerApiService
   */
  getValoRoleTypes(): Observable<Array<Item>> {
    const path = this.BASE_URL + 'ValoRoleTypes';
    return this.get(path);
  }
}
