import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgIf } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';

import { AbstractSearchFilter } from '../abstract-search-filter';

import { ReferenceTableData } from '../../../../utils/models/ReferenceTableData';
import { TaxationResponse } from '../../../../utils/models/TaxationResponse';
import { I18nService } from '../../../../utils/services/i18n.service';
import { ReferenceTablesService } from '../../../../utils/services/reference-tables.service';
import { SnackbarService } from '../../../../utils/services/snackbar.service';

@Component({
  selector: 'app-search-taxation',
  templateUrl: './search-taxation.component.html',
  styleUrls: ['./search-taxation.component.scss'],
  standalone: true,
  imports: [MatChipsModule, NgFor, NgIf, MatIconModule],
})
export class SearchTaxationComponent extends AbstractSearchFilter {
  public taxationItems: ReferenceTableData[];

  constructor(
    public i18nService: I18nService,
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly snackbarService: SnackbarService,
  ) {
    super();
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    this.referenceTablesService.getTableReferenceInfo('Taxations').subscribe(
      (items: Array<TaxationResponse>) => {
        this.taxationItems = items
          .filter((item: TaxationResponse) => item.taxationType)
          .map((taxation) => {
            const item = new ReferenceTableData();
            item.id = taxation.id;
            item.label = taxation.label.replace(/_+/g, ' ');

            return item;
          })
          .sort((first: ReferenceTableData, second: ReferenceTableData) => {
            return first.label.localeCompare(second.label);
          });

        super.ngOnInit();
      },
      () => {
        this.snackbarService.sendErrorOccured();
      },
    );
  }

  selectItem(item: ReferenceTableData) {
    this.search.taxation = this.search.taxation === item.id ? undefined : (item.id as number);
    this._searchFormUtilsService.setSearchFilter({ where: this.search }, this.waitForSearch);
  }

  protected initData() {
    const taxation = this.taxationItems.find((value) => value.id === this.search.taxation);
    this.filterChangeEvent.next(taxation ? [taxation.label.replace(/_+/g, ' ')] : []);
  }
}
