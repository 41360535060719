export class ResponsiblesInfo {
  responsibleBi: ResponsibleInfo;
  responsibleNonBi: ResponsibleInfo;
}

export class ResponsibleInfo {
  firstName: string;
  lastName: string;
  address: string;
  postalCode: string;
  city: string;
  deskPhone: string;
  email: string;
  mobilePhone?: string;
}
