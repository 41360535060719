<div class="grid grid-cols-2">
  <div class="col-span-2 lg:col-span-1 flex flex-row">
    <app-fast-search-form class="w-full h-full flex justify-center fast-search-form"></app-fast-search-form>
  </div>

  <!-- TODO: Insert a SnackBar error dev in file.ts "Error_SnackBar_ErrorOccuredRetry" i18N -->
  <div class="col-span-2 lg:col-span-1 flex flex-col">
    <div class="hidden lg:flex flex-col justify-start items-end mb-8 md:mb-0 w-full p-8" *ngIf="loggedUserIsValo && !isConnectionAs">
      <app-login-as-form class="flex flex-row"></app-login-as-form>
    </div>
    <div class="flex flex-col justify-start items-center mb-8 md:mb-0 w-full p-8">
      <app-target2sell-reco [target2SellRecoId]="target2SellRecoId"></app-target2sell-reco>
    </div>
  </div>
</div>
