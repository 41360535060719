import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { DataLayerApiService } from './data-layer-api.service';
import { CountryResponse } from '../utils/models/CountryResponse';

@Injectable({ providedIn: 'root' })
export class CountryApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Get country by id
   *
   * @returns {CountryResponse}
   * @memberof DataLayerApiService
   */
  async getCountryById(id: number): Promise<CountryResponse> {
    const path = this.BASE_URL + 'Countries/' + id;
    return await lastValueFrom(this.get(path));
  }
}
