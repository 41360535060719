/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { NgClass, NgFor, NgIf } from '@angular/common';

import { AbstractFormEvent } from '../abstract-form-event';

import { AppIconsName } from '../../standalone/icon/utils/app-icon-names';
import { TooltipComponent } from '../../shared/tooltip/tooltip.component';
import { IconComponent } from '../../standalone/icon/icon.component';

@Component({
  selector: 'app-radio-card',
  templateUrl: './radio-card.component.html',
  styleUrls: ['./radio-card.component.scss'],
  standalone: true,
  imports: [NgFor, NgClass, IconComponent, NgIf, TooltipComponent],
})
export class RadioCardComponent extends AbstractFormEvent implements OnInit {
  @Input() data: { id: number; label: string; icon: AppIconsName; [keys: string]: any }[] | null = [
    { id: 1, label: 'Une personne', icon: 'User' },
    { id: 2, label: 'Deux personnes', icon: 'Users' },
    { id: 3, label: 'Une société', icon: 'BuildingOffice' },
  ];

  @Input() radioCardControl: AbstractControl | null;
  @Input() valueProp = 'value';
  @Input() labelProp = 'value';
  @Input() tooltip: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.data?.forEach((card) => {
      card.isChecked = card[this.valueProp] === this.radioCardControl?.value;
    });
  }

  onRadioCardClick(card: any) {
    this.data?.forEach((card) => {
      card.isChecked = false;
    });
    card.isChecked = true;
    this.radioCardControl.setValue(card[this.valueProp]);
    this.radioCardControl.markAsDirty();
    this.radioCardControl.markAsTouched();
  }
}
