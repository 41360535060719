import { CdkStep } from '@angular/cdk/stepper';
import { Component } from '@angular/core';

@Component({
  selector: 'app-small-stepper-step',
  templateUrl: './small-stepper-step.component.html',
  styleUrls: ['./small-stepper-step.component.scss'],
  providers: [{ provide: CdkStep, useExisting: SmallStepperStepComponent }],
  standalone: true,
})
export class SmallStepperStepComponent extends CdkStep {}
