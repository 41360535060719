<div class="grid grid-cols-12 w-full justify-between bg-white fixed z-20 py-3 l-0 r-0 shadow-sm">
  <div class="flex justify-start items-start md:ml-5 col-span-3">
    <button
      (click)="goBack()"
      class="btn-small ml-2 md:ml-0"
      color="primary"
      mat-stroked-button
      title="{{ i18nService._('Txt_Button_GoBack') }}"
      data-cy="goBack"
    >
      <span class="icon-arrow-left text-sm font-bold txt-orange lg:pr-2"></span>
      <span class="hidden lg:inline">{{ i18nService._('Txt_Button_GoBack') | ellipsis: 9 }}</span>
    </button>
  </div>

  <!-- Title -->
  <h2 [attr.data-cy]="titleText" class="text-center text-2xl font-bold col-span-6">
    {{ titleText }}
  </h2>

  <!-- Action button -->
  <div class="flex justify-end items-start md:mr-5 col-span-3">
    <ng-container *ngIf="isActionButtonVisible && !isActionButtonVisibleStategie">
      <button
        [attr.data-cy]="actionButtonLabel"
        *ngIf="actionButtonLabel || isActionButtonActiveAsEmpty"
        class="btn-small mr-2 md:mr-0 truncate"
        mat-stroked-button
        [disabled]="isActionButtonDisabled"
        color="primary"
        (click)="onActionButtonClicked()"
        title="{{ actionButtonLabel }}"
      >
        <span [class]="actionButtonIconMob" class="lg:hidden text-xs pr-lg-2"></span>
        <span title="{{ actionButtonTooltipText }}" class="hidden lg:inline">
          <span *ngIf="actionButtonLabel">{{ actionButtonLabel }}</span>
          <span class="{{ actionButtonIcon + (isActionButtonActiveAsEmpty ? '' : ' lg:pl-2') }}"></span>
        </span>
      </button>
    </ng-container>

    <ng-container *ngIf="isActionButtonVisibleStategie">
      <app-action-button-stategie
        [actionButtonLabel]="actionButtonLabel"
        [isActionButtonDisabled]="isActionButtonDisabled"
      ></app-action-button-stategie>
    </ng-container>
  </div>
</div>
