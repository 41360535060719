<div class="desktop-menu h-full hidden sm:flex flex-row items-center">
  <div class="main-nav h-full flex flex-row">
    <ng-container *ngFor="let menuItem of menuItems">
      <ng-container *ngIf="!menuItem.isOffNav">
        <app-menu-item
          *ngIf="!menuItem.hide"
          [attr.data-cy]="menuItem.label"
          [i18nService]="i18nService"
          [menuItem]="menuItem"
          class="menu-item relative mx-1 lg:mx-2 h-full group"
        ></app-menu-item>
      </ng-container>
    </ng-container>
  </div>

  <span class="v-sep h-5 mx-4 border-l border-white"></span>
  <div class="off-nav h-full flex flex-row">
    <ng-container *ngFor="let menuItem of menuItems">
      <ng-container *ngIf="menuItem.isOffNav">
        <app-menu-item
          *ngIf="!menuItem.hide"
          [attr.data-cy]="menuItem.label"
          [i18nService]="i18nService"
          [menuItem]="menuItem"
          [class]="menuItem.className"
          class="menu-item relative mx-1 h-full group"
        ></app-menu-item>
      </ng-container>
    </ng-container>
  </div>
</div>
