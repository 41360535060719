<span class="add-box" (click)="addRow()" *ngIf="!field?.props?.disabled && field?.props?.canAddRow">
  <div class="add-icon">
    <app-icon class="text-white flex" name="Plus"></app-icon>
  </div>
  {{ i18nService._(field?.props?.addButtonLabel) }}
</span>

<app-grid-tracking-table
  #table
  [columnDefs]="field?.props?.columnDefs"
  [defaultColDef]="field?.props?.defaultColDef"
  [tableLoader]="tableLoader"
  [pageSize]="pageSize"
  [tableHeight]="tableHeight"
  [gridOptions]="gridOptions"
  [enablePagination]="!field?.props?.disablePagination"
  [getRowId]="null"
  [serverSide]="false"
>
</app-grid-tracking-table>
