/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ValoRole } from '@commons-dto/valo-back';

import { AppConfigService } from './app-config.service';
import { ConfigurableParamService } from './configurable-param.service';
import { I18nService } from './i18n.service';
import { UserRoleService } from './user-role.service';

/**
 * Injects Google Tag Manager in the application and authorize its tags
 * in regards of our CSP
 * https://valorissimo.atlassian.net/l/c/Xz41dXr3
 */
@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  /**
   * ID of the GTM iframe tag in the DOM
   */
  public static readonly gtmiFrameId = 'gtmiFrame';
  /**
   * ID of the GTM script tag in the DOM
   */
  public static readonly gtmScriptId = 'gtmScript';
  private readonly browserGlobals = {
    windowRef(): any {
      return window;
    },
    documentRef(): any {
      return document;
    },
  };
  private gtmId: string;
  private isLoaded = false;

  constructor(
    private readonly configurableParamService: ConfigurableParamService,
    private readonly userRoleService: UserRoleService,
    private readonly _i18nService: I18nService,
  ) {
    firstValueFrom(this.configurableParamService.getGoogleTagManagerId(true)).then((gtmId) => {
      this.gtmId = gtmId;
      this.addGtmToDom();
    });
  }

  /**
   * Nonce generated by nginx at each request
   * Null if no nonce is found (as in a local environment)
   */
  public get nginxNonce(): string {
    const selector = this.browserGlobals.documentRef().querySelector('[nonce]');
    if (selector) {
      return selector.nonce || selector.getAttribute('nonce');
    }

    return null;
  }

  /**
   * Push a tag Google Tag Manager
   *
   * @param item Item to push
   */
  public pushTag(item: object): void {
    if (AppConfigService.isDebug()) {
      console.log('pushing tag', item);
    }
    this.pushOnDataLayer(item);
  }

  public getDatalayerRole(userRole: ValoRole): string {
    if (this.userRoleService.isContractor(userRole)) {
      return this._i18nService._('Txt_datalayer_role_rpv');
    }
    if (this.userRoleService.isDeveloper(userRole)) {
      return this._i18nService._('Txt_datalayer_role_dev');
    }
    if (this.userRoleService.isValo(userRole)) {
      return this._i18nService._('Txt_datalayer_role_valo');
    }

    return this._i18nService._('Txt_datalayer_role_free');
  }

  /**
   * Add Google Tag Manager to DOM with nonce propagation
   */
  public addGtmToDom(): void {
    if (!this.gtmId || this.isLoaded) {
      return;
    }

    this.pushOnDataLayer({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    const doc = this.browserGlobals.documentRef();
    const nonce = this.nginxNonce || 'nginx-did-not-generate-nonce';

    const gtmScript = doc.createElement('script');
    gtmScript.id = GoogleTagManagerService.gtmScriptId;

    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${this.gtmId}`;
    gtmScript.setAttribute('nonce', nonce);
    // Pass the nonce to a data attribute that will be retrieved by GTM
    gtmScript.setAttribute('data-nonce', nonce);
    gtmScript.async = true;
    doc.head.appendChild(gtmScript);

    const iframe = doc.createElement('iframe');
    iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${this.gtmId}`);
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';

    const noscript = doc.createElement('noscript');
    noscript.id = GoogleTagManagerService.gtmiFrameId;
    noscript.appendChild(iframe);

    doc.body.prepend(noscript);
    this.isLoaded = true;
  }

  /**
   * Get the window data layer
   */
  private getDataLayer(): Array<any> {
    const window = this.browserGlobals.windowRef();
    window.dataLayer = window.dataLayer || [];

    return window.dataLayer;
  }

  /**
   * Push data to window data layer
   *
   * @param obj Data to push
   */
  private pushOnDataLayer(obj: object): void {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }
}
