<div class="comparator-preview-item" *ngIf="lot && !isEmpty" [class.comparator-unavailable-preview-item]="lot.isUnvailable">
  <div class="close" (click)="removeLot(lot)">
    <mat-icon>close</mat-icon>
  </div>
  <div class="comparator-preview-item-perspective">
    <img [alt]="lot.perspective.title" [src]="documentPerspective" />
  </div>
  <div class="comparator-preview-item-infos">
    <div class="comparator-preview-item-lot-number">
      {{ i18nService._('Txt_Page_Program_ListLotNumber') + lot.lotNumber }}
    </div>
    <div class="comparator-preview-item-city">{{ lot.city }} ({{ lot.postalCode }})</div>
    <div class="comparator-preview-item-delivery-and-price">T{{ lot.rooms }} | {{ formattedLotPrice }}</div>
  </div>
  <div class="comparator-preview-item-unavailable-info" *ngIf="lot.isUnvailable">
    <mat-icon class="material-icons-outlined size-18">error_outline</mat-icon>
    <span>{{ i18nService._('Txt_comparator_lot_unavailable') }}</span>
  </div>
</div>
<ng-container *ngIf="isEmpty">
  <div class="comparator-preview-empty-item">
    {{ i18nService._('Txt_button_comparator_empty_container') }}
    <mat-icon [svgIcon]="'find_compare'" class="comparator-preview-empty-item-icon material-icons-outlined size-18"></mat-icon>
  </div>
</ng-container>
