<div *ngIf="isHighStake" class="card-high-stake">
  <app-tags [ngClass]="highStakeTag.ngClass" [tagOption]="highStakeTag.tagOption"></app-tags>
</div>
<div>
  <div class="field-header font-normal">{{ i18nService._('Txt_Page_Program_RpvCard_FirstPublication') }}</div>
  <div class="font-semibold mb-4">{{ firstPublicationDate }}</div>
  <div class="field-header font-normal">{{ i18nService._('Txt_Page_Program_RpvCard_DeveloperUpdate') }}</div>
  <div class="font-semibold mb-4">{{ publishedDate }}</div>
</div>
<div>
  <div class="field-header font-normal">{{ i18nService._('Txt_Page_Program_ManagerCard_AssociatedManager') }}</div>
  <div class="font-semibold mb-4">{{ associatedRpv }}</div>
</div>
<div>
  <a class="font-bold" data-cy="mandateStrategy" routerLink="/programme/{{ programId }}/strategies" [queryParams]="{ tabMandat: 0 }"
    ><u>{{ i18nService._('Title_MandateStrategy') }}</u>
    <mat-icon class="material-icons-outlined size-18">navigate_next</mat-icon>
  </a>
  <br /><br />
  <a class="font-bold" data-cy="financialStrategy" routerLink="/programme/{{ programId }}/strategies" [queryParams]="{ tabMandat: 1 }"
    ><u>{{ i18nService._('Title_FinancialStrategy') }}</u>
    <mat-icon class="material-icons-outlined size-18">navigate_next</mat-icon>
  </a>
</div>
