<div class="text-base font-bold text-left mb-3">
  {{ i18nService._('Title_Form_SearchForm_RoomNumber') }}
</div>
<div class="flex flex-row">
  <app-checkbox-populated
    [source]="roomItems"
    [notTranslated]="true"
    (checkedElementsChange)="setSelectedRooms($event)"
    [cssClassNames]="'mat-checkbox-rounded-number mr-3'"
    [checkedElements]="getRoom()"
  >
  </app-checkbox-populated>
</div>
