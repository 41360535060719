import { Injectable } from '@angular/core';
import moment from 'moment-timezone';

import { BasicFormatsService } from '../basic-formats.service';
import { AppConfigService } from '../app-config.service';

@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  constructor(
    private readonly _formatsService: BasicFormatsService,
    private readonly appConfigService: AppConfigService,
  ) {
    this.zone = this.appConfigService.getAppConfig().timeZone;
    this.local = this.appConfigService.getLocale();
  }

  private _format = BasicFormatsService.defaultDateFormat;

  get format(): string {
    return this._format;
  }

  set format(value: string) {
    this._format = value;
  }

  private _transformTimeZone = false;

  get transformTimeZone(): boolean {
    return this._transformTimeZone;
  }

  set transformTimeZone(value: boolean) {
    this._transformTimeZone = value;
  }

  private _zone;

  get zone() {
    return this._zone;
  }

  set zone(value) {
    this._zone = value;
  }

  private _local;

  get local() {
    return this._local;
  }

  set local(value) {
    this._local = value;
  }

  formatToString({ date, transformTimeZone = false, zone }: { date: moment.Moment; transformTimeZone?: boolean; zone?: string }): string {
    let dateToTransforme = date.clone();
    if (transformTimeZone) {
      dateToTransforme = moment.tz(date, zone);
    }

    return dateToTransforme.format(this.format);
  }
}
