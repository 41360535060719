import { Component, Input, OnInit } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

import { AlertEnum } from '../../models/enums/alert.enum';
import { I18nService } from '../../services/i18n.service';
import { SafePipe } from '../../pipes/safe.pipe';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, SafePipe],
})
export class AlertComponent implements OnInit {
  @Input() type: AlertEnum;
  @Input() title: string;
  @Input() body: string;

  config = {
    [AlertEnum.INFO]: {
      styleClass: 'info',
      iconName: 'info',
    },
    [AlertEnum.WARN]: {
      styleClass: 'warn',
      iconName: 'warning',
    },
    [AlertEnum.ERROR]: {
      styleClass: 'error',
      iconName: 'error',
    },
  };

  constructor(public _i18nService: I18nService) {}

  ngOnInit() {
    this._initDefaultValue();
  }

  private _initDefaultValue() {
    this.type = this.type || AlertEnum.INFO;
  }
}
