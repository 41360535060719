<div *ngIf="formView" class="w-full h-full pt-10 px-8">
  <div class="flex w-full mx-auto max-w-7xl gap-8 xl:gap-10 h-full">
    <div class="flex flex-col w-[300px]">
      <ng-container>
        <app-box-back-button
          [goBack]="goBack()"
          [disabled]="loadingPdf"
          [label]="draftState ? 'txt_return_dossier_signature' : 'txt_return_progress'"
          class="mb-6"
        ></app-box-back-button>
        <app-side-info [formView]="formView"></app-side-info>
      </ng-container>
    </div>
    <div class="flex flex-col flex-1 gap-x-8">
      <!-- Header -->
      <app-form-header [formView]="formView" class="flex bg-white h-22 w-full rounded-10 shadow-custom mb-6"></app-form-header>

      <ngx-extended-pdf-viewer
        *ngIf="pdf; else waitingPDF"
        class="mb-6 rounded-10 bg-white s hadow-custom"
        style="overflow: hidden"
        [src]="pdf"
        [height]="'683px'"
        [useBrowserLocale]="true"
        [handTool]="false"
        [showHandToolButton]="false"
        [showFindButton]="true"
        [showRotateButton]="false"
        [showPresentationModeButton]="true"
        [showOpenFileButton]="false"
        [showPrintButton]="false"
        [showDownloadButton]="true"
      ></ngx-extended-pdf-viewer>

      <ng-template #waitingPDF>
        <div
          *ngIf="loadingPdf; else pdfError"
          style="height: 683px"
          class="mb-6 rounded-10 bg-gray-200 shadow-custom p-4 flex flex-col justify-center items-center"
        >
          <mat-spinner [color]="'primary'" [diameter]="60" [strokeWidth]="6" class="mx-auto my-5" [mode]="'indeterminate'"></mat-spinner>
          <span>{{ i18nService._('txt_synthesis_pdf_load') }}</span>
        </div>

        <ng-template #pdfError>
          <div style="min-height: 683px" class="mb-6 bg-white rounded-10 shadow-custom p-4 flex flex-col justify-center items-center">
            <p class="text-red-400 bg-gray-100 rounded-10 p-4 shadow-custom">
              {{ i18nService._('txt_synthesis_pdf_error_1') }}<br />
              {{ i18nService._('txt_synthesis_pdf_error_2') }}<br />
              {{ i18nService._('txt_synthesis_pdf_error_3') }}
            </p>
          </div>
        </ng-template>
      </ng-template>

      <div class="h-24 px-8 py-6 bg-white shadow-custom flex justify-between rounded-10">
        <button
          *ngIf="draftState"
          [disabled]="loadingPdf"
          [routerLink]="['../dashboard']"
          routerLinkActive="router-link-active"
          class="rounded-button-grey"
        >
          <app-icon name="Pencil" type="solid" class="pb-1 mr-2"></app-icon>
          {{ i18nService._('txt_synthesis_modify') }}
        </button>
        <button *ngIf="draftState" [disabled]="loadingPdf || hasError" type="button" class="rounded-button" (click)="chooseSignatureType()">
          {{ i18nService._('txt_synthesis_validate') }}
        </button>
      </div>
    </div>
  </div>
</div>
