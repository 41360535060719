import {
  CompleteDossierResponse,
  DossierCreate,
  DossierCreateResponse,
  DossierResponse,
  SearchDossierQuery,
} from '@commons-dto/dossier-prospect';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ParamRequest } from '@commons-dto/valo-back';
import { SimulationResponseDto } from 'libs/commons-dto/src/lib/dossier-prospect';

import { DataLayerApiService } from './data-layer-api.service';
import { DossierProspectTrackingTableServiceResponse } from '../tracking-tables/models/DossierProspectTrackingTableResponse';

import { SimulationRequestDto } from './../../../../../libs/commons-dto/src/lib/dossier-prospect/simulation/simulation.request.dto';

@Injectable({ providedIn: 'root' })
export class DossierProspectApiService extends DataLayerApiService {
  BASE_URL = 'prospect/';

  /**
   * Get dossier-prospect by params
   *
   * @returns {Observable<DossierProspectTrackingTableServiceResponse>}
   * @memberof DataLayerApiService
   */
  getDossierProspectForTrackingTable(params: ParamRequest): Observable<DossierProspectTrackingTableServiceResponse> {
    const path = this.BASE_URL + 'dossiers/list';
    return this.getTableRessources(params, path);
  }

  getDossierProspect(searchQuery: SearchDossierQuery): Observable<Partial<DossierResponse>[]> {
    const path = this.BASE_URL + 'dossiers';
    return this.getBySearchQuery(path, searchQuery);
  }

  canUpsert(dossier: Partial<DossierResponse>) {
    const url = `${this.BASE_URL}dossiers/can-upsert`;
    return this.post<number>(url, dossier);
  }

  createDossier(body: DossierCreate): Observable<DossierCreateResponse> {
    const url = `${this.BASE_URL}dossiers/create`;
    return this.post<DossierCreateResponse>(url, body);
  }

  updateDossier(data: Partial<DossierResponse>): Observable<CompleteDossierResponse> {
    const url = `${this.BASE_URL}dossiers/` + data.id;
    return this.put<CompleteDossierResponse>(url, data);
  }

  loadDossiersForSearching() {
    const url = `${this.BASE_URL}dossiers/dossiers-for-searching`;
    return this.get<{ nbItems: number; items: Partial<DossierResponse>[] }>(url);
  }

  getDossierByLotId(lotId: number): Observable<DossierResponse> {
    const url = `${this.BASE_URL}dossiers/lot/${lotId}`;
    return this.get<DossierResponse>(url);
  }

  getDossier(id: number, forConsult = false) {
    const baseUrl = `${this.BASE_URL}dossiers/${id}`;
    const url = forConsult ? `${baseUrl}?forConsult=${forConsult}` : baseUrl;
    return this.get<CompleteDossierResponse>(url);
  }

  saveSimulation(simulation: SimulationRequestDto): Observable<SimulationResponseDto> {
    const baseUrl = `${this.BASE_URL}simulation/save`;
    return this.put<SimulationResponseDto>(baseUrl, simulation);
  }

  getSimulation(id): Observable<SimulationResponseDto> {
    const baseUrl = `${this.BASE_URL}simulation/${id}`;
    return this.get<SimulationResponseDto>(baseUrl);
  }
}
