<div class="flex flex-col max-w-full w-3xl px-9 py-6 relative">
  <app-icon
    name="XMark"
    size="1.5rem"
    class="text-gray-500 hover:text-gray-600 absolute right-0 top-0 cursor-pointer"
    (click)="close(false)"
  ></app-icon>
  <h1 mat-dialog-title class="text-3xl font-bold mb-8 text-center">{{ _i18nService._('update_signature_dossier') }}</h1>
  <mat-dialog-content>
    <p [innerHTML]="_i18nService._('update_signature_dossier_text')"></p>
  </mat-dialog-content>
  <div class="flex justify-end gap-4">
    <button (click)="close(false)" class="rounded-button-grey">{{ _i18nService._('update_signature_dossier_cancel') }}</button>
    <button type="button" class="rounded-button" (click)="close(true)">{{ _i18nService._('update_signature_dossier_validate') }}</button>
  </div>
</div>
