import { AfterViewInit, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgIf } from '@angular/common';

import { LotComparatorMetaLineComponent } from '../lot-comparator-meta-line/lot-comparator-meta-line.component';

import { LotComparatorDetail } from '../../../../lot/model/lot-comparator-detail';
import { ComparatorMeta, ComparatorMetaWrapper } from '../../../models/comparator-meta';
import { AbstractComparatorComponent } from '../../abstract-comparator-component';

@Component({
  selector: 'app-lot-comparator-price-details',
  templateUrl: './lot-comparator-price-details.component.html',
  styleUrls: ['./lot-comparator-price-details.component.scss'],
  standalone: true,
  imports: [NgIf, LotComparatorMetaLineComponent],
})
export class LotComparatorPriceDetailsComponent extends AbstractComparatorComponent implements AfterViewInit {
  lotMetaPriceInfos: ComparatorMetaWrapper;
  @Input() lotDetailToCompare: LotComparatorDetail[];
  @ViewChild('priceFormatted', { static: true })
  public priceFormattedTemplateRef: TemplateRef<unknown>;
  @ViewChild('reducedPriceFormatted', { static: true })
  public reducedPriceFormattedTemplateRef: TemplateRef<unknown>;

  ngAfterViewInit(): void {
    this.lotMetaPriceInfos = this._comparatorPageService.comparatorMeta.priceInfos;

    (this.lotMetaPriceInfos.priceFormatted as ComparatorMeta).templateRef = this.priceFormattedTemplateRef;
    (this.lotMetaPriceInfos.reducedPriceFormatted as ComparatorMeta).templateRef = this.reducedPriceFormattedTemplateRef;
  }
}
