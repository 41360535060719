import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom } from 'rxjs';

import { CompanyData } from '../models/CompanyData';
import { AppConfigService } from './app-config.service';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class SharedCompanyService {
  private activeContractorCompanies: CompanyData[];

  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
  ) {
    this.activeContractorCompanies = undefined;
  }

  async getActiveContractorAndIndependantCompanies(programId: number, refreshCacheData = true): Promise<CompanyData[]> {
    if (!this.activeContractorCompanies || refreshCacheData) {
      const url = `${this.appConfig.getLoopbackApiUrl()}/Companies/active-contractors-and-independants/${programId}`;
      this.activeContractorCompanies = (await lastValueFrom(
        this.http
          .get<CompanyData[]>(url)
          .pipe(catchError(this.errorHandlerService.handleError<void>('companyService', 'getActiveContractorAndIndependantCompanies'))),
      )) as CompanyData[];
    }

    return this.activeContractorCompanies;
  }
}
