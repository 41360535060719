import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgControl, UntypedFormControl } from '@angular/forms';
import { isNil } from 'lodash-es';
import { NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { CommonModule } from '@angular/common';

import { DocumentResponse } from '../../../utils/models/DocumentResponse';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { UploadFile } from '../../services/upload-file';
import { FileExtention } from '../../../utils/models/app-constant';
import { IconComponent } from '../../standalone/icon/icon.component';

@Component({
  selector: 'app-form-file',
  standalone: true,
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  imports: [CommonModule, NgxFileDropModule, IconComponent],
})
export class FileComponent implements OnInit {
  /**
   * Select file label input
   *
   * @type {string}
   * @memberof FileDropComponent
   */
  @Input() selectFileLabel: string;

  /**
   * Accepted files label input
   *
   * @type {string}
   * @memberof FileDropComponent
   */
  @Input() acceptedFilesLabel: string;

  /**
   * Max file size input in octet (default max size is 20 Mio)
   *
   * @type {number}
   * @memberof FileDropComponent
   */
  @Input() maxFileSize: number = 1024 ** 2 * 20;

  /**
   * multiple input
   *
   * @type {boolean}
   * @memberof FileDropComponent
   */
  @Input() multiple: boolean;

  /**
   * readonly input
   *
   * @type {boolean}
   * @memberof FileDropComponent
   */
  @Input() readonly: boolean;

  /**
   * required input
   *
   * @type {boolean}
   * @memberof FileDropComponent
   */
  @Input() required: boolean;

  /**
   * Types attribute
   *
   * @type {MimeTypeExtention[] | Array<string>}
   * @memberof FileDropComponent
   */
  @Input() types: Array<FileExtention> | Array<string> = [FileExtention.PDF, FileExtention.PNG, FileExtention.JPG, FileExtention.JPEG];

  /**
   * docType type of the upload document (CI, SOAM, KBIS...)
   *
   * @type {MimeTypeExtention[] | Array<string>}
   * @memberof FileDropComponent
   */
  @Input() docType: string;

  /**
   * fieldNameKey input for formly
   *
   * @type {string}
   * @memberof FileDropComponent
   */
  @Input() fieldNameKey: string;

  /**
   * formControl input
   *
   * @type {FormControl}
   * @memberof FileDropComponent
   */
  @Input() formControlCustom: UntypedFormControl;

  /**
   * uploadedFiles
   * @type {*}
   * @memberof FileDropComponent
   */
  uploadedFiles: File[];

  /**
   * files input
   * @type {*}
   * @memberof FileDropComponent
   */
  @Input() files: [DocumentResponse];

  /**
   * fileRequiredErrorI18nToken attribute
   * @type {string}
   * @memberof FileDropComponent
   */
  @Input() fileRequiredErrorI18nToken = 'Error_Field_Document';

  /**
   * fileFormatErrorI18nToken attribute
   * @type {string}
   * @memberof FileDropComponent
   */
  @Input() fileFormatErrorI18nToken = 'Error_Field_DocumentFormat';

  /**
   * fileSizeErrorI18nToken attribute
   * @type {string}
   * @memberof FileDropComponent
   */
  @Input() fileSizeErrorI18nToken = 'Error_Field_DocumentSize';

  /**
   * manyAttachmentForbiddenErrorI18nToken attribute
   * @type {string}
   * @memberof FileDropComponent
   */
  @Input() manyAttachmentForbiddenErrorI18nToken = 'Error_SnackBar_ManyAttachmentsForbidden';

  /**
   * incomptaibleBrowserErrorI18nToken attribute
   * @type {string}
   * @memberof FileDropComponent
   */
  @Input() incomptaibleBrowserErrorI18nToken = 'Error_SnackBar_BrowserNotCompatibleWithAttachment';

  @Input() uploadService: UploadFile;
  @Input() documentForId: number;

  /**
   * browserAvailable attribute
   *
   * @type {boolean}
   * @memberof FileDropComponent
   */
  public browserAvailable: boolean;

  /**
   *  Variable for file size error message
   *
   * @type {number}
   * @memberof FileDropComponent
   */
  public sizeErrorMessage: number;

  /**
   * Variable for file type error message
   *
   * @type {string}
   * @memberof FileDropComponents
   */
  public extErrorMessage: string;

  /**
   * Return of split on types
   *
   * @type {string}
   * @memberof FileDropComponent
   */
  public splitTypes: string;

  public isInvalidFormat = false;

  private readonly greyBg: string = `rgb(242 242 242 / var(--tw-bg-opacity))`;
  private readonly whiteBg: string = `white`;

  /**
   * Creates an instance of FileDropComponent.
   *
   * @param {I18nService} i18nService
   * @param {SnackbarService} snackbarService
   * @param {MatDialog} dialog
   *
   * @memberof FileDropComponent
   */
  constructor(
    public i18nService: I18nService,
    private readonly snackbarService: SnackbarService,
    private readonly ngRef: ChangeDetectorRef,
  ) {}

  /**
   * ngOnInit method
   *
   * @memberof FileDropComponent
   */
  public ngOnInit(): void {
    this.setupValidationMessages();
    this.checkBrowserCompatibility();
    this.initFormControl();
  }

  private setupValidationMessages() {
    // Variable for file type error message
    this.extErrorMessage = this.types
      .map((ext) => ext.split('/')[1])
      .toString()
      .toUpperCase();

    // Varibale to set html authorized extension
    this.splitTypes = this.types.toString();

    // Variable for file size error message
    // Change from Mo to Mio (VALO-3657)
    this.sizeErrorMessage = Math.round(this.maxFileSize / 1024 ** 2);
  }

  private checkBrowserCompatibility() {
    // Check if browser can upload attachmentControl
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.browserAvailable = typeof window['File'] !== 'undefined' && typeof window['FileReader'] !== 'undefined';
    if (!this.browserAvailable) {
      const message: SnackbarMessage = {
        text: this.i18nService._(this.incomptaibleBrowserErrorI18nToken),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
    }
  }

  private initFormControl() {
    this.formControlCustom = this.formControlCustom || new UntypedFormControl();
    if (!this.formControlCustom.value) {
      this.formControlCustom.setValue(this.multiple ? [] : null);
    }
  }

  /**
   * fileSize method, to validate file size
   *
   * @param {NgControl} control
   * @returns {{ [key: string]: boolean }}
   * @memberof FileDropComponent
   */
  public fileSize(control: NgControl): { [key: string]: boolean } {
    if (this.maxFileSize > control?.value?.size || (!this.required && !control?.value)) {
      return undefined;
    }

    return { fileSize: true };
  }

  /**
   * fileDropped method, called when file is dropped
   *
   * @param {UploadEvent} event
   * @memberof FileDropComponent
   */
  public fileDropped(files: Array<NgxFileDropEntry>): void {
    this.fileLeave();
    if (this.isValidFilesInput(files)) {
      Array.from(files).forEach((file) => {
        this.checkAndAddDroppedFile(file);
      });
    } else {
      const message: SnackbarMessage = {
        text: this.i18nService._(this.manyAttachmentForbiddenErrorI18nToken),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
    }
  }

  /**
   * setNewFile method
   *
   * @public
   * @param {*} file
   * @memberof FileDropComponent
   */
  public setNewFile(file): void {
    let files = this.uploadedFiles ?? [];
    if (!this.multiple) {
      files = [];
    }
    files.push(file);
    this.uploadedFiles = files;

    if (this.multiple) {
      this.formControlCustom.setValue(this.uploadedFiles);
    } else {
      this.formControlCustom.setValue(this.uploadedFiles[0]);
    }

    this.ngRef.detectChanges();
  }

  /**
   *
   * @memberof FileDropComponent
   */
  public getMaxFileSize(): number {
    return Math.round(this.maxFileSize / 1024 ** 2);
  }

  /**
   *
   * @memberof FileDropComponent
   */
  public watchFile(index?: number): void {
    if (isNil(index)) {
      window.open(URL.createObjectURL(this.formControlCustom.value));
    } else {
      window.open(URL.createObjectURL(this.formControlCustom.value[index]));
    }
  }

  /**
   *
   * @memberof FileDropComponent
   */
  public getFileUrl(index?: number): string {
    return isNil(index) ? URL.createObjectURL(this.formControlCustom.value) : URL.createObjectURL(this.formControlCustom.value[index]);
  }

  /**
   *
   * @memberof FileDropComponent
   */
  public deleteFile(file): void {
    if (isNil(file.id)) {
      this.removeFileFromUploadedFiles(file);
      return;
    }

    if (this.uploadService) {
      this.uploadService.delete(file.id).subscribe(() => {
        this.removeFileFromUploadedFiles(file);
      });
    } else {
      this.removeFileFromUploadedFiles(file);
      return;
    }
  }

  private removeFileFromUploadedFiles(file) {
    this.uploadedFiles = this.uploadedFiles.filter((f) => {
      return f !== file;
    });
    this.formControlCustom.setValue(this.uploadedFiles);
  }

  public fileOver(): void {
    const element: HTMLDivElement = document.getElementsByClassName('ngx-file-drop__drop-zone')[0] as HTMLDivElement;
    element.style.backgroundColor = this.greyBg;
  }

  public fileLeave(): void {
    const element: HTMLDivElement = document.getElementsByClassName('ngx-file-drop__drop-zone')[0] as HTMLDivElement;
    element.style.backgroundColor = this.whiteBg;
  }

  private checkAndAddDroppedFile(file: NgxFileDropEntry): void {
    if (file?.fileEntry?.isFile) {
      const fileEntry = file.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        this.uploadFile(file);
      });
    } else {
      const message: SnackbarMessage = {
        text: this.i18nService._(this.fileFormatErrorI18nToken, [this.extErrorMessage]),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
    }
  }

  private isValidFilesInput(files) {
    return (files?.length === 1 && !this.multiple) || (files?.length && this.multiple);
  }

  private getFileExtension(file: File): string {
    return /(?:(\.[^.]+))?$/.exec(file.name)[1];
  }

  private hasValidType(file: File): boolean {
    const extension = this.getFileExtension(file);
    for (const type of this.types) {
      if (type.toString() == extension) return true;
    }
    return false;
  }

  private uploadFile(file: File) {
    this.isInvalidFormat = !this.hasValidType(file);
    if (!this.isInvalidFormat) {
      if (file.size <= this.maxFileSize) {
        this.processFileUpload(file);
      } else {
        this.sendFileSizeErrorMessage();
      }
    }
  }

  private processFileUpload(file: File): void {
    if (this.uploadService) {
      this.uploadService.upload(this.documentForId, file, this.docType).subscribe((doc) => {
        doc.size = file.size;
        this.setNewFile(doc);
      });
    } else {
      this.setNewFile(file);
    }
  }

  private sendFileSizeErrorMessage(): void {
    const message: SnackbarMessage = {
      text: this.i18nService._(this.fileSizeErrorI18nToken, [this.sizeErrorMessage]),
      type: SnackbarMessageType.Error,
    };
    this.snackbarService.sendMessage(message);
  }
}
