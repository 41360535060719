export enum TVA {
  NOMINAL_TAXATION = 'NOMINAL_TAXATION',
  ANRU = 'ANRU',
  PINEL = 'PINEL',
  'PINEL PLUS' = 'PINEL PLUS',
  PLS = 'PLS',
  'LMNP/LMP' = 'LMNP/LMP',
  MALRAUX = 'MALRAUX',
  'LMNP NON GERE' = 'LMNP NON GERE',
}
