<div class="text-base font-bold">
  {{ i18nService._('Title_Form_SearchForm_Budget') }}
</div>
<ngx-slider
  class="ngx-slider-styles"
  [(value)]="search.budget.minBudget"
  [(highValue)]="search.budget.maxBudget"
  [options]="budgetSliderOptions"
  (userChangeEnd)="selectedItem($event)"
>
</ngx-slider>
