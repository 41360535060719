<mat-form-field>
  <mat-label>{{ placeholder }}</mat-label>
  <mat-chip-grid #chipList class="chip-autocomplete">
    <mat-chip-row
      class="mt-1 mr-3 flex justify-center items-center"
      color="primary"
      *ngFor="let item of itemsLabelKeyList"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(item)"
    >
      <span matChipRemove *ngIf="removable" class="icon-cancel txt-orange text-xxs ml-0 mr-1 flex justify-center items-center"></span>
      {{ item }}
    </mat-chip-row>
    <input
      #chipInput
      [formControl]="chipCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let item of filteredItems" [value]="item">
      {{ item }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
