<ng-container *ngIf="currentUrlAfterRedirectObservable$ | async as currentUrl">
  <footer class="flex flex-col md:flex-row w-full bg-white-trans75 pt-1 md:pt-4 px-2 border-t border-gray-secondary" data-cy="footer">
    <ng-container>
      <!-- USER NOT LOGGED -->
      <div class="flex flex-col lg:flex-row text-xs overflow-hidden footer-contact" *ngFor="let contact of contacts">
        <div [ngClass]="isLoggedIn ? 'footer-col-container h-full' : 'footer-col-container h-full md:mr-auto'">
          <div [ngClass]="isLoggedIn ? 'contact-title w-full' : 'contact-title md:w-full w-xl-auto'" class="footer-contact-title">
            {{ i18nService._(contact.title) }}
          </div>
          <div class="mt-2 footer-contact-container">
            <div class="footer-contact-content">
              <div class="footer-contact-icon">
                <span class="icon-phone icons-footer"></span>
              </div>
              <a
                href="tel:{{ contact.phone.split('/')[0] }}"
                [ngClass]="isLoggedIn ? 'inline-block w-xl-auto text-xs' : 'inline-block w-xl-auto'"
              >
                {{ contact.phone.split('/')[0] }}
              </a>
              <span *ngIf="contact.phone.split('/').length === 2">/</span>
              <a
                *ngIf="contact.phone.split('/').length === 2"
                href="tel:{{
                  contact.phone.split('/')[0].substr(0, contact.phone.split('/')[0].length - contact.phone.split('/')[1].length) +
                    contact.phone.split('/')[1]
                }}"
                [ngClass]="isLoggedIn ? 'inline-block w-xl-auto text-xs' : 'inline-block w-xl-auto'"
                class="text-right"
              >
                {{ contact.phone.split('/')[1] }}
              </a>
            </div>
            <div class="footer-contact-content">
              <div class="footer-contact-icon">
                <span class="icon-mail icons-footer"></span>
              </div>
              <a
                href="mailto:{{ contact.email }}"
                [ngClass]="isLoggedIn ? 'inline-block w-full w-xl-auto text-xs' : 'inline-block w-full w-xl-auto'"
              >
                {{ contact.email }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- EOF USER LOGGED -->

    <div class="flex flex-col text-xs footer-link-container">
      <div *ngIf="!isLoggedIn" class="footer-col-container h-full md:mr-auto border-b-0 contact-link">
        <a data-cy="legalsNotices" href="/mentions-legales" class="contact-link relative footer-no-icon">
          {{ i18nService._('Footer_Link_MentionsLegales') }}
        </a>
      </div>
    </div>
  </footer>
</ng-container>
