import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { ValidateDeveloperCompanyName } from '../../../companies/models/validate-developer-company-name';
import { CompanyService } from '../../../companies/services/company.service';
import { DocumentResponse } from '../../../utils/models/DocumentResponse';
import { FormUtilsService } from '../../../utils/services/form/form-utils.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SPECIAL_OFFER_TYPE, SpecialOfferType } from '../../model/special-offer-type';
import { SpecialOfferTypeService } from '../../service/special-offer-type.service';
import { SPECIAL_OFFER_CONTROL_PATH } from '../../service/special-offer.service';
import { FileDropComponent } from '../../../utils/components/file-drop/file-drop.component';
import { InputDateComponent } from '../../../utils/components/input-date/input-date.component';

@Component({
  selector: 'app-step-one-special-offer-form',
  templateUrl: './step-one-special-offer-form.component.html',
  styleUrls: ['./step-one-special-offer-form.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    NgFor,
    MatOptionModule,
    NgIf,
    MatSelectModule,
    TextFieldModule,
    InputDateComponent,
    FileDropComponent,
  ],
})
export class StepOneSpecialOfferFormComponent implements OnInit, OnDestroy {
  @Input() specialOfferForm: UntypedFormGroup;
  @Input() submit: boolean;
  companyList: Array<ValidateDeveloperCompanyName> = [];
  companyListFiltered: Array<ValidateDeveloperCompanyName>;
  soTypeList: Array<SpecialOfferType>;
  public sOTypeOther: SpecialOfferType = SPECIAL_OFFER_TYPE.OTHER;
  SPECIAL_OFFER_CONTROL_PATH = SPECIAL_OFFER_CONTROL_PATH;
  formUtils = FormUtilsService;
  private readonly _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public readonly i18nService: I18nService,
    private readonly _companyService: CompanyService,
    private readonly _snackBar: MatSnackBar,
    private readonly _specialOfferTypeService: SpecialOfferTypeService,
  ) {}

  private _amendment: DocumentResponse;
  private _amendmentWithFusionFields: DocumentResponse;

  get amendment(): DocumentResponse {
    return this._amendment;
  }

  @Input() set amendment(amendment: DocumentResponse) {
    this._amendment = amendment;
  }

  get amendmentWithFusionFields(): DocumentResponse {
    return this._amendmentWithFusionFields;
  }

  @Input() set amendmentWithFusionFields(amendmentWithFusionFields: DocumentResponse) {
    this._amendmentWithFusionFields = amendmentWithFusionFields;
  }

  ngOnInit(): void {
    this._companyService.getValidateDeveloperCompanies().subscribe(
      (companyList) => {
        this.companyList = companyList;
        this.companyListFiltered = CompanyService.filterCompany(
          FormUtilsService.getFormValue(SPECIAL_OFFER_CONTROL_PATH.company, this.specialOfferForm),
          this.companyList,
        );
      },
      () => this._snackBar.open(this.i18nService._('Error_SnackBar_ErrorOccured')),
    );

    this.specialOfferForm
      .get(SPECIAL_OFFER_CONTROL_PATH.company)
      .valueChanges.pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this.companyListFiltered = CompanyService.filterCompany(value, this.companyList);
      });
    this._specialOfferTypeService.getSpecialOfferType().subscribe((soTypeList) => {
      this.soTypeList = soTypeList;
    });

    this.specialOfferForm
      .get(SPECIAL_OFFER_CONTROL_PATH.specialOfferType)
      .valueChanges.pipe(takeUntil(this._destroy$))
      .subscribe((specialOfferType) => {
        if (this.isSoTypeOther(specialOfferType)) {
          this.specialOfferForm.get(SPECIAL_OFFER_CONTROL_PATH.specialOfferTypeOther).setValidators([Validators.required]);
        } else {
          this.specialOfferForm.get(SPECIAL_OFFER_CONTROL_PATH.specialOfferTypeOther).setValidators([]);
          this.specialOfferForm.get(SPECIAL_OFFER_CONTROL_PATH.specialOfferTypeOther).setValue(null);
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  displayFn(value?: ValidateDeveloperCompanyName): string {
    return value ? value.corporateName : undefined;
  }

  isSoTypeOther(osType): boolean {
    return osType && osType.includes(this.sOTypeOther.id);
  }
}
