<div class="px-6 md:px-10 lg:px-20 py-14 mx-auto max-w-lg rounded-2xl shadow-2xl bg-white">
  <div class="mb-8 mx-12">
    <img class="max-h-36 mx-auto" src="assets/img/logo/LOGO_VALO_short.svg" alt="Valorissimo" />
  </div>

  <h2 class="text-2xl font-bold mb-4 text-center">
    {{ i18nService._('Title_SignIn') }}
  </h2>

  <form class="flex flex-col" [formGroup]="loginForm" (submit)="connect()">
    <mat-form-field class="mb-2">
      <mat-label>{{ i18nService._('Txt_Placeholder_Email') }}</mat-label>
      <input type="text" formControlName="username" name="email" data-cy="email" matInput maxlength="255" required />
      <mat-error *ngIf="emailControl.errors">
        <span *ngIf="emailControl.errors.required">
          {{ i18nService._('Error_Field_Required') }}
        </span>
        <span *ngIf="!emailControl.errors.email && !emailControl.errors.required">
          {{ i18nService._('Error_Field_IncorrectEmail') }}
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="mb-2">
      <mat-label>{{ i18nService._('Txt_Placeholder_Password') }}</mat-label>
      <input type="password" formControlName="password" name="password" data-cy="password" matInput maxlength="255" required />
      <mat-error *ngIf="passwordControl.errors">
        <span *ngIf="passwordControl.errors.required">{{ i18nService._('Error_Field_Required') }}</span>
      </mat-error>
    </mat-form-field>

    <div class="flex flex-row flex-wrap justify-between items-center mb-8">
      <div class="mr-2">
        <mat-checkbox formControlName="rememberMe" color="primary" class="block">
          {{ i18nService._('Txt_Checkbox_RememberMe') }}
        </mat-checkbox>
      </div>
      <div class="text-right -mb-px">
        <a
          data-cy="resetPassword"
          (click)="openReinitPasswordDialog()"
          title="{{ i18nService._('Title_ResetPassword') }}"
          class="txt-grey text-xs"
        >
          {{ i18nService._('Title_ResetPassword') }}
        </a>
      </div>
    </div>

    <div>
      <button data-cy="toConnect" class="h-12 w-full bg-gradient-to-r from-orange-300 to-orange-600 rounded-full">
        <span class="uppercase font-black text-sm text-white">{{ i18nService._('Txt_Button_Connect') }}</span>
      </button>
    </div>

    <app-log-access-form *ngIf="executeCounter"></app-log-access-form>

    <!-- <div>
      <app-recaptcha></app-recaptcha>
    </div> -->
  </form>
</div>
