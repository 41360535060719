import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { debounceTime, Observable, of, Subscription } from 'rxjs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { QuillModule } from 'ngx-quill';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';

import { AttachmentResponse } from '../../../utils/models/AttachmentsResponse';
import { CompanyData } from '../../../utils/models/CompanyData';
import { CoordinatesResponse } from '../../../utils/models/CoordinatesResponse';
import { DeveloperDescription } from '../../../utils/models/DeveloperDescription';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { FileFormat } from '../../../utils/models/FileFormat';
import { HighlightResponse } from '../../../utils/models/HighlightResponse';
import { PostalAddress } from '../../../utils/models/PostalAddress';
import { PostalAddressInvoice } from '../../../utils/models/PostalAdressInvoice';
import { ProgramCreate } from '../../../utils/models/ProgramCreate';
import { ProgramResponseUpdateForm } from '../../../utils/models/ProgramResponseUpdateForm';
import { ReferenceTableData } from '../../../utils/models/ReferenceTableData';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { ReferenceTablesService } from '../../../utils/services/reference-tables.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { UtilsCompanyService } from '../../../utils/services/utils-company.service';
import { isInteger } from '../../../utils/validators/is-integer';
import { isURLValidator } from '../../../utils/validators/is-url-validator.directive';
import { ProgramService } from '../../services/program.service';
import { HighlightService } from '../../../utils/services/highlight.service';
import { atLeastOne } from '../../../utils/validators/atLeastOne-validator.directive';
import { FileDropComponent } from '../../../utils/components/file-drop/file-drop.component';
import { CoordinatesComponent } from '../../../utils/components/coordinates/coordinates.component';
import { PostalAddressComponent } from '../../../utils/components/postal-address/postal-address.component';
import { DecimalMaskDirective } from '../../../utils/directives/decimal-mask.directive';
import { InputDateComponent } from '../../../utils/components/input-date/input-date.component';
import { DropdownListPopulatedComponent } from '../../../utils/components/dropdown-list-populated/dropdown-list-populated.component';

@Component({
  selector: 'app-step-one-form-program',
  templateUrl: './step-one-form-program.component.html',
  styleUrls: ['./step-one-form-program.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    DropdownListPopulatedComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatCheckboxModule,
    InputDateComponent,
    DecimalMaskDirective,
    PostalAddressComponent,
    CoordinatesComponent,
    MatAutocompleteModule,
    NgFor,
    MatOptionModule,
    QuillModule,
    FileDropComponent,
    MatButtonModule,
    MatStepperModule,
    AsyncPipe,
  ],
})
export class StepOneFormProgramComponent implements OnDestroy, OnInit, AfterViewInit {
  /**
   * Parent form group
   *
   * @type {FormGroup}
   * @memberof StepOneFormProgramComponent
   */
  @Input() parentForm: UntypedFormGroup;

  /**
   * is valorissimo
   *
   * @type {boolean}
   * @memberof StepOneFormProgramComponent
   */
  @Input() isValo: boolean;

  /**
   * accepted file type
   *
   * @type {string[]}
   * @memberof StepOneFormProgramComponent
   */
  @Input() acceptedFileType: string[];

  /**
   * output to emit programCreated
   *
   * @type {EventEmitter<number>}
   * @memberof StepOneFormProgramComponent
   */
  @Output() readonly programCreated = new EventEmitter<number>();

  /**
   * Input init data for update form
   *
   * @type {ProgramResponseUpdateForm}
   * @memberof StepOneFormProgramComponent
   */
  @Input() recoveredInfos: ProgramResponseUpdateForm;

  @Output() readonly devCompanyChanged = new EventEmitter<any>();

  /**
   * The following Abstract controls are used for form fields
   *
   * @type {AbstractControl}
   * @memberof StepOneFormProgramComponent
   */
  public longDescriptionValoControl: AbstractControl;

  public shortDescriptionValoControl: AbstractControl;

  public situationControl: AbstractControl;

  public servicesControl: AbstractControl;

  public qualityControl: AbstractControl;

  public accessControl: AbstractControl;

  public highlightControl: AbstractControl;

  public descriptionControl: AbstractControl;

  public digitalCommercialBrochureUrlControl: AbstractControl;
  public commercialBrochureControl: AbstractControl;

  /**
   * Form group for programText table
   *
   * @type {FormGroup}
   * @memberof StepOneFormProgramComponent
   */
  public programTextForm: UntypedFormGroup;

  /**
   * Recovered documents to init for update form
   *
   * @type {FileFormat[]}
   * @memberof StepOneFormProgramComponent
   */
  public initFile: Array<FileFormat>;

  /**
   * Recovered postal address init data for update form
   *
   * @type {PostalAddress}
   * @memberof StepOneFormProgramComponent
   */
  public recoveredPostalAddress: PostalAddress;

  /**
   * Recovered invoice postal address init data for update form
   *
   * @type {PostalAddress}
   * @memberof StepOneFormProgramComponent
   */
  public recoveredInvoicePostalAddress: PostalAddressInvoice;

  /**
   * Recovered coordinates init data for update form
   *
   * @type {CoordinatesResponse}
   * @memberof StepOneFormProgramComponent
   */
  public recoveredCoordinates: CoordinatesResponse;

  /**
   *
   * @type {DeveloperDescription}
   * @memberof StepOneFormProgramComponent
   */
  public recoveredDescrDeveloper: DeveloperDescription;

  public documents: Array<AttachmentResponse> = [];

  public programId: number;

  cityList: Observable<Array<ReferenceTableData>>;

  highlightList: Array<HighlightResponse>;

  isDeveloper: boolean;
  appConfig;

  isSubmit: boolean;
  isValid: boolean;

  corporateNameItems;

  accountingProgramRefControl;
  developerCompanyNameControl;
  programNameControl;
  url3DViewControl;
  lotsNumberControl;
  proximityCityControl;
  actableControl;
  constructionWorkRatioControl;

  taxZoneItems;
  public buildingPermitStartDateControl: AbstractControl;
  public buildingPermitExpireDateControl: AbstractControl;
  public workingDateControl: AbstractControl;
  /**
   * controlProgramRefValueChangesSubscription attribute
   *
   * @type {Subscription}
   * @memberof StepOneFormProgramComponent
   */
  private controlProgramRefValueChangesSubscription: Subscription;
  /**
   * controlCompanyIdValueChangesSubscription attribute
   *
   * @type {Subscription}
   * @memberof StepOneFormProgramComponent
   */
  private controlCompanyIdValueChangesSubscription: Subscription;
  /**
   * programIdObservableSubscription attribute
   *
   * @type {Subscription}
   * @memberof StepOneFormProgramComponent
   */
  private programIdObservableSubscription: Subscription;

  /**
   * Creates an instance of StepOneFormProgramComponent.
   * @param {I18nService} i18nService
   * @memberof StepOneFormProgramComponent
   */

  isElectronicSignatureValorissimo: boolean;

  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly utilsCompanyService: UtilsCompanyService,
    public i18nService: I18nService,
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly programService: ProgramService,
    private readonly highlightService: HighlightService,
    private readonly snackbarService: SnackbarService,
  ) {
    this.appConfig = this.appConfigService.appConfig;
    this.isDeveloper = false;
    this.isValo = false;
    this.isSubmit = false;
    this.isValid = false;
    this.recoveredDescrDeveloper = undefined;
  }

  /**
   * Init method
   *
   * @memberof StepOneFormProgramComponent
   */
  ngOnInit(): void {
    /* Init array for recovered documents */
    this.initFile = [];
    this.programTextForm = this.formBuilder.group({});

    if (this.isValo) {
      this.programTextForm.addControl('longDescrValo', new UntypedFormControl('', []));
      this.longDescriptionValoControl = this.programTextForm.controls.longDescrValo;
      this.programTextForm.addControl('shortDescrValo', new UntypedFormControl('', []));
      this.shortDescriptionValoControl = this.programTextForm.controls.shortDescrValo;
    }
    this.programTextForm.addControl('description', new UntypedFormControl('', []));
    this.descriptionControl = this.programTextForm.controls.description;

    this.programTextForm.addControl('situation', new UntypedFormControl('', []));
    this.situationControl = this.programTextForm.controls.situation;
    this.programTextForm.addControl('services', new UntypedFormControl('', []));
    this.servicesControl = this.programTextForm.controls.services;
    this.programTextForm.addControl('quality', new UntypedFormControl('', []));
    this.qualityControl = this.programTextForm.controls.quality;
    this.programTextForm.addControl('access', new UntypedFormControl('', []));
    this.accessControl = this.programTextForm.controls.access;

    /* Push controls and values to program text form array */
    const control = this.parentForm.controls.programText as UntypedFormArray;
    control.push(this.programTextForm);
    // Definition of controls
    this.parentForm.addControl('programRef', new UntypedFormControl('', [Validators.required]));
    this.accountingProgramRefControl = this.parentForm.controls.programRef;

    /* Control on program Reference to prevent program creation with existing programRef */
    this.controlProgramRefValueChangesSubscription = this.parentForm.controls.programRef.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.programReferenceValidator(this.programService);
      });
    this.parentForm.addControl('programName', new UntypedFormControl('', [Validators.required]));
    this.programNameControl = this.parentForm.controls.programName;
    this.parentForm.addControl('3dView', new UntypedFormControl('', [isURLValidator()]));
    this.url3DViewControl = this.parentForm.controls['3dView'];
    this.parentForm.addControl('digitalCommercialBrochureUrl', new UntypedFormControl('', [isURLValidator()]));
    this.digitalCommercialBrochureUrlControl = this.parentForm.controls.digitalCommercialBrochureUrl;

    this.parentForm.addControl('nbLots', new UntypedFormControl('', [Validators.required, isInteger()]));
    this.lotsNumberControl = this.parentForm.controls.nbLots;
    this.parentForm.addControl('actable', new UntypedFormControl(false, []));
    this.actableControl = this.parentForm.controls.actable;
    // Set key program dates controller
    this.parentForm.addControl('buildingPermitStartedAt', new UntypedFormControl(null, [Validators.required]));
    this.buildingPermitStartDateControl = this.parentForm.controls.buildingPermitStartedAt;
    this.parentForm.addControl('buildingPermitExpiredAt', new UntypedFormControl(null, [Validators.required]));
    this.buildingPermitExpireDateControl = this.parentForm.controls.buildingPermitExpiredAt;
    this.parentForm.addControl('workStartedAt', new UntypedFormControl(null, [Validators.required]));
    this.workingDateControl = this.parentForm.controls.workStartedAt;
    // Set key program for constuction work
    this.parentForm.addControl('constructionWorkRatio', new UntypedFormControl('', [Validators.min(0), Validators.max(100), isInteger()]));
    this.constructionWorkRatioControl = this.parentForm.controls.constructionWorkRatio;

    this.parentForm.addControl('proximityCity', new UntypedFormControl(false, []));
    this.proximityCityControl = this.parentForm.controls.proximityCity;

    // Set highlight controller
    this.parentForm.addControl('highlights', new UntypedFormControl('', []));
    this.highlightControl = this.programTextForm.controls.highlights;

    // Get tax zones reference for dropdown
    this.referenceTablesService.getTableReferenceInfo('TaxZones').subscribe((response) => {
      this.taxZoneItems = response
        .map((entry) => {
          return {
            id: entry.id,
            label: entry.code,
          };
        })
        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));
    });

    this.proximityCityControl.setValue('');

    this.cityList = this.proximityCityControl.valueChanges.pipe(
      switchMap((val) => (typeof val === 'string' ? this.programService.getCities(val) : of([val]))),
    );

    if (this.isValo) {
      // The user is a Valo user so we need the name of companies in DB for dropdown list
      this.utilsCompanyService.getCompanyTypeList().subscribe((companyTypeList) => {
        let idType = 0;
        for (const entry of companyTypeList) {
          if (entry.label === this.appConfig.companyType.developer) {
            idType = entry.id;
            break;
          }
        }
        // Get company list for dropdown
        this.utilsCompanyService.getActiveCompanyList(idType).subscribe((companyList) => {
          this.corporateNameItems = companyList.map((item) => {
            return {
              id: item.id,
              label: item.corporateName,
            };
          });
        });
      });
    } else {
      // The user is a developer
      this.isDeveloper = true;
      // Add special control
      this.parentForm.addControl('company', new UntypedFormControl('', [Validators.required]));
      this.developerCompanyNameControl = this.parentForm.controls.company;
      // Get corporate name for this user
      this.utilsCompanyService.getCompanyOfTheUser().subscribe((company: CompanyData) => {
        this.parentForm.addControl('companyId', new UntypedFormControl(company.id));
        this.developerCompanyNameControl.reset({
          value: company.corporateName,
          disabled: true,
        });
      });
    }

    this.parentForm.setValidators(this.validateRequiredFields.bind(this));

    // Fill all input forms if this is an edit of the prefilled form
    if (this.recoveredInfos) {
      /* Put recovered data to specific variable */
      this.separateRecoveredData();

      const keys = Object.keys(this.parentForm.controls);
      keys.forEach((key) => {
        if (key === 'programText') {
          const prgmTextKeys = Object.keys(this.programTextForm.controls);
          if (this.recoveredInfos.programText.length) {
            prgmTextKeys.forEach((prgrmTextKey) => {
              this.programTextForm.controls[prgrmTextKey].setValue(this.recoveredInfos.programText[0][prgrmTextKey]);
            });
          }
        } else if (key === 'programRef') {
          // Disable programRef value so it cannot be modified
          this.parentForm.controls[key].setValue(this.recoveredInfos[key]);
          this.parentForm.controls[key].disable();
        } else if (key !== 'document') {
          this.parentForm.controls[key].setValue(this.recoveredInfos[key]);
        }
      });
    }

    this.programIdObservableSubscription = this.programService.programIdObservable.subscribe((programId) => {
      this.programId = programId;
    });

    this.getHighlight();

    // Souscrivez aux changements de l'état de la checkbox depuis le service
    this.programService.getIsElectronicSignatureValorissimo().subscribe((isElectronicSignatureValorissimo) => {
      this.isElectronicSignatureValorissimo = isElectronicSignatureValorissimo;

      if (!this.isElectronicSignatureValorissimo) {
        /* Add at Least One validator on contractual documents */
        this.parentForm
          .get('document')
          .setValidators(
            atLeastOne(
              ['ApartmentReservationContract', 'HouseReservationContract', 'tradeReservationContract'],
              this.i18nService._('Error_Field_Contract'),
            ),
          );
      } else {
        this.parentForm.get('document').clearValidators();
      }
    });
  }

  ngAfterViewInit(): void {
    this.controlCompanyIdValueChangesSubscription = this.parentForm.controls.companyId.valueChanges.subscribe(() => {
      if (this.parentForm.controls.programRef.value) {
        this.programReferenceValidator(this.programService);
      }
    });
  }

  getHighlight(): void {
    this.highlightService.getHighlighs().subscribe((highlights) => {
      this.highlightList = highlights;
    });
  }

  proximityCityDisplay(city: { inseeCode: string; label: string }): string {
    return city ? city.label : '';
  }

  validateRequiredFields() {
    if (!this.parentForm.value.document.CommercialBrochure && !this.digitalCommercialBrochureUrlControl.value) {
      return { oneRequired: true };
    }
    return null;
  }

  /**
   * Submit function
   *
   * @memberof StepOneFormProgramComponent
   */
  submitStep(): void {
    // Is used to show errors on fields
    this.isSubmit = true;

    // Check if there is an error on the form
    if (!this.parentForm.valid) {
      const message: SnackbarMessage = {
        text: this.i18nService._('Error_SnackBar_FormIncomplete'),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
    }

    /* Check if form is valid before create a program */
    if (this.parentForm.valid && !this.programId) {
      const message: SnackbarMessage = {
        text: this.i18nService._('Error_SnackBar_ErrorOccuredRetry'),
        type: SnackbarMessageType.Error,
      };

      // initialisation of programToCreate
      const programToCreate = new ProgramCreate();
      const accessObjectControls = 'controls';
      // Escape HTML tags from VALO description
      if (this.isValo) {
        this.programService.sanitizeFormFields(this.parentForm.controls.programText[accessObjectControls]);
      }

      // transform to key-value object
      Object.keys(this.parentForm.controls).forEach((key) => (programToCreate[key] = this.parentForm.get(key).value));

      this.programService.createProgram(programToCreate).subscribe(
        (createResponse) => {
          this.programService.setIdProgramId(createResponse.id);
        },
        () => {
          this.snackbarService.sendMessage(message);
        },
      );
    }
  }

  /**
   * Separate recovered data to put them into specific variable
   *
   * @memberof StepOneFormProgramComponent
   */
  separateRecoveredData(): void {
    const accessObjectShortDescrDev = 'shortDescrDeveloper';
    const accessObjectLongDescrDev = 'longDescrDeveloper';
    /* Postal Address */
    this.recoveredPostalAddress = {
      address: this.recoveredInfos.address,
      countryId: this.recoveredInfos.countryId,
      postalCode: this.recoveredInfos.postalCode,
      city: this.recoveredInfos.city,
      cityInseeCode: this.recoveredInfos.cityInseeCode,
    };

    /* Invoice Postal Address */
    this.recoveredInvoicePostalAddress = {
      address: this.recoveredInfos.invoiceAddress,
      invoiceCountryId: this.recoveredInfos.invoiceCountryId,
      postalCode: this.recoveredInfos.invoicePostalCode,
      city: this.recoveredInfos.invoiceCity,
      cityInseeCode: this.recoveredInfos.invoiceCityInseeCode,
    };

    /* Coordinates */
    this.recoveredCoordinates = {
      lng: this.recoveredInfos.stringLongitude,
      lat: this.recoveredInfos.stringLatitude,
    };

    /* Developer Description */
    if (this.recoveredInfos.programText[0]) {
      this.recoveredDescrDeveloper = {
        shortDescrDeveloper: this.recoveredInfos.programText[0][accessObjectShortDescrDev],
        longDescrDeveloper: this.recoveredInfos.programText[0][accessObjectLongDescrDev],
      };
    } else {
      this.recoveredDescrDeveloper = {
        shortDescrDeveloper: '',
        longDescrDeveloper: '',
      };
    }

    /* Documents */
    this.recoveredInfos.documents.forEach((attachment) => {
      this.initFile[attachment.type.label] = attachment;
    });
  }

  /**
   * One shot validator to prevent program creation with existing programRef.
   * For an update, the programRef from recovered info is not controled
   *
   * @param {ProgramService} programService
   * @param {string} [oldRef]
   * @param {number} [programId]
   * @returns {void}
   * @memberof StepOneFormProgramComponent
   */
  programReferenceValidator(programService: ProgramService): void {
    const currentValue = this.accountingProgramRefControl.value;
    if (!currentValue?.length || (this.recoveredInfos && this.recoveredInfos.programRef === currentValue)) {
      return;
    }
    const companyId = this.parentForm.controls.companyId.value;

    programService.checkProgramReference(currentValue, companyId, this.programId).subscribe(
      (programRefIsUnique) => {
        if (!programRefIsUnique) {
          this.accountingProgramRefControl.setErrors({
            programRefExist: true,
          });
        } else {
          this.accountingProgramRefControl.setErrors(undefined);
        }
      },
      () => {
        this.snackbarService.sendErrorOccured();
      },
    );
  }

  /**
   * Control Max Length of the Long Description for Valo
   *
   * @param {*} $event
   * @memberof StepOneFormProgramComponent
   */
  controlMaxLength(event): void {
    const MAX_LENGTH = 10000;
    if (event.editor.getLength() > MAX_LENGTH) {
      event.editor.deleteText(MAX_LENGTH, event.editor.getLength());
    }
  }

  /**
   * ngOnDestroy method
   *
   * @memberof StepOneFormProgramComponent
   */
  ngOnDestroy(): void {
    if (this.controlProgramRefValueChangesSubscription) {
      this.controlProgramRefValueChangesSubscription.unsubscribe();
    }
    if (this.controlCompanyIdValueChangesSubscription) {
      this.controlCompanyIdValueChangesSubscription.unsubscribe();
    }
    if (this.programIdObservableSubscription) {
      this.programIdObservableSubscription.unsubscribe();
    }
  }

  onDevCompanyChange(event): void {
    this.devCompanyChanged.emit(event);
  }
}
