<p-autoComplete
  *ngIf="props.passObject"
  (completeMethod)="search($event)"
  [forceSelection]="props.forceChoice"
  [formControl]="$any(field.formControl)"
  [suggestions]="items"
  placeholder="{{ props.placeholder }}"
  [showClear]="props.showClear"
  (onClear)="onClear()"
  [dropdown]="props.dropdown"
  [dropdownIcon]="props.dropdownIcon"
  showEmptyMessage="true"
  [emptyMessage]="i18nService._('Txt_autoComplete_emptyMessage')"
  field="label"
>
</p-autoComplete>
<p-autoComplete
  *ngIf="!props.passObject"
  (completeMethod)="search($event)"
  [forceSelection]="props.forceChoice"
  [formControl]="$any(field.formControl)"
  [suggestions]="items"
  placeholder="{{ props.placeholder }}"
  [showClear]="props.showClear"
  (onClear)="onClear()"
  [dropdown]="props.dropdown"
  [dropdownIcon]="props.dropdownIcon"
  showEmptyMessage="true"
  [emptyMessage]="i18nService._('Txt_autoComplete_emptyMessage')"
>
</p-autoComplete>
