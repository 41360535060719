import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs';

import { SpecialOffer } from '../model/special-offer';

import { AppConfigService } from '../../utils/services/app-config.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';
import { FormUtilsService } from '../../utils/services/form/form-utils.service';
import { ReferenceSpecialOfferAsyncValidator } from '../../utils/validators/reference-special-offer-async-validator';

export const SPECIAL_OFFER_CONTROL_PATH = {
  conditions: 'conditions',
  endDate: 'conditions.endDate',
  startDate: 'conditions.startDate',
  company: 'conditions.company',
  specialOfferRef: 'conditions.specialOfferRef',
  specialOfferType: 'conditions.specialOfferType',
  specialOfferTypeOther: 'conditions.specialOfferTypeOther',
  amendment: 'conditions.amendment',
  amendmentWithFusionFields: 'conditions.amendmentWithFusionFields',
  title: 'conditions.specialOfferText.title',
  description: 'conditions.specialOfferText.description',
  legalNotice: 'conditions.specialOfferText.legalNotice',
  programIdList: 'programs.programIdList',
  programFinished: 'programs.finished',
  lotIdList: 'lots.lotIdList',
};

@Injectable({
  providedIn: 'root',
})
export class SpecialOfferService {
  constructor(
    private readonly _fb: UntypedFormBuilder,
    private readonly _http: HttpClient,
    private readonly _appConfig: AppConfigService,
    private readonly _errorHandlerService: ErrorHandlerService,
  ) {}

  generateForm(): UntypedFormGroup {
    return this._fb.group({
      conditions: this._fb.group({
        company: [
          null,
          {
            validators: [Validators.required],
          },
        ],
        specialOfferRef: [
          null,
          {
            asyncValidators: [ReferenceSpecialOfferAsyncValidator.referenceSpecialOfferExist(this)],
            updateOn: 'blur',
          },
        ],
        specialOfferType: [null, Validators.required],
        specialOfferTypeOther: [null],
        startDate: [null, Validators.required],
        endDate: [null, Validators.required],
        amendment: [null, Validators.required],
        amendmentWithFusionFields: [null],
        specialOfferText: this._fb.group({
          title: [null, Validators.required],
          description: [null, Validators.required],
          legalNotice: [null, Validators.required],
        }),
      }),
      programs: this._fb.group({
        programIdList: [undefined, [Validators.required]],
        finished: [false],
      }),
      lots: this._fb.group({ lotIdList: [undefined, [Validators.required]] }),
    });
  }

  checkIfDevelopersRefSpecialOfferExist(refSpecialOffer: string, companyId: number): Observable<boolean> {
    const url = `${this._appConfig.getLoopbackApiUrl()}/SpecialOffers/${companyId}/${refSpecialOffer}/is-exist`;

    return this._http
      .get<boolean>(url)
      .pipe(catchError(this._errorHandlerService.handleError<boolean>('SpecialOfferService', 'checkIfDevelopersRefSpecialOfferExist')));
  }

  create(soForm: UntypedFormGroup): Observable<SpecialOffer> {
    const url = `${this._appConfig.getLoopbackApiUrl()}/SpecialOffers`;
    const data = this.formToData(soForm);
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('SpecialOfferAmendment', FormUtilsService.getFormValue(SPECIAL_OFFER_CONTROL_PATH.amendment, soForm));
    formData.append(
      'SpecialOfferAmendmentWithFusionFields',
      FormUtilsService.getFormValue(SPECIAL_OFFER_CONTROL_PATH.amendmentWithFusionFields, soForm),
    );

    return this._http
      .post<SpecialOffer>(url, formData)
      .pipe(catchError(this._errorHandlerService.handleError<SpecialOffer>('SpecialOfferService', 'create')));
  }

  update(soForm: UntypedFormGroup, specialOfferId: number): Observable<SpecialOffer> {
    const url = `${this._appConfig.getLoopbackApiUrl()}/SpecialOffers/${specialOfferId}`;
    const data = this.formToData(soForm);
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    const amendment = FormUtilsService.getFormValue(SPECIAL_OFFER_CONTROL_PATH.amendment, soForm);
    const amendmentWithFusionFields = FormUtilsService.getFormValue(SPECIAL_OFFER_CONTROL_PATH.amendmentWithFusionFields, soForm);

    if (amendment instanceof File) {
      formData.append('SpecialOfferAmendment', amendment);
    }

    if (amendmentWithFusionFields instanceof File) {
      formData.append('SpecialOfferAmendmentWithFusionFields', amendmentWithFusionFields);
    }

    return this._http
      .put<SpecialOffer>(url, formData)
      .pipe(catchError(this._errorHandlerService.handleError<SpecialOffer>('SpecialOfferService', 'update')));
  }

  formToData(soForm: UntypedFormGroup): SpecialOffer {
    return {
      refSpecialOffer: FormUtilsService.getFormValue(SPECIAL_OFFER_CONTROL_PATH.specialOfferRef, soForm),
      startDate: FormUtilsService.getFormValue(SPECIAL_OFFER_CONTROL_PATH.startDate, soForm),
      endDate: FormUtilsService.getFormValue(SPECIAL_OFFER_CONTROL_PATH.endDate, soForm),
      companyId: FormUtilsService.getFormValue(SPECIAL_OFFER_CONTROL_PATH.company, soForm).id,
      specialOfferTypesIds: FormUtilsService.getFormValue(SPECIAL_OFFER_CONTROL_PATH.specialOfferType, soForm),
      specialOfferTypeOther: FormUtilsService.getFormValue(SPECIAL_OFFER_CONTROL_PATH.specialOfferTypeOther, soForm),
      specialOfferText: {
        description: FormUtilsService.getFormValue(SPECIAL_OFFER_CONTROL_PATH.description, soForm),
        title: FormUtilsService.getFormValue(SPECIAL_OFFER_CONTROL_PATH.title, soForm),
        legalNotice: FormUtilsService.getFormValue(SPECIAL_OFFER_CONTROL_PATH.legalNotice, soForm),
      },
      lotIds: FormUtilsService.getFormValue(SPECIAL_OFFER_CONTROL_PATH.lotIdList, soForm),
    };
  }

  setDataToForm(specialOffer: SpecialOffer, soForm: UntypedFormGroup): void {
    FormUtilsService.setFormValue(SPECIAL_OFFER_CONTROL_PATH.specialOfferRef, soForm, specialOffer.refSpecialOffer);
    FormUtilsService.setFormValue(SPECIAL_OFFER_CONTROL_PATH.startDate, soForm, specialOffer.startDate);
    FormUtilsService.setFormValue(SPECIAL_OFFER_CONTROL_PATH.endDate, soForm, specialOffer.endDate);
    FormUtilsService.setFormValue(SPECIAL_OFFER_CONTROL_PATH.company, soForm, {
      id: specialOffer.companyId,
      corporateName: specialOffer.companyName,
    });
    FormUtilsService.setFormValue(SPECIAL_OFFER_CONTROL_PATH.specialOfferType, soForm, specialOffer.specialOfferTypesIds);
    FormUtilsService.setFormValue(SPECIAL_OFFER_CONTROL_PATH.specialOfferTypeOther, soForm, specialOffer.specialOfferTypeOther);
    FormUtilsService.setFormValue(SPECIAL_OFFER_CONTROL_PATH.description, soForm, specialOffer.specialOfferText.description);
    FormUtilsService.setFormValue(SPECIAL_OFFER_CONTROL_PATH.title, soForm, specialOffer.specialOfferText.title);
    FormUtilsService.setFormValue(SPECIAL_OFFER_CONTROL_PATH.legalNotice, soForm, specialOffer.specialOfferText.legalNotice);
    FormUtilsService.setFormValue(SPECIAL_OFFER_CONTROL_PATH.lotIdList, soForm, specialOffer.lotIds);
    FormUtilsService.setFormValue(SPECIAL_OFFER_CONTROL_PATH.programIdList, soForm, specialOffer.programsIds);
    FormUtilsService.setFormValue(SPECIAL_OFFER_CONTROL_PATH.amendment, soForm, specialOffer.amendment);
    FormUtilsService.setFormValue(SPECIAL_OFFER_CONTROL_PATH.amendmentWithFusionFields, soForm, specialOffer.amendmentWithFusionFields);
  }

  findSpecialOffer(specialOfferId: number): Observable<SpecialOffer> {
    const url = `${this._appConfig.getLoopbackApiUrl()}/SpecialOffers/${specialOfferId}`;

    return this._http
      .get<SpecialOffer>(url)
      .pipe(
        catchError(this._errorHandlerService.handleError<SpecialOffer>('SpecialOfferService', 'checkIfDevelopersRefSpecialOfferExist')),
      );
  }

  disableForm(specialOfferForm: UntypedFormGroup): void {
    FormUtilsService.disableControl(SPECIAL_OFFER_CONTROL_PATH.specialOfferRef, specialOfferForm, true);
    FormUtilsService.disableControl(SPECIAL_OFFER_CONTROL_PATH.startDate, specialOfferForm, true);
    FormUtilsService.disableControl(SPECIAL_OFFER_CONTROL_PATH.endDate, specialOfferForm, true);
    FormUtilsService.disableControl(SPECIAL_OFFER_CONTROL_PATH.company, specialOfferForm, true);
    FormUtilsService.disableControl(SPECIAL_OFFER_CONTROL_PATH.specialOfferType, specialOfferForm, true);
    FormUtilsService.disableControl(SPECIAL_OFFER_CONTROL_PATH.specialOfferTypeOther, specialOfferForm, true);
    FormUtilsService.disableControl(SPECIAL_OFFER_CONTROL_PATH.description, specialOfferForm, true);
    FormUtilsService.disableControl(SPECIAL_OFFER_CONTROL_PATH.title, specialOfferForm, true);
    FormUtilsService.disableControl(SPECIAL_OFFER_CONTROL_PATH.legalNotice, specialOfferForm, true);
    FormUtilsService.disableControl(SPECIAL_OFFER_CONTROL_PATH.lotIdList, specialOfferForm, true);
    FormUtilsService.disableControl(SPECIAL_OFFER_CONTROL_PATH.programIdList, specialOfferForm, true);
    FormUtilsService.disableControl(SPECIAL_OFFER_CONTROL_PATH.amendment, specialOfferForm, true);
  }
}
