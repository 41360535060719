import { Component, Input, OnInit } from '@angular/core';
import { style, transition, trigger } from '@angular/animations';
import { RouterLink } from '@angular/router';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';

import { MenuConfig } from '../header.component';

import { ModalDisplayService } from '../../../services/modal-display.service';
import { I18nService } from '../../../services/i18n.service';
import { IconComponent } from '../../../../common/standalone/icon/icon.component';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss'],
  animations: [trigger('fade', [transition(':enter', [style({ opacity: 0 })]), transition(':leave', [style({ opacity: 1 })])])],
  standalone: true,
  imports: [IconComponent, MatExpansionModule, NgFor, NgIf, NgClass, RouterLink],
})
export class MobileNavComponent implements OnInit {
  public modalPanelCollapsed = false;
  @Input() menuItems: MenuConfig[];
  @Input() otherMenuItems: MenuConfig[];

  constructor(
    private readonly modalDisplayService: ModalDisplayService,
    public readonly i18nService: I18nService,
  ) {}

  ngOnInit(): void {
    this.modalDisplayService.isModalDisplayed$.subscribe((d) => (this.modalPanelCollapsed = !d));
  }

  toggleModalPanelCollapsed(): void {
    this.modalDisplayService.changeModalDisplay(this.modalPanelCollapsed);
  }
}
