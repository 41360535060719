<div
  (click)="switchToEdit($event)"
  [ngClass]="{
    'location-filled': !titelUp && search.localisationsList && search.localisationsList.length
  }"
  class="localisation-search-input"
>
  <div *ngIf="titelUp" class="text-base font-bold text-left mb-3">
    {{ i18nService._('Txt_search_filter_location_panel_title') }}
  </div>
  <div [hidden]="selected" class="mat-mdc-form-field mat-mdc-form-field-has-icon-prefix">
    <div class="mat-mdc-text-field-wrapper mdc-text-field">
      <div class="mat-mdc-form-field-flex min-w-full">
        <div class="mat-mdc-form-field-icon-prefix">
          <mat-icon
            class="mat-icon notranslate fontSize16 material-icons-outlined mr-1 material-icons mat-ligature-font map-priority-icon mat-icon-no-color"
            [ngClass]="{ 'map-priority-icon': true }"
          >
            room
          </mat-icon>
        </div>
        <div
          *ngIf="!titelUp && locationLabelised"
          class="mat-mdc-form-field-infix localisation-search-input-label"
          id="searchLocationInputDataLabel"
          data-cy="fastSearchLocation"
        >
          {{ locationLabelised }}
        </div>
        <ng-container *ngIf="isMapPrior$ | async as isMapPrior; else programPlaceholder">
          <div class="mat-mdc-form-field-infix localisation-search-input-map-placeholder" *ngIf="isMapPrior">
            {{ i18nService._('Txt_mapbox_localisation_map_placeholder') }}
          </div>
        </ng-container>
        <ng-template #programPlaceholder>
          <div class="mat-mdc-form-field-infix localisation-search-input-placeholder" *ngIf="titelUp || !locationLabelised">
            {{ i18nService._('Txt_search_filter_location_placeholder') }}
          </div>
        </ng-template>
        <div class="mdc-line-ripple"></div>
      </div>
    </div>
    <div class="mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align">
      <div class="mat-mdc-form-field-hint-wrapper" style="opacity: 1; transform: translateY(0%)">
        <div class="mat-mdc-form-field-hint-spacer"></div>
      </div>
    </div>
  </div>
  <mat-form-field [hidden]="!selected" #localisationInputRef>
    <input #inputElement matInput [matAutocomplete]="auto" type="text" [formControl]="locationSearchForm" />
    <mat-icon matPrefix class="fontSize16 material-icons-outlined mr-1" [ngClass]="{ 'map-priority-icon': true }">room</mat-icon>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectLocation($event)" (click)="$event.stopImmediatePropagation()">
      <mat-option *ngFor="let item of filteredItems" [value]="item" (click)="$event.stopImmediatePropagation()">
        {{ item }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>

<app-search-localisation-list
  (deleteLocalisationEvent)="deleteLocalisation()"
  *ngIf="showPanel || open"
  [locationList]="search.localisationsList"
  [ngClass]="{ 'localisation-search-input-panel-open': open }"
  [open]="open"
  [titelUp]="titelUp"
  class="localisation-search-input-panel"
>
</app-search-localisation-list>
