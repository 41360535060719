import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { QuillModule } from 'ngx-quill';

import { ComparatorModule } from '../comparator/comparator.module';
import { DesignSystemModule } from '../design-system/design-system.module';
import { DialogModule } from '../dialog/dialog.module';
import { PipesModule } from '../utils/pipes/pipes.module';
import { UtilsModule } from '../utils/utils.module';
import { AreaInputFormProgramComponent } from './components/area-input-form-program/area-input-form-program.component';
import { EditRegisterFormProgramComponent } from './components/edit-register-form-program/edit-register-form-program.component';
import { LotsTableProgramComponent } from './components/lots-table-program/lots-table-program.component';
import { ProgramPageDescriptionComponent } from './components/program-page-v2/program-page-description/program-page-description.component';
import { ProgramPageDocumentsListComponent } from './components/program-page-v2/program-page-documents-list/program-page-documents-list.component';
import { ProgramPageHeaderComponent } from './components/program-page-v2/program-page-header/program-page-header.component';
import { ProgramPageLotDetailComponent } from './components/program-page-v2/program-page-lot/program-page-lot-detail/program-page-lot-detail.component';
import { ProgramPageLotHeaderComponent } from './components/program-page-v2/program-page-lot/program-page-lot-header/program-page-lot-header.component';
import { ProgramPageLotInformationComponent } from './components/program-page-v2/program-page-lot/program-page-lot-information/program-page-lot-information.component';
import { ProgramPageLotOptionComponent } from './components/program-page-v2/program-page-lot/program-page-lot-option/program-page-lot-option.component';
import { ProgramPageLotPriceComponent } from './components/program-page-v2/program-page-lot/program-page-lot-price/program-page-lot-price.component';
import { ReduceTaxationDialogComponent } from './components/program-page-v2/program-page-lot/program-page-lot-price/reduce-taxation-dialog/reduce-taxation-dialog.component';
import { ProgramPageLotRentAndProfitabilityComponent } from './components/program-page-v2/program-page-lot/program-page-lot-rent-and-profitability/program-page-lot-rent-and-profitability.component';
import { ProgramPageLotsListComponent } from './components/program-page-v2/program-page-lot/program-page-lots-list/program-page-lots-list.component';
import { ProgramePageLotLayoutComponent } from './components/program-page-v2/program-page-lot/programe-page-lot-layout/programe-page-lot-layout.component';
import { ProgramPageMainInfosComponent } from './components/program-page-v2/program-page-main-infos/program-page-main-infos.component';
import { ProgramPageNavigationHeaderComponent } from './components/program-page-v2/program-page-navigation-header/program-page-navigation-header.component';
import { ProgramPageNeighborhoodMapDialogComponent } from './components/program-page-v2/program-page-neighborhood-map-dialog/program-page-neighborhood-map-dialog.component';
import { ProgramPageNeighborhoodMapComponent } from './components/program-page-v2/program-page-neighborhood-map/program-page-neighborhood-map.component';
import { ProgramPagePromoterInfosComponent } from './components/program-page-v2/program-page-promoter-infos/program-page-promoter-infos.component';
import { ProgramPageProspectiveComponent } from './components/program-page-v2/program-page-prospective/program-page-prospective.component';
import { ProgramPageV2Component } from './components/program-page-v2/program-page-v2.component';
import { RegisterFormProgramComponent } from './components/register-form-program/register-form-program.component';
import { SecondaryLotComponent } from './components/secondary-lot/secondary-lot.component';
import { StepFourFormProgramComponent } from './components/step-four-form-program/step-four-form-program.component';
import { StepOneFormProgramComponent } from './components/step-one-form-program/step-one-form-program.component';
import { StepThreeFormProgramComponent } from './components/step-three-form-program/step-three-form-program.component';
import { StepTwoFormProgramComponent } from './components/step-two-form-program/step-two-form-program.component';
import { TaxationCheckboxesLotComponent } from './components/taxation-checkboxes-lot/taxation-checkboxes-lot.component';
import { ValidateRegisterFormProgramComponent } from './components/validate-register-form-program/validate-register-form-program.component';
import { AnchorDirective } from './directive/anchor.directive';
import { ProgramsRoutingModule } from './programs-routing.module';
import { ProgramPageRpvCardComponent } from './components/program-page-v2/program-page-rpv-card/program-page-rpv-card.component';
import { TrackingTablesModule } from '../tracking-tables/tracking-tables.module';
import { ProgramPageHighlightsComponent } from './components/program-page-v2/program-page-highlights/program-page-highlights.component';

@NgModule({
  imports: [
    CommonModule,
    ProgramsRoutingModule,
    MatAutocompleteModule,
    UtilsModule,
    DialogModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'underline', 'italic', 'strike'],
          [{ list: 'bullet' }, { list: 'ordered' }],
        ],
      },
    }),
    DesignSystemModule,
    ComparatorModule,
    PipesModule,
    TrackingTablesModule,
    ProgramPageHighlightsComponent,
    RegisterFormProgramComponent,
    StepOneFormProgramComponent,
    StepTwoFormProgramComponent,
    StepThreeFormProgramComponent,
    LotsTableProgramComponent,
    TaxationCheckboxesLotComponent,
    SecondaryLotComponent,
    EditRegisterFormProgramComponent,
    StepFourFormProgramComponent,
    AreaInputFormProgramComponent,
    ValidateRegisterFormProgramComponent,
    ProgramPageV2Component,
    AnchorDirective,
    ProgramPageHeaderComponent,
    ProgramPageMainInfosComponent,
    ProgramPageRpvCardComponent,
    ProgramPageProspectiveComponent,
    ProgramPageLotsListComponent,
    ProgramPageDescriptionComponent,
    ProgramPagePromoterInfosComponent,
    ProgramePageLotLayoutComponent,
    ProgramPageNavigationHeaderComponent,
    ProgramPageDocumentsListComponent,
    ProgramPageNeighborhoodMapComponent,
    ProgramPageNeighborhoodMapDialogComponent,
    ProgramPageLotDetailComponent,
    ProgramPageLotInformationComponent,
    ProgramPageLotPriceComponent,
    ProgramPageLotRentAndProfitabilityComponent,
    ProgramPageLotHeaderComponent,
    ProgramPageLotOptionComponent,
    ReduceTaxationDialogComponent,
  ],
  providers: [DatePipe, DecimalPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ProgramsModule {}
