import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

import { I18nService } from '../../../../utils/services/i18n.service';
import { LotDetailResponse } from '../../../../utils/models/LotDetailResponse';
import { ComparatorPageService } from '../../../../comparator/services/comparator-page.service';
import { FeesComponent } from '../../../../design-system/component/fees/fees.component';
import { ComparatorModule } from '../../../../comparator/comparator.module';
import { ButtonBackComponent } from '../../../../design-system/component/app-button-back/button-back.component';

@Component({
  selector: 'app-program-page-navigation-header',
  templateUrl: './program-page-navigation-header.component.html',
  styleUrls: ['./program-page-navigation-header.component.scss'],
  standalone: true,
  imports: [ButtonBackComponent, NgIf, ComparatorModule, FeesComponent],
})
export class ProgramPageNavigationHeaderComponent {
  @Input() goBack: () => void;

  @Input() i18nService: I18nService;

  @Input() backLabel: string;

  @Input() showComparator = false;

  @Input() lot?: LotDetailResponse;
  @Input() programId?: number;

  constructor(private readonly _comparatorPageService: ComparatorPageService) {}

  comparatorChange($event: boolean) {
    this._comparatorPageService.pushToDatalayerComparatorChangeEvent({
      programId: this.programId,
      lot: this.lot,
      isInserted: $event,
    });
  }
}
