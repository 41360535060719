<h2 class="text-center" mat-dialog-title>
  {{ i18nService._('Title_Save_Search') }}
</h2>

<form [formGroup]="formGroup">
  <mat-form-field class="w-full">
    <mat-label>{{ i18nService._('Txt_SaveSearchPopin_FormTitleLabel') }}</mat-label>
    <textarea
      name="title"
      [formControl]="titleControls"
      [required]="true"
      matInput
      maxlength="100"
      matTextareaAutosize
      matAutosizeMinRows="5"
      matAutosizeMaxRows="10"
    >
    </textarea>
    <mat-error *ngIf="titleControls.errors && titleControls.errors.required">
      {{ i18nService._('Error_Field_Required') }}
    </mat-error>
  </mat-form-field>

  <mat-form-field class="w-full">
    <mat-label>{{ i18nService._('Txt_SaveSearchPopin_FormDescriptionLabel') }}</mat-label>
    <textarea
      name="description"
      [formControl]="descriptionControls"
      [required]="false"
      matInput
      maxlength="256"
      matTextareaAutosize
      matAutosizeMinRows="5"
      matAutosizeMaxRows="10"
    >
    </textarea>
  </mat-form-field>

  <div mat-dialog-actions class="justify-center">
    <button class="mr-3" type="button" (click)="cancel()" color="primary" mat-stroked-button>
      {{ i18nService._('Txt_Button_Cancel') }}
    </button>
    <button class="mr-3" (click)="submit()" color="primary" [disabled]="disableSubmitButton" mat-flat-button>
      {{ i18nService._('Txt_Button_Valid') }}
    </button>
  </div>
</form>
