<mat-dialog-content class="flex flex-col p-9">
  <button class="closing-dialog" (click)="closeDialog(false)">
    <app-icon name="XMark"></app-icon>
  </button>
  <div class="flex justify-between">
    <h1 class="mb-6 text-2xl font-bold">{{ i18nService._('Title_documents_modal') }}</h1>
  </div>
  <span class="mb-6"> {{ i18nService._('txt_documents_modal_description') }} </span>
  <app-form-file-drop
    [acceptedFilesLabel]="i18nService._('txt_documents_modal_format')"
    [docType]="data.documentType"
    [documentForId]="data.dossierId"
    [files]="data.documents"
    [maxFileSize]="31457300"
    [multiple]="true"
    [readonly]="false"
    [selectFileLabel]="i18nService._('txt_document_add')"
    [types]="acceptedType"
    [uploadService]="signatureDocumentService"
    class="mb-6"
    fileRequiredErrorI18nToken="Error_Field_SupportingDocumentRequired"
    ngDefaultControl
  >
  </app-form-file-drop>
</mat-dialog-content>

<mat-dialog-actions class="justify-center mb-1 flex-col">
  <div class="flex w-full justify-between">
    <div class="flex flex-col justify-between">
      <a [href]="link" target="_blank">
        <span class="hover:cursor-pointer underline underline-offset-2 mb-4">
          {{ i18nService._('txt_documents_modal_utils') }}
        </span>
      </a>
      <span></span>
    </div>
    <div class="flex justify-around items-end">
      <button class="bg-gray-300 w-36 h-12 mr-5 rounded-full uppercase font-bold" mat-dialog-close>
        {{ i18nService._('Txt_Button_Cancel') }}
      </button>
      <button class="rounded-button" mat-dialog-close>
        {{ i18nService._('Txt_Button_Valid') }}
      </button>
    </div>
  </div>
</mat-dialog-actions>
