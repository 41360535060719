import { Component, Input } from '@angular/core';
import { CdkStep } from '@angular/cdk/stepper';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-my-step',
  templateUrl: './my-step.component.html',
  styleUrls: ['./my-step.component.scss'],
  standalone: true,
})
export class MyStepComponent extends CdkStep {
  @Input() subHead: boolean;
  @Input() head: boolean;
  @Input() field: FormlyFieldConfig;
  @Input() fieldIndex: number;
}
