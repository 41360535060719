import { Injectable } from '@angular/core';

import { DocumentResponse } from '../../../utils/models/DocumentResponse';
import { LotDetailsDocumentViewModel } from '../../../utils/models/LotDetailsDocumentViewModel';
import { ProgramDetailResponse } from '../../../utils/models/ProgramDetailResponse';
import { ProgramTextResponse } from '../../../utils/models/ProgramTextResponse';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { FileSystemService } from '../../../utils/services/file-system.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { ProgramPageService } from '../../services/program-page.service';

@Injectable({
  providedIn: 'root',
})
export class PageProgramHelperService {
  public programTypes: { [key: string]: string };
  public documentTypes: { [key: string]: string };
  public documentTypeCode: { [key: string]: string };
  private readonly disabledFeatures: { [key: string]: boolean };

  constructor(
    private _programPageService: ProgramPageService,
    private _fileSystemService: FileSystemService,
    private i18nService: I18nService,
    private _appConfigService: AppConfigService,
  ) {
    this.programTypes = this._appConfigService.getAppConfig().programTypes;
    this.documentTypes = this._appConfigService.getAppConfig().documentTypes;
    this.documentTypeCode = this._appConfigService.getAppConfig().documentTypeCode;
    this.disabledFeatures = this._appConfigService.getAppConfig().disabledFeatures;
  }

  get program() {
    return this._programPageService.program;
  }

  get programText(): ProgramTextResponse {
    return {
      description: this.program.programTextDescription,
      longDescrValo: this.program.programTextLongDescValo,
      situation: this.program.programTextSituation,
      services: this.program.programTextServices,
      quality: this.program.programTextQuality,
      access: this.program.programTextAccess,
    };
  }

  get companyLogo(): string {
    const documentLogoCompany: DocumentResponse = {
      fileName: this.program.logoCompanyFileName,
      title: this.program.logoCompanyTitle,
      container: this.program.logoCompanyContainer,
    };

    return this._fileSystemService.getLogo(documentLogoCompany);
  }

  get documentPerspective(): string {
    const documentPerspective = this.program.documents ? this.program.documents.find((doc) => doc.type.label === 'perspective') : null;

    return this._fileSystemService.getLogo(documentPerspective);
  }

  public openDocumentInBrowser($event: Event, document: DocumentResponse, name?: string): void {
    if ($event) {
      $event.stopImmediatePropagation();
    }
    this._fileSystemService.openDocumentInBrowser(
      document,
      name ? name : this.i18nService._(`Txt_Commercial_Program_Sheet_${document.codeLabel}`),
    );
  }

  get presentationsDocs(): LotDetailsDocumentViewModel[] {
    return [
      {
        ...this._initDocViewModelObject('ParkingMap', true),
      },
      {
        ...this._initDocViewModelObject('FloorMap', true),
      },
      {
        ...this._initDocViewModelObject('CommercialBrochure'),
      },
      {
        ...this._initDocViewModelObject('presentationOfTheProgram'),
      },
      {
        ...this._initDocViewModelObject('descriptiveNotice'),
      },
      {
        ...this._initDocViewModelObject('groundPlan'),
      },
    ];
  }

  get contratsDocs(): LotDetailsDocumentViewModel[] {
    return [
      {
        ...this._initDocViewModelObject('ApartmentContractualDocumentPackage', true),
      },
      {
        ...this._initDocViewModelObject('HouseContractualDocumentPackage', true),
      },
      {
        ...this._initDocViewModelObject('TradeContractualDocumentPackage', true),
      },
      {
        ...this._initDocViewModelObject('guaranteeFormula', true),
      },
      {
        ...this._initDocViewModelObject('ERPDocument', true),
      },
    ];
  }

  /**
   * Sets the active program in the page from a DTO response
   * obtained from the back-end
   * @param program Response from the GET /Program/detail endpoint
   */
  setProgramDetails(program: ProgramDetailResponse) {
    this._programPageService.setProgramFromResponse(program);
  }

  private _shouldHideDocLabelByProgramTypeLabel(label: string, programTypeLabel: string): boolean {
    const programTypesObject = {
      HOUSE: {
        documentType: this.documentTypes.HouseContractualDocumentPackage,
      },
      APARTMENT: {
        documentType: this.documentTypes.ApartmentContractualDocumentPackage,
      },
      TRADE: {
        documentType: this.documentTypes.TradeContractualDocumentPackage,
      },
    };

    return programTypesObject[programTypeLabel] ? programTypesObject[programTypeLabel].documentType !== label : false;
  }

  _shouldHideDocument(label: string): boolean {
    if (this.disabledFeatures?.disableShowContractualDocumentPackages) {
      return false;
    }

    let hide =
      label !== this.documentTypes.guaranteeFormula &&
      label !== this.documentTypes.SpecialOfferAmendment &&
      this._shouldHideDocLabelByProgramTypeLabel(label, this.program.programTypeLabel);

    const documentToHideForSignature = {
      ApartmentContractualDocumentPackage: 'ApartmentContractualDocumentPackage',
      HouseContractualDocumentPackage: 'HouseContractualDocumentPackage',
      TradeContractualDocumentPackage: 'TradeContractualDocumentPackage',
    };

    const documentToDisplayForSignature = {
      ParkingMap: 'ParkingMap',
      ERPDocument: 'ERPDocument',
      FloorMap: 'FloorMap',
    };

    if (this.program.isElectronicSignatureValorissimo && documentToHideForSignature[label] === label) {
      hide = true;
    } else if (this.program.isElectronicSignatureValorissimo && documentToDisplayForSignature[label] === label) {
      hide = false;
    } else if (!this.program.isElectronicSignatureValorissimo && documentToDisplayForSignature[label] === label) {
      hide = true;
    }
    return hide;
  }

  private _initDocViewModelObject(docCode: string, initDisplay = false): LotDetailsDocumentViewModel {
    const document = this.program.documents.find(
      (doc) => doc.codeLabel === this.documentTypeCode[docCode] || (doc.type && doc.type.code === this.documentTypeCode[docCode]),
    );
    const label = this.documentTypes[docCode];

    const hide = this._shouldHideDocument(label);

    return {
      label,
      document,
      code: this.documentTypeCode[docCode],
      downloadUrl: this.initDocViewModelDownloadUrl(document),
      actions: {
        checked: false,
        display: initDisplay ? !hide : true,
        disabled: !document,
      },
    };
  }

  public initDocViewModelDownloadUrl(document: DocumentResponse): string {
    return document
      ? `${this._appConfigService.getLoopbackApiUrl()}/Containers/${document.container}/download/${encodeURIComponent(document.fileName)}`
      : null;
  }

  public initEmptyDocViewModel({ codeLabel, display, disabled }: { codeLabel: string; display: boolean; disabled: boolean }) {
    const documentByCode = this._appConfigService.getAppConfig().documentByCode;
    const label = documentByCode[codeLabel];

    return {
      label,
      document: undefined,
      code: codeLabel,
      actions: {
        checked: false,
        display,
        disabled,
      },
    };
  }
}
