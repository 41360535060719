import { Component, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';

import { InterlocutorContactComponent } from '../interlocutor-contact/interlocutor-contact.component';

import { I18nService } from '../../services/i18n.service';
import { AccountService } from '../../services/account.service';
import { ResponsibleInfo } from '../../models/ResponsibleInfo';

@Component({
  selector: 'app-interlocutor-contacts',
  templateUrl: './interlocutor-contacts.component.html',
  styleUrls: ['./interlocutor-contacts.component.scss'],
  standalone: true,
  imports: [NgIf, InterlocutorContactComponent],
})
export class InterlocutorContactsComponent implements OnInit {
  public responsibleBi: ResponsibleInfo;
  public responsibleNonBi: ResponsibleInfo;
  public multipleContact: boolean;

  constructor(
    public readonly i18nService: I18nService,
    private readonly accountService: AccountService,
  ) {}

  ngOnInit(): void {
    this.accountService.getResponsibles().subscribe((responsibles) => {
      this.responsibleBi = responsibles.responsibleBi;
      this.responsibleNonBi = responsibles.responsibleNonBi;
      let nbContact = 0;
      if (this.responsibleBi) nbContact++;
      if (this.responsibleNonBi) nbContact++;
      this.multipleContact = nbContact > 1;
    });
  }
}
