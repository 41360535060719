<div class="program-lots-header">
  <div class="program-lots-header-left-side">
    <span [innerHTML]="listLotsRecapTitle" class="program-lots-header-available-lots"></span>
    <span class="program-lots-header-min-price">
      -
      {{ i18nService._('Txt_Page_Program_ProgramPriceFrom', [basicFormatsService.formatCurrency(program.minPrice, undefined, 0, 0, 4)]) }}
    </span>
  </div>
  <div (click)="accessLotsPrices()" *ngIf="this.displayedColumns.length > 4" class="program-lots-header-right-side">
    <span data-cy="seeAllLink" class="cursor-pointer">{{ i18nService._('Txt_page_program_lots_see_all_link') }}</span>
    <mat-icon class="material-icons-outlined size-18">navigate_next</mat-icon>
  </div>
</div>
<div class="program-lots-list">
  <mat-accordion [ngClass]="{ 'program-lot-open': lotOpen }" multi>
    <ng-container *ngFor="let typeOfLot of dataSource | keyvalue; let i = index">
      <mat-expansion-panel *ngFor="let nbRooms of typeOfLot.value | keyvalue; let j = index" hideToggle>
        <mat-expansion-panel-header
          [collapsedHeight]="expansionPanelHeaderHeight"
          [expandedHeight]="expansionPanelHeaderHeight"
          data-cy="program-lots"
        >
          <mat-panel-title>
            <span class="mat-panel-title-prefix-icon material-icons-outlined">home_work</span>
            {{
              i18nService._('Txt_Page_Program_SummaryLotAccordion_v2', [
                nbRooms.value.lots.length,
                nbRooms.value.lots.length | i18nPlural: getPluralProgramTypeLabelMapping(typeOfLot.key),
                nbRooms.key,
                nbRooms.key | i18nPlural: getPluralRoomsMapping()
              ])
            }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!--      <ng-template matExpansionPanelContent>-->
        <mat-table
          [@.disabled]="true"
          [attr.lotType]="typeOfLot.key"
          [attr.nbRooms]="nbRooms.key"
          [dataSource]="nbRooms.value.dataSource"
          class="mat-elevation-z8 mb-n3 shadow-none"
          matSort
        >
          <ng-container matColumnDef="comparator">
            <mat-header-cell *matHeaderCellDef class="text-black">
              {{ i18nService._('Txt_Table_Column_Comparator') }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="lot-comparator">
              <app-management-lot-comparator
                (comparatorChangeEvent)="comparatorChange($event, element)"
                [idLot]="element.id"
                [viewText]="false"
              ></app-management-lot-comparator>
            </mat-cell>
          </ng-container>
          <!-- Lot -->
          <ng-container matColumnDef="lot">
            <mat-header-cell *matHeaderCellDef class="text-black">
              {{ i18nService._('Txt_Table_Column_Lot') }}
            </mat-header-cell>

            <mat-cell *matCellDef="let element" class="cursor-pointer justify-start">
              {{ element.lot }}
              <!--
                A workaround used to covers the whole row and contains an anchor.
              -->
              <a (click)="showLotsInformations($event, element.index, nbRooms.value.lots)" class="mat-row-link"></a>
            </mat-cell>
          </ng-container>
          <!-- Surface -->
          <ng-container matColumnDef="surface">
            <mat-header-cell *matHeaderCellDef class="text-black" mat-sort-header>
              {{ i18nService._('Txt_Table_Column_LivingSpace') }}
            </mat-header-cell>

            <mat-cell *matCellDef="let element">
              {{ element.surface }}
            </mat-cell>
          </ng-container>
          <!-- Surface & etage-->
          <ng-container matColumnDef="surfaceFloor">
            <mat-header-cell *matHeaderCellDef class="text-black">
              {{ i18nService._('Txt_Table_Column_LivingSpaceFloor') }}
            </mat-header-cell>

            <mat-cell *matCellDef="let element">
              {{ element.surfaceFloor }}
            </mat-cell>
          </ng-container>
          <!-- Plan -->
          <ng-container matColumnDef="plan">
            <mat-header-cell *matHeaderCellDef class="text-black">
              {{ i18nService._('Txt_Table_Column_Plan') }}
            </mat-header-cell>

            <mat-cell *matCellDef="let element" class="program-page-lot-list-column-plan">
              <mat-icon
                (click)="pageProgramHelperService.openDocumentInBrowser($event, element.plan)"
                [svgIcon]="'plan'"
                class="plan-icon material-icons-outlined icon-size-30"
              ></mat-icon>
            </mat-cell>
          </ng-container>

          <!-- Etage/O. -->
          <ng-container matColumnDef="floor">
            <mat-header-cell *matHeaderCellDef class="text-black">
              {{ i18nService._('Txt_Table_Column_FloorOrientation_1') }}<br />
              {{ i18nService._('Txt_Table_Column_FloorOrientation_2') }}
            </mat-header-cell>

            <mat-cell *matCellDef="let element">
              {{ element.floor }}
            </mat-cell>
          </ng-container>

          <!-- Annexes. -->
          <ng-container matColumnDef="annexes">
            <mat-header-cell *matHeaderCellDef class="text-black">
              {{ i18nService._('Txt_Table_Column_Annexes') }}
            </mat-header-cell>

            <mat-cell *matCellDef="let element">
              <div *ngIf="element?.exteriorAnnexes?.length">
                <div [matTooltip]="element.exteriorAnnexes" class="annexes-details">
                  <div class="annexes-details-icon">
                    <svg fill="none" height="24" viewBox="0 0 16 16" width="24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.41309 0.112456C12.2496 0.556311 14.6514 2.31144 15.9854 4.74148C16.0298 4.82204 15.9682 4.92278 15.8757 4.92278H11.6821C11.391 3.08435 10.6299 1.47333 9.41332 0.112427L9.41309 0.112456Z"
                        fill="black"
                      />
                      <path
                        d="M4.31824 4.92287H0.124666C0.0318613 4.92287 -0.0295769 4.82213 0.0146569 4.74158C1.34907 2.31149 3.75046 0.556513 6.58738 0.112549C5.37079 1.47345 4.60972 3.08451 4.31855 4.9229L4.31824 4.92287Z"
                        fill="black"
                      />
                      <path
                        d="M5.26318 4.92283C5.60101 2.99012 6.51404 1.33444 7.99689 2.10185e-05C7.9979 2.10185e-05 7.99891 -0.00012207 7.99993 -0.00012207C8.00094 -0.00012207 8.00195 2.10185e-05 8.00311 2.10185e-05C9.48596 1.33463 10.3991 2.9903 10.7367 4.92283H5.26318Z"
                        fill="black"
                      />
                      <path
                        d="M6.13509 12.3076V15.8154C6.13509 15.9173 6.05154 16 5.94861 16H5.07822C4.97529 16 4.89174 15.9173 4.89174 15.8154L4.89159 12.9229H1.78305V15.8153C1.78305 15.9172 1.69949 15.9999 1.59657 15.9999H0.726175C0.623253 15.9999 0.539695 15.9172 0.539695 15.8153L0.539551 7.38435C0.539551 7.04452 0.817822 6.76892 1.1613 6.76892C1.50462 6.76892 1.78304 7.04437 1.78304 7.38435V11.0767H4.89159C5.57839 11.0767 6.13509 11.6278 6.13509 12.3076Z"
                        fill="black"
                      />
                      <path
                        d="M11.1087 11.0769H14.2173V7.38448C14.2173 7.04464 14.4956 6.76904 14.839 6.76904C15.1823 6.76904 15.4608 7.04449 15.4608 7.38448V15.8154C15.4608 15.9173 15.3772 16 15.2743 16H14.4039C14.301 16 14.2174 15.9173 14.2174 15.8154L14.2173 12.923H11.1087V15.8154C11.1087 15.9173 11.0252 16 10.9223 16H10.0519C9.94894 16 9.86538 15.9173 9.86538 15.8154L9.86523 12.3077C9.86523 11.6279 10.4219 11.0769 11.1087 11.0769Z"
                        fill="black"
                      />
                      <path d="M8.62191 15.9999H7.37842V10.769H8.62191V15.9999Z" fill="black" />
                      <path
                        d="M12.3519 9.23061V9.84605L3.64795 9.8459V9.23047C3.64795 8.89064 3.92622 8.61504 4.2697 8.61504H7.37824V5.84583H8.62173V8.61518H11.7303C12.0736 8.61518 12.352 8.89063 12.352 9.23062L12.3519 9.23061Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div *ngIf="element?.parkingAnnexes?.length">
                <div [matTooltip]="element.parkingAnnexes" class="annexes-details">
                  <div class="annexes-details-icon">
                    <svg fill="none" height="24" viewBox="0 0 16 16" width="24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.8728 0H1.12724C0.505577 0 0 0.505715 0 1.12724V14.8728C0 15.4943 0.505716 16 1.12724 16H14.8728C15.4944 16 16 15.4943 16 14.8728V1.12724C15.9999 0.505715 15.4943 0 14.8726 0H14.8728ZM15.2074 14.8642C15.2074 15.0215 15.08 15.1489 14.9227 15.1489H1.07731C0.920037 15.1489 0.792597 15.0215 0.792597 14.8642V1.13564C0.792597 0.978364 0.920037 0.850924 1.07731 0.850924H14.9227C15.08 0.850924 15.2074 0.978364 15.2074 1.13564V14.8642Z"
                        fill="black"
                      />
                      <path
                        d="M9.42956 4.9322C9.2627 4.83135 9.06952 4.76318 8.85013 4.72794C8.63062 4.69298 8.40247 4.67529 8.16543 4.67529H6.55908V7.493H8.16543C8.40249 7.493 8.63064 7.47545 8.85013 7.44035C9.06951 7.40539 9.26271 7.33734 9.42956 7.23637C9.59628 7.13552 9.72993 6.99269 9.83104 6.80841C9.93189 6.62414 9.98238 6.38277 9.98238 6.08428C9.98238 5.78606 9.93175 5.54442 9.83104 5.36C9.73006 5.17573 9.59628 5.03318 9.42956 4.9322Z"
                        fill="black"
                      />
                      <path
                        d="M1.3623 1.4203V14.5797L14.6382 14.5795V1.42017L1.3623 1.4203ZM11.8063 7.19665C11.6879 7.56101 11.4991 7.88353 11.2401 8.16446C10.9812 8.4454 10.6454 8.67139 10.233 8.84256C9.82019 9.01374 9.31987 9.0992 8.73193 9.0992H6.55939V12.4699H4.49229V3.06887H8.7321C9.32004 3.06887 9.82019 3.15446 10.2332 3.32564C10.6456 3.49682 10.9813 3.72294 11.2403 4.00374C11.4991 4.28468 11.6881 4.60502 11.8065 4.96482C11.9249 5.32486 11.9843 5.69787 11.9843 6.08411C11.9841 6.46144 11.9247 6.8324 11.8064 7.19663L11.8063 7.19665Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div *ngIf="element?.storageAnnexes?.length">
                <div [matTooltip]="element.storageAnnexes" class="annexes-details">
                  <div class="annexes-details-icon">
                    <svg fill="none" height="24" viewBox="0 0 13 16" width="24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.49993 0C10.0899 0 13 2.52823 13 5.64715V15.5295C13 15.7893 12.7575 16 12.4583 16H0.541743C0.242549 16 0 15.7893 0 15.5295V5.64715C0 2.52829 2.91021 0 6.50007 0L6.49993 0ZM1.08316 14.1177V15.0589H11.9164V13.6472H1.6248C1.32561 13.6472 1.08306 13.8578 1.08306 14.1177H1.08316ZM3.52063 11.7648V12.706H11.9166V11.2942H4.0626C3.7634 11.2942 3.52086 11.5049 3.52086 11.7648H3.52063ZM5.95822 9.41182V10.353H11.9166V8.94127H6.49987C6.20067 8.94127 5.95822 9.15199 5.95822 9.41182ZM8.39569 7.0589V8.00007H11.9165V6.58835H8.93734C8.63814 6.58835 8.39572 6.79896 8.39572 7.0589H8.39569Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <!-- Points fort -->
          <ng-container matColumnDef="strongPoints">
            <mat-header-cell *matHeaderCellDef class="text-black">
              {{ i18nService._('Txt_Table_Column_StrongPoints') }}
            </mat-header-cell>

            <mat-cell *matCellDef="let element">
              <app-strong-point-list
                class="strong-points rounded"
                *ngIf="element?.strongPoints?.length"
                [strongPoints]="element.strongPoints"
                [numberOfPointsToDisplay]="numberOfPointsToDisplay"
                [customClass]="['w-9', 'h-9', 'text-base']"
              ></app-strong-point-list>
            </mat-cell>
          </ng-container>

          <!-- Fiscalité -->
          <ng-container matColumnDef="taxations">
            <mat-header-cell *matHeaderCellDef class="text-black text-center">
              {{ i18nService._('Txt_Table_Column_Taxations') }}
            </mat-header-cell>

            <mat-cell *matCellDef="let element" class="py-2">
              <div class="flex flex-col gap-1">
                <div *ngFor="let taxation of element.taxations; let i = index">
                  <app-tags
                    *ngIf="i < 2"
                    [tagOption]="{ text: i18nService._(taxation.label), type: 1, textClass: 'text-center' }"
                  ></app-tags>
                </div>
                <div
                  *ngIf="element.taxations.length > 2"
                  [matTooltip]="getTaxationsTooltip(element.taxations)"
                  class="z-30 flex justify-center"
                >
                  <span>...</span>
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <!-- Prix -->
          <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef class="text-black" mat-sort-header>
              {{ i18nService._('Txt_Table_Column_TotalSellingPriceIT') }}
            </mat-header-cell>

            <mat-cell *matCellDef="let element">
              <span class="mat-column-price-span">{{ element.price }}</span>
              <span *ngIf="element.vatPrice" class="mat-column-tva-span">
                {{ i18nService._('Txt_Table_Column_ReducedTaxation') }} -
                {{ element.vatPrice }}
              </span>
              <span *ngIf="element.workPrice" class="mat-column-tva-span">
                {{ i18nService._('Txt_Table_Column_EstimatedWorkPrice') }} - {{ element.workPrice }}
              </span>
              <app-tags
                (click)="openOffer($event, element.specialOffer)"
                *ngIf="element.specialOffer"
                [ngClass]="tagSpecialOffer.ngClass"
                [tagOption]="tagSpecialOffer.tagOption"
              ></app-tags>
            </mat-cell>
          </ng-container>

          <!-- Status -->
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef class="text-black" mat-sort-header>
              {{ i18nService._('Txt_Table_Column_Status') }}
            </mat-header-cell>

            <mat-cell *matCellDef="let element" [class.free]="element.status.isFree" [class.optioned-by-me]="element.status.isOptionedByMe">
              <mat-icon class="mat-column-status-prefix-icon material-icons-outlined size-16">
                {{ element.status.icon }}
              </mat-icon>
              {{ element.status.label }}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

          <mat-row
            (click)="showLotsInformations($event, row.index, nbRooms.value.lots)"
            *matRowDef="let row; columns: displayedColumns"
            [class.optioned-by-me]="row.status.isOptionedByMe"
            [class.optioned-by-other]="row.status.isOptionedByOther"
            [class.unavailable]="row.status.unavailable"
            data-cy="lot"
            class="cursor-pointer relative"
          >
          </mat-row>
        </mat-table>
        <!--      </ng-template>-->
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
  <app-programe-page-lot-layout (closeEvent)="closePanel()" [ngClass]="{ 'program-lot-open': lotOpen }" class="program-lot-component">
  </app-programe-page-lot-layout>
</div>
