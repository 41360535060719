import { IsObject, IsString } from 'class-validator';

import { SearchCriteria } from './search-criteria.enum';
import { SearchCriteriaDto } from './search-criteria.response.dto';
import { DossierResponse } from '../dossier/dossier.response.dto';

export class DossierSearchPreferenceResponseDto {
  @IsObject()
  searchCriteria?: SearchCriteriaDto;

  @IsString()
  searchCriteriaCode: SearchCriteria;

  value: any;

  @IsObject()
  dossier?: DossierResponse;
}
