import { Component, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-financial-strategy-nb-lot-by-typology',
  templateUrl: './financial-strategy-nb-lot-by-typology.component.html',
  styleUrls: ['./financial-strategy-nb-lot-by-typology.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, FormsModule, MatInputModule, ReactiveFormsModule],
})
export class FinancialStrategyNbLotByTypologyComponent implements OnInit {
  /**
   * placeholder input
   *
   * @type {string}
   * @memberof FinancialStrategyNbLotByTypologyComponent
   */
  @Input() public placeholder: string;
  @Input() public readonly: boolean;
  /**
   * fieldName input
   *
   * @type {string}
   * @memberof FinancialStrategyNbLotByTypologyComponent
   */
  @Input() public fieldName: string;
  /**
   * nbLotMax input
   *
   * @type {number}
   * @memberof FinancialStrategyNbLotByTypologyComponent
   */
  @Input() public nbLotMax: number;
  /**
   * parentForm input
   *
   * @type {FormGroup}
   * @memberof FinancialStrategyNbLotByTypologyComponent
   */
  @Input() public parentForm: UntypedFormGroup;
  /**
   * roomControl attribute
   *
   * @type {FormControl}
   * @memberof FinancialStrategyNbLotByTypologyComponent
   */
  public roomControl: FormControl;
  /**
   * nbLot input
   *
   * @private
   * @type {number}
   * @memberof FinancialStrategyNbLotByTypologyComponent
   */
  @Input() nbLot: number;

  /**
   * ngOnInit method
   *
   * @memberof FinancialStrategyNbLotByTypologyComponent
   */
  ngOnInit(): void {
    this.parentForm.addControl(this.fieldName, new UntypedFormControl(undefined));
    this.roomControl = this.parentForm.controls[this.fieldName] as FormControl;

    this.roomControl.setValue(this.nbLot);
    if (this.readonly) {
      this.roomControl.disable();
    }
  }

  /**
   * checkIsValid method
   *
   * @memberof FinancialStrategyNbLotByTypologyComponent
   */
  checkIsValid(): void {
    if (this.roomControl.value > this.nbLotMax) {
      this.roomControl.setValue(undefined);
    }
  }
}
