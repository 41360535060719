import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-button-back',
  templateUrl: './button-back.component.html',
  styleUrls: ['./button-back.component.scss'],
  standalone: true,
  imports: [MatIconModule],
})
export class ButtonBackComponent {
  @Input() goBack: () => void;

  @Input() backLabel: string;

  constructor(public i18nService: I18nService) {}
}
