import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgFor } from '@angular/common';

import { CircleProgressComponent } from '../circle-progress/circle-progress.component';

import { I18nService } from '../../../utils/services/i18n.service';
import { Categories } from '../../models/Categories';
import { Sitemap } from '../../../utils/models/Sitemap';
import { IconComponent } from '../../../common/standalone/icon/icon.component';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  standalone: true,
  imports: [NgFor, CircleProgressComponent, IconComponent],
})
export class CategoryComponent {
  @Input() categories: Categories[];
  @Input() dossierId: number;

  constructor(
    public readonly i18nService: I18nService,
    private readonly router: Router,
  ) {}

  goToForm(id: string) {
    this.router.navigate([Sitemap.signature.form.path.replace(/:signatureId/, `${this.dossierId}`)], {
      queryParams: { activeStep: id },
    });
  }
}
