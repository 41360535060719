import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuItemService {
  public $closeOtherMenuSubject: Subject<string> = new Subject();

  public closeOthe(uuid: string) {
    this.$closeOtherMenuSubject.next(uuid);
  }
}
