import { Injectable } from '@angular/core';
import moment from 'moment-timezone';

import { AppConfigService } from '../../utils/services/app-config.service';
import { DocumentResponse } from '../../utils/models/DocumentResponse';
import { I18nService } from '../../utils/services/i18n.service';
import { ZipFileService } from '../../utils/services/zip-file.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadUtils {
  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly i18nService: I18nService,
    private readonly zipFileService: ZipFileService,
  ) {}

  public downloadAndZipContractualDocuments(documents: Array<DocumentResponse>, element: any): void {
    console.log('download zip', documents);
    if (!documents || !documents.length) {
      element.downloading = false;

      return;
    }
    const timeZoneDefault = this.appConfigService.getAppConfig().timeZone;
    const date = moment(new Date()).tz(timeZoneDefault).format(this.appConfigService._('dateFormats', 'prereservationZipName'));
    const zipName = this.i18nService._('Zip_Name_PrereservationContractualDocumentPackage', [element.programName, date]);
    const documentsToDownloadInZip = documents.map((document) => {
      return {
        ...document,
        targetNameInZip: this.i18nService._(document.type.label),
      };
    });
    this.zipFileService.inProgress$.subscribe((inProgress) => {
      element.downloading = inProgress;
    });
    this.zipFileService.createZip(documentsToDownloadInZip, zipName, 'Error_Snackbar_EmptyPrereservationContractualDocumentPackageZip');
  }
}
