import { I18nPluralPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DateTime, Interval } from 'luxon';
import { isNil } from 'lodash-es';

import { LotAction } from '../model/lot-action';
import { LotDetailBlock } from '../model/lot-detail-block';
import { LotDocument } from '../model/lot-document';

import { AppItem } from '../../design-system/model/app-item';
import { TagsInterface, TagTypeEnum } from '../../design-system/model/tag-option';
import { annexeTags } from '../../programs/models/enum/annexe-tag.enum';
import { fiscalityTags } from '../../programs/models/enum/fiscality-tags.enum';
import { ActionOnOptionResponse } from '../../utils/models/ActionOnOptionResponse';
import { DocumentResponse } from '../../utils/models/DocumentResponse';
import { LotDetailResponse } from '../../utils/models/LotDetailResponse';
import { LotDetailsDocumentViewModel } from '../../utils/models/LotDetailsDocumentViewModel';
import { LotFormatDetailResponse } from '../../utils/models/LotFormatDetailResponse';
import { SecondaryLotDetailResponse } from '../../utils/models/SecondaryLotDetailResponse';
import { SpecialOfferDetailResponse } from '../../utils/models/SpecialOfferDetailResponse';
import { TaxationDetailResponse } from '../../utils/models/TaxationDetailResponse';
import { AppConfigService } from '../../utils/services/app-config.service';
import { BasicFormatsService } from '../../utils/services/basic-formats.service';
import { I18nService } from '../../utils/services/i18n.service';
import { ServiceLocator } from '../../utils/services/service-locator';

@Injectable({
  providedIn: 'root',
})
export class PageLotService {
  private readonly tags: { [key: string]: TagsInterface } = {
    hasSpecialOffer: {
      ngClass: 'special-offer-tag',
      tagOption: {
        prefixIcon: 'local_offer',
        text: 'Offres spéciales',
        type: TagTypeEnum.DEFAULT,
      },
    },
  };

  private readonly _i18nPluralPipe: I18nPluralPipe;

  constructor(
    private readonly _i18nService: I18nService,
    private readonly basicFormatsService: BasicFormatsService,
    private readonly appConfigService: AppConfigService,
  ) {
    this._i18nPluralPipe = ServiceLocator.injector.get<I18nPluralPipe>(I18nPluralPipe);
  }

  public getFees(lot: LotDetailResponse): number {
    return lot ? lot.fees / 100 : undefined;
  }

  public getInformationItems(lot: LotFormatDetailResponse, nominalTaxationValue: number): LotDetailBlock {
    const pluralRoomsMapping = {
      '=1': this._i18nService._('Txt_Page_Program_Room').toLowerCase(),
      other: this._i18nService._('Txt_Page_Program_Rooms').toLowerCase(),
    };

    const lotInformation: LotDetailBlock = {
      lotNumber: {
        label: 'Txt_Page_Program_ListLotNumber',
        value: lot.lotNumber,
      },
      typeLot: {
        label: 'Txt_Page_Program_ListLotType',
        value: this._i18nService._(lot.lotTypeLabel),
      },
      room: {
        label: 'Txt_page_program_Detail_Lot_RoomNumber',
        value: `${lot.nbRooms} ${this._i18nPluralPipe.transform(lot.nbRooms, pluralRoomsMapping)}`,
      },
      space: {
        label: 'Txt_page_program_Detail_Lot_Surface',
        value: `${lot.surface}`,
      },
      lotFloor: {
        label: 'Txt_page_program_Detail_Lot_floor',
        value: `${lot.floor}`,
      },
      lotOrientation: {
        label: 'Txt_page_program_Detail_Lot_Orientation',
        value: `${this._i18nService._(lot.lotOrientation)}`,
      },
      fees: {
        label: 'Txt_Page_Program_ListFees',
        value: lot.fees,
      },
      delivryDate: {
        label: 'Txt_Page_Program_ListDeliveryDate',
        value: lot.deliveryDate,
      },
      taxation: {
        label: 'Txt_Page_Program_ListTaxationList',
        value: lot.taxationList,
      },
      annexes: this._computeAnnexes(lot, nominalTaxationValue),
      areas: lot.lotAreas.map((value) => {
        return {
          label: value.label,
          value: this.basicFormatsService.formatArea(value.area),
        };
      }),
    };
    lotInformation.price = this._computePriceTTc(lot);

    return lotInformation;
  }

  public getPriceItems(
    lot: LotFormatDetailResponse,
    nominalTaxationValue: number,
    reducedTaxationLot: TaxationDetailResponse,
  ): LotDetailBlock {
    const priceItems = {
      price: this._computePriceTTc(lot),
      reducePrice: this._computeReducePrice(lot, reducedTaxationLot),
    };
    Object.assign(priceItems, this._computeLmnpPrices(lot));
    Object.assign(priceItems, this._computeOtherTaxationsPrices(lot));
    Object.assign(priceItems, this._computeNueProprieteTaxationsPrices(lot));
    Object.assign(priceItems, this._computeLmnpLmpTaxationsPrices(lot));
    return priceItems;
  }

  /**
   * Returns an array of (i18n_label, value) given the taxations of a lot
   * for several properties: renting price, profitability, ...
   * Those value-key pairs will be used to populate the rent and profitability
   * section of the program page.
   * @param lot
   * @returns Array<(string, string)>
   */
  public getRentAndProfitablityItems(lot: LotFormatDetailResponse): AppItem[] {
    const rentAndProfitablityItems = [];
    const taxationsList = this.appConfigService.appConfig.taxationList;
    let lmnpTaxation;
    let pinelTaxation;
    let plsTaxation;
    let pinelPlusTaxation;

    for (const taxation of lot.taxations) {
      switch (taxation.label) {
        case taxationsList['PINEL PLUS']:
          pinelPlusTaxation = taxation;
          break;
        case taxationsList.LMNPLMP:
          lmnpTaxation = taxation;
          break;
        case taxationsList.PINEL:
          pinelTaxation = taxation;
          break;
        case taxationsList.PLS:
          plsTaxation = taxation;
          break;
        default:
          break;
      }
    }

    if (lmnpTaxation) {
      this._addItem(rentAndProfitablityItems, 'Txt_Page_Program_ListGuaranteedMonthlyRentingPriceIT', lot.guaranteedMonthlyRentingPriceIT);
      this._addItem(rentAndProfitablityItems, 'Txt_Page_Program_ListMonthlyRentingPriceET', lot.monthlyRentingPriceET);
      this._addItem(rentAndProfitablityItems, 'Txt_Page_Program_ListMonthlyRentalLotPriceIT', lot.monthlyRentalLotPriceIT);
      this._addItem(
        rentAndProfitablityItems,
        'Txt_Page_Program_ListMonthlyRentalSecondaryLotPriceIT',
        lot.monthlyRentalSecondaryLotPriceIT,
      );
      this._addItem(
        rentAndProfitablityItems,
        'Txt_Page_Program_ListLmnpLmpProfitabilityET',
        this.getProfitabilityFixedValue(lmnpTaxation.profitability),
      );
    } else {
      // DEFAULT
      this._addItem(rentAndProfitablityItems, 'Txt_Page_Program_ListEstimatedMonthlyRentingPrice', lot.estimatedMonthlyRentingPrice);
      this._addItem(rentAndProfitablityItems, 'Txt_Page_Program_ListEstimatedProfitability', lot.estimatedProfitability);

      this._addItem(rentAndProfitablityItems, 'Txt_Page_Program_ListEstimatedFurnishedMarketRent', lot.estimatedFurnishedMarketRent);
      this._addItem(rentAndProfitablityItems, 'Txt_Page_Program_ListEstimatedFurnishedMarketYield', lot.estimatedFurnishedMarketYield);

      // PINEL PLUS
      if (pinelPlusTaxation) {
        this._addItem(
          rentAndProfitablityItems,
          'Txt_Page_Program_ListPinelPlusMonthlyRentingPrice',
          this.getRentingPrice(pinelPlusTaxation.rentingPrice),
        );
        this._addItem(
          rentAndProfitablityItems,
          'Txt_Page_Program_ListPinelPlusProfitability',
          this.getProfitabilityFixedValue(pinelPlusTaxation.profitability),
        );
      }

      // PINEL
      if (pinelTaxation) {
        this._addItem(
          rentAndProfitablityItems,
          'Txt_Page_Program_ListPinelMonthlyRentingPrice',
          this.getRentingPrice(pinelTaxation.rentingPrice),
        );
        this._addItem(
          rentAndProfitablityItems,
          'Txt_Page_Program_ListPinelProfitability',
          this.getProfitabilityFixedValue(pinelTaxation.profitability),
        );
      }

      // PLS
      if (plsTaxation) {
        this._addItem(
          rentAndProfitablityItems,
          'Txt_Page_Program_ListPlsMonthlyRentingPrice',
          this.getRentingPrice(plsTaxation.rentingPrice),
        );
        this._addItem(
          rentAndProfitablityItems,
          'Txt_Page_Program_ListPlsProfitability',
          this.getProfitabilityFixedValue(plsTaxation.profitability),
        );
      }
    }

    return rentAndProfitablityItems;
  }

  separateLotDetails(selectedLot: LotDetailResponse): LotFormatDetailResponse {
    const taxationReduced = selectedLot.taxations.find((taxation) => taxation.reducedTaxation);

    return {
      id: selectedLot.lotId,
      lotNumber: selectedLot.lotNumber,
      lotTypeLabel: selectedLot.lotTypeLabel,
      nbRooms: selectedLot.rooms,
      surface: this.basicFormatsService.formatArea(selectedLot.livingSpace),
      lotAreas: selectedLot.lotAreas,
      floor: `${this.handleFloorSyntax(selectedLot)}`,
      lotOrientation: selectedLot.lotOrientationLabel,
      secondaryLots: this.handleSecondaryLotPrice(selectedLot, this.sortSecondaryLotASC(selectedLot.secondaryLots)),
      price: this.formatPrice(selectedLot.commercialLotSellingPriceIT),
      vatPrice: this.formatPriceCeil(selectedLot.reducedTotalSellingPriceIT),
      fees: selectedLot.fees !== undefined ? this.basicFormatsService.formatPercentage(selectedLot.fees / 100) : '',
      deliveryDate: this.handleDeliveryDate(new Date(selectedLot.deliveryDate)),
      taxations: selectedLot.taxations,
      taxationList: this.sortTaxations(selectedLot.taxations),
      estimatedMonthlyRentingPrice: this.formatPrice(selectedLot.estmatedMonthlyRentingPrice),
      estimatedProfitability: selectedLot.estimatedProfitability
        ? this.basicFormatsService.formatPercentage(selectedLot.estimatedProfitability / 100)
        : '-',
      pinelTaxation: selectedLot.pinelTaxation,
      plsTaxation: selectedLot.plsTaxation,
      lmnpLmpNonGereTaxation: selectedLot.lmnpLmpNonGereTaxation,
      pinelMonthlyRentingPrice:
        !selectedLot.pinelTaxation || !selectedLot.pinelTaxation.rentingPrice
          ? '-'
          : this.formatPriceCurrency(selectedLot.pinelTaxation.rentingPrice),
      pinelProfitability:
        !selectedLot.pinelTaxation || !selectedLot.pinelTaxation.profitability
          ? '-'
          : this.basicFormatsService.formatPercentage(selectedLot.pinelTaxation.profitability / 100),
      plsMonthlyRentingPrice:
        !selectedLot.plsTaxation || !selectedLot.plsTaxation.rentingPrice
          ? '-'
          : this.formatPriceCurrency(selectedLot.plsTaxation.rentingPrice),
      plsProfitability:
        !selectedLot.plsTaxation || !selectedLot.plsTaxation.profitability
          ? '-'
          : this.basicFormatsService.formatPercentage(selectedLot.plsTaxation.profitability / 100),
      lmnpLmpNonGereMonthlyRentingPrice:
        !selectedLot.lmnpLmpNonGereTaxation || !selectedLot.lmnpLmpNonGereTaxation.rentingPrice
          ? '-'
          : this.formatPriceCurrency(selectedLot.lmnpLmpNonGereTaxation.rentingPrice),
      lmnpLmpNonGereProfitability:
        !selectedLot.lmnpLmpNonGereTaxation || !selectedLot.lmnpLmpNonGereTaxation.profitability
          ? '-'
          : this.basicFormatsService.formatPercentage(selectedLot.lmnpLmpNonGereTaxation.profitability / 100),
      guaranteedMonthlyRentingPriceIT: this.formatPrice(selectedLot.guaranteedMonthlyRentingPriceIT),
      estatePriceET: this.formatPrice(selectedLot.estatePriceET),
      // MALRAUX
      estimatedWorkPrice: this.formatPrice(selectedLot.estimatedWorkPrice),
      estimatedLandPrice: this.formatPrice(selectedLot.estimatedLandPrice),
      // LMNP NON GERE :
      estimatedFurnishedMarketRent: this.formatPrice(selectedLot.estimatedFurnishedMarketRent),
      estimatedFurnishedMarketYield: selectedLot.estimatedFurnishedMarketYield
        ? this.basicFormatsService.formatPercentage(selectedLot.estimatedFurnishedMarketYield / 100)
        : '-',
      // NUE PROPRIETE :
      usufruitValue: this.formatPrice(selectedLot.usufruitValue),
      distributionKey: !selectedLot.distributionKey ? '-' : selectedLot.distributionKey,
      nueProprieteDuration: this.formatYear(selectedLot.nueProprieteDuration),
      // ----------
      housingPriceET: this.formatPrice(selectedLot.housingPriceET),
      globalSellingPriceET: this.formatPrice(selectedLot.globalSellingPriceET),
      tangiblePriceET: this.formatPrice(selectedLot.tangiblePriceET),
      loanFees: this.formatPrice(selectedLot.loanFees),
      sellingFees: this.formatPrice(selectedLot.sellingFees),
      attorneyFees: this.formatPrice(selectedLot.attorneyFees),
      monthlyRentingPriceET: this.formatPrice(selectedLot.monthlyRentingPriceET),
      monthlyRentalLotPriceIT: this.formatPrice(selectedLot.monthlyRentalLotPriceIT),
      monthlyRentalSecondaryLotPriceIT: this.formatPrice(selectedLot.monthlyRentalSecondaryLotPriceIT),
      lmnpLmpProfitabilityET: !selectedLot.lmnpLmpProfitabilityET
        ? '-'
        : this.basicFormatsService.formatPercentage(selectedLot.lmnpLmpProfitabilityET / 100),
      isReducedTaxation: taxationReduced ? taxationReduced.reducedTaxation : false,
      secondaryLotPriceIncluded: selectedLot.secondaryLotPriceIncluded,
      specialOffers: selectedLot.specialOffers,
      hasSpecialOffer: Boolean(selectedLot.specialOffers && selectedLot.specialOffers.length),
      profitability: !selectedLot.profitability ? null : this.basicFormatsService.formatPercentage(selectedLot.profitability / 100),
      strongPoints: selectedLot.strongPoints,
    };
  }

  /**
   * Handle floor syntax to show 'RDC' instead of O
   *
   * @param {LotDetailResponse} lot
   * @returns {string}
   * @memberof ProgramPageLotsDetailsComponent
   */
  handleFloorSyntax(lot: LotDetailResponse): string {
    return Number(lot.floor) === 0 ? this._i18nService._('Txt_Page_Program_GroundFloor') : String(lot.floor);
  }

  /**
   * Handle delivery Date syntax (trimester year)
   * Ex: 1T 2020
   *
   * @param {Date} deliveryDate
   * @returns {string}
   * @memberof ProgramPageLotsDetailsComponent
   */
  handleDeliveryDate(deliveryDate: Date): string {
    // Return the matching trimester
    return `${Math.floor(deliveryDate.getMonth() / 3) + 1}T ${deliveryDate.getFullYear()}`;
  }

  /**
   * Sort Taxations Label ASC
   *
   * @param {Array<TaxationResponse>} taxations
   * @returns {string}
   * @memberof ProgramPageLotsDetailsComponent
   */
  sortTaxations(taxations: Array<TaxationDetailResponse>): string {
    return taxations
      .map((element) => {
        return this._i18nService._(element.label);
      })
      .sort((a, b) => {
        if (!a && !b) {
          return 0;
        }
        if (a && !b) {
          return 1;
        }
        if (!a && b) {
          return -1;
        }

        return a.localeCompare(b);
      })
      .join(', ');
  }

  /**
   * Sort secondary lot type ASC
   *
   * @param {Array<SecondaryLotDetailResponse>} secondaryLots
   * @returns {Array<SecondaryLotDetailResponse>}
   * @memberof ProgramPageLotsDetailsComponent
   */
  sortSecondaryLotASC(secondaryLots: Array<SecondaryLotDetailResponse>): Array<SecondaryLotDetailResponse> {
    return secondaryLots.sort((a, b) => {
      if (!a && !b) {
        return 0;
      }
      if (a && !b) {
        return 1;
      }
      if (!a && b) {
        return -1;
      }

      return this._i18nService._(a.label).localeCompare(this._i18nService._(b.label));
    });
  }

  /**
   * Handle VAT Percentage
   *
   * @param {LotFormatDetailResponse} lotDetails
   * @returns {string}
   * @memberof ProgramPageLotsDetailsComponent
   */
  handleLotVAT(lotDetails: LotFormatDetailResponse, nominalTaxationValue): string {
    if (lotDetails.taxations.length === 1 && lotDetails.isReducedTaxation) {
      return this.basicFormatsService.formatPercentage(lotDetails.taxations[0].taxation / 100, 0, 0, 2);
    }

    return this.basicFormatsService.formatPercentage(nominalTaxationValue / 100, 0, 0, 2);
  }

  /**
   * Handle secondary lot price if there is only one reduced taxation
   *
   * @param {LotDetailResponse} lotDetails
   * @param {Array<SecondaryLotDetailResponse>} secondaryLots
   * @returns {Array<SecondaryLotDetailResponse>}
   * @memberof ProgramPageLotsDetailsComponent
   */
  handleSecondaryLotPrice(
    lotDetails: LotDetailResponse,
    secondaryLots: Array<SecondaryLotDetailResponse>,
  ): Array<SecondaryLotDetailResponse> {
    if (lotDetails.taxations.length === 1 && lotDetails.taxations.find((element) => element.reducedTaxation)) {
      secondaryLots.forEach((secondaryLot) => {
        secondaryLot.lotSellingPriceIT = (secondaryLot.lotSellingPriceIT * (100 - lotDetails.taxations[0].taxation)) / 100;
      });
    }

    return secondaryLots;
  }

  /**
   * Check if selected lot has LMNP/LMP taxation
   *
   * @param {Array<TaxationDetailResponse>} selectedLotTaxationList
   * @returns {boolean}
   * @memberof ProgramPageLotsDetailsComponent
   */
  hasLmnpLmpTaxation(selectedLotTaxationList: Array<TaxationDetailResponse>): boolean {
    return Boolean(selectedLotTaxationList.find((taxation) => taxation.label === this.appConfigService.appConfig.taxationList.LMNPLMP));
  }

  /**
   * Check if selected lot has LMNP/LMP taxation
   *
   * @param {Array<TaxationDetailResponse>} selectedLotTaxationList
   * @returns {boolean}
   * @memberof ProgramPageLotsDetailsComponent
   */
  hasOtherTaxation(selectedLotTaxationList: Array<TaxationDetailResponse>): boolean {
    const taxationList = this.appConfigService.appConfig.taxationList;
    return Boolean(
      selectedLotTaxationList.find((taxation) =>
        [taxationList.MALRAUX, taxationList['DEFICIT FONCIER'], taxationList['MONUMENTS HISTORIQUES']].includes(taxation.label),
      ),
    );
  }

  /**
   * Check if selected lot has Nue propriété taxation
   *
   * @param {Array<TaxationDetailResponse>} selectedLotTaxationList
   * @returns {boolean}
   * @memberof ProgramPageLotsDetailsComponent
   */
  hasNueProprieteTaxation(selectedLotTaxationList: Array<TaxationDetailResponse>): boolean {
    const taxationList = this.appConfigService.appConfig.taxationList;
    return Boolean(selectedLotTaxationList.find((taxation) => [taxationList['NUE PROPRIETE']].includes(taxation.label)));
  }

  /**
   * Check if selected lot has LMNP NON GERE taxation
   *
   * @param {Array<TaxationDetailResponse>} selectedLotTaxationList
   * @returns {boolean}
   * @memberof ProgramPageLotsDetailsComponent
   */

  hasLmnpLmpNonGereTaxation(selectedLotTaxationList: Array<TaxationDetailResponse>): boolean {
    const taxationList = this.appConfigService.appConfig.taxationList;
    return Boolean(selectedLotTaxationList.find((taxation) => [taxationList['LMNP NON GERE']].includes(taxation.label)));
  }

  formatReduceTaxation(reducedTaxationLot: TaxationDetailResponse) {
    return this.basicFormatsService.formatPercentage(reducedTaxationLot.taxation / 100);
  }

  getLotDocuments(lot: LotDetailResponse): LotDocument {
    const plan = this._getlotPlanDoc(lot);
    const specialOffer = this._getSpecialOfferDocument(lot);

    return {
      plan: this._initDocViewModelObject(plan, true),
      specialOfferAmmendment: specialOffer
        ? this._initDocViewModelObject(specialOffer.amendment, !!specialOffer, [specialOffer.title])
        : undefined,
    };
  }

  _getlotPlanDoc(lot: LotDetailResponse): DocumentResponse {
    return {
      title: lot.lotPlanTitle,
      container: lot.lotPlanContainer,
      fileName: lot.lotPlanFileName,
      mimeType: lot.lotPlanMimeType,
      type: {
        code: this.appConfigService.getAppConfig().documentTypeCode.lotPlan,
      },
    };
  }

  getLotFiscalityTags(lot: LotFormatDetailResponse): TagsInterface[] {
    if (!lot || !lot.taxations || !lot.taxations.length) {
      return [];
    }

    const tags: TagsInterface[] = [];
    lot.taxations.forEach((val) => {
      // la tag NOMINAL_TAXATION est exclu de cette liste
      if (val.label !== 'NOMINAL_TAXATION') {
        tags.push(fiscalityTags[val.label]);
      }
    });

    return tags;
  }

  getLotTags(lot: LotFormatDetailResponse): TagsInterface[] {
    if (!lot) {
      return [];
    }

    const tags: TagsInterface[] = [];
    Object.keys(this.tags).forEach((key) => {
      if (lot[key]) {
        tags.push(this.tags[key]);
      }
    });

    return tags;
  }

  getAnnexeTags(lot: LotFormatDetailResponse): TagsInterface[] {
    if (!lot) {
      return [];
    }

    return this.extractAnnexTags([...lot.secondaryLots, ...lot.lotAreas]);
  }

  public extractAnnexTags(annexes: { label: string }[]) {
    const tags: TagsInterface[] = [];
    annexes.forEach((val) => {
      const tag = annexeTags[val.label];
      tag.tagOption.text = this._i18nService._(val.label);
      tags.push(tag);
    });

    return tags;
  }

  getLotActions(actionOnOption: ActionOnOptionResponse, isAuthorizedToManageOnlyReservation, forComparator = false): LotAction {
    const lotAction = new LotAction();
    lotAction.hide = false;
    if (!isAuthorizedToManageOnlyReservation) {
      Object.assign(lotAction, actionOnOption);
    }
    const timeZoneDefault = this.appConfigService.getAppConfig().timeZone;
    if (actionOnOption.checkRemainingTimeForOptionOwner) {
      const timeLeftFormated = DateTime.fromISO(actionOnOption.checkRemainingTimeForOptionOwner)
        .setZone(timeZoneDefault)
        .toFormat(`dd/MM/yyyy 'à' HH:mm`);
      lotAction.userHasOptionUntil = actionOnOption.checkOwnerOptionSatusIsPending
        ? this._i18nService._('Txt_Detail_Lot_Option_Pending')
        : forComparator
        ? this._i18nService._('Txt_comparator_Option_Time_Left', [timeLeftFormated])
        : this._i18nService._('Txt_Option_Time_Left', [timeLeftFormated]);
    } else if (actionOnOption.checkRemainingTimeForOptionOtherOwner) {
      const timeLeftFormated = DateTime.fromISO(actionOnOption.checkRemainingTimeForOptionOtherOwner)
        .setZone(timeZoneDefault)
        .toFormat(`dd/MM/yyyy 'à' HH:mm`);

      if (!actionOnOption.checkOtherOwnerOptionSatusIsPending && !forComparator) {
        lotAction.userHasOptionUntil = this._i18nService._('Txt_Option_Time_Left_Other', [timeLeftFormated]);
      }
    }
    return lotAction;
  }

  private getProfitabilityFixedValue(value: number): string {
    if (isNil(value)) {
      return '-';
    }

    return `${value.toFixed(2)} %`;
  }

  private getRentingPrice(value: number): string {
    if (isNil(value)) {
      return '-';
    }

    return `${value} €`;
  }

  private formatPrice(price: number): string {
    return !price ? '-' : this.formatPriceCurrency(price);
  }

  private formatYear(years: number): string {
    if (!years) {
      return '-';
    }
    return years === 1 ? '1 an' : years + ' ans';
  }

  private formatPriceCeil(price: number): string {
    return !price ? '-' : this.formatPriceCurrencyCeil(price);
  }

  private formatPriceCurrency(price: number): string {
    return this.basicFormatsService.formatCurrency(price, undefined, 0, 0, 2);
  }

  private formatPriceCurrencyCeil(price: number): string {
    return this.basicFormatsService.formatCurrencyCeil(price, undefined, 0, 0, 2);
  }

  private _computePriceTTc(lot: LotFormatDetailResponse): AppItem {
    const item = new AppItem();
    item.label = 'Txt_Page_Program_ListTotalSellingPriceIT';
    if (this.hasLmnpLmpTaxation(lot.taxations)) {
      item.label = 'Txt_page_program_Detail_Lot_CommercialLotSellingPriceIT_lmnp';
    }

    if (this.hasOtherTaxation(lot.taxations)) {
      item.label = 'Txt_Placeholder_CommercialLotSellingPriceIT_other_taxation';
    }

    if (this.hasNueProprieteTaxation(lot.taxations)) {
      item.label = 'Txt_Placeholder_PleineProprietePrice_nue_propriete';
    }

    const priceSuffix =
      lot.secondaryLots.length && lot.secondaryLotPriceIncluded ? this._i18nService._('Txt_Page_Program_IncludedArea') : '';
    item.value = `${lot.price} ${priceSuffix}`;

    return item;
  }

  private _computeReducePrice(lot: LotFormatDetailResponse, reducedTaxationLot: TaxationDetailResponse): AppItem {
    const item = new AppItem();
    if (reducedTaxationLot) {
      item.label = 'Txt_Page_Program_ListReducedTotalSellingPriceIT';
      const priceSuffix =
        lot.secondaryLots.length && lot.secondaryLotPriceIncluded ? this._i18nService._('Txt_Page_Program_IncludedArea') : '';
      item.value = `${lot.vatPrice} ${priceSuffix}`;
    }
    if (this.hasNueProprieteTaxation(lot.taxations)) {
      item.label = 'Txt_page_program_Detail_Lot_CommercialLotSellingPriceIT_nuePropriete';
    }
    return item;
  }

  private _computeLmnpPrices(lot: LotFormatDetailResponse): LotDetailBlock {
    if (!this.hasLmnpLmpTaxation(lot.taxations)) {
      return {};
    }

    return {
      estatePriceET: {
        label: 'Txt_Page_Program_ListEstatePriceET',
        value: lot.estatePriceET,
      },
      housingPriceET: {
        label: 'Txt_Page_Program_ListHousingPriceET',
        value: lot.housingPriceET,
      },
      globalSellingPriceET: {
        label: 'Txt_page_program_Detail_Lot_blockPrice_ListGlobalSellingPriceET',
        value: lot.globalSellingPriceET,
      },
      tangiblePriceET: {
        label: 'Txt_Page_Program_ListTangiblePriceET',
        value: lot.tangiblePriceET,
      },
      sellingFees: {
        label: 'Txt_Page_Program_ListSellingFees',
        value: lot.sellingFees,
      },
      loanFees: {
        label: 'Txt_Page_Program_ListLoanFees',
        value: lot.loanFees,
      },
      attorneyFees: {
        label: 'Txt_Page_Program_ListAttorneyFees',
        value: lot.attorneyFees,
      },
    };
  }

  private _computeOtherTaxationsPrices(lot: LotFormatDetailResponse): LotDetailBlock {
    if (!this.hasOtherTaxation(lot.taxations)) {
      return {};
    }

    return {
      estimatedWorkPrice: {
        label: 'Txt_Page_Program_ListEstimatedWorkPrice',
        value: lot.estimatedWorkPrice,
      },
      estimatedLandPrice: {
        label: 'Txt_Page_Program_ListEstimatedLandPrice',
        value: lot.estimatedLandPrice,
      },
    };
  }

  private _computeNueProprieteTaxationsPrices(lot: LotFormatDetailResponse): LotDetailBlock {
    if (!this.hasNueProprieteTaxation(lot.taxations)) {
      return {};
    }

    return {
      usufruitValue: {
        label:
          lot.usufruitValue && lot.usufruitValue !== '-' ? 'Txt_Page_Program_UsufruitValue' : 'Txt_Page_Program_UsufruitValue_Undefined',
        value: lot.usufruitValue,
      },
      distributionKey: {
        label:
          lot.distributionKey && lot.distributionKey !== '-'
            ? 'Txt_Page_Program_DistributionKey'
            : 'Txt_Page_Program_DistributionKey_Undefined',
        value: lot.distributionKey,
      },
      nueProprieteDuration: {
        label:
          lot.nueProprieteDuration && lot.nueProprieteDuration !== '-'
            ? 'Txt_Page_Program_NueProprieteDuration'
            : 'Txt_Page_Program_NueProprieteDuration_Undefined',
        value: lot.nueProprieteDuration,
      },
    };
  }

  private _computeLmnpLmpTaxationsPrices(lot: LotFormatDetailResponse): LotDetailBlock {
    if (this.hasLmnpLmpTaxation(lot.taxations)) {
      return {};
    }

    return {
      estimatedFurnishedMarketRent: {
        label: 'Txt_Page_Program_EstimatedFurnishedMarketRent',
        value: lot.estimatedFurnishedMarketRent,
      },
      estimatedFurnishedMarketYield: {
        label: 'Txt_Page_Program_EstimatedFurnishedMarketYield',
        value: lot.estimatedFurnishedMarketYield,
      },
    };
  }

  private _computeAnnexes(lot: LotFormatDetailResponse, nominalTaxationValue): AppItem[] {
    const items: AppItem[] = [];
    lot.secondaryLots.forEach((secondaryLot) => {
      const appItem = new AppItem();
      appItem.label = 'Txt_Page_Program_ListArea';
      if (secondaryLot.lotSellingPriceIT) {
        appItem.value = this._i18nService._('Txt_Page_Program_SecondaryLotDetailsSellingPrice', [
          this._i18nService._(secondaryLot.label),
          secondaryLot.numLot,
          this.formatPriceCurrency(secondaryLot.lotSellingPriceIT),
          this.handleLotVAT(lot, nominalTaxationValue),
        ]);
      } else {
        appItem.value = this._i18nService._('Txt_Page_Program_SecondaryLotDetails', [
          this._i18nService._(secondaryLot.label),
          secondaryLot.numLot,
        ]);
      }
      items.push(appItem);
    });

    return items;
  }

  /**
   * Adds a {label, value} object to an array and normalize the value display, or
   * use with a default value if needed
   * @param rentAndProfitablityItems Array to add the {label, value} object to
   * @param label
   * @param value
   * @param defaultValue value to use if value is undefined
   */
  private _addItem(rentAndProfitablityItems: { label: string; value: string }[], label: string, value: string, defaultValue?: string) {
    const item = { label, value };
    if (value) {
      item.value = item.value.replace('.', ',');
      rentAndProfitablityItems.push(item);
    } else if (defaultValue) {
      item.value = defaultValue.replace('.', ',');
      rentAndProfitablityItems.push(item);
    }
  }

  private _getSpecialOfferDocument(lot: LotDetailResponse): SpecialOfferDetailResponse {
    return lot.specialOffers.find((so) => {
      const soStartDate = DateTime.fromJSDate(new Date(so.startDate));
      const soEndDate = DateTime.fromJSDate(new Date(so.endDate));

      return Interval.fromDateTimes(soStartDate, soEndDate).contains(DateTime.now());
    });
  }

  private _initDocViewModelObject(document: DocumentResponse, display: boolean, textArguments?: string[]): LotDetailsDocumentViewModel {
    const documentByCode = this.appConfigService.getAppConfig().documentByCode;
    let code = '';

    if (document && document.type && document.type.code) {
      code = document.type.code;
    }

    const label = documentByCode[code];

    return {
      label,
      document,
      code,
      actions: {
        checked: false,
        display,
        disabled: !document,
      },
      textArguments,
    };
  }
}
