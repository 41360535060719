import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Intercom } from '@supy-io/ngx-intercom';

import { TokenService } from '../../services/authorisation/token.service';
import { ConfigurableParamService } from '../../services/configurable-param.service';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  standalone: true,
})
export class ChatbotComponent implements OnInit, OnDestroy {
  rootSubscription: Subscription;
  identityUser = { user_id: '', email: '', app_id: '' };
  private intercomAppId: string;

  constructor(
    public intercom: Intercom,
    private readonly tokenService: TokenService,
    private readonly _configurableParamService: ConfigurableParamService,
  ) {
    this.rootSubscription = new Subscription();
  }

  ngOnInit() {
    this._configurableParamService.getConfigParam('INTERCOM_APP_ID').subscribe((intercomAppId) => {
      if (intercomAppId) {
        this.intercomAppId = intercomAppId['INTERCOM_APP_ID'];
        this.identityUser.app_id = intercomAppId['INTERCOM_APP_ID'];
      }
    });

    this.rootSubscription.add(
      this.tokenService.getLoggedInObservable().subscribe((isLoggedIn: boolean) => {
        // Reset context after switch
        this.intercom.shutdown();

        if (isLoggedIn) {
          const isLoggedAs = this.tokenService.isConnectionAs();
          // New connection handled
          // Update only when user is logged in with his profile
          if (!isLoggedAs) {
            this.identityUser.user_id = `${this.tokenService.getUserId()}`;
            this.identityUser.email = `${this.tokenService.getEmail()}`;
          } else {
            // TODO: Handle connectedAs
            // this.identityUser.user_id = `${this.tokenService.getAsUserId()}`;
            // this.identityUser.email = `${this.tokenService.getAsUserEmail()}`;
            // debugger;
            return;
          }

          this.intercom.boot({
            app_id: this.intercomAppId,
            ...this.identityUser,
          });
        } else {
          // disconnected
          this.intercom.boot({
            app_id: this.intercomAppId,
          });
        }
      }),
    );
  }

  /**
   * ngOnDestroy method
   *
   * @memberof HeaderComponent
   */
  ngOnDestroy(): void {
    this.rootSubscription.unsubscribe();
  }
}
