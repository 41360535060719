import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { I18nService } from '../../../../utils/services/i18n.service';
import { SearchFormUtilsService } from '../../../services/search-form-utils.service';

@Component({
  selector: 'app-search-filter-reinit',
  templateUrl: './search-filter-reinit.component.html',
  styleUrls: ['./search-filter-reinit.component.scss'],
  standalone: true,
  imports: [MatIconModule, NgClass],
})
export class SearchFilterReinitComponent {
  @Input() iconClass: string[] | string;

  constructor(
    public readonly i18nService: I18nService,
    private readonly _searchFormUtilsService: SearchFormUtilsService,
  ) {}

  clearAllFilter() {
    this._searchFormUtilsService.reinitialiseFilter();
  }
}
