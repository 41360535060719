<div class="container">
  <!-- error map container -->
  <div class="map-error-container" *ngIf="showError">
    <div class="map-error-popup">
      <mat-icon class="map-error-popup-icon material-icons-outlined size-48" [svgIcon]="'map_error_icon'"></mat-icon>
      <span class="map-error-popup-text">{{ i18nService._('Txt_mapbox_popup_error_text') }}</span>
      <span class="map-error-popup-link cursor-pointer" (click)="reloadPage()">{{ i18nService._('Txt_mapbox_popup_error_link') }}</span>
    </div>
  </div>
  <!-- map -->
  <mgl-map
    *ngIf="accessToken && style"
    [zoom]="zoom"
    [maxZoom]="maxZoom"
    [minZoom]="minZoom"
    [center]="center"
    [bounds]="bounds"
    [accessToken]="accessToken"
    [style]="style"
    [cursorStyle]="cursorStyle"
    [attributionControl]="false"
    (mapLoad)="mapLoaded($event)"
    (moveEnd)="displayOrRemoveControls($event)"
    (mapError)="showErrorPopup($event)"
    (styleImageMissing)="logStyleImageMissing($event)"
    #valorissimoMainMap
  >
    <mgl-control *ngIf="showMapControls" mglNavigation [showCompass]="showCompass" position="top-right"></mgl-control>

    <mgl-geojson-source
      id="program-data"
      [data]="geoProgramsData"
      [cluster]="true"
      [clusterProperties]="{ sum: ['+', ['get', 'nbLots', ['properties']]] }"
      [clusterRadius]="30"
      [clusterMaxZoom]="10"
    >
    </mgl-geojson-source>
    <mgl-geojson-source
      id="program-data-for-hover"
      [data]="{ type: 'FeatureCollection', features: [] }"
      [cluster]="true"
      [clusterProperties]="{ sum: ['+', ['get', 'nbLots', ['properties']]] }"
      [clusterRadius]="50"
      [clusterMaxZoom]="10"
    >
    </mgl-geojson-source>
    <mgl-layer
      [id]="programMarkerLayer.id"
      [source]="programMarkerLayer.source"
      [type]="programMarkerLayer.type"
      [layout]="programMarkerLayer.layout"
      (layerClick)="openProgramInformationPopup($event)"
      (layerMouseEnter)="changeMarkerStyleOnMouseEnter($event)"
      (layerMouseLeave)="changeMarkerStyleOnMouseLeave()"
    >
    </mgl-layer>
    <mgl-layer
      [id]="hoveredProgramMarkerLayer.id"
      [source]="hoveredProgramMarkerLayer.source"
      [type]="hoveredProgramMarkerLayer.type"
      [layout]="hoveredProgramMarkerLayer.layout"
      (layerClick)="openProgramInformationPopup($event)"
      (layerMouseEnter)="changeMarkerStyleOnMouseEnter($event)"
      (layerMouseLeave)="changeMarkerStyleOnMouseLeave()"
    >
    </mgl-layer>
    <mgl-layer
      [id]="programClusterLayer.id"
      [source]="programClusterLayer.source"
      [type]="programClusterLayer.type"
      (layerClick)="inspectProgramsCluster($event)"
      [filter]="programClusterLayer.filter"
      [paint]="programClusterLayer.paint"
      (layerMouseEnter)="cursorStyle = 'pointer'"
      (layerMouseLeave)="cursorStyle = ''"
    >
    </mgl-layer>
    <mgl-layer
      [id]="programClusterCountLayer.id"
      [source]="programClusterCountLayer.source"
      [type]="programClusterCountLayer.type"
      [filter]="programClusterCountLayer.filter"
      [layout]="programClusterCountLayer.layout"
      [paint]="programClusterCountLayer.paint"
      (layerMouseEnter)="cursorStyle = 'pointer'"
    >
    </mgl-layer>
    <mgl-popup
      *ngIf="selectedPoint && !selectedPoint.properties.cluster"
      [lngLat]="selectedPoint.geometry.coordinates"
      [offset]="15"
      className="program-marker-popup"
      (popupClose)="closeProgramInformationPopup()"
    >
      <div class="program-marker-popup-item">
        <div class="program-marker-popup-item-perspective">
          <div class="app-tag-container">
            <app-tags
              *ngIf="selectedPoint.properties.nbSpecialOffers"
              [ngClass]="tagSpecialOffer.ngClass"
              [tagOption]="tagSpecialOffer.tagOption"
            ></app-tags>
            <app-tags *ngIf="selectedPoint.properties.isNew" [ngClass]="tagNouveau.ngClass" [tagOption]="tagNouveau.tagOption"></app-tags>
          </div>
          <img
            [alt]="selectedPoint.properties.title"
            [src]="selectedPoint.properties.logo"
            (error)="selectedPoint.properties.logo = 'assets/img/fallbacks/fallback__perspective.svg'"
            class="w-100 h-100"
          />
        </div>
        <div class="program-marker-popup-item-infos">
          <div class="program-marker-popup-item-infos-title">
            {{ selectedPoint.properties.programName }}
          </div>
          <div class="program-marker-popup-item-infos-price">
            {{ selectedPoint.properties.price }}
          </div>
          <div
            class="program-marker-popup-item-infos-delivery"
            [innerHTML]="i18nService._('Txt_mapbox_popup_Program_delivery_date_prefix') + selectedPoint.properties.deliveryDate"
          ></div>
          <div class="program-marker-popup-item-infos-see-more" (click)="redirectToProgram(selectedPoint.properties.id)">
            <span class="cursor-pointer text-right">{{ i18nService._('Txt_mapbox_popup_Program_see_more') }}</span>
            <mat-icon class="material-icons-outlined size-14">navigate_next</mat-icon>
          </div>
        </div>
      </div>
    </mgl-popup>
  </mgl-map>
  <!-- map -->

  <div *ngIf="showMapControls" class="mapboxgl-control-container">
    <div class="mapboxgl-ctrl-top-left">
      <div
        data-cy="mapboxTitle"
        class="center-on-coordinates-control mapboxgl-ctrl mapboxgl-ctrl-group"
        *ngIf="haveCenterControl"
        (click)="centerOnCoordinates(true)"
      >
        <mat-icon class="mapboxgl-ctrl-icon material-icons-outlined size-18">gps_fixed</mat-icon>
        <span>{{ this.i18nService._('Txt_mapbox_center_control_title') }}</span>
      </div>
    </div>
    <div class="mapboxgl-ctrl-top-right"></div>
    <div class="mapboxgl-ctrl-bottom-left">
      <div class="custom-legend-control mapboxgl-ctrl mapboxgl-ctrl-group">
        <mat-icon class="custom-legend-control-cluster" [svgIcon]="'map_cluster_icon'"></mat-icon>
        <span class="span-cluster">4</span>
        <mat-icon class="custom-legend-control-marker" [svgIcon]="'marker_black'"></mat-icon>
        <span class="span-marker">10</span>
        <span class="custom-legend-control-label">{{ i18nService._('Txt_mapbox_legend') }}</span>
      </div>
    </div>
    <div class="mapboxgl-ctrl-bottom-right"></div>
    <div class="mapboxgl-ctrl-bottom-center">
      <div class="refresh-carte-on-moving-control" *ngIf="haveRefreshWhileMovingControl">
        <mat-checkbox [disabled]="false" [(ngModel)]="isChecked" color="primary" (change)="onCheckMovingControl($event)">
          <span>{{ this.i18nService._('Txt_mapbox_refresh_on_moving_control_title') }}</span>
        </mat-checkbox>
      </div>

      <div
        data-cy="refreshControl"
        class="refresh-carte-manually-control"
        *ngIf="!haveRefreshWhileMovingControl"
        (click)="lunchSearchOnRefreshClick()"
      >
        <mat-icon class="mapboxgl-ctrl-icon material-icons-outlined size-14" [svgIcon]="'refresh'"></mat-icon>
        <span>{{ this.i18nService._('Txt_mapbox_refresh_manually_control_title') }}</span>
      </div>
    </div>
    <div class="mapboxgl-ctrl-top-center"></div>
  </div>
</div>
