import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { AutoCompleteService } from '../../common/form/auto-complete.service';
import { AccountApiService } from '../../adapters/account-api.service';
import { AccountsResponse } from '../../adapters/models/account/accounts.response';
import { AccountSummaryResponse } from '../../adapters/models/account/account.summary.response';
import { AccountParamRequest } from '../../adapters/models/account/account-request';

@Injectable({
  providedIn: 'root',
})
export class SellerSearch implements AutoCompleteService {
  constructor(private accountApiService: AccountApiService) {}

  findData(name: string, observers: any): Observable<{ label: string; id: number }[]> {
    const companyTypeIds = observers['props.companyTypeIds'] ? observers['props.companyTypeIds'].value : [3, 4];
    const params: AccountParamRequest = { name: name, companyTypeId: companyTypeIds, offset: 0, nbResult: 200 };
    return this.accountApiService.getAccounts(params).pipe(
      map((accountsResponse: AccountsResponse) => {
        return accountsResponse.items.map((account: AccountSummaryResponse) => {
          return {
            id: account.id,
            label: `${account.firstName} ${account.lastName} (${account.companyName})`,
          };
        });
      }),
    );
  }
}
