import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

import { AbstractAdvancedTrackingTableService } from './abstract-advanced-tracking-table.service';
import { SpecialOffersTrackingTableItem } from '../models/specialOffersTrackingTableItem';

import { SpecialOffersTrackingTableResponse } from '../../utils/models/SpecialOffersTrackingTableResponse';
import { TableLoader, TableResponse } from '../../table/interfaces/DataLoader';
import { AppConfigService } from '../../utils/services/app-config.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';
import { CacheService } from '../../utils/services/cache.service';
import { SpecialOfferApiService } from '../../adapters/special-offer-api.service';

@Injectable({
  providedIn: 'root',
})
export class SpecialOffersTrackingTableService
  extends AbstractAdvancedTrackingTableService<SpecialOffersTrackingTableResponse>
  implements TableLoader<SpecialOffersTrackingTableItem>
{
  constructor(
    public readonly appConfig: AppConfigService,
    public readonly errorHandlerService: ErrorHandlerService,
    public readonly http: HttpClient,
    public readonly cacheService: CacheService,
    private specialOfferApiService: SpecialOfferApiService,
  ) {
    super(appConfig, errorHandlerService, http, cacheService);
  }

  async get(params): Promise<TableResponse<SpecialOffersTrackingTableItem>> {
    const res = this.specialOfferApiService.getSpecialOffer(params);
    return lastValueFrom(res);
  }

  async getById(id: number): Promise<SpecialOffersTrackingTableItem> {
    const res = this.specialOfferApiService.getSpecialOfferById(id);
    return lastValueFrom(res);
  }
}
