import { Subject, firstValueFrom } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AreaType, CompleteDossierResponse, LotResponseDto, LotResponseTaxation, SecondaryLotType } from '@commons-dto/dossier-prospect';

import { TransactionStatusColorPipe } from './pipes/transaction-option-color.pipe';
import { DossierProspectRouteService } from '../dossier-prospect/dossier-prospect.route.service';

import { UtilsModule } from '../../../utils/utils.module';
import { DesignSystemModule } from '../../../design-system/design-system.module';
import { FilterSortHeaderBarComponent } from '../../../common/standalone/filter-sort-header-bar/filter-sort-header-bar.component';
import { PipesModule } from '../../../utils/pipes/pipes.module';
import { DossierProspectLotApiService } from '../../../adapters/dossier-prospect-lot-api.service';
import { IconComponent } from '../../../common/standalone/icon/icon.component';
import { I18nService } from '../../../utils/services/i18n.service';
import { Taxation } from '../../../programs/models/enum/taxation.enum';
import { ChipComponent } from '../../../design-system/component/chip/chip.component';
import { ButtonComponent } from '../../../design-system/component/button/button.component';
import { LotDetailsPanelComponent } from '../../../utils/components/lot-details-panel/lot-details-panel.component';
import { PageProgramHelperService } from '../../../programs/components/program-page-v2/page-program-helper.service';
import { DossierProspectAssociationService } from '../../../dialog/services/dossier-prospect-association.service';
import { DossierProspectTakeOptionData } from '../../../dialog/models/DossierProspectTakeOptionData';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { EllipsisMiddlePipe } from '../../../utils/pipes/ellipsis-middle.pipe';
import { QuarterDatePipe } from '../../../utils/pipes/quarter-date.pipe';

@Component({
  selector: 'app-dossier-prospect-lots',
  standalone: true,
  templateUrl: './lot.component.html',
  styleUrls: ['./lot.component.scss'],
  imports: [
    CommonModule,
    MatSidenavModule,
    UtilsModule,
    DesignSystemModule,
    TransactionStatusColorPipe,
    FilterSortHeaderBarComponent,
    PipesModule,
    IconComponent,
    ChipComponent,
    ButtonComponent,
    LotDetailsPanelComponent,
    EllipsisMiddlePipe,
    QuarterDatePipe,
  ],
})
export class LotComponent implements OnInit {
  @Input() searchPreferencesUpdated: Subject<void>;

  public AreaType = AreaType;
  public SecondaryLotType = SecondaryLotType;
  public Taxation = Taxation;

  private _dossier: CompleteDossierResponse;
  @Input()
  get dossier(): CompleteDossierResponse {
    return this._dossier;
  }

  set dossier(value: CompleteDossierResponse) {
    if (value.id === this._dossier?.id) return;
    if (value != null) {
      this._dossier = value;
      this.initModel();
    }
  }

  public lots: LotResponseDto[] = [];
  public loaded = false;

  public lotSelected: LotResponseDto = null;
  public panelOpened = false;
  public hLotInfos: any;

  constructor(
    private readonly dossierProspectLotApiService: DossierProspectLotApiService,
    private readonly dossierProspectRouterService: DossierProspectRouteService,
    private readonly i18nService: I18nService,
    private readonly pageProgramHelperService: PageProgramHelperService,
    private readonly snackbarService: SnackbarService,
    private readonly dossierProspectAssociationService: DossierProspectAssociationService,
  ) {}

  ngOnInit() {
    if (!this.searchPreferencesUpdated) return;
    this.searchPreferencesUpdated.subscribe(() => {
      this.loaded = false;
      this.initModel();
    });
  }

  private async initModel() {
    this.lots = await this.dossierProspectLotApiService.getLotsByDossierId(this._dossier.id);
    this.hLotInfos = {};
    for (const lot of this.lots) {
      this.hLotInfos[lot.id] = {
        exteriorSummary: this.getExteriorAnnexesSummary(lot),
        parkingSummary: this.getParkingAnnexesSummary(lot),
        storageSummary: this.getStorageAnnexesSummary(lot),
        vatRate: this.getLowestVatRate(lot),
        taxationsNonNominal: this.getTaxationsNonNominal(lot),
      };
      this.hLotInfos[lot.id].vatPrice = this.getVatPrice(lot, this.hLotInfos[lot.id].vatRate);
    }
    this.loaded = true;
  }

  private getTaxationsNonNominal(lot: LotResponseDto): LotResponseTaxation[] {
    return lot.taxations.filter((taxation) => taxation.id !== Taxation.NOMINAL_TAXATION);
  }

  private getLowestVatRate(lot: LotResponseDto): number {
    const lowestTaxation = lot.taxations.reduce(function (prev, curr) {
      return prev.rate < curr.rate ? prev : curr;
    });
    return lowestTaxation.rate;
  }

  private getVatPrice(lot: LotResponseDto, rate: number): number {
    return (rate * lot.commercialLotSellingPriceIT) / 100;
  }

  private getExteriorAnnexesSummary(lot: LotResponseDto): string {
    let summary = '';
    if (!lot.areaTypes) return summary;
    for (const areaType of lot.areaTypes) {
      if (summary) summary += '\r\n';
      summary += this.i18nService._(AreaType[areaType] as unknown as string);
    }
    return summary;
  }

  private getParkingAnnexesSummary(lot: LotResponseDto): string {
    let summary = '';
    if (!lot.secondaryLotTypes) return summary;
    for (const secondaryLotType of lot.secondaryLotTypes) {
      switch (secondaryLotType) {
        case SecondaryLotType.GARAGE:
        case SecondaryLotType.OUTDOOR_PARKING:
        case SecondaryLotType.BASEMENT_PARKING_LOT:
        case SecondaryLotType.CARPORT:
          if (summary) summary += '\r\n';
          summary += this.i18nService._(SecondaryLotType[secondaryLotType] as unknown as string);
          break;
      }
    }
    return summary;
  }

  private getStorageAnnexesSummary(lot: LotResponseDto): string {
    let summary = '';
    if (!lot.secondaryLotTypes) return summary;
    for (const secondaryLotType of lot.secondaryLotTypes) {
      switch (secondaryLotType) {
        case SecondaryLotType.CELLAR:
        case SecondaryLotType.ATTIC:
        case SecondaryLotType.LARDER:
        case SecondaryLotType.BOXES:
          if (summary) summary += '\r\n';
          summary += this.i18nService._(SecondaryLotType[secondaryLotType] as unknown as string);
          break;
      }
    }
    return summary;
  }

  open(lot: LotResponseDto) {
    this.lotSelected = lot;
    this.panelOpened = true;
  }

  openPlan(lot: LotResponseDto) {
    const plan = {
      title: 'Plan',
      container: 'lotDocuments',
      fileName: lot.lotPlanPath,
      mimeType: 'application/pdf',
    };
    this.pageProgramHelperService.openDocumentInBrowser(undefined, plan);
  }

  close() {
    this.lotSelected = null;
    this.panelOpened = false;
  }

  goToSearchPreferences() {
    this.dossierProspectRouterService.goToSearchPreferences(this.dossier.id, false);
  }

  async createOption(lot: LotResponseDto) {
    const takeOptionRequest: DossierProspectTakeOptionData = {
      dossierProspectId: this.dossier.id,
      lotId: lot.id,
    };

    await firstValueFrom(this.dossierProspectAssociationService.takeOption(takeOptionRequest));

    this.snackbarService.sendMessage({
      text: this.i18nService._('Success_SnackBar_TakeOption'),
      type: SnackbarMessageType.Success,
    });
    this.dossierProspectRouterService.goToTransactions(this.dossier.id);
  }

  seeAllLots() {
    this.dossierProspectRouterService.goToSearchWithSearchPreferences(this.dossier.summarySearchPreferences);
  }
}
