<div class="wrapper flex flex-col lg:flex-row w-full h-full">
  <app-search-localisation
    class="mx-1 w-full lg:w-auto lg:flex-1 lg:max-w-md"
    (filterChangeEvent)="setFilter($event, panelTypeEnum.LOCATION)"
  ></app-search-localisation>
  <div class="flex flex-row items-center justify-start">
    <app-filter-menu-badge
      class="inline-flex mx-1"
      (filterChangeEvent)="setFilter($event, panelTypeEnum.ROOM)"
      [placeholder]="'Txt_search_filter_rooms_placeholder'"
      [panelComponant]="panelTypeEnum.ROOM"
      [selectedData]="data[panelTypeEnum.ROOM]"
    ></app-filter-menu-badge>
    <app-filter-menu-badge
      class="inline-flex mx-1"
      (filterChangeEvent)="setFilter($event, panelTypeEnum.FISCALITY)"
      [panelComponant]="panelTypeEnum.FISCALITY"
      [placeholder]="'Txt_search_filter_taxation_placeholder'"
      [selectedData]="data[panelTypeEnum.FISCALITY]"
      [limit]="1"
    ></app-filter-menu-badge>
    <app-filter-menu-badge
      class="inline-flex mx-1"
      (filterChangeEvent)="setFilter($event, panelTypeEnum.OTHER)"
      [placeholder]="'Txt_search_filter_other_criteria_placeholder'"
      [selectedData]="data[panelTypeEnum.OTHER]"
      [modal]="true"
      [limit]="1"
      [prefixIcon]="'add'"
      (click)="openSearchMoreCriteriaModal()"
    ></app-filter-menu-badge>
    <div class="inline-flex mx-1">
      <button
        (click)="launchSearch()"
        class="button w-8 h-8 flex justify-center items-center rounded-full shadow-md hover:shadow-lg bg-orange-500 text-white"
      >
        <mat-icon class="size-18">search</mat-icon>
      </button>
    </div>
  </div>

  <!-- <div
    class="more-filter-panel-md"
    [ngClass]="{ 'more-filter-panel-md-show': showFilterDialogPanel }"
    (click)="$event.stopImmediatePropagation()">
    <app-search-filter-more-panel (launchSearchEvent)="launchSearch($event)"></app-search-filter-more-panel>
  </div> -->
</div>
