import { tagClass, TagsInterface, TagTypeEnum } from '../../../design-system/model/tag-option';

export const fiscalityTags: { [key: string]: TagsInterface } = {
  PINEL: {
    ngClass: tagClass[TagTypeEnum.TRANSPARENT],
    tagOption: {
      text: 'PINEL',
      type: TagTypeEnum.TRANSPARENT,
    },
  },
  'PINEL PLUS': {
    ngClass: tagClass[TagTypeEnum.TRANSPARENT],
    tagOption: {
      text: 'PINEL PLUS',
      type: TagTypeEnum.TRANSPARENT,
    },
  },
  MALRAUX: {
    ngClass: tagClass[TagTypeEnum.TRANSPARENT],
    tagOption: {
      text: 'MALRAUX',
      type: TagTypeEnum.TRANSPARENT,
    },
  },
  'DEFICIT FONCIER': {
    ngClass: tagClass[TagTypeEnum.TRANSPARENT],
    tagOption: {
      text: 'DEFICIT FONCIER',
      type: TagTypeEnum.TRANSPARENT,
    },
  },
  ANRU: {
    ngClass: tagClass[TagTypeEnum.TRANSPARENT],
    tagOption: {
      text: 'ANRU',
      type: TagTypeEnum.TRANSPARENT,
    },
  },
  PLS: {
    ngClass: tagClass[TagTypeEnum.TRANSPARENT],
    tagOption: {
      text: 'PLS',
      type: TagTypeEnum.TRANSPARENT,
    },
  },
  'LMNP/LMP': {
    ngClass: tagClass[TagTypeEnum.TRANSPARENT],
    tagOption: {
      text: 'LMNP/LMP',
      type: TagTypeEnum.TRANSPARENT,
    },
  },
  'MONUMENTS HISTORIQUES': {
    ngClass: tagClass[TagTypeEnum.TRANSPARENT],
    tagOption: {
      text: 'MONUMENTS HISTORIQUES',
      type: TagTypeEnum.TRANSPARENT,
    },
  },
  'LMNP NON GERE': {
    ngClass: tagClass[TagTypeEnum.TRANSPARENT],
    tagOption: {
      text: 'LMNP NON GERE',
      type: TagTypeEnum.TRANSPARENT,
    },
  },
  'NUE PROPRIETE': {
    ngClass: tagClass[TagTypeEnum.TRANSPARENT],
    tagOption: {
      text: 'NUE PROPRIETE',
      type: TagTypeEnum.TRANSPARENT,
    },
  },
  NOMINAL_TAXATION: {
    ngClass: tagClass[TagTypeEnum.TRANSPARENT],
    tagOption: {
      text: 'TVA NOMINALE',
      type: TagTypeEnum.TRANSPARENT,
    },
  },
};
