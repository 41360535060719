/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';

import { I18nService } from '../../../../utils/services/i18n.service';
import { ConfigurableParamService } from '../../../../utils/services/configurable-param.service';
import { ExternalDocumentLinkTypeEnum } from '../../../../utils/models/external-document-link';
import { SignatureDocumentService } from '../../../services/signature-document.service';
import { FileExtention } from '../../../../utils/models/app-constant';
import { FileDropComponent } from '../../../../common/form/file-drop/file-drop.component';
import { IconComponent } from '../../../../common/standalone/icon/icon.component';

export interface ModalDocumentData {
  dossierId: number;
  documentType: string;
  documents: any[];
}

@Component({
  selector: 'app-modal-document',
  templateUrl: './modal-document.component.html',
  styleUrls: ['./modal-document.component.scss'],
  standalone: true,
  imports: [IconComponent, MatDialogModule, FileDropComponent, FormsModule],
})
export class ModalDocumentComponent implements OnInit {
  acceptedType = [FileExtention.PDF, FileExtention.JPEG, FileExtention.JPG, FileExtention.PNG];
  link: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalDocumentData,
    public dialogRef: MatDialogRef<ModalDocumentComponent>,
    public readonly i18nService: I18nService,
    private readonly _configurableParamService: ConfigurableParamService,
    public readonly signatureDocumentService: SignatureDocumentService,
  ) {}

  ngOnInit(): void {
    this._configurableParamService.externalDocumentsLink$.subscribe((externalDocumentsLink) => {
      if (externalDocumentsLink) {
        this.link = externalDocumentsLink.find((doc) => doc.code === ExternalDocumentLinkTypeEnum.DOCUMENTS_UTILS).link;
      }
    });
  }

  closeDialog(answer: boolean) {
    this.dialogRef.close(answer);
  }
}
