import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';

import { Step } from '../model/Progress';

import { I18nService } from '../../../utils/services/i18n.service';
import { IconComponent } from '../../standalone/icon/icon.component';

@Component({
  selector: 'app-progress-step',
  templateUrl: './progress-step.componet.html',
  styleUrls: ['./progress-step.componet.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, IconComponent, NgFor],
})
export class ProgressStepComponent {
  @Input() step: Step;
  @Input() keyPrefix = '';

  constructor(private readonly _i18nService: I18nService) {}

  getLabel(key: string, data?: string[]) {
    const fullKey = this.keyPrefix + key;
    if (data && data.length > 0) {
      return this._i18nService._(fullKey, data);
    } else {
      return this._i18nService._(fullKey);
    }
  }
}
