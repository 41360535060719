import { Component, HostBinding, Input } from '@angular/core';
import { NgIconComponent, provideNgIconLoader } from '@ng-icons/core';
import * as heroIconsSolid from '@ng-icons/heroicons/solid';
import * as heroIconsOutline from '@ng-icons/heroicons/outline';
import { CommonModule } from '@angular/common';

import { AppIconsName } from './utils/app-icon-names';

import { APP_ICONS } from '../../../utils/constantes/icones';

@Component({
  selector: 'app-icon',
  standalone: true,
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  providers: [
    provideNgIconLoader((name) => {
      return heroIconsOutline[name] ?? heroIconsSolid[name] ?? APP_ICONS[name] ?? name;
    }),
  ],
  imports: [NgIconComponent, CommonModule],
})
export class IconComponent {
  public iconFullName: string;

  public APP_ICONS = APP_ICONS;

  @Input()
  @HostBinding('style.--size')
  public size: string;

  _name: AppIconsName;
  @Input()
  get name(): AppIconsName {
    return this._name;
  }

  set name(value: AppIconsName) {
    this._name = value;
    this.init();
  }

  _type: 'solid' | 'outline' = 'outline';
  @Input()
  get type(): 'solid' | 'outline' {
    return this._type;
  }

  set type(value: 'solid' | 'outline') {
    this._type = value;
    this.init();
  }

  _provider: 'heroicon' | 'custom' = 'heroicon';
  @Input()
  get provider(): 'heroicon' | 'custom' {
    return this._provider;
  }

  set provider(value: 'heroicon' | 'custom') {
    this._provider = value;
    this.init();
  }

  init() {
    this.iconFullName = this.getName();
  }

  getName(): string {
    const solidSuffix = this.type === 'solid' ? 'Solid' : '';
    const providerPrefix = this.provider === 'heroicon' ? 'hero' : '';
    return `${providerPrefix}${this.name}${solidSuffix}`;
  }
}
