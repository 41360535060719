import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActionButtonStategieService {
  $emitPublicationStrategies = new Subject<boolean>();

  constructor() {
    this.$emitPublicationStrategies = new EventEmitter<boolean>();
  }

  public emitPublicationStrategies(action: boolean): void {
    this.$emitPublicationStrategies.next(action);
  }
}
