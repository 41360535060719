/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBadgeModule } from '@angular/material/badge';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgIf, NgClass } from '@angular/common';

import { AdvancedTrackingTablesNoBackPageComponent } from '../advanced-tracking-tables-no-back-page/advanced-tracking-tables-no-back-page.component';

import { TableCheckboxShow } from '../../../table/enums/table-checkbox-show.enum';
import { AlertEnum } from '../../../utils/models/enums/alert.enum';
import { I18nService } from '../../../utils/services/i18n.service';
import { AdvancedTrackingTableClassDef, AdvancedTrackingTableColumn } from '../../models/AdvancedTrackingTableColumn';
import { ExcludeListTrackingTableItem } from '../../models/exlude-list/exclude-list-tracking-table-item';
import { ExcludeListTrackingTableService } from '../../services/exclude-list-tracking-table.service';
import { AlertComponent } from '../../../utils/components/alert/alert.component';

@Component({
  selector: 'app-exclude-list-tracking-table',
  templateUrl: './exclude-list-tracking-table.component.html',
  styleUrls: ['./exclude-list-tracking-table.component.scss'],
  standalone: true,
  imports: [
    AlertComponent,
    NgIf,
    MatButtonToggleModule,
    FormsModule,
    NgClass,
    MatBadgeModule,
    AdvancedTrackingTablesNoBackPageComponent,
    MatProgressSpinnerModule,
  ],
})
export class ExcludeListTrackingTableComponent implements OnInit {
  public alertEnum = AlertEnum;
  showCheckboxSelection: any;
  /**
   * showCheckboxSelection all
   *
   * @type {TableCheckboxShow}
   * @memberof MandateStrategySelectionTableComponent
   */
  public readonly showCheckboxSelectionALL: TableCheckboxShow = TableCheckboxShow.ALL;
  /**
   * showCheckboxSelection not selected
   *
   * @type {TableCheckboxShow}
   * @memberof MandateStrategySelectionTableComponent
   */
  public readonly showCheckboxSelectionNotSelected: TableCheckboxShow = TableCheckboxShow.NOT_SELECTED;
  /**
   * showCheckboxSelection selected
   *
   * @type {TableCheckboxShow}
   * @memberof MandateStrategySelectionTableComponent
   */
  public readonly showCheckboxSelectionSelected: TableCheckboxShow = TableCheckboxShow.SELECTED;
  @Input() companyId;
  @Output() readonly selectedExcludeList: EventEmitter<number[]> = new EventEmitter<number[]>();
  columns: Array<AdvancedTrackingTableColumn>;
  public items: ExcludeListTrackingTableItem[];
  public itemsFiltered: { data: any[]; clearSelection: boolean };
  public selectedItemIds: any[];
  private readonly _ngClassSelected: AdvancedTrackingTableClassDef;

  constructor(
    public readonly i18nService: I18nService,
    private readonly _exludeListTrackingTableService: ExcludeListTrackingTableService,
  ) {
    this.showCheckboxSelection = this.showCheckboxSelectionALL;
    this._ngClassSelected = {
      classFalse: 'text-xs',
      classTrue: 'text-xs txt-error',
      condition(item): boolean {
        return item.isExclude;
      },
    };

    this.columns = [
      {
        key: 'id',
        hidden: true,
        sortable: false,
        i18nTitle: '',
      },
      {
        key: 'isExclude',
        hidden: true,
        sortable: false,
        i18nTitle: '',
      },
      {
        key: 'corporateName',
        sortable: true,
        i18nTitle: 'Txt_Placeholder_CorporateName',
        ngClass: this._ngClassSelected,
      },
      {
        key: 'siret',
        sortable: true,
        replaceFalsyWithDash: true,
        i18nTitle: 'Txt_ContractorTrackingTable_Column_Siret',
        ngClass: this._ngClassSelected,
      },
      {
        key: 'responsible',
        sortable: true,
        i18nTitle: 'Txt_Table_Column_Responsible',
        ngClass: this._ngClassSelected,
      },
      {
        key: 'city',
        sortable: true,
        replaceFalsyWithDash: true,
        i18nTitle: 'Txt_Placeholder_City',
        ngClass: this._ngClassSelected,
      },
    ];
  }

  ngOnInit() {
    this._exludeListTrackingTableService.getItems(this.companyId).subscribe((data) => {
      this.items = data.items;
      this.selectedItemIds = data.items.filter((item) => item.isExclude).map((value) => value.id);
      this.selectedExcludeList.emit(this.selectedItemIds);
      this.filterList();
    });
  }

  filterList(): void {
    switch (this.showCheckboxSelection) {
      case TableCheckboxShow.ALL: {
        this.itemsFiltered = { data: [...this.items], clearSelection: false };
        break;
      }
      case TableCheckboxShow.NOT_SELECTED: {
        this.itemsFiltered = {
          data: this.items.filter((value) => !this.selectedItemIds.includes(value.id)),
          clearSelection: false,
        };
        break;
      }
      case TableCheckboxShow.SELECTED: {
        this.itemsFiltered = {
          data: this.items.filter((value) => this.selectedItemIds.includes(value.id)),
          clearSelection: false,
        };
        break;
      }
      default:
    }
  }

  checkedRowsChange($event: Array<any>): void {
    this.selectedItemIds = $event;
    this.itemsFiltered.data.forEach((value) => (value.isExclude = $event.includes(value.id)));
    this.filterList();
    this.selectedExcludeList.emit(this.selectedItemIds);
  }
}
