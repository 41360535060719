import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgClass, NgIf } from '@angular/common';

import { AbstractProgramPageLot } from '../abstract-program-page-lot';
import { ReduceTaxationDialogComponent } from './reduce-taxation-dialog/reduce-taxation-dialog.component';

import { tagClass, TagsInterface, TagTypeEnum } from '../../../../../design-system/model/tag-option';
import { LotDetailBlock } from '../../../../../lot/model/lot-detail-block';
import { LotDetailResponse } from '../../../../../utils/models/LotDetailResponse';
import { LotFormatDetailResponse } from '../../../../../utils/models/LotFormatDetailResponse';
import { TaxationDetailResponse } from '../../../../../utils/models/TaxationDetailResponse';
import { ProgramService } from '../../../../services/program.service';
import { I18nService } from '../../../../../utils/services/i18n.service';
import { SafePipe } from '../../../../../utils/pipes/safe.pipe';
import { SingleAccordionPanelComponent } from '../../../../../design-system/component/single-accordion-panel/single-accordion-panel.component';
import { ItemComponent } from '../../../../../design-system/component/item/item.component';
import { TagsComponent } from '../../../../../design-system/component/tags/tags.component';

@Component({
  selector: 'app-program-page-lot-price',
  templateUrl: './program-page-lot-price.component.html',
  styleUrls: ['./program-page-lot-price.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, TagsComponent, ItemComponent, SingleAccordionPanelComponent, SafePipe],
})
export class ProgramPageLotPriceComponent extends AbstractProgramPageLot {
  public lotData: LotDetailBlock;
  public isLmnpLmp: boolean;
  // MALRAUX ou DEFICIT FONCIER
  public isOtherTaxation: boolean;
  public isNueProprieteTaxation: boolean;
  public isLmnpLmpNonGereTaxation: boolean;
  public reducedTaxationLot: TaxationDetailResponse;
  public taxationTags: {
    taxations: TagsInterface;
    reducedTaxation?: TagsInterface;
  };
  nominalTaxationValue: number;

  constructor(
    private readonly dialog: MatDialog,
    private _programService: ProgramService,
    public i18nService: I18nService,
  ) {
    super();
  }

  initData(lot?: LotDetailResponse, formattedLot?: LotFormatDetailResponse) {
    if (formattedLot) {
      this._programService.getNominalTaxationValue().subscribe((data) => {
        this.reducedTaxationLot = formattedLot.taxations.find((element) => element.reducedTaxation);
        this.isLmnpLmp = this._pageLotService.hasLmnpLmpTaxation(formattedLot.taxations);
        this.isOtherTaxation = this._pageLotService.hasOtherTaxation(formattedLot.taxations);
        this.isNueProprieteTaxation = this._pageLotService.hasNueProprieteTaxation(formattedLot.taxations);
        this.isLmnpLmpNonGereTaxation = this._pageLotService.hasLmnpLmpNonGereTaxation(formattedLot.taxations);
        if (this.isNueProprieteTaxation) {
          this.nominalTaxationValue = formattedLot.taxations.find((item) => item.label === 'NUE PROPRIETE').taxation;
        } else {
          this.nominalTaxationValue = data[0].taxation;
        }

        this.lotData = this._pageLotService.getPriceItems(formattedLot, this.nominalTaxationValue, this.reducedTaxationLot);
        this.extractTags(formattedLot);
      });
    }
  }

  openReduceTaxDialog() {
    this.dialog.open(ReduceTaxationDialogComponent, {
      hasBackdrop: true,
      width: '60vw',
      maxWidth: '60vw',
      maxHeight: '95vh',
      height: 'auto',
      data: this.reducedTaxationLot,
    });
  }

  private extractTags(formattedLot: LotFormatDetailResponse) {
    this.taxationTags = {
      taxations: {
        ngClass: tagClass[TagTypeEnum.TAXATIONS],
        tagOption: {
          text: `TVA à ${this._pageLotService.handleLotVAT(formattedLot, this.nominalTaxationValue)}`,
          type: TagTypeEnum.TAXATIONS,
        },
      },
    };
    if (this.reducedTaxationLot) {
      this.taxationTags.reducedTaxation = {
        ngClass: tagClass[TagTypeEnum.REDUCED_TAXATIONS],
        tagOption: {
          text: `TVA à ${this._pageLotService.formatReduceTaxation(this.reducedTaxationLot)}`,
          type: TagTypeEnum.REDUCED_TAXATIONS,
          suffixIcon: 'navigate_next',
        },
      };
    }
  }
}
