import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

import { PageProgramHelperService } from '../page-program-helper.service';
import { ProgramPageNeighborhoodMapDialogComponent } from '../program-page-neighborhood-map-dialog/program-page-neighborhood-map-dialog.component';

import { I18nService } from '../../../../utils/services/i18n.service';
import { ProgramDetail } from '../../../../utils/models/ProgramDetail';

@Component({
  selector: 'app-program-page-neighborhood-map',
  templateUrl: './program-page-neighborhood-map.component.html',
  styleUrls: ['./program-page-neighborhood-map.component.scss'],
  standalone: true,
  imports: [NgIf, MatIconModule, MatButtonModule],
})
export class ProgramPageNeighborhoodMapComponent implements OnInit {
  program: ProgramDetail;

  constructor(
    public i18nService: I18nService,
    private readonly dialog: MatDialog,
    private pageProgramHelperService: PageProgramHelperService,
  ) {}

  ngOnInit(): void {
    this.program = this.pageProgramHelperService.program;
  }

  openCityScanDialog(): void {
    this.dialog.open(ProgramPageNeighborhoodMapDialogComponent, {
      hasBackdrop: true,
      width: '95vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
      height: 'auto',
    });
  }
}
