<div (click)="actionComparator($event)" *ngIf="!hide">
  <div *ngIf="existLot" class="comparator comparator-lot-remove">
    <div class="icon-remove-comparator">
      <mat-icon [svgIcon]="'compare'" class="material-icons-round text-xs leading-none w-3 h-3"></mat-icon>
    </div>
    <span *ngIf="viewText" class="comparator-lot-remove-icon">{{ i18nService._('Txt_Remove_From_Comparator') }} </span>
  </div>

  <div
    *ngIf="!existLot"
    [className]="getLengthCompareStorage() ? 'comparator-lot-add' : 'comparator-lot-max-add'"
    [matTooltip]="getCompareMessage()"
    class="comparator comparator-lot-add"
  >
    <div [className]="viewText ? 'icon-add-comparator-hover' : 'icon-add-comparator'">
      <mat-icon [svgIcon]="'compare'" class="material-icons-round text-xs leading-none w-3 h-3"></mat-icon>
    </div>
    <span *ngIf="viewText" class="comparator-lot-icon">{{ i18nService._('Txt_Add_To_Comparator') }}</span>
  </div>
</div>
