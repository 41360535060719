<div *ngIf="design === 'default'" class="flex flex-col">
  <mat-form-field class="fullW flex w-full">
    <mat-label>{{ placeholder }}</mat-label>
    <mat-chip-grid #chipList [required]="required" class="chip-autocomplete">
      <mat-chip-row
        (removed)="remove(programNameSuggestedWithIdProgramInsideChipChoosed)"
        *ngFor="let programNameSuggestedWithIdProgramInsideChipChoosed of itemsLabelKeyList"
        [removable]="removable"
        [selectable]="selectable"
        class="mt-1 mr-3 flex justify-center items-center"
        color="primary"
      >
        <span *ngIf="removable" class="icon-cancel txt-orange text-xxs ml-0 mr-1 flex justify-center items-center" matChipRemove></span>
        {{ hideProgramIdKeyWithReplacement(programNameSuggestedWithIdProgramInsideChipChoosed) }}
      </mat-chip-row>
      <input
        #chipInput
        (keyup)="filter()"
        (matChipInputTokenEnd)="add($event)"
        [formControl]="chipCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        data-cy="programName"
      />
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let programNameSuggestedWithIdProgram of filteredItems" [value]="programNameSuggestedWithIdProgram">
        {{ hideProgramIdKeyWithReplacement(programNameSuggestedWithIdProgram) }}
      </mat-option>
      <mat-option *ngIf="notEnoughLetters === true" disabled>
        {{ i18nService._('Txt_NotEnoughLetters') }}
      </mat-option>
      <mat-option *ngIf="emptyResponse === true" disabled>
        {{ i18nService._('Txt_No_Result') }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <span *ngIf="chipList.errorState" class="flex w-full fast-search-error txt-red">
    {{ i18nService._('Error_Field_Required') }}
  </span>
</div>

<p-autoComplete
  #chipList
  *ngIf="design === 'homepage'"
  [placeholder]="hasSelectedProgram ? '' : i18nService._('Txt_placeholder_program_search')"
  (completeMethod)="filter($event.query)"
  (onSelect)="selectedProgram($event)"
  (onUnselect)="unselectedProgram($event)"
  [autofocus]="true"
  [showEmptyMessage]="hasSelectedProgram"
  [maxLength]="1"
  [multiple]="true"
  [readonly]="hasSelectedProgram"
  [emptyMessage]="i18nService._('Txt_No_Result')"
  [suggestions]="filteredItems"
  data-cy="programName"
>
</p-autoComplete>
