import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forkJoin, mergeMap, Observable, of, Subject, take, takeUntil, tap } from 'rxjs';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ValoRole } from '@commons-dto/valo-back';

import { MobileNavComponent } from './mobile-nav/mobile-nav.component';
import { DropMenuComponent } from './drop-menu/drop-menu.component';

import { FeatureFlagService } from './../../../feature-flag/feature-flag.service';
import { ChooseDialogComponent, ChooseDialogData } from './../../../dialog/components/choose-dialog/choose-dialog.component';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from './../../../dialog/components/confirmation-dialog/confirmation-dialog.component';
import { LocalStorageService } from './../../services/local-storage.service';
import { FooterContact } from '../../models/footerContact';
import { LoggedFooterResponse } from '../../models/LoggedFooterResponse';
import { Sitemap } from '../../models/Sitemap';
import { AccountAsService } from '../../services/account-as.service';
import { AccountService } from '../../services/account.service';
import { AppConfigService } from '../../services/app-config.service';
import { TokenService } from '../../services/authorisation/token.service';
import { ConfigurableParamService } from '../../services/configurable-param.service';
import { GoogleTagManagerService } from '../../services/google-tag-manager.service';
import { I18nService } from '../../services/i18n.service';
import { RoutingStateService } from '../../services/routing-state.service';
import { ToggleFeesService } from '../../services/toggle-fees.service';
import { UserRoleService } from '../../services/user-role.service';
import { SnackbarMessage } from '../../models/SnackbarMessage';
import { SnackbarMessageType } from '../../models/enums/snackbar-message-type.enum';
import { SnackbarService } from '../../services/snackbar.service';
import { ModalDisplayService } from '../../services/modal-display.service';
import { ExternalDocumentLink, ExternalDocumentLinkTypeEnum } from '../../models/external-document-link';
import { AppIconsName } from '../../../common/standalone/icon/utils/app-icon-names';
import { SelectDialogComponent } from '../../../dialog/components/select-dialog/select-dialog.component';
import { ProspectSelectDialogComponent } from '../../../dialog/components/prospect-select-dialog/prospect-select-dialog.component';
import { SelectDialogProperties } from '../../../dialog/models/SelectDialogProperties';
import { DossierProspectRouteService } from '../../../dossier-prospect/components/dossier-prospect/dossier-prospect.route.service';
import { IconComponent } from '../../../common/standalone/icon/icon.component';
import packageInfo from '../../../../../../../package.json';

export interface MenuConfigEntry {
  label?: string;
  path?: string;
  title?: string;
  externalLink?: string;
  hasAction?: boolean;
  icon?: string;
  class?: string;
  data?: { roles: Array<ValoRole> };
  subMenu?: Array<MenuConfigEntry>;
  mustBeHidden?: boolean;
  twTxtColor?: string;
  altLabel?: string;

  contacts?: {
    label: string;
    phone: string;
    email: string;
  }[];

  action?(): void;
}

export interface MenuConfig {
  label: string;
  icon: AppIconsName;
  altIcon?: AppIconsName;
  isOffNav?: boolean;
  hasExtLinks?: boolean;
  isNotDropMenu?: boolean;
  mustBeDisabled?: boolean;
  hide?: boolean;
  isSwitch?: boolean;
  path?: string;
  className?: string;
  // Weight to ordering menu.
  dropItems?: Array<MenuConfigEntry>;
  item?: MenuConfigEntry;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, DropMenuComponent, IconComponent, MobileNavComponent, AsyncPipe],
})
export class HeaderComponent implements OnInit, OnDestroy {
  /**
   * Path to prospect register route.
   *
   * @type {string}
   * @memberof RegisterFormProgramComponent
   */
  readonly prospectsRegisterPath = Sitemap.prospects.register.path;
  /**
   * isLoggedIn attribute.
   *
   * @type {boolean}
   *
   * @memberof HeaderComponent
   */
  public isLoggedIn = false;
  public isContractorOrDeveloper: boolean;
  public isContractor: boolean;
  public isContractorOrValo: boolean;
  public toggleFeesClass: AppIconsName = 'Eye';
  public externalDocumentsLink: Array<ExternalDocumentLink> = [];
  public menuConfigList: Array<MenuConfig> = [];
  public otherMenuConfigList: Array<MenuConfig> = [];
  public loggedRole: ValoRole;
  public loggedRoleName: string;
  public appVersion: string = packageInfo.version;
  /**
   * isValo attribute described if the logged-in member is a valo member
   *
   * @type {boolean}
   *
   * @memberof HeaderComponent
   */
  public isValo: boolean;
  /**
   * isConnectionAs attribute describes the logged in user is, as somebody else (login as functionality).
   *
   * @type {boolean}
   *
   * @memberof HeaderComponent
   */
  public isConnectionAs: boolean;
  /**
   * userFirstName attribute
   *
   * @type {string}
   * @memberof HeaderComponent
   */
  public userFirstName: string;
  /**
   * userLastName attribute
   *
   * @type {string}
   * @memberof HeaderComponent
   */
  public userLastName: string;
  /**
   * userEmail attribute
   *
   * @type {string}
   * @memberof HeaderComponent
   */
  public userEmail: string;
  /**
   * userCompanyLabel attribute
   *
   * @type {string}
   * @memberof HeaderComponent
   */
  public userCompanyLabel: string;
  /**
   * userCivilityLabel attribute
   *
   * @type {string}
   * @memberof HeaderComponent
   */
  public userCivilityLabel: string;
  public canSeeProspectDeclarationButton: boolean;
  public outerMenu: MenuConfig;
  /**
   * currentUrlAfterRedirectObservable$ attribute to know the current url after a redirection.
   *
   * @type {Observable<string>}
   *
   * @memberof HeaderComponent
   */
  public currentUrlAfterRedirectObservable$: Observable<string>;
  public showHideFeesTitle = 'TxT_hideFees';
  public isFeesHidden: boolean;
  public helpCenterAdress: Record<string, string> = {};
  public contactsMenuPhone: Array<MenuConfigEntry> = new Array<MenuConfigEntry>();
  isConnected: boolean;
  skipProspectPopin = false;
  dossierProspectEnabled = false;

  public loaded = false;
  /**
   * _destroy$
   *
   * @type {Subject}
   * @memberof RegisterFormProgramComponent
   */
  private readonly _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public i18nService: I18nService,
    private readonly routingStateService: RoutingStateService,
    private readonly tokenService: TokenService,
    public accountAsService: AccountAsService,
    private readonly userRoleService: UserRoleService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly localStorageService: LocalStorageService,
    private readonly appConfigService: AppConfigService,
    private readonly toggleFeesService: ToggleFeesService,
    private readonly accountService: AccountService,
    private readonly _configurableParamService: ConfigurableParamService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly snackbarService: SnackbarService,
    private readonly modalDisplayService: ModalDisplayService,
    private readonly _featureFlags: FeatureFlagService,
    private readonly dossierProspectRouteService: DossierProspectRouteService,
  ) {}

  ngOnInit(): void {
    this.menuConfigList = [];
    this.localStorageService.getItem('Prospect_Confirm_Create_Dialog_Title_skip').subscribe((shouldSkip) => {
      this.skipProspectPopin = shouldSkip === 'true';
    });

    const userCannotSeeFees$ = this.accountService.checkFeesCantBeSeen();
    this.generateMenu();
    this.accountAsService.isConnectedAsEvent$.subscribe((newState) => {
      this.isConnectionAs = newState;
      this.generateMenu();
    });

    // Chain sequence observable sequantially after each values of logged in (check if user can see fees)
    this.tokenService
      .getLoggedInObservable()
      .pipe(
        takeUntil(this._destroy$),
        tap((newState) => {
          this.isConnected = newState;
        }),
        mergeMap((isLoggedIn: boolean) => {
          return forkJoin(this._featureFlags.isEnabled('dossier-prospect')).pipe(
            tap(([dossierProspectEnabled]) => {
              this.dossierProspectEnabled = dossierProspectEnabled;
              this.extractLoggedData(isLoggedIn);
              this.loaded = true;
            }),
            mergeMap(() => {
              if (this.isConnected) {
                return userCannotSeeFees$;
              } else {
                return of(false);
              }
            }),
          );
        }),
      )
      .subscribe((userCannotSeeFees: boolean) => {
        this.isFeesHidden = userCannotSeeFees;
        this.generateMenu();
      });

    this.toggleFeesService.toggleFeesState$.pipe(takeUntil(this._destroy$)).subscribe((state) => {
      if (state) {
        this.toggleFeesClass = 'EyeSlash';
        this.showHideFeesTitle = 'TxT_showFees';
      } else {
        this.toggleFeesClass = 'Eye';
        this.showHideFeesTitle = 'TxT_hideFees';
      }
      this.generateMenu();
    });

    this._configurableParamService.loggedFooterResponse$
      .pipe(takeUntil(this._destroy$))
      .subscribe((loggedFooterData: LoggedFooterResponse) => {
        if (loggedFooterData && this.isLoggedIn) {
          this.contactsMenuPhone = [
            loggedFooterData.commercialContact,
            loggedFooterData.mandateContact,
            loggedFooterData.billingContact,
          ].map((contact: FooterContact) => {
            return {
              showLabel: true,
              label: contact.title,
              subMenu: [
                { externalLink: `tel: ${contact.phone}`, label: contact.phone },
                {
                  externalLink: `mailto: ${contact.email}`,
                  label: contact.email,
                },
              ],
              data: {
                roles: [...this.userRoleService.contractorRoles, ...this.userRoleService.developerRoles],
              },
            };
          });
          this.generateMenu();
        }
      });

    // Allow to reload this component and display the content updated (css class + content for logged users)
    this._configurableParamService.externalDocumentsLink$.subscribe((externalDocumentsLink) => {
      if (externalDocumentsLink) {
        this.externalDocumentsLink = externalDocumentsLink;
        this.generateMenu();
      }
      this.currentUrlAfterRedirectObservable$ = this.routingStateService.currentUrlAfterRedirectObservable;
    });
  }

  /**
   * checkIsHomepage.
   *
   * @param urlAfterRedirects
   *
   * @memberof HeaderComponent
   */
  public checkIsHomepage(urlAfterRedirects: string): boolean {
    return urlAfterRedirects.includes(`/${Sitemap.utils.homepage.path}`);
  }

  /**
   * backToHomepage.
   *
   * @memberof HeaderComponent
   */
  public backToHomepage(): void {
    this.router.navigate([Sitemap.utils.homepage.path]);
  }

  /**
   * backToAnyPage
   *
   * @memberof HeaderComponent
   */
  public goToPage(url: string): void {
    this.router.navigate([url]);
  }

  public toggleFeesVisibility(): void {
    this.toggleFeesService.toggle();
    this.generateMenu();
  }

  public async generateMenu(): Promise<void> {
    if (this.externalDocumentsLink && this.externalDocumentsLink.length) {
      this.loggedRoleName = this.tokenService.getRoleName();
      this.loggedRole = this.tokenService.getRole();

      const userLastNameConnectedUser = this.userLastName ? this.userLastName.slice(0, 1).toUpperCase() + '.' : '';
      const userFirstNameConnectedUser = this.userFirstName ? this.userFirstName.charAt(0).toUpperCase() + this.userFirstName.slice(1) : '';

      const menuItemsList: MenuConfig[] = [
        {
          label: 'Title_MenuDossierProspect',
          icon: `FolderOpen`,
          isOffNav: false,
          hasExtLinks: false,
          isNotDropMenu: true,
          hide: !this.dossierProspectEnabled,
          item: {
            action: () => {
              this.dossierProspectRouteService.goToList();
            },
          },
        },
        {
          label: 'Title_MenuDashboard',
          icon: `TableCells`,
          isOffNav: false,
          hasExtLinks: false,
          dropItems: [
            {
              label: 'Title_MenuDashboardOptions',
              ...Sitemap.dashboards.options,
            },
            {
              label: 'Title_MenuDashboardPreReservations',
              ...Sitemap.dashboards.preReservations,
            },
            {
              label: 'Title_MenuDashboardDossier',
              action: () => {
                this._googleTagManagerService.pushTag({ event: 'esignature_folder' });
              },
              ...Sitemap.dashboards.dossier,
            },
            {
              label: 'Title_MenuDashboardReservations',
              ...Sitemap.dashboards.reservations,
            },
            {
              label: 'Title_MenuDashboardProspects',
              ...Sitemap.dashboards.prospects,
            },
            {
              label: 'Title_MenuDashboardPrograms',
              ...Sitemap.dashboards.salesPrograms,
            },
            {
              label: 'Title_MenuDashboardSalers',
              ...Sitemap.dashboards.salers,
            },
          ],
        },
        {
          label: 'Title_MenuAdmin',
          icon: `Cog`,
          isOffNav: false,
          hasExtLinks: false,
          dropItems: [
            {
              label: 'Title_MenuAdminUsers',
              ...Sitemap.accounts.admin,
            },
            {
              label: 'Title_MenuAdminCompanies',
              ...Sitemap.companies.admin,
            },
            {
              label: 'Title_MenuAdminPrograms',
              ...Sitemap.programs.admin,
            },
            {
              label: 'Title_MenuAdminSpecialOffers',
              ...Sitemap.specialOffer.admin,
            },
          ],
        },
        {
          label: 'Title_MenuRecommendations',
          icon: `Fire`,
          isOffNav: false,
          hasExtLinks: false,
          isNotDropMenu: true,
          item: {
            action: () => {
              this.goToPage(Sitemap.utils.recommendations.path);
            },
          },
        },
        {
          label: 'Afficher/Cacher les honoraires',
          icon: this.toggleFeesClass,
          altIcon: 'EyeSlash',
          mustBeDisabled: this.isFeesHidden || this.isConnectionAs,
          isOffNav: true,
          hasExtLinks: false,
          isSwitch: true,
          dropItems: [
            {
              label: this.showHideFeesTitle,
              altLabel: 'TxT_hideFees',
              action: () => {
                this.toggleFeesVisibility();
              },
              data: {
                roles: [
                  ...this.userRoleService.contractorRoles,
                  ...this.userRoleService.independantRoles,
                  ...this.userRoleService.valoRoles,
                ],
              },
            },
          ],
        },
        {
          label: 'Title_Activities_And_Notifications',
          icon: `Bell`,
          isOffNav: true,
          hasExtLinks: false,
          dropItems: [
            {
              label: 'Title_Notifications',
              ...Sitemap.dashboards.info,
            },
            {
              label: 'Title_Activities',
              ...Sitemap.dashboards.action,
            },
          ],
        },
        {
          label: 'Title_MenuContacts',
          icon: `Phone`,
          isOffNav: true,
          hasExtLinks: false,
          dropItems: [
            {
              contacts: [
                {
                  label: 'Title_MenuCommercial',
                  phone: '01 55 38 53 35',
                  email: 'contact@valorissimo.com',
                },
                {
                  label: 'Title_MenuMandat',
                  phone: '01 55 38 53 19',
                  email: 'mandat@valorissimo.com',
                },
              ],
            },
          ],
        },
        {
          label: 'Title_MenuDocuments',
          icon: `DocumentText`,
          isOffNav: true,
          hasExtLinks: true,
          dropItems: this.externalDocumentsLink
            .filter((doc) => doc.code !== ExternalDocumentLinkTypeEnum.HELP_CENTER)
            .map((doc) => ({
              label: doc.labelId,
              externalLink: doc.link,
            })),
        },
        {
          label: 'Compte',
          icon: `User`,
          isOffNav: true,
          dropItems: [
            this.externalDocumentsLink
              .filter((doc) => doc.code === ExternalDocumentLinkTypeEnum.HELP_CENTER)
              .map((doc) => ({
                label: doc.labelId,
                externalLink: doc.link,
              }))
              .pop(),
            {
              label: this.i18nService._('Title_MenuAccountVersion', [this.appVersion]),
              hasAction: false,
              action: () => {
                return;
              },
            },
            {
              label: userLastNameConnectedUser + userFirstNameConnectedUser,
              icon: 'User',
              hasAction: false,
              action: () => {
                return;
              },
            },
            {
              label: 'Title_MenuAccountDisconnect',
              twTxtColor: 'text-red-600',
              action: () => {
                this.disconnect();
              },
            },
          ],
        },
      ];

      this.menuConfigList = this.buildMenu(menuItemsList);

      let otherMenuItemsList: MenuConfig[] = [
        {
          label: 'Title_Other_Head_Menu',
          icon: `EllipsisHorizontal`,
          isOffNav: true,
          hasExtLinks: false,
          className: 'prospectEllipsis',
          dropItems: [
            {
              label: 'Title_Header_Add_Paternity',
              mustBeHidden:
                !this.dossierProspectEnabled || !Sitemap.dossierProspects.paternity.data.roles.includes(this.tokenService.getRole()),
              action: () =>
                this.dialog.open(ChooseDialogComponent, {
                  data: {
                    title: 'Prospect_Paternity_Add_Popin_Title',
                    imageSource: '/assets/img/icon/declare-paternite.svg',
                    message: 'Prospect_Paternity_Add_Popin_Content',
                    hasHTMLMessage: true,
                    options: [
                      {
                        action: () =>
                          this.router.navigateByUrl(Sitemap.dossierProspects.paternity.path.replace(':existingProspect', 'true')),
                        icon: 'Folder',
                        title: 'Prospect_Paternity_Add_Popin_Existing',
                      },
                      {
                        action: () =>
                          this.router.navigateByUrl(Sitemap.dossierProspects.paternity.path.replace(':existingProspect', 'false')),
                        icon: 'FolderPlus',
                        title: 'Prospect_Paternity_Add_Popin_New',
                      },
                    ],
                  } as ChooseDialogData,
                }),
            },
            {
              label: `Txt_DossierProspectSimulationButton`,
              mustBeHidden:
                !this.dossierProspectEnabled || !Sitemap.dossierProspects.paternity.data.roles.includes(this.tokenService.getRole()),
              action: () => this.openSimulationModal(),
            },
          ],
        },
      ];
      otherMenuItemsList = otherMenuItemsList.filter((item) => item.dropItems.filter((dropItem) => !dropItem.mustBeHidden).length);
      if (otherMenuItemsList.length) {
        this.otherMenuConfigList = this.buildMenu(otherMenuItemsList);
      }
    }
  }

  buildMenu(allItems: MenuConfig[]): MenuConfig[] {
    const targetMenu: MenuConfig[] = [];
    allItems.forEach((menuConfig: MenuConfig) => {
      if (menuConfig.isNotDropMenu) {
        targetMenu.push(menuConfig);
      } else if (!menuConfig.item?.data || menuConfig.item?.data.roles.includes(this.loggedRole)) {
        const items = menuConfig.dropItems.filter((item) => !item.data || item.data.roles.includes(this.loggedRole));
        if (items.length) {
          menuConfig.dropItems = items;
          targetMenu.push(menuConfig);
        }
      }
    });
    return targetMenu;
  }

  /**
   * Disconnect user.
   *
   * @memberof HeaderComponent
   */
  public disconnect(): void {
    this.accountService.logout().subscribe(() => {
      this.tokenService.deleteToken();
      this.router.navigate([Sitemap.utils.login.path]);
    });
  }

  /**
   * Navigates to the appropriate path for prospect creation.
   * The navigation path depends on whether the action is triggered from a simulation
   * and whether the dossier prospect feature is enabled.
   *
   * @param {boolean} isFromSimulation - Flag to indicate if the action is triggered from a simulation.
   */
  public createProspect(isFromSimulation = false): void {
    const navigationPath = this.determineProspectPath();
    this.router.navigate([navigationPath], this.generateQueryParams(isFromSimulation));
    this._googleTagManagerService.pushTag({ event: 'prospect_declaration' });
  }

  /**
   * Determines the navigation path for creating a prospect.
   * @returns {string} - The navigation path.
   */
  private determineProspectPath(): string {
    return this.dossierProspectEnabled ? this.dossierProspectRouteService.getCreatePath() : this.prospectsRegisterPath;
  }

  /**
   * Generates query parameters for the navigation based on whether the prospect is created from a simulation.
   *
   * @param {boolean} isFromSimulation - Flag to indicate if the action is triggered from a simulation.
   * @returns {NavigationExtras} - Navigation extras with or without query parameters.
   */
  private generateQueryParams(isFromSimulation: boolean): any {
    return isFromSimulation ? { queryParams: { displaySimulation: true } } : {};
  }

  openProspectModal() {
    const data: ConfirmationDialogData = {
      title: 'Prospect_Confirm_Create_Dialog_Title',
      canBeSkipped: true,
      imageSource: '/assets/img/icon/declaration-prospect-popin.svg',
      buttonConfirm: 'Prospect_Confirm_Create_Dialog_Yes',
      message: 'Prospect_Confirm_Create_Dialog_Message',
    };
    const dialog = this.dialog.open(ConfirmationDialogComponent, { data });
    dialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((createProspect) => {
        if (createProspect) {
          this.createProspect();
        }
      });
  }

  openSimulationModal() {
    const data: ConfirmationDialogData = {
      title: 'Prospect_Simulation_Confirm_Create_Dialog_Title',
      canBeSkipped: true,
      imageSource: '/assets/img/icon/building.svg',
      buttonConfirm: 'Prospect_Simulation_Confirm_Create_Dialog_Yes',
      message: 'Prospect_Simulation_New_Or_Existant_Folder',
    };
    const dialog = this.dialog.open(ConfirmationDialogComponent, { data });
    dialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((confirmSimulation) => {
        if (confirmSimulation) {
          this.simulateExistingOrNewFolder();
        }
      });
  }

  simulateExistingOrNewFolder() {
    const data: SelectDialogProperties = {
      title: 'Prospect_Simulation_Confirm_Create_Dialog_Title',
      message: 'Prospect_Simulation_New_Or_Existant_Folder',
      imageSource: '/assets/img/icon/simulation.svg',
      options: [
        {
          label: 'Txt_Prospect_SimulationPopin_ExistingFolder',
          closingLabel: 'existing-dossier',
          action: () => {
            this.showSearchProspect();
          },
          icon: 'Folder',
        },
        {
          label: 'Txt_Prospect_SimulationPopin_NewFolder',
          closingLabel: 'new-dossier',
          action: () => {
            this.createProspect(true);
          },
          icon: 'FolderPlus',
        },
      ],
    };

    this.dialog.open(SelectDialogComponent, {
      autoFocus: false,
      width: '40vw',
      panelClass: 'dialog-container-new',
      data: data,
    });
  }

  showSearchProspect() {
    const data: SelectDialogProperties = {
      title: 'Txt_Prospect_SimulationPopin_FindYourProspect',
      message: 'Txt_Prospect_SimulationPopin_Selection',
      imageSource: '/assets/img/icon/building.svg',
      button: 'Txt_Prospect_Simulation_Button',
    };

    const dialogRef = this.dialog.open(ProspectSelectDialogComponent, {
      data: data,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe((result) => {
        if (result?.dossierProspectId) {
          this.dossierProspectRouteService.goToSimulation(result.dossierProspectId);
        }
      });
  }

  /**
   * ngOnDestroy method
   *
   * @memberof HeaderComponent
   */
  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  logoutAs() {
    this.accountAsService.logoutAs().subscribe(() => {
      this.accountAsService.changeState(false);
      this.isConnectionAs = false;
      this.isValo = true;
      this.accountService.getConnectedUser().subscribe((loginResponse) => {
        this.tokenService.setOldToken(loginResponse);
        this.router.navigateByUrl(Sitemap.utils.homepage.path);
        this.isValo = this.userRoleService.isValo();

        const message: SnackbarMessage = {
          text: this.i18nService._('Success_SnackBar_LogoutAs'),
          type: SnackbarMessageType.Info,
        };
        this.snackbarService.sendMessage(message);
      });
    });
  }

  displayModalPanel(): void {
    this.modalDisplayService.changeModalDisplay(true);
  }

  private extractLoggedData(isLoggedIn: boolean) {
    this.isLoggedIn = isLoggedIn;
    this.isContractorOrDeveloper = isLoggedIn && (this.userRoleService.isContractor() || this.userRoleService.isDeveloper());
    this.isContractorOrValo = isLoggedIn && (this.userRoleService.isContractor() || this.userRoleService.isValo());
    this.isContractor = isLoggedIn && this.userRoleService.isContractor();
    this.isValo = this.userRoleService.isValo();
    this.isConnectionAs = this.tokenService.isConnectionAs();
    // List of valoRole name need to see stepFour
    const valoAccountList = [
      this.appConfigService.appConfig.roles.valoSystem,
      this.appConfigService.appConfig.roles.valoAdminSuper,
      this.appConfigService.appConfig.roles.valoAdminSimple,
      this.appConfigService.appConfig.roles.valoRcvSuper,
      this.appConfigService.appConfig.roles.valoRcvSimple,
    ];

    this.userFirstName = this.tokenService.getLastName();
    this.userLastName = this.tokenService.getFirstName() ? this.tokenService.getFirstName().toUpperCase() : undefined;
    this.userEmail = this.tokenService.getEmail();
    this.userCivilityLabel = this.tokenService.getCivilityLabel();
    this.userCompanyLabel = this.tokenService.getCompanyTradingName();

    const prescriptorOrIndependantRoles = [
      this.appConfigService.appConfig.roles.independantNetworkHeader,
      this.appConfigService.appConfig.roles.independantNetworkLeader,
      this.appConfigService.appConfig.roles.independant,
      this.appConfigService.appConfig.roles.contractorHyper,
      this.appConfigService.appConfig.roles.contractorSuper,
      this.appConfigService.appConfig.roles.contractorSimple,
    ];

    if (this.dossierProspectEnabled) {
      this.canSeeProspectDeclarationButton =
        this.tokenService.checkIsLoggedIn() && prescriptorOrIndependantRoles.includes(this.tokenService.getRoleName());
    } else {
      // Developer, contractor and valo system, admin (super and simple), rcv (super and simple), independantNetworkHeader,  independantNetworkLeader, independant can see prospect declaration button
      this.canSeeProspectDeclarationButton =
        valoAccountList.includes(this.tokenService.getRoleName()) ||
        this.isContractorOrDeveloper ||
        prescriptorOrIndependantRoles.includes(this.tokenService.getRoleName());
    }
  }
}
