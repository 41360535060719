import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLinkActive, RouterLink } from '@angular/router';
import { catchError, take } from 'rxjs';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgIf } from '@angular/common';

import { SignatureChoiceComponent } from '../signature-choice/signature-choice.component';
import { FormHeaderComponent } from '../form-header/form-header.component';
import { SideInfoComponent } from '../side-info/side-info.component';

import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { Sitemap } from '../../../utils/models/Sitemap';
import { I18nService } from '../../../utils/services/i18n.service';
import { FormViewResponse, Status } from '../../models/FormResponse';
import { DocumentHttpResponse, SignatureDocumentService } from '../../services/signature-document.service';
import { SignatureService } from '../../services/signature.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { Dossier } from '../../models/Dossier';
import { SignatureStatus } from '../../models/signature-status.enum';
import { SignatureType } from '../../models/signature-type.enum';
import { IconComponent } from '../../../common/standalone/icon/icon.component';
import { BoxBackButtonComponent } from '../../../common/shared/box-back-button/box-back-button.component';

@Component({
  selector: 'app-synthesis',
  templateUrl: './synthesis.component.html',
  styleUrls: ['./synthesis.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    BoxBackButtonComponent,
    SideInfoComponent,
    FormHeaderComponent,
    NgxExtendedPdfViewerModule,
    MatProgressSpinnerModule,
    RouterLinkActive,
    RouterLink,
    IconComponent,
  ],
})
export class SynthesisComponent implements OnInit {
  dossierId: number;
  formView: FormViewResponse;
  loadingPdf = true;
  pdf: Blob;
  pdfId: number;
  hasError = false;
  dialogRef: MatDialogRef<SignatureChoiceComponent>;
  draftState: boolean;

  constructor(
    private readonly signatureDocumentService: SignatureDocumentService,
    public readonly i18nService: I18nService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly signatureService: SignatureService,
    private readonly dialog: MatDialog,
    private readonly snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.dossierId = Number(this.route.snapshot.params.signatureId);
    this.formView = this.route.snapshot.data.data.formview;
    this.draftState = (this.route.snapshot.data.data.dossier as Dossier).status === SignatureStatus.DRAFT;
    this.getContract();
  }

  private getContract() {
    const contractArray = this.formView.documents.filter((doc) => doc.documentTypeCode === 'CNT');
    if (this.formView.status === Status.DRAFT || contractArray.length < 1) {
      this.launchContractCreation();
    } else {
      this.pdfId = Number(contractArray[0].id);
      this.getDocument(contractArray[0].id);
    }
  }

  private launchContractCreation(): void {
    this.signatureService
      .launchContractCreation(this.dossierId)
      .pipe(
        catchError((err) => {
          this.handleError();
          throw err;
        }),
      )
      .subscribe((document: DocumentHttpResponse) => {
        this.pdfId = document.id;
        this.getDocument(this.pdfId.toString());
      });
  }

  private getDocument(documentId: string): void {
    this.signatureDocumentService
      .getDocumentAsBlob(documentId)
      .pipe(
        catchError((err) => {
          this.handleError();
          throw err;
        }),
      )
      .subscribe((pdf) => {
        this.loadingPdf = false;
        this.pdf = pdf;
      });
  }

  chooseSignatureType() {
    this.dialogRef = this.dialog.open(SignatureChoiceComponent, {
      data: {
        dossierId: this.dossierId,
        pdfId: this.pdfId,
      },
    });
    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((choice: SignatureType) => {
        if (choice) {
          if (choice === SignatureType.PAPER) {
            saveAs(
              this.pdf,
              `Contrat ${this.formView.contextData.program.name} ${this.formView.contextData.lot.number} ${DateTime.now().toFormat(
                'dd-MM-yy',
              )}.pdf`,
            );
          }
          this.router.navigate([Sitemap.signature.progress.path.replace(':signatureId', this.dossierId.toString())]);
        }
      });
  }

  goBack(): () => void {
    return () => {
      this.router.navigate([
        this.draftState
          ? Sitemap.signature.dashboard.path.replace(/:signatureId/, this.dossierId.toString())
          : Sitemap.signature.progress.path.replace(/:signatureId/, this.dossierId.toString()),
      ]);
    };
  }

  handleError() {
    this.loadingPdf = false;
    this.hasError = true;
    const message = `${this.i18nService._('txt_synthesis_pdf_error_1')}
                    ${this.i18nService._('txt_synthesis_pdf_error_2')}
                    ${this.i18nService._('txt_synthesis_pdf_error_3')}`;
    const errorMessage: SnackbarMessage = {
      text: message,
      type: SnackbarMessageType.Error,
    };
    this.snackbarService.sendMessage(errorMessage);
  }
}
