<div class="flex flex-col">
  <app-sticky-header-form [titleText]="i18nService._(title)" [goBackUrl]="goBackPath"></app-sticky-header-form>

  <div class="grid grid-cols-10 pt-16">
    <div class="col-span-10 lg:col-span-8 lg:col-start-2 xl:col-span-6 xl:col-start-3 flex flex-col pt-16">
      <form [formGroup]="specialOfferForm" (ngSubmit)="onSubmit()">
        <mat-stepper
          orientation="vertical"
          #stepper
          [linear]="true"
          [@.disabled]="true"
          appMatVerticalStepper
          (selectionChange)="selectionChange($event)"
          class="mb-5 header-desactivate"
        >
          <ng-template matStepperIcon="edit">
            <span class="icon-validate-small"></span>
          </ng-template>

          <!-- Form Condition -->
          <mat-step
            [completed]="(specialOfferForm.get('conditions').valid && stepOneSubmit) || disabledForm || specialOffer?.isImported"
            formGroupName="conditions"
            state="conditions"
            [stepControl]="specialOfferForm.get('conditions')"
          >
            <ng-template matStepLabel>
              {{ i18nService._('Txt_specialOffer_register_step1') }}
            </ng-template>
            <app-step-one-special-offer-form
              [specialOfferForm]="specialOfferForm"
              [submit]="stepOneSubmit"
              [amendment]="specialOffer?.amendment"
              [amendmentWithFusionFields]="specialOffer?.amendmentWithFusionFields"
              class="flex flex-col"
            >
            </app-step-one-special-offer-form>
            <div class="flex w-1/5">
              <button type="button" class="mt-3" mat-stroked-button (click)="submitStepOne()" color="primary" data-cy="nextStep">
                {{ i18nService._('Txt_Button_NextStep') }}
              </button>
            </div>
          </mat-step>

          <!-- Form Programmes -->
          <mat-step
            [completed]="(stepTwoSubmit && specialOfferForm.get('programs').valid) || disabledForm || specialOffer?.isImported"
            formGroupName="programs"
            state="programs"
            [stepControl]="specialOfferForm.get('programs')"
          >
            <ng-template matStepLabel>
              <div
                [ngClass]="{
                  'txt-error': specialOfferForm.get('programs').invalid && stepTwoSubmit
                }"
              >
                {{ i18nService._('Txt_specialOffer_register_step2') }}
              </div>
              <mat-error *ngIf="specialOfferForm.get('programs').invalid && stepTwoSubmit"
                >{{ i18nService._('Error_field_programIdList') }}
              </mat-error>
            </ng-template>
            <div class="flex flex-col">
              <app-step-two-special-offer-form
                [specialOfferForm]="specialOfferForm"
                [submit]="stepTwoSubmit"
                [disabledForm]="disabledForm"
                (emptyDataEvent)="emptyData($event)"
              >
              </app-step-two-special-offer-form>
              <div class="flex w-1/5" *ngIf="!stepTwoEmptyData">
                <button type="button" class="mt-3" mat-stroked-button (click)="submitStepTwo()" color="primary" data-cy="nextStep">
                  {{ i18nService._('Txt_Button_NextStep') }}
                </button>
              </div>
            </div>
          </mat-step>

          <!-- Form Lot -->
          <mat-step
            [completed]="specialOfferForm.get('lots').valid || disabledForm || specialOffer?.isImported"
            formGroupName="lots"
            state="lots"
            [stepControl]="specialOfferForm.get('lots')"
            [aria-labelledby]="stepTwoEmptyData ? 'disabled_af' : null"
          >
            <ng-template matStepLabel>
              <div
                [ngClass]="{
                  'txt-error': specialOfferForm.get('lots').invalid && stepThreeSubmit
                }"
              >
                {{ i18nService._('Txt_specialOffer_register_step3') }}
              </div>
              <mat-error *ngIf="specialOfferForm.get('lots').invalid && stepThreeSubmit"
                >{{ i18nService._('Error_field_lotIdList') }}
              </mat-error>
            </ng-template>
            <app-step-three-special-offer-form
              [specialOfferForm]="specialOfferForm"
              [specialOfferId]="specialOfferId"
              [disabledForm]="disabledForm"
              [submit]="stepThreeSubmit"
            >
            </app-step-three-special-offer-form>
          </mat-step>
        </mat-stepper>

        <div class="flex flex-row" *ngIf="true">
          <div class="my-5 mr-4">
            <button
              mat-flat-button
              color="primary"
              type="submit"
              data-cy="submitCreate"
              [disabled]="this.specialOfferForm.invalid || disabledForm"
            >
              {{ i18nService._(textButton) }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
