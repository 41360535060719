import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PaternityStatusCode } from 'libs/commons-dto/src/lib/dossier-prospect/paternity/paternity-status-code.enum';
import { CompleteDossierResponse } from '@commons-dto/dossier-prospect';

import { DossierProspectService } from './dossier-prospect.service';

import { sanitize } from '../../utils/services/utils/file-utils';
import { MandatedDeveloperCompanyData } from './../../utils/models/MandatedDeveloperCompanyData';
import { ProgramResponse } from './../../utils/models/ProgramResponse';
import { AutoCompleteService } from '../../common/form/auto-complete.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyProgramSearch implements AutoCompleteService {
  companies: MandatedDeveloperCompanyData[];
  selectedCompanyId: number;
  programs: ProgramResponse[];

  constructor(private _prospect: DossierProspectService) {
    this._prospect.devCompaniesSub.subscribe((companies) => {
      this.companies = companies;
      this.refinePrograms();
    });
  }

  updateCompany(id: number) {
    if (this.selectedCompanyId === id) {
      return false;
    }
    this.selectedCompanyId = id;
    this.refinePrograms();
    return true;
  }

  refinePrograms() {
    if (!this.companies || !this.selectedCompanyId) {
      return;
    }
    const pendingAndAcceptedPaternities = this.getPendingAndAcceptedPaternitiesIds();
    const selectedCompanyPrograms = this.getSelectedCompanyPrograms();

    this.programs = this.getPaternityEligiblePrograms(selectedCompanyPrograms, pendingAndAcceptedPaternities);
  }

  findData(filter: string): Observable<ProgramResponse[]> {
    filter = sanitize(filter);
    const results = this.programs.filter((program) => sanitize(program.programName).match(`${filter}`));
    return of(results);
  }

  private getPendingAndAcceptedPaternitiesIds(): number[] {
    const dossier = this._prospect.getCurrentDossier() as CompleteDossierResponse;
    return (
      dossier?.summaryPaternities
        .filter(
          (paternite) =>
            paternite.statuses.includes(PaternityStatusCode.PENDING) || paternite.statuses.includes(PaternityStatusCode.ACCEPTED),
        )
        .map((paternity) => paternity.programId) || []
    );
  }

  private getSelectedCompanyPrograms(): ProgramResponse[] {
    return this.companies.find((company) => company.id === this.selectedCompanyId)?.programs;
  }

  private getPaternityEligiblePrograms(selectedCompanyPrograms: ProgramResponse[], programsToExclude: number[]): ProgramResponse[] {
    return selectedCompanyPrograms
      ?.filter((program) => !programsToExclude.includes(program.id))
      .map((program) => ({ ...program, label: program.programName }));
  }
}
