<div class="grid grid-cols-8 gap:4 lg:gap-6 my-4">
  <div class="col-span-2">
    <mat-form-field class="fullW flex w-full">
      <mat-label>{{ i18nService._('Txt_Placeholder_Search') }}</mat-label>
      <input [(ngModel)]="filterValue" (ngModelChange)="textFilterSubject.next($event)" matInput />
    </mat-form-field>
  </div>

  <div *ngIf="type !== CompanyType.INDEPENDANT">
    <mat-form-field class="fullW flex w-full my-0">
      <mat-label>{{ i18nService._('Txt_Placeholder_ProfessionalCategory') }}</mat-label>
      <mat-select (selectionChange)="onProfessionalCategoryFilterChange($event)" multiple [value]="i18nFilter.ProfessionalCategories">
        <mat-option *ngFor="let professionalCategory of professionalCategories" [value]="professionalCategory.id">
          {{ i18nService._(professionalCategory.label) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="type === CompanyType.CONTRACTOR">
    <mat-form-field class="fullW flex w-full my-0">
      <mat-label>{{ i18nService._('Txt_Placeholder_TCard') }}</mat-label>
      <mat-select (selectionChange)="onTCardFilterChange($event.value)" multiple [value]="i18nFilter.tCard">
        <mat-option *ngFor="let tCard of tCardFilters" [value]="tCard.id">
          {{ i18nService._(tCard.label) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="type === CompanyType.CONTRACTOR">
    <mat-form-field class="fullW flex w-full my-0">
      <mat-label>{{ i18nService._('Txt_Placeholder_Mandat') }}</mat-label>
      <mat-select (selectionChange)="onMandatFilterChange($event.value)" multiple [value]="i18nFilter.mandat">
        <mat-option *ngFor="let mandat of mandatsFilters" [value]="mandat.id">
          {{ i18nService._(mandat.label) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="fullW flex w-full my-0">
      <mat-label>{{ i18nService._('Txt_AccountsTrackingTable_Column_Active') }}</mat-label>
      <mat-select (selectionChange)="onActiveFilterChange($event)" multiple [value]="i18nFilter.companyActive">
        <mat-option *ngFor="let active of activeFilters" [value]="active.value">
          {{ i18nService._(active.label) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="fullW flex w-full my-0">
      <mat-label>{{ i18nService._('Txt_AccountsTrackingTable_Column_Status') }}</mat-label>
      <mat-select (selectionChange)="onStatusFilterChange($event.value)" multiple [value]="i18nFilter.CompanyStatuses">
        <mat-option *ngFor="let status of statuses" [value]="status.id">
          {{ i18nService._(status.label) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="type !== CompanyType.INDEPENDANT">
    <mat-form-field class="fullW flex w-full my-0">
      <mat-label>{{ i18nService._('Txt_ContractorTrackingTable_Column_ValoResponsible') }}</mat-label>
      <input [(ngModel)]="i18nFilter.responsibleFullName" (ngModelChange)="onValoResponsibleFilterChange($event)" matInput />
    </mat-form-field>
  </div>
</div>

<div class="tracking-mat-table">
  <app-grid-tracking-table
    [columnDefs]="columnDefs"
    [tableLoader]="companiesTrackingTableService"
    [pageSize]="pageSize"
    [defaultSortColunm]="sortColumn"
    [defaultSortDirection]="sortDirection"
    [textFilter]="textFilterValue"
    [i18nFilter]="i18nFilter"
    [tableHeight]="'inherit'"
  >
  </app-grid-tracking-table>
</div>
