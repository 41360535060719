<ng-template #priceRef let-lotDetail>
  <div class="lot-comparator-glance lot-comparator-glance-price">
    {{ basicFormatsService.formatCurrency(lotDetail.commercialLotSellingPriceIT) }}
  </div>
</ng-template>
<ng-template #roomAreaPlanRef let-lotDetail>
  <div class="lot-comparator-glance lot-comparator-glance-room-area-plan">
    <div>
      <div class="lot-comparator-glance-room-area">
        <span class="rooms">{{ lotDetail.rooms }}</span
        >&nbsp;|&nbsp;<span class="living-space">{{ this.basicFormatsService.formatArea(lotDetail.livingSpace) }}</span>
      </div>
      <mat-icon
        [svgIcon]="'plan'"
        class="plan-icon material-icons-outlined size-22"
        (click)="openDocumentInBrowser($event, lotDetail.lotPlan)"
      ></mat-icon>
    </div>
  </div>
</ng-template>
<ng-template #specialofferRef let-lotDetail>
  <div
    *ngIf="(lotMeta['specialOffer'] | as: ComparatorMetaPartial)?.show"
    class="lot-comparator-glance lot-comparator-glance-special-offer"
  >
    <app-tags
      *ngIf="lotDetail.specialOffer; else emptyData"
      [ngClass]="specialOfferTags.ngClass"
      [tagOption]="specialOfferTags.tagOption"
    ></app-tags>
  </div>
</ng-template>
<ng-template #taxationRef let-lotDetail>
  <div class="lot-comparator-glance lot-comparator-glance-taxation" *ngIf="(lotMeta['taxationsLabels'] | as: ComparatorMetaPartial)?.show">
    <ng-container *ngIf="lotDetail.taxationsLabels && lotDetail.taxationsLabels.length; else emptyData">
      <app-tags *ngFor="let tag of lotDetail.fiscalityTags" [ngClass]="tag.ngClass" [tagOption]="tag.tagOption"></app-tags>
    </ng-container>
  </div>
</ng-template>
<ng-template #profitabilityRef let-lotDetail>
  <div
    class="lot-comparator-glance lot-comparator-glance-profitablity"
    *ngIf="(lotMeta['profitability'] | as: ComparatorMetaPartial)?.show"
  >
    <span *ngIf="lotDetail.profitability; else emptyData" class="text-center">
      {{ i18nService._('TXT_comparator_glance_profitability', [basicFormatsService.formatPercentage(lotDetail.profitability / 100)]) }}
    </span>
  </div>
</ng-template>
<ng-template #actableRef let-lotDetail>
  <div class="lot-comparator-glance lot-comparator-glance-actable" *ngIf="(lotMeta['actable'] | as: ComparatorMetaPartial)?.show">
    <div *ngIf="lotDetail.actable; else emptyData">
      <mat-icon class="material-icons-outlined size-18">check</mat-icon>
      <span>{{ i18nService._('Txt_Page_Program_ProgramActableProgram') | uppercase }}</span>
    </div>
  </div>
</ng-template>
<ng-template #delivryDateRef let-lotDetail>
  <div class="lot-comparator-glance lot-comparator-glance-deliveryDate" *ngIf="(lotMeta['deliveryDate'] | as: ComparatorMetaPartial)?.show">
    <span>
      {{ getDeliveryDate(lotDetail) }}
    </span>
  </div>
</ng-template>
<ng-template #annexeRef let-lotDetail>
  <div
    class="lot-comparator-glance lot-comparator-glance-annexes"
    *ngIf="(lotMeta['annexes'] | as: ComparatorMetaPartial)?.show"
    [ngClass]="{
      'empty-annexes': !lotDetail.annexes || !lotDetail.annexes.length
    }"
  >
    <ng-container *ngIf="lotDetail.annexes && lotDetail.annexes.length; else emptyData">
      <app-tags *ngFor="let tag of getAnnexesTags(lotDetail)" [ngClass]="tag.ngClass" [tagOption]="tag.tagOption"></app-tags>
    </ng-container>
  </div>
</ng-template>

<ng-template #lotStrongPointsRef let-lotDetail>
  <div
    class="lot-comparator-glance lot-comparator-glance-strong-points"
    *ngIf="(lotMeta['lotStrongPoints'] | as: ComparatorMetaPartial)?.show"
    [ngClass]="{
      'empty-values': !lotDetail.lotStrongPoints || !lotDetail.lotStrongPoints.length
    }"
  >
    <app-strong-point-list [strongPoints]="lotDetail.lotStrongPoints"></app-strong-point-list>
  </div>
</ng-template>

<ng-template #emptyData>
  <span class="empty-values">-</span>
</ng-template>

<div class="comparator-lot-separator separator-small" *ngIf="lotMeta?.atLeastOneShow">
  <div>{{ i18nService._('Txt_comparator_lot_glance_title') }}</div>
</div>
<app-lot-comparator-meta-line
  class="border-radius-bottom"
  *ngIf="lotMeta"
  [lotDetailToCompare]="lotDetailToCompare"
  [withoutTitle]="true"
  [lotMetaWrapper]="lotMeta"
></app-lot-comparator-meta-line>
