import { Component } from '@angular/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-formly-textarea',
  templateUrl: './formly-textarea.component.html',
  styleUrls: ['./formly-textarea.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, FormlyModule],
})
export class FormlyTextareaComponent extends FieldType {
  constructor(public i18nService: I18nService) {
    super();
  }
}
