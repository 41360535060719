/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FromMapper, FromMapperEnum, FromMapperItem } from '../model/search-program-data-where-mapper';
import { SearchPageDisplayType, SearchProgramData, SearchProgramorderBy } from '../model/search-program-data';
import { SearchProgramDataWhere } from '../model/search-program-data-where';

import { LOCALISATION_LABELS } from '../../utils/models/app-constant';
import { LocationData } from '../../utils/models/LocationData';
import { AppConfigService } from '../../utils/services/app-config.service';
import { GoogleTagManagerService } from '../../utils/services/google-tag-manager.service';

export const ORDER_BY_MAP = {
  ASC: 1,
  DESC: 0,
  0: 'DESC',
  1: 'ASC',
};

@Injectable({
  providedIn: 'root',
})
export class SearchFormUtilsService {
  private readonly _searchFilter: SearchProgramData = new SearchProgramData();

  get searchFilter(): SearchProgramData {
    return this._searchFilter;
  }

  private readonly _checkDisableSubject: BehaviorSubject<SearchProgramDataWhere> = new BehaviorSubject(new SearchProgramDataWhere());

  get checkDisableSubject(): Observable<SearchProgramDataWhere> {
    return this._checkDisableSubject.asObservable();
  }

  private readonly _searchLaunchByMapSubject: Subject<{
    bbox: string;
    priority: string;
    where: SearchProgramDataWhere;
  }> = new Subject();

  get searchLaunchByMapSubject(): Observable<{ bbox: string; priority: string; where: SearchProgramDataWhere }> {
    return this._searchLaunchByMapSubject.asObservable();
  }

  private readonly _searchLaunchSubject: Subject<SearchProgramData> = new Subject();

  get searchLaunchSubject(): Observable<SearchProgramData> {
    return this._searchLaunchSubject.asObservable();
  }

  private readonly _searchFilterSubject: BehaviorSubject<SearchProgramData>;

  get searchFilterSubject(): Observable<SearchProgramData> {
    return this._searchFilterSubject.asObservable();
  }

  constructor(
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly _appConfigService: AppConfigService,
  ) {
    this._searchFilterSubject = new BehaviorSubject<SearchProgramData>(this.initialiseFilter());
  }

  get toMapper(): any {
    return {
      budget: {
        minBudget: 'bgtmi',
        maxBudget: 'bgtma',
      },
      programTypeIds: 'ptid',
      nbRooms: 'nbr',
      areaTypeIds: 'atid',
      nbFloors: 'nbf',
      lotOrientationIds: 'loid',
      secondaryLotTypeIds: 'sltid',
      deliveryDateMonth: 'ddm',
      developerCompany: 'devCompany',
      localisations: {
        cities: 'locc',
        departments: 'locd',
        regions: 'locr',
        agglomerations: 'loca',
      },
      taxation: 'tax',
      taxationZone: 'taxz',
      profitability: 'prof',
      rent: {
        minRent: 'rmi',
        maxRent: 'rma',
      },
      isActable: 'isac',
      fees: 'fees',
      hasSpecialOffer: 'so',
      isHighStake: 'hs',
      isNew: 'new',
      programId: 'pid',
      bbox: 'bbox',
      priority: 'priority',
      parkingSecondaryLotTypeIds: 'psltid',
      storageSecondaryLotTypeIds: 'ssltid',
    };
  }

  get fromMapper(): FromMapper {
    return {
      bgtmi: {
        type: FromMapperEnum.Object,
        subType: FromMapperEnum.number,
        mainField: 'budget',
        subField: 'minBudget',
      },
      bgtma: {
        type: FromMapperEnum.Object,
        subType: FromMapperEnum.number,
        mainField: 'budget',
        subField: 'maxBudget',
      },
      ptid: {
        type: FromMapperEnum.Array,
        mainField: 'programTypeIds',
        subType: FromMapperEnum.number,
      },
      nbr: {
        type: FromMapperEnum.Array,
        mainField: 'nbRooms',
        subType: FromMapperEnum.string,
      },
      atid: {
        type: FromMapperEnum.Array,
        mainField: 'areaTypeIds',
        subType: FromMapperEnum.number,
      },
      nbf: {
        type: FromMapperEnum.Array,
        mainField: 'nbFloors',
        subType: FromMapperEnum.string,
      },
      loid: {
        type: FromMapperEnum.Array,
        mainField: 'lotOrientationIds',
        subType: FromMapperEnum.number,
      },
      sltid: {
        type: FromMapperEnum.Array,
        mainField: 'secondaryLotTypeIds',
        subType: FromMapperEnum.number,
      },
      ddm: {
        type: FromMapperEnum.number,
        mainField: 'deliveryDateMonth',
      },
      devCompany: {
        type: FromMapperEnum.number,
        mainField: 'developerCompany',
      },
      locc: {
        type: FromMapperEnum.localisation,
        mainField: 'localisations',
        subType: FromMapperEnum.Array,
        subField: 'cities',
      },
      locd: {
        type: FromMapperEnum.localisation,
        mainField: 'localisations',
        subType: FromMapperEnum.Array,
        subField: 'departments',
      },
      locr: {
        type: FromMapperEnum.localisation,
        mainField: 'localisations',
        subType: FromMapperEnum.Array,
        subField: 'regions',
      },
      loca: {
        type: FromMapperEnum.localisation,
        mainField: 'localisations',
        subType: FromMapperEnum.Array,
        subField: 'agglomerations',
      },
      tax: {
        type: FromMapperEnum.number,
        mainField: 'taxation',
      },
      taxz: {
        type: FromMapperEnum.number,
        mainField: 'taxationZone',
      },
      prof: {
        type: FromMapperEnum.string,
        mainField: 'profitability',
      },
      rmi: {
        type: FromMapperEnum.Object,
        subType: FromMapperEnum.number,
        mainField: 'rent',
        subField: 'minRent',
      },
      rma: {
        type: FromMapperEnum.Object,
        subType: FromMapperEnum.number,
        mainField: 'rent',
        subField: 'maxRent',
      },
      isac: {
        type: FromMapperEnum.boolean,
        mainField: 'isActable',
      },
      fees: {
        type: FromMapperEnum.string,
        mainField: 'fees',
      },
      so: {
        type: FromMapperEnum.boolean,
        mainField: 'hasSpecialOffer',
      },
      hs: {
        type: FromMapperEnum.boolean,
        mainField: 'isHighStake',
      },
      new: {
        type: FromMapperEnum.boolean,
        mainField: 'isNew',
      },
      pid: {
        type: FromMapperEnum.number,
        mainField: 'programId',
      },
      bbox: {
        type: FromMapperEnum.string,
        mainField: 'bbox',
      },
      priority: {
        type: FromMapperEnum.string,
        mainField: 'priority',
      },
      ssltid: {
        type: FromMapperEnum.Array,
        mainField: 'storageSecondaryLotTypeIds',
        subType: FromMapperEnum.number,
      },
      psltid: {
        type: FromMapperEnum.Array,
        mainField: 'parkingSecondaryLotTypeIds',
        subType: FromMapperEnum.number,
      },
    };
  }

  get filterFilledFuncions(): {
    [key: string]: (where: SearchProgramDataWhere) => boolean;
  } {
    return {
      budget: (where) => {
        if (!where.budget) {
          return false;
        }

        return !!where.budget.minBudget || where.budget.maxBudget < this._appConfigService.getAppConfig().budgetCeil;
      },
      programTypeIds: (where) => !!(where.programTypeIds && where.programTypeIds.length),
      areaTypeIds: (where) => !!(where.areaTypeIds && where.areaTypeIds.length),
      nbFloors: (where) => !!(where.nbFloors && where.nbFloors.length),
      lotOrientationIds: (where) => !!(where.lotOrientationIds && where.lotOrientationIds.length),
      parkingSecondaryLotTypeIds: (where) => !!(where.parkingSecondaryLotTypeIds && where.parkingSecondaryLotTypeIds.length),
      storageSecondaryLotTypeIds: (where) => !!(where.storageSecondaryLotTypeIds && where.storageSecondaryLotTypeIds.length),
      deliveryDateMonth: (where) => where.deliveryDateMonth >= -1,
      developerCompany: (where) => !!where.developerCompany,
      taxationZone: (where) => !!where.taxationZone,
      profitability: (where) => !!where.profitability,
      rent: (where) => {
        if (!where.rent) {
          return false;
        }

        return !!where.rent.minRent || where.rent.maxRent < this._appConfigService.getAppConfig().rentCeil;
      },
      isActable: (where) => where.isActable,
      fees: (where) => !!where.fees,
      hasSpecialOffer: (where) => where.hasSpecialOffer,
      isHighStake: (where) => where.isHighStake,
      isNew: (where) => where.isNew,
    };
  }

  private static _processToSimpleArray(key: string, array: any[]): any {
    return array && array.length && { [key]: array.join(',') };
  }

  private static _processToSimpleValue(key: string, value: string | number): any {
    return { [key]: `${value}` };
  }

  private static _processToObject(objectMapper: any, value: any): any {
    const filterChain = {};
    Object.keys(value).forEach((property) => {
      // eslint-disable-next-line no-prototype-builtins
      if (objectMapper.hasOwnProperty(property)) {
        const data = value[property];
        if (data !== undefined && data !== null) {
          if (data instanceof Array && data.length && (typeof data[0] === 'number' || typeof data[0] === 'string')) {
            Object.assign(filterChain, SearchFormUtilsService._processToSimpleArray(objectMapper[property], data));
          }
          if (typeof data === 'number' || typeof data === 'string') {
            Object.assign(filterChain, SearchFormUtilsService._processToSimpleValue(objectMapper[property], data));
          }
          if (typeof data === 'boolean') {
            Object.assign(filterChain, SearchFormUtilsService._processToSimpleValue(objectMapper[property], data ? 1 : 0));
          }
          if (data instanceof Object && !(data instanceof Array)) {
            SearchFormUtilsService._processToObject(objectMapper[property], data);
          }
        }
      }
    });

    return filterChain;
  }

  private static _processFromObj(
    searchProgramDataWhere: SearchProgramDataWhere,
    from: FromMapperItem,
    filterChain: { [p: string]: string },
    filterChainKey: string,
  ) {
    searchProgramDataWhere[from.mainField] = searchProgramDataWhere[from.mainField] || {};
    if (from.subField) {
      if (from.subType === FromMapperEnum.Array) {
        searchProgramDataWhere[from.mainField][from.subField] = filterChain[filterChainKey].split(',');
      }
      if (from.subType === FromMapperEnum.number) {
        searchProgramDataWhere[from.mainField][from.subField] = Number(filterChain[filterChainKey]);
      }
      if (from.subType === FromMapperEnum.string) {
        searchProgramDataWhere[from.mainField][from.subField] = filterChain[filterChainKey];
      }
    }
  }

  private static _processFromArray(data: string[], subType: FromMapperEnum) {
    if (subType === FromMapperEnum.number) {
      return data.map(Number);
    }

    return data;
  }

  setSearchFilter(searchProgramData: SearchProgramData, waitForSearch = false) {
    searchProgramData.waitForSearch = waitForSearch;
    Object.assign(this._searchFilter, searchProgramData);
    this._searchFilterSubject.next(this._searchFilter);
  }

  reinitialiseFilter(waitForSearch = false) {
    const searchProgramData = new SearchProgramData();
    searchProgramData.where.budget = {
      minBudget: 0,
      maxBudget: this._appConfigService.getAppConfig().budgetCeil,
    };
    searchProgramData.waitForSearch = waitForSearch;
    Object.assign(this._searchFilter, searchProgramData);
    this._searchFilterSubject.next(this._searchFilter);
  }

  dispatchSearchByMapEvent(bbox: string, priority: string, where: SearchProgramDataWhere): void {
    this._searchLaunchByMapSubject.next({ bbox, priority, where });
  }

  initialiseFilter(): SearchProgramData {
    const searchProgramData = new SearchProgramData();
    searchProgramData.where.budget = {
      minBudget: 0,
      maxBudget: this._appConfigService.getAppConfig().budgetCeil,
    };

    return searchProgramData;
  }

  parseSearchProgramToFilterChain(filterData: SearchProgramData): any {
    const mapTo = this.mapFilterDataToFilterChain(filterData.where);
    mapTo.order = filterData.order.label;
    mapTo.direction = ORDER_BY_MAP[filterData.order.direction];
    this._searchLaunchSubject.next(filterData);

    return mapTo;
  }

  parseSearchProgramFromFilterChain(filterData: any, orderBy = null, searchDataLimit = null): SearchProgramData {
    const searchProgramData = {
      where: this.mapFilterDataFromFilterChain(filterData),
      order:
        filterData && filterData.order
          ? {
              label: filterData.order,
              direction: ORDER_BY_MAP[filterData.direction],
            }
          : orderBy,
      skip: 0,
      limit: searchDataLimit || this._appConfigService.getAppConfig().limit,
    };
    this.setSearchFilter(searchProgramData);

    return searchProgramData;
  }

  pushDatalayer(where: SearchProgramDataWhere, advancedSearch: boolean) {
    const item = this.processSearchDataLayer(where, advancedSearch);
    this._googleTagManagerService.pushTag(item);
  }

  pushSortChangeDatalayer(orderBy: SearchProgramorderBy) {
    this._googleTagManagerService.pushTag({
      event: 'list_sort',
      listing: `${orderBy.label}${orderBy.direction ? ',' : ''}${orderBy.direction}`,
    });
  }

  pushTypeChangeDatalayer(typeSearch: SearchPageDisplayType) {
    const event = {
      [SearchPageDisplayType.Lot]: 'lot_listing',
      [SearchPageDisplayType.Programme]: 'program_listing',
      [SearchPageDisplayType.Carte]: 'carte_listing',
    };
    this._googleTagManagerService.pushTag({
      event: event[typeSearch],
    });
  }

  computeNbOtherFilter(where: SearchProgramDataWhere) {
    let nbOtherFilter = 0;
    Object.keys(this.filterFilledFuncions).forEach((key) => {
      if (this.filterFilledFuncions[key](where)) {
        nbOtherFilter++;
      }
    });

    return nbOtherFilter;
  }

  private processSearchDataLayer(where: SearchProgramDataWhere, advancedSearch: boolean) {
    const item = {
      budget_min: where.budget && where.budget.minBudget ? where.budget.minBudget : 0,
      budget_max: where.budget && where.budget.maxBudget ? where.budget.maxBudget : this._appConfigService.getAppConfig().budgetCeil,
      type: where.programTypeIds ? where.programTypeIds.join(',') : null,
      taxation: where.taxation,
      taxationArea: where.taxationZone,
      actable: where.isActable,
      feeRate: where.profitability,
      specialOffer: where.hasSpecialOffer,
      roomNumber: where.nbRooms ? where.nbRooms.join(',') : null,
      floorNumber: where.nbFloors ? where.nbFloors.join(',') : null,
      orientation: where.lotOrientationIds ? where.lotOrientationIds.join(',') : null,
      outside: [
        ...(Array.isArray(where.parkingSecondaryLotTypeIds) ? where.parkingSecondaryLotTypeIds : []),
        ...(Array.isArray(where.storageSecondaryLotTypeIds) ? where.storageSecondaryLotTypeIds : []),
      ],
      delivery: where.deliveryDateMonth,
      event: advancedSearch ? 'advanced_search' : 'search_program',
    };
    this.processLocalisationForDatalayer(where, item);
    Object.keys(item).forEach((key) => {
      if (item[key] === null || item[key] === undefined) {
        item[key] = '';
      }
    });

    return item;
  }

  private getOutsideIds(where: SearchProgramDataWhere): Array<number> | null {
    let outside = null;
    outside = where.parkingSecondaryLotTypeIds;
    if (where.storageSecondaryLotTypeIds) {
      outside = outside != null ? (outside = [...outside, ...where.storageSecondaryLotTypeIds]) : where.parkingSecondaryLotTypeIds;
    }

    return outside;
  }

  private processLocalisationForDatalayer(where: SearchProgramDataWhere, item: any) {
    if (where.localisationsList) {
      const locc = [];
      const zipCodes = [];
      const locd = [];
      const locr = [];

      where.localisationsList.forEach((localisation) => {
        switch (localisation.typeLocation) {
          case LOCALISATION_LABELS.cities: {
            locc.push(localisation.label);
            zipCodes.push(localisation.zipcode);
            break;
          }
          case LOCALISATION_LABELS.departments: {
            locd.push(localisation.label);
            break;
          }
          case LOCALISATION_LABELS.regions: {
            locr.push(localisation.key);
            break;
          }
          default:
            break;
        }
      });
      Object.assign(item, {
        city: locc.join(','),
        department: locd.join(','),
        postalCode: zipCodes.join(','),
        region: locr.join(','),
      });
    }
  }

  private mapFilterDataToFilterChain(where: SearchProgramDataWhere): any {
    const filterChain = {};
    Object.keys(where).forEach((property) => {
      // eslint-disable-next-line no-prototype-builtins
      if (this.toMapper.hasOwnProperty(property)) {
        const data = where[property];
        if (data !== undefined && data !== null && property !== 'localisations') {
          if (data instanceof Array && data.length && (typeof data[0] === 'number' || typeof data[0] === 'string')) {
            Object.assign(filterChain, SearchFormUtilsService._processToSimpleArray(this.toMapper[property], data));
          }
          if (typeof data === 'number' || typeof data === 'string') {
            Object.assign(filterChain, SearchFormUtilsService._processToSimpleValue(this.toMapper[property], data));
          }
          if (typeof data === 'boolean') {
            Object.assign(filterChain, SearchFormUtilsService._processToSimpleValue(this.toMapper[property], data ? 1 : 0));
          }
          if (data instanceof Object && !(data instanceof Array)) {
            Object.assign(filterChain, SearchFormUtilsService._processToObject(this.toMapper[property], data));
          }
        }

        if (data && property === 'localisations' && where.localisationsList && where.localisationsList.length) {
          this.processLocalisationTo(where, filterChain);
        }
      }
    });

    return filterChain;
  }

  private processLocalisationTo(
    where: SearchProgramDataWhere,
    // eslint-disable-next-line @typescript-eslint/ban-types
    filterChain: {},
  ) {
    const locc = [];
    const locd = [];
    const locr = [];
    const loca = [];
    where.localisationsList.forEach((localisation) => {
      switch (localisation.typeLocation) {
        case LOCALISATION_LABELS.cities: {
          locc.push(`${localisation.label}|${localisation.zipcode}`);
          break;
        }
        case LOCALISATION_LABELS.agglomeration: {
          loca.push(`${localisation.label}|${localisation.zipcode}|${localisation.id}`);
          break;
        }
        case LOCALISATION_LABELS.departments: {
          locd.push(`${localisation.label}|${localisation.code}|${localisation.id}`);
          break;
        }
        case LOCALISATION_LABELS.regions: {
          locr.push(`${localisation.key}|${localisation.id}`);
          break;
        }
        default:
          break;
      }
    });

    if (locc.length) {
      Object.assign(filterChain, { locc: locc.join(',') });
    }
    if (loca.length) {
      Object.assign(filterChain, { loca: loca.join(',') });
    }
    if (locd.length) {
      Object.assign(filterChain, { locd: locd.join(',') });
    }
    if (locr.length) {
      Object.assign(filterChain, { locr: locr.join(',') });
    }
  }

  private mapFilterDataFromFilterChain(filterChain: { [keys: string]: string }): SearchProgramDataWhere {
    const searchProgramDataWhere: SearchProgramDataWhere = new SearchProgramDataWhere();
    searchProgramDataWhere.budget = { minBudget: 0, maxBudget: undefined };
    searchProgramDataWhere.rent = undefined;

    Object.keys(filterChain).forEach((filterChainKey) => {
      const from = this.fromMapper[filterChainKey];
      if (from) {
        if (from.type === FromMapperEnum.Object) {
          SearchFormUtilsService._processFromObj(searchProgramDataWhere, from, filterChain, filterChainKey);
        }

        if (from.type === FromMapperEnum.localisation) {
          this.processLocalisationFrom(searchProgramDataWhere, filterChain, filterChainKey);
        }
        if (from.type === FromMapperEnum.Array) {
          searchProgramDataWhere[from.mainField] = SearchFormUtilsService._processFromArray(
            filterChain[filterChainKey].split(','),
            from.subType,
          );
        }
        if (from.type === FromMapperEnum.number) {
          searchProgramDataWhere[from.mainField] = Number(filterChain[filterChainKey]);
        }
        if (from.type === FromMapperEnum.string) {
          searchProgramDataWhere[from.mainField] = filterChain[filterChainKey];
        }
        if (from.type === FromMapperEnum.boolean) {
          searchProgramDataWhere[from.mainField] = !!Number(filterChain[filterChainKey]);
        }
      }
    });

    return searchProgramDataWhere;
  }

  private processLocalisationFrom(
    searchProgramDataWhere: SearchProgramDataWhere,
    filterChain: { [p: string]: string },
    filterChainKey: string,
  ) {
    searchProgramDataWhere.localisations = searchProgramDataWhere.localisations || new LocationData();
    searchProgramDataWhere.localisationsList = searchProgramDataWhere.localisationsList || [];
    const localisations = filterChain[filterChainKey].split(',');
    switch (filterChainKey) {
      case 'locc': {
        this.processCityFrom(localisations, searchProgramDataWhere);
        break;
      }
      case 'loca': {
        this.processAggloFrom(localisations, searchProgramDataWhere);
        break;
      }
      case 'locd': {
        this.processDepartmentFrom(localisations, searchProgramDataWhere);
        break;
      }
      case 'locr': {
        this.processRegionFrom(localisations, searchProgramDataWhere);
        break;
      }
      default:
        break;
    }
  }

  private processRegionFrom(localisations: string[], searchProgramDataWhere: SearchProgramDataWhere) {
    const localisationsTempData = {
      localisations: [],
      localisationsList: [],
    };
    localisations.forEach((value) => {
      const tab = value.split('|');
      const label = tab[0];
      const id = Number(tab[1]);
      localisationsTempData.localisations.push(id);
      localisationsTempData.localisationsList.push({
        label,
        id,
        typeLocation: LOCALISATION_LABELS.regions,
        key: label,
      });
    });
    searchProgramDataWhere.localisations.regions = searchProgramDataWhere.localisations.regions.concat(localisationsTempData.localisations);
    searchProgramDataWhere.localisationsList = searchProgramDataWhere.localisationsList.concat(localisationsTempData.localisationsList);
  }

  private processAggloFrom(localisations: string[], searchProgramDataWhere: SearchProgramDataWhere) {
    const localisationsTempData = {
      localisations: [],
      localisationsList: [],
    };
    localisations.forEach((value) => {
      const tab = value.split('|');
      const label = tab[0];
      const zipcode = tab[1];
      const id = Number(tab[2]);
      localisationsTempData.localisations.push(id);
      localisationsTempData.localisationsList.push({
        id,
        label,
        zipcode,
        typeLocation: LOCALISATION_LABELS.agglomeration,
        key: `${label} (${zipcode})`,
      });
    });

    searchProgramDataWhere.localisations.agglomerations = searchProgramDataWhere.localisations.agglomerations.concat(
      localisationsTempData.localisations,
    );
    searchProgramDataWhere.localisationsList = searchProgramDataWhere.localisationsList.concat(localisationsTempData.localisationsList);
  }

  private processDepartmentFrom(localisations: string[], searchProgramDataWhere: SearchProgramDataWhere) {
    const localisationsTempData = {
      localisations: [],
      localisationsList: [],
    };
    localisations.forEach((value) => {
      const tab = value.split('|');
      const label = tab[0];
      const code = tab[1];
      const id = Number(tab[2]);
      localisationsTempData.localisations.push(id);
      localisationsTempData.localisationsList.push({
        label,
        code,
        id,
        typeLocation: LOCALISATION_LABELS.departments,
        key: `${label} (${code})`,
      });
    });
    searchProgramDataWhere.localisations.departments = searchProgramDataWhere.localisations.departments.concat(
      localisationsTempData.localisations,
    );
    searchProgramDataWhere.localisationsList = searchProgramDataWhere.localisationsList.concat(localisationsTempData.localisationsList);
  }

  private processCityFrom(localisations: string[], searchProgramDataWhere: SearchProgramDataWhere) {
    const localisationsTempData = {
      localisations: [],
      localisationsList: [],
    };
    localisations.forEach((value) => {
      const tab = value.split('|');
      const label = tab[0];
      const zipcode = tab[1];
      localisationsTempData.localisations.push(label);
      localisationsTempData.localisationsList.push({
        label,
        zipcode,
        typeLocation: LOCALISATION_LABELS.cities,
        key: `${label} (${zipcode})`,
      });
    });
    searchProgramDataWhere.localisations.cities = searchProgramDataWhere.localisations.cities.concat(localisationsTempData.localisations);
    searchProgramDataWhere.localisationsList = searchProgramDataWhere.localisationsList.concat(localisationsTempData.localisationsList);
  }

  checkDisableSearchInput(search: SearchProgramDataWhere) {
    this._checkDisableSubject.next(search);
  }
}
