<app-dashboard-card
  icon="Clock"
  [title]="
    activeOption
      ? activeOption?.optionStatusId === 3
        ? 'Txt_DossierProspectOptionTitleAccepted'
        : 'Txt_DossierProspectOptionTitlePending'
      : 'Txt_DossierProspectOptionTitleNoTransaction'
  "
  [complete]="isOptionComplete"
>
  <div class="card">
    <div *ngIf="activeOption?.lot?.program?.company">
      <div>
        <b>{{ i18nService._('Txt_DossierProspectOptionLotNumber', [activeOption.lot.lotNumber]) }}</b>
      </div>
      <div class="lot-info">
        {{ activeOption.lot.program.company.corporateName }} / {{ activeOption.lot.program.programName }} /
        {{ activeOption.lot.program.city }} ({{ activeOption.lot.program.postalCode.slice(0, 2) }})
      </div>

      <div
        class="option-status"
        [innerHTML]="
          (activeOption
            ? activeOption?.optionStatusId === 3
              ? 'Txt_DossierProspectOptionTextAccepted'
              : 'Txt_DossierProspectOptionTextPending'
            : 'Txt_DossierProspectOptionTextNoTransaction'
          )
            | translate
            | safe: 'html'
        "
      ></div>
    </div>
    <div *ngIf="options">
      <div class="flex w-full font-bold">
        <div class="flex flex-col flex-1 gap-4">
          <div>{{ 'Txt_DossierProspectOptionSubTitleOption' | translate }}</div>
          <div>{{ 'Txt_DossierProspectOptionSubTitlePreReservation' | translate }}</div>
          <div>{{ 'Txt_DossierProspectOptionSubTitleReservation' | translate }}</div>
        </div>
        <div class="flex flex-col w-20 gap-4 text-gray-400">
          <div>{{ 'Txt_DossierProspectOptionRefused' | translate }} :</div>
          <div>{{ 'Txt_DossierProspectOptionRefused' | translate }} :</div>
          <div>{{ 'Txt_DossierProspectOptionWithdrawn' | translate }} :</div>
        </div>
        <div class="flex flex-col w-6 gap-4">
          <div>{{ optionRefused }}</div>
          <div>{{ preResaRefused }}</div>
          <div>{{ resaWithdrawn }}</div>
        </div>
        <div class="flex flex-col w-20 gap-4 text-gray-400">
          <div>{{ 'Txt_DossierProspectOptionExpired' | translate }} :</div>
          <div>{{ 'Txt_DossierProspectOptionCanceled' | translate }} :</div>
        </div>
        <div class="flex flex-col w-6 gap-4">
          <div>{{ optionExpired }}</div>
          <div>{{ preResaCanceled }}</div>
        </div>
      </div>
      <br />
      <div [innerHTML]="'Txt_DossierProspectOptionTextNoTransaction' | translate | safe: 'html'" class="italic"></div>
    </div>
  </div>

  <footer>
    <div *ngIf="activeOption && !isOptionComplete">
      <app-button [type]="ButtonType.SECONDARY" (clickButton)="goToTransaction()">
        {{ 'Txt_DossierProspectButtonOptionSeeTransaction' | translate }}
      </app-button>
    </div>
    <div *ngIf="activeOption && isOptionComplete" class="w-full">
      <div class="timer-wrapper flex w-full">
        <div class="flex flex-col flex-1">
          <div>{{ 'Txt_DossierProspectOptionTimeExpire' | translate }}</div>
          <div class="timer" [innerHTML]="remainingTime?.inSecond | time: 'html' | safe: 'html'"></div>
          <div #progress id="progress">
            <div #progressBar id="progressBar"></div>
          </div>
        </div>
        <div class="buttons" *ngIf="!hideActions">
          <app-button [type]="ButtonType.TERTIARY" (clickButton)="preBook()">
            {{ 'Txt_DossierProspectButtonOptionPreReservation' | translate }}
          </app-button>
          <app-button *ngIf="!activeOption.isExtended" [type]="ButtonType.QUADRARY" (clickButton)="extend()">
            {{ 'Txt_DossierProspectButtonOptionExtend' | translate }}
          </app-button>
        </div>
      </div>
    </div>
    <div *ngIf="options">
      <div class="flex gap-4 w-full">
        <app-button [type]="ButtonType.SECONDARY" (clickButton)="seeTransactions()" class="flex flex-col">
          {{ 'Txt_DossierProspectButtonOptionSeeAllTransactions' | translate }}
        </app-button>
        <app-button [type]="ButtonType.PRIMARY" (clickButton)="searchLot()" class="flex flex-col">
          {{ 'Txt_DossierProspectButtonOptionSearchLot' | translate }}
        </app-button>
      </div>
    </div>
  </footer>
</app-dashboard-card>
