import { lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { ParamRequest } from '@commons-dto/valo-back';

import { DossierProspectApiService } from './../../adapters/dossier-prospect-api.service';
import { TableLoader, TableResponse } from './../../table/interfaces/DataLoader';
import { DossierProspectTrackingTableItem } from './../models/DossierProspectTrackingTableItem';

@Injectable({
  providedIn: 'root',
})
export class DossierProspectTrackingTableService implements TableLoader<DossierProspectTrackingTableItem> {
  constructor(private dossierProspectApiService: DossierProspectApiService) {}

  async get(params: ParamRequest): Promise<TableResponse<DossierProspectTrackingTableItem>> {
    const modifiedParams = { ...params };
    if (modifiedParams.statusFilter === 'ALL') {
      modifiedParams.statusFilter = null;
    }
    return lastValueFrom(this.dossierProspectApiService.getDossierProspectForTrackingTable(modifiedParams));
  }
}
