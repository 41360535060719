import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { DatePipe, NgFor, NgIf } from '@angular/common';

import { ProgramSummary } from '../../../utils/models/ProgramSummary';
import { I18nService } from '../../../utils/services/i18n.service';
import { ProgramService } from '../../services/program.service';
import { FileDropComponent } from '../../../utils/components/file-drop/file-drop.component';

@Component({
  selector: 'app-step-three-form-program',
  templateUrl: './step-three-form-program.component.html',
  styleUrls: ['./step-three-form-program.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, FileDropComponent, MatButtonModule, MatStepperModule, DatePipe],
})
export class StepThreeFormProgramComponent {
  /**
   * Know if edit or validate program
   *
   * @type {boolean}
   * @memberof StepThreeFormProgramComponent
   */
  @Input() isEditOrValidateForm: boolean;
  /**
   * Know if user is Valo or not
   *
   * @type {boolean}
   * @memberof StepThreeFormProgramComponent
   */
  @Input() isValo: boolean;
  /**
   * Id of the program
   *
   * @type {number}
   * @memberof StepThreeFormProgramComponent
   */
  @Input() programId: number;
  /**
   * Output to tell to inform that step is completed
   *
   * @type {EventEmitter<boolean>}
   * @memberof StepThreeFormProgramComponent
   */
  @Output() readonly stepCompleted: EventEmitter<boolean>;
  submitDisabled = false;
  /**
   *  FormGroup used by app-file-drop component
   *
   * @type {FormGroup}
   * @memberof StepThreeFormProgramComponent
   */
  attachementsFormGroup = new UntypedFormGroup({});

  /**
   * Creates an instance of StepThreeFormProgramComponent.
   * @param {ProgramService} programService
   * @param {I18nService} i18nService
   * @memberof StepThreeFormProgramComponent
   */
  constructor(
    private readonly programService: ProgramService,
    public i18nService: I18nService,
  ) {
    this.stepCompleted = new EventEmitter<boolean>();
  }

  /**
   * Program Summary to be shown
   *
   * @type {ProgramSummary}
   * @memberof StepThreeFormProgramComponent
   */
  private _programSummary: ProgramSummary;

  get programSummary(): ProgramSummary {
    return this._programSummary;
  }

  @Input() set programSummary(val: ProgramSummary) {
    this._programSummary = val;
    if (this._programSummary && this._programSummary.specialOffers) {
      this._programSummary.specialOffers.forEach((specialOffer) => {
        this.attachementsFormGroup.controls[specialOffer.refSpecialOffer] = new UntypedFormControl(specialOffer.amendment);
      });
    }
  }

  /**
   * Send pending validation of program if program creation
   * Tell stepper that actual step is completed
   *
   * @memberof StepThreeFormProgramComponent
   */
  sendPendingValidation(): void {
    if (!this.isEditOrValidateForm && this.programId) {
      this.programService.sendPendingValidationProgram(this.programId, this.isValo);
    }
    // Event to tell parent component that step is completed and that user can go to step 4
    this.stepCompleted.emit(true);
  }
}
