import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AutoCompleteService } from '../../common/form/auto-complete.service';
import { ProspectLegacyApiService } from '../../adapters/prospect-legacy-api.service';
import { ProspectParamRequest } from '../../../../../../libs/commons-dto/src/lib/dossier-prospect/prospect/prospect-request';
import { LegacyProspectsResponse } from '../../../../../../libs/commons-dto/src/lib/dossier-prospect/prospect/legacy-prospects.response';
import { LegacyProspectSummaryResponse } from '../../../../../../libs/commons-dto/src/lib/dossier-prospect/prospect/legacy-prospect.summary.response';

@Injectable({
  providedIn: 'root',
})
export class ProspectLegacySearch implements AutoCompleteService {
  companies: { label: string; id: number }[];

  constructor(private prospectLegacyApiService: ProspectLegacyApiService) {}

  findData(name: string, observers: any): Observable<{ label: string; id: number }[]> {
    const accountId = observers['props.accountId'] ? observers['props.accountId'].value : undefined;
    const params: ProspectParamRequest = { accountId: accountId, name: name, offset: 0, nbResult: 200 };
    return this.prospectLegacyApiService.getLegacyProspects(params).pipe(
      map((prospectsResponse: LegacyProspectsResponse) => {
        return prospectsResponse.items.map((prospect: LegacyProspectSummaryResponse) => {
          return {
            id: prospect.id,
            label: `${prospect.firstName} ${prospect.lastName}`,
          };
        });
      }),
    );
  }
}
