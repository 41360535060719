import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { CompaniesTrackingTableServiceResponse } from '../models/CompaniesTrackingTableServiceResponse';
import { AbstractAdvancedTrackingTableService } from './abstract-advanced-tracking-table.service';

import { CompanyType } from './../../utils/models/enums/CompanyType.enum';
import { CompanyApiService } from '../../adapters/company-api.service';
import { AppConfigService } from './../../utils/services/app-config.service';
import { ErrorHandlerService } from './../../utils/services/error-handler.service';
import { CacheService } from './../../utils/services/cache.service';
import { CompaniesTrackingTableItem } from './../models/CompaniesTrackingTableItem';
import { TableLoader, TableResponse } from './../../table/interfaces/DataLoader';

@Injectable({
  providedIn: 'root',
})
export class CompaniesTrackingTableService
  extends AbstractAdvancedTrackingTableService<CompaniesTrackingTableServiceResponse>
  implements TableLoader<CompaniesTrackingTableItem>
{
  public type: CompanyType;

  constructor(
    public readonly appConfig: AppConfigService,
    public readonly errorHandlerService: ErrorHandlerService,
    public readonly http: HttpClient,
    public readonly cacheService: CacheService,
    private companyApiService: CompanyApiService,
  ) {
    super(appConfig, errorHandlerService, http, cacheService);
  }

  async get(params): Promise<TableResponse<CompaniesTrackingTableItem>> {
    const res = this.companyApiService.getCompanies(this.type, params);
    return await lastValueFrom(res);
  }

  async getById(id: number): Promise<CompaniesTrackingTableItem> {
    const res = this.companyApiService.getCompanyById(this.type, id);
    return await lastValueFrom(res);
  }
}
