import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { SafePipe } from 'apps/valo-front/src/app/utils/pipes/safe.pipe';

import { TakeOptionDialogComponent } from './../../../../../dialog/components/take-option-dialog/take-option-dialog.component';
import { FeatureFlagService } from './../../../../../feature-flag/feature-flag.service';
import { PreReservationSubmitionDialogComponent } from '../../../../../dialog/components/pre-reservation-submition-dialog/pre-reservation-submition-dialog.component';
import { ProspectAssociationDialogComponent } from '../../../../../dialog/components/prospect-association-dialog/prospect-association-dialog.component';
import { QuestionDialogComponent } from '../../../../../dialog/components/question-dialog/question-dialog.component';
import { PreReservationSubmitionDialogData } from '../../../../../dialog/models/PreReservationSubmitionDialogData';
import { ProspectAssociation } from '../../../../../dialog/models/ProspectAssociation';
import { QuestionDialogData } from '../../../../../dialog/models/QuestionDialogData';
import { ProspectAssociationService } from '../../../../../dialog/services/prospect-association.service';
import { LotAction } from '../../../../../lot/model/lot-action';
import { LotComparatorDetail } from '../../../../../lot/model/lot-comparator-detail';
import { PageLotService } from '../../../../../lot/service/page-lot.service';
import { ActionOnOptionResponse } from '../../../../../utils/models/ActionOnOptionResponse';
import { SnackbarMessageType } from '../../../../../utils/models/enums/snackbar-message-type.enum';
import { PoseoptionResponse } from '../../../../../utils/models/PoseoptionResponse';
import { Sitemap } from '../../../../../utils/models/Sitemap';
import { SnackbarMessage } from '../../../../../utils/models/SnackbarMessage';
import { BasicFormatsService } from '../../../../../utils/services/basic-formats.service';
import { GoogleTagManagerService } from '../../../../../utils/services/google-tag-manager.service';
import { PoseOptionsService } from '../../../../../utils/services/pose-options.service';
import { SnackbarService } from '../../../../../utils/services/snackbar.service';
import { SpinnerWithBackdropService } from '../../../../../utils/services/spinner-with-backdrop.service';
import { AbstractComparatorComponent } from '../../../abstract-comparator-component';

@Component({
  selector: 'app-lot-comparator-head-option-button',
  templateUrl: './lot-comparator-head-option-button.component.html',
  styleUrls: ['./lot-comparator-head-option-button.component.scss'],
  standalone: true,
  imports: [NgIf, MatButtonModule, MatProgressSpinnerModule, MatIconModule, SafePipe],
})
export class LotComparatorHeadOptionButtonComponent extends AbstractComparatorComponent implements OnInit {
  @Input() lot: LotComparatorDetail;

  private isDossierProspectEnabled: boolean;
  public lotAction: LotAction = new LotAction();
  public ongoingCall: boolean;
  public showSpinnerOnLoading: boolean;

  constructor(
    private readonly _poseOptionService: PoseOptionsService,
    private readonly _dialog: MatDialog,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly _prospectAssociationService: ProspectAssociationService,
    private readonly _snackbarService: SnackbarService,
    private readonly _spinnerWithBackdropService: SpinnerWithBackdropService,
    private readonly _pageLotService: PageLotService,
    private readonly _router: Router,
    private readonly featureFlagService: FeatureFlagService,
    public basicFormatsService: BasicFormatsService,
  ) {
    super();
    this.isDossierProspectEnabled = false;
  }

  ngOnInit() {
    this._prospectAssociationService.lotIdUpdateToOptionObservable.pipe(takeUntil(this.$d)).subscribe((lotIdUpdate) => {
      if (lotIdUpdate && this.lot.id === lotIdUpdate.lotIdUpdated && !lotIdUpdate.isAlert) {
        switch (lotIdUpdate.labelStatus) {
          case this._appConfigService.getAppConfig().statusLotLabels.optioned: {
            this.lot.status = { id: lotIdUpdate.lotIdUpdated, optionned: true, optionnedByMe: true, sold: false };
            break;
          }
          case this._appConfigService.getAppConfig().statusLotLabels.sold: {
            this.lot.status = { id: lotIdUpdate.lotIdUpdated, optionned: false, optionnedByMe: false, sold: true };
            break;
          }
          default: {
            this.lot.status = { optionned: false, optionnedByMe: false, sold: false };
          }
        }
      }
    });

    if (this.lot && this.isAuthorizedToManageOption) {
      this.getActionOnOption();
    }

    this.featureFlagService.isEnabled('dossier-prospect').then((res) => {
      this.isDossierProspectEnabled = res;
    });
  }

  public openPreReservationPopin(): void {
    const data: MatDialogConfig<PreReservationSubmitionDialogData> = {
      autoFocus: false,
      data: {
        lotId: this.lot.id,
        lotNumber: this.lot.lotNumber,
        programName: this.lot.programName,
      },
    };

    const preReservationPopin = this._dialog.open(PreReservationSubmitionDialogComponent, data);
    preReservationPopin
      .afterClosed()
      .pipe(takeUntil(this.$d))
      .subscribe((success: boolean) => {
        if (success) {
          this._router.navigate([Sitemap.dashboards.preReservations.path]);
        }
      });
  }

  public checkValidity(): void {
    this._comparatorPageService.pushToDatalayerComparatorLotOptionAction(this.lot, 'click_option_comparator');
    this._poseOptionService.checkValidity(this.lot.id).subscribe((response) => {
      if (response) {
        const dialogData: ProspectAssociation = {
          lotId: this.lot.id,
          lotNumber: this.lot.lotNumber,
          lotPrice: this.basicFormatsService.formatCurrency(this.lot.commercialLotSellingPriceIT, undefined, 0, 0, 4),
          title: this.lotAction.checkIfOptionCanBeAutomaticallyTaken
            ? 'Title_ProspectAssociationPopin_PopinTitle'
            : 'Title_ProspectAssociationPopin_PopinTitleAsk',
        };
        let dialogRef;

        if (this.isDossierProspectEnabled) {
          dialogRef = this._dialog.open(TakeOptionDialogComponent, {
            autoFocus: false,
            data: dialogData,
          });
        } else {
          dialogRef = this._dialog.open(ProspectAssociationDialogComponent, {
            autoFocus: false,
            data: dialogData,
          });
        }

        // Reload Component according to response
        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.$d))
          .subscribe((lotIdUpdateToOptionned: number) => {
            if (lotIdUpdateToOptionned) {
              this.lot.status.optionned = true;
              this.lot.status.optionnedByMe = true;
              // MAJ du statut après action
              this.getActionOnOption();
              this._googleTagManagerService.pushTag({
                event: 'validation_option',
              });
              this._prospectAssociationService.setLotIdUpdateToOption({
                lotIdUpdateStatus: lotIdUpdateToOptionned,
                lotIdUpdated: this.lot.id,
                isOptionned: true,
                labelStatus: this._appConfigService.getAppConfig().statusLotLabels.optioned,
              });
            }
          });
      } else {
        const message: SnackbarMessage = {
          text: this.i18nService._('Txt_comparator_take_Option_error'),
          type: SnackbarMessageType.Error,
        };
        this._snackbarService.sendMessage(message);
      }
    });
  }

  public callRemoveOption(): void {
    this._googleTagManagerService.pushTag({ event: 'cancel_option' });
    const dialogData: QuestionDialogData = {
      message: this.i18nService._('Txt_Popin_Remove_Option', [this.lot.lotNumber, this.lot.programName]),
      title: this.i18nService._('Txt_Popin_Remove_Option_Title'),
    };

    const dialogRef = this._dialog.open(QuestionDialogComponent, {
      data: dialogData,
    });

    // Add in form and update company status
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.$d))
      .subscribe((result) => {
        if (result.answer) {
          this._googleTagManagerService.pushTag({
            event: 'cancel_option_validation',
          });
          this._poseOptionService.removeOption(this.lot.id).subscribe(
            (response: PoseoptionResponse) => {
              this._responseUpdateOption(response, this._appConfigService.getAppConfig().statusLotLabels.free);
              // MAJ du statut après action
              this.getActionOnOption();
            },
            (error: HttpErrorResponse) => {
              this._errorUpdateOption(error, this.i18nService._('Error_SnackBar_UnavailableRemoveOption'));
            },
          );
        }
      });
  }

  callExtendedOption(): void {
    this.ongoingCall = true;
    this._spinnerWithBackdropService.show();
    this._poseOptionService.extendOption(this.lot.id).subscribe(
      (response: PoseoptionResponse) => {
        this._responseUpdateOption(response, this._appConfigService.getAppConfig().statusLotLabels.optioned);
        this.ongoingCall = false;
        this._spinnerWithBackdropService.hide();
      },
      (error: HttpErrorResponse) => {
        this._errorUpdateOption(error, this.i18nService._('Error_SnackBar_UnavailableExtendOption'));
        this.ongoingCall = false;
        this._spinnerWithBackdropService.hide();
      },
    );
  }

  public takeAlert(): void {
    this._comparatorPageService.pushToDatalayerComparatorLotOptionAction(this.lot, 'create_alert_comparator');
    this._lotService.createAlert(this.lot.id).subscribe(() => {
      this._snackbarService.infoI18n('Success_SnackBar_AlertTaken');
      this.lotAction.checkIfAlertCanBeTaken = false;
      this._prospectAssociationService.setLotIdUpdateToOption({
        lotIdUpdateStatus: this.lot.id,
        isAlert: true,
        labelStatus: this._appConfigService.getAppConfig().statusLotLabels.optioned,
      });
    });
  }

  public getActionOnOption() {
    this.showSpinnerOnLoading = true;
    this._poseOptionService.getActionOnOption(this.lot.id).subscribe(
      (actionOnOption: ActionOnOptionResponse) => {
        this.lotAction = this._pageLotService.getLotActions(actionOnOption, this.isAuthorizedToManageOnlyReservation, true);
        this.showSpinnerOnLoading = false;
      },
      () => (this.showSpinnerOnLoading = false),
    );
  }

  private _responseUpdateOption(response: PoseoptionResponse, nextStatus: string): void {
    const message: SnackbarMessage = {
      text: response.libelleRetour,
      type: SnackbarMessageType.Info,
    };
    this._snackbarService.sendMessage(message);
    if (response.codeRetour === 200) {
      this._prospectAssociationService.setLotIdUpdateToOption({
        lotIdUpdateStatus: response.option.lotId,
        isOptionned: false,
        labelStatus: nextStatus,
      });
    }
  }

  private _errorUpdateOption(error: HttpErrorResponse, text: string): void {
    if (error && (error.status === 502 || error.status === 504)) {
      const message: SnackbarMessage = {
        type: SnackbarMessageType.Error,
        text,
      };
      this._snackbarService.sendMessage(message);
    }
  }
}
