import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { BehaviorSubject, finalize } from 'rxjs';

import { DocumentToDownloadInZip } from '../models/DocumentToDownloadInZip';
import { FileSystemService } from './file-system.service';
import { I18nService } from './i18n.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class ZipFileService {
  /**
   * Creates an instance of ZipFileService.
   * @param {I18nService} i18nService
   * @memberof FileSystemService
   * @param {SnackbarService} snackbarService
   */
  constructor(
    private readonly i18nService: I18nService,
    private readonly fileSystemService: FileSystemService,
    private readonly snackbarService: SnackbarService,
  ) {
    this.inProgress$ = new BehaviorSubject(false);
  }

  public inProgress$: BehaviorSubject<boolean>;

  /**
   * createZip method
   *
   * @param documents
   * @param programName
   * @memberof ReservationsTrackingTableComponent
   */
  public createZip(documents: Array<DocumentToDownloadInZip>, targetZipName: string, errorEmptyKey: string): void {
    if (!documents || !documents.length) {
      return;
    }
    this.inProgress$.next(true);
    const zip = new JSZip();

    let counter = 0;
    documents.forEach((document) => {
      if (document) {
        this.fileSystemService
          .downloadFile(document)
          .pipe(
            finalize(() => {
              counter++;
              // After last file downloading, generate zip file
              if (counter === documents.length) {
                if (Object.keys(zip.files).length) {
                  zip.generateAsync({ type: 'blob' }).then((content) => {
                    this.inProgress$.next(false);
                    saveAs(content, targetZipName);
                  });
                } else {
                  this.inProgress$.next(false);
                  this.snackbarService.errorI18n(errorEmptyKey);
                }
              }
            }),
          )
          .subscribe((response) => {
            // Add file to zip
            let newFileName = document.fileName;
            if (document.targetNameInZip) {
              const ext = document.fileName.substr(document.fileName.lastIndexOf('.') + 1);
              newFileName = `${document.targetNameInZip}.${ext}`;
            }
            zip.file(newFileName, response);
          });
      }
    });
  }
}
