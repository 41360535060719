import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { I18nService } from '../../../utils/services/i18n.service';
import { SaveSearchService } from '../../../tracking-tables/services/save-search.service';

@Component({
  selector: 'app-save-search-dialog',
  templateUrl: './save-search-dialog.component.html',
  styleUrls: ['./save-search-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, MatButtonModule],
})
export class SaveSearchDialogComponent implements OnInit {
  /**
   * Form Group for check paternity
   *
   * @private
   * @type {FormGroup}
   * @memberof SaveSearchDialogComponent
   */
  public formGroup: UntypedFormGroup;

  /**
   * titleControls
   *
   * @type {AbstractControl}
   * @memberof SaveSearchDialogComponent
   */
  public titleControls: AbstractControl;

  /**
   * titleControls
   *
   * @type {AbstractControl}
   * @memberof SaveSearchDialogComponent
   */
  public descriptionControls: AbstractControl;

  public disableSubmitButton = false;

  constructor(
    public readonly i18nService: I18nService,
    public dialogRef: MatDialogRef<SaveSearchDialogComponent>,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly saveSearchService: SaveSearchService,
    @Inject(MAT_DIALOG_DATA) public data: unknown,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(100)]],
      description: ['', [Validators.maxLength(256)]],
    });

    this.titleControls = this.formGroup.controls.title;
    this.descriptionControls = this.formGroup.controls.description;
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

  public submit(): void {
    if (!this.formGroup.valid) {
      return;
    }
    this.disableSubmitButton = true;

    const search = {
      title: this.titleControls.value,
      description: this.descriptionControls.value,
      criterias: this.data,
    };

    this.saveSearchService.saveSearch(search).subscribe(() => {
      this.dialogRef.close(false);
    });
  }
}
