import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs';

import { SnackbarMessageType } from '../utils/models/enums/snackbar-message-type.enum';
import { I18nService } from '../utils/services/i18n.service';
import { SnackbarService } from '../utils/services/snackbar.service';

/**
 * WatchDogService class
 * @export
 * @class WatchDogService
 * @implements {HttpInterceptor}
 */
@Injectable()
export class WatchDogService implements HttpInterceptor {
  message = 'Error_SnackBar_WafAnomaly';

  /**
   * Creates an instance of WatchDogService.
   *
   * @param {I18nService} i18nService
   * @param {SnackbarService} snackbarService
   *
   * @memberof WatchDogService
   */
  constructor(
    private readonly i18nService: I18nService,
    private readonly snackbarService: SnackbarService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event) => {
        return event;
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 403 && !error.headers.has('x-content-type-options')) {
          this.snackbarService.sendMessage({
            text: this.i18nService._(this.message),
            type: SnackbarMessageType.Error,
          });
        }

        return throwError(error);
      }),
    );
  }
}
