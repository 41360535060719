<div [ngClass]="chipCtrl.disabled ? 'mat-chip-disabled' : 'mat-chip-enabled'" class="flex flex-col flex-auto">
  <mat-form-field *ngIf="isEmpty || !sentenceIfNoItems">
    <mat-label>{{ title }}</mat-label>
    <mat-chip-grid #chipList [formControl]="chipCtrl" [required]="required" aria-label="Item selection" class="chip-autocomplete">
      <mat-chip-row
        (removed)="remove(item)"
        *ngFor="let item of selectedItems"
        [removable]="removable"
        [selectable]="selectable"
        class="mt-1 flex justify-center items-center"
        color="primary"
      >
        <span *ngIf="removable" class="icon-cancel txt-orange text-xxs ml-0 mr-1 flex justify-center items-center" matChipRemove> </span>
        {{ item }}
      </mat-chip-row>
      <!-- 
        TODO : remove this when the issue is fixed
        (blur) line that I added is workaround to fix Mdc Chip Grid empties all options when using Reactive Forms and focusing out #26358 
      -->
      <input
        #itemInput
        [formControl]="itemCtrl"
        [matAutocomplete]="auto"
        (blur)="onBlur()"
        [matChipInputAddOnBlur]="addOnBlur"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      />
    </mat-chip-grid>
    <mat-error *ngIf="chipCtrl.hasError('minlength')">
      {{ i18nService._('Error_Field_MinValue', [this.min]) }}
    </mat-error>
    <mat-error *ngIf="chipCtrl.hasError('maxlength')">
      {{ i18nService._('Error_Field_MaxValue', [this.max]) }}
    </mat-error>
    <mat-error *ngIf="chipCtrl.hasError('required')">
      {{ i18nService._('Error_Field_Required') }}
    </mat-error>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        (onSelectionChange)="selected(item)"
        *ngFor="let item of (filteredItems | async) || availableItems"
        [innerHTML]="item | highlight: itemCtrl.value"
      >
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <span *ngIf="!isEmpty && sentenceIfNoItems">
    {{ sentenceIfNoItems }}
  </span>
</div>
