import { IsArray, IsBoolean, IsNumber, IsObject, IsOptional, IsString } from 'class-validator';

import { SortDirection } from '../search/search-query.entity';

class Order {
  @IsObject()
  direction: SortDirection;

  @IsString()
  label: string;
}

class Budget {
  @IsOptional()
  @IsNumber()
  maxBudget?: number;

  @IsOptional()
  @IsNumber()
  minBudget?: number;
}

class Localisations {
  @IsOptional()
  @IsArray()
  agglomerations?: string[];

  @IsOptional()
  @IsArray()
  cities?: string[];

  @IsOptional()
  @IsArray()
  departments?: string[];

  @IsOptional()
  @IsArray()
  regions?: string[];
}

class Rent {
  @IsOptional()
  @IsNumber()
  minRent?: number;
}

export class LotSearchQueryWhereDto {
  @IsOptional()
  @IsNumber()
  userId?: number;

  @IsOptional()
  @IsArray()
  areaTypeIds?: number[];

  @IsOptional()
  @IsString()
  bbox?: string;

  @IsOptional()
  @IsObject()
  budget?: Budget;

  @IsOptional()
  @IsObject()
  localisations?: Localisations;

  @IsOptional()
  @IsArray()
  lotOrientationIds?: number[];

  @IsOptional()
  @IsArray()
  nbFloors?: string[];

  @IsOptional()
  @IsArray()
  nbRooms?: string[];

  @IsOptional()
  @IsNumber()
  taxation?: number;

  @IsOptional()
  @IsNumber()
  taxationZone?: number;

  @IsOptional()
  @IsNumber()
  deliveryDateMonth?: number;

  @IsOptional()
  @IsBoolean()
  isActable?: boolean;

  @IsOptional()
  @IsBoolean()
  hasSpecialOffer?: boolean;

  @IsOptional()
  @IsBoolean()
  isNew?: boolean;

  @IsOptional()
  @IsBoolean()
  available?: boolean;

  @IsOptional()
  @IsArray()
  programTypeIds?: number[];

  @IsOptional()
  @IsObject()
  rent?: Rent;

  @IsOptional()
  @IsArray()
  parkingSecondaryLotTypeIds?: number[];

  @IsOptional()
  @IsArray()
  storageSecondaryLotTypeIds?: number[];
}

export class LotSearchQueryDto {
  @IsNumber()
  limit: number;

  @IsOptional()
  @IsObject()
  order?: Order;

  @IsOptional()
  @IsNumber()
  skip?: number;

  @IsObject()
  where: LotSearchQueryWhereDto;
}
