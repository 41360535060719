/* eslint-disable @typescript-eslint/no-explicit-any */

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Component, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { ActivatedRoute } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

import { GridTrackingTableComponent as GridTrackingTableComponent_1 } from '../grid-tacking-table/grid-tracking-table.component';

import { GridTrackingTableComponent } from './../grid-tacking-table/grid-tracking-table.component';
import { TableLoader, TableResponse } from './../../../table/interfaces/DataLoader';
import { ProgramMandateStrategiesTrackingTableService } from './../../services/program-mandate-strategies-tracking-table.service';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { SpinnerWithBackdropService } from './../../../utils/services/spinner-with-backdrop.service';
import { SnackbarService } from './../../../utils/services/snackbar.service';
import { MandateStrategyService } from './../../../strategies/services/mandate-strategy.service';
import { I18nService } from './../../../utils/services/i18n.service';
import { SnackbarMessage } from './../../../utils/models/SnackbarMessage';
import { QuestionDialogResponse } from './../../../dialog/models/QuestionDialogResponse';
import { QuestionDialogComponent } from './../../../dialog/components/question-dialog/question-dialog.component';
import { QuestionDialogData } from './../../../dialog/models/QuestionDialogData';
import { MandatStrategyResponse } from './../../../utils/models/MandatStrategyResponse';
import { BasicFormatsService } from './../../../utils/services/basic-formats.service';
import { DateRendererComponent } from './../../renderers/date-renderer/date-renderer.component';
import { ActionRendererComponent } from '../../renderers/action-renderer/action-renderer.component';
import { ProgramMandatStrategyTrackingTableItem } from '../../models/ProgramMandatStrategyTrackingTableItem';

@Component({
  selector: 'app-program-mandate-strategies-tracking-table',
  templateUrl: './program-mandate-strategies-tracking-table.component.html',
  styleUrls: ['./program-mandate-strategies-tracking-table.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, GridTrackingTableComponent_1],
})
export class ProgramMandateStrategiesTrackingTableComponent {
  @ViewChild(GridTrackingTableComponent)
  gridTrackingTable: GridTrackingTableComponent;

  @Output()
  public readonly updateButtonClicked: EventEmitter<MandatStrategyResponse> = new EventEmitter<MandatStrategyResponse>();

  @Output() public readonly deletedRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() public readonly numberOfmandateStrategies: EventEmitter<number> = new EventEmitter<number>();
  @Output() private readonly globalMandateStrategyFound: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public programId: number;

  pageSize = 10;
  pageNumber = 1;
  tableHeight = 'auto';
  serverSide = false;
  columnDefs: ColDef[];
  gridOptions: GridOptions = {
    headerHeight: 45,
    rowHeight: 60,
    paginationPageSize: 90,
    paginationPageSizeSelector: false,
    suppressCellFocus: true,
    rowModelType: 'clientSide',
    domLayout: 'autoHeight',
  };
  filterValue: string;
  isGlobalFound = false;
  tableLoader: TableLoader<ProgramMandatStrategyTrackingTableItem>;
  textFilterSubject = new Subject<string>();
  textFilterValue: string;

  constructor(
    public readonly i18nService: I18nService,
    private readonly basicFormatsService: BasicFormatsService,
    private readonly mandateStrategyService: MandateStrategyService,
    public programMandateStrategiesTrackingTableService: ProgramMandateStrategiesTrackingTableService,
    private readonly dialog: MatDialog,
    private readonly snackbarService: SnackbarService,
    private readonly spinnerWithBackdropService: SpinnerWithBackdropService,
    private readonly route: ActivatedRoute,
  ) {
    const programId = parseInt(this.route.snapshot.paramMap.get('programId'), 10);
    this.tableLoader = {
      async get(): Promise<TableResponse<ProgramMandatStrategyTrackingTableItem>> {
        const mandateStrategry: ProgramMandatStrategyTrackingTableItem[] =
          await programMandateStrategiesTrackingTableService.getProgramMandateStrategies(programId);
        return { items: mandateStrategry };
      },
    };

    this.columnDefs = [
      {
        field: 'isGlobal',
        headerName: this.i18nService._('Txt_MandateStrategies_Column_Global'),
        valueFormatter: (params) => {
          return params.value ? 'G' : '';
        },
        minWidth: 20,
        flex: 1,
      },
      {
        field: 'title',
        headerName: this.i18nService._('Txt_MandateStrategies_Column_Name'),
        minWidth: 40,
        flex: 2,
      },
      {
        field: 'nbLots',
        headerName: this.i18nService._('Txt_MandateStrategies_Column_NbLots'),
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'nbContractors',
        headerName: this.i18nService._('Txt_MandateStrategies_Column_NbContractors'),
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'startDate',
        headerName: this.i18nService._('Txt_MandateStrategies_Column_StartDate'),
        cellRenderer: DateRendererComponent,
        cellRendererParams: {
          format: 'DD/MM/yyyy',
          replaceFalsyWithDash: true,
        },
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'endDate',
        headerName: this.i18nService._('Txt_MandateStrategies_Column_EndDate'),
        cellRenderer: DateRendererComponent,
        cellRendererParams: {
          format: 'DD/MM/yyyy',
          replaceFalsyWithDash: true,
        },
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'rate',
        headerName: this.i18nService._('Txt_MandateStrategies_Column_Rate'),
        valueFormatter: (params) => {
          return params.value ? this.basicFormatsService.formatPercentage(params.value / 100) : '-';
        },
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'actions',
        headerName: this.i18nService._('Txt_Table_Action_s'),
        cellRenderer: ActionRendererComponent,
        cellRendererParams: {
          actionItems: (item: any) => {
            return this.getActionItems(item);
          },
        },
        minWidth: 60,
        maxWidth: 60,
        flex: 2,
        cellStyle: { paddingRight: '10px' },
        sortable: false,
      },
    ];

    this.onTextFilterChange();
  }

  onTextFilterChange() {
    this.textFilterSubject.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      this.textFilterValue = value;
    });
  }

  nbItemsChange(nbItems: number) {
    this.numberOfmandateStrategies.emit(nbItems);
  }

  getActionItems(item: ProgramMandatStrategyTrackingTableItem) {
    const actions = [
      {
        label: 'Txt_Tooltip_mandateStrategy',
        action: () => {
          return this.updateButtonClicked.emit(item);
        },
      },
      {
        label: 'Txt_Tooltip_Delete',
        action: () => {
          return this.delete(item);
        },
      },
    ];

    return actions;
  }

  refreshRows(): void {
    this.gridTrackingTable.refreshRows();
  }

  getIsGlobalFound(): boolean {
    return this.isGlobalFound;
  }

  delete(element: MandatStrategyResponse): void {
    // Open dialog
    const dialogData: MatDialogConfig<QuestionDialogData> = {
      data: {
        message: this.i18nService._('Txt_Popin_MandateStrategy_Deletion'),
        title: this.i18nService._('Title_MandateStrategy_Deletion'),
      },
    };

    const dialogRef = this.dialog.open(QuestionDialogComponent, dialogData);

    dialogRef.afterClosed().subscribe((result: QuestionDialogResponse) => {
      if (result && result.answer) {
        this.spinnerWithBackdropService.show();
        // If the answer is yes, delete the mandate strategy on server
        this.mandateStrategyService.deleteMandateStrategy(String(element.id)).subscribe({
          next: () => {
            const message: SnackbarMessage = {
              text: this.i18nService._('Success_SnackBar_MandateStrategyDeleted'),
              type: SnackbarMessageType.Info,
            };
            this.spinnerWithBackdropService.hide();
            this.snackbarService.sendMessage(message);
            this.deletedRow.emit();
            // Update recap table
            this.gridTrackingTable.refreshRows();
          },
          error: () => {
            this.spinnerWithBackdropService.hide();
            this.snackbarService.sendErrorOccured();
          },
        });
      }
    });
  }
}
