import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { Moment } from 'moment-timezone';
import { map } from 'rxjs';
import { Subscription } from 'rxjs';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { NgIf, NgFor } from '@angular/common';

import { DocumentResponse } from '../../../utils/models/DocumentResponse';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { TokenService } from '../../../utils/services/authorisation/token.service';
import { FormUtilsService } from '../../../utils/services/form/form-utils.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { ReferenceTablesService } from '../../../utils/services/reference-tables.service';
import { ReservationInfos } from '../../models/ReservationInfos';
import { FileDropComponent } from '../../../utils/components/file-drop/file-drop.component';
import { InputDateComponent } from '../../../utils/components/input-date/input-date.component';

@Component({
  selector: 'app-step-two-form-reservation',
  templateUrl: './step-two-form-reservation.component.html',
  styleUrls: ['./step-two-form-reservation.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatCardModule,
    InputDateComponent,
    FileDropComponent,
    MatFormFieldModule,
    MatTooltipModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatInputModule,
  ],
})
export class StepTwoFormReservationComponent implements OnDestroy, OnInit {
  private static readonly OTHER_MOTIVE_ID = 10;

  /**
   * parentForm input
   *
   * @type {FormGroup}
   * @memberof StepTwoFormReservationComponent
   */
  public stepTwoForm: UntypedFormGroup;

  /**
   * parentForm input
   *
   * @type {FormGroup}
   * @memberof StepTwoFormReservationComponent
   */
  @Input() resaInformation: ReservationInfos;

  @Output() readonly formGroupChanged: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

  public isUserValorrisimo = false;
  public isNotarySignatureObtained = false;
  public showOtherWithdrawMotive = false;

  public isReservationWithdrawn: boolean;
  public withdrawMotives: Array<{ id: number; label: string }>;

  public initialWithdrawDocument: DocumentResponse;
  public isCanceled: boolean;
  public reservationDocument: DocumentResponse;
  public reservationAmmendmentDocument: DocumentResponse;
  public documentTypes: Record<string, string>;
  /**
   * isAcceptedDeveloperValueChangesSubscription attribute
   *
   * @type {Subscription}
   * @memberof StepTwoFormReservationComponent
   */
  private isAcceptedDeveloperValueChangesSubscription: Subscription;
  private readonly tearDownSubscription: Subscription = new Subscription();

  constructor(
    public i18nService: I18nService,
    private readonly tokenService: TokenService,
    private readonly appConfigService: AppConfigService,
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  get isAcceptedDeveloper(): UntypedFormControl {
    return this.stepTwoForm.get('isAcceptedDeveloper') as UntypedFormControl;
  }

  get developerReservationRejectionDate(): UntypedFormControl {
    return this.stepTwoForm.get('developerReservationRejectionDate') as UntypedFormControl;
  }

  get developerReservationValidationDate(): UntypedFormControl {
    return this.stepTwoForm.get('developerReservationValidationDate') as UntypedFormControl;
  }

  get notarySignatureDate(): UntypedFormControl {
    return this.stepTwoForm.get('notarySignatureDate') as UntypedFormControl;
  }

  get reservationDesistedDate(): UntypedFormControl {
    return this.stepTwoForm.get('reservationDesistedDate') as UntypedFormControl;
  }

  get valoTrackingComments(): UntypedFormControl {
    return this.stepTwoForm.get('valoTrackingComments') as UntypedFormControl;
  }

  get withdrawMotive(): UntypedFormControl {
    return this.stepTwoForm.get('withdrawMotive') as UntypedFormControl;
  }

  get withdrawOtherMotive(): UntypedFormControl {
    return this.stepTwoForm.get('withdrawOtherMotive') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.documentTypes = this.appConfigService.getAppConfig().documentTypes;
    this.isCanceled =
      this.appConfigService.getAppConfig().reservationStatus.reservationCanceled === this.resaInformation.reservationStatus.label;
    this.reservationDocument = this.resaInformation.document;
    this.reservationAmmendmentDocument = this.resaInformation.reservationAmmendmentDocument;
    const reservationStatus = this.appConfigService.getAppConfig().reservationStatus;
    const isAcceptedDeveloper = this.resaInformation.developerReservationValidationDate
      ? true
      : this.resaInformation.developerReservationRejectionDate
      ? false
      : undefined;
    this.isReservationWithdrawn = !!this.resaInformation.reservationDesistedDate;
    const cannotEditNotarySignatureDate = [
      reservationStatus.rejectedDeveloper,
      reservationStatus.clientDesisted,
      reservationStatus.reservationCanceled,
    ].includes(this.resaInformation.reservationStatus.label);

    this.isNotarySignatureObtained = this.resaInformation.reservationStatus.label === reservationStatus.notarySignatureObtained;
    this.stepTwoForm = new UntypedFormGroup({
      valoReservationFileReceiptDate: new UntypedFormControl(this.resaInformation.valoReservationFileReceiptDate, []),
      valoReservationValidationDate: new UntypedFormControl(this.resaInformation.valoReservationValidationDate, []),
      valoReservationSentDate: new UntypedFormControl(this.resaInformation.valoReservationSentDate, []),
      isAcceptedDeveloper: new UntypedFormControl(isAcceptedDeveloper, []),
      developerReservationValidationDate: new UntypedFormControl(
        {
          disabled: this.isNotarySignatureObtained,
          value: this.resaInformation.developerReservationValidationDate,
        },
        [],
      ),
      developerReservationRejectionDate: new UntypedFormControl(
        {
          disabled: this.isNotarySignatureObtained,
          value: this.resaInformation.developerReservationRejectionDate,
        },
        [],
      ),
      sruResevationNotificationDate: new UntypedFormControl(this.resaInformation.sruResevationNotificationDate, []),
      principleAgreementDate: new UntypedFormControl(this.resaInformation.principleAgreementDate, []),
      loanOfferDate: new UntypedFormControl(this.resaInformation.loanOfferDate, []),
      notaryMeetingDate: new UntypedFormControl(this.resaInformation.notaryMeetingDate, []),
      estimatedSaleDate: new UntypedFormControl(this.resaInformation.estimatedSaleDate, []),
      notarySignatureDate: new UntypedFormControl(
        {
          disabled: cannotEditNotarySignatureDate,
          value: this.resaInformation.notarySignatureDate,
        },
        [],
      ),
      reservationDesistedDate: new UntypedFormControl(
        {
          disabled: this.isNotarySignatureObtained,
          value: this.resaInformation.reservationDesistedDate,
        },
        [],
      ),
      valoTrackingComments: new UntypedFormControl(this.resaInformation.valoTrackingComments, []),
      withdrawMotive: new UntypedFormControl(
        {
          disabled: this.resaInformation.reservationDesistedDate,
          value: this.resaInformation.withdrawId,
        },
        [],
      ),
      withdrawOtherMotive: new UntypedFormControl(
        {
          disabled: this.resaInformation.reservationDesistedDate,
          value: this.resaInformation.withdrawOtherMotive,
        },
        [],
      ),
    });
    this.showOtherWithdrawMotive = !!this.resaInformation.withdrawOtherMotive;

    this.isAcceptedDeveloperValueChangesSubscription = this.isAcceptedDeveloper.valueChanges.subscribe((value) => {
      // Update values and validators of developerReservationValidationDate and developerReservationRejectionDate controls
      // depending on value of isAcceptedDeveloper control
      if (!value && this.notarySignatureDate.value) {
        this.isAcceptedDeveloper.setValue(true);
      } else if (value) {
        this.developerReservationValidationDate.setValue(this.developerReservationRejectionDate.value);
        this.developerReservationRejectionDate.setValue(undefined);
        this.developerReservationRejectionDate.clearValidators();
        this.developerReservationRejectionDate.updateValueAndValidity();
        this.onDeveloperReservationRejectionDateValueChange(undefined);
      } else {
        this.developerReservationRejectionDate.setValue(this.developerReservationValidationDate.value);
        this.developerReservationValidationDate.setValue(undefined);
        this.developerReservationValidationDate.clearValidators();
        this.developerReservationValidationDate.updateValueAndValidity();
        this.onDeveloperReservationRejectionDateValueChange(this.developerReservationRejectionDate.value);
      }
    });

    if (reservationStatus.reservationCanceled === this.resaInformation.reservationStatus.label) {
      this.stepTwoForm.disable();
    }

    this.initialWithdrawDocument = this.resaInformation.withdrawDocument;

    this.formGroupChanged.emit(this.stepTwoForm);

    // List of valoRole name need to see stepFour
    const rolesList = this.appConfigService.appConfig.roles;
    const valoAdminAccountList = [
      rolesList.valoSystem,
      rolesList.valoAdminSuper,
      rolesList.valoAdminSimple,
      rolesList.valoBoSuper,
      rolesList.valoBoSimple,
      rolesList.valoRcvSuper,
      rolesList.valoRcvSimple,
      rolesList.valoDevSuper,
      rolesList.valoDevSimple,
      rolesList.valoMarketerSuper,
      rolesList.valoMarketerSimple,
    ];

    // Check is the user is a valo user
    if (this.tokenService.checkIsLoggedIn() && valoAdminAccountList.includes(this.tokenService.getRoleName())) {
      this.isUserValorrisimo = true;
    }

    this.fetchWithdrawMotives();
  }

  /**
   * onDeveloperReservationRejectionDateValueChange method
   * Disable or enable notarySignatureDate control
   *
   * @param event - Moment date
   * @memberof StepTwoFormReservationComponent
   */
  public onDeveloperReservationRejectionDateValueChange(event: Moment): void {
    if (event && !this.isAcceptedDeveloper.value) {
      this.notarySignatureDate.disable();
    } else {
      this.notarySignatureDate.enable();
    }
  }

  /**
   * onNotarySignatureDateValueChange method
   * Disable or enable developerReservationValidationDate, developerReservationRejectionDate and reservationDesistedDate controls
   *
   * @param event - Moment date
   * @memberof StepTwoFormReservationComponent
   */
  public onNotarySignatureDateValueChange(event: Moment): void {
    if (event) {
      this.developerReservationValidationDate.disable();
      this.developerReservationRejectionDate.disable();
      this.reservationDesistedDate.disable();
    } else {
      this.developerReservationValidationDate.enable();
      this.developerReservationRejectionDate.enable();
      this.reservationDesistedDate.enable();
    }
  }

  /**
   * onReservationDesistedDateValueChange method
   * Disable or enable notarySignatureDate control
   *
   * @param event - Moment date
   * @memberof StepTwoFormReservationComponent
   */
  public onReservationDesistedDateValueChange(event: Moment): void {
    this.isReservationWithdrawn = !!event;
    if (event) {
      this.stepTwoForm.controls.notarySignatureDate.disable();
      if (!this.resaInformation.reservationDesistedDate) {
        this.stepTwoForm.controls.withdrawMotive.enable();
        this.stepTwoForm.controls.withdrawMotive.setValue(undefined);
        this.stepTwoForm.controls.withdrawMotive.setValidators(Validators.required);
        this.stepTwoForm.controls.withdrawMotive.updateValueAndValidity();
      }
    } else {
      this.stepTwoForm.controls.notarySignatureDate.enable();
      this.stepTwoForm.controls.withdrawMotive.setValue(undefined);
      this.stepTwoForm.controls.withdrawMotive.clearValidators();
      this.stepTwoForm.controls.withdrawMotive.disable();
      this.clearWithdrawOtherMotive();
    }
    this.cdRef.detectChanges();
  }

  public withdrawMotiveSelectionChange(event: MatSelectChange): void {
    if (event.value === StepTwoFormReservationComponent.OTHER_MOTIVE_ID) {
      this.showOtherWithdrawMotive = true;
      this.stepTwoForm.controls.withdrawOtherMotive.setValidators(Validators.required);
    } else {
      this.showOtherWithdrawMotive = false;
      this.clearWithdrawOtherMotive();
    }
    this.cdRef.detectChanges();
  }

  /**
   * ngOnDestroy method
   *
   * @memberof StepTwoFormReservationComponent
   */
  ngOnDestroy(): void {
    this.isAcceptedDeveloperValueChangesSubscription.unsubscribe();
    this.tearDownSubscription.unsubscribe();
  }

  clearFormValue(formPathToCheck: string, formPathToClear: string) {
    if (!FormUtilsService.isValid(formPathToCheck, this.stepTwoForm)) {
      FormUtilsService.setFormValue(formPathToClear, this.stepTwoForm, undefined);
    }
  }

  isFormValid(formPath: string) {
    return !!FormUtilsService.getFormValue(formPath, this.stepTwoForm);
  }

  private fetchWithdrawMotives(): void {
    this.tearDownSubscription.add(
      this.referenceTablesService
        .getTableReferenceInfo('ReservationWithdrawMotives')
        .pipe(
          map(
            (response): Array<{ id: number; label: string }> =>
              response.map((entry) => {
                return {
                  id: entry.id,
                  label: this.i18nService._(entry.label),
                };
              }),
          ),
        )
        .subscribe((response) => (this.withdrawMotives = response)),
    );
  }

  private clearWithdrawOtherMotive(): void {
    this.stepTwoForm.controls.withdrawOtherMotive.setValue(undefined);
    this.stepTwoForm.controls.withdrawOtherMotive.clearValidators();
    this.stepTwoForm.controls.withdrawOtherMotive.updateValueAndValidity();
  }
}
