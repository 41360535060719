<div class="container-fullHeight">
  <div *ngIf="dataIsLoad" class="flex flex-col h-full">
    <app-sticky-header-form [goBackUrl]="goBackPath" [defaultGoBackUrl]="defaultGoBackUrl" [titleText]="i18nService._(pageTitle)">
    </app-sticky-header-form>

    <div class="grid grid-cols-10 pt-16">
      <div class="col-span-10 lg:col-span-8 lg:col-start-2 xl:col-span-6 xl:col-start-3 flex flex-col pt-16">
        <form [formGroup]="registerCompany">
          <mat-stepper
            orientation="vertical"
            #stepper
            (selectionChange)="selectionChange($event.selectedIndex)"
            [@.disabled]="true"
            [linear]="true"
            appMatVerticalStepper
            class="mb-5"
          >
            <ng-template matStepperIcon="edit">
              <span class="icon-validate-small"></span>
            </ng-template>

            <!-- Formulaire Company -->
            <mat-step [stepControl]="companyForm">
              <ng-template matStepLabel>
                {{ i18nService._('Title_Company') }}
              </ng-template>
              <app-step-one-form-company
                [isDeveloper]="isDeveloper"
                [parentForm]="companyForm"
                [recoveredInfo]="recoveredInfo"
                class="flex flex-col"
              ></app-step-one-form-company>
            </mat-step>

            <!-- Formulaire Contacts -->
            <mat-step [stepControl]="contacts" data-cy="{{ i18nService._('Title_LegalRepresentative') }}">
              <ng-template matStepLabel>
                {{ i18nService._('Title_LegalRepresentative') }}
              </ng-template>
              <app-step-two-form-company
                [parentForm]="contacts"
                [recoveredInfo]="recoveredInfo"
                [validatedCompany]="validatedCompany"
                class="flex flex-col"
              ></app-step-two-form-company>
            </mat-step>

            <!-- Formulaire Document -->
            <mat-step [stepControl]="documentsForm">
              <ng-template matStepLabel>
                {{ i18nService._('Title_DocumentsRequired') }}
              </ng-template>
              <form [formGroup]="documentsForm">
                <!--TODO replace isMandatDelegation with good boolean-->
                <app-step-three-form-company
                  [isDeveloper]="isDeveloper"
                  [isValoRole]="isValoRole"
                  [parentForm]="documentsForm"
                  [recoveredInfo]="recoveredDocuments"
                  class="flex flex-col"
                >
                </app-step-three-form-company>
              </form>

              <div *ngIf="!isValoRole" class="flex flex-row justify-start">
                <div class="my-5 mr-4">
                  <button
                    (click)="updateCompanyStatus(appConfig.status.pending)"
                    [disabled]="disableSubmit"
                    color="primary"
                    mat-flat-button
                    type="button"
                    data-cy="submit"
                  >
                    {{ i18nService._('Txt_Button_Submit') }}
                  </button>
                </div>
              </div>
            </mat-step>

            <!-- Formulaire Valorissimo -->
            <mat-step *ngIf="isValoRole">
              <ng-template matStepLabel>
                {{ i18nService._('Title_Valorissimo') }}
              </ng-template>
              <form [formGroup]="adminValoForm">
                <app-step-four-form-company
                  [isDeveloper]="isDeveloper"
                  [isSubmit]="isSubmitFinal"
                  [parentForm]="adminValoForm"
                  [recoveredInfo]="recoveredInfo"
                  [validatedCompany]="validatedCompany"
                  class="flex flex-col"
                >
                </app-step-four-form-company>
              </form>

              <div class="flex flex-row justify-start">
                <div class="my-5 mr-4">
                  <ng-container *ngIf="validatedCompany; else elseBlock">
                    <button
                      (click)="onSubmit(appConfig.status.valid)"
                      [disabled]="disableSubmit"
                      class="mr-3 mb-3"
                      color="primary"
                      mat-flat-button
                      type="button"
                    >
                      {{ i18nService._('Txt_Button_UpdateCompany') }}
                    </button>
                  </ng-container>

                  <ng-template #elseBlock>
                    <button
                      (click)="updateCompanyStatus(appConfig.status.valid)"
                      [disabled]="disableSubmit"
                      class="mr-3 mb-3"
                      color="primary"
                      mat-flat-button
                      type="button"
                      data-cy="acceptCompany"
                    >
                      {{ i18nService._('Txt_Button_AcceptCompany') }}
                    </button>
                    <button
                      (click)="openDialog(modalType.DENIED)"
                      [disabled]="disableSubmit"
                      class="mr-3 mb-3"
                      color="primary"
                      mat-stroked-button
                      type="button"
                      data-cy="rejectCompany"
                    >
                      {{ i18nService._('Txt_Button_RejectCompany') }}
                    </button>
                    <button
                      (click)="openDialog(modalType.INCOMPLETE)"
                      [disabled]="disableSubmit"
                      class="mr-3 mb-3"
                      color="primary"
                      mat-stroked-button
                      type="button"
                    >
                      {{ i18nService._('Txt_Button_IncompleteCompany') }}
                    </button>
                  </ng-template>

                  <ng-container *ngIf="isModif">
                    <button
                      (click)="onSubmit()"
                      [disabled]="disableSubmit"
                      class="mr-3 mb-3"
                      color="primary"
                      mat-stroked-button
                      type="button"
                    >
                      {{ i18nService._('Txt_Button_SaveModificationCompany') }}
                    </button>
                  </ng-container>
                </div>
              </div>
            </mat-step>
          </mat-stepper>
          <app-recaptcha *ngIf="computeRecaptcha"></app-recaptcha>
        </form>
      </div>
    </div>
  </div>
</div>
