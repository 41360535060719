<div class="flex flex-col w-full h-full overflow-auto">
  <!-- Sticky subHeader form -->
  <app-sticky-header-form
    (actionButtonClicked)="redirectionProgramCreation()"
    [actionButtonIconMob]="displayNewProgButton ? 'lg:hidden icon-plus text-sm font-bold pl-lg-2 inline-block align-middle' : null"
    [actionButtonIcon]="displayNewProgButton ? 'icon-plus text-sm pb-1 font-bold pl-2 inline-block align-middle' : null"
    [actionButtonLabel]="displayNewProgButton ? i18nService._('Title_CommercialOperation') : null"
    [titleText]="i18nService._('Title_Program')"
  >
  </app-sticky-header-form>

  <div class="pt-18 mx-3 lg:mx-5 md:mx-5 sm:mx-4 px-2 lg:px-6 md:px-5 sm:px-3">
    <div class="grid grid-cols-8 gap:4 lg:gap-6">
      <div class="col-span-2">
        <mat-form-field class="fullW flex w-full">
          <mat-label>Recherche</mat-label>
          <input data-cy="search" [(ngModel)]="filterValue" (ngModelChange)="textFilterSubject.next($event)" matInput />
        </mat-form-field>
      </div>

      <mat-form-field class="fullW flex w-full my-0">
        <mat-label>Statut</mat-label>
        <mat-select data-cy="status" [ngModel]="statusFilter" (selectionChange)="onStatusListChange($event)" multiple>
          <mat-option *ngFor="let status of ProgramStatusEnum | keyvalue" [value]="status.key" [attr.data-cy]="status.key">
            {{ i18nService._('PROGRAM_' + status.key) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="tracking-mat-table">
      <app-grid-tracking-table
        [tableLoader]="programsTrackingTableService"
        [columnDefs]="columnDefs"
        [pageSize]="pageSize"
        [textFilter]="textFilterValue"
        [statusFilter]="statusFilter"
      >
      </app-grid-tracking-table>
    </div>
  </div>
</div>
