// Angular Deps
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Third-party libraries
import { Subject, takeUntil } from 'rxjs';
import * as mapboxgl from 'mapbox-gl';
import { GeoJSONSource, LngLatBounds, LngLatBoundsLike, LngLatLike, Map, MapboxGeoJSONFeature, MapLayerMouseEvent } from 'mapbox-gl';
import { MapComponent, NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { FeatureCollection, Point } from 'geojson';
import distance from '@turf/distance';
import { Units } from '@turf/helpers';
// Services
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgIf } from '@angular/common';

import { ProgramPageService } from '../../../programs/services/program-page.service';
import { BasicFormatsService } from '../../../utils/services/basic-formats.service';
import { ProgramService } from '../../../programs/services/program.service';
import { SearchFormUtilsService } from '../../../search/services/search-form-utils.service';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { ValorissimoMapService } from '../../services/valorissimo-map.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { ConfigurableParamService } from '../../../utils/services/configurable-param.service';
// Models
import { ProgramDetailResponse } from '../../../utils/models/ProgramDetailResponse';
import { AbstractValorissimoMapComponent } from '../../models/AbstractValorissimoMapComponent';
import {
  FRANCE_COORDINATES,
  HOVERED_PROGRAM_MARKER_LAYER,
  HOVERED_PROGRAM_SOURCE_ID,
  PROGRAM_CLUSTER_COUNT_LAYER,
  PROGRAM_CLUSTER_LAYER,
  PROGRAM_MARKER_LAYER,
  PROGRAM_SOURCE_ID,
} from '../../models/map-init-data';
import { Sitemap } from '../../../utils/models/Sitemap';
import { ProgramSearchResponse } from '../../../utils/models/ProgramSearchResponse';
import { tagClass, TagTypeEnum } from '../../../design-system/model/tag-option';
// Components
import { TagsInterfaceExtended } from '../../../programs/components/program-page-v2/program-page-main-infos/program-page-main-infos.component';
// Enums
import { AnchorEnum } from '../../../programs/models/enum/anchor.enum';
import { mapHttpParams } from '../../../utils/enums/map.enum';
import { LocationData } from '../../../utils/models/LocationData';
import { TagsComponent } from '../../../design-system/component/tags/tags.component';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-valorissimo-main-map',
  templateUrl: './main-map.component.html',
  styleUrls: ['./main-map.component.scss'],
  standalone: true,
  imports: [NgIf, MatIconModule, NgxMapboxGLModule, TagsComponent, NgClass, MatCheckboxModule, FormsModule],
})
export class MainMapComponent extends AbstractValorissimoMapComponent implements OnChanges, OnInit, OnDestroy {
  @Input() programs: Array<ProgramSearchResponse>;
  @ViewChild('valorissimoMainMap', { static: false }) valorissimoMapComponent: MapComponent;
  public showError = false;
  public showCompass = false;
  public showMapControls = false;
  public geoProgramsData: FeatureCollection;
  public geoProgramsDataForHover: FeatureCollection;
  public selectedPoint: MapboxGeoJSONFeature | null;
  public programMarkerLayer = PROGRAM_MARKER_LAYER;
  public hoveredProgramMarkerLayer = HOVERED_PROGRAM_MARKER_LAYER;
  public programClusterLayer = PROGRAM_CLUSTER_LAYER;
  public programClusterCountLayer = PROGRAM_CLUSTER_COUNT_LAYER;
  public haveCenterControl = false;
  public haveRefreshWhileMovingControl = true;
  public isChecked = false;
  private perfectBounds: LngLatBounds;
  private readonly _destroy$: Subject<boolean> = new Subject<boolean>();
  private isMapPriority = false;

  constructor(
    public readonly router: Router,
    private readonly route: ActivatedRoute,
    public readonly i18nService: I18nService,
    private readonly _appConfigService: AppConfigService,
    private readonly _searchFormUtilsService: SearchFormUtilsService,
    public readonly basicFormatsService: BasicFormatsService,
    private readonly _programPageService: ProgramPageService,
    private readonly _valorissimoMapService: ValorissimoMapService,
    private readonly _programService: ProgramService,
    private readonly _route: ActivatedRoute,
    private readonly _configurableParamService: ConfigurableParamService,
  ) {
    super();
    this.geoProgramsData = {
      type: 'FeatureCollection',
      features: [],
    };
    this.center = FRANCE_COORDINATES.center as LngLatLike;
    this.bounds = FRANCE_COORDINATES.bbox as LngLatBoundsLike;
    this.zoom = [9];
    this.maxZoom = 19.6;
    this.minZoom = 4.6;
    this.cursorStyle = '';
  }

  get tagSpecialOffer(): TagsInterfaceExtended {
    return {
      ngClass: tagClass[TagTypeEnum.SPECIAL_OFFER],
      tagOption: {
        text: this.i18nService._('Txt_Special_Offer'),
        type: TagTypeEnum.SPECIAL_OFFER,
      },
      anchor: AnchorEnum.map,
    };
  }

  get tagNouveau(): TagsInterfaceExtended {
    return {
      ngClass: tagClass[TagTypeEnum.SEMI_RADIUS],
      tagOption: {
        text: this.i18nService._('Txt_page_program_prospective_tag_label'),
        type: TagTypeEnum.SEMI_RADIUS,
      },
      anchor: AnchorEnum.map,
    };
  }

  ngOnInit(): void {
    this._configurableParamService
      .getConfigParam(['MAPBOX_TOKEN', 'MAPBOX_STYLE'])
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: ((configParam: Record<string, string>) => {
          if (!configParam || !configParam['MAPBOX_TOKEN'] || !configParam['MAPBOX_STYLE']) {
            this.showError = true;
            console.error('[MainMapComponent] Missing Mapbox token or style');
            return;
          }

          this.accessToken = configParam.MAPBOX_TOKEN;
          this.style = configParam.MAPBOX_STYLE;
        }).bind(this),
        error: ((error) => {
          this.showError = true;
          console.error('[MainMapComponent] Error while getting config params', error);
        }).bind(this),
      });

    this._route.queryParams.pipe(takeUntil(this._destroy$)).subscribe((params) => {
      this.isMapPriority = params['priority'] === mapHttpParams.MAP;
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Refresh Map when detecting changes in programs input and this change is not the first when component just load
    if (changes.programs && this.map) {
      if (!this.isMapPriority) {
        this.refreshMap({ firstLoad: true });
      }
      // Refresh Map when detecting changes in programs input and this change is not the first when component just load
      this.refreshMap({ firstLoad: false });
    }
  }

  refreshMap({ firstLoad = false }): void {
    this.cursorStyle = '';
    // The only way to redraw map is to call setData
    (this.map.getSource(PROGRAM_SOURCE_ID) as GeoJSONSource).setData(this.getGeoJsonData());

    // After a change we will adjust the map at the perfect zoom and bounds to contains all markers
    this.centerOnCoordinates(firstLoad);
  }

  mapLoaded($event: Map): void {
    console.log(`[mapLoaded] ${$event}`);
    this.map = $event;
    this.refreshMap({ firstLoad: true });
    this.showError = false;
    this.showMapControls = true;
    // Define event handler in the service
    this._valorissimoMapService.defineMapEventsHandlers($event);
    // Add scale to map
    this.map.addControl(new mapboxgl.ScaleControl(), 'bottom-right');
  }

  reloadPage(): void {
    this.router.navigate([this.router.url]);
  }

  showErrorPopup($event): void {
    console.warn(`[showErrorPopup] ${$event.error}`);
    if ($event.error.message === 'Expected varint not more than 10 bytes') {
      // TODO: try to understand why this error is thrown ( probably because it is due to cluster layer and babel transpilation issue)
      return;
    }
    this.showError = true;
  }

  logStyleImageMissing($event): void {
    console.warn(`[styleImageMissing] ${$event.id}`);
  }

  getGeoJsonData(): FeatureCollection {
    // This attribute will be used to fit the map after data change, so map will contain all markers with perfect zoom and bounds
    this.perfectBounds = new LngLatBounds();
    this.geoProgramsData.features = [];
    this.geoProgramsData.type = 'FeatureCollection';
    if (this.programs) {
      this.programs.forEach((program) => {
        // After a change we will adjust the map at the perfect zoom and bounds to contains all markers
        this.perfectBounds.extend([program.longitude, program.latitude]);
        this.geoProgramsData.features.push({
          type: 'Feature',
          id: program.programId,
          geometry: {
            type: 'Point',
            coordinates: [program.longitude, program.latitude],
          },
          properties: {
            id: program.programId,
            programName: program.programName,
            logo: program.perspectiveFileName
              ? `${this._appConfigService.getFileSystemUrl()}/${program.perspectiveContainer}/${program.perspectiveFileName}`
              : '',
            deliveryDate: this._programPageService.getDeliveryDate(program.minDeliveryDate, program.maxDeliveryDate),
            price: this._programPageService.getMinMaxPrice(program.price, program.maxPrice),
            nbSpecialOffers: program.nbSpecialOffers,
            nbLots: program.nbLotsAvailable + program.nbLotsOptionned + program.nbLotsNotAvailable,
            isNew: program.publishedDate && this._programPageService.isNewProgram(program),
          },
        });
      });
    }

    return this.geoProgramsData;
  }

  openProgramInformationPopup(evt: MapLayerMouseEvent): void {
    if (!evt.features[0].properties.cluster) {
      this.selectedPoint = evt.features[0];
      this.changeMarkerStyleOnMouseEnter(evt);
    }
    this._valorissimoMapService.pushClicked('map_select_item');
  }

  closeProgramInformationPopup(): void {
    this.selectedPoint = null;
    this.changeMarkerStyleOnMouseLeave();
  }

  redirectToProgram(idProgram: number): void {
    this._programService.getProgram(idProgram).subscribe((infos) => {
      const program: ProgramDetailResponse = infos;
      this._valorissimoMapService.pushClicked('map_select_program', program);
    });
    window.open(`/${Sitemap.programs.read.path.replace(/:programId/, String(idProgram))}`, '_blank');
  }

  inspectProgramsCluster($event: MapLayerMouseEvent): void {
    const features = this.map.queryRenderedFeatures($event.point, { layers: [this.programClusterLayer.id] });
    const clusterSource = this.map.getSource(PROGRAM_SOURCE_ID) as GeoJSONSource;
    const geometry = $event.features[0].geometry as Point;
    const clusterId = features[0].properties.cluster_id;
    const coordinates = geometry.coordinates.slice() as LngLatLike;

    // Zoom on cluster with easeTo to show children when clicked
    clusterSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) {
        return;
      }
      this.map.easeTo({ center: coordinates, zoom }, { byFitBounds: true });
    });
    this.closeProgramInformationPopup();
  }

  changeMarkerStyleOnMouseEnter($event: MapLayerMouseEvent): void {
    if (!$event.features[0].properties.cluster) {
      // Add cursor style
      this.cursorStyle = 'pointer';

      if (!this.selectedPoint || this.selectedPoint.id === $event.features[0].properties.id) {
        // Add the only feature (program that was hovered) to source
        (this.map.getSource(HOVERED_PROGRAM_SOURCE_ID) as GeoJSONSource).setData({
          type: 'FeatureCollection',
          features: [$event.features[0]],
        });
        // If Popup is not open we show the hovered layer to see hovered color
        this.map.setLayoutProperty(this.hoveredProgramMarkerLayer.id, 'visibility', 'visible');
      }
    }
  }

  changeMarkerStyleOnMouseLeave(): void {
    // Remove cursor style
    this.cursorStyle = '';
    // If Popup is closed we hide the hovered layer
    if (!this.selectedPoint) {
      this.map.setLayoutProperty(this.hoveredProgramMarkerLayer.id, 'visibility', 'none');
    }
  }

  displayOrRemoveControls($event): void {
    if (this.isChecked) {
      this.deleteLocations();
      this.updateHttpParams();
    }
    this.haveRefreshWhileMovingControl = this.isChecked ? true : $event.byFitBounds;
    if (!$event.byFitBounds && this.isChecked) {
      this.lunchSearchOnRefreshClick();
    }
    // If we don't have result don't need to show control
    if (this.programs.length) {
      const centerControlConfig = this.getCenterControlConfig();
      const distanceAfterMapMoved = distance(this.map.getCenter().toArray(), this.perfectBounds.getCenter().toArray(), {
        units: centerControlConfig.units,
      });

      if (distanceAfterMapMoved >= centerControlConfig.distanceToShowCenterControl && !this.haveCenterControl) {
        this.haveCenterControl = true;
      } else if (distanceAfterMapMoved < centerControlConfig.distanceToShowCenterControl && this.haveCenterControl) {
        this.haveCenterControl = false;
      }
    }
  }

  centerOnCoordinates(forceCenter = false) {
    if (!this.perfectBounds.isEmpty()) {
      if (forceCenter) {
        // Each time we refresh the map we update the perfectBounds attribute in our custom control
        this.map.fitBounds(this.getBoundingBox(this.perfectBounds), { padding: 30, maxZoom: 17.6 }, { byFitBounds: true });
      }
      this.haveRefreshWhileMovingControl = true;
    }
  }

  updateHttpParams(): void {
    this.router.navigate([Sitemap.utils.search.path], {
      queryParams: {
        ...this.route.snapshot.queryParams,
        bbox: this.map && this.map.getBounds() ? this.map.getBounds().toArray().join(',') : '',
        priority: this.isChecked ? mapHttpParams.MAP : mapHttpParams.FILTERS,
      },
      replaceUrl: true,
    });
  }

  lunchSearchOnRefreshClick(): void {
    this.deleteLocations();
    this._searchFormUtilsService.dispatchSearchByMapEvent(
      this.map.getBounds().toArray().join(','),
      mapHttpParams.MAP,
      this.route.snapshot.queryParams,
    );
  }

  onCheckMovingControl($event) {
    if ($event.checked) {
      this._valorissimoMapService.pushClicked('mapRefresh');
    }
  }

  deleteLocations() {
    const searchPrograms = this._searchFormUtilsService.searchFilter;

    // Reset locations
    searchPrograms.where.localisations = new LocationData();
    searchPrograms.where.localisationsList = [];
    searchPrograms.where.localisationsMapList = [];

    this._searchFormUtilsService.setSearchFilter({ where: searchPrograms.where }, false);
  }

  private getCenterControlConfig(): { distanceToShowCenterControl: number; units: Units } {
    if (this.map) {
      // We are using the algorithm : 2 * (map_scale distance) to show the center control
      const mapScale = this.map.getContainer().querySelector('.mapboxgl-ctrl-scale').textContent;
      if (mapScale.indexOf('km') >= 0) {
        // remove 'km' from mapScale example '20m'
        return {
          distanceToShowCenterControl: Number(mapScale.slice(0, -2)) * 2,
          units: 'kilometers',
        };
      }
      // remove 'm' from mapScale example '20m'

      return {
        distanceToShowCenterControl: Number(mapScale.slice(0, -1)) * 2,
        units: 'meters',
      };
    }
  }

  private getBoundingBox(perfectBounds): [number, number, number, number] {
    const bbox: [number, number, number, number] = [
      perfectBounds.getWest(),
      perfectBounds.getSouth(),
      perfectBounds.getEast(),
      perfectBounds.getNorth(),
    ];
    return bbox;
  }
}
