import { Component } from '@angular/core';
import { NgIf } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';

import { AbstractSearchFilter } from '../abstract-search-filter';

import { I18nService } from '../../../../utils/services/i18n.service';
import { UserRoleService } from '../../../../utils/services/user-role.service';

@Component({
  selector: 'app-search-filter-tags',
  templateUrl: './search-filter-tags.component.html',
  styleUrls: ['./search-filter-tags.component.scss'],
  standalone: true,
  imports: [MatChipsModule, NgIf],
})
export class SearchFilterTagsComponent extends AbstractSearchFilter {
  public isValo: boolean;

  constructor(
    public readonly i18nService: I18nService,
    private readonly userRoleService: UserRoleService,
  ) {
    super();
    this.isValo = false;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    super.ngOnInit();
    this.isValo = this.userRoleService.isValo();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected initData() {}
}
