import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { LotDetailResponse } from 'apps/valo-front/src/app/utils/models/LotDetailResponse';
import { LotFormatDetailResponse } from 'apps/valo-front/src/app/utils/models/LotFormatDetailResponse';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { StrongPointListComponent } from 'apps/valo-front/src/app/design-system/component/strong-point-list/strong-point-list.component';
import { SafePipe } from 'apps/valo-front/src/app/utils/pipes/safe.pipe';

import { AbstractProgramPageLot } from '../abstract-program-page-lot';
import { ProgramPageLotOptionComponent } from '../program-page-lot-option/program-page-lot-option.component';

import { OfferSpecialDialogComponent } from '../../../../../dialog/components/offer-special-dialog/offer-special-dialog.component';
import { LotAction } from '../../../../../lot/model/lot-action';
import { breakpoints } from '../../../../../utils/models/breakpoint';
import { BreakpointEnum } from '../../../../../utils/models/enums/breakpoint.enum';
import { SpecialOfferDetailResponse } from '../../../../../utils/models/SpecialOfferDetailResponse';
import { I18nService } from '../../../../../utils/services/i18n.service';
import { AppConfigService } from '../../../../../utils/services/app-config.service';
import { TagsComponent } from '../../../../../design-system/component/tags/tags.component';

@Component({
  selector: 'app-program-page-lot-header',
  templateUrl: './program-page-lot-header.component.html',
  styleUrls: ['./program-page-lot-header.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, TagsComponent, NgClass, ProgramPageLotOptionComponent, StrongPointListComponent, SafePipe],
})
export class ProgramPageLotHeaderComponent extends AbstractProgramPageLot implements AfterViewInit {
  public canChangeReservationComponentPosition = false;
  public lotAction: LotAction = new LotAction();
  public profitabilityValue = '-';

  constructor(
    public readonly i18nService: I18nService,
    private readonly dialog: MatDialog,
    private readonly _breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    private readonly appConfig: AppConfigService,
  ) {
    super();
  }

  ngAfterViewInit() {
    Promise.resolve().then(() => {
      this._updateReservationComponentHtmlLocation();
    });
  }

  override initData(lot?: LotDetailResponse, formattedLot?: LotFormatDetailResponse) {
    if (formattedLot) {
      this.profitabilityValue = this.calculateProfitabilityValue(); // Recalculer la rentabilité ici
    }
  }

  public openOffer(): void {
    const specialOffer = this.lot.specialOffers && this.lot.specialOffers.length ? this.lot.specialOffers[0] : false;
    if (specialOffer) {
      const data: MatDialogConfig<SpecialOfferDetailResponse> = {
        autoFocus: false,
        data: specialOffer,
      };
      this.dialog.open(OfferSpecialDialogComponent, data);
    }
  }

  public returnInfoLot(lotAction: LotAction): void {
    this.lotAction = lotAction;
  }

  private _updateReservationComponentHtmlLocation() {
    this._breakpointObserver
      .observe(breakpoints[BreakpointEnum.md].down)
      .pipe(takeUntil(this.$d))
      .subscribe((breakPoint) => {
        this.canChangeReservationComponentPosition = breakPoint.matches;
        this.cdr.detectChanges();
      });
  }

  public calculateProfitabilityValue(): string {
    const profitability = this.isTaxationLabelLMNPNONGERE()
      ? this.formattedLot.estimatedFurnishedMarketYield
      : this.formattedLot.profitability;

    const profitabilityAsNumber = parseFloat(profitability);

    if (isNaN(profitabilityAsNumber) || profitabilityAsNumber === Infinity || profitabilityAsNumber === -Infinity) {
      return '-';
    }

    return profitability;
  }

  private isTaxationLabelLMNPNONGERE(): boolean {
    const taxationLabels = this.appConfig.getAppConfig().taxationList;

    return this.formattedLot?.taxations?.some((taxation) => taxation.label === taxationLabels['LMNP NON GERE']);
  }
}
