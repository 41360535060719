import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { NgFor, NgIf } from '@angular/common';

import { AbstractProgramPageLot } from '../abstract-program-page-lot';

import { AppItem } from '../../../../../design-system/model/app-item';
import { LotDetailBlock } from '../../../../../lot/model/lot-detail-block';
import { AccountService } from '../../../../../utils/services/account.service';
import { LotDetailResponse } from '../../../../../utils/models/LotDetailResponse';
import { LotFormatDetailResponse } from '../../../../../utils/models/LotFormatDetailResponse';
import { ToggleFeesService } from '../../../../../utils/services/toggle-fees.service';
import { UserRoleService } from '../../../../../utils/services/user-role.service';
import { ProgramService } from '../../../../services/program.service';
import { SingleAccordionPanelComponent } from '../../../../../design-system/component/single-accordion-panel/single-accordion-panel.component';
import { ItemComponent } from '../../../../../design-system/component/item/item.component';

@Component({
  selector: 'app-program-page-lot-information',
  templateUrl: './program-page-lot-information.component.html',
  styleUrls: ['./program-page-lot-information.component.scss'],
  standalone: true,
  imports: [ItemComponent, NgIf, NgFor, SingleAccordionPanelComponent],
})
export class ProgramPageLotInformationComponent extends AbstractProgramPageLot implements OnInit, OnDestroy {
  public lotData: LotDetailBlock;
  public isValo: boolean;
  hideLotFees: boolean;
  feesCantBeSeen = true;

  constructor(
    private readonly toggleFeesService: ToggleFeesService,
    private readonly _userRoleService: UserRoleService,
    private readonly _accountService: AccountService,
    private readonly _programService: ProgramService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.isValo = this._userRoleService.isValo();
    this._accountService
      .checkFeesCantBeSeen()
      .pipe(takeUntil(this.$d))
      .subscribe((cantSeeFees) => {
        this.feesCantBeSeen = cantSeeFees;
      });
    this.toggleFeesService.toggleFeesState$.pipe(takeUntil(this.$d)).subscribe((state: boolean) => (this.hideLotFees = state));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  feesCanBeSeen() {
    return !this.hideLotFees && !this.feesCantBeSeen;
  }

  initData(lot?: LotDetailResponse, formattedLot?: LotFormatDetailResponse) {
    if (formattedLot) {
      this._programService.getNominalTaxationValue().subscribe((data) => {
        this.lotData = this._pageLotService.getInformationItems(formattedLot, data[0].taxation);
        // VALO-5142 : If user is VALO => hide fees
        (this.lotData.fees as AppItem).value = this.isValo ? '-' : (this.lotData.fees as AppItem).value;
      });
    }
  }
}
