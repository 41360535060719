import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { NgIf } from '@angular/common';

import { PageProgramHelperService } from '../page-program-helper.service';

import { breakpoints } from '../../../../utils/models/breakpoint';
import { BreakpointEnum } from '../../../../utils/models/enums/breakpoint.enum';
import { I18nService } from '../../../../utils/services/i18n.service';
import { ProgramTextResponse } from '../../../../utils/models/ProgramTextResponse';
import { ProgramDetail } from '../../../../utils/models/ProgramDetail';
import { SafePipe } from '../../../../utils/pipes/safe.pipe';

@Component({
  selector: 'app-program-page-promoter-infos',
  templateUrl: './program-page-promoter-infos.component.html',
  styleUrls: ['./program-page-promoter-infos.component.scss'],
  standalone: true,
  imports: [NgIf, SafePipe],
})
export class ProgramPagePromoterInfosComponent implements OnInit, OnDestroy {
  public canChangeDisplayMode: boolean;
  private readonly destroyed$: Subject<boolean> = new Subject();
  programText: ProgramTextResponse;
  program: ProgramDetail;
  companyLogo: string;

  constructor(
    public i18nService: I18nService,
    private readonly _breakpointObserver: BreakpointObserver,
    private pageProgramHelperService: PageProgramHelperService,
  ) {}

  ngOnInit() {
    this.program = this.pageProgramHelperService.program;
    this.programText = this.pageProgramHelperService.programText;
    this.companyLogo = this.pageProgramHelperService.companyLogo;
    this._canChangeDisplayMode();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private _canChangeDisplayMode() {
    this._breakpointObserver
      .observe(breakpoints[BreakpointEnum.md].down)
      .pipe(
        tap((breakPoint) => (this.canChangeDisplayMode = breakPoint.matches)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }
}
