import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { NgClass, NgFor, NgIf } from '@angular/common';

import { SmallStepperComponent } from '../../form/stepper/small-stepper/small-stepper.component';
import { I18nService } from './../../../utils/services/i18n.service';
import { IconComponent } from '../../standalone/icon/icon.component';
import { SmallStepperStepComponent } from '../../form/stepper/small-stepper/small-stepper-step/small-stepper-step.component';

@Component({
  selector: 'app-formly-small-stepper',
  templateUrl: './formly-small-stepper.component.html',
  styleUrls: ['./formly-small-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SmallStepperComponent, NgFor, NgIf, SmallStepperStepComponent, NgClass, IconComponent, FormlyModule],
})
export class FormlySmallStepperComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper: SmallStepperComponent;

  lastStepIndex: number;
  $destroy = new Subject();

  constructor(
    public readonly i18nService: I18nService,
    private readonly detectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.detectorRef.detectChanges();
    this.lastStepIndex = this.getLastVisibleStepIndex();
    this.field.form.valueChanges.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.lastStepIndex = this.getLastVisibleStepIndex();
      this.detectorRef.detectChanges();
    });
  }

  // Calculate index of last visible step, ignore hidden steps
  getLastVisibleStepIndex(): number {
    const fields = this.field.fieldGroup.filter((fg) => !fg.hide);
    if (fields.length == 0) return 0;
    return this.field.fieldGroup.lastIndexOf(fields[fields.length - 1]);
  }

  isValid(field: FormlyFieldConfig) {
    if (field.key) {
      return field.formControl?.valid;
    }
    return field?.fieldGroup?.every((f) => this.isValid(f));
  }

  async next(step: FormlyFieldConfig) {
    if (step.props.beforeNext) {
      const shouldContinue = await lastValueFrom(step.props.beforeNext(this.form));
      if (!shouldContinue) {
        return;
      }
    }
    this.stepper.next();
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
  }
}
