<mat-dialog-content class="px-5 text-center">
  <div class="absolute cursor-pointer" style="top: -25px; right: -25px" (click)="onClose()">
    <div class="rounded-md bg-gray-500 w-12 h-12 flex items-center justify-center">
      <app-icon name="XMark" size="1.5rem" class="text-white flex items-center justify-center"></app-icon>
    </div>
  </div>

  <div class="flex justify-center items-center">
    <img [attr.src]="modalProperties.imageSource" alt="" />
  </div>
  <h2 class="text-center" mat-dialog-title>
    {{ i18nService._(modalProperties.title) }}
  </h2>
  <div class="flex justify-center items-center">
    <p>
      {{ i18nService._(modalProperties.message) }}
    </p>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="justify-center mb-1 flex-col">
  <div
    *ngFor="let option of modalProperties.options"
    (click)="onSelectOption(option)"
    class="outline-1 outline text-zinc-200 rounded-10 shadow-custom cursor-pointer flex gap-4 items-center px-4 py-6 w-80 mb-8"
  >
    <div>
      <div class="rounded-full bg-slate-100 w-12 h-12 flex items-center justify-center">
        <app-icon [name]="option.icon" type="solid" size="1.5rem" class="text-gray-500 flex items-center justify-center"></app-icon>
      </div>
    </div>

    <h2 class="text-base text-black font-bold">{{ i18nService._(option.label) }}</h2>
  </div>
</mat-dialog-actions>
