import { DocumentResponse } from '../../utils/models/DocumentResponse';

export class ProgramPageDocumentViewModel {
  document: DocumentResponse;
  actions: {
    checked: boolean;
    disabled: boolean;
    display: boolean;
  };
  textArguments?: Array<string>;
  code?: string;
  downloadUrl?: string;
  label: string;

  constructor(label: string) {
    this.label = label;
    this.actions = {
      checked: false,
      disabled: false,
      display: true,
    };
    this.document = new DocumentResponse();
  }
}
