import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, NgControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FileSystemFileEntry, NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, NgIf } from '@angular/common';

import { QuestionDialogComponent } from '../../../dialog/components/question-dialog/question-dialog.component';
import { QuestionDialogData } from '../../../dialog/models/QuestionDialogData';
import { QuestionDialogResponse } from '../../../dialog/models/QuestionDialogResponse';
import { SnackbarMessageType } from '../../models/enums/snackbar-message-type.enum';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { DocumentResponse } from '../../models/DocumentResponse';
import { FileSystemService } from '../../services/file-system.service';
import { I18nService } from '../../services/i18n.service';
import { MimeType } from '../../models/app-constant';

@Component({
  selector: 'app-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, MatFormFieldModule, MatTooltipModule, NgxFileDropModule],
})
export class FileDropComponent implements OnInit, OnChanges {
  /**
   * attachmentName input
   *
   * @type {string}
   * @memberof FileDropComponent
   */
  @Input() attachmentName: string;

  /**
   * required inpute
   *
   * @type {boolean}
   * @memberof FileDropComponent
   */
  @Input() required: boolean;

  /**
   * includingForm input
   *
   * @type {FormGroup}
   * @memberof FileDropComponent
   */
  @Input() includingForm: UntypedFormGroup;

  /**
   * fieldNameKey input
   *
   * @type {string}
   * @memberof FileDropComponent
   */
  @Input() fieldNameKey: string;

  /**
   * isSubmit input
   *
   * @type {boolean}
   * @memberof FileDropComponent
   */
  @Input() isSubmit: boolean;

  /**
   * hoveringText input
   *
   * @type {string}
   * @memberof FileDropComponent
   */
  @Input() hoveringText: string;

  /**
   * validExt attribute
   *
   * @type {string[]}
   * @memberof FileDropComponent
   */
  @Input() validExt: Array<string> = [MimeType.PNG, MimeType.PDF, MimeType.JPG, MimeType.JPEG];

  /**
   * initialValue input
   * @type {*}
   * @memberof FileDropComponent
   */
  @Input() initialValue: DocumentResponse;

  /**
   * maxFileSize attribute
   * Default max file size is 20Mo
   * @type {number}
   * @memberof FileDropComponent
   */
  @Input() maxFileSize: number = 1024 ** 2 * 20;

  /**
   * tooltip attribute
   * @type {string}
   * @memberof FileDropComponent
   */
  @Input() tooltip: string;

  /**
   * helper attribute to display under the label
   * @type {string}
   * @memberof FileDropComponent
   */
  @Input() helper: string;

  /**
   * fileRequiredErrorI18nToken attribute
   * @type {string}
   * @memberof FileDropComponent
   */
  @Input() fileRequiredErrorI18nToken = 'Error_Field_Document';

  /**
   * fileFormatErrorI18nToken attribute
   * @type {string}
   * @memberof FileDropComponent
   */
  @Input() fileFormatErrorI18nToken = 'Error_Field_DocumentFormat';

  /**
   * fileSizeErrorI18nToken attribute
   * @type {string}
   * @memberof FileDropComponent
   */
  @Input() fileSizeErrorI18nToken = 'Error_Field_DocumentSize';

  /**
   * fileSizeErrorI18nToken attribute
   * @type {string}
   * @memberof FileDropComponent
   */
  @Input() manyAttachmentForbiddenErrorI18nToken = 'Error_SnackBar_ManyAttachmentsForbidden';

  /**
   * fileSizeErrorI18nToken attribute
   * @type {string}
   * @memberof FileDropComponent
   */
  @Input() incomptaibleBrowserErrorI18nToken = 'Error_SnackBar_BrowserNotCompatibleWithAttachment';

  /**
   * isDisabled input
   *
   * @type {boolean}
   * @memberof FileDropComponent
   */
  @Input() isDisabled: boolean;

  @ViewChild('fileInput') fileInput: ElementRef;

  /**
   * browserAvailable attribute
   *
   * @type {boolean}
   * @memberof FileDropComponent
   */
  public browserAvailable: boolean;

  /**
   * attachmentControl attribute
   *
   * @type {AbstractControl}
   * @memberof FileDropComponent
   */
  public attachmentControl: AbstractControl;

  /**
   * firstLoad attribute
   * @type {boolean}
   * @memberof FileDropComponent
   */
  public firstLoad: boolean;

  /**
   *  Variable for file size error message
   *
   * @type {number}
   * @memberof FileDropComponent
   */
  public sizeErrorMessage: number;

  /**
   * Variable for file type error message
   *
   * @type {string}
   * @memberof FileDropComponents
   */
  public extErrorMessage: string;

  /**
   * Return of split on validExt
   *
   * @type {string}
   * @memberof FileDropComponent
   */
  public splitTypes: string;

  /**
   * constant for Word document type
   * @type {string}
   * @memberof FileDropComponent
   */
  private docxType = 'VND.OPENXMLFORMATS-OFFICEDOCUMENT.WORDPROCESSINGML.DOCUMENT';

  /**
   * Creates an instance of FileDropComponent.
   * WARNING : this component must have a unique fieldNameKey
   *
   * @param {I18nService} i18nService
   * @param {SnackbarService} snackbarService
   * @param {FileSystemService} fileSystemService
   * @param {MatDialog} dialog
   *
   * @memberof FileDropComponent
   */
  constructor(
    public i18nService: I18nService,
    private readonly snackbarService: SnackbarService,
    public readonly fileSystemService: FileSystemService,
    private readonly dialog: MatDialog,
  ) {
    // Use this attribute to hide field required at the begining
    this.firstLoad = false;
    this.hoveringText = undefined;
  }

  /**
   * ngOnInit method
   *
   * @memberof FileDropComponent
   */
  ngOnInit(): void {
    // Variable for file type error message
    this.extErrorMessage = this.setExtErrorMessage();

    // Varibale to set html authorized extension
    this.splitTypes = this.validExt.toString();

    // Variable for file size error message
    this.sizeErrorMessage = this.maxFileSize / 1024 ** 2;

    // Check if browser can upload attachmentControl
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.browserAvailable = typeof window['File'] !== 'undefined' && typeof window['FileReader'] !== 'undefined';
    if (!this.browserAvailable) {
      const message: SnackbarMessage = {
        text: this.i18nService._(this.incomptaibleBrowserErrorI18nToken),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
    }
    // init the form control of the parent form
    const validatorsToSet = [this.fileType.bind(this), this.fileSize.bind(this)];
    if (this.required) {
      validatorsToSet.push(Validators.required);
    }
    this.includingForm.addControl(this.fieldNameKey, new UntypedFormControl('', validatorsToSet));
    this.attachmentControl = this.includingForm.controls[this.fieldNameKey];

    // If a valo role consult a company creation, there is a initial value of file from database
    if (this.initialValue) {
      this.setNewFile({
        name: this.initialValue.title,
        container: this.initialValue.container,
        fileName: this.initialValue.fileName,
        size: this.initialValue.size,
        type: this.initialValue.mimeType,
        id: this.initialValue.id,
        alreadySave: true,
      });
    }

    if (this.isDisabled) {
      this.attachmentControl.disable();
    }
  }

  /**
   * On changes / Implemented for RG_1410 (seen with GRI)
   *
   * @memberof FileDropComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.required && changes.required.previousValue !== undefined) {
      if (!changes.required.previousValue) {
        this.attachmentControl.setValidators([Validators.required, this.fileType.bind(this), this.fileSize.bind(this)]);
      } else {
        this.attachmentControl.clearValidators();
        this.attachmentControl.setValidators([this.fileType.bind(this), this.fileSize.bind(this)]);
      }
      this.attachmentControl.updateValueAndValidity();
    }
    if (changes.validExt) {
      this.extErrorMessage = this.setExtErrorMessage();
      if (this.attachmentControl) {
        this.attachmentControl.clearValidators();
        this.attachmentControl.setValidators([this.fileType.bind(this), this.fileSize.bind(this)]);
        this.attachmentControl.updateValueAndValidity();
      }
    }
  }

  setExtErrorMessage(): string {
    return this.validExt
      .map((extArray) => {
        const types = extArray.split('/');
        // rename word type for error message
        if (types[1].toUpperCase() === this.docxType) {
          types[1] = 'DOCX';
        }
        return types[1].toUpperCase();
      })
      .toString();
  }

  /**
   * fileType method, for validate file type
   *
   * @param {NgControl} control
   * @returns {{ [key: string]: boolean }}
   * @memberof FileDropComponent
   */
  fileType(control: NgControl): { [key: string]: boolean } {
    if ((control.value && control.value.type && this.validExt.indexOf(control.value.type) !== -1) || (!this.required && !control.value)) {
      return undefined;
    }

    return { fileType: true };
  }

  /**
   * fileSize method, to validate file size
   *
   * @param {NgControl} control
   * @returns {{ [key: string]: boolean }}
   * @memberof FileDropComponent
   */
  fileSize(control: NgControl): { [key: string]: boolean } {
    if ((control.value && control.value.size && this.maxFileSize > control.value.size) || (!this.required && !control.value)) {
      return undefined;
    }

    return { fileSize: true };
  }

  /**
   * method fileAdded, called when file is add with the input
   *
   * @param {*} files
   * @memberof FileDropComponent
   */
  public fileAdded(files): void {
    // Check that only one file is selected
    if (files.length === 1) {
      const file = files[0];
      this.setNewFile(file);
    } else {
      const message: SnackbarMessage = {
        text: this.i18nService._(this.manyAttachmentForbiddenErrorI18nToken),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
    }
  }

  /**
   * fileDropped method, called when file is dropped
   *
   * @param {UploadEvent} event
   * @memberof FileDropComponent
   */
  public fileDropped(files: Array<NgxFileDropEntry>): void {
    // If many files, snackBar and ask only one file
    if (files.length !== 1) {
      const message: SnackbarMessage = {
        text: this.i18nService._(this.manyAttachmentForbiddenErrorI18nToken),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
    } else {
      const droppedFile = files[0];
      if (droppedFile && droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.setNewFile(file);
        });
      } else {
        const message: SnackbarMessage = {
          text: this.i18nService._(this.fileFormatErrorI18nToken, [this.extErrorMessage]),
          type: SnackbarMessageType.Error,
        };
        this.snackbarService.sendMessage(message);
      }
    }
  }

  /**
   * setNewFile method
   *
   * @public
   * @param {*} file
   * @memberof FileDropComponent
   */
  public setNewFile(file): void {
    this.attachmentControl.setValue(file);
    this.firstLoad = true;
  }

  /**
   * removeAttachment method, called when user delete attachment
   *
   * @memberof FileDropComponent
   */
  public removeAttachment(): void {
    if (!this.initialValue) {
      this.attachmentControl.reset();
      this.fileInput.nativeElement.value = '';
    } else {
      this.openDialog();
    }
  }

  /**
   * openDialog method
   *
   * @param {ModalType} type
   * @memberof FileDropComponent
   */
  public openDialog(): void {
    const dialogData: MatDialogConfig<QuestionDialogData> = {
      data: {
        message: this.i18nService._('Txt_Popin_Remove_File_Question'),
        title: '',
        buttonConfirm: this.i18nService._('Txt_Popin_Remove_File'),
        buttonCancelHidden: true,
      },
    };

    const dialogRef = this.dialog.open(QuestionDialogComponent, dialogData);

    dialogRef.afterClosed().subscribe((result: QuestionDialogResponse) => {
      if (result && result.answer) {
        this.initialValue = undefined;
        this.attachmentControl.reset();
        this.fileInput.nativeElement.value = '';
      }
    });
  }
}
