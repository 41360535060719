<mat-dialog-actions>
  <div class="app-program-page-lot-dialog-back cursor-pointer" (click)="close()">
    <mat-icon class="size-18">arrow_back</mat-icon>
    <span>{{ i18nService._('Txt_Button_GoBack') }}</span>
  </div>

  <mat-icon class="size-20" mat-dialog-close tabindex="-1">close</mat-icon>
</mat-dialog-actions>
<mat-dialog-content>
  <p class="text-sm font-bold">
    {{
      i18nService._('Txt_Page_Program_ReducedTaxationLabelAndValue', [i18nService._(reducedTaxationLot.label), reducedTaxationLot.taxation])
    }}
  </p>
  <p class="text-sm" [innerHTML]="i18nService._('Txt_Page_Program_LegalNoticeVAT') | safe: 'html'"></p>
</mat-dialog-content>
