import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';

import { AbstractSearchFilter } from '../abstract-search-filter';
import { SearchFilterDialogComponent } from '../search-filter-dialog/search-filter-dialog.component';
import { FilterMenuBadgeComponent } from '../filter-menu-badge/filter-menu-badge.component';
import { SearchLocalisationComponent } from '../search-localisation/search-localisation.component';

import { mapHttpParams } from '../../../../utils/enums/map.enum';
import { breakpoints } from '../../../../utils/models/breakpoint';
import { BreakpointEnum } from '../../../../utils/models/enums/breakpoint.enum';
import { AppConfigService } from '../../../../utils/services/app-config.service';
import { EventUtilsService } from '../../../../utils/services/event/event-utils.service';
import { I18nService } from '../../../../utils/services/i18n.service';
import { SearchPanelTypeEnum } from '../../../model/search-panel-type-enum';
import { SearchProgramDataOrderBy } from '../../../model/search-program-data-order-by';
import { SearchProgramData } from '../../../model/search-program-data';

@Component({
  selector: 'app-search-fix-menu',
  templateUrl: './search-fix-menu.component.html',
  styleUrls: ['./search-fix-menu.component.scss'],
  standalone: true,
  imports: [SearchLocalisationComponent, FilterMenuBadgeComponent, MatIconModule],
})
export class SearchFixMenuComponent extends AbstractSearchFilter {
  public panelTypeEnum = SearchPanelTypeEnum;
  public data: { [key in SearchPanelTypeEnum]?: string[] } = {};
  @Input() public orderBy: SearchProgramDataOrderBy;
  @Output() readonly searchEvent: EventEmitter<SearchProgramData> = new EventEmitter<SearchProgramData>(true);
  public showFilterDialogPanel: boolean;
  private readonly _searchPanelTypeEnumKeys = Object.keys(SearchPanelTypeEnum);

  constructor(
    public readonly i18nService: I18nService,
    private readonly _breakpointObserver: BreakpointObserver,
    private readonly dialog: MatDialog,
    private readonly _eventUtilsService: EventUtilsService,
    private readonly route: ActivatedRoute,
    public appConfig: AppConfigService,
    private readonly eRef: ElementRef,
  ) {
    super();
    this._searchPanelTypeEnumKeys.forEach((value) => {
      this.data[value] = [];
    });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    super.ngOnInit();
    this._filterUtilsService.$openPanel.pipe(takeUntil(this.$d)).subscribe((value) => {
      this.showFilterDialogPanel = value[SearchPanelTypeEnum.OTHER];
    });

    this._breakpointObserver
      .observe(breakpoints[BreakpointEnum.md].down)
      .pipe(takeUntil(this.$d))
      .subscribe(() => {
        this.initData();
      });

    this._searchFormUtilsService.searchLaunchByMapSubject.pipe(takeUntil(this.$d)).subscribe((mapBoxParams) => {
      this.launchSearch(true, mapBoxParams);
    });
  }

  setFilter($event: string[], searchPanelType: SearchPanelTypeEnum) {
    this.data[searchPanelType] = $event;
  }

  launchSearch(launch = true, extraMapParams?: { bbox: string; priority: string }) {
    this.searchProgramData.order = this.orderBy;
    this._filterUtilsService.hideAll();
    if (extraMapParams && Object.prototype.hasOwnProperty.call(extraMapParams, 'bbox')) {
      this.searchProgramData.where.bbox = extraMapParams.bbox;
      this.searchProgramData.where.priority = extraMapParams.priority;
    } else {
      this.searchProgramData.where.priority = mapHttpParams.FILTERS;
    }
    if (launch) {
      this.searchEvent.next(this.searchProgramData);
    }
    this._filterUtilsService.hidePanel(SearchPanelTypeEnum.OTHER);
  }

  openSearchMoreCriteriaModal() {
    const preReservationPopin = this.dialog.open(SearchFilterDialogComponent, {
      width: '100%',
      maxWidth: '740px',
      autoFocus: false,
    });

    // Uses to ensure that the modal is fully loaded before build the filter
    preReservationPopin.afterOpened().subscribe(() => {
      const childComponentRef = preReservationPopin.componentInstance;
      childComponentRef.loadFilter();
    });

    preReservationPopin.afterClosed().subscribe((success: boolean) => {
      if (success) {
        this.launchSearch();
      } else {
        this._searchFormUtilsService.parseSearchProgramFromFilterChain(
          { ...this.route.snapshot.queryParams },
          {
            label: 'price',
            text: this.i18nService._('Txt_SortBy_Price_ASC'),
            direction: 'ASC',
          },
          this.appConfig.getAppConfig().limit,
        );
      }
    });
  }

  protected initData() {
    const nbOtherFilter = this._searchFormUtilsService.computeNbOtherFilter(this.search);
    let dataToShow = `${this.i18nService._('Txt_search_filter_other_criteria_placeholder')} - ${nbOtherFilter}`;
    if (this._breakpointObserver.isMatched(breakpoints[BreakpointEnum.md].down)) {
      dataToShow = `+${nbOtherFilter}`;
    }
    this.data[SearchPanelTypeEnum.OTHER] = nbOtherFilter > 0 ? [dataToShow] : [];
  }
}
