import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NgFor } from '@angular/common';

import { SelectDialogOption, SelectDialogProperties } from '../../models/SelectDialogProperties';
import { I18nService } from '../../../utils/services/i18n.service';
import { IconComponent } from '../../../common/standalone/icon/icon.component';

@Component({
  selector: 'app-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, IconComponent, NgFor],
})
export class SelectDialogComponent {
  constructor(
    public readonly i18nService: I18nService,
    public dialogRef: MatDialogRef<SelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public modalProperties: SelectDialogProperties,
  ) {}

  onSelectOption(selectedOption: SelectDialogOption): void {
    if (selectedOption.action) {
      selectedOption.action();
    }
    this.dialogRef.close(selectedOption.closingLabel);
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
