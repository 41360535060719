import { CssClass } from '../../utils/constantes/types';

export enum TagTypeEnum {
  DEFAULT,
  TRANSPARENT,
  BIG_NO_BORDER,
  BIG_WITH_BORDER,
  SEMI_RADIUS,
  SMALL_NO_BORDER,
  XS_NO_BORDER,
  TAXATIONS,
  REDUCED_TAXATIONS,
  SPECIAL_OFFER,
  FEES,
}

declare type TagClass = { [key in TagTypeEnum]: string };
export const tagClass: TagClass = {
  [TagTypeEnum.DEFAULT]: 'app-tag-default',
  [TagTypeEnum.TRANSPARENT]: 'app-tag-transparent',
  [TagTypeEnum.BIG_NO_BORDER]: 'app-tag-big-no-border',
  [TagTypeEnum.BIG_WITH_BORDER]: 'app-tag-big-with-border',
  [TagTypeEnum.SEMI_RADIUS]: 'app-tag-semi-radius',
  [TagTypeEnum.SMALL_NO_BORDER]: 'app-tag-small-no-border',
  [TagTypeEnum.TAXATIONS]: 'app-tag-taxation',
  [TagTypeEnum.REDUCED_TAXATIONS]: 'app-tag-reduce-taxation',
  [TagTypeEnum.XS_NO_BORDER]: 'app-tag-xs-no-border',
  [TagTypeEnum.SPECIAL_OFFER]: 'app-tag-special-offer-program',
  [TagTypeEnum.FEES]: 'app-tag-default',
};

export interface TagsInterface {
  ngClass?: CssClass;
  tagOption: TagOption;
}

export class TagOption {
  type?: TagTypeEnum;
  prefixIcon?: string;
  isCustomPrefixIcon?: boolean;
  text: string;
  textClass?: CssClass;
  suffixIcon?: string;
  isCustomSuffixIcon?: boolean;

  constructor(type?: TagTypeEnum) {
    this.type = type || TagTypeEnum.DEFAULT;
    this.isCustomPrefixIcon = false;
    this.isCustomSuffixIcon = false;
  }
}
