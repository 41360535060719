<div class="w-full bg-white p-8 shadow-custom rounded-10">
  <div class="flex flex-col gap-5">
    <div class="w-12 h-12 flex justify-center items-center rounded-full bg-gray-200 overflow-hidden">
      <app-icon *ngIf="acquisitionTypeIcon" class="w-7 h-7" [name]="acquisitionTypeIcon"></app-icon>
    </div>
    <div *ngIf="formView?.contextData?.acquirer && (formView?.contextData?.acqType === 1 || formView?.contextData?.acqType === 2)">
      <h3
        class="font-bold text-xl truncate"
        [title]="formView?.contextData?.acquirer?.firstName + ' ' + formView?.contextData?.acquirer?.lastName"
      >
        {{ formView?.contextData?.acquirer?.firstName }} {{ formView?.contextData?.acquirer?.lastName }}
      </h3>
      <div class="text-sm leading-6 text-gray-600">
        <p class="truncate" [title]="formView?.contextData?.acquirer?.email">
          {{ formView?.contextData?.acquirer?.email }}
        </p>
        <div *ngIf="formView?.contextData?.acquirer?.phone" class="flex items-center">
          <p class="mr-2">{{ formView?.contextData?.acquirer?.phone?.phoneE164 }}</p>
        </div>
      </div>
    </div>
    <div *ngIf="formView?.contextData?.coAcquirer && formView?.contextData?.acqType === 2">
      <h3
        class="font-bold text-xl"
        [title]="formView?.contextData?.coAcquirer?.firstName + ' ' + formView?.contextData?.coAcquirer?.lastName"
      >
        {{ formView?.contextData?.coAcquirer?.firstName }} {{ formView?.contextData?.coAcquirer?.lastName }}
      </h3>
      <div class="text-sm leading-6 text-gray-600">
        <p [title]="formView?.contextData?.coAcquirer?.email">
          {{ formView?.contextData?.coAcquirer?.email }}
        </p>
        <p>{{ formView?.contextData?.coAcquirer?.phone?.phoneE164 }}</p>
      </div>
    </div>
    <div *ngIf="formView?.contextData?.company && formView?.contextData?.acqType === 3">
      <h3 class="font-bold text-xl" [title]="formView?.contextData?.company?.companyName">
        {{ formView?.contextData?.company?.companyName }}
      </h3>
      <div class="text-sm leading-6 text-gray-600">
        <p>{{ formView?.contextData?.company?.firstName }} {{ formView?.contextData?.company?.lastName }}</p>
        <p [title]="formView?.contextData?.company?.email">
          {{ formView?.contextData?.company?.email }}
        </p>
        <p>{{ formView?.contextData?.company?.phone?.phoneE164 }}</p>
      </div>
    </div>
    <div
      *ngIf="
        (!formView?.contextData?.acquirer && formView?.contextData?.acqType === 1) ||
        (!formView?.contextData?.acquirer && !formView?.contextData?.coAcquirer && formView?.contextData?.acqType === 2) ||
        (!formView?.contextData?.company && formView?.contextData?.acqType === 3)
      "
    >
      <h3 class="font-bold text-xl" [title]="formView?.contextData?.prospect?.firstname + ' ' + formView?.contextData?.prospect?.lastname">
        {{ formView?.contextData?.prospect?.firstname }} {{ formView?.contextData?.prospect?.lastname }}
      </h3>
      <div class="text-sm leading-6 text-gray-600">
        <p [title]="formView?.contextData?.prospect?.email">
          {{ formView?.contextData?.prospect?.email }}
        </p>
        <p>{{ formView?.contextData?.prospect?.telephone }}</p>
      </div>
    </div>
  </div>

  <hr class="my-6 border border-gray-400" />

  <div class="flex flex-col">
    <h3 class="font-bold text-xl">{{ i18nService._('txt_lot_numero') }}{{ formView?.contextData?.lot?.number }}</h3>
    <div class="text-sm text-gray-600 mb-3">
      <p>{{ formView?.contextData?.program?.name }}</p>
      <p class="font-bold text-xs">{{ formView?.contextData?.program?.city }}</p>
    </div>
    <ul class="text-sm leading-6 list-disc pl-4 text-gray-600">
      <li class="mb-1">{{ i18nService._(formView?.contextData?.lot?.propertyType) }}</li>
      <li class="mb-1">{{ formView?.contextData?.lot?.typology }}</li>
      <li class="mb-1">{{ formView?.contextData?.lot?.floor }}</li>
      <li class="mb-1">{{ i18nService._('txt_delivery') }} : {{ formView?.contextData?.program?.deliveryDate }}</li>
    </ul>
  </div>

  <hr class="my-6 border border-gray-400" />

  <div class="price-title">
    {{ i18nService._('txt_signature_form_context_lot_price_initial') }}
  </div>
  <div class="taxation-value">
    {{ i18nService._(taxation | i18nTVALabelPourcentage) }}
    {{ i18nService._('txt_signature_form_context_lot_price_initial_sub') }}
  </div>
  <div class="price-value">
    {{ formView?.contextData?.lot?.initialPrice ?? formView?.contextData?.lot?.price | currency: 'EUR' : 'symbol' : '1.2-2' }}
  </div>

  <hr class="my-6 border border-gray-400" />

  <div class="price-title">
    {{ i18nService._('txt_signature_form_context_lot_price_final') }}
  </div>
  <div class="taxation-value">
    {{ i18nService._(taxation | i18nTVALabelPourcentage) }}
    {{ i18nService._('txt_signature_form_context_lot_price_initial_sub') }}
  </div>
  <div class="final-price-value">
    {{ formView?.contextData?.lot?.finalPrice ?? formView?.contextData?.lot?.price | currency: 'EUR' : 'symbol' : '1.2-2' }}
  </div>
</div>
