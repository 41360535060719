import { animate, query, sequence, stagger, style, transition, trigger } from '@angular/animations';

const itemToAnimateSelector = '.dropdown-angular-animation';

export const dropDownAnimation = trigger('dropDownMenu', [
  transition(':enter', [
    style({ height: 0, overflow: 'hidden' }),
    query(itemToAnimateSelector, [style({ opacity: 0, transform: 'translateY(-50px)' })]),
    sequence([
      animate('200ms', style({ height: '*' })),
      query(itemToAnimateSelector, [stagger(-50, [animate('150ms ease', style({ opacity: 1, transform: 'none' }))])]),
    ]),
  ]),

  transition(':leave', [
    style({ height: '*', overflow: 'hidden' }),
    query(itemToAnimateSelector, [style({ opacity: 1, transform: 'none' })]),
    sequence([
      query(itemToAnimateSelector, [stagger(50, [animate('150ms ease', style({ opacity: 0, transform: 'translateY(-50px)' }))])]),
      animate('200ms', style({ height: 0 })),
    ]),
  ]),
]);
