<div class="mt-8">
  <p class="text-base font-normal text-center">
    <span>
      {{ i18nService._('Title_NoAccountYet') }}
    </span>
    <a data-cy="inscriptionCompany" (click)="redirectionCompanyForm()" class="text-blue-400 cursor-pointer underline">
      {{ i18nService._('Title_SignUp') }}
    </a>
  </p>
</div>
