<div class="ag-grid-pagination-wrapper">
  <ag-grid-angular
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [gridOptions]="gridOptions"
    [pagination]="enablePagination"
    [paginationPageSizeSelector]="[10, 20, 50, 100]"
    [paginationPageSize]="pageSize"
    [rowData]="rowData"
    [getRowId]="getRowId"
    [isRowSelectable]="isRowSelectable"
    (cellValueChanged)="onCellValueChanged()"
    (selectionChanged)="onSelectionChanged($event)"
    (gridReady)="onGridReady($event)"
    [style.height]="tableHeight"
    [getRowId]="getRowId"
    [loadingOverlayComponent]="loadingOverlayComponent"
    class="ag-theme-material grid-style"
  >
  </ag-grid-angular>
</div>
