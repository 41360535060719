<ng-container *ngFor="let category of categories; let i = index">
  <div (click)="goToForm(category?.id)" class="bg-white rounded-10 shadow-custom cursor-pointer mb-3 hover:shadow-lg px-8 py-4">
    <div class="wrapper h-full flex flex-col lg:flex-row lg:items-center lg:justify-between">
      <div class="flex items-center sm:w-full sm:mb-2">
        <div class="step-icon mr-5">
          <app-circle-progress
            [progress]="((category?.requiredFields - category?.remainedFields) * 100) / category?.requiredFields"
            [icon]="category?.icon"
            class="flex flex-1 w-12 h-12"
            [index]="i"
          >
          </app-circle-progress>
        </div>
        <h2 class="text-xl font-bold">{{ i18nService._(category?.label) }}</h2>
      </div>
      <div class="step-info flex sm:justify-end lg:items-center sm:w-full">
        <h2 class="leading-[26px]">
          {{ category?.remainedFields === 0 ? '' : category?.remainedFields + ' ' + i18nService._('txt_remained_fields') }}
        </h2>
        <app-icon size="1.5rem" class="ml-2 fill-black" name="ChevronRight" type="solid"></app-icon>
      </div>
    </div>
  </div>
</ng-container>
