import { Pipe, PipeTransform } from '@angular/core';
import { DossierStatusCode } from '@commons-dto/dossier-prospect';

import { StatusEnum } from '../../../../common/shared/status-tag/status-tag.component';

@Pipe({
  name: 'statusColor',
  standalone: true,
})
export class StatusColorPipe implements PipeTransform {
  transform(dossierStatus: DossierStatusCode): StatusEnum {
    switch (dossierStatus) {
      case DossierStatusCode.DISCOVER:
        return StatusEnum.PENDING;
      default:
        return StatusEnum.DRAFT;
    }
  }
}
