export enum ProgramStatusEnum {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  VALID = 'VALID',
  INCOMPLETE = 'INCOMPLETE',
  DENIED = 'DENIED',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  SCHEDULED = 'SCHEDULED',
}
