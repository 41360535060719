import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AcountTypeEnum, RoleTypeEnum } from '@commons-dto/valo-back';
import { NgIf } from '@angular/common';

import { AccountFormComponent } from '../account-form/account-form.component';

import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { Sitemap } from '../../../utils/models/Sitemap';
import { AccountService } from '../../../utils/services/account.service';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { CreateAccountRequest } from '../../models/CreateAccountRequest';
import { NeededInformationsForAcountCreation } from '../../models/NeededInformationsForAcountCreation';
import { AccountUtilsService } from '../../services/account-utils.service';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
  standalone: true,
  imports: [NgIf, AccountFormComponent],
})
export class CreateAccountComponent implements OnInit, OnDestroy {
  public neededInfos: NeededInformationsForAcountCreation;
  public createAccountRequest: CreateAccountRequest;
  public boIdList: Array<number>;
  public loggedUserRoleName: string;
  public pageTitle: string;
  public loggedUserIsValo: boolean;
  public roleTypeName: string;

  private readonly _destroy$: Subject<boolean> = new Subject<boolean>();

  /**
   * Creates an instance of CreateAccountComponent.
   *
   * @param {I18nService} i18nService
   * @param {Router} router
   * @param {ActivatedRoute} route
   * @param {AccountService} accountService
   * @param {SnackbarService} snackbarService
   * @memberof CreateAccountComponent
   */
  constructor(
    public readonly i18nService: I18nService,
    public readonly appConfigService: AppConfigService,
    private readonly router: Router,
    public readonly route: ActivatedRoute,
    private readonly accountService: AccountService,
    private readonly snackbarService: SnackbarService,
    private readonly accountUtilsService: AccountUtilsService,
  ) {}

  ngOnInit(): void {
    const accountId = Number(this.route.snapshot.paramMap.get('id'));
    const parentId = this.accountUtilsService.getParentAndLoggedUserInfos(accountId).parentId;
    this.loggedUserRoleName = this.accountUtilsService.getParentAndLoggedUserInfos().loggedUserRoleName;
    this.loggedUserIsValo = this.accountUtilsService.getParentAndLoggedUserInfos().loggedUserIsValo;

    const ROLES = 'roles';
    const ACOUNT_TYPE = 'accountType';

    const DEVELOPER_HYPER_ROLE = this.appConfigService._(ROLES, RoleTypeEnum.DEVELOPER_HYPER);
    const DEVELOPER_SUPER_ROLE = this.appConfigService._(ROLES, RoleTypeEnum.DEVELOPER_SUPER);
    const CONTRACTOR_HYPER_ROLE = this.appConfigService._(ROLES, RoleTypeEnum.CONTRACTOR_HYPER);
    const CONTRACTOR_SUPER_ROLE = this.appConfigService._(ROLES, RoleTypeEnum.CONTRACTOR_SUPER);
    const INDEPENDANT_NETWORK_HEADER_ROLE = this.appConfigService._(ROLES, RoleTypeEnum.INDEPENDANT_NETWORK_HEADER);

    const setRoleTypeNameAndPageTitle = (roleTypeName: AcountTypeEnum, pageTitle: string) => {
      this.pageTitle = pageTitle;
      this.roleTypeName = this.appConfigService._(ACOUNT_TYPE, roleTypeName);
    };

    // If we have a parentId in the url we take its value, otherwise parentId = userId
    // If the user is a developer or contractor, the notion of "super" is not displayed
    this.accountUtilsService
      .getInfosForCreate(parentId)
      .pipe(takeUntil(this._destroy$))
      .subscribe((response) => {
        this.neededInfos = response;

        switch (response.parentRoleName) {
          case DEVELOPER_HYPER_ROLE:
            setRoleTypeNameAndPageTitle(
              AcountTypeEnum.DEVELOPER,
              this.loggedUserIsValo ? 'Title_SuperDeveloperCreation' : 'Title_DeveloperCreation',
            );
            break;
          case DEVELOPER_SUPER_ROLE:
            setRoleTypeNameAndPageTitle(AcountTypeEnum.DEVELOPER, 'Title_DeveloperCreation');
            break;
          case CONTRACTOR_HYPER_ROLE:
            setRoleTypeNameAndPageTitle(
              AcountTypeEnum.CONTRACTOR,
              this.loggedUserIsValo ? 'Title_SuperContractorCreation' : 'Title_ContractorCreation',
            );
            break;
          case CONTRACTOR_SUPER_ROLE:
            setRoleTypeNameAndPageTitle(AcountTypeEnum.CONTRACTOR, 'Title_ContractorCreation');
            break;
          case INDEPENDANT_NETWORK_HEADER_ROLE:
            setRoleTypeNameAndPageTitle(AcountTypeEnum.INDEPENDANT, 'Title_IndependantCreation');
            break;
          default:
            this.pageTitle = 'Title_ValorissimoUserCreation';
            break;
        }
      });
  }

  getResponsibleId(valoPartData, neededInfos) {
    if (this.isResponsibleNotRequired(valoPartData, neededInfos)) return undefined;
    return valoPartData?.responsible?.id || neededInfos?.defaultResponsible?.id;
  }

  getOtherResponsibleId(valoPartData, neededInfos) {
    return valoPartData?.otherResponsible?.id || neededInfos?.otherDefaultResponsible?.id;
  }

  /**
   * Détermine si un responsable est nécessaire pour la création du compte.
   * @param valoPartData Les données du formulaire.
   * @param neededInfos informations par defaut
   * @returns true si un responsable n'est pas nécessaire, false sinon.
   */
  private isResponsibleNotRequired(valoPartData, neededInfos): boolean {
    const isIndependentCompanyType = this.appConfigService._('companyType', AcountTypeEnum.INDEPENDANT) === neededInfos.companyTypeLabel;
    return valoPartData.valoRoleType || neededInfos.valoRoleTypeId || isIndependentCompanyType;
  }

  /**
   * Action on click event Create
   *
   * @param {*} values FormData
   */
  public createAccount(values): void {
    this.boIdList = [];
    if (values.valoPartData.hasBo) {
      values.valoPartData.hasBo.forEach((bo) => {
        this.boIdList.push(bo.id);
      });
    }
    this.createAccountRequest = {
      // Personal Data
      civilityId: values.personnalData.civilityId,
      lastName: values.personnalData.lastName,
      firstName: values.personnalData.firstName,
      email: values.personnalData.email,
      mobilePhone: values.personnalData.mobilePhone,
      deskPhone: values.personnalData.deskPhone,
      professionnalFunction: values.personnalData.professionnalFunction,

      // Adress Infos
      countryId: values.personnalData.countryId,
      postalCode: values.personnalData.postalCode,
      address: values.personnalData.address,
      city: values.personnalData.city,
      cityInseeCode: values.personnalData.cityInseeCode,

      // Role Infos
      parentId: this.neededInfos.parentId,
      profilTypeId: this.neededInfos.profilTypeId,
      valoRoleTypeId: values.valoPartData.valoRoleType ? values.valoPartData.valoRoleType : this.neededInfos.valoRoleTypeId,
      companyTypeId: this.neededInfos.companyTypeId,

      companyId: this.neededInfos.companyId,

      // Optionnal Data
      responsibleId: this.getResponsibleId(values.valoPartData, this.neededInfos),
      otherResponsibleId: this.getOtherResponsibleId(values.valoPartData, this.neededInfos),
      nbOptionsMax: values.valoPartData.nbOptionsMax,
      isActive: !!values.valoPartData.isActive,
      isVisibleCommercialLot: !!values.valoPartData.isVisibleCommercialLot,
      isFeesHidden: !!values.valoPartData.isFeesHidden,
      hasBo: values.valoPartData.hasBo ? this.boIdList : undefined,
      hasDepartement: values.valoPartData.hasDepartement,
      commentBo: values.valoPartData.commentBo,
    };
    if (values.valoPartData.isForbidden) {
      this.createAccountRequest.isForbidden = values.valoPartData.isForbidden;
    }
    // Create account and redirect to main board page.
    this.accountService.createAccount(this.createAccountRequest).subscribe(
      () => {
        this.snackbarService.sendMessage({
          text: this.loggedUserIsValo
            ? this.i18nService._('Success_SnackBar_ValidatedAccount')
            : this.i18nService._('Success_SnackBar_AccountInValidation'),
          type: SnackbarMessageType.Info,
        });
        this.router.navigate([this.getRoute()]);
      },
      () => {
        this.snackbarService.sendErrorOccured();
      },
    );
  }

  private routeConfig = {
    [this.appConfigService._('accountType', AcountTypeEnum.INDEPENDANT)]: Sitemap.accounts.adminIndependant.path,
    [this.appConfigService._('accountType', AcountTypeEnum.DEVELOPER)]: Sitemap.accounts.adminDeveloper.path,
    [this.appConfigService._('accountType', AcountTypeEnum.CONTRACTOR)]: Sitemap.accounts.adminContractor.path,
  };

  /**
   * Redirecting to tracking table according to created user
   */
  getRoute(): string {
    return this.routeConfig[this.roleTypeName] || Sitemap.accounts.admin.path;
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
