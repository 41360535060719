import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DossierProspectRouteService } from 'apps/valo-front/src/app/dossier-prospect/components/dossier-prospect/dossier-prospect.route.service';
import { DossierResponse, SearchDossierQuery } from '@commons-dto/dossier-prospect';
import { TokenService } from 'apps/valo-front/src/app/utils/services/authorisation/token.service';
import { SortDirection } from '@commons-dto/search';
import { NgIf } from '@angular/common';

import { CarouselComponent, CarouselConfig } from '../../../../common/standalone/carousel/carousel.component';
import { I18nService } from '../../../../utils/services/i18n.service';
import { GoogleTagManagerService } from '../../../../utils/services/google-tag-manager.service';
import { DossierProspectService } from '../../../../dossier-prospect/services/dossier-prospect.service';
import { ProspectCardComponent } from '../../../../common/shared/prospect-card/prospect-card.component';
import { ButtonCardComponent } from '../../../../common/shared/button-card/button-card.component';

@Component({
  selector: 'app-last-consult-prospect-list',
  templateUrl: './last-consult-prospect-list.component.html',
  styleUrls: ['./last-consult-prospect-list.component.scss'],
  standalone: true,
  imports: [NgIf, ButtonCardComponent, CarouselComponent, ProspectCardComponent],
})
export class LastConsultProspectListComponent implements OnInit {
  dossiers: Partial<DossierResponse>[] = [];

  carouselConfig: CarouselConfig = {
    items: [],
    numScrollDefault: 1,
    numVisibleDefault: 10,
    responsiveParams: [
      {
        breakpoint: '4390px',
        numVisible: 9,
        numScroll: 1,
      },
      {
        breakpoint: '4050px',
        numVisible: 8,
        numScroll: 2,
      },
      {
        breakpoint: '3620px',
        numVisible: 7,
        numScroll: 3,
      },
      {
        breakpoint: '3190px',
        numVisible: 6,
        numScroll: 4,
      },
      {
        breakpoint: '2760px',
        numVisible: 5,
        numScroll: 5,
      },
      {
        breakpoint: '2330px',
        numVisible: 4,
        numScroll: 4,
      },
      {
        breakpoint: '1900px',
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: '1470px',
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: '1024px',
        numVisible: 1,
        numScroll: 1,
      },
    ],
  };

  constructor(
    public i18nService: I18nService,
    private _router: Router,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly _dossierProspectService: DossierProspectService,
    private readonly dossierProspectRouteService: DossierProspectRouteService,
    private readonly tokenService: TokenService,
  ) {}

  ngOnInit(): void {
    const searchQuery: SearchDossierQuery = {
      columns: [
        'id',
        'dossierStatusCode',
        'prospects.SIRET',
        'prospects.city',
        'prospects.postalCode',
        'prospects.companyName',
        'prospects.email',
        'prospects.firstName',
        'prospects.lastName',
        'prospects.phoneNumber',
        'prospects.prospectType',
      ],
      relations: ['prospects'],
      prescriptorIds: [this.tokenService.getUserId()],
      sortDirection: SortDirection.DESC,
      sortColumn: 'lastConsultDate',
      limit: 10,
    };
    this._dossierProspectService.getDossierProspectBySearchQuery(searchQuery).subscribe((dossierProspect) => {
      this.dossiers = dossierProspect;
      this.carouselConfig.items = this.dossiers;
    });
  }

  public goToDossierProspectList() {
    this.dossierProspectRouteService.goToList();
  }

  public goToCreateProspect() {
    this.dossierProspectRouteService.goToCreate();
    this._googleTagManagerService.pushTag({ event: 'prospect_declaration' });
  }
}
