import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
  standalone: true,
})
export class HighlightPipe implements PipeTransform {
  public transform(text: string, search: string): string {
    if (search) {
      return text.replace(new RegExp(search, 'gi'), (match) => `<strong>${match}</strong>`).replace(' ', '&nbsp;');
    }

    return text;
  }
}
