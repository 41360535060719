import { Component, Input } from '@angular/core';

import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
  standalone: true,
})
export class TitleComponent {
  @Input() label: string;

  constructor(public readonly i18nService: I18nService) {}
}
