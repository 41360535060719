import { Injectable } from '@angular/core';
import { toUpper } from 'lodash-es';
import { DateTime } from 'luxon';
import moment from 'moment';

import { programTags } from '../models/enum/program-tag.enum';

import { TagsInterface } from '../../design-system/model/tag-option';
import { LotAreaTypeEnum } from '../../lot/model/lot-area-type.enum';
import { LotService } from '../../lot/service/lot.service';
import { LotStatus } from '../../utils/models/enums/lot-status.enum';
import { LotDetailResponse } from '../../utils/models/LotDetailResponse';
import { ProgramDetail } from '../../utils/models/ProgramDetail';
import { ProgramDatalayer, ProgramDetailResponse } from '../../utils/models/ProgramDetailResponse';
import { AppConfigService } from '../../utils/services/app-config.service';
import { BasicFormatsService } from '../../utils/services/basic-formats.service';
import { GoogleTagManagerService } from '../../utils/services/google-tag-manager.service';
import { I18nService } from '../../utils/services/i18n.service';
import { TaxationsService } from '../../utils/services/taxations/taxations.service';
import { UserRoleService } from '../../utils/services/user-role.service';
import { TaxationDetailResponse } from '../../utils/models/TaxationDetailResponse';

@Injectable({
  providedIn: 'root',
})
export class ProgramPageService {
  private readonly tags: { [key: string]: TagsInterface } = { ...programTags };

  constructor(
    private readonly _appConfig: AppConfigService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly _userRoleService: UserRoleService,
    private readonly _taxationsService: TaxationsService,
    private readonly _basicFormatsService: BasicFormatsService,
    public i18nService: I18nService,
  ) {}

  private _program: ProgramDetail;

  get program(): ProgramDetail {
    return this._program;
  }

  private _lots: LotDetailResponse[];

  get lots(): LotDetailResponse[] {
    return this._lots;
  }

  private _programTags: TagsInterface[];

  get programTags(): TagsInterface[] {
    return this._programTags;
  }

  private _programFiscalityTags: TagsInterface[];

  get programFiscalityTags(): TagsInterface[] {
    return this._programFiscalityTags;
  }

  private _lotsIndexed: { [p: number]: LotDetailResponse };

  get lotsIndexed(): { [p: number]: LotDetailResponse } {
    return this._lotsIndexed;
  }

  get hasTvaNominal(): boolean {
    return this._program.hasTvaNominal;
  }

  get fees(): number {
    if (this.program && !this._userRoleService.isDeveloper()) {
      return this.program.minFees / 100;
    }
  }

  get hasFeesRange(): boolean {
    if (this.program) {
      return this._userRoleService.isContractor();
    }
  }

  get maxFees(): number {
    return this.hasFeesRange ? this.program.maxFees / 100 : this.fees;
  }

  getLotListByTypologie(lotType: string, typologie: number): LotDetailResponse[] {
    return this.program.lots.filter((l) => l.floor === typologie && l.lotTypeLabel === lotType);
  }

  /**
   * Updates the active program and generate details from a response of
   * the back-end
   * @param program Response from GET /Program/details
   */
  setProgramFromResponse(program: ProgramDetailResponse) {
    this._program = program;
    this._program.isFrench = this._appConfig.getAppConfig().codeCountryFr === program.countryCode;
    this._lots = program.lots;
    this._aggregateCounterProperties();
    this._lotsIndexed = this.indexLots(this._lots);
    this._program.hasSpecialOffer = this._hasSpecialOffer(this._lots);
    this._program.hasElectronicSignature = this._hasElectronicSignature(this._program);
    this._program.hasExclusiveOffer = this._hasExclusiveOffer(this._lots);
    this._program.hasTvaNominal = !!program.lots.find((l) => l.taxations.find((t) => t.label === 'NOMINAL_TAXATION'));
    this._program.deliveryDateAsString = this.getDeliveryDate(this._program.minDeliveryDate, this._program.maxDeliveryDate);
    this._program.fullAdress = this._getFullAdress(this._program);
    this._programTags = this._getTags(this._program);
    this._programFiscalityTags = this._getFiscalityTags(this._program);
  }

  pushProgramDatalayerData({
    program,
    lot,
    _programDataLayer,
  }: {
    program: ProgramDetail;
    lot?: LotDetailResponse;
    _programDataLayer: ProgramDatalayer;
  }) {
    const dataLayer = {
      program: {
        name: this._setDatalayerValue(program.programName),
        id: this._setDatalayerValue(program.programId),
        postalCode: this._setDatalayerValue(program.postalCode),
        city: this._setDatalayerValue(program.city),
        department: this._setDatalayerValue(_programDataLayer && _programDataLayer.department),
        region: this._setDatalayerValue(_programDataLayer && _programDataLayer.region),
        promoteurName: this._setDatalayerValue(_programDataLayer && _programDataLayer.corporateName),
        actable: this._setDatalayerValue(program.actable),
        dematEligibility: '',
        lotsAvailable: this._setDatalayerValue(program.nbLotsAvailable),
        publicationDate: this._setDatalayerValue(_programDataLayer.publishedDate),
      },
      lot: {
        id: lot ? lot.lotId : '',
      },
      event: 'program_page',
    };
    this._googleTagManagerService.pushTag(dataLayer);
  }

  public _hasSpecialOffer(_lots: LotDetailResponse[]): boolean {
    return !!_lots.find((lot) => lot.specialOffers && lot.specialOffers.length);
  }

  public isNewProgram(programToCheck = null): boolean {
    const program = programToCheck || this._program;

    if (!program || !program.firstPublicationDate) {
      return null;
    }

    const currentDate = moment();
    const creationDate = moment(program.firstPublicationDate);
    const creationDateTagAsNew = moment(program.firstPublicationDate).add(this._appConfig.getAppConfig().newsMonthsDelay, 'month');

    return currentDate.isSameOrAfter(creationDate) && creationDateTagAsNew.isSameOrAfter(currentDate);
  }

  public pushTagProgramPageLot(program: ProgramDetailResponse, lotList: Array<LotDetailResponse>, selectedLotIndex: number, event: string) {
    this._googleTagManagerService.pushTag({
      program: this.getProgramTraking(),
      lot: this._setLotDatalayer(lotList[selectedLotIndex]),
      event,
    });
  }

  private extractProfitabilitiesMeubleValue(lot: {
    taxations: TaxationDetailResponse[];
    estimatedFurnishedMarketYield: number;
  }): Array<number> {
    const taxationLabels = this._appConfig.getAppConfig().taxationList;
    // lot en LMNP/LMP: Rentabilité HT = taxation.profitability
    // lot en taxation LNMP non geré: Rentabilité meublé marché estimé = lot.estimatedFurnishedMarketYield
    const profitabilities = lot.taxations.map((taxation) => {
      switch (taxation.label) {
        case taxationLabels['LMNP NON GERE']:
          return lot.estimatedFurnishedMarketYield;
        case taxationLabels['LMNPLMP']:
          return taxation.profitability;
        default:
          return null;
      }
    });
    return profitabilities.filter(Number);
  }

  private extractProfitabilityNueValue(lot: { taxations: TaxationDetailResponse[]; estimatedProfitability: number }): number {
    return lot.estimatedProfitability;
  }

  /**
   * Generate properties in the program details that are calculated
   * from every lot based on the program details back-end response
   */
  private _aggregateCounterProperties() {
    this.program.nbLotsAvailable = 0;
    this.program.nbLotsNotAvailable = 0;
    this.program.nbLotsOptionned = 0;
    this.program.minPrice = Infinity;
    this.program.minProfitabilityNue = Infinity;
    this.program.maxProfitabilityNue = -Infinity;
    this.program.minProfitabilityMeuble = Infinity;
    this.program.maxProfitabilityMeuble = -Infinity;
    this.program.minRoom = Infinity;
    this.program.maxRoom = -Infinity;
    this.program.minDeliveryDate = new Date(9999, 1);
    this.program.maxDeliveryDate = new Date(1970, 1);
    this.program.minFees = Infinity;
    this.program.maxFees = -Infinity;

    for (const lot of this.program.lots) {
      switch (lot.status) {
        case LotStatus.FREE:
          this.program.nbLotsAvailable++;
          break;
        case LotStatus.OPTIONED:
          this.program.nbLotsOptionned++;
          break;
        default:
          this.program.nbLotsNotAvailable++;
          break;
      }

      // Meublé
      const profitabilitiesMeuble = this.extractProfitabilitiesMeubleValue(lot);
      lot.profitabilityMeuble = Math.max(...profitabilitiesMeuble);
      this.program.minProfitabilityMeuble = Math.min(this.program.minProfitabilityMeuble, Math.min(...profitabilitiesMeuble));
      this.program.maxProfitabilityMeuble = Math.max(this.program.maxProfitabilityMeuble, Math.max(...profitabilitiesMeuble));

      // Nue
      const profitabilityNue = this.extractProfitabilityNueValue(lot);
      lot.profitabilityNue = profitabilityNue;
      lot.profitability = profitabilityNue;
      this.program.minProfitabilityNue = Math.min(this.program.minProfitabilityNue, profitabilityNue);
      this.program.maxProfitabilityNue = Math.max(this.program.maxProfitabilityNue, profitabilityNue);

      this.program.minRoom = Math.min(this.program.minRoom, lot.profitabilityNue);
      this.program.maxRoom = Math.max(this.program.maxRoom, lot.profitabilityNue);
      this.program.minPrice = Math.min(this.program.minPrice, lot.commercialLotSellingPriceIT);
      /* tslint does not allow to compare objects with standard operators */
      /* eslint-disable */
      this.program.minDeliveryDate = lot.deliveryDate < this.program.minDeliveryDate ? lot.deliveryDate : this.program.minDeliveryDate;
      this.program.maxDeliveryDate = lot.deliveryDate > this.program.maxDeliveryDate ? lot.deliveryDate : this.program.maxDeliveryDate;
      /* eslint-enable */
      this.program.minFees = Math.min(this.program.minFees, lot.fees);
      this.program.maxFees = Math.max(this.program.minFees, lot.fees);
    }

    this.program.isFinancialStrategyAttained = this.program.nbLotsNotAvailable > 0;
  }

  private _getTags(program: ProgramDetailResponse): TagsInterface[] {
    if (!program) {
      return [];
    }

    const tags: TagsInterface[] = [];
    Object.keys(this.tags).forEach((key) => {
      if (program[key]) {
        tags.push(this.tags[key]);
      }
    });

    return tags;
  }

  private _setLotDatalayer(lot: LotDetailResponse) {
    return {
      Id: this._setDatalayerValue(lot.lotId),
      roomNumber: this._setDatalayerValue(lot.rooms),
      price: [lot.commercialLotSellingPriceIT, lot.reducedTotalSellingPriceIT].filter(Boolean).join(','),
      taxation: lot.taxations && lot.taxations.length ? lot.taxations.map((tax) => tax.label).join(',') : '',
      surface: this._setDatalayerValue(lot.livingSpace),
      status: this._setDatalayerValue(lot.status),
      type: this._setDatalayerValue(lot.lotTypeLabel),
      tva: lot.taxations && lot.taxations.length ? lot.taxations.map((tax) => tax.taxation).join(',') : '',
      delivery: this._setDatalayerValue(lot.deliveryDate),
      orientation: this._setDatalayerValue(lot.lotOrientationLabel),
      profitability: this._setDatalayerValue(lot.estimatedProfitability),
      rent: this._setDatalayerValue(lot.estmatedMonthlyRentingPrice),
      specialOffer: !!(lot.specialOffers && lot.specialOffers.length),
      excluValo: '',
      terrace: LotService.hasAreaSurface(lot, LotAreaTypeEnum.TERRACE),
      balcony: LotService.hasAreaSurface(lot, LotAreaTypeEnum.BALCONY),
      garden: LotService.hasAreaSurface(lot, LotAreaTypeEnum.GARDEN),
      loggia: LotService.hasAreaSurface(lot, LotAreaTypeEnum.LOGGIA),
      annexIncluded: lot.secondaryLots.length && lot.secondaryLotPriceIncluded,
      feeRate: this._setDatalayerValue(lot.fees),
    };
  }

  private _setDatalayerValue(val): string {
    return val ? val : '';
  }

  public _hasElectronicSignature(_program: ProgramDetail): boolean {
    return Boolean(_program.isElectronicSignatureBI || _program.isElectronicSignatureValorissimo);
  }

  public _hasExclusiveOffer(_lots: LotDetailResponse[]): boolean {
    return _lots.some((lot) => !!lot.isValorissimoExclusivity);
  }

  private _getFiscalityTags(program: ProgramDetailResponse): TagsInterface[] {
    const labelsProfitability = new Set<string>();
    for (const lot of program.lots) {
      for (const taxation of lot.taxations) {
        labelsProfitability.add(taxation.label);
      }
    }
    return this._taxationsService.getTaxationsTags([...labelsProfitability]);
  }

  private _getFullAdress(_program: ProgramDetailResponse) {
    return `${_program.address}, ${toUpper(_program.city)} (${_program.postalCode}) - ${toUpper(_program.countryLabel)}`;
  }

  public getDeliveryDate(minDate: Date, maxDate: Date): string {
    const minDeliveryDateTrimesterWM = DateTime.fromJSDate(new Date(minDate)).toFormat(`q'T&nbsp;'yyyy`);
    const maxDeliveryDateTrimesterWM = DateTime.fromJSDate(new Date(maxDate)).toFormat(`q'T&nbsp;'yyyy`);

    return minDeliveryDateTrimesterWM === maxDeliveryDateTrimesterWM
      ? maxDeliveryDateTrimesterWM
      : this.i18nService._('Txt_page_program_infos_delivery_between', [minDeliveryDateTrimesterWM, maxDeliveryDateTrimesterWM]);
  }

  public getMinMaxPrice(minPrice: number, maxPrice: number): string {
    const minPriceFormatted = this._basicFormatsService.formatCurrency(minPrice, undefined, 0, 0, 4);
    const maxPriceFormatted = this._basicFormatsService.formatCurrency(maxPrice, undefined, 0, 0, 4);

    return this.i18nService._('Txt_mapbox_popup_Program_only_min_max_price', [minPriceFormatted, maxPriceFormatted]);
  }

  private indexLots(_lots: LotDetailResponse[]): { [key: number]: LotDetailResponse } {
    const lotIndexed: { [key: number]: LotDetailResponse } = {};
    _lots.forEach((lot) => (lotIndexed[lot.lotId] = lot));

    return lotIndexed;
  }

  private getProgramTraking() {
    return {
      name: this._setDatalayerValue(this.program.programName),
      id: this._setDatalayerValue(this.program.programId),
      postalCode: this._setDatalayerValue(this.program.postalCode),
      city: this._setDatalayerValue(this.program.city),
      department: this._setDatalayerValue(this.program.departementCode),
      promoteurName: this._setDatalayerValue(this.program.companyDescriptionTitle),
      actable: this._setDatalayerValue(this.program.actable),
      dematEligibility: '',
      lotsAvailable: this._setDatalayerValue(this.program.nbLotsAvailable),
      publicationDateProgram: this._setDatalayerValue(this.program.publishedDate),
    };
  }
}
