interface Scripts {
  name: string;
  src: string;
}

export const CITY_SCAN = 'CITY_SCAN';
export const CITY_SCAN_LOCAL = 'CITY_SCAN_LOCAL';

export const allScriptStoreInApp: Scripts[] = [
  {
    name: CITY_SCAN,
    src: 'https://www.cityscan.fr/assets/build/js/lib/cityscan-widget.js',
  },
];
