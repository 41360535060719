/* eslint-disable @typescript-eslint/no-explicit-any */
import { LocationData } from '../../utils/models/LocationData';

export class SearchProgramDataWhere {
  budget?: {
    minBudget: number;
    maxBudget: number;
  };
  programTypeIds?: Array<number>;
  nbRooms?: Array<string>;
  areaTypeIds?: Array<number>;
  nbFloors?: Array<string>;
  lotOrientationIds?: Array<number>;
  storageSecondaryLotTypeIds?: Array<number>;
  parkingSecondaryLotTypeIds?: Array<number>;
  deliveryDateMonth?: number;
  developerCompany?: number;
  localisations?: LocationData;
  localisationsMapList?: Array<any>;
  localisationsList?: Array<any>;
  taxation?: number;
  taxationZone?: number;
  profitability?: string;
  rent?: {
    minRent: number;
    maxRent: number;
  };
  isActable?: boolean;
  isHighStake?: boolean;
  isNew?: boolean;
  fees?: string;
  hasSpecialOffer?: boolean;
  programId?: number;
  bbox?: string;
  priority?: string;

  constructor() {
    this.budget = {
      minBudget: undefined,
      maxBudget: undefined,
    };
    this.developerCompany = undefined;
    this.localisations = new LocationData();
    this.localisationsMapList = [];
    this.localisationsList = [];
    this.programTypeIds = [];
    this.nbRooms = [];
    this.areaTypeIds = [];
    this.nbFloors = [];
    this.lotOrientationIds = [];
    this.storageSecondaryLotTypeIds = [];
    this.parkingSecondaryLotTypeIds = [];
    this.deliveryDateMonth = undefined;
    this.taxation = undefined;
    this.taxationZone = undefined;
    this.profitability = undefined;
    this.rent = {
      minRent: undefined,
      maxRent: undefined,
    };
    this.isActable = undefined;
    this.hasSpecialOffer = undefined;
    this.isHighStake = undefined;
    this.isNew = undefined;
    this.fees = undefined;
    this.bbox = undefined;
    this.priority = undefined;
  }
}
