import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { interval } from 'rxjs/internal/observable/interval';
import { debounce, takeUntil, tap } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

import { AbstractComparatorComponent } from '../abstract-comparator-component';
import { ComparatorPreviewBlocComponent } from '../comparator-preview-bloc/comparator-preview-bloc.component';

import { dropDownAnimation } from '../../../design-system/animations';
import { breakpoints } from '../../../utils/models/breakpoint';
import { BreakpointEnum } from '../../../utils/models/enums/breakpoint.enum';
import { EventUtilsService } from '../../../utils/services/event/event-utils.service';

@Component({
  selector: 'app-comparator-preview-button',
  templateUrl: './comparator-preview-button.component.html',
  styleUrls: ['./comparator-preview-button.component.scss'],
  animations: [dropDownAnimation],
  standalone: true,
  imports: [NgIf, MatButtonModule, MatIconModule, ComparatorPreviewBlocComponent],
})
export class ComparatorPreviewButtonComponent extends AbstractComparatorComponent implements OnInit {
  showPanel = false;
  @Input() label: string;
  hide: boolean;

  constructor(
    private readonly _breakpointObserver: BreakpointObserver,
    private readonly _eventUtilsService: EventUtilsService,
    private readonly eRef: ElementRef,
  ) {
    super();
  }

  ngOnInit() {
    this._comparatorPageService
      .getComparatorStorage()
      .pipe(takeUntil(this.$d))
      .subscribe((comparatorStorage) => {
        this.lotToCompare = this._comparatorPageService.getComparatorStorageForCurrentUser(comparatorStorage);
      });
    this._comparatorPageService.$shouldOpenComparatorPreview.subscribe(() => {
      this.showSubPanel(true);
    });

    this._breakpointObserver
      .observe(breakpoints[BreakpointEnum.md].down)
      .pipe(
        tap((breakPoint) => {
          this.hide = breakPoint.matches;
        }),
        takeUntil(this.$d),
      )
      .subscribe();
    this._eventUtilsService.documentClickedTarget
      .pipe(
        takeUntil(this.$d),
        debounce(() => interval(50)),
      )
      .subscribe((target) => {
        this.documentClickListener(target);
      });
  }

  showSubPanel(fromEvent = false) {
    this.showPanel = fromEvent ? true : !this.showPanel;

    if (!fromEvent && this.showPanel) {
      this._comparatorPageService.pushToDatalayerAccessPreview();
    }
  }

  documentClickListener(target): void {
    if (!this.eRef.nativeElement.contains(target)) {
      // Clicked outside
      this.showPanel = false;
    }
  }
}
