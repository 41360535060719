import { Directive, EventEmitter, Output } from '@angular/core';

export interface FormEvent {
  onChange(event: Event): void;

  onClick(event: Event): void;

  onBlur(event: Event): void;

  onFocus(event: Event): void;
}

@Directive()
export class AbstractFormEvent implements FormEvent {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<Event> = new EventEmitter<Event>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click: EventEmitter<Event> = new EventEmitter<Event>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<Event> = new EventEmitter<Event>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus: EventEmitter<Event> = new EventEmitter<Event>();

  onBlur(event: Event): void {
    this.blur.emit(event);
  }

  onChange(event: Event): void {
    this.change.emit(event);
  }

  onClick(event: Event): void {
    this.click.emit(event);
  }

  onFocus(event: Event): void {
    this.focus.emit(event);
  }
}
