import { Component, Input, ViewChild } from '@angular/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgFor, NgIf } from '@angular/common';

import { MenuConfig, MenuConfigEntry } from '../header/header.component';

import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-header-menu-entry',
  templateUrl: './header-menu-entry.component.html',
  styleUrls: ['./header-menu-entry.component.scss'],
  standalone: true,
  imports: [NgIf, MatMenuModule, MatIconModule, NgClass, NgFor, RouterLink],
})
export class HeaderMenuEntryComponent {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  recheckIfInMenu: boolean;
  @Input() isMobile = false;
  @Input() helpCenterAdress: string;
  @Input() menuConfig: MenuConfig;
  @Input() isFirst: boolean;

  /**
   * Creates an instance of HeaderMenuComponent.
   *
   * @param {I18nService} i18nService
   */
  constructor(public i18nService: I18nService) {}

  openMenu() {
    this.trigger.openMenu();
    this.recheckIfInMenu = true;
  }

  closeMenu() {
    setTimeout(() => {
      if (!this.recheckIfInMenu) {
        this.trigger.closeMenu();
      }
    }, 50);
    this.recheckIfInMenu = false;
  }

  /**
   * Call action item
   *
   * @memberof HeaderMenuEntryComponent
   */
  public callbackAction(item: MenuConfigEntry): void {
    item.action();
  }
}
