import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { isNil } from 'lodash-es';

@Component({
  selector: 'app-formly-text',
  templateUrl: './formly-text.component.html',
  styleUrls: ['./formly-text.component.scss'],
  standalone: true,
})
export class FormlyTextComponent extends FieldType {
  public isTitle(): boolean {
    return !isNil(this.field.props.titleLevel);
  }
}
