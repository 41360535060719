import { Inject, Injectable, Optional } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateAdapterOptions, MomentDateAdapter } from '@angular/material-moment-adapter';
import moment from 'moment-timezone';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { DateTimeService } from './date-time.service';

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {
  constructor(
    private readonly _dateTimeService: DateTimeService,
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
    @Optional()
    @Inject(MAT_MOMENT_DATE_ADAPTER_OPTIONS)
    private readonly options?: MatMomentDateAdapterOptions,
  ) {
    super(dateLocale, options); // set default locale
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public format(date: moment, displayFormat: string): string {
    const format = this._dateTimeService.format;
    if (this._dateTimeService.transformTimeZone) {
      return moment.tz(date, this._dateTimeService.zone).format(format);
    }

    return date.format(format);
  }
}
