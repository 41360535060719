/* eslint-disable @typescript-eslint/no-explicit-any */
import { ValoRole } from '@commons-dto/valo-back';

import { StatusTagColor } from '../../design-system/model/status-tag-color.enum';

export interface AdvancedTrackingTableClassDef {
  classTrue: string | string[] | { [key: string]: boolean };
  classFalse: string | string[] | { [key: string]: boolean };
  ngClassComputed?: string | string[] | { [key: string]: boolean };

  condition(item): boolean;
}

export class AdvancedTrackingTableColumn {
  key: string;
  hidden?: boolean;
  sortable?: boolean;
  replaceFalsyWithDash?: boolean;
  i18nTitle?: string;
  type?: string;
  parameters?: any;
  visibleFor?: Array<ValoRole>;
  table?: string;
  ngClass?: AdvancedTrackingTableClassDef;
  statusTagOption?: {
    computeTagType(item): StatusTagColor;
  };
  showFilter?: boolean;
  customFormat?: (row: any) => any;
  selfSearch?: {
    // eslint-disable-next-line
    type: 'input' | 'autocomplete' | 'dateSimple' | 'dateRange' | 'boolean';
    dataFillServicePath?: string; // doit etre utilise dans le cas au il y a une liste de données pur le composent formulaire
  };
  picto?: {
    iconName: string;
    i18nTooltip?: string;
    class: string;
  };
}
