export enum DossierStatusEnum {
  DRAFT = 'DRAFT',
  SENDING = 'SENDING',
  SIGN_ACQ = 'SIGN_ACQ',
  REJECT_ACQ = 'REJECT_ACQ',
  COUNTERSIGN = 'COUNTERSIGN',
  COUNTERSIGNED = 'COUNTERSIGNED',
  REJECT_DEV = 'REJECT_DEV',
  ERROR = 'ERROR',
  CANCELLED = 'CANCELLED',
  SIGN_PAPER = 'SIGN_PAPER',
  EXPIRED = 'EXPIRED',
}
