import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgFor, NgIf } from '@angular/common';

import { SnackbarService } from '../../../utils/services/snackbar.service';
import { ReferenceTablesService } from '../../../utils/services/reference-tables.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { AutoCompleteByProgram } from '../../../utils/models/AutoCompleteByProgram';
import { ChipAutocompletePopulatedComponent } from '../../../utils/components/chip-autocomplete-populated/chip-autocomplete-populated.component';
import { ProgramService } from '../../../programs/services/program.service';

@Component({
  selector: 'app-search-form-programs',
  templateUrl: './search-form-programs.component.html',
  styleUrls: ['./search-form-programs.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatFormFieldModule,
    MatChipsModule,
    NgFor,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatOptionModule,
    AutoCompleteModule,
  ],
})
export class SearchFormProgramsComponent extends ChipAutocompletePopulatedComponent implements OnInit {
  @Input() design = 'default';
  @ViewChild('chipList') chipList;
  public emptyResponse;
  public notEnoughLetters;
  hasSelectedProgram = false;

  constructor(
    referenceTablesService: ReferenceTablesService,
    snackbarService: SnackbarService,
    public i18nService: I18nService,
    public programService: ProgramService,
  ) {
    super(referenceTablesService, snackbarService, i18nService);
  }

  public formatProgramResponseList(programs: Array<AutoCompleteByProgram>): void {
    this.mapListFilteredItems.clear();
    programs.forEach((element) => {
      // The purpose of the symbol @@idOfProgram@@ is to guarant unique program name result see: VALO-4281
      // Use program id is as key instead of only name and department to guarantee suggestion of all program including those with same name
      if (this.design === 'homepage') {
        this.mapListFilteredItems.set(`${element.programName} (${element.code})`, element.id);
      } else {
        this.mapListFilteredItems.set(`@@${element.id}@@ ${element.programName} (${element.code})`, element.id);
      }
    });
  }

  public filter(event?: string): Observable<string[]> {
    clearTimeout(this.timeoutWork);
    this.timeoutWork = setTimeout((): Observable<Array<string>> => {
      const value = event ? event : this.chipCtrl.value.trim();
      if (!value.length) {
        this.notEnoughLetters = false;
      } else if (value.length < 3) {
        this.notEnoughLetters = true;
        this.emptyResponse = false;
      } else {
        this.notEnoughLetters = false;
        this.chipList.errorState = this.chipCtrl.errors && this.chipCtrl.errors?.required;
        this.programService.searchProgramsByName(value).subscribe((itemsFound) => {
          this.emptyResponse = itemsFound.length === 0;
          this.formatProgramResponseList(itemsFound);
          this.filteredItems = Array.from(this.getMapListFilteredItems().keys());
        });

        return of(Array.from(this.getMapListFilteredItems().keys()));
      }
    }, 200);

    return of([]);
  }

  /**
   * Used to hide from view the id** of program
   *
   * **: Id of program used to guarant unique key result suggestion of program
   * @param programNameSuggestedWithIdProgram string
   * @returns string
   */
  hideProgramIdKeyWithReplacement(programNameSuggestedWithIdProgram: string): string {
    const replaceRegex = /@@.*@@/i;
    return programNameSuggestedWithIdProgram && programNameSuggestedWithIdProgram.replace(replaceRegex, '');
  }

  selectedProgram(event) {
    this.selected(event);
    this.hasSelectedProgram = true;
  }

  unselectedProgram(event) {
    this.remove(event);
    this.hasSelectedProgram = false;
  }
}
