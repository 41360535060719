import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { CoordinatesResponse } from '../../models/CoordinatesResponse';
import { AppConfigService } from '../../services/app-config.service';
import { I18nService } from '../../services/i18n.service';
import { maxDegreeValidator } from '../../validators/max-degree-validator.directive';

@Component({
  selector: 'app-coordinates',
  templateUrl: './coordinates.component.html',
  styleUrls: ['./coordinates.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatTooltipModule],
})
export class CoordinatesComponent implements OnInit {
  /**
   * ParentForm to add controls to
   *
   * @type {FormGroup}
   * @memberof CoordinatesComponent
   */
  @Input() parentForm: UntypedFormGroup;

  /**
   * Recovered coordinates for update forms
   *
   * @type {CoordinatesResponse}
   * @memberof CoordinatesComponent
   */
  @Input() recoveredInfo: CoordinatesResponse;

  /**
   *  Control of Latitude
   *  @type {FormControl}
   *  @memberof CoordinatesComponent
   */
  public latitudeControl: FormControl;

  /**
   *  Control of Longitude
   *  @type {FormControl}
   *  @memberof CoordinatesComponent
   */
  public longitudeControl: FormControl;

  /**
   * Creates an instance of CoordinatesComponent.
   * @param {I18nService} i18nService
   * @memberof CoordinatesComponent
   */
  constructor(
    public i18nService: I18nService,
    private readonly appConfigService: AppConfigService,
  ) {}

  ngOnInit(): void {
    const regCoordinateFormat = this.appConfigService.appConfig.regEx.coordinates;

    this.parentForm.addControl(
      'latitude',
      new UntypedFormControl('', [Validators.required, maxDegreeValidator(-90, 90, 'latitude'), Validators.pattern(regCoordinateFormat)]),
    );

    this.latitudeControl = this.parentForm.controls.latitude as FormControl;

    this.parentForm.addControl(
      'longitude',
      new UntypedFormControl('', [
        Validators.required,
        maxDegreeValidator(-180, 180, 'longitude'),
        Validators.pattern(regCoordinateFormat),
      ]),
    );
    this.longitudeControl = this.parentForm.controls.longitude as FormControl;

    /* Init data for update form */
    if (this.recoveredInfo) {
      this.longitudeControl.setValue(this.recoveredInfo.lng);
      this.latitudeControl.setValue(this.recoveredInfo.lat);
    }
  }

  getErrorMessage(control: FormControl): string {
    const coordinateError =
      control.getError('maxDegreeValidator') === 'latitude'
        ? this.i18nService._('Error_Field_CoordinatesFormat_Latitude')
        : this.i18nService._('Error_Field_CoordinatesFormat_Longitude');

    return control.hasError('required')
      ? this.i18nService._('Error_Field_Required')
      : control.hasError('pattern')
      ? this.i18nService._('Error_Field_Invalid_Format')
      : control.hasError('maxDegreeValidator')
      ? coordinateError
      : undefined;
  }
}
