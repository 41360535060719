<div class="choices">
  <ng-container *ngFor="let possibleChoice of possibleChoices">
    <div (click)="onToggleChoice(possibleChoice)" class="choice" [class.multiple]="multiple" [class.selected]="possibleChoice.selected">
      <span class="icon" *ngIf="possibleChoice.icon">
        <app-icon [name]="possibleChoice.icon" size="20px"></app-icon>
      </span>
      {{ possibleChoice.label | translate: possibleChoice.labelParam }}
    </div>
  </ng-container>
</div>
