/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgClass } from '@angular/common';

import { I18nService } from './../../../utils/services/i18n.service';

@Component({
  selector: 'app-information-renderer',
  templateUrl: './information-renderer.component.html',
  styleUrls: ['./information-renderer.component.scss'],
  standalone: true,
  imports: [NgIf, MatIconModule, NgClass, MatTooltipModule],
})
export class InformationRendererComponent implements ICellRendererAngularComp {
  public cellValue: any;
  public picto: any;
  public action: any;
  public row: any;

  constructor(public readonly i18nService: I18nService) {}

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
    this.row = params.data;
    this.picto = params['picto'];
    this.action = params['action'];
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    // set value into cell again
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.value;
  }
}
