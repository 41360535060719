<div class="flex flex-col p-5">
  <!-- Subheader -->
  <app-sticky-header-form attr.data-cy="i18nService._('Title_LegalNotice')" [titleText]="i18nService._('Title_LegalNotice')">
  </app-sticky-header-form>

  <div class="grid grid-cols-10 pt-16">
    <div class="col-span-10 lg:col-span-8 lg:col-start-2 xl:col-span-6 xl:col-start-3 flex flex-col pt-16">
      <div class="mb-2">
        <h2 class="m-0">{{ i18nService._('Title_EditorLegal') }}</h2>
        <div>
          <p>
            VALORISSIMO<br />
            Société par actions simplifiées à associé unique au capital de 2.879.353 € <br />
            Siège social : 3 Boulevard Galliéni 92130 Issy-les-Moulineaux <br />
            834 500 241 RCS Nanterre <br />
            Téléphone : 01 55 38 53 31 <br />
            Adresse mail :
            <a class="txt-orange" href="mailto:contact@valorissimo.com">contact&#64;valorissimo.com</a>
          </p>
        </div>
      </div>

      <div class="mb-2">
        <h2 class="m-0">{{ i18nService._('Title_PublicationLegal') }}</h2>
        <div>
          <p>
            Directeur de la publication : Emma Leca <br />
            Directeur de la rédaction : Dominique Tremouille
          </p>
        </div>
      </div>

      <div class="mb-2">
        <h2 class="m-0">{{ i18nService._('Title_HostLegal') }}</h2>
        <div>
          <p>
            Prosodie <br />
            150 Rue Gallieni <br />
            92100 Boulogne-Billancourt
          </p>
        </div>
      </div>

      <div class="mb-2">
        <h2 class="m-0">{{ i18nService._('Title_ConfidentialityLegal') }}</h2>
        <div>
          <p>
            Le site VALORISSIMO.COM est un site BtoB exclusivement réservé à ses partenaires ayant signé un contrat et disposant de codes
            d'accès . Les informations collectées sur le site VALORISSIMO.COM sont destinées à VALORISSIMO. Elles font l'objet d'un
            traitement informatique ayant pour finalité le traitement de votre demande et notre gestion commerciale, en particulier : <br />
          </p>

          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">la gestion des demandes de partenariat,</p>
            </div>
          </mat-list-item>

          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">la gestion de nos relations, en particulier, la mise à jour de vos comptes utilisateurs.</p>
            </div>
          </mat-list-item>

          <p>
            la réponse aux demandes d'information formulées sur le site, l'envoi d'informations commerciales lorsque vous avez donné votre
            accord ou lorsque la législation en vigueur l'autorise, l'établissement de statistiques générales sur la consultation du site
            afin d'améliorer les prestations que nous offrons sur ce site. Les informations collectées sont susceptibles de faire l'objet
            d'un traitement par profilage en vue de vous recommander un bien immobilier correspondant au mieux à vos critères de recherche.
          </p>
          <p>
            La politique de confidentialité des données personnelles vous est proposée par la société VALORISSIMO dans le cadre de la loi
            n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, et au règlement (UE) 2016/679 du Parlement
            européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à
            l'égard du traitement des données à caractère personnel et à la libre circulation des données (le "RGPD").
          </p>
          <p>La société VALORISSIMO s'engage à traiter en toute sécurité les données personnelles de vos clients.</p>
        </div>
      </div>

      <div class="mb-2">
        <h2 class="m-0">{{ i18nService._('Title_RightsLegal') }}</h2>
        <div>
          <p>L'internaute dispose dans les conditions définies par la Loi Informatique et Libertés et le RGPD, sauf exceptions : <br /></p>

          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">
                (i) du droit de retirer à tout moment son consentement aux parties du Traitement mis en œuvre par la société VALORISSIMO
                fondées sur ledit consentement.
              </p>
            </div>
          </mat-list-item>

          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">
                (ii) du droit d'obtenir de VALORISSIMO la confirmation que des données à caractère personnel les concernant sont ou ne sont
                pas traitées et, lorsqu'elles le sont, l'accès auxdites données à caractère personnel ainsi qu’à plusieurs informations sur
                les traitements (finalités du traitement, catégories de données à caractère personnel concernées, destinataires ou
                catégories de destinataires auxquels les données à caractère personnel ont été ou seront communiquées, la durée de
                conservation des données à caractère personnel envisagée ou, lorsque ce n'est pas possible, les critères utilisés pour
                déterminer cette durée, etc.) ;
              </p>
            </div>
          </mat-list-item>

          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">
                (iii) du droit d'obtenir de la société VALORISSIMO, dans les meilleurs délais, la rectification des données à caractère
                personnel les concernant qui sont inexactes ;
              </p>
            </div>
          </mat-list-item>

          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">
                (iv) du droit d'obtenir de société la VALORISSIMO l'effacement, dans les meilleurs délais, de données à caractère personnel
                les concernant ;
              </p>
            </div>
          </mat-list-item>

          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">(v) du droit d'obtenir de société la VALORISSIMO la limitation du traitement ;</p>
            </div>
          </mat-list-item>

          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">
                (vi) du droit de recevoir les données à caractère personnel les concernant qu’ils ont fournies à la société VALORISSIMO,
                dans un format structuré, couramment utilisé et lisible, et du droit de transmettre ces données à un autre responsable du
                traitement sans que société VALORISSIMO y fasse obstacle ;
              </p>
            </div>
          </mat-list-item>

          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">
                (vii) du droit d’obtenir à tout moment de la société VALORISSIMO, pour des raisons tenant à leur situation particulière,
                qu’il ne procède plus au traitement des données à caractère personnel les concernant ;
              </p>
            </div>
          </mat-list-item>

          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">
                (viii) du droit de s'opposer à tout moment au traitement des données à caractère personnel les concernant à des fins de
                prospection ;
              </p>
            </div>
          </mat-list-item>

          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">
                ((ix) du droit de ne pas faire l'objet d'une décision fondée exclusivement sur un traitement automatisé, y compris le
                profilage, produisant des effets juridiques les concernant ou les affectant de manière significative de façon similaire.
              </p>
            </div>
          </mat-list-item>

          <p>
            Le Client a également le droit d’introduire une réclamation auprès de l’autorité française de protection des données, la CNIL
            (<a class="txt-orange" href="https://www.cnil.fr" target="_blank">www.cnil.fr</a>). Le Client peut définir, modifier et révoquer
            à tout moment des directives relatives à la conservation, à l'effacement et à la communication de ses données à caractère
            personnel après son décès. Le Client a ainsi le droit de désigner un tiers auquel les données le concernant pourront être
            communiquées après son décès. Il s’engage alors à informer ce tiers de sa démarche. Sous réserve de justifier de son identité et
            des éléments susvisés, le Client peut exercer les droits décrits ci-dessus en écrivant à la société VALORISSIMO à l’adresse
            électronique suivante :
            <a class="txt-orange" href="mailto:dpo@valorissimo.com">dpo&#64;valorissimo.com</a>
            ou à l’adresse postale de son siège social.
          </p>
        </div>
      </div>

      <div class="mb-2">
        <h2 class="m-0">{{ i18nService._('Title_PersonalDataLegal') }}</h2>
        <div>
          <p>
            Les partenaires de VALORISSIMO sont amenés à renseigner sous leur responsabilité sur le site VALORISSIMO.COM les données
            personnelles des prospects comme :
          </p>
          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">Des coordonnées (par exemple : nom, prénom, n° de téléphone, email, adresse, code postal, ville)</p>
            </div>
          </mat-list-item>
          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">Dates de toutes les demandes effectuées via les différents formulaires disponibles sur nos sites Internet</p>
            </div>
          </mat-list-item>
          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">
                Vos préférences et centres d'intérêt (par exemple : résidence principale, résidence secondaire, bien locatif, prix du
                logement recherché, nombre de pièce, surface habitable, surface des annexes, durée du dispositif Pinel, prix au m², choix
                d'investissement (défiscaliser, constituer un patrimoine, avoir un revenu complémentaire à la retraite, autre),
                téléchargement de plan)
              </p>
            </div>
          </mat-list-item>
          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">
                Des informations financières et fiscales (par exemple : revenus nets mensuels au foyer, revenus annuels imposables, apport,
                durée du prêt, taux du prêt, taux de l'assurance, composition du foyer, qualification primo-accédant, revenu fiscal de
                référence n-2, mensualité désirée, situation familiale, nombre d'enfants à charge, capacité d'épargne mensuelle)
              </p>
            </div>
          </mat-list-item>
          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">Vos questions/commentaires</p>
            </div>
          </mat-list-item>
          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">
                Vos questions et les réclamations que vous pourriez avoir et toutes les données que vous nous adressez si nous communiquons
                avec vous par courrier électronique, en ligne ou sur les réseaux sociaux.
              </p>
            </div>
          </mat-list-item>
        </div>
      </div>

      <div class="mb-2">
        <h2 class="m-0">{{ i18nService._('Title_TreatmentLegal') }}</h2>
        <div>
          <p>
            Les finalités visées par la société VALORISSIMO sont susceptibles de constituer des mesures de profilage : les données
            collectées (en dehors de toutes données personnelles des clients) pouvant être utilisées à des fins de démarches commerciales.
            En particulier, nous collectons les données personnelles dans le but de : <br />
          </p>
          <ol>
            <li>
              <p>Gérer et répondre aux demandes des prescripteurs pour vous déclarer comme prospects pour et ainsi :</p>
              <span class="flex">
                <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
                <p class="mt-0">Réserver un lot</p> </span
              ><span class="flex">
                <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
                <p class="mt-0">Valider une option</p> </span
              ><span class="flex">
                <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
                <p class="mt-0">Déclarer un prospect</p>
              </span>
            </li>
            <li>
              <p>Adapter les parcours et contenus proposées aux prescripteurs</p>
            </li>
            <li>
              <p>
                Personnaliser notre communication auprès des prescripteurs via notre programme marketing client, afin d'effectuer des
                opérations marketings, de promotion de marques et d'avoir une meilleure compréhension de vos besoins et de vos souhaits.
              </p>
            </li>
            <li>
              <p>Adapter nos produits et services proposés aux prescripteurs afin de répondre aux mieux vos demandes.</p>
            </li>
            <li>
              <p>Personnaliser les offres marchandes et les messages relationnels à l'intention des prescripteurs.</p>
            </li>
            <li>
              <p>
                Améliorer nos services, notamment : réalisation d'études et d'analyses des questionnaires et commentaires prospects et
                clients, gestion des réclamations...
              </p>
            </li>
            <li>
              <p>
                Sécuriser et améliorer votre utilisation des sites Internet, notamment : améliorer la navigation, mise en œuvre de la
                sécurité et prévention des fraudes.
              </p>
            </li>
            <p>
              Il s'agit d'un droit individuel qui ne peut être exercé que par la personne concernée relativement à ses propres informations
              : pour des raisons de sécurité, VALORISSIMO devra vérifier son identité afin d'éviter toute communication d'informations
              confidentielles la concernant à une autre personne qu'elle.
            </p>
          </ol>
        </div>
      </div>

      <div class="mb-2">
        <h2 class="m-0">{{ i18nService._('Title_CookiesLegal') }}</h2>
        <div>
          <ol>
            <li>
              <p>Cookies à usages techniques :</p>
              <span class="flex">
                <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
                <p class="mt-0">
                  Cookies nécessaires au bon usage du Site : <br />
                  VALORISSIMO utilise des cookies de mémorisation, qui sont des fichiers transférés sur votre ordinateur, mais ne permettent
                  pas de vous identifier. <br />
                  Les cookies enregistrent des informations relatives à la navigation de votre ordinateur sur le site (les pages que vous
                  avez consultées, la date et l'heure de la consultation, etc.) et permet de les extraire lors de vos connexions ultérieures
                  au site afin de personnaliser votre connexion. La durée de conservation de ces données dans votre ordinateur est de 20
                  minutes par session de navigation. Vous pouvez vous opposer à l'enregistrement de cookies en configurant votre ordinateur
                  selon le navigateur que vous utilisez. Toutefois, la suppression des cookies pourrait empêcher l’accès à tout ou une
                  partie du site notamment à votre compte personnel si vous êtes connecté à ce dernier. La section aide de votre navigateur
                  peut vous indiquer comment les refuser. Pour en savoir plus sur les cookies, rendez-vous sur cette page.
                </p>
              </span>
            </li>
            <li>
              <p>Cookies à usage de suivi :</p>
              <span class="flex">
                <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
                <p class="mt-0">
                  Mesure d'audience : <br />
                  données statistiques de fréquentation et d'utilisation du Site (rubriques et contenus visés, parcours) afin de nous aider
                  à mesurer et étudier l'efficacité de notre contenu interactif en ligne, de ses caractéristiques, publicités et autres
                  communications, et ainsi d'améliorer l'intérêt et l'ergonomie des services consultés sur le Site.
                </p>
              </span>
            </li>
          </ol>
        </div>
      </div>

      <div class="mb-2">
        <h2 class="m-0">
          {{ i18nService._('Title_IntellectualPriorityLegal') }}
        </h2>
        <div>
          <p>
            VALORISSIMO a ouvert ce site pour l'information personnelle de ses utilisateurs. Aucune exploitation commerciale même partielle
            des données qui y sont présentées ne pourra être effectuée sans l'accord préalable et écrit de VALORISSIMO. La présentation et
            le contenu du présent site constituent ensemble une œuvre protégée par les lois en vigueur sur la propriété intellectuelle.
            Aucune reproduction et/ou représentation, partielle ou intégrale, ne pourra être faite sans l'accord préalable et écrit de
            VALORISSIMO. La dénomination VALORISSIMO, le logo VALORISSIMO, la dénomination des filiales de VALORISSIMO, le nom et les logos
            de leurs produits ainsi que les slogans sont propriété de VALORISSIMO et/ou de ses filiales. Toute reproduction, utilisation
            et/ou modification qui en serait faite sans l'accord préalable et écrit de VALORISSIMO, est susceptible d'en constituer la
            contrefaçon. Elle n'engagera par ailleurs d'aucune manière la responsabilité de VALORISSIMO. Les dessins, photographies, images,
            textes, séquences animées sonores ou non, et autres documentations représentées sur le présent site Internet sont objet de
            droits de propriété industrielle et/ou intellectuelle, propriétés de VALORISSIMO ou de ses filiales. A ce titre, toute
            reproduction, représentation, adaptation, traduction, et/ou transformation, partielle ou intégrale, ou transfert sur un autre
            site sont interdits. La copie à usage privé de ces différents objets de droit est autorisée moyennant mention de la source. Leur
            reproduction partielle ou intégrale, sans l'accord préalable et écrit de VALORISSIMO, est strictement interdite. Les
            renseignements, présentations et illustrations contenus dans les pages de ce site sont fournis à titre purement indicatif et
            restent susceptibles de modifications à tout moment et sans préavis. Leur utilisation à des fins privées ou professionnelles ne
            saurait engager la responsabilité de VALORISSIMO de quelque manière que ce soit. En conséquence VALORISSIMO décline toute
            responsabilité : <br />
          </p>
          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">
                pour tous dommages, directs et/ou indirects, qu'elles qu'en soient les causes, origines, natures ou conséquences, provoquées
                à raison de l'accès de quiconque au site ou de l'impossibilité d'y accéder, de même que l'utilisation du site et/ou du
                crédit accordé à une quelconque information provenant directement ou indirectement du site.
              </p>
            </div>
          </mat-list-item>
          <mat-list-item class="text-base block">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">pour tout usage qui pourra être fait de ces différentes informations.</p>
            </div>
          </mat-list-item>
        </div>
      </div>

      <div class="mb-2">
        <h2 class="m-0">{{ i18nService._('Title_HypertextLinksLegal') }}</h2>
        <div>
          <p>
            VALORISSIMO.COM met à votre disposition des liens hypertextes vers d'autres sites Internet. VALORISSIMO ne vérifie toutefois pas
            la licéité du contenu de ces sites et ne saurait être tenu responsable des prestations ou informations fournies sur ces sites.
          </p>
        </div>
      </div>

      <div class="mb-2">
        <h2 class="m-0">{{ i18nService._('Title_PriceLegal') }}</h2>
        <div>
          <p>
            Les prix affichés sur le site sont susceptibles d’être révisés à tout moment par les partenaires de VALORISSIMO dont l'offre
            commerciale est intégrée par VALORISSIMO sur son site VALORISSIMO.COM. Des mises à jour du site sont susceptibles d’intervenir
            quotidiennement.
          </p>
        </div>
      </div>

      <div class="mb-2">
        <h2 class="m-0">{{ i18nService._('Title_ProfitabilityLegal') }}</h2>
        <div>
          <p>
            Les rentabilités marché estimées affichées sur le site sont définies comme étant la rentabilité brute estimée pour les douze
            mois suivant l'achèvement de l'immeuble, hors incidence impôts fonciers et charges diverses du bailleur et hors impacts fiscaux.
            Le prix du logement s'entend parking inclus, hors frais de notaires, de garanties bancaires (hypothèque ou caution logement),
            d'enregistrement et de règlement de copropriété ou état descriptif de copropriété. Le calcul du taux de rentabilité prévisionnel
            est égal au loyer brut annuel divisé par le prix de vente du logement parking compris (si associé au lot) multiplié par 100. Il
            est ici précisé que les indications de rentabilité estimée figurant sur le site ne sont qu'indicatives et ne sont pas
            contractuelles ni définitives. Elles sont susceptibles d'être révisées à tout moment par les promoteurs partenaires de
            VALORISSIMO. Le site est mis à jour régulièrement.
          </p>
        </div>
      </div>

      <div class="mb-2">
        <h2 class="m-0">{{ i18nService._('Title_RentLegal') }}</h2>
        <div>
          <p>
            Les loyers marchés estimés proposés à titre indicatif sont issus d’une estimation réalisée par un professionnel (administrateur
            de biens) partenaire de VALORISSIMO. Cette estimation est basée sur différents indicateurs du marché locatif du secteur
            d’implantation du programme.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
