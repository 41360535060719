import { Component } from '@angular/core';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';
import { NgIf } from '@angular/common';

import { SafePipe } from '../../../utils/pipes/safe.pipe';

@Component({
  selector: 'app-formly-wrapper-label-html',
  template: `
    <div class="p-field">
      <label *ngIf="props.label && props.hideLabel !== true" [for]="id" class="block mb-2">
        <span [innerHTML]="props.label | safe: 'html'"></span>
        <span *ngIf="props.required && props.hideRequiredMarker !== true" aria-hidden="true">*</span>
      </label>
      <ng-container #fieldComponent></ng-container>
      <small *ngIf="showError" class="p-error">
        <formly-validation-message class="ui-message-text" [field]="field"></formly-validation-message>
      </small>
    </div>
  `,
  styles: [
    `
      .ui-messages-error {
        margin: 0;
        margin-top: 4px;
      }
    `,
  ],
  standalone: true,
  imports: [FormlyModule, NgIf, SafePipe],
})
export class FormlyWrapperLabelHtmlComponent extends FieldWrapper {}
