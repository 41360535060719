/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { NgClass, NgFor, NgIf } from '@angular/common';

import { SearchLocationLabelComponent } from './search-location-label/search-location-label.component';

import { I18nService } from '../../../../../utils/services/i18n.service';

@Component({
  selector: 'app-search-localisation-list',
  templateUrl: './search-localisation-list.component.html',
  styleUrls: ['./search-localisation-list.component.scss'],
  standalone: true,
  imports: [NgIf, MatChipsModule, NgClass, NgFor, MatIconModule, SearchLocationLabelComponent],
})
export class SearchLocalisationListComponent {
  @Input() locationList: any[];
  @Input() open: boolean;
  @Input() titelUp: boolean;
  @Output() readonly deleteLocalisationEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(public readonly i18nService: I18nService) {}

  removeLocalisation($event: Event, index: number) {
    $event.stopImmediatePropagation();
    const removed = this.locationList.splice(index, 1);
    this.deleteLocalisationEvent.emit(removed[0]);
  }
}
