<div class="flex flex-col w-full h-full overflow-auto">
  <!-- Sticky subHeader form -->
  <app-sticky-header-form
    (actionButtonClicked)="redirectionSpecialOfferCreation()"
    [actionButtonIconMob]="displayNewSObutton ? 'lg:hidden icon-plus text-sm font-bold pl-lg-2 inline-block align-middle' : null"
    [actionButtonIcon]="displayNewSObutton ? 'icon-plus text-sm pb-1 font-bold pl-2 inline-block align-middle' : null"
    [actionButtonLabel]="displayNewSObutton ? i18nService._('Title_SpecialOfferCreation') : null"
    [titleText]="i18nService._('Title_SpecialOffers')"
  >
  </app-sticky-header-form>

  <div class="pt-16 mx-3 mb-4 lg:mx-5 md:mx-5 sm:mx-4 px-2 lg:px-6 md:px-5 sm:px-3">
    <div class="grid grid-cols-7 mb-3">
      <div class="col-span-2">
        <mat-form-field class="fullW flex w-full">
          <mat-label>{{ i18nService._('Txt_Placeholder_Search') }}</mat-label>
          <input [(ngModel)]="filterValue" (ngModelChange)="textFilterSubject.next($event)" matInput />
        </mat-form-field>
      </div>
    </div>
    <app-grid-tracking-table [columnDefs]="columnDefs" [tableLoader]="specialOffersTrackingTableService" [textFilter]="textFilterValue">
    </app-grid-tracking-table>
  </div>
</div>
