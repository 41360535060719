import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { RegisterSpecialOfferComponent } from './component/register-special-offer/register-special-offer.component';
import { SpecialOfferRoutingModule } from './special-offer-routing.module';
import { UtilsModule } from '../utils/utils.module';
import { StepOneSpecialOfferFormComponent } from './component/step-one-special-offer-form/step-one-special-offer-form.component';
import { StepTwoSpecialOfferFormComponent } from './component/step-two-special-offer-form/step-two-special-offer-form.component';
import { TrackingTablesModule } from '../tracking-tables/tracking-tables.module';
import { StepThreeSpecialOfferFormComponent } from './component/step-three-special-offer-form/step-three-special-offer-form.component';

@NgModule({
  imports: [
    CommonModule,
    MatAutocompleteModule,
    SpecialOfferRoutingModule,
    UtilsModule,
    TrackingTablesModule,
    MatButtonToggleModule,
    RegisterSpecialOfferComponent,
    StepOneSpecialOfferFormComponent,
    StepTwoSpecialOfferFormComponent,
    StepThreeSpecialOfferFormComponent,
  ],
})
export class SpecialOfferModule {}
