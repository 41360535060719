<mat-checkbox
  color="primary"
  *ngFor="let item of items"
  [checked]="isChecked(item.id)"
  (change)="onChange($event, item.id)"
  [ngClass]="cssClassNames"
  [name]="'' + item.id"
>
  {{ item.label }}
</mat-checkbox>
