import { Component, Input } from '@angular/core';
import { ProspectType } from 'libs/commons-dto/src/lib/dossier-prospect/prospect/prospect-type.enum';
import { DossierResponse, ProspectResponse } from '@commons-dto/dossier-prospect';
import { NgFor, NgIf } from '@angular/common';

import { StatusTagComponent } from '../status-tag/status-tag.component';
import { CardComponent } from '../card/card.component';

import { DossierProspectUtils } from './../../../dossier-prospect/services/dossier-prospect-utils.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { DossierProspectService } from '../../../dossier-prospect/services/dossier-prospect.service';
import { DossierProspectRouteService } from '../../../dossier-prospect/components/dossier-prospect/dossier-prospect.route.service';
import { IconComponent } from '../../standalone/icon/icon.component';

@Component({
  selector: 'app-prospect-card',
  templateUrl: './prospect-card.component.html',
  styleUrls: ['./prospect-card.component.scss'],
  standalone: true,
  imports: [CardComponent, NgFor, IconComponent, NgIf, StatusTagComponent],
})
export class ProspectCardComponent {
  private _dossier: DossierResponse;
  activeProspect: ProspectResponse;
  ProspectType = ProspectType;
  prospects: any;

  get dossier(): DossierResponse {
    return this._dossier;
  }

  @Input() set dossier(dossier: DossierResponse) {
    this._dossier = dossier;
    const prospects = [];
    for (const prospect of dossier.prospects) {
      prospects.push({
        ...prospect,
        icon: this.prospectService.getIconByProspect(prospect),
      });
    }
    this.prospects = prospects;

    this.activeProspect = prospects?.length ? prospects[0] : ({} as ProspectResponse);
  }

  constructor(
    public readonly i18nService: I18nService,
    private readonly dossierProspectRouteService: DossierProspectRouteService,
    private readonly prospectService: DossierProspectService,
  ) {}

  goToDossier() {
    this.dossierProspectRouteService.goToDashboard(this.dossier.id);
  }

  getStatus() {
    return DossierProspectUtils.getStatusColor(this.dossier.dossierStatusCode);
  }

  selectProspect(prospect: ProspectResponse) {
    this.activeProspect = prospect;
  }
}
