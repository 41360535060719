import { Component } from '@angular/core';

import { ProgramPageLotRentAndProfitabilityComponent } from '../program-page-lot-rent-and-profitability/program-page-lot-rent-and-profitability.component';
import { ProgramPageLotPriceComponent } from '../program-page-lot-price/program-page-lot-price.component';
import { ProgramPageLotInformationComponent } from '../program-page-lot-information/program-page-lot-information.component';

import { I18nService } from '../../../../../utils/services/i18n.service';

@Component({
  selector: 'app-program-page-lot-detail',
  templateUrl: './program-page-lot-detail.component.html',
  styleUrls: ['./program-page-lot-detail.component.scss'],
  standalone: true,
  imports: [ProgramPageLotInformationComponent, ProgramPageLotPriceComponent, ProgramPageLotRentAndProfitabilityComponent],
})
export class ProgramPageLotDetailComponent {
  constructor(public readonly i18nService: I18nService) {}
}
