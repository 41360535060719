<div class="px-4">
  <h2 class="text-center" mat-dialog-title>
    {{ i18nService._('Txt_Link_ResetPassword') }}
  </h2>

  <form [formGroup]="emailForm" class="flex flex-col">
    <mat-form-field>
      <mat-label>{{ i18nService._('Txt_Placeholder_Email') }}</mat-label>
      <input [formControl]="emailControl" matInput maxlength="255" name="email" required type="text" data-cy="email" />
      <mat-error *ngIf="emailControl.errors">
        <span *ngIf="emailControl.errors.required">
          {{ i18nService._('Error_Field_Required') }}
        </span>
        <span *ngIf="!emailControl.errors.email && !emailControl.errors.required">
          {{ i18nService._('Error_Field_IncorrectEmail') }}
        </span>
      </mat-error>
    </mat-form-field>
  </form>

  <div class="mt-4 mb-3 justify-center" mat-dialog-actions>
    <button
      (click)="dialogRef.close()"
      class="mr-0 lg:mr-4 md:mr-4 sm:mr-4 mb-3 lg:mb-0 sm:mb-0 md:mb-0"
      color="primary"
      mat-stroked-button
      data-cy="cancel"
    >
      {{ i18nService._('Txt_Button_Cancel') }}
    </button>
    <button
      data-cy="send"
      (click)="submit()"
      [disabled]="isSubmitDisable()"
      class="mb-3 lg:mb-0 sm:mb-0 md:mb-0"
      color="primary"
      mat-flat-button
    >
      {{ i18nService._('Txt_Button_Send') }}
    </button>
  </div>
  <app-recaptcha></app-recaptcha>
</div>
