import { Injectable } from '@angular/core';

import { ProgramTypeResponse } from '../utils/models/ProgramTypeResponse';
import { ProgramTypeApiService } from '../adapters/program-type-api.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramTypeService {
  programTypes: ProgramTypeResponse[];

  constructor(private readonly programTypeApiService: ProgramTypeApiService) {}

  public async getProgramTypes(): Promise<ProgramTypeResponse[]> {
    if (!this.programTypes) {
      await this.loadProgramTypes();
    }
    return this.programTypes;
  }

  private async loadProgramTypes(): Promise<void> {
    this.programTypes = await this.programTypeApiService.getProgramTypes();
  }
}
