<app-chip-populated
  [referenceTable]="referenceTable"
  [fieldName]="fieldName"
  [parentForm]="parentForm"
  [isSubmit]="isSubmit"
  (selectedItemChange)="chipValueChanged($event)"
  [dataToInit]="dataToInit"
>
</app-chip-populated>

<mat-form-field *ngIf="isOther" class="flex w-full lg:w-1/2">
  <mat-label>{{ i18nService._('Txt_Page_Form_RegisterCompany_Other') }}</mat-label>
  <input type="text" name="fieldNameOther" [formControl]="otherControl" matInput maxlength="255" required />
  <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
</mat-form-field>
