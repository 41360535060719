import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PipesModule } from '../../../utils/pipes/pipes.module';

@Component({
  selector: 'app-strong-point',
  standalone: true,
  templateUrl: './strong-point.component.html',
  styleUrls: ['./strong-point.component.scss'],
  imports: [CommonModule, PipesModule, MatButtonModule, MatTooltipModule],
})
export class StrongPointComponent implements OnChanges {
  @Input() tooltip: string;
  @Input() isHoverable = true;
  @Input() customClass: string[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isHoverable) {
      this.customClass.push('hover');
    }
  }
}
