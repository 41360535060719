import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';

import { DataLayerApiService } from './data-layer-api.service';
import { LotOrientationResponse } from '../utils/models/LotOrientationResponse';

@Injectable({ providedIn: 'root' })
export class LotOrientationApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Get lot orientations
   *
   * @returns {LotOrientationResponse[]}
   * @memberof DataLayerApiService
   */
  async getLotOrientations(): Promise<LotOrientationResponse[]> {
    const path = this.BASE_URL + 'LotOrientations';
    const res: Observable<LotOrientationResponse[]> = await this.get(path);
    return await lastValueFrom(res);
  }
}
