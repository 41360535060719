import { IsObject, IsOptional, IsString } from 'class-validator';

import { CriteriaCategory } from './criteria-category.enum';
import { SearchCriteria } from './search-criteria.enum';

export class SearchCriteriaDto {
  @IsString()
  @IsOptional()
  code?: SearchCriteria;

  @IsObject()
  category: CriteriaCategory;
}
