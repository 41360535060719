import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
/* eslint-disable @typescript-eslint/no-empty-function */
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgIf } from '@angular/common';

import { SearchFormProgramsComponent } from '../search-form-programs/search-form-programs.component';
import { SearchFilterDialogComponent } from '../filters/search-filter-dialog/search-filter-dialog.component';
import { LastConsultProspectListComponent } from './last-consult-prospect-list/last-consult-prospect-list.component';

import { Target2SellEnum } from '../../../utils/models/enums/target-2-sell.enum';
import { mapHttpParams } from '../../../utils/enums/map.enum';
import { Sitemap } from '../../../utils/models/Sitemap';
import { SearchFormUtilsService } from '../../services/search-form-utils.service';
import { CitiesLocationTypeAcceptedData } from '../../../utils/models/CitiesLocationTypeAcceptedData';
import { AutoCompleteService } from '../../../common/form/auto-complete.service';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { LocationData } from '../../../utils/models/LocationData';
import { AccountUtilsService } from '../../../accounts/services/account-utils.service';
import { AccountAsService } from '../../../utils/services/account-as.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { FilterUtilsService } from '../../services/filter/filter-utils.service';
import { GoogleTagManagerService } from '../../../utils/services/google-tag-manager.service';
import { FeatureFlagService } from '../../../feature-flag/feature-flag.service';
import { DossierProspectRouteService } from '../../../dossier-prospect/components/dossier-prospect/dossier-prospect.route.service';
import { SearchPageDisplayType, SearchProgramData } from '../../model/search-program-data';
import { SearchProgramDataWhere } from '../../model/search-program-data-where';
import { Target2SellRecoComponent } from '../../../utils/components/target2sell-reco/target2sell-reco.component';
import { IconComponent } from '../../../common/standalone/icon/icon.component';
import { FormLocationComponent } from '../../../utils/components/form-location/form-location.component';
import { LoginAsFormComponent } from '../../../utils/components/login-as form/login-as-form.component';

interface AutoCompleteServices {
  [key: string]: AutoCompleteService;
}

declare global {
  interface Window {
    newHomepage: boolean;
  }
}

@Component({
  selector: 'app-new-fast-search-page',
  templateUrl: './new-fast-search-page.component.html',
  styleUrls: ['./new-fast-search-page.component.scss'],
  standalone: true,
  imports: [
    LoginAsFormComponent,
    NgIf,
    FormLocationComponent,
    SearchFormProgramsComponent,
    IconComponent,
    LastConsultProspectListComponent,
    Target2SellRecoComponent,
  ],
})
export class NewFastSearchPageComponent implements OnInit {
  @ViewChild('searchPrograms') searchPrograms: SearchFormProgramsComponent;
  @Output() readonly searchEvent: EventEmitter<SearchProgramData> = new EventEmitter<SearchProgramData>(true);

  formBuilder: UntypedFormBuilder = new UntypedFormBuilder();
  formGroup: UntypedFormGroup;
  isConnectionAs: boolean;
  loggedUserIsValo: boolean;
  isLoggedUserContractorOrIndependant: boolean;
  isFastSearchEnable = true;
  programId: number;
  localisationsMapList: Map<string, any> = new Map();
  selectedProgramIds: Array<number> = [];
  private readonly budgetFloor: number;
  private readonly budgetCeil: number;
  private readonly defaultSearch: unknown;
  public items = [];
  private autoCompleteServices: AutoCompleteServices;
  filterLocalisationTypeAccepted: CitiesLocationTypeAcceptedData = {};
  mapListFilteredItems: Map<string, CitiesLocationTypeAcceptedData> = new Map();
  protected _filterUtilsService: FilterUtilsService;
  search: SearchProgramDataWhere;
  searchProgramData: SearchProgramData;
  target2SellSelection: Target2SellEnum;
  target2SellNew: Target2SellEnum;
  target2SellRegion: Target2SellEnum;
  target2SellT3: Target2SellEnum;
  target2SellT4: Target2SellEnum;
  isDossierProspectEnabled = false;

  constructor(
    public i18nService: I18nService,
    readonly appConfigService: AppConfigService,
    public accountUtilsService: AccountUtilsService,
    public accountAsService: AccountAsService,
    private readonly _searchFormUtilsService: SearchFormUtilsService,
    private readonly dialog: MatDialog,
    private readonly searchFormUtilsService: SearchFormUtilsService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public appConfig: AppConfigService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly dossierProspectRouteService: DossierProspectRouteService,
  ) {
    this.budgetFloor = this.appConfigService.getAppConfig().budgetFloor;
    this.budgetCeil = this.appConfigService.getAppConfig().budgetCeil;
    this.defaultSearch = {
      budget: {
        minBudget: this.budgetFloor,
        maxBudget: this.budgetCeil,
      },
      nbRooms: [],
      localisations: new LocationData(),
      taxation: undefined,
      parkingSecondaryLotTypeIds: [],
      storageSecondaryLotTypeIds: [],
    };
    window.newHomepage = true;
  }

  ngOnInit(): void {
    this.initTarget2SellEnums();
    this.formGroup = this.formBuilder.group({});
    this.search = JSON.parse(JSON.stringify(this.defaultSearch));
    this.loggedUserIsValo = this.accountUtilsService.getParentAndLoggedUserInfos().loggedUserIsValo;
    this.isLoggedUserContractorOrIndependant = this.accountUtilsService.isLoggedUserContractorOrIndependant();
    this.accountAsService.isConnectedAsEvent$.subscribe((newState) => {
      this.isConnectionAs = newState;
    });
    this.featureFlagService.isEnabled('dossier-prospect').then((res) => {
      this.isDossierProspectEnabled = res;
    });
  }

  private initTarget2SellEnums() {
    // Initialise les enums Target2Sell
    this.target2SellSelection = Target2SellEnum.TARGET_2_SELL_SELECTION_HOMEPAGE;
    this.target2SellNew = Target2SellEnum.TARGET_2_SELL_NEW_PROGRAM_HOMEPAGE;
    this.target2SellRegion = Target2SellEnum.TARGET_2_SELL_REGION_HOMEPAGE;
    this.target2SellT3 = Target2SellEnum.TARGET_2_SELL_T3_HOMEPAGE;
    this.target2SellT4 = Target2SellEnum.TARGET_2_SELL_T4_HOMEPAGE;
  }

  public onFastSearchClick(): void {
    this.isFastSearchEnable = true;
  }

  public onProgramSearchClick(): void {
    this.isFastSearchEnable = false;
    if (this.selectedProgramIds.length) {
      this.programId = this.selectedProgramIds[0];
    }
  }

  public updateSearch(): void {
    if (!this.isFastSearchEnable) {
      if (this.selectedProgramIds.length) {
        this.searchPrograms.chipCtrl.disable();
        this.programId = this.selectedProgramIds[0];
      } else {
        this.searchPrograms.chipCtrl.enable();
      }
    } else {
      this.localisationsMapList.forEach((value: any, key: string) => {
        value.key = key;
      });
      this.search.localisationsList = Array.from(this.localisationsMapList.values());
      this._searchFormUtilsService.setSearchFilter({ where: this.search }, false);
    }
  }

  openAdvancedModal() {
    const preReservationPopin = this.dialog.open(SearchFilterDialogComponent, {
      width: '100%',
      maxWidth: '740px',
      autoFocus: false,
    });

    // Uses to ensure that the modal is fully loaded before build the filter
    preReservationPopin.afterOpened().subscribe(() => {
      const childComponentRef = preReservationPopin.componentInstance;
      childComponentRef.loadFilter();
    });

    preReservationPopin.afterClosed().subscribe((success: boolean) => {
      if (success) {
        this.search = this._searchFormUtilsService.searchFilter.where;
        this.onSearch();
      } else {
        this._searchFormUtilsService.parseSearchProgramFromFilterChain(
          { ...this.route.snapshot.queryParams },
          {
            label: 'price',
            text: this.i18nService._('Txt_SortBy_Price_ASC'),
            direction: 'ASC',
          },
          this.appConfig.getAppConfig().limit,
        );
      }
    });
  }

  onSearch(): void {
    if (!this.isFastSearchEnable && this.formGroup && !this.selectedProgramIds.length) {
      this.searchPrograms.chipList.errorState = true;
      this.formGroup.controls.program.setErrors({
        required: true,
      });
    } else {
      sessionStorage.removeItem('savedSearchId');
      let navigate: string;
      let option;
      if (this.isFastSearchEnable) {
        this.search.taxation = this.formGroup.value.taxationId;
        navigate = Sitemap.utils.search.path;
        this.searchFormUtilsService.pushDatalayer(this.search, false);
        const parseSearchProgramToFilterChain = this.searchFormUtilsService.parseSearchProgramToFilterChain({
          where: this.search,
          order: {
            label: 'price',
            direction: 'ASC',
          },
        });
        option = {
          queryParams: parseSearchProgramToFilterChain ? parseSearchProgramToFilterChain : undefined,
        };
        option.queryParams.type = SearchPageDisplayType.Programme;
        option.queryParams.bbox = '';
        option.queryParams.priority = mapHttpParams.FILTERS;
      } else {
        navigate = Sitemap.programs.read.path.replace(':programId', this.selectedProgramIds[0].toString());
      }
      this.router.navigate([navigate], option);
    }
  }
}
