/* eslint-disable @typescript-eslint/no-explicit-any */
import { formatCurrency } from '@angular/common';
import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LabelType, NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';

import { SearchFormProgramsComponent } from '../search-form-programs/search-form-programs.component';

import { ProgramService } from '../../../programs/services/program.service';
import { LocationData } from '../../../utils/models/LocationData';
import { ReferenceTableData } from '../../../utils/models/ReferenceTableData';
import { Sitemap } from '../../../utils/models/Sitemap';
import { TaxationResponse } from '../../../utils/models/TaxationResponse';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { ReferenceTablesService } from '../../../utils/services/reference-tables.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { ArraySortCompareFn } from '../../../utils/utils.module';
import { SearchFormUtilsService } from '../../services/search-form-utils.service';
import { mapHttpParams } from '../../../utils/enums/map.enum';
import { SearchFormService } from '../../services/search-form.service';
import { SearchProgramDataWhere } from '../../model/search-program-data-where';
import { SearchPageDisplayType } from '../../model/search-program-data';
import { CheckboxPopulatedComponent } from '../../../utils/components/checkbox-populated/checkbox-populated.component';
import { DropdownListPopulatedComponent } from '../../../utils/components/dropdown-list-populated/dropdown-list-populated.component';
import { FormLocationComponent } from '../../../utils/components/form-location/form-location.component';

@Component({
  selector: 'app-fast-search-form',
  templateUrl: './fast-search-form.component.html',
  styleUrls: ['./fast-search-form.component.scss'],
  standalone: true,
  imports: [
    MatExpansionModule,
    FormLocationComponent,
    DropdownListPopulatedComponent,
    CheckboxPopulatedComponent,
    NgxSliderModule,
    SearchFormProgramsComponent,
    MatButtonModule,
    MatChipsModule,
  ],
})
export class FastSearchFormComponent implements OnInit {
  formBuilder: UntypedFormBuilder = new UntypedFormBuilder();
  panelFastSearchOpened: boolean;
  taxationItems: Array<ReferenceTableData>;
  localisationsMapList: Map<string, any> = new Map();
  selectedProgramIds: Array<number> = [];
  @ViewChild('searchPrograms') searchPrograms: SearchFormProgramsComponent;

  /**
   * formGroup
   *
   * @type {FormGroup}
   * @memberof FastSearchFormComponent
   */
  public formGroup: UntypedFormGroup;

  /**
   * Event emitter to refresh components
   */
  refresh = new EventEmitter();
  /**
   * availableTaxations$
   *
   * @type {Observable<Map<string, any>>}
   * @memberOf FastSearchFormComponent
   */
  public availableTaxations$: Observable<Map<string, any>>;
  /**
   * RG_95.4.1	 : Budget slider constant options
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  public readonly budgetSliderOptions: Object;
  /**
   * RG_95.3.1 : Room items
   */
  readonly roomItems = [
    {
      id: '1',
      label: '1',
    },
    {
      id: '2',
      label: '2',
    },
    {
      id: '3',
      label: '3',
    },
    {
      id: '4',
      label: '4',
    },
    {
      id: 'GT5',
      label: '+5',
    },
  ];
  programId: number;
  /**
   * Private search object
   */
  search: SearchProgramDataWhere;
  /**
   * Budget floor constant
   */
  private readonly budgetFloor: number;
  /**
   * Budget ceil constant
   */
  private readonly budgetCeil: number;
  /**
   * Default search constant for init and reset
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  private readonly defaultSearch: Object;

  constructor(
    public i18nService: I18nService,
    readonly appConfigService: AppConfigService,
    readonly referenceTablesService: ReferenceTablesService,
    readonly snackbarService: SnackbarService,
    readonly programService: ProgramService,
    readonly router: Router,
    private readonly searchFormService: SearchFormService,
    public searchFormUtilsService: SearchFormUtilsService,
  ) {
    this.budgetFloor = this.appConfigService.getAppConfig().budgetFloor;
    this.budgetCeil = this.appConfigService.getAppConfig().budgetCeil;
    this.defaultSearch = {
      budget: {
        minBudget: this.budgetFloor,
        maxBudget: this.budgetCeil,
      },
      nbRooms: [],
      localisations: new LocationData(),
      taxation: undefined,
      isActable: undefined,
    };
    this.budgetSliderOptions = {
      floor: this.budgetFloor,
      ceil: this.budgetCeil,
      step: 10000,
      hideLimitLabels: true,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Ceil:
            return `+ ${formatCurrency(value, 'fr', '€', 'EUR', '1.0-0')}`;
          case LabelType.Low:
          case LabelType.High:
          case LabelType.Floor:
          case LabelType.TickValue:
          default:
            return `${value >= this.budgetCeil ? '+' : ''} ${formatCurrency(value, 'fr', '€', 'EUR', '1.0-0')}`;
        }
      },
    };
  }

  ngOnInit(): void {
    // panel opened by default
    this.panelFastSearchOpened = true;

    // Form init
    this.search = JSON.parse(JSON.stringify(this.defaultSearch));

    // Create form group.
    this.formGroup = this.formBuilder.group({});

    // Taxation items
    this.referenceTablesService.getTableReferenceInfo('Taxations').subscribe(
      (items: Array<TaxationResponse>) => {
        this.taxationItems = items
          .filter((item: TaxationResponse) => item.taxationType)
          .map((taxation) => {
            const item = new ReferenceTableData();
            item.id = taxation.id;
            item.label = taxation.label.replace(/_+/g, ' ');

            return item;
          })
          .sort((first: ReferenceTableData, second: ReferenceTableData) => {
            return first.label.localeCompare(second.label);
          });
      },
      () => {
        this.snackbarService.sendErrorOccured();
      },
    );
  }

  /**
   * Sorts by closure
   */
  public sortBy(fieldName: string): ArraySortCompareFn {
    return (a: any, b: any) => {
      return a[fieldName] < b[fieldName] ? -1 : a[fieldName] > b[fieldName] ? 1 : 0;
    };
  }

  public updateSearch(): void {
    if (!this.panelFastSearchOpened) {
      if (this.selectedProgramIds.length) {
        this.searchPrograms.chipCtrl.disable();
        this.programId = this.selectedProgramIds[0];
      } else {
        this.searchPrograms.chipCtrl.enable();
      }
    } else {
      this.localisationsMapList.forEach((value: any, key: string) => {
        value.key = key;
      });
      this.search.localisationsList = Array.from(this.localisationsMapList.values());
    }
  }

  public onProgramClosed(): void {
    this.panelFastSearchOpened = true;
  }

  public onProgramOpened(): void {
    this.panelFastSearchOpened = false;
    if (this.selectedProgramIds.length) {
      this.programId = this.selectedProgramIds[0];
    }
  }

  onSearch(): void {
    if (!this.panelFastSearchOpened && this.formGroup && !this.selectedProgramIds.length) {
      this.searchPrograms.chipList.errorState = true;
      this.formGroup.controls.program.setErrors({
        required: true,
      });
    } else {
      sessionStorage.removeItem('savedSearchId');
      let navigate: string;
      let option;
      if (this.panelFastSearchOpened) {
        this.search.taxation = this.formGroup.value.taxationId;
        navigate = Sitemap.utils.search.path;
        this.searchFormUtilsService.pushDatalayer(this.search, false);
        const parseSearchProgramToFilterChain = this.searchFormUtilsService.parseSearchProgramToFilterChain({
          where: this.search,
          order: {
            label: 'price',
            direction: 'ASC',
          },
        });
        option = {
          queryParams: parseSearchProgramToFilterChain ? parseSearchProgramToFilterChain : undefined,
        };
        option.queryParams.type = SearchPageDisplayType.Programme;
        option.queryParams.bbox = '';
        option.queryParams.priority = mapHttpParams.FILTERS;
      } else {
        navigate = Sitemap.programs.read.path.replace(':programId', this.programId.toString());
      }
      this.router.navigate([navigate], option);
    }
  }

  selectItem(fieldName: string, res) {
    this.search[fieldName] = res;
  }
}
