/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';

import { ErrorHandlerService } from '../../../app/utils/services/error-handler.service';
import { AppConfigService } from '../../utils/services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class ContactInformationService {
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
  ) {}

  checkEmailExist(email: string): Observable<any> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Accounts/check-email-exist`;
    return this.http
      .post<any>(url, { email })
      .pipe(catchError(this.errorHandlerService.handleError<any>('contactInformationService', 'get')));
  }
}
