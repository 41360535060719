/* eslint-disable no-control-regex */
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';

import { ContactInformationService } from '../services/contact-information.service';

export const emailExistValidator = (contactInformationService: ContactInformationService, oldEmail?: string): AsyncValidatorFn => {
  return (control: AbstractControl): Observable<{ [key: string]: boolean } | undefined> => {
    return new Observable((observer) => {
      /* RFC 5322 Official Standard */
      /* eslint-disable-next-line max-len */
      const emailRegex =
        /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

      if (oldEmail === control.value || !control.value || !emailRegex.test(control.value)) {
        observer.next();
        observer.complete();
      } else {
        contactInformationService.checkEmailExist(control.value).subscribe(
          (emailResponse) => {
            if (emailResponse.emailFound) {
              observer.next({ emailExist: true });
            } else {
              observer.next();
            }
            observer.complete();
          },
          (err) => {
            observer.error(err);
          },
        );
      }
    });
  };
};
