import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'primeng/carousel';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroChevronLeftSolid, heroChevronRightSolid } from '@ng-icons/heroicons/solid';

@Component({
  selector: 'app-carousel',
  standalone: true,
  imports: [CommonModule, CarouselModule, NgIconComponent],
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  providers: [provideIcons({ heroChevronLeftSolid, heroChevronRightSolid })],
})
export class CarouselComponent implements OnInit {
  @Input() config: CarouselConfig;
  @Input() elementTemplate: TemplateRef<unknown>;
  ready = false;
  defaultConfig: CarouselConfig = {
    responsiveParams: [],
    circular: false,
    showIndicators: false,
    numVisibleDefault: 3,
    numScrollDefault: 1,
    items: [],
  };

  ngOnInit(): void {
    this.config = { ...this.defaultConfig, ...this.config };
    this.ready = true;
  }
}

export interface CarouselConfig {
  responsiveParams?: {
    breakpoint: string;
    numVisible: number;
    numScroll: number;
  }[];
  circular?: boolean;
  showIndicators?: boolean;
  numVisibleDefault?: number;
  numScrollDefault?: number;
  items: any[];
}
