<ng-container *ngIf="isReady">
  <app-file-drop
    [fieldNameKey]="'KBIS'"
    [attachmentName]="i18nService._('Txt_Placeholder_KBISExtract')"
    [required]="true"
    [includingForm]="parentForm"
    [isSubmit]="isSubmit"
    [initialValue]="initFile['KBIS']"
    class="app-file-drop"
  >
  </app-file-drop>
  <app-file-drop
    [fieldNameKey]="'identityDocumentOfTheLegalRepresentative'"
    [attachmentName]="i18nService._('Txt_Placeholder_IdentityDocumentOfTheLegalRepresentative')"
    [required]="true"
    [includingForm]="parentForm"
    [isSubmit]="isSubmit"
    [initialValue]="initFile['identityDocumentOfTheLegalRepresentative']"
    [hoveringText]="i18nService._('Txt_Tooltip_IdentityDocument')"
    class="app-file-drop"
  >
  </app-file-drop>
  <app-file-drop
    *ngIf="!isDeveloper"
    [fieldNameKey]="'tCard'"
    [attachmentName]="i18nService._('Txt_Placeholder_TCard')"
    [required]="isfrenchCountry$ | async"
    [includingForm]="parentForm"
    [isSubmit]="isSubmit"
    [initialValue]="initFile['tCard']"
    class="app-file-drop"
  >
  </app-file-drop>
  <app-file-drop
    [fieldNameKey]="'insuranceCertificateAndCivilResponsability'"
    [attachmentName]="i18nService._('Txt_Placeholder_InsuranceCertificateAndCivilResponsability')"
    [required]="true"
    [includingForm]="parentForm"
    [initialValue]="initFile['insuranceCertificateAndCivilResponsability']"
    [isSubmit]="isSubmit"
  >
  </app-file-drop>
  <app-file-drop
    *ngIf="!isDeveloper"
    [fieldNameKey]="'financialGuarantee'"
    [attachmentName]="i18nService._('Txt_Placeholder_FinancialGuarantee')"
    [required]="false"
    [includingForm]="parentForm"
    [initialValue]="initFile['financialGuarantee']"
    [hoveringText]="i18nService._('Txt_Page_Form_RegisterCompany_FinancialGuarantee')"
    [isSubmit]="isSubmit"
  >
  </app-file-drop>
  <app-file-drop
    *ngIf="isMandatDelegation"
    [fieldNameKey]="'identityDocumentOfTheMandateSignatory'"
    [attachmentName]="i18nService._('Txt_Placeholder_IdentityDocumentOfTheMandateSignatory')"
    [required]="true"
    [includingForm]="parentForm"
    [isSubmit]="isSubmit"
    [initialValue]="initFile['identityDocumentOfTheMandateSignatory']"
    [hoveringText]="i18nService._('Txt_Tooltip_IdentityDocument')"
  >
  </app-file-drop>
  <app-file-drop
    *ngIf="isMandatDelegation"
    [fieldNameKey]="'mandateDelegation'"
    [attachmentName]="i18nService._('Txt_Placeholder_MandateDelegation')"
    [required]="true"
    [includingForm]="parentForm"
    [initialValue]="initFile['mandateDelegation']"
    [isSubmit]="isSubmit"
  >
  </app-file-drop>
  <app-file-drop
    *ngIf="isDeveloper"
    [fieldNameKey]="'RCP'"
    [attachmentName]="i18nService._('Txt_Placeholder_RCP')"
    [required]="true"
    [includingForm]="parentForm"
    [initialValue]="initFile['RCP']"
    [isSubmit]="isSubmit"
  >
  </app-file-drop>
  <mat-form-field>
    <mat-label>{{ i18nService._('Txt_Page_Form_RegisterCompany_Comment') }}</mat-label>
    <textarea [formControl]="documentCommentControl" matInput maxlength="2000"></textarea>
  </mat-form-field>

  <div *ngIf="isValoRole" class="flex w-1/5">
    <button type="submit" (click)="submitStep()" class="mt-3" mat-stroked-button color="primary" matStepperNext>
      {{ i18nService._('Txt_Button_NextStep') }}
    </button>
  </div>
</ng-container>
