<div class="grid grid-cols-2 gap-x-16">
  <mat-form-field class="col-span-2 lg:col-span-1 mb-3">
    <mat-label>{{ lastNamePlaceholder }}</mat-label>
    <input
      type="text"
      data-cy="lastName"
      name="lastName"
      [formControl]="lastNameControl"
      matInput
      attr.data-cy="{{ lastNamePlaceholder }}"
      maxlength="255"
      [required]="lastNameRequired"
    />
    <span *ngIf="lastNameTooltipText" class="icon-info txt-gray pr-2" matSuffix [matTooltip]="lastNameTooltipText"> </span>
    <mat-error *ngIf="lastNameControl.errors">
      {{ i18nService._('Error_Field_Required') }}
    </mat-error>
  </mat-form-field>
  <mat-form-field class="col-span-2 lg:col-span-1 mb-3">
    <mat-label>{{ firstNamePlaceholder }}</mat-label>
    <input
      type="text"
      name="firstName"
      data-cy="firstName"
      [formControl]="firstNameControl"
      attr.data-cy="{{ firstNamePlaceholder }}"
      matInput
      maxlength="255"
      [required]="firstNameRequired"
    />
    <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
  </mat-form-field>
</div>
