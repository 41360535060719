import { Directive, Input, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appStepContext]',
  standalone: true,
})
export class StepContextDirective {
  @Input() myClass: string;

  constructor(public viewContainerRef: ViewContainerRef) {}
}
