import { ListNode } from './list-node.type';

export class LinkedList<T> {
  public head: ListNode<T> | null;
  public size: number;

  constructor() {
    this.head = null;
    this.size = 0;
  }

  public push(value: T): boolean {
    const newNode: ListNode<T> = new ListNode<T>(value);

    if (!this.head) {
      this.head = newNode;
    } else {
      let current: ListNode<T> | null = this.head;

      while (current.next) {
        current = current.next;
      }
      current.next = newNode;
    }

    this.size++;

    return true;
  }

  public pop(): boolean {
    if (!this.head) {
      return false;
    }

    if (this.size === 1) {
      this.head = null;
      this.size--;
      return true;
    }

    let current: ListNode<T> | null = this.head;
    let prevNode: ListNode<T> | null = null;

    while (current.next) {
      prevNode = current;
      current = current.next;
    }

    prevNode.next = null;
    this.size--;

    return true;
  }
}
