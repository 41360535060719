import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

import { StepFourFormProgramComponent } from '../step-four-form-program/step-four-form-program.component';
import { StepThreeFormProgramComponent } from '../step-three-form-program/step-three-form-program.component';
import { StepTwoFormProgramComponent } from '../step-two-form-program/step-two-form-program.component';
import { StepOneFormProgramComponent } from '../step-one-form-program/step-one-form-program.component';

import { CheckboxDialogComponent } from '../../../dialog/components/checkbox-dialog/checkbox-dialog.component';
import { TextareaDialogComponent } from '../../../dialog/components/textarea-dialog/textarea-dialog.component';
import { CheckboxDialogData } from '../../../dialog/models/CheckboxDialogData';
import { CheckboxDialogResponse } from '../../../dialog/models/CheckboxDialogResponse';
import { TextareaDialogData } from '../../../dialog/models/TextareaDialogData';
import { ProgramStatus } from '../../../utils/models/enums/program-status.enum';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { LotSummary } from '../../../utils/models/LotSummary';
import { ProgramResponseUpdateForm } from '../../../utils/models/ProgramResponseUpdateForm';
import { ProgramSummary } from '../../../utils/models/ProgramSummary';
import { ProgramUpdate } from '../../../utils/models/ProgramUpdate';
import { Sitemap } from '../../../utils/models/Sitemap';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { TokenService } from '../../../utils/services/authorisation/token.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { ReferenceTablesService } from '../../../utils/services/reference-tables.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { SpinnerWithBackdropService } from '../../../utils/services/spinner-with-backdrop.service';
import { ProgramService } from '../../services/program.service';
import { CompanyService } from '../../../companies/services/company.service';
import { CompanyData } from '../../../utils/models/CompanyData';
import { MatVerticalStepperScrollerDirective } from '../../../utils/directives/mat-vertical-stepper-scroller.directive';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';

@Component({
  selector: 'app-register-form-program',
  templateUrl: './register-form-program.component.html',
  styleUrls: ['./register-form-program.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    StickyHeaderFormComponent,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatVerticalStepperScrollerDirective,
    StepOneFormProgramComponent,
    StepTwoFormProgramComponent,
    StepThreeFormProgramComponent,
    StepFourFormProgramComponent,
    MatButtonModule,
  ],
})
export class RegisterFormProgramComponent implements OnInit, OnDestroy {
  /**
   * Path to programs dashboard route.
   *
   * @type {string}
   * @memberof RegisterFormProgramComponent
   */
  readonly programsDashboardPath = Sitemap.programs.admin.path;

  /**
   * Input from edit form to know if update or not
   *
   * @type {boolean}
   * @memberof RegisterFormProgramComponent
   */
  @Input() isEditForm: boolean;

  /**
   * Input from validate form to know if validation or not
   *
   * @type {boolean}
   * @memberof RegisterFormProgramComponent
   */
  @Input() isValidateForm: boolean;

  /**
   * Recovered info from edit form
   *
   * @type {ProgramResponseUpdateForm}
   * @memberof RegisterFormProgramComponent
   */
  @Input() recoveredInfos: ProgramResponseUpdateForm;

  /**
   * Used to know if program status is Draft or Incomplete
   *
   * @type {boolean}
   * @memberof RegisterFormProgramComponent
   */
  @Input() isDraftOrIncompleteStatusProgram: boolean;

  /**
   * Used to know if program status is Valid
   *
   * @type {boolean}
   * @memberof RegisterFormProgramComponent
   */
  @Input() isValidStatusProgram: boolean;

  /**
   * Global configuration of the application
   */
  appConfig;

  /**
   * Multiple formGroup
   *
   * @type {FormGroup}
   * @memberof RegisterFormProgramComponent
   */
  public creationProgramForm: UntypedFormGroup;
  public programForm: UntypedFormGroup;
  public lotsOfProgramForm: UntypedFormGroup;
  public summaryForm: UntypedFormGroup;
  public adminValoForm: UntypedFormGroup;
  public documentsForm: UntypedFormGroup;
  public documentsValoForm: UntypedFormGroup;
  public programTextForm: UntypedFormArray;

  /**
   * Do not remind dialog
   *
   * @memberof RegisterFormProgramComponent
   */
  readonly stopRemindingInfoItem = 'stopRemindingCreationProgramInfo';

  /**
   * Used to know if user is Valo or not
   *
   * @type {boolean}
   * @memberof RegisterFormProgramComponent
   */
  public isValo: boolean;

  /**
   * Final submit
   *
   * @type {boolean}
   * @memberof RegisterFormProgramComponent
   */
  public isSubmitFinal: boolean;

  /**
   * Id of the program
   *
   * @type {number}
   * @memberof RegisterFormProgramComponent
   */
  public programId: number;

  /**
   * Program Summary for step 3
   *
   * @type {ProgramSummary}
   * @memberof RegisterFormProgramComponent
   */
  public programSummary: ProgramSummary;

  /**
   * Lot summary for step 3
   *
   * @type {LotSummary}
   * @memberof RegisterFormProgramComponent
   */
  public lotSummary: LotSummary;

  /**
   * Boolean to know if program is from XML import
   *
   * @type {boolean}
   * @memberof RegisterFormProgramComponent
   */
  public isImported: boolean;

  /**
   * accepted file type list
   *
   * @type {string[]}
   * @memberof RegisterFormProgramComponent
   */
  public acceptedFileType = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

  /**
   * accepted file type list
   *
   * @type {boolean}
   * @memberof RegisterFormProgramComponent
   */
  public displaySignatureErrorMessage = false;

  company: Partial<CompanyData>;

  /**
   * View child of stepper
   *
   * @type {MatStepper}
   * @memberof RegisterFormProgramComponent
   */
  @ViewChild('stepper') stepper: MatStepper;

  @ViewChild('step4') stepFourFormProgramComponent: StepFourFormProgramComponent;

  /**
   * programIdObservableSubscription attribute
   *
   * @type {Subscription}
   * @memberof RegisterFormProgramComponent
   */
  private programIdObservableSubscription: Subscription;

  /**
   * lotSummaryObservableSubscription attribute
   *
   * @type {Subscription}
   * @memberof RegisterFormProgramComponent
   */
  private lotSummaryObservableSubscription: Subscription;

  /**
   * Creates an instance of RegisterFormProgramComponent.
   * @param {FormBuilder} formBuilder
   * @param {I18nService} i18nService
   * @param {TokenService} tokenService
   * @param {AppConfigService} appConfigService
   * @param {MatDialog} dialog
   * @param {SnackbarService} snackbarService
   * @param {Router} router
   * @param {ProgramService} programService
   * @param {ReferenceTablesService} referenceTablesService
   * @memberof RegisterFormProgramComponent
   */
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    public i18nService: I18nService,
    private readonly tokenService: TokenService,
    private readonly appConfigService: AppConfigService,
    private readonly dialog: MatDialog,
    private readonly snackbarService: SnackbarService,
    private readonly router: Router,
    private readonly companyService: CompanyService,
    private readonly programService: ProgramService,
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly spinnerWithBackdropService: SpinnerWithBackdropService,
  ) {
    this.isEditForm = false;
    this.isValidateForm = false;
    this.isSubmitFinal = false;
    this.appConfig = this.appConfigService.appConfig;
    this.isValidStatusProgram = false;
    this.isDraftOrIncompleteStatusProgram = false;
    this.isImported = false;
    // Check if the user already check the 'do not remind me'
    if (!localStorage.getItem(this.stopRemindingInfoItem)) {
      this.showWelcomeDialog();
    }
  }

  /**
   * Init method
   *
   * @memberof RegisterFormProgramComponent
   */
  ngOnInit(): void {
    // Form init
    this.creationProgramForm = this.formBuilder.group({
      ['program']: (this.programForm = this.formBuilder.group({
        ['document']: (this.documentsForm = this.formBuilder.group({})),
        ['programText']: (this.programTextForm = this.formBuilder.array([])),
      })),
      ['lotsOfProgram']: (this.lotsOfProgramForm = this.formBuilder.group([])),
      ['summaryForm']: (this.summaryForm = this.formBuilder.group({})),
      ['adminValoForm']: (this.adminValoForm = this.formBuilder.group({
        ['document']: (this.documentsValoForm = this.formBuilder.group({})),
      })),
    });

    // List of valoRole name need to see stepFour
    const valoAdminAccountList = [
      this.appConfig.roles.valoSystem,
      this.appConfig.roles.valoAdminSuper,
      this.appConfig.roles.valoAdminSimple,
      this.appConfig.roles.valoBoSuper,
      this.appConfig.roles.valoBoSimple,
      this.appConfig.roles.valoDevSuper,
      this.appConfig.roles.valoDevSimple,
      this.appConfig.roles.valoMarketerSuper,
      this.appConfig.roles.valoMarketerSimple,
    ];

    // Check is the user is a valo user
    if (this.tokenService.checkIsLoggedIn() && valoAdminAccountList.includes(this.tokenService.getRoleName())) {
      this.isValo = true;
    }

    if (this.recoveredInfos) {
      this.programId = this.recoveredInfos.id;
      this.isImported = this.recoveredInfos.isImported;
      if (this.recoveredInfos.programStatus.label === ProgramStatus.INCOMPLETE) {
        this.isDraftOrIncompleteStatusProgram = true;
      }
      if (this.isValo && this.recoveredInfos.programStatus.label === ProgramStatus.VALID) {
        this.isValidStatusProgram = true;
      }
    } else {
      this.programIdObservableSubscription = this.programService.programIdObservable.subscribe((programId) => {
        this.programId = programId;
      });
    }

    // Init behavior subject for updateSummary method
    this.lotSummaryObservableSubscription = this.programService.lotSummaryObservable.subscribe((lotSummary) => {
      if (lotSummary) {
        this.lotSummary = lotSummary;
      }
    });

    this.creationProgramForm.get('adminValoForm').valueChanges.subscribe((form) => {
      this.isElectronicSignatureValorissimo(form?.isElectronicSignatureValorissimo);
    });

    this.creationProgramForm
      .get('program')
      .get('document')
      .valueChanges.subscribe(() => {
        this.displaySignatureErrorMessage = !this.creationProgramForm.get('program').get('document').valid;
      });
  }

  /**
   * On submit method to update prgm
   *
   * @memberof RegisterFormProgramComponent
   */
  onSubmit(): void {
    this.isSubmitFinal = true;
    this.creationProgramForm.updateValueAndValidity();

    if (this.creationProgramForm.valid) {
      if (this.isDraftOrIncompleteStatusProgram) {
        this.sendUpdateProgram(this.appConfig.status.pending);
      } else {
        this.programService.sendPendingValidationProgram(this.programId, this.isValo);
      }
    } else {
      this.handleSnackBarErrorMessageForm();
    }
  }

  /**
   * Methood to spot changes on steps
   *
   * @param {*} event
   * @memberof RegisterFormProgramComponent
   */
  stepChanged(event: StepperSelectionEvent): void {
    event.selectedStep.interacted = false;
    if (event.previouslySelectedIndex === 0) {
      this.loadCompanyInfo();
    }
    // Update summary on step 3
    if (event.selectedIndex === 2) {
      this.updateSummary();
    }
  }

  /**
   * Open dialog to write message on denied or incomplete prgm
   *
   * @param {string} type
   * @memberof RegisterFormProgramComponent
   */
  openDialog(type: string): void {
    let textPopin = '';
    let placeHolderPopin = '';
    switch (type) {
      case this.appConfig.status.denied:
        textPopin = 'Txt_Popin_Denied_Program';
        placeHolderPopin = 'Txt_Placeholder_Denied_Program';
        break;
      case this.appConfig.status.incomplete:
        textPopin = 'Txt_Popin_Invalid_Program';
        placeHolderPopin = 'Txt_Placeholder_Invalid_Program';
        break;
      default:
        break;
    }
    const dialogData: TextareaDialogData = {
      message: this.i18nService._(textPopin),
      placeholder: this.i18nService._(placeHolderPopin),
    };
    const dialogRef = this.dialog.open(TextareaDialogComponent, {
      data: dialogData,
    });
    // Add in form and update company status
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.sendUpdateProgram(type, result);
      }
    });
  }

  /**
   * Method to change program status to valid
   *
   * @memberof RegisterFormProgramComponent
   */
  sendUpdateProgram(status: string, returnMessage?: string): void {
    this.isSubmitFinal = true;
    const accessObjectControls = 'controls';
    const programObjectControls = 'program';

    // Avant de récupérer les erreurs, mettez à jour la validité du formulaire
    this.creationProgramForm.updateValueAndValidity();

    if (this.creationProgramForm.valid) {
      // initialisation of programToUpdate
      const programToUpdate = new ProgramUpdate();

      // Escape HTML tags for update on short and long descr VALO
      this.programService.sanitizeFormFields([this.creationProgramForm.controls[programObjectControls][accessObjectControls].programText]);

      // Put adminValoForm documents into programForm documents
      Object.keys(this.documentsValoForm.controls).forEach((element) => {
        this.documentsForm.controls[element] = this.documentsValoForm.controls[element];
        this.documentsForm.value[element] = this.documentsValoForm.value[element];
      });
      delete this.adminValoForm.controls.document;

      // Change empty doc "" to undefined
      Object.keys(this.documentsForm.controls).forEach((element) => {
        if (this.documentsForm.value[element] === '') {
          this.documentsForm.controls[element].setValue(undefined);
        }
      });

      // Add all values of child forms to programToUpdate
      Object.keys(this.creationProgramForm.controls).forEach((key) => {
        Object.keys(this.creationProgramForm.controls[key][accessObjectControls]).forEach((element) => {
          programToUpdate[element] = this.creationProgramForm.controls[key].get(element).value;
        });
      });
      Object.keys(this.programForm.controls).forEach((key) => (programToUpdate[key] = this.programForm.get(key).value));

      programToUpdate.programRef = this.programForm.get('programRef').value;
      programToUpdate.message = returnMessage;
      programToUpdate.documentsId = [];
      delete programToUpdate.isNotMandatoryInfos;

      this.spinnerWithBackdropService.show();
      this.programService.updateProgram(this.programId, programToUpdate, status).subscribe(
        (updatePrgm) => {
          this.spinnerWithBackdropService.hide();
          let snackBarMessage = '';
          let emailContacts = '';
          switch (status) {
            case this.appConfig.status.denied:
            case this.appConfig.status.incomplete:
              emailContacts = JSON.stringify(updatePrgm.emailContacts);
              snackBarMessage = 'Txt_Popin_Form_ResetPassword_EmailSent';
              break;
            case this.appConfig.status.valid:
              snackBarMessage = 'Success_SnackBar_ProgramValidated';
              break;
            default:
              snackBarMessage = 'Success_SnackBar_ProgramModified';
              if ([this.appConfig.status.draft, this.appConfig.status.incomplete].includes(this.recoveredInfos.programStatus.label)) {
                snackBarMessage = 'TxT_Program_Form_Valid_Submission';
              }
              break;
          }
          this.snackbarService.sendMessage({
            text: this.i18nService._(snackBarMessage, [emailContacts]),
            type: SnackbarMessageType.Info,
          });
          this.router.navigate([this.programsDashboardPath]);
        },
        ({ error }) => {
          this.spinnerWithBackdropService.hide();

          if (error.error.code === 'ERROR_PROGRAM_INCOMPLETE_LOTS') {
            const initHtmlTagList = '\n <ul> \n';
            const endHtmlTagList = '</ul>';
            let lotsNumbersWithoutCompleteStatusHtmlTag = initHtmlTagList;
            let failedLots;

            try {
              failedLots = JSON.parse(error.error.message);
            } catch (error) {
              this.snackbarService.sendMessage({
                type: SnackbarMessageType.Error,
                text: this.i18nService._('Error_SnackBar_FormIncomplete'),
              });

              return;
            }

            failedLots.forEach((lotNotComplete) => {
              lotsNumbersWithoutCompleteStatusHtmlTag = `${lotsNumbersWithoutCompleteStatusHtmlTag} <li> ${lotNotComplete} </li> \n`;
            });

            const errorMessageHTML = `<span> ${this.i18nService._('Error_SnackBar_lotsAreNotComplete')} </span>`;

            // => <ul> <li> A56 </li> </ul>
            lotsNumbersWithoutCompleteStatusHtmlTag = lotsNumbersWithoutCompleteStatusHtmlTag.concat(endHtmlTagList);

            this.snackbarService.message(
              errorMessageHTML.concat(lotsNumbersWithoutCompleteStatusHtmlTag),
              SnackbarMessageType.Error,
              false,
              [],
              true,
            );

            return;
          }

          this.snackbarService.sendMessage({
            type: SnackbarMessageType.Error,
            text: this.i18nService._('Error_SnackBar_FormIncomplete'),
          });
        },
      );
    } else {
      this.handleSnackBarErrorMessageForm();
    }
  }

  /**
   * Method used to go to step 4 after click on 'NextStep' in stepThree
   * Step 3 state is complete = false so user can't go to step 4 without clicking on 'NextStep' in stepThree
   *
   * @param {boolean} event
   * @memberof RegisterFormProgramComponent
   */
  onCompletedStep(event: boolean): void {
    this.stepper.selected.completed = event;
    this.stepper.next();
  }

  /**
   * Method to update summary on step 3 with informations from previous steps
   *
   * @memberof RegisterFormProgramComponent
   */
  updateSummary(): void {
    this.referenceTablesService.getTableReferenceInfoTranslated('Countries').subscribe((countriesData) => {
      if (countriesData) {
        // Get Country Label from cached data
        const countryLabel = countriesData.filter((country) => country.id === this.programForm.get('countryId').value)[0].label;
        this.programSummary = {
          programName: this.programForm.get('programName').value,
          address: `${this.programForm.get('address').value} ${this.programForm.get('postalCode').value}
                 ${this.programForm.get('city').value} ${countryLabel}`,
          nbLots: this.lotSummary && this.lotSummary.nbLots ? this.lotSummary.nbLots : 0,
          deliveryDate:
            this.lotSummary && this.lotSummary.deliveryDate ? this.programService.formatCustomDate(this.lotSummary.deliveryDate) : '',
          specialOffers: this.recoveredInfos ? this.recoveredInfos.specialOffers : undefined,
        };
      }
    });
  }

  /**
   * Method to print error text on snackbar
   *
   * @memberof RegisterFormProgramComponent
   */
  handleSnackBarErrorMessageForm(): void {
    let errorText = this.i18nService._('Error_SnackBar_FormIncomplete');

    if (this.adminValoForm.get('document')?.get('ApartmentContractualDocumentPackage')?.errors?.required) {
      errorText = this.i18nService._('Error_SnackBar_MissingApartmentContractualDocumentPackage');
    }
    if (this.adminValoForm.get('document')?.get('HouseContractualDocumentPackage')?.errors?.required) {
      errorText = this.i18nService._('Error_SnackBar_MissingHouseContractualDocumentPackage');
    }
    if (this.adminValoForm.get('document')?.get('TradeContractualDocumentPackage')?.errors?.required) {
      errorText = this.i18nService._('Error_SnackBar_MissingTradeContractualDocumentPackage');
    }

    if (this.adminValoForm.controls.document.errors?.atLeastOne) {
      errorText = this.adminValoForm.controls.document.errors.atLeastOne;
    }

    if (this.documentsForm.errors?.atLeastOne) {
      errorText = this.documentsForm.errors?.atLeastOne;
    }

    this.snackbarService.sendMessage({
      type: SnackbarMessageType.Error,
      text: errorText,
    });
  }

  /**
   * Method output of app-step-four-form-program for ElectronicSignature special behavior
   *
   * @memberof RegisterFormProgramComponent
   */
  isElectronicSignatureValorissimo(value) {
    if (value) {
      this.acceptedFileType = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    } else {
      this.acceptedFileType = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    }
  }

  /**
   * On destroy method to remove programId
   *
   * @memberof RegisterFormProgramComponent
   */
  ngOnDestroy(): void {
    this.programService.setIdProgramId(undefined);
    this.lotSummaryObservableSubscription.unsubscribe();
    if (this.programIdObservableSubscription) {
      this.programIdObservableSubscription.unsubscribe();
    }
  }

  /**
   * Method to show welcome dialog
   *
   * @private
   * @memberof RegisterFormProgramComponent
   */
  private showWelcomeDialog(): void {
    const dialogData: MatDialogConfig<CheckboxDialogData> = {
      data: {
        title: this.i18nService._('Title_Information'),
        message: this.i18nService._('Txt_Dialog_SaveNewOperationInfo'),
        checkbox: this.i18nService._('Txt_Checkbox_DoNotRemindMe'),
      },
    };
    const dialogRef = this.dialog.open(CheckboxDialogComponent, dialogData);

    dialogRef.afterClosed().subscribe((dialogResult: CheckboxDialogResponse) => {
      if (dialogResult && dialogResult.checked) {
        // Save in local storage that user doesn't want to see the dialog anymore
        localStorage.setItem(this.stopRemindingInfoItem, JSON.stringify(true));
      }
    });
  }

  loadCompanyInfo() {
    if (
      this.programForm.get('companyId').value &&
      (!this.recoveredInfos || this.recoveredInfos.companyId !== this.programForm.get('companyId').value)
    ) {
      this.companyService.getCompanyInformation(this.programForm.get('companyId').value).subscribe((data: Partial<CompanyData>) => {
        this.company = data;
      });
    }
  }
  onDevCompanyChanged(event): void {
    if (event[0]?.id) {
      this.companyService.getCompanyInformation(event[0].id).subscribe((data: Partial<CompanyData>) => {
        this.stepFourFormProgramComponent.getAssociateManagerList(data.isBi);
      });
    }
  }
}
