/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup, FormsModule } from '@angular/forms';
import { distinctUntilChanged, filter, Subject, takeUntil } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatBadgeModule } from '@angular/material/badge';
import { NgClass, NgIf } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { ProgramService } from '../../../programs/services/program.service';
import { TableCheckboxShow } from '../../../table/enums/table-checkbox-show.enum';
import { AdvancedTrackingTableColumnType } from '../../../tracking-tables/models/advanced-tracking-table-column-type.enum';
import { FormUtilsService } from '../../../utils/services/form/form-utils.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SPECIAL_OFFER_CONTROL_PATH } from '../../service/special-offer.service';
import { ProgramTrackingTableData } from '../../../programs/models/ProgramLotTableDisplayData';
import { AdvancedTrackingTablesNoBackPageComponent } from '../../../tracking-tables/components/advanced-tracking-tables-no-back-page/advanced-tracking-tables-no-back-page.component';

@Component({
  selector: 'app-step-two-special-offer-form',
  templateUrl: './step-two-special-offer-form.component.html',
  styleUrls: ['./step-two-special-offer-form.component.scss'],
  standalone: true,
  imports: [
    MatButtonToggleModule,
    FormsModule,
    NgClass,
    MatBadgeModule,
    NgIf,
    AdvancedTrackingTablesNoBackPageComponent,
    MatFormFieldModule,
  ],
})
export class StepTwoSpecialOfferFormComponent implements OnInit, OnDestroy {
  public programList: ProgramTrackingTableData[];
  public programListFiltered: { data: Array<any>; clearSelection: boolean };
  isLoadingResults = true;
  /**
   * showCheckboxSelection all
   *
   * @type {TableCheckboxShow}
   * @memberof MandateStrategySelectionTableComponent
   */
  public readonly showCheckboxSelectionALL: TableCheckboxShow = TableCheckboxShow.ALL;
  /**
   * showCheckboxSelection not selected
   *
   * @type {TableCheckboxShow}
   * @memberof MandateStrategySelectionTableComponent
   */
  public readonly showCheckboxSelectionNotSelected: TableCheckboxShow = TableCheckboxShow.NOT_SELECTED;
  /**
   * showCheckboxSelection selected
   *
   * @type {TableCheckboxShow}
   * @memberof MandateStrategySelectionTableComponent
   */
  public readonly showCheckboxSelectionSelected: TableCheckboxShow = TableCheckboxShow.SELECTED;
  @Input() specialOfferForm: UntypedFormGroup;
  @Input() submit: boolean;
  basicColumns = [
    {
      key: 'id',
      hidden: true,
    },
    {
      key: 'programName',
      sortable: true,
      i18nTitle: 'Txt_ProgramsTrackingTable_ProgramName',
    },
    {
      key: 'cityPostalCode',
      sortable: true,
      i18nTitle: 'Txt_ProgramsTrackingTable_CityPostalCode',
    },
    {
      key: 'lotsPublishedOnLotsStock',
      sortable: false,
      i18nTitle: 'Txt_ProgramsTrackingTable_LotsPublishedOnLotsStock',
    },
    {
      key: 'optionedLots',
      sortable: true,
      type: AdvancedTrackingTableColumnType.Number,
      i18nTitle: 'Txt_ProgramsTrackingTable_OptionedLots',
    },
    {
      key: 'deliveryDate',
      sortable: true,
      type: AdvancedTrackingTableColumnType.Date,
      parameters: {
        dateFormat: 'DD/MM/YYYY',
      },
      replaceFalsyWithDash: true,
      i18nTitle: 'Txt_ProgramsTrackingTable_DeliveryDate',
    },
    {
      key: 'isFromFlux',
      sortable: true,
      i18nTitle: 'Txt_ProgramTrackingTable_IsFromFlux',
      type: AdvancedTrackingTableColumnType.Boolean,
    },
    {
      key: 'programStatusLabel',
      sortable: true,
      i18nTitle: 'Txt_ProgramsTrackingTable_ProgramStatus',
      type: AdvancedTrackingTableColumnType.I18nToken,
      parameters: {
        i18nTokenPrefix: 'PROGRAM_',
      },
    },
  ];
  showCheckboxSelection: any;
  SPECIAL_OFFER_CONTROL_PATH = SPECIAL_OFFER_CONTROL_PATH;
  formUtilsService = FormUtilsService;
  @Output() readonly emptyDataEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  companyId: number;
  private readonly _destroy$: Subject<boolean> = new Subject<boolean>();
  private selectedProgramIds: Array<any>;

  constructor(
    public i18nService: I18nService,
    private readonly _programService: ProgramService,
  ) {
    this.showCheckboxSelection = this.showCheckboxSelectionALL;
  }

  private _disabledForm: boolean;

  get disabledForm(): boolean {
    return this._disabledForm;
  }

  @Input() set disabledForm(disabledForm: boolean) {
    this._disabledForm = disabledForm;
  }

  get programIdListControl(): AbstractControl {
    return FormUtilsService.getControl(SPECIAL_OFFER_CONTROL_PATH.programIdList, this.specialOfferForm);
  }

  ngOnInit(): void {
    this.formUtilsService
      .getControl(SPECIAL_OFFER_CONTROL_PATH.company, this.specialOfferForm)
      .valueChanges.pipe(
        filter((value) => value && value instanceof Object),
        distinctUntilChanged(),
        takeUntil(this._destroy$),
      )
      .subscribe((value: any) => {
        this.companyId = value.id;
        this.formUtilsService.setFormValue(SPECIAL_OFFER_CONTROL_PATH.programIdList, this.specialOfferForm, undefined);
        this._programService.getProgramByCompany(this.companyId).subscribe(
          (programList) => {
            this.programList = programList;
            this.programListFiltered = {
              data: programList,
              clearSelection: true,
            };
            this._preselectProgram(FormUtilsService.getFormValue(SPECIAL_OFFER_CONTROL_PATH.programIdList, this.specialOfferForm));
            this.emptyDataEvent.emit(!programList || !programList.length);
            this.isLoadingResults = false;
          },
          () => (this.isLoadingResults = false),
        );
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  checkedRowsChange($event: Array<any>): void {
    this.selectedProgramIds = $event;
    this.programIdListControl.setValue(this.selectedProgramIds);
  }

  filterProgramList(): void {
    switch (this.showCheckboxSelection) {
      case TableCheckboxShow.ALL: {
        this.programListFiltered = {
          data: [...this.programList],
          clearSelection: false,
        };
        break;
      }
      case TableCheckboxShow.NOT_SELECTED: {
        this.programListFiltered = {
          data: this.programList.filter((value) => !this.selectedProgramIds.includes(value.id)),
          clearSelection: false,
        };
        break;
      }
      case TableCheckboxShow.SELECTED: {
        this.programListFiltered = {
          data: this.programList.filter((value) => this.selectedProgramIds.includes(value.id)),
          clearSelection: false,
        };
        break;
      }
      default:
    }
  }

  private _preselectProgram(programIdList: any): void {
    this.selectedProgramIds = programIdList;
  }
}
