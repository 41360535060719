import { Injectable } from '@angular/core';

import { CountryApiService } from '../../adapters/country-api.service';
import { CountryResponse } from '../../utils/models/CountryResponse';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  /**
   * Creates an instance of CountryService.
   * @param {CountryApiService} countryApiService
   * @memberof CountryService
   */
  constructor(private readonly countryApiService: CountryApiService) {}

  public async getCountryById(id: number): Promise<CountryResponse> {
    return this.countryApiService.getCountryById(id);
  }
}
