<div #containerApp class="container-app">
  <div #containerHeader class="container-header">
    <app-header *ngIf="!isLoginRoute" class="flex h-22"></app-header>
  </div>
  <div class="container-body">
    <router-outlet></router-outlet>
  </div>
</div>

<app-spinner-with-backdrop></app-spinner-with-backdrop>
<app-chatbot></app-chatbot>
