import { IsInt, IsObject, IsOptional } from 'class-validator';

import { StatusInsertPaternitiesResponse } from '../paternity';

export class DossierCreateResponse {
  @IsInt()
  id: number;

  @IsObject()
  @IsOptional()
  statusInsertPaternities?: StatusInsertPaternitiesResponse;
}
