export enum ValoRole {
  contractorHyper = 'contractorHyper',
  contractorPtf = 'contractorPtf',
  contractorSimple = 'contractorSimple',
  contractorSuper = 'contractorSuper',
  developerHyper = 'developerHyper',
  developerPtf = 'developerPtf',
  developerSimple = 'developerSimple',
  developerSuper = 'developerSuper',
  independant = 'independant',
  independantNetworkHeader = 'independantNetworkHeader',
  independantNetworkLeader = 'independantNetworkLeader',
  technical = 'technical',
  valoAdminSimple = 'valoAdminSimple',
  valoAdminSuper = 'valoAdminSuper',
  valoBoSimple = 'valoBoSimple',
  valoBoSuper = 'valoBoSuper',
  valoDevSimple = 'valoDevSimple',
  valoDevSuper = 'valoDevSuper',
  valoMarketerSimple = 'valoMarketerSimple',
  valoMarketerSuper = 'valoMarketerSuper',
  valoPtf = 'valoPtf',
  valoRcvSimple = 'valoRcvSimple',
  valoRcvSuper = 'valoRcvSuper',
  valoSystem = 'valoSystem',
}
