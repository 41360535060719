import { Component } from '@angular/core';

import { LogAccountComponent } from '../log-account/log-account.component';

import { FooterComponent } from '../../../utils/components/footer/footer.component';

@Component({
  selector: 'app-log-container',
  templateUrl: './log-container.component.html',
  styleUrls: ['./log-container.component.scss'],
  standalone: true,
  imports: [LogAccountComponent, FooterComponent],
})
export class LogContainerComponent {}
