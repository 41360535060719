<div class="text-base font-bold text-left mb-3">
  {{ i18nService._('Txt_search_filter_taxation_placeholder') }}
</div>
<mat-chip-listbox class="mat-chip-list-stacked mb-4">
  <mat-chip-option
    color="primary"
    *ngFor="let item of taxationItems"
    class="mr-3 text-base"
    [selected]="item.id === search.taxation"
    (click)="selectItem(item)"
  >
    <mat-icon *ngIf="item.id === search.taxation" class="material-icons-outlined size-18">radio_button_checked</mat-icon>
    <mat-icon *ngIf="item.id !== search.taxation" class="material-icons-outlined size-18">radio_button_unchecked</mat-icon>
    <div class="fiscality-label text-sm">{{ item.label }}</div>
  </mat-chip-option>
</mat-chip-listbox>
