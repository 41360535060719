/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs';

import { FinancialStrategyFormData } from '../models/FinancialStrategyFormData';

import { ProgramGeneratedFeesAmounts } from '../../utils/models/ProgramGeneratedFeesAmounts';
import { AppConfigService } from '../../utils/services/app-config.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class FinancialStrategyService {
  /**
   * Creates an instance of FinancialStrategyService.
   * @param {HttpClient} http
   * @param {ErrorHandlerService} errorHandlerService
   * @param {AppConfigService} appConfig
   * @memberof FinancialStrategyService
   */
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
  ) {}

  /**
   * getFormData method
   *
   * @param {string} programId
   * @returns {Observable<FinancialStrategyFormData>}
   * @memberof FinancialStrategyService
   */
  getFormData(programId: string): Observable<FinancialStrategyFormData> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/FinancialStrategies/form-infos`;
    const params = new HttpParams().set('id', programId);

    return this.http
      .get<FinancialStrategyFormData>(url, { params })
      .pipe(catchError(this.errorHandlerService.handleError<FinancialStrategyFormData>('financialStrategyService', 'getFormData')));
  }

  /**
   * createOrUpdateFinancialStrategy method
   *
   * @param {*} financialStrategyData
   * @param {number} existingFinancialStrategyId
   * @returns {Observable<any>}
   * @memberof FinancialStrategyService
   */
  createOrUpdateFinancialStrategy(financialStrategyData: any, existingFinancialStrategyId: number): Observable<any> {
    let url = `${this.appConfig.getLoopbackApiUrl()}/FinancialStrategies`;

    if (existingFinancialStrategyId) {
      url = `${url}/${existingFinancialStrategyId}`;

      return this.http
        .put<any>(url, financialStrategyData)
        .pipe(catchError(this.errorHandlerService.handleError<any>('financialStrategyService', 'createOrUpdateFinancialStrategy')));
    }

    return this.http
      .post<any>(url, financialStrategyData)
      .pipe(catchError(this.errorHandlerService.handleError<any>('financialStrategyService', 'createOrUpdateFinancialStrategy')));
  }

  /**
   * Publish program
   *
   * @param programId
   * @returns {ProgramGeneratedFeesAmounts}
   * @memberof SharedProgramService
   */
  getGeneratedFees(programId: string): Observable<ProgramGeneratedFeesAmounts> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Financialstrategies/fees/${programId}`;

    return this.http.get<any>(url).pipe(catchError(this.errorHandlerService.handleError<any>('sharedProgramService', 'getGeneratedFees')));
  }
}
