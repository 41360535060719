import { ValoRole } from '../../../../../../libs/commons-dto/src/lib/valo-back/valo-role.enum';

/**
 * Sitemap: <{ [key: string]: { [key: string]: Route } }>
 */
export class Sitemap {
  /**
   * Grouping utils routes.
   */
  public static readonly utils = {
    homepage: {
      path: 'homepage',
      data: {
        snackbarIfNotConnected: false,
      },
    },
    login: {
      path: 'login',
    },
    notFound: {
      path: '404',
    },
    changePassword: {
      path: 'changement-mot-de-passe',
    },
    search: {
      path: 'recherche',
    },
    fastSearch: {
      path: 'recherche/rapide',
    },
    myProfile: {
      path: 'compte/mon-profil',
    },
    myCompany: {
      path: 'compte/ma-societe',
    },
    legalNotice: {
      path: 'mentions-legales',
    },
    recommendations: {
      path: 'recommandations',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.valoMarketerSuper,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.valoDevSuper,
          ValoRole.valoDevSimple,
          ValoRole.developerHyper,
          ValoRole.developerSuper,
          ValoRole.contractorHyper,
          ValoRole.contractorSuper,
        ],
      },
    },
    dossierProspect: {
      path: 'dossier-prospect',
    },
  };

  /**
   * Grouping accounts routes.
   */
  public static readonly accounts = {
    admin: {
      path: 'administration/utilisateurs',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.valoMarketerSuper,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.valoDevSuper,
          ValoRole.valoDevSimple,
          ValoRole.developerHyper,
          ValoRole.developerSuper,
          ValoRole.contractorHyper,
          ValoRole.contractorSuper,
          ValoRole.independantNetworkHeader,
          ValoRole.independantNetworkLeader,
        ],
      },
    },
    adminDeveloper: {
      path: 'administration/utilisateurs/promoteurs',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoDevSuper,
          ValoRole.valoDevSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.developerHyper,
          ValoRole.developerSuper,
        ],
      },
    },
    adminContractor: {
      path: 'administration/utilisateurs/prescripteurs',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.contractorHyper,
          ValoRole.contractorSuper,
        ],
      },
    },
    adminIndependant: {
      path: 'administration/utilisateurs/independant',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.valoRcvSuper,
          ValoRole.independantNetworkHeader,
          ValoRole.independantNetworkLeader,
        ],
      },
    },
    register: {
      path: 'administration/utilisateurs/creation-compte',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.developerHyper,
          ValoRole.developerSuper,
          ValoRole.contractorHyper,
          ValoRole.contractorSuper,
          ValoRole.valoRcvSuper,
        ],
      },
    },
    registerById: {
      path: 'administration/utilisateurs/creation-compte/:id',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.developerHyper,
          ValoRole.developerSuper,
          ValoRole.contractorHyper,
          ValoRole.contractorSuper,
          ValoRole.valoRcvSuper,
        ],
      },
    },
    validateById: {
      path: 'administration/utilisateurs/validation-compte/:id',
      data: {
        roles: [ValoRole.valoSystem, ValoRole.valoAdminSuper, ValoRole.valoAdminSimple, ValoRole.valoBoSuper, ValoRole.valoBoSimple],
      },
    },
    updateById: {
      path: 'administration/utilisateurs/modification-compte/:id',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.valoRcvSuper,
        ],
      },
    },
  };

  /**
   * Grouping companies routes.
   */
  public static readonly companies = {
    admin: {
      path: 'administration/societes',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.valoDevSuper,
          ValoRole.valoDevSimple,
        ],
      },
    },
    adminDeveloper: {
      path: 'administration/societes/promotrices',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoDevSimple,
          ValoRole.valoDevSuper,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
        ],
      },
    },
    adminContractor: {
      path: 'administration/societes/prescriptrices',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
        ],
      },
    },
    adminIndependant: {
      path: 'administration/societes/independant',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.valoRcvSuper,
        ],
      },
    },
    validate: {
      path: 'administration/societes/validation-societe/:companyId',
      data: {
        roles: [ValoRole.valoSystem, ValoRole.valoAdminSuper, ValoRole.valoAdminSimple, ValoRole.valoBoSuper, ValoRole.valoBoSimple],
      },
    },
    edit: {
      path: 'administration/societes/modification-societe/:companyId',
      data: {
        roles: [ValoRole.valoSystem, ValoRole.valoAdminSuper, ValoRole.valoAdminSimple, ValoRole.valoBoSuper, ValoRole.valoBoSimple],
      },
    },
    register: {
      path: 'inscription/societe',
    },
    registerById: {
      path: 'inscription/societe/:companyId',
    },
    registerDeveloper: {
      path: 'inscription/societe/promoteur',
    },
    registerContractor: {
      path: 'inscription/societe/prescripteur',
    },
    mandateStrategiesContractor: {
      path: 'administration/societes/:companyId/strategies',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoMarketerSuper,
          ValoRole.valoMarketerSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.valoDevSuper,
          ValoRole.valoDevSimple,
        ],
      },
    },
  };

  /**
   * Grouping programs routes.
   */
  public static readonly programs = {
    read: {
      path: 'programme/:programId',
    },
    strategies: {
      path: 'programme/:programId/strategies',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoDevSuper,
          ValoRole.valoMarketerSuper,
          ValoRole.valoMarketerSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
        ],
      },
    },
    admin: {
      path: 'administration/programmes',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoMarketerSuper,
          ValoRole.valoMarketerSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.valoDevSuper,
          ValoRole.valoDevSimple,
          ValoRole.developerHyper,
          ValoRole.developerSuper,
        ],
      },
    },
    register: {
      path: 'administration/programmes/creation-programme',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoMarketerSuper,
          ValoRole.valoMarketerSimple,
          ValoRole.valoDevSuper,
          ValoRole.valoDevSimple,
          ValoRole.developerHyper,
          ValoRole.developerSuper,
        ],
      },
    },
    edit: {
      path: 'administration/programmes/modification-programme/:programId',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoMarketerSuper,
          ValoRole.valoMarketerSimple,
          ValoRole.valoDevSuper,
          ValoRole.valoDevSimple,
          ValoRole.developerHyper,
          ValoRole.developerSuper,
        ],
      },
    },
    validate: {
      path: 'administration/programmes/validation-programme/:programId',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoMarketerSuper,
          ValoRole.valoMarketerSimple,
          ValoRole.valoDevSuper,
          ValoRole.valoDevSimple,
        ],
      },
    },
  };
  /**
   * Grouping special offers routes.
   */
  public static readonly specialOffer = {
    register: {
      path: 'administration/special-offer/creation-special-offer',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoMarketerSuper,
          ValoRole.valoMarketerSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.valoDevSuper,
          ValoRole.valoDevSimple,
          ValoRole.valoBoSimple,
          ValoRole.valoBoSuper,
        ],
      },
    },
    edit: {
      path: 'administration/special-offer/modification-special-offer/:specialOfferId',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoMarketerSuper,
          ValoRole.valoMarketerSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.valoDevSuper,
          ValoRole.valoDevSimple,
          ValoRole.valoBoSimple,
          ValoRole.valoBoSuper,
        ],
        readonlyRoles: [ValoRole.valoRcvSuper, ValoRole.valoRcvSimple, ValoRole.valoBoSimple, ValoRole.valoBoSuper],
      },
    },
    admin: {
      path: 'administration/special-offers',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoMarketerSuper,
          ValoRole.valoMarketerSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.valoDevSuper,
          ValoRole.valoDevSimple,
          ValoRole.valoBoSimple,
          ValoRole.valoBoSuper,
        ],
      },
    },
  };

  /**
   * Grouping prospects routes.
   */
  public static readonly prospects = {
    register: {
      path: 'prospect/creation',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.developerHyper,
          ValoRole.developerSuper,
          ValoRole.developerSimple,
          ValoRole.contractorHyper,
          ValoRole.contractorSuper,
          ValoRole.contractorSimple,
        ],
      },
    },
  };

  public static readonly dossierProspects = {
    create: {
      path: 'dossier-prospect/creation',
      data: {
        roles: [
          ValoRole.contractorHyper,
          ValoRole.contractorSuper,
          ValoRole.contractorSimple,
          ValoRole.independantNetworkHeader,
          ValoRole.independantNetworkLeader,
          ValoRole.independant,
        ],
      },
    },
    dashboard: {
      path: 'dossier-prospect/:id/dashboard',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.contractorHyper,
          ValoRole.contractorSuper,
          ValoRole.contractorSimple,
          ValoRole.independantNetworkHeader,
          ValoRole.independantNetworkLeader,
          ValoRole.independant,
        ],
      },
    },
    dashboardSimulation: {
      path: 'dossier-prospect/:id/dashboard/simulation',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.contractorHyper,
          ValoRole.contractorSuper,
          ValoRole.contractorSimple,
          ValoRole.independantNetworkHeader,
          ValoRole.independantNetworkLeader,
          ValoRole.independant,
        ],
      },
    },
    dashboardPaternities: {
      path: 'dossier-prospect/:id/dashboard/paternities',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.contractorHyper,
          ValoRole.contractorSuper,
          ValoRole.contractorSimple,
          ValoRole.independantNetworkHeader,
          ValoRole.independantNetworkLeader,
          ValoRole.independant,
        ],
      },
    },
    dashboardLots: {
      path: 'dossier-prospect/:id/dashboard/lots',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.contractorHyper,
          ValoRole.contractorSuper,
          ValoRole.contractorSimple,
          ValoRole.independantNetworkHeader,
          ValoRole.independantNetworkLeader,
          ValoRole.independant,
        ],
      },
    },
    dashboardTransactions: {
      path: 'dossier-prospect/:id/dashboard/transactions',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.contractorHyper,
          ValoRole.contractorSuper,
          ValoRole.contractorSimple,
          ValoRole.independantNetworkHeader,
          ValoRole.independantNetworkLeader,
          ValoRole.independant,
        ],
      },
    },
    dashboardSearchPreferences: {
      path: 'dossier-prospect/:id/dashboard/search-preferences',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.contractorHyper,
          ValoRole.contractorSuper,
          ValoRole.contractorSimple,
          ValoRole.independantNetworkHeader,
          ValoRole.independantNetworkLeader,
          ValoRole.independant,
        ],
      },
    },
    edit: {
      path: 'dossier-prospect/:id/edit',
      data: {
        roles: [ValoRole.contractorHyper, ValoRole.contractorSuper, ValoRole.contractorSimple],
      },
    },
    paternity: {
      path: 'dossier-prospect/:existingProspect/paternities',
      data: {
        roles: [ValoRole.contractorHyper, ValoRole.contractorSuper, ValoRole.contractorSimple],
      },
    },
    list: {
      path: 'suivi/dossier-prospect',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.contractorHyper,
          ValoRole.contractorSimple,
          ValoRole.contractorSuper,
          ValoRole.independantNetworkHeader,
          ValoRole.independantNetworkLeader,
          ValoRole.independant,
        ],
      },
    },
  };

  /**
   * Grouping dashboards routes.
   */
  public static readonly dashboards = {
    options: {
      path: 'suivi/options',
    },
    preReservations: {
      path: 'suivi/pre-reservations',
    },
    reservations: {
      path: 'suivi/reservations',
    },
    prospects: {
      path: 'suivi/prospects',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.developerHyper,
          ValoRole.developerSuper,
          ValoRole.developerSimple,
          ValoRole.contractorHyper,
          ValoRole.contractorSuper,
          ValoRole.contractorSimple,
          ValoRole.independantNetworkHeader,
          ValoRole.independantNetworkLeader,
          ValoRole.independant,
        ],
      },
    },
    salesPrograms: {
      path: 'suivi/programmes',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.valoDevSuper,
          ValoRole.valoDevSimple,
          ValoRole.developerHyper,
          ValoRole.developerSuper,
          ValoRole.developerSimple,
        ],
      },
    },
    action: {
      path: 'suivi/activites',
    },
    info: {
      path: 'suivi/notifications',
    },
    salers: {
      path: 'suivi/vendeurs',
      data: {
        roles: [
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.developerHyper,
          ValoRole.developerSuper,
          ValoRole.developerSimple,
          ValoRole.contractorHyper,
          ValoRole.contractorSuper,
          ValoRole.contractorSimple,
        ],
      },
    },
    savedSearches: {
      path: 'suivi/recherches',
    },
    dossier: {
      path: 'suivi/dossier',
      data: {
        roles: [
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.developerHyper,
          ValoRole.developerSuper,
          ValoRole.developerSimple,
          ValoRole.contractorHyper,
          ValoRole.contractorSuper,
          ValoRole.contractorSimple,
          ValoRole.independantNetworkLeader,
          ValoRole.independantNetworkHeader,
          ValoRole.independant,
        ],
      },
    },
  };

  /**
   * Grouping pre-reservation routes.
   */
  public static readonly preReservations = {
    create: {
      path: 'pre-reservations/create',
      data: {
        roles: [
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
        ],
      },
    },
  };

  /**
   * Grouping reservation routes.
   */
  public static readonly reservations = {
    edit: {
      path: 'reservation/:reservationId',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.valoMarketerSuper,
          ValoRole.valoMarketerSimple,
          ValoRole.valoDevSuper,
          ValoRole.valoDevSimple,
          ValoRole.developerHyper,
          ValoRole.developerSuper,
          ValoRole.developerSimple,
        ],
      },
    },
  };

  /**
   * comparator reservation routes.
   */
  public static readonly comparator = {
    lot: {
      path: 'comparator/lot',
    },
  };

  /**
   * Grouping signature routes.
   */
  public static readonly signature = {
    dashboard: {
      path: 'signature/:signatureId/dashboard',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.developerHyper,
          ValoRole.developerSuper,
          ValoRole.developerSimple,
          ValoRole.contractorHyper,
          ValoRole.contractorSimple,
          ValoRole.contractorSuper,
          ValoRole.independantNetworkHeader,
          ValoRole.independantNetworkLeader,
          ValoRole.independant,
        ],
      },
    },
    form: {
      path: 'signature/:signatureId/form',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.contractorHyper,
          ValoRole.contractorSimple,
          ValoRole.contractorSuper,
          ValoRole.independantNetworkHeader,
          ValoRole.independantNetworkLeader,
          ValoRole.independant,
        ],
      },
    },
    synthesis: {
      path: 'signature/:signatureId/synthesis',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.contractorHyper,
          ValoRole.contractorSimple,
          ValoRole.contractorSuper,
          ValoRole.independantNetworkHeader,
          ValoRole.independantNetworkLeader,
          ValoRole.independant,
        ],
      },
    },
    progress: {
      path: 'signature/:signatureId/progress',
      data: {
        roles: [
          ValoRole.valoSystem,
          ValoRole.valoAdminSuper,
          ValoRole.valoAdminSimple,
          ValoRole.valoRcvSuper,
          ValoRole.valoRcvSimple,
          ValoRole.valoBoSuper,
          ValoRole.valoBoSimple,
          ValoRole.developerHyper,
          ValoRole.developerSuper,
          ValoRole.developerSimple,
          ValoRole.contractorHyper,
          ValoRole.contractorSimple,
          ValoRole.contractorSuper,
          ValoRole.independantNetworkHeader,
          ValoRole.independantNetworkLeader,
          ValoRole.independant,
        ],
      },
    },
  };
}
