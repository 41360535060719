import {
  CompleteDossierResponse,
  DossierSearchPreferenceResponseDto,
  SearchCriteria,
  SummarySearchPreferenceResponse,
  SummarySimulationResponse,
} from '@commons-dto/dossier-prospect';
import { distinctUntilChanged, firstValueFrom, skip, Subject, takeUntil, throttleTime } from 'rxjs';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SimulationResponseDto } from 'libs/commons-dto/src/lib/dossier-prospect';
import { UntypedFormGroup } from '@angular/forms';
import { ProspectType } from 'libs/commons-dto/src/lib/dossier-prospect/prospect/prospect-type.enum';
import { CommonModule } from '@angular/common';

import { DossierProspectRouteService } from '../dossier-prospect/dossier-prospect.route.service';

import { DossierProspectService } from './../../services/dossier-prospect.service';
import { DossierProspectFormlyService } from './../../services/dossier-prospect-formly.service';
import { SimulationFormData } from '../../model/forms/simulation-form-data.model';
import { ButtonComponent } from '../../../design-system/component/button/button.component';
import { PipesModule } from '../../../utils/pipes/pipes.module';
import { UtilsModule } from '../../../utils/utils.module';
import { SharedModule } from '../../../common/shared/shared.module';
import { FormatSimulationValuePipe } from '../../pipes/simulation-results.pipe';
import { DossierSearchPreferenceService } from '../../../dossier-search-preference/dossier-search-preference.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { ButtonType } from '../../../design-system/component/button/button.type.enum';

@Component({
  selector: 'app-simulation-tab',
  standalone: true,
  templateUrl: './simulation-tab.component.html',
  styleUrls: ['./simulation-tab.component.scss'],
  imports: [CommonModule, FormlyModule, ButtonComponent, PipesModule, SharedModule, FormatSimulationValuePipe, UtilsModule],
})
export class SimulationTabComponent implements OnInit, OnDestroy {
  @Input() dossier: CompleteDossierResponse;

  private $destroy: Subject<void> = new Subject<void>();

  @Output() searchPreferencesUpdated = new EventEmitter<boolean>();
  @Output() simulationUpdated = new EventEmitter<void>();

  fields: FormlyFieldConfig[];
  model: SimulationFormData;
  options: FormlyFormOptions = {};
  form = new UntypedFormGroup({});
  simulation: SimulationResponseDto;
  loaded = false;

  simulationResultItems;
  ButtonType = ButtonType;

  constructor(
    private readonly prospectService: DossierProspectService,
    private readonly formlyService: DossierProspectFormlyService,
    private readonly dossierSearchPreferenceService: DossierSearchPreferenceService,
    private readonly dossierProspectRouteService: DossierProspectRouteService,
    private readonly snackbarService: SnackbarService,
    private readonly i18nService: I18nService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.dossier.simulationIds?.length > 0) {
      const simulation = await firstValueFrom(this.prospectService.loadSimulation(this.dossier.simulationIds[0]));
      this.initForms(simulation, this.dossier);
      this.loaded = true;
    } else {
      this.loaded = true;
    }
  }

  ngOnDestroy() {
    this.$destroy.next();
  }

  initForms(simulation: SimulationResponseDto, dossier: CompleteDossierResponse) {
    this.mapSimulation(simulation);
    this.model = SimulationFormData.fromDto(simulation);
    this.fields = this.formlyService.getFormSimulation(
      dossier.prospects?.length > 1 && dossier.prospects[0].prospectType !== ProspectType.COMPANY,
    );
    this.form.valueChanges
      .pipe(throttleTime(1000, undefined, { leading: true, trailing: true }), distinctUntilChanged(), skip(1), takeUntil(this.$destroy))
      .subscribe(async (value: SimulationFormData) => {
        await this.saveSimulation(value);
      });
  }

  async saveSimulation(value: SimulationFormData) {
    if (this.form.valid) {
      const simulation: SimulationResponseDto = await this.prospectService.saveSimulation(SimulationFormData.toDto(value));
      this.mapSimulation(simulation);

      const summarySimulation: SummarySimulationResponse = new SummarySimulationResponse();
      summarySimulation.id = simulation.id;
      summarySimulation.loanCapacity = simulation.loanCapacity;
      summarySimulation.personalFunds = simulation.personalFunds;
      summarySimulation.purchasingCapacity = simulation.purchasingCapacity;
      this.dossier.summarySimulations = [summarySimulation];
      this.simulationUpdated.emit();
    }
  }

  mapSimulation(simulation: SimulationResponseDto) {
    this.simulation = simulation;
    this.simulationResultItems = [
      { label: 'Prospect_Simulation_Debt_Ratio', value: this.simulation?.debtRatio, format: 'percent' },
      {
        label: 'Prospect_Simulation_Remaining_Funds',
        value: this.simulation?.remainingFunds,
        format: 'currency',
        hasTooltip: true,
        tooltip: 'Prospect_Simulation_Remaining_Funds_Helper',
      },
      { label: 'Prospect_Simulation_Payment_Capacity', value: this.simulation?.paymentCapacity, format: 'currency' },
      {
        label: 'Prospect_Simulation_Loan_Capacity',
        value: this.simulation?.loanCapacity,
        format: 'currency',
        hasTooltip: true,
        tooltip: 'Prospect_Simulation_Loan_Capacity_Helper',
      },
    ];
  }

  search() {
    const summarySearchPreferences: SummarySearchPreferenceResponse[] = this.getSearchPreferencesWithSimulation();
    if (this.dossier.summarySearchPreferences) {
      for (const searchPreference of this.dossier.summarySearchPreferences) {
        if (summarySearchPreferences.some((sp) => sp.searchCriteriaCode === searchPreference.searchCriteriaCode)) continue;
        const summarySearchPreference: SummarySearchPreferenceResponse = {
          searchCriteriaCode: searchPreference.searchCriteriaCode,
          value: searchPreference.value,
        };
        summarySearchPreferences.push(summarySearchPreference);
      }
    }
    this.dossierProspectRouteService.goToSearchWithSearchPreferences(summarySearchPreferences);
  }

  getSearchPreferencesWithSimulation(): DossierSearchPreferenceResponseDto[] {
    const searchPreferences: DossierSearchPreferenceResponseDto[] = [];
    const spMaxPrice: DossierSearchPreferenceResponseDto = new DossierSearchPreferenceResponseDto();
    spMaxPrice.searchCriteriaCode = SearchCriteria.LOT_MAX_PRICE;
    spMaxPrice.value = [this.simulation.purchasingCapacity];
    searchPreferences.push(spMaxPrice);

    const actualMinPrices = this.dossierSearchPreferenceService.getSearchPreferenceValue(this.dossier, SearchCriteria.LOT_MIN_PRICE);
    if (actualMinPrices != null && actualMinPrices.length > 0) {
      const actualMinPrice = actualMinPrices[0];
      if (actualMinPrice > this.simulation.purchasingCapacity) {
        const spMinPrice: DossierSearchPreferenceResponseDto = new DossierSearchPreferenceResponseDto();
        spMinPrice.searchCriteriaCode = SearchCriteria.LOT_MIN_PRICE;
        spMinPrice.value = [0];
        searchPreferences.push(spMinPrice);
      }
    }
    return searchPreferences;
  }

  async saveToSearchPreferences(): Promise<void> {
    if (!this.simulation.purchasingCapacity) return;
    const searchPreferences: DossierSearchPreferenceResponseDto[] = this.getSearchPreferencesWithSimulation();

    await this.dossierSearchPreferenceService.upsertByDossierId(this.dossier.id, searchPreferences);

    // Update the dossier in the summary of dossier
    let existingSearchPreference = this.dossier.summarySearchPreferences
      ? this.dossier.summarySearchPreferences.find(
          (searchPreference) => searchPreference.searchCriteriaCode === SearchCriteria.LOT_MAX_PRICE,
        )
      : null;
    if (!existingSearchPreference) {
      existingSearchPreference = new SummarySearchPreferenceResponse();
      existingSearchPreference.searchCriteriaCode = SearchCriteria.LOT_MAX_PRICE;
      this.dossier.summarySearchPreferences.push(existingSearchPreference);
    }
    existingSearchPreference.value = [this.simulation.purchasingCapacity];

    this.searchPreferencesUpdated.emit(true);

    this.snackbarService.sendMessage({
      text: this.i18nService._('Prospect_Simulation_Save_Confirmation_To_SearchPreferences'),
      type: SnackbarMessageType.Success,
    });
  }
}
