/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs';
import { ListResponse, ParamRequest } from '@commons-dto/valo-back';

import { AppConfigService } from '../../utils/services/app-config.service';
import { CacheService } from '../../utils/services/cache.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export abstract class AbstractAdvancedTrackingTableService<T> {
  protected serviceName = 'AbstractAdvancedTrackingTableService';
  protected url: string;
  protected cacheName: string;

  constructor(
    public readonly appConfig: AppConfigService,
    public readonly errorHandlerService: ErrorHandlerService,
    public readonly http: HttpClient,
    public readonly cacheService: CacheService,
  ) {}

  /**
   * Get all items for tracking table
   *
   * @returns {Observable<T>}
   * @memberof AbstractAdvancedTrackingTableService
   */
  getItems(paramsRequest: ParamRequest): Observable<ListResponse<T>> {
    const { params, cacheName } = this._setParams(paramsRequest);
    const url = [this.appConfig.getLoopbackApiUrl(), this.url, paramsRequest.serviceParams].filter(Boolean).join('/');
    const observable: Observable<ListResponse<T>> =
      paramsRequest.i18nFilters || paramsRequest.selfFilters
        ? this.http.post<ListResponse<T>>(url, this.paramFilter(paramsRequest), { params })
        : this.http.get<ListResponse<T>>(url, {
            params,
          });

    return observable.pipe(
      tap((result) => {
        this.cacheService.addToCache(cacheName, { nbItems: result.nbItems });
      }),
      catchError(this.errorHandlerService.handleError<any>(this.serviceName, 'get')),
    );
  }

  /**
   * Get all items for tracking table
   *
   * @returns {Observable<T>}
   * @memberof AbstractAdvancedTrackingTableService
   */
  countItems({ serviceParams, filter = '' }: { serviceParams?: string; filter?: string }): Observable<T> {
    let params = new HttpParams();
    let cacheName = `${this.cacheName}-count`;
    if (filter) {
      params = new HttpParams().set('filter', filter);
      cacheName = `${cacheName}-${filter}`;
    }
    const data = this.cacheService.getData(cacheName);
    if (data) {
      return of(data);
    }

    return this.http
      .get<T>([this.appConfig.getLoopbackApiUrl(), this.url, 'count', serviceParams].filter(Boolean).join('/'), {
        params,
      })
      .pipe(
        tap((result) => {
          this.cacheService.addToCache(cacheName, result);
        }),
        catchError(this.errorHandlerService.handleError<any>(this.serviceName, 'get')),
      );
  }

  /**
   * Get single item to update in tracking table
   *
   * @param {number} id
   * @returns {Observable<T>}p
   * @memberof AbstractAdvancedTrackingTableService
   */
  getSingleItem(id: number): Observable<T> {
    return this.http
      .get<T>(`${this.appConfig.getLoopbackApiUrl()}/${this.url}`, {
        params: new HttpParams().set('id', id.toString()),
      })
      .pipe(catchError(this.errorHandlerService.handleError<any>(this.serviceName, 'get')));
  }

  private _setParams(paramsRequest: ParamRequest): any {
    let params = new HttpParams()
      .set('offset', `${paramsRequest.pageNum * paramsRequest.nbResult}`)
      .set('limit', paramsRequest.nbResult.toString());
    if (paramsRequest.sortColumn) {
      params = params.set('sortColumn', paramsRequest.sortColumn).set('sortDirection', paramsRequest.sortDirection);
    }
    if (!isNil(paramsRequest.isTeamMember)) {
      params = params.set('isTeamMember', `${paramsRequest.isTeamMember ? 1 : 0}`);
    }

    if (!isNil(paramsRequest.isPartner)) {
      params = params.set('isPartner', `${paramsRequest.isPartner ? 1 : 0}`);
    }

    const obj = this._setCountParams(params, paramsRequest);
    const cacheName = obj.cacheName;
    params = obj.params;

    return { params, cacheName };
  }

  private _setCountParams(params: HttpParams, paramsRequest: ParamRequest): any {
    let cacheName = `${this.cacheName}-count`;
    if (paramsRequest.filter) {
      // eslint-disable-next-line no-param-reassign
      params = params.set('filter', paramsRequest.filter);
      cacheName = `${cacheName}-${paramsRequest.filter}`;
    }
    if (paramsRequest.statusFilter) {
      // eslint-disable-next-line no-param-reassign
      params = params.set('statusFilter', paramsRequest.statusFilter);
      cacheName = `${cacheName}-${paramsRequest.statusFilter}`;
    }
    if (paramsRequest.serviceParams) {
      cacheName = `${cacheName}-${paramsRequest.serviceParams}`;
    }
    if (!isNil(paramsRequest.isTeamMember)) {
      cacheName = `${cacheName}-${paramsRequest.isTeamMember}`;
    }

    if (!isNil(paramsRequest.isPartner)) {
      cacheName = `${cacheName}-${paramsRequest.isPartner}`;
    }

    if (paramsRequest.i18nFilters) {
      Object.keys(paramsRequest.i18nFilters).forEach(
        (key) => (cacheName = `${cacheName}-${key}-${paramsRequest.i18nFilters[key].join('-')}`),
      );
    }
    if (paramsRequest.selfFilters) {
      Object.keys(paramsRequest.selfFilters).forEach((key) => (cacheName = `${cacheName}-${key}-${paramsRequest.selfFilters[key]}`));
    }
    const data = this.cacheService.getData(cacheName);
    if (data) {
      // eslint-disable-next-line no-param-reassign
      params = params.set('count', data.nbItems);
    }

    return { cacheName, params };
  }

  paramFilter(paramsRequest: ParamRequest): any {
    const param: any = {};
    if (paramsRequest.i18nFilters) {
      param.i18nFilters = paramsRequest.i18nFilters;
    }
    if (paramsRequest.selfFilters) {
      param.selfFilters = paramsRequest.selfFilters;
    }

    return param;
  }
}
