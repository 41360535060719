<div class="app-tag" [ngClass]="tagClass[tagOption.type]">
  <mat-icon
    *ngIf="tagOption.prefixIcon"
    [svgIcon]="tagOption.isCustomPrefixIcon ? tagOption.prefixIcon : ''"
    class="app-tag-prefix-icon material-icons-outlined size-18"
    >{{ !tagOption.isCustomPrefixIcon ? tagOption.prefixIcon : '' }}
  </mat-icon>
  <span class="app-tag-text" [ngClass]="tagOption.textClass">{{ tagOption.text }}</span>
  <mat-icon
    *ngIf="tagOption.suffixIcon"
    [svgIcon]="tagOption.isCustomSuffixIcon ? tagOption.suffixIcon : ''"
    class="app-tag-suffix-icon material-icons-outlined size-18"
    >{{ !tagOption.isCustomSuffixIcon ? tagOption.suffixIcon : '' }}
  </mat-icon>
</div>
