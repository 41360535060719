import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { isNil } from 'lodash';

import { DataLayerApiService } from './data-layer-api.service';
import { ProgramsTrackingTableItem } from '../tracking-tables/models/ProgramsTrackingTableItem';
import { ProgramsTrackingTableServiceResponse } from '../tracking-tables/models/ProgramsTrackingTableServiceResponse';
import { ProgramParamRequest } from './models/program-param-request';
import { ProgramResponse } from '../utils/models/ProgramResponse';

@Injectable({ providedIn: 'root' })
export class ProgramApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Get programs by params
   *
   * @returns {Observable<ProgramsTrackingTableServiceResponse>}
   * @memberof DataLayerApiService
   */
  getPrograms(params: ProgramParamRequest): Observable<ProgramsTrackingTableServiceResponse> {
    const path = this.BASE_URL + 'Programs/tracking-table/programs';
    return this.getTableRessources(params, path);
  }

  /**
   * Get program by id
   *
   * @returns {Observable<ProgramsTrackingTableItem>}
   * @memberof DataLayerApiService
   */
  getProgramById(id: number): Observable<ProgramsTrackingTableItem> {
    const path = this.BASE_URL + 'Programs/tracking-table/programs';
    return this.getRessource(path, id);
  }

  /**
   * Get program information by id
   *
   * @returns {Observable<ProgramResponse>}
   * @memberof DataLayerApiService
   */
  getProgramInformationById(id: number): Observable<ProgramResponse> {
    const path = this.BASE_URL + 'Programs/' + id;
    return this.get(path);
  }

  protected getQueryParams(paramsRequest: ProgramParamRequest): HttpParams {
    let params = super.getQueryParams(paramsRequest);
    if (!isNil(paramsRequest.companyId)) {
      params = params.set('companyId', paramsRequest.companyId);
    }
    if (!isNil(paramsRequest.programStatusId)) {
      for (const programStatusId of paramsRequest.programStatusId) {
        params = params.append('programStatusId[]', programStatusId);
      }
    }
    return params;
  }

  protected getBody(paramsRequest: ProgramParamRequest): { i18nFilters?: string; selfFilters?: string } {
    const param = super.getBody(paramsRequest) as any;

    if (!isNil(paramsRequest.companyId)) {
      param.companyId = paramsRequest.companyId;
    }
    if (!isNil(paramsRequest.programStatusId)) {
      //param.programStatusId = paramsRequest.programStatusId.join(',');
      param.programStatusId = [];
      for (const programStatusId of paramsRequest.programStatusId) {
        param.programStatusId.push(programStatusId);
      }
    }
    return param;
  }
}
