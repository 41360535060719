import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DataLayerApiService } from './data-layer-api.service';

@Injectable({ providedIn: 'root' })
export class ReservationApiService extends DataLayerApiService {
  BASE_URL = '';

  getReservationsByDossierProspectId(dossierProspectId: number): Observable<any[]> {
    const path = `${this.BASE_URL}Reservations/dossier-prospect-get-reservations/${dossierProspectId}`;
    return this.get(path);
  }
}
