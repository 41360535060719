<ng-container *ngIf="programText">
  <ng-container *ngIf="program.companyDescription && program.companyDescription.length">
    <span class="app-program-promoter-infos-title">{{ i18nService._('Txt_page_program_promoter_infos_title') }}</span>
    <span class="app-program-promoter-infos-subtitle">{{ program?.companyDescriptionTitle }}</span>
    <div class="app-program-promoter-infos-container">
      <img class="max-w-full" src="{{ companyLogo }}" alt="{{ program?.logoCompanyTitle }}" />
      <div class="app-program-promoter-infos-content" [innerHTML]="program.companyDescription | safe: 'html'"></div>
    </div>
  </ng-container>
</ng-container>
