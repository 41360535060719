/* eslint-disable @typescript-eslint/no-explicit-any */
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { Observable, of, Subscription, switchMap } from 'rxjs';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';

import { I18nService } from '../../services/i18n.service';
import { ReferenceTablesService } from '../../services/reference-tables.service';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-chip-autocomplete-populated',
  templateUrl: './chip-autocomplete-populated.component.html',
  styleUrls: ['./chip-autocomplete-populated.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatChipsModule, NgFor, NgIf, FormsModule, MatAutocompleteModule, ReactiveFormsModule, MatOptionModule],
})
export class ChipAutocompletePopulatedComponent implements OnDestroy, OnInit {
  /**
   * Input selectable, to check if chips are selectable
   * @type {string}
   * @memberof ChipAutocompletePopulatedComponent
   */
  @Input() selectable = false;

  /**
   * Input removable, to check if chips are removable
   * @type {string}
   * @memberof ChipAutocompletePopulatedComponent
   */

  @Input() id: string;
  @Input() removable = true;
  @Input() min = 0;
  @Input() max: number;
  @Input() required: boolean;
  @Input() parentForm: UntypedFormGroup;

  /**
   * Map of selected items (key => label, value => any)
   * @type {Map<string,any>}
   * @memberof ChipAutocompletePopulatedComponent
   */
  mapSelectedItems: Map<string, any> = new Map();
  itemsLabelKeyList = Array.from(this.mapSelectedItems.keys());

  /**
   * Input selectedItems
   * @type {Array<any>}
   * @memberof ChipAutocompletePopulatedComponent
   */
  @Input() selectedItems: Array<any>;
  @Input() label = 'label';

  /**
   * Input selectedItems, event emit selected items
   * @type {string}
   * @memberof ChipAutocompletePopulatedComponent
   */
  @Output() readonly selectedItemsChanged: EventEmitter<Array<any>> = new EventEmitter();

  public mapListFilteredItems: Map<string, any> = new Map();
  public filteredItems: Array<string>;

  @Input() chipCtrl: UntypedFormControl;

  public timeoutWork;

  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  /**
   * Input referenceTable to get tables informations
   * @type {string}
   * @memberof ChipAutocompletePopulatedComponent
   */
  @Input() referenceTable: string;

  /**
   * Get if reference table should be translated or not
   *
   * @type {boolean}
   * @memberof ChipAutocompletePopulatedComponent
   */
  @Input() notTranslated: boolean;

  /**
   * Placeholder input
   *
   * @type {string}
   * @memberof ChipAutocompletePopulatedComponent
   */
  @Input() placeholder: string;

  readonly separatorKeysCodes: Array<number> = [ENTER, COMMA];

  /**
   * chipCtrlValueChangesSubscription attribute
   *
   * @type {Subscription}
   * @memberof ChipAutocompletePopulatedComponent
   */
  private chipCtrlValueChangesSubscription: Subscription;

  constructor(
    public referenceTablesService: ReferenceTablesService,
    public snackbarService: SnackbarService,
    public i18nService: I18nService,
  ) {}

  ngOnInit(): void {
    // Get reference Table info
    if (this.notTranslated && this.referenceTable) {
      this.referenceTablesService.getTableReferenceInfo(this.referenceTable).subscribe(
        (itemsFound) => {
          this.formatResponseList(itemsFound);
        },
        () => {
          this.snackbarService.sendErrorOccured();
        },
      );
    } else if (!this.notTranslated && this.referenceTable) {
      this.referenceTablesService.getTableReferenceInfoTranslated(this.referenceTable).subscribe(
        (itemsFound) => {
          this.formatResponseList(itemsFound);
        },
        () => {
          this.snackbarService.sendErrorOccured();
        },
      );
    }

    const validators: Array<ValidatorFn> = [Validators.minLength(this.min)];
    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.max) {
      validators.push(Validators.maxLength(this.max));
    }
    this.chipCtrl = new UntypedFormControl(undefined, validators);
    this.chipCtrlValueChangesSubscription = this.chipCtrl.valueChanges
      .pipe(switchMap((value) => this._filter(value)))
      .subscribe((items) => (this.filteredItems = items));
    this.parentForm.addControl(this.id, this.chipCtrl);
  }

  /**
   * format ReferenceTableResponse to a map list of items {mapListFilteredItems}
   *
   * @type {void}
   * @memberof ChipAutocompletePopulatedComponent
   */
  public formatResponseList(referenceTableResponse): void {
    referenceTableResponse.forEach((res) => {
      if (res[this.label]) {
        this.mapListFilteredItems.set(res[this.label], res);
      }
    });
    this.refreshChipsItemList();
  }

  public mapSelectedItemsToSelectedItems(): void {
    this.selectedItems.splice(0);
    this.mapSelectedItems.forEach((value) => {
      this.selectedItems.push(value);
    });
  }

  public add(event: MatChipInputEvent): void {
    // Add item only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;
      const existOnMapListFilteredItems = this.mapListFilteredItems.has(value);
      const existOnMapSelectedItems = this.mapSelectedItems.has(value);
      // Add our item
      if (value && value.trim() && existOnMapListFilteredItems && !existOnMapSelectedItems) {
        this.mapSelectedItems.set(value, this.mapListFilteredItems.get(value));
        this.mapSelectedItemsToSelectedItems();
        this.selectedItemsChanged.emit(this.selectedItems);
      }
      // Reset the input value
      if (input) {
        input.value = '';
      }
      this.chipCtrl.setValue(undefined);
    }
    this.refreshChipsItemList();
  }

  public remove(item): void {
    const value = item?.value ? item.value : item;
    this.mapSelectedItems.delete(value);
    this.mapSelectedItemsToSelectedItems();
    this.selectedItemsChanged.emit(this.selectedItems);
    this.refreshChipsItemList();
  }

  public refreshChipsItemList(): void {
    this.itemsLabelKeyList = Array.from(this.mapSelectedItems.keys());
  }

  public selected(event: any): void {
    const value = event?.option?.value ? event.option.value : event.value;
    if (this.max === undefined || this.mapSelectedItems.size < this.max) {
      const existOnMapListFilteredItems = this.mapListFilteredItems.has(value);
      const existOnMapSelectedItems = this.mapSelectedItems.has(value);
      if (existOnMapListFilteredItems && !existOnMapSelectedItems) {
        this.mapSelectedItems.set(value, this.mapListFilteredItems.get(value));
        this.mapSelectedItemsToSelectedItems();
        this.selectedItemsChanged.emit(this.selectedItems);
        this.refreshChipsItemList();
        this.chipCtrl.setErrors(undefined);
      }
    }
    if (this.chipInput) {
      this.chipInput.nativeElement.value = '';
    }
    this.chipCtrl.setValue(undefined);
  }

  public resetSeletedValues(): void {
    this.itemsLabelKeyList = [];
    this.selectedItems = [];
    this.mapSelectedItems.clear();
    this.chipCtrl.setValue(undefined);
  }

  public getFilteredItems(): Array<string> {
    return this.filteredItems;
  }

  public setFilteredItems(array: Array<string>): void {
    this.filteredItems = array;
  }

  public getMapListFilteredItems(): Map<string, any> {
    return this.mapListFilteredItems;
  }

  public _filter(value: string): Observable<Array<string>> {
    clearTimeout(this.timeoutWork);
    this.timeoutWork = setTimeout((): void => {
      if (value && typeof value === 'string' && value.trim().length >= 3) {
        const mapListFiltered = this.getMapListFilteredItems().keys();
        this.setFilteredItems(
          Array.from(mapListFiltered).filter((item) => item.toString().toLowerCase().indexOf(value.trim().toLowerCase()) >= 0),
        );
      }
    }, 100);

    return of([]);
  }

  /**
   * ngOnDestroy method
   *
   * @memberof StepFourFormProgramComponent
   */
  ngOnDestroy(): void {
    if (this.chipCtrlValueChangesSubscription) {
      this.chipCtrlValueChangesSubscription.unsubscribe();
    }
  }
}
