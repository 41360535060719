<div class="flex flex-col w-full">
  <!-- Financial Strategies table -->
  <app-program-financial-stategy-tracking-table [programId]="programId" class="mt-5 pr-4 mb-4 ml-4">
  </app-program-financial-stategy-tracking-table>

  <form [formGroup]="financialStrategyForm" (ngSubmit)="onSubmit()" class="flex flex-col m-4">
    <!-- Responsible of the strategy -->
    <div class="grid grid-cols-2 gap-16 mt-3 mb-2">
      <app-dropdown-list-populated
        #responsibleDropdown
        [parentForm]="financialStrategyForm"
        [searchable]="true"
        [items]="rcvItems"
        [placeholder]="i18nService._('Title_FinancialStrategy_Responsible')"
        [fieldName]="'responsible'"
        [isSubmit]="isSubmit"
        [required]="true"
        [readonly]="readonly"
        maxlength="255"
      >
      </app-dropdown-list-populated>
    </div>

    <!-- Honorary capping -->
    <div class="text-base mt-4 mb-2 font-semibold">
      {{ i18nService._('Title_FinancialStrategy_FeeCap') }}
    </div>

    <div class="grid grid-cols-2 gap-16 mb-2">
      <mat-form-field>
        <mat-label>{{ i18nService._('Title_FinancialStrategy_FeesAmount') }}</mat-label>
        <input
          appDecimalMask
          [maxNumberDecimals]="0"
          [maxNumberDigitsBeforeSeparator]="8"
          suffix=" €"
          class="text-right"
          name="feesAmount"
          [formControl]="feesAmountControl"
          matInput
          data-cy="financialStrategyFeesAmount"
          (keyup)="feesAmountModified()"
        />
        <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
      </mat-form-field>
    </div>

    <ng-container *ngIf="feesAmountControl && feesAmountControl.value">
      <div class="grid grid-cols-2 gap-16 mt-3 mb-2">
        <mat-form-field class="col-span-1">
          <mat-label>{{ i18nService._('Title_FinancialStrategy_CappingThreshold') }} *</mat-label>
          <input
            appDecimalMask
            [maxNumberDecimals]="0"
            [maxNumberDigitsBeforeSeparator]="3"
            class="text-right"
            data-cy="financialStrategyCappingThreshold"
            name="cappingThreshold"
            [formControl]="cappingThresholdControl"
            matInput
          />
          <span class="text-black px-2" matSuffix>%</span>
          <span
            class="icon-info txt-gray pr-2"
            matSuffix
            [matTooltip]="i18nService._('Txt_FinancialStrategy_CappingThresholdTooltip')"
          ></span>
          <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
        </mat-form-field>

        <mat-form-field class="col-span-1">
          <mat-label>{{ i18nService._('Title_FinancialStrategy_NotificationThreshold') }} *</mat-label>
          <input
            appDecimalMask
            [maxNumberDecimals]="0"
            [maxNumberDigitsBeforeSeparator]="2"
            class="text-right"
            name="notificationThreshold"
            [formControl]="notificationThresholdControl"
            matInput
          />
          <span class="text-black px-2" matSuffix>%</span>
          <span
            class="icon-info txt-gray pr-2"
            matSuffix
            matTooltip="{{ i18nService._('Txt_FinancialStrategy_NotificationThresholdTooltip') }}"
          ></span>
          <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
        </mat-form-field>
      </div>
    </ng-container>

    <!-- Lot typology level -->
    <div class="text-base mt-4 mb-2 font-semibold">
      {{ i18nService._('Title_FinancialStrategy_LotTypologyTable') }}
    </div>

    <div *ngFor="let entry of lotsByTopologyTable">
      <app-financial-strategy-nb-lot-by-typology
        [placeholder]="entry.placeholder"
        [fieldName]="entry.fieldName"
        [nbLotMax]="entry.nbLotMax"
        [nbLot]="entry.nbLot"
        [parentForm]="financialStrategyForm"
        [readonly]="readonly"
      >
      </app-financial-strategy-nb-lot-by-typology>
    </div>

    <!-- Lot typology level -->
    <div class="text-base mt-4 mb-2 font-semibold">
      {{ i18nService._('Title_FinancialStrategy_PercentageOfTransformation') }}
    </div>

    <div class="grid grid-cols-2 gap-16 my-2">
      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Title_FinancialStrategy_Reservation') }}</mat-label>
        <input name="reservation" [formControl]="reservationControl" matInput />
        <span class="txt-gray px-2" matSuffix data-cy="financialStrategyReservation">%</span>
      </mat-form-field>

      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Title_FinancialStrategy_Prereservation') }}</mat-label>
        <input name="prereservation" [formControl]="prereservationControl" matInput />
        <span class="txt-gray px-2" matSuffix>%</span>
      </mat-form-field>
    </div>

    <div class="flex flex-row mb-6 ml-5 pl-2">
      <button
        mat-flat-button
        [disabled]="readonly"
        class="mr-3 mb-3"
        data-cy="financialStrategyModifyButton"
        color="primary"
        type="submit"
        [ngStyle]="readonly && { cursor: 'not-allowed' }"
      >
        {{ i18nService._(existingFinancialStrategyId ? 'Txt_FinancialStrategy_ModifyButton' : 'Txt_FinancialStrategy_SubmitButton') }}
      </button>
    </div>
  </form>
</div>
