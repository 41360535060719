<p-multiSelect
  class="p-multiselect"
  [options]="!isObservable(this.props.options) ? props.options : (props.options | formlySelectOptions: field | async)"
  [defaultLabel]=""
  [disabled]="props.disabled"
  [class.disabled]="props.disabled"
  [readonly]="props.readonly"
  [formControl]="$any(field.formControl)"
  [optionLabel]="props.optionLabel"
  [optionValue]="props.optionValue"
  [dropdownIcon]="props.dropdownIcon"
  [showHeader]="props.showHeader ?? true"
  [showClear]="props.showClear ?? true"
  [maxSelectedLabels]="props.maxSelectedLabels ?? 100"
>
</p-multiSelect>
