<div class="program-navigation-header">
  <app-button-back [goBack]="goBack" [backLabel]="backLabel"></app-button-back>
  <app-comparator-preview-button
    *ngIf="showComparator"
    [label]="'Txt_button_comparator_open_container_label'"
  ></app-comparator-preview-button>
  <div>
    <app-management-lot-comparator
      *ngIf="!showComparator"
      class="mr-4"
      [idLot]="lot?.lotId"
      [viewText]="true"
      (comparatorChangeEvent)="comparatorChange($event)"
    >
    </app-management-lot-comparator>

    <app-fees *ngIf="lot && lot.fees" [fees]="lot.fees / 100" [hasFeesRange]="false"></app-fees>
  </div>
</div>
