<div class="close-lot-panel-icon my-2 cursor-pointer" (click)="closePanel.emit()">
  <span class="icon-cancel text-xs border-orange-500 border txt-orange font-bold rounded-full p-2"></span>
</div>
<div *ngIf="lot && !loading" class="grid grid-cols-2 md-down-block justify-around">
  <div class="col-span-2 lg:col-span-1 flex-col pr-2 md:pr-3">
    <div class="pr-3 my-3 justify-between items-center">
      <div class="lot-program-title pl-4">
        <h2 class="m-0">{{ lot.program.programName }}</h2>
        <h5 class="m-0 font-semibold">
          {{ lot.program.address }},
          <span class="uppercase">
            {{ lot.program.city }}{{ getPostalCode() }} -
            {{ i18nService._(lot.program.country.label) }}
          </span>
        </h5>
      </div>
      <div *ngIf="companyLogo" class="w-1/3 text-right">
        <img [alt]="companyLogo.title" [src]="companyLogoImage" class="py-1 w-auto max-h-10" />
      </div>
    </div>

    <div class="relative mx-auto">
      <div *ngIf="perspective" class="mb-4">
        <img [alt]="perspective.title" [src]="perspectiveImage" height="350" class="w-full max-w-xl max-h-88" />
      </div>
    </div>

    <app-documents-box
      *ngIf="documents && lot.program.programName && lot.program.programType.label"
      [documents]="documents"
      [programName]="lot.program.programName"
      [programTypeLabel]="lot.programType.label"
      [programId]="lot.programId"
      [lotId]="lot.id"
      [title]="i18nService._('Title_Documents_Lot_Details')"
      class="mb-3"
    >
    </app-documents-box>

    <ng-container *ngIf="specialOffers && specialOffers.length">
      <mat-list-item class="shadow-md p-4 my-2 text-sm font-bold">
        <div class="grid grid-cols-2 my-2">
          <div class="w-1/5 mr-2 my-auto py-1 px-2 text-white inline-grid grid-cols-2 text-xxs bg-violet">
            <span class="mx-auto">
              {{ i18nService._('Txt_Table_SpecialOffer') }}
            </span>
          </div>

          <a class="w-4/5 ml-2 mb-2 my-auto txt-violet" (click)="openSpecialOffer(specialOffers[0])">
            {{ specialOffers[0].specialOfferText.title }}
          </a>
        </div>
      </mat-list-item>
    </ng-container>
  </div>

  <div class="col-span-2 lg:col-span-1 flex-col mt-3 md:mt-0">
    <mat-list class="shadow-md mb-2 mr-2 xl-down-block lot_info-list py-3">
      <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item max-w-" [ngClass]="{ ' ': itemsCounter > 15 }">
        <mat-list-item class="h-full w-full px-2">
          <p class="font-bold text-sm">
            {{ i18nService._('Txt_Page_Lot_Details_LotType_LotNumber_DeliveryDate') }}
          </p>
        </mat-list-item>
        <mat-list-item class="w-full text-right text-xs px-2">
          <p class="whitespace-normal" mat-line>
            {{ getTypeNumberAndDeliveryDate(lot) }}
          </p>
        </mat-list-item>
      </div>

      <mat-divider class="mx-5 px-2"></mat-divider>

      <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
        <mat-list-item class="h-full w-full px-2">
          <p class="font-bold text-sm">{{ i18nService._('Txt_Page_Lot_Details_Rooms_LivingSpace') }}</p>
        </mat-list-item>
        <mat-list-item class="w-full text-right text-xs px-2">
          <p class="whitespace-normal" mat-line>
            {{ getRoomsAndLivingSpace(lot) }}
          </p>
        </mat-list-item>
      </div>

      <ng-container *ngIf="lot.lotAreas">
        <ng-container *ngFor="let lotArea of lot.lotAreas">
          <mat-divider class="mx-5 px-2"></mat-divider>

          <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
            <mat-list-item class="h-full w-full px-2">
              <p class="font-bold text-sm">{{ i18nService._(lotArea.areaType.label) }}</p>
            </mat-list-item>
            <mat-list-item class="w-full text-right text-xs px-2">
              <p class="whitespace-normal" mat-line>
                {{ basicFormatsService.formatArea(lotArea.area) }}
              </p>
            </mat-list-item>
          </div>
        </ng-container>
      </ng-container>

      <mat-divider class="mx-5 px-2"></mat-divider>

      <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
        <mat-list-item class="h-full w-full px-2">
          <p class="font-bold text-sm">{{ i18nService._('Txt_Page_Lot_Details_Floor_Orientation') }}</p>
        </mat-list-item>
        <mat-list-item class="w-full text-right text-xs px-2">
          <p class="whitespace-normal" mat-line>
            {{ getFloorAndOrientation(lot) }}
          </p>
        </mat-list-item>
      </div>

      <!-- Secondary lots details : Annexe -->
      <ng-container *ngIf="lot.secondaryLots && lot.secondaryLots.length">
        <mat-divider class="mx-5 px-2"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">{{ i18nService._('Txt_Page_Lot_Details_SecondaryLots') }}</p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <ng-container *ngFor="let secondaryLot of lot.secondaryLots" mat-line>
              <p
                *ngIf="secondaryLot && secondaryLot.secondaryLotType && secondaryLot.secondaryLotType.label"
                class="whitespace-nowrap truncate mb-1"
                title="{{
                  i18nService._('Txt_Page_Program_SecondaryLotDetails', [
                    i18nService._(secondaryLot.secondaryLotType.label),
                    secondaryLot.numLot
                  ])
                }}"
              >
                {{
                  i18nService._('Txt_Page_Program_SecondaryLotDetails', [
                    i18nService._(secondaryLot.secondaryLotType.label),
                    secondaryLot.numLot ? secondaryLot.numLot : ''
                  ])
                }}
              </p>
            </ng-container>
          </mat-list-item>
        </div>
      </ng-container>
      <!-- Secondary lots details: Annexe -->

      <mat-divider class="mx-5 px-2"></mat-divider>

      <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
        <mat-list-item class="h-full w-full px-2">
          <p class="font-bold text-sm">{{ i18nService._('Txt_Page_Lot_Details_Price') }}</p>
        </mat-list-item>
        <mat-list-item class="w-full text-right text-xs px-2">
          <p class="p-2 whitespace-normal" mat-line>
            {{ lot.price ? basicFormatsService.formatCurrency(lot.price) : '-' }}
          </p>
        </mat-list-item>
      </div>

      <ng-container *ngIf="lot.reducedTotalSellingPriceIT">
        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">
              {{ i18nService._('Txt_Page_Lot_Details_ReducedTotalSellingPriceIT') }}
            </p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{ basicFormatsService.formatCurrency(lot.reducedTotalSellingPriceIT) }}
            </p>
          </mat-list-item>
        </div>
      </ng-container>
      <!-- Fees: Honoraires: RG_1748.5  -->
      <ng-container *ngIf="canUserSeeContractorFees && !hideFees">
        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">{{ i18nService._('Txt_Page_Lot_Details_Fees') }}</p>
            <span *ngIf="isValo" class="ml-2 font-bold text-xs cursor-pointer" (click)="openFeeHistory()">voir l'historique</span>
          </mat-list-item>
          <mat-list-item class="text-right text-xs px-2">
            <div mat-line class="p-2 fees-item">
              <app-tags [tagOption]="getTagOption(lot.contractorFees)"></app-tags>
            </div>
          </mat-list-item>
        </div>
      </ng-container>
      <ng-container *ngIf="lot.fees && !hideFees">
        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="px-2">
            <p class="font-bold text-sm inline-block">{{ i18nService._('Txt_Page_Lot_Details_Fees') }}</p>
            <span *ngIf="isValo" class="ml-2 font-bold text-xs underline cursor-pointer" (click)="openFeeHistory()">
              {{ i18nService._('TXT_lot_fees_history_link_title') }}
            </span>
          </mat-list-item>
          <mat-list-item class="text-right text-xs px-2">
            <div mat-line class="fees-item">
              <a target="_blank" class="flex items-center" href="{{ feesInformationExternalLink }}">
                <span class="mr-1">{{ i18nService._('Txt_Page_Lot_Details_Fees_info') }}</span>
                <mat-icon
                  [svgIcon]="'info_valo'"
                  matTooltip="{{ i18nService._('Txt_Tooltip_feesInformation') }}"
                  class="w-3.5 text-xs mr-2"
                ></mat-icon>
              </a>
              <app-tags [tagOption]="getTagOption(lot.fees)"></app-tags>
            </div>
          </mat-list-item>
        </div>
      </ng-container>
      <!-- Fees: Honoraires -->

      <mat-divider class="mx-5 px-2 w-35"></mat-divider>

      <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
        <mat-list-item class="col-span-1 px-2">
          <p class="font-bold text-sm">{{ i18nService._('Txt_Page_Lot_Details_TaxationList') }}</p>
        </mat-list-item>
        <mat-list-item class="col-span-1 text-right text-xs px-2">
          <p class="p-2 whitespace-normal" mat-line>{{ taxationList }}</p>
        </mat-list-item>
      </div>

      <ng-container *ngIf="pinelRentingPrice && pinelProfitability">
        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">{{ i18nService._('Txt_Page_Lot_Details_PinelRentingPrice') }}</p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{ basicFormatsService.formatCurrency(pinelRentingPrice, appConfigService.getDefaultCurrency(), 1, 2, 2) }}
            </p>
          </mat-list-item>
        </div>

        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">
              {{ i18nService._('Txt_Page_Lot_Details_PinelProfitability') }}
            </p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{ basicFormatsService.formatPercentage(pinelProfitability / 100) }}
            </p>
          </mat-list-item>
        </div>
      </ng-container>

      <ng-container *ngIf="plsRentingPrice && plsProfitability">
        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">{{ i18nService._('Txt_Page_Lot_Details_PlsRentingPrice') }}</p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{ basicFormatsService.formatCurrency(plsRentingPrice, undefined, 1, 2, 2) }}
            </p>
          </mat-list-item>
        </div>

        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">{{ i18nService._('Txt_Page_Lot_Details_PlsProfitability') }}</p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{ basicFormatsService.formatPercentage(plsProfitability / 100) }}
            </p>
          </mat-list-item>
        </div>
      </ng-container>

      <ng-container *ngIf="isLmpLmnpTaxation">
        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">
              {{ i18nService._('Txt_Page_Lot_Details_GuaranteedMonthlyRentingPriceIT') }}
            </p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{
                lot.guaranteedMonthlyRentingPriceIT
                  ? basicFormatsService.formatCurrency(lot.guaranteedMonthlyRentingPriceIT, undefined, 1, 0, 2)
                  : '-'
              }}
            </p>
          </mat-list-item>
        </div>

        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">{{ i18nService._('Txt_Page_Lot_Details_EstatePriceET') }}</p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{ lot.estatePriceET ? basicFormatsService.formatCurrency(lot.estatePriceET) : '-' }}
            </p>
          </mat-list-item>
        </div>

        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">{{ i18nService._('Txt_Page_Lot_Details_HousingPriceET') }}</p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{ lot.housingPriceET ? basicFormatsService.formatCurrency(lot.housingPriceET) : '-' }}
            </p>
          </mat-list-item>
        </div>

        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">
              {{ i18nService._('Txt_Page_Lot_Details_GlobalSellingPriceET') }}
            </p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{ lot.globalSellingPriceET ? basicFormatsService.formatCurrency(lot.globalSellingPriceET) : '-' }}
            </p>
          </mat-list-item>
        </div>

        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">{{ i18nService._('Txt_Page_Lot_Details_TangiblePriceET') }}</p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{ lot.tangiblePriceET ? basicFormatsService.formatCurrency(lot.tangiblePriceET) : '-' }}
            </p>
          </mat-list-item>
        </div>

        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">{{ i18nService._('Txt_Page_Lot_Details_SellingFees') }}</p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{ lot.sellingFees || '-' }}
            </p>
          </mat-list-item>
        </div>

        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">{{ i18nService._('Txt_Page_Lot_Details_LoanFees') }}</p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{ lot.loanFees || '-' }}
            </p>
          </mat-list-item>
        </div>

        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">{{ i18nService._('Txt_Page_Lot_Details_AttorneyFees') }}</p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{ lot.attorneyFees || '-' }}
            </p>
          </mat-list-item>
        </div>

        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">
              {{ i18nService._('Txt_Page_Lot_Details_MonthlyRentingPriceET') }}
            </p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{ lot.monthlyRentingPriceET ? basicFormatsService.formatCurrency(lot.monthlyRentingPriceET, undefined, 1, 0, 2) : '-' }}
            </p>
          </mat-list-item>
        </div>

        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">
              {{ i18nService._('Txt_Page_Lot_Details_MonthlyRentalLotPriceIT') }}
            </p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{ lot.monthlyRentalLotPriceIT ? basicFormatsService.formatCurrency(lot.monthlyRentalLotPriceIT) : '-' }}
            </p>
          </mat-list-item>
        </div>

        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">
              {{ i18nService._('Txt_Page_Lot_Details_MonthlyRentalSecondaryLotPriceIT') }}
            </p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{ lot.monthlyRentalSecondaryLotPriceIT ? basicFormatsService.formatCurrency(lot.monthlyRentalSecondaryLotPriceIT) : '-' }}
            </p>
          </mat-list-item>
        </div>

        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">
              {{ i18nService._('Txt_Page_Lot_Details_LmnpLmpProfitability') }}
            </p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{ lmnpLmpProfitability ? basicFormatsService.formatPercentage(lmnpLmpProfitability / 100) : '-' }}
            </p>
          </mat-list-item>
        </div>
      </ng-container>

      <ng-container *ngIf="isOtherTaxation">
        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">{{ i18nService._('Txt_Page_Lot_Details_EstimatedWorkPrice') }}</p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{ lot.estimatedWorkPrice ? basicFormatsService.formatCurrency(lot.estimatedWorkPrice) : '-' }}
            </p>
          </mat-list-item>
        </div>

        <mat-divider class="mx-5 px-2 w-35"></mat-divider>

        <div class="grid grid-cols-2 mx-2 py-2 border-0 lot_info-list-item" [ngClass]="{ ' ': itemsCounter > 15 }">
          <mat-list-item class="h-full w-full px-2">
            <p class="font-bold text-sm">{{ i18nService._('Txt_Page_Lot_Details_EstimatedLandPrice') }}</p>
          </mat-list-item>
          <mat-list-item class="w-full text-right text-xs px-2">
            <p class="p-2 whitespace-normal" mat-line>
              {{ lot.estimatedLandPrice ? basicFormatsService.formatCurrency(lot.estimatedLandPrice) : '-' }}
            </p>
          </mat-list-item>
        </div>
      </ng-container>
    </mat-list>
  </div>
</div>
<div *ngIf="loading" class="md-down-block justify-around spinner">
  <mat-spinner [color]="'primary'" [diameter]="60" [strokeWidth]="6" class="mx-auto my-auto"></mat-spinner>
</div>
