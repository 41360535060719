<app-lots-program-tracking-table
  #lotsTableProgram
  [isImported]="isImported"
  [isValo]="isValo"
  (isDeleted)="onLotDeletion($event)"
  (selectedLotForEdit)="onSelectedLotForEdit($event)"
>
</app-lots-program-tracking-table>

<ng-container *ngIf="lotCreation || isEditLot">
  <form class="flex flex-col" [formGroup]="lotForm">
    <div class="text-base mb-4 font-bold">
      {{ i18nService._('Txt_Page_Form_RegisterProgram_LotDescriptionSection') }}
    </div>

    <!-- Lot main informations -->
    <div class="grid grid-cols-2 gap-16 mb-2">
      <!-- Input Lot Reference -->
      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_Placeholder_LotRef') }}</mat-label>
        <input type="text" name="lotRef" [formControl]="lotRefControl" matInput data-cy="lotRef" maxlength="255" required />
        <span class="icon-info txt-gray pr-2" matSuffix matTooltip="{{ i18nService._('Txt_Tooltip_LotRef') }}"></span>
        <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
      </mat-form-field>
      <!-- Input Lot Number -->
      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_Placeholder_LotNumber') }}</mat-label>
        <input type="text" name="lotNumber" [formControl]="lotNumberControl" matInput data-cy="lotNumber" maxlength="20" required />
        <span class="icon-info txt-gray pr-2" matSuffix matTooltip="{{ i18nService._('Txt_Tooltip_LotNumber') }}"></span>
        <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
      </mat-form-field>
    </div>

    <!-- Select Lot Type -->
    <div class="grid grid-cols-2 gap-16 mt-2 mb-1 pt-1">
      <app-dropdown-list-populated
        [parentForm]="lotForm"
        [referenceTable]="lotTypeReferenceTable"
        [searchable]="false"
        [placeholder]="i18nService._('Txt_Placeholder_LotType')"
        data-cy="lotType"
        [fieldName]="'programType'"
        [isSubmit]="isSubmitLotCreation || isSubmitLotModification"
        [required]="true"
        [dataToInit]="editableLot"
        class="col-span-1 mb-2"
      >
      </app-dropdown-list-populated>
    </div>

    <!-- Select Lot Orientation -->
    <div class="grid grid-cols-2 gap-16 mt-2 mb-1 pt-1">
      <app-dropdown-list-populated
        class="col-span-1"
        [parentForm]="lotForm"
        [referenceTable]="'LotOrientations'"
        [searchable]="false"
        [placeholder]="i18nService._('Txt_Placeholder_LotOrientation')"
        data-cy="lotOrientation"
        [fieldName]="'lotOrientation'"
        [isSubmit]="isSubmitLotCreation || isSubmitLotModification"
        [required]="true"
        [dataToInit]="editableLot"
        class="col-span-1 mb-2"
      >
      </app-dropdown-list-populated>
    </div>

    <!-- Input Lot Living Space -->
    <div class="grid grid-cols-2 gap-16 mt-2 mb-2">
      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_Placeholder_LivingSpace') }}</mat-label>
        <input
          appDecimalMask
          class="text-right"
          name="livingSpace"
          [formControl]="livingSpaceControl"
          matInput
          data-cy="livingSpace"
          required
        />
        <span class="text-black px-2" matSuffix>m²</span>
        <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
      </mat-form-field>
    </div>

    <!-- Input Rooms Number -->
    <div class="grid grid-cols-2 gap-16 mb-3">
      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_Placeholder_Rooms') }}</mat-label>
        <input type="number" name="rooms" [formControl]="roomsControl" maxlength="2" matInput data-cy="rooms" required />
        <mat-error>
          <span *ngIf="roomsControl.errors && roomsControl.errors.required">
            {{ i18nService._('Error_Field_Required') }}
          </span>
          <span *ngIf="roomsControl.errors && roomsControl.errors.min">
            {{ i18nService._('Error_Field_MinValueRooms', [roomsControl.errors.min.min]) }}
          </span>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Input Floors Number -->
    <div class="grid grid-cols-2 gap-16 mb-3">
      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_Placeholder_Floor') }}</mat-label>
        <input type="text" name="floor" [formControl]="floorControl" maxlength="3" matInput data-cy="floor" required />
        <span class="icon-info txt-gray pr-2" matSuffix matTooltip="{{ i18nService._('Txt_Tooltip_Floor') }}"></span>
        <!-- Floors Requied error -->
        <mat-error *ngIf="floorControl.hasError('required')">
          {{ i18nService._('Error_Field_Required') }}
        </mat-error>
        <!-- Floors incorrect pattern error -->
        <mat-error *ngIf="floorControl.hasError('pattern')">
          {{ i18nService._('Error_Field_IncorrectFloorValue') }}
        </mat-error>
      </mat-form-field>
    </div>

    <app-area-input-form-program [parentForm]="lotForm" [dataToInit]="lotAreas" [isSubmit]="isSubmitLotCreation || isSubmitLotModification">
    </app-area-input-form-program>

    <!-- Datepicker Lot Delivery Date -->
    <div class="grid grid-cols-2 gap-16 mb-2">
      <app-input-date
        class="col-span-1"
        fieldName="deliveryDate"
        [placeholder]="i18nService._('Txt_Placeholder_DeliveryDate')"
        [readonly]="true"
        [required]="true"
        [parentForm]="lotForm"
      >
      </app-input-date>
    </div>

    <!-- Checkbox Valorissimo Exclusivity -->
    <div class="grid grid-cols-2 gap-16 mb-3">
      <mat-checkbox
        name="isValorissimoExclusivity"
        data-cy="isValorissimoExclusivity"
        [formControl]="isValorissimoExclusivityControl"
        color="primary"
        class="col-span-1"
      >
        {{ i18nService._('Txt_Checkbox_IsValorissimoExclusivity') }}
        <span class="icon-info txt-gray cursor-pointer" [matTooltip]="i18nService._('Txt_Tooltip_IsValorissimoExclusivity')"> </span>
      </mat-checkbox>
    </div>

    <!-- End of Lot main informations -->

    <hr class="w-5 my-12" />

    <div class="text-base mb-4 font-bold">
      {{ i18nService._('Txt_Page_Form_RegisterProgram_LotTaxationSection') }}
    </div>

    <!-- Lot taxation informations -->
    <div class="text-base my-2 font-semibold">{{ i18nService._('Txt_Page_Form_RegisterProgram_LotTaxation') }} *</div>
    <div class="grid grid-cols-2 gap-16 mb-4">
      <app-taxation-checkboxes-lot
        [parentForm]="lotForm"
        [dataToInit]="editableLot"
        [isSubmit]="isSubmitLotCreation || isSubmitLotModification"
      >
      </app-taxation-checkboxes-lot>
    </div>

    <!-- File Drop Lot Plan -->
    <div class="grid grid-cols-2 gap-16 mt-3">
      <app-file-drop
        [fieldNameKey]="'lotPlan'"
        [attachmentName]="i18nService._('Txt_Placeholder_LotPlan')"
        [required]="true"
        [includingForm]="documentControl"
        [isSubmit]="isSubmitLotCreation || isSubmitLotModification"
        [initialValue]="initFile['lotPlan']"
        class="app-file-drop w-full"
      >
      </app-file-drop>
    </div>

    <hr class="w-5 my-12" />

    <!-- Secondary lot -->
    <div class="text-base mb-4 font-bold">
      {{ i18nService._('Txt_Page_Form_RegisterProgram_SecondaryLot') }}
      <span class="icon-info txt-gray cursor-pointer" [matTooltip]="i18nService._('Txt_Page_Form_RegisterProgram_SecondaryLotTooltip')">
      </span>
    </div>

    <!-- Included Annexes -->
    <div class="text-base font-semibold">{{ i18nService._('Txt_Page_Form_RegisterProgram_IncludedAnnexes') }} *</div>
    <div class="grid grid-cols-2 gap-16 mb-2 mt-2 min-h-80px">
      <mat-radio-group class="col-span-1 flex flex-col" [formControl]="secondaryLotPriceIncludedControl">
        <div class="grid grid-cols-2">
          <mat-radio-button [value]="true" color="primary">
            {{ i18nService._('Txt_RadioButton_Yes') }}
          </mat-radio-button>
          <mat-radio-button [value]="false" color="primary">
            {{ i18nService._('Txt_RadioButton_No') }}
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>

    <app-secondary-lot [lotId]="lotId" [programId]="programId" [isEditLot]="isEditLot" (secondaryLotChange)="onSecondaryLotsChange($event)">
    </app-secondary-lot>
    <!-- EOF Lot informations -->

    <!-- Create Lot Button -->
    <div class="grid grid-cols-2 gap-16">
      <div class="col-span-1" *ngIf="lotCreation">
        <button type="submit" class="my-3" mat-stroked-button color="primary" (click)="submitLotCreation()" data-cy="submitCreateLot">
          {{ i18nService._('Txt_Button_SubmitCreateLot') }}
        </button>
      </div>
      <div class="col-span-1" *ngIf="isEditLot">
        <button type="submit" class="my-3 mr-4" mat-stroked-button color="primary" (click)="submitLotModification()" data-cy="editLot">
          {{ i18nService._('Txt_Button_EditLot') }}
        </button>
      </div>
      <div class="col-span-1" *ngIf="lotCreation || isEditLot">
        <button
          type="button"
          class="mr-3 my-3"
          mat-stroked-button
          color="primary"
          (click)="cancelLotCreationOrModification(true)"
          data-cy="cancel"
        >
          {{ i18nService._('Txt_Button_Cancel') }}
        </button>
      </div>
    </div>
  </form>
</ng-container>

<div class="flex flex-row my-2">
  <div class="flex w-full">
    <!-- New Lot Button -->
    <button
      *ngIf="!lotCreation && !isEditLot"
      type="submit"
      class="mr-3 mb-3"
      data-cy="createNewLot"
      mat-stroked-button
      color="primary"
      (click)="newLot()"
    >
      <span class="icon-plus text-sm font-bold"></span>
      {{ i18nService._('Txt_Button_CreateNewLot') }}
    </button>

    <!-- Next Step -->
    <button type="submit" mat-stroked-button color="primary" matStepperNext (click)="submitStep()" data-cy="lotNextStep">
      {{ i18nService._('Txt_Button_NextStep') }}
    </button>
  </div>
</div>
