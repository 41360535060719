<div class="comparator-lot-separator" *ngIf="lotMeta?.atLeastOneShow">
  <div>
    {{ i18nService._('Txt_page_program_Detail_Lot_information_block_rent_and_profitability') }}
  </div>
</div>
<app-lot-comparator-meta-line
  *ngIf="lotMeta"
  class="border-radius-bottom"
  [lotDetailToCompare]="lotDetailToCompare"
  [lotMetaWrapper]="lotMeta"
></app-lot-comparator-meta-line>
