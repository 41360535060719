import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

import { I18nService } from '../../../utils/services/i18n.service';
import { IconComponent } from '../../standalone/icon/icon.component';

@Component({
  selector: 'app-box-back-button',
  templateUrl: './box-back-button.component.html',
  styleUrls: ['./box-back-button.component.scss'],
  standalone: true,
  imports: [NgClass, IconComponent],
})
export class BoxBackButtonComponent {
  @Input() label: string;
  @Input() goBack: () => void;
  @Input() disabled = false;

  constructor(public readonly i18nService: I18nService) {}
}
