import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { ReferenceTableData } from '../../models/ReferenceTableData';
import { FormUpdateService } from '../../services/form-update.service';
import { ReferenceTablesService } from '../../services/reference-tables.service';
import { SortPipe } from '../../pipes/sort.pipe';

@Component({
  selector: 'app-dropdown-list-populated',
  templateUrl: './dropdown-list-populated.component.html',
  styleUrls: ['./dropdown-list-populated.component.scss'],
  standalone: true,
  imports: [NgSelectModule, FormsModule, NgIf, MatFormFieldModule, SortPipe],
})
export class DropdownListPopulatedComponent implements OnInit, OnDestroy {
  /**
   * referenceTable input, set this name to have an autocomplete liste
   *
   * @type {string}
   * @memberof DropdownListPopulatedComponent
   */
  @Input() referenceTable: string;

  /**
   * searchable input, set it to have a filter
   *
   * @type {boolean}
   * @memberof DropdownListPopulatedComponent
   */
  @Input() searchable: boolean;

  /**
   * Placeholder input
   *
   * @type {string}
   * @memberof DropdownListPopulatedComponent
   */
  @Input() placeholder: string;

  /**
   * Parent form group
   *
   * @type {FormGroup}
   * @memberof DropdownListPopulatedComponent
   */
  @Input() parentForm: UntypedFormGroup;

  /**
   * Field name where it is used
   *
   * @type {string}
   * @memberof DropdownListPopulatedComponent
   */
  @Input() fieldName: string;

  /**
   * Get submit from parentForm
   *
   * @type {boolean}
   * @memberof DropdownListPopulatedComponent
   */
  @Input() isSubmit: boolean;

  /**
   * Get required from parentForm
   *
   * @type {BehaviorSubject}
   * @memberof DropdownListPopulatedComponent
   */
  @Input() required: boolean;

  /**
   * Get if reference table should be translated or not
   *
   * @type {BehaviorSubject}
   * @memberof DropdownListPopulatedComponent
   */
  @Input() notTranslated: boolean;

  /**
   * ParentForm to add controls to
   *
   * @type {FormGroup}
   * @memberof RadioButtonPopulatedComponent
   */

  @Input() dataToInit;

  /**
   * items attribute
   *
   * @type {ReferenceTableData}
   * @memberof DropdownListPopulatedComponent
   */
  @Input() items: ReferenceTableData[];

  /**
   * items sort by
   *
   * @type {string}
   * @memberof DropdownListPopulatedComponent
   */
  @Input() itemsSortBy = 'label';

  /**
   * Value to bind
   *
   * @type {string}
   * @memberof DropdownListPopulatedComponent
   */
  @Input() bindValue = 'id';

  /**
   * Get if mutiple selection is allowed or not
   *
   * @type {BehaviorSubject}
   * @memberof DropdownListPopulatedComponent
   */
  @Input() multiple: boolean;

  @Input() readonly: boolean;

  /**
   * items sort by
   *
   * @type {number}
   * @memberof DropdownListPopulatedComponent
   */
  @Input() maxSelectedItems = 1;

  /**
   * Give parent event
   *
   * @memberof DropdownListPopulatedComponent
   */
  @Output() readonly selectedItemChange = new EventEmitter();

  /**
   * Control on dropdown
   *
   * @type {AbstractControl}
   * @memberof DropdownListPopulatedComponent
   */
  public dropdownControl: AbstractControl;

  /**
   *  Field name for dropdown field
   *
   * @type {string}
   * @memberof DropdownListPopulatedComponent
   */
  public fieldNameId: string;

  /**
   * firstLoad attribute
   *
   * @type {boolean}
   * @memberof DropdownListPopulatedComponent
   */
  public firstLoad: boolean;
  /**
   * values attribute for binding data frome db
   *
   * @type {any[]}
   * @memberof DropdownListPopulatedComponent
   */
  public values: Array<unknown>;
  /**
   * subscription value
   * @type {Subscription}
   */
  subscription: Subscription;
  /**
   * valueChanged attribute
   *
   * @private
   * @type {boolean}
   * @memberof ChipPopulatedComponent
   */
  private valueChanged: boolean;

  DROPDOWN_OPTIONS_LIST = ['commercialCourtInseeCode', 'invoiceCountryId', 'countryId', 'highlights'];

  /**
   * Creates an instance of DropdownListPopulatedComponent.
   * @param {ReferenceTablesService} referenceTablesService
   * @param {I18nService} i18nService
   * @param {FormUpdateService} formUpdateService
   * @memberof DropdownListPopulatedComponent
   */
  constructor(
    private readonly referenceTablesService: ReferenceTablesService,
    public i18nService: I18nService,
    private readonly formUpdateService: FormUpdateService,
    private readonly snackbarService: SnackbarService,
  ) {
    // Used for autocomplete
    this.searchable = true;
    // Use this attribute to hide field required at the begining
    this.firstLoad = false;
    this.items = undefined;

    this.values = [];

    this.subscription = this.formUpdateService.subscribeChanges('stepOneFormCompany').subscribe(() => {
      if (this.parentForm && this.parentForm.controls[this.fieldNameId].value) {
        // Oh my god...
        if (this.items) {
          this.setNewValueFromDb(this.parentForm.controls[this.fieldNameId].value);
        } else {
          this.valueChanged = true;
        }
      }
    });
  }

  /**
   * ngOnInit method
   *
   * @memberof DropdownListPopulatedComponent
   */
  ngOnInit(): void {
    // Set dropdown field name
    this.fieldNameId = this.fieldName;
    if (!this.DROPDOWN_OPTIONS_LIST.includes(this.fieldName)) {
      this.fieldNameId += 'Id';
    }

    // Add controls on fields
    this.parentForm.addControl(this.fieldNameId, new UntypedFormControl('', []));
    this.dropdownControl = this.parentForm.controls[this.fieldNameId];

    if (this.required) {
      this.placeholder += ' *';
      this.dropdownControl.setValidators([Validators.required]);
      this.dropdownControl.reset();
    } else {
      this.dropdownControl.clearValidators();
      this.dropdownControl.reset();
    }

    if (this.readonly) {
      this.dropdownControl.disable();
    }

    const nextItemsFound = (itemsFound) => {
      this.items = itemsFound;
      if (this.valueChanged) {
        this.setNewValueFromDb(this.parentForm.controls[this.fieldNameId].value);
      }
    };

    // Get reference Table info
    if (this.referenceTable) {
      if (this.notTranslated) {
        this.referenceTablesService.getTableReferenceInfo(this.referenceTable).subscribe(() => {
          this.snackbarService.sendErrorOccured();
        });
      } else {
        this.referenceTablesService.getTableReferenceInfoTranslated(this.referenceTable).subscribe(nextItemsFound, () => {
          this.snackbarService.sendErrorOccured();
        });
      }
    }

    if (this.dataToInit && this.dataToInit[this.fieldNameId]) {
      this.parentForm.controls[this.fieldNameId].setValue(this.dataToInit[this.fieldNameId]);
      if (this.fieldName === 'highlights') {
        this.values = this.dataToInit[this.fieldNameId].map((highlight) => highlight.id);
      } else {
        this.values = [this.dataToInit[this.fieldNameId]];
      }
    }
  }

  /**
   * destroy chip populated component
   *
   * @memberof DropdownListPopulatedComponent
   */
  ngOnDestroy(): void {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  /**
   * setNewValueFromDb method
   *
   * @param {*} value
   * @memberof DropdownListPopulatedComponent
   */
  setNewValueFromDb(value): void {
    this.values = [value];
    const event: { inseeCode?: string; label?: string; id?: string } = {};

    const returnTable = [];
    event[this.bindValue] = value;
    for (const entry of this.items) {
      if (entry[this.bindValue] === value) {
        event.label = entry.label;
        break;
      }
    }
    returnTable.push(event);
    this.onSelectedItemChange(returnTable);
  }

  /**
   * Check if 'OTHER' has been selected and setValue of the dropdown list
   *
   * @param {*} event
   * @memberof DropdownListPopulatedComponent
   */
  onSelectedItemChange(event): void {
    this.selectedItemChange.emit(event);
    if (this.dropdownControl) {
      if (this.fieldName === 'highlights') {
        this.dropdownControl.setValue(event);
      } else if (event.length) {
        this.dropdownControl.setValue(event[0][this.bindValue]);
      } else {
        this.dropdownControl.reset();
      }
    }
    this.firstLoad = true;
  }

  /**
   * resetValue method
   *
   * @memberof DropdownListPopulatedComponent
   */
  public resetValue(): void {
    this.parentForm.controls[this.fieldNameId].setValue(undefined);
    this.values = [];
    this.firstLoad = false;
  }

  /**
   * Set new dataToInit values (used for lot on program creation/edit form)
   *
   * @param {*} dataToInit
   * @memberof DropdownListPopulatedComponent
   */
  public setNewValue(dataToInit): void {
    this.parentForm.controls[this.fieldNameId].setValue(dataToInit[this.fieldNameId]);
    this.values = [dataToInit[this.fieldNameId]];
  }
}
