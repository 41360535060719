/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { Component, OnInit } from '@angular/core';
import { DossierStatusCode } from '@commons-dto/dossier-prospect';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgIf, NgFor } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

import { GridTrackingTableComponent } from '../grid-tacking-table/grid-tracking-table.component';

import { ProspectRendererComponent } from './../../renderers/prospect-renderer/prospect-renderer.component';
import { DossierProspectTrackingTableItem } from './../../models/DossierProspectTrackingTableItem';
import { StatusTagsRendererComponent } from './../../renderers/status-tags-renderer/status-tags-renderer.component';
import { StatusTagColor } from './../../../design-system/model/status-tag-color.enum';
import { DossierProspectTrackingTableService } from './../../services/dossier-prospect-tracking-table-service';
import { AbstractTrackingTable } from './../../abstract-tracking-table';
import { FilterEventOutput } from './../../models/filter/filter-event-output';
import { DateRendererComponent } from './../../renderers/date-renderer/date-renderer.component';
import { ActionRendererComponent } from './../../renderers/action-renderer/action-renderer.component';
import { TableLoader } from './../../../table/interfaces/DataLoader';
import { I18nService } from './../../../utils/services/i18n.service';
import { DossierProspectRouteService } from '../../../dossier-prospect/components/dossier-prospect/dossier-prospect.route.service';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';

const tagTypeByDossierProspectStatus: { [key in DossierStatusCode]: StatusTagColor } = {
  DISCOVER: StatusTagColor.IN_PROGRESS,
  IDENTIFICATION: StatusTagColor.IN_PROGRESS,
  CLOSING: StatusTagColor.SUCCESS,
  BILLING: StatusTagColor.SUCCESS,
  DONE: StatusTagColor.SUCCESS,
};

@Component({
  selector: 'app-dossier-prospect-tracking-table',
  templateUrl: './dossier-prospect-tracking-table.html',
  styleUrls: ['./dossier-prospect-tracking-table.scss'],
  standalone: true,
  imports: [
    MatSidenavModule,
    StickyHeaderFormComponent,
    NgIf,
    MatButtonToggleModule,
    FormsModule,
    NgFor,
    MatFormFieldModule,
    MatInputModule,
    GridTrackingTableComponent,
  ],
})
export class DossierProspectTrackingTableComponent extends AbstractTrackingTable implements OnInit {
  pageSize = 10;
  pageNumber = 1;
  tableHeight = 'auto';
  columnDefs: ColDef[];
  tableLoader: TableLoader<any>;
  statusFilter: any;
  statusList: any;
  filterValue: string;
  filterUrlParam: FilterEventOutput;
  textFilterSubject = new Subject<string>();
  textFilterValue: string;

  dossierStatusList = [
    { label: 'DOSSIER_PROSPECT_DISCOVER', code: DossierStatusCode.DISCOVER },
    { label: 'DOSSIER_PROSPECT_IDENTIFICATION', code: DossierStatusCode.IDENTIFICATION },
    { label: 'DOSSIER_PROSPECT_CLOSING', code: DossierStatusCode.CLOSING },
    { label: 'DOSSIER_PROSPECT_BILLING', code: DossierStatusCode.BILLING },
    { label: 'DOSSIER_PROSPECT_DONE', code: DossierStatusCode.DONE },
  ];

  constructor(
    public i18nService: I18nService,
    public dossierProspectTrackingTableService: DossierProspectTrackingTableService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dossierProspectRouteService: DossierProspectRouteService,
  ) {
    super(dossierProspectRouteService.getListPath(), router);
  }

  ngOnInit(): void {
    this.setStatusList();
    this.initializeGrid();
    this.setUrlParam();
    this.onTextFilterChange();
  }

  private initializeGrid() {
    this.columnDefs = this.createColumnDefs();
  }

  private createColumnDefs(): ColDef[] {
    return [
      {
        field: 'prospects',
        headerName: this.i18nService._('Txt_DossierProspectTrackingTable_Column_Prospect'),
        cellRenderer: ProspectRendererComponent,
        cellRendererParams: {
          click: (item: any) => {
            return this.dossierProspectRouteService.goToDashboard(item.id);
          },
        },
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'createdAt',
        headerName: this.i18nService._('Txt_DossierProspectTrackingTable_Column_CreatedAt'),
        cellRenderer: DateRendererComponent,
        minWidth: 40,
        flex: 2,
      },
      {
        field: 'prospects[0].email',
        headerName: this.i18nService._('Txt_DossierProspectTrackingTable_Column_Email'),
        valueFormatter: (params) => {
          return params.data?.prospects[0]?.email;
        },
        minWidth: 80,
        flex: 3,
      },
      {
        headerName: this.i18nService._('Txt_DossierProspectTrackingTable_Column_Phone'),
        valueFormatter: (params) => {
          return params.data?.prospects[0]?.phoneNumber;
        },
        minWidth: 60,
        flex: 2,
      },
      {
        headerName: this.i18nService._('Txt_DossierProspectTrackingTable_Column_City'),
        valueFormatter: (params) => {
          return params.data ? `${params.data.prospects[0].city} (${params.data.prospects[0].postalCode})` : '-';
        },
        minWidth: 80,
        flex: 3,
      },
      {
        headerName: this.i18nService._('Txt_DossierProspectTrackingTable_Column_Partner'),
        valueFormatter: (params) => {
          return params.data ? `${params.data.prescriptor.firstName} ${params.data.prescriptor.lastName}` : '-';
        },
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'dossierStatusCode',
        headerName: this.i18nService._('Txt_DossierProspectTrackingTable_Column_Status'),
        cellRenderer: StatusTagsRendererComponent,
        cellRendererParams: {
          tagsList: tagTypeByDossierProspectStatus,
          isUpperCase: true,
        },
        valueFormatter: (params) => {
          const status = this.dossierStatusList.find((status) => status.code === params.value)?.label;
          return status ?? params.value;
        },
        minWidth: 60,
        flex: 2,
      },
      {
        field: 'actions',
        headerName: this.i18nService._('Title_DossierTrackingTable_Column_Actions'),
        cellRenderer: ActionRendererComponent,
        cellRendererParams: {
          actionItems: (item: any) => {
            return this.getActionItems(item);
          },
        },
        minWidth: 60,
        maxWidth: 60,
        flex: 2,
        cellStyle: { paddingRight: '10px' },
        sortable: false,
      },
    ];
  }

  private setStatusList(): void {
    this.statusList = [
      {
        code: 'ALL',
        label: this.i18nService._('Txt_DossierProspectTrackingTable_Status_All'),
      },
      {
        code: 'DISCOVER',
        label: this.i18nService._('Txt_DossierProspectTrackingTable_Status_Prospect_Discover'),
      },
      {
        code: 'IDENTIFICATION',
        label: this.i18nService._('Txt_DossierProspectTrackingTable_Status_Prospect_Identification'),
      },
      {
        code: 'CLOSING',
        label: this.i18nService._('Txt_DossierProspectTrackingTable_Status_Prospect_Closing'),
      },
    ];
  }

  getActionItems(item: DossierProspectTrackingTableItem) {
    return [
      {
        label: 'Txt_DossierProspectTrackingTable_Action_View_Dossier',
        action: () => this.dossierProspectRouteService.goToDashboard(item?.id),
      },
    ];
  }

  private setUrlParam(): void {
    // get filter from URL
    const statusFilterParams = this.route.snapshot.queryParamMap.get('statusFilter');
    this.statusFilter = statusFilterParams ?? this.statusList[0].code;
    this.filterValue = this.route.snapshot.queryParamMap.get('filter');
    this.textFilterValue = this.route.snapshot.queryParamMap.get('filter');

    // set filter for URL
    this.filterUrlParam = {
      statusFilter: this.statusFilter,
      filter: this.filterValue,
    };
    super.filterEvent(this.filterUrlParam);
  }

  public statusFilterChange(): void {
    this.filterUrlParam.statusFilter = this.statusFilter;
    super.filterEvent(this.filterUrlParam);
  }

  onTextFilterChange() {
    this.textFilterSubject.pipe(debounceTime(this.textFilterDebounceTimer), distinctUntilChanged()).subscribe((value) => {
      this.filterUrlParam.filter = value;
      super.filterEvent(this.filterUrlParam);
      this.textFilterValue = value;
    });
  }
}
