import { Component, Input, OnInit } from '@angular/core';
import { NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { ActionEntry } from './action-entry.model';

import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-action-list-button',
  templateUrl: './action-list-button.component.html',
  styleUrls: ['./action-list-button.component.scss'],
  standalone: true,
  imports: [MatMenuModule, MatIconModule, NgFor],
})
export class ActionListButtonComponent implements OnInit {
  @Input() i18nButtonLabel: string;
  @Input() actions: ActionEntry[];

  buttonLabel = '';

  trackActions = (index: number, item: ActionEntry): string => item.label;

  constructor(public i18nService: I18nService) {}

  ngOnInit() {
    this.buttonLabel = this.i18nService._(this.i18nButtonLabel);
  }
}
