import { take } from 'rxjs';

import { RoutingStateService } from '../services/routing-state.service';
import { ServiceLocator } from '../services/service-locator';

export class AbstractAppBack {
  private readonly _routingStateService: RoutingStateService;
  public goBackPath: string;

  constructor() {
    this._routingStateService = ServiceLocator.injector.get(RoutingStateService);
    this._routingStateService.previousRoutePath.pipe(take(1)).subscribe((previousRoutePath) => {
      this.goBackPath = previousRoutePath;
    });
  }
}
