import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';

import { ProspectEditComponent } from './components/prospect-edit/prospect-edit.component';
import { DossierProspectRoutingModule } from './dossier-prospect-routing.module';
import { UtilsModule } from '../utils/utils.module';
import { ProspectCreateComponent } from './components/prospect-create/prospect-create.component';
import { PaternityCreateComponent } from './components/paternity-create/paternity-create.component';
import { SharedModule } from '../common/shared/shared.module';
import { DossierProspectDashboardComponent } from './components/dashboard/dossier-prospect-dashboard.component';
import { PaternitiesListComponent } from './components/paternity-list/paternities-list.component';
import { PaternitiesListElementComponent } from './components/paternity-list/paternity-list-element/paternity-list-element.component';
import { PaternityQuickFormComponent } from './components/paternity-list/paternity-quick-form/paternity-quick-form.component';
import { AppFormlyModule } from '../common/formly/app-formly.module';
import { AppFormModule } from '../common/form/app-form.module';
import { SimulationTabComponent } from './components/simulation-tab/simulation-tab.component';
import { TransactionComponent } from './components/transaction/transaction.component';
import { StatusColorPipe } from './components/dossier-prospect/pipes/status-color.pipe';
import { DossierProspectComponent } from './components/dossier-prospect/dossier-prospect.component';
import { SearchPreferenceComponent } from './components/search-preference/search-preference.component';
import { PipesModule } from '../utils/pipes/pipes.module';
import { LotComponent } from './components/lot/lot.component';

@NgModule({
  imports: [
    CommonModule,
    DossierProspectRoutingModule,
    UtilsModule,
    MatDialogModule,
    TooltipModule,
    AppFormlyModule,
    AppFormModule,
    FormlyModule.forChild(),
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    TabViewModule,
    PipesModule,
    DossierProspectDashboardComponent,
    SearchPreferenceComponent,
    TransactionComponent,
    LotComponent,
    SimulationTabComponent,
    DossierProspectComponent,
    ProspectCreateComponent,
    PaternityCreateComponent,
    PaternitiesListComponent,
    PaternitiesListElementComponent,
    PaternityQuickFormComponent,
    ProspectEditComponent,
    StatusColorPipe,
    DossierProspectComponent,
  ],
})
export class DossierProspectModule {}
