import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CompleteDossierResponse, TransactionResponse, TransactionStatus, TransactionType } from '@commons-dto/dossier-prospect';
import { ListResponse } from '@commons-dto/valo-back';

import { TransactionStatusColorPipe } from './pipes/transaction-option-color.pipe';
import { DossierProspectRouteService } from '../dossier-prospect/dossier-prospect.route.service';

import { UtilsModule } from '../../../utils/utils.module';
import { DesignSystemModule } from '../../../design-system/design-system.module';
import { FilterSortHeaderBarComponent } from '../../../common/standalone/filter-sort-header-bar/filter-sort-header-bar.component';
import { TransactionApiService } from '../../../adapters/transaction-api.service';
import { PipesModule } from '../../../utils/pipes/pipes.module';
import { TransactionActionService } from '../../../common/services/transaction/transaction-action.service';

@Component({
  selector: 'app-transaction',
  standalone: true,
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss'],
  imports: [
    CommonModule,
    MatSidenavModule,
    UtilsModule,
    DesignSystemModule,
    TransactionStatusColorPipe,
    FilterSortHeaderBarComponent,
    PipesModule,
  ],
})
export class TransactionComponent {
  private _dossier: CompleteDossierResponse;
  public TransactionType = TransactionType;
  public hTransactionActions: any;

  @Input()
  get dossier(): CompleteDossierResponse {
    return this._dossier;
  }

  set dossier(value: CompleteDossierResponse) {
    if (value.id === this._dossier?.id) return;
    if (value != null) {
      this._dossier = value;
      this.initModel();
    }
  }

  public transactions: ListResponse<TransactionResponse> = {
    items: [],
    nbItems: 0,
  };

  public transactionSelected: TransactionResponse = null;
  public panelOpened = false;

  constructor(
    private readonly transactionApiService: TransactionApiService,
    private readonly transactionActionService: TransactionActionService,
    private readonly dossierProspectRouterService: DossierProspectRouteService,
  ) {}

  private async initModel() {
    this.transactions = await this.transactionApiService.getDossierProspect(this.dossier.id);
    this.transactions.items.map((transaction) => {
      transaction.dateStartLibelle = this.getStartDateLibelle(transaction);
      transaction.dateEndLibelle = this.getEndDateLibelle(transaction);
      transaction.endDateToDisplay = transaction.dateEndLibelle
        ? transaction.transactionType === TransactionType.OPTION
          ? transaction.optionEndDate
          : transaction.lastStatusChangedDate
        : null;
    });
    this.hTransactionActions = {};
    for (const transaction of this.transactions.items) {
      this.hTransactionActions[transaction.id] = this.transactionActionService.getActionItemsFromTransaction(
        transaction,
        this.open.bind(this, transaction),
        this.onItemUpdated.bind(this, transaction),
      );
    }
  }

  open(transaction: TransactionResponse) {
    this.transactionSelected = transaction;
    this.panelOpened = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onItemUpdated(transaction: TransactionResponse) {
    this.refresh();
  }

  close() {
    this.transactionSelected = null;
    this.panelOpened = false;
  }

  search() {
    this.dossierProspectRouterService.goToSearchWithDossierSearchPreferences(this.dossier, false);
  }

  public async refresh(): Promise<void> {
    await this.initModel();
  }

  private getStartDateLibelle(transaction: TransactionResponse): string {
    if (TransactionType.RESERVATION === transaction.transactionType) return `Date de la réservation`;
    if (TransactionType.PRERESERVATION === transaction.transactionType) {
      if (
        transaction.status === TransactionStatus.PRERESERVATION_CANCELED ||
        transaction.status === TransactionStatus.PRERESERVATION_REJECTED
      ) {
        return `Date de début`;
      } else {
        return `Date de la demande`;
      }
    }
    return `Date de début`;
  }

  private getEndDateLibelle(transaction: TransactionResponse): string {
    let endDateLibelle = ``;
    if (TransactionType.OPTION === transaction.transactionType) {
      endDateLibelle = `Date de fin`;
    } else {
      endDateLibelle = this.getReservationEndDateLabel(transaction.status);
    }
    return endDateLibelle ? endDateLibelle + ` :` : endDateLibelle;
  }

  private getReservationEndDateLabel(reservationStatus: TransactionStatus): string {
    switch (reservationStatus) {
      case TransactionStatus.RECEIVED_VALO:
        return 'Date de réception';
      case TransactionStatus.VALIDATED_VALO:
        return 'Date de validation valorissimo';
      case TransactionStatus.SENT_DEVELOPER:
        return `Date d'envoi au promoteur`;
      case TransactionStatus.VALIDATED_DEVELOPER:
        return `Date de validation du promoteur`;
      case TransactionStatus.REJECTED_DEVELOPER:
        return `Date de rejet promoteur`;
      case TransactionStatus.NOTARY_SIGNATURE_OBTAINED:
        return `Date de signature notaire`;
      case TransactionStatus.CLIENT_DESISTED:
        return `Date désistement client`;
      case TransactionStatus.LOAN_OFFER_OBTAINED:
        return `Date d'obtention de l'offre de prêt`;
      case TransactionStatus.AGREEMENT_OBTAINED:
        return `Date d'obtention de l'accord de principe`;
      case TransactionStatus.PRERESERVATION_CANCELED:
      case TransactionStatus.RESERVATION_CANCELED:
        return `Date d'annulation`;
      case TransactionStatus.RESERVATION_IN_SIGNATURE:
        return `Date de création du dossier de signature`;
      case TransactionStatus.NOTIFICATION_SRU_IN_PROGRESS:
      case TransactionStatus.NOTIFICATION_SRU_PURGED:
        return `Date de la notification SRU`;
      case TransactionStatus.NOTARY_MEETING_OBTAINED:
        return `Date rendez-vous notaire`;
      default:
        console.error('Le status demandé n existe pas', reservationStatus);
        return '';
    }
  }
}
