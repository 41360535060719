import { Pipe, PipeTransform } from '@angular/core';

import { I18nService } from '../services/i18n.service';

@Pipe({
  name: 'range',
  standalone: true,
  pure: true,
})
export class RangePipe implements PipeTransform {
  constructor(private readonly i18nService: I18nService) {}

  transform(values: string[] | number[]): string {
    if (!values || values.length === 0) return null;
    let hasOneRange = true;
    let firstValue: any;
    let lastValue: any;
    let untilInfinite = false;
    for (const i in values) {
      let value: string | number = values[i];
      if (typeof value == 'string') {
        if (value.startsWith('GT') || value.startsWith('>')) {
          untilInfinite = true;
          value = value.replace('GT', '>');
          values[i] = value;
        } else {
          value = Number(value);
          values[i] = value;
        }
      } else {
        value = Number(value);
        if (lastValue && lastValue + 1 != value) {
          hasOneRange = false;
        }
      }
      if (firstValue == null) firstValue = value;
      lastValue = value;
    }
    if (hasOneRange && firstValue != lastValue) {
      if (untilInfinite) {
        return this.i18nService._('at_least', [firstValue]);
      } else {
        return this.i18nService._('from_to', [firstValue, lastValue]);
      }
    } else {
      return values.join(', ');
    }
  }
}
