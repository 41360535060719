<label *ngIf="label && name" [ngClass]="labelCssClass" class="mb-2 inline-block">{{ label }}</label>
<input
  #intlTelInput
  (countrychange)="i18nizePhoneNumber()"
  [(ngModel)]="phoneNumber"
  [attr.aria-required]="required || null"
  [ngClass]="cssClass"
  [required]="required"
  [disabled]="disabled"
  [readOnly]="readonly"
  pInputText
  type="text"
/>
