<ng-container *ngIf="program?.fullAdress">
  <span class="program-page-neighborhood-map-title">
    {{ i18nService._('Txt_page_program_neighborhood_map_bloc_title') }}
  </span>
  <div class="program-page-neighborhood-map-container">
    <div class="program-page-neighborhood-map-header">
      <div class="program-page-neighborhood-map-address">
        <mat-icon fontSet="material-icons-outlined" inline="true">place_outline</mat-icon>
        <span>{{ program?.fullAdress }}</span>
      </div>
      <!--      <button class="street-view" mat-stroked-button>-->
      <!--        <mat-icon class="size-18">streetview</mat-icon>-->
      <!--        {{ i18nService._('Txt_page_program_neighborhood_map_view_streetview') }}-->
      <!--      </button>-->
    </div>

    <span class="program-page-neighborhood-interest-points" (click)="openCityScanDialog()">
      {{ i18nService._('Txt_page_program_neighborhood_map_interest_points') }}
    </span>
    <div class="program-page-neighborhood-map-img">
      <mat-icon [svgIcon]="'marker'" class="material-icons-outlined map-marker"></mat-icon>
      <img [alt]="program?.perspectiveTitle" src="assets/img/map_preview.png" class="w-full" />
      <button mat-flat-button (click)="openCityScanDialog()">
        <mat-icon [svgIcon]="'map_custom'" class="material-icons-outlined"></mat-icon>
        {{ i18nService._('Txt_page_program_neighborhood_map_explore_label') }}
      </button>
    </div>
    <div class="program-page-neighborhood-map-situation" (click)="openCityScanDialog()">
      <span class="cursor-pointer">
        {{ i18nService._('Txt_page_program_neighborhood_map_view_situation_access') }}
      </span>
    </div>
  </div>
</ng-container>
