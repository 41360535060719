import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { StrongPointResponseDto } from '@commons-dto/valo-back';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

import { ProgramService } from '../../../programs/services/program.service';
import { DocumentResponse } from '../../../utils/models/DocumentResponse';
import { ProgramSearchResponse } from '../../../utils/models/ProgramSearchResponse';
import { Sitemap } from '../../../utils/models/Sitemap';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { FileSystemService } from '../../../utils/services/file-system.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { UserRoleService } from '../../../utils/services/user-role.service';
import { SearchFormService } from '../../services/search-form.service';
import { ProgramPageService } from '../../../programs/services/program-page.service';
import { QuarterDatePipe } from '../../../utils/pipes/quarter-date.pipe';
import { TranslatePipe } from '../../../utils/pipes/translate.pipe';
import { StrongPointListComponent } from '../../../design-system/component/strong-point-list/strong-point-list.component';

@Component({
  selector: 'app-search-result-program',
  standalone: true,
  templateUrl: './search-result-program.component.html',
  styleUrls: ['./search-result-program.component.scss'],
  imports: [CommonModule, RouterLink, QuarterDatePipe, TranslatePipe, StrongPointListComponent],
})
export class SearchResultProgramComponent implements OnInit, OnDestroy {
  @ViewChild('imgElementProgramPerspectives', { static: true })
  public imgElementProgramPerspectives: ElementRef;

  @Input() index: number;

  private _program: ProgramSearchResponse;

  @Input()
  get program(): ProgramSearchResponse {
    return this._program;
  }

  set program(value: ProgramSearchResponse) {
    this._program = value;
    this.onProgramChange();
  }

  public userIsValo: boolean;

  public strongPointDisplayed: StrongPointResponseDto[];
  public isNewProgram: boolean;
  public deliveryDateFormatted: string;
  public minMaxNbRoomsFormatted: string;
  public visibilityOfAvailableLotStyleBlock: SafeStyle;
  public availableLotsFormatted: string;
  public taxations: Array<string>;
  public programPerspectiveImage: string;
  public isCountryFr = false;

  constructor(
    private readonly appConfig: AppConfigService,
    private readonly fileSystemService: FileSystemService,
    private readonly i18nService: I18nService,
    private readonly programService: ProgramService,
    private readonly searchFormService: SearchFormService,
    private readonly userRoleService: UserRoleService,
    private readonly domSanitizater: DomSanitizer,
    private readonly programPageService: ProgramPageService,
    private readonly quarterDatePipe: QuarterDatePipe,
  ) {}

  ngOnInit(): void {
    this.userIsValo = this.userRoleService.isValo();
  }

  private onProgramChange(): void {
    this.isCountryFr = this.appConfig.getAppConfig().codeCountryFr === this.program.countryCode;
    const documentPerspective: DocumentResponse = {
      title: this.program.perspectiveTitle,
      fileName: this.program.perspectiveFileName,
      container: this.program.perspectiveContainer,
    };
    this.programPerspectiveImage = this.fileSystemService.getLogo(documentPerspective);

    this.taxations = this.getTaxations(this.program.labelsProfitability);

    this.strongPointDisplayed = this.program.strongPoints;
    this.isNewProgram = this.programPageService.isNewProgram(this.program);
    this.deliveryDateFormatted = this.formatDeliveryDate(this.program.minDeliveryDate, this.program.maxDeliveryDate);
    this.minMaxNbRoomsFormatted = this.formatMinMaxNbRooms(this.program.minRoom, this.program.maxRoom);
    this.visibilityOfAvailableLotStyleBlock = this.getVisibilityOfAvailableLotStyleBlock();
    this.availableLotsFormatted = this.programService.constructAvailableLotsString(this.program);
  }

  private getTaxations(profitabilityLabels: string): Array<string> {
    const profitabilityLabelsList = profitabilityLabels.split(',');
    const traductedTaxations = [];
    if (profitabilityLabelsList.length) {
      profitabilityLabelsList.forEach((element: string) => {
        traductedTaxations.push(element);
      });

      return traductedTaxations.sort();
    }
  }

  private getVisibilityOfAvailableLotStyleBlock(): SafeStyle {
    if (this.program.nbLotsAvailable > 0 || this.program.nbLotsOptionned > 0 || this.program.nbLotsNotAvailable > 0) {
      return this.domSanitizater.bypassSecurityTrustStyle('visible');
    }

    return this.domSanitizater.bypassSecurityTrustStyle('hidden');
  }

  private formatMinMaxNbRooms(min: number, max: number): string {
    return min !== max
      ? `${this.i18nService._('Txt_Page_SearchPrograms_From')} ${this.i18nService._('Txt_Page_SearchPrograms_Rooms', [min, max])}`
      : this.i18nService._('Txt_Page_SearchPrograms_Rooms_Once', [min]);
  }

  private formatDeliveryDate(minDate: Date, maxDate: Date): string {
    //const minDeliveryDateTrimester = DateTime.fromJSDate(new Date(minDate)).toFormat(`q'T 'yyyy`);
    //const maxDeliveryDateTrimester = DateTime.fromJSDate(new Date(maxDate)).toFormat(`q'T 'yyyy`);
    const minDeliveryDateTrimester = this.quarterDatePipe.transform(minDate);
    const maxDeliveryDateTrimester = this.quarterDatePipe.transform(maxDate);

    if (minDeliveryDateTrimester === maxDeliveryDateTrimester) {
      return maxDeliveryDateTrimester;
    }
    return minDeliveryDateTrimester + ' - ' + maxDeliveryDateTrimester;
  }

  public redirectToProgramPage(idProgram): string {
    this.searchFormService.setSearchByLot(false);

    return `/${Sitemap.programs.read.path.replace(/:programId/, String(idProgram))}`;
  }

  public pushToDatalayer(program: ProgramSearchResponse, index: number) {
    this.programService.pushClickedProgramToDatalayer(program, index);
  }

  ngOnDestroy(): void {
    // Used to cancel unecessary loading ( downloading in background ) images
    this.imgElementProgramPerspectives.nativeElement.src = '';
  }
}
