import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsisMiddle',
  standalone: true,
})
export class EllipsisMiddlePipe implements PipeTransform {
  private readonly ellipsis = '[..]';

  public transform(val: string, args?: number): string {
    if (args === undefined) {
      return val;
    }

    if (val && val.length > args) {
      const segmentSize = Math.ceil(args / 2 - this.ellipsis.length / 2);
      return val.substring(0, segmentSize) + this.ellipsis + val.substring(val.length - segmentSize);
    }

    return val;
  }
}
