<mat-dialog-content class="px-5 text-center">
  <div class="absolute cursor-pointer" style="top: -25px; right: -25px" (click)="onClose()">
    <div class="rounded-md bg-gray-500 w-12 h-12 flex items-center justify-center">
      <app-icon name="XMark" size="1.5rem" class="text-white flex items-center justify-center"></app-icon>
    </div>
  </div>

  <div class="flex justify-center items-center">
    <img [attr.src]="'/assets/img/icon/building.svg'" alt="" />
  </div>
  <h2 class="text-center" mat-dialog-title>
    {{ i18nService._(data.title) }}
  </h2>
  <div class="flex justify-center items-center mb-8">
    <p>
      {{ i18nService._(data.message) }}
    </p>
  </div>

  <app-chip-populated-autocomplete
    *ngIf="dossierProspectItems$ | async as dossierProspectItems"
    [id]="'dossierProspectId'"
    [title]="i18nService._('Txt_DossierProspectAssociationPopin_LookingForProspect')"
    [required]="false"
    [max]="1"
    [parentForm]="dossierProspectSelectedForm"
    [items]="dossierProspectItems"
    (valueChanged)="dossierProspectSelected($event)"
    [sentenceIfNoItems]="i18nService._('Txt_DossierProspectAssociationPopin_NoProspect')"
  >
  </app-chip-populated-autocomplete>
</mat-dialog-content>

<mat-dialog-actions class="justify-center mb-1 flex-col">
  <button (click)="submit()" class="my-3 lg:mb-0 sm:mb-0 md:mb-0" mat-flat-button color="primary">
    {{ i18nService._(data.button) }}
  </button>
</mat-dialog-actions>
