import { IsArray, IsEnum, IsInt, IsOptional, IsString } from 'class-validator';

import { ProspectType } from '../prospect';

export class DossierUpdateRequest {
  @IsInt()
  @IsOptional()
  id: number;

  @IsInt()
  @IsOptional()
  prescriptorId: number;

  @IsArray()
  @IsOptional()
  prospects: DossierUpdateProspect[];

  @IsString()
  @IsOptional()
  comment?: string;
}

export class DossierUpdateProspect {
  @IsInt()
  @IsOptional()
  id: number;

  @IsString()
  @IsOptional()
  email?: string;

  @IsString()
  @IsOptional()
  civility?: 'Madame' | 'Monsieur';

  @IsString()
  @IsOptional()
  phoneNumber?: string;

  @IsString()
  @IsOptional()
  lastName?: string;

  @IsString()
  @IsOptional()
  firstName?: string;

  @IsString()
  @IsOptional()
  companyName?: string;

  @IsString()
  @IsOptional()
  SIRET?: string;

  @IsString()
  @IsOptional()
  country?: string;

  @IsString()
  @IsOptional()
  city?: string;

  @IsString()
  @IsOptional()
  postalCode?: string;

  @IsEnum(ProspectType)
  prospectType: ProspectType;
}
