/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgFor, NgIf } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';

import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { FormUpdateService } from '../../services/form-update.service';
import { ReferenceTablesService } from '../../services/reference-tables.service';

@Component({
  selector: 'app-chip-populated',
  templateUrl: './chip-populated.component.html',
  styleUrls: ['./chip-populated.component.scss'],
  standalone: true,
  imports: [MatChipsModule, NgFor, NgIf, MatFormFieldModule],
})
export class ChipPopulatedComponent implements OnInit, OnDestroy {
  /**
   * Input referenceTable to get tables informations
   * @type {string}
   * @memberof ChipPopulatedComponent
   */
  @Input() referenceTable: string;

  /**
   * Parent form group
   *
   * @type {string}
   * @memberof ChipPopulatedComponent
   */
  @Input() fieldName: string;

  /**
   * Field name where it is used
   *
   * @type {FormGroup}
   * @memberof ChipPopulatedComponent
   */
  @Input() parentForm: UntypedFormGroup;

  /**
   * Get submit from parentForm
   *
   * @type {boolean}
   * @memberof ChipPopulatedComponent
   */
  @Input() isSubmit: boolean;

  /**
   * Get if reference table should be translated or not
   *
   * @type {boolean}
   * @memberof RadioButtonPopulatedComponent
   */
  @Input() notTranslated: boolean;

  /**
   * ParentForm to add controls to
   *
   * @type {FormGroup}
   * @memberof RadioButtonPopulatedComponent
   */

  @Input() dataToInit: any;

  /**
   * Give parent event
   *
   * @memberof ChipPopulatedComponent
   */
  @Output() readonly selectedItemChange = new EventEmitter();

  /**
   * Table informations
   * @type {Array<any>}
   * @memberof ChipPopulatedComponent
   */
  public referenceTableInfos: Array<any>;

  /**
   * Id of the selected chip
   * @type {*}
   * @memberof ChipPopulatedComponent
   */
  public chipSelectedId: any;

  /**
   * Control on input field if 'OTHER' is selected
   *
   * @type {AbstractControl}
   * @memberof ChipPopulatedComponent
   */
  public chipControl: AbstractControl;

  /**
   * firstLoad attribute
   *
   * @type {boolean}
   * @memberof ChipPopulatedComponent
   */
  public firstLoad: boolean;

  /**
   *  Field name for chip field
   *
   * @type {string}
   * @memberof ChipPopulatedComponent
   */
  public fieldNameId: string;
  /**
   * subscription value
   * @type {Subscription}
   */
  subscription: Subscription;
  /**
   * valueChanged attribute
   *
   * @private
   * @type {boolean}
   * @memberof ChipPopulatedComponent
   */
  private valueChanged: boolean;

  /**
   * Creates an instance of ChipPopulatedComponent.
   * @param {ReferenceTablesService} referenceTablesService
   * @param {I18nService} i18nService
   * @memberof ChipPopulatedComponent
   */
  constructor(
    private readonly referenceTablesService: ReferenceTablesService,
    public i18nService: I18nService,
    private readonly formUpdateService: FormUpdateService,
    private readonly snackbarService: SnackbarService,
  ) {
    // Use this attribute to hide field required at the begining
    this.firstLoad = false;
    this.valueChanged = false;
    this.subscription = this.formUpdateService.subscribeChanges('stepOneFormCompany').subscribe(() => {
      if (this.parentForm.controls[this.fieldNameId].value) {
        // Oh my god...
        if (this.referenceTableInfos) {
          this.chipSelected(this.parentForm.controls[this.fieldNameId].value);
        } else {
          this.valueChanged = true;
        }
      }
    });
  }

  /**
   * Init Component
   * @memberof ChipPopulatedComponent
   */
  ngOnInit(): void {
    // Set chip field name
    this.fieldNameId = `${this.fieldName}Id`;
    // Add controls on fields
    this.parentForm.addControl(this.fieldNameId, new UntypedFormControl(undefined, [Validators.required]));
    this.chipControl = this.parentForm.controls[this.fieldNameId];

    const nextGetTableREf = (itemsFound) => {
      this.referenceTableInfos = itemsFound;
      this.initData();
      if (this.valueChanged) {
        this.chipSelected(this.parentForm.controls[this.fieldNameId].value);
      }
    };

    // Get reference Table info
    if (this.notTranslated) {
      this.referenceTablesService.getTableReferenceInfo(this.referenceTable).subscribe(() => {
        this.snackbarService.sendErrorOccured();
      });
    } else {
      this.referenceTablesService.getTableReferenceInfoTranslated(this.referenceTable).subscribe(nextGetTableREf, () => {
        this.snackbarService.sendErrorOccured();
      });
    }
  }

  /**
   * destroy chip populated component
   *
   * @memberof ChipPopulatedComponent
   */
  ngOnDestroy(): void {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    // Ensure that no chip are visualy preselected when going back to the form
    this.chipSelected(undefined);
  }

  initData(): void {
    if (this.dataToInit && this.dataToInit[this.fieldNameId]) {
      this.parentForm.controls[this.fieldNameId].setValue(this.dataToInit[this.fieldNameId]);
      this.chipSelected(this.parentForm.controls[this.fieldNameId].value);
    }
  }

  /**
   * Change selected value of chips on click
   * Get id of the selected value to add it in the input fields
   * @param {*} checkedChipId
   * @param {*} referenceTableInfos
   * @memberof ChipPopulatedComponent
   */
  chipSelected(checkedChipId): void {
    for (const referenceTableInfo of this.referenceTableInfos) {
      if (referenceTableInfo.id !== checkedChipId) {
        referenceTableInfo.isSelected = false;
      } else {
        referenceTableInfo.isSelected = true;
        this.chipSelectedId = referenceTableInfo.id;
        this.chipControl.setValue(this.chipSelectedId);
        this.selectedItemChange.emit(referenceTableInfo.label);
      }
    }

    this.firstLoad = true;
  }
}
