/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, switchMap, tap } from 'rxjs';

import { ApprovalData } from '../../utils/models/ApprovalData';
import { DocumentResponse } from '../../utils/models/DocumentResponse';
import { ReservationCancel } from '../../utils/models/ReservationCancel';
import { ReservationCreate } from '../../utils/models/ReservationCreate';
import { Sitemap } from '../../utils/models/Sitemap';
import { TaxationResponse } from '../../utils/models/TaxationResponse';
import { AppConfigService } from '../../utils/services/app-config.service';
import { AttachmentService } from '../../utils/services/attachment.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class ReservationService {
  /**
   * taxationList
   *
   * @type {Array<TaxationResponse>}
   * @memberOf reservationService
   */
  private taxationList: Array<TaxationResponse>;

  readonly container = AppConfigService.getAppConfig().containerName.reservationDocuments;

  private readonly errorHandlerServiceRedirectOn404Options;

  /**
   * Creates an instance of ReservationService.
   * @param {AppConfigService} appConfig
   * @param {AttachmentService} attachmentService
   * @param {ErrorHandlerService} errorHandlerService
   * @param {HttpClient} http
   * @memberof ReservationService
   */
  constructor(
    private readonly appConfig: AppConfigService,
    private readonly attachmentService: AttachmentService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly http: HttpClient,
  ) {
    this.errorHandlerServiceRedirectOn404Options = {
      404: this.errorHandlerService.errorFnFactory[404]({
        redirect: true,
        redirectUrl: Sitemap.utils.notFound.path,
      }),
    };
  }

  /**
   * createReservation method
   * @param {ReservationCreate} data
   * @returns {Observable<string>}
   * @memberof ReservationService
   */
  createReservation(data: ReservationCreate): Observable<string> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Reservations`;

    const reservationDocuments = [
      {
        file: data.supportingDocument,
        documentType: this.appConfig._('documentTypes', 'reservationSupportingDocument'),
      },
    ];

    if (data.supportingDocument) {
      return this.attachmentService
        .uploadReservationDocumentsFiles(reservationDocuments, this.container, data.lotId, data.prospectId, data.dossierProspectId)
        .pipe(
          tap(({ result }) => (data.supportingDocumentId = result.files.file[0].id)),
          switchMap(() =>
            this.http
              .post<string>(url, data)
              .pipe(catchError(this.errorHandlerService.handleError<string>('ReservationsService', 'createReservation'))),
          ),
        );
    }

    return this.http
      .post<string>(url, data)
      .pipe(catchError(this.errorHandlerService.handleError<string>('ReservationService', 'createReservation')));
  }

  /**
   * getInfosOfPageReservation method
   *
   * @param {string} reservationId
   * @returns {Observable<ReservationCreate>}
   * @memberof ReservationService
   */
  getInfosOfPageReservation(reservationId: string): Observable<ReservationCreate> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Reservations/get-infos-reservation-page/${reservationId}`;

    // Redirection if 404, the reservation does not exist
    return this.http
      .get<ReservationCreate>(url)
      .pipe(
        catchError(
          this.errorHandlerService.handleError<ReservationCreate>(
            'ReservationService',
            'getInfosOfPageReservation',
            this.errorHandlerServiceRedirectOn404Options,
          ),
        ),
      );
  }

  /**
   * getInfosApprovalPopin method
   *
   * @param {string} reservationId
   * @returns {Observable<ReservationCreate>}
   * @memberof ReservationService
   */
  getInfosApprovalPopin(reservationId: string): Observable<ReservationCreate> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Reservations/get-infos-approval-popin/${reservationId}`;

    // Redirection if 404, the reservation does not exist
    return this.http
      .get<ReservationCreate>(url)
      .pipe(
        catchError(
          this.errorHandlerService.handleError<ReservationCreate>(
            'ReservationService',
            'getInfosApprovalPopin',
            this.errorHandlerServiceRedirectOn404Options,
          ),
        ),
      );
  }

  /**
   * sendApproval method
   *
   * @param {ApprovalData} data
   * @returns {Observable<any>}
   * @memberof ReservationService
   */
  sendApproval(data: ApprovalData): Observable<any> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Reservations/prereservation-approval`;

    return this.http
      .post<any>(url, data)
      .pipe(
        catchError(
          this.errorHandlerService.handleError<any>('ReservationService', 'sendApproval', this.errorHandlerServiceRedirectOn404Options),
        ),
      );
  }

  /**
   * cancelReservation method
   *
   * @param {ReservationCancel} data
   * @returns {Observable<any>}
   * @memberof ReservationService
   */
  cancelReservation(data: ReservationCancel): Observable<any> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Reservations/reservation-cancellation`;

    return this.http
      .post<any>(url, data)
      .pipe(catchError(this.errorHandlerService.handleError<any>('ReservationService', 'cancelReservation')));
  }

  cancelPreReservation(reservationId: number): Observable<string> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Reservations/prereservation-cancelation/${reservationId}`;

    return this.http
      .put<string>(url, {})
      .pipe(catchError(this.errorHandlerService.handleError<string>('ReservationService', 'cancelReservation')));
  }

  /**
   * accessElectronicSignatures method
   *
   * @returns {Observable<string>}
   * @memberof ReservationService
   */
  accessElectronicSignatures(): Observable<{ url: string }> {
    const uri = `${this.appConfig.getLoopbackApiUrl()}/Reservations/access-electronic-signatures`;

    return this.http
      .get<{ url: string }>(uri)
      .pipe(catchError(this.errorHandlerService.handleError<any>('ReservationService', 'accessElectronicSignatures')));
  }

  /**
   * signOnline method
   *
   * @param {number} reservationId
   * @returns {Observable<string>}
   * @memberof ReservationService
   */
  signOnline(reservationId: number): Observable<{ url: string }> {
    const uri = `${this.appConfig.getLoopbackApiUrl()}/Reservations/sign-contract-electronically/${reservationId}`;

    return this.http
      .get<{ url: string }>(uri)
      .pipe(catchError(this.errorHandlerService.handleError<any>('ReservationService', 'signOnline')));
  }

  /**
   * updateReservation method
   *
   * @param {string} reservationId
   * @param {object} data
   * @returns {Observable<any>}
   * @memberof ReservationService
   */
  updateReservation(reservationId: string, data: any): Observable<any> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Reservations/${reservationId}/update`;

    return this.http
      .post<ReservationCreate>(url, data)
      .pipe(catchError(this.errorHandlerService.handleError<any>('ReservationService', 'updateReservation')));
  }

  /**
   * getDocuments method
   *
   * @param {number} reservationId
   * @returns {Observable<DocumentResponse>}
   * @memberof ReservationService
   */
  getContractualDocumentPackage(reservationId: number): Observable<Array<DocumentResponse>> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Reservations/${reservationId}/contractual-document-package`;
    return this.http
      .get<Array<DocumentResponse>>(url)
      .pipe(
        catchError(this.errorHandlerService.handleError<Array<DocumentResponse>>('ReservationService', 'getContractualDocumentPackage')),
      );
  }

  /**
   * getTaxationList
   *
   * @returns {Observable<any>}
   * @memberof ReservationService
   */
  getTaxations(): Observable<Array<TaxationResponse>> {
    if (!this.taxationList) {
      const url = `${this.appConfig.getLoopbackApiUrl()}/Taxations`;

      return this.http.get<Array<TaxationResponse>>(url).pipe(
        tap((response) => (this.taxationList = response)),
        catchError(this.errorHandlerService.handleError<Array<TaxationResponse>>('programService', 'get')),
      );
    }

    return of(this.taxationList);
  }
}
