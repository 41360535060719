/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackbarComponent, SnackbarComponentData } from '../components/snackbar/snackbar.component';
import { SnackbarMessageType } from '../models/enums/snackbar-message-type.enum';
import { SnackbarMessage, SnackbarTexts } from '../models/SnackbarMessage';
import { I18nService } from './i18n.service';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  /**
   * Creates an instance of BannerInfoService.
   */
  currentSnackbarMessage: SnackbarMessage;

  snackbarMessageBuffer: Array<SnackbarMessage>;

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly i18nService: I18nService,
  ) {
    this.currentSnackbarMessage = undefined;
    this.snackbarMessageBuffer = [];
  }

  /**
   * errorI18n method as sendMessage shorthand
   * default : no translation
   *
   * @param {string} message
   * @memberof SnackbarService
   */
  errorI18n(message: string | SnackbarTexts, params: Array<any> = []): void {
    this.message(message, SnackbarMessageType.Error, true, params);
  }

  /**
   * error method as sendMessage shorthand
   * default : no translation
   *
   * @param {string} message
   * @param {boolean} isI18nToken
   * @memberof SnackbarService
   */
  error(message: string | SnackbarTexts, isI18nToken = false, i18nParams: Array<any> = [], isHtml = false): void {
    this.message(message, SnackbarMessageType.Error, isI18nToken, i18nParams, isHtml);
  }

  /**
   * infoI18n method as sendMessage shorthand
   * default : no translation
   *
   * @param {string} message
   * @memberof SnackbarService
   */
  infoI18n(message: string | SnackbarTexts, params: Array<any> = []): void {
    this.message(message, SnackbarMessageType.Info, true, params);
  }

  /**
   * info method as sendMessage shorthand
   * default : no translation
   *
   * @param {string} message
   * @param {boolean} isI18nToken
   * @memberof SnackbarService
   */
  info(message: string | SnackbarTexts, isI18nToken = false, i18nParams: Array<any> = [], isHtml = false): void {
    this.message(message, SnackbarMessageType.Info, isI18nToken, i18nParams, isHtml);
  }

  /**
   * warningI18n method as sendMessage shorthand
   * default : no translation
   *
   * @param {string} message
   * @memberof SnackbarService
   */
  warningI18n(message: string | SnackbarTexts, params: Array<any> = [], isHtml = false): void {
    this.message(message, SnackbarMessageType.Warning, true, params, isHtml);
  }

  /**
   * waring method as sendMessage shorthand
   * default : no translation
   *
   * @param {string} message
   * @param {boolean} isI18nToken
   * @memberof SnackbarService
   */
  warning(message: string | SnackbarTexts, isI18nToken = false, i18nParams: Array<any> = [], isHtml = false): void {
    this.message(message, SnackbarMessageType.Warning, isI18nToken, i18nParams, isHtml);
  }

  /**
   * message method as sendMessage shorthand
   * default : warning, no translation
   *
   * @param {string} message
   * @param {string} type
   * @param {boolean} isI18nToken
   * @memberof SnackbarService
   */
  message(
    message: string | SnackbarTexts,
    type: SnackbarMessageType,
    isI18nToken = false,
    i18nParams: Array<any> = [],
    isHtml = false,
  ): void {
    let messageData: SnackbarTexts = {};
    if (typeof message === 'string' || message instanceof String) {
      const textMessage: string = isI18nToken ? this.i18nService._(message as string, i18nParams) : (message as string);
      if (isHtml) {
        messageData.html = textMessage;
      } else {
        messageData.text = textMessage;
      }
    } else {
      messageData = message;
      if (isI18nToken) {
        messageData.html = messageData.html ? this.i18nService._(messageData.html, i18nParams) : undefined;
        messageData.text = messageData.text ? this.i18nService._(messageData.text, i18nParams) : undefined;
        messageData.title = messageData.title ? this.i18nService._(messageData.title, i18nParams) : undefined;
        messageData.linkTxt = messageData.linkTxt ? this.i18nService._(messageData.linkTxt, i18nParams) : undefined;
      }
    }
    const snackbarMessage: SnackbarMessage = {
      type,
      ...messageData,
    };
    this.sendMessage(snackbarMessage);
  }

  /**
   * sendMessage method, by default : 10 sec and acknowledgable true
   * put 0 in duration if you want an unlimited duration
   *
   * @param {SnackbarMessage} message
   * @memberof SnackbarService
   */
  sendMessage(message: SnackbarMessage): void {
    if (!message) {
      return;
    }

    if (this.currentSnackbarMessage) {
      // Message already displayed
      this.snackbarMessageBuffer.push(message);
    } else {
      // No message displayed
      this.currentSnackbarMessage = message;
      const snackbarOptions: {
        [name: string]: string | Array<string> | number;
      } = {};

      // Set duration
      // eslint-disable-next-line no-prototype-builtins
      if (message.hasOwnProperty('duration')) {
        snackbarOptions.duration = message.duration;
      }

      // Set style according to message type
      switch (message.type) {
        case SnackbarMessageType.Error:
          snackbarOptions.panelClass = ['snackbar-error'];
          break;
        case SnackbarMessageType.Warning:
          snackbarOptions.panelClass = ['snackbar-warning'];
          break;
        case SnackbarMessageType.Info:
          snackbarOptions.panelClass = ['snackbar-info'];
          break;
        case SnackbarMessageType.Success:
          snackbarOptions.panelClass = ['snackbar-success'];
          break;
        default:
          // If unknown type, use snackbar error style
          snackbarOptions.panelClass = ['snackbar-error'];
      }

      snackbarOptions.verticalPosition = 'top';
      snackbarOptions.horizontalPosition = 'right';

      const { text, html, linkTxt, link, title } = message;
      // html : If there is HTML content to show
      // text: If there is normal text to show
      // action: The button that will be showed
      const snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
        data: {
          html,
          text,
          link,
          linkTxt,
          title,
          action:
            // eslint-disable-next-line no-prototype-builtins
            message.hasOwnProperty('acknowledgeable') && !message.acknowledgeable ? undefined : 'x',
        } as SnackbarComponentData,
        ...snackbarOptions,
      });

      // Check if there is a message in queus
      snackBarRef.afterDismissed().subscribe(() => {
        this.currentSnackbarMessage = undefined;
        this.checkMessagesQueue();
      });
    }
  }

  sendErrorOccured(): void {
    const message: SnackbarMessage = {
      type: SnackbarMessageType.Error,
      text: this.i18nService._('Error_SnackBar_ErrorOccured'),
      acknowledgeable: true,
      duration: 0,
    };
    this.sendMessage(message);
  }

  /**
   * checkMessageQueue method
   *
   * @memberof SnackbarService
   */
  checkMessagesQueue(): void {
    // Check if there are lefting message in queue
    if (this.snackbarMessageBuffer.length > 0) {
      const message = this.snackbarMessageBuffer[0];
      this.snackbarMessageBuffer.shift();
      this.sendMessage(message);
    }
  }
}
