import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-person-details',
  templateUrl: './person-details.component.html',
  styleUrls: [],
  standalone: true,
  imports: [MatCardModule],
})
export class AppPersonDetailsComponent {
  @Input() title: string;
  @Input() firstName: string;
  @Input() lastName: string;
}
