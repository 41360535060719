import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../security/authguard';
import { Sitemap } from '../utils/models/Sitemap';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { LogContainerComponent } from './components/log-container/log-container.component';
import { UpdateAccountComponent } from './components/update-account/update-account.component';

const routes: Routes = [
  {
    path: Sitemap.utils.login.path,
    component: LogContainerComponent,
  },
  {
    path: Sitemap.utils.homepage.path,
    data: {
      snackbarIfNotConnected: Sitemap.utils.homepage.data.snackbarIfNotConnected,
    },
    component: HomepageComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.utils.changePassword.path,
    component: ChangePasswordComponent,
  },
  {
    path: Sitemap.accounts.registerById.path,
    component: CreateAccountComponent,
    data: {
      roles: Sitemap.accounts.registerById.data.roles,
    },
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.accounts.register.path,
    data: {
      roles: Sitemap.accounts.register.data.roles,
    },
    component: CreateAccountComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.accounts.validateById.path,
    data: {
      isValidation: true,
      roles: Sitemap.accounts.validateById.data.roles,
    },
    component: UpdateAccountComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.accounts.updateById.path,
    data: {
      roles: Sitemap.accounts.updateById.data.roles,
    },
    component: UpdateAccountComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
  declarations: [],
})
export class AccountsRoutingModule {}
