import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PageProgramHelperService } from '../page-program-helper.service';

import { ProgramDetail } from '../../../../utils/models/ProgramDetail';
import { PipesModule } from '../../../../utils/pipes/pipes.module';

@Component({
  selector: 'app-program-page-highlights',
  standalone: true,
  templateUrl: './program-page-highlights.component.html',
  styleUrls: ['./program-page-highlights.component.scss'],
  imports: [CommonModule, PipesModule],
})
export class ProgramPageHighlightsComponent {
  public program: ProgramDetail;

  constructor(pageProgramHelperService: PageProgramHelperService) {
    this.program = pageProgramHelperService.program;
  }
}
