<div class="text-base font-bold text-left mb-3">
  {{ i18nService._('Title_Form_SearchForm_Delivery') }}
</div>
<mat-chip-listbox>
  <mat-chip-option
    color="primary"
    *ngFor="let item of deliveryDateItems"
    class="mr-3 w-[130px]"
    [selected]="item.id === search.deliveryDateMonth"
    (click)="selectedItem('deliveryDateMonth', item.id)"
  >
    <div class="filter-chip-label">{{ getYearLabel(item.id) }}</div>
  </mat-chip-option>
</mat-chip-listbox>
