<app-interlocutor-contact
  *ngIf="responsibleBi"
  [contact]="responsibleBi"
  subtitle="{{ multipleContact ? i18nService._('Txt_Contact_BI_Offer') : '' }}"
></app-interlocutor-contact>
<app-interlocutor-contact
  *ngIf="responsibleNonBi"
  [contact]="responsibleNonBi"
  subtitle="{{ multipleContact ? i18nService._('Txt_Contact_Non_BI_Offer') : '' }}"
></app-interlocutor-contact>
