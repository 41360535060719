import { IsArray, IsEnum, IsInt, IsOptional, IsString } from 'class-validator';

import { PaternityCreateProperty } from '../paternity';
import { ProspectType } from '../prospect';

export class DossierCreate {
  @IsInt()
  prescriptorId: number;

  @IsArray()
  prospects: ProspectDto[];

  @IsArray()
  @IsOptional()
  paternities?: PaternityCreateProperty[];
}

export class ProspectDto {
  @IsString()
  @IsOptional()
  email?: string;

  @IsString()
  @IsOptional()
  civility?: 'Madame' | 'Monsieur';

  @IsString()
  @IsOptional()
  phoneNumber?: string;

  @IsString()
  @IsOptional()
  lastName?: string;

  @IsString()
  @IsOptional()
  firstName?: string;

  @IsString()
  @IsOptional()
  companyName?: string;

  @IsString()
  @IsOptional()
  SIRET?: string;

  @IsString()
  @IsOptional()
  country?: string;

  @IsString()
  @IsOptional()
  city?: string;

  @IsString()
  @IsOptional()
  postalCode?: string;

  @IsEnum(ProspectType)
  prospectType: ProspectType;
}
