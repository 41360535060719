import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { NgFor } from '@angular/common';

import { Step } from '../model/Progress';
import { ProgressStepComponent } from '../progress-step/progress-step.componet';

@Component({
  selector: 'app-vertical-progress',
  templateUrl: './vertical-progress.component.html',
  styleUrls: ['./vertical-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgFor, ProgressStepComponent],
})
export class VerticalProgressComponent {
  @Input() steps: Step[];
  @Input() keyPrefix = '';
}
