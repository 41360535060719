import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { DossierSearchPreferenceResponseDto, SearchDossierSearchPreferenceQuery } from '@commons-dto/dossier-prospect';

import { DataLayerApiService } from './data-layer-api.service';

@Injectable({ providedIn: 'root' })
export class DossierSearchPreferenceApiService extends DataLayerApiService {
  BASE_URL = 'prospect/';

  /**
   * Get dossier search preferences by dossierId
   *
   * @returns {DossierSearchPreferenceResponseDto[]}
   * @memberof DataLayerApiService
   */
  async getByDossierId(dossierId: number, searchQuery?: SearchDossierSearchPreferenceQuery): Promise<DossierSearchPreferenceResponseDto[]> {
    const path = `${this.BASE_URL}dossiers/${dossierId}/searchPreferences`;
    const res: Observable<DossierSearchPreferenceResponseDto[]> = await this.getBySearchQuery(path, searchQuery);
    return await lastValueFrom(res);
  }

  /**
   * Save dossier search preferences by dossierId
   *
   * @returns {void}
   * @memberof DataLayerApiService
   */
  async saveByDossierId(dossierId: number, dossierSearchPreferences: DossierSearchPreferenceResponseDto[]): Promise<void> {
    const path = `${this.BASE_URL}dossiers/${dossierId}/searchPreferences`;
    const res: Observable<void> = await this.put(path, dossierSearchPreferences);
    return await lastValueFrom(res);
  }

  /**
   * Upsert dossier search preferences by dossierId
   *
   * @returns {void}
   * @memberof DataLayerApiService
   */
  async upsertByDossierId(dossierId: number, dossierSearchPreferences: DossierSearchPreferenceResponseDto[]): Promise<void> {
    const path = `${this.BASE_URL}dossiers/${dossierId}/searchPreferences`;
    const res: Observable<void> = await this.patch(path, dossierSearchPreferences);
    return await lastValueFrom(res);
  }
}
