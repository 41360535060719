<!-- Company informations -->
<div class="grid grid-cols-2 gap-16 mb-2">
  <!-- Input Corporate Name -->
  <mat-form-field class="col-span-2 lg:col-span-1">
    <mat-label>{{ i18nService._('Txt_Placeholder_CorporateName') }}</mat-label>
    <input
      [formControl]="corporateNameControl"
      matInput
      maxlength="255"
      name="corporateName"
      data-cy="corporateName"
      required
      type="text"
    />
    <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
  </mat-form-field>
  <!-- Input Trading Name -->
  <mat-form-field class="col-span-2 lg:col-span-1">
    <mat-label>{{ i18nService._('Txt_Placeholder_TradingName') }}</mat-label>
    <input [formControl]="tradingNameControl" matInput maxlength="255" name="tradingName" data-cy="tradingName" required type="text" />
    <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
  </mat-form-field>
</div>

<app-dropdown-list-populated-with-other
  [parentForm]="parentForm"
  [referenceTable]="'LegalStructures'"
  [searchable]="false"
  [placeholder]="i18nService._('Txt_Placeholder_LegalStructure')"
  data-cy="legalStructure"
  [fieldName]="'legalStructure'"
  [isSubmit]="isSubmit"
  class="mb-2"
  [required]="true"
  [dataToInit]="recoveredInfo"
>
</app-dropdown-list-populated-with-other>

<!-- Select Professional Category -->
<app-dropdown-list-populated-with-other
  [parentForm]="parentForm"
  [referenceTable]="'ProfessionalCategories'"
  [required]="true"
  [searchable]="false"
  [placeholder]="i18nService._('Txt_Placeholder_ProfessionalCategory')"
  data-cy="professionalCategory"
  [fieldName]="'professionalCategory'"
  [isSubmit]="isSubmit"
  class="mb-2"
  [required]="true"
  [dataToInit]="recoveredInfo"
>
</app-dropdown-list-populated-with-other>

<!-- Input Home Entity -->

<div class="grid grid-cols-2 gap-16 mt-2 mb-1 pt-1">
  <mat-form-field class="col-span-2 lg:col-span-1 mb-3">
    <mat-label>{{ i18nService._('Txt_Placeholder_HomeEntity') }}</mat-label>
    <input type="text" name="homeEntity" [formControl]="homeEntityControl" matInput data-cy="homeEntity" maxlength="255" />
    <span class="icon-info txt-gray pr-2" matSuffix matTooltip="{{ i18nService._('Txt_Tooltip_BiggerCompany') }}"></span>
  </mat-form-field>
</div>

<!-- Select Company Size -->
<div class="text-base font-semibold mt-3">
  {{ i18nService._('Txt_Page_Form_RegisterCompany_CompanySize') }}
</div>
<app-chip-populated
  [referenceTable]="'CompanySizes'"
  [fieldName]="'companySize'"
  [parentForm]="parentForm"
  [isSubmit]="isSubmit"
  [dataToInit]="recoveredInfo"
></app-chip-populated>
<!-- End of Company informations -->

<hr class="w-5 my-12" />

<!-- Domiciliation informations -->
<app-postal-address [parentForm]="parentForm" [recoveredInfo]="recoveredInfo"></app-postal-address>

<!-- Address : Cedex -->
<div *ngIf="isFrenchCountry" class="grid grid-cols-2 gap-16 mt-3">
  <mat-form-field class="col-span-2 lg:col-span-1">
    <mat-label>{{ i18nService._('Txt_Placeholder_Cedex') }}</mat-label>
    <input matInput maxlength="255" [formControl]="cedexControl" name="cedex" data-cy="cedex" type="text" />
  </mat-form-field>
</div>
<!-- End of Company informations -->

<hr class="w-5 my-12" />

<!-- Other informations -->
<div *ngIf="isFrenchCountry">
  <!-- Input Siret -->
  <div class="grid grid-cols-2 gap-16 mb-3">
    <mat-form-field class="col-span-2 lg:col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_Siret') }}</mat-label>
      <input
        type="text"
        name="siret"
        [formControl]="siretControl"
        maxlength="14"
        matInput
        data-cy="siret"
        (keyup)="checkIsNumber($event)"
        required
      />
      <mat-error *ngIf="siretControl.errors">
        <span *ngIf="siretControl.errors.minlength">
          {{ i18nService._('Error_Field_NotEnoughNumber', ['14']) }}
        </span>
        <span *ngIf="siretControl.errors.isInteger">
          {{ i18nService._('Error_Field_NotEnoughNumber', ['14']) }}
        </span>
        <span *ngIf="siretControl.errors.required">
          {{ i18nService._('Error_Field_Required') }}
        </span>
        <span *ngIf="siretControl.errors.siretExist">
          {{ siretExistWarning }}
        </span>
      </mat-error>
    </mat-form-field>
  </div>
</div>

<div *ngIf="!isDeveloper" class="grid grid-cols-2 gap-16 mt-1 mb-1">
  <!-- Input Carte T -->
  <mat-form-field class="col-span-2 lg:col-span-1">
    <mat-label>{{ i18nService._('Txt_Placeholder_CarteTNumber') }}</mat-label>
    <input
      type="text"
      name="numCarteT"
      [formControl]="numCarteTControl"
      minlength="17"
      maxlength="17"
      matInput
      data-cy="carteTNumber"
      [required]="isFrenchCountry"
    />
    <span class="icon-info txt-gray pr-2" matSuffix matTooltip="{{ i18nService._('Txt_Tooltip_TCard') }}"></span>
    <mat-error *ngIf="numCarteTControl.errors">
      <span *ngIf="numCarteTControl.errors.minlength">
        {{ i18nService._('Error_Field_NotEnoughNumber', ['17']) }}
      </span>
      <span *ngIf="numCarteTControl.errors.required">
        {{ i18nService._('Error_Field_Required') }}
      </span>
    </mat-error>
  </mat-form-field>

  <!-- Input Carte T End Date -->
  <app-input-date
    class="col-span-2 lg:col-span-1"
    fieldName="finValiditeCarteT"
    [forcePick]="false"
    [parentForm]="parentForm"
    [placeholder]="i18nService._('Txt_Placeholder_CarteTEndDate')"
    [required]="isFrenchCountry"
  >
  </app-input-date>
</div>

<div *ngIf="isFrenchCountry">
  <!-- Select Code NAF -->
  <div class="grid grid-cols-2 gap-16 mb-2">
    <app-dropdown-list-populated
      class="col-span-2 lg:col-span-1"
      [parentForm]="parentForm"
      [searchable]="true"
      [items]="nafItems"
      data-cy="nafCode"
      [placeholder]="i18nService._('Txt_Placeholder_CodeNAF')"
      [fieldName]="'naf'"
      [isSubmit]="isSubmit"
      [required]="isFrenchCountry"
      [notTranslated]="true"
      [dataToInit]="recoveredInfo"
    >
    </app-dropdown-list-populated>
  </div>
</div>

<!-- Input Capital -->
<div class="grid grid-cols-2 gap-16 mt-2 mb-2">
  <mat-form-field class="col-span-2 lg:col-span-1">
    <mat-label>{{ i18nService._('Txt_Placeholder_Capital') }}</mat-label>
    <input
      appDecimalMask
      maxNumberDigitsBeforeSeparator="13"
      class="text-right"
      name="capital"
      [formControl]="capitalControl"
      matInput
      data-cy="capital"
      required
    />
    <span class="text-black px-2" matSuffix matTooltip="{{ i18nService._('Txt_Tooltip_TCard') }}">€</span>
    <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
  </mat-form-field>
</div>

<div *ngIf="isFrenchCountry" class="grid grid-cols-2 gap-16 mb-2">
  <!-- Commercial Court -->
  <app-dropdown-list-populated
    class="col-span-2 lg:col-span-1"
    [parentForm]="parentForm"
    [searchable]="true"
    [items]="commercialCourtItems"
    [placeholder]="i18nService._('Txt_Placeholder_CommercialCourt')"
    fieldName="commercialCourtInseeCode"
    bindValue="inseeCode"
    [isSubmit]="isSubmit"
    [required]="isFrenchCountry"
    [dataToInit]="recoveredInfo"
  >
  </app-dropdown-list-populated>
  <!-- Chamber of Commerce and Industry -->
  <app-dropdown-list-populated
    class="col-span-2 lg:col-span-1"
    [parentForm]="parentForm"
    [items]="cciItems"
    [searchable]="true"
    [placeholder]="i18nService._('Txt_Placeholder_CCI')"
    [fieldName]="'cci'"
    [isSubmit]="isSubmit"
    [required]="isFrenchCountry"
    [dataToInit]="recoveredInfo"
  >
  </app-dropdown-list-populated>
</div>

<!-- TVA -->
<div class="grid grid-cols-2 gap-16 mt-2 mb-3">
  <mat-form-field class="col-span-2 lg:col-span-1 mt-1">
    <mat-label>{{ i18nService._('Txt_Placeholder_TVANumber') }}</mat-label>
    <input type="text" name="intracommunityVAT" matInput data-cy="tvaNumber" maxlength="20" [formControl]="intraCommunityVatControl" />
  </mat-form-field>
</div>

<!-- End of Other informations -->

<!-- Next Step -->
<div class="flex w-1/5">
  <button type="button" class="mt-3" mat-stroked-button color="primary" data-cy="nextStep" matStepperNext (click)="submitStep()">
    {{ i18nService._('Txt_Button_NextStep') }}
  </button>
</div>
