import { LotResponse } from '../../../utils/models/LotResponse';

export interface StatusLotLabels {
  free: string;
  unavailable: string;
  optioned: string;
  reserved: string;
  prereserved: string;
  sold: string;
}

const canEditLot = (isValo: boolean, isImported: boolean, statusLabelFromAppConfig: StatusLotLabels, element: LotResponse): boolean => {
  const { isPublished, status, isPublishedProgram } = element;
  // RG_108.14.2
  // Check if user is not valorissimo profil and not imported program
  if (!isValo && isImported) {
    return false;
  }

  // Not imported program or user is valo

  // if program isNot published => can edit
  if (!isPublishedProgram) {
    return true;
  }

  // if lot is not published ( check if program is published before) => can edit
  if (isPublishedProgram && !isPublished) {
    return true;
  }

  return status === statusLabelFromAppConfig.free || status === statusLabelFromAppConfig.optioned;
};

const canPublishImportedUnpublishlot = (
  isValo: boolean,
  isImported: boolean,
  statusLabelFromAppConfig: StatusLotLabels,
  element: LotResponse,
): boolean => {
  const { isPublished, status, isPublishedProgram } = element;

  if (!isValo || !isPublishedProgram) {
    return false;
  }

  // Lot is publish with status FREE or Optioned
  return !isPublished && (status === statusLabelFromAppConfig.free || status === statusLabelFromAppConfig.optioned);
};

export { canEditLot, canPublishImportedUnpublishlot };
