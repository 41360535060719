import { Pipe, PipeTransform } from '@angular/core';

import { I18nService } from '../../utils/services/i18n.service';

@Pipe({
  name: 'time',
  standalone: true,
})
export class TimePipe implements PipeTransform {
  constructor(public readonly i18nService: I18nService) {}

  transform(value: number, type: 'string' | 'html' = 'string'): string {
    if (value === undefined) return '';

    const { hours, minutes, seconds } = this.convertToTimeFormat(value);

    return this.createTimeString(hours, minutes, seconds, value, type).trim();
  }

  private convertToTimeFormat(value: number) {
    const seconds = Math.floor(value % 60);
    const minutes = Math.floor((value / 60) % 60);
    const hours = Math.floor(value / 3600);

    return { hours, minutes, seconds };
  }

  private createTimeString(hours: number, minutes: number, seconds: number, totalRemainingTime: number, type: 'string' | 'html') {
    if (!totalRemainingTime) {
      return this.getExpireString(type);
    }

    return `${hours ? this.getTimeString('Hour', 'Hours', type, hours) : ''}
            ${this.getTimeString('Minute', 'Minutes', type, minutes, hours, seconds)}
            ${!hours ? this.getTimeString('Second', 'Seconds', type, seconds, minutes) : ''}`;
  }

  private getExpireString(type: 'string' | 'html'): string {
    const wrappedValue = this.htmlWrap('00', type);
    const wrappedMinute = this.htmlWrap(this.i18nService._('Txt_TimeMinute'), type);
    const wrappedSecond = this.htmlWrap(this.i18nService._('Txt_TimeSecond'), type);
    return `${wrappedValue} ${wrappedMinute} ${wrappedValue} ${wrappedSecond}`;
  }

  private getTimeString(single: string, plural: string, type: 'string' | 'html', value: number, ...conditions: number[]): string {
    const wrappedValue = this.htmlWrap(this.pad(value), type);
    const wrappedSingleText = this.htmlWrap(this.i18nService._('Txt_Time' + single), type);
    const wrappedPluralText = this.htmlWrap(this.i18nService._('Txt_Time' + plural), type);
    if (value > 1) {
      return `${wrappedValue} ${wrappedPluralText}`;
    } else if (value == 1) {
      return `${wrappedValue} ${wrappedSingleText}`;
    } else {
      return conditions.some((x) => x >= 1) ? `00 ${wrappedSingleText}` : '';
    }
  }

  pad(num: number): string {
    return String(num).padStart(2, '0');
  }

  htmlWrap(text: string, type: 'string' | 'html'): string {
    if (type === 'string') return text;

    return `<div>${text}</div>`;
  }
}
