import { Component, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';

import { FeatureFlagService } from './../../../feature-flag/feature-flag.service';
import { FooterComponent } from '../../../utils/components/footer/footer.component';
import { SearchModule } from '../../../search/search.module';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  standalone: true,
  imports: [NgIf, SearchModule, FooterComponent],
})
export class HomepageComponent implements OnInit {
  isNewHomepageEnabled = false;

  constructor(private readonly featureFlagService: FeatureFlagService) {}

  ngOnInit(): void {
    this.featureFlagService.isEnabled('new-homepage').then((res) => {
      this.isNewHomepageEnabled = res;
    });
  }
}
