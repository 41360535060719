import { Injectable } from '@angular/core';

import { ChoiceItem } from '../common/formly/formly-chipList/formly-chip-list.component';

@Injectable({
  providedIn: 'root',
})
export class DeliveryDateService {
  deliveryDates: ChoiceItem[];

  constructor() {
    this.deliveryDates = [
      { id: -1, label: 'DELIVERY_AVAILABLE' },
      { id: 0, label: 'DELIVERY_BEFORE_YEAR', labelParam: '2024' },
      { id: 1, label: 'DELIVERY_BEFORE_YEAR', labelParam: '2025' },
      { id: 2, label: 'DELIVERY_BEFORE_YEAR', labelParam: '2026' },
    ];
  }

  public getDeliveryDates(): ChoiceItem[] {
    return this.deliveryDates;
  }
}
