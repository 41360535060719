<form [formGroup]="formGroup" (ngSubmit)="submit()">
  <mat-dialog-content class="px-5 text-center overflow-visible">
    <div class="absolute cursor-pointer" style="top: -25px; right: -25px" (click)="onClose()">
      <div class="rounded-md bg-gray-500 w-12 h-12 flex items-center justify-center">
        <app-icon name="XMark" size="1.5rem" class="text-white flex items-center justify-center"></app-icon>
      </div>
    </div>
    <h2 class="text-center" mat-dialog-title>
      {{ i18nService._('PreReservationCreateForm_AddSecondaryLot') }}
    </h2>

    <div class="grid grid-cols-2 gap-x-6 gap-y-6 justify-center items-center my-8 px-6">
      <div class="flex flex-col items-start col-span-1">
        <label>{{ i18nService._('PreReservationCreateForm_SecondaryLotsTable_Number') }}</label>
        <input formControlName="selectedNumber" pInputText type="text" required />
      </div>
      <div class="flex flex-col items-start col-span-1">
        <label>{{ i18nService._('PreReservationCreateForm_SecondaryLotsTable_Reference') }}</label>
        <input pInputText formControlName="selectedReference" type="text" required />
      </div>
      <div class="flex flex-col items-start col-span-1">
        <label>{{ i18nService._('PreReservationCreateForm_SecondaryLotsTable_Type') }}</label>
        <p-dropdown
          formControlName="selectedType"
          class="w-full"
          [dropdownIcon]="'material-symbols-outlined arrow-drop-down'"
          [options]="data.secondaryLotTypes"
          [optionValue]="'id'"
          [showClear]="true"
          required
        >
          <ng-template let-selectedSecondaryLotType pTemplate="selectedItem">
            <div class="flex align-items-center" *ngIf="selectedSecondaryLotType">
              <div>{{ i18nService._(selectedSecondaryLotType.label) }}</div>
            </div>
          </ng-template>
          <ng-template let-secondaryLotType pTemplate="item">
            <div class="flex align-items-center">
              <div>{{ i18nService._(secondaryLotType.label) }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="flex flex-col items-start col-span-1">
        <label>{{ i18nService._('PreReservationCreateForm_SecondaryLotsTable_Price') }}</label>
        <p-inputNumber
          formControlName="selectedPrice"
          [currency]="'EUR'"
          [maxFractionDigits]="2"
          [minFractionDigits]="2"
          [suffix]="'€'"
          locale="fr-FR"
          mode="decimal"
          [useGrouping]="true"
          required
        ></p-inputNumber>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="flex flex-col">
    <div class="justify-center mb-1">
      <button (click)="onClose()" class="my-3 lg:mb-0 sm:mb-0 md:mb-0" mat-stroked-button color="primary">
        {{ i18nService._('PreReservationCreateForm_SecondaryLotsAddDialog_Cancel') }}
      </button>
      <button type="submit" class="my-3 lg:mb-0 sm:mb-0 md:mb-0" mat-flat-button color="primary" [disabled]="formGroup.invalid">
        {{ i18nService._('PreReservationCreateForm_SecondaryLotsAddDialog_Add') }}
      </button>
    </div>
  </mat-dialog-actions>
</form>
