/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { ReferenceTableData } from '../../../../utils/models/ReferenceTableData';
import { I18nService } from '../../../../utils/services/i18n.service';
import { DropdownListPopulatedComponent } from '../../../../utils/components/dropdown-list-populated/dropdown-list-populated.component';
import { InputDateComponent } from '../../../../utils/components/input-date/input-date.component';

@Component({
  selector: 'app-publication-strategies-dialog',
  templateUrl: './publication-strategies-dialog.component.html',
  styleUrls: ['./publication-strategies-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, FormsModule, ReactiveFormsModule, InputDateComponent, DropdownListPopulatedComponent, MatButtonModule],
})
export class PublicationStrategiesDialogComponent implements OnInit {
  public planningPublicationStrategyForm: UntypedFormGroup;

  public planningHourSelect: Array<ReferenceTableData> = [];
  public initData: any;

  constructor(
    public i18nService: I18nService,
    private readonly formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<PublicationStrategiesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    if (this.data) {
      this.initData = {
        planningHourId: this.data.hour,
      };
    }
    this.planningPublicationStrategyForm = this.formBuilder.group({
      planningDate: new UntypedFormControl(this.data ? this.data.toJSDate() : undefined, [Validators.required]),
    });
    for (let i = 0; i < 24; i++) {
      this.planningHourSelect.push({
        id: i,
        label: i < 10 ? `0${i}h00` : `${i}h00`,
      });
    }
    this.planningHourSelect.sort();
  }

  clickSaveButton(): void {
    if (this.planningPublicationStrategyForm.valid) {
      this.dialogRef.close(this.planningPublicationStrategyForm.value);
    }
  }
}
