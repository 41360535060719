<mat-button-toggle-group [(ngModel)]="showCheckboxSelection" (ngModelChange)="onShowCheckboxSelectionChange($event)">
  <mat-button-toggle [value]="TableCheckboxShow.ALL">
    <span
      [ngClass]="{ 'mr-4 matBadge-center': rowsId.length }"
      [matBadge]="rowsId.length"
      matBadgeOverlap="false"
      [matBadgeHidden]="!rowsId.length"
    >
      {{ i18nService._('Txt_ToggleButton_All') }}
    </span>
  </mat-button-toggle>

  <mat-button-toggle [value]="TableCheckboxShow.SELECTED">
    <span
      [ngClass]="{ 'mr-4 matBadge-center': rowsIdChecked.length }"
      [matBadge]="rowsIdChecked.length"
      matBadgeOverlap="false"
      [matBadgeHidden]="!rowsIdChecked.length"
    >
      {{ i18nService._('Txt_ToggleButton_Selected') }}
    </span>
  </mat-button-toggle>

  <mat-button-toggle [value]="TableCheckboxShow.NOT_SELECTED">
    <span
      [ngClass]="{ 'mr-4 matBadge-center': rowsIdNotChecked.length }"
      [matBadge]="rowsIdNotChecked.length"
      matBadgeOverlap="false"
      [matBadgeHidden]="!rowsIdNotChecked.length"
    >
      {{ i18nService._('Txt_ToggleButton_NotSelected') }}
    </span>
  </mat-button-toggle>
</mat-button-toggle-group>

<div class="grid grid-cols-8 gap:4 lg:gap-6">
  <div class="col-span-4">
    <mat-form-field class="fullW flex w-full">
      <mat-label>{{ i18nService._('Txt_Placeholder_Filter') }}</mat-label>
      <input [(ngModel)]="textFilter" matInput />
    </mat-form-field>
  </div>
</div>

<app-grid-tracking-table
  [columnDefs]="columnDefs"
  [tableLoader]="tableLoader"
  [pageSize]="pageSize"
  [tableHeight]="tableHeight"
  [gridOptions]="gridOptions"
  [externalFilter]="externalFilter"
  [preSelectedRows]="preSelectedRows"
  (selectedRows)="setSelectedRows($event)"
  (rows)="getRows($event)"
  [serverSide]="false"
  [textFilter]="textFilter"
  [isRowSelectable]="isRowSelectable"
>
</app-grid-tracking-table>
