<div class="text-base font-bold text-left mb-3">
  {{ i18nService._('Title_Form_SearchForm_Type') }}
</div>
<mat-chip-listbox [multiple]="true">
  <mat-chip-option
    color="primary"
    *ngFor="let item of tableItems"
    class="mr-3 text-xs w-[175px]"
    [selected]="search.programTypeIds && search.programTypeIds.includes(item.id)"
    (click)="selectItem(item)"
  >
    <mat-icon class="material-icons-outlined">{{ programTypeIcon[item.id] }}</mat-icon>
    <div class="filter-chip-label">{{ item.label }}</div>
  </mat-chip-option>
</mat-chip-listbox>
