import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

import { ReservationCreate } from '../../../utils/models/ReservationCreate';
import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-vendor-details',
  templateUrl: './vendor-details.component.html',
  styleUrls: [],
  standalone: true,
  imports: [MatCardModule, NgIf],
})
export class AppVendorDetailsComponent {
  @Input() reservationInformations: ReservationCreate;

  constructor(private i18nService: I18nService) {}

  translate(key: string): string {
    return this.i18nService._(key);
  }
}
