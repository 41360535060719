import { Component, EventEmitter, Output } from '@angular/core';
import { NgIf } from '@angular/common';

import { AbstractProgramPageLot } from '../abstract-program-page-lot';
import { ProgramPageLotDetailComponent } from '../program-page-lot-detail/program-page-lot-detail.component';
import { ProgramPageLotHeaderComponent } from '../program-page-lot-header/program-page-lot-header.component';

import { I18nService } from '../../../../../utils/services/i18n.service';
import { PageProgramHelperService } from '../../page-program-helper.service';
import { ProgramPageNavigationHeaderComponent } from '../../program-page-navigation-header/program-page-navigation-header.component';

@Component({
  selector: 'app-programe-page-lot-layout',
  templateUrl: './programe-page-lot-layout.component.html',
  styleUrls: ['./programe-page-lot-layout.component.scss'],
  standalone: true,
  imports: [ProgramPageNavigationHeaderComponent, NgIf, ProgramPageLotHeaderComponent, ProgramPageLotDetailComponent],
})
export class ProgramePageLotLayoutComponent extends AbstractProgramPageLot {
  @Output() readonly closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public readonly i18nService: I18nService,
    public pageProgramHelperService: PageProgramHelperService,
  ) {
    super();
  }

  get fees(): number {
    return this._pageLotService.getFees(this.lot);
  }

  goBack(): () => void {
    return () => {
      this.closeEvent.emit(true);
    };
  }

  initData() {
    // Do nothing
  }
}
