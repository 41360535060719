import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgFor } from '@angular/common';

import { ModalDocumentComponent } from '../modal/documents/modal-document.component';

import { GoogleTagManagerService } from './../../../utils/services/google-tag-manager.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { Documents } from '../../models/FormResponse';
import { SignatureDocumentService } from '../../services/signature-document.service';
import { IconComponent } from '../../../common/standalone/icon/icon.component';

@Component({
  selector: 'app-signature-document',
  templateUrl: './signature-document.component.html',
  styleUrls: ['./signature-document.component.scss'],
  standalone: true,
  imports: [IconComponent, NgFor],
})
export class SignatureDocumentComponent {
  @Input() documents: Documents[];
  @Input() dossierId: number;
  @Output() documentDialogClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public dialog: MatDialog,
    public readonly i18nService: I18nService,
    public _document: SignatureDocumentService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
  ) {}

  openModalDocument() {
    this._googleTagManagerService.pushTag({ event: 'esignature_add_file' });
    const dialogRef = this.dialog.open(ModalDocumentComponent, {
      width: '720px',
      data: { dossierId: this.dossierId, documentType: 'SPEC' },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.documentDialogClose.next(true);
    });
  }

  async deleteDoc(id: string) {
    this._document.delete(Number(id)).subscribe(() => (this.documents = this.documents.filter((doc) => doc.id !== id)));
  }

  downloadDoc(document: Documents): void {
    this._document.downloadBundleDocuments(document);
  }
}
