<!-- Responsible List -->
<div class="grid grid-cols-2 gap-16 mb-2">
  <app-dropdown-list-populated
    [dataToInit]="recoveredInfo"
    [fieldName]="'responsible'"
    [isSubmit]="isSubmit"
    [items]="responsibleList"
    [parentForm]="parentForm"
    [placeholder]="placeholderAffiliate"
    [required]="true"
    [searchable]="true"
  >
  </app-dropdown-list-populated>
</div>

<!-- Company Category -->
<div class="mt-2 mb-5">
  <div class="text-base mb-3 font-semibold">
    {{ i18nService._('Txt_Page_Form_RegisterCompany_CompanyCategory') }}{{ !this.isDeveloper ? ' *' : '' }}
  </div>
  <mat-radio-group
    (change)="companyCategoryChange($event)"
    [formControl]="companyCategoryControl"
    class="flex flex-row"
    name="companyCategoryId"
  >
    <mat-radio-button
      *ngFor="let companyCategory of companyCategories"
      [attr.data-cy]="companyCategory.label"
      [value]="companyCategory.id"
      color="primary"
    >
      {{ companyCategory.label }}
    </mat-radio-button>
  </mat-radio-group>
  <mat-error *ngIf="isSubmit && companyCategoryControl.errors">
    {{ i18nService._('Error_Field_Required') }}
  </mat-error>
</div>

<!-- Admin Profile -->
<div class="mb-4">
  <div class="text-base mb-3 font-semibold">
    {{ i18nService._('Txt_Page_Form_RegisterCompany_AdminProfile') }}
  </div>
  <div class="text-base mb-3 gap-16">
    {{ i18nService._('TxT_Program_Form_Administrator_Profil') }}
  </div>

  <mat-radio-group [formControl]="isHyperControl" class="flex flex-row" name="isHyper">
    <mat-radio-button [value]="true" color="primary" data-cy="developerHyper">
      {{ i18nService._(isDeveloper ? 'developerHyper' : 'contractorHyper') }}
    </mat-radio-button>
    <mat-radio-button [value]="false" color="primary" data-cy="contractorSuper">
      {{ i18nService._(isDeveloper ? 'developerSuper' : 'contractorSuper') }}
    </mat-radio-button>
  </mat-radio-group>
  <mat-error *ngIf="isHyperControl.errors && isSubmit">
    {{ i18nService._('Error_Field_Required') }}
  </mat-error>
</div>

<!-- Group Company -->
<div class="grid grid-cols-2 gap-16 mb-4">
  <app-dropdown-list-populated
    [dataToInit]="recoveredInfo"
    [fieldName]="'groupCompany'"
    [isSubmit]="isSubmit"
    [items]="groupCompanyList | translateList"
    [parentForm]="parentForm"
    [placeholder]="i18nService._('Txt_Placeholder_GroupCompany')"
    [required]="false"
    [searchable]="false"
  >
  </app-dropdown-list-populated>
</div>

<!-- Mandate -->
<div [formGroup]="mandateForm" class="mb-2">
  <div class="grid grid-cols-2 gap-16 mb-4">
    <mat-form-field>
      <mat-label>{{ i18nService._('Txt_Placeholder_MandateNumber') }}</mat-label>
      <input
        [formControl]="mandatNumberControl"
        data-cy="mandateNumber"
        matInput
        maxlength="255"
        name="mandatNumber"
        required
        type="text"
      />
      <mat-error *ngIf="mandatNumberControl.errors && isSubmit">
        {{ i18nService._('Error_Field_Required') }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="grid grid-cols-2 gap-16 mb-4">
    <app-input-date
      [forcePick]="false"
      [parentForm]="mandateForm"
      [placeholder]="i18nService._('Txt_Placeholder_MandateStartDate')"
      [required]="true"
      data-cy="mandateStartDate"
      class="col-span-1"
      fieldName="mandatStart"
    >
    </app-input-date>

    <app-input-date
      [forcePick]="false"
      [parentForm]="mandateForm"
      [placeholder]="i18nService._('Txt_Placeholder_MandateEndDate')"
      [required]="true"
      data-cy="mandateEndDate"
      class="col-span-1"
      fieldName="mandatEnd"
    >
    </app-input-date>
  </div>

  <!-- Remuneration -->
  <div *ngIf="!isDeveloper" class="grid grid-cols-2 gap-16 mb-2">
    <mat-form-field>
      <mat-label>{{ i18nService._('Txt_Placeholder_Remuneration') }}</mat-label>
      <input
        [formControl]="remunerationControl"
        appDecimalMask
        data-cy="remuneration"
        class="text-right"
        matInput
        maxNumberDigitsBeforeSeparator="3"
        name="remuneration"
        required
      />
      <span class="text-black px-2" matSuffix matTooltip="{{ i18nService._('Txt_Placeholder_Remuneration') }}">%</span>
      <mat-error *ngIf="remunerationControl.errors && isSubmit">
        {{ i18nService._('Error_Field_MustBeBetween0And100') }}
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Markup -->
  <div *ngIf="isDeveloper" class="grid grid-cols-2 gap-16 mb-2">
    <mat-form-field>
      <mat-label>{{ i18nService._('Txt_Placeholder_Markup') }}</mat-label>
      <input
        [formControl]="markupControl"
        appDecimalMask
        data-cy="markup"
        class="text-right"
        matInput
        maxNumberDigitsBeforeSeparator="3"
        name="markup"
        required
      />
      <span class="text-black px-2 font-bold" matSuffix matTooltip="{{ i18nService._('Txt_Placeholder_Markup') }}">%</span>
      <mat-error *ngIf="markupControl.errors && isSubmit">
        {{ i18nService._('Error_Field_MustBeBetween0And100') }}
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Signed Mandate -->
  <mat-checkbox [formControl]="signedMandatControl" data-cy="signedMandat" color="primary" name="signedMandat">
    {{ i18nService._('Txt_Checkbox_SignedMandat') }}
  </mat-checkbox>
  <mat-error *ngIf="isSubmit && signedMandatControl.errors">
    {{ i18nService._('Error_Field_Required') }}
  </mat-error>
</div>

<!-- Active Company -->
<div class="mb-2">
  <mat-checkbox [formControl]="activeCompanyControl" data-cy="activeCompany" color="primary" name="activeCompany">
    {{ i18nService._('Txt_Checkbox_ActiveCompany') }}
  </mat-checkbox>
  <mat-error *ngIf="isSubmit && activeCompanyControl.errors">
    {{ i18nService._('Error_Field_Required') }}
  </mat-error>
</div>

<div *ngIf="!isDeveloper" class="flex flex-col">
  <!-- Club Member -->
  <mat-checkbox
    *ngIf="!isClubMemberCheckboxDisabled"
    [formControl]="clubMemberControl"
    data-cy="clubMember"
    class="mb-2"
    color="primary"
    name="clubMember"
  >
    {{ i18nService._('Txt_Checkbox_ClubMember') }}
  </mat-checkbox>
  <!-- Boosted Fees -->
  <mat-checkbox [formControl]="boostedFeesControl" data-cy="noostedFeeds" class="mb-2" color="primary" name="boostedFees">
    {{ i18nService._('Txt_Checkbox_BoostedFeeds') }}
  </mat-checkbox>
  <!-- Only BI Offer -->
  <div class="mb-2 tooltip-line-break">
    <mat-checkbox [formControl]="onlyBIOfferControl" data-cy="onlyBIOffer" color="primary" name="onlyBIOffer">
      {{ i18nService._('Txt_Checkbox_OnlyBIOffer') }}
    </mat-checkbox>
    <span
      *ngIf="recoveredInfo && !recoveredInfo.canCheckOnlyBIOffer"
      class="icon-info txt-gray ml-2 pr-2"
      matSuffix
      matTooltip="{{ textTooltipOnlyBIOffer }}"
    ></span>
  </div>
  <!-- No automated deactivation -->
  <mat-checkbox
    [checked]="isKeyAccount"
    [formControl]="isAutomatedDeactivationControl"
    data-cy="isAutomatedDesactivation"
    class="mb-2"
    color="primary"
    name="isAutomatedDeactivation"
  >
    {{ i18nService._('Txt_Checkbox_IsAutomatedDeactivation') }}
  </mat-checkbox>
</div>

<div *ngIf="isDeveloper" class="flex flex-col">
  <div class="mt-4 mb-2">
    <div class="text-base font-semibold">
      {{ i18nService._('Txt_Title_RadioButton_PaternityAutomaticValidation') }}
      <span [matTooltip]="i18nService._('Txt_Tooltip_PaternityAutomaticValidation')" class="icon-info txt-gray pr-2" matSuffix></span>
    </div>

    <mat-radio-group
      [formControl]="paternityAutomaticValidationTypeIdControl"
      class="flex flex-row justify-start items-center mb-2 mt-2 min-h-80px"
      name="paternityAutomaticValidationTypeId"
    >
      <mat-radio-button *ngFor="let validationType of validationTypes" [value]="validationType.id" color="primary">
        {{ i18nService._(validationType.label + '_PATERNITY') }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="mb-2">
    <div class="text-base font-semibold">
      {{ i18nService._('Txt_Title_RadioButton_OptionAutomaticValidation') }}
      <span [matTooltip]="i18nService._('Txt_Tooltip_OptionAutomaticValidation')" class="icon-info txt-gray pr-2" matSuffix></span>
    </div>

    <mat-radio-group
      [formControl]="optionAutomaticValidationTypeIdControl"
      class="flex flex-row justify-start items-center mb-2 mt-2 min-h-80px"
      name="optionAutomaticValidationTypeId"
    >
      <mat-radio-button *ngFor="let validationType of validationTypes" [value]="validationType.id" color="primary">
        {{ i18nService._(validationType.label) }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <!-- Paternity Duration -->
  <div class="grid grid-cols-2 gap-16 mb-2">
    <mat-form-field>
      <mat-label>{{ i18nService._('Txt_Placeholder_PaternityDuration') }}</mat-label>
      <input
        (keyup)="checkIsNumber($event)"
        [formControl]="paternityDurationControl"
        matInput
        maxlength="3"
        name="paternityDuration"
        type="text"
      />
    </mat-form-field>
  </div>

  <mat-checkbox [formControl]="isAllowedToExtendOptionControl" class="mb-5" color="primary" name="isAllowedToExtendOption">
    {{ i18nService._('Txt_Checkbox_IsOptionDisagenge') }}
  </mat-checkbox>

  <mat-checkbox
    [disabled]="!isAllowedToExtendOptionControl.value"
    [formControl]="isAllowedToExtendOptionFromPreresaControl"
    class="mb-5"
    color="primary"
    name="isAllowedToExtendOptionFromPreresa"
  >
    {{ i18nService._('Txt_Checkbox_IsOptionFromPreresaDisagenge') }}
  </mat-checkbox>

  <mat-checkbox [formControl]="isEligibleDigitalSigningControl" class="mb-5" color="primary" name="isEligibleDigitalSigning">
    {{ i18nService._('Txt_Checkbox_IsEligibleDigitalSigning') }}
  </mat-checkbox>
  <mat-checkbox
    *ngIf="isEligibleDigitalSigningControl?.value === true"
    [formControl]="isValoMandateEsignControl"
    class="mb-5"
    color="primary"
    name="isEligibleDigitalSigning"
  >
    {{ i18nService._('Txt_Checkbox_IsValoMandateEsign') }}
  </mat-checkbox>

  <!-- SpecialOfferAmendmentNotaryFees -->
  <app-file-drop
    [attachmentName]="i18nService._('Txt_Placeholder_SpecialOfferAmendmentNotaryFees')"
    [fieldNameKey]="'SpecialOfferAmendmentNotaryFees'"
    [includingForm]="parentForm"
    [initialValue]="initFile['SpecialOfferAmendmentNotaryFees']"
    [isSubmit]="isSubmit"
    [required]="false"
    *ngIf="isEligibleDigitalSigningControl?.value === true"
    [validExt]="['application/vnd.openxmlformats-officedocument.wordprocessingml.document']"
    class="mb-2"
  >
  </app-file-drop>

  <!-- SpecialOfferAmendmentDiscount -->
  <app-file-drop
    [attachmentName]="i18nService._('Txt_Placeholder_SpecialOfferAmendmentDiscount')"
    [fieldNameKey]="'SpecialOfferAmendmentDiscount'"
    [includingForm]="parentForm"
    [initialValue]="initFile['SpecialOfferAmendmentDiscount']"
    [isSubmit]="isSubmit"
    [required]="false"
    *ngIf="isEligibleDigitalSigningControl?.value === true"
    [validExt]="['application/vnd.openxmlformats-officedocument.wordprocessingml.document']"
    class="mb-2"
  >
  </app-file-drop>

  <!-- documentList -->
  <app-file-drop
    [attachmentName]="i18nService._('Txt_Placeholder_documentList')"
    [fieldNameKey]="'documentList'"
    [includingForm]="parentForm"
    [initialValue]="initFile['documentList']"
    [isSubmit]="isSubmit"
    [required]="false"
    *ngIf="isEligibleDigitalSigningControl?.value === true"
    [validExt]="['application/pdf']"
    class="mb-2"
  >
  </app-file-drop>

  <!-- documentListLMNP -->
  <app-file-drop
    [attachmentName]="i18nService._('Txt_Placeholder_documentListLMNP')"
    [fieldNameKey]="'documentListLMNP'"
    [includingForm]="parentForm"
    [initialValue]="initFile['documentListLMNP']"
    [isSubmit]="isSubmit"
    [required]="false"
    *ngIf="isEligibleDigitalSigningControl?.value === true"
    [validExt]="['application/pdf']"
    class="mb-2"
  >
  </app-file-drop>

  <!-- customerFile -->
  <app-file-drop
    [attachmentName]="i18nService._('Txt_Placeholder_customerFile')"
    [fieldNameKey]="'customerFile'"
    [includingForm]="parentForm"
    [initialValue]="initFile['customerFile']"
    [isSubmit]="isSubmit"
    [required]="false"
    *ngIf="isEligibleDigitalSigningControl?.value === true"
    [validExt]="['application/vnd.openxmlformats-officedocument.wordprocessingml.document']"
    class="mb-2"
  >
  </app-file-drop>

  <!-- operatingModeAR24 -->
  <app-file-drop
    [attachmentName]="i18nService._('Txt_Placeholder_operatingModeAR24')"
    [fieldNameKey]="'operatingModeAR24'"
    [includingForm]="parentForm"
    [initialValue]="initFile['operatingModeAR24']"
    [isSubmit]="isSubmit"
    [required]="false"
    *ngIf="isEligibleDigitalSigningControl?.value === true"
    [validExt]="['application/pdf']"
    class="mb-2"
  >
  </app-file-drop>

  <div *ngIf="isEligibleDigitalSigningControl?.value === true && companySignatoryControl">
    <ng-container *ngIf="isValoMandateEsignControl.value === false">
      <!-- Civility -->
      <div class="text-base my-2 font-semibold">{{ i18nService._('Txt_Page_Form_RegisterCompany_Signatory') }}</div>
      <div class="flex flex-row justify-start items-center mb-2 mt-2 min-h-80px">
        <app-radio-button-populated
          [dataToInit]="previousSignatory"
          [fieldName]="'civility'"
          [isSubmit]="isSubmit"
          [parentForm]="companySignatoryControl"
          [referenceTable]="'Civilities'"
          [required]="true"
          class="flex"
        ></app-radio-button-populated>
      </div>

      <!-- LastName + Firstname -->
      <app-name-information [isSubmit]="isSubmit" [parentForm]="companySignatoryControl" class="w-full"></app-name-information>

      <!-- Email -->
      <div class="grid grid-cols-2 gap-x-16">
        <mat-form-field class="col-span-2 md:col-span-1 mb-3 flex fullW">
          <mat-label>{{ i18nService._('Txt_Placeholder_Email') }}</mat-label>
          <input [formControl]="companySignatoryControl.get('email')" data-cy="email" matInput maxlength="255" name="email" type="email" />
          <mat-error *ngIf="emailControl.errors">
            <span *ngIf="emailControl.errors.required">
              {{ i18nService._('Error_Field_Required') }}
            </span>
            <span *ngIf="!emailControl.errors.email && !emailControl.errors.required && !emailControl.errors.emailExist">
              {{ i18nService._('Error_Field_IncorrectEmail') }}
            </span>
            <span *ngIf="emailControl.errors.emailExist">
              {{ i18nService._('Error_Field_AccountEmailUsed') }}
            </span>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Mobile Phone + Desk Phone -->
      <div class="grid grid-cols-2 gap-x-16 mt-4 mb-5">
        <app-phone-number-information
          [isRequired]="true"
          [isSubmit]="isSubmit"
          [parentForm]="companySignatoryControl"
          data-cy="mobile"
          class="col-span-2 md:col-span-1 mb-3"
          fieldName="mobilePhone"
        ></app-phone-number-information>
      </div>
    </ng-container>
  </div>

  <!-- Committee Approbation Result -->
  <app-file-drop
    [attachmentName]="i18nService._('Txt_Placeholder_ApprovalComitteeResult')"
    [fieldNameKey]="'approvalComitteeResult'"
    [includingForm]="parentForm"
    [initialValue]="initFile['approvalComitteeResult']"
    [isSubmit]="isSubmit"
    [required]="false"
    class="mb-4"
  >
  </app-file-drop>

  <!-- Legal Informations -->
  <app-file-drop
    [attachmentName]="i18nService._('Txt_Placeholder_LegalNotice')"
    [fieldNameKey]="'legalNotice'"
    [includingForm]="parentForm"
    [initialValue]="initFile['legalNotice']"
    [isSubmit]="isSubmit"
    [required]="false"
    class="mb-4"
  >
  </app-file-drop>

  <!-- Description Title -->
  <div class="flex flex-row mb-2">
    <mat-form-field class="w-1/2">
      <mat-label>{{ i18nService._('Txt_Placeholder_DescriptionTitle') }}</mat-label>
      <input
        [formControl]="descriptionTitleControl"
        matInput
        maxlength="255"
        name="descriptionTitle"
        required
        type="text"
        data-cy="descriptionTitle"
      />
      <mat-error *ngIf="descriptionTitleControl.errors">
        {{ i18nService._('Error_Field_Required') }}
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Description -->
  <div class="flex flex-row mb-4">
    <mat-form-field class="w-1/2">
      <mat-label>{{ i18nService._('Txt_Placeholder_Description') }}</mat-label>
      <textarea [formControl]="descriptionControl" matInput maxlength="1000" name="description" required data-cy="description"> </textarea>
      <mat-error *ngIf="descriptionControl.errors">
        {{ i18nService._('Error_Field_Required') }}
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Company Logo -->
  <app-file-drop
    [attachmentName]="i18nService._('Txt_Placeholder_CompanyLogo')"
    [fieldNameKey]="'CompanyLogo'"
    [includingForm]="parentForm"
    [initialValue]="initFile['CompanyLogo']"
    [isSubmit]="isSubmit"
    [required]="isDeveloper"
    [validExt]="['image/png', 'image/jpg', 'image/jpeg']"
    class="mb-2"
  >
  </app-file-drop>
</div>

<!-- BO Comment -->
<div class="flex flex-row mb-2 mt-4">
  <mat-form-field class="w-1/2">
    <mat-label>{{ i18nService._('Txt_Placeholder_BOComment') }}</mat-label>
    <textarea [formControl]="boCommentControl" data-cy="boComment" matInput maxlength="2000" name="boComment"> </textarea>
  </mat-form-field>
</div>
