import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { finalize, Subscription } from 'rxjs';
import { DateTime, Interval } from 'luxon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { NgClass, NgFor, NgIf } from '@angular/common';

import { DocumentsBoxComponent } from '../documents-box/documents-box.component';

import { TagOption, TagTypeEnum } from '../../../design-system/model/tag-option';
import { HistoryLotFeesDialogComponent } from '../../../dialog/components/history-lot-fees-dialog/history-lot-fees-dialog.component';
import { OfferSpecialDialogComponent } from '../../../dialog/components/offer-special-dialog/offer-special-dialog.component';
import { ProgramService } from '../../../programs/services/program.service';
import { DocumentResponse } from '../../models/DocumentResponse';
import { LotResponse } from '../../models/LotResponse';
import { SpecialOfferDetailResponse } from '../../models/SpecialOfferDetailResponse';
import { SpecialOfferResponse } from '../../models/SpecialOfferResponse';
import { AppConfigService } from '../../services/app-config.service';
import { BasicFormatsService } from '../../services/basic-formats.service';
import { FileSystemService } from '../../services/file-system.service';
import { I18nService } from '../../services/i18n.service';
import { SharedLotService } from '../../services/shared-lot.service';
import { SnackbarService } from '../../services/snackbar.service';
import { ToggleFeesService } from '../../services/toggle-fees.service';
import { UserRoleService } from '../../services/user-role.service';
import { DesignSystemModule } from '../../../design-system/design-system.module';

@Component({
  selector: 'app-lot-details-panel',
  templateUrl: './lot-details-panel.component.html',
  styleUrls: ['./lot-details-panel.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    DocumentsBoxComponent,
    MatListModule,
    NgClass,
    MatDividerModule,
    NgFor,
    DesignSystemModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
  ],
})
export class LotDetailsPanelComponent implements OnInit, OnDestroy, OnChanges {
  public feesInformationExternalLink = 'https://intercom.help/aide/fr/articles/6029114-comment-connaitre-le-taux-d-honoraire-d-un-lot';
  /**
   * closePanel attibute
   *
   * @type {EventEmitter<void>}
   * @memberof LotDetailsPanelComponent
   */
  @Output() public readonly closePanel: EventEmitter<void> = new EventEmitter<void>();

  /**
   * companyLogo attibute
   *
   * @type {DocumentResponse}
   * @memberof LotDetailsPanelComponent
   */
  public companyLogo: DocumentResponse;

  /**
   * companyLogoImage attibute
   *
   * @type {string}
   * @memberof LotDetailsPanelComponent
   */
  public companyLogoImage: string;

  /**
   * documents attibute
   *
   * @type {Array<DocumentResponse>}
   * @memberof LotDetailsPanelComponent
   */
  public documents: Array<DocumentResponse>;

  /**
   * isLmpLmnpTaxation attibute
   *
   * @type {boolean}
   * @memberof LotDetailsPanelComponent
   */
  public isLmpLmnpTaxation: boolean;

  /**
   * isOtherTaxation attibute
   *
   * @type {boolean}
   * @memberof LotDetailsPanelComponent
   */
  public isOtherTaxation: boolean;

  /**
   * itemsCounter attibute
   *
   * @type {number}
   * @memberof LotDetailsPanelComponent
   */
  public itemsCounter: number;

  /**
   * lmnpLmpProfitability attibute
   *
   * @type {number}
   * @memberof LotDetailsPanelComponent
   */
  public lmnpLmpProfitability: number;

  /**
   * lot attibute
   *
   * @type {LotResponse}
   * @memberof LotDetailsPanelComponent
   */
  public lot: LotResponse;

  /**
   * lotId attibute
   *
   * @type {number}
   * @memberof LotDetailsPanelComponent
   */
  @Input() public lotId: number;

  /**
   * optionLotPriceId attibute
   *
   * @type {number}
   * @memberof LotDetailsPanelComponent
   */
  @Input() public optionLotPriceId: number;

  /**
   * perspective attibute
   *
   * @type {DocumentResponse}
   * @memberof LotDetailsPanelComponent
   */
  public perspective: DocumentResponse;

  /**
   * perspectiveImage attibute
   *
   * @type {string}
   * @memberof LotDetailsPanelComponent
   */
  public perspectiveImage: string;

  /**
   * pinelProfitability attibute
   *
   * @type {number}
   * @memberof LotDetailsPanelComponent
   */
  public pinelProfitability: number;

  /**
   * pinelRentingPrice attibute
   *
   * @type {number}
   * @memberof LotDetailsPanelComponent
   */
  public pinelRentingPrice: number;

  /**
   * plsProfitability attibute
   *
   * @type {number}
   * @memberof LotDetailsPanelComponent
   */
  public plsProfitability: number;

  /**
   * plsRentingPrice attibute
   *
   * @type {number}
   * @memberof LotDetailsPanelComponent
   */
  public plsRentingPrice: number;

  /**
   * programType attibute
   *
   * @type {string}
   * @memberof LotDetailsPanelComponent
   */
  public programType: string;

  /**
   * reservationId attibute
   *
   * @type {number}
   * @memberof LotDetailsPanelComponent
   */
  @Input() public reservationId: number;

  /**
   * taxationList attibute
   *
   * @type {string}
   * @memberof LotDetailsPanelComponent
   */
  public taxationList: string;

  /**
   * show / hide fees to customer
   */
  public hideFees: boolean;
  public loading: boolean;
  private readonly tearDownSubscription: Subscription = new Subscription();
  isValo: boolean;

  public specialOffers: Array<SpecialOfferResponse>;

  private static readonly BASE_COUNT = 5;
  private static readonly EXTRA_COUNT_FOR_SECONDARY_LOTS = 1;
  private static readonly EXTRA_COUNT_FOR_SELLING_PRICE = 1;
  private static readonly EXTRA_COUNT_FOR_RENT_PROFITABILITY = 2;
  private static readonly EXTRA_COUNT_FOR_LMP_LMNP_TAXATION = 12;
  private static readonly EXTRA_COUNT_FOR_OTHER_TAXATION = 4;

  constructor(
    public readonly basicFormatsService: BasicFormatsService,
    public readonly userRoleService: UserRoleService,
    public readonly i18nService: I18nService,
    public readonly appConfigService: AppConfigService,
    private readonly fileSystemService: FileSystemService,
    private readonly matDialog: MatDialog,
    private readonly sharedLotService: SharedLotService,
    private readonly snackbarService: SnackbarService,
    private readonly toggleFeesService: ToggleFeesService,
    private readonly programService: ProgramService,
    private readonly dialog: MatDialog,
  ) {}

  /**
   * Used to know if user can see fees
   *
   * @returns {number}
   * @memberof LotDetailsPanelComponent
   */
  get canUserSeeContractorFees(): boolean {
    // Developer not show fees
    // If user preference isFeesHidden then no show fees
    // If contractorFees is NaN (null, undefined ..etc) not show fees
    return (
      (this.userRoleService.isContractor() || this.userRoleService.isValo()) && !this.lot.isFeesHidden && !isNaN(this.lot.contractorFees)
    );
  }

  ngOnInit(): void {
    this.tearDownSubscription.add(this.toggleFeesService.toggleFeesState$.subscribe((state) => (this.hideFees = state)));
    this.isValo = this.userRoleService.isValo();
  }

  ngOnDestroy(): void {
    this.tearDownSubscription.unsubscribe();
  }

  /**
   * Get lot floor and orientation
   *
   * @param {LotResponse} lot
   * @returns {string}
   * @memberof LotDetailsPanelComponent
   */
  public getFloorAndOrientation(lot: LotResponse): string {
    if (!lot.lotOrientation || !lot.lotOrientation.label) {
      return `${lot.floor}`;
    }

    return `${lot.floor} / ${this.i18nService._(lot.lotOrientation.label)}`;
  }

  /**
   * Get postal code
   *
   * @returns {string}
   * @memberof LotDetailsPanelComponent
   */
  public getPostalCode(): string {
    return this.lot.program.postalCode ? ` (${this.lot.program.postalCode.slice(0, 2)})` : '';
  }

  /**
   * Get lot rooms and living space
   *
   * @param {LotResponse} lot
   * @returns {string}
   * @memberof LotDetailsPanelComponent
   */
  public getRoomsAndLivingSpace(lot: LotResponse): string {
    return (
      `${lot.rooms}` +
      ` ${
        lot.rooms <= 1
          ? this.i18nService._('Txt_Page_Program_Room').toLowerCase()
          : this.i18nService._('Txt_Page_Program_Rooms').toLowerCase()
      }` +
      ` / ${this.basicFormatsService.formatArea(lot.livingSpace)}`
    );
  }

  /**
   * Get lot type, number and delivery date
   *
   * @param {LotResponse} lot
   * @returns {string}
   * @memberof LotDetailsPanelComponent
   */
  public getTypeNumberAndDeliveryDate(lot: LotResponse): string {
    return (
      `${this.i18nService._(lot.programType.label)} n° ${lot.lotNumber}` +
      ` / Livraison : ${this.basicFormatsService.handleDeliveryDate(lot.deliveryDate)}`
    );
  }

  /**
   * ngOnChanges method
   *
   * @memberof LotDetailsPanelComponent
   */
  public ngOnChanges(): void {
    if (this.lotId) {
      this.loadLotDetails();
    }
  }

  private loadLotDetails(): void {
    this.loading = true;

    const lotDetailsSubscription = this.sharedLotService
      .getLotDetails(this.lotId, this.optionLotPriceId, this.reservationId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        (lot) => this.handleLotDetails(lot),
        () => this.snackbarService.sendErrorOccured(),
      );

    this.tearDownSubscription.add(lotDetailsSubscription);
  }

  private handleLotDetails(lot: LotResponse): void {
    this.lot = lot;
    this.resetTaxationFlags();

    this.processDocuments();
    this.processSpecialOffers();
    this.processTaxations();

    this.itemsCounter = this.countItems();
  }

  private resetTaxationFlags(): void {
    this.isLmpLmnpTaxation = false;
    this.isOtherTaxation = false;
  }

  private processDocuments(): void {
    this.documents = [...this.lot.documents, ...this.lot.program.documents, ...this.lot.program.company.documents];

    this.setCompanyLogoAndPerspective();
  }

  private setCompanyLogoAndPerspective(): void {
    const documentTypeCode = this.appConfigService.getAppConfig().documentTypeCode;

    this.companyLogo = this.documents.find((document) => document.type.code === documentTypeCode.CompanyLogo);
    this.perspective = this.documents.find((document) => document.type.code === documentTypeCode.perspective);

    this.companyLogoImage = this.companyLogo ? this.fileSystemService.getLogo(this.companyLogo) : null;
    this.perspectiveImage = this.perspective ? this.fileSystemService.getLogo(this.perspective) : null;
  }

  private processSpecialOffers(): void {
    this.specialOffers = this.extractSpecialOffers(this.lot, !!this.reservationId);

    if (this.specialOffers && this.specialOffers.length > 0) {
      this.specialOffers.forEach((specialOffer) => {
        if (specialOffer.document) {
          specialOffer.document.parameterText = [specialOffer.specialOfferText.title];
          this.documents.push(specialOffer.document);
        }
      });
    }
  }

  private processTaxations(): void {
    if (!this.lot.lotHasTaxations) return;

    this.taxationList = this.lot.lotHasTaxations.reduce((accumulator, lotHasTaxation) => {
      const taxationLabel = this.i18nService._(lotHasTaxation.taxation.label);
      return accumulator ? `${accumulator}, ${taxationLabel}` : taxationLabel;
    }, '');

    const taxationLabels = this.appConfigService.getAppConfig().taxationList;

    this.lot.lotHasTaxations.forEach((lotHasTaxation) => {
      switch (lotHasTaxation.taxation.label) {
        case taxationLabels.PINEL:
          this.pinelProfitability = lotHasTaxation.profitability;
          this.pinelRentingPrice = lotHasTaxation.rentingPrice;
          break;
        case taxationLabels.PLS:
          this.plsProfitability = lotHasTaxation.profitability;
          this.plsRentingPrice = lotHasTaxation.rentingPrice;
          break;
        case taxationLabels.LMNPLMP:
          this.isLmpLmnpTaxation = true;
          this.lmnpLmpProfitability = lotHasTaxation.profitability;
          break;
        default:
          if (
            [taxationLabels.MALRAUX, taxationLabels['DEFICIT FONCIER'], taxationLabels['MONUMENTS HISTORIQUES']].includes(
              lotHasTaxation.taxation.label,
            )
          ) {
            this.isOtherTaxation = true;
          }
          break;
      }
    });
  }

  private getStartDate(data: LotResponse, isReservation = false): DateTime {
    if (isReservation) {
      if (data?.reservations?.length) {
        return DateTime.fromJSDate(new Date(data.reservations[0].created));
      }
    }

    if (data?.optionsLotPrice?.length) {
      return DateTime.fromJSDate(new Date(data.optionsLotPrice[0].startDate));
    }

    return DateTime.invalid('Invalid date');
  }

  public extractSpecialOffers(data: LotResponse, isReservation = false): Array<SpecialOfferResponse> {
    const startDate = this.getStartDate(data, isReservation);

    if (!startDate.isValid) {
      return [];
    }

    return data.specialOffer.filter((offer) => {
      const offerStartDate = DateTime.fromJSDate(new Date(offer.startDate));
      const offerEndDate = DateTime.fromJSDate(new Date(offer.endDate));

      return Interval.fromDateTimes(offerStartDate, offerEndDate).contains(startDate);
    });
  }

  /**
   * openSpecialOffer method
   *
   * @param specialOffer
   */
  public openSpecialOffer(specialOffer: SpecialOfferResponse): void {
    if (specialOffer) {
      const data: MatDialogConfig<SpecialOfferDetailResponse> = {
        autoFocus: false,
        data: {
          description: specialOffer.specialOfferText.description,
          endDate: specialOffer.endDate,
          id: specialOffer.id,
          legalNotice: specialOffer.specialOfferText.legalNotice,
          refSpecialOffer: specialOffer.refSpecialOffer,
          startDate: specialOffer.startDate,
          title: specialOffer.specialOfferText.title,
        },
      };
      this.matDialog.open(OfferSpecialDialogComponent, data);
    }
  }

  getTagOption(fees: number): TagOption {
    const tagOption = new TagOption(TagTypeEnum.FEES);
    tagOption.text = `${fees}%`;

    return tagOption;
  }

  openFeeHistory() {
    const data: MatDialogConfig<{ lotId: number; lotNumber: string }> = {
      autoFocus: false,
      data: { lotId: this.lotId, lotNumber: this.lot.lotNumber },
    };
    this.dialog.open(HistoryLotFeesDialogComponent, data);
  }

  /**
   * Counts the number of relevant items for the LotDetailsPanelComponent.
   *
   * @returns {number} The count of relevant items.
   */
  private countItems(): number {
    let itemCount = LotDetailsPanelComponent.BASE_COUNT;

    itemCount += this.countIfPresent(this.lot.lotAreas);
    itemCount += this.countIfNotEmpty(this.lot.secondaryLots, LotDetailsPanelComponent.EXTRA_COUNT_FOR_SECONDARY_LOTS);
    itemCount += this.lot.reducedTotalSellingPriceIT ? LotDetailsPanelComponent.EXTRA_COUNT_FOR_SELLING_PRICE : 0;
    itemCount += this.hasRentingPriceAndProfitability() ? LotDetailsPanelComponent.EXTRA_COUNT_FOR_RENT_PROFITABILITY : 0;
    itemCount += this.isLmpLmnpTaxation ? LotDetailsPanelComponent.EXTRA_COUNT_FOR_LMP_LMNP_TAXATION : 0;
    itemCount += this.isOtherTaxation ? LotDetailsPanelComponent.EXTRA_COUNT_FOR_OTHER_TAXATION : 0;

    return itemCount;
  }

  private countIfPresent(items?: any[]): number {
    return items?.length ?? 0;
  }

  private countIfNotEmpty(items: any[], count: number): number {
    return items?.length ? count : 0;
  }

  private hasRentingPriceAndProfitability(): boolean {
    const isPinelValid = Boolean(this.pinelRentingPrice && this.pinelProfitability);
    const isPlsValid = Boolean(this.plsRentingPrice && this.plsProfitability);
    return isPinelValid || isPlsValid;
  }
}
