import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { NgIf } from '@angular/common';

import { I18nService } from '../../../utils/services/i18n.service';
import { HistoryLotFeesService } from '../../../lot/service/history-lot-fees.service';
import { BasicFormatsService } from '../../../utils/services/basic-formats.service';
import { AlertEnum } from '../../../utils/models/enums/alert.enum';
import { AlertComponent } from '../../../utils/components/alert/alert.component';

interface LotFeesTabeDataType {
  created: string;
  rate: string;
}

@Component({
  selector: 'app-history-lot-fees-dialog',
  templateUrl: './history-lot-fees-dialog.component.html',
  styleUrls: ['./history-lot-fees-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, AlertComponent, NgIf, MatTableModule, MatPaginatorModule],
})
export class HistoryLotFeesDialogComponent implements OnInit, AfterViewInit {
  public lotFeesHistoryList: LotFeesTabeDataType[];
  public alertEnum = AlertEnum;
  dataSource: MatTableDataSource<LotFeesTabeDataType> = new MatTableDataSource<LotFeesTabeDataType>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = ['created', 'rate'];
  private _lotId: number;

  constructor(
    public readonly i18nService: I18nService,
    private readonly _historyLotFeesService: HistoryLotFeesService,
    @Inject(MAT_DIALOG_DATA) public dialogData: { lotId: number; lotNumber: string },
    private readonly _basicFormatsService: BasicFormatsService,
  ) {}

  ngOnInit() {
    this._lotId = this.dialogData.lotId;
    this._historyLotFeesService.getFeesHistoryByLot(this._lotId).subscribe((lotFeesHistoryList) => {
      this.lotFeesHistoryList = lotFeesHistoryList.map((item) => {
        return {
          created: this._basicFormatsService.fromIsoTime(item.created, 'dd MMM yyyy'),
          rate: item.rate ? this._basicFormatsService.formatPercentage(item.rate / 100, 0, 2, 2) : '-',
        };
      });

      this.dataSource.data = this.lotFeesHistoryList;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
}
