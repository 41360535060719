<label *ngIf="label && name" [ngClass]="labelCssClass">
  {{ label }}
  <span *ngIf="required"> *</span>
</label>
<div [ngClass]="styleClass" class="flex gap-2">
  <p-inputNumber #dayInput (onInput)="handlerInput($event)" [(ngModel)]="day" [max]="31" [min]="0" class="w-14" name="day"></p-inputNumber>
  <p-inputNumber
    #monthInput
    (onInput)="handlerInput($event)"
    [(ngModel)]="month"
    [max]="12"
    [min]="0"
    class="w-14"
    name="month"
  ></p-inputNumber>
  <p-inputNumber
    #yearInput
    (onInput)="handlerInput($event)"
    [(ngModel)]="year"
    [max]="9999"
    [min]="0"
    class="w-20"
    name="year"
    [useGrouping]="false"
  ></p-inputNumber>
</div>
