import { Component } from '@angular/core';

import { AbstractSearchFilter } from '../abstract-search-filter';

import { FLOOR_ITEMS } from '../../../../utils/models/app-constant';
import { I18nService } from '../../../../utils/services/i18n.service';
import { CheckboxPopulatedComponent } from '../../../../utils/components/checkbox-populated/checkbox-populated.component';

@Component({
  selector: 'app-search-filter-floor',
  templateUrl: './search-filter-floor.component.html',
  styleUrls: ['./search-filter-floor.component.scss'],
  standalone: true,
  imports: [CheckboxPopulatedComponent],
})
export class SearchFilterFloorComponent extends AbstractSearchFilter {
  /**
   * RG_95.3.1 : Room items
   */
  readonly floorItems = FLOOR_ITEMS;

  constructor(public readonly i18nService: I18nService) {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectedFloors($event: any) {
    this.search.nbFloors = $event;
    this._searchFormUtilsService.setSearchFilter({ where: this.search }, this.waitForSearch);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected initData() {}
}
