import { Component, Input } from '@angular/core';

import { AppIconsName } from '../../standalone/icon/utils/app-icon-names';
import { IconComponent } from '../../standalone/icon/icon.component';

@Component({
  selector: 'app-form-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  standalone: true,
  imports: [IconComponent],
})
export class FormLinkComponent {
  /**
   * Variable for label
   *
   * @type {string}
   * @memberof FormLinkComponent
   */
  @Input() label: string;

  /**
   * Variable for icon
   *
   * @type {string}
   * @memberof FormLinkComponent
   */
  @Input() icon: AppIconsName;

  /**
   * Variable for link
   *
   * @type {string}
   * @memberof FormLinkComponent
   */
  @Input() link: string;
}
