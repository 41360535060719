import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from 'apps/valo-front/src/app/utils/utils.module';

import { PipesModule } from './../../../../utils/pipes/pipes.module';
import { DossierProspectRouteService } from '../../dossier-prospect/dossier-prospect.route.service';

@Component({
  selector: 'app-dossier-prospect-dashboard-completion',
  standalone: true,
  templateUrl: './dossier-prospect-dashboard-completion.component.html',
  styleUrls: ['./dossier-prospect-dashboard-completion.component.scss'],
  imports: [CommonModule, UtilsModule, PipesModule],
})
export class DossierProspectDashboardCompletionComponent {
  /**
   * Complete
   *
   * @type {boolean}
   * @memberof DossierProspectDashboardCompletionComponent
   */
  @Input() isDossierComplete: boolean;
  @Input() dossierId: number;

  constructor(private readonly dossierProspectRouteService: DossierProspectRouteService) {}

  updateDossier() {
    this.dossierProspectRouteService.goToEdit(this.dossierId);
  }
}
