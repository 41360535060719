import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { TooltipModule } from 'primeng/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';

interface PrimengInputNumberProps extends FormlyFieldProps {
  mode?: 'decimal' | 'currency' | undefined;
  local?: string;
  currency?: string;
  maxFractionDigits?: number;
  minFractionDigits?: number;
  prefix?: string;
  suffix?: string;
  useGrouping?: boolean;
  tooltip?: string;
  inputStyleClass?: string;
}

@Component({
  selector: 'app-formly-input-number',
  templateUrl: './formly-input-number.component.html',
  styleUrls: ['./formly-input-number.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [InputNumberModule, FormsModule, ReactiveFormsModule, TooltipModule],
})
export class FormlyInputNumberComponent extends FieldType<FieldTypeConfig<PrimengInputNumberProps>> {}
