import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { ProgramsTrackingTableServiceResponse } from '../models/ProgramsTrackingTableServiceResponse';
import { AbstractAdvancedTrackingTableService } from './abstract-advanced-tracking-table.service';
import { ProgramsTrackingTableItem } from '../models/ProgramsTrackingTableItem';

import { ProgramApiService } from '../../adapters/program-api.service';
import { TableLoader, TableResponse } from '../../table/interfaces/DataLoader';
import { AppConfigService } from '../../utils/services/app-config.service';
import { CacheService } from '../../utils/services/cache.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramsTrackingTableService
  extends AbstractAdvancedTrackingTableService<ProgramsTrackingTableServiceResponse>
  implements TableLoader<ProgramsTrackingTableItem>
{
  public url = 'Programs/tracking-table/programs';
  protected cacheName = 'tracking-programs';

  constructor(
    public readonly appConfig: AppConfigService,
    public readonly errorHandlerService: ErrorHandlerService,
    public readonly http: HttpClient,
    public readonly cacheService: CacheService,
    private programApiService: ProgramApiService,
  ) {
    super(appConfig, errorHandlerService, http, cacheService);
  }

  async get(params): Promise<TableResponse<ProgramsTrackingTableItem>> {
    const res = this.programApiService.getPrograms(params);
    return await lastValueFrom(res);
  }

  async getById(id: number): Promise<ProgramsTrackingTableItem> {
    const res = this.programApiService.getProgramById(id);
    return await lastValueFrom(res);
  }
}
