import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { NgClass } from '@angular/common';

import { DateComponent } from '../../form/date/date.component';

@Component({
  selector: 'app-formly-date',
  templateUrl: './formly-date.component.html',
  styleUrls: ['./formly-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DateComponent, NgClass],
})
export class FormlyDateComponent extends FieldType<FieldTypeConfig> implements OnInit {
  public ngOnInit(): void {
    this.formControl.valueChanges.subscribe((value) => {
      this.field.props.date = value;
    });
  }
}
