import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { AppConfigService } from '../services/app-config.service';

export const isEmailValidators = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors => {
    // If no value, no error
    if (!control.value) {
      return undefined;
    }

    const appConfigService = new AppConfigService();
    const rightEmail = appConfigService.appConfig.mailregex.test(control.value);

    return rightEmail ? undefined : { email: rightEmail };
  };
};
