<mat-card appearance="outlined" *ngIf="isUserValorrisimo" class="mt-3">
  <mat-card-title class="text-lg mx-auto mb-3">
    {{ i18nService._('Title_reservation_record') }}
  </mat-card-title>
  <mat-card-content>
    <div class="grid grid-cols-2 gap-4 mb-3">
      <!-- Datepicker reception reservation date -->
      <app-input-date
        class="col-span-1"
        fieldName="valoReservationFileReceiptDate"
        [placeholder]="i18nService._('Txt_ReservationFormStepTwo_valoReservationFileReceiptDate')"
        (dateChange)="clearFormValue('valoReservationFileReceiptDate', 'reservationDocument')"
        [parentForm]="stepTwoForm"
        [readonly]="false"
        [required]="true"
      >
      </app-input-date>
      <div class="col-span-1" *ngIf="isFormValid('valoReservationFileReceiptDate')">
        <div class="mb-2">
          <app-file-drop
            class="no-margin-top"
            fieldNameKey="reservationFile"
            [includingForm]="stepTwoForm"
            [attachmentName]="i18nService._('Txt_Reservation_FormSupportingDocumentLabel')"
            fileRequiredErrorI18nToken="Error_Field_SupportingDocumentRequired"
            [required]="!isCanceled"
            [isDisabled]="isCanceled"
            [initialValue]="resaInformation.docs[documentTypes.reservationFile]"
            [maxFileSize]="104857600"
            [validExt]="['application/pdf']"
          >
          </app-file-drop>
        </div>

        <div>
          <app-file-drop
            fieldNameKey="reservationAmmendmentDocument"
            [includingForm]="stepTwoForm"
            [attachmentName]="i18nService._('Txt_Reservation_Form_ammendment_document_label')"
            fileRequiredErrorI18nToken="Error_Field_SupportingDocumentRequired"
            [isDisabled]="isCanceled"
            [initialValue]="resaInformation.docs[documentTypes.reservationAmmendmentDocument]"
            [maxFileSize]="104857600"
            [validExt]="['application/pdf']"
          >
          </app-file-drop>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4 mb-2">
      <!-- Datepicker validation reservation date -->
      <div class="col-span-1">
        <app-input-date
          class="input-date-w100-info"
          fieldName="valoReservationValidationDate"
          [placeholder]="i18nService._('Txt_ReservationFormStepTwo_valoReservationValidationDate')"
          [parentForm]="stepTwoForm"
          [readonly]="false"
        >
        </app-input-date>
        <span matSuffix class="icon-info txt-gray pl-2" matTooltip="{{ i18nService._('Txt_Tooltip_valoReservationValidationDate') }}">
        </span>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4 my-4">
      <div class="col-span-1 pl-2">
        <div class="text-base mb-3 font-semibold pl-3">
          {{ i18nService._('Txt_ReservationFormStepTwo_isAcceptedDeveloper') }}
        </div>
        <mat-radio-group class="flex flex-row" [formControl]="isAcceptedDeveloper">
          <mat-radio-button class="flex" name="isAccepted" [value]="true" color="primary">
            {{ i18nService._('Txt_RadioButton_Accept') }}
          </mat-radio-button>
          <mat-radio-button class="flex" name="isAccepted" [value]="false" color="primary">
            {{ i18nService._('Txt_RadioButton_Refuse') }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <ng-container
        *ngIf="
          isUserValorrisimo && (isAcceptedDeveloper.touched || isAcceptedDeveloper.value === true || isAcceptedDeveloper.value === false)
        "
      >
        <app-input-date
          class="col-span-1"
          [fieldName]="isAcceptedDeveloper.value ? 'developerReservationValidationDate' : 'developerReservationRejectionDate'"
          [parentForm]="stepTwoForm"
          [placeholder]="
            i18nService._(
              isAcceptedDeveloper.value
                ? 'Txt_ReservationFormStepTwo_developerReservationValidationDate'
                : 'Txt_ReservationFormStepTwo_developerReservationRejectionDate'
            )
          "
          [readonly]="isNotarySignatureObtained"
          [required]="isAcceptedDeveloper.touched || isAcceptedDeveloper.value === true || isAcceptedDeveloper.value === false"
          (dateChange)="onDeveloperReservationRejectionDateValueChange($event)"
        >
        </app-input-date>
      </ng-container>
    </div>

    <div class="grid grid-cols-2 gap-4 mb-3">
      <!-- Datepicker sruResevationNotificationDate date -->
      <app-input-date
        class="col-span-1"
        fieldName="sruResevationNotificationDate"
        [parentForm]="stepTwoForm"
        [placeholder]="i18nService._('Txt_ReservationFormStepTwo_sruResevationNotificationDate')"
        [readonly]="false"
      >
      </app-input-date>
      <!-- Datepicker sending reservation date -->
      <div class="col-span-1">
        <!-- Change from Mo to Mio for maxFileSize (VALO-3657) -->
        <app-file-drop
          *ngIf="isFormValid('sruResevationNotificationDate')"
          fieldNameKey="sruDocument"
          [includingForm]="stepTwoForm"
          [attachmentName]="i18nService._('Txt_Reservation_Form_SRU_label')"
          fileRequiredErrorI18nToken="Error_Field_SupportingDocumentRequired"
          [isDisabled]="isCanceled"
          [initialValue]="resaInformation.docs[documentTypes.sruDocument]"
          [maxFileSize]="104857600"
          [validExt]="['application/pdf']"
        >
        </app-file-drop>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="mt-3">
  <mat-card-title class="text-lg mx-auto mb-3">
    {{ i18nService._('Title_reservation_founding') }}
  </mat-card-title>
  <mat-card-content>
    <div class="grid grid-cols-2 gap-4 mb-2 row-column-file-drop">
      <!-- Datepicker principleAgreementDate date -->
      <app-input-date
        class="col-span-1 flex flex-row"
        fieldName="principleAgreementDate"
        [parentForm]="stepTwoForm"
        [placeholder]="i18nService._('Txt_ReservationFormStepTwo_principleAgreementDate')"
        [readonly]="false"
      >
      </app-input-date>
      <div class="col-span-1">
        <!-- Change from Mo to Mio for maxFileSize (VALO-3657) -->
        <app-file-drop
          *ngIf="isFormValid('principleAgreementDate')"
          fieldNameKey="principeAccordDocument"
          [includingForm]="stepTwoForm"
          [attachmentName]="i18nService._('Txt_Reservation_Form_principe_accord_label')"
          fileRequiredErrorI18nToken="Error_Field_SupportingDocumentRequired"
          [isDisabled]="isCanceled"
          [initialValue]="resaInformation.docs[documentTypes.principeAccordDocument]"
          [maxFileSize]="104857600"
          [validExt]="['application/pdf']"
        >
        </app-file-drop>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4 mb-3 row-column-file-drop">
      <!-- Datepicker loanOfferDate -->
      <app-input-date
        class="col-span-1"
        fieldName="loanOfferDate"
        [parentForm]="stepTwoForm"
        [placeholder]="i18nService._('Txt_ReservationFormStepTwo_loanOfferDate')"
        [readonly]="false"
      >
      </app-input-date>
      <div class="col-span-1">
        <!-- Change from Mo to Mio for maxFileSize (VALO-3657) -->
        <app-file-drop
          *ngIf="isFormValid('loanOfferDate')"
          fieldNameKey="loanOfferDocument"
          [includingForm]="stepTwoForm"
          [attachmentName]="i18nService._('Txt_Reservation_Form_loanOfferDocument_label')"
          fileRequiredErrorI18nToken="Error_Field_SupportingDocumentRequired"
          [isDisabled]="isCanceled"
          [initialValue]="resaInformation.docs[documentTypes.loanOfferDocument]"
          [maxFileSize]="104857600"
          [validExt]="['application/pdf']"
        >
        </app-file-drop>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4 mb-2">
      <!-- Datepicker estimatedSaleDate date -->
      <app-input-date
        class="col-span-1"
        fieldName="estimatedSaleDate"
        [parentForm]="stepTwoForm"
        [placeholder]="i18nService._('Txt_ReservationFormStepTwo_estimatedSaleDate')"
        [readonly]="false"
      >
      </app-input-date>
    </div>
    <div class="grid grid-cols-2 gap-4 mb-3">
      <!-- Datepicker notaryMeetingDate date -->
      <app-input-date
        class="col-span-1"
        fieldName="notaryMeetingDate"
        [parentForm]="stepTwoForm"
        [placeholder]="i18nService._('Txt_ReservationFormStepTwo_notaryMeetingDate')"
        [readonly]="false"
      >
      </app-input-date>
    </div>

    <div class="grid grid-cols-2 gap-4 mb-3 row-column-file-drop">
      <!-- Datepicker notarySignatureDate date -->
      <app-input-date
        class="col-span-1"
        fieldName="notarySignatureDate"
        [parentForm]="stepTwoForm"
        [placeholder]="i18nService._('Txt_ReservationFormStepTwo_notarySignatureDate')"
        [readonly]="false"
        (dateChange)="onNotarySignatureDateValueChange($event)"
      >
      </app-input-date>
      <div class="col-span-1">
        <!-- Change from Mo to Mio for maxFileSize (VALO-3657) -->
        <app-file-drop
          *ngIf="isFormValid('notarySignatureDate')"
          fieldNameKey="selleAttestationDocument"
          [includingForm]="stepTwoForm"
          [attachmentName]="i18nService._('Txt_Reservation_Form_selleAttestationDocument_label')"
          fileRequiredErrorI18nToken="Error_Field_SupportingDocumentRequired"
          [isDisabled]="isCanceled"
          [initialValue]="resaInformation.docs[documentTypes.selleAttestationDocument]"
          [maxFileSize]="104857600"
          [validExt]="['application/pdf']"
        >
        </app-file-drop>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4 mb-3">
      <!-- Datepicker DesistedDate date -->
      <app-input-date
        class="col-span-1"
        fieldName="reservationDesistedDate"
        [parentForm]="stepTwoForm"
        [placeholder]="i18nService._('Txt_ReservationFormStepTwo_reservationDesistedDate')"
        [readonly]="false"
        (dateChange)="onReservationDesistedDateValueChange($event)"
      >
      </app-input-date>

      <div class="col-span-1 flex flex-col" *ngIf="isReservationWithdrawn">
        <mat-form-field class="flex w-full">
          <mat-label>{{ i18nService._('Txt_ReservationFormStepTwo_withdrawMotive') }}</mat-label>
          <mat-select [formControl]="withdrawMotive" (selectionChange)="withdrawMotiveSelectionChange($event)">
            <mat-option *ngFor="let motive of withdrawMotives" [value]="motive.id">
              {{ motive.label }}
            </mat-option>
          </mat-select>
          <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
        </mat-form-field>
        <mat-form-field class="flex w-full" *ngIf="showOtherWithdrawMotive">
          <mat-label>{{ i18nService._('Txt_Page_Form_RegisterCompany_Other') }}</mat-label>
          <textarea matInput [formControl]="withdrawOtherMotive" maxlength="250"> </textarea>
        </mat-form-field>
        <div class="flex w-full">
          <app-file-drop
            fieldNameKey="withdrawDocument"
            [includingForm]="stepTwoForm"
            [tooltip]="i18nService._('Txt_ReservationFormStepTwo_withdrawDocument')"
            [attachmentName]="i18nService._('Txt_PreReservationSubmitionPopin_FormSupportingDocumentLabel')"
            [required]="false"
            [initialValue]="resaInformation.docs[documentTypes.withdrawDocument]"
            [validExt]="['application/pdf']"
            [isDisabled]="!!resaInformation.reservationDesistedDate"
          >
          </app-file-drop>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- BO Comment -->
<mat-card appearance="outlined" *ngIf="isUserValorrisimo" class="mt-3">
  <mat-card-title class="text-lg mx-auto mb-3">
    {{ i18nService._('Title_reservation_commentary') }}
  </mat-card-title>
  <mat-card-content>
    <div class="grid grid-cols-2 gap-4 mb-4">
      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_ReservationFormStepTwo_valoTrackingComments') }}</mat-label>
        <textarea matInput class="col-span-1" name="reservationComment" [formControl]="valoTrackingComments" maxlength="2000"> </textarea>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
