import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, take, takeUntil, tap } from 'rxjs';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import _ from 'lodash';
import { DossierResponse } from '@commons-dto/dossier-prospect';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgIf } from '@angular/common';

import { DossierProspectRouteService } from '../dossier-prospect/dossier-prospect.route.service';

import { SnackbarService } from './../../../utils/services/snackbar.service';
import { QuestionDialogComponent } from './../../../dialog/components/question-dialog/question-dialog.component';
import { QuestionDialogData } from './../../../dialog/models/QuestionDialogData';
import { DossierProspectService } from '../../services/dossier-prospect.service';
import { DossierProspectFormlyService } from '../../services/dossier-prospect-formly.service';
import { QuestionDialogResponse } from '../../../dialog/models/QuestionDialogResponse';
import { AppIconsName } from '../../../common/standalone/icon/utils/app-icon-names';
import { I18nService } from '../../../utils/services/i18n.service';
import { ProspectDataMapper } from '../../model/prospect.mapper';
import { DossierProspectMapper } from '../../model/dossier-prospect.mapper';

@Component({
  selector: 'app-prospect-edit',
  templateUrl: './prospect-edit.component.html',
  styleUrls: ['./prospect-edit.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, FormlyModule],
})
export class ProspectEditComponent implements OnInit, OnDestroy, AfterContentChecked {
  dossier: DossierResponse;
  header: SafeHtml;
  icon: AppIconsName;

  fields: FormlyFieldConfig[];
  model = {};
  options: FormlyFormOptions = {};
  form = new UntypedFormGroup({});

  formChanged = false;
  criticalFieldChanged = false;
  formValid = true;
  backButtonLabel = 'Prospect_Return_Prospect_List';

  private readonly _destroy$: Subject<boolean> = new Subject<boolean>();
  private hasChangeType: boolean;

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _dialog: MatDialog,
    private readonly _snackbar: SnackbarService,
    private readonly _dossierProspect: DossierProspectService,
    private readonly _formly: DossierProspectFormlyService,
    private readonly dossierProspectRouteService: DossierProspectRouteService,
    private readonly sanitizer: DomSanitizer,
    public readonly i18nService: I18nService,
    public readonly detectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const id = this._route.snapshot.params.id;
    const isFromDashboardProspect = this._route.snapshot.queryParams['isFromDashboardProspect'];
    if (isFromDashboardProspect) {
      this.backButtonLabel = 'Prospect_Return_Prospect_Dashboard';
    }
    this._dossierProspect
      .getDossier(id, true)
      .pipe(takeUntil(this._destroy$))
      .subscribe((dossier) => this.setFormAndData(dossier));
  }

  ngAfterContentChecked(): void {
    this.detectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  validateChanges() {
    this.isValid();
    if (this.formValid) {
      this.hasChanged();
      if (this.formChanged) {
        this.hasCriticalChanges();
      }
    }
  }

  private hasChanged() {
    const potentialChanged = _.differenceWith(_.toPairs(this.form.value), _.toPairs(DossierProspectMapper.toForm(this.dossier)), _.isEqual);
    const prospect1PhoneChange = potentialChanged.find((keyValue) => keyValue[0] === 'prospect_1_phone');
    this.formChanged =
      potentialChanged.length > 1 || (prospect1PhoneChange && prospect1PhoneChange[1].phoneE164 !== this.dossier.prospects[0].phoneNumber);
  }

  private hasCriticalChanges() {
    const changedProspect = ProspectDataMapper.fromProspectCreate(this.form.value);
    this.criticalFieldChanged =
      this.dossier.prospects[0].prospectType !== changedProspect[0].prospectType ||
      (this.dossier.prospects[0].SIRET && this.dossier.prospects[0]?.SIRET !== changedProspect[0].SIRET) ||
      this.dossier.prospects[0].email !== changedProspect[0].email ||
      this.dossier.prospects[0].phoneNumber !== changedProspect[0].phoneNumber ||
      this.dossier.prospects[1]?.email !== changedProspect[1]?.email ||
      this.dossier.prospects[1]?.phoneNumber !== changedProspect[1]?.phoneNumber;

    this.hasChangeType =
      this.dossier.prospects.length != changedProspect.length || this.dossier.prospects[0].prospectType !== changedProspect[0].prospectType;
  }

  private isValid() {
    this.formValid = this.form.valid;
    for (const control in this.form.controls) {
      if (this.form.controls[control].errors) {
        this.formValid = false;
      }
    }
    return this.formValid;
  }

  setFormAndData(dossier: DossierResponse) {
    this.header = this.sanitizer.bypassSecurityTrustHtml(this._dossierProspect.getEditHeader(dossier));
    this.dossier = dossier;
    this.fields = this._formly.getFormProspect(this.dossier.id, true, true, {
      customAction: () => {
        return this.save(true);
      },
      showCustomAction: true,
      context: 'dossier-prospect',
      customActionTitle: 'Prospect_Edit_Save_Modification',
      stepTitle: this.header,
      goBackFunction: () => {
        return this.goBack();
      },
    });
    this.model = DossierProspectMapper.toForm(this.dossier);
  }

  clearForm() {
    for (const control in this.form.controls) {
      this.form.controls[control].markAsPristine();
      this.form.controls[control].markAsUntouched();
    }
    this.form.markAsUntouched();
    this.form.markAsPristine();
  }

  goBack() {
    return () => {
      if (this.formChanged) {
        this._dialog
          .open(QuestionDialogComponent, {
            data: {
              title: 'Prospect_Edit_Changes_Detected_Title',
              message: 'Prospect_Edit_Changes_Detected_Txt',
              buttonCancel: 'Prospect_Edit_Changes_Detected_No_Save',
              buttonConfirm: 'Prospect_Edit_Changes_Detected_Save',
            } as QuestionDialogData,
          })
          .afterClosed()
          .pipe(take(1))
          .subscribe((result: QuestionDialogResponse) => {
            if (result.hasAnwered) {
              if (!result.answer) {
                this.goToDashboard();
              } else {
                this.save(true);
              }
            }
          });
      } else {
        this.goToDashboard();
      }
    };
  }

  save(goBack: boolean) {
    if (this.isValid()) {
      if (this.openPaternityQuestionDialog(goBack)) {
        return;
      }
      this.submit().subscribe(() => {
        if (goBack) this.goToDashboard();
      });
    } else {
      this._snackbar.errorI18n({
        title: 'Prospect_Edit_Save_Errors_Title',
        text: 'Prospect_Edit_Save_Errors_Txt',
      });
    }
  }

  private openPaternityQuestionDialog(goBack: boolean) {
    if (this.hasChangeType || this.criticalFieldChanged) {
      const prospectCriticalChangeDialog = this._dialog.open(QuestionDialogComponent, {
        data: {
          buttonCancel: 'Prospect_Edit_Critical_Change_Cancel',
          buttonConfirm: 'Prospect_Edit_Critical_Change_Confirm',
          hasHTMLMessage: true,
          message: 'Prospect_Edit_Critical_Change_Txt',
          title: 'Prospect_Edit_Critical_Change_Title',
        } as QuestionDialogData,
      });
      prospectCriticalChangeDialog
        .afterClosed()
        .pipe(take(1))
        .subscribe((value) => {
          if (value.answer === true) {
            this.submit().subscribe(() => {
              if (goBack) this.goToDashboard();
            });
          }
        });
      return true;
    }

    return false;
  }

  private submit() {
    if (this.formValid) {
      const updated: Partial<DossierResponse> = {
        id: this.dossier.id,
        prospects: ProspectDataMapper.fromProspectCreate(this.form.value),
      };
      return this._dossierProspect.updateDossier(updated).pipe(
        tap(() => {
          this.clearForm();
        }),
      );
    }
  }

  goToDashboard() {
    this.dossierProspectRouteService.goToDashboard(this.dossier.id);
  }
}
