import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { InputNumber, InputNumberModule } from 'primeng/inputnumber';
import { FormsModule, UntypedFormControl } from '@angular/forms';
import { isDate } from 'moment';
import { DateTime } from 'luxon';
import { NgClass, NgIf } from '@angular/common';

import { LinkedList } from '../../../utils/types/linked-list/linked-list.type';
import { ListNode } from '../../../utils/types/linked-list/list-node.type';
import { KeyValue } from '../../../utils/@types/KeyValue';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, InputNumberModule, FormsModule],
})
export class DateComponent implements OnInit, OnChanges {
  public day: number = null;
  public month: number = null;
  public year: number = null;

  public list: LinkedList<KeyValue> = new LinkedList();

  @Input() public label!: string;
  @Input() public labelCssClass!: string;
  @Input() public name = 'intl-date-name';
  @Input() public cssClass!: string;
  @Input() public required!: boolean;
  @Input() public inputDateControl: UntypedFormControl;
  @Input() public date: Date;
  @Output() public dateChange: EventEmitter<Date | null>;

  @ViewChild('dayInput') public dayInput: InputNumber;
  @ViewChild('monthInput') public monthInput: InputNumber;
  @ViewChild('yearInput') public yearInput: InputNumber;
  styleClass: string | string[] | Record<string, boolean>;

  constructor() {
    this.dateChange = new EventEmitter<Date | null>();
  }

  public ngOnInit(): void {
    this.list.push({
      key: 'day',
      value: this.day,
    });
    this.list.push({
      key: 'month',
      value: this.month,
    });
    this.list.push({
      key: 'year',
      value: this.year,
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'date' || propName === 'inputDateControl') {
        const currentValue = propName === 'date' ? changes[propName]?.currentValue : changes[propName]?.currentValue.value;
        const currentDate: Date = this.getDate(currentValue);

        if (isDate(currentDate)) {
          this.day = currentDate.getDate();
          this.month = currentDate.getMonth() + 1;
          this.year = currentDate.getFullYear();
        }
      }
    }
  }

  getDate(data: string): Date | null {
    if (!data) {
      return null;
    }

    return new Date(data);
  }

  public handlerInput(event): void {
    if (this.isValidFieldValue(event.originalEvent.target)) {
      const inputName: string = event.originalEvent.target.name;
      let inputNode: ListNode<KeyValue> = this.list.head;

      while (inputNode.value.key !== inputName) {
        inputNode = inputNode.next;
      }

      if (inputNode.next) {
        this[`${inputNode.next.value.key}Input`].input.nativeElement.focus();
        this.dateChange.emit(null);
      } else {
        this[`${inputNode.value.key}Input`].input.nativeElement.blur();
      }
    }

    const currentDate = this.getCurrentDate();
    this.dateChange.emit(currentDate);
    this.inputDateControl.setValue(currentDate);
    this.inputDateControl.markAsDirty();
    this.inputDateControl.markAsTouched();
  }

  private generateDate(): DateTime | undefined {
    if (!this.year || this.year <= 1800) {
      return undefined;
    }
    return DateTime.fromObject({
      day: this.day ? this.day : -1,
      month: this.month ? this.month : -1,
      year: this.year,
    });
  }

  private isValidFieldValue(field: InputNumber): boolean {
    return (
      field.value && (field.value >= +field.max || field.value < +field.min || field.value.toString().length >= field.max.toString().length)
    );
  }

  private getCurrentDate(): Date | undefined {
    const dateTime = this.generateDate();
    if (!dateTime?.isValid) {
      return undefined;
    }
    return dateTime.toJSDate();
  }
}
