import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AutoCompleteService } from '../../common/form/auto-complete.service';
import { ProgramApiService } from '../../adapters/program-api.service';
import { ProgramsTrackingTableServiceResponse } from '../../tracking-tables/models/ProgramsTrackingTableServiceResponse';
import { ProgramsTrackingTableItem } from '../../tracking-tables/models/ProgramsTrackingTableItem';
import { ProgramParamRequest } from '../../adapters/models/program-param-request';

@Injectable({
  providedIn: 'root',
})
export class ProgramSearch implements AutoCompleteService {
  constructor(private programApiService: ProgramApiService) {}

  findData(filter: string, observers: any): Observable<{ label: string; id: number }[]> {
    const companyId = observers['props.companyId'] ? observers['props.companyId'].value : undefined;
    const params: ProgramParamRequest = {
      filter: filter,
      companyId: companyId,
      programStatusId: [6, 7],
      sortColumn: 'programName',
      offset: 0,
      nbResult: 200,
    };
    return this.programApiService.getPrograms(params).pipe(
      map((programs: ProgramsTrackingTableServiceResponse) => {
        return programs.items.map((program: ProgramsTrackingTableItem) => {
          return {
            id: program.id,
            label: program.programName + '  /  ' + program.cityPostalCode,
          };
        });
      }),
    );
  }
}
