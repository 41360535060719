import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LegalNoticeRoutingModule } from './legal-notice-routing.module';
import { LegalNoticeComponent } from './components/legal-notice/legal-notice.component';
import { UtilsModule } from '../utils/utils.module';

@NgModule({
  imports: [CommonModule, LegalNoticeRoutingModule, UtilsModule, LegalNoticeComponent],
})
export class LegalNoticeModule {}
