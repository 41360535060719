import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs';

import { CronExecutionResponse } from '../models/CronExecutionResponse';
import { SalersTrackingTableServiceResponse } from '../models/SalersTrackingTableResponse';
import { AbstractAdvancedTrackingTableNoBackPageService } from './abstract-advanced-tracking-table-no-back-page.service';

@Injectable({
  providedIn: 'root',
})
export class SalersTrackingTableService extends AbstractAdvancedTrackingTableNoBackPageService<SalersTrackingTableServiceResponse> {
  protected url = 'Accounts/get-sales-table';
  protected cacheName = 'tracking-sales';

  getLastCronExecution(): Observable<CronExecutionResponse> {
    const filter = {
      where: {
        cronName: 'insertDailySellersDatamart',
      },
    };

    const params = new HttpParams().set('filter', JSON.stringify(filter));
    const url = `${this.appConfig.getLoopbackApiUrl()}/CronExecutions`;

    return this.http.get<CronExecutionResponse>(url, { params }).pipe(
      map((res) => res[0]),
      catchError(this.errorHandlerService.handleError<CronExecutionResponse>(this.serviceName, 'get')),
    );
  }
}
