import { firstValueFrom } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { cloneDeep } from 'lodash';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

import { FileSystemService } from '../../../utils/services/file-system.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { PreReservationCreateFormlyService } from '../../services/pre-reservation-create-formly.service';
import { LotService } from '../../../lot/service/lot.service';
import { AreaType } from '../../../utils/models/app-constant';
import { TaxationService } from '../../../taxation/taxation.service';
import { AccountService } from '../../../utils/services/account.service';
import { ProspectService } from '../../../prospects/services/prospect.service';
import { AttachmentService } from '../../../utils/services/attachment.service';
import { AttachmentUploadData } from '../../../utils/models/AttachmentUploadData';
import { ReservationService } from '../../../reservations/services/reservation.service';
import { ReservationCreate } from '../../../utils/models/ReservationCreate';
import { PaternityService } from '../../../prospects/services/paternity.service';
import { FormlyNavigationService } from '../../../common/formly/formly-navigation-service';
import { CountryService } from '../../../country/services/country.service';
import { Sitemap } from '../../../utils/models/Sitemap';
import { CityService } from '../../../utils/services/city.service';
import { FeatureFlagService } from '../../../feature-flag/feature-flag.service';
import { SecondaryLot } from '../../../utils/models/SecondaryLot';
import { LotToCreate } from '../../../utils/models/LotToCreate';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { SpinnerWithBackdropService } from '../../../utils/services/spinner-with-backdrop.service';
import { PaternityLegacyCreate } from '../../../utils/models/PaternityLegacyCreate';

const COUNTRY_ID_FRANCE = 72;

@Component({
  selector: 'app-pre-reservation-create',
  standalone: true,
  templateUrl: './pre-reservation-create.component.html',
  styleUrls: ['./pre-reservation-create.component.scss'],
  providers: [FileSystemService, I18nService],
  imports: [CommonModule, FormlyModule],
})
export class PreReservationCreateComponent implements OnInit {
  form = new UntypedFormGroup({});

  formChanged = false;
  formValid = true;

  fields: FormlyFieldConfig[];
  options: FormlyFormOptions = {};
  model: any = { secondaryLots: [] };
  previousModel: any;

  activePage = 1;
  nbPage: number;
  needToRefreshValues = false;

  isDossierProspectEnabled = false;

  constructor(
    public i18nService: I18nService,
    public appConfig: AppConfigService,
    private readonly lotService: LotService,
    private readonly formlyService: PreReservationCreateFormlyService,
    private readonly taxationService: TaxationService,
    private readonly accountService: AccountService,
    private readonly prospectService: ProspectService,
    private readonly countryService: CountryService,
    private readonly cityService: CityService,
    private readonly paternityService: PaternityService,
    private readonly attachmentService: AttachmentService,
    private readonly reservationService: ReservationService,
    private readonly formlyNavigationService: FormlyNavigationService,
    private readonly router: Router,
    private readonly featureFlagService: FeatureFlagService,
    private readonly spinnerWithBackdropService: SpinnerWithBackdropService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.fields = await this.formlyService.getForm();
    this.nbPage = this.getNbPage();

    this.formlyNavigationService.nextPage();
    this.formlyNavigationService.onPageChanged.subscribe((activePage: number) => {
      this.activePage = activePage;
    });

    this.setDefaultSecondaryLotPriceIncluded();

    this.isDossierProspectEnabled = await this.featureFlagService.isEnabled('dossier-prospect');
  }

  setDefaultSecondaryLotPriceIncluded(): void {
    this.model.secondaryLotPriceIncluded = 0;
  }

  private getNbPage(): number {
    let nbPage = 0;
    for (const field of this.fields) {
      nbPage += field.fieldGroup.filter((fg) => !fg?.props?.isHead).length;
    }
    return nbPage;
  }

  async onChange() {
    this.needToRefreshValues = false;

    // If developer has changed, we clear the program value
    if (this.model?.developer?.id !== this?.previousModel?.developer?.id) {
      this.onDeveloperChange();
    }

    // If program is set and has changed, we get info about this program and clear lot value
    if (this.model?.program?.id !== this?.previousModel?.program?.id) {
      await this.onProgramChange();
    }

    // Lot
    if (this.model?.lot?.id !== this?.previousModel?.lot?.id) {
      await this.onLotChange();
    }

    // Taxation
    if (
      this.model?.lotNueProprietePrice !== this?.previousModel?.lotNueProprietePrice ||
      this.model?.lotUsufruitValue !== this?.previousModel?.lotUsufruitValue
    ) {
      this.setDistributionKey();
    }

    // If seller has changed, we clear the prospect value
    if (this.model?.seller?.id !== this?.previousModel?.seller?.id) {
      await this.onSellerChange();
    }

    // Prospect (Legacy)
    if (this.model?.prospect?.id !== this?.previousModel?.prospect?.id) {
      await this.onProspectChange();
    }

    // Dossier prospect
    if (this.model?.dossierProspect?.id !== this?.previousModel?.dossierProspect?.id) {
      this.onDossierProspectChange();
    }

    // Refresh
    if (this.needToRefreshValues) {
      this.model = cloneDeep(this.model);
      this.previousModel = cloneDeep(this.model);
    }

    this.formValid = this.form.valid;
  }

  private onDeveloperChange() {
    this.model.program = undefined;
    this.model.lot = undefined;
    this.needToRefreshValues = true;
  }

  private async onProgramChange() {
    this.model.lot = undefined;
    this.needToRefreshValues = true;
  }

  private async onLotChange() {
    let lot;
    if (this.model?.lot?.id) {
      lot = await this.lotService.getLotById(this.model.lot.id);
    } else {
      lot = { secondaryLotPriceIncluded: 0 };
    }
    this.needToRefreshValues = true;

    this.model.lotRef = lot.lotRef;
    this.model.lotNumber = lot.lotNumber;
    this.model.lotProgramTypeId = lot.programTypeId ? '' + lot.programTypeId : undefined;
    this.model.lotOrientationId = lot.lotOrientationId ? '' + lot.lotOrientationId : undefined;
    this.model.lotLivingSpace = lot.livingSpace;
    this.model.lotRooms = lot.rooms;
    this.model.lotFloor = lot.floor;

    const terrace = this.getLotArea(lot, AreaType.TERRACE);
    this.model.lotTerrace = terrace ? true : false;
    this.model.lotTerraceArea = terrace?.area;

    const balcony = this.getLotArea(lot, AreaType.BALCONY);
    this.model.lotBalcony = balcony ? true : false;
    this.model.lotBalconyArea = balcony?.area;

    const garden = this.getLotArea(lot, AreaType.GARDEN);
    this.model.lotGarden = garden ? true : false;
    this.model.lotGardenArea = garden?.area;

    const loggia = this.getLotArea(lot, AreaType.LOGGIA);
    this.model.lotLoggia = loggia ? true : false;
    this.model.lotLoggiaArea = loggia?.area;

    this.model.lotDeliveryDate = lot.deliveryDate ? new Date(lot.deliveryDate) : null;
    this.model.lotCommercialLotSellingPriceIT = lot.commercialLotSellingPriceIT;

    const taxations = await this.taxationService.getTaxations();
    const lotTaxations = taxations.filter((t) => lot?.taxations?.includes(t.id));
    if (lotTaxations) {
      this.model.lotTaxations = lotTaxations.map((t) => t.id);
    }

    // Taxation
    this.model.lotGuaranteedMonthlyRentingPriceIT = lot.guaranteedMonthlyRentingPriceIT;
    this.model.lotEstatePriceET = lot.estatePriceET;
    this.model.lotHousingPriceET = lot.housingPriceET;
    this.model.lotTangiblePriceET = lot.tangiblePriceET;
    this.model.lotGlobalSellingPriceET = lot.globalSellingPriceET;
    this.model.lotNueProprietePrice = lot.nueProprietePrice;
    this.model.lotUsufruitValue = lot.usufruitValue;
    this.model.lotDistributionKey = lot.distributionKey;
    this.model.lotNueProprieteDuration = lot.nueProprieteDuration;
    this.model.lotEstimatedWorkPrice = lot.estimatedWorkPrice;
    this.model.lotEstimatedLandPrice = lot.estimatedLandPrice;

    // Secondary lots
    if (lot.secondaryLots) {
      for (const secondaryLot of lot.secondaryLots) {
        secondaryLot.remove_enabled = false;
      }
    }
    this.model.secondaryLots = lot?.secondaryLots ?? [];
    this.model.secondaryLotPriceIncluded = lot.secondaryLotPriceIncluded;
  }

  setDistributionKey(): void {
    const nueProprietePrice = this.model.lotNueProprietePrice;
    const usufruitValue = this.model.lotUsufruitValue;
    if (nueProprietePrice && usufruitValue) {
      const ratio = Math.round((nueProprietePrice * 100) / (nueProprietePrice + usufruitValue));
      this.model.lotDistributionKey = `${ratio}/${100 - ratio}`;
    } else {
      this.model.lotDistributionKey = '';
    }
    this.needToRefreshValues = true;
  }

  private async onSellerChange() {
    this.model.prospect = undefined;
    this.needToRefreshValues = true;

    let account;
    if (this.model?.seller?.id) {
      account = await this.accountService.getAccountById(this.model.seller.id);
    } else {
      account = {};
    }
    this.model.sellerPhone = account.deskPhone ?? account.mobilePhone;
    this.model.sellerEmail = account.email;
    this.model.sellerPostalCode = account.postalCode;
    this.model.sellerCity = account.city;
    this.model.sellerCompanyId = account.companyId;
  }

  private async onProspectChange() {
    this.needToRefreshValues = true;

    if (this.model?.prospect?.id) {
      const prospect = await this.prospectService.getProspectLegacyById(this.model.prospect.id);
      this.model.prospectCivility = prospect.civilityId;
      this.model.prospectLastName = prospect.lastName;
      this.model.prospectFirstName = prospect.firstName;
      this.model.prospectEmail = prospect.email;
      this.model.prospectCountryFrance = prospect.countryId === COUNTRY_ID_FRANCE;
      if (prospect.countryId !== COUNTRY_ID_FRANCE) {
        const country = await this.countryService.getCountryById(prospect.countryId);
        this.model.prospectCountryOther = {
          id: country.id,
          code: country.code,
          label: country.label,
        };
      }
      this.model.prospectPhone = prospect.phone;
      this.model.prospectLocalisation = {
        postalCode: prospect.postalCode,
      };

      if (prospect.cityInseeCode) {
        const city = await this.cityService.getCityById(prospect.cityInseeCode);
        this.model.prospectLocalisation.city = {
          inseeCode: city.inseeCode,
          label: city.label,
          departementId: city.departementId,
        };
      }
    }
  }

  private onDossierProspectChange() {
    this.needToRefreshValues = true;

    const prospects = this.model?.dossierProspect?.prospects;
    if (prospects?.length) {
      prospects.forEach((prospect, index) => {
        this.setProspectModel(prospect, index);
      });
    }
  }

  private setProspectModel(prospect: any, index: number): void {
    if (prospect.prospectType === 'COMPANY') {
      this.model['prospectCompanySiret'] = prospect.SIRET;
      this.model['prospectCompanyName'] = prospect.companyName;
    }
    this.model[`prospectCivility${index}`] = prospect.civility;
    this.model[`prospectLastName${index}`] = prospect.lastName;
    this.model[`prospectFirstName${index}`] = prospect.firstName;
    this.model[`prospectEmail${index}`] = prospect.email;
    this.model[`prospectPhone${index}`] = prospect.phoneNumber;
  }

  private getLotArea(lot: any, areaTypeId: number) {
    if (!lot.areas) return null;
    for (const lotArea of lot.areas) {
      if (areaTypeId === lotArea.areaTypeId) {
        return lotArea;
      }
    }
    return null;
  }

  nextPage() {
    this.formlyNavigationService.nextPage();
  }

  previousPage() {
    this.formlyNavigationService.previousPage();
  }

  async createLot() {
    const lot: LotToCreate = {
      lotRef: this.model.lotRef,
      lotNumber: this.model.lotNumber,
      livingSpace: this.model.lotLivingSpace,
      rooms: this.model.lotRooms,
      floor: this.model.lotFloor,
      deliveryDate: this.model.lotDeliveryDate,
      secondaryLotPriceIncluded: this.model.secondaryLotPriceIncluded == 1,
      document: {
        lotPlan: this.model.lotDocument,
      },
      lotHasTaxations: [],
      lotHasAreas: [],
      secondaryLots: [],
      isValorissimoExclusivity: false,
      programTypeId: this.model.lotProgramTypeId,
      lotOrientationId: this.model.lotOrientationId,
      commercialLotSellingPriceIT: this.model.lotCommercialLotSellingPriceIT,
      vatRate: 20,
      guaranteedMonthlyRentingPriceIT: this.model.lotGuaranteedMonthlyRentingPriceIT,
      estatePriceET: this.model.lotEstatePriceET,
      housingPriceET: this.model.lotHousingPriceET,
      tangiblePriceET: this.model.lotTangiblePriceET,
      globalSellingPriceET: this.model.lotGlobalSellingPriceET,
      nueProprietePrice: this.model.lotNueProprietePrice,
      usufruitValue: this.model.lotUsufruitValue,
      distributionKey: this.model.lotDistributionKey,
      nueProprieteDuration: this.model.lotNueProprieteDuration,
      estimatedWorkPrice: this.model.lotEstimatedWorkPrice,
      estimatedLandPrice: this.model.lotEstimatedLandPrice,
      programId: this.model.program.id,
      isForManualReservation: true,
    };

    // Taxations
    lot.lotHasTaxations = [];
    for (const taxationId of this.model.lotTaxations) {
      lot.lotHasTaxations.push({
        taxationId: taxationId,
      });
    }

    // Areas
    lot.lotHasAreas = [];
    if (this.model.lotTerrace === 'true') {
      lot.lotHasAreas.push({
        area: this.model.lotTerraceArea,
        areaTypeId: AreaType.TERRACE,
      });
    }
    if (this.model.lotBalcony === 'true') {
      lot.lotHasAreas.push({
        area: this.model.lotBalconyArea,
        areaTypeId: AreaType.BALCONY,
      });
    }
    if (this.model.lotGarden === 'true') {
      lot.lotHasAreas.push({
        area: this.model.lotGardenArea,
        areaTypeId: AreaType.GARDEN,
      });
    }
    if (this.model.lotLoggia === 'true') {
      lot.lotHasAreas.push({
        area: this.model.lotLoggiaArea,
        areaTypeId: AreaType.LOGGIA,
      });
    }

    // Secondary lots
    lot.secondaryLots = [];
    if (this.model.secondaryLots) {
      for (const modelSecondaryLot of this.model.secondaryLots) {
        const secondaryLot: SecondaryLot = {
          refLot: modelSecondaryLot.refLot,
          numLot: modelSecondaryLot.numLot,
          secondaryLotTypeId: Number(modelSecondaryLot.secondaryLotTypeId),
          lotSellingPriceIT: modelSecondaryLot.lotSellingPriceIT,
        };
        lot.secondaryLots.push(secondaryLot);
      }
    }

    const res = await firstValueFrom(this.lotService.createLot(lot));
    return res.id;
  }

  async createProspect() {
    // Create prospect (legacy)
    const countryId = this.model.prospectCountryFrance ? COUNTRY_ID_FRANCE : this.model.prospectCountryOther.id;

    const prospect = {
      email: this.model.prospectEmail,
      lastName: this.model.prospectLastName,
      firstName: this.model.prospectFirstName,
      phone: this.model.prospectPhone?.phoneE164,
      countryId: countryId,
      isFrench: true,
      postalCode: this.model.prospectLocalisation.postalCode,
      city: this.model.prospectLocalisation.city.label,
      cityInseeCode: this.model.prospectLocalisation.city.inseeCode,
      civilityId: this.model.prospectCivility,
      programList: [], // We don't create paternities directly
      companyId: this.model.sellerCompanyId,
    };

    const prospectResponse = await firstValueFrom(this.prospectService.createProspect(prospect));
    const prospectId = prospectResponse.id;

    const paternity: PaternityLegacyCreate = {
      paternityStatusId: 1, // Accepté
      prospectId: prospectId,
      programId: this.model.program.id,
      expirationDate: new Date(), // TODO: J+60 ?
      validationDate: new Date(),
      accountId: this.model.seller.id,
    };
    await this.paternityService.createPaternityLegacy(paternity);
    return prospectId;
  }

  async createReservation(prospectId: number, dossierProspectId: number, lotId: number) {
    let documentId;
    if (this.model.document) {
      const attachments: Array<AttachmentUploadData> = [
        {
          file: this.model.document,
          documentType: 'reservationSupportingDocument',
        },
      ];
      const fileUpload = await firstValueFrom(
        this.attachmentService.uploadReservationDocumentsFiles(attachments, 'reservationDocuments', lotId, prospectId, dossierProspectId),
      );
      documentId = fileUpload.result.files.file[0].id;
    }

    const reservation: ReservationCreate = {
      sellerAccountId: this.model.seller.id,
      lotId: lotId,
      prospectId: prospectId,
      dossierProspectId: dossierProspectId,
      reservationMotiveId: this.model.motif,
      comments: this.model.comment,
      supportingDocumentId: documentId,
      estimatedAppointmentDate: this.model.estimatedAppointmentDate,
      isManual: true,
    };
    await firstValueFrom(this.reservationService.createReservation(reservation));
  }

  async getProspectId() {
    if (this.model?.prospect?.id) {
      return this.model.prospect.id;
    } else {
      return await this.createProspect();
    }
  }

  async submit() {
    try {
      this.spinnerWithBackdropService.show();

      let prospectId = null;
      let dossierProspectId = null;
      if (this.isDossierProspectEnabled) {
        dossierProspectId = this.model.dossierProspect.id;
      } else {
        prospectId = await this.getProspectId();
      }

      let lotId;
      if (this.model?.lot?.id) {
        lotId = this.model.lot.id;
      } else {
        lotId = await this.createLot();
      }

      await this.createReservation(prospectId, dossierProspectId, lotId);

      this.router.navigate([Sitemap.dashboards.preReservations.path]);
    } finally {
      this.spinnerWithBackdropService.hide();
    }
  }
}
