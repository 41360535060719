import {
  ChargeRequestDto,
  ChargeTypeEnum,
  RevenueRequestDto,
  RevenueTypeEnum,
  SimulationResponseDto,
} from 'libs/commons-dto/src/lib/dossier-prospect';
import { pick } from 'lodash';
import { v4 as uuidV4 } from 'uuid';

export class SimulationFormData {
  id: number;
  revenues?: {
    [revenueType: string]: SimulationRevenueAmountField;
  };
  charges?: {
    is_owner?: boolean;
    has_estate_credit?: boolean;
    other_charges?: SimulationChargeAmountField[];
    [chargeType: string]: SimulationChargeAmountField | unknown;
  };
  loanDuration?: number;
  insuranceRate?: number;
  loanRate?: number;
  personalFunds?: number;

  static additionalChargeTypes = [
    ChargeTypeEnum.CONSUMER_LOAN,
    ChargeTypeEnum.OTHER_ESTATE_LOAN,
    ChargeTypeEnum.LDD_LOAN,
    ChargeTypeEnum.LOA_LOAN,
    ChargeTypeEnum.OTHER_LOAN,
    ChargeTypeEnum.REVOLVING_LOAN,
    ChargeTypeEnum.STUDENT_LOAN,
    ChargeTypeEnum.VEHICLE_LOAN,
  ];

  /**
   * The function `fromDto` converts a SimulationResponseDto object into a SimulationFormData object by
   * mapping the revenues and charges properties.
   * @param {SimulationResponseDto} model - The `model` parameter is of type `SimulationResponseDto`.
   * @returns an object of type `SimulationFormData`.
   */
  static fromDto(model: SimulationResponseDto): SimulationFormData {
    const revenues = {};
    model.revenues.forEach(
      (revenue) =>
        (revenues[revenue.revenueTypeCode] = {
          revenueTypeCode: revenue.revenueTypeCode,
          amount: revenue.amount,
        }),
    );
    const charges = {
      is_owner: null,
      has_estate_credit: null,
    };
    model.charges.forEach((charge) => {
      /**
       * SimulationFormData have is_owner and has_estate_credit but SimulationResponseDto doesn't
       * We calculate their values based on another field
       */
      if (charge.chargeTypeCode === ChargeTypeEnum.RENT) {
        charges['is_owner'] = false;
      } else if (charge.chargeTypeCode === ChargeTypeEnum.ESTATE_LOAN) {
        charges['is_owner'] = true;
        if (charge.amount === 0) {
          charges['has_estate_credit'] = false;
        } else {
          charges['has_estate_credit'] = true;
        }
      }

      if (this.additionalChargeTypes.includes(charge.chargeTypeCode)) {
        charges['other_charges'] = charges['other_charges'] || [];
        charges['other_charges'].push(charge);
      } else {
        charges[charge.chargeTypeCode] = charge;
      }
    });
    return {
      id: model.id,
      ...pick(model, ['loanRate', 'insuranceRate', 'personalFunds', 'loanDuration']),
      charges,
      revenues,
    };
  }

  /**
   * The function `toDto` takes in a `SimulationFormData` object and converts it into a
   * `SimulationResponseDto` object by filtering and extracting relevant data.
   * @param {SimulationFormData} data - The `data` parameter is of type `SimulationFormData`.
   * @returns a SimulationResponseDto object.
   */
  static toDto(data: SimulationFormData): SimulationResponseDto {
    const revenues: RevenueRequestDto[] = [];
    for (const revenue in data.revenues) {
      if (data.revenues[revenue].amount > 0) {
        revenues.push(data.revenues[revenue]);
      }
    }
    const charges: ChargeRequestDto[] = [];
    for (const charge in data.charges) {
      if (charge === 'other_charges' && data.charges.other_charges.length > 0) {
        data.charges.other_charges.forEach((otherCharge) => {
          if (otherCharge.amount > 0 && otherCharge.chargeTypeCode) {
            otherCharge.id = otherCharge.id || uuidV4();
            charges.push(otherCharge);
          }
        });
      } else if (
        data.charges[charge] &&
        typeof data.charges[charge] === 'object' &&
        (data.charges[charge] as SimulationChargeAmountField).amount >= 0
      ) {
        if ((data.charges[charge] as SimulationChargeAmountField).amount >= 0) {
          charges.push(data.charges[charge] as SimulationChargeAmountField);
        }
      }
    }
    return {
      id: data.id,
      revenues,
      charges,
      loanDuration: data.loanDuration,
      loanRate: data.loanRate,
      personalFunds: data.personalFunds,
      insuranceRate: data.insuranceRate,
    };
  }
}

export interface SimulationChargeAmountField {
  id: string;
  chargeTypeCode: ChargeTypeEnum;
  amount: number;
}

export interface SimulationRevenueAmountField {
  revenueTypeCode: RevenueTypeEnum;
  amount: number;
}
