<div class="grid grid-cols-2 gap:6 lg:gap-16 my-4">
  <mat-form-field class="fullW flex w-full">
    <mat-label>{{ i18nService._('Txt_Placeholder_Search') }}</mat-label>
    <input [(ngModel)]="filterValue" matInput />
  </mat-form-field>

  <mat-form-field class="fullW flex w-full my-0">
    <mat-label>{{ i18nService._('Txt_Placeholder_ColumnsToDisplay') }}</mat-label>
    <mat-select [(ngModel)]="selectedColumns" multiple>
      <mat-select-trigger>
        {{ selectedColumnsSummary }}
      </mat-select-trigger>
      <mat-option *ngFor="let column of columns" [value]="column.value">
        {{ i18nService._(column.label) }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<app-grid-tracking-table
  [columnDefs]="columnDefs"
  [tableLoader]="tableLoader"
  [pageSize]="pageSize"
  [tableHeight]="tableHeight"
  [gridOptions]="gridOptions"
  [visibleColumns]="selectedColumns"
  (rows)="getRows($event)"
  [serverSide]="false"
  [textFilter]="filterValue"
>
</app-grid-tracking-table>
