import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { FormlyModule } from '@ngx-formly/core';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { SignatureDashboardComponent } from './components/dashboard/signature-dashboard.component';
import { SignatureDocumentComponent } from './components/documents/signature-document.component';
import { ModalDocumentComponent } from './components/modal/documents/modal-document.component';
import { SignatureRoutingModule } from './signature-routing.module';
import { SignatureFormComponent } from './components/form/signature-form.component';
import { SharedModule } from '../common/shared/shared.module';
import { AppFormlyModule } from '../common/formly/app-formly.module';
import { SideInfoComponent } from './components/side-info/side-info.component';
import { CommentComponent } from './components/comment/comment.component';
import { SignatureService } from './services/signature.service';
import { CategoryComponent } from './components/categories/category.component';
import { FormHeaderComponent } from './components/form-header/form-header.component';
import { CircleProgressComponent } from './components/circle-progress/circle-progress.component';
import { AppFormModule } from '../common/form/app-form.module';
import { SynthesisComponent } from './components/synthesis/synthesis.component';
import { SignatureChoiceComponent } from './components/signature-choice/signature-choice.component';
import { SignatureProgressComponent } from './components/signature-progress/signature-progress.component';
import { PrefillDialogComponent } from './components/prefill-dialog/prefill-dialog.component';
import { UtilsModule } from '../utils/utils.module';
import { ModalCancelSignatureComponent } from './components/signature-progress/modal/modal-cancel-and-modify.component';
import { PipesModule } from '../utils/pipes/pipes.module';
import { UpdateDialogComponent } from './components/update-dialog/update-dialog.component';
import { IconComponent } from '../common/standalone/icon/icon.component';

@NgModule({
  providers: [SignatureService],
  imports: [
    CommonModule,
    UtilsModule,
    PipesModule,
    MatDialogModule,
    FormsModule,
    SignatureRoutingModule,
    AppFormlyModule,
    ReactiveFormsModule,
    SharedModule,
    AppFormModule,
    FormlyModule.forChild(),
    NgxExtendedPdfViewerModule,
    InputTextareaModule,
    IconComponent,
    SignatureDashboardComponent,
    ModalDocumentComponent,
    SignatureDocumentComponent,
    SideInfoComponent,
    CommentComponent,
    CategoryComponent,
    FormHeaderComponent,
    SignatureFormComponent,
    CircleProgressComponent,
    SynthesisComponent,
    SignatureChoiceComponent,
    SignatureProgressComponent,
    ModalCancelSignatureComponent,
    PrefillDialogComponent,
    UpdateDialogComponent,
  ],
})
export class SignatureModule {}
