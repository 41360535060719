import { Component, Input, TemplateRef } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';

import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-single-accordion-panel',
  templateUrl: './single-accordion-panel.component.html',
  styleUrls: ['./single-accordion-panel.component.scss'],
  standalone: true,
  imports: [MatExpansionModule, NgTemplateOutlet],
})
export class SingleAccordionPanelComponent {
  @Input() dataContent: unknown;
  @Input() title: string;
  @Input() open: boolean;
  @Input() optionTemplate: TemplateRef<unknown>;

  constructor(public i18nService: I18nService) {
    this.dataContent = 'test';
  }
}
