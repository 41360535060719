import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../security/authguard';
import { Sitemap } from '../utils/models/Sitemap';
import { PreReservationCreateComponent } from './components/pre-reservation-create/pre-reservation-create.component';

const routes: Routes = [
  {
    path: Sitemap.preReservations.create.path,
    data: {
      roles: Sitemap.preReservations.create.data.roles,
    },
    component: PreReservationCreateComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [PreReservationCreateComponent, RouterModule.forChild(routes)],
  declarations: [],
})
export class PreReservationsRoutingModule {}
