<div class="px-4">
  <h2 class="text-center" mat-dialog-title>
    {{ i18nService._('Txt_Button_PlanningPublicationStrategies') }}
  </h2>
  <div mat-dialog-content>
    <form [formGroup]="planningPublicationStrategyForm">
      <div>
        <app-input-date
          class="w-full"
          fieldName="planningDate"
          [required]="true"
          [parentForm]="planningPublicationStrategyForm"
          data-cy="registerPlanningPublicationMandateStrategy"
          [placeholder]="i18nService._('Txt_Placeholder_RegisterPlanningPublicationMandateStrategy')"
        >
        </app-input-date>
      </div>
      <div>
        <app-dropdown-list-populated
          class="w-full"
          [parentForm]="planningPublicationStrategyForm"
          [items]="planningHourSelect"
          [placeholder]="i18nService._('Title_FinancialStrategy_planningHour')"
          [fieldName]="'planningHour'"
          [required]="true"
          [readonly]="false"
          [dataToInit]="initData"
          maxlength="5"
        >
        </app-dropdown-list-populated>
      </div>
    </form>
  </div>
  <div class="justify-center text-center" mat-dialog-actions>
    <button class="mr-0 lg:mr-4 md:mr-4 sm:mr-4 mb-3 lg:mb-0 sm:mb-0 md:mb-0" color="primary" mat-dialog-close mat-stroked-button>
      {{ i18nService._('Txt_Button_Cancel') }}
    </button>
    <button
      (click)="clickSaveButton()"
      [disabled]="planningPublicationStrategyForm.invalid"
      cdkFocusInitial
      class="mb-3 lg:mb-0 sm:mb-0 md:mb-0"
      color="primary"
      mat-flat-button
    >
      {{ i18nService._('Txt_Button_Planning') }}
    </button>
  </div>
</div>
