export class OptionResponse {
  companyId: number;
  created: Date;
  deleted?: Date;
  developerProgramRef: string;
  emailSalesRep: string;
  endDate: Date;
  expirationDate?: Date;
  id: number;
  isExtended: boolean;
  isExternalOption: boolean;
  lotId?: number;
  lotRef: string;
  modified: Date;
  optionRef?: string;
  prospectRef?: string;
  dossierProspectId: number;
  startDate: Date;
  optionStatusId: number;
  isActive?: boolean;
}

export class OptionEntity {
  id: number;
  lotId: number;
  lotNumber: string;
  programName: string;
}

export class OptionForDossierProspectDashboard {
  id: number;
  lotId: number;
  startDate: Date;
  endDate: Date;
  optionStatusId: number;
  companyId: number;
  isActive: boolean;
  lot: {
    lotNumber: string;
    programId: number;
    program: {
      programName: string;
      companyId: number;
      company: {
        corporateName: string;
      };
      city: string;
      postalCode: string;
    };
  };
}
