<div class="app-file-drop-container">
  <div *ngIf="readonly && multiple && formControl.value && formControl.value.length > 0">
    <a *ngFor="let file of formControl.value; index as index" [href]="getFileUrl(index)" class="flex p-4 my-1 items-center cursor-pointer">
      <div class="flex items-center gap-2">
        <ng-icon size="1.5rem" name="heroDocument"></ng-icon>
      </div>
      <div class="text-sm truncate font-bold ml-2">
        {{ file.name || file['fileName'] }}
      </div>
    </a>
  </div>

  <div *ngIf="readonly && !multiple && formControl.value">
    <div (click)="watchFile()" class="flex p-4 my-1 items-center cursor-pointer">
      <div class="flex items-center gap-2">
        <ng-icon size="1.5rem" name="heroDocument"></ng-icon>
      </div>
      <div class="text-sm truncate font-bold ml-2">
        {{ formControl.value.name }}
      </div>
    </div>
  </div>

  <div *ngIf="!readonly && multiple && formControl.value && formControl.value.length > 0" class="flex flex-wrap mb-6">
    <div *ngFor="let file of formControl.value; index as index" class="border border-black rounded-md flex max-w-xxs m-1">
      <div (click)="watchFile(index)" class="text-sm truncate cursor-pointer p-1" title="{{ formControl.value.name }}">
        {{ file.name }}
      </div>
      <div class="ml-auto flex items-center justify-center action-button p-1 rounded-md min-w-[28px]">
        <button (click)="deleteFile(file.id)" class="flex items-center justify-center" type="button">
          <app-icon name="XMark"></app-icon>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!readonly && !multiple && formControl.value" class="flex flex-wrap mb-6">
    <div class="border border-black rounded-md flex max-w-xs p-1 m-1">
      <div (click)="watchFile()" class="text-sm truncate cursor-pointer" title="{{ formControl.value.name }}">
        {{ formControl.value.name }}
      </div>
      <div class="ml-auto flex items-center gap-2">
        <button (click)="deleteFile()" class="ml-auto flex items-center" type="button">
          <app-icon name="XMark"></app-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="isInvalidFormat">
    <span class="text-red-600 text-xs font-normal"> {{ i18nService._('txt_format_invalid') }} </span>
  </div>
  <ngx-file-drop
    (onFileDrop)="fileDropped($event)"
    (onFileLeave)="fileLeave()"
    (onFileOver)="fileOver()"
    *ngIf="!readonly"
    accept="{{ splitTypes }}"
    class="file-drop file-dropped"
    id="file-drop"
  >
    <ng-template let-openFileSelector="openFileSelector" ngx-file-drop-content-tmp>
      <div (click)="openFileSelector()" class="flex w-full items-center cursor-pointer px-5">
        <div #dropZoneLabel [innerHTML]="selectFileLabel"></div>
        <button class="ml-auto flex items-center" type="button">
          <app-icon class="text-orange-500" size="1.25rem" name="Plus"></app-icon>
        </button>
      </div>
    </ng-template>
  </ngx-file-drop>

  <div *ngIf="!readonly" class="flex font-semibold text-xs txt-grey mt-1 gap-3">
    <div *ngIf="acceptedFilesLabel && acceptedFilesLabel.length > 0">
      {{ i18nService._('txt_file_drop_form_accepted_files') }} : {{ acceptedFilesLabel }}
    </div>
    <div *ngIf="maxFileSize">
      {{ i18nService._('txt_file_drop_form_max_size') }} : {{ getMaxFileSize() }} {{ i18nService._('txt_file_drop_form_mb') }}
    </div>
  </div>
</div>
