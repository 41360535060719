<div class="flex flex-row flex-1 w-full bg-graylight h-full d-table">
  <mat-drawer-container [hasBackdrop]="true" class="bg-white w-full">
    <mat-drawer
      #drawer
      class="shadow-xl overflow-visible visible border-l-4 border-orange-500 w-full lg:w-4/5 p-4"
      [opened]="false"
      mode="over"
      position="end"
    >
    </mat-drawer>

    <mat-drawer-content>
      <div class="flex flex-col w-full">
        <!-- Sticky subHeader form -->
        <app-sticky-header-form [titleText]="i18nService._('Title_DossierProspectTrackingTable')"></app-sticky-header-form>

        <div class="pt-20 mx-3 mb-4 lg:mx-5 md:mx-5 sm:mx-4 px-2 lg:px-6 md:px-5 sm:px-3">
          <div class="flex justify-between">
            <mat-button-toggle-group *ngIf="statusList.length" [(ngModel)]="statusFilter" (change)="statusFilterChange()" class="mb-4">
              <mat-button-toggle *ngFor="let status of statusList" [value]="status.code">
                <span class="pr-2">{{ status.label }}</span>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="grid grid-cols-8 gap:4 lg:gap-6 mb-3">
            <div class="col-span-2">
              <mat-form-field class="fullW flex w-full">
                <mat-label>{{ i18nService._('Txt_Button_Search') }}</mat-label>
                <input [(ngModel)]="filterValue" (ngModelChange)="textFilterSubject.next($event)" matInput />
              </mat-form-field>
            </div>
          </div>

          <div class="tracking-mat-table">
            <app-grid-tracking-table
              [tableLoader]="dossierProspectTrackingTableService"
              [columnDefs]="columnDefs"
              [pageSize]="pageSize"
              [textFilter]="textFilterValue"
              [statusFilter]="statusFilter"
            >
            </app-grid-tracking-table>
          </div>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
