import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { StrongPointResponseDto } from '@commons-dto/valo-back';

import { StrongPointComponent } from '../strong-point/strong-point.component';

import { PipesModule } from '../../../utils/pipes/pipes.module';

@Component({
  selector: 'app-strong-point-list',
  standalone: true,
  templateUrl: './strong-point-list.component.html',
  styleUrls: ['./strong-point-list.component.scss'],
  imports: [CommonModule, PipesModule, StrongPointComponent],
})
export class StrongPointListComponent implements OnChanges {
  strongPointsToDisplay: StrongPointResponseDto[];

  @Input() isHoverable = true;
  @Input() numberOfPointsToDisplay = 3;
  @Input() customClass: string[] = [];

  @Input() strongPoints: StrongPointResponseDto[];

  ngOnChanges(): void {
    this.strongPointsToDisplay = this.initStrongPoints();
  }

  private initStrongPoints(): StrongPointResponseDto[] {
    if (!this.strongPoints) return [];
    let strongPointsFormated = this.strongPoints.sort((a, b) => a.position - b.position);
    if (this.numberOfPointsToDisplay === 0) {
      const strongPointAggregated = {
        tooltip: strongPointsFormated.map((sp) => sp.name).join('\r\n'),
        name: strongPointsFormated.length.toString(),
        position: 0,
      };
      strongPointsFormated = [strongPointAggregated];
    } else if (strongPointsFormated.length > this.numberOfPointsToDisplay + 1) {
      const strongPointAggregated = {
        tooltip: strongPointsFormated
          .slice(this.numberOfPointsToDisplay)
          .map((sp) => sp.name)
          .join('\r\n'),
        name: '+' + (strongPointsFormated.length - this.numberOfPointsToDisplay),
        position: 0,
      };
      strongPointsFormated = [...strongPointsFormated.slice(0, this.numberOfPointsToDisplay), strongPointAggregated];
    }
    return strongPointsFormated;
  }
}
