import { DossierStatusCode } from '@commons-dto/dossier-prospect';

import { StatusEnum } from './../../common/shared/status-tag/status-tag.component';

export class DossierProspectUtils {
  static getStatusColor(dossierStatus: DossierStatusCode) {
    switch (dossierStatus) {
      case DossierStatusCode.DISCOVER:
        return StatusEnum.PENDING;
      default:
        return StatusEnum.DRAFT;
    }
  }
}
