<div class="box">
  <div class="steps">
    <div class="step">
      <img [attr.src]="'/assets/img/icon/dossier-prospect/status/discover/discover-' + stepDiscover + '.svg'" />
    </div>

    <div class="bar_progress">
      <svg *ngIf="stepDiscover === 0" width="100%" height="4" viewBox="0 0 100% 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="4" rx="2" fill="#F5F5FA" />
      </svg>
      <svg *ngIf="stepDiscover === 1" width="100%" height="4" viewBox="0 0 100% 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="33%" height="4" rx="2" fill="#5A52FA" />
        <rect x="33%" width="100%" height="4" rx="2" fill="#F5F5FA" />
      </svg>
      <svg *ngIf="stepDiscover === 2" width="100%" height="4" viewBox="0 0 100% 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="4" rx="2" fill="#41B8A0" />
      </svg>
    </div>

    <div class="step">
      <img [attr.src]="'/assets/img/icon/dossier-prospect/status/identification/identification-' + stepIdentification + '.svg'" />
    </div>

    <div class="bar_progress">
      <svg *ngIf="stepIdentification === 0" width="100%" height="4" viewBox="0 0 100% 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="4" rx="2" fill="#F5F5FA" />
      </svg>
      <svg *ngIf="stepIdentification === 1" width="100%" height="4" viewBox="0 0 100% 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="33%" height="4" rx="2" fill="#5A52FA" />
        <rect x="33%" width="100%" height="4" rx="2" fill="#F5F5FA" />
      </svg>
      <svg *ngIf="stepIdentification === 2" width="100%" height="4" viewBox="0 0 100% 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="4" rx="2" fill="#41B8A0" />
      </svg>
    </div>

    <div class="step">
      <img [attr.src]="'/assets/img/icon/dossier-prospect/status/closing/closing-' + stepClosing + '.svg'" />
    </div>

    <!--
    <div class="bar_progress">
      <svg *ngIf="stepClosing === 0" width="100%" height="4" viewBox="0 0 100% 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="4" rx="2" fill="#F5F5FA"/>
      </svg>
      <svg *ngIf="stepClosing === 1" width="100%" height="4" viewBox="0 0 100% 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="33%" height="4" rx="2" fill="#5A52FA"/>
        <rect x="33%" width="100%" height="4" rx="2" fill="#F5F5FA"/>
      </svg>
      <svg *ngIf="stepClosing === 2" width="100%" height="4" viewBox="0 0 100% 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="4" rx="2" fill="#41B8A0"/>
      </svg>   
    </div>

    <div class="step">     
      <svg *ngIf="stepBilling === 0" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4.5" y="4.5" width="47" height="47" rx="23.5" stroke="#B8B8C9" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 0)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M 19 29.271 C 19 29.024 19.033 28.778 19.099 28.54 L 20.622 23.019 C 20.951 21.826 22.036 21 23.273 21 L 32.727 21 C 33.964 21 35.049 21.826 35.378 23.019 L 36.901 28.54 C 36.967 28.778 37 29.024 37 29.271 L 37 33 C 37 34.105 36.105 35 35 35 L 21 35 C 19.895 35 19 34.105 19 33 L 19 29.271 Z M 22.068 23.418 C 22.218 22.876 22.711 22.5 23.273 22.5 L 32.727 22.5 C 33.289 22.5 33.783 22.876 33.932 23.418 L 35.455 28.938 C 35.461 28.959 35.466 28.979 35.47 29 L 32 29 C 31.647 29 31.32 29.186 31.14 29.49 L 30.534 30.511 C 30.354 30.814 30.027 31 29.674 31 L 26.236 31 C 25.857 31 25.511 30.786 25.342 30.447 L 24.894 29.553 C 24.725 29.214 24.379 29 24 29 L 20.53 29 C 20.534 28.979 20.539 28.959 20.545 28.938 L 22.068 23.418 Z" fill="#88889E" transform="matrix(1, 0, 0, 1, 4.440892098500626e-16, 4.440892098500626e-16)"/>
      </svg>

      <svg *ngIf="stepBilling === 1" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="55" height="55" rx="28" stroke="#362EC4" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -1.7763568394002505e-15, 0)"/>
        <rect width="48" height="48" rx="24" fill="#5A52FA" x="4" y="4" transform="matrix(1, 0, 0, 1, 4.440892098500626e-16, 4.440892098500626e-16)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M 19 29.271 C 19 29.024 19.033 28.778 19.099 28.54 L 20.622 23.019 C 20.951 21.826 22.036 21 23.273 21 L 32.727 21 C 33.964 21 35.049 21.826 35.378 23.019 L 36.901 28.54 C 36.967 28.778 37 29.024 37 29.271 L 37 33 C 37 34.105 36.105 35 35 35 L 21 35 C 19.895 35 19 34.105 19 33 L 19 29.271 Z M 22.068 23.418 C 22.218 22.876 22.711 22.5 23.273 22.5 L 32.727 22.5 C 33.289 22.5 33.783 22.876 33.932 23.418 L 35.455 28.938 C 35.461 28.959 35.466 28.979 35.47 29 L 32 29 C 31.647 29 31.32 29.186 31.14 29.49 L 30.534 30.511 C 30.354 30.814 30.027 31 29.674 31 L 26.236 31 C 25.857 31 25.511 30.786 25.342 30.447 L 24.894 29.553 C 24.725 29.214 24.379 29 24 29 L 20.53 29 C 20.534 28.979 20.539 28.959 20.545 28.938 L 22.068 23.418 Z" fill="white" transform="matrix(1, 0, 0, 1, 4.440892098500626e-16, 4.440892098500626e-16)"/>
      </svg>

      <svg *ngIf="stepBilling === 2" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="56" height="56" rx="28" fill="#41B8A0" transform="matrix(1, 0, 0, 1, 4.440892098500626e-16, 1.3877787807814457e-17)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M 19 29.271 C 19 29.024 19.033 28.778 19.099 28.54 L 20.622 23.019 C 20.951 21.826 22.036 21 23.273 21 L 32.727 21 C 33.964 21 35.049 21.826 35.378 23.019 L 36.901 28.54 C 36.967 28.778 37 29.024 37 29.271 L 37 33 C 37 34.105 36.105 35 35 35 L 21 35 C 19.895 35 19 34.105 19 33 L 19 29.271 Z M 22.068 23.418 C 22.218 22.876 22.711 22.5 23.273 22.5 L 32.727 22.5 C 33.289 22.5 33.783 22.876 33.932 23.418 L 35.455 28.938 C 35.461 28.959 35.466 28.979 35.47 29 L 32 29 C 31.647 29 31.32 29.186 31.14 29.49 L 30.534 30.511 C 30.354 30.814 30.027 31 29.674 31 L 26.236 31 C 25.857 31 25.511 30.786 25.342 30.447 L 24.894 29.553 C 24.725 29.214 24.379 29 24 29 L 20.53 29 C 20.534 28.979 20.539 28.959 20.545 28.938 L 22.068 23.418 Z" fill="white" transform="matrix(1, 0, 0, 1, 4.440892098500626e-16, 4.440892098500626e-16)"/>
      </svg>
    </div> 
    -->
  </div>

  <div class="step_details">
    <div class="step_detail">
      <div class="step_number">{{ 'Txt_DossierProspectPhaseStep1' | translate }}</div>
      <div class="step_name" [class.todo]="stepDiscover === 0" [class.current]="stepDiscover === 1" [class.done]="stepDiscover === 2">
        {{ 'Txt_DossierProspectPhaseDecouverte' | translate }}
      </div>
    </div>
    <div class="step_detail">
      <div class="step_number">{{ 'Txt_DossierProspectPhaseStep2' | translate }}</div>
      <div
        class="step_name"
        [class.todo]="stepIdentification === 0"
        [class.current]="stepIdentification === 1"
        [class.done]="stepIdentification === 2"
      >
        {{ 'Txt_DossierProspectPhaseIdentification' | translate }}
      </div>
    </div>
    <div class="step_detail last">
      <div class="step_number">{{ 'Txt_DossierProspectPhaseStep3' | translate }}</div>
      <div class="step_name" [class.todo]="stepClosing === 0" [class.current]="stepClosing === 1" [class.done]="stepClosing === 2">
        {{ 'Txt_DossierProspectPhaseClosing' | translate }}
      </div>
    </div>
    <!--
    <div class="step_detail last">
      <div class="step_number">{{ 'Txt_DossierProspectPhaseStep4' | translate }}</div>
      <div class="step_name" 
        [class.todo]="stepBilling===0"
        [class.current]="stepBilling===1"
        [class.done]="stepBilling===2">
        {{ 'Txt_DossierProspectPhaseFacturation' | translate }}
        
      </div>   
      <div class="step_tag">
        <span *ngIf="stepBilling===0" class="todo">{{ 'Txt_DossierProspectBientotDisponible' | translate }}</span> 
        <span *ngIf="stepBilling===1" class="current">{{ 'Txt_DossierProspectEnCours' | translate }}</span> 
        <span *ngIf="stepBilling===2" class="done">{{ 'Txt_DossierProspectValide' | translate }}</span>
      </div>  
    </div>
    -->
  </div>
</div>
