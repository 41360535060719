export enum FooterContactEnum {
  commercialPhone = 'FOOTER_LOGGED_COMMERCIAL_PHONE',
  comercialEmail = 'FOOTER_LOGGED_COMMERCIAL_EMAIL',
  mandatePhone = 'FOOTER_LOGGED_MANDATE_PHONE',
  mandateEmail = 'FOOTER_LOGGED_MANDATE_EMAIL',
  billingPhone = 'FOOTER_LOGGED_BILLING_PHONE',
  billingEmail = 'FOOTER_LOGGED_BILLING_EMAIL',
  developerEmail = 'FOOTER_PUBLIC_DEVELOPER_EMAIL',
  developerPhone = 'FOOTER_PUBLIC_DEVELOPER_PHONE',
  contractorEmail = 'FOOTER_PUBLIC_CONTRACTOR_EMAIL',
  contractorPhone = 'FOOTER_PUBLIC_CONTRACTOR_PHONE',
}
