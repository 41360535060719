export class DocumentResponse {
  id?: number;
  created?: Date;
  modified?: Date;
  deleted?: Date;
  title: string;
  fileName: string;
  mimeType?: string;
  documentTypeId?: number;
  documentTypeLabel?: string;
  size?: number;
  container: string;
  // /!\ correspond ici au genre du document (ex RCP KBIS etc) /!\
  type?: {
    code?: string;
    label?: string;
    mimeType?: string;
  };
  codeLabel?: string;
  url?;
  parameterText?: string[];
}
