import { Component, OnInit } from '@angular/core';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';
import { NgFor, NgIf } from '@angular/common';

import { IconComponent } from '../../standalone/icon/icon.component';

@Component({
  selector: 'app-formly-repeat',
  templateUrl: './formly-repeat.component.html',
  styleUrls: ['./formly-repeat.component.scss'],
  standalone: true,
  imports: [NgFor, FormlyModule, NgIf, IconComponent],
})
export class FormlyRepeatComponent extends FieldArrayType implements OnInit {
  public fields;

  public ngOnInit(): void {
    this.fields = this.field.fieldGroup['fieldGroup'];
  }

  public stringify(field): string {
    return JSON.stringify(field);
  }
}
