<!-- Civility -->
<div *ngIf="includeCivility" class="text-base my-2 font-semibold">{{ i18nService._('Txt_Page_Form_RegisterCompany_Civility') }} *</div>
<div *ngIf="includeCivility" class="flex flex-row justify-start items-center mb-2 mt-2 min-h-80px">
  <app-radio-button-populated
    class="flex"
    [referenceTable]="'Civilities'"
    [parentForm]="parentForm"
    [fieldName]="'civility'"
    [isSubmit]="isSubmit"
    [dataToInit]="contactsInfo"
    [required]="true"
  ></app-radio-button-populated>
</div>

<!-- LastName + Firstname -->
<app-name-information
  [isSubmit]="isSubmit"
  [parentForm]="parentForm"
  [firstNameRequired]="firstNameRequired"
  [lastNameRequired]="lastNameRequired"
  [lastNamePlaceholder]="lastNamePlaceholder"
  [firstNamePlaceholder]="firstNamePlaceholder"
  [lastNameTooltipText]="lastNameTooltipText"
></app-name-information>

<!-- Email -->
<div class="grid grid-cols-2 gap-x-16">
  <mat-form-field class="col-span-2 md:col-span-1 mb-3 flex fullW">
    <mat-label>{{ emailPlaceholder }}</mat-label>
    <input type="email" name="email" data-cy="email" [formControl]="emailControl" matInput maxlength="255" [required]="emailRequired" />
    <mat-error *ngIf="emailControl.errors">
      <p *ngIf="emailControl.errors.required">
        {{ i18nService._('Error_Field_Required') }}
      </p>
      <p *ngIf="!emailControl.errors.email && !emailControl.errors.required && !emailControl.errors.emailExist">
        {{ i18nService._('Error_Field_IncorrectEmail') }}
      </p>
      <p *ngIf="emailControl.errors.emailExist">
        {{ i18nService._('Error_Field_AccountEmailUsed') }}
      </p>
    </mat-error>
  </mat-form-field>
</div>

<!-- Mobile Phone + Desk Phone -->
<div class="grid grid-cols-2 gap-x-16 mt-4 mb-5">
  <app-phone-number-information
    class="col-span-2 md:col-span-1 mb-3"
    [parentForm]="parentForm"
    [isRequired]="mobilePhoneRequired"
    fieldName="mobilePhone"
    [placeholder]="mobilePhonePlaceholder"
    data-cy="mobile"
    [isSubmit]="isSubmit"
  ></app-phone-number-information>

  <app-phone-number-information
    *ngIf="deskPhoneDisplay"
    class="col-span-2 md:col-span-1 mb-3"
    [parentForm]="parentForm"
    [isRequired]="false"
    fieldName="deskPhone"
    [placeholder]="i18nService._('Txt_Placeholder_DeskPhone')"
    data-cy="deskPhone"
    [isSubmit]="isSubmit"
  ></app-phone-number-information>
</div>

<!-- Function Type Contact -->
<div *ngIf="chipFunctionDisplay">
  <div class="text-base mt-2 font-semibold">
    {{ i18nService._('Txt_Page_Form_RegisterCompany_FunctionType') }}
  </div>
  <app-chip-populated-with-other
    class="flex flex-col lg:flex-row mb-4"
    [referenceTable]="'CompanyContactFunctions'"
    [fieldName]="'companyContactFunction'"
    [parentForm]="parentForm"
    [isSubmit]="isSubmit"
    [dataToInit]="contactsInfo"
  >
  </app-chip-populated-with-other>
</div>
