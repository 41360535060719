import { AbstractControl, FormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';

export const atLeastOne = (listOfControls: Array<string>, errorMessage?: string): ValidatorFn => {
  return (group: AbstractControl): ValidationErrors | null => {
    if (group instanceof FormGroup) {
      const hasAtLeastOne = listOfControls.some((control) => !!group.get(control)?.value);

      if (!hasAtLeastOne) {
        const errors = { atLeastOne: errorMessage };
        Object.assign(group.errors || {}, errors); // Ajouter les erreurs à l'objet errors du FormGroup
        return errors;
      }
    }

    return null;
  };
};
