import { Component, Input } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';

import { MenuConfig } from '../header.component';
import { MenuItemComponent } from './menu-item/menu-item.component';

import { I18nService } from '../../../services/i18n.service';

@Component({
  selector: 'app-drop-menu',
  templateUrl: './drop-menu.component.html',
  styleUrls: ['./drop-menu.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, MenuItemComponent],
})
export class DropMenuComponent {
  @Input() menuItems: MenuConfig[];

  constructor(public readonly i18nService: I18nService) {}
}
