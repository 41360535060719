import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { NgIf } from '@angular/common';

import { RegisterFormProgramComponent } from '../register-form-program/register-form-program.component';

import { CompanyType } from '../../../utils/models/enums/CompanyType.enum';
import { ProgramStatus } from '../../../utils/models/enums/program-status.enum';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { ProgramResponseUpdateForm } from '../../../utils/models/ProgramResponseUpdateForm';
import { Sitemap } from '../../../utils/models/Sitemap';
import { AccountService } from '../../../utils/services/account.service';
import { TokenService } from '../../../utils/services/authorisation/token.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { ProgramService } from '../../services/program.service';

@Component({
  selector: 'app-validate-register-form-program',
  templateUrl: './validate-register-form-program.component.html',
  styleUrls: ['./validate-register-form-program.component.scss'],
  standalone: true,
  imports: [NgIf, RegisterFormProgramComponent],
})
export class ValidateRegisterFormProgramComponent implements OnInit {
  /**
   * Data infos of the programs
   *
   * @type {ProgramResponseUpdateForm}
   * @memberof ValidateRegisterFormProgramComponent
   */
  public recoveredInfo: ProgramResponseUpdateForm;

  /**
   * Boolean used to load registerProgram Component at the end of program subscribe
   *
   * @type {boolean}
   * @memberof ValidateRegisterFormProgramComponent
   */
  public isReady: boolean;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly programService: ProgramService,
    private readonly snackbarService: SnackbarService,
    private readonly router: Router,
    private readonly i18nService: I18nService,
    private readonly tokenService: TokenService,
    private readonly accountService: AccountService,
  ) {
    this.isReady = false;
  }

  ngOnInit(): void {
    // Get UserId
    const userId: number = this.tokenService.getUserId();

    // Get ProgramId
    const programId = Number(this.route.snapshot.paramMap.get('programId'));

    if (programId) {
      // Get program informations
      this.programService
        .getProgramInformations(programId)
        .pipe(
          switchMap((infos) => {
            return this.programService.getSpecialOffers(programId).pipe(map((specialOffers) => ({ ...infos, specialOffers })));
          }),
        )
        .subscribe(
          (infos) => {
            this.accountService.getAccountCompanyIdAndLabel(userId).subscribe((accountCompany) => {
              if (this.isValidatableProgram(infos, accountCompany.label)) {
                this.recoveredInfo = infos;
                this.programService.setIdProgramId(programId);
                this.isReady = true;
              } else {
                this.snackbarService.sendMessage({
                  // TO DO CHANGE MESSAGE.
                  text: this.i18nService._('Error_SnackBar_RedirectPageNotAllowed'),
                  type: SnackbarMessageType.Error,
                });
                this.router.navigate([Sitemap.utils.login.path]);
              }
            });
          },
          () => {
            this.snackbarService.sendErrorOccured();
          },
        );
    }
  }

  /**
   * Function to know if user can validate program.
   * VALO can validate only PENDING programs
   *
   * @private
   * @memberof ValidateRegisterFormProgramComponent
   */
  private readonly isValidatableProgram = (programInfos: ProgramResponseUpdateForm, accountCompanyLabel: string): boolean => {
    if (programInfos && CompanyType.VALORISSIMO === accountCompanyLabel && programInfos.programStatus.label === ProgramStatus.PENDING) {
      return true;
    }

    return false;
  };
}
