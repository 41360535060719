<!-- NOTE : if maxFractionDigits is > 0 and minFractionDigits === 0 then you will have some display issue with suffix and currency symbol -->

<p-inputNumber
  (onInput)="props.change && props.change(field, $event)"
  [currency]="props.currency ?? 'EUR'"
  [formControl]="formControl"
  [maxFractionDigits]="props.maxFractionDigits ?? 0"
  [minFractionDigits]="props.minFractionDigits ?? 0"
  [min]="props.min ?? 0"
  [mode]="props.mode ?? 'decimal'"
  [prefix]="props.prefix"
  [required]="props.required"
  [readonly]="props.readonly"
  [placeholder]="props.placeholder"
  [showButtons]="false"
  [suffix]="props.suffix"
  [useGrouping]="props.useGrouping ?? true"
  locale="fr-FR"
  [pTooltip]="props.tooltip"
  [inputStyleClass]="props.inputStyleClass"
></p-inputNumber>
