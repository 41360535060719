<div
  *ngIf="listLotsToCompare$ | async as listLotsToCompare; else spinner_item"
  [ngClass]="getComparatorPreviewBlocItemClass()"
  class="app-comparator-preview-bloc-container dropdown-angular-animation"
>
  <app-comparator-preview-item *ngFor="let lot of listLotsToCompare" [lot]="lot"></app-comparator-preview-item>
</div>
<app-comparator-preview-item *ngIf="!lotToCompare.length" [isEmpty]="true"></app-comparator-preview-item>
<ng-template #spinner_item>
  <div class="app-comparator-spinner-container" *ngIf="lotToCompare.length" [ngClass]="getComparatorPreviewBlocItemClass()">
    <div class="app-comparator-spinner-item" *ngFor="let lot of lotToCompare">
      <mat-progress-spinner color="primary" diameter="100" mode="indeterminate" value="30"></mat-progress-spinner>
    </div>
  </div>
</ng-template>
<div
  [ngClass]="lotToCompare.length <= 1 ? 'one-lot-actions-buttons' : 'multiple-lot-actions-buttons'"
  class="app-comparator-preview-bloc-button-container dropdown-angular-animation"
>
  <button
    [routerLink]="'/' + comparatorUrl"
    [disabled]="!lotToCompare.length"
    class="compare-lot-button"
    [class.disabled-button]="!lotToCompare.length"
    mat-flat-button
    (click)="accessComparator()"
  >
    {{ i18nService._('Txt_button_comparator_compare_lot_label') }}
  </button>

  <button (click)="clearLotsToCompareForCurrentUser()" *ngIf="lotToCompare.length" class="clear-lot-button" mat-flat-button>
    <mat-icon class="material-icons-round size-18">delete_outline</mat-icon>
    <span>{{ i18nService._('Txt_button_comparator_clear_lot_label') }}</span>
  </button>
</div>
