import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatSimulationValue',
  standalone: true,
})
export class FormatSimulationValuePipe implements PipeTransform {
  transform(value: any, format: string): string {
    if (value == null || value === undefined) {
      return '--';
    }

    switch (format) {
      case 'currency':
        return this.formatCurrency(value);
      case 'percent':
        return `${value} %`;
      default:
        return value.toString();
    }
  }

  private formatCurrency(value: number): string {
    if (value <= 0) {
      return '-- €';
    }
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(value);
  }
}
