import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

// eslint-disable-next-line max-len
import { UtilsModule } from '../utils/utils.module';
import { RegisterFormProspectComponent } from './components/register-form-prospect/register-form-prospect.component';
import { StepOneFormProspectComponent } from './components/step-one-form-prospect/step-one-form-prospect.component';
import { StepTwoFormProspectComponent } from './components/step-two-form-prospect/step-two-form-prospect.component';
import { ProspectsRoutingModule } from './prospects-routing.module';

@NgModule({
  imports: [
    CommonModule,
    ProspectsRoutingModule,
    UtilsModule,
    MatDialogModule,
    RegisterFormProspectComponent,
    StepOneFormProspectComponent,
    StepTwoFormProspectComponent,
  ],
  exports: [StepOneFormProspectComponent],
})
export class ProspectsModule {}
