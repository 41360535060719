export enum TransactionStatus {
  OPTION_PENDING = 'OPTION_PENDING',
  OPTION_REFUSED = 'OPTION_REFUSED',
  OPTION_ACCEPTED = 'OPTION_ACCEPTED',
  OPTION_EXPIRED = 'OPTION_EXPIRED',
  PENDING = 'PENDING',
  PRERESERVATION_CANCELED = 'PRERESERVATION_CANCELED',
  PRERESERVATION_REJECTED = 'PRERESERVATION_REJECTED',
  PRERESERVED = 'PRERESERVED',
  RECEIVED_VALO = 'RECEIVED_VALO',
  VALIDATED_VALO = 'VALIDATED_VALO',
  SENT_DEVELOPER = 'SENT_DEVELOPER',
  VALIDATED_DEVELOPER = 'VALIDATED_DEVELOPER',
  REJECTED_DEVELOPER = 'REJECTED_DEVELOPER',
  SRU_NOTIF_SET = 'SRU_NOTIF_SET',
  NOTIFICATION_SRU_IN_PROGRESS = 'NOTIFICATION_SRU_IN_PROGRESS',
  NOTIFICATION_SRU_PURGED = 'NOTIFICATION_SRU_PURGED',
  AGREEMENT_OBTAINED = 'AGREEMENT_OBTAINED',
  LOAN_OFFER_OBTAINED = 'LOAN_OFFER_OBTAINED',
  NOTARY_MEETING_OBTAINED = 'NOTARY_MEETING_OBTAINED',
  RESERVATION_CANCELED = 'RESERVATION_CANCELED',
  NOTARY_SIGNATURE_OBTAINED = 'NOTARY_SIGNATURE_OBTAINED',
  CLIENT_DESISTED = 'CLIENT_DESISTED',
  RESERVATION_IN_SIGNATURE = 'RESERVATION_IN_SINGATURE',
}
