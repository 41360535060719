import { CirclePaint, Layer, SymbolLayout } from 'mapbox-gl';

export const FRANCE_COORDINATES = {
  id: 'country.19008108158641660',
  type: 'Feature',
  place_type: ['country'],
  relevance: 1,
  properties: {
    wikidata: 'Q142',
    short_code: 'fr',
  },
  'text_fr-FR': 'France',
  'language_fr-FR': 'fr',
  'place_name_fr-FR': 'France',
  text: 'France',
  language: 'fr',
  place_name: 'France',
  bbox: [-5.24227249992918, 41.3108229, 9.66015649291518, 51.148409399929],
  center: [2.61878695312962, 47.8249046208979],
  geometry: {
    type: 'Point',
    coordinates: [2.61878695312962, 47.8249046208979],
  },
};

export const PROGRAM_SOURCE_ID = 'program-data';
export const HOVERED_PROGRAM_SOURCE_ID = 'program-data-for-hover';

export const PROGRAM_MARKER_LAYOUT: SymbolLayout = {
  // The icon-allow-overlap is very important here, if we change marker icons appears behind clusters icons
  'icon-image': 'marker',
  'icon-size': 0.8,
  'icon-ignore-placement': true,
  'icon-allow-overlap': true,
  'text-allow-overlap': true,
  'text-ignore-placement': true,
  'text-field': ['get', 'nbLots'],
  'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
  'text-size': 12,
  'text-line-height': 12,
  'text-offset': [0, -0.3],
};
export const PROGRAM_MARKER_LAYER: Layer = {
  id: 'program-markers',
  type: 'symbol',
  source: PROGRAM_SOURCE_ID,
  layout: PROGRAM_MARKER_LAYOUT,
};

export const HOVERED_PROGRAM_MARKER_LAYOUT: SymbolLayout = { ...PROGRAM_MARKER_LAYOUT, 'icon-image': 'marker-hovered' };

export const HOVERED_PROGRAM_MARKER_LAYER: Layer = {
  id: 'program-markers-for-hover',
  type: 'symbol',
  source: HOVERED_PROGRAM_SOURCE_ID,
  layout: HOVERED_PROGRAM_MARKER_LAYOUT,
};

export const PROGRAM_CLUSTER_LAYOUT: SymbolLayout = {
  // The text-allow-overlap is very important here, if we change some numbers don't show at first zoom level
  'text-allow-overlap': true,
  'text-ignore-placement': true,
  'icon-ignore-placement': true,
  'icon-allow-overlap': true,
  'text-field': ['get', 'sum'],
  'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
  'text-size': 14,
  'text-line-height': 14,
};
export const PROGRAM_CLUSTER_PAINT: CirclePaint = {
  // Use step expressions if you want to add multiple circles colors (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
  'circle-color': '#55B6D4',
  'circle-stroke-color': '#FFFFFF',
  'circle-stroke-width': 3,
  'circle-radius': 20,
};
export const PROGRAM_CLUSTER_LAYER: Layer = {
  id: 'program-clusters',
  type: 'circle',
  source: PROGRAM_SOURCE_ID,
  filter: ['has', 'point_count'],
  paint: PROGRAM_CLUSTER_PAINT,
};
export const PROGRAM_CLUSTER_COUNT_LAYER: Layer = {
  id: 'program-clusters-count',
  type: 'symbol',
  source: PROGRAM_SOURCE_ID,
  filter: ['has', 'point_count'],
  layout: PROGRAM_CLUSTER_LAYOUT,
  paint: { 'text-color': '#ffffff' },
};
