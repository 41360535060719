import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { firstValueFrom } from 'rxjs';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { UntypedFormGroup } from '@angular/forms';

import { I18nService } from '../../utils/services/i18n.service';
import { MandatedDeveloperCompanyData } from '../../utils/models/MandatedDeveloperCompanyData';
import { LOT_ORIENTATION, PROGRAM_TYPES } from '../../utils/models/app-constant';
import { SecondaryLotService } from '../../programs/services/secondary-lot.service';
import { ReferenceTablesService } from '../../utils/services/reference-tables.service';
import { Sitemap } from '../../utils/models/Sitemap';
import { ButtonRendererComponent } from '../../tracking-tables/renderers/button-renderer/button-renderer.component';
import { GridTrackingTableComponent } from '../../tracking-tables/components/grid-tacking-table/grid-tracking-table.component';
import { TaxationResponse } from '../../utils/models/TaxationResponse';
import { SecondaryLotTypeResponse } from '../../utils/models/SecondaryLotTypeResponse';
import { SecondaryLotAddDialogComponent } from '../../dialog/components/secondary-lot-add-dialog/secondary-lot-add-dialog.component';
import { FeatureFlagService } from '../../feature-flag/feature-flag.service';

@Injectable({
  providedIn: 'root',
})
export class PreReservationCreateFormlyService {
  devCompaniesSub: BehaviorSubject<MandatedDeveloperCompanyData[]> = new BehaviorSubject<MandatedDeveloperCompanyData[]>(null);
  secondaryLotTypes: SecondaryLotTypeResponse[] = [];
  taxations: TaxationResponse[];

  isDossierProspectEnabled = false;

  private RESERVATION_MOTIVE = {
    CONTRACT_SIGNED: 1,
    SIGNING_APPOINTMENT_PROJECTED: 2,
    WAITING_FOR_DEVELOPER: 3,
    OTHER: 4,
  };

  constructor(
    private readonly i18nService: I18nService,
    private readonly secondaryLotService: SecondaryLotService,
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly featureFlagService: FeatureFlagService,
  ) {
    this.featureFlagService.isEnabled('dossier-prospect').then((enabled) => {
      this.isDossierProspectEnabled = enabled;
    });
  }

  //**************Forms*********************/

  async getForm(): Promise<FormlyFieldConfig[]> {
    await this.getSecondaryLotTypes();
    await this.getTaxations();
    return [
      {
        type: 'stepper',
        props: {
          disabledRightSideBar: true,
          hideNavigationButton: true,
          backButtonLabel: this.i18nService._('txt_back'),
          goBackRoute: Sitemap.dashboards.preReservations.path,
          deactivateScrollTop: true,
          stickySideBar: true,
        },
        fieldGroup: [
          {
            props: {
              id: 'preresa',
              label: 'TXT_stepper_preresa_header',
              isHead: true,
            },
          },
          await this.getInformationLotForm(),
          this.getInformationSellerForm(),
          this.isDossierProspectEnabled ? this.getInformationDossierProspectForm() : this.getInformationProspectForm(),
          await this.getInformationJustificatifForm(),
        ],
      },
    ];
  }

  private async getInformationLotForm() {
    return {
      props: {
        label: this.i18nService._('PreReservationCreateForm_LotInformation'),
        headId: 'preresa',
        formTitle: 'PreReservationCreateForm_Title',
      },
      fieldGroup: [
        this.getInformationProgramForm(),
        {
          type: 'text',
          props: {
            label: this.i18nService._('PreReservationCreateForm_LotPrincipal'),
            titleLevel: 5,
          },
          className: 'mb-4',
        },
        {
          key: 'lot',
          type: 'autocomplete',
          props: {
            label: this.i18nService._('PreReservationCreateForm_LotNumber'),
            autoCompleteService: 'programLot',
            passObject: true,
            forceChoice: true,
            placeholder: this.i18nService._('PreReservationCreateForm_LotNumber_Placeholder'),
            dropdown: true,
            showClear: true,
            disabled: true,
          },
          expressions: {
            'props.disabled': (field: FormlyFieldConfig) => {
              return !field.model.program;
            },
            'props.programId': (field: FormlyFieldConfig) => {
              return field.model.program ? field.model.program.id : undefined;
            },
            'props.required': (field: FormlyFieldConfig) => {
              return !field.model?.lotRef;
            },
          },
          className: 'mb-8 w-1/2 pr-10',
        },
        {
          type: 'info-box',
          className: 'mb-8',
          props: {
            label: this.i18nService._('PreReservationCreateForm_LotPrincipalDescription'),
          },
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-20 mb-8',
          fieldGroup: [
            {
              key: 'lotRef',
              type: 'input',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotRef'),
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'col-span-1',
            },
            {
              key: 'lotNumber',
              type: 'input',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotNumber'),
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'col-span-1',
            },
          ],
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-20 mb-8',
          fieldGroup: [
            {
              key: 'lotProgramTypeId',
              type: 'select',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotProgramType'),
                options: this.getProgramTypes(),
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'col-span-1',
            },
            {
              key: 'lotOrientationId',
              type: 'select',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotOrientation'),
                options: this.getLotOrientations(),
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'col-span-1',
            },
          ],
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-20 mb-8',
          fieldGroup: [
            {
              key: 'lotLivingSpace',
              type: 'number',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotLivingSpace'),
                maxFractionDigits: 2,
                minFractionDigits: 2,
                suffix: ' m2',
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'col-span-1',
            },
            {
              key: 'lotRooms',
              type: 'number',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotRooms'),
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'col-span-1',
            },
          ],
        },
        {
          key: 'lotFloor',
          type: 'number',
          props: {
            label: this.i18nService._('PreReservationCreateForm_LotFloor'),
          },
          expressions: {
            'props.disabled': (field: FormlyFieldConfig) => {
              return field.model?.lot?.id != undefined;
            },
            'props.required': (field: FormlyFieldConfig) => {
              return !field.model?.lot?.id;
            },
          },
          className: 'mb-8 w-1/2 pr-10',
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-20 mb-1',
          fieldGroup: [
            {
              key: 'lotTerrace',
              type: 'radio',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotTerrace'),
                options: [
                  {
                    label: this.i18nService._('Txt_RadioButton_Yes'),
                    value: true,
                  },
                  {
                    label: this.i18nService._('Txt_RadioButton_No'),
                    value: false,
                  },
                ],
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'mb-8 inline-radio',
            },
            {
              key: 'lotTerraceArea',
              type: 'number',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotTerraceArea'),
                disabled: true,
                maxFractionDigits: 2,
                minFractionDigits: 2,
                suffix: ' m2',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return field.model.lotTerrace !== true;
                },
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'mb-8 inline-radio',
            },
          ],
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-20 mb-1',
          fieldGroup: [
            {
              key: 'lotBalcony',
              type: 'radio',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotBalcony'),
                options: [
                  {
                    label: this.i18nService._('Txt_RadioButton_Yes'),
                    value: true,
                  },
                  {
                    label: this.i18nService._('Txt_RadioButton_No'),
                    value: false,
                  },
                ],
                disabled: true,
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'mb-8 inline-radio',
            },
            {
              key: 'lotBalconyArea',
              type: 'number',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotBalconyArea'),
                disabled: true,
                maxFractionDigits: 2,
                minFractionDigits: 2,
                suffix: ' m2',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return field.model.lotBalcony !== true;
                },
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id !== undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'mb-8 inline-radio',
            },
          ],
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-20 mb-1',
          fieldGroup: [
            {
              key: 'lotGarden',
              type: 'radio',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotGarden'),
                options: [
                  {
                    label: this.i18nService._('Txt_RadioButton_Yes'),
                    value: true,
                  },
                  {
                    label: this.i18nService._('Txt_RadioButton_No'),
                    value: false,
                  },
                ],
                disabled: true,
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'mb-8 inline-radio',
            },
            {
              key: 'lotGardenArea',
              type: 'number',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotGardenArea'),
                disabled: true,
                maxFractionDigits: 2,
                minFractionDigits: 2,
                suffix: ' m2',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return field.model.lotGarden !== true;
                },
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'mb-8 inline-radio',
            },
          ],
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-20 mb-1',
          fieldGroup: [
            {
              key: 'lotLoggia',
              type: 'radio',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotLoggia'),
                options: [
                  {
                    label: this.i18nService._('Txt_RadioButton_Yes'),
                    value: true,
                  },
                  {
                    label: this.i18nService._('Txt_RadioButton_No'),
                    value: false,
                  },
                ],
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'mb-8 inline-radio',
            },
            {
              key: 'lotLoggiaArea',
              type: 'number',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotLoggiaArea'),
                maxFractionDigits: 2,
                minFractionDigits: 2,
                suffix: ' m2',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return field.model.lotLoggia !== true;
                },
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'mb-8 inline-radio',
            },
          ],
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-20 mb-8',
          fieldGroup: [
            {
              key: 'lotDeliveryDate',
              type: 'calendar',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotDeliveryDate'),
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'col-span-1',
            },
          ],
        },
        {
          key: 'lotTaxations',
          type: 'select-multiple',
          props: {
            label: this.i18nService._('PreReservationCreateForm_LotTaxations'),
            options: this.taxations,
            optionValue: 'id',
            multiple: true,
            showHeader: false,
          },
          expressions: {
            'props.disabled': (field: FormlyFieldConfig) => {
              return field.model?.lot?.id != undefined;
            },
            'props.required': (field: FormlyFieldConfig) => {
              return !field.model?.lot?.id;
            },
          },
          className: 'mb-8 w-1/2 pr-10',
        },
        {
          key: 'lotCommercialLotSellingPriceIT',
          type: 'number',
          props: {
            label: this.i18nService._('PreReservationCreateForm_LotCommercialLotSellingPriceIT'),
            suffix: ' €',
          },
          expressions: {
            'props.disabled': (field: FormlyFieldConfig) => {
              return field.model?.lot?.id != undefined;
            },
            'props.required': (field: FormlyFieldConfig) => {
              return !field.model?.lot?.id;
            },
          },
          className: 'mb-8 w-1/2 pr-10',
        },
        this.getLotTaxationFields(),
        {
          key: 'lotDocument',
          type: 'file',
          props: {
            label: this.i18nService._('lotPlan'),
            files: [],
            types: ['.pdf', '.jpeg', '.png', '.jpg'],
            typeDoc: 'SOAM',
            multiple: false,
            disabled: false,
            maxFileSize: 1024 ** 2 * 20, // 20 Mo
            acceptedFilesLabel: 'Documents acceptés : PDF, JPEG, JPG, PNG',
          },
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return field.model?.lot?.id != undefined;
            },
            'props.required': (field: FormlyFieldConfig) => {
              return !field.model?.lot?.id;
            },
          },
          className: 'mb-8 w-1/2',
        },
        {
          type: 'text',
          props: {
            label: this.i18nService._('PreReservationCreateForm_SecondaryLot'),
            titleLevel: 5,
          },
          className: 'mb-8',
        },
        {
          key: 'secondaryLotPriceIncluded',
          type: 'radio',
          props: {
            label: this.i18nService._('PreReservationCreateForm_Annexe'),
            options: [
              {
                label: this.i18nService._('Txt_RadioButton_Yes'),
                value: 1,
              },
              {
                label: this.i18nService._('Txt_RadioButton_No'),
                value: 0,
              },
            ],
          },
          expressions: {
            'props.disabled': (field: FormlyFieldConfig) => {
              return field.model?.lot?.id != undefined;
            },
            'props.required': (field: FormlyFieldConfig) => {
              return !field.model?.lot?.id;
            },
          },
          className: 'mb-8 inline-radio',
        },
        {
          key: 'secondaryLots',
          type: 'table',
          props: {
            columnDefs: this.createSecondaryLotsColumnDefs(),
            addButtonLabel: 'PreReservationCreateForm_AddSecondaryLot',
            data: { secondaryLotTypes: this.secondaryLotTypes },
            addDialog: SecondaryLotAddDialogComponent,
            canAddRow: true,
            disablePagination: true,
            onFieldCreated: (table: GridTrackingTableComponent, form: UntypedFormGroup, field: FormlyFieldConfig) => {
              form.get('lot').valueChanges.subscribe(() => {
                const editable = field.model?.lot?.id === undefined;
                table.columnDefs.find((col) => col.field === 'remove_enabled').hide = !editable;
                table.gridApi.updateGridOptions({
                  defaultColDef: {
                    cellClass: editable ? '' : 'read-only',
                  },
                });
                table.gridApi.setGridOption('columnDefs', table.columnDefs);
              });
            },
          },
          expressions: {
            'props.disabled': (field: FormlyFieldConfig) => {
              return field.model?.lot?.id != undefined;
            },
          },
          className: 'w-full',
        },
      ],
    };
  }

  private getLotTaxationFields() {
    return {
      expressions: {
        hide: (field: FormlyFieldConfig) => {
          return !field.model?.lotTaxations?.length;
        },
      },
      fieldGroup: [
        {
          type: 'text',
          props: {
            label: this.i18nService._('PreReservationCreateForm_LotTaxationLMNP'),
            titleLevel: 5,
          },
          className: 'mb-4',
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return !field.model?.lotTaxations?.find((id) => this.taxations.find((taxation) => taxation.id === id).label === 'LMNP/LMP');
            },
          },
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-y-8 gap-x-20 mb-8',
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return !field.model?.lotTaxations?.find((id) => this.taxations.find((taxation) => taxation.id === id).label === 'LMNP/LMP');
            },
          },
          fieldGroup: [
            {
              key: 'lotGuaranteedMonthlyRentingPriceIT',
              type: 'number',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotGuaranteedMonthlyRentingPriceIT'),
                suffix: ' €',
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'col-span-1',
            },
            {
              key: 'lotEstatePriceET',
              type: 'number',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotEstatePriceET'),
                suffix: ' €',
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'col-span-1',
            },
            {
              key: 'lotHousingPriceET',
              type: 'number',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotHousingPriceET'),
                suffix: ' €',
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'col-span-1',
            },
            {
              key: 'lotTangiblePriceET',
              type: 'number',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotTangiblePriceET'),
                suffix: ' €',
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'col-span-1',
            },
            {
              key: 'lotGlobalSellingPriceET',
              type: 'number',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotGlobalSellingPriceET'),
                suffix: ' €',
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'col-span-1',
            },
          ],
        },
        {
          type: 'text',
          props: {
            label: this.i18nService._('PreReservationCreateForm_LotTaxationNuePropriete'),
            titleLevel: 5,
          },
          className: 'mb-4',
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return !field.model?.lotTaxations?.find(
                (id) => this.taxations.find((taxation) => taxation.id === id).label === 'NUE PROPRIETE',
              );
            },
          },
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-y-8 gap-x-20 mb-8',
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return !field.model?.lotTaxations?.find(
                (id) => this.taxations.find((taxation) => taxation.id === id).label === 'NUE PROPRIETE',
              );
            },
          },
          fieldGroup: [
            {
              key: 'lotNueProprietePrice',
              type: 'number',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotNueProprietePrice'),
                suffix: ' €',
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'col-span-1',
            },
            {
              key: 'lotUsufruitValue',
              type: 'number',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotUsufruitValue'),
                suffix: ' €',
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'col-span-1',
            },
            {
              key: 'lotDistributionKey',
              type: 'input',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotDistributionKey'),
                disabled: true,
              },
              expressions: {
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'col-span-1',
            },
            {
              key: 'lotNueProprieteDuration',
              type: 'number',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotNueProprieteDuration'),
                suffix: ' ans',
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'col-span-1',
            },
          ],
        },
        {
          type: 'text',
          props: {
            label: this.i18nService._('PreReservationCreateForm_LotTaxationMalraux'),
            titleLevel: 5,
          },
          className: 'mb-4',
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return !field.model?.lotTaxations?.find((id) => this.taxations.find((taxation) => taxation.id === id).label === 'MALRAUX');
            },
          },
        },
        {
          type: 'text',
          props: {
            label: this.i18nService._('PreReservationCreateForm_LotTaxationMonumentsHistorique'),
            titleLevel: 5,
          },
          className: 'mb-4',
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return !field.model?.lotTaxations?.find(
                (id) => this.taxations.find((taxation) => taxation.id === id).label === 'MONUMENTS HISTORIQUES',
              );
            },
          },
        },

        {
          type: 'text',
          props: {
            label: this.i18nService._('PreReservationCreateForm_LotTaxationMonumentsDeficitFoncier'),
            titleLevel: 5,
          },
          className: 'mb-4',
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return !field.model?.lotTaxations?.find(
                (id) => this.taxations.find((taxation) => taxation.id === id).label === 'DEFICIT FONCIER',
              );
            },
          },
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-y-8 gap-x-20 mb-8',
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return (
                !field.model?.lotTaxations?.find(
                  (id) => this.taxations.find((taxation) => taxation.id === id).label === 'DEFICIT FONCIER',
                ) &&
                !field.model?.lotTaxations?.find((id) => this.taxations.find((taxation) => taxation.id === id).label === 'MALRAUX') &&
                !field.model?.lotTaxations?.find(
                  (id) => this.taxations.find((taxation) => taxation.id === id).label === 'MONUMENTS HISTORIQUES',
                )
              );
            },
          },
          fieldGroup: [
            {
              key: 'lotEstimatedWorkPrice',
              type: 'number',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotEstimatedWorkPrice'),
                suffix: ' €',
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'col-span-1',
            },
            {
              key: 'lotEstimatedLandPrice',
              type: 'number',
              props: {
                label: this.i18nService._('PreReservationCreateForm_LotEstimatedLandPrice'),
                suffix: ' €',
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.lot?.id != undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.lot?.id;
                },
              },
              className: 'col-span-1',
            },
          ],
        },
      ],
    };
  }

  private getInformationProgramForm() {
    return {
      fieldGroup: [
        {
          key: 'developer',
          type: 'autocomplete',
          props: {
            label: this.i18nService._('PreReservationCreateForm_Developer'),
            autoCompleteService: 'developerCompany',
            passObject: true,
            forceChoice: true,
            required: true,
            placeholder: this.i18nService._('PreReservationCreateForm_Developer_Placeholder'),
            dropdown: true,
            showClear: true,
          },
          className: 'mb-8 w-1/2 pr-10',
        },
        {
          className: 'formgroup program codepostal 6',
          fieldGroupClassName: 'grid grid-cols-2 gap-20 mb-8',
          fieldGroup: [
            {
              key: 'program',
              type: 'autocomplete',
              props: {
                label: this.i18nService._('PreReservationCreateForm_Program'),
                autoCompleteService: 'program',
                passObject: true,
                forceChoice: true,
                required: true,
                placeholder: this.i18nService._('PreReservationCreateForm_Program_Placeholder'),
                dropdown: true,
                showClear: true,
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return !field.model.developer;
                },
                'props.companyId': (field: FormlyFieldConfig) => {
                  return field.model.developer ? field.model.developer.id : undefined;
                },
              },
              className: 'col-span-1',
            },
          ],
        },
      ],
    };
  }

  public getInformationSellerForm() {
    return {
      props: {
        label: this.i18nService._('PreReservationCreateForm_Seller'),
        headId: 'preresa',
        formTitle: 'PreReservationCreateForm_Title',
      },
      fieldGroup: [
        {
          key: 'seller',
          type: 'autocomplete',
          props: {
            label: this.i18nService._('PreReservationCreateForm_SellerName'),
            autoCompleteService: 'seller',
            passObject: true,
            forceChoice: true,
            required: true,
            placeholder: this.i18nService._('PreReservationCreateForm_SellerName_Placeholder'),
            dropdown: true,
            showClear: true,
          },
          className: 'mb-8 w-1/2 pr-10',
          expressions: {
            'props.companyTypeIds': (field: FormlyFieldConfig) => {
              let companyTypeIds: number[];
              if (field?.model?.developer?.isBi) {
                companyTypeIds = [3];
              } else {
                companyTypeIds = [3, 4];
              }
              return companyTypeIds;
            },
          },
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-x-20 gap-y-8 mb-8',
          fieldGroup: [
            {
              key: 'sellerPhone',
              type: 'phone',
              props: {
                label: this.i18nService._('PreReservationCreateForm_SellerPhone'),
                require: true,
                disabled: true,
                phoneOptions: { autoPlaceholder: 'off' },
              },
              className: 'col-span-1',
            },
            {
              key: 'sellerEmail',
              type: 'input',
              props: {
                label: this.i18nService._('PreReservationCreateForm_SellerEmail'),
                require: true,
                disabled: true,
              },
              className: 'col-span-1',
            },
            {
              key: 'sellerPostalCode',
              type: 'input',
              props: {
                label: this.i18nService._('PreReservationCreateForm_SellerPostalCode'),
                require: true,
                disabled: true,
              },
              className: 'col-span-1',
            },
            {
              key: 'sellerCity',
              type: 'input',
              props: {
                label: this.i18nService._('PreReservationCreateForm_SellerCity'),
                require: true,
                disabled: true,
              },
              className: 'col-span-1',
            },
          ],
        },
      ],
    };
  }

  public getInformationProspectForm() {
    return {
      props: {
        label: this.i18nService._('PreReservationCreateForm_Prospect'),
        headId: 'preresa',
        formTitle: 'PreReservationCreateForm_Title',
      },
      fieldGroup: [
        {
          type: 'text',
          props: {
            label: this.i18nService._('PreReservationCreateForm_ProspectDescription'),
          },
          className: 'w-full mb-8',
        },
        {
          key: 'prospect',
          type: 'autocomplete',
          props: {
            label: this.i18nService._('PreReservationCreateForm_ProspectName'),
            autoCompleteService: 'prospectLegacy',
            passObject: true,
            forceChoice: true,
            placeholder: this.i18nService._('PreReservationCreateForm_ProspectName_Placeholder'),
            dropdown: true,
            showClear: true,
          },
          expressions: {
            'props.accountId': (field: FormlyFieldConfig) => {
              return field.model.seller ? field.model.seller.id : undefined;
            },
            'props.disabled': (field: FormlyFieldConfig) => {
              return field.model?.seller?.id === undefined;
            },
            'props.required': (field: FormlyFieldConfig) => {
              return !field.model?.prospectEmail;
            },
          },
          className: 'mb-8 w-1/2 pr-10',
        },
        {
          type: 'info-box',
          props: {
            label: this.i18nService._('PreReservationCreateForm_ProspectDescriptionCreation'),
          },
          className: 'mb-8',
        },
        {
          key: 'prospectCivility',
          type: 'radio',
          props: {
            label: this.i18nService._('PreReservationCreateForm_ProspectCivility'),
            options: [
              {
                label: this.i18nService._('PreReservationCreateForm_Option_H'),
                value: 1,
              },
              {
                label: this.i18nService._('PreReservationCreateForm_Option_F'),
                value: 2,
              },
            ],
          },
          expressions: {
            'props.disabled': (field: FormlyFieldConfig) => {
              return field.model?.prospect?.id !== undefined;
            },
            'props.required': (field: FormlyFieldConfig) => {
              return !field.model?.prospect?.id;
            },
          },
          className: 'mb-8 inline-radio',
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-20 mb-8',
          fieldGroup: [
            {
              key: 'prospectLastName',
              type: 'input',
              props: {
                label: this.i18nService._('PreReservationCreateForm_ProspectLastName'),
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.prospect?.id !== undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.prospect?.id;
                },
              },
              className: 'col-span-1',
            },
            {
              key: 'prospectFirstName',
              type: 'input',
              props: {
                label: this.i18nService._('PreReservationCreateForm_ProspectFirstName'),
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.prospect?.id !== undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.prospect?.id;
                },
              },
              className: 'col-span-1',
            },
          ],
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-20 mb-8',
          fieldGroup: [
            {
              key: 'prospectEmail',
              type: 'input',
              props: {
                label: this.i18nService._('PreReservationCreateForm_ProspectEmail'),
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.prospect?.id !== undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.prospect?.id;
                },
              },
              className: 'col-span-1',
            },
            {
              key: 'prospectPhone',
              type: 'phone',
              props: {
                label: this.i18nService._('PreReservationCreateForm_ProspectPhone'),
                phoneOptions: { autoPlaceholder: 'off' },
              },
              expressions: {
                'props.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.prospect?.id !== undefined;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  return !field.model?.prospect?.id;
                },
              },
              className: 'col-span-1',
            },
          ],
        },

        {
          key: 'prospectCountryFrance',
          type: 'radio',
          props: {
            label: this.i18nService._('PreReservationCreateForm_ProspectCountry'),
            options: [
              {
                label: this.i18nService._('PreReservationCreateForm_Option_France'),
                value: true,
              },
              {
                label: this.i18nService._('PreReservationCreateForm_Option_Autre'),
                value: false,
              },
            ],
          },
          expressions: {
            'props.disabled': (field: FormlyFieldConfig) => {
              return field.model?.prospect?.id !== undefined;
            },
          },
          className: 'mb-8 inline-radio col-span-1',
        },
        {
          key: 'prospectCountryOther',
          type: 'autocomplete',
          props: {
            label: this.i18nService._('PreReservationCreateForm_ProspectCountry'),
            autoCompleteService: 'country',
            passObject: true,
            forceChoice: true,
            placeholder: this.i18nService._('PreReservationCreateForm_Country_Placeholder'),
            dropdown: true,
            showClear: true,
          },
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return field.model?.prospectCountryFrance !== false;
            },
          },
          className: 'mb-8 inline-radio col-span-1',
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-20 mb-8',
          fieldGroup: [
            {
              key: 'prospectLocalisation',
              type: 'city',
              props: {
                city: {
                  label: this.i18nService._('PreReservationCreateForm_ProspectCity'),
                },
                postalCode: {
                  label: this.i18nService._('PreReservationCreateForm_ProspectPostalCode'),
                },
              },
              expressions: {
                'props.city.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.prospect?.id !== undefined;
                },
                'props.postalCode.disabled': (field: FormlyFieldConfig) => {
                  return field.model?.prospect?.id !== undefined;
                },
              },
              className: 'col-span-2',
            },
          ],
        },
      ],
    };
  }

  public getInformationDossierProspectForm() {
    const companyInformations = {
      fieldGroupClassName: 'grid grid-cols-2 gap-20 mb-8',
      fieldGroup: [
        {
          key: 'prospectCompanySiret',
          type: 'input',
          props: {
            label: this.i18nService._('Prospect_Form_Company_SIRET'),
            disabled: true,
          },
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return field.model?.dossierProspect?.prospects[0].prospectType === 'COMPANY' ? false : true;
            },
          },
          className: 'col-span-1',
        },
        {
          key: 'prospectCompanyName',
          type: 'input',
          props: {
            label: this.i18nService._('Prospect_Form_Company_Name'),
            disabled: true,
          },
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return field.model?.dossierProspect?.prospects[0].prospectType === 'COMPANY' ? false : true;
            },
          },
          className: 'col-span-1',
        },
      ],
    };

    const prospectInformations = (index: number) => [
      {
        type: 'text',
        className: 'w-full mb-8',
        expressions: {
          'props.label': (field: FormlyFieldConfig) => {
            return field.model?.dossierProspect?.prospects.length === 1
              ? this.i18nService._('PreReservationCreateForm_ProspectName')
              : this.i18nService._('PreReservationCreateForm_ProspectName') + ' ' + (index + 1);
          },
          hide: (field: FormlyFieldConfig) => {
            return field.model?.dossierProspect?.prospects[index] ? false : true;
          },
        },
      },
      {
        key: 'prospectCivility' + index,
        type: 'radio',
        props: {
          label: this.i18nService._('PreReservationCreateForm_ProspectCivility'),
          options: [
            {
              label: this.i18nService._('PreReservationCreateForm_Option_H'),
              value: 'Monsieur',
            },
            {
              label: this.i18nService._('PreReservationCreateForm_Option_F'),
              value: 'Madame',
            },
          ],
          disabled: true,
        },
        expressions: {
          hide: (field: FormlyFieldConfig) => {
            return field.model?.dossierProspect?.prospects[index] ? false : true;
          },
        },
        className: 'mb-8 inline-radio',
      },
      {
        fieldGroupClassName: 'grid grid-cols-2 gap-20 mb-8',
        fieldGroup: [
          {
            key: 'prospectLastName' + index,
            type: 'input',
            props: {
              label: this.i18nService._('PreReservationCreateForm_ProspectLastName'),
              disabled: true,
            },
            expressions: {
              hide: (field: FormlyFieldConfig) => {
                return field.model?.dossierProspect?.prospects[index] ? false : true;
              },
            },
            className: 'col-span-1',
          },
          {
            key: 'prospectFirstName' + index,
            type: 'input',
            props: {
              label: this.i18nService._('PreReservationCreateForm_ProspectFirstName'),
              disabled: true,
            },
            expressions: {
              hide: (field: FormlyFieldConfig) => {
                return field.model?.dossierProspect?.prospects[index] ? false : true;
              },
            },
            className: 'col-span-1',
          },
        ],
      },
      {
        fieldGroupClassName: 'grid grid-cols-2 gap-20 mb-8',
        fieldGroup: [
          {
            key: 'prospectEmail' + index,
            type: 'input',
            props: {
              label: this.i18nService._('PreReservationCreateForm_ProspectEmail'),
              disabled: true,
            },
            expressions: {
              hide: (field: FormlyFieldConfig) => {
                return field.model?.dossierProspect?.prospects[index] ? false : true;
              },
            },
            className: 'col-span-1',
          },
          {
            key: 'prospectPhone' + index,
            type: 'phone',
            props: {
              label: this.i18nService._('PreReservationCreateForm_ProspectPhone'),
              phoneOptions: { autoPlaceholder: 'off' },
              disabled: true,
            },
            expressions: {
              hide: (field: FormlyFieldConfig) => {
                return field.model?.dossierProspect?.prospects[index] ? false : true;
              },
            },
            className: 'col-span-1',
          },
        ],
      },
    ];

    return {
      props: {
        label: this.i18nService._('PreReservationCreateForm_Prospect'),
        headId: 'preresa',
        formTitle: 'PreReservationCreateForm_Title',
      },
      fieldGroup: [
        {
          type: 'text',
          props: {
            label: this.i18nService._('PreReservationCreateForm_ProspectDescription'),
          },
          className: 'w-full mb-8',
        },
        {
          key: 'dossierProspect',
          type: 'autocomplete',
          props: {
            label: this.i18nService._('PreReservationCreateForm_DossierProspectName'),
            autoCompleteService: 'dossierProspectByPrescriptor',
            passObject: true,
            forceChoice: true,
            placeholder: this.i18nService._('PreReservationCreateForm_DossierProspectName_Placeholder'),
            dropdown: true,
            showClear: true,
          },
          expressions: {
            'props.prescriptorId': (field: FormlyFieldConfig) => {
              return field.model.seller ? field.model.seller.id : undefined;
            },
            'props.disabled': (field: FormlyFieldConfig) => {
              return field.model?.seller?.id === undefined;
            },
            'props.required': (field: FormlyFieldConfig) => {
              return !field.model?.dossierProspect?.id;
            },
          },
          className: 'mb-8 w-2/3 pr-10',
        },
        companyInformations,
        ...prospectInformations(0),
        ...prospectInformations(1),
      ],
    };
  }

  public async getInformationJustificatifForm() {
    return {
      props: {
        label: this.i18nService._('PreReservationCreateForm_JustificatifTitle'),
        headId: 'preresa',
        formTitle: 'PreReservationCreateForm_Title',
      },
      fieldGroup: [
        {
          key: 'motif',
          type: 'select',
          props: {
            label: this.i18nService._('PreReservationCreateForm_JustificatifMotif'),
            options: await this.getMotifs(),
            required: true,
          },
          className: 'mb-8 w-1/2',
        },
        {
          key: 'document',
          type: 'file',
          props: {
            label: this.i18nService._('PreReservationCreateForm_JustificatifDocument'),
            files: [],
            types: ['.pdf', '.jpeg', '.png', '.jpg'],
            typeDoc: 'SOAM',
            multiple: false,
            disabled: false,
            maxFileSize: 16000000,
            acceptedFilesLabel: 'Documents acceptés : PDF, JPEG, JPG, PNG',
          },
          expressions: {
            'props.required': (field: FormlyFieldConfig) => {
              return [this.RESERVATION_MOTIVE.CONTRACT_SIGNED, this.RESERVATION_MOTIVE.SIGNING_APPOINTMENT_PROJECTED].includes(
                field.model.motif,
              );
            },
            'props.displayOptionalLabel': (field: FormlyFieldConfig) => {
              return [this.RESERVATION_MOTIVE.CONTRACT_SIGNED, this.RESERVATION_MOTIVE.SIGNING_APPOINTMENT_PROJECTED].includes(
                field.model.motif,
              );
            },
          },
          className: 'mb-8 w-1/2',
        },
        {
          key: 'estimatedAppointmentDate',
          type: 'calendar',
          props: {
            label: this.i18nService._('PreReservationCreateForm_AppointmentSignatureDate'),
            required: true,
          },
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return [this.RESERVATION_MOTIVE.WAITING_FOR_DEVELOPER, this.RESERVATION_MOTIVE.OTHER].includes(field.model.motif);
            },
          },
          className: 'mb-8 w-1/2',
        },
        {
          key: 'comment',
          type: 'textarea',
          props: {
            label: this.i18nService._('PreReservationCreateForm_Commentary'),
            displayOptionalLabel: false,
            required: true,
          },
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return [this.RESERVATION_MOTIVE.CONTRACT_SIGNED, this.RESERVATION_MOTIVE.SIGNING_APPOINTMENT_PROJECTED].includes(
                field.model.motif,
              );
            },
          },
          className: 'mb-8 w-1/2',
        },
        {
          key: 'comment',
          type: 'textarea',
          props: {
            label: this.i18nService._('PreReservationCreateForm_Commentary'),
            displayOptionalLabel: true, // To be able to dynamically make comment optional, we show/hide the right one
            required: false,
          },
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return (
                !field.model.motif ||
                [this.RESERVATION_MOTIVE.WAITING_FOR_DEVELOPER, this.RESERVATION_MOTIVE.OTHER].includes(field.model.motif)
              );
            },
          },
          className: 'mb-8 w-1/2',
        },
      ],
    };
  }

  private getProgramTypes(): { value: string; label: string }[] {
    const options = [];
    for (const programType of PROGRAM_TYPES) {
      options.push({
        value: programType.id,
        label: this.i18nService._(programType.label),
      });
    }
    return options;
  }

  private getLotOrientations(): { value: string; label: string }[] {
    const options = [];
    for (const lotOrentation of LOT_ORIENTATION) {
      options.push({
        value: lotOrentation.id,
        label: this.i18nService._(lotOrentation.label),
      });
    }
    return options;
  }

  private async getMotifs(): Promise<{ value: string; label: string }[]> {
    const motifs = await firstValueFrom(this.referenceTablesService.getTableReferenceInfoTranslated('ReservationMotives'));
    const options = [];
    for (const motif of motifs) {
      options.push({
        value: motif.id,
        label: motif.label,
      });
    }
    return options;
  }

  private async getTaxations(): Promise<void> {
    const taxationList: any = await firstValueFrom(this.referenceTablesService.getTableReferenceInfoTranslated('Taxations'));
    this.taxations = taxationList.sort((a, b) => a.position - b.position);
  }

  private async getSecondaryLotTypes(): Promise<void> {
    const secondaryLotTypes = await this.secondaryLotService.getSecondaryLotTypes();
    this.secondaryLotTypes = secondaryLotTypes.sort((a, b) => a.priority - b.priority);
  }

  private createSecondaryLotsColumnDefs(): ColDef[] {
    return [
      {
        field: 'id',
        hide: true,
        minWidth: 60,
        flex: 2,
        cellDataType: 'text',
      },
      {
        field: 'refLot',
        headerName: this.i18nService._('PreReservationCreateForm_SecondaryLotsTable_Number'),
        minWidth: 60,
        flex: 2,
        cellDataType: 'text',
      },
      {
        field: 'numLot',
        headerName: this.i18nService._('PreReservationCreateForm_SecondaryLotsTable_Reference'),
        minWidth: 60,
        flex: 2,
        cellDataType: 'text',
      },
      {
        field: 'secondaryLotTypeId',
        headerName: this.i18nService._('PreReservationCreateForm_SecondaryLotsTable_Type'),
        valueFormatter: (params) => {
          if (!params?.value) return '';
          const label = this.secondaryLotTypes.find((type) => type?.id === params?.value)?.label;
          return this.i18nService._(label);
        },
        minWidth: 60,
        flex: 2,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.secondaryLotTypes.map((p) => p.id),
        },
      },
      {
        field: 'lotSellingPriceIT',
        headerName: this.i18nService._('PreReservationCreateForm_SecondaryLotsTable_Price'),
        minWidth: 60,
        flex: 2,
        cellDataType: 'number',
        valueFormatter: (params) => {
          return params?.value ? params.value + ' €' : '';
        },
        valueParser: (params) => {
          return params.newValue;
        },
      },
      {
        field: 'remove_enabled',
        headerName: '',
        maxWidth: 50,
        flex: 0,
        refData: {
          icon: 'XMark',
        },
        cellStyle: { paddingRight: '10px' },
        cellRendererParams: {
          onClick: (params: ICellRendererParams) => {
            const field: FormlyFieldConfig = params?.context?.componentParent?.field;
            field.formControl.value.splice(params.node.rowIndex, 1);
            field.formControl.setValue(field.formControl.value);
          },
        },
        cellRenderer: ButtonRendererComponent,
      },
    ];
  }
}
