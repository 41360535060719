import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

import { ChipPopulatedWithOtherComponent } from '../chip-populated-with-other/chip-populated-with-other.component';
import { PhoneNumberInformationComponent } from '../phone-number-information/phone-number-information.component';
import { NameInformationComponent } from '../name-information/name-information.component';
import { RadioButtonPopulatedComponent } from '../radio-button-populated/radio-button-populated.component';

import { CompanyService } from '../../../companies/services/company.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { emailExistValidator } from '../../../utils/validators/email-exist-validator.directive';
import { isEmailValidators } from '../../../utils/validators/is-email-validator.directive';
import { CompanyContact } from '../../models/CompanyContact';
import { ReferenceTableData } from '../../models/ReferenceTableData';
import { ContactInformationService } from '../../services/contact-information.service';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RadioButtonPopulatedComponent,
    NameInformationComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    PhoneNumberInformationComponent,
    ChipPopulatedWithOtherComponent,
  ],
})
export class ContactInformationComponent implements OnInit {
  /**
   * ParentForm to add controls to
   *
   * @type {FormGroup}
   * @memberof ContactInformationComponent
   */

  @Input() parentForm: UntypedFormGroup;

  /**
   * Get submit from parentForm
   *
   * @type {boolean}
   * @memberof ContactInformationComponent
   */
  @Input() isSubmit: boolean;

  @Input() contactsInfo: CompanyContact;

  @Input() value: string;

  @Input() isDisabled: boolean;

  /**
   * Get if the form needs an input instead of chips for 'Fonction'
   *
   * @type {boolean}
   * @memberof ContactInformationComponent
   */
  @Input() chipFunctionDisplay = true;

  /**
   * Get if the desk phone is displayed
   *
   * @type {boolean}
   * @memberof ContactInformationComponent
   */
  @Input() deskPhoneDisplay = true;

  /**
   * Get if the email is required
   *
   * @type {boolean}
   * @memberof ContactInformationComponent
   */
  @Input() emailRequired = true;

  /**
   * Get if the firstName is required
   *
   * @type {boolean}
   * @memberof ContactInformationComponent
   */
  @Input() firstNameRequired = true;

  /**
   * Get if the lastName is required
   *
   * @type {boolean}
   * @memberof ContactInformationComponent
   */
  @Input() lastNameRequired = true;

  /**
   * Get if the mobile phone is required
   *
   * @type {boolean}
   * @memberof ContactInformationComponent
   */
  @Input() mobilePhoneRequired = true;

  /**
   * Get if the civility is included
   *
   * @type {boolean}
   * @memberof ContactInformationComponent
   */
  @Input() includeCivility = true;

  /**
   * Form name to do apply specific controls on fields
   *
   * @type {string}
   * @memberof ContactInformationComponent
   */
  @Input() formName: string;

  /**
   * lastNamePlaceholder input
   *
   * @type {string}
   * @memberof ContactInformationComponent
   */
  @Input() lastNamePlaceholder: string;

  /**
   * firstNamePlaceholder input
   *
   * @type {string}
   * @memberof ContactInformationComponent
   */
  @Input() firstNamePlaceholder: string;

  /**
   * emailPlaceholder input
   *
   * @type {string}
   * @memberof ContactInformationComponent
   */
  @Input() emailPlaceholder: string;

  /**
   * mobilePhonePlaceholder input
   *
   * @type {string}
   * @memberof ContactInformationComponent
   */
  @Input() mobilePhonePlaceholder: string;

  /**
   * lastNameTooltipText input
   *
   * @type {string}
   * @memberof ContactInformationComponent
   */
  @Input() lastNameTooltipText: string;

  /**
   * Control on email
   *
   * @type {FormControl}
   * @memberof ContactInformationComponent
   */
  public emailControl: FormControl;

  /**
   * Control on mobile phone
   *
   * @type {FormControl}
   * @memberof ContactInformationComponent
   */
  public mobilePhoneControl: FormControl;

  public contactTypeOfForm: number;
  public adminRole: ReferenceTableData;
  /**
   * Control on desk phone
   *
   * @type {FormControl}
   * @memberof ContactInformationComponent
   */
  public deskPhoneControl: FormControl;

  constructor(
    public i18nService: I18nService,
    private readonly companyService: CompanyService,
    private readonly contactInformationService: ContactInformationService,
  ) {}

  /**
   * Init method
   *
   * @memberof ContactInformationComponent
   */
  ngOnInit(): void {
    // Init placeholder values
    this.emailPlaceholder = this.emailPlaceholder ? this.emailPlaceholder : this.i18nService._('Txt_Placeholder_Email');
    this.mobilePhonePlaceholder = this.mobilePhonePlaceholder ? this.mobilePhonePlaceholder : this.i18nService._('Txt_Placeholder_Mobile');

    this.parentForm.addControl(
      'lastName',
      new UntypedFormControl(
        this.contactsInfo ? this.contactsInfo.lastName : undefined,
        this.lastNameRequired ? [Validators.required] : [],
      ),
    );
    this.parentForm.addControl(
      'firstName',
      new UntypedFormControl(
        this.contactsInfo ? this.contactsInfo.firstName : undefined,
        this.firstNameRequired ? [Validators.required] : [],
      ),
    );
    this.parentForm.addControl(
      'email',
      new UntypedFormControl(this.contactsInfo ? this.contactsInfo.email : undefined, [isEmailValidators()]),
    );
    this.emailControl = this.parentForm.controls.email as FormControl;
    this.parentForm.addControl(
      'mobilePhone',
      new UntypedFormControl(
        this.contactsInfo ? this.contactsInfo.mobilePhone : undefined,
        this.mobilePhoneRequired ? [Validators.required] : [],
      ),
    );
    this.mobilePhoneControl = this.parentForm.controls.mobilePhone as FormControl;
    this.parentForm.addControl('deskPhone', new UntypedFormControl(this.contactsInfo ? this.contactsInfo.deskPhone : undefined, []));
    this.deskPhoneControl = this.parentForm.controls.deskPhone as FormControl;

    // Specific actions for account form.
    if (this.formName === 'accountForm') {
      if (this.value) {
        this.emailControl.setAsyncValidators(emailExistValidator(this.contactInformationService, this.value));
      } else {
        this.emailControl.setAsyncValidators(emailExistValidator(this.contactInformationService));
      }
      this.emailControl.updateValueAndValidity({ onlySelf: true });
      this.parentForm.updateValueAndValidity({ onlySelf: true });
    }
  }
}
