import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

import { ActionButtonStategieComponent } from './action-button-stategie/action-button-stategie.component';

import { AbstractAppBack } from '../../autres/abstract-app-back';
import { I18nService } from '../../services/i18n.service';
import { RoutingStateService } from '../../services/routing-state.service';
import { EllipsisPipe } from '../../pipes/ellipsis.pipe';

@Component({
  selector: 'app-sticky-header-form',
  templateUrl: './sticky-header-form.component.html',
  styleUrls: ['./sticky-header-form.component.scss'],
  standalone: true,
  imports: [MatButtonModule, NgIf, ActionButtonStategieComponent, EllipsisPipe],
})
export class StickyHeaderFormComponent extends AbstractAppBack implements OnInit {
  /**
   * Go back url for return button
   *
   * @type {string}
   * @memberof StickyHeaderFormComponent
   */
  @Input() goBackUrl: string;
  /**
   * default go back url if not home page
   *
   * @type {string}
   * @memberof StickyHeaderFormComponent
   */
  @Input() defaultGoBackUrl: string;

  /**
   * Form Title Text
   *
   * @type {string}
   * @memberof StickyHeaderFormComponent
   */
  @Input() titleText: string;

  /**
   * actionButtonLabel input
   *
   * @type {string}
   * @memberof StickyHeaderFormComponent
   */
  @Input() actionButtonLabel = '';

  /**
   * actionButtonIcon input
   *
   * @type {string}
   * @memberof StickyHeaderFormComponent
   */
  @Input() actionButtonIcon = '';

  /**
   * actionButtonIconMob input
   *
   * @type {string}
   * @memberof StickyHeaderFormComponent
   */
  @Input() actionButtonIconMob = '';

  /**
   * isActionButtonVisible input
   *
   * @type {boolean}
   * @memberof StickyHeaderFormComponent
   */
  @Input() isActionButtonVisible = true;
  @Input() isActionButtonVisibleStategie?: boolean;

  /**
   * isActionButtonDisabled input
   *
   * @type {boolean}
   * @memberof StickyHeaderFormComponent
   */
  @Input() isActionButtonDisabled = false;

  /**
   * isActionButtonActiveAsEmpty input
   *
   * @type {boolean}
   * @memberof StickyHeaderFormComponent
   */
  @Input() isActionButtonActiveAsEmpty = false;

  /**
   * actionButtonTooltipText input
   *
   * @memberof StickyHeaderFormComponent
   */
  @Input() actionButtonTooltipText;

  /**
   * actionButtonClicked output
   *
   * @memberof StickyHeaderFormComponent
   */
  @Output() readonly actionButtonClicked = new EventEmitter<void>();

  /**
   * Creates an instance of StickyHeaderFormComponent.
   *
   * @param {I18nService} i18nService
   * @param {Router} router
   * @param {RoutingStateService} routingStateService
   */
  constructor(
    public i18nService: I18nService,
    private readonly router: Router,
    private readonly routingStateService: RoutingStateService,
  ) {
    super();
  }

  ngOnInit(): void {
    // If no tooltiptext, set label.
    if (!this.actionButtonTooltipText) {
      this.actionButtonTooltipText = this.actionButtonLabel;
    }
  }

  /**
   * Go back to url
   *
   * @memberof StickyHeaderFormComponent
   */
  public goBack(): void {
    // Remove current page from history.
    this.routingStateService.removeLastHistoryEntry();
    // Go to previous url, or goBackUrl if provided, or homepage url if none exists.
    this.router.navigateByUrl(this.routingStateService.getPreviousUrl(this.goBackUrl || this.goBackPath, this.defaultGoBackUrl));
  }

  /**
   * onActionButtonClicked method
   *
   * @memberof StickyHeaderFormComponent
   */
  public onActionButtonClicked(): void {
    this.actionButtonClicked.emit();
  }
}
