/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, catchError, Observable, of, take, tap } from 'rxjs';
import { get, set } from 'lodash';

import { FormResponse, FormViewResponse, Status } from '../models/FormResponse';
import { Signature } from '../models/Signature';
import { CreateDossier, Dossier } from '../models/Dossier';
import { DossierResponse } from '../models/DossierResponse';
import { ContextData } from '../models/ContextData';
import { DocumentHttpResponse } from './signature-document.service';
import { PrefillDialogComponent } from '../components/prefill-dialog/prefill-dialog.component';
import {
  ModalCancelSignatureComponent,
  ModalCancelSignatureData,
} from '../components/signature-progress/modal/modal-cancel-and-modify.component';
import { SignatureType } from '../models/signature-type.enum';
import { Taxation } from '../models/Taxation';

import { ErrorHandlerService } from '../../utils/services/error-handler.service';
import { AppConfigService } from '../../utils/services/app-config.service';
import { LotPriceService } from '../../common/form/services/lot-price.service';
import { SnackbarService } from '../../utils/services/snackbar.service';
import { DossierApiService } from '../../adapters/dossier.api.service';

@Injectable({
  providedIn: 'root',
})
export class SignatureService {
  /**
   * Creates an instance of SignatureService.
   *
   * @param {HttpClient} http
   * @param {ErrorHandlerService} errorHandlerService
   * @param {AppConfigService} appConfig
   *
   * @memberof SignatureService
   */

  formDossier$: BehaviorSubject<FormResponse> = new BehaviorSubject(null);
  formViewDossier$: BehaviorSubject<FormViewResponse> = new BehaviorSubject(null);
  initialLotPrice: number;
  finalLotPrice: number;
  dossierId: number;
  contextData: ContextData;

  contractURL$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private readonly dialog: MatDialog,
    private readonly http: HttpClient,
    private readonly snackbarService: SnackbarService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
    private readonly lotPriceService: LotPriceService,
    private readonly dossierApi: DossierApiService,
  ) {}

  getFormViewByDossierId(dossierId: number) {
    const url = `${this.appConfig.getDematApiUrl()}/forms/${dossierId}/view`;

    this.http
      .get<FormViewResponse>(url)
      .pipe(catchError(this.errorHandlerService.handleError<FormViewResponse>('SignatureService', 'getFormViewByDossierId')))
      .subscribe((formViewResponse) => {
        this.formViewDossier$.next(formViewResponse);
      });
  }

  getFormByDossierId(dossierId: number) {
    const url = `${this.appConfig.getDematApiUrl()}/forms/${dossierId}`;
    this.dossierId = dossierId;

    this.http
      .get<FormResponse>(url)
      .pipe(catchError(this.errorHandlerService.handleError<FormResponse>('SignatureService', 'getFormByDossierId')))
      .subscribe((formResponse) => {
        this.contextData = formResponse.contextData;
        this.updateDisplayLotPrice(this.contextData);
        this.formDossier$.next(formResponse);
      });
  }

  updateDisplayLotPrice(contextData: ContextData) {
    let annexesPrice = 0;
    if (!contextData.lot?.secondaryLotPriceIncluded) {
      contextData?.secondaryLots.forEach((secondaryLot) => {
        if (secondaryLot && secondaryLot.price) {
          annexesPrice += secondaryLot.price;
        }
      });
    }
    this.lotPriceService.updateLotPrice({ price: contextData?.lot?.price, annexesPrice: annexesPrice });
  }

  putFormData(dossierId: number, body: Record<string, any>): Observable<any> {
    if (!body) {
      return of(undefined);
    }

    const url = `${this.appConfig.getDematApiUrl()}/dossiers/form-data/${dossierId}`;
    return this.http.put<any>(url, body).pipe(catchError(this.errorHandlerService.handleError<any>('SignatureService', 'putFormData')));
  }

  updataLotPriceAndTaxationInContextData(initialLotPrice: number, finalLotPrice: number, taxation: Taxation) {
    this.contextData.lot.initialPrice = initialLotPrice;
    this.contextData.lot.finalPrice = finalLotPrice;
    this.contextData.lot.taxation = taxation;
    const body = { id: this.dossierId, contextData: this.contextData };
    this.updateDossierById(this.dossierId, body).subscribe();
  }

  updateDossierById(dossierId: number, body: any): Observable<DossierResponse> {
    const url = `${this.appConfig.getDematApiUrl()}/dossiers/${dossierId}`;

    return this.http
      .put<DossierResponse>(url, body)
      .pipe(catchError(this.errorHandlerService.handleError<DossierResponse>('SignatureService', 'updateDossierById')));
  }

  resetDossierById(dossierId: number): Observable<DossierResponse> {
    const url = `${this.appConfig.getDematApiUrl()}/dossiers/${dossierId}/reset`;

    return this.http.get<DossierResponse>(url).pipe(
      catchError(this.errorHandlerService.handleError<DossierResponse>('SignatureService', 'resetDossierById', {})),
      tap(() => this.snackbarService.infoI18n('update_signature_dossier_success')),
    );
  }

  async canResetDossier(dossierId: number): Promise<boolean> {
    return await this.dossierApi.canResetDossier(dossierId);
  }

  launchContractCreation(dossierId: number): Observable<DocumentHttpResponse> {
    const url = `${this.appConfig.getDematApiUrl()}/documents/${dossierId}/contract`;

    return this.http
      .put<DocumentHttpResponse>(url, { dossierId })
      .pipe(catchError(this.errorHandlerService.handleError<DocumentHttpResponse>('SignatureService', 'launchContractCreation')));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getSignatureProgress(dossierId: number): Observable<Signature> {
    const url = `${this.appConfig.getDematApiUrl()}/signature/${dossierId}/progress`;

    return this.http
      .get<Signature>(url)
      .pipe(catchError(this.errorHandlerService.handleError<Signature>('SignatureService', 'getSignatureProgress')));
  }

  getDossier(dossierID: number): Observable<Dossier> {
    const url = `${this.appConfig.getDematApiUrl()}/dossiers/${dossierID}`;

    return this.http.get<Dossier>(url).pipe(catchError(this.errorHandlerService.handleError<Dossier>('SignatureService', 'getDossier')));
  }

  findDossier(dossier: Partial<Dossier>, onlyCurrent = false): Observable<Dossier[]> {
    const param = new URLSearchParams();
    for (const key in dossier) {
      param.set(key, dossier[key]);
    }
    const url = `${this.appConfig.getDematApiUrl()}/dossiers${onlyCurrent ? '/current' : ''}?${param.toString()}`;
    return this.http
      .get<Dossier[]>(url)
      .pipe(catchError(this.errorHandlerService.handleError<Dossier[]>('SignatureService', 'findDossier')));
  }

  updateDossier(dossierId: string, dossier: Dossier): Observable<Dossier> {
    const url = `${this.appConfig.getDematApiUrl()}/dossiers/${dossierId}`;

    return this.http
      .put<Dossier>(url, dossier)
      .pipe(catchError(this.errorHandlerService.handleError<Dossier>('SignatureService', 'updateDossier')));
  }

  createDossier(dossier: CreateDossier) {
    const url = `${this.appConfig.getDematApiUrl()}/dossiers`;

    return this.http
      .post<Dossier>(url, dossier)
      .pipe(catchError(this.errorHandlerService.handleError<Dossier>('SignatureService', 'createDossier')));
  }

  createSignature(dossierId: number, pdfId: number, type: SignatureType) {
    const url = `${this.appConfig.getDematApiUrl()}/signature`;

    return this.http
      .post<any>(url, { dossierId, pdfId, type })
      .pipe(catchError(this.errorHandlerService.handleError<any>('SignatureService', 'createSignature')));
  }

  cancelAndModify(id, data: { comment: string; isSignatureReject: boolean }, isDossierId): Observable<Signature> {
    const url = `${this.appConfig.getDematApiUrl()}/signature/${id}/cancelAndModify${isDossierId ? 'ByDossier' : ''}`;
    return this.http
      .patch<Signature>(url, data)
      .pipe(catchError(this.errorHandlerService.handleError<Signature>('SignatureService', 'getDocumentAsBlob')));
  }

  openPrefillDialog() {
    this.dialog.open(PrefillDialogComponent);
  }

  prefillAcq2(fields: string[]) {
    this.formDossier$.pipe(take(1)).subscribe((dossier: FormResponse) => {
      const model = dossier.formData.model;
      fields.forEach((field) => set(model, field, get(model, field.replace('2', '1'))));
      this.formDossier$.next({ ...dossier });
    });
  }

  deleteDossierSignature(dossierId: number): Observable<any> {
    const url = `${this.appConfig.getDematApiUrl()}/dossiers/${dossierId}`;
    return this.http.delete(url).pipe(catchError(this.errorHandlerService.handleError<any>('SignatureService', 'deleteDossierSignature')));
  }

  cancelDossierSignature(dossierId: number, reason: string): Observable<Dossier> {
    const url = `${this.appConfig.getDematApiUrl()}/dossiers/${dossierId}/cancel`;
    return this.http
      .patch<Dossier>(url, { reason })
      .pipe(catchError(this.errorHandlerService.handleError<any>('SignatureService', 'cancelDossierSignature')));
  }

  private hasNotToVoidNecessary(status: Status): boolean {
    return [Status.REJECT_ACQ, Status.REJECT_DEV, Status.EXPIRED, Status.SIGN_PAPER, Status.ERROR, Status.DRAFT].includes(status);
  }

  openCancelAndModfifyModal(
    status: Status,
    id: number,
    isDossierId = false,
    cancelOnly = false,
  ): MatDialogRef<ModalCancelSignatureComponent> {
    const hasNotToVoidNecessary = this.hasNotToVoidNecessary(status);
    return this.dialog.open(ModalCancelSignatureComponent, {
      width: '720px',
      data: {
        dossierOrSignatureId: id,
        cancelOnly: cancelOnly,
        isDossierId,
        hasNotToVoidNecessary,
        keyForTitle: cancelOnly
          ? 'txt_signature_cancel_modal_title_dossier'
          : hasNotToVoidNecessary
          ? 'txt_signature_cancel_modal_title_reject'
          : 'txt_signature_cancel_modal_title',
        keyForMainMessage: cancelOnly
          ? 'txt_signature_cancel_modal_text_dossier'
          : hasNotToVoidNecessary
          ? 'txt_signature_cancel_modal_text_reject'
          : 'txt_signature_cancel_modal_text',
        keyForCommentLabel: cancelOnly
          ? 'txt_signature_cancel_modal_comment_label_dossier'
          : hasNotToVoidNecessary
          ? 'txt_signature_cancel_modal_comment_label_reject'
          : 'txt_signature_cancel_modal_comment_label',
        keyForConfirming: cancelOnly ? 'txt_signature_cancel_modal_confirm_cancel_dossier' : 'Txt_Button_Signature_Valid_Cancel',
        keyForCancelling: cancelOnly ? 'txt_signature_cancel_modal_cancel_cancel_dossier' : 'Txt_Button_Cancel_And_Close',
      } as ModalCancelSignatureData,
    });
  }

  async deleteKBISFromDossier(dossierId: number): Promise<void> {
    return await this.dossierApi.deleteKBISFromDossier(dossierId);
  }
}
