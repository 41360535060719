import { Injectable } from '@angular/core';

import { FeatureFlagApiService } from '../adapters/feature-flag-api.service';
import { FeatureFlag } from '../tracking-tables/models/FeatureFlag';

import { environment } from '../../environments/environment.map';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  featureFlags: FeatureFlag[];
  loadDate: Date;
  cacheDurationInSec: number = environment.featureFlagCacheDurationInSec;

  constructor(private featureFlagApiService: FeatureFlagApiService) {}

  async loadFeatureFlags(): Promise<void> {
    this.featureFlags = await this.featureFlagApiService.getFeatureFlags();
    this.loadDate = new Date();
  }

  async isEnabled(feature: string): Promise<boolean> {
    if (this.isExpired()) {
      await this.loadFeatureFlags();
    }
    return this.featureFlags.find((f) => f.feature == feature)?.enabled;
  }

  isExpired() {
    if (!this.loadDate) return true;
    const durationInSec = (new Date().getTime() - this.loadDate.getTime()) / 1000;
    return durationInSec > this.cacheDurationInSec;
  }
}
