<div class="border-b border-gray-300 py-2 text-sm" *ngIf="contact">
  <h3 class="text-xxs text-orange-600 font-bold uppercase py-1">
    {{ i18nService._('Txt_Contact_My_Contact') }}<span *ngIf="subtitle"> ({{ subtitle }})</span>
  </h3>
  <div>
    <div class="inline-flex py-1 text-xl font-bold justify-start align-top">
      <span class="whitespace-nowrap"
        >{{ contact.firstName }} <span class="uppercase">{{ contact.lastName }}</span></span
      >
      <app-icon name="CheckBadge" class="w-6 h-6 fill-orange-400 text-white -mt-2"></app-icon>
    </div>
  </div>

  <div class="text-sm font-normal">
    <ng-container *ngIf="contact.mobilePhone; else deskPhone">
      <a href="tel:{{ contact.mobilePhone }}" class="no-underline text-black py-1">
        {{ contact.mobilePhone | phone }}
      </a>
    </ng-container>
    <ng-template #deskPhone>
      <a href="tel:{{ contact.deskPhone }}" class="no-underline text-black py-1">
        {{ contact.deskPhone | phone }}
      </a>
    </ng-template>
  </div>

  <div class="text-sm font-normal lowercase">
    <a href="mailto:{{ contact.email }}" class="no-underline text-black lowercase">
      {{ contact.email }}
    </a>
  </div>
</div>
