import { ChangeDetectorRef, Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { getUniqueId } from '../../../utils/models/app-constant';
import { ServiceLocator } from '../../../utils/services/service-locator';
import { FilterUtilsService } from '../../services/filter/filter-utils.service';
import { SearchFormUtilsService } from '../../services/search-form-utils.service';
import { ArrayFilterCallbackFn } from '../../../utils/@types/ArrayFns';
import { SearchProgramDataWhere } from '../../model/search-program-data-where';
import { SearchProgramData } from '../../model/search-program-data';

@Directive()
export abstract class AbstractSearchFilter implements OnDestroy, OnInit {
  public search: SearchProgramDataWhere;
  public searchProgramData: SearchProgramData;
  // if true wait for the search to be effective for updating label in the fix filter menu
  @Input() titelUp: boolean;
  @Input() waitForSearch: boolean;
  @Output() readonly filterChangeEvent: EventEmitter<string[]> = new EventEmitter(true);
  protected readonly _searchFormUtilsService: SearchFormUtilsService;
  protected readonly $d = new Subject();
  protected _filterUtilsService: FilterUtilsService;
  protected _uid = getUniqueId(3);

  constructor() {
    this._searchFormUtilsService = ServiceLocator.injector.get(SearchFormUtilsService);
    this._filterUtilsService = ServiceLocator.injector.get(FilterUtilsService);
  }

  ngOnInit(): void {
    this._searchFormUtilsService.searchFilterSubject.pipe(takeUntil(this.$d)).subscribe((searchProgramData: SearchProgramData) => {
      this.searchProgramData = { ...searchProgramData };
      this.search = { ...searchProgramData.where };
      if (!this.searchProgramData.waitForSearch) {
        this.initData();
      }
    });
    this._searchFormUtilsService.searchLaunchSubject.pipe(takeUntil(this.$d)).subscribe(() => {
      if (this.searchProgramData.waitForSearch) {
        this.initData();
      }
    });
  }

  ngOnDestroy() {
    this.$d.next(true);
    this.$d.complete();
    this._filterUtilsService.hideAll();
  }

  /**
   * Sorts by closure
   */
  public sortBy(fieldName: string): (a, b) => number {
    return (a, b) => {
      return a[fieldName] < b[fieldName] ? -1 : a[fieldName] > b[fieldName] ? 1 : 0;
    };
  }

  selectedItem(fieldName: string, res) {
    this.search[fieldName] = res;
    this._searchFormUtilsService.setSearchFilter({ where: this.search }, this.waitForSearch);
  }

  protected abstract initData();

  protected _markForCheck(changeDetectorRef: ChangeDetectorRef) {
    setTimeout(() => {
      changeDetectorRef.markForCheck();
    }, 0);
  }

  public getFilterMethod(fieldName: string, filterValue?: string): ArrayFilterCallbackFn {
    return (item) => item[fieldName] === filterValue;
  }
}
