import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AcountTypeEnum } from '@commons-dto/valo-back';
import { NgIf } from '@angular/common';

import { AccountFormComponent } from '../account-form/account-form.component';

import { AbstractAppBack } from '../../../utils/autres/abstract-app-back';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { Sitemap } from '../../../utils/models/Sitemap';
import { AccountService } from '../../../utils/services/account.service';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { AccountInfos } from '../../models/AccountInfos';
import { UpdateValidateAccountRequest } from '../../models/UpdateValidateAccountRequest';
import { AccountUtilsService } from '../../services/account-utils.service';
import { RoutingStateService } from '../../../utils/services/routing-state.service';

@Component({
  selector: 'app-update-account',
  templateUrl: './update-account.component.html',
  styleUrls: ['./update-account.component.scss'],
  standalone: true,
  imports: [NgIf, AccountFormComponent],
})
export class UpdateAccountComponent extends AbstractAppBack implements OnInit, OnDestroy {
  public updateAccountRequest: UpdateValidateAccountRequest;
  public neededInfos: AccountInfos;
  public accountId: number;
  public boIdList: Array<number>;
  public departementIdList: Array<number>;
  public pageTitle: string;
  public loggedUserIsValo: boolean;
  public roleTypeName: string;
  /**
   * Subscription of validation observable
   *
   * @private
   * @type {Subscription}
   * @memberof ValidateAccountComponent
   */
  private readonly _destroy$ = new Subject<void>();
  private isValidation: boolean;

  /**
   * Creates an instance of CreateAccountComponent.
   *
   * @param {Router} router
   * @param {I18nService} i18nService
   * @param appConfigService
   * @param {ActivatedRoute} route
   * @param {AccountService} accountService
   * @param snackbarService
   * @param accountUtilsService
   * @memberof CreateAccountComponent
   */
  constructor(
    public readonly i18nService: I18nService,
    public readonly appConfigService: AppConfigService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly accountService: AccountService,
    private readonly snackbarService: SnackbarService,
    private readonly accountUtilsService: AccountUtilsService,
    private readonly routingStateService: RoutingStateService,
  ) {
    super();
  }

  /**
   * ngOnInit
   *
   * @memberof CreateAccountComponent
   */
  ngOnInit(): void {
    this.isValidation = !!Object.getOwnPropertyDescriptor(this.route.snapshot.data, 'isValidation');
    // Get current user id.
    this.accountId = Number(this.route.snapshot.paramMap.get('id'));
    this.loggedUserIsValo = this.accountUtilsService.getParentAndLoggedUserInfos().loggedUserIsValo;

    const COMPANY_TYPE = 'companyType';
    const ACOUNT_TYPE = 'accountType';

    const DEVELOPER_TYPE = this.appConfigService._(COMPANY_TYPE, AcountTypeEnum.DEVELOPER);
    const CONTRACTOR_TYPE = this.appConfigService._(COMPANY_TYPE, AcountTypeEnum.CONTRACTOR);
    const INDEPENDANT_TYPE = this.appConfigService._(COMPANY_TYPE, AcountTypeEnum.INDEPENDANT);
    const VALORISSIMO_TYPE = this.appConfigService._(COMPANY_TYPE, AcountTypeEnum.VALORISSIMO);

    const setRoleTypeNameAndPageTitle = (roleTypeName: AcountTypeEnum, pageTitle: string) => {
      this.roleTypeName = this.appConfigService._(ACOUNT_TYPE, roleTypeName);
      this.pageTitle = pageTitle;
    };

    // If we have a parentId in the url we take its value, otherwise parentId = userId
    this.accountUtilsService
      .getInfosForUpdate(this.accountId)
      .pipe(takeUntil(this._destroy$))
      .subscribe((response) => {
        // If the account is not in Pending it can not be validated, so we redirect
        if (this.isValidation && response.accountStatus.label !== this.appConfigService._('accountStatus', 'pending')) {
          this.router.navigate([Sitemap.accounts.admin.path]);
          this.snackbarService.sendMessage({
            text: this.i18nService._('Error_SnackBar_RedirectPageAccountCantBeValidated'),
            type: SnackbarMessageType.Error,
          });
        }

        // We define the title of the page in function of the account we are creating
        switch (response.companyType.label) {
          case DEVELOPER_TYPE:
            setRoleTypeNameAndPageTitle(
              AcountTypeEnum.DEVELOPER,
              this.isValidation ? 'Title_DeveloperValidation' : 'Title_DeveloperModification',
            );
            break;
          case CONTRACTOR_TYPE:
            setRoleTypeNameAndPageTitle(
              AcountTypeEnum.CONTRACTOR,
              this.isValidation ? 'Title_ContractorValidation' : 'Title_ContractorModification',
            );
            break;
          case INDEPENDANT_TYPE:
            setRoleTypeNameAndPageTitle(AcountTypeEnum.INDEPENDANT, 'Title_IndependantModification');
            break;
          case VALORISSIMO_TYPE:
          default:
            this.pageTitle = this.isValidation ? 'Title_ValorissimoUseValidation' : 'Title_ValorissimoUseModification';
            break;
        }
        // Personal Data
        this.neededInfos = {
          contactInfos: {
            civilityId: response.civilityId,
            lastName: response.lastName,
            firstName: response.firstName,
            email: response.email,
            mobilePhone: response.mobilePhone,
            deskPhone: response.deskPhone,
          },
          adressInfos: {
            countryId: response.country.id,
            postalCode: response.postalCode,
            address: response.address,
            city: response.city,
            cityInseeCode: response.cityInseeCode,
          },
          parentId: response.parent ? response.parent.id : undefined,
          parentLastName: response.parent ? response.parent.lastName : undefined,
          parentFirstName: response.parent ? response.parent.firstName : undefined,
          parentProfilTypeLabel: response.parent ? response.parent.profilLabel : undefined,
          profilTypeId: response.profil.id,
          valoRoleTypeId: response.valoRoleType ? response.valoRoleType.id : undefined,
          companyTypeId: response.companyType.id,
          companyTypeLabel: response.companyType.label,
          defaultResponsible: {
            firstName: response.responsible ? response.responsible.firstName : undefined,
            lastName: response.responsible ? response.responsible.lastName : undefined,
            id: response.responsible ? response.responsible.id : undefined,
          },
          otherDefaultResponsible: {
            firstName: response.otherResponsible?.firstName,
            lastName: response.otherResponsible?.lastName,
            id: response.otherResponsible?.id,
          },
          professionnalFunction: response.professionnalFunction,
          nbOptionsMax: response.nbOptionsMax,
          isActive: response.isActive,
          isForbidden: response.isForbidden,
          isVisibleCommercialLot: response.isVisibleCommercialLot,
          isFeesHidden: response.isFeesHidden,

          hasBo: response.hasBo,
          hasDepartement: response.hasDepartement,
          companyCorporateName: response.company.corporateName,
          companyTradingName: response.company.tradingName,
          companyId: response.company.id,
          commentBo: response.commentBo,
        };
      });
  }

  getResponsibleId(needResponsible, valoPartData, neededInfos) {
    if (!needResponsible) return undefined;
    return valoPartData.responsible?.id || neededInfos.defaultResponsible.id;
  }

  getOtherResponsibleId(valoPartData) {
    return valoPartData.otherResponsible?.id || null;
  }

  /**
   * Get submit event.
   *
   * @memberof CreateAccountComponent
   */
  public updateAccount(values): void {
    const needResponsible = !(values.valoPartData.valoRoleType || this.neededInfos.valoRoleTypeId);
    this.boIdList = [];
    this.departementIdList = [];
    if (values.valoPartData.hasBo) {
      values.valoPartData.hasBo.forEach((bo) => {
        this.boIdList.push(bo.id);
      });
    }
    if (values.valoPartData.hasDepartment) {
      values.valoPartData.hasDepartment.forEach((departement) => {
        this.departementIdList.push(departement.id);
      });
    }

    // Validate account and redirect to main board page.
    this.updateAccountRequest = {
      // Personal Data
      civilityId: values.personnalData.civilityId,
      lastName: values.personnalData.lastName,
      firstName: values.personnalData.firstName,
      email: values.personnalData.email,
      mobilePhone: values.personnalData.mobilePhone,
      deskPhone: values.personnalData.deskPhone,
      professionnalFunction: values.personnalData.professionnalFunction,
      id: this.accountId,

      // Adress Infos
      countryId: values.personnalData.countryId,
      postalCode: values.personnalData.postalCode,
      address: values.personnalData.address,
      city: values.personnalData.city,
      cityInseeCode: values.personnalData.cityInseeCode,

      // Optionnal Data
      responsibleId: this.getResponsibleId(needResponsible, values.valoPartData, this.neededInfos),
      otherResponsibleId: this.getOtherResponsibleId(values.valoPartData),
      nbOptionsMax: values.valoPartData.nbOptionsMax,
      isForbidden: values.valoPartData.isForbidden,
      isActive: values.valoPartData.isActive,
      isVisibleCommercialLot: values.valoPartData.isVisibleCommercialLot,
      isFeesHidden: values.valoPartData.isFeesHidden,
      hasBo: values.valoPartData.hasBo ? this.boIdList : undefined,
      hasDepartement: values.valoPartData.hasDepartment ? this.departementIdList : undefined,
      commentBo: values.valoPartData.commentBo,
    };

    this.accountService.updateAccount(this.updateAccountRequest, this.isValidation).subscribe(
      (updateAccountResponse) => {
        // Show snackbar account modified
        this.snackbarService.sendMessage({
          text: this.i18nService._(this.isValidation ? 'Success_SnackBar_ValidatedAccount' : 'Success_SnackBar_UpdatedAccount'),
          type: SnackbarMessageType.Info,
        });
        if (updateAccountResponse.newEmail) {
          // Show snackbar mail modified
          this.snackbarService.sendMessage({
            text: this.i18nService._('Success_SnackBar_UpdatedEmailAccount', [updateAccountResponse.newEmail]),
            type: SnackbarMessageType.Info,
          });
        }
        this.routingStateService.removeLastHistoryEntry();
        this.router.navigate([this.getRoute()]);
      },
      () => {
        this.snackbarService.sendErrorOccured();
      },
    );
  }

  private routeConfig = {
    [this.appConfigService._('accountType', AcountTypeEnum.INDEPENDANT)]: Sitemap.accounts.adminIndependant.path,
    [this.appConfigService._('accountType', AcountTypeEnum.DEVELOPER)]: Sitemap.accounts.adminDeveloper.path,
    [this.appConfigService._('accountType', AcountTypeEnum.CONTRACTOR)]: Sitemap.accounts.adminContractor.path,
  };

  /**
   * Redirecting to tracking table according to created user
   */
  getRoute(): string {
    return this.routeConfig[this.roleTypeName] || Sitemap.accounts.admin.path;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
