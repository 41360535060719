/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-boolean-renderer',
  templateUrl: './boolean-renderer.component.html',
  styleUrls: ['./boolean-renderer.component.scss'],
})
export class BooleanRendererComponent implements ICellRendererAngularComp {
  public cellValue: any;

  constructor(public readonly i18nService: I18nService) {}

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    // set value into cell again
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    if (params.value == '1' || params.value == 'true') {
      return this.i18nService._('Txt_Yes');
    } else if (params.value == '0' || params.value == 'false') {
      return this.i18nService._('Txt_No');
    } else {
      return '';
    }
  }
}
