import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { atLeastOne } from '../../../utils/validators/atLeastOne-validator.directive';
import { isEmailValidators } from '../../../utils/validators/is-email-validator.directive';
import { ProspectData } from '../../../dialog/models/ProspectData';
import { PostalAddressComponent } from '../../../utils/components/postal-address/postal-address.component';
import { PhoneNumberInformationComponent } from '../../../utils/components/phone-number-information/phone-number-information.component';
import { IdentityInformationComponent } from '../../../utils/components/identity-information/identity-information.component';

@Component({
  selector: 'app-step-one-form-prospect',
  templateUrl: './step-one-form-prospect.component.html',
  styleUrls: ['./step-one-form-prospect.component.scss'],
  standalone: true,
  imports: [
    IdentityInformationComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    PhoneNumberInformationComponent,
    PostalAddressComponent,
  ],
})
export class StepOneFormProspectComponent implements OnInit {
  /**
   * parentForm input
   *
   * @type {FormGroup}
   * @memberof StepOneFormProspectComponent
   */
  @Input() parentForm: UntypedFormGroup;

  @Input() prospectDetails: ProspectData;

  /**
   * emailControl attribute
   *
   * @type {AbstractControl}
   * @memberof StepOneFormProspectComponent
   */
  public emailControl: AbstractControl;

  /**
   * isSubmit attribute
   *
   * @type {boolean}
   * @memberof StepOneFormProspectComponent
   */
  public isSubmit: boolean;

  /**
   * Creates an instance of StepOneFormProspectComponent.
   * @param {I18nService} i18nService
   * @param {SnackbarService} snackbarService
   * @memberof StepOneFormProspectComponent
   */
  constructor(
    public i18nService: I18nService,
    private readonly snackbarService: SnackbarService,
  ) {
    this.isSubmit = false;
  }

  /**
   * ngOnInit method
   *
   * @memberof StepOneFormProspectComponent
   */
  ngOnInit(): void {
    this.parentForm.addControl('email', new UntypedFormControl('', [isEmailValidators()]));
    this.emailControl = this.parentForm.controls.email;
    this.parentForm.setValidators(atLeastOne(['email', 'phone']));
  }

  /**
   * checkStep method, return true if step is ok
   *
   * @memberof StepOneFormProspectComponent
   */
  public checkStep(): boolean {
    if (this.parentForm.valid) {
      // We can go to next step now
      return true;
    }

    // Compute if a required field not fill (except mail or phone)
    const isFieldsRequiredError = Object.values(this.parentForm.controls).some((control) => control.errors && control.errors.required);

    if (isFieldsRequiredError) {
      // Form not valid
      const message: SnackbarMessage = {
        text: this.i18nService._('Error_SnackBar_FormIncomplete'),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
    } else {
      // Miss email or phone number
      const message: SnackbarMessage = {
        text: this.i18nService._('Error_Field_EmailOrPhoneRequired'),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
    }
    this.isSubmit = true;

    return false;
  }
}
