<p-autoComplete
  (completeMethod)="search($event)"
  (onSelect)="selectItem($event)"
  [forceSelection]="props.forceChoice"
  [suggestions]="localisations"
  placeholder="{{ props.placeholder }}"
  [showClear]="false"
  (onClear)="onClear()"
  [(ngModel)]="txtInput"
  [formControl]="autoCompleteFormControl"
  [dropdown]="false"
  showEmptyMessage="true"
  [emptyMessage]="noResultMessage"
  styleClass="inputLocalisation"
  field="label"
>
</p-autoComplete>
<div class="localisations">
  <div class="localisation" *ngFor="let localisation of selectedLocalisations">
    <label>{{ localisation.label }}</label>
    <app-icon name="XMark" (click)="removeLocalisation(localisation)"></app-icon>
  </div>
</div>
