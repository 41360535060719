import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs';
import { ProspectResponse as ProspectLightResponse } from '@commons-dto/dossier-prospect';

import { ChangeStatusProspectResponse } from '../models/ChangeStatusProspectResponse';

import { ProspectCreate } from '../../utils/models/ProspectCreate';
import { ProspectResponse } from '../../utils/models/ProspectResponse';
import { AppConfigService } from '../../utils/services/app-config.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';
import { ProspectApiService } from '../../adapters/prospect-api.service';
import { ProspectLegacyApiService } from '../../adapters/prospect-legacy-api.service';

@Injectable({
  providedIn: 'root',
})
export class ProspectService {
  /**
   * Creates an instance of ProspectService.
   * @param {HttpClient} http
   * @param {ErrorHandlerService} errorHandlerService
   * @param {AppConfigService} appConfig
   * @memberof ProspectService
   */
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
    private readonly prospectApiService: ProspectApiService,
    private readonly prospectLegacyApiService: ProspectLegacyApiService,
  ) {}

  public getProspect(prospectId: number): Observable<ProspectResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Prospects/${prospectId}`;

    return this.http
      .get<Array<ProspectResponse>>(url)
      .pipe(catchError(this.errorHandlerService.handleError<any>('ProspectService', 'getProspect')));
  }

  public createProspect(prospectCreate: ProspectCreate): Observable<ProspectResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Prospects`;

    return this.http.post<ProspectResponse>(url, prospectCreate);
  }

  public checkPaternity(id: number, values: { email: string; phone: string }): Observable<{ message: string }> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Paternities/${id}/verify`;

    return this.http.post(url, values).pipe(catchError(this.errorHandlerService.handleError<any>('ProspectService', 'checkPaternity')));
  }

  public acceptPaternity(paternityId: number): Observable<ChangeStatusProspectResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Paternities/${paternityId}/accept`;

    return this.http
      .post<ChangeStatusProspectResponse>(url, {})
      .pipe(catchError(this.errorHandlerService.handleError<any>('ProspectService', 'acceptPaternity')));
  }

  public rejectPaternity(paternityId: number): Observable<ChangeStatusProspectResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Paternities/${paternityId}/reject`;

    return this.http
      .post<ChangeStatusProspectResponse>(url, {})
      .pipe(catchError(this.errorHandlerService.handleError<any>('ProspectService', 'rejectPaternity')));
  }

  public async getProspectById(id: number): Promise<ProspectLightResponse> {
    return lastValueFrom(this.prospectApiService.getProspectById(id));
  }

  public async getProspectLegacyById(id: number): Promise<ProspectResponse> {
    return lastValueFrom(this.prospectLegacyApiService.getLegacyProspectById(id));
  }
}
