/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { isNil } from 'lodash';
import { ParamRequest } from '@commons-dto/valo-back';

import { AppConfigService } from '../../utils/services/app-config.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class GridTrackingTableService {
  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly httpClient: HttpClient,
  ) {}

  /**
   * Get table data from url given through parameter
   *
   * @returns {Observable<{ entity: Array<Dossier>; count: number }>}
   * @memberof GridTrackingTableService
   */
  getTrackingTableDemat(body: ParamRequest, serviceUrl: string): Observable<{ items: Array<any>; nbItems: number }> {
    const url = `${this.appConfigService.getDematApiUrl()}/` + serviceUrl;

    return this.httpClient
      .post<{ items: Array<any>; nbItems: number }>(url, body)
      .pipe(catchError(this.errorHandlerService.handleError<{ items: Array<any>; nbItems: number }>('getTrackingTableDemat')));
  }

  /**
   * Get table data from url given through parameter
   *
   * @returns {Observable<{ items: Array<any>; nbItems: number }>}
   * @memberof GridTrackingTableService
   */
  getTrackingTableBack(paramsRequest: ParamRequest, serviceUrl: string): Observable<{ items: Array<any>; nbItems: number }> {
    const params = this._setParams(paramsRequest);
    const url = [this.appConfigService.getLoopbackApiUrl(), serviceUrl, paramsRequest.serviceParams].filter(Boolean).join('/');

    const body = this.paramFilter(paramsRequest);
    if (Object.keys(body).length > 0) {
      return this.httpClient
        .post<{ items: Array<any>; nbItems: number }>(url, body, { params })
        .pipe(catchError(this.errorHandlerService.handleError<{ items: Array<any>; nbItems: number }>('getTrackingTableBack')));
    } else {
      return this.httpClient
        .get<{ items: Array<any>; nbItems: number }>(url, { params })
        .pipe(catchError(this.errorHandlerService.handleError<{ items: Array<any>; nbItems: number }>('getTrackingTableBack')));
    }
  }

  /**
   * Get single item to update in tracking table
   *
   * @param {number} id
   * @returns {Observable<T>}p
   * @memberof GridTrackingTableService
   */
  getSingleItem(id: number, serviceUrl: string): Observable<any> {
    return this.httpClient
      .get<any>(`${this.appConfigService.getLoopbackApiUrl()}/${serviceUrl}`, {
        params: new HttpParams().set('id', id.toString()),
      })
      .pipe(catchError(this.errorHandlerService.handleError<any>('gridSingleItemTableService', 'get')));
  }

  private _setParams(paramsRequest: ParamRequest): any {
    let params = new HttpParams().set('offset', paramsRequest.offset.toString()).set('limit', paramsRequest.nbResult.toString());
    if (paramsRequest.sortColumn) {
      params = params.set('sortColumn', paramsRequest.sortColumn).set('sortDirection', paramsRequest.sortDirection);
    }
    if (!isNil(paramsRequest.isTeamMember)) {
      params = params.set('isTeamMember', `${paramsRequest.isTeamMember ? 1 : 0}`);
    }
    if (!isNil(paramsRequest.columns)) {
      params = params.set('columns', paramsRequest.columns.join(','));
    }
    if (!isNil(paramsRequest.isPartner)) {
      params = params.set('isPartner', `${paramsRequest.isPartner ? 1 : 0}`);
    }
    if (!isNil(paramsRequest.filter)) {
      params = params.set('filter', paramsRequest.filter);
    }
    if (!isNil(paramsRequest.statusFilter)) {
      params = params.set('statusFilter', paramsRequest.statusFilter);
    }
    return params;
  }

  paramFilter(paramsRequest: ParamRequest): any {
    const param: any = {};
    if (paramsRequest.i18nFilters) {
      param.i18nFilters = paramsRequest.i18nFilters;
    }
    if (paramsRequest.selfFilters) {
      param.selfFilters = paramsRequest.selfFilters;
    }

    return param;
  }
}
