export enum Taxation {
  NOMINAL_TAXATION = 1,
  ANRU = 2,
  PINEL = 3,
  PLS = 4,
  LMNP_LMP = 5,
  PINEL_PLUS = 6,
  MALRAUX = 7,
  DEFICIT_FONCIER = 8,
  NUE_PROPRIETE = 9,
  LMNP_LMP_NON_GERE = 10,
  MONUMENTS_HISTORIQUES = 11,
}
