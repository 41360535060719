import { Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

import { SearchFilterContentComponent } from '../search-filter-content/search-filter-content.component';
import { SearchFilterReinitComponent } from '../search-filter-reinit/search-filter-reinit.component';

import { I18nService } from '../../../../utils/services/i18n.service';

@Component({
  selector: 'app-search-filter-dialog',
  templateUrl: './search-filter-dialog.component.html',
  styleUrls: ['./search-filter-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatIconModule, SearchFilterReinitComponent, SearchFilterContentComponent, NgIf],
})
export class SearchFilterDialogComponent {
  public modalIsFullyLoaded = false;

  constructor(
    public i18nService: I18nService,
    public dialogRef: MatDialogRef<SearchFilterDialogComponent, boolean>,
  ) {}

  loadFilter() {
    this.modalIsFullyLoaded = true;
  }

  launchSearch() {
    this.dialogRef.close(true);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
