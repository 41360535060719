<div *ngIf="formView$ | async; let formView" class="w-full h-full pt-10 px-8">
  <div class="flex w-full mx-auto max-w-7xl gap-8 xl:gap-10 h-full">
    <div class="flex flex-col w-[300px]">
      <ng-container>
        <app-box-back-button [goBack]="goBack()" [label]="'txt_return'" class="mb-6"></app-box-back-button>
        <app-side-info [formView]="formView"></app-side-info>
      </ng-container>
    </div>
    <div class="flex flex-col flex-1 gap-x-8">
      <!-- Header -->
      <app-form-header [formView]="formView" class="flex bg-white h-22 w-full rounded-10 shadow-custom mb-6"></app-form-header>

      <div class="mb-6 rounded-10 bg-white shadow-custom relative min-h-[592px] flex flex-col justify-between">
        <ng-container *ngIf="signatureProgress$ | async; else waitingProgress">
          <div class="absolute top-8 right-8 flex items-center flex-col">
            <span
              [ngClass]="{ 'bg-black': signature.type === SignatureType.REMOTE, 'bg-orange-500': signature.type === SignatureType.PAPER }"
              class="relative top-0 right-0 rounded-md uppercase font-bold bg-black text-white py-1 px-2 text-xs"
            >
              {{ _i18nService._('txt_signature_progress_' + signature.type) }}
            </span>
            <span *ngIf="showExpire && !developerDone" class="text-center">
              <span *ngIf="!isExpired" class="text-xs text-gray-500 font-semibold flex align-middle ng-star-inserted">
                {{ _i18nService._('txt_signature_progress_buyer_remaining_time', [signature.expiresAt | daysFromNow]) }}
              </span>
              <span *ngIf="isExpired" class="text-xs text-gray-500 font-semibold flex align-middle ng-star-inserted">
                {{ _i18nService._('txt_signature_progress_expired') }}
              </span>
            </span>
          </div>
          <app-vertical-progress [keyPrefix]="'txt_signature_progress_'" [steps]="signature.steps" class="mb-6"></app-vertical-progress>
          <div class="h-24 px-8 py-6 flex justify-between">
            <button [routerLink]="getSynthesisLink()" class="rounded-button-grey" routerLinkActive="router-link-active">
              {{ _i18nService._('txt_signature_progress_show_files') }}
            </button>

            <button
              (click)="downloadSignedContract()"
              *ngIf="allOK; else specificButton"
              [disabled]="downloading"
              [ngClass]="{ downloading: downloading }"
              class="rounded-button"
              type="button"
            >
              <app-icon class="w-4 h-5 pb-1 mr-2" [name]="downloading ? 'ArrowPath' : 'ArrowDownTray'" type="solid"></app-icon>
              {{ _i18nService._('txt_signature_progress_download_proof') }}
            </button>
            <ng-template #specificButton>
              <button (click)="openCancelAndModfifyModal()" *ngIf="canCancel" class="rounded-button" type="button">
                {{ _i18nService._('txt_signature_progress_modify') }}
              </button>
              <button
                *ngIf="!notOK && _userRoleService.isDeveloper() && !developerDone"
                [disabled]="developerCurrent"
                class="rounded-button"
                type="button"
              >
                {{ _i18nService._('txt_signature_progress_developer_valid') }}
              </button>
            </ng-template>
          </div>
        </ng-container>

        <ng-template #waitingProgress>
          <div *ngIf="!hasError; else progressError" class="mb-6 p-4 flex flex-col justify-center items-center" style="height: 683px">
            <mat-spinner [color]="'primary'" [diameter]="60" [mode]="'indeterminate'" [strokeWidth]="6" class="mx-auto my-5"></mat-spinner>
          </div>

          <ng-template #progressError>
            <div class="mb-6 p-4 flex flex-col justify-center items-center" style="min-height: 683px">
              <p class="text-red-400 bg-gray-100 rounded-10 p-4 shadow-custom">
                {{ _i18nService._('txt_signature_progress_error_1') }}<br />
                {{ _i18nService._('txt_signature_progress_error_2') }}<br />
                {{ _i18nService._('txt_signature_progress_error_3') }}
              </p>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </div>
    <div class="w-[300px] hidden xl:block"></div>
  </div>
</div>
