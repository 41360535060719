<div class="text-base font-bold text-left mb-3">
  {{ i18nService._('Txt_search_filter_tags_title') }}
</div>
<mat-chip-listbox [multiple]="true">
  <mat-chip-option
    color="primary"
    class="mr-3 w-fit"
    [selected]="search.hasSpecialOffer"
    (click)="selectedItem('hasSpecialOffer', !search.hasSpecialOffer)"
  >
    <div class="filter-chip-label">
      {{ i18nService._('Title_Form_SearchForm_SpecialOffer') }}
    </div>
  </mat-chip-option>
  <mat-chip-option
    class="w-fit"
    *ngIf="isValo"
    color="primary"
    [selected]="search.isHighStake"
    (click)="selectedItem('isHighStake', !search.isHighStake)"
  >
    <div class="filter-chip-label">
      {{ i18nService._('Title_Form_SearchForm_HighStake') }}
    </div>
  </mat-chip-option>
  <mat-chip-option color="primary" class="w-fit" [selected]="search.isNew" (click)="selectedItem('isNew', !search.isNew)">
    <div class="filter-chip-label">
      {{ i18nService._('Title_Form_SearchForm_New') }}
    </div>
  </mat-chip-option>
</mat-chip-listbox>
