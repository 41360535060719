<app-dashboard-card title="{{ 'Txt_DossierProspectNotePourDossier' | translate }}">
  <div class="body-input">
    <textarea
      class="comment-text p-inputtext p-component p-element ng-pristine ng-valid p-filled ng-touched"
      type="text"
      placeholder="{{ 'Txt_DossierProspectNotePlaceholder' | translate }}"
      [(ngModel)]="comment"
      (ngModelChange)="this.commentUpdate.next($event)"
    >
    </textarea>
  </div>
</app-dashboard-card>
