<div class="flex flex-col">
  <!-- Sticky subHeader form -->
  <app-sticky-header-form
    (actionButtonClicked)="onPublishButtonClicked()"
    [actionButtonIconMob]="'lg:hidden icon-validate-small text-xs pl-2'"
    [actionButtonIcon]="'icon-validate-small text-xs pl-2'"
    [actionButtonLabel]="i18nService._('Txt_Button_Publish')"
    [actionButtonTooltipText]="publishedButtonTooltipText"
    [goBackUrl]="programsDashboardPath"
    [isActionButtonDisabled]="isPublishButtonDisabled"
    [isActionButtonVisible]="isPublishButtonVisible"
    [isActionButtonVisibleStategie]="isActionButtonVisibleStategie"
    [titleText]="i18nService._('Title_BusinessStrategies') + ' - ' + programName"
  >
  </app-sticky-header-form>

  <div class="grid grid-cols-10 pt-16">
    <div class="col-span-6 col-start-3">
      <!-- Information -->
      <div class="text-base font-bold ml-4 mt-5">
        {{ i18nService._('Title_Information') }}
      </div>
      <p class="ml-4 mb-5">
        {{ i18nService._('Txt_Page_Form_RegisterStategy_Information') }}
      </p>

      <!-- Secondary navigation -->
      <mat-tab-group dynamicHeight mat-align-tabs="center" [selectedIndex]="activeTab" mat-stretch-tabs>
        <!-- Mandate strategy-->
        <mat-tab label="{{ i18nService._('Title_MandateStrategy') }}">
          <app-register-form-mandate-strategy
            (numberOfmandateStrategies)="onMandateStrategiesChange($event)"
            [readonly]="readonly"
            [programId]="programId"
          ></app-register-form-mandate-strategy>
        </mat-tab>

        <!-- Finance strategy-->
        <mat-tab label="{{ i18nService._('Title_FinancialStrategy') }}">
          <app-register-form-financial-strategy
            [programId]="programId"
            [readonly]="readonly"
            (financialStrategyChange)="onFinancialStrategyChange($event)"
          ></app-register-form-financial-strategy>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
