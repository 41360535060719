import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CurrencyPipe, NgIf } from '@angular/common';

import { StepContextComponent } from '../step-context-component';

import { Taxation } from './../../../../../../programs/models/enum/taxation.enum';
import { I18nTVALabelPourcentagePipe } from './../../../../../../utils/pipes/i18n-tva-pourcentage.pipe';
import { I18nService } from '../../../../../../utils/services/i18n.service';
import { SignatureService } from '../../../../../../signature/services/signature.service';
import { FormResponse } from '../../../../../../signature/models/FormResponse';
import { LotPriceService } from '../../../../services/lot-price.service';

@Component({
  selector: 'app-price-step-context-data',
  templateUrl: './price-step-context-data.component.html',
  styleUrls: ['./price-step-context-data.component.scss'],
  standalone: true,
  imports: [NgIf, CurrencyPipe, I18nTVALabelPourcentagePipe],
})
export class PriceStepContextDataComponent implements StepContextComponent, OnInit, OnDestroy {
  dossierId: number;
  form$: Observable<FormResponse>;
  annexePrice = 0;
  initialPrice: number;
  finalPrice: number;
  taxation: Taxation;

  private readonly _unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly renderer: Renderer2,
    private elRef: ElementRef,
    public i18nService: I18nService,
    private readonly route: ActivatedRoute,
    private readonly _signatureService: SignatureService,
    private readonly _lotPriceService: LotPriceService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.dossierId = this.route.snapshot.params.signatureId;
    this._lotPriceService.lotPrice$.pipe(takeUntil(this._unsubscribe$)).subscribe((lotPrices) => {
      if (lotPrices) {
        this.initialPrice = lotPrices.initialLotPrice;
        this.finalPrice = lotPrices.finalLotPrice;
        this.taxation = lotPrices.taxation;
      }
    });
  }

  setClass(myClass: string) {
    const classes = myClass.split(' ');
    classes.forEach((c) => this.renderer.addClass(this.elRef.nativeElement, c));
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next(true);
    this._unsubscribe$.complete();
  }
}
