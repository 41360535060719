import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

import { I18nService } from '../../services/i18n.service';
import { ResponsibleInfo } from '../../models/ResponsibleInfo';
import { PhonePipe } from '../../pipes/phone.pipe';
import { IconComponent } from '../../../common/standalone/icon/icon.component';

@Component({
  selector: 'app-interlocutor-contact',
  templateUrl: './interlocutor-contact.component.html',
  styleUrls: ['./interlocutor-contact.component.scss'],
  standalone: true,
  imports: [NgIf, IconComponent, PhonePipe],
})
export class InterlocutorContactComponent {
  @Input()
  public contact: ResponsibleInfo;

  @Input()
  public subtitle: string;

  constructor(public readonly i18nService: I18nService) {}
}
