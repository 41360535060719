import { Location, NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';

import { AdvancedTrackingTablesNoBackPageComponent } from '../advanced-tracking-tables-no-back-page/advanced-tracking-tables-no-back-page.component';

import { ActivityCategories } from '../../../activities/enum/activity-categories.enum';
import { Activity } from '../../../activities/models/Activity';
import { Sitemap } from '../../../utils/models/Sitemap';
import { I18nService } from '../../../utils/services/i18n.service';
import { AdvancedTrackingTableColumnType } from '../../models/advanced-tracking-table-column-type.enum';
import { ActivitiesTrackingTableService } from '../../services/activities-tracking-table.service';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';

@Component({
  selector: 'app-activities-tracking-table',
  templateUrl: './activities-tracking-table.component.html',
  styleUrls: ['./activities-tracking-table.component.scss'],
  standalone: true,
  imports: [StickyHeaderFormComponent, MatTabsModule, NgFor, AdvancedTrackingTablesNoBackPageComponent],
})
export class ActivitiesTrackingTableComponent {
  /**
   * Tabs configuration.
   *
   * @type {Array<{}>}
   * @memberof ProgramPageLotsComponent
   */
  public basicColumns = [
    {
      key: 'id',
      hidden: true,
    },
    {
      key: 'creationDate',
      type: AdvancedTrackingTableColumnType.Date,
      parameters: {
        dateFormat: 'DD/MM/YYYY HH:mm',
      },
      sortable: true,
      i18nTitle: 'Txt_Table_Date_Creation',
    },
    {
      key: 'template',
      sortable: true,
      i18nTitle: 'Txt_Table_Label',
    },
    {
      key: 'isVisible',
      sortable: true,
      type: AdvancedTrackingTableColumnType.Boolean,
      parameters: {
        reverse: true,
      },
      i18nTitle: 'Txt_Table_IsConsumed',
    },
    {
      key: 'actions',
      type: AdvancedTrackingTableColumnType.Actions,
      sortable: false,
      i18nTitle: 'Txt_Table_Action_s',
      parameters: {
        actions: {
          redirect: (element: Activity) => {
            const queryParams = {};
            const url = element.url.split('?');
            if (url.length > 1) {
              const params = url[1].split('&');
              params.forEach((param) => {
                const paramSeparateKeyValue = param.split('=');
                queryParams[paramSeparateKeyValue[0]] = paramSeparateKeyValue[1];
              });
            }
            this.router.navigate([url[0]], { queryParams });
          },
        },
      },
    },
  ];

  /**
   * Tabs configuration.
   *
   * @type {Array<{}>}
   * @memberof ProgramPageLotsComponent
   */
  public tabs = [
    {
      title: 'Title_Activities',
      category: ActivityCategories.ACTION,
      serviceParams: 'action',
      noResult: 'Txt_FastSearchActivities_No_Result',
    },
    {
      title: 'Title_Notifications',
      category: ActivityCategories.INFO,
      serviceParams: 'info',
      noResult: 'Txt_FastSearchNotifications_No_Result',
    },
  ];

  /**
   * Selected index for tabs.
   *
   * @type {number}
   * @memberof ProgramPageLotsComponent
   */
  public selectedTabIndex: number;

  /**
   * Creates an instance of ProgramsTrackingTableComponent.
   *
   * @param {I18nService} i18nService
   * @param {ActivatedRoute} activatedRoute
   * @param {Location} location
   * @param {Router} router
   * @param {ActivitiesTrackingTableService} service
   * @memberof ProgramsTrackingTableComponent
   */
  constructor(
    public readonly i18nService: I18nService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location,
    private readonly router: Router,
    public readonly service: ActivitiesTrackingTableService,
  ) {
    this.selectedTabIndex = this.tabs.findIndex((tab) => tab.category === activatedRoute.snapshot.data.category);
  }

  /**
   * Change selected tab and url location.
   *
   * @memberof ProgramsTrackingTableComponent
   */
  public changeTab($event: number): void {
    this.selectedTabIndex = $event;
    this.location.go(
      this.tabs[this.selectedTabIndex].category === ActivityCategories.ACTION
        ? Sitemap.dashboards.action.path
        : Sitemap.dashboards.info.path,
    );
  }
}
