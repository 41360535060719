<div class="flex flex-col">
  <!-- Sticky subHeader form -->
  <app-sticky-header-form [titleText]="i18nService._('Title_Saved_Searches')"></app-sticky-header-form>

  <div class="pt-16 mx-3 mb-4 lg:mx-5 md:mx-5 sm:mx-4 px-2 lg:px-6 md:px-5 sm:px-3">
    <app-advanced-tracking-table-no-back-page
      class="flex w-full"
      [columns]="columns"
      [service]="service"
      [noResultMessageI18nToken]="noResultMessageI18nToken"
    >
    </app-advanced-tracking-table-no-back-page>
  </div>
</div>
