import { Directive } from '@angular/core';

import { getUniqueId } from '../models/app-constant';

@Directive()
export abstract class AbstractUuid {
  protected uuid: string;

  constructor() {
    this.uuid = getUniqueId(4);
  }
}
