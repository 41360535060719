// eslint-disable-next-line
/* eslint-disable max-lines */
/* eslint-disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { signature } from './signature/signature_fr_FR';
import { prospect } from './prospect/prospect_fr_FR';

@Injectable()
export class StringsProject {
  strings = {
    Name_app: 'Valorissimo',

    /**
     * i18n Database Label
     *
     */

    /* Common */
    OTHER: 'Autre',
    HELLO: 'Bonjour',

    /* Time translation */

    Txt_TimeHour: 'heure',
    Txt_TimeHours: 'heures',
    Txt_TimeMinute: 'minute',
    Txt_TimeMinutes: 'minutes',
    Txt_TimeSecond: 'seconde',
    Txt_TimeSeconds: 'secondes',

    /* Civility */
    MISTER: 'Monsieur',
    MISSUS: 'Madame',
    MR: 'Mr',
    MRS: 'Mme',

    /* Legal Structure */
    SA: 'SA',
    SAS: 'SAS',
    SARL: 'SARL',
    EURL: 'EURL',
    INDIVIDUAL_COMPANY: 'Société en nom propre',
    PARTNERSHIP_COMPANY: 'Société en nom collectif',
    SCP: 'SCP',
    SEP: 'SEP',
    SCI: 'SCI',

    /* Group company list */
    LCL: 'LCL',
    PRIMAXIA: 'PRIMAXIA',
    CAISSE_EPARGNE: 'CAISSE EPARGNE',
    AFEDIM: 'AFEDIM',
    EXPERTISIMO: 'EXPERTISIMO',
    BANQUE_POSTALE: 'BANQUE POSTALE',
    CREDIT_AGRICOLE: 'CREDIT AGRICOLE',
    LEEMO: 'LEEMO',
    THESEIS: 'THESEIS',
    MEDICIS: 'MEDICIS',
    IZIMMO: 'IZIMMO',
    LMI: 'LMI',
    OPTISSIMO: 'OPTISSIMO',
    TO_BE_CREATED: 'A créer',

    /* Lot Orientation */
    N: 'Nord',
    NE: 'Nord-Est',
    E: 'Est',
    SE: 'Sud-Est',
    S: 'Sud',
    SW: 'Sud-Ouest',
    W: 'Ouest',
    NW: 'Nord-Ouest',
    TRAVERSING: 'Traversant',

    SHORT_N: 'N',
    SHORT_NE: 'NE',
    SHORT_E: 'E',
    SHORT_SE: 'SE',
    SHORT_S: 'S',
    SHORT_SW: 'SO',
    SHORT_W: 'O',
    SHORT_NW: 'NO',
    SHORT_TRAVERSING: 'TRAVERSANT',

    /* Professional Category */
    CGP: 'CGP',
    CGPI: 'CGPI',
    DISTRIBUTOR: 'Commercialisateur',
    BANKING_NETWORK: 'Réseau bancaire',
    ESTATE_AGENCY: 'Agence Immobilière',
    INSURANCE_COMPANY: "Compagnie d'assurance",
    DEVELOPER_COMPANY: 'Promoteur',

    /* Program Type */
    MIXED: 'Mixte',
    HOUSE: 'Maison',
    APARTMENT: 'Appartement',
    TRADE: 'Local commercial',
    PLURAL_MIXED: 'Mixte',
    PLURAL_HOUSE: 'Maisons',
    PLURAL_APARTMENT: 'Appartements',
    PLURAL_TRADE: 'Locaux commerciaux',

    /* Secondary Lot Type */
    OUTDOOR_PARKING: 'Parking ext.',
    BASEMENT_PARKING_LOT: 'Parking int.',
    OUTDOOR_PARKING_SHORT: 'Park. ext.',
    BASEMENT_PARKING_LOT_SHORT: 'Park. int.',
    PARKING: 'Parking',
    BOXES: 'Box',
    GARAGE: 'Garage',
    CARPORT: 'Carport',
    CELLAR: 'Cave',
    LARDER: 'Cellier',
    ATTIC: 'Grenier',

    /* Company Size  */
    '0_9': '0 à 9',
    '10_49': '10 à 49',
    '50_99': '50 à 99',
    '100+': '100+',

    /* Taxation */
    NOMINAL_TAXATION: 'TVA Nominale',
    ANRU: 'ANRU',
    PINEL: 'PINEL',
    PINEL_PLUS: 'PINEL PLUS',
    PLS: 'PLS',
    'LMNP/LMP': 'LMNP/LMP',
    LMNP_LMP: 'LMNP/LMP',
    LMNP_LMP_NON_GERE: 'LMNP/LMP Non geré',
    'PINEL PLUS': 'PINEL PLUS',
    MALRAUX: 'MALRAUX',
    NUE_PROPRIETE: 'NUE PROPRIETE',
    DEFICIT_FONCIER: 'DEFICIT FONCIER',
    'DEFICIT FONCIER': 'DEFICIT FONCIER',
    MONUMENTS_HISTORIQUES: 'MONUMENTS HISTORIQUES',
    'MONUMENTS HISTORIQUES': 'MONUMENTS HISTORIQUES',
    'NUE PROPRIETE': 'NUE PROPRIETE',
    /* Taxation Type */
    INVESTMENT: 'Investisseur',
    OCCUPIER: 'Occupant',

    /* Profil */
    hyper: 'Hyper',
    super: 'Super',
    simple: 'Simple',

    /* ValoRoleType */
    system: 'Système',
    admin: 'Administrateur',
    bo: 'BO',
    developer: 'Développeur',
    marketer: 'Marketeur',
    rpv: 'RPV',
    network_leader: 'Chef de réseau',

    /* Account Status */
    // PENDING: 'En attente' // Duplicate key
    VALIDATED: 'Validé',
    BLOCKED: 'Bloqué',
    DEACTIVATED: 'Suspendu',
    UNAUTHORIZED: 'Non autorisé',
    REFUSED: 'Refusé',
    /* Country */
    ANDORRA: 'Andorre',
    UNITED_ARAB_EMIRATES: 'Émirats arabes unis',
    AFGHANISTAN: 'Afghanistan',
    ANTIGUA_AND_BARBUDA: 'Antigua-et-Barbuda',
    ANGUILLA: 'Anguilla',
    ALBANIA: 'Albanie',
    ARMENIA: 'Arménie',
    NETHERLANDS_ANTILLES: 'Antilles néerlandaises',
    ANGOLA: 'Angola',
    ANTARCTICA: 'Antarctique',
    ARGENTINA: 'Argentine',
    AMERICAN_SAMOA: 'Samoa américaines',
    AUSTRIA: 'Autriche',
    AUSTRALIA: 'Australie',
    ARUBA: 'Aruba',
    AZERBAIJAN: 'Azerbaïdjan',
    BOSNIA_AND_HERZEGOVINA: 'Bosnie-Herzégovine',
    BARBADOS: 'Barbade',
    BANGLADESH: 'Bangladesh',
    BELGIUM: 'Belgique',
    BURKINA_FASO: 'Burkina Faso',
    BULGARIA: 'Bulgarie',
    BAHRAIN: 'Bahreïn',
    BURUNDI: 'Burundi',
    BENIN: 'Bénin',
    BERMUDA: 'Bermudes',
    BRUNEI: 'Brunei',
    BOLIVIA: 'Bolivie',
    BRAZIL: 'Brésil',
    BAHAMAS: 'Bahamas',
    BHUTAN: 'Bhoutan',
    BOUVET_ISLAND: 'Île Bouvet',
    BOTSWANA: 'Botswana',
    BELARUS: 'Biélorussie',
    BELIZE: 'Belize',
    CANADA: 'Canada',
    COCOS_KEELING_ISLANDS: 'Cocos Keeling (Îles)',
    CONGO_DRC: 'Congo RDC',
    CENTRAL_AFRICAN_REPUBLIC: 'République centrafricaine',
    CONGO_REPUBLIC: 'République du Congo',
    SWITZERLAND: 'Suisse',
    COTE_D_IVOIRE: "Côte d'Ivoire",
    COOK_ISLANDS: 'Cook (Îles Cook)',
    CHILE: 'Chili',
    CAMEROON: 'Cameroun',
    CHINA: 'Chine',
    COLOMBIA: 'Colombie',
    COSTA_RICA: 'Costa Rica',
    CUBA: 'Cuba',
    CAPE_VERDE: 'Cap-Vert',
    CHRISTMAS_ISLAND: 'Christmas (Île Christmas)',
    CYPRUS: 'Chypre',
    CZECH_REPUBLIC: 'République tchèque',
    GERMANY: 'Allemagne',
    DJIBOUTI: 'Djibouti',
    DENMARK: 'Danemark',
    DOMINICA: 'Dominique',
    DOMINICAN_REPUBLIC: 'République dominicaine',
    ALGERIA: 'Algérie',
    ECUADOR: 'Équateur',
    ESTONIA: 'Estonie',
    EGYPT: 'Égypte',
    WESTERN_SAHARA: 'Sahara occidental',
    ERITREA: 'Érythrée',
    SPAIN: 'Espagne',
    ETHIOPIA: 'Éthiopie',
    FINLAND: 'Finlande',
    FIJI: 'Fidji',
    FALKLAND_ISLANDS: 'Malouines (Îles)',
    MICRONESIA: 'Micronésie',
    FAROE_ISLANDS: 'Féroé (Îles)',
    FRANCE: 'France',
    GABON: 'Gabon',
    UNITED_KINGDOM: 'Royaume-Uni',
    GRENADA: 'Grenade',
    GEORGIA: 'Géorgie',
    FRENCH_GUIANA: 'Guyane française',
    GUERNSEY: 'Guernesey',
    GHANA: 'Ghana',
    GIBRALTAR: 'Gibraltar',
    GREENLAND: 'Groenland',
    GAMBIA: 'Gambie',
    GUINEA: 'Guinée',
    GUADELOUPE: 'Guadeloupe',
    EQUATORIAL_GUINEA: 'Guinée équatoriale',
    GREECE: 'Grèce',
    SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS: 'Géorgie du Sud et îles Sandwich du Sud',
    GUATEMALA: 'Guatemala',
    GUAM: 'Guam',
    'GUINEA-BISSAU': 'Guinée-Bissau',
    GUYANA: 'Guyane',
    GAZA_STRIP: 'bande de Gaza',
    HONG_KONG: 'Hong Kong',
    HEARD_ISLAND_AND_MCDONALD_ISLANDS: 'Heard (Île) et McDonald (Îles)',
    HONDURAS: 'Honduras',
    CROATIA: 'Croatie',
    HAITI: 'Haïti',
    HUNGARY: 'Hongrie',
    INDONESIA: 'Indonésie',
    IRELAND: 'Irlande',
    ISRAEL: 'Israël',
    ISLE_OF_MAN: 'Île de Man',
    INDIA: 'Inde',
    BRITISH_INDIAN_OCEAN_TERRITORY: "Territoire britannique de l'océan Indien",
    IRAQ: 'Irak',
    IRAN: 'Iran',
    ICELAND: 'Islande',
    ITALY: 'Italie',
    JERSEY: 'Jersey',
    JAMAICA: 'Jamaïque',
    JORDAN: 'Jordanie',
    JAPAN: 'Japon',
    KENYA: 'Kenya',
    KYRGYZSTAN: 'Kirghizistan',
    CAMBODIA: 'Cambodge',
    KIRIBATI: 'Kiribati',
    COMOROS: 'Comores',
    SAINT_KITTS_AND_NEVIS: 'Saint-Kitts-et-Nevis',
    NORTH_KOREA: 'Corée du Nord',
    SOUTH_KOREA: 'Corée du Sud',
    KUWAIT: 'Koweït',
    CAYMAN_ISLANDS: 'Îles Caïmans',
    KAZAKHSTAN: 'Kazakhstan',
    LAOS: 'Laos',
    LEBANON: 'Liban',
    SAINT_LUCIA: 'Sainte-Lucie',
    LIECHTENSTEIN: 'Liechtenstein',
    SRI_LANKA: 'Sri Lanka',
    LIBERIA: 'Libéria',
    LESOTHO: 'Lesotho',
    LITHUANIA: 'Lituanie',
    LUXEMBOURG: 'Luxembourg',
    LATVIA: 'Lettonie',
    LIBYA: 'Libye',
    MOROCCO: 'Maroc',
    MONACO: 'Monaco',
    MOLDOVA: 'Moldavie',
    MONTENEGRO: 'Monténégro',
    MADAGASCAR: 'Madagascar',
    MARSHALL_ISLANDS: 'Marshall (Îles)',
    MACEDONIA: 'Macédoine',
    MALI: 'Mali',
    MYANMAR: 'Myanmar',
    MONGOLIA: 'Mongolie',
    MACAU: 'Macao',
    NORTHERN_MARIANA_ISLANDS: 'Mariannes du Nord (Îles)',
    MARTINIQUE: 'Martinique',
    MAURITANIA: 'Mauritanie',
    MONTSERRAT: 'Montserrat',
    MALTA: 'Malte',
    MAURITIUS: 'Île Maurice',
    MALDIVES: 'Maldives',
    MALAWI: 'Malawi',
    MEXICO: 'Mexique',
    MALAYSIA: 'Malaisie',
    MOZAMBIQUE: 'Mozambique',
    NAMIBIA: 'Namibie',
    NEW_CALEDONIA: 'Nouvelle-Calédonie',
    NIGER: 'Niger',
    NORFOLK_ISLAND: 'Île Norfolk',
    NIGERIA: 'Nigéria',
    NICARAGUA: 'Nicaragua',
    NETHERLANDS: 'Pays-Bas',
    NORWAY: 'Norvège',
    NEPAL: 'Népal',
    NAURU: 'Nauru',
    NIUE: 'Nioué',
    NEW_ZEALAND: 'Nouvelle-Zélande',
    OMAN: 'Oman',
    PANAMA: 'Panama',
    PERU: 'Pérou',
    FRENCH_POLYNESIA: 'Polynésie française',
    PAPUA_NEW_GUINEA: 'Papouasie-Nouvelle-Guinée',
    PHILIPPINES: 'Philippines',
    PAKISTAN: 'Pakistan',
    POLAND: 'Pologne',
    SAINT_PIERRE_AND_MIQUELON: 'Saint-Pierre-et-Miquelon',
    PITCAIRN_ISLANDS: 'Pitcairn (Îles)',
    PUERTO_RICO: 'Porto Rico',
    PALESTINIAN_TERRITORIES: 'Palestinien',
    PORTUGAL: 'Portugal',
    PALAU: 'Palau',
    PARAGUAY: 'Paraguay',
    QATAR: 'Qatar',
    REUNION: 'Réunion',
    ROMANIA: 'Roumanie',
    SERBIA: 'Serbie',
    RUSSIA: 'Russie',
    RWANDA: 'Rwanda',
    SAUDI_ARABIA: 'Arabie saoudite',
    SOLOMON_ISLANDS: 'Îles Salomon',
    SEYCHELLES: 'Seychelles',
    SUDAN: 'Soudan',
    SWEDEN: 'Suède',
    SINGAPORE: 'Singapour',
    SAINT_HELENA: 'Sainte-Hélène',
    SLOVENIA: 'Slovénie',
    SVALBARD_AND_JAN_MAYEN: 'Svalbard et Jan Mayen',
    SLOVAKIA: 'Slovaquie',
    SIERRA_LEONE: 'Sierra Leone',
    SAN_MARINO: 'Saint-Marin',
    SENEGAL: 'Sénégal',
    SOMALIA: 'Somalie',
    SURINAME: 'Suriname',
    SAO_TOME_AND_PRINCIPE: 'São Tomé e Príncipe',
    EL_SALVADOR: 'Salvador',
    SYRIA: 'Syrie',
    SWAZILAND: 'Swaziland',
    TURKS_AND_CAICOS_ISLANDS: 'Turks et Caïques (Îles)',
    CHAD: 'Tchad',
    FRENCH_SOUTHERN_TERRITORIES: 'Territoires français du Sud',
    TOGO: 'Togo',
    THAILAND: 'Thaïlande',
    TAJIKISTAN: 'Tadjikistan',
    TOKELAU: 'Tokélaou',
    'TIMOR-LESTE': 'Timor-Leste',
    TURKMENISTAN: 'Turkménistan',
    TUNISIA: 'Tunisie',
    TONGA: 'Tonga',
    TURKEY: 'Turquie',
    TRINIDAD_AND_TOBAGO: 'Trinité-et-Tobago',
    TUVALU: 'Tuvalu',
    TAIWAN: 'Taïwan',
    TANZANIA: 'Tanzanie',
    UKRAINE: 'Ukraine',
    UGANDA: 'Ouganda',
    US_MINOR_OUTLYING_ISLANDS: 'Îles mineures éloignées des États-Unis',
    UNITED_STATES: 'États-Unis',
    URUGUAY: 'Uruguay',
    UZBEKISTAN: 'Ouzbékistan',
    VATICAN_CITY: 'Cité du Vatican',
    SAINT_VINCENT_AND_THE_GRENADINES: 'Saint-Vincent-et-les Grenadines',
    VENEZUELA: 'Venezuela',
    BRITISH_VIRGIN_ISLANDS: 'Îles Vierges britanniques',
    US_VIRGIN_ISLANDS: 'Îles Vierges américaines',
    VIETNAM: 'Vietnam',
    VANUATU: 'Vanuatu',
    WALLIS_AND_FUTUNA: 'Wallis et Futuna',
    SAMOA: 'Samoa',
    KOSOVO: 'Kosovo',
    YEMEN: 'Yémen',
    MAYOTTE: 'Mayotte',
    SOUTH_AFRICA: 'Afrique du Sud',
    ZAMBIA: 'Zambie',
    ZIMBABWE: 'Zimbabwe',
    ALAND_ISLANDS: 'Îles Åland',
    SAINT_BARTHELEMY: 'Saint-Barthélemy',
    BES_ISLAND: 'Pays-Bas Caribéens',
    CURACAO: 'Curaçao',
    EUROPEAN_ECONOMIC_COMMUNITY: 'Communauté économique européenne',
    COLLECTIVITY_OF_SAINT_MARTIN: 'Collectivité de Saint-Martin Antilles Française',
    SOUTH_SUDAN: 'Soudan du Sud',
    SINT_MAARTEN: 'Saint-Martin',

    /* Contact Function */
    PARTNER_MANAGER: 'Associé / Gérant',
    CEO: 'Directeur général / Président',
    SALES_MANAGER: 'Responsable commercial',

    /* Area Types */
    TERRACE: 'Terrasse',
    BALCONY: 'Balcon',
    GARDEN: 'Jardin',
    LOGGIA: 'Loggia',

    /* Company Category */
    KEY_ACCOUNT: 'Grand compte',
    CLASSIC: 'Classique',

    /* VALO ROLE NAMES  */
    developerHyper: 'Hyper promoteur',
    developerSuper: 'Super promoteur',
    contractorHyper: 'Hyper prescripteur',
    contractorSuper: 'Super prescripteur',

    /* Status */
    PENDING: 'En attente',
    VALID: 'Validé',
    INCOMPLETE: 'Incomplet',
    DENIED: 'Refusé',
    DRAFT: 'Saisie en cours',

    OPTION_REFUSED: 'Refusée',
    OPTION_ACCEPTED: 'Acceptée',
    OPTION_EXPIRED: 'Expirée',
    OPTION_PENDING: 'En attente',

    TRANSACTION_OPTION_REFUSED: 'Option refusée',
    TRANSACTION_OPTION_ACCEPTED: 'Option acceptée',
    TRANSACTION_OPTION_EXPIRED: 'Option expirée',
    TRANSACTION_OPTION_PENDING: 'Option en attente',
    TRANSACTION_PRERESERVED: 'Pré-réservation validée',
    TRANSACTION_PENDING: 'Pré-réservation en attente',
    TRANSACTION_PRERESERVATION_REJECTED: 'Pré-réservation refusée',
    TRANSACTION_PRERESERVATION_CANCELED: 'Pré-réservation annulée',
    TRANSACTION_RESERVATION_IN_SIGNATURE: 'Réservation en signature',
    TRANSACTION_RECEIVED_VALO: 'Réservation - reçue valorissimo',
    TRANSACTION_NOTARY_SIGNATURE_OBTAINED: 'Réservation vendue',
    TRANSACTION_LOAN_OFFER_OBTAINED: 'Réservation - Accord de prêt obtenu',
    TRANSACTION_AGREEMENT_OBTAINED: 'Réservation - Accord de principe obtenu',
    TRANSACTION_CLIENT_DESISTED: 'Réservation désistée',
    TRANSACTION_NOTIFICATION_SRU_IN_PROGRESS: 'Réservation - délai SRU en cours',
    TRANSACTION_NOTIFICATION_SRU_PURGED: 'Réservation - délai SRU purgé',
    TRANSACTION_VALIDATED_VALO: 'Réservation - validée valorissimo',
    TRANSACTION_VALIDATED_DEVELOPER: 'Réservation - validée promoteur',
    TRANSACTION_REJECTED_DEVELOPER: 'Réservation - rejetée promoteur',
    TRANSACTION_SENT_DEVELOPER: 'réservation - envoyée promoteur',
    TRANSACTION_NOTARY_MEETING_OBTAINED: 'Réservation - rdv notaire obtenu',
    TRANSACTION_RESERVATION_CANCELED: 'Réservation annulée',

    COMPANY_PENDING: 'En attente',
    COMPANY_VALID: 'Validé',
    COMPANY_INCOMPLETE: 'Incomplet',
    COMPANY_DENIED: 'Refusé',

    PROGRAM_DRAFT: 'Brouillon',
    PROGRAM_PENDING: 'En attente de validation',
    PROGRAM_VALID: 'Validé',
    PROGRAM_INCOMPLETE: 'Incomplet',
    PROGRAM_DENIED: 'Rejeté',
    PROGRAM_PUBLISHED: 'Publié',
    PROGRAM_UNPUBLISHED: 'Dépublié',
    PROGRAM_SCHEDULED: 'Programmé',

    PATERNITY_ACCEPTED: 'Accepté',
    PATERNITY_PENDING: 'En attente de validation',
    PATERNITY_REJECTED: 'Refusé',

    SPECIAL_OFFER_ACTIVE: 'Active',
    SPECIAL_OFFER_TOCOMEUP: 'À venir',
    SPECIAL_OFFER_EXPIRED: 'Expirée',

    LOTS_FREE: 'Libre',
    LOTS_UNAVAILABLE: 'Indisponible',
    LOTS_OPTIONED: 'Optionné',
    LOTS_SOLD: 'Vendu',
    LOTS_RESERVED: 'Réservé',
    LOTS_PRERESERVED: 'Pré-réservé',

    PROSPECTS_REJECTED: 'REJECTED',

    /* Reservation motives*/
    CONTRACT_SIGNED: 'Contrat de réservation signé',
    SIGNING_APPOINTMENT_PROJECTED: 'Rdv signature planifié',
    WAITING_FOR_DEVELOPER: 'En attente retour promoteur',

    /* Prereservation status*/
    PRERESERVATION_PENDING: 'En attente de validation',
    PRERESERVATION_PRERESERVATION_REJECTED: 'Refusé',
    PRERESERVATION_PRERESERVATION_CANCELED: 'Annulé',
    PRERESERVATION_PRERESERVED: 'Validé',
    ERPDocument: 'Etat des risques et pollutions',

    /* Prereservation status*/
    RESERVATION_PENDING: 'En attente de validation',
    RESERVATION_PRERESERVATION_REJECTED: 'Refusé',
    RESERVATION_PRERESERVATION_CANCELED: 'Annulé',
    RESERVATION_PRERESERVED: 'Validé',
    RESERVATION_RECEIVED_VALO: 'Reçu VALORISSIMO',
    RESERVATION_VALIDATED_VALO: 'Validé VALORISSIMO',
    RESERVATION_SENT_DEVELOPER: 'Envoyé Promoteur',
    RESERVATION_VALIDATED_DEVELOPER: 'Validé',
    RESERVATION_REJECTED_DEVELOPER: 'Rejeté',
    RESERVATION_SRU_NOTIF_SET: 'Notification SRU fixée',
    RESERVATION_AGREEMENT_OBTAINED: 'Accord de principe obtenu',
    RESERVATION_LOAN_OFFER_OBTAINED: 'Accord de prêt obtenu',
    RESERVATION_NOTARY_MEETING_OBTAINED: 'RDV Notaire obtenu',
    RESERVATION_RESERVATION_CANCELED: 'Annulé',
    RESERVATION_NOTARY_SIGNATURE_OBTAINED: 'Signature Notaire',
    RESERVATION_CLIENT_DESISTED: 'Client désisté',

    /* Document type labels */
    lotPlan: 'Plan du lot',
    ApartmentContractualDocumentPackage: 'Liasse contractuelle (Appartement)',
    HouseContractualDocumentPackage: 'Liasse contractuelle (Maison)',
    TradeContractualDocumentPackage: 'Liasse contractuelle (Local Commercial)',
    ParkingMap: 'Plan de parking',
    FloorMap: "Plan d'étage",

    RECEIVED_VALO: 'Reçu VALORISSIMO',
    VALIDATED_VALO: 'Validé VALORISSIMO',
    SENT_DEVELOPER: 'Envoyé Promoteur',
    VALIDATED_DEVELOPER: 'Validé',
    REJECTED_DEVELOPER: 'Rejeté',
    SRU_NOTIF_SET: 'Notification SRU fixée',
    AGREEMENT_OBTAINED: 'Accord de principe obtenu',
    LOAN_OFFER_OBTAINED: 'Accord de prêt obtenu',
    NOTARY_MEETING_OBTAINED: 'RDV Notaire obtenu',
    NOTARY_SIGNATURE_OBTAINED: 'Signature Notaire',
    CLIENT_DESISTED: 'Client désisté',
    RESERVATION_CANCELED: 'Annulé',

    /* Client qualifications */
    INVESTOR: 'Investisseur',
    OCCUPANT: 'Occupant',

    // Company Type
    VALORISSIMO: 'Valorissimo',
    CONTRACTOR: 'Prescripteur',
    DEVELOPER: 'Promoteur',
    INDEPENDANT: 'Independant',

    // Validation Type
    NONE: 'Non',
    ONLY_EXCLUSIVE_OFFER: 'Offre exclusive',
    ALL: "Toute l'offre",

    NONE_PATERNITY: 'Non',
    ONLY_EXCLUSIVE_OFFER_PATERNITY: "Offre exclusive lors de la pose d'option",
    ALL_PATERNITY: "Toute l'offre",

    // Reservation withdraw motive
    WITHIN_10_DAYS_PERIOD: 'Rétractation dans le délai de 10 jours',
    LOAN_DECLINED: 'Problème financement – refus de prêt',
    BLOC_SELLING_OR_PROGRAM_STRATEGY: 'Stratégie programme/BLOC',
    LOT_OR_DEVELOPER_CHANGE: 'Changement de lot/Promoteur',
    VOID_CONTRACT: 'Contrat caduc',
    PROGRAM_DELAYED: "Décalage d'opération",
    JOB_LOSS: "Perte d'emploi",
    PROGRAM_CANCELLED: 'Programme abandonné',
    PERSONAL_REASONS: 'Raisons Personnelles',

    /**
     * i18n Error Message
     * Syntax : Error_Field_Subject or Error_SnackBar_Subject
     *
     */
    /* Field Error */
    Error_Field_Required: 'Merci de renseigner ce champ', // ERR_1
    Error_Field_IncorrectEmail: 'Format invalide, merci de saisir une adresse mail valide', // ERR_3
    Error_Field_IncorrectPhoneNumber: 'Format invalide, merci de saisir un numéro de téléphone valide', // ERR_35
    Error_Field_EmailOrPhoneRequired: 'Merci de saisir une adresse mail ou un numéro de téléphone valide', // ERR_34
    Error_Field_IncorrectDate: 'Format invalide, merci de saisir une date au format JJ/MM/AAAA',
    Error_Field_PasswordMustRespectRules: 'Le mot de passe doit respecter les règles de sécurité définies plus haut', // ERR_5
    Error_Field_PasswordsMustMatch: 'Les mots de passe saisis ne sont pas identiques', // ERR_6
    Error_Field_Recaptcha: "Si vous n'êtes pas un robot, merci de recharger la page et recommencer", // ERR_7
    Error_Field_Document: 'Merci de joindre ce document', // ERR_9
    Error_Field_Siret: 'Une société avec le même SIRET existe déjà comme partenaire Valorissimo, veuillez contacter le {0}', // ERR_10
    Error_Field_ConnectionRefused: 'Connexion refusée', // ERR_11
    Error_Field_CoordinatesFormat_Longitude: 'Longitude comprise entre +180° et -180°', // ERR_14
    Error_Field_CoordinatesFormat_Latitude: 'Latitude comprise entre -90° et +90°', // ERR_8
    Error_Field_DocumentFormat: 'Seuls les documents au format {0} sont acceptés', // ERR_12 ERR_16 ERR_17
    Error_Field_DocumentSize: 'Le document ne doit pas dépasser {0} Mo', // ERR_13 ERR_30
    Error_Field_DocumentEmpty: 'Le document est vide',
    Error_Field_CoordinatesFormat: "Le format de la coordonnée n'est pas valide",
    Error_Field_AccountEmailUsed: 'Un compte existe déjà avec cette adresse mail.', // ERR_15
    Error_Field_Contract: 'Merci de charger à minima un contrat appartement, un contrat maison ou un contrat local commercial', // ERR_18
    Error_Field_Contract_ESign_VALO:
      'Merci de charger au format docx avec champs de fusion à minima un contrat appartement, un contrat maison ou un contrat local commercial',
    Error_Field_CommercialBrochure: "Merci de charger la plaquette commerciale ou saisir l'url de la plaquette numérique",
    Error_Field_NoResult: '<b>Veuillez élargir votre recherche</b>&nbspafin d’obtenir plus de résultats.', // ERR_19
    Error_Field_DownloadDocuments: 'Veuillez sélectionner des documents à télécharger', // ERR_21
    Error_Field_SendDocuments: 'Veuillez sélectionner des documents à envoyer', // ERR_22
    Error_Field_ZipCodeMustMatchCity: 'La ville ne correspond pas au code postal saisi', // ERR_23
    Error_Field_Incorrect: 'Aucune valeur ne correspond à la saisie', // ERR_24
    Error_Field_LastPassword: "Merci d'utiliser un mot de passe différents de vos derniers mots de passe", // ERR_25
    Error_Field_NotEnoughNumber: 'Merci de saisir {0} chiffres',
    Error_Field_Wrong_ZipCode: "Le code Postal n'est pas valide, merci d'en saisir un présent dans la liste",
    Error_Field_ValueBetween: 'La valeur doit être comprise entre {0} et {1}',
    Error_Field_TooManyCheckedReducedTaxation: "Il n'est pas possible de sélectionner plus d'une fiscalité TVA réduite",
    Error_Field_Invalid_Format: 'Format Invalide', // ERR_33
    Error_Field_MustBeBetween0And100: 'Le montant doit être compris entre 0 et 100',
    Error_Field_IncorrectInteger: 'Merci de saisir un entier',
    Error_Field_ProgramReference: 'Une référence comptable programme identique existe déjà pour cette société',
    Error_Field_IncorrectPhone: 'Merci de saisir un numéro de téléphone valide',
    Error_Field_Url: 'Merci de saisir une url valide',
    Error_Field_MinValue: 'Merci de saisir au minimum {0} valeur(s).',
    Error_Field_MaxValue: 'Merci de saisir au maximum {0} valeur(s).',
    Error_Field_EstimatedAppointmentDateRequired: "Merci d'indiquer la date prévisionnelle du rendez-vous.", // ERR_39
    Error_Field_SupportingDocumentRequired: 'Merci de fournir une pièce justificative.', // ERR_40
    Error_Field_CommentsRequired: 'Merci de saisir un commentaire.', // ERR_42
    Error_Field_IncorrectLenghtMax: 'Format invalide, merci de rentrer moins de caractères',
    Error_FileSystem_UnresolvedUrlOfDocument: 'Lien introuvable',
    Error_Field_IncorrectField: 'Champ invalide.',
    Error_Field_MinValueRooms: 'Un lot ne peut pas contenir 0 pièce',
    Error_Field_IncorrectFloorValue:
      "Merci de saisir un numéro d'étage valid compris entre 99 et -99 \
      Exemple: 1, 3, 4, -1 (étage négatif), 0 (RDC)",
    Error_field_specialOfferRef: `Cette référence existe déjà pour ce promoteur`,
    Error_field_programIdList: `Veuillez sélectionner au moins un programme`,
    Error_field_lotIdList: `Veuillez sélectionner au moins un lot`,
    /* Snackbar Error */
    Error_SnackBar_FormIncomplete: 'Une erreur est survenue, vérifiez tous les champs du formulaire', // ERR_20
    Error_SnackBar_ContactIncomplete: 'Une erreur est survenue, vérifiez les champs du formulaire de contact',
    Error_SnackBar_CompanyIncomplete: 'Une erreur est survenue, vérifiez les champs du formulaire de société',
    Error_SnackBar_AdminFormIncomplete: 'Une erreur est survenue, vérifiez les champs du formulaire de valorissimo',
    Error_SnackBar_DocumentIncomplete: 'Une erreur est survenue, vérifiez les champs du formulaire des pièce à fournir',
    Error_SnackBar_InvalidStatus: "Impossible de modifier le status d'une société déjà valide",
    Error_SnackBar_Mandat: 'Vous avez indiqué que le mandat était signé, merci de renseigner les informations du mandat pour valider',
    Error_Lot_Has_Too_Many_Reduced_Taxations: `Il n'est pas possible de sélectionner plus d'une fiscalité TVA réduite`, // ERR_29
    Error_SnackBar_AlreadyExistsMandateStrategy: 'Il existe déjà une Stratégie Mandat avec ce nom', // ERR_31
    Error_SnackBar_AlreadyPublishedProgram: 'Le Programme est deja publiée',
    Error_SnackBar_404Redirection: 'La ressource associée à la méthode {0} du service {1} est indisponible',
    Error_SnackBar_ConnectionAsExpired: 'Votre connexion "En tant que" a expiré',
    // TO ADD
    Error_SnackBar_RedirectNotConnected: "Vous avez été redirigé car vous n'êtes pas connecté. Veuillez-saisir vos identifiants",
    Error_SnackBar_RedirectNotAllowed: "Vous avez été redirigé car votre compte n'est pas habilité pour l'action précédente",
    Error_SnackBar_RedirectPageNotAllowed: "Vous avez été redirigé car votre compte n'est pas habilité pour accéder à cette page",
    Error_SnackBar_TokenNotValidForPasswordChange: "L'url de cette page de changement de mot de passe n'est pas valide",
    Error_SnackBar_ErrorOccured: 'Une erreur est survenue',
    Error_SnackBar_ErrorOccuredRetry: 'Une erreur est survenue, merci de réessayer', // ERR_28
    Error_Snackbar_FailLoadingContractPDF: 'Erreur lors du chargement du contrat de réservation',
    Error_Field_PasswordUnusable: "Merci d'utiliser un mot de passe différents de vos {0} derniers mots de passe",
    Error_SnackBar_BrowserNotCompatibleWithAttachment: 'Votre navigateur ne peux pas gérer le dépot de pièce jointe',
    Error_SnackBar_ManyAttachmentsForbidden: "Veuillez n'ajouter qu'une seule pièce jointe",
    Error_SnackBar_AttachmentTypeForbidden: "Ce type de pièce jointe n'est pas permis",
    Error_SnackBar_AttachmentSendingFailed: "Une erreur est survenue lors de l'envoi des pièces jointes",
    Error_SnackBar_UniquenessRefOrNumber:
      "Une erreur est survenue, merci de vérifier l'unicité de la \
      référence ou du numéro du lot secondaire", // ERR_27
    Error_SnackBar_UniquenessLotRefOrLotNumber:
      "Une erreur est survenue, \
      merci de vérifier l'unicité de la référence ou du numéro du lot principal ou réessayez", // ERR_26
    Error_SnackBar_UnavailableRemoveOption: "La levée d'option est momentanément indisponible, veuillez réessayer ultérieurement.",
    Error_SnackBar_UnavailableExtendOption: "La prolongation d'option est momentanément indisponible, veuillez réessayer ultérieurement.",
    Error_SnackBar_UnavailableAcceptOrRejectOption:
      "L'acceptation\\suppression est momentanément indisponible, \
      veuillez réessayer ultérieurement.",
    Error_SnackBar_AccountToValidateMissingInformations: "Le compte a valider n'a pas de parent.",
    Error_SnackBar_AccountToValidateErrorStatus: "Ce compte n'est pas en attente de validation.",
    Error_SnackBar_MissingApartmentContractualDocumentPackage: 'Merci de joindre la liasse contractuelle Appartement au programme.',
    Error_SnackBar_MissingHouseContractualDocumentPackage: 'Merci de joindre la liasse contractuelle Maison au programme.',
    Error_SnackBar_MissingTradeContractualDocumentPackage: 'Merci de joindre la liasse contractuelle Local Commercial au programme.',
    Error_SnackBar_ValoReservationValidationDateBeforeValoReservationFileReceiptDate:
      'La date de validation du dossier de réservation \
      ne peut être antérieure à la date de réception.',
    Error_SnackBar_NotarySignatureDateBeforeNotaryMeetingDate:
      'La date de signature notaire ne peut être antérieure à la date de \
      rendez-vous notaire.',
    Error_SnackBar_NotaryMeetingDateBeforeLoanOfferDate:
      "La date de rendez-vous notaire ne peut être antérieure à la date d'obtention de \
      l'offre de prêt.",
    Error_SnackBar_LoanOfferDateBeforePrincipleAgreementDate:
      "La date d'obtention de l'offre de prêt ne peut être antérieure à la date \
      d'obtention de l'accord de principe.",
    Error_SnackBar_PrincipleAgreementDateBeforeSruResevationNotificationDate:
      "La date d'obtention de l'accord de principe ne peut être \
      antérieure à la date de notification SRU.",
    Error_Snackbar_EmptyPrereservationContractualDocumentPackageZip: 'Aucun fichier à inclure dans le zip de la liasse contractuelle.',
    Error_Snackbar_EmptyReservationDocumentPackageZip: 'Aucun fichier à inclure dans le zip de contrat de réservation.',
    Error_Snackbar_PaternityFailed:
      'Suite à un problème technique, la demande de paternité a  \
      échoué. Merci de réessayer ultérieurement.', // ERR_54
    Error_SnackBar_RedirectPageAccountCantBeValidated: 'Vous avez été redirigé car le compte est déjà ou ne peut être validé',
    Error_SnackBar_RecaptchaLoading: "Une erreur est survenue lors de l'initialisation du recaptcha",
    Error_SnackBar_lotsAreNotComplete: 'Impossible de valider le programme car les lots suivants sont en erreur :  ',
    Error_SnackBar_lotsAreNotComplete_Publish: 'Impossible de publier le programme car les lots suivants sont en erreur :  ',
    Txt_No_Result: 'Aucun résultat',
    Txt_No_Result_Saved_Search: 'Aucune recherche a été sauvegardée',
    Txt_PaternitiesTrackingTable_No_Result: "Vous n'avez pas de prospects déclarés disponibles actuellement",
    Txt_OptionsTrackingTable_No_Result: "Vous n'avez pas d'options en cours disponibles actuellement.",
    Txt_PreReservationsTrackingTable_No_Result: "Vous n'avez pas de pré-réservations disponibles actuellement.",
    Txt_FastSearchActivities_No_Result: "Vous n'avez pas d'activité disponible actuellement",
    Txt_FastSearchNotifications_No_Result: "Vous n'avez pas de notification disponible actuellement",
    Txt_ReservationsTrackingTable_No_Result: 'Aucune réservation',
    Txt_NotEnoughLetters: 'Tapez au moins 3 caractères',
    Txt_NotEnoughLetters_TwoLetters: 'Tapez au moins 2 caractères',
    Error_SnackBar_WafAnomaly: 'Une erreur est survenue, merci de nous contacter.',

    /* Snackbar Info */
    Info_SnackBar_LotCreated: 'Le lot a bien été créé',
    Info_SnackBar_LotUpdated: 'Le lot a bien été modifié',
    Info_SnackBar_LotCommercialPriceTTC:
      'Le prix TTC du lot commercial est incohérent. \
      Veuillez renseigner un prix compris entre 40 000 € et 3 000 000 €.', // ERR_41
    Info_SnackBar_UpdateReservationSuccess: 'Le dossier de réservation a été mis à jour avec succès.',
    Info_SnackBar_ProgramAndLotsPublished: 'Le programme et ses lots seront publiés avec les stratégies Mandat et Finance mises en place.',
    Info_SnackBar_ProgramAndLotsUnpublished: 'Le programme et ses lots ont été dépubliés.',
    Info_SnackBar_UnscheduleProgramPublicationSuccess: 'Planification de la publication annulée avec succès.',
    Info_SnackBar_LotPublished: 'Le lot a bien été publié',
    Info_SnackBar_LotUnpublished: 'Le lot a bien été dépublié',
    Info_SnackBar_ExcludeListModified: `Liste d'exclusion modifiée avec succès`,

    /**
     * i18n Title Message
     * Syntax : Title_Subject
     *
     */

    Title_SignIn: 'Connexion',
    Title_RegisterFile: "Dossier d'inscription",
    Title_Company: 'Société',
    Title_LegalRepresentative: 'Représentant légal',
    Title_NetworkHeader: 'Chef de réseau',
    Title_Applicant: 'Demandeur',
    Title_MandateDelegation: 'Délégation du mandat',
    Title_DocumentsRequired: 'Pièces à fournir',
    Title_Valorissimo: 'Valorissimo',
    Title_QuickSearch: 'Recherche rapide',
    Title_SearchByProgram: 'Recherche par programme',
    Title_AvailablePlots: 'Lots disponibles',
    Title_Documents_Lot_Details: 'Documents',
    Title_PresentationDocumentation: 'Documents de présentation',
    Title_BookingAndGuaranteesBook: 'Documents contractuels',
    Title_PropertyProgram: 'Programme',
    Title_PropertyLotOfProgram: 'Lots du programme',
    Title_PropertyProgramDescription: 'Descriptif du programme',
    Title_TaxLMNPLMP: 'Fiscalité LMNP/LMP',
    Title_TaxMalraux: 'Fiscalité Malraux',
    Title_TaxMonumentsHistoriques: 'Fiscalité Monuments Historiques',
    Title_TaxNuePropriete: 'Fiscalité Nue propriété',
    Title_Documents: 'Documents',
    Title_ConstructionWork: 'Travaux',
    Title_Location: 'Localisation',
    Title_BillingAddress: 'Adresse de facturation',
    Title_KeyDates: 'Dates clés',
    Title_BusinessTransaction: 'Opération Commerciale',
    Title_Information: 'Information',
    Title_EditProgram: 'Modifier un programme',
    Title_ValidateProgram: 'Valider un programme',
    Title_EditPlot: 'Modifier un lot',
    Title_DeletePlot: 'Supprimer un lot',
    Title_Description: 'Description',
    Title_Situation: 'Situation',
    Title_Access: 'Accès',
    Title_CommercialServices: 'Prestations',
    Title_Qualities: 'Qualités',
    Title_Developer: 'Promoteur',
    Title_TaxANRU: 'Fiscalité ANRU',
    Title_Summary: 'Récapitulatif',
    Title_ContractorCreation: "Création d'un nouveau prescripteur",
    Title_IndependantCreation: "Création d'un nouvel indépendant",
    Title_IndependantModification: "Modification d'un compte indépendant",
    Title_SuperContractorCreation: "Création d'un nouveau super prescripteur",
    Title_ContractorValidation: "Validation d'un compte prescripteur",
    Title_ContractorModification: "Modification d'un compte prescripteur",
    Title_DeveloperCreation: "Création d'un nouveau promoteur",
    Title_SuperDeveloperCreation: "Création d'un nouveau super promoteur",
    Title_DeveloperValidation: "Validation d'un compte promoteur",
    Title_DeveloperModification: "Modification d'un compte promoteur",
    Title_ValorissimoUserCreation: "Création d'un utilisateur",
    Title_ValorissimoUseModification: "Modification d'un utilisateur Valorissimo",
    Title_GeneralInformations: 'Informations Générales',
    Title_ContractualDocuments: 'Documents contractuels',
    Title_AdministrativeDocuments: 'Documents administratifs',
    Title_CompanyCreation: "Création d'une société",
    Title_BusinessStrategies: 'Stratégies Commerciales',
    Title_MandateStrategy: 'Stratégie Mandat',
    Title_FinancialStrategy: 'Stratégie Finance',
    Title_CreateAlotement: 'Créer un Alottement',
    Title_MandateCompanies: 'Mandater des Sociétés',
    Title_MandateStrategyInformation: 'Informations de la Stratégie Mandat',
    Title_Program: 'Programme(s)',
    Title_SpecialOffers: 'Offres Spéciales',
    Title_Search_Program: 'Recherche par programme',
    Title_Paternity: 'Mes prospects',
    Title_Check_Paternity_Email_Tab: 'Adresse Mail',
    Title_Check_Paternity_Phone_Tab: 'Numéro de téléphone',
    Title_Check_Paternity_Dialog: 'Vérifier une demande de déclaration de prospect',
    Title_Surface_Area: 'Surface',
    Title_Buyer_Infos: 'Informations Acquéreur',
    Title_Financial_Infos: 'Informations Financières',
    Title_Sales_Administrator: 'Administrateur des Ventes',
    Title_Sales_Administrator_Valorissimo: 'ADV Valorissimo',
    Title_Sales_Administrator_Developer: 'ADV Promoteur',
    Title_UnpublishProgram: 'Dépublier un programme',
    Title_Unschedule: 'Déplanifier un programme',
    Title_Salers: "Suivi de l'activité de vente",
    Title_Desktop_Publishing_Dialog: 'Générer un document contractuel',
    Title_Exclude_list_Dialog: 'Liste d’exclusion',
    Title_Saved_Searches: 'Mes recherches',
    Title_Delete_Search: 'Supprimer ma recherche',
    Title_reservation_record: 'Dossier de réservation',
    Title_reservation_founding: 'Avancement et financement',
    Title_reservation_commentary: 'Commentaire',
    Title_neighborhood_map: 'Plan de quartier',

    // TO ADD
    Title_NoAccountYet: "Je n'ai pas encore de compte.",
    Title_SignUp: "S'inscrire",
    Title_NotFound: '404',
    Title_ResetPassword: 'Réinitialiser le mot de passe',
    Title_RegisterCompany: "Formulaire d'inscription",
    Title_ModifyCompany_Dev: 'Modification Société(s) Promotrice(s)',
    Title_ModifyCompany: 'Modification Société(s) Prescriptrices(s)',
    Title_ValidateCompany_Dev: 'Validation Société(s) Promotrice(s)',
    Title_ValidateCompany: 'Validation Société(s) Prescriptrice(s)',
    Title_CreationProgram: "Formulaire de création d'un programme",
    Title_CommercialOperation: "Création d'un programme",
    Title_SpecialOfferCreation: "Création d'une Offre Spéciale",
    Title_ModifyCommercialOperation: "Modification  d'un programme",
    Title_ValidateCommercialOperation: "Validation  d'un programme",
    Title_Lot_Deletion: 'Supprimer un lot',
    Title_Lot_Unpublication: 'Dépublier un lot',
    Title_UserPersonalData: "Données personnelles de l'utilisateur",
    Title_MandateStrategy_Deletion: 'Suppression de la Stratégie Mandat',
    Title_LegalNotice: 'Mentions légales',
    Title_EditorLegal: 'Editeur du site valorissimo.com',
    Title_PublicationLegal: 'Publication',
    Title_HostLegal: 'Hébergeur du site',
    Title_ConfidentialityLegal: 'Politique de confidentialité - Données Personnelles',
    Title_RightsLegal: 'Vos droits',
    Title_PersonalDataLegal: 'Vos données personnelles',
    Title_TreatmentLegal: 'Les finalités de traitement',
    Title_CookiesLegal: 'Cookies',
    Title_IntellectualPriorityLegal: 'Priorité Intellectuelle',
    Title_HypertextLinksLegal: 'Liens hypertextes',
    Title_PriceLegal: 'Prix',
    Title_ProfitabilityLegal: 'Rentabilité marché estimée',
    Title_RentLegal: 'Loyer marché estimé',
    Title_SecondaryLot_Deletion: 'Supprimer un lot secondaire',
    Title_Activities: 'Activité',
    Title_Notifications: 'Notifications',
    Title_Activities_And_Notifications: 'Mon activité et notifications',

    /**
     * i18n Paragraph Message
     * Syntax : Txt_Container_(Form)_(FormName)_Subject
     *
     * Container : Page, Popin, Tooltip, Button, SnackBar, Checkbox, Link, Placeholder, RadioButton
     *
     */

    /* Home Page */
    Txt_placeholder_fast_search: 'Ville ou département ou région',
    Txt_placeholder_program_search: 'Nom du programme',
    Txt_Search_Tab_Localisation: 'Recherche par localisation',
    Txt_Search_Tab_Program: 'Recherche par programme',
    Txt_Advanced_Search: 'Recherche avancé',
    Txt_Remove_All_Selection: 'Tout effacer',

    /* Form Text */
    /* Reset Password Form Text */
    Txt_Page_Form_ResetPassword_Rules: 'Le mot de passe doit respecter les règles suivantes :',
    Txt_Page_Form_ResetPassword_CharNumber: '8 caractères',
    Txt_Page_Form_ResetPassword_CharCriteria: 'Au moins 3 des 4 critères : majuscule, minuscule, chiffre, caractère spécial',
    Txt_Page_Form_ResetPassword_LastPasswords: 'Choisir un mot de passe différent de vos derniers mots de passe',

    /* Init Company Form Text */

    Txt_Page_Form_InitCompany_Info:
      "Merci de lire attentivement les informations ci-dessous \
    avant de compléter le formulaire d'inscription.",
    Txt_Page_Form_InitCompany_Cat: 'Vous êtes : ',
    Txt_Page_Form_InitCompany_MustHave: 'Afin de poursuivre votre inscription, les documents suivants seront nécessaires :',
    Txt_Page_Form_InitCompany_KBIS: 'Extrait KBIS de moins de 3 mois',
    Txt_Page_Form_InitCompany_Id: "Copie recto-verso de la pièce d'identité du représentant légal",
    Txt_Page_Form_InitCompany_TCard: 'Copie recto-verso carte de Transaction immobilière',
    Txt_Page_Form_InitCompany_Insurance: "Attestation d'assurance et de responsabilité civile",
    Txt_Page_Form_InitCompany_Guarantee:
      "Attestation garantie financière valide pour l'année en cours (Obligatoire en cas de maniement \
      de fonds)",
    Txt_Page_Form_InitCompany_MandateDelegation: 'Délégation du MANDAT (le cas échéant)',
    Txt_Page_Form_InitCompany_MandateId: "Copie recto-verso de la pièce d'identité du délégataire (le cas échéant)",

    /* Register Company Form Text */
    Txt_Page_Form_RegisterCompany_CompanyStep: 'Société',
    Txt_Page_Form_RegisterCompany_LegalRepresentativeStep: 'Représentant Légal',
    Txt_Page_Form_RegisterCompany_DocRequiredStep: 'Pièces à fournir',
    Txt_Page_Form_RegisterCompany_ValoStep: 'Valorissimo',
    Txt_Page_Form_RegisterCompany_Signatory:
      'Veuillez renseigner les informations du contre signataire promoteur à qui sera transmis le contrat de réservation pour signature en ligne',
    Txt_Page_Form_RegisterCompany_LegalRepresentative: 'Etes-vous le réprésentant légal ?',
    Txt_Page_Form_RegisterCompany_MandateDelegation: 'Y a-t-il une délégation de mandat ?',
    Txt_Page_Form_RegisterCompany_MandateSignatory: 'Etes-vous le signataire du mandat ?',
    Txt_Page_Form_RegisterCompany_FinancialGuarantee:
      "L'attestation de garantie financière doit être fournie \
    lorsque vous êtes détenteur de fonds.",
    Txt_Page_Form_RegisterCompany_Comment: 'Si vous le souhaitez, veuillez laisser un commentaire',
    Txt_Page_Form_RegisterCompany_Admin:
      "Qui sera l'administrateur pour votre société sur Valorissimo \
    (création des comptes, modification des données de la société, ...) ?",
    Txt_Page_Form_RegisterCompany_Other: 'Précisez si autre.',
    Txt_Page_Form_RegisterCompany_CompanySize: 'Nombre de collaborateurs',
    Txt_Page_Form_RegisterCompany_Domiciliation: 'Domiciliation',
    Txt_Page_Form_RegisterCompany_Country: 'Pays',
    Txt_Page_Form_RegisterCompany_Civility: 'Civilité',
    Txt_Page_Form_RegisterCompany_FunctionType: 'Fonction',
    Txt_Page_Form_RegisterCompany_Validation: "Merci de votre confiance. Votre demande d'inscription a été envoyée à Valorissimo",
    Txt_Page_Form_RegisterCompany_Updated: 'Modifications enregistrées',
    Txt_Page_Form_RegisterCompany_Updated_Resubmit:
      "Modifications enregistrées. \
    N'oubliez pas de re-soumettre votre dossier à Valorissimo.",
    Txt_Page_Form_RegisterProgram_LotDescriptionSection: 'Descriptif du lot',
    Txt_Page_Form_RegisterProgram_LotTaxationSection: 'Fiscalité du lot',
    Txt_Page_Form_RegisterProgram_LotTaxation: 'Fiscalité',
    Txt_Page_Form_RegisterProgram_LotInvestorTaxationSection: 'Fiscalités investisseurs',
    Txt_Page_Form_RegisterProgram_LotLmnpLmpTaxationSection: 'Fiscalité LMNP/LMP',
    Txt_Page_Form_RegisterProgram_LotMalrauxTaxationSection: 'Fiscalité Malraux',
    Txt_Page_Form_RegisterProgram_LotDeficitFoncierTaxationSection: 'Fiscalité Deficit Foncier',
    Txt_Page_Form_RegisterProgram_LotMonumentsHistoriquesTaxationSection: 'Fiscalité Monuments Historiques',
    Txt_Page_Form_RegisterProgram_LotNueProprieteTaxationSection: 'Fiscalité Nue Propriété',
    Txt_Page_Form_RegisterCompany_Created: 'Société validée',
    Txt_Page_Form_RegisterCompany_Denied: 'Société refusée',
    Txt_Page_Form_RegisterCompany_Incomplete: 'Société incomplète',
    Txt_Page_Form_RegisterCompany_CompanyCategory: 'Type de société',
    Txt_Page_Form_RegisterCompany_AdminProfile: "Profil de l'administrateur",
    Txt_Page_Form_RegisterProgram_SecondaryLot: 'Lot Secondaire',
    Txt_Page_Form_RegisterProgram_IncludedAnnexes: 'Annexes Incluse',
    Txt_Page_Form_RegisterProgram_SecondaryLotRef: 'Référence Lot Secondaire',
    Txt_Page_Form_RegisterProgram_SecondaryLotNumber: 'Numéro Lot Secondaire',
    Txt_Page_Form_RegisterProgram_SecondaryLotType: 'Type du lot',
    Txt_Page_Form_RegisterProgram_SecondaryLotPrice: 'Prix Lot Secondaire',
    Txt_Page_Form_RegisterProgram_Actions: 'Actions',

    Txt_Page_Form_RegisterProgram_SecondaryLotTooltip:
      'Ajoutez autant de lots secondaires que souhaités \
    et indiquez si leur prix est inclus dans le prix du lot principal',

    /* Register Program Form Text */
    Txt_Page_Form_RegisterProgram_LongDescrDev: 'Descriptif long Promoteur',
    Txt_Page_Form_RegisterProgram_ShortDescrDev: 'Descriptif court Promoteur',
    Txt_Page_Form_RegisterProgram_LongDescriptionValo: 'Descriptif long Valorissimo',
    Txt_Page_Form_RegisterProgram_ShortDescriptionValo: 'Descriptif court Valorissimo',
    Txt_Page_Form_RegisterProgram_Description: 'Description',
    Txt_Page_Form_RegisterProgram_Situation: 'Situation',
    Txt_Page_Form_RegisterProgram_Services: 'Prestation',
    Txt_Page_Form_RegisterProgram_Quality: 'Qualité',
    Txt_Page_Form_RegisterProgram_Access: 'Accès',
    Txt_Page_Form_Note_LongDescriptionValo:
      'Note: Le champ "Descriptif long Valorissimo" ne traite pas le contenu HTML. Pour un formatage enrichi (HTML), veuillez utiliser le champ "Description" ci-dessous.',
    Txt_Page_Form_Note_Description:
      'Note: Vous pouvez utiliser le HTML dans ce champ pour un formatage enrichi. Le contenu sera interprété sur la page de détail du programme.',
    Txt_Page_Form_Note_ShortDescriptionValo:
      'Note: Le champ "Descriptif court Valorissimo" est déprécié et sera supprimé prochainement. Il est recommandé d\'utiliser "Descriptif long Valorissimo" ou "Description" pour une meilleure visibilité sur la plateforme',

    /* Business Strategies Form text */
    Txt_Page_Form_RegisterStategy_Information:
      "Pour publier un programme il est nécessaire d'avoir validé au moins une Stratégie \
      Mandat et une Stratégie Finance. La Stratégie Finance peut être non applicable à cette opération.",

    /* LogPage Text */
    Txt_Page_Login_IntroParagraph01: "Professionnels de l'immobilier",
    Txt_Page_Login_IntroParagraph02: '<b>{0} logements</b> et <b>{1} locaux commerciaux</b> disponibles actuellement sur Valorissimo',
    Txt_Page_Login_CompanyRegisterParagraph: 'Vous souhaitez développer votre activité ?',

    Txt_PageTrackingCompanyTable_NoCompany: "Vous n'avez pas de société actuellement.",

    /* Tooltip Text */
    Txt_Tooltip_inExcludeList: `Liste d'exclusion`,
    Txt_Tooltip_BiggerCompany: 'Merci de préciser si votre structure est rattachée à un réseau (Bancaire...).',
    Txt_Tooltip_IdentityDocument:
      "Seuls une carte nationale d'identité ou un passeport en cours de validité \
    sont acceptés pour justifier votre identité.",
    Txt_Tooltip_TCard: 'Merci de renseigner les 17 chiffres de votre carte T.',
    Txt_Tooltip_LotRef: 'Indiquer la référence promoteur du lot (clé technique).',
    Txt_Tooltip_LotNumber: 'Numéro de lot affiché sur la fiche du lot.',
    Txt_Tooltip_Floor: 'Indiquer la valeur 0 pour un bien en RDC.',
    Txt_Tooltip_CommercialLotSellingPriceIT:
      "Dans le cas d'une fiscalité ANRU Unique, indiquer le prix TTC en TVA 5,5% \
    sinon indiquer le prix TTC en TVA 20%.",
    Txt_Tooltip_LongDescriptionValo:
      'Afin de formater votre texte vous pouvez utiliser les balises HTML suivantes : \
      Gras : <b></b>, Souligné : <u></u>, Italique : <i></i>, Barré : <s></s>',
    Txt_Tooltip_AccountingProgramRef: 'Indiquer la référence programme promoteur',
    Txt_Tooltip_feesInformation: 'En savoir plus',
    Txt_Tooltip_Url3DViewControl: 'Vous pouvez saisir le lien menant vers une vue 3D',
    Txt_Tooltip_DigitalCommercialBrochureUrl: 'Vous pouvez saisir le lien menant vers la plaquette numérique',
    Txt_Tooltip_ValidateCompany: 'Valider la société',
    Txt_Tooltip_Edit: 'Modifier la société',
    Txt_Tooltip_mandateStrategy: 'Modifier la stratégie',
    Txt_Tooltip_Delete: 'Supprimer',
    Txt_Tooltip_Publish: 'Publier',
    Txt_Tooltip_Unpublish: 'Dépublier',
    Txt_Tooltip_Unschedule: 'Annuler la planification',
    Txt_Tooltip_CompleteCommercialStrategies: 'Afin de publier le programme merci de renseigner les Stratégies Commerciales',
    Txt_Tooltip_Accept: 'Accepter',
    Txt_Tooltip_Refuse: 'Refuser',
    Txt_Tooltip_Check: 'Vérifier',
    Txt_Tooltip_Motive: 'Motif de Rejet',
    Txt_Tooltip_Create_Account: 'Créer un compte',
    Txt_Tooltip_UpdateAccount: 'Modifier un compte',
    Txt_Tooltip_ValidateAccount: 'Valider un compte',
    Txt_Tooltip_EditProgram: 'Modifier',
    Txt_Tooltip_ValidateProgram: 'Valider',
    Txt_Tooltip_RedirectToStrategies: ' Accéder aux stratégies commerciales',
    Txt_Tooltip_EditLot: 'Modifier le lot',
    Txt_Tooltip_EditSecondaryLot: 'Modifier le lot secondaire',
    Txt_Tooltip_Phone: 'Indiquer le numéro au format international.',
    Txt_Tooltip_EditSearch: 'Modifier la recherche',
    Txt_Tooltip_DeleteSearch: 'Supprimer la recherche',

    Txt_Tooltip_Cancel: 'Annuler',
    Txt_Tooltip_DownloadContractualBundle: 'Télécharger la liasse contractuelle',
    Txt_Tooltip_ShowPreReservation: 'Accéder à la pré-réservation',
    Txt_Tooltip_GotoReservation: 'Accéder à la réservation',
    Txt_Tooltip_ShowReservation: 'Accéder à la réservation',
    Txt_Tooltip_OnlineSign: 'Démarrer ma signature en ligne',
    Txt_Tooltip_ContinueOnlineSign: 'Reprendre ma signature en ligne',
    Txt_Tooltip_ShowOfferResume: "Aperçu de l'offre",
    Txt_Tooltip_DownloadReservationContrat: 'Télécharger le contrat',

    Txt_Tooltip_Remove: 'Lever',
    Txt_Tooltip_Extend: 'Prolonger',
    Txt_Tooltip_PreReserve: 'Pré-réserver',
    Txt_Tooltip_LastNameAcquirer: "Saisir le Nom de famille de l'acquéreur ou de l'entité juridique à l'origine de la réservation.",

    Txt_Tooltip_AcceptOption: 'Accepter option',
    Txt_Tooltip_RejectOption: 'Refuser option',

    Txt_Tooltip_UpdateCompanyOnlyBIOffer:
      'La société est la seule société mandatée sur une stratégie mandat. Merci de vérifier les\
      stratégies mandat des programmes suivants :{0}',
    Txt_Tooltip_IsValorissimoExclusivity:
      "Cette case permet d'indiquer que le lot est en vente en exclusivité sur la plateforme\
      VALORISSIMO.COM",
    Txt_Tooltip_PaternityAutomaticValidation:
      "NON : Validation manuelle de toutes les paternités\
      \n\nOffre exclusive : Validation automatique si demande d'option sur un lot en exclusivité\
      \n\nToute l'offre : Validation automatique de toutes les paternités",
    Txt_Tooltip_OptionAutomaticValidation:
      "NON : Validation manuelle de toutes les options\
      \n\nOffre exclusive : Validation automatique si demande d'option sur un lot en exclusivité\
      \n\nToute l'offre : Validation automatique de toutes les options",

    /* Popin Text */
    Txt_Popin_Form_RegisterCompany_Reject: 'Merci de saisir la raison du rejet qui sera envoyée au partenaire.',
    Txt_Popin_Form_RegisterCompany_Incomplete:
      'Merci de saisir la raison pour laquelle le dossier est incomplet. \
    Elle sera envoyée au partenaire.',
    Txt_Popin_Form_ResetPassword_EmailSent: "Un mail a été envoyé à l'adresse suivante {0}",
    Txt_Popin_Lot_Deletion: 'Êtes-vous sûr de vouloir supprimer ce lot ? Cette action est irréversible.',
    Txt_Popin_Lot_Unpublication: 'Êtes-vous sûr de vouloir dépublier ce lot ?',
    Txt_Popin_MandateStrategy_Deletion:
      'Êtes-vous sûr de vouloir supprimer cette stratégie ? <br>\
    Les lots contenus dans cette stratégie seront associés à la Stratégie Mandat Globale si elle existe. <br>\
    Cette action est irréversible.',
    Txt_Popin_Invalid_Program: "Merci de saisir la raison de l'invalidation du programme qui sera envoyée au partenaire.",
    Txt_Popin_Denied_Program: 'Merci de saisir le motif du rejet qui sera envoyée au partenaire.',
    Txt_Check_Paternity_Dialog: 'Entrez un numéro de téléphone ou une adresse mail',
    Txt_Popin_Remove_Option_Title: "Levée d'option",
    Txt_Popin_Remove_Option: "Vous êtes sur le point de lever l'option sur le lot {0} du programme {1}. Voulez-vous continuez ?",
    Txt_Popin_Remove_File_Question: 'Confirmation de suppression du fichier ?',
    Txt_Popin_Remove_File: 'Supprimer définitivement le fichier',
    Txt_Popin_UnpublishProgram: 'Êtes-vous sûr de vouloir dépublier ce programme ?',
    Txt_Popin_UnscheduleProgram: 'Êtes-vous sûr de vouloir déplanifier ce programme ?',
    Txt_Delete_Search:
      'Etes-vous sûr de vouloir supprimer votre recherche ?<br>\
      \n\nToute validation de la suppression entraînera la suppression définitive de votre recherche sauvegardée.',

    /* Accept Paternity Text */
    Txt_Popin_Accept_Paternity: "Vous êtes sur le point d'accepter une demande de paternité. Voulez-vous continuer ?",
    Txt_Accept_Paternity_Success: 'La demande de déclaration de prospect a bien été acceptée',

    /* Reject Paternity Text */
    Txt_Popin_Reject_Paternity: 'Vous êtes sur le point de refuser une demande de paternité. Voulez-vous continuer ?',
    Txt_Reject_Paternity_Success: 'La demande de déclaration de prospect a été refusée',

    Txt_Popin_Motive_Title: 'Motif de rejet de la demande de paternité',

    // TO ADD
    Txt_Page_Form_ResetPassword_NewPassword: 'Nouveau mot de passe',
    Txt_Page_Form_ResetPassword_PasswordConfirmation: 'Confirmation du mot de passe',
    Txt_Page_NotFound: "Oups ! La page que vous recherchez n'existe pas",

    // Text documents by code
    Txt_Commercial_Program_Sheet_LP: 'Plan du lot',
    Txt_Commercial_Program_Sheet_CB: 'Plaquette commerciale',
    Txt_Commercial_Program_Sheet_DN: 'Notice',
    Txt_Commercial_Program_Sheet_PP: 'Présentation du programme',
    Txt_Commercial_Program_Sheet_GP: 'Plan de masse',
    Txt_Commercial_Program_Sheet_ARC: 'Contrat de réservation Appartement',
    Txt_Commercial_Program_Sheet_HRC: 'Contrat de réservation Maison',
    Txt_Commercial_Program_Sheet_TRC: 'Contrat de réservation Local commercial',
    Txt_Commercial_Program_Sheet_ACDP: 'Liasse contractuelle Appartement',
    Txt_Commercial_Program_Sheet_HCDP: 'Liasse contractuelle Maison',
    Txt_Commercial_Program_Sheet_TCDP: 'Liasse contractuelle Commerce',
    Txt_Commercial_Program_Sheet_SOAM: 'Avenant Offre spéciale - {0}',
    Txt_Commercial_Program_Sheet_SOAM_mail: 'Avenant Offre spéciale',
    Txt_Commercial_Program_Sheet_GF: 'Gestion locative, protection revente et trésorerie',
    Txt_Commercial_Program_Sheet_ERP: 'Etat des risques et pollutions',
    Txt_Commercial_Program_Sheet_PM: 'Plan de parking',
    Txt_Commercial_Program_Sheet_FM: "Plan d'étage",

    // Text Reservation
    Txt_Co_Acquirer_FirstName: 'Prénom co-acquéreur',
    Txt_Co_Acquirer_LastName: 'Nom co-acquéreur',
    Txt_Co_Acquirer_Email: 'Email co-acquéreur',
    Txt_Co_Acquirer_Phone: 'Téléphone co-acquéreur',
    Txt_Main_Lot: 'Lot Principal',
    Txt_Secondary_Lot: 'Lots secondaires',
    Txt_Customer_Qualification: 'Qualification client *',
    Txt_Tax_System: 'Fiscalité *',
    Txt_VAT_Rate: 'Taux TVA',
    Txt_Amount_Of_Discounts: 'Montant des remises',
    Txt_Special_Offer: 'Offre spéciale',
    Txt_Selling_Price: 'Prix de vente',
    Txt_Notary_Fees_Free: 'Frais de notaire offerts',
    Txt_Markup: 'Markup (%)',
    Txt_Markup_Amount: 'Montant effectif de markup (€)',
    Txt_Date_Of_Booking: 'Date de la réservation',
    Txt_Rates_Of_Pay: 'Taux de rémunération',
    Txt_Effective_Amount_Of_Fees: "Montant effectif d'honoraires",
    Txt_Specific_Contractor_Fees: 'Honoraires spécifiques',
    Txt_Specific_Contractor_Fees_Comment: 'Si vous le souhaitez, veuillez laisser un commentaire',
    Txt_Booking_File: 'Dossier de réservation',

    /* Button Text */
    Txt_Button_Connect: 'Se connecter',
    Txt_Button_Check: 'Vérifier',
    Txt_Button_Send: 'Envoyer',
    Txt_Button_CompanyRegister: "S'inscrire",
    Txt_Button_RegisterProspect: 'Je déclare un prospect',
    Txt_Button_Simulation: 'Faire une simulation',
    Txt_Button_Disconnect: 'Se déconnecter',
    Txt_Button_Cancel: 'Annuler',
    Txt_Button_Cancel_And_Close: 'Annuler et fermer',
    Txt_Button_Modify: 'Modifier',
    Txt_Button_Exclude_List: `Liste d'exclusion`,
    Txt_Button_Valid: 'Valider',
    Txt_Button_Save: 'Enregistrer',
    Txt_Button_Add: 'Ajouter',
    Txt_Button_Add_File: 'Ajouter un fichier',
    Txt_Button_Exit: 'Fermer',
    Txt_Button_NextStep: 'Étape suivante',
    Txt_Button_GoBack: 'Retour',
    Txt_Button_PlanningPublicationStrategies: 'Planifier la publication',
    Txt_Button_GoBackResults: 'Retour aux résultats',
    Txt_Button_Submit: 'Soumettre le dossier',
    Txt_Button_AcceptCompany: 'Valider la société',
    Txt_Button_RejectCompany: 'Rejeter la société',
    Txt_Button_IncompleteCompany: 'Dossier incomplet',
    Txt_Button_UpdateCompany: 'Modifier les données du dossier',
    Txt_Button_SaveModificationCompany: 'Enregistrer les modifications',
    Txt_Button_Yes: 'Oui',
    Txt_Button_No: 'Non',
    Txt_Button_Find: 'Chercher',
    Txt_Button_Search: 'Rechercher',
    Txt_Current_Dossier_Prospect: 'Dossiers prospect en cours',
    Txt_See_All_Dossier_Prospect: 'Voir tous mes dossiers >',
    Txt_No_Dossier_Prospect: "Vous n'avez encore aucun dossier prospect.",
    Txt_Button_ReinitiateCriteria: 'Réinitialiser les critères',
    Txt_Button_SaveSearch: 'Sauvegarder ma recherche',
    Txt_Button_EditSearch: 'Sauvegarder et visualiser',
    Txt_Button_SubmitCreateAccount: 'Créer le compte',
    Txt_Button_SubmitValidateAccount: 'Valider le compte',
    Txt_Button_SubmitUpdateAccount: 'Modifier le compte',
    Txt_Button_CreateNewLot: 'Créer un nouveau lot',
    Txt_Button_EditLot: 'Modifier le lot',
    Txt_Button_SubmitCreateLot: 'Créer le lot',
    Txt_Button_SubmitCreate: 'Créer',
    Txt_Button_SubmitModify: 'Modifier',
    Txt_Button_Publish: 'Publier',
    Txt_Button_SubmitMandateStrategy: 'Soumettre la stratégie mandat',
    Txt_Button_ModifyMandateStrategy: 'Modifier la stratégie mandat',
    Txt_Button_Declare: 'Déclarer',
    Txt_Button_ModifyProgram: 'Enregistrer',
    Txt_Button_AcceptProgram: 'Valider le Programme',
    Txt_Button_ReturnProgram: 'Retourner le Programme',
    Txt_Button_RefusedProgram: 'Rejeter le Programme',
    Txt_Button_TakeOption: 'Poser une option',
    Txt_Button_RequestOption: 'Demander une option',
    Txt_Button_RemoveOption: 'Lever',
    Txt_Button_RemoveOptionLong: `Lever l'option`,
    Txt_Button_ExtendOption: 'Prolonger',
    Txt_Button_Continue: 'Continuer',
    Txt_Button_Confirm: 'Confirmer',
    Txt_Button_PreReserve: 'Pré-réserver',
    Txt_Button_TakeAlert: 'Créer une alerte',
    Txt_Button_Quit: 'Quitter',
    Txt_Button_Lots_Prices_Access: 'Accéder à la grille des prix',
    Txt_Button_Advanced_Search_Hide: 'Recherche avancée',
    Txt_Button_Advanced_Search_Show: 'Masquer',
    Title_Button_ShowStrategies: 'Afficher les stratégies mandats',
    Txt_Button_Planning: 'Enregistrer',
    Txt_Button_Signature_Valid_Cancel: 'Confirmer et Modifier',
    Txt_Button_Action: 'Actions',

    /* Toggle Button Text */
    Txt_ToggleButton_All: 'Tous',
    Txt_ToggleButton_Selected: 'Sélectionnés',
    Txt_ToggleButton_NotSelected: 'Non-sélectionnés',

    /* Checkbox Text */
    Txt_Checkbox_RememberMe: 'Se souvenir de moi',
    Txt_Checkbox_SignedMandat: 'Mandat signé',
    Txt_Checkbox_ActiveCompany: 'Société active',
    Txt_Checkbox_ClubMember: 'Membre Club',
    Txt_Checkbox_BoostedFeeds: 'Bénéficiaires des honoraires boostés',
    Txt_Checkbox_OnlyBIOffer: "Éligible uniquement à l'offre Bouygues Immobilier",
    Txt_Checkbox_IsAutomatedDeactivation: 'Non assujetti à la désactivation automatique des comptes',
    Txt_Checkbox_Actable: 'Actable',
    Txt_Checkbox_DoNotRemindMe: 'Ne pas me le rappeler',
    Txt_Checkbox_ActiveAccount: 'Compte actif',
    Txt_Checkbox_VisibleCommercial: 'Locaux commerciaux visibles',
    Txt_Checkbox_ForbiddenAccess: 'Accès interdit à Valorissimo',
    Txt_Checkbox_IsValorissimoExclusivity: 'Exclusivité VALORISSIMO',
    Txt_Checkbox_IsElectronicSignatureBI: 'Éligible à la signature électronique BI',
    Txt_Checkbox_IsElectronicSignatureValorissimo: 'Éligible à la signature électronique Valorissimo',
    Txt_Checkbox_IsElectronicSignatureValorissimo_Disabled: "La société promotrice n'est pas éligible à la signature valorissimo",
    Txt_Checkbox_IsElectronicSignatureValorissimo_Error: 'Les documents contractuels doivent être au format DOCX',
    Txt_Checkbox_IsHighStakeProgram: 'Programme à enjeu',
    Txt_Checkbox_ElectronicallySignedFile: 'Dossier signé électroniquement',
    Txt_Checkbox_IsOptionDisagenge: "Prolongation d'option autorisée",
    Txt_Checkbox_IsOptionFromPreresaDisagenge: "Prolongation d'option pour les préréservations autorisée",
    Txt_Checkbox_IsEligibleDigitalSigning: 'Eligible signature électronique Valorissimo',
    Txt_Checkbox_IsValoMandateEsign: 'Contre signature mandatée à l’équipe BO Valorissimo',

    Txt_Checkbox_IsFeesHidden: 'Masquer les honoraires',

    /* Link Text */
    Txt_Link_ResetPassword: '(Ré)initialiser mon mot de passe',
    Txt_Link_QuiSommeNousPDF: 'Découvrez qui nous sommes',
    Txt_Link_Activities: 'Voir toutes les activités',
    Txt_Link_Notifications: 'Voir toutes les notifications',
    Txt_Link_External_Documents_Utils: 'Documents utiles',
    Txt_Link_External_Documents_Utils_Tooltip: 'Documents utiles',
    Txt_Link_External_Start_With_Valorissimo: 'Débuter sur Valorissimo',
    Txt_Link_External_FAQ: 'Questions fréquentes',
    Txt_Link_External_Announcement: 'Annonces',
    Txt_Link_External_Help_Center: `Centre d'aide`,
    /* Dialog Text  */
    Txt_Dialog_SaveNewOperationInfo:
      'Vous vous apprêtez à saisir une nouvelle opération commerciale.<br><br> \
    Cliquez à tout moment sur le bouton <B>"Enregistrer le brouillon"</B> afin de sauvegarder vos informations \
    saisies pour y revenir plus tard',

    /* Dialog contact */
    TxT_Dialog_Contact_Title: 'Mon interlocuteur Valorissimo',
    Title_Dialog_Contact_Title: 'Mon Interlocuteur',

    /* Contacts */
    Txt_Contact_My_Contact: 'Mon interlocuteur',
    Txt_Contact_BI_Offer: 'OFFRES BOUYGUES IMMO',
    Txt_Contact_Non_BI_Offer: 'OFFRES PROMOTEURS sélectionnés',

    TxT_showFees: 'Afficher les honoraires',
    TxT_hideFees: 'Cacher les honoraires',

    /* Dialog offer special */
    TxT_Dialog_Offer_Descriptif: 'Descriptif',
    TxT_Dialog_Offer_Date: 'Du {0} au {1}',
    TxT_Dialog_Offer_Reference: 'Référence',
    TxT_Dialog_Offer_Title: 'Libellé',

    /* Confirmation Dialog */
    dialog_skip_next_time: 'Ne plus afficher ce message',

    /* Placeholder Text */
    Txt_Placeholder_Email: 'Email',
    Txt_Placeholder_Password: 'Mot de passe',
    Txt_Placeholder_Login: 'Identifiant de connexion',
    Txt_Placeholder_CorporateName: 'Raison sociale',
    Txt_Placeholder_TradingName: 'Nom commercial',
    Txt_Placeholder_LegalStructure: 'Forme de la société',
    Txt_Placeholder_ProfessionalCategory: 'Catégorie Professionnelle',
    Txt_Placeholder_HomeEntity: 'Entité de rattachement',
    Txt_Placeholder_Address: 'Adresse',
    Txt_Placeholder_ZipCode: 'Code Postal',
    Txt_Placeholder_City: 'Ville',
    Txt_Placeholder_Proximity_City: 'Ville à proximité',
    Txt_Placeholder_Cedex: 'Cedex',
    Txt_Placeholder_Siret: 'Siret',
    Txt_Placeholder_CarteTNumber: 'Numéro de la carte T',
    Txt_Placeholder_CarteTEndDate: 'Date de fin de validité de la carte T ',
    Txt_Placeholder_BuildingPermitStartDate: 'PC Obtenu',
    Txt_Placeholder_BuildingPermitExpireDate: 'PC Purgé',
    Txt_Placeholder_WorkingDate: 'Date de début des travaux',
    Txt_Placeholder_ConstructionWorkRatio: 'Ratio travaux',
    Txt_Placeholder_CodeNAF: 'Code NAF',
    Txt_Placeholder_Capital: 'Capital',
    Txt_Placeholder_TVANumber: 'Numéro TVA intracommunautaire',
    Txt_Placeholder_CCI: "Chambre de commerce et de l'industrie",
    Txt_Placeholder_CommercialCourt: 'Tribunal de commerce',
    Txt_Placeholder_Civility: 'Civilité',
    Txt_Placeholder_LastName: 'Nom',
    Txt_Placeholder_FirstName: 'Prénom',
    Txt_Placeholder_Mobile: 'Mobile',
    Txt_Placeholder_DeskPhone: 'Fixe',
    Txt_Placeholder_Phone: 'Téléphone',
    Txt_Placeholder_KBISExtract: 'Extrait KBIS',
    Txt_Placeholder_IdentityDocumentOfTheLegalRepresentative: "Pièce d'identité du représentant légal",
    Txt_Placeholder_TCard: 'Carte T',
    Txt_Placeholder_Mandat: 'Mandat',
    Txt_Placeholder_InsuranceCertificateAndCivilResponsability: "Attestation d'assurance et de responsabilité civile",
    Txt_Placeholder_FinancialGuarantee: "Attestation de garantie financière valable pour l'année en cours",
    Txt_Placeholder_IdentityDocumentOfTheMandateSignatory: "Pièce d'identité du signataire du mandat ",
    Txt_Placeholder_MandateDelegation: 'Délégation de mandat',
    Txt_Placeholder_RCP: 'RCP',
    Txt_Placeholder_AffiliateRCV: 'Affilier un responsable BI',
    Txt_Placeholder_AffiliateDEV: 'Affilier un DEV',
    Txt_Placeholder_AffiliateTier: 'Affilier un responsable Promoteurs Sélectionnés',
    Txt_Placeholder_MandateNumber: 'Numéro du MANDAT',
    Txt_Placeholder_MandateStartDate: 'Date de début du MANDAT',
    Txt_Placeholder_MandateEndDate: 'Date de fin du MANDAT',
    Txt_Placeholder_Date: 'Date',
    Txt_Placeholder_Remuneration: 'Rémunération',
    Txt_Placeholder_Markup: 'Markup',
    Txt_Placeholder_PaternityDuration: "Durée d'une paternité (en jours)",
    Txt_Placeholder_GroupCompany: 'Groupe de rattachement',
    Txt_Placeholder_ApprovalComitteeResult: "Résultat du comité d'approbation",
    Txt_Placeholder_LegalNotice: 'Informations légales',
    Txt_Placeholder_DescriptionTitle: 'Titre de la description',
    Txt_Placeholder_Description: 'Description',
    Txt_Placeholder_CompanyLogo: 'Logo de la société',
    Txt_Placeholder_BOComment: 'Commentaire BO',
    Txt_Placeholder_Filter: 'Filtrer',
    Txt_Placeholder_Search: 'Rechercher',
    Txt_Placeholder_ColumnsToDisplay: 'Colonne(s) à afficher',
    Txt_Placeholder_Latitude: 'Latitude',
    Txt_Placeholder_Longitude: 'Longitude',
    Txt_Placeholder_Perspective: 'Perspective',
    Txt_Placeholder_GroundPlan: 'Plan de masse',
    Txt_Placeholder_CommercialBrochure: 'Plaquette',
    Txt_Placeholder_PresentationOfTheProgram: 'Présentation Programme',
    Txt_Placeholder_DescriptiveNotice: 'Notice Descriptive',
    Txt_Placeholder_ApartmentReservationContract: 'Contrat Appartement',
    Txt_Placeholder_ApartmentReservationContractESignValo: 'Contrat Appartement avec champs de fusion',
    Txt_Placeholder_HouseReservationContract: 'Contrat Maison',
    Txt_Placeholder_HouseReservationContractESignValo: 'Contrat Maison avec champs de fusion',
    Txt_Placeholder_TradeReservationContract: 'Contrat Local Commercial',
    Txt_Placeholder_tradeReservationContractESignValo: 'Contrat Local Commercial avec champs de fusion',
    Txt_Placeholder_ApartmentDescriptiveNoticeReservationContractESignValo: 'Notice descriptive appartement avec champs de fusion',
    Txt_Placeholder_HouseDescriptiveNoticeReservationContractESignValo: 'Notice descriptive maison avec champs de fusion',
    Txt_Placeholder_TradeDescriptiveNoticeReservationContractESignValo: 'Notice descriptive local commercial avec champs de fusion',
    Txt_Placeholder_LmnpMnpSignatorySpecificFilesEsignValo: 'Document spécifique signature LMNP/MNP avec champs de fusion',
    Txt_Placeholder_ParkingMap: 'Plan de parking',
    Txt_Placeholder_FloorMap: "Plan d'étage",
    Txt_Placeholder_ERPDocument: 'ERP',
    Txt_Placeholder_GuaranteeFormula: 'Gestion locative, protection revente et trésorerie',
    Txt_Placeholder_SubstantiveTimeLine: 'Echéancier d’appels de fonds',
    Txt_Placeholder_GfaAttestation: 'Attestation GFA',
    Txt_Placeholder_Amendement: 'Avenant',
    Txt_Placeholder_DeveloperCompanyName: 'Nom de la societe Promotrice',
    Txt_Placeholder_AccountingProgramRef: 'Référence comptable programme',
    Txt_Placeholder_ProgramName: 'Nom du programme',
    Txt_Placeholder_url3DView: 'Vue 3D',
    Txt_Placeholder_DigitalCommercialBrochureUrl: 'Plaquette commerciale numérique',
    Txt_Placeholder_taxZone: 'Zone fiscale',
    Txt_Placeholder_programType: 'Type de bien',
    Txt_Placeholder_lotsNumber: 'Nombre de lots total',
    Txt_Placeholder_LotRef: 'Référence Lot',
    Txt_Placeholder_LotNumber: 'Numéro du Lot',
    Txt_Placeholder_LotType: 'Type de bien',
    Txt_Placeholder_LotOrientation: 'Orientation',
    Txt_Placeholder_LivingSpace: 'Surface habitable',
    Txt_Placeholder_Rooms: 'Nombre de pièces',
    Txt_Placeholder_Floor: 'Etage',
    Txt_Placeholder_DeliveryDate: 'Date de livraison',
    Txt_Placeholder_LotPlan: 'Plan du lot',
    Txt_Placeholder_CommercialLotSellingPriceIT: 'Prix TTC Lot commercial',
    Txt_Placeholder_CommercialLotSellingPriceIT_lmnp: 'Prix TTC Lot commercial (Hors mobilier)',
    Txt_Placeholder_CommercialLotSellingPriceIT_NuePropriete: 'Prix TTC nue propriété',
    Txt_Placeholder_CommercialLotSellingPriceIT_other_taxation: 'Prix Lot commercial',
    Txt_Placeholder_PleineProprietePrice_nue_propriete: 'Prix nue propriété TTC',
    Txt_Placeholder_VATRate: 'Taux TVA',
    Txt_Placeholder_EstmatedMonthlyRentingPrice: 'Loyer marché estimé',
    Txt_Placeholder_EstimatedProfitability: 'Rentabilité marché estimée',
    Txt_Placeholder_EstimatedFurnishedMarketRent: 'Loyer meublé marché estimé',
    Txt_Placeholder_estimatedFurnishedMarketYield: 'Rentabilité meublé marché estimée',
    Txt_Placeholder_GuaranteedMonthlyRentingPriceIT: 'Loyer mensuel garanti TTC',
    Txt_Placeholder_EstatePriceET: 'Prix foncier HT',
    Txt_Placeholder_EstimatedWorkPrice: 'Prix travaux',
    Txt_Placeholder_EstimatedLandPrice: 'Prix foncier',
    Txt_Placeholder_EstimatedTotalPrice: 'Prix total de ventes TTC',
    Txt_Placeholder_HousingPriceET: 'Prix immo HT',
    Txt_Placeholder_GlobalSellingPriceET: 'Prix de vente total HT (Hors mobilier)',
    Txt_Placeholder_TangiblePriceET: 'Prix mobilier HT',
    Txt_Placeholder_UsufruitValue: 'Valeur usufruit',
    Txt_Placeholder_DistributionKey: 'Clé de répartition',
    Txt_Placeholder_NueProprieteDuration: 'Durée',
    Txt_Placeholder_SellingFees: 'Frais de vente (estimation)',
    Txt_Placeholder_LoanFees: 'Frais de prêt (estimation)',
    Txt_Placeholder_AttorneyFees: 'Frais de procuration (estimation)',
    Txt_Placeholder_MonthlyRentingPriceET: 'Loyer mensuel HT',
    Txt_Placeholder_MonthlyRentalLotPriceIT: 'Loyer mensuel lot principal TTC (TVA : 10%)',
    Txt_Placeholder_MonthlyRentalSecondaryLotPriceIT: 'Loyer lot(s) secondaire(s) TTC (TVA : 20%)',
    Txt_Placeholder_RegisterCompany_RejectReason: 'Raison du refus',
    Txt_Placeholder_RegisterCompany_IncompleteReason: 'Raison pour laquelle le dossier est incomplet',
    Txt_Placeholder_RegisterMandateStrategy_Title: 'Libellé de la stratégie Mandat',
    Txt_Placeholder_RegisterMandateStrategy_Honorary: 'Honoraire',
    Txt_Placeholder_RegisterMandateStrategy_EndDate: 'Date fin',
    Txt_Placeholder_RegisterPlanningPublicationMandateStrategy: 'Date',
    Txt_Placeholder_AssiociatedManager: 'Responsable associé',
    Txt_Placeholder_AccountingReference: 'Référence Comptable Valorissimo',
    Txt_Placeholder_Mandate: 'Mandat / Avenant',
    Txt_Placeholder_Invalid_Program: 'Message de retour',
    Txt_Placeholder_Denied_Program: 'Message de rejet',
    Txt_Placeholder_ApartmentContractualDocumentPackage: 'Liasse contractuelle Appartement',
    Txt_Placeholder_HouseContractualDocumentPackage: 'Liasse contractuelle Maison',
    Txt_Placeholder_TradeContractualDocumentPackage: 'Liasse contractuelle Local Commercial',
    Txt_Placeholder_SpecialOfferAmendmentNotaryFees: 'Avenant avec champs de fusion de frais de notaires offerts',
    Txt_Placeholder_SpecialOfferAmendmentDiscount: 'Avenant avec champs de fusion de remise de prix',
    Txt_Placeholder_documentList: 'Liste des documents',
    Txt_Placeholder_documentListLMNP: 'Liste des documents LMNP',
    Txt_Placeholder_customerFile: 'Fiche client',
    Txt_Placeholder_operatingModeAR24: 'Mode opératoire AR24',

    Txt_Placeholder_AssociateManager: 'Manager associé',
    Txt_Placeholder_AffiliateParent: 'Affilier un {0} {1}',

    Txt_Placeholder_Type: 'Type',
    Txt_Placeholder_Profile: 'Profil',
    Txt_Placeholder_Role: 'Rôle',
    Txt_Placeholder_Has_Department: 'Affecter des départements',
    Txt_Placeholder_Has_BO: 'Affecter des BO',
    Txt_Placeholder_Nb_Options_Max: "Nombre d'options autorisées",
    Txt_Placeholder_LastNameAcquirer: 'Nom acquéreur',
    Txt_Placeholder_FirstNameAcquirer: 'Prénom acquéreur',
    Txt_Placeholder_EmailAcquirer: 'Email acquéreur',
    Txt_Placeholder_PhoneAcquirer: 'Téléphone acquéreur',
    Txt_Placeholder_AdvValorissimo: 'ADV Valorissimo',
    Txt_Placeholder_LastNameADV: 'Nom ADV',
    Txt_Placeholder_FirstNameADV: 'Prénom ADV',
    Txt_Placeholder_EmailADV: 'Email ADV',
    Txt_Placeholder_PhoneADV: 'Téléphone ADV',
    Txt_Placeholder_CompanyData: 'Données de la société',
    Txt_Placeholder_LoginAs: 'Connecté en tant que',

    /* RadioButton */
    Txt_RadioButton_Developer: 'Promoteur',
    Txt_RadioButton_Contractor: 'Prescripteur',
    Txt_RadioButton_Valorissimo: 'Valorissimo',
    Txt_RadioButton_France: 'France',
    Txt_RadioButton_Other: 'Autre',
    Txt_RadioButton_Yes: 'Oui',
    Txt_RadioButton_No: 'Non',
    Txt_RadioButton_Accept: 'Accepter',
    Txt_RadioButton_Refuse: 'Refuser',
    Txt_RadioButton_Hyper: 'Hyper',
    Txt_RadioButton_Super: 'Super',
    Txt_RadioButton_Simple: 'Simple',
    Txt_RadioButton_ValoAdmin: 'Administrateur',
    Txt_RadioButton_ValoBO: 'BO',
    Txt_RadioButton_ValoDeveloper: 'Développeur',
    Txt_RadioButton_ValoMarketer: 'Marketeur',
    Txt_RadioButton_ValoRPV: 'RPV',

    Txt_Title_RadioButton_PaternityAutomaticValidation: 'Validation directe des paternités',
    Txt_Title_RadioButton_OptionAutomaticValidation: 'Validation directe des options',

    /* RadioButton text from the DB */
    APPLICANT: 'Vous-même',
    LEGAL_REPRESENTATIVE: 'Représentant légal',
    MANDATE_SIGNATORY: 'Signataire du mandat',

    /* Program page */
    Txt_Page_Program_ProgramActableProgram: 'Programme actable',
    Txt_Page_Program_ProgramMinProfitability: 'Rentabilité : {0}',
    Txt_Page_Program_ProgramMinMaxProfitability: 'Rentabilité : de {0} à {1}',
    Txt_Page_Program_ProgramZone: 'Zone {0}',
    Txt_Page_Program_ProgramAvailableLot: 'lot libre',
    Txt_Page_Program_ProgramAvailableLots: 'lots libres',
    Txt_Page_Program_ProgramNotAvailableLot: 'lot indisponible',
    Txt_Page_Program_ProgramNotAvailableLots: 'lots indisponibles',
    Txt_Page_Program_ProgramOptionnedLot: 'lot optionné',
    Txt_Page_Program_ProgramOptionnedLots: 'lots optionnés',
    Txt_Page_Program_ProgramRoomsNumber: 'de {0} à {1} pièces',
    Txt_Page_Program_ProgramPriceFrom: 'à partir de {0}',
    Txt_Page_Program_ProgramFeesFrom: "Taux d'honoraires : à partir de {0}",
    Txt_Page_Program_GroundFloor: 'RDC',
    Txt_Page_Program_VAT: 'TVA',
    Txt_Page_Program_SummaryLotAccordion: '{0} {1} de {2} {3}',
    Txt_Page_Program_SummaryLotAccordion_v2: '{0} {1} {2} {3}',
    Txt_Page_Program_SummaryLot: '{0} {1}, {2} {3} et {4} {5}',
    Txt_Page_Program_Room: 'Pièce',
    Txt_Page_Program_Rooms: 'Pièces',
    Txt_Page_Program_AvailableLot: 'disponible',
    Txt_Page_Program_AvailableLots: 'disponibles',
    Txt_Page_Program_LegalNoticeVAT: `TVA à taux réduit sous réserve que les conditions de l'article 278 sexies 11° du
      Code Général des Impôts soient remplies<br />(résidence principale de l'acquéreur - plafonds de ressources du réservataire à respecter).<br />
      Renseignements et conditions disponibles sur simple demande.`,
    Txt_Page_Program_IncludedArea: '(Annexes Incluses)',
    Txt_Page_Program_RpvCard_FirstPublication: 'Première publication :',
    Txt_Page_Program_RpvCard_DeveloperUpdate: 'Date de mise à jour :',
    Txt_Page_Program_ManagerCard_AssociatedManager: 'Responsable associé :',
    Txt_Page_Program_ListLotNumber: 'Lot n°',
    Txt_Page_Program_ListLotType: 'Type de lot',
    Txt_Page_Program_ListRoomNumber: 'Nombre de pièces',
    Txt_Page_Program_ListLivingSpace: 'Surface',
    Txt_Page_Program_ListFloor: 'Etage',
    Txt_Page_Program_ListOrientation: 'Orientation',
    Txt_Page_Program_ListArea: 'Annexes',
    Txt_Page_Program_ListTotalSellingPriceIT: 'Prix TTC',
    Txt_Page_Program_ListReducedTotalSellingPriceIT: 'Prix - TVA réduite',
    Txt_Page_Program_ListFees: 'Honoraires',
    Txt_Page_Program_ValoFees: 'Honoraires : selon prescripteur',
    Txt_Page_Program_ListDeliveryDate: 'Livraison',
    Txt_Page_Program_ListTaxationList: 'Fiscalité',
    Txt_Page_Program_ListEstimatedMonthlyRentingPrice: 'Loyer marché estimé *',
    Txt_Page_Program_ListEstimatedProfitability: 'Rentabilité marché estimée *',
    Txt_Page_Program_ListEstimatedFurnishedMarketRent: 'Loyer meublé marché estimé *',
    Txt_Page_Program_ListEstimatedFurnishedMarketYield: 'Rentabilité meublé marché estimée *',
    Txt_Page_Program_ListPinelMonthlyRentingPrice: 'Loyer plafond Pinel *',
    Txt_Page_Program_ListPinelPlusMonthlyRentingPrice: 'Loyer plafond Pinel Plus *',
    Txt_Page_Program_ListPinelProfitability: 'Rentabilité Pinel * ',
    Txt_Page_Program_ListPinelPlusProfitability: 'Rentabilité Pinel Plus * ',
    Txt_Page_Program_ListPlsMonthlyRentingPrice: 'Loyer plafond PLS *',
    Txt_Page_Program_ListPlsProfitability: 'Rentabilité PLS * ',
    Txt_Page_Program_ListLmnpLmpNonGereProfitability: 'Rentabilité LMNP/LMP non géré * ',
    Txt_Page_Program_ListLmnpLmpNonGereMonthlyRentingPrice: 'Loyer marché estimé LMNP/LMP non géré *',
    Txt_Page_Program_ListGuaranteedMonthlyRentingPriceIT: 'Loyer mensuel garanti TTC',
    Txt_Page_Program_ListEstatePriceET: 'Prix foncier HT',
    Txt_Page_Program_ListEstimatedWorkPrice: 'Prix travaux',
    Txt_Page_Program_ListEstimatedLandPrice: 'Prix foncier',
    Txt_Page_Program_ListEstimatedTotalPrice: 'Prix de vente total TTC',
    Txt_Page_Program_ListHousingPriceET: 'Prix immo HT ',
    Txt_Page_Program_ListGlobalSellingPriceET: 'Prix de vente total HT (Hors mobilier)',
    Txt_Page_Program_ListTangiblePriceET: 'Prix mobilier HT',
    Txt_Page_Program_ListSellingFees: 'Frais de vente (estimation)',
    Txt_Page_Program_ListLoanFees: 'Frais de prêt (estimation)',
    Txt_Page_Program_ListAttorneyFees: 'Frais de procuration (estimation)',
    Txt_Page_Program_NueProprietePrice: 'Prix nue propriété',
    Txt_Page_Program_UsufruitValue: 'Valeur usufruit',
    Txt_Page_Program_UsufruitValue_Undefined: 'Valeur usufruit *',
    Txt_Page_Program_DistributionKey: 'Clé de répartition',
    Txt_Page_Program_DistributionKey_Undefined: 'Clé de répartition *',
    Txt_Page_Program_NueProprieteDuration: 'Durée',
    Txt_Page_Program_NueProprieteDuration_Undefined: 'Durée *',
    Txt_Page_Program_NueProprieteInformations:
      '* Veuillez vous référer au descriptif du programme ou à votre interlocuteur Valorissimo afin d’obtenir cette information.',
    Txt_Page_Program_EstimatedFurnishedMarketRent: 'Loyer meublé marché estimé',
    Txt_Page_Program_EstimatedFurnishedMarketYield: 'Rentabilité meublé marché estimée',
    Txt_Page_Program_ListMonthlyRentingPriceET: 'Loyer mensuel HT',
    Txt_Page_Program_ListMonthlyRentalLotPriceIT: 'Loyer mensuel lot principal TTC (TVA : 10%)',
    Txt_Page_Program_ListMonthlyRentalSecondaryLotPriceIT: 'Loyer lot(s) secondaire(s) TTC (TVA : 20%)',
    Txt_Page_Program_ListLmnpLmpProfitabilityET: 'Rentabilité HT',
    Txt_Page_Program_ListInformationsLegalMention:
      '* Pour connaître le mode de calcul des estimations de loyer et rentabilité, \
    merci de consulter nos mentions légales.',
    Txt_Page_Program_ReducedTaxationLabelAndValue: '{0} TVA à {1} %',
    Txt_Page_Program_SecondaryLotDetailsSellingPrice: '{0} N°{1} (Valeur&nbsp;:&nbsp;{2}&nbsp;(TVA&nbsp;{3}))',
    Txt_Page_Program_SecondaryLotDetails: '{0} N°{1}',
    Txt_Page_Program_VATPercentage: 'TVA à {0}',
    Txt_Page_Lot_Details_LotType_LotNumber_DeliveryDate: 'Type de lot n° du lot / Livraison',
    Txt_Page_Lot_Details_Rooms_LivingSpace: 'Nombre de pièces / Surface',
    Txt_Page_Lot_Details_Floor_Orientation: 'Étage / Orientation',
    Txt_Page_Lot_Details_SecondaryLots: 'Annexes',
    Txt_Page_Lot_Details_Price: 'Prix',
    Txt_Page_Lot_Details_ReducedTotalSellingPriceIT: 'Prix - TVA réduite',
    Txt_Page_Lot_Details_Fees: 'Honoraires',
    Txt_Page_Lot_Details_Fees_info: 'Pour information',
    Txt_Page_Lot_Details_TaxationList: 'Fiscalité(s)',
    Txt_Page_Lot_Details_PinelRentingPrice: 'Loyer plafond Pinel',
    Txt_Page_Lot_Details_PinelPlusRentingPrice: 'Loyer plafond Pinel Plus',
    Txt_Page_Lot_Details_PinelProfitability: 'Rentabilité Pinel',
    Txt_Page_Lot_Details_PinelPlusProfitability: 'Rentabilité Pinel Plus',
    Txt_Page_Lot_Details_PlsRentingPrice: 'Loyer plafond PLS',
    Txt_Page_Lot_Details_PlsProfitability: 'Rentabilité PLS',
    Txt_Page_Lot_Details_LmnpLmpNonGereProfitability: 'Rentabilité LMNP/LMP non géré',
    Txt_Page_Lot_Details_LmnpLmpNonGereRentingPrice: 'Loyer marché LMNP/LMP géré',
    Txt_Page_Lot_Details_GuaranteedMonthlyRentingPriceIT: 'Loyer mensuel garanti TTC',
    Txt_Page_Lot_Details_EstatePriceET: 'Prix foncier HT',
    Txt_Page_Lot_Details_EstimatedWorkPrice: 'Prix travaux',
    Txt_Page_Lot_Details_EstimatedLandPrice: 'Prix foncier',
    Txt_Page_Lot_Details_EstimatedTotalPrice: 'Prix de vente total TTC',
    Txt_Page_Lot_Details_HousingPriceET: 'Prix immo HT',
    Txt_Page_Lot_Details_GlobalSellingPriceET: 'Prix de vente total HT (Hors mobilier)',
    Txt_Page_Lot_Details_TangiblePriceET: 'Prix mobilier HT',
    Txt_Page_Lot_Details_SellingFees: 'Frais de vente (estimation)',
    Txt_Page_Lot_Details_LoanFees: 'Frais de prêt (estimation)',
    Txt_Page_Lot_Details_AttorneyFees: 'Frais de procuration (estimation)',
    Txt_Page_Lot_Details_MonthlyRentingPriceET: 'Loyer mensuel HT',
    Txt_Page_Lot_Details_MonthlyRentalLotPriceIT: 'Loyer mensuel lot principal TTC (TVA : 10%)',
    Txt_Page_Lot_Details_MonthlyRentalSecondaryLotPriceIT: 'Loyer lot(s) secondaire(s) TTC (TVA : 20%)',
    Txt_Page_Lot_Details_LmnpLmpProfitability: 'Rentabilité HT',

    ProgramTypeApartment: 'Appartement',
    ProgramTypeHouse: 'Maison',
    ProgramTypeMixed: 'Mixte',
    ProgramTypeTrade: 'Local commercial',
    Txt_Program_Form_Summary_Name: 'Opération Commerciale : {0}',
    Txt_Program_Form_Summary_Address: `Adresse de l'opération : {0}`,
    Txt_Program_Form_Summary_Nb_Lots: 'Nombre de lots : {0}',
    Txt_Program_Form_Summary_Delivery_Date: 'Livraison : {0}',
    TxT_Program_Form_Valid_Submission: 'Votre opération commerciale a bien été soumise pour validation à nos services',
    TxT_Program_Form_Administrator_Profil:
      "L'entreprise est-elle est organisée sur un niveau hiérarchique (super) \
    ou deux niveaux hiérarchiques (hyper) ?",
    Txt_Program_Form_Reference_Special_Offer: "Référence de l'offre spéciale : ",
    Txt_Program_Form_Special_Offer_Label: "Libellé de l'offre spéciale : ",
    Txt_Program_Form_Special_Offer_Description: "Texte descriptif de l'offre spéciale : ",
    Txt_Program_Form_Legal_Notice_Special_Offer: "Texte mentions légales de l'offre spéciale : ",
    Txt_Program_Form_Start_Date_Month_Special_Offer: "Date de début de l'offre spéciale : ",
    Txt_Program_Form_End_Date_Month_Special_Offer: "Date de fin de l'offre spéciale : ",
    Txt_Program_Form_Amendment_Special_Offer: "Avenant de l'offre spéciale : ",
    Txt_Program_Form_No_Lots_Special_Offer: "Nbre de lots associés à l'offre spéciale : ",

    /* Select */
    Txt_Select_Form_Table_And_Other: '(+ {0} autre)',
    Txt_Select_Form_Table_And_Other_Plural: '(+ {0} autres)',
    Txt_Select_Form_Table_All: 'Toutes',

    // ADD CATEGORY TO CONFLUENCE
    Success_SnackBar_PasswordChanged: 'Changement du mot de passe réalisé avec succès',
    Success_SnackBar_Lot_Deleted: 'Le lot a bien été supprimé',
    Success_SnackBar_AccountInValidation: "La demande a été enregistrée et transmise pour validation à l'équipe Valorissimo.",
    Success_SnackBar_ValidatedAccount: 'Compte validé',
    Success_SnackBar_UpdatedAccount: 'Compte modifié',
    Success_SnackBar_UpdatedEmailAccount:
      "L'adresse mail de l'utilisateur a été modifiée. Son identifiant pour se connecter au site \
      Valorissimo est désormais : {0}.",
    Success_SnackBar_MandateStrategyDeleted: 'La stratégie a bien été supprimée.',
    Success_SnackBar_MandateStrategyCreated: 'La stratégie mandat a bien été créée.',
    Success_SnackBar_MandateStrategyModified: 'La stratégie mandat a bien été mise à jour.',
    Success_SnackBar_ProgramValidated: 'Programme validé',
    Success_SnackBar_ProgramPublished: 'Le programme a bien été publié',
    Success_SnackBar_ProgramPlanning: 'La planification de la publication a bien été prise en compte',
    Success_SnackBar_ProgramModified: 'Votre opération commerciale a bien été mise à jour',
    Success_SnackBar_ReservationCanceled: 'La pré-réservation a été annulée.',
    Success_SnackBar_AlertTaken: "L'alerte sur ce lot a été créée.",
    Success_SnackBar_LoginAs: 'Vous êtes maintenant connecté en tant que {0} {1} ( {2} ).',
    Success_SnackBar_LogoutAs: 'Vous avez bien quitté le mode de connexion en tant que.',
    Success_SnackBar_SpecialOfferCreated: `L'offre spéciale été créée avec succès`,
    Success_SnackBar_SpecialOfferUpdated: `L'offre spéciale a été modifiée avec succès`,

    Error_SnackBar_Lot_Deleted: "Une erreur s'est produite lors de la suppression",

    LogPage_Company_Register_Paragraph_Suite: "L'équipe Valorissimo étudiera votre demande dans les meilleurs délais",
    LogPage_Developer_Contact: 'Contact Promoteurs',
    LogPage_Contractor_Contact: 'Contact Prescripteurs',

    Header_TrackingTable: 'Tableaux de suivi',
    Header_TrackingTableOptions: 'Mes Options',
    Header_TrackingTableProspects: 'Mes Prospects',
    Header_TrackingTablePreBookings: 'Mes pré-réservations',
    Header_TrackingTableBookings: 'Mes réservations',
    Header_TrackingTableAlerts: 'Mes alertes',
    Header_TrackingTablePrograms: 'Mes programmes',
    Header_TrackingTableContributors: 'Mes collaborateurs',
    Header_TrackingTableAccountsManagement: 'Gestion des comptes',
    Header_TrackingTableCompanies: 'Sociétés',
    Header_TrackingTableClubHandler: 'Gestion Club',
    Header_TrackingTableProgramsSales: 'Suivi des programmes',

    Footer_Link_QuiSommeNous: 'Annexe décret de Montgolfier',
    Footer_Link_MentionsLegales: 'Mentions légales',
    Footer_Title_DeveloperContact: 'Vous êtes promoteur ?',
    Footer_Title_ContractorContact: 'Vous êtes prescripteur ?',
    Footer_Title_ContactCommercial: 'Contact COMMERCIAL',
    Footer_Title_ContactMandat: 'Contact MANDAT',
    Footer_Title_ContactFacturation: 'Contact FACTURATION/RÈGLEMENTS',

    Title_MenuAccount: 'Compte',
    Title_MenuAccountPreferences: 'Préférences',
    Title_MenuAccountDisconnect: 'Déconnexion',
    Title_MenuAccountVersion: 'Version: {0}',
    Title_MenuAccountConnect: 'CONNEXION',
    Title_MenuExternalHelpCenter: 'Centre d’aide',
    Title_MenuAdmin: 'Administration',
    Title_MenuRecommendations: 'À ne pas manquer !',
    Title_MenuAdminUsers: 'Utilisateurs',
    Title_MenuAdminCompanies: 'Sociétés',
    Title_MenuAdminPrograms: 'Programmes',
    Title_MenuAdminSpecialOffers: 'Offres Spéciales',
    Title_MenuDashboard: 'Tableaux de bord',
    Title_MenuDashboardOptions: 'Options',
    Title_MenuDashboardPreReservations: 'Pré-réservations',
    Title_MenuDashboardDossier: 'Dossiers de signature',
    Title_MenuDashboardReservations: 'Réservations',
    Title_MenuDashboardProspects: 'Prospects',
    Title_MenuDashboardPrograms: 'Programmes',
    Title_MenuDashboardSalers: 'Conseiller',
    Title_MenuDashboardSavedSearches: 'Recherches sauvegardées',
    Title_MenuDossierProspect: 'Dossiers prospect',
    Title_MenuDocuments: 'Documents',
    Title_MenuUtilsDocuments: 'Documents utiles',
    Title_MenuBeginDocuments: 'Débuter sur Valorissimo',
    Title_MenuFaqDocuments: 'Questions fréquentes',
    Title_MenuAnnounceDocuments: 'Annonces',
    Title_MenuCommercial: 'Commercial',
    Title_MenuMandat: 'Mandat',
    Title_MenuContacts: 'Contacts',
    Title_Other_Head_Menu: 'Autres',
    Title_Header_Add_Paternity: 'Déclarer une paternité',
    Title_MenuDeclareProspect: 'Créer un dossier prospect',
    Title_MenuSimulation: 'Faire une simulation',

    // Program types
    Txt_Table_Column_Typo_MIXED: 'MIXED',
    Txt_Table_Column_Typo_HOUSE: 'MI',
    Txt_Table_Column_Typo_APARTMENT: 'APPT',
    Txt_Table_Column_Typo_TRADE: 'LCOM',

    Txt_Table_Actions: 'Actions',
    Txt_Table_Action_s: 'Action(s)',
    Txt_Table_Date_Creation: 'Date de création',
    Txt_Table_Label: 'Libellé',
    Txt_Table_IsConsumed: 'Activité traitée',

    // Table lot column names
    Txt_Table_Column_Number: 'Numéro',
    Txt_Table_Column_LotNumber: 'N° de lot',
    Txt_Table_Column_Typo: 'Type',
    Txt_Table_Column_Rooms: 'Pièces',
    Txt_Table_Column_LivingSpace: 'Surface',
    Txt_Table_Column_LivingSpaceFloor: 'Surface / Étage',
    Txt_Table_Column_Plan: 'Plan',
    Txt_Table_Column_TotalSellingPriceIT: 'Prix TTC',
    Txt_Table_Column_ReducedTotalSellingPriceIT: 'Prix TVA réduite',
    Txt_Table_Column_CommercialLotSellingPriceIT: 'Prix TTC',
    Txt_Table_Column_ReducedSellingPriceIT: 'Prix TVA réduite',
    Txt_Table_Column_ProfitabilityET: 'Rentabilité',
    Txt_Table_Column_Status: 'Statut',
    Txt_Table_Column_IsPublished: 'Publié',
    Txt_Table_Column_Delivery: 'Date de livraison',
    Txt_Table_Column_Floor: 'Etage',
    Txt_Table_Column_Actions: 'Actions',
    Txt_Table_Column_FloorOrientation: 'Étage / Orient.',
    Txt_Table_Column_FloorOrientation_1: 'Étage /',
    Txt_Table_Column_FloorOrientation_2: 'Orient.',
    Txt_Table_Column_Lot: 'Lot',
    Txt_Table_Column_Annexes: 'Annexes',
    Txt_Table_Column_Taxations: 'Fiscalités',
    Txt_Table_Column_StrongPoints: 'Points forts',
    Txt_Table_Column_Comparator: 'Comparer',
    Txt_Table_Column_ReducedTaxation: 'TVA réduite',
    Txt_Table_Column_Program: 'Programme',
    Txt_Table_Column_PriceIT: 'Prix TTC',
    Txt_Table_Column_ProfitabilityRentingPrice: 'Rentabilité / Loyer nu',
    Txt_Table_Column_LoyerMeubleRentingPrice: 'Rentabilité / Loyer meublé',
    Txt_Table_Column_Fees: 'Honoraires',
    Txt_Table_Column_Siret: 'Siret',
    Txt_Table_Column_IsComplete: 'Complet',
    Txt_Table_Column_SpecialOfferConflict: 'Offre en conflit',
    Txt_Table_Column_EstimatedWorkPrice: 'Prix travaux',

    Txt_Table_Column_CorporateName: 'Raison Sociale',
    Txt_Table_Column_GroupCompany: 'Entité',
    Txt_Table_Column_RCV: 'RPV',
    Txt_Table_Column_Responsible: 'Responsable',
    Txt_Table_Column_City: 'Ville',

    /* Prospect Column names */
    Txt_Table_Column_Prospect: 'Prospect',
    Txt_Table_Column_PaternityDateCreated: 'Date de déclaration du prospect',
    Txt_Table_Column_PaternityDateEnd: 'Date de fin de paternité',
    Txt_Table_Column_PaternityDateValidated: "Date de retour d'acceptation de la paternité",
    Txt_Table_Column_PaternityStatus: 'Statut de la paternité',
    Txt_ProspectTable_City: 'Ville (CP)',
    Txt_ProspectTable_Email: 'Adresse mail',
    Txt_ProspectTable_RPV: 'Responsable commercial',
    Txt_ProspectTable_RPV_PARTNER: 'Responsable commercial',

    Txt_DossierProspectPhaseStep1: 'ETAPE 1',
    Txt_DossierProspectPhaseStep2: 'ETAPE 2',
    Txt_DossierProspectPhaseStep3: 'ETAPE 3',
    Txt_DossierProspectPhaseStep4: 'ETAPE 4',
    Txt_DossierProspectPhaseDecouverte: 'Découverte',
    Txt_DossierProspectPhaseIdentification: 'Identification',
    Txt_DossierProspectPhaseClosing: 'Closing',
    Txt_DossierProspectPhaseFacturation: 'Facturation',
    Txt_DossierProspectBientotDisponible: 'Bientot disponible',
    Txt_DossierProspectEnCours: 'En cours',
    Txt_DossierProspectValide: 'Validé',

    Txt_DossierProspectNotePourDossier: 'Note pour le dossier',
    Txt_DossierProspectNotePlaceholder: 'Exemple : SCI en cours de création, ...',

    Txt_DossierProspectNotComplete: 'Dossier incomplet',
    Txt_DossierProspectNotCompleteDetail:
      'Des informations relatives à votre prospect sont manquantes. Veuillez les compléter afin de profiter de l’ensemble des fonctionnalités du dossier.',
    Txt_DossierProspectNotCompleteButton: 'Compléter le dossier',

    Txt_DossierProspectComplete: 'Dossier complet',
    Txt_DossierProspectCompleteDetail: 'Félicitations, vous avez collecté toutes les informations de votre prospect !',
    Txt_DossierProspectCompleteButton: 'Modifier le dossier',

    Txt_DossierProspectSimulation: 'Simulation de prêt immo',
    Txt_DossierProspectSimulationNoSimulation: `Vous n'avez pas encore calculé la capacité d'achat de votre (vos) prospect(s). Utilisez notre calculateur pour le faire`,
    Txt_DossierProspectSimulationPurchasingCapacity: `Capacité d'achat`,
    Txt_DossierProspectSimulationLoanCapacity: `Capacité d'emprunt`,
    Txt_DossierProspectSimulationPersonalFunds: `Apport`,
    Txt_DossierProspectSimulationButton: 'Simuler un emprunt',
    Txt_DossierProspectDetailSimulationButton: 'Accéder au détail de la simulation',

    Txt_DossierProspectOptionTitleNoTransaction: 'Aucune transaction en cours',
    Txt_DossierProspectOptionTitlePending: 'Option en cours',
    Txt_DossierProspectOptionTitleAccepted: 'Option validée',
    Txt_DossierProspectOptionLotNumber: 'N° lot : {0}',
    Txt_DossierProspectOptionTextPending:
      'Votre option est <B>en attente de validation</B>. Cliquez sur le lien ci-dessous pour accéder au détail de la transaction.',
    Txt_DossierProspectOptionTextAccepted: 'Votre option est <B>validée</B>. Cliquez sur le lien ci-dessous pour pré-réserver le lot.',
    Txt_DossierProspectOptionTextAlmostExpired:
      'Votre option <B>expire bientôt</B>. Cliquez sur le lien ci-dessous pour pré-réserver le lot ou pour prolonger votre option.',
    Txt_DossierProspectOptionTextNoTransaction:
      'Vous n’avez aucune transaction en cours pour ce dossier prospect. Cliquez sur "Voir toutes les transactions" pour accéder à l’historique des transactions rattachées à ce dossier, ou "Rechercher un lot" pour démarrer une recherche et une nouvelle transaction.',
    Txt_DossierProspectButtonOptionSeeTransaction: 'Voir la transaction',
    Txt_DossierProspectButtonOptionPreReservation: 'Pré-réserver',
    Txt_DossierProspectButtonOptionExtend: 'Prolonger',
    Txt_DossierProspectButtonOptionSeeAllTransactions: 'Voir toutes les transactions',
    Txt_DossierProspectButtonOptionSearchLot: 'Rechercher un lot',
    Txt_DossierProspectOptionSubTitleOption: 'Option(s)',
    Txt_DossierProspectOptionSubTitlePreReservation: 'Pré-réservation(s)',
    Txt_DossierProspectOptionSubTitleReservation: 'Réservation(s)',
    Txt_DossierProspectOptionRefused: 'Refusée',
    Txt_DossierProspectOptionExpired: 'Expirée',
    Txt_DossierProspectOptionCanceled: 'Annulée',
    Txt_DossierProspectOptionWithdrawn: 'Désistée',
    Txt_DossierProspectOptionTimeExpire: 'Expire dans',

    Txt_DossierProspectSearch: 'Critères de recherche',
    Txt_DossierProspectSearchDetail: 'Trouvez en quelques clics le lot qui correspond à votre client',
    Txt_DossierProspectDashboardNoSearchPreference: `Vous n'avez pas encore défini de critères de recherche pour votre (vos) prospect(s). Cliquez sur "Modifier mes critères de recherche" pour le faire`,
    Txt_DossierProspectButtonSetSearchPreferences: 'Définir mes critères de recherche',
    Txt_DossierProspectSearchButton: 'Rechercher un lot',
    Txt_DossierProspectButtonEditSearchPreferences: 'Modifier mes critères de recherche',
    min_price: 'Min: {0}',
    max_price: 'Max: {0}',
    at_least: 'Au moins {0}',
    from_to: '{0} à {1}',
    nb_rooms: '{0} pièces',
    nb_floors: '{0} étages',
    value_more_than: 'A partir de {0}',
    value_from_to: 'De {0} à {1}',
    value_less_than: "Jusqu'a {0}",
    Txt_TransactionSearchButtonWithCriteria: 'Rechercher un lot',
    Txt_DossierProspectNoTransaction: `Aucune transaction n'est ratachée à ce dossier.`,
    Txt_DossierProspectOptionMessage: 'Optionnez un lot en le rattachant à ce dossier pour créer votre première transaction',
    Txt_DossierProspectSearchButtonWithCriteria: 'Rechercher un lot avec les critères du prospect',
    Txt_DossierProspectSearchButtonWithoutCriteria: 'Lancer une recherche sans critère',
    Txt_DossierProspectNoLot: `Aucun lot ne correspond à vos critères de recherche.`,
    Txt_DossierProspectUpdateSearchPreference: "Veuillez élargir votre recherche afin d'obtenir plus de résultats",
    Txt_DossierProspectLotLivraison: 'Livraison',
    Txt_Available_Lot: 'Libre',
    Txt_Create_Option: 'Optionner',
    Txt_See_All_Lots: 'Voir plus de lots',
    Txt_Lots: 'Lots',
    Txt_Pieces: 'Pièces',
    Txt_Surface: 'Surf.',
    Txt_Etage_Orientation: 'Etage / Orient.',
    Txt_Annexes: 'Annexes',
    Txt_Fiscalites: 'Fiscalités',
    Txt_Prix: 'Prix',
    Txt_Actions: 'Actions',

    /* Mandate Strategies Recap Column names */
    Txt_MandateStrategies_Column_Global: '',
    Txt_MandateStrategies_Column_Name: 'Nom',
    Txt_MandateStrategies_Column_NbLots: 'Nbre de lots',
    Txt_MandateStrategies_Column_NbContractors: 'Nbre de prescripteurs',
    Txt_MandateStrategies_Column_StartDate: 'Date début',
    Txt_MandateStrategies_Column_EndDate: 'Date fin',
    Txt_MandateStrategies_Column_Rate: 'Taux',

    Txt_MandateStrategies_Type_Title: 'Type de mandat stratégie',
    Txt_MandateStrategies_SpecifiqueType: 'Spécifique',
    Txt_MandateStrategies_GlobalType: 'Globale',

    /* Contractor tracking table names */
    Txt_ContractorTrackingTable_Column_TCardEnd: 'Fin de la carte T',
    Txt_ContractorTrackingTable_Column_MandateEnd: 'Fin de mandat',
    Txt_ContractorTrackingTable_Column_Active: 'Actif',
    Txt_ContractorTrackingTable_Column_ValoResponsible: 'Responsable VALORISSIMO',
    Txt_ContractorTrackingTable_Column_Siret: 'Siret',

    /* Accounts tracking table names */
    Txt_AccountsTrackingTable_Column_CorporateName: 'Raison sociale',
    Txt_AccountsTrackingTable_Column_Network: 'Réseau',
    Txt_AccountsTrackingTable_Column_TradingName: 'Nom commercial',
    Txt_AccountsTrackingTable_Column_Name: 'Nom Prénom',
    Txt_AccountsTrackingTable_Column_CityPostalCode: 'Ville (Code postal)',
    Txt_AccountsTrackingTable_Column_Type: 'Type',
    Txt_AccountsTrackingTable_Column_Profil: 'Profil',
    Txt_AccountsTrackingTable_Column_Manager: 'Manager associé',
    Txt_AccountsTrackingTable_Column_BiResponsible: 'Responsable BI',
    Txt_AccountsTrackingTable_Column_ValoResponsible: 'Responsable VALORISSIMO',
    Txt_AccountsTrackingTable_Column_Responsible: 'Responsable PS',
    Txt_AccountsTrackingTable_Column_NumberOptions: 'Nombre options simultanées',
    Txt_AccountsTrackingTable_Column_Active: 'Actif',
    Txt_AccountsTrackingTable_Column_Status: 'Statut',
    Txt_AccountsTrackingTable_Column_Department: 'Départements',
    Txt_AccountsTrackingTable_Column_Bo: 'BO associé',
    Txt_AccountsTrackingTable_Column_Account: 'Collaborateur',
    Txt_AccountsTrackingTable_Column_FunctionType: 'Fonction',
    Txt_AccountsTrackingTable_Column_AssociateManager: 'Manager associé',
    Txt_AccountsTrackingTable_Column_Actions: 'Action(s)',
    Txt_AccountsTrackingTable_Column_Siret: 'Siret',
    Txt_AccountsTrackingTable_Column_Phone: 'Téléphone',
    Txt_AccountsTrackingTable_Column_Email: 'Email',

    /* Programs sales tracking table names */
    Title_ProgramsTrackingTable_Sales: 'Suivi des programmes',
    Txt_ProgramsTrackingTable_Column_CompanyName: 'Nom commercial de la société',
    Txt_ProgramsTrackingTable_Column_ProgramName: 'Nom programme',
    Txt_ProgramsTrackingTable_Column_Location: 'Localisation',
    Txt_ProgramsTrackingTable_Column_Prospects: 'Nombre de prospects déclarés',
    Txt_ProgramsTrackingTable_Column_InProgressOptions: "Nombre d'options en cours",
    Txt_ProgramsTrackingTable_Column_PosedOptions: "Nombre d'options posées depuis la commercialisation",
    Txt_ProgramsTrackingTable_Column_InProgressPreReservations: 'Nombre de pré-réservations en cours',
    Txt_ProgramsTrackingTable_Column_InProgressReservations: 'Nombre de réservations en cours',
    Txt_ProgramsTrackingTable_Column_Sales: 'Nombre de ventes',
    Txt_ProgramsTrackingTable_Column_StoredLots: 'Nombre de lots restant en stock',
    Txt_ProgramsTrackingTable_Column_FeesSales: "Montant d'honoraires générés par les ventes",
    Txt_ProgramsTrackingTable_Column_NbReservations: 'Nombre de réservations en cours',
    Txt_ProgramsTrackingTable_Column_NbSales: 'Nombre de ventes',
    Txt_No_Programs_Available: "Vous n'avez pas de programmes disponibles actuellement.",

    /* Program tracking Table names */
    Txt_ProgramsTrackingTable_No_Result: "Vous n'avez pas de programmes disponibles actuellement.",
    Txt_ProgramsTrackingTable_CompanyTradingName: 'Promoteur',
    Txt_ProgramsTrackingTable_ProgramName: 'Programme',
    Txt_ProgramsTrackingTable_LotsPublishedOnLotsStock: 'Lot publié / Lot en stock',
    Txt_ProgramsTrackingTable_OptionedLots: 'Lot optionné',
    Txt_ProgramsTrackingTable_DeliveryDate: 'Livraison',
    Txt_ProgramsTrackingTable_Actability: 'Actabilité',
    Txt_ProgramsTrackingTable_CityPostalCode: 'Ville (Code postal)',
    Txt_ProgramsTrackingTable_ProgramStatus: 'Statut',
    Txt_ProgramTrackingTable_IsFromFlux: 'Flux XML',
    Txt_ProgramTrackingTable_sheduledDate: 'Date de publication',
    Txt_ProgramsTrackingTable_Actions: 'Action(s)',
    Txt_Program_Status_Draft: 'Brouillon',
    Txt_Program_Status_Pending: 'En attente de validation',
    Txt_Program_Status_Valid: 'Validé',
    Txt_Program_Status_Incomplete: 'Incomplet',
    Txt_Program_Status_Denied: 'Rejeté',
    Txt_Program_Status_SCHEDULED: 'Programmé',
    Txt_Page_FastSearch_Location: 'Ville ou département ou région',
    Txt_Page_Prospect_Location: 'Saisissez un département ou une région',

    /* Special Offers tracking table names */
    Txt_SpecialOffersTrackingTable_No_Result: 'Aucune offre spéciale créée.',
    Txt_SpecialOffersTrackingTable_developer: 'Promoteur',
    Txt_SpecialOffersTrackingTable_reference: 'Référence',
    Txt_SpecialOffersTrackingTable_label: 'Libellé',
    Txt_SpecialOffersTrackingTable_startDate: 'Date de début',
    Txt_SpecialOffersTrackingTable_endDate: 'Date de fin',
    Txt_SpecialOffersTrackingTable_totalPrograms: 'Nombre de Programmes',
    Txt_SpecialOffersTrackingTable_totalLots: 'Nombre de lots',
    Txt_SpecialOffersTrackingTable_fromImport: 'Par Import',
    Txt_SpecialOffersTrackingTable_status: 'Statut',
    Txt_SpecialOffersTrackingTable_Actions: 'Action(s)',
    Txt_SpecialOffersTrackingTable_LotsPublishedOnLotsStock: 'Lot publié /<br>Lot en stock',

    /* Company Types */
    Txt_Company_Type_Developer: 'Promoteur',
    Txt_Company_Type_Contractor: 'Prescipteur',
    Txt_Company_Type_Valorissimo: 'Valorissimo',
    Txt_Company_Type_Independant: 'Réseau',

    /* Lot type */
    LOT_TYPE_APARTMENT: 'Appartement',
    LOT_TYPE_HOUSE: 'Maison',
    LOT_TYPE_MIXED: 'Mixte',
    LOT_TYPE_TRADE: 'Local commercial',

    Txt_Checkbox_Form_SearchForm_Floor_1: 'RDC',
    Txt_Checkbox_Form_SearchForm_Floor_2: '1',
    Txt_Checkbox_Form_SearchForm_Floor_3: '2',
    Txt_Checkbox_Form_SearchForm_Floor_4: '3',
    Txt_Checkbox_Form_SearchForm_Floor_GT5: '+4',

    Txt_SelectOption_Form_SearchForm_Delivery_Now: 'Immédiate',
    Txt_SelectOption_Form_SearchForm_Delivery_Date_Prefix: "Jusqu'à ",
    Txt_SelectOption_Form_SearchForm_Delivery_Current_Year: 'Année en cours',
    Txt_SelectOption_Form_SearchForm_Delivery_Next_Year: 'n+1',
    Txt_SelectOption_Form_SearchForm_Delivery_Next_Next_Year: 'n+2',
    Txt_SelectOption_Form_SearchForm_Delivery_LT6Months: '< 6 mois',
    Txt_SelectOption_Form_SearchForm_Delivery_LT12Months: '< 12 mois',
    Txt_SelectOption_Form_SearchForm_Delivery_LT24Months: '< 24 mois',

    Title_Form_SearchForm_Budget: 'Budget',
    Title_Form_SearchForm_Type: 'Type',
    Title_Form_SearchForm_DeveloperName: 'Promoteur',
    Title_Form_SearchForm_RoomNumber: 'Nombre de pièces',
    Title_Form_SearchForm_Outside: 'Extérieur',
    Title_Form_SearchForm_Floor: 'Étage',
    Title_Form_SearchForm_Orientation: 'Orientation',
    Title_Form_SearchForm_Annexes: 'Annexes',
    Title_Form_SearchForm_StrongPoints: 'Points forts',
    Title_Form_SearchForm_Storage_Annexes: 'Stockage',
    Title_Form_SearchForm_Parking_Annexes: 'Stationnement',
    Title_Form_SearchForm_Delivery_legacy: 'Livraison',
    Title_Form_SearchForm_Delivery: 'Date de livraison maximale',

    Title_Form_SearchForm_Taxation: 'Fiscalité',
    Title_Form_SearchForm_TaxZone: 'Zone fiscale',
    Title_Form_SearchForm_MinProfitability: 'Rentabilité minimale',
    Title_Form_SearchForm_Rent: 'Loyer Maximum',
    Title_Form_SearchForm_Actable: 'Actable',
    Title_Form_SearchForm_Fees: "Taux d'honoraires",
    Title_Form_SearchForm_SpecialOffer: 'Offre spéciale',
    Title_Form_SearchForm_HighStake: 'Programmes à enjeux',
    Title_Form_SearchForm_New: 'Nouveautés',

    Title_Form_Prospect_DeclareProspect: 'Déclarer un prospect',
    Title_Form_Prospect_ContactInformationOfProspect: 'Coordonnées du prospect',
    Title_Form_Prospect_AskForPaternity: 'Demander une paternité',

    Title_TrackingTable_ContractorCompanies: 'Société(s) prescriptrice(s)',
    Title_TrackingTable_IndependantCompanies: 'Réseaux d’indépendants',
    Title_TrackingTable_DeveloperCompanies: 'Société(s) promotrice(s)',
    Title_TrackingTable_Companies: 'Société(s)',
    Title_TrackingTable_ContractorCompaniesTab: 'Mes société(s) prescriptrice(s)',
    Title_TrackingTable_IndependantCompaniesTab: 'Mes réseaux d’indépendants',
    Title_TrackingTable_DeveloperCompaniesTab: 'Mes société(s) promotrice(s)',
    Title_TrackingTable_ContractorAccounts: 'Collaborateur(s) prescripteur(s)',
    Title_TrackingTable_DeveloperAccounts: 'Collaborateur(s) promoteur(s)',
    Title_TrackingTable_ValorissimoAccounts: 'Collaborateur(s) Valorissimo',
    Title_TrackingTable_IndependantAccounts: 'Collaborateur(s) Indépendants',
    Title_TrackingTable_ContractorAccountsTab: 'Mes compte(s) prescripteur(s)',
    Title_TrackingTable_DeveloperAccountsTab: 'Mes compte(s) promoteur(s)',
    Title_TrackingTable_ValorissimoAccountsTab: 'Mes compte(s) Valorissimo',
    Title_TrackingTable_IndependantAccountsTab: 'Mes compte(s) Indépendants',

    Title_TrackingTable_Column_ProspectFullName: 'Prospect',
    Title_TrackingTable_Column_ProgramDetails: 'Ville / Nom du programme / Promoteur',
    Title_TrackingTable_Column_StatusLabel: 'Statut',
    Title_TrackingTable_Column_CompanyTradingName: 'Société du demandeur',
    Title_TrackingTable_Column_GlobalSellingPrice: 'Prix du lot',
    Title_TrackingTable_Column_RcvFullname: 'RPV',
    Title_TrackingTable_Column_LotNumber: 'N° du lot',
    Title_TrackingTable_Column_LotNumber_Picto_Tooltip: 'Voir détail du lot',
    Title_TrackingTable_Column_AccountFullname: 'Demandeur',
    Title_TrackingTable_Column_CreatedDate: 'Date de demande',
    Title_TrackingTable_Column_Actions: 'Action(s)',
    Title_TrackingTable_Column_TakenBy: 'Posée par',
    Title_TrackingTable_Column_StartDate: "Date de début de l'option",
    Title_TrackingTable_Column_EndDate: "Date de fin de l'option",
    Title_OptionsTrackingTable: 'Mes options',
    Title_OptionsTrackingTable_Column_Client: 'Client',
    Title_OptionsTrackingTable_Column_ProgramDetails: 'Promoteur / Programme / Ville',
    Title_OptionsTrackingTable_Column_CompanyTradingName: 'Société',
    Title_OptionsTrackingTable_Column_LotNumber: 'N° du lot',
    Title_OptionsTrackingTable_Column_Price: 'Prix',

    Title_FinancialStrategyFeesAmountsTable_PreReservations: 'Lots Pré-réservés',
    Title_FinancialStrategyFeesAmountsTable_Reservations: 'Lots Réservés',
    Title_FinancialStrategyFeesAmountsTable_Sales: 'Lots Vendus',
    Title_FinancialStrategyFeesAmountsTable_Total: 'Total',

    // Tracking table dossier de signature
    Title_DossierTrackingTable: 'Dossier de signature',
    Txt_continueDossier: 'Reprendre ma signature',
    Txt_editDossier: 'Modifier',
    Txt_noEditDossier: 'Accéder au dossier',
    Txt_cancelDossier: 'Annuler',
    STATUS_DOSSIER_DRAFT: 'Saisie en cours',
    STATUS_DOSSIER_SENDING: 'En cours d’envoi ',
    STATUS_DOSSIER_SIGN_ACQ: 'Signature acquéreur',
    STATUS_DOSSIER_REJECT_ACQ: 'Refusé acquéreur',
    STATUS_DOSSIER_COUNTERSIGN: 'Contre-signature',
    STATUS_DOSSIER_COUNTERSIGNED: 'Contre-signé',
    STATUS_DOSSIER_REJECT_DEV: 'Refusé promoteur',
    STATUS_DOSSIER_ERROR: 'En erreur',
    STATUS_DOSSIER_CANCELLED: 'Annulé',
    STATUS_DOSSIER_SIGN_PAPER: 'Signature papier',
    STATUS_DOSSIER_EXPIRED: 'Expiré',
    Title_DossierTrackingTable_Column_CreationDate: 'Création',
    Title_DossierTrackingTable_Column_DeveloperName: 'Promoteur',
    Title_DossierTrackingTable_Column_Lot: 'Lot',
    Title_DossierTrackingTable_Column_Program: 'Programme',
    Title_DossierTrackingTable_Column_City: 'Ville',
    Title_DossierTrackingTable_Column_Acquirer: 'Acquéreurs',
    Title_DossierTrackingTable_Column_PrescriptorName: 'Partenaire',
    Title_DossierTrackingTable_Column_Status: 'Statut',
    Title_DossierTrackingTable_Column_Actions: 'Actions',
    Title_Cancel_Dossier_Signature_En_Cours: 'Annuler ce dossier de signature ?',
    Txt_Popin_Cancel_Dossier_Signature_En_Cours: "L'annulation du dossier de signature est définitive.",
    Txt_Button_Confirmed_Canceled: 'Confirmer et annuler',
    Txt_Button_Canceled: 'Fermer sans annuler',
    Success_SnackBar_SignatureDeleted: 'Le dossier de signature a été annulé avec succès',

    // Table Paginator
    Txt_Paginator_ItemsPerPageLabel: 'Éléments par page :',
    Txt_Paginator_FirstPageLabel: 'Première page',
    Txt_Paginator_PreviousPageLabel: 'Page précédente',
    Txt_Paginator_NextPageLabel: 'Page suivante',
    Txt_Paginator_LastPageLabel: 'Dernière page',
    Txt_Paginator_RangeLabel: 'sur',

    Txt_Table_SquareMeter: 'm²',
    Txt_Table_Empty: 'Aucune donnée disponible actuellement.',
    Txt_Table_EmptyLots: 'Aucun lot disponible actuellement.',
    Txt_Table_EmptyCompanies: 'Aucune société disponible actuellement.',
    Txt_Table_EmptyAccounts: "Vous n'avez pas de collaborateur actuellement.",
    Txt_Table_EmptyPrograms: `Vous n'avez pas de programmes valides disponibles actuellement`,
    Txt_Table_LotNumber: 'N° lot :',
    Txt_Table_FreeLot: 'Ce lot est libre',
    Txt_Table_OptionedLot: 'Ce lot est optionné',
    Txt_Table_UnavailableLot: 'Indisponible',
    Txt_Table_ReducedVATConditions:
      "TVA à taux réduit sous réserve que les conditions de l'article 278 sexies 11° du Code Général des \
      Impôts soient remplies (résidence principale de l'acquéreur - plafonds de ressources du réservataire à respecter). Renseignements \
      et conditions disponibles sur simple demande.",
    Txt_Table_SpecialOffer: 'Offre spéciale',
    Txt_Table_HighStakeProgram: 'Programme à enjeu',

    Txt_Page_SearchPrograms_New: 'Nouveau',
    Txt_Page_SearchPrograms_From: 'De',
    Txt_Page_SearchPrograms_AvailableLot: '{0} lot en stock',
    Txt_Page_SearchPrograms_AvailableLots: '{0} lots en stock',
    Txt_Page_SearchPrograms_Delivery: 'Livraison',
    Txt_Page_SearchPrograms_Rooms: '{0} à {1} pièces',
    Txt_Page_SearchPrograms_Rooms_Once: '{0} pièces',
    Txt_Page_SearchPrograms_Price: 'à partir de',
    Txt_Page_SearchResults_Program: 'programme',
    Txt_Page_SearchResults_Programs: 'programmes',
    Txt_Page_SearchResults_Lot: 'lot',
    Txt_Page_SearchResults_Lots: 'lots',
    Txt_Page_SearchResults_Prefix: ' correspondent à votre recherche',
    Txt_SortBy: 'Trier par',
    Txt_SortBy_Price_ASC: 'Prix croissant',
    Txt_SortBy_Price_DESC: 'Prix décroissant',
    Txt_SortBy_City_ASC: 'Ville croissante',
    Txt_SortBy_City_DESC: 'Ville décroissante',
    Txt_SortBy_DeliveryDate_ASC: 'Date de livraison croissante',
    Txt_SortBy_DeliveryDate_DESC: 'Date de livraison décroissante',
    Txt_SortBy_PublishedDate_DESC: 'Nouveaux programmes',

    Title_PreReservationsTrackingTable: 'Pré-réservation(s)',

    Txt_SlideToggle_SearchResults_Program: 'Programme',
    Txt_SlideToggle_SearchResults_Lot: 'Lot',

    Txt_SpanTitle_SeePlan: 'Voir le plan',
    Txt_Remove_From_Comparator: 'Retirer du comparateur',
    Txt_Add_To_Comparator: 'Ajouter au comparateur',
    Txt_Message_To_Comparator: 'Supprimer un lot du comparateur pour ajouter celui-ci',

    Txt_Yes: 'Oui',
    Txt_No: 'Non',
    Txt_Active: 'Actif',
    Txt_Inactive: 'Inactif',
    Txt_Detail: 'Détail',
    Txt_Expire: 'Expiré',
    Txt_ExpireThisMonth: 'Expire ce mois',
    Txt_ExpireThisYear: 'Expire cette année',

    Title_ProspectAssociationPopin_PopinTitle: 'Poser une Option',
    Title_ProspectAssociationPopin_PopinTitleAsk: 'Demander une Option',
    Txt_ProspectAssociationPopin_Information: 'Vous allez poser une option sur le lot {0} au prix de {1}.',
    Txt_ProspectAssociationPopin_InformationPart2:
      'Si votre client ne figure pas parmi vos prospects déclarés, merci de renseigner ses \
      coordonnées dans le formulaire ci-dessous.',
    Txt_ProspectAssociationPopin_InformationPart1: 'Retrouvez vos prospects déclarés',
    Txt_ProspectAssociationPopin_LookingForProspect: "Recherche d'un prospect",
    Txt_ProspectAssociationPopin_NoProspect: "Vous n'avez pas de prospects déclarés",

    Title_FinancialStrategy_Responsible: 'Responsable de la Stratégie',
    Title_FinancialStrategy_planningHour: 'Heure',
    Title_FinancialStrategy_LotTypologyTable: 'Plafonnement par Typologie de Lot',
    Title_FinancialStrategy_PercentageOfTransformation: 'Pourcentage de Transformation',
    Title_FinancialStrategy_FeeCap: 'Plafonnement par Honoraire',
    Title_FinancialStrategy_FeesAmount: 'Montant Honoraire',
    Title_FinancialStrategy_CappingThreshold: 'Palier plafonnement',
    Title_FinancialStrategy_NotificationThreshold: 'Palier Notification',
    Txt_FinancialStrategy_CappingThresholdTooltip:
      "Indiquer le plafond de blocage des options sur le programme. La valeur doit être \
      supérieure à 100% (Exemple : Palier plafonnement de 120% pour un Montant d'honoraire de 100 000€ implique un blocage des options \
      lorsque la valeur réelle du montant d'honoraire atteint 120 000€)",
    Txt_FinancialStrategy_NotificationThresholdTooltip:
      "Indiquer le plafond à partir duquel le responsable du programme sera notifié de \
      l'approche du montant d'honoraire de la stratégie finance. La valeur doit être inférieure à 100% (Exemple : Palier notification \
      de 80% pour un Montant d'honoraire de 100 000€ implique l'envoie d'une notification lorsque la valeur réelle du montant \
      d'honoraire atteint 80 000€)",
    Txt_FinancialStrategy_SubmitButton: 'Valider Stratégie Finance',
    Txt_FinancialStrategy_ModifyButton: 'Modifier Stratégie Finance',
    Title_FinancialStrategy_Prereservation: 'Pré-réservations',
    Title_FinancialStrategy_Reservation: 'Réservations',
    Txt_FinancialStrategy_FeesAmountCapSuccess: "Stratégie Finance validée avec plafonnement des taux d'honoraires",
    Txt_FinancialStrategy_TypologyCapSuccess: 'Stratégie Finance validée avec plafonnement par typologie de biens',
    Txt_FinancialStrategy_BothCapSuccess: "Stratégie Finance validée avec plafonnement par taux d'honoraires et typologie de biens",
    Txt_FinancialStrategy_NoCapSuccess: 'Aucune stratégie finance ne sera appliquée à ce programme',
    Txt_FinancialStrategy_ModificationSuccess: 'La stratégie finance a bien été mise à jour',

    Txt_OneRoomLabel: '1 Pièce',
    Txt_NRoomsLabel: '{0} Pièces',
    Txt_6RoomsOrMoreLabel: '6 Pièces et plus',

    Title_Save_Search: 'Sauvegarder ma recherche',
    Txt_SaveSearchPopin_FormTitleLabel: 'Titre de la recherche',
    Txt_SaveSearchPopin_FormDescriptionLabel: 'Description de la recherche',
    Title_PreReservation_Creation: 'Demander à pré-réserver un lot',
    Title_PreReservation_Cancelation: 'Annuler une pré-réservation',
    Reason_Void_Signature_After_Resa_Canceled: 'Réservation annulée',
    Failed_Cancel_Signature_of_Resa: "Le dossier de signature affilié n'a pu être annulé",
    Txt_PreReservationSubmitionPopin_HeaderInformationText:
      'Vous allez pré-réserver le lot <B>{0}</B> du programme <B>{1}</B> pour le prospect suivant :',
    Txt_PreReservationSubmitionPopin_HeaderInformationText_Multiple:
      'Vous allez pré-réserver le lot <B>{0}</B> du programme <B>{1}</B> pour les prospects suivants :',
    Txt_PreReservationSubmitionPopin_HeaderInformationText_Company:
      'Vous allez pré-réserver le lot <B>{0}</B> du programme <B>{1}</B> pour la société suivante :',
    Txt_PreReservationSubmitionPopin_HeaderSocietyLabel: 'Société',
    Txt_PreReservationSubmitionPopin_HeaderEMailLabel: 'Mail',
    Txt_PreReservationSubmitionPopin_HeaderPhoneLabel: 'Téléphone',
    Txt_PreReservationSubmitionPopin_HeaderAddressLabel: 'Adresse',
    Txt_PreReservationSubmitionPopin_FormInformationText: 'Veuillez renseigner les informations associées à la pré-réservation.',
    Txt_PreReservationSubmitionPopin_FormReservationMotiveLabel: 'Motif',
    Txt_PreReservationSubmitionPopin_FormEstimatedAppointmentDateLabel: 'Date prévisionnelle de rendez-vous',
    Txt_PreReservationSubmitionPopin_FormSignatureDateLabel: 'Date de signature',
    Txt_PreReservationSubmitionPopin_FormEstimatedAppointmentDateInformation:
      'Si le contrat de réservation est signé, merci de renseigner la date de signature du contrat. \
      Si le rendez-vous pour la signature est planifié, merci de renseigner la date du rendez-vous.',
    Txt_PreReservationSubmitionPopin_FormSupportingDocumentLabel: 'Pièce justificative',
    Txt_PreReservationSubmitionPopin_FormSupportingDocumentInformation:
      "Si le contrat de réservation est signé, merci de fournir un scan du contrat de réservation ou du mail de signature électronique. \
      Si le rendez-vous pour la signature est planifié, merci de fournir un mail ou une lettre d'intention du client.",
    Txt_PreReservationSubmitionPopin_FormCommentsLabel: 'Si vous le souhaitez, veuillez laisser un commentaire',
    Txt_PreReservationSubmitionPopin_DatePickerPlaceholder: 'Date de rappel',
    Txt_PreReservationSubmitionPopin_ValoCommentPlaceholder: 'Si vous le souhaitez, veuillez laisser un commentaire',
    Txt_PreReservationSubmitionPopin_ValidPrereservation: 'Valider la pré-réservation',
    Txt_PreReservationSubmitionPopin_RejectPrereservation: 'Rejeter la pré-réservation',
    Txt_PreReservationSubmitionPopin_SuccessValid: 'Pré-réservation validée',
    Txt_PreReservationSubmitionPopin_SuccessReject: 'Pré-réservation rejetée',
    Txt_PreReservationSubmitionPopin_WarningPaternityMessagePending: "Attention le prospect n'a pas encore été validé",
    Txt_PreReservationSubmitionPopin_WarningPaternityMessageRejectedValo: "Attention le prescripteur n'a pas la paternité sur le prospect",
    Txt_PreReservationSubmitionPopin_WarningPaternityMessageRejectedContractor: "Attention vous n'avez pas la paternité sur le prospect",

    Title_ReservationsTrackingTable: 'Mes réservations',
    Txt_Button_ReservationsAccessSignatures: 'Accès aux signatures',
    Txt_Button_ReservationsCreateManualPrereservation: `Création d'une pré-reservation`,
    Txt_Button_ReservationsAccessDocapostSignatures: 'Dossier Bouygues immobilier',
    Title_ResaTrackingTable_Column_IsPurged: '',
    Title_ResaTrackingTable_Column_ReservationRef: 'Ref Réservation',
    Title_ResaTrackingTable_Column_AcquirerName: 'Acquéreur',
    Title_ResaTrackingTable_Column_DeveloperName: 'Promoteur',
    Title_ResaTrackingTable_Column_ProgramName: 'Programme',
    Title_ResaTrackingTable_Column_LotNumber: 'N° du lot',
    Title_ResaTrackingTable_Column_ReservationDate: 'Date de la réservation',
    Title_ResaTrackingTable_Column_ResponsableCommercial: 'Responsable commercial',
    Title_ResaTrackingTable_Column_ReservationSellingPriceIT: 'Prix de vente',
    Title_ResaTrackingTable_Column_SellerName: 'Prescripteur',
    Title_ResaTrackingTable_Column_Responsible: 'Responsable commercial',
    Title_ResaTrackingTable_Column_Status: 'Statut',
    Title_ResaTrackingTable_Column_StatusDate: 'Date du statut',
    Title_ResaTrackingTable_Column_AdvName: 'ADV Valorissimo',

    Title_PreresaTrackingTable_ToggleButton_All: 'Tous',
    Title_PreresaTrackingTable_ToggleButton_Ongoing: 'En cours',
    Title_PreresaTrackingTable_ToggleButton_Closed: 'Refusé/Annulé',
    Title_PreresaTrackingTable_ToggleButton_Transformed: 'Transformé',
    Title_ResaTrackingTable_ToggleButton_All: 'Tous',
    Title_ResaTrackingTable_ToggleButton_Reservation: 'Client réservataires',
    Title_ResaTrackingTable_ToggleButton_Sold: 'Client acquéreurs',
    Title_ResaTrackingTable_ToggleButton_Desisted: 'Client désistés',

    Title_SalersTrackingTable_Column_Name: 'Nom Prénom',
    Title_SalersTrackingTable_Column_Company: 'Société',
    Title_SalersTrackingTable_Column_DeclaredProspects: 'Nbre de prospects déclarés',
    Title_SalersTrackingTable_Column_CurrentOptions: "Nbre d'options en cours",
    Title_SalersTrackingTable_Column_TakenOptions: "Nbre d'options posées",
    Title_SalersTrackingTable_Column_CurrentPrereservations: 'Nbre de pré-réservations en cours',
    Title_SalersTrackingTable_Column_CurrentReservations: 'Nbre de réservations en cours',
    Title_SalersTrackingTable_Column_Sells: 'Nbre de ventes',
    Title_SalersTrackingTable_Column_SuperiorId: 'Responsible Id',
    Title_SalersTrackingTable_Column_Id: 'Account Id',

    Title_SavedSearches_Column_Title: 'Recherche',
    Title_SavedSearches_Column_Description: 'Description',

    Title_Reservation_Form: 'Réservation',
    Title_Reservation_ProgressOfTheReservation: 'Avancement et documents',
    Title_Reservation_DeveloperInformation: 'Information Promoteur',
    Title_Reservation_VendorInformation: 'Information Vendeur',
    Title_Reservation_CancelTheReservation: 'Annulation de la réservation',
    Txt_Reservation_CancelTheReservation:
      "Attention, l'annulation d'une réservation est une opération irréversible. \
      Etes-vous sûr de vouloir continuer ? <br><br>\
      Les champs du dossier de réservation ne seront plus éditable une fois la réservation annulée.",
    Txt_Reservation_SuccessCancelTheReservation: 'Réservation annulée avec succès !',
    Txt_PreReservationCancelationPopin_InformationText:
      'Êtes-vous sûr de vouloir annuler cette pré-réservation ? \
      Cette action est irréversible.',
    Title_Reservation_CancelCommentReservation: 'La reservation a été annulé : ',
    Txt_Reservation_FormSupportingDocumentLabel: 'Dossier de réservation',
    Txt_Reservation_Form_ammendment_document_label: 'Avenant au contrat',
    Txt_Reservation_Form_SRU_label: 'Courrier SRU',
    Txt_Reservation_Form_principe_accord_label: 'Accord de principe',
    Txt_Reservation_Form_loanOfferDocument_label: 'Offre de prêt',
    Txt_Reservation_Form_selleAttestationDocument_label: 'Attestation de vente',
    Txt_Tooltip_LongitudeFormat: '2.2672',
    Txt_Tooltip_LatitudeFormat: '48.8329',

    Txt_ReservationFormStepTwo_valoReservationFileReceiptDate: 'Date de réception du dossier de réservation',
    Txt_ReservationFormStepTwo_valoReservationValidationDate: 'Date de validation du dossier de réservation',
    Txt_ReservationFormStepTwo_valoReservationSentDate: "Date d'envoi du dossier de réservation",
    Txt_ReservationFormStepTwo_isAcceptedDeveloper: 'Approbation Promoteur',
    Txt_ReservationFormStepTwo_developerReservationValidationDate: "Date d'approbation promoteur",
    Txt_ReservationFormStepTwo_developerReservationRejectionDate: 'Date de rejet promoteur',
    Txt_ReservationFormStepTwo_sruResevationNotificationDate: 'Date notification SRU',
    Txt_ReservationFormStepTwo_principleAgreementDate: "Date d'obtention de l'accord de principe",
    Txt_ReservationFormStepTwo_loanOfferDate: "Date d'obtention de l'offre de prêt",
    Txt_ReservationFormStepTwo_notaryMeetingDate: 'Date de RDV notaire',
    Txt_ReservationFormStepTwo_estimatedSaleDate: 'Date de vente prévisionnelle',
    Txt_ReservationFormStepTwo_notarySignatureDate: 'Date de signature notaire',
    Txt_ReservationFormStepTwo_reservationDesistedDate: 'Date de désistement client',
    Txt_ReservationFormStepTwo_valoTrackingComments: 'Si vous le souhaitez, veuillez laisser un commentaire',
    Txt_ReservationFormStepTwo_withdrawMotive: 'Motif de désistement',
    Txt_ReservationFormStepTwo_withdrawDocument: 'Si vous le souhaitez, vous pouvez ajouter une pièce justificative.',
    Txt_Tooltip_valoReservationValidationDate: 'Date de validation du dossier de réservation par les équipes Valorissimo',
    Txt_Tooltip_valoReservationSentDate: "Date d'envoi du dossier de réservation au Promoteur par les équipes Valorissimo",

    Zip_Name_PrereservationContractualDocumentPackage: 'Liasse_contractuelle_{0}_{1}',
    Zip_Name_ReservationContractualDocumentPackage: 'Contrat_reservation_{0}_{1}',

    Txt_Option_Time_Left: "Vous avez optionné ce lot jusqu'au {0}",
    Txt_Option_Time_Left_Other: 'L’option expire le {0}',
    Txt_Detail_Lot_Option_Pending: `Votre demande d'option est en attente de validation promoteur`,
    Txt_Detail_Lot_Alert_Message: `Alerte créée sur ce lot, vous recevrez un mail dès son retour à l’offre le {0}`,

    Txt_NoResults: 'Aucun résultat.',

    Txt_Search_Status_Lot_Free: 'Ce lot est libre',
    Txt_Search_Status_Lot_Unavailable: 'Ce lot est indisponible',
    Txt_Search_Status_Lot_Optioned: 'Ce lot est optionné',
    Txt_Detail_Program_Status_Lot_Free: 'Libre',
    Txt_Detail_Program_Status_Lot_Unavailable: 'Indisponible',
    Txt_Detail_Program_Status_Lot_Optioned: 'Optionné',
    Txt_Detail_Program_Status_Lot_Reserved: 'Réservé',
    Txt_Detail_Program_Status_Lot_Prereserved: 'Pré-réservé',
    Txt_Detail_Program_Status_Lot_Sold: 'Vendu',

    Txt_Select_Year_Salers: "Veuillez sélectionner l'année pour laquelle vous souhaitez suivre l'activité de vente",
    Txt_Select_Year_Informations: 'Date de dernière récupération des informations : ',

    Txt_Desktop_Publishing_downloadYourFile: 'Télécharger votre fichier .DOCX',
    Txt_Desktop_Publishing_generateDocument: 'Générer le document contractuel',
    txt_Desktop_Publishing_Error:
      "Suite à une erreur technique votre fichier n'a pas été généré. <br> \
        Veuillez réessayer en fermant la modale. <br> Si ce problème persiste, veuillez contacter votre responsable. <br>",

    Txt_Location_Filter_Prospect_Creation: 'Aucun programme disponible pour cette localisation',

    Title_MandateStrategiesTrackingTable: 'Liste des stratégies mandats de la société',
    Title_MandateStrategiesTable_Column_IsGlobal: 'Type Stratégie Mandat',
    Title_MandateStrategiesTable_Column_Name: 'Nom',
    Title_MandateStrategiesTable_Column_Developer: 'Promoteur',
    Title_MandateStrategiesTable_Column_Program: 'Programme',
    Title_MandateStrategiesTable_Column_Rate: 'Taux stratégie',
    Title_MandateStrategiesTable_Column_StartDate: 'Date de début',
    Title_MandateStrategiesTable_Column_EndDate: 'Date de fin',

    Txt_Success_Saved_Search: 'Votre recherche a été enregistrée avec succès',

    Txt_TrackingTable_MY_PARTNERS: 'Mes partenaires',
    Txt_TrackingTable_MY_TEAM: 'Mon équipe',

    /**
     * Special offer text
     */
    // special offer register form

    Title_specialOffer_creation: `Création d'une offre spéciale`,
    Title_specialOffer_edit: `Modification d'une offre spéciale`,
    Txt_specialOffer_register_step1: `Conditions`,
    Txt_specialOffer_register_step2: `Programmes de l'offre spéciale`,
    Txt_specialOffer_register_step3: `Lots de l'offre spéciale`,

    // special offer step 1 form label
    Txt_specialOffer_register_step1_companyName: `Nom de la société promotrice`,
    Txt_specialOffer_register_step1_OSRef: `Référence de l'offre spéciale`,
    Txt_specialOffer_register_step1_OSType: `Type d'offre`,
    Txt_specialOffer_register_step1_OSTypeOther: `Précisez si autre`,
    Txt_specialOffer_register_step1_label: `Libellé`,
    Txt_specialOffer_register_step1_description: `Texte descriptif de l'offre spéciale`,
    Txt_specialOffer_register_step1_legalMention: `Texte mentions légales de l'offre spéciale`,
    Txt_specialOffer_register_step1_startDate: `Date de début de l'offre spéciale`,
    Txt_specialOffer_register_step1_endDate: `Date de fin de l'offre spéciale`,
    Txt_specialOffer_register_step1_document: `Avenant de l'offre spéciale`,
    Txt_specialOffer_register_step1_document_with_fusion: `Avenant de l'offre spéciale avec champs de fusion`,

    // alert text
    Txt_alert_private_data_developer: `Sur la plateforme Valorissimo, les informations emails et téléphones de vos prospects sont masquées aux promoteurs.`,
    Txt_alert_financial_strategy: `<strong>Victime de son succès !</strong></br>Certains lots sont indisponibles pour le moment : un quota sur le nombre de réservations a été défini par le promoteur.</br>Restez attentif, des lots peuvent se libérer. Contactez votre interlocuteur Valorissimo pour plus d’informations.`,
    Txt_alert_exclude_list: `<div class="mb-1">Une société sélectionnée sera exclue de tous les mandats des programmes du promoteur.</div><div class="mt-1">Une société retirée de la liste sera mandatée sur toutes les stratégies mandats Globales du promoteur.</div>`,
    Txt_alert_lot_fees_history: `L’honoraire retenu est celui en vigueur au moment de la signature du contrat de réservation. Ces honoraires ne prennent pas en compte les spécificités sur les sociétés.`,

    // search filter text
    Txt_search_filter_rooms_placeholder: 'Pièces',
    Txt_search_filter_taxation_placeholder: 'Fiscalité',
    Txt_search_filter_other_criteria_placeholder: 'de critères',
    Txt_search_filter_location_placeholder: 'Ajouter une région, un département ou une ville',
    Txt_search_filter_location_placeholder_ellipsis: '{0} en tout',
    Txt_search_filter_location_panel_title: 'Localisation',
    Txt_search_filter_location_panel_placeholder: 'Choisir une région, un département ou une ville...',
    Txt_search_filter_actable_title: 'Acte notarié',
    Txt_search_filter_tags_title: 'Tags',
    Txt_search_filter_taxation_disable_tooltip: 'Le critère Taux de Rentabilité minimal n’est pas disponible en zone ANRU',
    Txt_search_filter_reinit: 'Réinitialiser tous les critères',
    Txt_search_filter_rent_disable_tooltip: 'Le critère Loyer maximum n’est pas disponible en zone ANRU',
    Txt_search_filter_profitability_disable_tooltip: 'Le critère Taux de Rentabilité minimal n’est pas disponible en zone ANRU',

    /**
     * Page programme V2
     */

    // Programme header text

    Txt_page_program_header_back: 'Retour à ma recherche',
    Txt_page_program_header_map: 'Voir la carte',
    Txt_page_program_infos_delivery_between: '{0}&nbsp;- {1}',
    Txt_page_program_infos_rentability: 'De <b>{0}</b> à <b>{1}</b>',
    Txt_page_program_infos_more_infos: 'Plus d’informations',
    Txt_page_program_infos_action_button_map: 'Voir le quartier',
    Txt_page_program_infos_action_button_documents: 'Consulter les documents',
    Txt_page_program_infos_action_button_3dview: 'Voir la maquette 3D',
    Txt_page_program_prospective_tag_label: 'NOUVEAU',
    Txt_page_program_lots_see_all_link: 'Voir la grille complète',
    Txt_page_program_lots_status_unavailable: 'Quota atteint',
    Txt_page_program_promoter_infos_title: 'Informations promoteur',
    Txt_page_program_neighborhood_map_interest_points: 'Trouver les points d’intérêts aux alentours',
    Txt_page_program_neighborhood_map_bloc_title: 'Plan du quartier',
    Txt_page_program_neighborhood_map_explore_label: 'Explorer le quartier',
    Txt_page_program_neighborhood_map_view_situation_access: 'Voir l’accès et la situation',
    Txt_page_program_neighborhood_map_view_streetview: 'STREETVIEW',
    Txt_page_program_documents_list_title: 'Documents',
    Txt_page_program_highlights_title: 'Ce qu’on aime',
    Txt_page_program_documents_list_download_zip_label: 'Télécharger les documents sélectionnés',
    Txt_page_program_documents_list_send_mail_label: 'Partager avec votre client',
    Txt_Page_Program_FeesFrom: `Honoraires des lots : `,
    Txt_Page_Program_Min_Max_FeesFrom: `Honoraires des lots : de {0} à {1}`,
    Txt_page_program_Delivery_Date: `Date de livraison`,
    Txt_Page_Program_Fiscality: 'Fiscalité',
    Txt_Page_Program_Rentability: 'Rentabilité',
    Txt_Page_Program_Key_Date: 'Dates clés',
    Txt_Page_Program_Key_Date_BuildingPermitStartDate: 'PC obtenu',
    Txt_Page_Program_Key_Date_BuildingPermitExpireDate: 'PC purgé',
    Txt_Page_Program_Key_Date_Actable: 'Actable',
    Txt_Page_Program_Key_Date_WorkStarted: 'Début des travaux',
    Txt_Page_Program_Strong_Points: 'Points forts',
    Txt_Page_Program_Nue: 'Nue',
    Txt_Page_Program_Meuble: 'Meublée',

    // Program Lot text
    Txt_page_program_Detail_Lot_header_back: 'Retour aux lots',
    Txt_page_program_Detail_Lot_information_block_title: 'Informations sur le lot',
    Txt_page_program_Detail_Lot_information_block_price: 'Détails du prix',
    Txt_page_program_Detail_Lot_information_block_rent_and_profitability: 'Loyer et rentabilité',
    Txt_page_program_Detail_Lot_RoomNumber: 'Nombre de pièces',
    Txt_page_program_Detail_Lot_Surface: 'Surface',
    Txt_page_program_Detail_Lot_floor: 'Etage',
    Txt_page_program_Detail_Lot_Orientation: 'Orientation',
    Txt_page_program_Detail_Lot_blockPrice_priceTTC: 'Prix',
    Txt_page_program_Detail_Lot_blockPrice_ListGlobalSellingPriceET: 'Prix de vente total HT (Hors&nbsp;mobilier)',
    Txt_page_program_Detail_Lot_CommercialLotSellingPriceIT_lmnp: 'Prix TTC Lot commercial (Hors&nbsp;mobilier)',
    Txt_page_program_Detail_Lot_CommercialLotSellingPriceIT_nuePropriete: 'Prix pleine propriété',
    Txt_page_program_Detail_Lot_unavailable: "Ce lot n'est plus disponible.",

    // Comparateur lots
    Txt_button_comparator_open_container_label: 'Comparer des lots',
    Txt_button_comparator_find_lot_label: 'Trouver des lots',
    Txt_button_comparator_compare_lot_label: 'Comparer',
    Txt_button_comparator_clear_lot_label: 'Vider le comparateur',
    Txt_button_comparator_empty_container: "Vous pouvez ajouter jusqu'à 3 lots dans le comparateur !",
    Txt_comparator_lot_title: 'Comparateur',
    Txt_comparator_lot_glance_title: "coup d'oeil du lot",
    Txt_comparator_lot_unavailable: 'Ce lot n’est plus disponible',
    Txt_comparator_empty_sucess: 'Comparateur vidé avec succès',
    Txt_comparator_Option_Time_Left: 'Vous avez récemment posé une option sur ce lot. Une nouvelle option sera possible à partir du {0}.',
    Txt_comparator_take_Option_error: "La pose d'option est momentanément indisponible, veuillez réessayer ultérieurement.",
    Txt_comparator_reached_lot_quota:
      ' <strong> Victime de son succès ! </strong> <br> \
      Le lot est indisponible pour le moment : un quota sur le nombre de réservations a été défini par le promoteur. Restez attentif, le lot peut se libérer. Contactez votre interlocuteur Valorissimo pour plus d’informations',
    TXT_comparator_glance_profitability: `{0} de rentabilité`,
    TXT_comparator_glance_delivry_date: `Livraison au {0}`,
    TXT_comparator_price_infos_lmnp: '(Hors mobilier)',
    TXT_comparator_price_infos_lmnp_and_IncludedArea: '(Hors mobilier, Annexes Incluses)',
    TXT_comparator_information_price: `Prix TTC du Lot`,
    TXT_comparator_information_rooms: `Nombre de pièces`,
    TXT_comparator_information_livingSpace: 'Surface',
    TXT_comparator_information_taxations: `Fiscalité`,
    TXT_comparator_information_typology: 'Typologie',
    TXT_comparator_information_floor: 'Etage',
    TXT_comparator_information_orientation: 'Orientation',
    TXT_comparator_information_deliveryDate: 'Date de livraison',
    TXT_comparator_information_fees: 'Honoraire',
    TXT_comparator_information_specialOffer: 'Offre spéciale',
    TXT_comparator_annexe_Terrace: 'Terrasse',
    TXT_comparator_annexe_Balcony: 'Balcon',
    TXT_comparator_annexe_Garden: 'Jardin',
    TXT_comparator_annexe_Loggia: 'Loggia',
    TXT_comparator_annexe_OUTDOOR_PARKING: 'Parking ext.',
    TXT_comparator_annexe_BASEMENT_PARKING_LOT: 'Parking int.',
    TXT_comparator_annexe_garage: 'Garage',
    TXT_comparator_annexe_boxes: 'Box',
    TXT_comparator_annexe_larder: 'Cellier',
    TXT_comparator_annexe_attic: 'Grenier',
    TXT_comparator_annexe_cellar: 'Cave',
    TXT_comparator_annexe_carport: 'Carport',
    TXT_comparator_alert_message_small_screen: `Le comparateur de lot n’est pas disponible sur cette taille d'écran.`,
    TXT_comparator_alert_button_small_screen: `Retourner à l'accueil`,
    TXT_comparator_alert_no_lot: `Le comparateur est vide !<br>Vous pouvez ajouter jusqu’à 3 lots.`,
    TXT_comparator_alert_button_no_lot: `Trouver des lots`,

    // Datalayer traduction
    Txt_datalayer_role_valo: 'valo',
    Txt_datalayer_role_rpv: 'prescripteur',
    Txt_datalayer_role_dev: 'promoteur',
    Txt_datalayer_role_free: 'visiteur',

    // Mapbox
    Txt_search_result_tab_programs: 'PROGRAMMES',
    Txt_search_result_tab_carte: 'CARTE',
    Txt_search_result_tab_lots: 'LOTS',
    Txt_mapbox_legend: 'Nombre de lots disponibles',
    Txt_mapbox_popup_error_text: 'Il semblerait qu’un problème soit survenu.',
    Txt_mapbox_popup_error_link: 'Recharger la page',
    Txt_mapbox_popup_Program_delivery_date_prefix: 'Livraison au ',
    Txt_mapbox_popup_Program_only_min_price: 'À partir de {0}',
    Txt_mapbox_popup_Program_only_min_max_price: 'De {0} à {1}',
    Txt_mapbox_popup_Program_see_more: 'En savoir plus',
    Txt_mapbox_center_control_title: 'Recentrer sur mes résultats de recherche',
    Txt_mapbox_refresh_on_moving_control_title: 'Actualiser la carte lors du déplacement',
    Txt_mapbox_refresh_manually_control_title: 'Rafraichir la carte',
    Txt_mapbox_localisation_map_placeholder: 'Zone géographique',
    // lot fees history
    TXT_lot_fees_history_step_one_resa_link_title: `voir l'historique des honoraires`,
    TXT_lot_fees_history_link_title: `voir l'historique`,
    TXT_lot_fees_history_dialog_title: `Historique des honoraires - {0}`,
    TXT_lot_fees_history_dialog_table_header_date: `Date de modification`,
    TXT_lot_fees_history_dialog_table_header_rate: `Honoraires lot`,

    //Stepper
    txt_stepper_step: 'Etape {0}/{1}',

    // Session expired
    TXT_session_expired_error: `Votre session a expiré, veuillez vous reconnecter.`,
    TXT_stepper_preresa_header: 'Étapes',

    // PreReservation manual creation from
    txt_back: 'Retour',
    PreReservationCreateForm_Title: "Création manuelle d'une pré-réservation",
    PreReservationCreateForm_LotInformation: 'Informations lot',
    PreReservationCreateForm_Developer: 'Promoteur',
    PreReservationCreateForm_Developer_Placeholder: 'Sélectionner une société promotrice',
    PreReservationCreateForm_Program: 'Programme',
    PreReservationCreateForm_Program_Placeholder: 'Sélectionner un programme',
    PreReservationCreateForm_ProspectCityAndPostalCode: 'Ville (Code postal)',
    PreReservationCreateForm_City_Placeholder: 'Sélectionner une ville',
    PreReservationCreateForm_Country_Placeholder: 'Sélectionner un pays',
    PreReservationCreateForm_LotPrincipal: 'Lot principal',
    PreReservationCreateForm_LotPrincipalDescription:
      "Si le lot que vous souhaitez rattacher à cette pré-réservation n'apparait pas dans la liste, saisissez ses informations dans le formulaire ci-dessous",
    PreReservationCreateForm_LotNumber: 'Numéro du lot',
    PreReservationCreateForm_LotNumberDescription:
      "Si le lot que vous souhaitez rattacher à cette pré-réservation n'apparait pas dans la liste, saisissez ses informations dans le formulaire ci-dessous",
    PreReservationCreateForm_LotNumber_Placeholder: 'Sélectionner un lot',
    PreReservationCreateForm_LotRef: 'Référence du lot',
    PreReservationCreateForm_LotProgramType: 'Type de bien',
    PreReservationCreateForm_LotOrientation: 'Orientation',
    PreReservationCreateForm_LotLivingSpace: 'Surface habitable',
    PreReservationCreateForm_LotRooms: 'Nombre de pièces',
    PreReservationCreateForm_LotFloor: 'Etage',
    PreReservationCreateForm_LotTerrace: 'Terasse',
    PreReservationCreateForm_LotTerraceArea: 'Surface de la terrasse',
    PreReservationCreateForm_LotBalcony: 'Balcon',
    PreReservationCreateForm_LotBalconyArea: 'Surface du balcon',
    PreReservationCreateForm_LotGarden: 'Jardin',
    PreReservationCreateForm_LotGardenArea: 'Surface du jardin',
    PreReservationCreateForm_LotLoggia: 'Loggia',
    PreReservationCreateForm_LotLoggiaArea: 'Surface du Loggia',
    PreReservationCreateForm_LotDeliveryDate: 'Date de livraison',
    PreReservationCreateForm_LotCommercialLotSellingPriceIT: 'Prix du lot',
    PreReservationCreateForm_LotTaxations: 'Fiscalité',
    PreReservationCreateForm_LotTaxationLMNP: 'Fiscalité LMNP/LMP',
    PreReservationCreateForm_LotGuaranteedMonthlyRentingPriceIT: 'Loyer mensuel garanti TTC',
    PreReservationCreateForm_LotEstatePriceET: 'Prix foncier HT',
    PreReservationCreateForm_LotHousingPriceET: 'Prix immo HT',
    PreReservationCreateForm_LotTangiblePriceET: 'Prix mobilier HT',
    PreReservationCreateForm_LotGlobalSellingPriceET: 'Prix de vente total HT (Hors mobilier)',
    PreReservationCreateForm_LotTaxationNuePropriete: 'Fiscalité nue propriété',
    PreReservationCreateForm_LotNueProprietePrice: 'Prix nue propriété',
    PreReservationCreateForm_LotUsufruitValue: 'Valeur usufruit',
    PreReservationCreateForm_LotDistributionKey: 'Clé de répartition',
    PreReservationCreateForm_LotNueProprieteDuration: 'Durée',
    PreReservationCreateForm_LotTaxationMalraux: 'Fiscalité Malraux',
    PreReservationCreateForm_LotTaxationMonumentsHistorique: 'Fiscalité Monuments Historiques',
    PreReservationCreateForm_LotTaxationMonumentsDeficitFoncier: 'Fiscalité Déficit foncier',
    PreReservationCreateForm_LotEstimatedWorkPrice: 'Prix travaux',
    PreReservationCreateForm_LotEstimatedLandPrice: 'Prix foncier',
    PreReservationCreateForm_SecondaryLot: 'Lot(s) secondaire(s)',
    PreReservationCreateForm_SecondaryLotRefLot: 'Référence du lot secondaire',
    PreReservationCreateForm_SecondaryLotNumLot: 'Numéro du lot secondaire',
    PreReservationCreateForm_SecondaryLotType: 'Type du lot',
    PreReservationCreateForm_SecondaryLotPrice: 'Prix du lot (TTC)',
    PreReservationCreateForm_Seller: 'Information vendeur',
    PreReservationCreateForm_SellerName: 'Nom prénom (Raison sociale)',
    PreReservationCreateForm_SellerPhone: 'Téléphone',
    PreReservationCreateForm_SellerEmail: 'Email',
    PreReservationCreateForm_SellerPostalCode: 'Code postal',
    PreReservationCreateForm_SellerCity: 'Ville',
    PreReservationCreateForm_SellerName_Placeholder: 'Sélectionner un vendeur',
    PreReservationCreateForm_Prospect: 'Informations prospect',
    PreReservationCreateForm_ProspectDescription:
      'Sélectionnez le prospect à rattacher à la pré-réservation parmi les prospects du vendeur',
    PreReservationCreateForm_ProspectName: 'Prospect',
    PreReservationCreateForm_ProspectName_Placeholder: 'Sélectionner un prospect',
    PreReservationCreateForm_DossierProspectName: 'Dossier prospect',
    PreReservationCreateForm_DossierProspectName_Placeholder: 'Sélectionner un dossier prospect',
    PreReservationCreateForm_ProspectDescriptionCreation:
      "Si le prospect que vous souhaitez rattacher à cette pré-réservation n'apparait pas dans la liste, saisissez ses informations dans le formulaire ci-dessous",
    PreReservationCreateForm_ProspectCivility: 'Civilité',
    PreReservationCreateForm_Option_H: 'Monsieur',
    PreReservationCreateForm_Option_F: 'Madame',
    PreReservationCreateForm_ProspectLastName: 'Nom',
    PreReservationCreateForm_ProspectFirstName: 'Prénom',
    PreReservationCreateForm_ProspectEmail: 'Email',
    PreReservationCreateForm_ProspectPhone: 'Téléphone',
    PreReservationCreateForm_ProspectCountry: 'Pays',
    PreReservationCreateForm_Option_France: 'France',
    PreReservationCreateForm_Option_Autre: 'Autre',
    PreReservationCreateForm_ProspectPostalCode: 'Code postal',
    PreReservationCreateForm_ProspectCity: 'Ville',
    PreReservationCreateForm_JustificatifTitle: 'Justificatifs',
    PreReservationCreateForm_JustificatifMotif: 'Motif',
    PreReservationCreateForm_JustificatifDocument: 'Pièce justificative',
    PreReservationCreateForm_Commentary: 'Commentaire',
    PreReservationCreateForm_AppointmentSignatureDate: 'Date du rendez-vous de signature',
    PreReservationCreateForm_Submit: 'Valider la pré-réservation',
    PreReservationCreateForm_Previous: 'Précédent',
    PreReservationCreateForm_Next: 'Suivant',
    PreReservationCreateForm_AddSecondaryLot: 'Ajouter un lot secondaire',
    PreReservationCreateForm_Annexe: 'Annexe incluse ?',
    PreReservationCreateForm_SecondaryLotsAddDialog_Add: 'Ajouter',
    PreReservationCreateForm_SecondaryLotsAddDialog_Cancel: 'Annuler',
    PreReservationCreateForm_SecondaryLotsTable_Number: 'Numéro',
    PreReservationCreateForm_SecondaryLotsTable_Reference: 'Référence',
    PreReservationCreateForm_SecondaryLotsTable_Type: 'Type',
    PreReservationCreateForm_SecondaryLotsTable_Price: 'Prix',
    PreReservationCreateForm_SecondaryLotsTable_Remove: 'Supprimer',

    // Search preferences
    SearchPreference_Localisation: 'Localisation',
    SearchPreference_Area_Or_City: 'Regions, Villes...',
    SearchPreference_Price_Taxation: 'Prix et fiscalité',
    LotMinPricePlace: 'Prix minimum (€)',
    LotMinPricePlaceHolder: 'Ex: 100 000 €',
    LotMaxPricePlace: 'Prix maximum (€)',
    LotMaxPricePlaceHolder: 'Ex: 1 000 000 €',
    LotTaxation: 'Fiscalité',
    LotTaxArea: 'Zone fiscale',
    SearchPreference_Lot: 'Caractéristiques du bien',
    SearchPreference_Other: 'Autres critères',
    LotProgramType: 'Type',
    Tout: 'Tout',
    LotRooms: 'Nombre de pièces',
    LotFloors: 'Étage',
    LotAreaType: 'Extérieur',
    LotOrientation: 'Orientation',
    LotSecondaryLot: 'Annexes',
    DeliveryDate: 'Date de livraison',
    DELIVERY_AVAILABLE: 'Immediate',
    DELIVERY_BEFORE_YEAR: `Jusqu'à {0}`,
    Purchasing_capacity_from_simulation: `La capacité d'achat simulée pour ce dossier est de `,

    //Recommandation
    Title_recommandation: 'Votre sélection de biens immobilier',
    SubTitle_recommandation:
      'Chaque jeudi, nos équipes sélectionnent des programmes pertinents pour vos prospects en fonction du budget, de la rentabilité, de la zone géographique et de la fiscalité…',

    // Option model form
    OptionFrom_Modal_Title: 'Poser une option',
    OptionFrom_Price_Info:
      'Vous allez poser sur le lot <strong>{0}</strong> au prix de <strong>{1}</strong>. </br> Sur la plateforme Valorissimo, les informations emails et téléphones de vos prospects sont masqués aux promoteurs.',
    OptionFrom_Selected_Dossier: 'Sélectionnez parmi vos dossiers prospects',
    OptionFrom_Prospect_Info:
      'Si votre client ne figure pas parmi vos dossiers déclarés, merci de renseigner ses coordonnées dans le formulaire ci-dessous.',
    OptionFrom_DossierType: 'Type de dossier',
    OptionFrom_DossierType_Simple_Acquirer_Option: 'Une personne',
    OptionFrom_DossierType_Double_Acquirer_Option: 'Deux personnes',
    OptionFrom_DossierType_Company_Acquirer_Option: 'Une société',
    OptionFrom_ProspectCivility: 'Civilité',
    OptionFrom_ProspectCivility_Man_Option: 'Monsieur',
    OptionFrom_ProspectCivility_Woman_Option: 'Madame',
    OptionFrom_ProspectLastName: 'Nom',
    OptionFrom_ProspectFirstName: 'Prénom',
    OptionFrom_Acquirer: 'Prospect',
    OptionFrom_Acquirer_One: 'Prospect n° 1',
    OptionFrom_Acquirer_Two: 'Prospect n° 2',
    OptionFrom_Acquirer_Company: 'Société',
    OptionFrom_Modal_Cancel: 'Annuler',
    OptionFrom_Modal_Valid: 'Valider',

    // Option Modal
    Title_OptionModal_take_option: 'Poser une Option',
    Txt_OptionModal_do_not_display_again_checkbox: 'Ne plus afficher ce message',
    Txt_OptionModal_take_option_button: 'Poser une option',
    Txt_Option_Lorem: 'Lorem ipsum dolor sit amet.',
    Txt_DossierProspectAssociationPopin_ExistingDossier: "Poser l'option pour un dossier existant",
    Txt_DossierProspectAssociationPopin_NewDossier: "Poser l'option pour un nouveau dossier",

    Txt_DossierProspectAssociationPopin_LookingForProspect: "Recherche d'un dossier prospect",
    Txt_DossierProspectAssociationPopin_NoProspect: "Vous n'avez pas de dossier prospects déclarés",

    // auto complete
    Txt_autoComplete_emptyMessage: 'Aucun résultat',
  };

  getStrings(): { [name: string]: string } {
    return { ...this.strings, ...signature, ...prospect };
  }
}
