<app-filter-sort-header-bar></app-filter-sort-header-bar>
<div class="main" *ngIf="loaded">
  <app-card *ngIf="lots === undefined || lots.length === 0" class="card no-lots">
    <div class="head-no-lots">{{ 'Txt_DossierProspectNoLot' | translate }}</div>
    <div class="detail-no-lots">{{ 'Txt_DossierProspectUpdateSearchPreference' | translate }}</div>
    <app-button (click)="goToSearchPreferences()">
      {{ 'Txt_DossierProspectButtonEditSearchPreferences' | translate }}
    </app-button>
  </app-card>

  <div class="lots" *ngIf="lots !== undefined && lots.length > 0">
    <div class="nav-bar-backdrop" [class.hide]="!panelOpened" (click)="close()"></div>
    <div class="nav-bar" [class.hide]="!panelOpened">
      <app-lot-details-panel [lotId]="lotSelected?.id" (closePanel)="close()"></app-lot-details-panel>
    </div>

    <div (click)="seeAllLots()" class="see-all-links">
      <span>{{ i18nService._('Txt_See_All_Lots') }}</span>
      <app-icon size="20px" provider="custom" name="navigate_next"></app-icon>
    </div>

    <div class="header">
      <div class="program-thumbnail"></div>
      <div class="lot">{{ 'Txt_Lots' | translate }}</div>
      <div class="rooms">{{ 'Txt_Pieces' | translate }}</div>
      <div class="living-space">{{ 'Txt_Surface' | translate }}</div>
      <div class="floor-orientation">{{ 'Txt_Etage_Orientation' | translate }}</div>
      <div class="secondary-lot-types">{{ 'Txt_Annexes' | translate }}</div>
      <div class="taxations">{{ 'Txt_Fiscalites' | translate }}</div>
      <div class="prices">{{ 'Txt_Prix' | translate }}</div>
      <div class="actions">{{ 'Txt_Actions' | translate }}</div>
    </div>

    <div class="rows">
      <app-card *ngFor="let lot of lots" class="row">
        <div class="program-thumbnail">
          <span>
            <img src="{{ '/logos/programDocuments/' + lot.programThumbnailPath }}" alt="program" />
          </span>
        </div>
        <div class="lot">
          <div class="lot-row">
            <span class="lot-number">{{ lot.lotNumber }}</span>
            <span>
              <mat-icon
                class="info-icon"
                matTooltip="{{ 'Title_TrackingTable_Column_LotNumber_Picto_Tooltip' | translate }}"
                (click)="open(lot)"
                >informations
              </mat-icon>
            </span>
          </div>
          <div class="lot-row program" title="{{ lot.programName }}">{{ lot.programName | uppercase | ellipsisMiddle: 36 }}</div>
          <div class="lot-row city" title="{{ lot.city }}">{{ lot.city + ' (' + lot.departementCode + ')' | ellipsisMiddle: 36 }}</div>
          <div class="lot-row delivery-date">
            {{ 'Txt_DossierProspectLotLivraison' | translate }} : {{ lot.deliveryDate | quarterDate }}
          </div>
        </div>
        <div class="rooms">
          <span>
            {{ lot.rooms }} /
            <mat-icon
              class="plan-icon"
              (click)="openPlan(lot)"
              [svgIcon]="'plan'"
              matTooltip="{{ 'Txt_SpanTitle_SeePlan' | translate }}"
            ></mat-icon>
          </span>
        </div>
        <div class="living-space">{{ lot.livingSpace | number: '1.2-2' }} {{ 'Txt_Table_SquareMeter' | translate }}</div>
        <div class="floor-orientation">{{ lot.floor }} / {{ lot.orientation }}</div>
        <div class="secondary-lot-types">
          <span *ngIf="hLotInfos[lot.id].exteriorSummary" [matTooltip]="hLotInfos[lot.id].exteriorSummary">
            <app-icon class="icon" size="16px" provider="custom" name="exterior_custom"></app-icon>
          </span>
          <span *ngIf="hLotInfos[lot.id].parkingSummary" [matTooltip]="hLotInfos[lot.id].parkingSummary">
            <app-icon class="icon" size="16px" provider="custom" name="parking_area_custom"></app-icon>
          </span>
          <span *ngIf="hLotInfos[lot.id].storageSummary" [matTooltip]="hLotInfos[lot.id].storageSummary">
            <app-icon class="icon" size="16px" provider="custom" name="storage_custom"></app-icon>
          </span>
        </div>
        <div class="taxations">
          <app-chip class="taxation" *ngFor="let taxation of hLotInfos[lot.id].taxationsNonNominal">
            {{ Taxation[taxation.id] | translate }}
          </app-chip>
        </div>
        <div class="prices">
          <div class="vat">TVA : {{ hLotInfos[lot.id].vatRate | number: '1.1-1' }} %</div>
          <div class="price">{{ lot.commercialLotSellingPriceIT | number: '1.0-0' }} €</div>
          <div class="vat-reducted" *ngIf="lot.reducedTotalSellingPriceIT">
            {{ 'Txt_Table_Column_ReducedTaxation' | translate }} : {{ lot.reducedTotalSellingPriceIT | number: '1.0-0' }} €
          </div>
          <div class="special-offer" *ngIf="lot.hasSpecialOffer">
            <span>
              <app-chip>
                {{ 'Txt_Special_Offer' | translate | uppercase }}
              </app-chip>
            </span>
          </div>
        </div>
        <div class="actions">
          <span class="available">{{ 'Txt_Available_Lot' | translate | uppercase }}</span>
          <app-button (click)="createOption(lot)">
            {{ 'Txt_Create_Option' | translate }}
          </app-button>
        </div>
      </app-card>
    </div>
  </div>
</div>
