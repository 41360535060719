import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';

import { AppConfigService } from '../../utils/services/app-config.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class DesktopPublishingService {
  /**
   * Creates an instance of DesktopPublishingService.
   * @param {HttpClient} http
   * @param {ErrorHandlerService} errorHandlerService
   * @param {AppConfigService} appConfig
   * @memberof DesktopPublishingService
   */
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
  ) {}

  /**
   * getDesktopPublishing method
   *
   * @param {number} companyId
   * @param {File} originFile
   * @returns {Observable<any>}
   * @memberof DesktopPublishingService
   */
  getDesktopPublishing(companyId: number, originFile: File): Observable<BlobPart> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Companies/getDesktopPublishing`;

    const formData = new FormData();

    /* Pass documentType in original file name */
    formData.append('file', originFile);
    formData.append('companyId', String(companyId));

    return this.http
      .post<BlobPart>(url, formData, { responseType: 'blob' as 'json' })
      .pipe(catchError(this.errorHandlerService.handleError<BlobPart>('DesktopPublishingService', 'getDesktopPublishing')));
  }
}
