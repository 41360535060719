import { IsNumber, IsString, IsObject, IsArray, IsBoolean, IsDate } from 'class-validator';

import { Orientation } from './orientation.enum';
import { AreaType } from './area-type.enum';
import { SecondaryLotType } from './secondary-lot-type.enum';

export class LotResponseTaxation {
  @IsNumber()
  id: number;

  @IsNumber()
  rate: number;
}

export class LotResponseOption {
  @IsDate()
  expirationDate: Date;
}

export class LotResponseDto {
  @IsNumber()
  id: number;

  @IsString()
  lotNumber: string;

  @IsString()
  companyName: string;

  @IsString()
  programName: string;

  @IsString()
  programThumbnailPath: string;

  @IsString()
  lotPlanPath: string;

  @IsString()
  city: string;

  @IsString()
  departementCode: string;

  @IsDate()
  deliveryDate: Date;

  @IsNumber()
  rooms: number;

  @IsNumber()
  livingSpace: number;

  @IsNumber()
  floor: number;

  @IsObject()
  orientation: Orientation;

  @IsArray()
  areaTypes: AreaType[];

  @IsArray()
  secondaryLotTypes: SecondaryLotType[];

  @IsArray()
  taxations: LotResponseTaxation[];

  @IsNumber()
  commercialLotSellingPriceIT: number;

  @IsBoolean()
  hasSpecialOffer: boolean;

  @IsObject()
  activeOption: LotResponseOption;

  @IsNumber()
  reducedTotalSellingPriceIT: number;
}
