<div class="text-base mb-4 font-bold">
  {{ i18nService._('Title_GeneralInformations') }}
</div>

<!-- RCV List -->
<div class="flex flex-row mb-4">
  <app-dropdown-list-populated
    class="w-1/2"
    [parentForm]="parentForm"
    [searchable]="true"
    [items]="responsibleList"
    [placeholder]="i18nService._('Txt_Placeholder_AssiociatedManager')"
    [fieldName]="'responsible'"
    [isSubmit]="isSubmit"
    [required]="true"
    [dataToInit]="recoveredInfos"
  >
  </app-dropdown-list-populated>
</div>

<!-- Intracommunity VAT -->
<div class="flex flex-row mb-3">
  <mat-form-field class="w-1/2">
    <mat-label>{{ i18nService._('Txt_Placeholder_TVANumber') }}</mat-label>
    <input
      type="text"
      name="intracommunityVAT"
      data-cy="intracommunityVAT"
      [formControl]="intracommunityVATControl"
      maxlength="20"
      matInput
      (keyup)="checkIsNumber($event)"
    />
  </mat-form-field>
</div>

<!-- Accountings Reference  -->
<div class="flex flex-row mb-3">
  <mat-form-field class="w-1/2">
    <mat-label>{{ i18nService._('Txt_Placeholder_AccountingReference') }}</mat-label>
    <input
      type="text"
      name="accountingReference"
      [formControl]="accountingReferenceControl"
      maxlength="20"
      matInput
      (keyup)="checkIsNumber($event)"
    />
  </mat-form-field>
</div>

<!-- Mandate -->
<app-file-drop
  [fieldNameKey]="'Mandate'"
  [attachmentName]="i18nService._('Txt_Placeholder_Mandate')"
  [required]="false"
  [includingForm]="parentForm.controls.document"
  [isSubmit]="isSubmit"
  [initialValue]="initFile['Mandate']"
>
</app-file-drop>

<!-- isElectronicSignatureBI -->
<div class="flex flex-row mb-3">
  <mat-checkbox
    name="isElectronicSignatureBIControl"
    [formControl]="isElectronicSignatureBIControl"
    color="primary"
    class="flex w-1/2"
    data-cy="isElectronicSignatureBI"
    (change)="onElectronicSignatureChange($event, isElectronicSignatureValorissimoControl, false)"
  >
    {{ i18nService._('Txt_Checkbox_IsElectronicSignatureBI') }}
  </mat-checkbox>
</div>

<!-- isElectronicSignatureValorissimo -->
<div class="flex flex-col mb-3">
  <mat-checkbox
    name="isElectronicSignatureValorissimoControl"
    [formControl]="isElectronicSignatureValorissimoControl"
    [disabled]="!canESignValo()"
    color="primary"
    class="flex w-1/2"
    data-cy="isElectronicSignatureValorissimo"
    (change)="onElectronicSignatureChange($event, isElectronicSignatureBIControl, true)"
  >
    {{ i18nService._('Txt_Checkbox_IsElectronicSignatureValorissimo') }}
  </mat-checkbox>
  <span
    *ngIf="!(recoveredInfos?.company?.isEligibleDigitalSigning || companyData?.isEligibleDigitalSigning)"
    class="text-orange-300 pl-3 text-[11.2px] font-medium"
    >{{ i18nService._('Txt_Checkbox_IsElectronicSignatureValorissimo_Disabled') }}</span
  >
  <span class="text-red-500 pl-3 text-[11.2px] font-medium" *ngIf="displaySignatureErrorMessage">
    {{ i18nService._('Txt_Checkbox_IsElectronicSignatureValorissimo_Error') }}
  </span>
</div>

<!-- isHighStake -->
<div class="flex flex-row mb-3">
  <mat-checkbox name="isHighStake" [formControl]="isHighStake" color="primary" class="flex w-1/2">
    {{ i18nService._('Txt_Checkbox_IsHighStakeProgram') }}
  </mat-checkbox>
</div>

<!-- ApartmentContractualDocumentPackage -->
<!-- Change from Mo to Mio for maxFileSize (VALO-3657) -->
<app-file-drop
  *ngIf="!isElectronicSignatureValorissimo"
  [fieldNameKey]="'ApartmentContractualDocumentPackage'"
  [attachmentName]="i18nService._('Txt_Placeholder_ApartmentContractualDocumentPackage')"
  [required]="contractualDocumentPackagesBooleanList?.isApartmentContractualDocumentPackages"
  [includingForm]="parentForm.controls.document"
  [isSubmit]="isSubmit"
  [initialValue]="initFile['ApartmentContractualDocumentPackage']"
  [validExt]="['application/pdf']"
  [maxFileSize]="104857600"
>
</app-file-drop>

<!-- HouseContractualDocumentPackage -->
<!-- Change from Mo to Mio for maxFileSize (VALO-3657) -->
<app-file-drop
  *ngIf="!isElectronicSignatureValorissimo"
  [fieldNameKey]="'HouseContractualDocumentPackage'"
  [attachmentName]="i18nService._('Txt_Placeholder_HouseContractualDocumentPackage')"
  [includingForm]="parentForm.controls.document"
  [isSubmit]="isSubmit"
  [initialValue]="initFile['HouseContractualDocumentPackage']"
  [validExt]="['application/pdf']"
  [maxFileSize]="104857600"
>
</app-file-drop>

<!-- TradeContractualDocumentPackage -->
<!-- Change from Mo to Mio for maxFileSize (VALO-3657) -->
<app-file-drop
  *ngIf="!isElectronicSignatureValorissimo"
  [fieldNameKey]="'TradeContractualDocumentPackage'"
  [attachmentName]="i18nService._('Txt_Placeholder_TradeContractualDocumentPackage')"
  [includingForm]="parentForm.controls.document"
  [isSubmit]="isSubmit"
  [initialValue]="initFile['TradeContractualDocumentPackage']"
  [validExt]="['application/pdf']"
  [maxFileSize]="104857600"
>
</app-file-drop>

<div *ngIf="isElectronicSignatureValorissimo">
  <!-- Apartment Contract ApartmentReservationContractESignValo-->
  <app-file-drop
    [fieldNameKey]="'ApartmentReservationContractESignValo'"
    [attachmentName]="i18nService._('Txt_Placeholder_ApartmentReservationContractESignValo')"
    [includingForm]="parentForm.controls.document"
    [isSubmit]="isSubmit"
    [validExt]="['application/vnd.openxmlformats-officedocument.wordprocessingml.document']"
    [initialValue]="initFile['ApartmentReservationContractESignValo']"
  >
  </app-file-drop>

  <!-- House Contract HouseReservationContractESignValo-->
  <app-file-drop
    [fieldNameKey]="'HouseReservationContractESignValo'"
    [attachmentName]="i18nService._('Txt_Placeholder_HouseReservationContractESignValo')"
    [includingForm]="parentForm.controls.document"
    [isSubmit]="isSubmit"
    [validExt]="['application/vnd.openxmlformats-officedocument.wordprocessingml.document']"
    [initialValue]="initFile['HouseReservationContractESignValo']"
  >
  </app-file-drop>

  <!-- Trade Contract tradeReservationContractESignValo -->
  <app-file-drop
    [fieldNameKey]="'tradeReservationContractESignValo'"
    [attachmentName]="i18nService._('Txt_Placeholder_tradeReservationContractESignValo')"
    [includingForm]="parentForm.controls.document"
    [isSubmit]="isSubmit"
    [validExt]="['application/vnd.openxmlformats-officedocument.wordprocessingml.document']"
    [initialValue]="initFile['tradeReservationContractESignValo']"
  >
  </app-file-drop>
  <!-- Apartment descriptive notice with merging fields-->
  <app-file-drop
    [fieldNameKey]="'apartmentDescriptiveNoticeReservationContractESignValo'"
    [attachmentName]="i18nService._('Txt_Placeholder_ApartmentDescriptiveNoticeReservationContractESignValo')"
    [includingForm]="parentForm.controls.document"
    [isSubmit]="isSubmit"
    [validExt]="['application/vnd.openxmlformats-officedocument.wordprocessingml.document']"
    [initialValue]="initFile['apartmentDescriptiveNoticeReservationContractESignValo']"
  >
  </app-file-drop>

  <!-- House descriptive notice with merging fields-->
  <app-file-drop
    [fieldNameKey]="'houseDescriptiveNoticeReservationContractESignValo'"
    [attachmentName]="i18nService._('Txt_Placeholder_HouseDescriptiveNoticeReservationContractESignValo')"
    [includingForm]="parentForm.controls.document"
    [isSubmit]="isSubmit"
    [validExt]="['application/vnd.openxmlformats-officedocument.wordprocessingml.document']"
    [initialValue]="initFile['houseDescriptiveNoticeReservationContractESignValo']"
  >
  </app-file-drop>

  <!-- Trade descriptive notice with merging fields-->
  <app-file-drop
    [fieldNameKey]="'tradeDescriptiveNoticeReservationContractESignValo'"
    [attachmentName]="i18nService._('Txt_Placeholder_TradeDescriptiveNoticeReservationContractESignValo')"
    [includingForm]="parentForm.controls.document"
    [isSubmit]="isSubmit"
    [validExt]="['application/vnd.openxmlformats-officedocument.wordprocessingml.document']"
    [initialValue]="initFile['tradeDescriptiveNoticeReservationContractESignValo']"
  >
  </app-file-drop>

  <app-file-drop
    [fieldNameKey]="'lmnpMnpSignatorySpecificDocumentEsignValo'"
    [attachmentName]="i18nService._('Txt_Placeholder_LmnpMnpSignatorySpecificFilesEsignValo')"
    [includingForm]="parentForm.controls.document"
    [isSubmit]="isSubmit"
    [validExt]="['application/vnd.openxmlformats-officedocument.wordprocessingml.document']"
    [initialValue]="initFile['lmnpMnpSignatorySpecificDocumentEsignValo']"
  >
  </app-file-drop>

  <!-- Error message at least one uploaded when isElectronicSignatureValorissimo (tradeReservationContractESignValo or HouseReservationContractESignValo or ApartmentReservationContractESignValo)-->
  <mat-error class="flex w-full mb-3" *ngIf="parentForm.controls.document?.errors?.atLeastOne">
    {{ parentForm.controls.document?.errors?.atLeastOne }}
  </mat-error>
</div>
