<div class="text-base font-bold text-left mb-3">
  {{ i18nService._('Title_Form_SearchForm_Floor') }}
</div>
<div class="flex flex-row">
  <app-checkbox-populated
    [source]="floorItems"
    [sortMethod]="sortBy('id')"
    [(checkedElements)]="search.nbFloors"
    [cssClassNames]="'mat-checkbox-rounded-number  mr-3'"
    (checkedElementsChange)="setSelectedFloors($event)"
  >
  </app-checkbox-populated>
</div>
