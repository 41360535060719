import { Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

import { LotComparatorHeadOptionButtonComponent } from './lot-comparator-head-option-button/lot-comparator-head-option-button.component';

import { LotComparatorDetail } from '../../../../lot/model/lot-comparator-detail';
import { Sitemap } from '../../../../utils/models/Sitemap';
import { FileSystemService } from '../../../../utils/services/file-system.service';
import { AbstractComparatorComponent } from '../../abstract-comparator-component';
import { StrongPointListComponent } from '../../../../design-system/component/strong-point-list/strong-point-list.component';

@Component({
  selector: 'app-lot-comparator-head',
  templateUrl: './lot-comparator-head.component.html',
  styleUrls: ['./lot-comparator-head.component.scss'],
  standalone: true,
  imports: [NgIf, MatIconModule, LotComparatorHeadOptionButtonComponent, RouterLink, StrongPointListComponent],
})
export class LotComparatorHeadComponent extends AbstractComparatorComponent implements OnInit {
  @Input() lot: LotComparatorDetail;
  documentPerspective: string;
  programUrl: string;
  isReserved: boolean;

  constructor(private readonly _fileSystemService: FileSystemService) {
    super();
  }

  ngOnInit() {
    if (this.lot) {
      this.documentPerspective = this._fileSystemService.getLogo(this.lot.perspective);
      this.programUrl = `/${Sitemap.programs.read.path.replace(/:programId/, String(this.lot.programId))}`;
    }
  }
}
