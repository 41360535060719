/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import { CompanyData } from './../../utils/models/CompanyData';
import { TableLoader } from '../../table/interfaces/DataLoader';
import { SharedCompanyService } from './../../utils/services/shared-company.service';

@Injectable({
  providedIn: 'root',
})
export class MandateCompaniesSelectionTableService implements TableLoader<CompanyData> {
  constructor(private readonly sharedProgramService: SharedCompanyService) {}

  public async getMandateCompanies(id: number): Promise<CompanyData[]> {
    const response = await this.sharedProgramService.getActiveContractorAndIndependantCompanies(id, true);
    return response;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getById(id: number): Promise<any> {
    return null;
  }
}
