import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';

import { Sitemap } from '../utils/models/Sitemap';
import { AuthGuard } from '../security/authguard';
import { SignatureDashboardComponent } from './components/dashboard/signature-dashboard.component';
import { SignatureFormComponent } from './components/form/signature-form.component';
import { SignatureProgressComponent } from './components/signature-progress/signature-progress.component';
import { SynthesisComponent } from './components/synthesis/synthesis.component';
import { SignatureContractResolver } from './services/signature-contract.resolver.service';
import { SignatureIsDraftResolver } from './services/signature-draft.resolver.service';
import { SignatureIsNotDraftResolver } from './services/signature-no-draft.resolver.service';

const routes: Routes = [
  {
    path: Sitemap.signature.dashboard.path,
    component: SignatureDashboardComponent,
    resolve: { dossier: SignatureIsDraftResolver },
    canActivate: mapToCanActivate([AuthGuard]),
    data: {
      roles: Sitemap.signature.dashboard.data.roles,
    },
  },
  {
    path: Sitemap.signature.form.path,
    component: SignatureFormComponent,
    resolve: { dossier: SignatureIsDraftResolver },
    canActivate: mapToCanActivate([AuthGuard]),
    data: {
      roles: Sitemap.signature.form.data.roles,
    },
  },
  {
    path: Sitemap.signature.synthesis.path,
    component: SynthesisComponent,
    resolve: { data: SignatureContractResolver },
    canActivate: mapToCanActivate([AuthGuard]),
    data: {
      roles: Sitemap.signature.synthesis.data.roles,
    },
  },
  {
    path: Sitemap.signature.progress.path,
    component: SignatureProgressComponent,
    resolve: { dossier: SignatureIsNotDraftResolver },
    canActivate: mapToCanActivate([AuthGuard]),
    data: {
      roles: Sitemap.signature.progress.data.roles,
    },
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
  declarations: [],
})
export class SignatureRoutingModule {}
