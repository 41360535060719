import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';
import { isNil } from 'lodash-es';

import { FormlyFieldPropertiesService } from './formly-field-properties.service';

import { MetaExpressionService } from '../../utils/services/calcul-engine/meta-expression.service';

export const FormlyFieldClassExtention: FormlyExtension = {
  prePopulate(field: FormlyFieldConfig) {
    field.props = field.props ?? {};

    if (field.type === 'select' || field.type === 'select-multiple' || field.type === 'autocomplete') {
      field.props.dropdownIcon = field.props.dropdownIcon || 'material-symbols-outlined arrow-drop-down';
      field.props.placeholder = field.props.placeholder || ' ';
      field.props.showClear = isNil(field.props.showClear) ? true : field.props.showClear;
    }

    if (field.key) {
      field.props.hideRequiredMarker = true;
      field.className = field.className || field.fieldGroupClassName || 'mb-8 w-3/5';

      if (field.props.displayOptionalLabel) {
        const optionalHtml = ' <small>(facultatif)</small>';
        if (field?.props?.label && !field.props.label.includes(optionalHtml)) {
          field.props.label = field.props.label + optionalHtml;
        }
      }
    }

    if (field.props.hideMetaExpression) {
      field.expressions = field.expressions || {};
      field.expressions.hide = (f: FormlyFieldConfig) => {
        return MetaExpressionService.computeExpression(f.props.hideMetaExpression, f.model || f.form.value);
      };
    }

    if (field.props.metaExpression) {
      field.expressions = field.expressions || {};
      const keys = Object.keys(field.props.metaExpression);
      keys.forEach((key) => {
        field.expressions[key] = (f: FormlyFieldConfig) => {
          return FormlyFieldPropertiesService.computeMetaExpression(f.props.metaExpression[key], f.model || f.form.value, key, f);
        };
      });
    }
  },
};
