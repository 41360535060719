import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { NgIf } from '@angular/common';

import { I18nService } from './../../../utils/services/i18n.service';
import { CityService } from '../../../utils/services/city.service';
import { AutoCompleteService } from '../../form/auto-complete.service';
import { ServiceLocator } from '../../../utils/services/service-locator';
import { AppCountryService } from '../../../utils/services/app-country.service';
import { CompanyProgramSearch } from './../../../dossier-prospect/services/company-program-search.service';
import { DevCompanySearch } from './../../../dossier-prospect/services/dev-company-search.service';
import { DossierProspectSearch } from './../../../dossier-prospect/services/dossier-prospect-search.service';
import { DossierProspectByPrescriptorSearch } from './../../../dossier-prospect/services/dossier-prospect-by-prescriptor-search.service';
import { DeveloperCompanySearch } from '../../../pre-reservations/services/developer-company-search.service';
import { ProgramSearch } from '../../../pre-reservations/services/program.search.service';
import { ProgramLotSearch } from '../../../pre-reservations/services/program-lot-search.service';
import { SellerSearch } from '../../../pre-reservations/services/seller-search.service';
import { ProspectSearch } from '../../../pre-reservations/services/prospect-search.service';
import { ProspectLegacySearch } from '../../../pre-reservations/services/prospect-legacy-search.service';
import { LocalisationService } from '../../../utils/services/localisation.service';

interface AutoCompleteServices {
  [key: string]: AutoCompleteService;
}

@Component({
  selector: 'app-formly-auto-complete',
  templateUrl: './formly-auto-complete.component.html',
  styleUrls: ['./formly-auto-complete.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, AutoCompleteModule, FormsModule, ReactiveFormsModule],
})
export class FormlyAutoCompleteComponent extends FieldType {
  public items = [];
  private autoCompleteServices: AutoCompleteServices;

  constructor(
    private readonly detectorRef: ChangeDetectorRef,
    public i18nService: I18nService,
  ) {
    super();
    this.autoCompleteServices = {
      city: ServiceLocator.injector.get<CityService>(CityService),
      country: ServiceLocator.injector.get<AppCountryService>(AppCountryService),
      dossierProspect: ServiceLocator.injector.get<DossierProspectSearch>(DossierProspectSearch),
      dossierProspectByPrescriptor: ServiceLocator.injector.get<DossierProspectByPrescriptorSearch>(DossierProspectByPrescriptorSearch),
      devCompany: ServiceLocator.injector.get<DevCompanySearch>(DevCompanySearch),
      devCompanyProgram: ServiceLocator.injector.get<CompanyProgramSearch>(CompanyProgramSearch),
      developerCompany: ServiceLocator.injector.get<DeveloperCompanySearch>(DeveloperCompanySearch),
      program: ServiceLocator.injector.get<ProgramSearch>(ProgramSearch),
      programLot: ServiceLocator.injector.get<ProgramLotSearch>(ProgramLotSearch),
      seller: ServiceLocator.injector.get<SellerSearch>(SellerSearch),
      prospect: ServiceLocator.injector.get<ProspectSearch>(ProspectSearch),
      prospectLegacy: ServiceLocator.injector.get<ProspectLegacySearch>(ProspectLegacySearch),
      localisation: ServiceLocator.injector.get<LocalisationService>(LocalisationService),
    };
  }

  search($event) {
    const field = this.field as any;
    this.autoCompleteServices[this.field.props.autoCompleteService].findData($event.query, field._observers).subscribe((result) => {
      if (!this.props.passObject) {
        this.items = result.map((result) => result.label);
      } else {
        this.items = result;
      }
      this.detectorRef.detectChanges();
    });
  }

  onClear() {
    this.field.formControl.setValue(null);
  }
}
