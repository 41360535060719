import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { findIndex } from 'lodash';
import { MatTabsModule } from '@angular/material/tabs';
import { NgIf } from '@angular/common';

import { CompanyTrackingTableTabComponent } from './companies-tracking-table-tab/companies-tracking-table-tab.component';

import { UserRoleService } from './../../../utils/services/user-role.service';
import { CompanyType } from '../../../utils/models/enums/CompanyType.enum';
import { Sitemap } from '../../../utils/models/Sitemap';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { RoutingStateService } from '../../../utils/services/routing-state.service';
import { AbstractTrackingTable } from '../../abstract-tracking-table';
import { AdvancedTrackingTableColumn } from '../../models/AdvancedTrackingTableColumn';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';

export interface CompanyTab {
  type: string;
  title: string;
  label: string;
  companyType: string;
  roles: Array<string>;
  columns: Array<AdvancedTrackingTableColumn>;
  serviceParams: string;
}

@Component({
  selector: 'app-companies-tracking-table',
  templateUrl: './companies-tracking-table.component.html',
  styleUrls: ['./companies-tracking-table.component.scss'],
  standalone: true,
  imports: [StickyHeaderFormComponent, NgIf, CompanyTrackingTableTabComponent, MatTabsModule],
})
export class CompaniesTrackingTableComponent extends AbstractTrackingTable {
  /**
   * Default selected index tab.
   * @type {number}
   */
  public selectedTabIndex = 0;
  companyType = CompanyType;

  /**
   * Default tabs configurations.
   * @type {Array<any>}
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public configTabs: Array<any>;

  /**
   * tabs attribute
   * @type {Array<CompanyTab>}
   */
  public tabs: Array<CompanyTab>;

  /**
   * Tabs configuration.
   * @type {Array<string>}
   */
  public tabsTypes = [CompanyType.DEVELOPER, CompanyType.CONTRACTOR];

  /**
   * actionButtonTooltipText attribute
   * @type {string}
   */
  public actionButtonTooltipText: string;

  /**
   * Display or not button to redirect to create a company
   * @type {boolean}
   */
  public canCreateCompany: boolean;

  /**
   * Url to go to clicking on the goBack button.
   * @type {string}
   */
  public goBackUrl;

  public displayDeveloperTab: boolean;
  public displayContractorTab: boolean;
  public displayIndependantTab: boolean;
  public displaySingleTab = false;

  public title: string;

  /**
   * Creates an instance of CompaniesTrackingTableComponent.
   * @param {AppConfigService} appConfigService
   * @param {I18nService} i18nService
   * @param {Router} router
   * @param {ActivatedRoute} _route
   * @param {RoutingStateService} _routingStateService
   * @param {UserRoleService} userRoleService
   */
  constructor(
    private readonly appConfigService: AppConfigService,
    public readonly i18nService: I18nService,
    public readonly router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _routingStateService: RoutingStateService,
    private readonly userRoleService: UserRoleService,
  ) {
    super(_route.snapshot.routeConfig.path, router);

    const roles = {
      DEVELOPER: [
        appConfigService.appConfig.roles.valoSystem,
        appConfigService.appConfig.roles.valoAdminSuper,
        appConfigService.appConfig.roles.valoAdminSimple,
        appConfigService.appConfig.roles.valoBoSuper,
        appConfigService.appConfig.roles.valoBoSimple,
        appConfigService.appConfig.roles.valoDevSuper,
        appConfigService.appConfig.roles.valoDevSimple,
      ],
      CONTRACTOR: [
        appConfigService.appConfig.roles.valoSystem,
        appConfigService.appConfig.roles.valoAdminSuper,
        appConfigService.appConfig.roles.valoAdminSimple,
        appConfigService.appConfig.roles.valoBoSuper,
        appConfigService.appConfig.roles.valoBoSimple,
        appConfigService.appConfig.roles.valoRcvSuper,
        appConfigService.appConfig.roles.valoRcvSimple,
      ],
      INDEPENDANT: [
        appConfigService.appConfig.roles.valoSystem,
        appConfigService.appConfig.roles.valoAdminSuper,
        appConfigService.appConfig.roles.valoAdminSimple,
        appConfigService.appConfig.roles.valoBoSuper,
        appConfigService.appConfig.roles.valoBoSimple,
        appConfigService.appConfig.roles.valoRcvSuper,
      ],
    };

    this.displayDeveloperTab = userRoleService.isInRoles(roles.DEVELOPER);
    this.displayContractorTab = userRoleService.isInRoles(roles.CONTRACTOR);
    this.displayIndependantTab = userRoleService.isInRoles(roles.INDEPENDANT);

    if (this.displayDeveloperTab && !this.displayContractorTab && !this.displayIndependantTab) {
      this.title = i18nService._('Title_TrackingTable_DeveloperCompanies');
    } else if (!this.displayDeveloperTab && this.displayContractorTab && !this.displayIndependantTab) {
      this.title = i18nService._('Title_TrackingTable_ContractorCompanies');
    } else if (!this.displayDeveloperTab && !this.displayContractorTab && this.displayIndependantTab) {
      this.title = i18nService._('Title_TrackingTable_IndependantCompanies');
    } else {
      this.title = i18nService._('Title_TrackingTable_Companies');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const configTabs: Array<any> = [];
    if (this.displayDeveloperTab)
      configTabs.push({
        type: CompanyType.DEVELOPER,
        title: 'Title_TrackingTable_DeveloperCompanies',
        path: Sitemap.companies.adminDeveloper.path,
      });
    if (this.displayContractorTab)
      configTabs.push({
        type: CompanyType.CONTRACTOR,
        title: 'Title_TrackingTable_ContractorCompanies',
        path: Sitemap.companies.adminContractor.path,
      });
    if (this.displayIndependantTab)
      configTabs.push({
        type: CompanyType.INDEPENDANT,
        title: 'Title_TrackingTable_IndependantCompanies',
        path: Sitemap.companies.adminIndependant.path,
      });
    this.configTabs = configTabs;

    let nbTab = 0;
    if (this.displayDeveloperTab) nbTab++;
    if (this.displayContractorTab) nbTab++;
    if (this.displayIndependantTab) nbTab++;
    if (nbTab == 1) {
      this.displaySingleTab = true;
    }

    this._routingStateService.previousRoutePath.pipe(take(1)).subscribe((previousRoutePath) => {
      this.goBackUrl = previousRoutePath;
    });

    if (this._route.snapshot.data.type) {
      this.selectedTabIndex = findIndex(this.configTabs, (tab) => tab.type === this._route.snapshot.data.type);
    }
    this.canCreateCompany = !userRoleService.isValoMarketer() && !userRoleService.isValoDev() && !userRoleService.isValoRcv();
  }

  /**
   * Create a company.
   */
  public createCompany(): void {
    this.router.navigate([Sitemap.companies.register.path]);
  }

  /**
   * Change value of the title in the sub-header when changing tab.
   *
   * @param event
   */
  public changeAndReloadTab(event: number): void {
    this.selectedTabIndex = event;
    const newUrl = this._processUrl();
    this.router.navigate([newUrl], {
      queryParamsHandling: '',
      replaceUrl: true,
    });
  }

  private _processUrl() {
    return this.configTabs[this.selectedTabIndex].path;
  }
}
