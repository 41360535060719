import { Component } from '@angular/core';

import { CardComponent } from '../card/card.component';

@Component({
  selector: 'app-horizontal-card',
  templateUrl: './horizontal-card.component.html',
  styleUrls: ['./horizontal-card.component.scss'],
  standalone: true,
  imports: [CardComponent],
})
export class HorizontalCardComponent {}
