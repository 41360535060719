export class LocationData {
  cities: Array<string>;
  departments: Array<number>;
  regions: Array<number>;
  agglomerations: Array<number>;

  constructor() {
    this.cities = [];
    this.departments = [];
    this.regions = [];
    this.agglomerations = [];
  }
}
