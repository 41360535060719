<app-sticky-header-form [titleText]="i18nService._('Title_DossierTrackingTable')"></app-sticky-header-form>

<div class="pt-18 mx-3 lg:mx-5 md:mx-5 sm:mx-4 px-2 lg:px-6 md:px-5 sm:px-3">
  <div class="grid grid-cols-2 mb-3">
    <div class="grid grid-cols-8 gap:4 lg:gap-6">
      <div class="col-span-4">
        <mat-form-field class="fullW flex w-full">
          <mat-label>Recherche</mat-label>
          <input [(ngModel)]="filterValue" (ngModelChange)="textFilterSubject.next($event)" matInput />
        </mat-form-field>
      </div>

      <mat-form-field class="fullW flex w-full my-0 col-span-2">
        <mat-label>Statut</mat-label>
        <mat-select data-cy="Statut" [ngModel]="selectedStatus" (selectionChange)="onStatusListChange($event)" multiple>
          <mat-option *ngFor="let status of statusFilterList" [value]="status.value" [attr.data-cy]="status.label">
            {{ i18nService._(status.label) }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="flex h-full items-center col-span-2">
        <app-toggle-button (selectChange)="changeTeamFilter($event)" *ngIf="displayTeamButton" [checked]="isTeamMemberParam">
          <span class="font-bold">{{ i18nService._('Txt_TrackingTable_MY_TEAM') }}</span>
        </app-toggle-button>
        <app-toggle-button (selectChange)="changePartnerFilter($event)" *ngIf="displayPartnerButton" [checked]="isPartnerParam">
          <span class="font-bold">{{ i18nService._('Txt_TrackingTable_MY_PARTNERS') }}</span>
        </app-toggle-button>
      </div>
    </div>

    <div class="flex justify-end" *ngIf="displayDocaposteSignatureButton">
      <button (click)="accessDocapostSignatures()" mat-stroked-button type="button" class="border-orange-600 text-orange-600 h-10">
        {{ i18nService._('Txt_Button_ReservationsAccessDocapostSignatures') | uppercase }}
      </button>
    </div>
  </div>

  <div class="tracking-mat-table">
    <app-grid-tracking-table
      [columnDefs]="columnDefs"
      [tableLoader]="dossierTrackingTableService"
      [pageSize]="pageSize"
      [defaultSortDirection]="sortDirection"
      [defaultSortColunm]="sortColumn"
      [textFilter]="textFilterValue"
      [i18nFilter]="i18nFilter"
      [isPartner]="isPartnerParam"
      [isTeamMember]="isTeamMemberParam"
      [minimumFilterLength]="minimumFilterLength"
    >
    </app-grid-tracking-table>
  </div>
</div>
