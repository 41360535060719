<mat-form-field>
  <mat-label [ngClass]="{ 'txt-grey': isDisable }">{{ i18nService._(filterSelectOption.placeholder) }}</mat-label>
  <mat-icon
    *ngIf="filterSelectOption.tooltip"
    [matTooltip]="i18nService._(filterSelectOption.tooltip.text)"
    matPrefix
    class="text-base material-icons-outlined mr-1"
    [ngClass]="{ 'txt-grey': isDisable }"
    >{{ filterSelectOption.tooltip.icon }}
  </mat-icon>
  <mat-select [formControl]="selectFormControl" (selectionChange)="selectedItem()">
    <mat-option [value]="undefined">&nbsp;</mat-option>
    <mat-option *ngFor="let item of tableItems" [value]="item.id">
      {{ item.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
