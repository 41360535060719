import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { ComparatorMeta, ComparatorMetaWrapper, ComparatorPageAllDataMeta } from '../models/comparator-meta';
import { ComparatorPageService } from '../services/comparator-page.service';

import { LotService } from '../../lot/service/lot.service';
import { AppConfigService } from '../../utils/services/app-config.service';
import { TokenService } from '../../utils/services/authorisation/token.service';
import { I18nService } from '../../utils/services/i18n.service';
import { ServiceLocator } from '../../utils/services/service-locator';
import { UserRoleService } from '../../utils/services/user-role.service';

@Directive()
export class AbstractComparatorComponent implements OnDestroy {
  protected readonly $d = new Subject();
  protected _comparatorPageService: ComparatorPageService;
  private readonly _comparatorPageAllDataMeta: ComparatorPageAllDataMeta;
  public i18nService: I18nService;

  protected readonly _appConfigService: AppConfigService;
  private readonly _tokenService: TokenService;
  private readonly _userRoleService: UserRoleService;
  protected isAuthorizedToManageOption: boolean;
  protected isAuthorizedToManageOnlyReservation: boolean;
  protected readonly _lotService: LotService;
  private _lotToCompare: number[];

  constructor() {
    this._comparatorPageService = ServiceLocator.injector.get<ComparatorPageService>(ComparatorPageService);
    this.i18nService = ServiceLocator.injector.get(I18nService);

    this._appConfigService = ServiceLocator.injector.get(AppConfigService);
    this._tokenService = ServiceLocator.injector.get<TokenService>(TokenService);
    this._userRoleService = ServiceLocator.injector.get<UserRoleService>(UserRoleService);
    this._lotService = ServiceLocator.injector.get<LotService>(LotService);

    this.isAuthorizedToManageOption = this._userRoleService.isAuthorizedToManageOption();
    this.isAuthorizedToManageOnlyReservation = this._userRoleService.isAuthorizedToManageOnlyReservation();
  }

  get lotToCompare(): number[] {
    return this._lotToCompare;
  }

  set lotToCompare(value: number[]) {
    this._lotToCompare = value;
  }

  get comparatorPageAllDataMeta(): ComparatorPageAllDataMeta {
    return this._comparatorPageAllDataMeta;
  }

  ngOnDestroy(): void {
    this.$d.next(true);
    this.$d.complete();
  }

  protected castToComparatorMeta(field: string, lotMeta: ComparatorMetaWrapper) {
    return lotMeta[field] as ComparatorMeta;
  }
}
