import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandsSeparatorPipe',
  standalone: true,
})
export class ThousandsSeparatorPipe implements PipeTransform {
  transform(value: string | number, thousandsSeparator = ' ', decimalSeparator = ','): string {
    // If value is string but NaN then return original value;
    if (value) {
      const valueSplittted = String(value).split('.');
      const thousandRegex = /\B(?=(\d{3})+(?!\d))/g;
      valueSplittted[0] = valueSplittted[0].replace(thousandRegex, thousandsSeparator);

      return valueSplittted.join(decimalSeparator);
    }

    return '';
  }
}
