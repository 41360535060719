import { Pipe, PipeTransform } from '@angular/core';

import { AppConfigService } from '../services/app-config.service';

@Pipe({
  name: 'sortPipe',
  standalone: true,
})
export class SortPipe implements PipeTransform {
  constructor(public appConfig: AppConfigService) {}

  other = this.appConfig.getAppConfig().other;

  // eslint-disable-next-line @typescript-eslint/ban-types
  transform(items: Array<Object>, field: string, order = 'ASC'): Array<Object> {
    // prevent Cannot convert undefined or null to object
    if (items !== undefined && items !== null) {
      return [].sort.call(items).sort((a, b) => {
        const multiplicator = order === 'ASC' ? 1 : -1;
        if (a[field] === this.other || b[field] === this.other) {
          return -1;
        }
        if (order === 'ASC') {
          if (a[field] < b[field]) {
            return multiplicator * -1;
          }
          if (a[field] > b[field]) {
            return multiplicator * 1;
          }
        }
      });
    }
  }
}
