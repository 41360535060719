import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { CompleteDossierResponse, DossierStatusCode, ProspectType } from '@commons-dto/dossier-prospect';
import { Subject } from 'rxjs';

import { DossierProspectDashboardCommentComponent } from './dossier-prospect-dashboard-comment/dossier-prospect-dashboard-comment.component';
import { DossierProspectDashboardCompletionComponent } from './dossier-prospect-dashboard-completion/dossier-prospect-dashboard-completion.component';
import { DossierProspectDashboardSearchComponent } from './dossier-prospect-dashboard-search/dossier-prospect-dashboard-search.component';
import { DossierProspectDashboardSimulationComponent } from './dossier-prospect-dashboard-simulation/dossier-prospect-dashboard-simulation.component';
import { DossierProspectDashboardStatusComponent } from './dossier-prospect-dashboard-status/dossier-prospect-dashboard-status.component';
import { DossierProspectDashboardOptionComponent } from './dossier-prospect-dashboard-option/dossier-prospect-dashboard-option.component';

import { UtilsModule } from '../../../utils/utils.module';
import { OptionForDossierProspectDashboard } from '../../../utils/models/OptionResponse';
import { OptionApiService } from '../../../adapters/option-api.service';

@Component({
  selector: 'app-dossier-prospect-dashboard',
  standalone: true,
  templateUrl: './dossier-prospect-dashboard.component.html',
  styleUrls: ['./dossier-prospect-dashboard.component.scss'],
  imports: [
    CommonModule,
    UtilsModule,
    DossierProspectDashboardCommentComponent,
    DossierProspectDashboardCompletionComponent,
    DossierProspectDashboardSearchComponent,
    DossierProspectDashboardSimulationComponent,
    DossierProspectDashboardStatusComponent,
    DossierProspectDashboardStatusComponent,
    DossierProspectDashboardCommentComponent,
    DossierProspectDashboardCompletionComponent,
    DossierProspectDashboardSimulationComponent,
    DossierProspectDashboardSearchComponent,
    DossierProspectDashboardOptionComponent,
  ],
})
export class DossierProspectDashboardComponent implements OnInit {
  @Input() isCompany: boolean;
  @Input() isDossierComplete: boolean;
  @Input() comment: string;
  @Input() actualStep: DossierStatusCode;
  activeOptions: OptionForDossierProspectDashboard[];
  options: OptionForDossierProspectDashboard[];
  private _dossier: CompleteDossierResponse;

  @Input() searchPreferencesUpdated: Subject<void>;
  @Input() simulationUpdated: Subject<void>;

  displayCompletion: boolean;
  displaySimulation: boolean;
  displaySearch: boolean;
  displayCommentary: boolean;
  displayOption: boolean;

  @Input()
  get dossier(): CompleteDossierResponse {
    return this._dossier;
  }

  set dossier(value: CompleteDossierResponse) {
    if (value.id === this._dossier?.id) return;
    if (value != null) {
      this._dossier = value;
      const isCompany = !!this._dossier.prospects.find((prospect) => prospect.prospectType === ProspectType.COMPANY);
      const step = this._dossier.dossierStatusCode;
      this.setCardsToDisplay(isCompany, step);
    }
  }

  constructor(private readonly optionApiService: OptionApiService) {}

  async ngOnInit(): Promise<void> {
    const options = await this.optionApiService.getOptionsWithOptionLotPriceByDossierProspectId(this.dossier.id);
    this.activeOptions = options.filter((option) => option.isActive);
    this.options = options;
  }

  setCardsToDisplay(isCompany: boolean, step: DossierStatusCode) {
    this.displayCompletion = false; // Disabled for now
    this.displaySimulation = !isCompany && step == DossierStatusCode.DISCOVER;
    this.displaySearch = true;
    this.displayCommentary = step == DossierStatusCode.DISCOVER || step == DossierStatusCode.IDENTIFICATION;
    this.displayOption = true;
  }
}
