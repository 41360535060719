<div class="flex flex-col px-4 xl-min-vw-65 lg-min-vw-65 md-min-vw-45 sm-min-vw-32">
  <h2 class="text-center" mat-dialog-title>{{ i18nService._('Title_Exclude_list_Dialog') }} {{ data.corporateName }}</h2>
  <div mat-dialog-content>
    <app-exclude-list-tracking-table
      (selectedExcludeList)="setSelectedExcludeIds($event)"
      [companyId]="data.companyId"
    ></app-exclude-list-tracking-table>
  </div>
  <div class="flex w-full justify-end text-center mb-3" mat-dialog-actions>
    <button class="mr-1" mat-stroked-button color="primary" title="{{ i18nService._('Txt_Button_Cancel') }}" (click)="close()">
      {{ i18nService._('Txt_Button_Cancel') }}
    </button>
    <button class="ml-1" mat-flat-button color="primary" type="submit" title="{{ i18nService._('Txt_Button_Save') }}" (click)="submit()">
      {{ i18nService._('Txt_Button_Save') }}
    </button>
  </div>
</div>
