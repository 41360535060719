/* eslint-disable @typescript-eslint/no-explicit-any */

import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColDef, IRowNode, IsRowSelectable } from 'ag-grid-community';

import { SelectionTrackingTableComponent } from '../selection-tracking-table/selection-tracking-table.component';

import { GridTrackingTableComponent } from './../grid-tacking-table/grid-tracking-table.component';
import { MandateCompaniesSelectionTableService } from './../../services/mandate-companies-selection-table.service';
import { MandateStrategySelectionTableDataType } from '../../../strategies/components/enums/mandate-strategy-selection-table-data-type.enum';
import { BasicFormatsService } from './../../../utils/services/basic-formats.service';
import { TableLoader, TableResponse } from './../../../table/interfaces/DataLoader';
import { I18nService } from './../../../utils/services/i18n.service';
import { DEFAULT_CHECK_BOX_SELECTABLE_DEF } from '../../models/constants/tracking-table-item-definition.constants';

@Component({
  selector: 'app-mandate-companies-selection-table',
  templateUrl: 'mandate-companies-selection-table.component.html',
  styleUrls: ['mandate-companies-selection-table.component.scss'],
  standalone: true,
  imports: [SelectionTrackingTableComponent],
})
export class MandateCompaniesSelectionTableComponent implements OnInit {
  @ViewChild(GridTrackingTableComponent)
  gridTrackingTable: GridTrackingTableComponent;

  @Output() companyIds: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Input() mandateStrategyForm: UntypedFormGroup;

  preSelectedRows: any[];
  rowsControl: AbstractControl;
  pageSize = 10;
  pageNumber = 1;
  tableHeight = 'auto';
  columnDefs: ColDef[];
  tableLoader: TableLoader<any>;

  @ViewChild(SelectionTrackingTableComponent)
  selectionTrackingTableComponent: SelectionTrackingTableComponent;

  isRowSelectable: IsRowSelectable = (params: IRowNode<any>) => {
    return !(params.data.excludeByContractorLists && params.data.excludeByContractorLists.length);
  };

  constructor(
    public readonly i18nService: I18nService,
    private readonly mandateCompaniesSelectionTableService: MandateCompaniesSelectionTableService,
    private readonly route: ActivatedRoute,
    private readonly basicFormatsService: BasicFormatsService,
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.initializeGrid();
  }

  private initializeForm() {
    this.mandateStrategyForm.addControl(MandateStrategySelectionTableDataType.COMPANIES, new UntypedFormControl([], Validators.required));
    this.rowsControl = this.mandateStrategyForm.controls[MandateStrategySelectionTableDataType.COMPANIES];
    this.initializeSelectedRows();
  }

  private initializeSelectedRows(): void {
    this.mandateStrategyForm.controls[MandateStrategySelectionTableDataType.COMPANIES].valueChanges.subscribe((value) => {
      this.preSelectedRows = value;
    });
  }

  setCompanyIds(): void {
    this.companyIds.emit(this.selectionTrackingTableComponent.rowsId);
  }

  private initializeGrid() {
    const programId = this.getProgramId();
    this.tableLoader = this.createTableLoader(programId);
    this.columnDefs = this.createColumnDefs();
  }

  private createTableLoader(programId: number): TableLoader<any> {
    const mandateCompaniesSelectionTableService = this.mandateCompaniesSelectionTableService;
    return {
      async get(): Promise<TableResponse<any>> {
        const mandateStrategry: any[] = await mandateCompaniesSelectionTableService.getMandateCompanies(programId);
        return { items: mandateStrategry };
      },
    };
  }

  private createColumnDefs(): ColDef[] {
    return [
      {
        field: 'id',
        hide: true,
        filter: 'agNumberColumnFilter',
      },
      DEFAULT_CHECK_BOX_SELECTABLE_DEF,
      {
        field: 'siret',
        headerName: this.i18nService._('Txt_Table_Column_Siret'),
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'corporateName',
        headerName: this.i18nService._('Txt_Table_Column_CorporateName'),
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'groupCompany',
        headerName: this.i18nService._('Txt_Table_Column_GroupCompany'),
        valueFormatter: (params) => {
          return params.value && params.value.label ? params.value.label : '-';
        },
        minWidth: 60,
        flex: 2,
      },
      {
        field: 'responsible',
        headerName: this.i18nService._('Txt_Table_Column_RCV'),
        valueFormatter: (params) => {
          return params.value
            ? (params.value.lastName ? params.value.lastName : '') +
                (params.value.lastName && params.value.firstName ? ' ' : '') +
                (params.value.firstName ? params.value.firstName : '')
            : undefined;
        },
        minWidth: 120,
        flex: 4,
      },
      {
        field: 'city',
        headerName: this.i18nService._('Txt_Table_Column_City'),
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'mandat',
        headerName: this.i18nService._('Txt_Table_Column_Fees'),
        valueFormatter: (params) => {
          return params.value && params.value.remuneration
            ? this.basicFormatsService.formatPercentage(params.value.remuneration / 100)
            : '-';
        },
        minWidth: 40,
        flex: 2,
      },
    ];
  }

  private getProgramId(): number {
    return parseInt(this.route.snapshot.paramMap.get('programId'), 10);
  }

  reset(): void {
    this.selectionTrackingTableComponent.reset();
  }
}
