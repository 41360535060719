import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';

import { TranslateListPipe } from '../utils/pipes/translate-list.pipe';
import { UtilsModule } from '../utils/utils.module';
import { CompaniesRoutingModule } from './companies-routing.module';
import { InitFormCompanyComponent } from './components/init-form-company/init-form-company.component';
import { RegisterFormCompanyComponent } from './components/register-form-company/register-form-company.component';
import { StepFourFormCompanyComponent } from './components/step-four-form-company/step-four-form-company.component';
import { StepOneFormCompanyComponent } from './components/step-one-form-company/step-one-form-company.component';
import { StepThreeFormCompanyComponent } from './components/step-three-form-company/step-three-form-company.component';
import { StepTwoFormCompanyComponent } from './components/step-two-form-company/step-two-form-company.component';
import { AppFormModule } from '../common/form/app-form.module';

@NgModule({
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    FormsModule,
    CompaniesRoutingModule,
    UtilsModule,
    AppFormModule,
    InitFormCompanyComponent,
    RegisterFormCompanyComponent,
    StepOneFormCompanyComponent,
    StepTwoFormCompanyComponent,
    StepThreeFormCompanyComponent,
    StepFourFormCompanyComponent,
    TranslateListPipe,
  ],
})
export class CompaniesModule {}
