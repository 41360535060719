import { Pipe, PipeTransform } from '@angular/core';

import { ReferenceTableData } from '../models/ReferenceTableData';
import { I18nService } from '../services/i18n.service';

@Pipe({
  name: 'translateList',
  standalone: true,
})
export class TranslateListPipe implements PipeTransform {
  constructor(public i18nService: I18nService) {}

  transform(itemList: Array<ReferenceTableData>): Array<ReferenceTableData> {
    if (itemList !== undefined) {
      itemList.forEach((item: ReferenceTableData) => {
        item.label = this.i18nService._(item.label);
      });
    }

    return itemList;
  }
}
