<div class="flex flex-col">
  <mat-radio-group class="flex flex-row" [formControl]="radioButtonControl" [disabled]="isDisabled">
    <mat-radio-button
      color="primary"
      *ngFor="let referenceTableInfo of referenceTableInfos"
      [value]="referenceTableInfo.id"
      [attr.data-cy]="referenceTableInfo.label"
    >
      {{ referenceTableInfo.label }}
    </mat-radio-button>
  </mat-radio-group>
  <mat-error class="pl-3" *ngIf="radioButtonControl.errors && isSubmit">
    {{ i18nService._('Error_Field_Required') }}
  </mat-error>
</div>
