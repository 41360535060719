import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-box-header',
  templateUrl: './box-header.component.html',
  styleUrls: ['./box-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf],
})
export class BoxHeaderComponent implements OnChanges {
  /**
   * Variable for formControl
   *
   * @type {FormControl}
   * @memberof BoxInputComponent
   */
  @Input() formControl: UntypedFormControl;

  /**
   * Variable for box label
   *
   * @type {string}
   * @memberof BoxInputComponent
   */
  @Input() label: string;

  /**
   * Variable for box subLabel
   *
   * @type {string}
   * @memberof BoxInputComponent
   */
  @Input() subLabel: string;

  /**
   * Label for the tag
   *
   * @type {string}
   * @memberof BoxInputComponent
   */
  @Input() tagLabel: string;

  constructor(private detectorRef: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'formControl') {
        this.label = changes[propName]?.currentValue.value ?? this.label;
        this.detectorRef.detectChanges();
      }
    }
  }
}
