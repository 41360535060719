import { Injectable } from '@angular/core';

import { TaxationApiService } from '../adapters/taxation-api.service';
import { TaxationResponse } from '../utils/models/TaxationResponse';

@Injectable({
  providedIn: 'root',
})
export class TaxationService {
  taxations: TaxationResponse[];

  constructor(private readonly taxationApiService: TaxationApiService) {}

  public async getTaxations(): Promise<TaxationResponse[]> {
    if (!this.taxations) {
      await this.loadTaxations();
    }
    return this.taxations;
  }

  private async loadTaxations(): Promise<void> {
    this.taxations = await this.taxationApiService.getTaxations();
  }
}
