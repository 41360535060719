import { CompleteDossierResponse, DossierResponse } from '@commons-dto/dossier-prospect';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ProspectType } from 'libs/commons-dto/src/lib/dossier-prospect/prospect/prospect-type.enum';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { NgFor, NgIf } from '@angular/common';

import { DossierProspectRouteService } from './dossier-prospect.route.service';
import { StatusColorPipe } from './pipes/status-color.pipe';
import { TransactionComponent } from '../transaction/transaction.component';
import { LotComponent } from '../lot/lot.component';
import { SearchPreferenceComponent } from '../search-preference/search-preference.component';
import { SimulationTabComponent } from '../simulation-tab/simulation-tab.component';
import { PaternitiesListComponent } from '../paternity-list/paternities-list.component';
import { DossierProspectDashboardComponent } from '../dashboard/dossier-prospect-dashboard.component';

import { TranslatePipe } from '../../../utils/pipes/translate.pipe';
import { DossierProspectTabType } from '../../model/dossier-prospect-tab-type.enum';
import { AppIconsName } from '../../../common/standalone/icon/utils/app-icon-names';
import { DossierProspectService } from './../../services/dossier-prospect.service';
import { I18nService } from './../../../utils/services/i18n.service';
import { SpinnerWithBackdropService } from './../../../utils/services/spinner-with-backdrop.service';
import { StatusTagComponent } from '../../../common/shared/status-tag/status-tag.component';
import { PageHeaderComponent } from '../../../common/shared/page-header/page-header.component';
import { SiderComponent } from '../../../common/shared/sider/sider.component';
import { BoxBackButtonComponent } from '../../../common/shared/box-back-button/box-back-button.component';

@Component({
  selector: 'app-dossier-prospect',
  templateUrl: './dossier-prospect.component.html',
  styleUrls: ['./dossier-prospect.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    BoxBackButtonComponent,
    SiderComponent,
    NgFor,
    PageHeaderComponent,
    StatusTagComponent,
    TabViewModule,
    SharedModule,
    DossierProspectDashboardComponent,
    PaternitiesListComponent,
    TooltipModule,
    SimulationTabComponent,
    SearchPreferenceComponent,
    LotComponent,
    TransactionComponent,
    TranslatePipe,
    StatusColorPipe,
  ],
})
export class DossierProspectComponent implements OnInit {
  dossier: CompleteDossierResponse;
  dossierId: number;
  step: string;
  isDossierComplete: boolean;
  isCompany = false;
  header: SafeHtml;
  icon: AppIconsName;
  _activeTab: DossierProspectTabType;

  public get activeTab(): DossierProspectTabType {
    return this._activeTab;
  }

  public set activeTab(activeTab: DossierProspectTabType) {
    switch (activeTab) {
      case DossierProspectTabType.DASHBOARD:
        this.dossierProspectRouteService.goToDashboard(this.dossierId, true);
        break;
      case DossierProspectTabType.SIMULATION:
        this.dossierProspectRouteService.goToSimulation(this.dossierId, true);
        break;
      case DossierProspectTabType.PATERNITIES:
        this.dossierProspectRouteService.goToPaternities(this.dossierId, true);
        break;
      case DossierProspectTabType.TRANSACTIONS:
        this.dossierProspectRouteService.goToTransactions(this.dossierId, true);
        break;
      case DossierProspectTabType.LOTS:
        this.dossierProspectRouteService.goToLots(this.dossierId, true);
        break;
      case DossierProspectTabType.SEARCH_PREFERENCES:
        this.dossierProspectRouteService.goToSearchPreferences(this.dossierId, true);
        break;
    }
    this._activeTab = activeTab;
  }

  public searchPreferencesUpdated: Subject<boolean> = new Subject();
  public simulationUpdated: Subject<void> = new Subject();

  constructor(
    private readonly _route: ActivatedRoute,
    public readonly _i18n: I18nService,
    private readonly _prospect: DossierProspectService,
    private readonly _spinner: SpinnerWithBackdropService,
    private readonly dossierProspectRouteService: DossierProspectRouteService,
    private readonly sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this._spinner.show();
    this.dossierId = Number(this._route.snapshot.params.id);
    this._prospect.getDossier(this.dossierId, true, false).subscribe(async (dossier) => {
      this.dossier = dossier as CompleteDossierResponse;
      this.isCompany = this.checkIfProspectIsCompany();
      this.isDossierComplete = this.checkIfDossierIsComplete(this.dossier);
      this.step = this.dossier.dossierStatusCode;
      this.header = this.sanitizer.bypassSecurityTrustHtml(this._prospect.getDashboardHeader(this.dossier));
      this._spinner.hide();
    });
    this.activeTab = this._route.snapshot.data.activeTab;
  }

  goBack() {
    return () => this.dossierProspectRouteService.goToList();
  }

  edit() {
    this.dossierProspectRouteService.goToEdit(this.dossierId, true);
  }

  onTabChanged(event) {
    this.activeTab = event.index;
  }

  private checkIfDossierIsComplete(dossier: DossierResponse): boolean {
    return dossier.prospects.every((prospect) => {
      return prospect.country && prospect.city && prospect.postalCode;
    });
  }

  private checkIfProspectIsCompany(): boolean {
    return !!this.dossier.prospects.find((prospect) => prospect.prospectType === ProspectType.COMPANY);
  }
}
