import { Component, Input, TemplateRef } from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';

import { I18nService } from '../../../utils/services/i18n.service';
import { AppItem } from '../../model/app-item';
import { CssClass } from '../../../utils/constantes/types';
import { SafePipe } from '../../../utils/pipes/safe.pipe';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, SafePipe],
})
export class ItemComponent {
  @Input() optionTemplate: TemplateRef<unknown>;
  @Input() item: AppItem;
  @Input() itemContainerClass: CssClass;
  @Input() itemLabelClass: CssClass;
  @Input() itemValueClass: CssClass;

  constructor(public i18nService: I18nService) {}
}
