import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';

import { InputListOption } from '../filter-sort-header-bar/models/input-list-option';

@Component({
  selector: 'app-sort-dropdown',
  standalone: true,
  imports: [FormsModule, DropdownModule],
  templateUrl: './sort-dropdown.component.html',
  styleUrls: ['./sort-dropdown.component.scss'],
})
export class SortDropdownComponent {
  @Input() sortOptions: InputListOption;

  @Output() selectedOptionChange = new EventEmitter<any[]>();

  selectedValue = null;

  onSelectedOptionChange(): void {
    this.selectedOptionChange.emit(this.selectedValue);
  }
}
