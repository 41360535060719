<form *ngIf="fields" [formGroup]="form" class="w-full">
  <formly-form
    #formlyForm
    [fields]="fields"
    [form]="form"
    [model]="model"
    [options]="options"
    (modelChange)="onChange($event)"
  ></formly-form>
</form>
