import { Injectable } from '@angular/core';

import { AbstractAdvancedTrackingTableNoBackPageService } from './abstract-advanced-tracking-table-no-back-page.service';

import { Activity } from '../../activities/models/Activity';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesTrackingTableService extends AbstractAdvancedTrackingTableNoBackPageService<Activity[]> {
  protected url = 'Activities/get-activities';
}
