import { Component, OnInit } from '@angular/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';

import { I18nService } from './../../../utils/services/i18n.service';

@Component({
  selector: 'app-formly-calendar',
  templateUrl: './formly-calendar.component.html',
  styleUrls: ['./formly-calendar.component.scss'],
  standalone: true,
  imports: [CalendarModule, FormsModule, ReactiveFormsModule, FormlyModule],
})
export class FormlyCalendarComponent extends FieldType implements OnInit {
  constructor(public i18nService: I18nService) {
    super();
  }
  ngOnInit() {
    if (this.formControl.value) {
      this.formControl.setValue(new Date(this.formControl.value));
    }
  }
}
