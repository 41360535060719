/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ValoRole } from '@commons-dto/valo-back';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf, UpperCasePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

import { GridTrackingTableComponent } from '../grid-tacking-table/grid-tracking-table.component';

import { Dossier } from '../../../signature/models/Dossier';
import { GoogleTagManagerService } from './../../../utils/services/google-tag-manager.service';
import { SignatureDocumentService } from '../../../signature/services/signature-document.service';
import { DossierTrackingTableService } from '../../services/dossiers-tracking-table.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { StatusTagsRendererComponent } from '../../renderers/status-tags-renderer/status-tags-renderer.component';
import { DateRendererComponent } from '../../renderers/date-renderer/date-renderer.component';
import { DossierStatusEnum } from '../../../utils/enums/statusEnums/dossier-status.enum';
import { StatusTagColor } from '../../../design-system/model/status-tag-color.enum';
import { ActionRendererComponent } from '../../renderers/action-renderer/action-renderer.component';
import { Sitemap } from '../../../utils/models/Sitemap';
import { ReservationService } from '../../../reservations/services/reservation.service';
import { SignatureService } from '../../../signature/services/signature.service';
import { UserRoleService } from '../../../utils/services/user-role.service';
import { QuestionDialogComponent } from '../../../dialog/components/question-dialog/question-dialog.component';
import { QuestionDialogData } from '../../../dialog/models/QuestionDialogData';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { AbstractTrackingTable } from '../../abstract-tracking-table';
import { FilterEventOutput } from '../../models/filter/filter-event-output';
import { ToggleButtonComponent } from '../../../design-system/component/toggle-button/toggle-button.component';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';
import { TokenService } from '../../../utils/services/authorisation/token.service';

const tagTypeByDossierStatus: { [key in DossierStatusEnum]: StatusTagColor } = {
  DRAFT: StatusTagColor.IN_PROGRESS,
  SENDING: StatusTagColor.IN_PROGRESS,
  SIGN_ACQ: StatusTagColor.IN_PROGRESS,
  REJECT_ACQ: StatusTagColor.CRITICAL,
  COUNTERSIGN: StatusTagColor.IN_PROGRESS,
  COUNTERSIGNED: StatusTagColor.SUCCESS,
  REJECT_DEV: StatusTagColor.CRITICAL,
  ERROR: StatusTagColor.CRITICAL,
  CANCELLED: StatusTagColor.CRITICAL,
  SIGN_PAPER: StatusTagColor.IN_PROGRESS,
  EXPIRED: StatusTagColor.CRITICAL,
};

const cancellableForEditStatus = [
  DossierStatusEnum.COUNTERSIGN,
  DossierStatusEnum.ERROR,
  DossierStatusEnum.EXPIRED,
  DossierStatusEnum.REJECT_ACQ,
  DossierStatusEnum.REJECT_DEV,
  DossierStatusEnum.SIGN_ACQ,
  DossierStatusEnum.SIGN_PAPER,
];

@Component({
  selector: 'app-dossier-tracking-table',
  templateUrl: './dossier-tracking-table.component.html',
  styleUrls: ['./dossier-tracking-table.component.scss'],
  standalone: true,
  imports: [
    StickyHeaderFormComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    NgIf,
    ToggleButtonComponent,
    MatButtonModule,
    GridTrackingTableComponent,
    UpperCasePipe,
  ],
})
export class DossierTrackingTableComponent extends AbstractTrackingTable implements OnInit {
  @ViewChild(GridTrackingTableComponent)
  gridTrackingTable: GridTrackingTableComponent;
  serviceUrl: any;
  pageSize = 10;
  pageNumber = 1;
  sortDirection: 'ASC' | 'DESC' = 'DESC';
  sortColumn = 'createdAt';
  columnDefs: ColDef[];
  i18nFilter: any[];
  statusFilterList = [
    { value: 'DRAFT', label: 'STATUS_DOSSIER_DRAFT' },
    { value: 'SENDING', label: 'STATUS_DOSSIER_SENDING' },
    { value: 'SIGN_ACQ', label: 'STATUS_DOSSIER_SIGN_ACQ' },
    { value: 'REJECT_ACQ', label: 'STATUS_DOSSIER_REJECT_ACQ' },
    { value: 'COUNTERSIGN', label: 'STATUS_DOSSIER_COUNTERSIGN' },
    { value: 'COUNTERSIGNED', label: 'STATUS_DOSSIER_COUNTERSIGNED' },
    { value: 'REJECT_DEV', label: 'STATUS_DOSSIER_REJECT_DEV' },
    { value: 'ERROR', label: 'STATUS_DOSSIER_ERROR' },
    { value: 'CANCELLED', label: 'STATUS_DOSSIER_CANCELLED' },
    { value: 'SIGN_PAPER', label: 'STATUS_DOSSIER_SIGN_PAPER' },
    { value: 'EXPIRED', label: 'STATUS_DOSSIER_EXPIRED' },
  ];
  filterValue: string;
  selectedStatus: any;
  minimumFilterLength = 3;
  filterUrlParam: FilterEventOutput;
  displayTeamButton: boolean;
  displayPartnerButton: boolean;
  isPartnerParam: boolean;
  isTeamMemberParam: boolean;
  displayDocaposteSignatureButton: boolean;
  textFilterSubject = new Subject<string>();
  textFilterValue: string;

  constructor(
    public readonly dossierTrackingTableService: DossierTrackingTableService,
    public readonly i18nService: I18nService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly reservationService: ReservationService,
    private readonly userRoleService: UserRoleService,
    private readonly signatureDocumentService: SignatureDocumentService,
    private readonly dialog: MatDialog,
    private readonly signatureService: SignatureService,
    private readonly snackbarService: SnackbarService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly tokenService: TokenService,
  ) {
    super(Sitemap.dashboards.dossier.path, router);
    this.columnDefs = [
      {
        field: 'createdAt',
        headerName: this.i18nService._('Title_DossierTrackingTable_Column_CreationDate'),
        cellRenderer: DateRendererComponent,
        sort: 'desc',
        minWidth: 80,
        flex: 2,
        cellRendererParams: {
          format: 'DD/MM/yyyy',
        },
      },
      {
        field: 'developerName',
        headerName: this.i18nService._('Title_DossierTrackingTable_Column_DeveloperName'),
        minWidth: 100,
        flex: 5,
      },
      {
        field: 'lot',
        headerName: this.i18nService._('Title_DossierTrackingTable_Column_Lot'),
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'program',
        headerName: this.i18nService._('Title_DossierTrackingTable_Column_Program'),
        minWidth: 100,
        flex: 5,
      },
      {
        field: 'city',
        headerName: this.i18nService._('Title_DossierTrackingTable_Column_City'),
        minWidth: 100,
        flex: 4,
      },
      {
        field: 'acquirer',
        headerName: this.i18nService._('Title_DossierTrackingTable_Column_Acquirer'),
        minWidth: 100,
        flex: 6,
        valueFormatter: (params) => {
          const acquirers = [params.data?.acquirer, params.data?.coAcquirer].filter(Boolean).join(' et ') || params.value;
          return acquirers;
        },
      },
      {
        field: 'prescriptorName',
        headerName: this.i18nService._('Title_DossierTrackingTable_Column_PrescriptorName'),
        minWidth: 100,
        flex: 4,
      },
      {
        field: 'status',
        headerName: this.i18nService._('Title_DossierTrackingTable_Column_Status'),
        minWidth: 150,
        flex: 5,
        cellRenderer: StatusTagsRendererComponent,
        cellRendererParams: {
          tagsList: tagTypeByDossierStatus,
          outline: true,
        },
        valueFormatter: (params) => {
          return this.statusFilterList.find((status) => status.value === params.value)?.label ?? params.value;
        },
      },
      {
        field: 'actions',
        headerName: this.i18nService._('Title_DossierTrackingTable_Column_Actions'),
        cellRenderer: ActionRendererComponent,
        cellRendererParams: {
          actionItems: (item: any) => {
            return this.getActionItems(item);
          },
        },
        minWidth: 60,
        maxWidth: 60,
        flex: 2,
        cellStyle: { paddingRight: '10px' },
        sortable: false,
      },
    ];
  }

  ngOnInit(): void {
    this.displayTeamButton = this.userRoleService.isInRoles([ValoRole.valoRcvSuper]);
    this.displayPartnerButton = this.userRoleService.isInRoles([ValoRole.valoRcvSimple]);
    const token = this.tokenService.getToken();
    this.displayDocaposteSignatureButton = !this.userRoleService.isIndependant() && !(this.userRoleService.isDeveloper() && !token.isBi);

    this.setFilterValue();
    this.onTextFilterChange();
  }

  setFilterValue() {
    const partner = JSON.parse(this.route.snapshot.queryParamMap.get('isPartner'));
    this.isPartnerParam = Boolean(this.displayPartnerButton && partner === null ? true : partner);
    this.isTeamMemberParam = Boolean(JSON.parse(this.route.snapshot.queryParamMap.get('isTeamMember')));
    this.filterValue = this.route.snapshot.queryParamMap.get('filter');
    this.textFilterValue = this.route.snapshot.queryParamMap.get('filter');
    this.selectedStatus = this.route.snapshot.queryParamMap.get('status')?.split(',');
    this.i18nFilter = [{ key: 'status', value: this.selectedStatus }];
    const i18nFilter = this.selectedStatus?.length ? { status: this.selectedStatus } : null;

    this.filterUrlParam = {
      i18nFilter: i18nFilter,
      filter: this.filterValue,
      isPartner: this.isPartnerParam,
      isTeamMember: this.isTeamMemberParam,
    };
    super.filterEvent(this.filterUrlParam);
  }

  getActionItems(item: any): any[] {
    const actions = [];

    if (!this.userRoleService.isDeveloper()) {
      if (item?.status === DossierStatusEnum.DRAFT) {
        actions.push(this.getContinueDossier());
      } else if (item?.status === DossierStatusEnum.CANCELLED) {
        actions.push(this.seeCancelReason());
      } else {
        actions.push(this.getSeeDossier());
      }
      if (cancellableForEditStatus.includes(item?.status)) {
        actions.push(this.getModify());
      }
    }

    if (item?.status === DossierStatusEnum.COUNTERSIGNED) {
      actions.push(this.getDownloadDossier());
    } else if (!this.userRoleService.isBo() && !this.userRoleService.isDeveloper() && item?.status !== DossierStatusEnum.CANCELLED) {
      actions.push(this.getCancelDossier());
    }

    return actions;
  }

  accessDocapostSignatures(): void {
    this.reservationService.accessElectronicSignatures().subscribe(({ url }) => {
      window.open(url, '_blank');
    });
  }

  private confirmAndCancelDossier(item: any): void {
    const dossierCancelationPopin = this.signatureService.openCancelAndModfifyModal(item.status, item.id, true, true);
    dossierCancelationPopin.afterClosed().subscribe((dossier: Dossier) => {
      if (dossier) {
        this.snackbarService.infoI18n('txt_signature_cancel_modal_dossier_success');
        this.gridTrackingTable.updateSingleDataRow(item.id, dossier);
      }
    });
  }

  onTextFilterChange() {
    this.textFilterSubject.pipe(debounceTime(this.textFilterDebounceTimer), distinctUntilChanged()).subscribe((value) => {
      this.filterUrlParam.filter = value;
      super.filterEvent(this.filterUrlParam);
      this.textFilterValue = value;
    });
  }

  onStatusListChange(statusList: any) {
    this.i18nFilter = [{ key: 'status', value: statusList.value }];
    const filter = statusList?.value.length ? { status: statusList.value } : null;
    this.filterUrlParam.i18nFilter = filter;
    super.filterEvent(this.filterUrlParam);
  }

  changeTeamFilter(event: boolean): void {
    this.isTeamMemberParam = event;
    this.filterUrlParam.isTeamMember = this.isTeamMemberParam;
    super.filterEvent(this.filterUrlParam);
  }

  changePartnerFilter(event: boolean): void {
    this.isPartnerParam = event;
    this.filterUrlParam.isPartner = this.isPartnerParam;
    super.filterEvent(this.filterUrlParam);
  }

  private getContinueDossier() {
    return {
      label: 'Txt_continueDossier',
      action: (item: any) => {
        return this.router.navigate([Sitemap.signature.dashboard.path.replace(':signatureId', item.id)]);
      },
    };
  }

  private getSeeDossier() {
    return {
      label: 'Txt_noEditDossier',
      action: (item: any) => {
        return this.router.navigate([Sitemap.signature.dashboard.path.replace(':signatureId', item.id)]);
      },
    };
  }

  private getCancelDossier() {
    return {
      label: 'Txt_cancelDossier',
      action: (item: any) => this.confirmAndCancelDossier(item),
    };
  }

  private getDownloadDossier() {
    return {
      label: 'txt_downloadLiasseContreSignee',
      action: (item: any) => {
        this._googleTagManagerService.pushTag({ event: 'esignature_download_files' });
        this.signatureDocumentService.downloadBundleDocuments(item.signedContract);
        return Promise.resolve(true);
      },
    };
  }

  private seeCancelReason() {
    return {
      label: 'txt_see_cancel_reason',
      action: (item: any) => {
        const data: QuestionDialogData = {
          title: this.i18nService._('txt_popin_see_cancel_reason_title'),
          message: this.i18nService._('txt_popin_see_cancel_reason_reason', [item.cancelComment]),
          buttonCancelHidden: true,
          buttonConfirm: this.i18nService._('txt_popin_see_cancel_reason_close'),
        };
        this.dialog.open(QuestionDialogComponent, { data });
      },
    };
  }

  private getModify() {
    return {
      label: 'Txt_editDossier',
      action: (item: any) => {
        this.signatureService.openCancelAndModfifyModal(item.status, item.id, true);
      },
    };
  }
}
