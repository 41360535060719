import { TransactionResponse } from '@commons-dto/dossier-prospect';
import { lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { ListResponse } from '@commons-dto/valo-back';

import { DataLayerApiService } from './data-layer-api.service';

@Injectable({ providedIn: 'root' })
export class TransactionApiService extends DataLayerApiService {
  BASE_URL = 'prospect/';

  /**
   * Get transactions by params
   *
   * @returns {Observable<ListResponse<TransactionResponse>>}
   * @memberof DataLayerApiService
   */
  getDossierProspect(dossierProspectId: number): Promise<ListResponse<TransactionResponse>> {
    const url = this.BASE_URL + 'dossiers/' + dossierProspectId + '/transactions';
    return lastValueFrom(this.get(url));
  }
}
