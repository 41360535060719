<div *ngIf="formView" class="w-full h-full pt-10 px-8">
  <div class="flex w-full mx-auto max-w-7xl gap-8 xl:gap-10 h-full">
    <div class="flex flex-col w-[300px] min-w-[300px]">
      <ng-container>
        <app-box-back-button [goBack]="goBack()" [label]="'txt_return'" class="mb-6"></app-box-back-button>
        <app-side-info [formView]="formView"></app-side-info>
      </ng-container>
    </div>
    <div class="flex flex-col flex-1 gap-x-8">
      <!-- Header -->
      <app-form-header [formView]="formView" class="flex bg-white h-22 w-full rounded-10 shadow-custom mb-6"></app-form-header>
      <!-- Category -->
      <app-category [categories]="formView?.categories" [dossierId]="dossierId" class="flex flex-col steps-list xl:mb-3"></app-category>
      <!-- Comment -->
      <div class="flex flex-col gap-3 xl:flex-row xl:gap-2 mb-8">
        <app-comment [comment]="formView.internalComments" class="w-full xl:w-7/12"></app-comment>
        <!-- Document -->
        <app-signature-document
          (documentDialogClose)="refreshDossier(dossierId)"
          [documents]="dashboardDocuments"
          [dossierId]="dossierId"
          class="w-full xl:w-5/12"
        >
        </app-signature-document>
      </div>
      <div class="flex flex-row justify-end mb-8">
        <button (click)="accessSynthesisPage()" [disabled]="!isFormCompleted(formView)" class="preview-button rounded-button" type="button">
          {{ i18nService._('txt_button_preview') }}
        </button>
      </div>
    </div>
    <div class="w-[300px] hidden xl:block"></div>
  </div>
</div>
