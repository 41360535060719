import { Injector, NgModule } from '@angular/core';

import { ServiceLocator } from './utils/services/service-locator';

@NgModule()
export class LocatorModule {
  constructor(private readonly injector: Injector) {
    ServiceLocator.injector = injector;
  }
}
