export enum Target2SellEnum {
  TARGET_2_SELL_DEFAULT = 'I4H2P2VEFO0L24-BBBB-CC',
  TARGET_2_SELL_SEARCH = 'I4H2P2VEFO0L24-1000-1',
  TARGET_2_SELL_PROGRAM = 'I4H2P2VEFO0L24-1201-20',
  TARGET_2_SELL_LOT = 'I4H2P2VEFO0L24-1200-3',
  TARGET_2_SELL_RECO_PAGE_SECTION1 = 'I4H2P2VEFO0L24-2200-9',
  TARGET_2_SELL_RECO_PAGE_SECTION2 = 'I4H2P2VEFO0L24-2200-51',
  TARGET_2_SELL_RECO_PAGE_SECTION3 = 'I4H2P2VEFO0L24-2200-52',
  TARGET_2_SELL_SELECTION_HOMEPAGE = 'I4H2P2VEFO0L24-1002-82',
  TARGET_2_SELL_NEW_PROGRAM_HOMEPAGE = 'I4H2P2VEFO0L24-1002-72',
  TARGET_2_SELL_REGION_HOMEPAGE = 'I4H2P2VEFO0L24-1002-74',
  TARGET_2_SELL_T3_HOMEPAGE = 'I4H2P2VEFO0L24-1003-80',
  TARGET_2_SELL_T4_HOMEPAGE = 'I4H2P2VEFO0L24-1003-84',
}
