export class ProgramUpdate {
  actable?: string;
  address: string;
  city: string;
  cityInseeCode: string;
  companyId: number;
  countryId: number;
  document: {
    ApartmentReservationContract?: File;
    CommercialBrochure: File;
    descriptiveNotice: File;
    gfaAttestation?: File;
    groundPlan: File;
    HouseReservationContract?: File;
    KBIS?: File;
    perspective: File;
    presentationOfTheProgram?: File;
    tradeReservationContract: File;
    Mandate: File;
  };
  isFrench: boolean;
  latitude: string;
  longitude: string;
  nbLots: string;
  postalCode: string;
  programName: string;
  programRef: string;
  programTypeId: number;
  programText: [
    {
      access?: string;
      longDescrValo?: string;
      quality?: string;
      services?: string;
      shortDescrValo?: string;
      situation?: string;
      description?: string;
    },
  ];
  taxZoneId: number;
  url3DView?: string;
  digitalCommercialBrochureUrl?: string;
  reponsibleId: number;
  intracommunityVAT?: string;
  accountingReference?: string;
  programStatus: string;
  message?: string;
  documentsId: Array<number>;
  formData?: FormData;
  isNotMandatoryInfos?: boolean;
  isElectronicSignatureBI: boolean;
  isElectronicSignatureValorissimo: boolean;
}
