import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { IconComponent } from '../../../../common/standalone/icon/icon.component';
import { I18nService } from '../../../../utils/services/i18n.service';
import { SignatureService } from '../../../services/signature.service';
import { Sitemap } from '../../../../utils/models/Sitemap';
import { Dossier } from '../../../models/Dossier';

export interface ModalCancelSignatureData {
  /* dossierId if cancelOnly is true or
     if isDossierId is true,
     signatureId otherwise */
  dossierOrSignatureId: number;
  isDossierId?: boolean;
  cancelOnly?: boolean;
  hasNotToVoidNecessary?: boolean;
  keyForTitle: string;
  keyForMainMessage: string;
  keyForCommentLabel: string;
  keyForConfirming: string;
  keyForCancelling: string;
}

@Component({
  selector: 'app-modal-cancel-and-modify',
  templateUrl: './modal-cancel-and-modify.component.html',
  styleUrls: ['./modal-cancel-and-modify.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatDialogModule, InputTextareaModule, IconComponent],
})
export class ModalCancelSignatureComponent implements OnInit {
  public form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: ModalCancelSignatureData,
    public dialogRef: MatDialogRef<ModalCancelSignatureComponent>,
    public readonly i18nService: I18nService,
    public readonly signatureService: SignatureService,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      comment: [
        undefined,
        {
          validators: [Validators.required],
        },
      ],
    });
  }

  closeDialog(answer: boolean) {
    this.dialogRef.close(answer);
  }

  cancelAndModify() {
    this.dialogRef.close();
    this.signatureService
      .cancelAndModify(
        this.data.dossierOrSignatureId,
        {
          ...this.form.value,
          hasNotToVoidNecessary: this.data.hasNotToVoidNecessary,
        },
        this.data.isDossierId,
      )
      .subscribe((signature) => {
        this.router.navigate([Sitemap.signature.dashboard.path.replace(/:signatureId/, `${signature.dossierId}`)]);
      });
  }

  cancel() {
    this.signatureService
      .cancelDossierSignature(this.data.dossierOrSignatureId, this.form.get('comment').value as string)
      .subscribe((cancelledDossier: Dossier) => {
        if (cancelledDossier) this.dialogRef.close(cancelledDossier);
      });
  }
}
