export function sanitize(stringToSanityze) {
  return stringToSanityze
    .normalize('NFD')
    .replace(/\W+/g, '') // remove all non-word char
    .toLowerCase();
}

export function isObject(v: unknown) {
  return v != null && typeof v === 'object' && !Array.isArray(v);
}
