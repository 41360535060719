export const SPECIAL_OFFER_TYPE: { [key: string]: SpecialOfferType } = {
  NOTAIRE: { id: 1, label: 'Frais de notaire offerts' },
  REMISE: { id: 2, label: 'Remise de prix' },
  CUISINE: { id: 3, label: 'Cuisine offerte' },
  ECHEANTIER: { id: 4, label: 'Echéancier préférentiel' },
  CHEQUE: { id: 5, label: 'Chèques cadeaux aménagement' },
  OTHER: { id: 6, label: 'Autre' },
};

export class SpecialOfferType {
  public id: number;
  public label: string;
}
