import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, Subscription } from 'rxjs';
import { NgFor } from '@angular/common';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { I18nService } from './../../../utils/services/i18n.service';
import { LocalisationService } from '../../../utils/services/localisation.service';
import { CitiesLocationData } from '../../../utils/models/CitiesLocationData';
import { CitiesLocationResponse } from '../../../utils/models/CitiesLocationResponse';
import { propertiesLocalisation } from '../../../utils/models/app-constant';
import { IconComponent } from '../../standalone/icon/icon.component';

@Component({
  selector: 'app-formly-localisation-city-area',
  templateUrl: './formly-localisation-city-area.component.html',
  styleUrls: ['./formly-localisation-city-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AutoCompleteModule, FormsModule, ReactiveFormsModule, NgFor, IconComponent],
})
export class FormlyLocalisationCityAreaComponent extends FieldType implements OnInit, OnDestroy {
  public localisations = [];
  public selectedLocalisations: LocalisationEntry[] = [];
  public txtInput: string | undefined;
  public autoCompleteFormControl: FormControl = new UntypedFormControl();
  public noResultMessage: string;
  private valueChangesSub: Subscription;

  constructor(
    private readonly detectorRef: ChangeDetectorRef,
    private readonly localisationService: LocalisationService,
    readonly i18nService: I18nService,
  ) {
    super();
    this.noResultMessage = i18nService._('Txt_No_Result');
  }

  ngOnInit(): void {
    this.valueChangesSub = this.autoCompleteFormControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter((value) => this.canSearch(value)),
      )
      .subscribe((value) => {
        this.search(value);
      });

    const value = this.field.formControl.getRawValue();
    if (value) this.selectedLocalisations = this.field.formControl.getRawValue();
  }

  canSearch(value: string): boolean {
    return value && value.length >= 2;
  }

  search($event: { query: string }) {
    if ($event?.query?.length < 2) {
      return;
    }
    const data: CitiesLocationData = {
      filter: $event.query,
      language: navigator.languages[0] || navigator.language,
    };
    this.localisationService.searchLocalisation(data).subscribe((localisationsResponse: CitiesLocationResponse) => {
      this.localisations = this.getLocalisations(localisationsResponse);
      this.detectorRef.detectChanges();
    });
  }

  private getLocalisations(localisationsResponse: CitiesLocationResponse): LocalisationEntry[] {
    let localisations: LocalisationEntry[] = [];
    for (const city of localisationsResponse.cities) {
      localisations.push({
        label: `${city.label} (${city.zipcode})`,
        typeLocation: propertiesLocalisation.city,
      });
    }
    for (const department of localisationsResponse.departments) {
      localisations.push({ label: `${department.label} (${department.code})`, typeLocation: propertiesLocalisation.department });
    }
    for (const region of localisationsResponse.regions) {
      localisations.push({ label: region.label, typeLocation: propertiesLocalisation.region });
    }
    // Exclude selected items
    localisations = localisations.filter((localisation) => {
      return !this.selectedLocalisations.find((l) => l.label === localisation.label && l.typeLocation === localisation.typeLocation);
    });
    return localisations;
  }

  selectItem(event) {
    const localisation = event.value;
    this.txtInput = undefined;
    this.selectedLocalisations.push(localisation);
    this.setValue();
  }

  removeLocalisation(localisation: LocalisationEntry) {
    this.selectedLocalisations = this.selectedLocalisations.filter((l) => l.label !== localisation.label);
    this.setValue();
  }

  setValue() {
    this.formControl.setValue(this.selectedLocalisations);
  }

  onClear() {
    this.field.formControl.setValue(null);
  }

  ngOnDestroy(): void {
    if (this.valueChangesSub) this.valueChangesSub.unsubscribe();
  }
}

export class LocalisationEntry {
  label: string;
  typeLocation: string;
}
