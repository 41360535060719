import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { ConfigurableParamService } from '../../services/configurable-param.service';
import { I18nService } from '../../services/i18n.service';
import { ScriptService } from '../../services/script-loader.service';

@Component({
  selector: 'app-cityscan-map',
  templateUrl: './cityscan-map.component.html',
  styleUrls: ['./cityscan-map.component.scss'],
  standalone: true,
  imports: [MatIconModule],
})
export class CityscanMapComponent implements OnInit {
  @ViewChild('refChild', { static: true }) refChild: ElementRef;

  @Input() programId: string;

  constructor(
    private readonly scriptService: ScriptService,
    private readonly renderer: Renderer2,
    private readonly _configurableParamService: ConfigurableParamService,
    public readonly i18nService: I18nService,
  ) {}

  async ngOnInit() {
    try {
      const scriptLoadingResult = await this.scriptService.loadScript('CITY_SCAN');
      if (
        !scriptLoadingResult ||
        !scriptLoadingResult.loaded ||
        (scriptLoadingResult.status !== 'Loaded' && scriptLoadingResult.status !== 'Already Loaded')
      ) {
        throw new Error('Script cant be loaded');
      }

      this._configurableParamService.getConfigParam('CITYSCAN_PUBLIC_KEY').subscribe((cityscanPublicKey) => {
        if (cityscanPublicKey) {
          // Script loaded with succes
          this.addElement(cityscanPublicKey['CITYSCAN_PUBLIC_KEY']);

          window.document.dispatchEvent(
            new Event('DOMContentLoaded', {
              bubbles: false,
              cancelable: true,
            }),
          );
        } else {
          throw new Error('Cityscan public key is not defined');
        }
      });
    } catch (errorLoadingCityScanScript) {
      console.error('Cityscan script cant be loaded because', errorLoadingCityScanScript);
    }
  }

  addElement(cityscanPublicKey: string): void {
    const divCityScan: HTMLDivElement = this.renderer.createElement('div');
    divCityScan.setAttribute('id', 'cityscan-widget');
    divCityScan.setAttribute('data-ext-address-id', this.programId);
    divCityScan.setAttribute('data-client-key', cityscanPublicKey);
    divCityScan.style.height = '-webkit-fill-available';
    this.renderer.appendChild(this.refChild.nativeElement, divCityScan);

    return;
  }
}
