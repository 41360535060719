export class ParamRequest {
  serviceParams?: string;
  filter?: string;
  statusFilter?: any;
  pageNum?: number;
  offset?: number;
  nbResult?: number;
  sortColumn?: string;
  sortDirection?: string;
  i18nFilters?: any;
  selfFilters?: string;
  isTeamMember?: boolean;
  isPartner?: boolean;
  columns?: string[];
}
