import { ConfigParamResponse } from './ConfigParamResponse';
import { DocumentResponse } from './DocumentResponse';
import { FooterContact } from './footerContact';

export class LoggedFooterResponse {
  configParams: Array<ConfigParamResponse>;
  documents: Array<DocumentResponse>;
  contractorContactInfo: FooterContact = new FooterContact();
  developerContactInfo: FooterContact = new FooterContact();
  commercialContact: FooterContact = new FooterContact();
  mandateContact: FooterContact = new FooterContact();
  billingContact: FooterContact = new FooterContact();
}
