<div class="app-alert">
  <div class="app-alert-border" [ngClass]="config[type].styleClass">&nbsp;</div>
  <div class="app-alert-content">
    <div class="app-alert-icon">
      <span class="material-icons" [ngClass]="config[type].styleClass">{{ config[type].iconName }}</span>
    </div>
    <div class="app-alert-body">
      <div class="app-alert-title" *ngIf="title">
        {{ _i18nService._(title) }}
      </div>
      <div class="app-alert-text" [innerHtml]="_i18nService._(body) | safe: 'html'"></div>
    </div>
  </div>
</div>
