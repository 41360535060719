import { Pipe, PipeTransform } from '@angular/core';

import { AdvancedTrackingTableColumn } from '../models/AdvancedTrackingTableColumn';
import { AdvancedTrackingTableColumnType } from '../models/advanced-tracking-table-column-type.enum';

import { BasicFormatsService } from '../../utils/services/basic-formats.service';

@Pipe({
  name: 'advancedTrackingTablePipe',
  standalone: true,
})
export class AdvancedTrackingTablePipe implements PipeTransform {
  constructor(private readonly basicFormatsService: BasicFormatsService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any, column: AdvancedTrackingTableColumn): string {
    switch (column.type) {
      case AdvancedTrackingTableColumnType.Currency:
        if (!value && column.replaceFalsyWithDash) {
          return ' - ';
        }

        if (isNaN(value)) {
          return value;
        }

        // eslint-disable-next-line no-case-declarations
        const currencyParameters = {
          ...column.parameters,
          currencyCode: undefined,
          minIntegerDigits: 0,
          minFractionDigits: 2,
          maxFractionDigits: 2,
        };

        return this.basicFormatsService.formatCurrency(
          value,
          currencyParameters.currencyCode,
          currencyParameters.minIntegerDigits,
          currencyParameters.minFractionDigits,
          currencyParameters.maxFractionDigits,
        );

      case AdvancedTrackingTableColumnType.Date:
        if (!value && column.replaceFalsyWithDash) {
          return ' - ';
        }

        // eslint-disable-next-line no-case-declarations
        let dateFormat;
        // eslint-disable-next-line no-case-declarations
        let dateFormatted;
        try {
          dateFormat = column.parameters.dateFormat;
        } catch (e) {
          dateFormat = BasicFormatsService.defaultDateFormat;
        }

        // eslint-disable-next-line prefer-const
        dateFormatted = this.basicFormatsService.formatDate(value, dateFormat);

        return dateFormatted ? dateFormatted : '';

      case AdvancedTrackingTableColumnType.Number:
        if (!value && column.replaceFalsyWithDash) {
          return ' - ';
        }

        if (isNaN(value)) {
          return value;
        }

        // eslint-disable-next-line no-case-declarations
        const numberParameters = {
          ...column.parameters,
          minIntegerDigits: 0,
          minFractionDigits: 0,
          maxFractionDigits: 10,
        };

        return this.basicFormatsService.formatDecimal(
          value,
          numberParameters.minIntegerDigits,
          numberParameters.minFractionDigits,
          numberParameters.maxFractionDigits,
        );

      case AdvancedTrackingTableColumnType.Percentage:
        if (!value && column.replaceFalsyWithDash) {
          return ' - ';
        }

        if (isNaN(value)) {
          return value;
        }

        return this.basicFormatsService.formatPercentage(value / 100);

      default:
        if (!value && column.replaceFalsyWithDash) {
          return ' - ';
        }

        return value;
    }
  }
}
