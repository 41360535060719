<ng-container *ngIf="!showSpinnerOnLoading && !lotAction.hide">
  <ng-container *ngIf="lotAction.checkIfOptionCanBePreReserved">
    <!--  Option a réactiver dans la version V2-->
    <!--  <button *ngIf="lotAction.checkIfOptionCanBePreReserved"
            mat-flat-button
            color="primary"
            type="button"
            (click)="openPreReservationPopin()">{{ i18nService._('Txt_Button_PreReserve') }}</button>-->
    <button mat-stroked-button class="is-optioned" color="primary" type="button" (click)="callRemoveOption()">
      {{ i18nService._('Txt_Button_RemoveOptionLong') }}
    </button>
  </ng-container>
  <button *ngIf="lotAction.checkIfOptionCanBeTaken" mat-flat-button color="primary" type="button" (click)="checkValidity()">
    {{
      lotAction.checkIfOptionCanBeAutomaticallyTaken ? i18nService._('Txt_Button_TakeOption') : i18nService._('Txt_Button_RequestOption')
    }}
  </button>

  <ng-container *ngIf="lotAction.checkIfOptionCanOnlyBeRemoved">
    <button mat-stroked-button class="is-optioned" color="primary" type="button" (click)="callRemoveOption()">
      {{ i18nService._('Txt_Button_RemoveOptionLong') }}
    </button>
  </ng-container>

  <ng-container *ngIf="lotAction.checkIfAlertCanBeTaken">
    <button mat-stroked-button class="is-optioned take-alert-button" color="primary" type="button" (click)="takeAlert()">
      {{ i18nService._('Txt_Button_TakeAlert') }}
    </button>
  </ng-container>
  <div *ngIf="lotAction.userHasOptionUntil && !lotAction.checkIfOptionCanBeRemoved" class="lot-option-message">
    {{ lotAction.userHasOptionUntil }}
  </div>
  <div
    *ngIf="lot.isUnvailable && lot.status.quotaReach"
    class="lot-option-message"
    [innerHTML]="i18nService._('Txt_comparator_reached_lot_quota') | safe: 'html'"
  ></div>

  <!--  <div *ngIf="lotAction.hasSubAction">
        Option a réactiver dans la version V2
          <div *ngIf="lotAction.checkIfOptionCanBeExtended"
               [ngClass]="{disabled: ongoingCall}"
               (click)="callExtendedOption()">{{ i18nService._('Txt_Button_ExtendOption') }}</div>
    </div>-->
</ng-container>

<div *ngIf="showSpinnerOnLoading" class="app-comparator-spinner-item">
  <mat-progress-spinner color="primary" diameter="30" mode="indeterminate" value="30"></mat-progress-spinner>
</div>
<ng-container *ngIf="lot.status.optionned">
  <span [class.optioned-by-me]="lot.status.optionnedByMe" class="is-optioned uppercase">
    <mat-icon class="mat-column-status-prefix-icon material-icons-outlined size-18">account_circle</mat-icon>
    {{ i18nService._('Txt_Detail_Program_Status_Lot_Optioned') }}
  </span>
</ng-container>
