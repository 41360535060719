import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs';

import { TrackingTableReloadOption } from '../models/tracking-table-reload-option';

@Injectable({
  providedIn: 'root',
})
export class AdvancedTrackingTableReloadService {
  private readonly _reloadSubject: Subject<TrackingTableReloadOption> = new Subject<TrackingTableReloadOption>();

  get $reload(): Observable<TrackingTableReloadOption> {
    return this._reloadSubject.asObservable();
  }

  reloadData(reloadOption: TrackingTableReloadOption): void {
    this._reloadSubject.next(reloadOption);
  }
}
