import { Component, Input, OnInit } from '@angular/core';
import { DatePipe, NgIf } from '@angular/common';
import { PaternityStatusCode, SummaryPaternityResponse } from '@commons-dto/prospect';

import { I18nService } from '../../../../utils/services/i18n.service';
import { StatusEnum, StatusTagComponent } from '../../../../common/shared/status-tag/status-tag.component';
import { HorizontalCardComponent } from '../../../../common/shared/horizontal-card/horizontal-card.component';

@Component({
  selector: 'app-paternities-list-element',
  templateUrl: './paternity-list-element.component.html',
  styleUrls: ['./paternity-list-element.component.scss'],
  standalone: true,
  imports: [HorizontalCardComponent, NgIf, StatusTagComponent, DatePipe],
})
export class PaternitiesListElementComponent implements OnInit {
  @Input() paternity: SummaryPaternityResponse;
  statusEnum: { [keys in PaternityStatusCode]: StatusEnum } = {
    [PaternityStatusCode.ACCEPTED]: StatusEnum.SUCCESS,
    [PaternityStatusCode.DENIED]: StatusEnum.FAILED,
    [PaternityStatusCode.PENDING]: StatusEnum.PENDING,
  };
  paternityStatus: PaternityStatusCode;

  constructor(public _i18n: I18nService) {}

  ngOnInit(): void {
    this.paternityStatus = this.determinePaternityStatus();
  }

  private determinePaternityStatus() {
    if (this.paternity?.statuses?.includes(PaternityStatusCode.DENIED)) {
      return PaternityStatusCode.DENIED;
    }
    if (this.paternity?.statuses?.includes(PaternityStatusCode.PENDING)) {
      return PaternityStatusCode.PENDING;
    }
    return PaternityStatusCode.ACCEPTED;
  }
}
