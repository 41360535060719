<div class="card-header flex flex-row justify-between mb-4">
  <h3 class="title text-lg font-semibold">{{ label }}</h3>
  <span *ngIf="tagLabel" class="tag uppercase bg-black rounded-md text-xxs text-white h-6 px-2 flex justify-center items-center font-bold">
    {{ tagLabel }}
  </span>
</div>
<div class="card-body">
  <div class="mb-12">
    <p *ngIf="subLabel" class="description text-xs font-normal text-gray-500">
      {{ subLabel }}
    </p>
  </div>
</div>
