import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { NgIf } from '@angular/common';

import { CityService } from '../../../utils/services/city.service';
import { CityResponse } from '../../../utils/models/CityResponse';

@Component({
  selector: 'app-formly-city',
  templateUrl: './formly-city.component.html',
  styleUrls: ['./formly-city.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, InputNumberModule, FormsModule, ReactiveFormsModule, DropdownModule],
})
export class FormlyCityComponent extends FieldType implements OnInit, OnDestroy {
  localizationForm: UntypedFormGroup = new UntypedFormGroup({
    postalCode: new UntypedFormControl(),
    city: new UntypedFormControl(),
  });

  cities: CityResponse[];
  selectedCity: CityResponse;

  previousPostalCode: string;
  isInternalChange = false;

  private $destroy: Subject<void> = new Subject<void>();

  constructor(
    private readonly cityService: CityService,
    private readonly detectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.localizationForm
      .get('postalCode')
      .valueChanges.pipe(takeUntil(this.$destroy), debounceTime(500))
      .subscribe((postalCode) => {
        this.onPostalCodeChanged(postalCode);
      });

    if (this.field.formControl.value) {
      this.setPostalCode(this.field.formControl.value.postalCode);
    }

    this.field.formControl.valueChanges.pipe(takeUntil(this.$destroy)).subscribe((value) => {
      this.setPostalCode(value.postalCode);
      this.setSelectedCity(value.city);
    });

    this.detectorRef.detectChanges();
  }

  private onPostalCodeChanged(postalCode: string): void {
    if (postalCode === this.previousPostalCode) return;

    const postalCodeLength = postalCode ? postalCode.toString().length : 0;
    if (postalCode && postalCodeLength >= 2) {
      this.refreshCities(postalCode);
    }
    this.previousPostalCode = postalCode;
  }

  private setPostalCode(postalCode): void {
    this.localizationForm.get('postalCode').setValue(postalCode);
  }

  private async refreshCities(postalCode: string): Promise<void> {
    const cities = await this.cityService.getCities(postalCode);

    this.cities = cities;

    if (cities.length === 1) {
      this.setSelectedCity(cities[0]);
    }

    this.setFormlyFormValue();
  }

  private setSelectedCity(city): void {
    this.selectedCity = city;
    this.localizationForm.get('city').setValue(city);
  }

  public setFormlyFormValue() {
    if (this.localizationForm.get('city').valid && this.localizationForm.get('postalCode').valid) {
      this.field.formControl.setValue({
        postalCode: this.localizationForm.get('postalCode').value,
        city: this.localizationForm.get('city').value,
      });
    } else {
      this.field.formControl.setValue(undefined);
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }
}
