import { NgIf } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyForm, FormlyModule } from '@ngx-formly/core';
import { MatButtonModule } from '@angular/material/button';
import { ProspectType } from 'libs/commons-dto/src/lib/dossier-prospect/prospect/prospect-type.enum';
import { cloneDeep } from 'lodash';

import { DossierProspectFormlyService } from '../../../dossier-prospect/services/dossier-prospect-formly.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { DossierProspectType } from '../../../dossier-prospect/model/dossier-prospect-type.enum';
import { ProspectAssociation } from '../../models/ProspectAssociation';
import { DossierProspectService } from '../../../dossier-prospect/services/dossier-prospect.service';
import { TokenService } from '../../../utils/services/authorisation/token.service';
import { DossierProspectFormData } from '../../../dossier-prospect/model/forms/dossier-prospect-form-data.model';
import { DossierProspectTakeOptionData } from '../../models/DossierProspectTakeOptionData';
import { SpinnerWithBackdropService } from '../../../utils/services/spinner-with-backdrop.service';
import { DossierProspectAssociationService } from '../../services/dossier-prospect-association.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { IconComponent } from '../../../common/standalone/icon/icon.component';
import { GoogleTagManagerService } from '../../../utils/services/google-tag-manager.service';

@Component({
  selector: 'app-take-option-dialog',
  templateUrl: './take-option-dialog.component.html',
  styleUrls: ['./take-option-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, NgIf, FormsModule, ReactiveFormsModule, MatButtonModule, FormlyModule, IconComponent],
})
export class TakeOptionDialogComponent implements OnInit {
  @ViewChild('formlyForm') formlyForm: FormlyForm;
  public fields: FormlyFieldConfig[];
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public model: any = { dossier_prospect_type: DossierProspectType.SOLO };
  previousModel: any;
  needToRefreshValues = false;
  formValid = false;

  constructor(
    private _dossierProspectFormly: DossierProspectFormlyService,
    private _dossierProspect: DossierProspectService,
    private readonly dossierProspectAssociationService: DossierProspectAssociationService,
    private readonly spinnerWithBackdropService: SpinnerWithBackdropService,
    private _token: TokenService,
    public dialogRef: MatDialogRef<TakeOptionDialogComponent>,
    private readonly snackbarService: SnackbarService,
    public i18nService: I18nService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    @Inject(MAT_DIALOG_DATA) public data: ProspectAssociation,
  ) {}

  ngOnInit(): void {
    this.fields = this._dossierProspectFormly.getFormProspectForOption(this.data);
  }

  async onChange() {
    this.needToRefreshValues = false;

    this.onSelectedProspectChange();

    // Refresh
    if (this.needToRefreshValues) {
      this.model = cloneDeep(this.model);
      this.previousModel = cloneDeep(this.model);
    }

    this.formValid = this.form.valid;
  }

  onSelectedProspectChange(): void {
    if (this.model?.dossier_prospect?.id !== this?.previousModel?.dossier_prospect?.id) {
      this.needToRefreshValues = true;

      if (this.model?.dossier_prospect?.id) {
        const prospectsInfo = this.model?.dossier_prospect?.prospects;

        if (prospectsInfo[0].prospectType === ProspectType.PERSON) {
          if (prospectsInfo.length > 1) {
            this.model.dossier_prospect_type = DossierProspectType.DUO;
            this.setSelectProspectInfo(2, prospectsInfo[1]);
            this.setSelectProspectInfo(1, prospectsInfo[0]);
          } else {
            this.model.dossier_prospect_type = DossierProspectType.SOLO;
            this.setSelectProspectInfo(1, prospectsInfo[0]);
          }
        } else if (prospectsInfo[0].prospectType === ProspectType.COMPANY) {
          this.model.dossier_prospect_type = DossierProspectType.COMPANY;
          this.setSelectProspectInfo(1, prospectsInfo[0]);
        }
      } else {
        this.model = { dossier_prospect_type: DossierProspectType.SOLO };
      }
    }
  }

  setSelectProspectInfo(prospectNumber: number, prospectInfo: any): void {
    if (prospectInfo?.prospectType == ProspectType.COMPANY) {
      this.model['company_name'] = prospectInfo?.companyName;
      this.model['company_siret'] = prospectInfo?.SIRET;
    }
    this.model['prospect_' + prospectNumber + '_id'] = prospectInfo?.id;
    this.model['prospect_' + prospectNumber + '_gender'] = prospectInfo?.civility;
    this.model['prospect_' + prospectNumber + '_lastname'] = prospectInfo?.lastName;
    this.model['prospect_' + prospectNumber + '_firstname'] = prospectInfo?.firstName;
    this.model['prospect_' + prospectNumber + '_email'] = prospectInfo?.email;
    this.model['prospect_' + prospectNumber + '_phone'] = prospectInfo?.phoneNumber;
    this.model['prospect_' + prospectNumber + '_country'] = prospectInfo?.country;
    this.model['prospect_' + prospectNumber + '_localization'] = { city: prospectInfo?.city, postalCode: prospectInfo?.postalCode };
  }

  submit() {
    if (this.formlyForm?.model?.dossier_prospect?.id) {
      this.takeOption(this.formlyForm?.model?.dossier_prospect?.id);
      this.dialogRef.close({ dossierProspectId: this.formlyForm?.model?.dossier_prospect?.id });
    } else {
      this._dossierProspect
        .createDossier(this.formlyForm.model as DossierProspectFormData, this._token.getUserId())
        .subscribe((dossierProspectResponse) => {
          this._googleTagManagerService.pushTag({ event: 'prospect_declaration' });
          this.takeOption(dossierProspectResponse.id);
          this.dialogRef.close({ dossierProspectId: this.formlyForm?.model?.dossier_prospect?.id });
        });
    }
  }

  private takeOption(prospectId: number) {
    const optionForm: DossierProspectTakeOptionData = {
      dossierProspectId: prospectId,
      lotId: this.data.lotId,
    };

    this.spinnerWithBackdropService.show();
    this.dossierProspectAssociationService.takeOption(optionForm).subscribe(
      (successTakeOption) => this.handleSuccessTakeOption(successTakeOption),
      () => this.spinnerWithBackdropService.hide(),
    );
  }

  private handleSuccessTakeOption(successTakeOption): void {
    this.spinnerWithBackdropService.hide();
    const message = this.formatSuccessMessage(successTakeOption);
    this.snackbarService.info(message, false, undefined, true);
    this.dialogRef.close(successTakeOption);
  }

  private formatSuccessMessage(successTakeOption): string {
    let message = `<p>${successTakeOption.libelleRetour}<p>`;
    if (successTakeOption.libelleRetourPaternity) {
      message += `<b>${successTakeOption.libelleRetourPaternity}</b>`;
    }
    return message;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
