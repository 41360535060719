import { AppIconsName } from '../../standalone/icon/utils/app-icon-names';

export interface Step {
  title: string;
  status: StepStatus;
  subTitleData?: StepKeyValue;
  events?: {
    label: StepKeyValue;
    value?: StepKeyValue;
  }[];
  action?: {
    key: string;
    value?: string[];
    function?: () => void;
  }[];
}

export enum StepStatus {
  VALID = 'VALID',
  CURRENT = 'CURRENT',
  INVALID = 'INVALID',
  FUTURE = 'FUTURE',
}

interface StepKeyValue {
  key: string;
  value?: string[];
  icon?: AppIconsName;
}
