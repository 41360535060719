import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, lastValueFrom } from 'rxjs';
import { ParamRequest } from '@commons-dto/valo-back';

import { PreReservationsCountServiceResponse } from '../models/PreReservationsCountServiceResponse';
import { PreReservationsTrackingTableItem } from '../models/PreReservationsTrackingTableItem';
import { AbstractAdvancedTrackingTableService } from './abstract-advanced-tracking-table.service';
import { PreReservationsTrackingTableServiceResponse } from '../models/PreReservationsTrackingTableServiceResponse';
import { GridTrackingTableService } from './grid-tacking-table.service';

import { SignatureService } from '../../signature/services/signature.service';
import { AppConfigService } from '../../utils/services/app-config.service';
import { CacheService } from '../../utils/services/cache.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';
import { TableLoader, TableResponse } from '../../table/interfaces/DataLoader';
import { PreReservationApiService } from '../../adapters/pre-reservation.service';

@Injectable({
  providedIn: 'root',
})
export class PreReservationsTrackingTableService
  extends AbstractAdvancedTrackingTableService<PreReservationsTrackingTableItem>
  implements TableLoader<PreReservationsTrackingTableItem>
{
  public url = 'Reservations/tracking-table/pre-reservations';
  protected cacheName = 'tracking-pre-reservation';

  constructor(
    private readonly _signature: SignatureService,
    public readonly appConfig: AppConfigService,
    public readonly errorHandlerService: ErrorHandlerService,
    public readonly http: HttpClient,
    public readonly cacheService: CacheService,
    private readonly gridTrackingTableService: GridTrackingTableService,
    private preReservationApiService: PreReservationApiService,
  ) {
    super(appConfig, errorHandlerService, http, cacheService);
  }

  public async getPreReservations(params: ParamRequest): Promise<PreReservationsTrackingTableServiceResponse> {
    const response: { items: Array<PreReservationsTrackingTableItem>; nbItems: number } = await lastValueFrom(
      this.gridTrackingTableService.getTrackingTableBack(params, this.url),
    );
    return response;
  }

  async get(params): Promise<TableResponse<PreReservationsTrackingTableItem>> {
    const res = this.preReservationApiService.getPrereservations(params);
    return await lastValueFrom(res);
  }

  async getById(id: number): Promise<PreReservationsTrackingTableItem> {
    const res = this.preReservationApiService.getPrereservationById(id);
    return await lastValueFrom(res);
  }

  /*
  // TODO: vérifier si tjs utile
  public getItems(
    paramsRequest: ParamRequest
  ): Observable<ListResponse<PreReservationsTrackingTableItem>> {
    let table: ListResponse<PreReservationsTrackingTableItem>;
    return super.getItems(paramsRequest).pipe(
      mergeMap(data => {
        table = data;
        const requests: Observable<PreReservationsTrackingTableItem>[] = [];
        table.items.forEach(item => {
          if ((item.actions.canOnlineSignValo || item.actions.canContinueOnlineSignValo) && item.id) {
            requests.push(
              this._signature.findDossier({ reservationId: item.id }).pipe(
                switchMap(dossiers => {
                  if (dossiers.length === 1 && item.actions.canContinueOnlineSignValo) {
                    item.signatureId = dossiers[0].id;
                    item.actions.canOnlineSignValo = false;
                  } else if (dossiers.length === 0) {
                    item.actions.canContinueOnlineSignValo = false;
                  }
                  return of(item);
                }),
                catchError(() => {
                  return of(item);
                })
              )
            );
          } else {
            requests.push(of(item));
          }
        });
        return forkJoin(requests);
      }),
      switchMap(() => of(table))
    );
  }
  */
  public countPreReservation(): Observable<PreReservationsCountServiceResponse> {
    return this.http
      .get<PreReservationsCountServiceResponse>([this.appConfig.getLoopbackApiUrl(), this.url, 'status/count'].filter(Boolean).join('/'))
      .pipe(catchError(this.errorHandlerService.handleError<PreReservationsCountServiceResponse>(this.serviceName, 'countPreReservation')));
  }
}
