import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';

import { InputListOption } from '../filter-sort-header-bar/models/input-list-option';

@Component({
  selector: 'app-filter-multiselect',
  standalone: true,
  imports: [FormsModule, MultiSelectModule],
  templateUrl: './filter-multiselect.component.html',
  styleUrls: ['./filter-multiselect.component.scss'],
})
export class FilterMultiSelectComponent {
  @Input() filterOptions: InputListOption;

  @Output() selectedOptionsChange = new EventEmitter<any[]>();

  selectedValues = [];

  onSelectedOptionsChange(): void {
    const emitValues = this.selectedValues.length
      ? this.selectedValues
      : this.filterOptions.options.map((option) => option[this.filterOptions.optionValue]);
    this.selectedOptionsChange.emit(emitValues);
  }
}
