import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { MatTabsModule } from '@angular/material/tabs';
import { NgIf } from '@angular/common';

import { PageProgramHelperService } from '../page-program-helper.service';

import { breakpoints } from '../../../../utils/models/breakpoint';
import { BreakpointEnum } from '../../../../utils/models/enums/breakpoint.enum';
import { I18nService } from '../../../../utils/services/i18n.service';
import { ProgramTextResponse } from '../../../../utils/models/ProgramTextResponse';
import { SafePipe } from '../../../../utils/pipes/safe.pipe';

@Component({
  selector: 'app-program-page-description',
  templateUrl: './program-page-description.component.html',
  styleUrls: ['./program-page-description.component.scss'],
  standalone: true,
  imports: [NgIf, MatTabsModule, SafePipe],
})
export class ProgramPageDescriptionComponent implements OnInit, OnDestroy {
  public canExplodeMatTabs: boolean;
  public haveOnlyOneTab: boolean;
  private readonly destroyed$: Subject<boolean> = new Subject();
  programText: ProgramTextResponse;

  constructor(
    public i18nService: I18nService,
    private readonly _breakpointObserver: BreakpointObserver,
    private pageProgramHelperService: PageProgramHelperService,
  ) {}

  private setHaveOnlyOneTabe() {
    this.haveOnlyOneTab =
      [
        Boolean(this.programText.description) || Boolean(this.programText.longDescrValo),
        Boolean(this.programText.services),
        Boolean(this.programText.quality),
      ].filter(Boolean).length === 1;
  }

  ngOnInit() {
    this.programText = this.pageProgramHelperService.programText;
    this.setHaveOnlyOneTabe();
    this.canExplodeMatTabs = this.haveOnlyOneTab;
    this._explodeMatTabsOnScreenResize();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private _explodeMatTabsOnScreenResize() {
    this._breakpointObserver
      .observe(breakpoints[BreakpointEnum.md].down)
      .pipe(
        tap((breakPoint) => (this.canExplodeMatTabs = breakPoint.matches || this.haveOnlyOneTab)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }
}
