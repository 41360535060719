import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-panel',
  templateUrl: './formly-panel.component.html',
  styleUrls: ['./formly-panel.component.scss'],
  standalone: true,
})
export class FormlyPanelComponent extends FieldWrapper {}
