<div
  class="hover:bg-orange-500 hover:bg-opacity-10 border-solid border border-orange-500 rounded-full text-sm cursor-pointer inline-flex flex-row justify-between items-center h-8 px-3 relative"
  [ngClass]="{ 'bg-transparent text-orange-500': showPlaceholder, 'bg-orange-500 text-white': !showPlaceholder }"
  (click)="showSubPanel()"
>
  <div *ngIf="showPlaceholder" class="inline-flex flex-row justify-center items-center">
    <mat-icon class="text-base w-4 -mt-px" *ngIf="prefixIcon">{{ prefixIcon }}</mat-icon>
    <div class="label-text">{{ i18nService._(placeholder) }}</div>
  </div>
  <div *ngIf="!showPlaceholder" class="filter-data" [ngClass]="{ 'filter-data-modal': !showPlaceholder }">
    {{ dataToShow }}
  </div>
  <div class="badge-value flex justify-center items-center" *ngIf="!modal">
    <span *ngIf="showDataLength">+{{ selectedData.length - limit }}</span>
    <mat-icon class="size-18" [hidden]="showPanel || modal">expand_more</mat-icon>
    <mat-icon class="size-18" [hidden]="!showPanel || modal">expand_less</mat-icon>
  </div>
  <div class="filter-panel px-4 pt-4" [ngClass]="{ 'show-panel': showPanel, 'hide-panel': !showPanel }" (click)="preventDefault($event)">
    <ng-template appSearchPanelTemplate></ng-template>
  </div>
</div>
