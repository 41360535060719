import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { map } from 'rxjs';

import { Dossier } from '../models/Dossier';
import { SignatureService } from './signature.service';
import { SignatureStatus } from '../models/signature-status.enum';

import { Sitemap } from '../../utils/models/Sitemap';

export const SignatureIsDraftResolver: ResolveFn<Dossier> = (route: ActivatedRouteSnapshot) => {
  const signature: SignatureService = inject(SignatureService);
  const router: Router = inject(Router);
  const id = route.paramMap.get('signatureId');
  return signature.getDossier(Number(id)).pipe(
    map((dossier: Dossier) => {
      if (dossier) {
        if (dossier.status !== SignatureStatus.DRAFT) {
          router.navigate([Sitemap.signature.progress.path.replace(':signatureId', id)]);
        }
        return dossier;
      }
    }),
  );
};
