import { Component, Input, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';

import { ReservationCreate } from '../../../utils/models/ReservationCreate';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-reason-of-reservation-cancellation',
  templateUrl: './reason-of-reservation-cancellation.component.html',
  styleUrls: ['./reason-of-reservation-cancellation.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class ReasonOfReservationCancellationComponent implements OnInit {
  /**
   * reservationInformations input
   *
   * @type {ReservationCreate}
   * @memberof DetailsOfAssociateSellerComponent
   */
  @Input() reservationInformations: ReservationCreate;
  /**
   * isCancelMessageDisplayed
   *
   * @type {boolean}
   * @memberof ReasonOfReservationCancellationComponent
   */
  public isCancelMessageDisplayed: boolean;

  /**
   * Creates an instance of ReasonOfReservationCancellationComponent.
   * @param {AppConfigService} appConfigService
   * @memberof ReasonOfReservationCancellationComponent
   */
  constructor(
    public i18nService: I18nService,
    private readonly appConfigService: AppConfigService,
  ) {}

  ngOnInit(): void {
    this.isCancelMessageDisplayed =
      this.reservationInformations.reservationStatus.label === this.appConfigService.getAppConfig().reservationStatus.reservationCanceled;
  }
}
