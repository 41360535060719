/* eslint-disable @typescript-eslint/no-unused-vars */
import { SelectionModel } from '@angular/cdk/collections';
import { NgClass, NgFor, NgIf, NgTemplateOutlet, TitleCasePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { ThousandsSeparatorPipe } from '../../../utils/pipes/thousands-separator.pipe';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { BasicFormatsService } from '../../../utils/services/basic-formats.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { TableCheckboxShow } from '../../enums/table-checkbox-show.enum';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    MatOptionModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    NgTemplateOutlet,
    NgClass,
    MatTooltipModule,
    MatPaginatorModule,
  ],
})
export class TableComponent implements AfterViewInit, OnDestroy {
  /**
   * Activate or not the checkboxes column
   *
   * @type {boolean}
   * @memberof TableComponent
   */
  @Input() public activateCheckboxSelection = false;

  /**
   * Activate or not the choice of columns to display
   *
   * @type {boolean}
   * @memberof TableComponent
   */
  @Input() public activateColumnsToDisplay = false;

  /**
   * Activate or not the filter
   *
   * @type {boolean}
   * @memberof TableComponent
   */
  @Input() public activateFilter = false;

  /**
   * Activate or not the paginator
   *
   * @type {boolean}
   * @memberof TableComponent
   */
  @Input() public activatePaginator = false;

  /**
   * Activate or not the sort
   *
   * @type {boolean}
   * @memberof TableComponent
   */
  @Input() public activateSort = false;

  /**
   * Array of all available columns in the data
   *
   * @type {string[]}
   * @memberof TableComponent
   */
  @Input() public allColumns: Array<string> = [];

  /**
   * List of checked rows
   *
   * @type {any[]}
   * @memberof TableComponent
   */
  @Input()
  public set checkedRows(value: Array<any>) {
    this.checkedRowsValue.next(value);
  }

  /**
   * Emitter of checkedRows changings
   *
   * @type {EventEmitter<any[]>}
   * @memberof TableComponent
   */
  @Output() public readonly checkedRowsChange = new EventEmitter<Array<any>>();

  /**
   * columnTitles input
   *
   * @type {{ [key: string]: string }}
   * @memberof TableComponent
   */
  @Input() public columnTitles: { [key: string]: string } = {};

  /**
   * columnFormats input
   *
   * @type {{ [key: string]: string }}
   * @memberof TableComponent
   */
  @Input() public columnFormats: { [key: string]: Array<string> } = {};

  /**
   * columnSort input
   *
   * @type {{ string }}
   * @memberof TableComponent
   */
  @Input() public columnSort: string;

  /**
   * columnSortOrder input
   *
   * @type {{ string }}
   * @memberof TableComponent
   */
  @Input() public columnSortOrder: 'asc' | 'desc';

  /**
   * Data to show in the table
   *
   * @type {Object[]}
   * @memberof TableComponent
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Input() public set data(value: Array<Object>) {
    this.dataValue.next(value);
  }

  /**
   * Array of columns to display
   *
   * @type {string[]}
   * @memberof TableComponent
   */
  @Input() public displayedColumns: Array<string> = [];

  /**
   * Initial page size option
   *
   * @type {number}
   * @memberof TableComponent
   */
  @Input() public pageSize: number;

  /**
   * Array of page size options to allow
   *
   * @type {number[]}
   * @memberof TableComponent
   */
  @Input() public pageSizeOptions: Array<number>;

  /**
   * Array of columns to allow sorting
   *
   * @type {string[]}
   * @memberof TableComponent
   */
  @Input() public sortedColumns: Array<string> = [];

  /**
   * Set or not the header sticky
   *
   * @type {boolean}
   * @memberof TableComponent
   */
  @Input() public stickyHeader = false;

  /**
   * Linked to activateCheckboxSelection=true
   *
   * @type {TableCheckboxShow}
   * @memberof TableComponent
   */
  @Input()
  public set showCheckboxSelection(value: TableCheckboxShow) {
    this.showCheckboxSelectionValue.next(value);
  }

  /**
   * Text to display if table is empty
   *
   * @type {string}
   * @memberof TableComponent
   */
  @Input() public textTableEmpty: string;

  /**
   * item field name for disable line
   */
  @Input() public disableField: string;

  /**
   * FormControl for the selector of columns to display
   *
   * @type {FormControl}
   * @memberof TableComponent
   */
  public columnsToDisplaySelector: UntypedFormControl = new UntypedFormControl();

  /**
   * Instance that implements methods to filter, paginate and sort data
   *
   * @type {MatTableDataSource<Object>}
   * @memberof TableComponent
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  public dataSource: MatTableDataSource<Object>;

  /**
   * List of columns names to display
   *
   * @type {string[]}
   * @memberof TableComponent
   */
  public matColumnsDef: Array<string> = [];

  /**
   * Array of selected options
   *
   * @type {string[]}
   * @memberof TableComponent
   */
  public selectedOptions: Array<string> = [];

  /**
   * SelectionModel instance
   *
   * @type {SelectionModel<any>}
   * @memberof TableComponent
   */
  public selection: SelectionModel<any> = new SelectionModel<any>(true, []);

  /**
   * Behavior subject to process the input checkedRows for this generic component
   *
   * @type {BehaviorSubject<Array<any>>}
   * @memberof TableComponent
   */
  private readonly checkedRowsValue: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  /**
   * checkedRowsValueSubscription attribute
   *
   * @type {Subscription}
   * @memberof TableComponent
   */
  private checkedRowsValueSubscription: Subscription;

  /**
   * columnsToDisplaySelectorValueChangesSubscription attribute
   *
   * @type {Subscription}
   * @memberof TableComponent
   */
  private columnsToDisplaySelectorValueChangesSubscription: Subscription;

  /**
   * Behavior subject to process the input data for this generic component
   *
   * @type {BehaviorSubject<Array<Object>>}
   * @memberof TableComponent
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  private readonly dataValue: BehaviorSubject<Array<Object>> = new BehaviorSubject(undefined);

  /**
   * dataValueSubscription attribute
   *
   * @type {Subscription}
   * @memberof TableComponent
   */
  private dataValueSubscription: Subscription;

  /**
   * Paginator for the MatTableDataSource
   *
   * @type {MatPaginator}
   * @memberof TableComponent
   */
  private paginator: MatPaginator;

  /**
   * Behavior subject to process the input showCheckboxSelection for this generic component
   *
   * @type {BehaviorSubject<TableCheckboxShow>}
   * @memberof TableComponent
   */
  private readonly showCheckboxSelectionValue: BehaviorSubject<TableCheckboxShow> = new BehaviorSubject(TableCheckboxShow.ALL);

  /**
   * showCheckboxSelectionValueSubscription attribute
   *
   * @type {Subscription}
   * @memberof TableComponent
   */
  private showCheckboxSelectionValueSubscription: Subscription;

  /**
   * Sort for the MatTableDataSource
   *
   * @type {MatSort}
   * @memberof TableComponent
   */
  private sort: MatSort;

  /**
   * To transform string to CamelCase format.
   *
   * @type {TitleCasePipe}
   * @memberOf TableComponent
   */
  private readonly titleCasePipe: TitleCasePipe;

  /**
   * To apply thousands separator to numbers.
   *
   * @type {ThousandsSeparatorPipe}
   * @memberOf TableComponent
   */
  private readonly thousandsSeparatorPipe: ThousandsSeparatorPipe;
  @Input() readonly: boolean;

  @ViewChild(MatPaginator) set matPaginator(matPaginator: MatPaginator) {
    this.paginator = matPaginator;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatSort) set matSort(matSort: MatSort) {
    this.sort = matSort;
    this.setDataSourceAttributes();

    // Play another detect changes pass.
    this.cd.detectChanges();
  }

  /**
   * Creates an instance of TableComponent.
   *
   * @param i18nService
   * @param appConfigService
   * @param basicFormatsService
   * @param cd
   */
  constructor(
    public i18nService: I18nService,
    private readonly appConfigService: AppConfigService,
    private readonly basicFormatsService: BasicFormatsService,
    private readonly cd: ChangeDetectorRef,
  ) {
    this.titleCasePipe = new TitleCasePipe();
    this.thousandsSeparatorPipe = new ThousandsSeparatorPipe();
    this.dataSource = new MatTableDataSource();
    this.pageSizeOptions = this.appConfigService.getPaginatorDefaultValue();
  }

  /**
   * Apply the filter
   *
   * @memberof TableComponent
   */
  public applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Display info in the columns to display selector
   *
   * @memberof TableComponent
   */
  public displaySelect(): string {
    if (this.displayedColumns.length === this.allColumns.length) {
      return this.i18nService._('Txt_Select_Form_Table_All');
    }

    const value = this.columnsToDisplaySelector.value;

    if (value) {
      const columnTitle = this.getColumnTitle(value[0]);
      if (value.length > 1) {
        return `${columnTitle} ${this.i18nService._('Txt_Select_Form_Table_And_Other', [value.length - 1])}`;
      }

      return columnTitle;
    }

    return '';
  }

  /**
   * Return the title of a column if in columnTitle and translated with i18nService if needed
   *
   * @param column
   * @memberof TableComponent
   */
  public getColumnTitle(column: string): string {
    return this.columnTitles[column] ? this.i18nService._(this.columnTitles[column]) : column;
  }

  /**
   * Format string or date.
   *
   * @param format
   * @param element
   * @param prev
   * @memberof TableComponent
   */
  formatStr(format: string, prev: any): string {
    let value = prev;

    switch (format) {
      case 'strong':
        value = `<strong>${value}</strong>`;
        break;

      case 'titleCase':
        value = this.titleCasePipe.transform(value);
        break;

      case 'thousandsSeparator':
        value = this.thousandsSeparatorPipe.transform(value);
        break;

      case 'date':
        value = this.basicFormatsService.formatDate(value);
        break;

      case 'dateHour':
        value = this.basicFormatsService.formatDateTime(value);
        break;

      case 'boolean':
        value = value ? this.i18nService._('Txt_Yes') : this.i18nService._('Txt_No');
        break;

      case 'i18n':
        value = this.i18nService._(value);
        break;

      default:
        break;
    }

    return value;
  }

  /**
   * Return the formatted value of a column.
   *
   * @param column
   * @param element
   * @memberof TableComponent
   */
  public getColumnFormat(column: string, element: any): string {
    let value =
      // eslint-disable-next-line no-prototype-builtins
      element.hasOwnProperty(column) && element[column] ? element[column] : undefined;

    // eslint-disable-next-line no-prototype-builtins
    if (value && this.columnFormats.hasOwnProperty(column)) {
      for (const format of this.columnFormats[column]) {
        value = this.formatStr(format, value);
      }
    }

    // eslint-disable-next-line no-prototype-builtins
    if (value && value.hasOwnProperty('label')) {
      value = value.label;
    }

    return value ? value : '-';
  }

  /**
   * Return if the column is a ng-template
   *
   * @memberof TableComponent
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  public isNgTemplate(element: Object, columnName: string): boolean {
    return element[columnName] instanceof TemplateRef;
  }

  /**
   * Return if the column is allowed for sorting
   *
   * @memberof TableComponent
   */
  public isSortable(columnName: string): boolean {
    return this.activateSort && (!this.sortedColumns.length || this.sortedColumns.includes(columnName));
  }

  public ngAfterViewInit(): void {
    // Due to a slow instantiation with the ngOnChanges method, we used these Behavior Subjects to update the component accordingly
    // when the corresponding input changes, hence the only inputs which can trigger changes in the component after their first
    // initialization are 'data', 'checkedRows' and 'showCheckboxSelection'
    this.dataValueSubscription = this.dataValue.asObservable().subscribe((_data) => {
      this.updateData();
    });
    this.checkedRowsValueSubscription = this.checkedRowsValue.asObservable().subscribe((_checkedRows) => {
      this.updateData();
    });
    this.showCheckboxSelectionValueSubscription = this.showCheckboxSelectionValue.asObservable().subscribe((_showCheckboxSelection) => {
      this.updateData();
    });

    this.columnsToDisplaySelectorValueChangesSubscription = this.columnsToDisplaySelector.valueChanges.subscribe((value) => {
      const intersection = value.filter((columnName: string) => this.allColumns.includes(columnName));
      this.displayedColumns = value.length ? intersection : this.displayedColumns;
      this.matColumnsDef = this.activateCheckboxSelection ? ['select', ...this.displayedColumns] : this.displayedColumns;
    });
    // Override function to use showCheckboxSelection
    this.dataSource._filterData = () => {
      const testSelection =
        !this.activateCheckboxSelection ||
        (this.activateCheckboxSelection &&
          (this.showCheckboxSelectionValue.value === TableCheckboxShow.SELECTED ||
            this.showCheckboxSelectionValue.value === TableCheckboxShow.NOT_SELECTED));

      this.dataSource.filteredData =
        !this.dataSource.filter && !testSelection
          ? this.dataSource.data
          : this.dataSource.data.filter((obj) => this.dataSource.filterPredicate(obj, this.dataSource.filter));

      if (this.paginator) {
        this.dataSource._updatePaginator(this.dataSource.filteredData.length);
      }

      return this.dataSource.filteredData;
    };
    // Change behavior of the filter to check only on the displayed columns and to check showCheckboxSelection
    // eslint-disable-next-line @typescript-eslint/ban-types
    this.dataSource.filterPredicate = (data: Object, filter: string) => {
      const show =
        !this.activateCheckboxSelection ||
        (this.activateCheckboxSelection &&
          ((this.showCheckboxSelectionValue.value !== TableCheckboxShow.ALL &&
            this.showCheckboxSelectionValue.value !== TableCheckboxShow.SELECTED &&
            this.showCheckboxSelectionValue.value !== TableCheckboxShow.NOT_SELECTED) ||
            this.showCheckboxSelectionValue.value === TableCheckboxShow.ALL ||
            (this.showCheckboxSelectionValue.value === TableCheckboxShow.SELECTED && this.checkedRowsValue.value.includes(data)) ||
            (this.showCheckboxSelectionValue.value === TableCheckboxShow.NOT_SELECTED && !this.checkedRowsValue.value.includes(data))));

      const isFound =
        filter && this.displayedColumns.some((element) => data[element] && data[element].toString().trim().toLowerCase().includes(filter));

      return show && (!filter || isFound);
    };
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string | number => {
      const column = this.displayedColumns.find((col) => col === sortHeaderId);
      const value = data[column];

      // eslint-disable-next-line no-prototype-builtins
      if (value.hasOwnProperty('label')) {
        return String(value.label).toUpperCase();
      }

      return String(value).toUpperCase();
    };
  }

  /**
   * ngOnDestroy method
   *
   * @memberof TableComponent
   */
  ngOnDestroy(): void {
    this.checkedRowsValueSubscription.unsubscribe();
    this.columnsToDisplaySelectorValueChangesSubscription.unsubscribe();
    this.dataValueSubscription.unsubscribe();
    this.showCheckboxSelectionValueSubscription.unsubscribe();
  }

  /**
   * Call when a checkbox has been checked or unchecked
   *
   * @param event
   * @param row
   * @memberof TableComponent
   */
  public onCheckedChangeRow(event: MatCheckboxChange, row: any): void {
    if (event) {
      let itemsSelected: Array<any>;
      if (row === 'master') {
        itemsSelected = event.checked ? this.dataSource.filteredData.filter((value) => !value[this.disableField]) : [];
      } else {
        this.selection.toggle(row);
        itemsSelected = this.selection.selected;
      }
      this.checkedRowsChange.emit(itemsSelected);
    }
  }

  /**
   * Set paginator and sort for the MatTableDataSource
   *
   * @memberof TableComponent
   */
  private setDataSourceAttributes(): void {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  /**
   * Update data in the table
   *
   * @memberof TableComponent
   */
  private updateData(): void {
    if (this.dataValue.value) {
      this.selection.clear();
      this.selection.select(...this.checkedRowsValue.value);
      this.allColumns = this.allColumns.length
        ? this.allColumns
        : this.dataValue.value[0]
        ? Object.keys(this.dataValue.value[0])
        : this.displayedColumns;
      this.displayedColumns = !this.displayedColumns.length ? this.allColumns : this.displayedColumns;
      this.matColumnsDef = this.activateCheckboxSelection ? ['select', ...this.displayedColumns] : this.displayedColumns;
      this.dataSource.data = this.dataValue.value;
      this.updateSelectedOptions();
      this.cd.detectChanges();
    }
  }

  /**
   * Update selected options in the columns to display selector
   *
   * @memberof TableComponent
   */
  private updateSelectedOptions(): void {
    this.selectedOptions = this.displayedColumns;
    this.columnsToDisplaySelector.patchValue([...this.displayedColumns]);
  }
}
