/* eslint-disable max-classes-per-file */
export enum FromMapperEnum {
  string = 'string',
  number = 'number',
  Object = 'Object',
  Array = 'Array',
  localisation = 'localisation',
  boolean = 'boolean',
}

export class FromMapperItem {
  mainField: string;
  subType?: FromMapperEnum;
  type: FromMapperEnum;
  subField?: string;
}

export class FromMapper {
  [key: string]: FromMapperItem;
}
