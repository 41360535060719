import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { DatePipe, NgClass, NgFor, NgIf, PercentPipe } from '@angular/common';
import { StrongPointListComponent } from 'apps/valo-front/src/app/design-system/component/strong-point-list/strong-point-list.component';
import { StrongPointResponseDto } from '@commons-dto/valo-back';

import { PageProgramHelperService } from '../page-program-helper.service';

import { tagClass, TagsInterface, TagTypeEnum } from '../../../../design-system/model/tag-option';
import { I18nService } from '../../../../utils/services/i18n.service';
import { AnchorEnum } from '../../../models/enum/anchor.enum';
import { ProgramPageService } from '../../../services/program-page.service';
import { ProgramDetail } from '../../../../utils/models/ProgramDetail';
import { SafePipe } from '../../../../utils/pipes/safe.pipe';
import { TagsComponent } from '../../../../design-system/component/tags/tags.component';

export type TagsInterfaceExtended = TagsInterface & { anchor: AnchorEnum };

@Component({
  selector: 'app-program-page-main-infos',
  templateUrl: './program-page-main-infos.component.html',
  styleUrls: ['./program-page-main-infos.component.scss'],
  standalone: true,
  imports: [NgClass, NgFor, TagsComponent, NgIf, MatIconModule, PercentPipe, SafePipe, DatePipe, StrongPointListComponent],
})
export class ProgramPageMainInfosComponent implements OnInit {
  /**
   * Output to tell parent to scroll to specific element within AnchorEnum
   *
   * @type {EventEmitter<AnchorEnum>}
   * @memberof ProgramPageHeaderComponent
   */
  @Output() readonly $shouldScrollTo: EventEmitter<AnchorEnum>;
  public anchorEnum = AnchorEnum;
  program: ProgramDetail;
  companyLogo: string;
  tagTypeEnum = TagTypeEnum;
  tagClass = tagClass;
  programStrongPoints: StrongPointResponseDto[];

  constructor(
    public i18nService: I18nService,
    private pageProgramHelperService: PageProgramHelperService,
    private _programPageService: ProgramPageService,
  ) {
    this.$shouldScrollTo = new EventEmitter<AnchorEnum>();
  }

  ngOnInit(): void {
    this.program = this.pageProgramHelperService.program;

    this.companyLogo = this.pageProgramHelperService.companyLogo;

    this.programStrongPoints = this.program.strongPoints;
  }

  get programFiscalityTags(): TagsInterface[] {
    return this._programPageService.programFiscalityTags;
  }

  get programActionButtons(): TagsInterfaceExtended[] {
    const actionButtonsArray = [
      {
        ngClass: tagClass[TagTypeEnum.BIG_WITH_BORDER],
        tagOption: {
          prefixIcon: 'map_circular',
          suffixIcon: 'chevron_right',
          text: this.i18nService._('Txt_page_program_infos_action_button_map'),
          type: TagTypeEnum.BIG_WITH_BORDER,
          isCustomPrefixIcon: true,
        },
        anchor: AnchorEnum.map,
      },
    ];
    if (this.program.documents && this.program.programTypeLabel && this.program.programName) {
      actionButtonsArray.push({
        ngClass: tagClass[TagTypeEnum.BIG_WITH_BORDER],
        tagOption: {
          prefixIcon: 'documents_circular',
          suffixIcon: 'chevron_right',
          text: this.i18nService._('Txt_page_program_infos_action_button_documents'),
          type: TagTypeEnum.BIG_WITH_BORDER,
          isCustomPrefixIcon: true,
        },
        anchor: AnchorEnum.document,
      });
    }

    if (this.program['3dView']) {
      actionButtonsArray.push({
        ngClass: tagClass[TagTypeEnum.BIG_WITH_BORDER],
        tagOption: {
          prefixIcon: '3d_circular',
          suffixIcon: 'chevron_right',
          text: this.i18nService._('Txt_page_program_infos_action_button_3dview'),
          type: TagTypeEnum.BIG_WITH_BORDER,
          isCustomPrefixIcon: true,
        },
        anchor: AnchorEnum['3dview'],
      });
    }

    return actionButtonsArray;
  }

  scrollTo(anchor: AnchorEnum) {
    // If tag is used for 3dView we change behavior
    if (anchor === AnchorEnum['3dview']) {
      this.open3dView();
    } else {
      this.$shouldScrollTo.emit(anchor);
    }
  }

  public open3dView(): void {
    window.open(decodeURIComponent(this.program['3dView']), '_blank');
  }

  isValidProfitability(value: number): boolean {
    return value !== Infinity && value !== -Infinity;
  }
}
