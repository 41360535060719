<div class="flex flex-row justify-center items-start">
  <div class="relative z-0">
    <app-login-as
      #searchAccount
      *ngIf="!isConnectionAs"
      [style.visibility]="isHomepage() ? 'visible' : 'hidden'"
      [(selectedItems)]="selectedAccountId"
      [id]="'account'"
      [max]="1"
      [parentForm]="parentForm"
      [placeholder]="i18nService._('Txt_Placeholder_LoginAs')"
      [required]="false"
    >
    </app-login-as>

    <ng-container *ngIf="isHomepage() && !isConnectionAs && selectedAccountId.length !== 0">
      <button
        mat-icon-button
        class="flex justify-center items-center button--no-style absolute right-2 top-0 h-full z-0"
        (click)="clearConnectedUserLabel()"
      >
        <mat-icon class="material-icons-outlined text-white -mt-1">cancel</mat-icon>
      </button>
    </ng-container>
  </div>
  <div title="{{ connectedUserLabel }}" class="pt-1" *ngIf="isConnectionAs && connectedUserLabel">
    <span class="mr-1 font-bold text-sm" [ngClass]="{ 'text-white': isNewHomepage }">
      {{ connectedUserLabel }}
    </span>
  </div>

  <ng-container *ngIf="isHomepage() || isConnectionAs">
    <button
      (click)="logInLogOutAs()"
      class="flex justify-center items-center bg-white text-black hover:text-orange-500 h-12 px-6 -ml-3 rounded-lg z-0"
      data-cy="menuAccountConnect"
    >
      <span class="text-sm font-semibold">
        {{ isConnectionAs ? ' - ' + i18nService._('Title_MenuAccountDisconnect') : i18nService._('Title_MenuAccountConnect') }}
      </span>
    </button>
  </ng-container>
</div>
