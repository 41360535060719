import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { isNil } from 'lodash';
import { ProspectParamRequest, ProspectsResponse, ProspectResponse } from '@commons-dto/dossier-prospect';

import { DataLayerApiService } from './data-layer-api.service';

@Injectable({ providedIn: 'root' })
export class ProspectApiService extends DataLayerApiService {
  BASE_URL = 'prospect/';

  /**
   * Get prospects by params
   *
   * @returns {Observable<ProspectsResponse>}
   * @memberof DataLayerApiService
   */
  getProspects(params: ProspectParamRequest): Observable<ProspectsResponse> {
    const path = this.BASE_URL + 'prospects';
    return this.getTableRessources(params, path);
  }

  /**
   * Get prospect by id
   *
   * @returns {Observable<AccountTrackingTableItem>}
   * @memberof DataLayerApiService
   */
  getProspectById(id: number): Observable<ProspectResponse> {
    const path = this.BASE_URL + 'prospects/' + id;
    return this.get(path);
  }

  protected getQueryParams(paramsRequest: ProspectParamRequest): HttpParams {
    let params = super.getQueryParams(paramsRequest);
    if (!isNil(paramsRequest.name)) {
      params = params.set('name', paramsRequest.name);
    }
    return params;
  }
}
