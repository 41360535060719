<div *ngIf="dossier" class="w-full pt-10 px-8 pb-8">
  <div class="flex w-full mx-auto max-w-7xl gap-8 xl:gap-10">
    <div class="flex flex-col w-[300px] min-w-[300px]">
      <app-box-back-button [goBack]="goBack()" [label]="'Prospect_Return_Prospect_List'" class="mb-6"></app-box-back-button>
      <app-sider [icon]="icon">
        <ng-container *ngFor="let prospect of dossier.prospects">
          <h4 class="text-xl font-bold">
            {{ prospect.companyName ? prospect.companyName : prospect.firstName + ' ' + prospect.lastName }}
          </h4>
          <span class="text-sm font-normal text-grey-background-dark">{{ prospect.email }}</span>
          <span class="text-sm font-normal text-grey-background-dark mb-6">{{ prospect.phoneNumber }}</span>
        </ng-container>
        <button class="outline-button" (click)="edit()">
          {{ 'Prospect_Modify_Prospect_Button' | translate }}
        </button>
      </app-sider>
    </div>
    <div class="flex flex-col flex-1 gap-x-8">
      <!-- Header -->
      <app-page-header class="mb-6">
        <span class="text-gray-300 font-bold" [innerHTML]="header"></span>
        <app-status-tag right-content [status]="dossier.dossierStatusCode | statusColor">
          {{ 'Prospect_Dossier_Status_' + dossier.dossierStatusCode | translate }}
        </app-status-tag>
      </app-page-header>
      <div>
        <p-tabView (onChange)="onTabChanged($event)" [activeIndex]="activeTab" *ngIf="dossier && dossier.id">
          <p-tabPanel header="{{ _i18n._('Prospect_Dashboard_Tab') }}">
            <ng-template pTemplate="content">
              <app-dossier-prospect-dashboard
                [isCompany]="isCompany"
                [isDossierComplete]="isDossierComplete"
                [dossier]="dossier"
                [comment]="dossier.comment"
                [actualStep]="step"
                [searchPreferencesUpdated]="searchPreferencesUpdated"
                [simulationUpdated]="simulationUpdated"
              >
              </app-dossier-prospect-dashboard>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel [header]="_i18n._('Prospect_Paternities_Tab', [dossier?.summaryPaternities?.length || 0])">
            <ng-template pTemplate="content">
              <app-paternities-list></app-paternities-list>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel [selected]="!isCompany && displaySimulation" [disabled]="isCompany">
            <ng-template pTemplate="header">
              <span
                [pTooltip]="isCompany ? _i18n._('Prospect_Simulation_Tab_toolTip_disabled') : undefined"
                tooltipPosition="bottom"
                class="p-tabview-title"
              >
                {{ _i18n._('Prospect_Simulation_Tab') }}
              </span>
            </ng-template>
            <ng-template pTemplate="content">
              <app-simulation-tab
                [dossier]="dossier"
                (searchPreferencesUpdated)="searchPreferencesUpdated.next($event)"
                (simulationUpdated)="simulationUpdated.next()"
                class="flex justify-between items-start"
              ></app-simulation-tab>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel [header]="_i18n._('Prospect_Criteres_recherche_Tab')">
            <app-search-preference
              [dossier]="dossier"
              [searchPreferencesUpdated]="searchPreferencesUpdated"
              (searchPreferencesUpdate)="searchPreferencesUpdated.next($event)"
              [simulationUpdated]="simulationUpdated"
            ></app-search-preference>
          </p-tabPanel>
          <p-tabPanel [header]="_i18n._('Prospect_Lots_Tab')">
            <ng-template pTemplate="content">
              <app-dossier-prospect-lots
                [dossier]="dossier"
                [searchPreferencesUpdated]="searchPreferencesUpdated"
              ></app-dossier-prospect-lots>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel [header]="_i18n._('Prospect_Transactions_Tab', [dossier?.nbOptions])">
            <ng-template pTemplate="content">
              <app-transaction [dossier]="dossier"></app-transaction>
            </ng-template>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</div>
