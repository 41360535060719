<mat-dialog-actions>
  <div class="app-program-page-neighborhood-map-dialog-back cursor-pointer" (click)="close()">
    <mat-icon class="size-18">arrow_back</mat-icon>
    <span>{{ i18nService._('Txt_Button_GoBack') }}</span>
  </div>
  <button mat-button mat-dialog-close tabindex="-1">
    <span class="icon-cancel text-sm"></span>
  </button>
</mat-dialog-actions>
<mat-dialog-content>
  <div class="app-program-page-neighborhood-map-dialog-left-side" *ngIf="showLeftSide">
    <ng-container *ngIf="programText.situation && programText.situation.length">
      <span class="app-program-page-neighborhood-map-dialog-title">{{ i18nService._('Title_Situation') }}</span>
      <div class="app-program-page-neighborhood-map-dialog-content" [innerHTML]="programText.situation | safe: 'html'"></div>
    </ng-container>
    <ng-container *ngIf="programText.access && programText.access.length">
      <span class="app-program-page-neighborhood-map-dialog-title">{{ i18nService._('Title_Access') }}</span>
      <div class="app-program-page-neighborhood-map-dialog-content" [innerHTML]="programText.access | safe: 'html'"></div>
    </ng-container>
  </div>
  <div
    class="app-program-page-neighborhood-map-dialog-city-scan"
    id="cityscan-widget"
    data-id=""
    data-client-key=""
    data-language=""
    #cityScanWidget
  ></div>
</mat-dialog-content>
