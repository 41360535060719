<app-filter-sort-header-bar></app-filter-sort-header-bar>

<div class="main">
  <app-card *ngIf="transactions.items === undefined || transactions.items.length === 0" class="card no-transactions">
    <div class="head-no-transactions">{{ 'Txt_DossierProspectNoTransaction' | translate }}</div>
    <div class="detail-no-transactions">{{ 'Txt_DossierProspectOptionMessage' | translate }}</div>

    <button (click)="search()" class="primary">{{ 'Txt_TransactionSearchButtonWithCriteria' | translate }}</button>
  </app-card>
  <div class="nav-bar-backdrop" [class.hide]="!panelOpened" (click)="close()"></div>
  <div class="nav-bar" [class.hide]="!panelOpened">
    <app-lot-details-panel
      [lotId]="transactionSelected?.lotId"
      [optionLotPriceId]="transactionSelected?.transactionType === TransactionType.OPTION ? transactionSelected.id : undefined"
      (closePanel)="close()"
    ></app-lot-details-panel>
  </div>

  <app-card *ngFor="let transaction of transactions.items" class="card">
    <div class="program-thumbnail">
      <img src="{{ '/logos/programDocuments/' + transaction.programThumbnailPath }}" alt="program photo" />
    </div>
    <div class="infos">
      <div class="lot">
        <span>N° lot : {{ transaction.lotNumber }}</span>
        <mat-icon
          ngClass="text-sm"
          class="tooltip"
          matTooltip="{{ 'Title_TrackingTable_Column_LotNumber_Picto_Tooltip' | translate }}"
          (click)="open(transaction)"
          >informations
        </mat-icon>
      </div>
      <div class="dates">
        <span>
          {{ transaction.dateStartLibelle }} :
          <span class="value">
            {{ transaction.transactionStartDate | date: 'dd/MM/yyyy à HH:mm' }}
          </span>
        </span>
        <span>
          {{ transaction.dateEndLibelle }}
          <span class="value">
            {{ transaction.endDateToDisplay | date: 'dd/MM/yyyy à HH:mm' }}
          </span>
        </span>
      </div>
      <div class="program">
        {{ transaction.companyName }} / {{ transaction.programName }} / {{ transaction.city }} ({{ transaction.departementCode }})
      </div>
      <div class="detail">
        <span class="price">{{ transaction.price | currency: 'EUR' : 'symbol' : '1.0-0' }}</span>
        <span class="rooms">T{{ transaction.rooms }}</span>
        <span class="area">{{ transaction.livingSpace | number: '1.0-0' }} m²</span>
      </div>
    </div>
    <div class="status_actions">
      <div>
        <app-status-tags class="tags" outline="false" [statusColor]="transaction.status | transactionStatusColor">
          <span class="">{{ 'TRANSACTION_' + transaction.status | translate }}</span>
        </app-status-tags>
      </div>
      <div class="actions">
        <app-action-list-button
          i18nButtonLabel="Txt_Button_Action"
          [actions]="hTransactionActions[transaction.id]"
        ></app-action-list-button>
      </div>
    </div>
  </app-card>
</div>
