import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

import { FileComponent } from '../../form/file/file.component';

@Component({
  selector: 'app-formly-file',
  templateUrl: './formly-file.component.html',
  styleUrls: ['./formly-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FileComponent],
})
export class FormlyFileComponent extends FieldType<FieldTypeConfig> {}
