import { StrongPointResponseDto } from '@commons-dto/valo-back';

import { CoordinatesResponse } from './CoordinatesResponse';

export class ProgramSearchResponse {
  city: string;
  postalCode: string;
  programName: string;
  publishedDate: Date;
  address: string;
  countryLabel: string;
  countryCode: string;
  perspectiveTitle: string;
  perspectiveContainer: string;
  perspectiveFileName: string;
  departementCode: string;
  price: number;
  maxPrice?: number;
  labelsProfitability: string;
  minRoom: number;
  maxRoom: number;
  programTypeLabel: string;
  minDeliveryDate: Date;
  maxDeliveryDate: Date;
  programId: number;
  nbLotsAvailable: number;
  nbLotsNotAvailable: number;
  nbLotsOptionned: number;
  nbSpecialOffers: number;
  // Used to know if program is in proximityCity or local city
  isProximityCity: number;
  zoneLabel: string;
  isHighStake: boolean;
  latitude?: number;
  longitude?: number;
  coordinates?: CoordinatesResponse;
  strongPoints?: StrongPointResponseDto[];
  firstPublicationDate?: Date;
  constructionWorkRatio?: number;
}
