/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs';

import { ExcludeListTrackingTableResponse } from '../models/exlude-list/exclude-list-tracking-table-response';
import { AbstractAdvancedTrackingTableNoBackPageService } from './abstract-advanced-tracking-table-no-back-page.service';

import { CacheService } from '../../utils/services/cache.service';

@Injectable({
  providedIn: 'root',
})
export class ExcludeListTrackingTableService extends AbstractAdvancedTrackingTableNoBackPageService<ExcludeListTrackingTableResponse> {
  protected url = 'Companies/exclude/active-contractors';
  protected serviceName = 'ExcludeListTrackingTableService';

  constructor(private readonly _cacheService: CacheService) {
    super();
  }

  saveExcludeList(companyId: number, companyContractorIds: number[]): Observable<any> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/CompanyDeveloperHasCompanyContractorExclusions/${companyId}/exclusion`;

    return this.http
      .post(url, { companyContractorIds })
      .pipe(catchError(this.errorHandlerService.handleError<any>('ExcludeListTrackingTableService', 'saveList')));
  }
}
