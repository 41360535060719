import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, lastValueFrom, Observable, of, Subject, take, tap } from 'rxjs';

import { ValidateDeveloperCompanyName } from '../models/validate-developer-company-name';

import { CompanyData } from '../../utils/models/CompanyData';
import { AppConfigService } from '../../utils/services/app-config.service';
import { CacheService } from '../../utils/services/cache.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';
import { I18nService } from '../../utils/services/i18n.service';
import { GroupCompanyResponse } from '../../utils/models/GroupCompanyResponse';
import { CompanyApiService } from '../../adapters/company-api.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  /**
   * Attribute to notify if mandat delegation exists.
   *
   * @type {BehaviorSubject<number>}
   * @memberof CompanyService
   */
  public readonly isMandatDelegation = new BehaviorSubject(false);
  /**
   * Attribute to notify changes detected.
   *
   * @type {BehaviorSubject<number>}
   * @memberof CompanyService
   */
  public readonly changesDetected: Subject<boolean> = new Subject();
  /**
   * Attribute to notify previously selected stepper.
   *
   * @type {BehaviorSubject<number>}
   * @memberof CompanyService
   */
  public readonly previouslySelectedIndex = new Subject();
  /**
   * Attribute to notify selected admin contact type.
   *
   * @type {BehaviorSubject<number>}
   * @memberof CompanyService
   */
  public readonly contactAdminTypeId = new Subject();
  private readonly getValidateDeveloperCompaniesCacheName = 'getValidateDeveloperCompaniesCache';

  /**
   * Creates an instance of CompanyService.
   *
   * @param {HttpClient} http
   * @param {ErrorHandlerService} errorHandlerService
   * @param {AppConfigService} appConfig
   * @param {I18nService} i18nService
   *
   * @param _cacheService
   * @memberof CompanyService
   */
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
    private readonly i18nService: I18nService,
    private readonly _cacheService: CacheService,
    private readonly companyApiService: CompanyApiService,
  ) {}

  public static filterCompany(
    value: string | ValidateDeveloperCompanyName,
    companyList: Array<ValidateDeveloperCompanyName>,
  ): Array<ValidateDeveloperCompanyName> {
    if (!companyList && !companyList.length) {
      return [];
    }
    if (!value) {
      return [...companyList];
    }
    if (value instanceof Object) {
      return companyList.filter((option) => option.id === value.id);
    }
    const filterValue = value.toLowerCase();

    return companyList.filter((option) => option.corporateName.toLowerCase().includes(filterValue));
  }

  checkSiret(siret: string): Observable<{ siretFound: boolean }> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Companies/check-siret/${siret}`;

    return this.http
      .get<{ siretFound: boolean }>(url)
      .pipe(catchError(this.errorHandlerService.handleError<{ siretFound: boolean }>('companyService', 'get')));
  }

  createCompany(companyData: CompanyData): Observable<CompanyData> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Companies`;

    return this.http.post<CompanyData>(url, companyData).pipe(
      tap((response: CompanyData) => response),
      catchError(this.errorHandlerService.handleError<CompanyData>('CompanyService', 'createCompany')),
    );
  }

  updateCompany(companyData: CompanyData, companyId: string, token?: string): Observable<CompanyData> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Companies/${companyId}`;

    let params = new HttpParams();
    // Add token if the user is a visitor (not valo)
    if (token) {
      params = params.set('token', token);
    }

    return this.http.put<CompanyData>(url, companyData, { params }).pipe(
      tap((response: CompanyData) => response),
      catchError(this.errorHandlerService.handleError<CompanyData>('CompanyService', 'updateCompany')),
    );
  }

  getCompanyInformation(companyId: string, token?: string): Observable<CompanyData> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Companies/informations/${companyId}`;

    let params = new HttpParams();
    // Add token if the user is a visitor (not valo)
    if (token) {
      params = params.set('token', token);
    }

    return this.http
      .get<CompanyData>(url, { params })
      .pipe(catchError(this.errorHandlerService.handleError<CompanyData>('companyService', 'get')));
  }

  async getCompanyInformationById(id: number): Promise<CompanyData> {
    return lastValueFrom(this.companyApiService.getCompanyInformationById(id));
  }

  getCompanyStatusId(companyStatus: string): Observable<{ id: number }> {
    const filter = {
      fields: {
        id: true,
      },
      where: {
        label: companyStatus,
      },
    };

    const params = new HttpParams().set('filter', JSON.stringify(filter));
    const url = `${this.appConfig.getLoopbackApiUrl()}/CompanyStatuses/findOne`;

    return this.http
      .get<{ id: number }>(url, { params })
      .pipe(catchError(this.errorHandlerService.handleError<{ id: number }>('companyService', 'get')));
  }

  /**
   * Get Company Types Id
   * Possibility to send a array of company type labels
   *
   * @param {Array<string>} companyType
   * @returns
   * @memberof CompanyService
   */
  getCompanyTypesId(companyType: Array<string>): Observable<{ id: number }> {
    const filter = {
      fields: {
        id: true,
      },
      where: {
        label: {
          inq: companyType,
        },
      },
    };

    const params = new HttpParams().set('filter', JSON.stringify(filter));
    const url = `${this.appConfig.getLoopbackApiUrl()}/CompanyTypes`;

    return this.http
      .get<{ id: number }>(url, { params })
      .pipe(catchError(this.errorHandlerService.handleError<{ id: number }>('companyService', 'get')));
  }

  /**
   * Get Profil Types Id
   * Possibility to send a array of profil labels
   * @param {Array<string>} profils
   * @returns
   * @memberof CompanyService
   */
  getProfilsId(profils: Array<string>): Observable<{ id: number }> {
    const filter = {
      fields: {
        id: true,
      },
      where: {
        label: {
          inq: profils,
        },
      },
    };
    const params = new HttpParams().set('filter', JSON.stringify(filter));
    const url = `${this.appConfig.getLoopbackApiUrl()}/Profils`;

    return this.http
      .get<{ id: number }>(url, { params })
      .pipe(catchError(this.errorHandlerService.handleError<{ id: number }>('companyService', 'get')));
  }

  getGroupCompanyList(companyTypeObject): Observable<GroupCompanyResponse[]> {
    const params = new HttpParams().set('filter[where][categoryId]', companyTypeObject[0].id);
    const url = `${this.appConfig.getLoopbackApiUrl()}/GroupCompanies`;

    return this.http
      .get<GroupCompanyResponse[]>(url, { params })
      .pipe(catchError(this.errorHandlerService.handleError<GroupCompanyResponse[]>('companyService', 'get')));
  }

  getValidateDeveloperCompanies(): Observable<Array<ValidateDeveloperCompanyName>> {
    const data = this._cacheService.getData(this.getValidateDeveloperCompaniesCacheName);
    if (data) {
      return of(data).pipe(take(1));
    }
    const url = `${this.appConfig.getLoopbackApiUrl()}/Companies/developers/active`;

    return this.http.get<Array<ValidateDeveloperCompanyName>>(url).pipe(
      tap((value) => {
        this._cacheService.addToCache(this.getValidateDeveloperCompaniesCacheName, value);
      }),
      catchError(
        this.errorHandlerService.handleError<Array<ValidateDeveloperCompanyName>>('companyService', 'getValidateDeveloperCompanies'),
      ),
    );
  }
}
