import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';

import { CompanyService } from '../../companies/services/company.service';

export const siretValidator = (companyService: CompanyService, oldSiret?: string): AsyncValidatorFn => {
  return (control: AbstractControl): Observable<{ [key: string]: boolean } | undefined> => {
    return new Observable((observer) => {
      if (oldSiret && oldSiret === control.value) {
        observer.next();
        observer.complete();
      } else {
        companyService.checkSiret(control.value).subscribe(
          (siretResponse) => {
            if (siretResponse.siretFound) {
              observer.next({ siretExist: true });
            } else {
              observer.next();
            }
            observer.complete();
          },
          (err) => {
            observer.error(err);
          },
        );
      }
    });
  };
};
