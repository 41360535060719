export enum SignatureStatus {
  DRAFT = 'DRAFT',
  SIGN_ACQ = 'SIGN_ACQ',
  SIGN_PAPER = 'SIGN_PAPER',
  REJECT_ACQ = 'REJECT_ACQ',
  ACCEPT_VALO = 'ACCEPT_VALO',
  REJECT_VALO = 'REJECT_VALO',
  REJECT_DEV = 'REJECT_DEV',
  ACCEPT_DEV = 'ACCEPT_DEV',
  COUNTERSIGN = 'COUNTERSIGN',
  COUNTERSIGNED = 'COUNTERSIGNED',
}
