<ng-template #smallScreenMessageRef>
  <div class="warning-message">
    <mat-icon class="material-icons-outlined size-24">computer</mat-icon>
    <div>{{ i18nService._('TXT_comparator_alert_message_small_screen') }}</div>
    <button (click)="gotToSearch()" mat-flat-button color="primary" type="button">
      {{ i18nService._('TXT_comparator_alert_button_small_screen') }}
    </button>
  </div>
</ng-template>
<ng-template #emptyComparator>
  <div class="warning-message">
    <mat-icon class="material-icons-outlined size-24">home_work</mat-icon>
    <div [innerHTML]="i18nService._('TXT_comparator_alert_no_lot') | safe: 'html'"></div>
    <button (click)="gotToSearch()" mat-flat-button color="primary" type="button">
      {{ i18nService._('TXT_comparator_alert_button_no_lot') }}
    </button>
  </div>
</ng-template>

<ng-container *ngIf="!hide; else smallScreenMessageRef">
  <div class="comparator-header">
    <app-button-back [goBack]="goBack()" [backLabel]="'Txt_Button_GoBack'"></app-button-back>
    <div class="title">{{ i18nService._('Txt_comparator_lot_title') }}</div>
  </div>
  <ng-container *ngIf="lotDetailToCompare$ | async">
    <ng-container *ngIf="atLeastOneLot; else emptyComparator">
      <div class="comparator-lot-header">
        <div
          *ngFor="let lot of lotDetailToCompare"
          [ngClass]="{
            'comparator-content-empty': !lot,
            'lot-is-unavailable': lot?.isUnvailable,
            'comparator-optionned-by-me': lot?.status.optionnedByMe && !lot?.status.sold
          }"
        >
          <ng-container *ngIf="lot">
            <div class="close" (click)="removeLot(lot)">
              <span class="material-icons-outlined">close</span>
            </div>
            <app-lot-comparator-head [lot]="lot"></app-lot-comparator-head>
          </ng-container>
        </div>
      </div>
      <app-lot-comparator-glance [lotDetailToCompare]="lotDetailToCompare"></app-lot-comparator-glance>
      <app-lot-comparator-information [lotDetailToCompare]="lotDetailToCompare"></app-lot-comparator-information>
      <app-lot-comparator-price-details [lotDetailToCompare]="lotDetailToCompare"></app-lot-comparator-price-details>
      <app-lot-comparator-rent-and-profitablity [lotDetailToCompare]="lotDetailToCompare"></app-lot-comparator-rent-and-profitablity>
      <app-lot-comparator-annexes [lotDetailToCompare]="lotDetailToCompare"></app-lot-comparator-annexes>
    </ng-container>
  </ng-container>
</ng-container>
