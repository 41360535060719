import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface Cache<T> {
  [key: string]: BehaviorSubject<T>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type serializable = object | Object;

const DEFAULT_EXPIRATION_TIME_IN_MILLISECONDS = 1000 * 60 * 30; // 30 minutes

/**
 * Subscribe to all localstorage events
 * Subscribe to when and what data is stored in localstorage
 * Subscribe to when and what data is modified in localstorage
 * Subscribe to when and what data is removed from localstorage]
 * Subscribe to last and what saved data that was stored in localstorage
 * Subscribe to when and what data is evicted or deleted from localstorage
 */

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  private readonly cache: Cache<unknown>;
  private expiration = DEFAULT_EXPIRATION_TIME_IN_MILLISECONDS;

  constructor(@Inject(DEFAULT_EXPIRATION_TIME_IN_MILLISECONDS) @Optional() expiration: number) {
    this.expiration = expiration ?? DEFAULT_EXPIRATION_TIME_IN_MILLISECONDS;

    this.cache = Object.create({});
  }

  /**
   * Set expiration time for items in localStorage.
   * @NOTE: This method clear localstorage.
   * @param expiration The expiration time in milliseconds
   * @returns void
   * @example
   * this.localStorage.setExpiration(1000);
   */
  set expirationTime(time: number) {
    this.clear();
    this.expiration = time;
  }

  /**
   * Set an item in localStorrage.
   * @param key The item's key
   * @param data The item's value
   * @returns A RxJS `Observable` to wait the end of the operation
   *
   * @example
   * this.localStorage.set('key', 'value').subscribe(() => {});
   */
  setItem<T extends serializable>(key: string, value: T, config?: { withExpiration: boolean }): BehaviorSubject<T> {
    if (config?.withExpiration) {
      return this.setItemWithExpiration(key, value);
    }

    localStorage.setItem(key, JSON.stringify(value));

    if (this.cache[key]) {
      this.cache[key].next(value);

      return this.cache[key] as BehaviorSubject<T>;
    }

    return (this.cache[key] = new BehaviorSubject(value));
  }

  /**
   * Get an item value in localStorage.
   * @param key The item's key
   * @returns The item's value if the key exists, `null` otherwise, wrapped in a RxJS `Observable`
   *
   * @example
   * this.localStorage.get('key').subscribe((result) => {
   *   result; // string or null
   * });
   *
   */
  getItem<T extends serializable>(key: string, config?: { withExpiration: boolean }): BehaviorSubject<T> {
    if (config?.withExpiration) {
      return this.getItemWithExpiration<T>(key);
    }

    if (this.cache[key]) {
      return this.cache[key] as BehaviorSubject<T>;
    }

    return (this.cache[key] = new BehaviorSubject(JSON.parse(localStorage.getItem(key))));
  }

  /**
   * Delete an item in localStorage
   * @param key The item's key
   *
   * @example
   * this.localStorage.removeItem('key');
   */
  removeItem(key: string): void {
    localStorage.removeItem(key);
    if (this.cache[key]) {
      this.cache[key].next(undefined);
    }
  }

  /**
   * Clear the localStorage
   * @returns A RxJS `Observable` to wait the end of the operation
   * @example
   * this.localStorage.clear().subscribe(() => {});
   *
   */
  clear(): BehaviorSubject<void> {
    localStorage.clear();
    return new BehaviorSubject(undefined);
  }

  /**
   * Get item value in localStorage with expiration time.
   * @param key The item's key
   * @returns The item's value if the key exists, `null` otherwise, wrapped in a RxJS `Observable`
   *  @example
   * this.localStorage.getItemWithExpiration('key', 1000).subscribe((result) => {
   *   result; // string or null
   * }
   */
  private getItemWithExpiration<T extends serializable>(key: string): BehaviorSubject<T> {
    let item = { value: null, expiration: null };

    if (this.cache[key]?.value && this.cache[`${key}_expiration`]?.value) {
      item.value = this.cache[key].value;
      item.expiration = this.cache[`${key}_expiration`].value;
    } else {
      item = JSON.parse(localStorage.getItem(key));
    }

    if (!item?.expiration) {
      return new BehaviorSubject(null);
    }

    const now = new Date();
    // compare the expiry time of the item with the current time
    const isExpired = now.getTime() > new Date(item.expiration).getTime();
    if (isExpired) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return new BehaviorSubject(null);
    }

    this.cache[key] = new BehaviorSubject(item.value);
    this.cache[`${key}_expiration`] = new BehaviorSubject(item.expiration);

    // If the item is not expired, return the item value
    return this.cache[key] as BehaviorSubject<T>;
  }

  /**
   * Set item value in localStorage with expiration time.
   * @param key The item's key
   * @param value The item's value
   * @returns A RxJS `Observable` to wait the end of the operation
   * @example
   * this.localStorage.setItemWithExpiration('key', 'value', 1000).subscribe(() => {});
   */
  private setItemWithExpiration<T extends serializable>(key: string, value: T): BehaviorSubject<T> {
    const expirationDate = new Date(Date.now() + this.expiration);

    const item = {
      value,
      expiration: expirationDate,
    };

    localStorage.setItem(key, JSON.stringify(item));

    if (this.cache[key]) {
      this.cache[key].next(value);
      this.cache[`${key}_expiration`].next(expirationDate);

      return this.cache[key] as BehaviorSubject<T>;
    }

    this.cache[key] = new BehaviorSubject(value);
    this.cache[`${key}_expiration`] = new BehaviorSubject(expirationDate);

    return this.cache[key] as BehaviorSubject<T>;
  }

  isItemExist(key: string): boolean {
    return !!localStorage.getItem(key);
  }
}
