import { Component, ComponentFactoryResolver, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { NgIf } from '@angular/common';

import { canEditLot, canPublishImportedUnpublishlot } from './lots-table.program.check';

import { QuestionDialogComponent } from '../../../dialog/components/question-dialog/question-dialog.component';
import { QuestionDialogData } from '../../../dialog/models/QuestionDialogData';
import { QuestionDialogResponse } from '../../../dialog/models/QuestionDialogResponse';
import { LotService } from '../../../lot/service/lot.service';
import { GenericActionsComponent } from '../../../table/components/generic-actions/generic-actions.component';
import { GenericActions } from '../../../table/interfaces/GenericActions';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { LotResponse } from '../../../utils/models/LotResponse';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { BasicFormatsService } from '../../../utils/services/basic-formats.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SharedProgramService } from '../../../utils/services/shared-program.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { ProgramLotTableDisplayData } from '../../models/ProgramLotTableDisplayData';
import { ProgramService } from '../../services/program.service';
import { TableModule } from '../../../table/table.module';

@Component({
  selector: 'app-lots-table-program',
  templateUrl: './lots-table-program.component.html',
  styleUrls: ['./lots-table-program.component.scss'],
  standalone: true,
  imports: [NgIf, TableModule],
})
export class LotsTableProgramComponent implements GenericActions, OnInit, OnDestroy {
  /**
   * List of all columns for dropdown columns to display in table
   *
   * @type {string[]}
   * @memberof LotsTableProgramComponent
   */
  public allColumns: Array<string> = [];

  /**
   * columnTitles attribute for columnTitles input of app-table
   *
   * @type {{ [key: string]: string }}
   * @memberof LotsTableProgramComponent
   */
  public columnTitles: { [key: string]: string } = {};

  /**
   * List of columns to display at creation
   *
   * @type {string[]}
   * @memberof LotsTableProgramComponent
   */
  public displayedColumns: Array<string> = [];

  /**
   * List of lots to display
   *
   * @type {ProgramLotTableDisplayData[]}
   * @memberof LotsTableProgramComponent
   */
  public lots: Array<ProgramLotTableDisplayData> = [];

  /**
   * Id of the program
   *
   * @type {string}
   * @memberof LotsTableProgramComponent
   */
  @Input() public programId: string;

  /**
   * Response of lots request
   *
   * @type {LotResponse[]}
   * @memberof LotsTableProgramComponent
   */
  public programLotList: Array<LotResponse>;

  /**
   * List of columns to allow sorting
   *
   * @type {string[]}
   * @memberof LotsTableProgramComponent
   */
  public sortedColumns: Array<string> = [];

  /**
   * Global columns formats.
   *
   * @type {{ [key: string]:  Array<string> }}
   * @memberof LotsTableProgramComponent
   */
  public columnFormats: { [key: string]: Array<string> } = {};
  /**
   * Input used to know if program is from XML import
   *
   * @type {boolean}
   * @memberof LotsTableProgramComponent
   */
  @Input() public isImported: boolean;
  /**
   * Input used to know if user is Valo or not
   *
   * @type {boolean}
   * @memberof LotsTableProgramComponent
   */
  @Input() public isValo: boolean;
  @Output() readonly isDeleted: EventEmitter<boolean>;
  @Output() readonly selectedLotForEdit: EventEmitter<LotResponse>;
  currencySubscription: Subscription;
  /**
   * Currency string for currency format
   *
   * @type {string}
   * @memberof LotsTableProgramComponent
   */
  private currency = undefined;

  /**
   * Creates an instance of LotsTableProgramComponent
   *
   * @param snackbarService
   * @param i18nService
   * @param viewContainerRef
   * @param componentFactoryResolver
   * @param programService
   * @param sharedProgramService
   * @param dialog
   * @param basicFormatsService
   */
  constructor(
    private readonly snackbarService: SnackbarService,
    public i18nService: I18nService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly programService: ProgramService,
    private readonly sharedProgramService: SharedProgramService,
    private readonly dialog: MatDialog,
    private readonly basicFormatsService: BasicFormatsService,
    private readonly appConfigService: AppConfigService,
    private readonly lotService: LotService,
  ) {
    this.isDeleted = new EventEmitter(false);
    this.selectedLotForEdit = new EventEmitter(undefined);
    this.programLotList = [];
  }

  /**
   * Delete a lot
   *
   * @param element
   * @memberof LotsTableProgramComponent
   */
  delete(element: LotResponse): void {
    const dialogData: MatDialogConfig<QuestionDialogData> = {
      data: {
        message: this.i18nService._('Txt_Popin_Lot_Deletion'),
        title: this.i18nService._('Title_Lot_Deletion'),
      },
    };

    const dialogRef = this.dialog.open(QuestionDialogComponent, dialogData);

    dialogRef.afterClosed().subscribe((result: QuestionDialogResponse) => {
      if (result && result.answer) {
        this.programService.deleteLot(element.id).subscribe(() => {
          const message: SnackbarMessage = {
            text: this.i18nService._('Success_SnackBar_Lot_Deleted'),
            type: SnackbarMessageType.Info,
          };
          this.snackbarService.sendMessage(message);
          this.updateLotTableData(element, 'delete');
          this.isDeleted.emit(true);
        });
      }
    });
  }

  /**
   * Edit a lot
   *
   * @param element
   * @memberof LotsTableProgramComponent
   */
  public edit(element: LotResponse): void {
    this.selectedLotForEdit.emit(element);
  }

  /**
   * Publish a lot
   *
   * @param {LotResponse} element
   * @memberof LotsTableProgramComponent
   */
  public publish(element: LotResponse): void {
    this.lotService.publishLot(element.id).subscribe(() => {
      element.isPublished = true;
      const message: SnackbarMessage = {
        text: this.i18nService._('Info_SnackBar_LotPublished'),
        type: SnackbarMessageType.Info,
      };
      this.snackbarService.sendMessage(message);
      // Dont subscribe again for lots information to update table, just update lot data in local
      this.updateLotTableData(element, 'changePublicationState');
    });
  }

  /**
   * Unpublish a lot
   *
   * @param {LotResponse} element
   * @memberof LotsTableProgramComponent
   */
  public unpublish(element: LotResponse): void {
    // Open modal
    const dialogData: MatDialogConfig<QuestionDialogData> = {
      data: {
        message: this.i18nService._('Txt_Popin_Lot_Unpublication'),
        title: this.i18nService._('Title_Lot_Unpublication'),
      },
    };
    const dialogRef = this.dialog.open(QuestionDialogComponent, dialogData);

    dialogRef.afterClosed().subscribe((result: QuestionDialogResponse) => {
      if (result && result.answer) {
        this.lotService.unpublishLot(element.id).subscribe(() => {
          element.isPublished = false;
          const message: SnackbarMessage = {
            text: this.i18nService._('Info_SnackBar_LotUnpublished'),
            type: SnackbarMessageType.Info,
          };
          this.snackbarService.sendMessage(message);
          // Dont subscribe again for lots information to update table, just update lot data in local
          this.updateLotTableData(element, 'changePublicationState');
        });
      }
    });
  }

  /**
   * Init component
   *
   * @memberof LotsTableProgramComponent
   */
  public ngOnInit(): void {
    // Prepare data to display table
    this.columnTitles = {
      number: 'Txt_Table_Column_Number',
      typo: 'Txt_Table_Column_Typo',
      rooms: 'Txt_Table_Column_Rooms',
      livingSpace: 'Txt_Table_Column_LivingSpace',
      commercialLotSellingPriceIT: 'Txt_Table_Column_CommercialLotSellingPriceIT',
      reducedTotalSellingPriceIT: 'Txt_Table_Column_ReducedSellingPriceIT',
      status: 'Txt_Table_Column_Status',
      isPublished: 'Txt_Table_Column_IsPublished',
      deliveryDate: 'Txt_Table_Column_Delivery',
      floor: 'Txt_Table_Column_Floor',
      actions: 'Txt_Table_Column_Actions',
      isComplete: 'Txt_Table_Column_IsComplete',
    };
    this.allColumns = [
      'number',
      'typo',
      'rooms',
      'livingSpace',
      'commercialLotSellingPriceIT',
      'reducedTotalSellingPriceIT',
      'status',
      'isPublished',
      'deliveryDate',
      'floor',
      'actions',
      'isComplete',
    ];
    this.displayedColumns = [
      'number',
      'typo',
      'rooms',
      'livingSpace',
      'commercialLotSellingPriceIT',
      'reducedTotalSellingPriceIT',
      'status',
      'isPublished',
      'actions',
      'isComplete',
    ];
    this.sortedColumns = [
      'number',
      'typo',
      'rooms',
      'livingSpace',
      'commercialLotSellingPriceIT',
      'reducedTotalSellingPriceIT',
      'status',
      'isPublished',
      'deliveryDate',
      'floor',
      'isComplete',
    ];

    this.columnFormats = {
      commercialLotSellingPriceIT: ['thousandsSeparator'],
      reducedTotalSellingPriceIT: ['thousandsSeparator'],
    };

    this.currencySubscription = this.programService.currencyObservable.subscribe((currency) => {
      if (currency) {
        this.currency = currency;
      }
    });

    this.getProgramLots();
  }

  /**
   * Update Lot Table data depending to source
   *
   * @param {lotTypeLabel} element
   * @param {string} source
   * @memberof LotsTableProgramComponent
   */
  public updateLotTableData(element: LotResponse, source: string): void {
    switch (source) {
      case 'delete':
        this.programLotList = this.programLotList.filter((lot) => lot.id !== element.id);
        break;
      case 'create':
        this.programLotList.push(element);
        break;
      default:
        // Modify the element without changing the index
        this.programLotList = this.programLotList.reduce((acc, lot) => (lot.id === element.id ? acc.concat(element) : acc.concat(lot)), []);
    }
    this.populateLots();
  }

  /**
   * On destroy method to set to undefined behavior subject
   *
   * @memberof LotsTableProgramComponent
   */
  ngOnDestroy(): void {
    if (this.currencySubscription) {
      this.currencySubscription.unsubscribe();
      this.programService.setCurrency(undefined);
    }
    this.programService.setLotSummary(undefined);
    this.programService.setContractualDocumentPackages({
      isApartmentContractualDocumentPackages: false,
      isHouseContractualDocumentPackages: false,
      isTradeContractualDocumentPackages: false,
    });
  }

  /**
   * Call database to get lots data
   *
   * @memberof LotsTableProgramComponent
   */
  private getProgramLots(): void {
    if (this.programId) {
      this.sharedProgramService.getProgramLotsInformation(this.programId).subscribe(
        (itemsFound) => {
          if (itemsFound.error) {
            const message: SnackbarMessage = {
              text: this.i18nService._('Error_SnackBar_ErrorOccuredRetry'),
              type: SnackbarMessageType.Error,
            };
            this.snackbarService.sendMessage(message);
          }

          this.programLotList = itemsFound.lots;
          this.populateLots();
        },
        () => {
          const message: SnackbarMessage = {
            text: this.i18nService._('Error_SnackBar_ErrorOccuredRetry'),
            type: SnackbarMessageType.Error,
          };
          this.snackbarService.sendMessage(message);
        },
      );
    }
  }

  /**
   * Used to resolve lot status label translated from app config
   * @param lotStatusLabel {object}
   * @param lotElementLabel {string}
   */
  private getStatus(lotStatusLabel: Record<string, string>, lotElementLabel: string): string {
    const { free, unavailable, optioned, reserved, prereserved, sold } = lotStatusLabel;

    const statusLabelsTranslated = {
      [free]: this.i18nService._('Txt_Detail_Program_Status_Lot_Free'),
      [unavailable]: this.i18nService._('Txt_Detail_Program_Status_Lot_Unavailable'),
      [optioned]: this.i18nService._('Txt_Detail_Program_Status_Lot_Optioned'),
      [reserved]: this.i18nService._('Txt_Detail_Program_Status_Lot_Reserved'),
      [prereserved]: this.i18nService._('Txt_Detail_Program_Status_Lot_Prereserved'),
      [sold]: this.i18nService._('Txt_Detail_Program_Status_Lot_Sold'),
    };

    if (!statusLabelsTranslated[lotElementLabel]) {
      return '';
    }

    return statusLabelsTranslated[lotElementLabel];
  }

  /**
   * Fill the lots array with database data
   *
   * @memberof LotsTableProgramComponent
   */
  private populateLots(): void {
    this.lots = [];

    if (Array.isArray(this.programLotList) && this.programLotList.length > 0) {
      this.programLotList.forEach((element: LotResponse) => {
        const deliveryDate = element.deliveryDate ? new Date(element.deliveryDate) : undefined;

        // Create actions component without adding it directly to the DOM
        const factory = this.componentFactoryResolver.resolveComponentFactory(GenericActionsComponent);
        const component = this.viewContainerRef.createComponent(factory);
        component.instance.genericActions = this;
        component.instance.element = element;
        component.instance.editToolTip = this.i18nService._('Txt_Tooltip_EditLot');
        component.instance.isMenu = true;
        // Can edit if user is Valo or if is developer and program is not imported. And if lot is not published and free
        // RG_108.14.2: Can edit if lot is
        component.instance.isEditable = canEditLot(
          this.isValo,
          this.isImported,
          this.appConfigService.getAppConfig().statusLotLabels,
          element,
        );

        // Can delete if user is Valo or if is developer and program is not imported. And if lot is not published
        component.instance.isDeletable = ((!this.isValo && !this.isImported) || this.isValo) && !element.isPublished;

        // Control display of publication/unpublication of lot
        if (element.switchPublicationLotStateAutorized) {
          component.instance.canPublishProgram = !element.isPublished;
          component.instance.canUnpublishProgram = element.isPublished;
        } else {
          component.instance.canPublishProgram = canPublishImportedUnpublishlot(
            this.isValo,
            this.isImported,
            this.appConfigService.getAppConfig().statusLotLabels,
            element,
          );
        }

        // Create lot object for the table
        const lot = new ProgramLotTableDisplayData();
        lot.number = element.lotNumber;
        lot.typo = element.lotTypeLabel ? this.i18nService._(`Txt_Table_Column_Typo_${element.lotTypeLabel}`) : undefined;
        lot.rooms = element.rooms;
        lot.livingSpace = element.livingSpace
          ? `${this.basicFormatsService.formatDecimal(element.livingSpace)} ${this.i18nService._('Txt_Table_SquareMeter')}`
          : undefined;
        lot.commercialLotSellingPriceIT = this.basicFormatsService.formatCurrency(element.commercialLotSellingPriceIT, this.currency);
        lot.reducedTotalSellingPriceIT = this.basicFormatsService.formatCurrency(element.reducedTotalSellingPriceIT, this.currency);
        const lotStatus = this.appConfigService.getAppConfig().statusLotLabels;

        lot.status = this.getStatus(lotStatus, element.status);

        lot.isPublished = element.isPublished ? this.i18nService._('Txt_Yes') : this.i18nService._('Txt_No');
        lot.deliveryDate = this.basicFormatsService.formatDate(deliveryDate);
        lot.floor = element.floor;
        lot.actions = component.instance.templateRef;
        lot.isComplete = element.isComplete
          ? { label: this.i18nService._('Txt_Yes') }
          : {
              label: this.i18nService._('Txt_No'),
              background: 'bg-red-invalid-trans25',
            };

        // Add this lot to the table data
        this.lots.push(lot);
      });
    }
    // Update data to be shown on step 3 program form
    this.updateLotSummary(this.programLotList);
    // Tell lot type to step 4
    this.lotTypeContractualDocumentPackages(this.programLotList);
  }

  /**
   * Method to give to step 3 of program form the needed informations :
   * nbLots & the closest deliveryDate
   *
   * @private
   * @param {Array<LotResponse>} lots
   * @memberof LotsTableProgramComponent
   */
  private updateLotSummary(lots: Array<LotResponse>): void {
    const lotSummary = {
      nbLots: lots.length,
      deliveryDate: this.programService.getDeliveryDateOfProgram(lots),
    };
    this.programService.setLotSummary(lotSummary);
  }

  /**
   * Method to know which contractual document packages is needed
   *
   * @private
   * @param {Array<LotResponse>} lots
   * @memberof LotsTableProgramComponent
   */
  private lotTypeContractualDocumentPackages(lots: Array<LotResponse>): void {
    const isApartmentContractualDocumentPackages = Boolean(
      lots.find((lot) => {
        return lot.lotTypeLabel === this.appConfigService.appConfig.programTypes.APARTMENT;
      }),
    );
    const isHouseContractualDocumentPackages = Boolean(
      lots.find((lot) => {
        return lot.lotTypeLabel === this.appConfigService.appConfig.programTypes.HOUSE;
      }),
    );
    const isTradeContractualDocumentPackages = Boolean(
      lots.find((lot) => {
        return lot.lotTypeLabel === this.appConfigService.appConfig.programTypes.TRADE;
      }),
    );
    this.programService.setContractualDocumentPackages({
      isApartmentContractualDocumentPackages,
      isHouseContractualDocumentPackages,
      isTradeContractualDocumentPackages,
    });
  }
}
