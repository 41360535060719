import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

import { StepOneFormProspectComponent } from '../step-one-form-prospect/step-one-form-prospect.component';
import { StepTwoFormProspectComponent } from '../step-two-form-prospect/step-two-form-prospect.component';

import { AlertEnum } from '../../../utils/models/enums/alert.enum';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { ProspectCreate } from '../../../utils/models/ProspectCreate';
import { Sitemap } from '../../../utils/models/Sitemap';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { SpinnerWithBackdropService } from '../../../utils/services/spinner-with-backdrop.service';
import { UserRoleService } from '../../../utils/services/user-role.service';
import { ProspectService } from '../../services/prospect.service';
import { MatVerticalStepperScrollerDirective } from '../../../utils/directives/mat-vertical-stepper-scroller.directive';
import { AlertComponent } from '../../../utils/components/alert/alert.component';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';

@Component({
  selector: 'app-register-form-prospect',
  templateUrl: './register-form-prospect.component.html',
  styleUrls: ['./register-form-prospect.component.scss'],
  standalone: true,
  imports: [
    StickyHeaderFormComponent,
    NgIf,
    AlertComponent,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatVerticalStepperScrollerDirective,
    StepOneFormProspectComponent,
    MatButtonModule,
    StepTwoFormProspectComponent,
  ],
})
export class RegisterFormProspectComponent implements OnInit {
  public alertEnum = AlertEnum;
  /**
   * prospectDeclarationForm attribute
   *
   * @type {FormGroup}
   * @memberof RegisterFormProspectComponent
   */
  public prospectDeclarationForm: UntypedFormGroup;

  /**
   * prospectContactInformationForm attribute
   *
   * @type {FormGroup}
   * @memberof RegisterFormProspectComponent
   */
  public prospectContactInformationForm: UntypedFormGroup;

  /**
   * programsChoiceForm attribute
   *
   * @type {FormGroup}
   * @memberof RegisterFormProspectComponent
   */
  public programsChoiceForm: UntypedFormGroup;
  public isNotDeveloper: boolean;

  /**
   * matStepper child attribute
   *
   * @type {MatStepper}
   * @memberof RegisterFormProspectComponent
   */
  @ViewChild('stepper') public matStepper: MatStepper;

  /**
   * stepOne child attribute
   *
   * @type {StepOneFormProspectComponent}
   * @memberof RegisterFormProspectComponent
   */
  @ViewChild('stepOne') public stepOne: StepOneFormProspectComponent;

  /**
   * Creates an instance of RegisterFormProspectComponent.
   * @param {FormBuilder} formBuilder
   * @param {I18nService} i18nService
   * @param {Router} router Service Router
   * @param {ProspectService} prospectService
   * @memberof RegisterFormProspectComponent
   */
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    public readonly i18nService: I18nService,
    private readonly router: Router,
    private readonly prospectService: ProspectService,
    private readonly snackbarService: SnackbarService,
    private readonly spinnerWithBackdropService: SpinnerWithBackdropService,
    private readonly userRoleService: UserRoleService,
  ) {}

  ngOnInit(): void {
    this.isNotDeveloper = this.userRoleService.isContractor() || this.userRoleService.isValo();
    // Form init
    this.prospectDeclarationForm = this.formBuilder.group({
      ['contactInformation']: (this.prospectContactInformationForm = this.formBuilder.group([])),
      ['programsChoice']: (this.programsChoiceForm = this.formBuilder.group({})),
    });
  }

  /**
   * nextStep method, need stepper in param
   *
   * @param {MatStepper} stepper
   * @memberof RegisterFormProspectComponent
   */
  public nextStep(stepper: MatStepper): void {
    stepper.next();
  }

  /**
   * onSubmit method
   *
   * @memberof RegisterFormProspectComponent
   */
  public onSubmit(): void {
    if (this.prospectDeclarationForm.valid) {
      this.spinnerWithBackdropService.show();
      const prospectCreate: ProspectCreate = {
        ...this.prospectContactInformationForm.value,
        ...this.programsChoiceForm.value,
      };
      this.prospectService.createProspect(prospectCreate).subscribe(
        (result) => {
          const message: SnackbarMessage = {
            text: result.message,
            type: SnackbarMessageType.Info,
          };
          this.snackbarService.sendMessage(message);
          this.spinnerWithBackdropService.hide();
          this.router.navigate([Sitemap.dashboards.prospects.path]);
        },
        () => {
          this.spinnerWithBackdropService.hide();
          const message: SnackbarMessage = {
            text: this.i18nService._('Error_Snackbar_PaternityFailed'),
            type: SnackbarMessageType.Error,
          };
          this.snackbarService.sendMessage(message);
        },
      );
    } else {
      const message: SnackbarMessage = {
        text: this.i18nService._('Error_SnackBar_FormIncomplete'),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
    }
  }

  public submitStepOne(): void {
    if (this.stepOne.checkStep()) {
      this.matStepper.next();
    }
  }
}
