import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import moment from 'moment-timezone';
import { finalize, Observable } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgClass, NgFor, NgIf } from '@angular/common';

import { AbstractProgramPageLot } from '../program-page-lot/abstract-program-page-lot';
import { PageProgramHelperService } from '../page-program-helper.service';

import { LotDetailResponse } from '../../../../utils/models/LotDetailResponse';
import { LotDetailsDocumentViewModel } from '../../../../utils/models/LotDetailsDocumentViewModel';
import { AppConfigService } from '../../../../utils/services/app-config.service';
import { TokenService } from '../../../../utils/services/authorisation/token.service';
import { ConfigurableParamService } from '../../../../utils/services/configurable-param.service';
import { FileSystemService } from '../../../../utils/services/file-system.service';
import { GoogleTagManagerService } from '../../../../utils/services/google-tag-manager.service';
import { I18nService } from '../../../../utils/services/i18n.service';
import { ProgramPageDocumentViewModel } from '../../../models/ProgramPageDocumentViewModel';
import { ExternalDocumentLink } from '../../../../utils/models/external-document-link';
import { ProgramPageService } from '../../../services/program-page.service';
import { ProgramDetail } from '../../../../utils/models/ProgramDetail';

@Component({
  selector: 'app-program-page-documents-list',
  templateUrl: './program-page-documents-list.component.html',
  styleUrls: ['./program-page-documents-list.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, MatCheckboxModule, FormsModule, MatButtonModule, MatIconModule, NgClass, MatProgressSpinnerModule],
})
export class ProgramPageDocumentsListComponent extends AbstractProgramPageLot implements OnInit, OnDestroy {
  public presentationsDocsArray: Array<LotDetailsDocumentViewModel> = [];
  public contratsDocsArray: Array<LotDetailsDocumentViewModel> = [];
  public downloadButtonDisabled = true;
  public isDownloadingByEmail = false;
  public isDownloadingAsZip = false;
  public counter: number;
  public readonly actionEvent: EventEmitter<string> = new EventEmitter<string>();
  public externalDocumentsLink: string;
  private readonly externalDocumentsLink$: Observable<Array<ExternalDocumentLink>>;
  private lotPlanDoc: LotDetailsDocumentViewModel;
  private specialOfferCode: string;
  private specialOfferDoc: ProgramPageDocumentViewModel;
  private lotPlanCode: string;
  program: ProgramDetail;

  constructor(
    public i18nService: I18nService,
    public readonly tokenService: TokenService,
    public readonly appConfigService: AppConfigService,
    public readonly fileSystemService: FileSystemService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    public readonly configurableParamService: ConfigurableParamService,
    private pageProgramHelperService: PageProgramHelperService,
    private _fileSystemService: FileSystemService,
    private _programPageService: ProgramPageService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.program = this.pageProgramHelperService.program;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /**
   * On checkbox label click download document
   *
   * @param {LotDetailsDocumentViewModel} docViewModel
   */
  public viewDocument($event: Event, docViewModel: LotDetailsDocumentViewModel): void {
    if (!docViewModel.actions.disabled) {
      this.pageProgramHelperService.openDocumentInBrowser($event, docViewModel.document, this.getDocumentFileName(docViewModel));
    }
  }

  public downloadDocument($event: Event, docViewModel: LotDetailsDocumentViewModel): void {
    $event.stopImmediatePropagation();
    if (!docViewModel.actions.disabled) {
      this._fileSystemService.downloadDocumentInBrowser(docViewModel.document, this.getDocumentFileName(docViewModel));
    }
  }

  /**
   * Translate filename.
   *
   * @param {LotDetailsDocumentViewModel} docViewModel
   * @return {string} formated filename
   */
  public getFileName(docViewModel: LotDetailsDocumentViewModel): string | null {
    if (!docViewModel || !docViewModel.code) {
      return null;
    }

    return docViewModel.textArguments
      ? this.i18nService._(`Txt_Commercial_Program_Sheet_${docViewModel.code}`, docViewModel.textArguments)
      : this.i18nService._(`Txt_Commercial_Program_Sheet_${docViewModel.code}`);
  }

  /**
   * Disable button if at least one document is checked in contratsDocs or presentationsDocs
   */
  public isAtLeastOneChecked(): void {
    this.downloadButtonDisabled =
      !this.contratsDocsArray.find((docViewModel) => docViewModel.actions.checked) &&
      !this.presentationsDocsArray.find((docViewModel) => docViewModel.actions.checked);
  }

  /**
   * On click prepare mail and open it in new page
   */
  public mailSelectedDocs(): void {
    // Show loader when prepare email
    this.isDownloadingByEmail = true;

    const enumReturnTranslateDocument = new Map([
      ['CommercialBrochure', this.i18nService._('Txt_Commercial_Program_Sheet_CB')],
      ['presentationOfTheProgram', this.i18nService._('Txt_Commercial_Program_Sheet_PP')],
      ['lotPlan', this.i18nService._('Txt_Commercial_Program_Sheet_LP')],
      ['descriptiveNotice', this.i18nService._('Txt_Commercial_Program_Sheet_DN')],
      ['ApartmentReservationContract', this.i18nService._('Txt_Commercial_Program_Sheet_ARC')],
      ['HouseReservationContract', this.i18nService._('Txt_Commercial_Program_Sheet_HRC')],
      ['tradeReservationContract', this.i18nService._('Txt_Commercial_Program_Sheet_TRC')],
      ['perspective', this.i18nService._('Txt_Commercial_Program_Sheet_CB')],
      ['gfaAttestation', this.i18nService._('Txt_Commercial_Program_Sheet_CB')],
      ['groundPlan', this.i18nService._('Txt_Commercial_Program_Sheet_GP')],
      ['Mandate', this.i18nService._('Txt_Commercial_Program_Sheet_CB')],
      ['ApartmentContractualDocumentPackage', this.i18nService._('Txt_Commercial_Program_Sheet_ACDP')],
      ['HouseContractualDocumentPackage', this.i18nService._('Txt_Commercial_Program_Sheet_HCDP')],
      ['TradeContractualDocumentPackage', this.i18nService._('Txt_Commercial_Program_Sheet_TCDP')],
      ['guaranteeFormula', this.i18nService._('Txt_Commercial_Program_Sheet_GF')],
      ['SpecialOfferAmendment', this.i18nService._('Txt_Commercial_Program_Sheet_SOAM_mail')],
    ]);

    const selectedDocsCont = this.contratsDocsArray.filter((docViewModel) => docViewModel.actions.checked);
    const selectedDocsPres = this.presentationsDocsArray.filter((docViewModel) => docViewModel.actions.checked);
    const loggedUserInfos = this.tokenService.getToken();
    let listOfDocument = '\u000A';

    const getLabelAndUrlOfDocument = (element: LotDetailsDocumentViewModel): string => {
      return `${enumReturnTranslateDocument.get(element.label)} : ${this.fileSystemService.getDownloadUrlOfDocument(element.document, {
        programId: this.pageProgramHelperService.program.programId,
        lotId: null,
      })} \u000A`;
    };

    selectedDocsPres.forEach((element) => {
      listOfDocument += getLabelAndUrlOfDocument(element);
    });

    selectedDocsCont.forEach((element) => {
      listOfDocument += getLabelAndUrlOfDocument(element);
    });

    const bodyMail = `Bonjour ${this.i18nService._(loggedUserInfos.civility)} ${loggedUserInfos.lastName},

    Je pense que le programme ${
      this.pageProgramHelperService.program.programName
    } peut vous intéresser, cliquez sur les liens ci-dessous pour en savoir plus :
    ${listOfDocument}`;

    const subject = `[VALORISSIMO] Découvrez le programme ${this.pageProgramHelperService.program.programName}`;

    const emailToSend = this._buildMailTo('nullepart@mozilla.org', subject, bodyMail);

    location.href = emailToSend;

    // Toggle loader
    this.isDownloadingByEmail = false;
    this._googleTagManagerService.pushTag({ event: 'email' });

    return;
  }

  /**
   * On click on download button generate zip folder
   */
  public downloadAndZipSelectedDocs(): void {
    const zip: JSZip = new JSZip();
    const selectedDocsCont = this.contratsDocsArray.filter((docViewModel) => docViewModel.actions.checked);
    const selectedDocsPres = this.presentationsDocsArray.filter((docViewModel) => docViewModel.actions.checked);
    const totalFiles: number = selectedDocsCont.length + selectedDocsPres.length;
    this.counter = 0;
    this.isDownloadingAsZip = true;
    // folder "documents de presentation"
    this.folderZip(zip, selectedDocsPres, totalFiles, false);
    // folder "documents contractuels"
    this.folderZip(zip, selectedDocsCont, totalFiles, true);
    this._googleTagManagerService.pushTag({ event: 'download' });
  }

  /**
   * Zip a folder.
   *
   * @param {JSZip} zip object
   * @param {Array<LotDetailsDocumentViewModel>} documents to zip
   * @param {number} totalFiles
   * @param {boolean} isContract
   */
  public folderZip(zip: JSZip, documents: Array<LotDetailsDocumentViewModel>, totalFiles: number, isContract: boolean): void {
    const folderName = this.i18nService._(isContract ? 'Title_BookingAndGuaranteesBook' : 'Title_PresentationDocumentation');
    const folder = zip.folder(folderName);
    documents.forEach((docViewModel) => {
      this.fileSystemService
        .downloadFile(docViewModel.document)
        .pipe(
          finalize(() => {
            this.counter++;
            if (this.counter === totalFiles) {
              this.downloadZip(zip);
            }
          }),
        )
        .subscribe((response) => {
          folder.file(this.getDocumentFileName(docViewModel), response);
        });
    });
  }

  /**
   * Get today
   *
   * @return {string} return string date with format 'YYYY-MM-DD-HHmmss'
   */
  public getDateStringFormat(): string {
    return moment().tz(this.appConfigService.getAppConfig().timeZone).format('YYYY-MM-DD-HHmmss');
  }

  initData(lot?: LotDetailResponse) {
    this.specialOfferCode = this.appConfigService.getAppConfig().documentTypeCode.SpecialOfferAmendment;
    this.lotPlanCode = this.appConfigService.getAppConfig().documentTypeCode.lotPlan;
    this.configurableParamService.externalDocumentsLink$.subscribe(() => {
      this.externalDocumentsLink = this.configurableParamService.getExternalLinkByLabelId('Txt_Link_External_Documents_Utils');
    });
    this.initPlanAndSpecialOfferDoc(lot);
  }

  public toggleCheckbox(doc: LotDetailsDocumentViewModel) {
    if (!doc.actions.disabled) {
      doc.actions.checked = !doc.actions.checked;
      this.isAtLeastOneChecked();
    }
  }

  /**
   * Get document filename.
   *
   * @param {LotDetailsDocumentViewModel} docViewModel
   * @return {string} formated filename
   */
  private getDocumentFileName(docViewModel: LotDetailsDocumentViewModel): string {
    const extension = docViewModel.document.fileName.substr(docViewModel.document.fileName.lastIndexOf('.'));

    return extension ? this.getFileName(docViewModel) + extension : docViewModel.document.fileName;
  }

  /**
   * Download zip file
   *
   * @params {JSZip} zip object
   */
  private downloadZip(zip: JSZip): void {
    const today = this.getDateStringFormat();
    const filename = `valorissimo-${this.pageProgramHelperService.program.programName}-${today}.zip`;
    zip.generateAsync({ type: 'blob' }).then((content) => {
      this.isDownloadingAsZip = false;
      saveAs(content, filename);
    });
  }

  private _buildMailTo(address, subject, body): string {
    const encodedAddress = encodeURIComponent(address);
    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);

    return `mailto:${encodedAddress}?subject=${encodedSubject}&body=${encodedBody}`;
  }

  private initPlanAndSpecialOfferDoc(lot: LotDetailResponse) {
    if (!lot) {
      this.lotPlanDoc = this.pageProgramHelperService.initEmptyDocViewModel({
        codeLabel: this.lotPlanCode,
        disabled: true,
        display: true,
      });
      this.specialOfferDoc = this.initEmptySpecialOfferDoc();
    } else {
      const lotDocuments = this._pageLotService.getLotDocuments(lot);
      this.lotPlanDoc = lotDocuments.plan;
      this.lotPlanDoc.downloadUrl = this.pageProgramHelperService.initDocViewModelDownloadUrl(this.lotPlanDoc.document);
      this.specialOfferDoc = lotDocuments.specialOfferAmmendment || this.initEmptySpecialOfferDoc();
      this.specialOfferDoc.downloadUrl = this.pageProgramHelperService.initDocViewModelDownloadUrl(this.specialOfferDoc.document);
    }

    if (!this._programPageService.program.hasSpecialOffer) {
      this.specialOfferDoc.actions.display = false;
    }
    this.presentationsDocsArray = [this.lotPlanDoc, ...this.pageProgramHelperService.presentationsDocs];
    this.contratsDocsArray = [...this.pageProgramHelperService.contratsDocs, this.specialOfferDoc];
  }

  private initEmptySpecialOfferDoc() {
    const specialOfferDoc = this.pageProgramHelperService.initEmptyDocViewModel({
      codeLabel: this.specialOfferCode,
      disabled: true,
      display: true,
    });
    specialOfferDoc.code = `${this.specialOfferCode}_mail`;

    return specialOfferDoc;
  }
}
