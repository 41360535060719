import { Location, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';

import { StepTwoFormCompanyComponent } from '../step-two-form-company/step-two-form-company.component';
import { StepFourFormCompanyComponent } from '../step-four-form-company/step-four-form-company.component';
import { StepThreeFormCompanyComponent } from '../step-three-form-company/step-three-form-company.component';
import { StepOneFormCompanyComponent } from '../step-one-form-company/step-one-form-company.component';

import { TextareaDialogComponent } from '../../../dialog/components/textarea-dialog/textarea-dialog.component';
import { TextareaDialogData } from '../../../dialog/models/TextareaDialogData';
import { AbstractAppBack } from '../../../utils/autres/abstract-app-back';
import { AttachmentResponse } from '../../../utils/models/AttachmentsResponse';
import { AttachmentUploadData } from '../../../utils/models/AttachmentUploadData';
import { CompanyData } from '../../../utils/models/CompanyData';
import { CompanyContact } from '../../../utils/models/CompanyContact';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { FileFormat } from '../../../utils/models/FileFormat';
import { ESIGNPROVIDER } from '../../../utils/models/eSign-provider.enum';
import { RecoveredDocuments } from '../../../utils/models/RecoverdedDocuments';
import { ReferenceTableData } from '../../../utils/models/ReferenceTableData';
import { Sitemap } from '../../../utils/models/Sitemap';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { AttachmentService } from '../../../utils/services/attachment.service';
import { TokenService } from '../../../utils/services/authorisation/token.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { PostalAddressService } from '../../../utils/services/postal-address.service';
import { RecaptchaService } from '../../../utils/services/recaptcha.service';
import { ReferenceTablesService } from '../../../utils/services/reference-tables.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { ModalType } from '../../enum/modal-type.enum';
import { CompanyService } from '../../services/company.service';
import { RoutingStateService } from '../../../utils/services/routing-state.service';
import { SpinnerWithBackdropService } from '../../../utils/services/spinner-with-backdrop.service';
import { ConfigurableParamService } from '../../../utils/services/configurable-param.service';
import { RecaptchaComponent } from '../../../utils/components/recaptcha/recaptcha.component';
import { MatVerticalStepperScrollerDirective } from '../../../utils/directives/mat-vertical-stepper-scroller.directive';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';

@Component({
  selector: 'app-register-form-company',
  templateUrl: './register-form-company.component.html',
  styleUrls: ['./register-form-company.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    StickyHeaderFormComponent,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatVerticalStepperScrollerDirective,
    StepOneFormCompanyComponent,
    StepTwoFormCompanyComponent,
    StepThreeFormCompanyComponent,
    MatButtonModule,
    StepFourFormCompanyComponent,
    RecaptchaComponent,
  ],
})
export class RegisterFormCompanyComponent extends AbstractAppBack implements OnInit, OnDestroy {
  /**
   * Stepper attribute.
   *
   * @type {MatStepper}
   * @memberof RegisterFormCompanyComponent
   */
  public stepper: MatStepper;
  /**
   * Stepper attribute.
   *
   * @type {StepTwoFormCompanyComponent}
   * @memberof RegisterFormCompanyComponent
   */
  public stepTwo: StepTwoFormCompanyComponent;
  /**
   * Path to companies register route.
   *
   * @type {string}
   * @memberof InitFormCompanyComponent
   */
  readonly companiesRegisterPath = Sitemap.companies.register.path;
  /**
   * Global configuration of the application
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appConfig: any = {};
  /**
   * FormGroup global
   *
   * @type {FormGroup}
   * @memberof RegisterFormCompanyComponent
   */
  public registerCompany: UntypedFormGroup;
  /**
   * FormGroup for the first step
   *
   * @type {FormGroup}
   * @memberof RegisterFormCompanyComponent
   */
  public companyForm: UntypedFormGroup;
  public contacts: UntypedFormGroup;
  public documentsForm: UntypedFormGroup;
  public adminValoForm: UntypedFormGroup;
  isValoRole: boolean;
  valoAdmiAccountList = [];
  public recoveredInfo: CompanyData;
  documentComments: string;
  public recoveredDocuments: RecoveredDocuments;
  public isReady: boolean;
  companyTypeId: number;
  dataIsLoad: boolean;
  companyId: string;
  public isSubmitFinal: boolean;
  public companyRequest: CompanyData = new CompanyData();
  modalType;
  public token: string;
  public validatedCompany = false;
  disableSubmit = false;
  signatoryType: ReferenceTableData;
  otherCompanyContactFunction: ReferenceTableData;
  /**
   * isPromotor attribute
   *
   * @type {boolean}
   * @memberof RegisterFormCompanyComponent
   */
  public isDeveloper: boolean;
  /**
   * pageTitle attribute
   *
   * @type {string}
   * @memberof RegisterFormCompanyComponent
   */
  public pageTitle = 'Title_RegisterCompany';
  public isModif: boolean;
  public computeRecaptcha: boolean;
  public defaultGoBackUrl: string;
  private $changesDetectedObservable: Subscription;
  private backOfficeGenericMail: string;

  constructor(
    public i18nService: I18nService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly router: Router,
    private readonly location: Location,
    private readonly tokenService: TokenService,
    private readonly route: ActivatedRoute,
    private readonly attachmentService: AttachmentService,
    private readonly appConfigService: AppConfigService,
    private readonly companyService: CompanyService,
    private readonly referenceTableService: ReferenceTablesService,
    private readonly snackbarService: SnackbarService,
    private readonly dialog: MatDialog,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly postalAdressService: PostalAddressService,
    private readonly reCaptchaService: RecaptchaService,
    private readonly routingStateService: RoutingStateService,
    private readonly _spinnerWithBackdropService: SpinnerWithBackdropService,
    private readonly configurableParamService: ConfigurableParamService,
  ) {
    super();

    this.appConfig = this.appConfigService.appConfig;
    this.isReady = false;
    this.isSubmitFinal = false;
    this.modalType = ModalType;
    this.token = undefined;
    this.isModif = false;
    this.defaultGoBackUrl = Sitemap.companies.admin.path;
    this.goBackPath = Sitemap.companies.admin.path;
  }

  // Can't be catch in ngOnInit because of an asynchronous ngIf.
  @ViewChild('stepper') set content(el: MatStepper) {
    if (el && !this.stepper) {
      this.stepper = el;

      // When stepper is ready.
      this.stepper._stepHeader.forEach((header, index) => {
        // Add listener when click on header.
        header._elementRef.nativeElement.addEventListener('click', () => {
          this.companyService.previouslySelectedIndex.next(index - 1);
        });
      });
    }
  }

  @ViewChild(StepTwoFormCompanyComponent) set pane(stepTwo: StepTwoFormCompanyComponent) {
    this.stepTwo = stepTwo;
  }

  /**
   * Init Component
   *
   * @memberof RegisterFormCompanyComponent
   */
  ngOnInit(): void {
    // Security check token.
    if (!!Object.getOwnPropertyDescriptor(this.route.snapshot.data, 'checkToken') && !this.route.snapshot.queryParamMap.has('token')) {
      this.sendSnackBarMessage(this.i18nService._('Error_SnackBar_RedirectPageNotAllowed'));
      this.backToHomepage();
    } else {
      this.configurableParamService
        .getConfigParam('BACK_OFFICE_GENERIC_EMAIL')
        .pipe(take(1))
        .subscribe((value) => (this.backOfficeGenericMail = value['BACK_OFFICE_GENERIC_EMAIL']));
      this.$changesDetectedObservable = this.companyService.changesDetected.subscribe((changes) =>
        changes ? this.changeDetectorRef.detectChanges() : undefined,
      );

      this.companyId = this.route.snapshot.paramMap.get('companyId');
      this.token = this.route.snapshot.queryParamMap.get('token');

      // List of valoRole name need to see stepFour
      this.valoAdmiAccountList = [
        this.appConfig.roles.valoSystem,
        this.appConfig.roles.valoAdminSuper,
        this.appConfig.roles.valoAdminSimple,
        this.appConfig.roles.valoBoSuper,
        this.appConfig.roles.valoBoSimple,
      ];

      // Check is the user is a valo user
      this.isValoRole = this.tokenService.checkIsLoggedIn() && this.valoAdmiAccountList.includes(this.tokenService.getRoleName());

      // Handle recaptcha if user is not logged
      this.computeRecaptcha = !this.tokenService.checkIsLoggedIn();
      this.preparePage();
    }
  }

  ngOnDestroy(): void {
    // Set isFrenchCountry to true after submission for the choice of another country
    this.postalAdressService.setIsFrenchCountry(undefined);
    this.companyService.previouslySelectedIndex.next(-1);

    if (this.$changesDetectedObservable) {
      this.$changesDetectedObservable.unsubscribe();
    }
    if (this.stepper) {
      this.stepper.selectionChange.unsubscribe();
    }
  }

  selectionChange(index: number): void {
    if (index === this.stepTwo.index) {
      this.stepTwo.updateContactAdminTypeId();
    }
  }

  preparePage(): void {
    // Get different companynType
    this.referenceTableService.getTableReferenceInfo('CompanyTypes').subscribe((companyTypeList) => {
      // if we add companyId its an update
      if (this.companyId) {
        this.initData();
      } else {
        // else it's a create
        this.isDeveloper = this.route.snapshot.data.isDeveloper;
        const companyTypeLabel = this.isDeveloper ? this.appConfig.companyType.developer : this.appConfig.companyType.contractor;
        this.companyTypeId = companyTypeList.find((type) => type.label === companyTypeLabel).id;

        this.createForm(this.companyTypeId);
        this.dataIsLoad = true;
        this.changeDetectorRef.detectChanges();
      }
    });
    // Get the signatoryType.
    this.referenceTableService.getTableReferenceInfo('CompanyContactTypes').subscribe((companyContactTypes) => {
      this.signatoryType = companyContactTypes.find((contact) => contact.label === this.appConfig.companyContactType.E_SIGNATORY);
    });

    // Get the signatoryType.
    this.referenceTableService.getTableReferenceInfo('CompanyContactFunctions').subscribe((companyContactFunctions) => {
      this.otherCompanyContactFunction = companyContactFunctions.find(
        (role) => role.label === this.appConfig.companyContactFunctions.OTHER,
      );
    });
  }

  sendSnackBarMessage(text: string): void {
    const message: SnackbarMessage = {
      text,
      type: SnackbarMessageType.Error,
    };
    this.snackbarService.sendMessage(message);
  }

  initData(): void {
    this.companyService.getCompanyInformation(this.companyId, this.token).subscribe(
      (companyData) => {
        this.recoveredInfo = companyData;
        this.companyTypeId = this.recoveredInfo.companyTypeId;
        this.isDeveloper = this.recoveredInfo.companyType.label === this.appConfig.companyType.developer;

        // Redirect according to company status.
        if ([this.appConfig.status.pending, this.appConfig.status.incomplete].includes(this.recoveredInfo.companyStatus.label)) {
          this.location.go(Sitemap.companies.validate.path.replace(/:companyId/, this.companyId));
          this.pageTitle = this.isDeveloper ? 'Title_ValidateCompany_Dev' : 'Title_ValidateCompany';
        } else if (this.recoveredInfo.companyStatus.label === this.appConfig.status.valid) {
          this.validatedCompany = true;
          this.location.go(Sitemap.companies.edit.path.replace(/:companyId/, this.companyId));
          this.pageTitle = this.isDeveloper ? 'Title_ModifyCompany_Dev' : 'Title_ModifyCompany';
        } else {
          this.sendSnackBarMessage(this.i18nService._('Txt_Page_Form_RegisterCompany_Denied'));
          this.router.navigate([Sitemap.companies.admin.path]);

          return;
        }

        // Allows Valo role to be modified without validating
        this.isModif = [this.appConfig.status.pending, this.appConfig.status.incomplete].includes(this.recoveredInfo.companyStatus.label);

        this.createForm(this.companyTypeId);
        // Set input for attachment
        this.companyRequest.attachments = companyData.documents.map((document) => {
          return {
            id: document.id,
            fieldName: document.type.label,
            fileName: document.fileName,
          };
        });
        this.registerCompany.controls.documentComment.setValue(this.recoveredInfo.documentComment);
        this.recoveredDocuments = {
          documents: this.recoveredInfo.documents,
          documentComment: this.recoveredInfo.documentComment,
        };
        this.companyService.isMandatDelegation.next(
          companyData.documents.filter((document) => document.type.label === 'mandateDelegation').length !== 0,
        );
        this.dataIsLoad = true;
        this.changeDetectorRef.detectChanges();
      },
      () => {
        this.sendSnackBarMessage(this.i18nService._('Error_SnackBar_ErrorOccuredRetry'));
      },
    );
  }

  createForm(companyTypeId): void {
    this.registerCompany = this.formBuilder.group({
      ['company']: (this.companyForm = this.formBuilder.group({})),
      ['contacts']: (this.contacts = this.formBuilder.group({})),
      ['documentsForm']: (this.documentsForm = this.formBuilder.group({})),
      ['adminValoForm']: (this.adminValoForm = this.formBuilder.group({})),
      contactAdminTypeId: ['', [Validators.required]],
      companyTypeId: [companyTypeId, [Validators.required]],
      documentComment: ['', [Validators.maxLength(2000)]],
      companyStatusId: [this.recoveredInfo ? this.recoveredInfo.companyStatusId : '', [Validators.required]],
    });
  }

  /**
   * backToHomepage method
   * @memberof HeaderComponent
   */
  backToHomepage(): void {
    this.router.navigate([Sitemap.utils.homepage.path]);
  }

  action(companyStatus?: string): void {
    this.companyRequest.contactAdminTypeId = this.registerCompany.controls.contactAdminTypeId.value;
    this.companyRequest.companyTypeId = this.registerCompany.controls.companyTypeId.value;
    this.companyRequest.documentComment = this.registerCompany.controls.documentComment.value;
    this.companyRequest.companyStatusId = this.registerCompany.controls.companyStatusId.value;

    let defaultText = this.isValoRole ? 'Txt_Page_Form_RegisterCompany_Updated' : 'Txt_Page_Form_RegisterCompany_Updated_Resubmit';
    if (companyStatus) {
      switch (companyStatus) {
        case this.appConfig.status.valid:
          defaultText = 'Txt_Page_Form_RegisterCompany_Created';
          break;
        case this.appConfig.status.denied:
          defaultText = 'Txt_Page_Form_RegisterCompany_Denied';
          break;
        case this.appConfig.status.incomplete:
          defaultText = 'Txt_Page_Form_RegisterCompany_Incomplete';
          break;
        default:
          defaultText = 'Txt_Page_Form_RegisterCompany_Validation';
          break;
      }
    }

    // avoids sending the same message back for offline users
    if (this.isModif) {
      defaultText = 'Txt_Page_Form_RegisterCompany_Updated';
    }

    const successResponse = () => {
      this.disableSubmit = false;
      this._spinnerWithBackdropService.hide();
      const message: SnackbarMessage = {
        text: this.i18nService._(defaultText),
        type: SnackbarMessageType.Info,
      };
      this.snackbarService.sendMessage(message);

      this.redirect();
    };

    const errorResponse = (error) => {
      this.disableSubmit = false;
      this._spinnerWithBackdropService.hide();
      if (error.error.error.code === 'DATA_MISSED_MANDAT') {
        this.sendSnackBarMessage(this.i18nService._('Error_SnackBar_Mandat'));
      } else {
        this.sendSnackBarMessage(this.i18nService._('Error_SnackBar_ErrorOccuredRetry'));
      }
    };

    if (this.recoveredInfo) {
      // Add languageId and currencyId (hidden fields)
      this.companyRequest.languageId = this.recoveredInfo.languageId;
      this.companyRequest.currencyId = this.recoveredInfo.currencyId;
      this.companyService.updateCompany(this.companyRequest, this.companyId, this.token).subscribe(successResponse, errorResponse);
    } else if (this.computeRecaptcha) {
      // If the recaptcha control is active, we voluntarily add a recaptcha token in the model sent to valo-back
      this.reCaptchaService
        .getToken()
        .then((recaptchaToken) => {
          const form = {
            ...this.companyRequest,
            reCAPTCHAToken: recaptchaToken,
          };
          this.companyService.createCompany(form).subscribe(successResponse, errorResponse);
        })
        .catch((error) => {
          const loginMessageError: SnackbarMessage = {
            text: this.i18nService._('Error_SnackBar_RecaptchaLoading'),
            type: SnackbarMessageType.Error,
          };
          this.snackbarService.sendMessage(loginMessageError);
          throw error;
        });
    } else {
      this.companyService.createCompany(this.companyRequest).subscribe(successResponse, errorResponse);
    }
  }

  updateCompanyStatus(status?: string): void {
    this.disableSubmit = true;
    this._spinnerWithBackdropService.show();
    const companyStatus = status || this.recoveredInfo.companyStatus.label;

    this.companyService.getCompanyStatusId(companyStatus).subscribe(
      (companyStatusId) => {
        this.registerCompany.controls.companyStatusId.setValue(companyStatusId.id);
        this.onSubmit(companyStatus);
      },
      () => {
        this.sendSnackBarMessage(this.i18nService._('Error_SnackBar_ErrorOccured'));
      },
    );
  }

  async uploadFiles(filesToUpload: Array<AttachmentUploadData>, commercialName: string): Promise<Array<AttachmentResponse>> {
    return new Promise<Array<AttachmentResponse>>((resolve, reject) => {
      if (this.tokenService.checkIsLoggedIn()) {
        this.attachmentService
          .uploadCompanyCreationDocuments(filesToUpload, this.appConfig.containerName.companyDocuments, commercialName)
          .subscribe(
            (success) => {
              resolve(
                success.result.files.file.map((file) => {
                  return {
                    id: file.id,
                    fieldName: file.fieldName,
                    fileName: file.name,
                  };
                }),
              );
            },
            () => {
              reject();
            },
          );
      } else {
        this.reCaptchaService
          .getToken()
          .then((recaptchaToken) => {
            this.attachmentService
              .uploadCompanyCreationDocumentsPublic(
                filesToUpload,
                this.appConfig.containerName.companyDocuments,
                commercialName,
                recaptchaToken,
              )
              .subscribe(
                (success) => {
                  resolve(
                    success.file.map((file) => {
                      return {
                        id: file.id,
                        fieldName: file.fieldName,
                        fileName: file.name,
                      };
                    }),
                  );
                },
                () => {
                  reject();
                },
              );
          })
          .catch((error) => {
            this.sendSnackBarMessage(this.i18nService._('Error_SnackBar_RecaptchaLoading'));
            console.error(error);
          });
      }
    });
  }

  /**
   * onSubmit method
   *
   * @memberof RegisterFormCompanyComponent
   */
  onSubmit(companyStatus: string): void {
    this._spinnerWithBackdropService.show();
    this.disableSubmit = true;
    const adminFormGroup = ['mandat', 'companySignatory'];
    // Hack to show errors
    Object.keys(this.adminValoForm.controls).forEach((key) => {
      if (!adminFormGroup.includes(key)) {
        this.adminValoForm.controls[key].markAsTouched();
      } else {
        const formGroup = this.adminValoForm.controls[key] as UntypedFormGroup;
        Object.keys(formGroup.controls).forEach((formGroupKey) => {
          formGroup.controls[formGroupKey].markAsTouched();
        });
      }
    });

    // Move companyForm controls to registerCompany form
    this.isSubmitFinal = true;
    if (this.companyForm.valid && this.contacts.valid && this.documentsForm.valid) {
      // Bypass step 4 controls if status is not valid
      if (companyStatus === this.appConfig.status.valid && this.adminValoForm.invalid) {
        this.sendSnackBarMessage(this.i18nService._('Error_SnackBar_FormIncomplete'));
        this.disableSubmit = false;
        this._spinnerWithBackdropService.hide();
      } else {
        const keys = Object.keys(this.companyForm.controls);
        keys.forEach((key) => {
          // not submit optional field
          if (this.companyForm.controls[key].value !== '') {
            this.companyRequest[key] = this.companyForm.controls[key].value;
          }
        });
        this.companyRequest.contacts = this.stepTwo.prepareValue();
        this.companyRequest.eSignProvider = ESIGNPROVIDER.NONE;
        const keysAdmin = Object.keys(this.adminValoForm.controls);
        keysAdmin.forEach((key) => {
          if (key !== 'companySignatory') {
            this.companyRequest[key] = this.adminValoForm.controls[key].value;
            if (key === 'isValoMandateEsign' && this.adminValoForm.controls['isValoMandateEsign']?.value === true) {
              this.setEsignatoryContact(key);
            }
          } else {
            this.setEsignatoryContact(key);
          }
        });
        this.registerCompany.controls.documentComment.setValue(this.documentsForm.controls.documentComment.value);
        const filesToUpload: Array<AttachmentUploadData> = [];
        const commercialName = this.companyRequest.tradingName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

        let documents = Object.entries(this.documentsForm.value);
        // Add documents of step 4
        documents = documents.concat(
          Object.entries(this.adminValoForm.value).filter(([key]) =>
            [
              'CompanyLogo',
              'legalNotice',
              'approvalComitteeResult',
              'SpecialOfferAmendmentNotaryFees',
              'SpecialOfferAmendmentDiscount',
              'documentList',
              'documentListLMNP',
              'customerFile',
              'operatingModeAR24',
            ].includes(key),
          ),
        );
        documents.forEach(([key, document]: [string, FileFormat]) => {
          if (key !== 'documentComment') {
            // If document is empty, delete it.
            if (!document && this.companyRequest.attachments) {
              this.companyRequest.attachments = this.companyRequest.attachments.filter((attachment) => attachment.fieldName !== key);
              // If property alreadySave, no upload the file beacause already on the server
            } else if (document.name && !document.alreadySave) {
              filesToUpload.push({
                file: document,
                documentType: key,
              });
            }
          }
        });
        // Before the upload  (more or less long depending on the connection), we deactivate the submit button
        if (filesToUpload.length) {
          this.uploadFiles(filesToUpload, commercialName)
            .then((newDocuments) => {
              // If create add new documents in attachments for control the document mandatory
              if (!this.companyId) {
                this.companyRequest.attachments = newDocuments;
              }
              this.companyRequest.newDocuments = newDocuments ? newDocuments : [];
              this.action(companyStatus);
            })
            .catch(() => {
              this.disableSubmit = false;
              this._spinnerWithBackdropService.hide();
            });
        } else {
          this.companyRequest.newDocuments = [];
          this.action(companyStatus);
        }
      }
    } else {
      this.disableSubmit = false;
      this._spinnerWithBackdropService.hide();
      this.sendSnackBarMessage(this.i18nService._('Error_SnackBar_FormIncomplete'));
    }
  }

  private setEsignatoryContact(key: string) {
    let signatory: CompanyContact = new CompanyContact();
    if (this.adminValoForm.controls['isValoMandateEsign']?.value === true) {
      signatory = {
        civilityId: 1,
        lastName: 'Valorissimo',
        firstName: 'back office',
        email: this.backOfficeGenericMail,
        mobilePhone: '0600000000',
      };
    } else {
      signatory = this.adminValoForm.controls[key].value;
    }
    //ensure we use the same contact as signatory
    if (this.recoveredInfo) {
      const previousSignatory = this.recoveredInfo.contacts.find((contact) =>
        contact.companyContactTypes.find((contactType) => contactType.label === this.appConfig.companyContactType.E_SIGNATORY),
      );
      if (previousSignatory) {
        signatory.id = previousSignatory.id;
      }
    }

    signatory.companyContactTypeId = this.signatoryType?.id as number;
    signatory.companyContactFunctionId = this.otherCompanyContactFunction.id as number;
    signatory.companyContactFunctionOther = 'e-signataire';
    this.companyRequest.contacts.push(signatory);
    this.companyRequest.eSignProvider = ESIGNPROVIDER.DOCUSIGN;
  }

  /**
   * openDialog method, put the type of dialog in attribute
   *
   * @param {ModalType} type
   * @memberof RegisterFormCompanyComponent
   */
  public openDialog(type: ModalType): void {
    const dialogData: TextareaDialogData = {
      message: this.i18nService._(
        type === ModalType.DENIED ? 'Txt_Popin_Form_RegisterCompany_Reject' : 'Txt_Popin_Form_RegisterCompany_Incomplete',
      ),
      placeholder: this.i18nService._(
        type === ModalType.DENIED ? 'Txt_Placeholder_RegisterCompany_RejectReason' : 'Txt_Placeholder_RegisterCompany_IncompleteReason',
      ),
    };

    const dialogRef = this.dialog.open(TextareaDialogComponent, {
      data: dialogData,
    });

    // Add in form and update company status
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.companyRequest.emailMessage = result;
        this.updateCompanyStatus(type === ModalType.DENIED ? this.appConfig.status.denied : this.appConfig.status.incomplete);
      }
    });
  }

  private redirect() {
    if (this.isValoRole) {
      // Redirect to previous page if exist; else redirect to adminPath

      this.routingStateService.removeLastHistoryEntry();
      const backUrl = this.routingStateService.getPreviousUrl(null, Sitemap.companies.admin.path);
      this.router.navigateByUrl(backUrl);
    } else {
      this.router.navigate([Sitemap.utils.login.path]);
    }
  }
}
