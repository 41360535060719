<div class="box">
  <div class="header">
    <app-icon *ngIf="icon" [name]="icon" class="icon" [class.incomplete]="complete !== true"></app-icon>
    <span class="title">{{ title | translate }}</span>
  </div>
  <div class="body-input">
    <ng-content></ng-content>
    <ng-content select="[footer]" class="footer"></ng-content>
  </div>
</div>
