import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { LogAccessFormComponent } from '../log-access-form/log-access-form.component';

import { ReinitPasswordDialogComponent } from '../../../dialog/components/reinit-password-dialog/reinit-password-dialog.component';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { Sitemap } from '../../../utils/models/Sitemap';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { AccountService, LoginForm } from '../../../utils/services/account.service';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { TokenService } from '../../../utils/services/authorisation/token.service';
import { GoogleTagManagerService } from '../../../utils/services/google-tag-manager.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { RecaptchaService } from '../../../utils/services/recaptcha.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { isEmailValidators } from '../../../utils/validators/is-email-validator.directive';
import { AccountUtilsService } from '../../services/account-utils.service';
import { SentryService } from '../../../utils/services/sentry.services';

@Component({
  selector: 'app-log-account',
  templateUrl: './log-account.component.html',
  styleUrls: ['./log-account.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, MatCheckboxModule, LogAccessFormComponent],
})
export class LogAccountComponent implements OnInit, OnDestroy {
  public loginForm = this.formBuilder.group({
    username: ['', [Validators.required, isEmailValidators()]],
    password: ['', Validators.required],
    rememberMe: [false],
  });
  public emailControl: FormControl<string>;
  public passwordControl: FormControl<string>;
  public loginError: boolean;
  private executeCounterObservableSubscription: Subscription;
  executeCounter: boolean;

  /**
   * returnUrl
   * @type {string}
   */
  returnUrl: string;

  constructor(
    private readonly accountService: AccountService,
    private readonly accountUtilsService: AccountUtilsService,
    private readonly tokenService: TokenService,
    private readonly formBuilder: NonNullableFormBuilder,
    private readonly router: Router,
    public i18nService: I18nService,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly snackbarService: SnackbarService,
    private readonly reCaptchaService: RecaptchaService,
    public readonly appConfigService: AppConfigService,
    public readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly sentryService: SentryService,
  ) {
    this.loginError = false;
    this.emailControl = this.loginForm.controls.username;
    this.passwordControl = this.loginForm.controls.password;
  }

  ngOnInit(): void {
    // Get return url from route parameters or default to homepage for redirection after login
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || Sitemap.utils.homepage.path;
    // Redirection to homepage if user already connected
    this.accountUtilsService.setExecuteCounterProgramsLogin(!this.tokenService.checkIsLoggedIn());
    if (this.tokenService.checkIsLoggedIn()) {
      this.router.navigate([Sitemap.utils.homepage.path]);
    }

    this.executeCounterObservableSubscription = this.accountUtilsService.executeCounterProgramsLoginObservable.subscribe(
      (executeCounter) => {
        this.executeCounter = executeCounter;
      },
    );
  }

  ngOnDestroy(): void {
    this.executeCounterObservableSubscription.unsubscribe();
  }

  async connect(): Promise<void> {
    if (!this.loginForm.errors) {
      let reCAPTCHAToken;
      try {
        reCAPTCHAToken = await this.reCaptchaService.getToken();
      } catch (error) {
        const loginMessageError: SnackbarMessage = {
          text: this.i18nService._('Error_SnackBar_RecaptchaLoading'),
          type: SnackbarMessageType.Error,
        };
        this.snackbarService.sendMessage(loginMessageError);
        throw error;
      }
      const loginForm: LoginForm = {
        ...this.loginForm.getRawValue(),
        reCAPTCHAToken,
      };

      this.accountService.login(loginForm).subscribe(
        (logPageResponse) => {
          this.tokenService.deleteAllToken();
          this.tokenService.setToken(logPageResponse);
          const logResp = this.tokenService.getToken();
          this._googleTagManagerService.pushTag({
            userId: logResp.userId,
            societeId: logResp.companyId,
            type: this._googleTagManagerService.getDatalayerRole(logResp.roleName),
            event: 'login',
          });
          this.sentryService.sendUserToSentry();
          this.router.navigateByUrl(this.returnUrl);
        },
        (err) => {
          console.log(err);
          this.loginForm.reset();
          this.loginError = true;
        },
      );
    }
  }

  openReinitPasswordDialog(): void {
    this.dialog.open(ReinitPasswordDialogComponent);
  }
}
