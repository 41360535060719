import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { debounce, takeUntil } from 'rxjs/operators';
import { interval } from 'rxjs/internal/observable/interval';
import { MatDialog } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { NgClass, NgFor, NgIf } from '@angular/common';

import { MenuConfig, MenuConfigEntry } from '../../header.component';
import { I18nService } from '../../../../services/i18n.service';
import { AbstractUuid } from '../../../abstract-uuid';
import { MenuItemService } from '../../../../services/menu/menu-item.service';
import { EventUtilsService } from '../../../../services/event/event-utils.service';
import { UserRoleService } from '../../../../services/user-role.service';
import { TokenService } from '../../../../services/authorisation/token.service';
import { CommercialContactComponent } from '../../../commercial-contact/commercial-contact.component';
import { InterlocutorContactsComponent } from '../../../interlocutor-contacts/interlocutor-contacts.component';
import { IconComponent } from '../../../../../common/standalone/icon/icon.component';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  standalone: true,
  imports: [NgIf, IconComponent, NgClass, NgFor, RouterLink, InterlocutorContactsComponent, CommercialContactComponent],
})
export class MenuItemComponent extends AbstractUuid implements OnInit, OnDestroy {
  @Input() menuItem: MenuConfig;
  @Input() i18nService: I18nService;
  navClass: string;
  open: boolean;
  isContractorOrDeveloperOrIndependant: boolean;

  private readonly $destroy = new Subject();

  constructor(
    private readonly menuItemService: MenuItemService,
    private readonly _eventUtilsService: EventUtilsService,
    private readonly eRef: ElementRef,
    private readonly userRoleService: UserRoleService,
    private readonly tokenService: TokenService,
    private readonly dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    this.menuItemService.$closeOtherMenuSubject.pipe(takeUntil(this.$destroy)).subscribe((uuid) => {
      if (this.uuid !== uuid) {
        this.open = false;
      }
    });
    this.tokenService
      .getLoggedInObservable()
      .pipe(takeUntil(this.$destroy))
      .subscribe(
        () =>
          (this.isContractorOrDeveloperOrIndependant =
            this.userRoleService.isContractor() || this.userRoleService.isDeveloper() || this.userRoleService.isIndependant()),
      );
    this._eventUtilsService.documentClickedTarget
      .pipe(
        takeUntil(this.$destroy),
        debounce(() => interval(50)),
      )
      .subscribe((target) => {
        if (!this.eRef.nativeElement.contains(target)) {
          // Clicked outside
          this.open = false;
        }
      });
    this.navClass = this.menuItem.isOffNav ? '-right-4 px-4' : '-left-5 lg:left-0 ml-2 px-4';
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  toggleMenu() {
    this.open = !this.open;
    if (this.open) {
      this.menuItemService.closeOthe(this.uuid);
    }
  }

  subItemClick(dropItem: MenuConfigEntry) {
    this.open = false;
    if (dropItem.action) {
      dropItem.action();
    }
  }
}
