<div class="header px-5">
  <app-search-filter-reinit class="text-sm txt-disable" [iconClass]="'size-18'"></app-search-filter-reinit>
  <mat-icon (click)="closePanel()" class="material-icons-outlined close-panel-icon size-18 txt-disable">close</mat-icon>
</div>

<div class="content px-5">
  <app-search-filter-content></app-search-filter-content>
</div>
<div class="search-action" (click)="launchSearch()">
  <mat-icon>search</mat-icon>
  <div>{{ i18nService._('Txt_Placeholder_Search') }}</div>
</div>
