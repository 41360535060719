import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { FinancialStrategyService } from './../../strategies/services/financial-strategy.service';
import { ProgramGeneratedFeesAmounts } from './../../utils/models/ProgramGeneratedFeesAmounts';
import { TableLoader } from '../../table/interfaces/DataLoader';

@Injectable({
  providedIn: 'root',
})
export class ProgramFinancialStrategiesTrackingTableService implements TableLoader<ProgramGeneratedFeesAmounts> {
  constructor(private readonly financialStrategyService: FinancialStrategyService) {}

  public async getProgramMandateStrategies(id: string): Promise<ProgramGeneratedFeesAmounts> {
    const response = await lastValueFrom(this.financialStrategyService.getGeneratedFees(id));
    return response;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getById(id: number): Promise<ProgramGeneratedFeesAmounts> {
    return null;
  }
}
