<div class="flex flex-col flex-1">
  <!-- Subheader -->
  <app-sticky-header-form [defaultGoBackUrl]="defaultGoBackUrl" [titleText]="i18nService._(pageTitle)"></app-sticky-header-form>

  <div class="w-full pt-22 grid grid-cols-10">
    <div class="col-span-6 col-start-3">
      <!--  common part in valo and others-->
      <form>
        <mat-stepper
          orientation="vertical"
          #stepper
          (selectionChange)="onSelectionChange($event)"
          [@.disabled]="true"
          [linear]="true"
          appMatVerticalStepper
          class="mb-5"
        >
          <!-- Step 1 -->
          <ng-template matStepperIcon="edit">
            <span class="icon-validate-small"></span>
          </ng-template>
          <mat-step [formGroup]="personnalDataForm" [stepControl]="personnalDataForm">
            <ng-template matStepLabel>
              {{ i18nService._('Title_UserPersonalData') }}
            </ng-template>

            <!-- User personal data -->
            <app-contact-information
              [chipFunctionDisplay]="false"
              [contactsInfo]="accountPersonalData"
              [formName]="'accountForm'"
              [isSubmit]="isSubmit"
              [mobilePhoneRequired]="!parentIsValorissimo"
              [parentForm]="personnalDataForm"
              [value]="infosAboutAccount.contactInfos?.email"
              class="w-full block mb-5"
            >
            </app-contact-information>

            <!-- User address -->
            <app-postal-address [parentForm]="personnalDataForm" [recoveredInfo]="accountPostalInfos"></app-postal-address>

            <!-- User function -->
            <div class="grid grid-cols-2 gap-x-16 mb-5">
              <mat-form-field class="col-span-2 lg:col-span-1 mb-3">
                <mat-label>{{ i18nService._('Txt_Page_Form_RegisterCompany_FunctionType') }}</mat-label>
                <input
                  [formControl]="professionnalFunctionControl"
                  matInput
                  maxlength="255"
                  data-cy="registerCompanyFunctionType"
                  required
                  type="text"
                />
                <mat-error *ngIf="professionnalFunctionControl.errors">
                  <span *ngIf="professionnalFunctionControl.errors.required">
                    {{ i18nService._('Error_Field_Required') }}
                  </span>
                  <span *ngIf="professionnalFunctionControl.errors.maxlength">
                    {{ i18nService._('Error_Field_IncorrectLenghtMax') }}
                  </span>
                </mat-error>
              </mat-form-field>
            </div>

            <div *ngIf="!loggedUserIsValo && parentIsContractor" class="flex flex-row mb-5">
              <mat-checkbox [formControl]="isFeesHiddenControl" class="mt-4" color="primary">
                {{ i18nService._('Txt_Checkbox_IsFeesHidden') }}
              </mat-checkbox>
            </div>

            <!-- Next Step -->
            <button
              data-cy="nextStep"
              (click)="submitFirstStep()"
              *ngIf="loggedUserIsValo"
              color="primary"
              mat-stroked-button
              matStepperNext
              type="submit"
            >
              {{ i18nService._('Txt_Button_NextStep') }}
            </button>
          </mat-step>

          <!-- Part only for valorissimo-->
          <div *ngIf="loggedUserIsValo">
            <mat-step [formGroup]="valorissimoPartForm" [stepControl]="valorissimoPartForm">
              <ng-template matStepLabel>
                {{ i18nService._('Title_Valorissimo') }}
              </ng-template>

              <!-- if companyType.label === Valorissimo  ManagerAssocié === parent === parentLastName and parentFirstName-->

              <div *ngIf="parentIsValorissimo" class="flex flex-row justify-start items-center mb-3">
                <mat-form-field class="w-1/2">
                  <mat-label>{{ i18nService._('Txt_Placeholder_AssociateManager') }}</mat-label>
                  <input [formControl]="parentInfosControl" matInput type="text" />
                </mat-form-field>
              </div>

              <!-- Triptique des Roles  /!\ Si on est en valo System un choix est possible-->
              <!-- Company Type -->

              <div class="flex flex-col text-base mt-2 mb-3 font-semibold">
                <label id="companyType"> {{ i18nService._('Txt_Placeholder_Type') }}</label>
              </div>
              <div class="flex flex-row justify-start items-center mb-8">
                <mat-radio-group [formControl]="companyTypeControl" aria-labelledby="companyType">
                  <mat-radio-button *ngFor="let companyType of companyTypeList" [value]="companyType.id" color="primary">
                    {{ companyType.label }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>

              <!-- Profile Type -->
              <div class="flex flex-col text-base mt-2 mb-3 font-semibold">
                <label id="profilType"> {{ i18nService._('Txt_Placeholder_Profile') }}</label>
              </div>
              <div class="flex flex-row justify-start items-center mb-8">
                <mat-radio-group [formControl]="profilTypeControl" aria-labelledby="profilType">
                  <mat-radio-button *ngFor="let profilType of profilList" [value]="profilType.id" color="primary">
                    {{ profilType.label }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>

              <!-- ValoRole Type, if it's valoSystem, we can choose-->

              <!-- ValoRoleType -->
              <div *ngIf="parentIsValorissimo">
                <div class="flex flex-col text-base mt-2 mb-3 font-semibold">
                  <label id="valoRoleType"> {{ i18nService._('Txt_Placeholder_Role') }}</label>
                </div>
                <div class="flex flex-row justify-start items-center mb-8">
                  <mat-radio-group (change)="valoRoleChange($event)" [formControl]="valoRoleTypeControl" aria-labelledby="valoRoleType">
                    <mat-radio-button *ngFor="let valoRoleType of valoRoleTypeList" [value]="valoRoleType.id" color="primary">
                      {{ valoRoleType.label }}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>

              <!-- If ValoRoleType is rpv or developer -->
              <div *ngIf="isRpvOrDeveloper">
                <!-- Départements -->
                <app-chip-populated-autocomplete
                  *ngIf="availableDepartment$ | async as department"
                  [default]="defaultHasDepartment"
                  [id]="'hasDepartment'"
                  [items]="department"
                  [max]="100"
                  [parentForm]="valorissimoPartForm"
                  [required]="false"
                  [title]="i18nService._('Txt_Placeholder_Has_Department')"
                >
                </app-chip-populated-autocomplete>

                <!-- BO -->
                <app-chip-populated-autocomplete
                  *ngIf="availableAffilation$ | async as boList"
                  [default]="defaultHasBo"
                  [id]="'hasBo'"
                  [items]="boList"
                  [max]="100"
                  [parentForm]="valorissimoPartForm"
                  [required]="true"
                  [title]="i18nService._('Txt_Placeholder_Has_BO')"
                >
                </app-chip-populated-autocomplete>
              </div>

              <!--if companyType.label diff Valorissimo afficher les informations de la société  companyCorporateName  companyTradingName -->

              <hr class="w-5 my-12" />

              <!-- Developer / Contractor -->
              <div *ngIf="!parentIsValorissimo" class="flex flex-col w-full">
                <div class="text-base mb-4 font-bold">
                  {{ infosAboutAccount.companyCorporateName }} -
                  {{ infosAboutAccount.companyTradingName }}
                </div>
              </div>

              <div *ngIf="!parentIsValorissimo && !parentIsIndependant">
                <!-- Responsible -->
                <app-chip-populated-autocomplete
                  *ngIf="availableAffilation$ | async as responsibleList"
                  [default]="defaultResponsible"
                  [id]="'responsible'"
                  [items]="responsibleList"
                  [max]="1"
                  [parentForm]="valorissimoPartForm"
                  [rawFormat]="true"
                  [required]="isResponsibleRequired"
                  [title]="i18nService._(responsibleTitle)"
                  class="mb-3"
                >
                </app-chip-populated-autocomplete>
              </div>

              <div *ngIf="!parentIsValorissimo && (parentIsIndependant || parentIsContractor)">
                <!-- Responsible tiers -->
                <!-- Composant visible -->
                <div *ngIf="otherAvailableAffilation$ | async as otherResponsibleList">
                  <app-chip-populated-autocomplete
                    [default]="otherDefaultResponsible"
                    [id]="isOtherResponsableDisabled ? 'otherResponsableDisabled' : 'otherResponsible'"
                    [items]="otherResponsibleList"
                    [max]="1"
                    [parentForm]="valorissimoPartForm"
                    [rawFormat]="true"
                    [required]="false"
                    [isDisabled]="isOtherResponsableDisabled"
                    [title]="i18nService._('Txt_Placeholder_AffiliateTier')"
                    class="mb-3"
                  ></app-chip-populated-autocomplete>

                  <!-- Champ caché si isOtherResponsableDisabled afin de maintenir la valeur -->
                  <input
                    *ngIf="isOtherResponsableDisabled"
                    type="hidden"
                    formControlName="otherResponsible"
                    [ngModel]="otherResponsibleList.get(otherDefaultResponsible)"
                  />
                </div>
              </div>

              <!-- Parent Infos-->
              <div
                *ngIf="!parentIsValorissimo && infosAboutAccount.parentProfilTypeLabel"
                class="flex flex-row justify-start items-center mb-3"
              >
                <mat-form-field class="w-1/2">
                  <mat-label
                    >{{ i18nService._('Txt_Placeholder_AffiliateParent', [infosAboutAccount.parentProfilTypeLabel, roleTypeName]) }}
                  </mat-label>
                  <input [formControl]="parentInfosControl" matInput type="text" />
                </mat-form-field>
              </div>
              <!-- If CompanyType is contractor see boolean-->

              <!-- If CompanyType is contractor  -->
              <div *ngIf="parentIsContractor">
                <div class="flex flex-row justify-start items-center mb-3">
                  <mat-checkbox [formControl]="isVisibleCommercialLotControl" class="mt-4" color="primary">
                    {{ i18nService._('Txt_Checkbox_VisibleCommercial') }}
                  </mat-checkbox>
                </div>
                <div class="flex flex-row justify-start items-center mb-3">
                  <mat-checkbox [formControl]="isForbiddenControl" class="mt-4" color="primary">
                    {{ i18nService._('Txt_Checkbox_ForbiddenAccess') }}
                  </mat-checkbox>
                </div>
                <div class="flex flex-row justify-start items-center mb-3">
                  <mat-checkbox [formControl]="isFeesHiddenControl" class="mt-4" color="primary">
                    {{ i18nService._('Txt_Checkbox_IsFeesHidden') }}
                  </mat-checkbox>
                </div>
              </div>

              <!-- NbOptionsMax : maxlength is unvailable with number type, little trick oninput -->
              <div class="flex flex-row justify-start items-center mb-3">
                <mat-form-field class="w-1/2">
                  <mat-label>{{ i18nService._('Txt_Placeholder_Nb_Options_Max') }}"</mat-label>
                  <input
                    [formControl]="nbOptionsMaxControl"
                    matInput
                    max="99"
                    min="0"
                    data-cy="numberOptionsMax"
                    required
                    step="1"
                    type="number"
                  />
                  <mat-error *ngIf="nbOptionsMaxControl.errors">
                    <span *ngIf="nbOptionsMaxControl.errors.required">
                      {{ i18nService._('Error_Field_Required') }}
                    </span>
                    <span *ngIf="nbOptionsMaxControl.errors.min || nbOptionsMaxControl.errors.max">
                      {{ i18nService._('Error_Field_ValueBetween', [nbrOptionMinValue, nbrOptionMaxValue]) }}
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- Active -->
              <div class="flex flex-row justify-start items-center mb-7">
                <mat-checkbox [formControl]="isActiveControl" class="my-3" color="primary">
                  {{ i18nService._('Txt_Checkbox_ActiveAccount') }}
                </mat-checkbox>
              </div>

              <!-- indication Login === email-->
              <div class="flex flex-row justify-start items-center mb-3">
                <mat-form-field class="w-1/2">
                  <mat-label>{{ i18nService._('Txt_Placeholder_Login') }}</mat-label>
                  <input [formControl]="loginControl" matInput type="text" />
                </mat-form-field>
              </div>

              <!-- BO Comment -->
              <div class="flex flex-row mt-4 mb-2">
                <mat-form-field class="w-full mt-2 mr-5 mb-4">
                  <mat-label>{{ i18nService._('Txt_Placeholder_BOComment') }}</mat-label>
                  <textarea
                    data-cy="boComment"
                    matInput
                    [cdkTextareaAutosize]="true"
                    [formControl]="commentBoControl"
                    matInput
                    maxlength="2000"
                    name="boComment"
                  >
                  </textarea>

                  <div *ngIf="commentBoControl.value" class="text-right text-xxs italic">{{ commentBoControl.value.length }} /2000</div>
                </mat-form-field>
              </div>
            </mat-step>
          </div>
        </mat-stepper>
      </form>

      <!-- Bouton -->

      <div *ngIf="!loggedUserIsValo || selectedIndex === 1" class="flex flex-row ml-4">
        <button data-cy="cancel" (click)="cancel()" class="mt-2 mb-4 mr-3" color="primary" mat-stroked-button type="reset">
          {{ i18nService._('Txt_Button_Cancel') }}
        </button>

        <button data-cy="submitValidateAccount" (click)="submitAccount()" class="mt-2 mb-4" color="primary" mat-flat-button type="submit">
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</div>
