import { AccountResponse } from './AccountResponse';
import { AttachmentResponse } from './AttachmentsResponse';
import { BasicResponse } from './BasicResponse';
import { CompanyContact } from './CompanyContact';
import { CurrencyResponse } from './CurrencyResponse';
import { DocumentResponse } from './DocumentResponse';
import { ESIGNPROVIDER } from './eSign-provider.enum';
import { GroupCompanyResponse } from './GroupCompanyResponse';
import { MandatResponse } from './MandatResponse';
import { ReferenceTableData } from './ReferenceTableData';

export class CompanyData {
  id?: number;
  companyTypeId: number;
  corporateName: string;
  tradingName: string;
  legalStructureId: number;
  legalStructureOther?: string;
  professionalCategoryId: number;
  professionalCategoryOther?: string;
  homeEntity: string;
  isFrench?: boolean;

  domiciliation: string;
  companySizeId: number;
  countryId: number;
  address: string;
  additionalAddress: string;
  postalCode: string;
  city: string;
  cityInseeCode?: string;

  siret?: string;
  numCarteT?: string;
  finValiditeCarteT?: Date;
  companyCategoryId: number;
  nafId?: number;

  activeCompany?: number;
  clubMember?: number;
  isAutomatedDeactivation?: number;
  optionAutomaticValidationTypeId?: number;
  paternityAutomaticValidationTypeId?: number;
  boostedFees?: number;
  onlyBIOffer?: number;
  paternityDuration?: number;
  isAllowedToExtendOption?: boolean;
  isAllowedToExtendOptionFromPreresa?: boolean;
  isEligibleDigitalSigning: boolean;
  eSignProvider: ESIGNPROVIDER;
  eSignContractId?: string;
  descriptionTitle?: string;
  description?: string;
  boComment?: string;

  responsibleId: number;
  capital: number;
  intracommunityVAT?: string;
  commercialCourtInseeCode?: string;
  cciId: number;
  attachments: Array<AttachmentResponse>;
  newDocuments: Array<AttachmentResponse>;
  contacts: Array<CompanyContact>;
  contactAdminTypeId?: number;
  mandat?: MandatResponse;
  mandatId?: number;
  companyStatusId?: number;
  companyStatus?: BasicResponse;
  currency?: CurrencyResponse;
  isHyper: boolean;

  // Include data

  companyType?: ReferenceTableData;
  documentComment?: string;
  documents?: Array<DocumentResponse>;

  emailMessage?: string;
  responsible?: AccountResponse;
  groupCompany?: GroupCompanyResponse;

  currencyId?: number;
  languageId?: number;
  singleMandatedPrograms?: Array<{
    programName: string;
    programPostalCode: string;
  }>;
  canCheckOnlyBIOffer?: boolean;
  excludeByContractorLists?: number[];
  isBi?: boolean;
}
