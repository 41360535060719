import { AbstractControl } from '@angular/forms';

// custom validator to check that two fields match
export const mustMatch = (controlName: string, matchingControlName: string) => {
  return (control: AbstractControl) => {
    const originalControl = control.get(controlName);
    const matchingControl = control.get(matchingControlName);
    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return null;
    }
    if (originalControl.value !== matchingControl.value) {
      return { mustMatch: true };
    } else {
      return null;
    }
  };
};
