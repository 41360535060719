import { NgIf, TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-name-information',
  templateUrl: './name-information.component.html',
  styleUrls: ['./name-information.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, NgIf, MatTooltipModule],
})
export class NameInformationComponent implements OnInit {
  /**
   * ParentForm to add controls to
   *
   * @type {FormGroup}
   * @memberof IdentityInformationComponent
   */
  @Input() parentForm: UntypedFormGroup;

  /**
   * Get submit from parentForm
   *
   * @type {boolean}
   * @memberof IdentityInformationComponent
   */
  @Input() isSubmit: boolean;

  /**
   * Get if the firstNameRequired is required
   *
   * @type {boolean}
   * @memberof IdentityInformationComponent
   */
  @Input() firstNameRequired = true;

  /**
   * Get if the firstNameRequired is required
   *
   * @type {boolean}
   * @memberof IdentityInformationComponent
   */
  @Input() lastNameRequired = true;

  /**
   * lastNamePlaceholder input
   *
   * @type {string}
   * @memberof IdentityInformationComponent
   */
  @Input() lastNamePlaceholder: string;

  /**
   * firstNamePlaceholder input
   *
   * @type {string}
   * @memberof IdentityInformationComponent
   */
  @Input() firstNamePlaceholder: string;

  /**
   * lastNameTooltipText input
   *
   * @type {string}
   * @memberof IdentityInformationComponent
   */
  @Input() lastNameTooltipText: string;

  /**
   * Control on last Name
   *
   * @type {FormControl}
   * @memberof IdentityInformationComponent
   */
  public lastNameControl: FormControl;

  /**
   * Control on first Name
   *
   * @type {FormControl}
   * @memberof IdentityInformationComponent
   */
  public firstNameControl: FormControl;

  /**
   * titleCasePipe
   *
   * @type {TitleCasePipe}
   * @memberOf IdentityInformationComponent
   */
  private readonly titleCasePipe: TitleCasePipe;

  constructor(public i18nService: I18nService) {
    this.titleCasePipe = new TitleCasePipe();
  }

  /**
   * Init method
   *
   * @memberof IdentityInformationComponent
   */
  ngOnInit(): void {
    // Init placeholder values
    this.lastNamePlaceholder = this.lastNamePlaceholder ? this.lastNamePlaceholder : this.i18nService._('Txt_Placeholder_LastName');
    this.firstNamePlaceholder = this.firstNamePlaceholder ? this.firstNamePlaceholder : this.i18nService._('Txt_Placeholder_FirstName');

    this.parentForm.addControl('lastName', new UntypedFormControl('', this.lastNameRequired ? [Validators.required] : []));
    this.lastNameControl = this.parentForm.controls.lastName as FormControl;
    this.lastNameControl.setValue(this.titleCasePipe.transform(this.lastNameControl.value));
    this.parentForm.addControl('firstName', new UntypedFormControl('', this.firstNameRequired ? [Validators.required] : []));
    this.firstNameControl = this.parentForm.controls.firstName as FormControl;
    this.firstNameControl.setValue(this.titleCasePipe.transform(this.firstNameControl.value));
  }
}
