import { DossierResponse } from '@commons-dto/dossier-prospect';
import { ProspectType } from 'libs/commons-dto/src/lib/dossier-prospect/prospect/prospect-type.enum';

import { DossierProspectFormData } from './forms/dossier-prospect-form-data.model';
import { DossierProspectType } from './dossier-prospect-type.enum';
import { ProspectDataMapper } from './prospect.mapper';

export class DossierProspectMapper {
  static toForm(dossier: DossierResponse): DossierProspectFormData {
    const dossierProspectType: DossierProspectType = this.getDossierProspectType(dossier);

    const form: DossierProspectFormData = {
      dossier_id: dossier.id,
      prescriptor_id: dossier.prescriptorId,
      dossier_prospect_type: dossierProspectType,
    };

    let i = 1;
    for (const prospect of dossier.prospects) {
      ProspectDataMapper.mapFormByProspect(form, prospect, i);
      i++;
    }
    return form;
  }

  private static getDossierProspectType(dossier: DossierResponse): DossierProspectType {
    if (dossier.prospects.length == 1) {
      const prospectType = dossier.prospects[0].prospectType;
      switch (prospectType) {
        case ProspectType.PERSON:
          return DossierProspectType.SOLO;
        case ProspectType.COMPANY:
          return DossierProspectType.COMPANY;
        default:
          throw new Error(`Unknown prospectType: ${prospectType}`);
      }
    }
    return DossierProspectType.DUO;
  }

  static fromForm(form: DossierProspectFormData): DossierResponse {
    const dossierProspectFormData: DossierResponse = {
      id: form.dossier_id,
      prescriptorId: form.prescriptor_id,
      dossierStatusCode: null,
      prospects: ProspectDataMapper.fromProspectCreate(form),
    };
    return dossierProspectFormData;
  }
}
