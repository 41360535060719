<div class="flex flex-row flex-1 w-full h-full overflow-auto bg-graylight">
  <mat-drawer-container [hasBackdrop]="true" class="bg-white w-full">
    <mat-drawer
      #drawer
      class="shadow-xl overflow-visible visible w-full lg:w-4/5 lg-up-w-85 p-4"
      [opened]="false"
      mode="over"
      position="end"
    >
      <app-lot-details-panel [lotId]="lotId" [optionLotPriceId]="optionLotPriceId" (closePanel)="drawer.close()"></app-lot-details-panel>
    </mat-drawer>

    <mat-drawer-content>
      <div class="flex flex-col w-full">
        <app-sticky-header-form [titleText]="i18nService._('Title_OptionsTrackingTable')"></app-sticky-header-form>

        <div class="pt-18 mx-3 lg:mx-5 md:mx-5 sm:mx-4 px-2 lg:px-6 md:px-5 sm:px-3">
          <div class="grid grid-cols-8 gap:4 lg:gap-6">
            <div class="col-span-2">
              <mat-form-field class="fullW flex w-full">
                <mat-label>{{ i18nService._('Txt_Placeholder_Search') }}</mat-label>
                <input [(ngModel)]="filterValue" (ngModelChange)="textFilterSubject.next($event)" matInput />
              </mat-form-field>
            </div>

            <mat-form-field class="fullW flex w-full my-0">
              <mat-label>Statut</mat-label>
              <mat-select data-cy="Statut" [ngModel]="selectedStatus" (selectionChange)="onStatusListChange($event)" multiple>
                <mat-option *ngFor="let status of statusFilterList" [value]="status.value" attr.data-cy="{{ status.label | translate }}">
                  {{ status.label | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div class="flex h-full items-center col-span-2">
              <app-toggle-button (selectChange)="changeTeamFilter($event)" *ngIf="displayTeamButton" [checked]="isTeamMemberParam">
                <span class="font-bold">{{ i18nService._('Txt_TrackingTable_MY_TEAM') }}</span>
              </app-toggle-button>
              <app-toggle-button (selectChange)="changePartnerFilter($event)" *ngIf="displayPartnerButton" [checked]="isPartnerParam">
                <span class="font-bold">{{ i18nService._('Txt_TrackingTable_MY_PARTNERS') }}</span>
              </app-toggle-button>
            </div>
          </div>

          <div class="tracking-mat-table">
            <app-grid-tracking-table
              [columnDefs]="columnDefs"
              [columns]="columns"
              [serviceUrl]="serviceUrl"
              [tableLoader]="optionsTrackingTableService"
              [pageSize]="pageSize"
              [defaultSortColunm]="defaultSortColunm"
              [defaultSortDirection]="defaultSortDirection"
              [isPartner]="isPartnerParam"
              [isTeamMember]="isTeamMemberParam"
              [textFilter]="textFilterValue"
              [i18nFilter]="i18nFilter"
              [minimumFilterLength]="minimumFilterLength"
            >
            </app-grid-tracking-table>
          </div>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
