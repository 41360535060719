<div class="flex flex-col">
  <p-calendar
    [formControl]="formControl"
    [formlyAttributes]="field"
    [dateFormat]="field.props.dateFormat || 'dd/mm/yy'"
    [disabled]="field.props.disabled"
    [readonlyInput]="field.props.readonly"
    [showOnFocus]="!field.props.readonly"
    [required]="field.props.required"
    [showIcon]="!field.props.readonly"
  ></p-calendar>
</div>
