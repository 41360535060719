import { I18nPluralPipe, Location } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxCaptchaModule } from 'ngx-captcha';
import { Settings } from 'luxon';
import { FormlyModule } from '@ngx-formly/core';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

import { AccountsModule } from './accounts/accounts.module';
import { AppInitService } from './app-init.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CompaniesModule } from './companies/companies.module';
import { ComparatorModule } from './comparator/comparator.module';
import { LegalNoticeModule } from './legal-notice/legal-notice.module';
import { LocatorModule } from './locator.module';
import { ProgramsModule } from './programs/programs.module';
import { ProgramService } from './programs/services/program.service';
import { ProspectsModule } from './prospects/prospects.module';
import { ReservationsModule } from './reservations/reservations.module';
import { SearchModule } from './search/search.module';
import { BearerInterceptor } from './security/bearer.interceptor';
import { HTMLEntitiesInterceptor } from './security/htmlentities.interceptor';
import { WatchDogService } from './security/watchdog.service';
import { SpecialOfferModule } from './special-offer/special-offer.module';
import { StrategiesModule } from './strategies/strategies.module';
import { TrackingTablesModule } from './tracking-tables/tracking-tables.module';
import { RecommendationsModule } from './recommendations/recommendations.module';
import { APP_ICONS } from './utils/constantes/icones';
import { ScriptService } from './utils/services/script-loader.service';
import { UtilsModule } from './utils/utils.module';
import { SignatureModule } from './signature/signature.module';
import { FormlyStepperComponent } from './common/formly/formly-stepper/formly-stepper.component';
import { FormlyRadioCardsComponent } from './common/formly/formly-radio-cards/formly-radio-cards.component';
import { RecaptchaService } from './utils/services/recaptcha.service';
import { FeatureFlagService } from './feature-flag/feature-flag.service';
import { DossierProspectModule } from './dossier-prospect/dossier-prospect.module';
import { Sitemap } from './utils/models/Sitemap';
import { FormlySimpleComponent } from './common/formly/formly-simple/formly-simple.component';
import { CountryModule } from './country/country.module';
import { PreReservationsRoutingModule } from './pre-reservations/pre-reservations-routing.module';

import { AppConfigService } from '../../src/app/utils/services/app-config.service';

Settings.defaultLocale = 'fr';

export const initializeApp = (
  appInitService: AppInitService,
  recaptchaService: RecaptchaService,
  appConfigService: AppConfigService,
  location: Location,
) => {
  return async (): Promise<boolean> => {
    try {
      await recaptchaService.setRecaptchaPublicKey();
      await recaptchaService.setRecaptchaTokeninit(appConfigService._('actionNameRecaptchaV3', 'initApp'));
    } catch (error) {
      console.error(error);
    }
    try {
      const path = location.path();

      if (!path.includes(Sitemap.utils.changePassword.path)) {
        await appInitService.init();
      }
      return true;
    } catch (e) {
      return true;
    }
  };
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    LocatorModule,
    BrowserModule,
    HttpClientModule,
    UtilsModule,
    FormlyModule.forRoot({
      types: [
        { name: 'stepper', component: FormlyStepperComponent, wrappers: [] },
        { name: 'simple', component: FormlySimpleComponent, wrappers: [] },
        { name: 'radio-cards', component: FormlyRadioCardsComponent },
      ],
    }),
    AccountsModule,
    CompaniesModule,
    StrategiesModule,
    LegalNoticeModule,
    ProgramsModule,
    SearchModule,
    //TODO: Remove and Delete this module entirely once DossierProspect is done
    ProspectsModule,
    DossierProspectModule,
    ReservationsModule,
    CountryModule,
    TrackingTablesModule,
    SpecialOfferModule,
    RecommendationsModule,
    BrowserAnimationsModule,
    NgxCaptchaModule,
    MatIconModule,
    ComparatorModule,
    SignatureModule,
    PreReservationsRoutingModule,
    AppRoutingModule,
  ],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: (featureFlagService: FeatureFlagService) => () => featureFlagService.loadFeatureFlags(),
      deps: [FeatureFlagService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService, RecaptchaService, AppConfigService, Location],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTMLEntitiesInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WatchDogService,
      multi: true,
    },
    ProgramService,
    ScriptService,
    I18nPluralPipe,
    { provide: 'i18nPluralPipe', useClass: I18nPluralPipe },
    { provide: LOCALE_ID, useValue: 'fr_FR' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    Object.keys(APP_ICONS).forEach((key) => {
      iconRegistry.addSvgIconLiteral(key, sanitizer.bypassSecurityTrustHtml(APP_ICONS[key]));
    });
  }
}
