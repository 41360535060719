import { Component, OnInit } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';

import { TokenService } from '../../services/authorisation/token.service';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  selector: 'app-commercial-contact',
  templateUrl: './commercial-contact.component.html',
  styleUrls: ['./commercial-contact.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf],
})
export class CommercialContactComponent implements OnInit {
  commercialContacts: any[];

  otherContacts = [
    {
      label: 'Mandat',
      email: 'mandat@valorissimo.com',
    },
    {
      label: 'Facturation',
      phone: '07 61 44 61 34',
      email: 'facturation@valorissimo.com',
    },
  ];

  constructor(
    private readonly tokenService: TokenService,
    private readonly appConfigService: AppConfigService,
  ) {}

  ngOnInit() {
    const userRole = this.tokenService.getRoleName();
    const usersRoles = this.appConfigService.appConfig.roles;
    const forbiddenRoles = [usersRoles.independant, usersRoles.independantNetworkLeader, usersRoles.independantNetworkHeader];

    if (!forbiddenRoles.includes(userRole)) {
      this.commercialContacts = [
        {
          name: 'Benjamin CHAPPAZ',
          phone: '06 65 06 70 77',
          email: 'BENJAMIN.CHAPPAZ@valorissimo.com',
        },
        {
          name: 'Cathy GUAMS',
          phone: '07 62 48 60 13',
          email: 'CATHY.GUAMS@valorissimo.com',
        },
      ];
    }
  }
}
