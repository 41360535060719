import { Component, EventEmitter, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SearchFilterContentComponent } from '../search-filter-content/search-filter-content.component';
import { SearchFilterReinitComponent } from '../search-filter-reinit/search-filter-reinit.component';

import { I18nService } from '../../../../utils/services/i18n.service';
import { SearchFormUtilsService } from '../../../services/search-form-utils.service';

@Component({
  selector: 'app-search-filter-more-panel',
  templateUrl: './search-filter-more-panel.component.html',
  styleUrls: ['./search-filter-more-panel.component.scss'],
  standalone: true,
  imports: [SearchFilterReinitComponent, MatIconModule, SearchFilterContentComponent],
})
export class SearchFilterMorePanelComponent {
  @Output() readonly launchSearchEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public readonly i18nService: I18nService,
    private readonly _searchFormUtilsService: SearchFormUtilsService,
  ) {}

  launchSearch() {
    this.launchSearchEvent.emit(true);
  }

  closePanel() {
    this.launchSearchEvent.emit(false);
  }
}
