<div class="text-base font-bold text-left mb-3" *ngIf="!titelUp">
  {{ i18nService._('Txt_search_filter_location_panel_title') }}
</div>
<mat-chip-listbox [ngClass]="{ 'mat-chip-list-stacked': !open }" *ngIf="locationList && locationList.length">
  <mat-chip-option color="primary" *ngFor="let item of locationList; let i = index" class="mr-3 text-sm">
    <mat-icon class="text-sm text-white h-auto align-middle" (click)="removeLocalisation($event, i)">close</mat-icon>
    <app-search-location-label [localisation]="item" class="text-white pr-1"></app-search-location-label>
  </mat-chip-option>
</mat-chip-listbox>

<div class="no-location txt-grey" *ngIf="!titelUp && (!locationList || !locationList.length)">
  <mat-icon class="material-icons-outlined">map</mat-icon>
  <div>{{ i18nService._('Txt_search_filter_location_panel_placeholder') }}</div>
</div>
