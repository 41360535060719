/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { ProgramLotsInformationResponse } from './../../utils/models/ProgramLotsInformations';
import { SharedProgramService } from './../../utils/services/shared-program.service';
import { TableLoader } from '../../table/interfaces/DataLoader';

@Injectable({
  providedIn: 'root',
})
export class LotsProgramTrackingTableService implements TableLoader<ProgramLotsInformationResponse> {
  constructor(private readonly sharedProgramService: SharedProgramService) {}

  public async getLotsProgram(programId: string): Promise<ProgramLotsInformationResponse> {
    return await lastValueFrom(this.sharedProgramService.getProgramLotsInformation(programId));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getById(id: number): Promise<any> {
    return null;
  }
}
