<div *ngIf="dataIsLoad" class="flex flex-col">
  <!-- Legal Representative Contact informations -->
  <form [formGroup]="contacts.legal.form">
    <app-contact-information class="w-full" [parentForm]="contacts.legal.form" [isSubmit]="isSubmit" [contactsInfo]="contacts.legal.data">
    </app-contact-information>
  </form>

  <ng-container *ngIf="!validatedCompany">
    <div class="text-base font-semibold my-2">
      {{ i18nService._('Txt_Page_Form_RegisterCompany_LegalRepresentative') }}
    </div>
    <mat-radio-group (change)="isLegalRepresentative(!$event.value)">
      <mat-radio-button name="isLegalRepresentative" color="primary" [value]="true" [checked]="!showLegalBlock">
        {{ i18nService._('Txt_RadioButton_Yes') }}
      </mat-radio-button>
      <mat-radio-button name="isLegalRepresentative" color="primary" [value]="false" [checked]="showLegalBlock">
        {{ i18nService._('Txt_RadioButton_No') }}
      </mat-radio-button>
    </mat-radio-group>
  </ng-container>
  <!-- End of Legal Representative informations -->

  <!-- Applicant Informations -->
  <div [hidden]="!showLegalBlock" class="mt-5">
    <form [formGroup]="contacts.applicant.form">
      <div class="flex flex-col">
        <div class="text-2xl mb-4 font-bold">
          {{ i18nService._('Title_Applicant') }}
        </div>
        <app-contact-information
          class="w-full"
          [parentForm]="contacts.applicant.form"
          [isSubmit]="isSubmit"
          [contactsInfo]="contacts.applicant.data"
        >
        </app-contact-information>
      </div>
    </form>
  </div>

  <!-- End of Applicant informations -->
  <ng-container *ngIf="!validatedCompany">
    <hr class="w-5 my-12" />

    <!-- Mandate Delegation Informations -->
    <div>
      <div class="text-base font-semibold my-2">
        {{ i18nService._('Txt_Page_Form_RegisterCompany_MandateDelegation') }}
      </div>
      <mat-radio-group (change)="isMandateDelegation($event.value)">
        <mat-radio-button name="mandateDelegation" color="primary" [value]="true" [checked]="isMandateDelegationValue">
          {{ i18nService._('Txt_RadioButton_Yes') }}
        </mat-radio-button>
        <mat-radio-button name="mandateDelegation" color="primary" [value]="false" [checked]="!isMandateDelegationValue">
          {{ i18nService._('Txt_RadioButton_No') }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="isMandateDelegationValue" class="mt-5">
      <div class="text-base font-semibold my-2">
        {{ i18nService._('Txt_Page_Form_RegisterCompany_MandateSignatory') }}
      </div>
      <mat-radio-group (change)="isMandatorySignatory(!$event.value)">
        <mat-radio-button name="isMandateSignatory" color="primary" [value]="true" [checked]="!showMandatoryBlock">
          {{ i18nService._('Txt_RadioButton_Yes') }}
        </mat-radio-button>
        <mat-radio-button name="isMandateSignatory" color="primary" [value]="false" [checked]="showMandatoryBlock">
          {{ i18nService._('Txt_RadioButton_No') }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <!-- End of Mandate Delegation informations -->
  </ng-container>

  <!-- Mandate Signatory Informations -->
  <div [hidden]="!showMandatoryBlock" class="mt-5">
    <form [formGroup]="contacts.mandate.form">
      <div class="flex flex-col">
        <div class="text-2xl mb-4 font-bold">
          {{ i18nService._('Title_MandateDelegation') }}
        </div>
        <app-contact-information
          class="w-full"
          [parentForm]="contacts.mandate.form"
          [isSubmit]="isSubmit"
          [contactsInfo]="contacts.mandate.data"
        >
        </app-contact-information>
      </div>
    </form>
  </div>
  <!-- End of Mandate Signatory informations -->

  <hr class="w-5 my-12" />

  <ng-container *ngIf="listAdminOptions.length > 1 || validatedCompany">
    <div class="mb-4">
      <div class="text-base font-semibold my-2">
        {{ i18nService._('Txt_Page_Form_RegisterCompany_Admin') }}
      </div>
      <mat-radio-group class="flex flex-row" [formControl]="contactAdminTypeIdControl" [disabled]="validatedCompany">
        <mat-radio-button *ngFor="let option of listAdminOptions" color="primary" [value]="option.id">
          {{ i18nService._(option.label) }}
        </mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="contactAdminTypeIdControl.errors && isSubmit">
        {{ i18nService._('Error_Field_Required') }}
      </mat-error>
    </div>
  </ng-container>

  <!-- Next Step -->
  <div class="flex w-1/5">
    <button type="button" class="mt-3" mat-stroked-button color="primary" data-cy="nextStep" matStepperNext (click)="submitStep()">
      {{ i18nService._('Txt_Button_NextStep') }}
    </button>
  </div>
</div>
