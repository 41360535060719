<div *ngIf="dossier" class="w-full h-full pt-10 px-8 bg-grey-background-light inline-table pb-6">
  <form *ngIf="fields" (ngSubmit)="save(true)" [formGroup]="form" class="w-full h-full bg-grey-background-light">
    <formly-form
      #formlyForm
      [fields]="fields"
      (modelChange)="validateChanges($event)"
      [form]="form"
      [model]="model"
      [options]="options"
    ></formly-form>
  </form>
</div>
