/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  FormControl,
  NgControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf, NgFor, NgStyle } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { FinancialStrategyNbLotByTypologyComponent } from '../financial-strategy-nb-lot-by-typology/financial-strategy-nb-lot-by-typology.component';

import { DropdownListPopulatedComponent } from '../../../utils/components/dropdown-list-populated/dropdown-list-populated.component';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { ReferenceTableData } from '../../../utils/models/ReferenceTableData';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { SpinnerWithBackdropService } from '../../../utils/services/spinner-with-backdrop.service';
import { FinancialStrategyFormData } from '../../models/FinancialStrategyFormData';
import { LotByTopologyEntry } from '../../models/LotByTopologyEntry';
import { FinancialStrategyService } from '../../services/financial-strategy.service';
import { DecimalMaskDirective } from '../../../utils/directives/decimal-mask.directive';
import { ProgramFinancialStrategiesTrackingTableComponent } from '../../../tracking-tables/components/program-financial-stategy-tracking-table/program-financial-stategy-tracking-table.component';

@Component({
  selector: 'app-register-form-financial-strategy',
  templateUrl: './register-form-financial-strategy.component.html',
  styleUrls: ['./register-form-financial-strategy.component.scss'],
  standalone: true,
  imports: [
    ProgramFinancialStrategiesTrackingTableComponent,
    FormsModule,
    ReactiveFormsModule,
    DropdownListPopulatedComponent,
    MatFormFieldModule,
    MatInputModule,
    DecimalMaskDirective,
    NgIf,
    MatTooltipModule,
    NgFor,
    FinancialStrategyNbLotByTypologyComponent,
    MatButtonModule,
    NgStyle,
  ],
})
export class RegisterFormFinancialStrategyComponent implements OnInit {
  /**
   * programId input
   *
   * @type {string}
   * @memberof RegisterFormFinancialStrategyComponent
   */
  @Input() public programId: number;
  @Input() public readonly: boolean;

  /**
   * financialStrategyChange output
   *
   * @type {EventEmitter<boolean>}
   * @memberof RegisterFormFinancialStrategyComponent
   */
  @Output() public readonly financialStrategyChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * responsibleDropdown viewchild
   *
   * @type {DropdownListPopulatedComponent}
   * @memberof RegisterFormFinancialStrategyComponent
   */
  @ViewChild('responsibleDropdown')
  public responsibleDropdown: DropdownListPopulatedComponent;

  /**
   * generatedFeesAmounts attribute
   *
   * @type {any}
   * @memberof RegisterFormFinancialStrategyComponent
   */
  public generatedFeesAmounts: any;

  /**
   * displayGeneratedFeesAmounts attribute
   *
   * @type {boolean}
   * @memberof RegisterFormFinancialStrategyComponent
   */
  public displayGeneratedFeesAmounts = false;

  /**
   * financialStrategyForm attribute
   *
   * @type {FormGroup}
   * @memberof RegisterFormFinancialStrategyComponent
   */
  public financialStrategyForm: UntypedFormGroup;
  /**
   * rcvItems attribute
   *
   * @type {Array<ReferenceTableData>}
   * @memberof RegisterFormFinancialStrategyComponent
   */
  public rcvItems: Array<ReferenceTableData>;
  /**
   * lotsByTopologyTable attribute
   *
   * @type {Array<LotByTopologyEntry>}
   * @memberof RegisterFormFinancialStrategyComponent
   */
  public lotsByTopologyTable: Array<LotByTopologyEntry>;
  /**
   * feesAmountControl attribute
   *
   * @type {FormControl}
   * @memberof RegisterFormFinancialStrategyComponent
   */
  public feesAmountControl: FormControl;
  /**
   * cappingThresholdControl attribute
   *
   * @type {FormControl}
   * @memberof RegisterFormFinancialStrategyComponent
   */
  public cappingThresholdControl: FormControl;
  /**
   * notificationThresholdControl attribute
   *
   * @type {FormControl}
   * @memberof RegisterFormFinancialStrategyComponent
   */
  public notificationThresholdControl: FormControl;
  /**
   * reservationControl attribute
   *
   * @type {FormControl}
   * @memberof RegisterFormFinancialStrategyComponent
   */
  public reservationControl: FormControl;
  /**
   * prereservationControl attribute
   *
   * @type {FormControl}
   * @memberof RegisterFormFinancialStrategyComponent
   */
  public prereservationControl: FormControl;
  /**
   * isSubmit attribute
   *
   * @type {boolean}
   * @memberof RegisterFormFinancialStrategyComponent
   */
  public isSubmit: boolean;
  /**
   * existingFinancialStrategyId attribute
   *
   * @private
   * @type {number}
   * @memberof RegisterFormFinancialStrategyComponent
   */
  public existingFinancialStrategyId: number;
  /**
   * defaultnotificationThreshold attribute
   *
   * @private
   * @type {number}
   * @memberof RegisterFormFinancialStrategyComponent
   */
  private defaultnotificationThreshold: number;

  /**
   * Creates an instance of RegisterFormFinancialStrategyComponent.
   * @param {FormBuilder} formBuilder
   * @param {I18nService} i18nService
   * @param {SnackbarService} snackbarService
   * @param {FinancialStrategyService} financialStrategyService
   * @memberof RegisterFormFinancialStrategyComponent
   */
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    public readonly i18nService: I18nService,
    private readonly snackbarService: SnackbarService,
    private readonly financialStrategyService: FinancialStrategyService,
    private readonly spinnerWithBackdropService: SpinnerWithBackdropService,
  ) {
    this.rcvItems = [];
    this.lotsByTopologyTable = [];
    this.isSubmit = false;
    this.defaultnotificationThreshold = undefined;
    this.existingFinancialStrategyId = undefined;
  }

  /**
   * Check if value contains only digit number and be parse to number value if it is string
   * @param {string | number} value
   */
  static isNumeric(value: string | number): boolean {
    if (!value || (typeof value !== 'string' && typeof value !== 'number')) {
      return false;
    }

    return /^\d+$/.test(`${value}`);
  }

  /**
   * Init component
   *
   * @memberof RegisterFormFinancialStrategyComponent
   */
  public ngOnInit(): void {
    // Set financial stratefy form
    this.financialStrategyForm = this.formBuilder.group({
      feesAmount: new UntypedFormControl(undefined),
      cappingThreshold: new UntypedFormControl(undefined, [this.requiredIfFeesAmount.bind(this)]),
      notificationThreshold: new UntypedFormControl(undefined, [this.requiredIfFeesAmount.bind(this)]),
    });
    this.feesAmountControl = this.financialStrategyForm.controls.feesAmount as FormControl;
    this.cappingThresholdControl = this.financialStrategyForm.controls.cappingThreshold as FormControl;
    this.notificationThresholdControl = this.financialStrategyForm.controls.notificationThreshold as FormControl;

    this.financialStrategyForm.addControl('reservation', new UntypedFormControl('', []));
    this.reservationControl = this.financialStrategyForm.controls.reservation as FormControl;
    this.financialStrategyForm.addControl('prereservation', new UntypedFormControl('', []));
    this.prereservationControl = this.financialStrategyForm.controls.prereservation as FormControl;

    // Get data for formulary
    this.financialStrategyService.getFormData('' + this.programId).subscribe((data: FinancialStrategyFormData) => {
      // Fill lotsByTopologyTable for Lot typology level part of html
      this.lotsByTopologyTable = [
        {
          fieldName: 'oneRooms',
          nbLotMax: data.nbOfLots.oneRooms,
          placeholder: this.i18nService._('Txt_OneRoomLabel'),
        },
        {
          fieldName: 'twoRooms',
          nbLotMax: data.nbOfLots.twoRooms,
          placeholder: this.i18nService._('Txt_NRoomsLabel', [2]),
        },
        {
          fieldName: 'threeRooms',
          nbLotMax: data.nbOfLots.threeRooms,
          placeholder: this.i18nService._('Txt_NRoomsLabel', [3]),
        },
        {
          fieldName: 'fourRooms',
          nbLotMax: data.nbOfLots.fourRooms,
          placeholder: this.i18nService._('Txt_NRoomsLabel', [4]),
        },
        {
          fieldName: 'fiveRooms',
          nbLotMax: data.nbOfLots.fiveRooms,
          placeholder: this.i18nService._('Txt_NRoomsLabel', [5]),
        },
        {
          fieldName: 'sixOrMoreRooms',
          nbLotMax: data.nbOfLots.sixOrMoreRooms,
          placeholder: this.i18nService._('Txt_6RoomsOrMoreLabel'),
        },
      ];

      // Set items of responsible dropdown
      this.rcvItems = data.rcvs.map((rcv) => {
        return {
          id: rcv.rcvId,
          label: `${rcv.rcvFirstname} ${rcv.rcvLastname}`,
        };
      });

      if (data.existingFinancialStrategy) {
        // Send signal for publish button updating
        this.financialStrategyChange.emit(true);

        this.existingFinancialStrategyId = data.existingFinancialStrategy.id;
        // Set value from existing financial strategy
        this.responsibleDropdown.setNewValueFromDb(data.existingFinancialStrategy.responsibleId);

        this.reservationControl.reset({
          value: data.existingFinancialStrategy.reservationTransformationRate,
          disabled: true,
        });
        this.prereservationControl.reset({
          value: data.existingFinancialStrategy.prereservationTransformationRate,
          disabled: true,
        });

        // Fill nbMax of lots
        if (data.existingFinancialStrategy.nbMaxOfLots) {
          data.existingFinancialStrategy.nbMaxOfLots.forEach((element) => {
            this.lotsByTopologyTable.find((lotsByTopology) => lotsByTopology.fieldName === element.type).nbLot = element.nbMax;
          });
        }

        // Fill honorary capping with existing values
        if (data.existingFinancialStrategy.feesAmount !== undefined) {
          this.feesAmountControl.setValue(data.existingFinancialStrategy.feesAmount);
          this.cappingThresholdControl.setValue(data.existingFinancialStrategy.cappingThreshold);
          this.notificationThresholdControl.setValue(data.existingFinancialStrategy.notificationThreshold);
        }

        // Add for back
        this.financialStrategyForm.addControl(
          'prereservationTransformationRate',
          new UntypedFormControl(data.existingFinancialStrategy.prereservationTransformationRate),
        );
        this.financialStrategyForm.addControl(
          'reservationTransformationRate',
          new UntypedFormControl(data.existingFinancialStrategy.reservationTransformationRate),
        );
        this.financialStrategyForm.addControl('programId', new UntypedFormControl(this.programId));
      } else {
        // Set the default rcv
        this.responsibleDropdown.setNewValueFromDb(data.defaultRcv);

        this.reservationControl.reset({
          value: data.params.tResa,
          disabled: true,
        });
        this.prereservationControl.reset({
          value: data.params.tPreresa,
          disabled: true,
        });

        // Add for back
        this.financialStrategyForm.addControl('prereservationTransformationRate', new UntypedFormControl(data.params.tPreresa));
        this.financialStrategyForm.addControl('reservationTransformationRate', new UntypedFormControl(data.params.tResa));
        this.financialStrategyForm.addControl('programId', new UntypedFormControl(this.programId));
      }

      this.defaultnotificationThreshold = data.params.defaultNotifValue;
    });

    if (this.readonly) {
      Object.keys(this.financialStrategyForm.controls).forEach((key) => {
        this.financialStrategyForm.controls[key].disable();
      });
    }
  }

  /**
   * feesAmountModified method
   *
   * @memberof RegisterFormFinancialStrategyComponent
   */
  public feesAmountModified(): void {
    if (this.feesAmountControl.value) {
      // Set default notification treshold
      this.notificationThresholdControl.setValue(Number(this.defaultnotificationThreshold));
    } else {
      // Remove value of capping and notification treshold if reseting feesAmount
      this.cappingThresholdControl.setValue(undefined);
      this.notificationThresholdControl.setValue(undefined);
      if (!this.isSubmit) {
        this.cappingThresholdControl.markAsUntouched();
        this.notificationThresholdControl.markAsUntouched();
      }
    }

    // Update validators status
    this.cappingThresholdControl.updateValueAndValidity();
    this.notificationThresholdControl.updateValueAndValidity();
  }

  /**
   * onSubmit method
   *
   * @memberof RegisterFormFinancialStrategyComponent
   */
  public onSubmit(): void {
    this.spinnerWithBackdropService.show();
    if (this.financialStrategyForm.valid) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const {
        feesAmountControl: { value: valueOfFeesAmountControl },
      } = this;

      // handleEmptyInput => ignore feesAmount value if empty or 0 or not number
      if (!RegisterFormFinancialStrategyComponent.isNumeric(valueOfFeesAmountControl || parseInt(valueOfFeesAmountControl, 10) === 0)) {
        this.feesAmountControl.setValue(undefined);
      }

      this.financialStrategyService
        .createOrUpdateFinancialStrategy(this.financialStrategyForm.value, this.existingFinancialStrategyId)
        .subscribe(
          () => {
            this.spinnerWithBackdropService.hide();
            let successMessage;
            if (this.existingFinancialStrategyId) {
              successMessage = this.i18nService._('Txt_FinancialStrategy_ModificationSuccess');
            } else {
              // Default success message: Financial strategy with both cap
              successMessage = this.i18nService._('Txt_FinancialStrategy_BothCapSuccess');
              if (!this.feesAmountControl.value && this.isTypologyCapEmpty()) {
                // Financial strategy empty
                successMessage = this.i18nService._('Txt_FinancialStrategy_NoCapSuccess');
              } else if (this.feesAmountControl.value && this.isTypologyCapEmpty()) {
                // Financial strategy only with fees amount cap
                successMessage = this.i18nService._('Txt_FinancialStrategy_FeesAmountCapSuccess');
              } else if (!this.feesAmountControl.value && !this.isTypologyCapEmpty()) {
                // Financial strategy only with typology cap
                successMessage = this.i18nService._('Txt_FinancialStrategy_TypologyCapSuccess');
              }
            }

            // Show snackbar
            const message: SnackbarMessage = {
              text: successMessage,
              type: SnackbarMessageType.Info,
            };
            this.snackbarService.sendMessage(message);
            this.financialStrategyChange.emit(true);
            this.financialStrategyService.getFormData('' + this.programId).subscribe((data: FinancialStrategyFormData) => {
              if (data.existingFinancialStrategy) {
                this.existingFinancialStrategyId = data.existingFinancialStrategy.id;
              }
            });
          },
          () => {
            this.spinnerWithBackdropService.hide();
          },
        );
    } else {
      // Form invalid
      this.isSubmit = true;
      this.spinnerWithBackdropService.hide();
      const message: SnackbarMessage = {
        text: this.i18nService._('Error_SnackBar_FormIncomplete'),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
    }
  }

  /**
   * isTypologyCapEmpty, return true if no typology cap
   *
   * @private
   * @returns {boolean}
   * @memberof RegisterFormFinancialStrategyComponent
   */
  private isTypologyCapEmpty(): boolean {
    let isEmpty = true;
    this.lotsByTopologyTable.forEach((element) => {
      const value = this.financialStrategyForm.controls[element.fieldName].value;
      isEmpty = isEmpty && !value && value !== 0;
    });

    return isEmpty;
  }

  /**
   * requiredIfFeesAmount validator
   *
   * @param {NgControl} control
   * @returns {{ [key: string]: boolean }}
   * @memberof RegisterFormFinancialStrategyComponent
   */
  private requiredIfFeesAmount(control: NgControl): { [key: string]: boolean } {
    if (this.feesAmountControl && this.feesAmountControl.value && !control.value) {
      return { requiredIfFeesAmount: true };
    }

    return undefined;
  }
}
