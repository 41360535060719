<div class="flex flex-col" *ngIf="reservationInformations$ | async as reservationInformations">
  <!-- Sticky subHeader form -->
  <app-sticky-header-form
    (actionButtonClicked)="onCancelReservationClicked()"
    [actionButtonLabel]="isCancelButtonDisplayed ? i18nService._('Txt_Button_Cancel') : undefined"
    [titleText]="i18nService._('Title_Reservation_Form')"
  >
  </app-sticky-header-form>

  <div class="grid grid-cols-10 pt-16">
    <div class="col-span-10 lg:col-span-8 lg:col-start-2 xl:col-span-6 xl:col-start-3 flex flex-col pt-16">
      <!-- Details of associate seller -->
      <app-details-of-associate-seller [reservationInformations]="reservationInformations"></app-details-of-associate-seller>

      <!-- Reason of reservation cancellation -->
      <app-reason-of-reservation-cancellation [reservationInformations]="reservationInformations"></app-reason-of-reservation-cancellation>

      <!-- Form -->
      <mat-stepper
        orientation="vertical"
        #stepper
        appMatVerticalStepper
        [@.disabled]="true"
        [linear]="true"
        (selectionChange)="onSelectionChange($event)"
        class="mb-5"
      >
        <ng-template matStepperIcon="edit">
          <span class="icon-validate-small"></span>
        </ng-template>

        <!-- Step 1 : reservation -->
        <mat-step [stepControl]="stepOneForm">
          <ng-template matStepLabel>
            {{ i18nService._('Title_Reservation_Form') }}
          </ng-template>
          <form [formGroup]="stepOneForm">
            <app-step-one-form-reservation
              [resaInformation]="reservationInformations"
              [isActiveStep]="selectedIndex === 0"
              [isSubmit]="isSubmit"
              (formGroupChanged)="setParentForm($event, 'stepOneForm')"
            >
            </app-step-one-form-reservation>
            <!-- Next Step -->
            <button type="submit" class="mt-4" mat-stroked-button (click)="nextStep()" color="primary">
              {{ i18nService._('Txt_Button_NextStep') }}
            </button>
          </form>
        </mat-step>

        <!-- Step 2 : status handler -->
        <mat-step>
          <ng-template matStepLabel>
            {{ i18nService._('Title_Reservation_ProgressOfTheReservation') }}
          </ng-template>
          <form [formGroup]="stepTwoForm">
            <app-step-two-form-reservation
              [resaInformation]="reservationInformations"
              (formGroupChanged)="setParentForm($event, 'stepTwoForm')"
            >
            </app-step-two-form-reservation>
            <!-- Next Step -->
            <button
              type="submit"
              class="mt-3"
              mat-stroked-button
              (click)="submitReservation()"
              [disabled]="stepOneForm.disabled || stepTwoForm.disabled"
              color="primary"
            >
              {{ i18nService._('Txt_Button_Save') }}
            </button>
          </form>
        </mat-step>
      </mat-stepper>
    </div>
  </div>
</div>
