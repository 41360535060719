<app-stepper
  #stepper
  [fields]="field.fieldGroup"
  [selectedIndex]="selectedStepIndex"
  [stepperParam]="field.props"
  (selectionChange)="onPageChanged($event)"
  [model]="model"
  [currentStep]="field"
>
  <ng-container *ngFor="let step of field.fieldGroup; let index = index; let last = last">
    <app-my-step
      *ngIf="step.hide === undefined || step.hide === false"
      [fieldIndex]="index"
      [field]="step"
      [head]="step.props['isHead']"
      [subHead]="step.props['isSubHead']"
    >
      <ng-template cdkStepLabel>
        <div class="pl-2 relative flex items-center">
          <div *ngIf="stepper.selectedStep.field.id === step.id" class="mr-2 w-1 bg-blue-500 overflow-hidden rounded">&nbsp;</div>
          <span
            [ngClass]="{
              'flex-1 text-xl leading-7': step.props['isHead'],
              'text-base leading-[26px]': step.props['isSubHead'],
              'text-sm leading-6': !step.props['isHead'] && !step.props['isSubHead']
            }"
          >
            {{ i18nService._(step.props.label) }}
          </span>
          <app-icon
            *ngIf="isValid(step) && !step.props['isHead'] && !step.props['isSubHead']"
            name="Check"
            class="text-green-400 ml-4"
          ></app-icon>
          <div *ngIf="step.props['isHead']" class="justify-self-end text-xl leading-7">
            <app-icon [name]="step.props['open'] ? 'ChevronUp' : 'ChevronDown'" class="justify-self-end right-0"></app-icon>
          </div>
        </div>
      </ng-template>
      <ng-container *ngIf="!step.props['isHead'] || !step.props['isSubHead']">
        <formly-field [field]="step"></formly-field>
        <div
          *ngIf="!step.props['hideStepAction'] && !stepper?.stepperParam?.hideNavigationButton"
          class="flex flex-1 items-end justify-between"
        >
          <div>
            <button
              *ngIf="index > 1"
              (click)="back()"
              class="bg-grey-1 rounded-3xl py-4 px-6 h-12 uppercase flex items-center justify-center font-bold text-sm leading-4"
              type="button"
            >
              <span>{{ i18nService._('txt_stepper_previous') }}</span>
            </button>
          </div>
          <div *ngIf="selectedStep">
            <button
              *ngIf="stepper.steps.length !== stepper.selectedIndex + 1"
              [disabled]="stepper.stepperParam.validStepForNext && !isValid(step)"
              (click)="next()"
              class="rounded-button"
              type="button"
            >
              {{ i18nService._(selectedStep.field.props['nextButtonLabel'] || 'txt_stepper_next') }}
            </button>
            <button
              *ngIf="stepper.steps.length === stepper.selectedIndex + 1"
              [disabled]="stepper.stepperParam.validStepForNext && !isValid(step)"
              class="rounded-button"
              type="submit"
              (click)="finish()"
            >
              {{ i18nService._(selectedStep.field.props['nextButtonLabel'] || field.props['lastPageButton'] || 'txt_stepper_next') }}
            </button>
          </div>
        </div>
      </ng-container>
    </app-my-step>
  </ng-container>
</app-stepper>
