import { NavigationExtras, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { CompleteDossierResponse, SearchCriteria, SummarySearchPreferenceResponse } from '@commons-dto/dossier-prospect';

import { Sitemap } from '../../../utils/models/Sitemap';
import { DEFAULT_SEARCH_PROGRAM_PARAMS } from '../../../search/model/search-page-contantes';

@Injectable({
  providedIn: 'root',
})
export class DossierProspectRouteService {
  constructor(
    private readonly router: Router,
    private location: Location,
  ) {}

  public goToDashboard(dossierId: number, rewriteOnly = false): void {
    this.goToPath(this.getDashboardPath(dossierId), rewriteOnly);
  }

  public getDashboardPath(dossierId: number): string {
    return Sitemap.dossierProspects.dashboard.path.replace(':id', `${dossierId}`);
  }

  public goToSimulation(dossierId: number, rewriteOnly = false): void {
    this.goToPath(this.getSimulationPath(dossierId), rewriteOnly);
  }

  public getSimulationPath(dossierId: number): string {
    return Sitemap.dossierProspects.dashboardSimulation.path.replace(':id', `${dossierId}`);
  }

  public goToPaternities(dossierId: number, rewriteOnly = false): void {
    this.goToPath(this.getPaternitiesPath(dossierId), rewriteOnly);
  }

  public getPaternitiesPath(dossierId: number): string {
    return Sitemap.dossierProspects.dashboardPaternities.path.replace(':id', `${dossierId}`);
  }

  public goToTransactions(dossierId: number, rewriteOnly = false): void {
    this.goToPath(this.getTransactionsPath(dossierId), rewriteOnly);
  }

  public getTransactionsPath(dossierId: number): string {
    return Sitemap.dossierProspects.dashboardTransactions.path.replace(':id', `${dossierId}`);
  }

  public goToLots(dossierId: number, rewriteOnly = false): void {
    this.goToPath(this.getLotsPath(dossierId), rewriteOnly);
  }

  public getLotsPath(dossierId: number): string {
    return Sitemap.dossierProspects.dashboardLots.path.replace(':id', `${dossierId}`);
  }

  public goToSearchPreferences(dossierId: number, rewriteOnly = false): void {
    this.goToPath(this.getSearchPreferencesPath(dossierId), rewriteOnly);
  }

  public getSearchPreferencesPath(dossierId: number): string {
    return Sitemap.dossierProspects.dashboardSearchPreferences.path.replace(':id', `${dossierId}`);
  }

  private getQueryParamForCityOrDepartment(location: any) {
    const matches = location.label.match(/([^)]+)\(([^)]+)\)/);
    if (!matches) return location.label;

    return matches[1].trim() + '|' + matches[2].trim();
  }

  private fillQueryparamWithLocations(queryParams: any, searchPreference: SummarySearchPreferenceResponse) {
    if (!searchPreference.value) return;

    const locc = [];
    const locd = [];
    const locr = [];
    for (const location of searchPreference.value) {
      switch (location.typeLocation) {
        case 'cities':
          locc.push(this.getQueryParamForCityOrDepartment(location));
          break;
        case 'departments':
          locd.push(this.getQueryParamForCityOrDepartment(location));
          break;
        case 'regions':
          locr.push(location.label);
          break;
        default:
          throw new Error('Unknown location type: ' + location.typeLocation);
      }
    }
    queryParams.locc = locc.join(',');
    queryParams.locd = locd.join(',');
    queryParams.locr = locr.join(',');
  }

  public goToSearchWithDossierSearchPreferences(dossier: CompleteDossierResponse, rewriteOnly = false): void {
    return this.goToSearchWithSearchPreferences(dossier.summarySearchPreferences, rewriteOnly);
  }

  public goToSearchWithSearchPreferences(summarySearchPreferences: SummarySearchPreferenceResponse[], rewriteOnly = false): void {
    const queryParams: any = {};
    queryParams.type = 'L'; // Display lot tab
    queryParams.order = 'price';
    queryParams.direction = 0; // DESC
    for (const searchPreference of summarySearchPreferences) {
      switch (searchPreference.searchCriteriaCode) {
        case SearchCriteria.PROGRAM_LOCATION:
          this.fillQueryparamWithLocations(queryParams, searchPreference);
          break;
        case SearchCriteria.LOT_MIN_PRICE:
          queryParams.bgtmi = this.getQueryParamValue(searchPreference.value);
          break;
        case SearchCriteria.LOT_MAX_PRICE:
          queryParams.bgtma = this.getQueryParamValue(searchPreference.value);
          break;
        case SearchCriteria.LOT_TAXATION:
          queryParams.tax = this.getQueryParamValue(searchPreference.value);
          break;
        case SearchCriteria.LOT_TAX_ZONE:
          queryParams.taxz = this.getQueryParamValue(searchPreference.value);
          break;
        case SearchCriteria.LOT_PROGRAM_TYPE:
          queryParams.ptid = this.getQueryParamValue(searchPreference.value);
          break;
        case SearchCriteria.LOT_ROOMS:
          queryParams.nbr = this.getQueryParamValue(searchPreference.value);
          break;
        case SearchCriteria.LOT_FLOORS:
          queryParams.nbf = this.getQueryParamValue(searchPreference.value);
          break;
        case SearchCriteria.LOT_AREA_TYPE:
          queryParams.atid = this.getQueryParamValue(searchPreference.value);
          break;
        case SearchCriteria.LOT_ORIENTATION:
          queryParams.loid = this.getQueryParamValue(searchPreference.value);
          break;
        case SearchCriteria.PARKING_SECONDARY_LOT:
          queryParams.psltid = this.getQueryParamValue(searchPreference.value);
          break;
        case SearchCriteria.STORAGE_SECONDARY_LOT:
          queryParams.ssltid = this.getQueryParamValue(searchPreference.value);
          break;
        case SearchCriteria.LOT_MIN_DELIVERY_DATE:
          queryParams.ddm = this.getQueryParamValue(searchPreference.value);
          break;
        case SearchCriteria.LOT_ACTABLE:
          queryParams.isac = this.getQueryParamValue(searchPreference.value);
          break;
        case SearchCriteria.PROGRAM_HAS_SPECIAL_OFFER:
          queryParams.so = this.getQueryParamValue(searchPreference.value);
          break;
        case SearchCriteria.PROGRAM_NEW:
          queryParams.new = this.getQueryParamValue(searchPreference.value);
          break;
      }
    }

    const extras: NavigationExtras = { queryParams };
    this.goToPath(Sitemap.utils.search.path, rewriteOnly, extras);
  }

  private getQueryParamValue(searchPreferenceValue: string[]): string {
    return searchPreferenceValue ? searchPreferenceValue.join(',') : '';
  }

  public goToEdit(dossierId: number, isFromDashboardProspect = false): void {
    const queryParams = isFromDashboardProspect ? { isFromDashboardProspect } : {};
    this.router.navigate([Sitemap.dossierProspects.edit.path.replace(':id', `${dossierId}`)], {
      queryParams,
    });
  }

  public goToList(): void {
    this.router.navigate([this.getListPath()]);
  }

  public getListPath(): string {
    return Sitemap.dossierProspects.list.path;
  }

  public goToCreate(): void {
    this.router.navigate([this.getCreatePath()]);
  }

  public getCreatePath(): string {
    return Sitemap.dossierProspects.create.path;
  }

  public goToSearch(searchParams?: NavigationExtras, rewriteOnly = false): void {
    const extras: NavigationExtras = searchParams ? searchParams : { queryParams: DEFAULT_SEARCH_PROGRAM_PARAMS };

    this.goToPath(Sitemap.utils.search.path, rewriteOnly, extras);
  }

  private goToPath(path: string, rewriteOnly = false, extras: NavigationExtras = undefined): void {
    if (rewriteOnly) {
      this.location.replaceState(path);
    } else {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      const currentUrl = this.router.url;
      this.router.navigateByUrl(currentUrl).then(() => {
        this.router.navigated = false;
        this.router.navigate([path], extras);
      });
    }
  }
}
