<div class="flex w-full gap-8 xl:gap-10 h-full text-grey-800 max-w-7xl mx-auto">
  <div class="flex flex-col w-[300px] w-min-[300px]">
    <app-box-back-button
      [label]="stepperParam.backButtonLabel || 'txt_back_to_signature_file'"
      class="mb-6"
      [goBack]="stepperParam.goBackFunction ? stepperParam.goBackFunction() : goBack()"
    >
    </app-box-back-button>
    <header
      class="header bg-white rounded-10 w-full shadow-custom"
      [ngClass]="{ 'sticky overflow-auto top-6': stepperParam.stickySideBar }"
    >
      <ng-container *ngFor="let step of steps; let i = index">
        <div
          (click)="selectedIndex = i"
          *ngIf="!step.head && !step.subHead"
          [ngClass]="{
            active: selectedIndex === i,
            'p-0': !step.field.props['expand'],
            'h-0': !step.field.props['expand'],
            'opacity-0': !step.field.props['expand'],
            'overflow-hidden': !step.field.props['expand'],
            'p-4': step.field.props['expand']
          }"
          class="cursor-pointer ml-4 transition-all"
        >
          <ng-container *ngIf="step.stepLabel" [ngTemplateOutlet]="step.stepLabel.template"></ng-container>
        </div>
        <div
          *ngIf="step.subHead"
          [ngClass]="{
            'p-0': !step.field.props['expand'],
            'h-0': !step.field.props['expand'],
            'opacity-0': !step.field.props['expand'],
            'overflow-hidden': !step.field.props['expand'],
            'overflow-hidden': !step.field.props['expand'],
            'p-4': step.field.props['expand']
          }"
          class="font-bold ml-4 transition-all opacity-100"
        >
          <ng-container *ngIf="step.stepLabel" [ngTemplateOutlet]="step.stepLabel.template"></ng-container>
        </div>
        <div
          (click)="toggleSubStep(step.fieldIndex)"
          *ngIf="step.head"
          class="rounded-xl stepColor p-4 m-2 text-xl font-bold cursor-pointer"
        >
          <ng-container *ngIf="step.stepLabel" [ngTemplateOutlet]="step.stepLabel.template"></ng-container>
        </div>
      </ng-container>
    </header>

    <div *ngIf="!this.stepperParam?.disabledRightSideBar && (screenSize === 'small' || screenSize === 'medium')" class="w-[300px] mt-8">
      <ng-template appStepContext myClass="sticky top-[40px] flex-col flex"></ng-template>
    </div>
  </div>

  <div class="flex flex-col flex-1">
    <app-step-head-title
      [showReload]="showReload"
      [showDuplicate]="showDuplicate"
      [title]="stepTitle"
      [showCustomAction]="showCustomAction"
      [customActionEvent]="stepperParam.customAction"
      [customActionTitle]="customActionTitle"
      [model]="model"
      [currentStep]="currentStep"
      class="bg-white rounded-10 mb-6 shadow-custom"
      [ngClass]="{
        'p-7': !showCustomAction,
        'p-5': showCustomAction
      }"
    ></app-step-head-title>
    <div
      [ngClass]="{
        'bg-white': !selectedStep?.field.props['transparentBg'],
        'p-8': !selectedStep?.field.props['transparentBg'],
        'shadow-custom': !selectedStep?.field.props['transparentBg']
      }"
      class="bg-white rounded-10 box-form"
    >
      <ng-container [ngTemplateOutlet]="selectedStep?.content"></ng-container>
    </div>
  </div>

  <div *ngIf="screenSize === 'large' && !this.stepperParam?.disabledRightSideBar" class="w-[300px] w-min-[300px]">
    <ng-template appStepContext myClass="sticky top-[40px] flex-col flex"></ng-template>
  </div>
</div>
