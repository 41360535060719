<div id="initFormCompany" class="flex flex-grow flex-row flex-wrap">
  <div class="bg-init-form-company hidden lg:flex w-full xl:w-1/2"></div>

  <div class="flex flex-col w-full xl:w-1/2 g:py-6 py-5">
    <div class="text-2xl font-bold mb-5 lg:px-6 px-5">
      {{ i18nService._('Txt_Page_Form_InitCompany_Info') }}
    </div>

    <div class="text-base font-bold mb-3 lg:px-6 px-5">
      {{ i18nService._('Txt_Page_Form_InitCompany_Cat') }}
    </div>

    <mat-radio-group [(ngModel)]="selectedCategory" class="flex lg:px-6 px-5">
      <mat-radio-button
        class="text-base"
        color="primary"
        *ngFor="let category of categories"
        [attr.data-cy]="category.value === 'promoteur' ? 'developer' : 'contractor'"
        [value]="category.value"
      >
        {{ category.label }}
      </mat-radio-button>
    </mat-radio-group>

    <ng-container *ngIf="selectedCategory">
      <div class="text-base font-bold mt-5 mb-4 lg:px-6 px-5">
        {{ i18nService._('Txt_Page_Form_InitCompany_MustHave') }}
      </div>

      <div class="lg:px-6 px-5">
        <mat-list *ngIf="selectedCategory === 'promoteur'; then blockDoc1; else blockDoc2"></mat-list>

        <ng-template #blockDoc1>
          <mat-list-item class="text-base block mb-4" *ngFor="let document of documentsDev">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">{{ document }}</p>
            </div>
          </mat-list-item>
        </ng-template>

        <ng-template #blockDoc2>
          <mat-list-item class="text-base block mb-4" *ngFor="let document of documentsPres">
            <div class="flex">
              <span class="text-xxs pt-1 px-2 pb-2 float-left icon-circle"></span>
              <p class="mt-0">{{ document }}</p>
            </div>
          </mat-list-item>
        </ng-template>

        <div class="flex mt-xl-6 mt-5 mb-4">
          <button mat-stroked-button data-cy="goBack" color="primary" class="btn-small mr-auto" (click)="goBack()">
            <span class="icon-arrow-left lg:pr-2"></span>
            <span>{{ i18nService._('Txt_Button_GoBack') }}</span>
          </button>

          <a class="" [routerLink]="[companiesRegisterPath, selectedCategory]">
            <button mat-flat-button color="primary" data-cy="nextStep">
              <span>{{ i18nService._('Txt_Button_NextStep') }}</span>
              <span class="icon-arrow-right lg:pl-2"></span>
            </button>
          </a>
        </div>
      </div>
    </ng-container>
  </div>
</div>
