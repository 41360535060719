export const DEFAULT_CHECK_BOX_SELECTABLE_DEF = {
  headerCheckboxSelection: true,
  checkboxSelection: true,
  showDisabledCheckboxes: true,
  headerCheckboxSelectionFilteredOnly: true,
  cellStyle: { 'margin-left': '4px' },
  minWidth: 50,
  maxWidth: 50,
  flex: 1,
};
