import { TransactionStatus } from './transaction-status.enum';
import { TransactionType } from './transaction-type.enum';

export class TransactionResponse {
  id: number;
  lotId: number;
  lotNumber: string;
  companyName: string;
  programName: string;
  programThumbnailPath: string;
  city: string;
  departementCode: string;
  rooms: number;
  livingSpace: number;
  transactionType: TransactionType;
  status: TransactionStatus;
  price: number;
  transactionStartDate: Date;
  optionEndDate: Date;
  lastStatusChangedDate: Date;
  possibleActions: TransactionAction;
  valoReservationValidationDate: Date;
  dossierSignatureId: number;
  ownerUserId: number;
  dateStartLibelle: string;
  dateEndLibelle: string;
  endDateToDisplay: Date;
}

export class TransactionAction {
  canExtend: boolean;
  canRemove: boolean;
  canPreReserve: boolean;
  canDownloadContractualBundle: boolean;
  canOnlineSign: boolean;
  canContinueOnlineSign: boolean;
  canOnlineSignValo: boolean;
  canContinueOnlineSignValo: boolean;
}
