import { TemplateRef } from '@angular/core';

export class ProgramLotTableDisplayData {
  id: number;
  number: string;
  typo: string;
  rooms: number;
  livingSpace: string;
  commercialLotSellingPriceIT: string;
  reducedTotalSellingPriceIT: string;
  profitabilityET: string;
  status: string;
  isPublished: string;
  deliveryDate: string;
  floor: number;
  actions: TemplateRef<unknown>;
  isComplete: { background?: string; class?: string; label?: string };
  taxations: string;
}

export class ProgramTrackingTableData {
  id: number;
  lotsPublishedOnLotsStock: string;
  deliveryDate: string;
  optionedLots: number;
  programName: string;
  cityPostalCode: string;
  actable: number;
  isFromFlux: number;
  companyTradingName: string;
  programStatusLabel: string;
  programStatusId: number;
  unpublicationOriginLabel: string;
}
