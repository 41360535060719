import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { isNil } from 'lodash';

import { DataLayerApiService } from './data-layer-api.service';
import { OptionParamRequest } from './models/option-param-request';
import { OptionsTrackingTableServiceResponse } from '../tracking-tables/models/OptionsTrackingTableServiceResponse';
import { OptionForDossierProspectDashboard } from '../utils/models/OptionResponse';

@Injectable({ providedIn: 'root' })
export class OptionApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Get options by params
   *
   * @returns {Observable<OptionsTrackingTableServiceResponse>}
   * @memberof DataLayerApiService
   */
  async getOptions(params: OptionParamRequest): Promise<OptionsTrackingTableServiceResponse> {
    const path = this.BASE_URL + 'Options';
    return await lastValueFrom(this.getTableRessources(params, path));
  }

  getOptionsWithOptionLotPriceByDossierProspectId(dossierProspectId: number): Promise<OptionForDossierProspectDashboard[]> {
    const path = `${this.BASE_URL}Options`;
    let queryParams: HttpParams = new HttpParams();
    queryParams = queryParams.set('dossierProspectId', dossierProspectId.toString());
    queryParams = queryParams.set(
      'cols',
      'lotId,isActive,companyId,startDate,endDate,optionStatusId,lot.lotNumber,lot.programId,lot.program.programName,lot.program.companyId,lot.program.company.corporateName,lot.program.city,lot.program.postalCode',
    );
    return lastValueFrom(this.get(path, queryParams));
  }

  protected getQueryParams(paramsRequest: OptionParamRequest): HttpParams {
    let params = super.getQueryParams(paramsRequest);
    if (!isNil(paramsRequest.dossierProspectId)) {
      params = params.set('dossierProspectId', paramsRequest.dossierProspectId);
    }
    return params;
  }
}
