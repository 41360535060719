export enum AdvancedTrackingTableColumnType {
  Number = 'number',
  Currency = 'currency',
  Date = 'date',
  I18nToken = 'i18nToken',
  Boolean = 'boolean',
  TitleCase = 'titleCase',
  Actions = 'actions',
  Percentage = 'percentage',
  Link = 'link',
  Surface = 'surface',
  CurrencyCeil = 'currencyCeil',
  Custom = 'custom',
  selfSearch = 'selfSearch',
}
