import { Directive, inject, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { TagsInterface } from '../../../../design-system/model/tag-option';
import { LotService } from '../../../../lot/service/lot.service';
import { PageLotService } from '../../../../lot/service/page-lot.service';
import { LotDetailResponse } from '../../../../utils/models/LotDetailResponse';
import { LotFormatDetailResponse } from '../../../../utils/models/LotFormatDetailResponse';
import { TokenService } from '../../../../utils/services/authorisation/token.service';
import { AppConfigService } from '../../../../utils/services/app-config.service';
import { ProgramService } from '../../../services/program.service';

@Directive()
export abstract class AbstractProgramPageLot implements OnInit, OnDestroy {
  public lot: LotDetailResponse;
  public formattedLot: LotFormatDetailResponse;
  protected readonly $d = new Subject();
  protected readonly _pageLotService: PageLotService;
  protected readonly _lotService: LotService;
  protected isAuthorizedToManageOption: boolean;
  protected isAuthorizedToManageOnlyReservation: boolean;
  private readonly _tokenService: TokenService;
  private accounts: string[];
  private authorizedAccountsOnlyForReservation: string[];
  protected readonly _appConfigService: AppConfigService;
  protected readonly programService: ProgramService;

  constructor() {
    this._appConfigService = inject(AppConfigService);
    this.programService = inject(ProgramService);
    this._pageLotService = inject(PageLotService);
    this._tokenService = inject(TokenService);
    this._lotService = inject(LotService);
  }

  get lotFiscalityTags(): TagsInterface[] {
    return this._pageLotService.getLotFiscalityTags(this.formattedLot);
  }

  get lotTags(): TagsInterface[] {
    return this._pageLotService.getLotTags(this.formattedLot);
  }

  get annexeTags(): TagsInterface[] {
    return this._pageLotService.getAnnexeTags(this.formattedLot);
  }

  ngOnInit() {
    this.accounts = [
      this._appConfigService.appConfig.roles.valoMarketerSuper,
      this._appConfigService.appConfig.roles.valoMarketerSimple,
      this._appConfigService.appConfig.roles.valoBoSuper,
      this._appConfigService.appConfig.roles.valoBoSimple,
    ];

    this.authorizedAccountsOnlyForReservation = [
      this._appConfigService.appConfig.roles.valoDevSuper,
      this._appConfigService.appConfig.roles.valoDevSimple,
    ];

    this.isAuthorizedToManageOption = this._tokenService.checkIsLoggedIn() && !this.accounts.includes(this._tokenService.getRoleName());
    this.isAuthorizedToManageOnlyReservation =
      this._tokenService.checkIsLoggedIn() && this.authorizedAccountsOnlyForReservation.includes(this._tokenService.getRoleName());
    this.programService.selectedLotDetail.pipe(takeUntil(this.$d)).subscribe((lotDetailResponse: LotDetailResponse) => {
      if (lotDetailResponse) {
        this.lot = lotDetailResponse;
        this.formattedLot = this._pageLotService.separateLotDetails(this.lot);
        this.initData(this.lot, this.formattedLot);
      } else {
        this.initData();
      }
    });
  }

  ngOnDestroy(): void {
    this.$d.next(true);
    this.$d.complete();
  }

  abstract initData(lot?: LotDetailResponse, formattedLot?: LotFormatDetailResponse);
}
