import { Subject } from 'rxjs';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { AutoCompleteModule } from 'primeng/autocomplete';
import {
  CompleteDossierResponse,
  DossierSearchPreferenceResponseDto,
  SearchCriteria,
  SummarySearchPreferenceResponse,
} from '@commons-dto/dossier-prospect';
import { SecondaryLotTypeEnum } from '@commons-dto/valo-back';
import { isNil } from 'lodash';

import { I18nService } from '../../../utils/services/i18n.service';
import { ChoiceItem } from '../../../common/formly/formly-chipList/formly-chip-list.component';
import { TaxationService } from '../../../taxation/taxation.service';
import { TaxZoneService } from '../../../tax-zone/tax-zone.service';
import { TaxationResponse } from '../../../utils/models/TaxationResponse';
import { TaxZoneResponse } from '../../../utils/models/TaxZoneResponse';
import { ProgramTypeService } from '../../../program-type/program-type.service';
import { ProgramTypeResponse } from '../../../utils/models/ProgramTypeResponse';
import { AreaTypeService } from '../../../area-type/area-type.service';
import { AreaTypeResponse } from '../../../utils/models/AreaTypeResponse';
import { SecondaryLotTypeService } from '../../../secondary-lot-type/secondary-lot-type.service';
import { LotOrientationService } from '../../../lot-orientation/lot-orientation.service';
import { LotOrientationResponse } from '../../../utils/models/LotOrientationResponse';
import { DossierSearchPreferenceService } from '../../../dossier-search-preference/dossier-search-preference.service';
import { DeliveryDateService } from '../../../delivery-date/delivery-date.service';

@Component({
  selector: 'app-search-preference',
  standalone: true,
  templateUrl: './search-preference.component.html',
  styleUrls: ['./search-preference.component.scss'],
  imports: [CommonModule, FormlyModule, AutoCompleteModule],
})
export class SearchPreferenceComponent implements OnInit, OnDestroy {
  private $destroy: Subject<void> = new Subject<void>();

  fields: FormlyFieldConfig[];
  form = new UntypedFormGroup({});
  model: { [key in SearchCriteria]?: any };
  options: FormlyFormOptions = {};
  private currencyPipe = new CurrencyPipe('fr-FR');

  private _dossier: CompleteDossierResponse;

  @Input()
  get dossier(): CompleteDossierResponse {
    return this._dossier;
  }

  set dossier(value: CompleteDossierResponse) {
    if (value.id === this._dossier?.id) return;
    if (value != null) {
      this._dossier = value;
      this.initModel();
      this.initForm();
    }
  }

  @Input() searchPreferencesUpdated: Subject<boolean>;
  @Output() searchPreferencesUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() simulationUpdated: Subject<void>;

  constructor(
    protected readonly i18nService: I18nService,
    private readonly taxationService: TaxationService,
    private readonly taxZoneService: TaxZoneService,
    private readonly programTypeService: ProgramTypeService,
    private readonly areaTypeService: AreaTypeService,
    private readonly secondaryLotTypeService: SecondaryLotTypeService,
    private readonly lotOrientationService: LotOrientationService,
    private readonly dossierSearchPreferenceService: DossierSearchPreferenceService,
    private readonly deliveryDateService: DeliveryDateService,
  ) {}

  ngOnInit() {
    this.searchPreferencesUpdated.subscribe((external: boolean) => {
      if (external) this.initModel();
    });
    this.simulationUpdated.subscribe(() => {
      this.initForm();
    });
  }

  async initModel() {
    const model = {};
    if (!isNil(this.dossier.id)) {
      const dossierSearchPreferences = await this.dossierSearchPreferenceService.getByDossierId(this.dossier.id);
      for (const dossierSearchPreference of dossierSearchPreferences) {
        model[dossierSearchPreference.searchCriteriaCode] = dossierSearchPreference.value;
      }
    }
    this.model = model;
  }

  async initForm() {
    this.fields = await this.getForm();
  }

  getLocalisationForm(): any {
    return {
      fieldGroup: [
        {
          type: 'text',
          props: {
            label: this.i18nService._('SearchPreference_Localisation'),
            titleLevel: 5,
          },
          className: 'input-group-title',
        },
        {
          key: SearchCriteria.PROGRAM_LOCATION,
          type: 'localisation-city-area',
          props: {
            passObject: true,
            forceChoice: true,
            placeholder: this.i18nService._('SearchPreference_Area_Or_City'),
            dropdown: true,
            showClear: false,
          },
          className: 'input-item',
        },
      ],
    };
  }

  async getPriceAndTaxationForm(): Promise<any> {
    return {
      fieldGroup: [
        {
          type: 'text',
          props: {
            label: this.i18nService._('SearchPreference_Price_Taxation'),
            titleLevel: 5,
          },
          className: 'input-group-title',
        },
        {
          key: SearchCriteria.LOT_MIN_PRICE,
          type: 'number',
          props: {
            label: this.i18nService._('LotMinPricePlace'),
            placeholder: this.i18nService._('LotMinPricePlaceHolder'),
            suffix: ' €',
          },
          className: 'input-item',
        },
        {
          key: SearchCriteria.LOT_MAX_PRICE,
          type: 'number',
          props: {
            label: this.i18nService._('LotMaxPricePlace'),
            placeholder: this.i18nService._('LotMaxPricePlaceHolder'),
            suffix: ' €',
          },
          className: 'input-item',
        },
        {
          type: 'info-box',
          props: {
            label:
              this.i18nService._('Purchasing_capacity_from_simulation') +
              (this.dossier.summarySimulations
                ? this.currencyPipe.transform(this.dossier.summarySimulations[0].purchasingCapacity, 'EUR', 'symbol', '1.0-2')
                : ''),
          },
          expressions: {
            hide: () => {
              return !this.dossier?.summarySimulations[0]?.purchasingCapacity;
            },
          },
          className: 'input-item',
        },
        {
          key: SearchCriteria.LOT_TAXATION,
          type: 'chip-list',
          props: {
            label: this.i18nService._('LotTaxation'),
            choices: await this.getTaxations(),
            multiple: false,
          },
          className: 'input-item',
        },
        {
          key: SearchCriteria.LOT_TAX_ZONE,
          type: 'chip-list',
          props: {
            label: this.i18nService._('LotTaxArea'),
            choices: await this.getTaxArea(),
            multiple: false,
          },
          className: 'input-item',
        },
      ],
    };
  }

  async getLotForm(): Promise<any> {
    return {
      fieldGroup: [
        {
          type: 'text',
          props: {
            label: this.i18nService._('SearchPreference_Lot'),
            titleLevel: 5,
          },
          className: 'input-group-title',
        },
        {
          key: SearchCriteria.LOT_PROGRAM_TYPE,
          type: 'chip-list',
          props: {
            label: this.i18nService._('LotProgramType'),
            choices: await this.getLotProgramType(),
          },
          className: 'input-item',
        },
        {
          key: SearchCriteria.LOT_ROOMS,
          type: 'chip-list',
          props: {
            label: this.i18nService._('LotRooms'),
            choices: this.getLotRooms(),
            allChoice: true,
          },
          className: 'input-item',
        },
        {
          key: SearchCriteria.LOT_FLOORS,
          type: 'chip-list',
          props: {
            label: this.i18nService._('LotFloors'),
            choices: this.getLotFloors(),
            allChoice: true,
          },
          className: 'input-item',
        },
        {
          key: SearchCriteria.LOT_AREA_TYPE,
          type: 'chip-list',
          props: {
            label: this.i18nService._('LotAreaType'),
            choices: await this.getLotAreaType(),
          },
          className: 'input-item',
        },
        {
          key: SearchCriteria.LOT_ORIENTATION,
          type: 'chip-list',
          props: {
            label: this.i18nService._('LotOrientation'),
            choices: await this.getLotOrientation(),
          },
          className: 'input-item',
        },
        {
          key: SearchCriteria.PARKING_SECONDARY_LOT,
          type: 'chip-list',
          props: {
            label: this.i18nService._('Title_Form_SearchForm_Parking_Annexes'),
            choices: await this.secondaryLotTypeService.getSecondaryLotTypesByAnnexeType(SecondaryLotTypeEnum.PARKING),
          },
          className: 'input-item',
        },
        {
          key: SearchCriteria.STORAGE_SECONDARY_LOT,
          type: 'chip-list',
          props: {
            label: this.i18nService._('Title_Form_SearchForm_Storage_Annexes'),
            choices: await this.secondaryLotTypeService.getSecondaryLotTypesByAnnexeType(SecondaryLotTypeEnum.STORAGE),
          },
          className: 'input-item',
        },
      ],
    };
  }

  getOtherCriterias(): any {
    return {
      fieldGroup: [
        {
          type: 'text',
          props: {
            label: this.i18nService._('SearchPreference_Lot'),
            titleLevel: 5,
          },
          className: 'input-group-title',
        },
        {
          key: SearchCriteria.LOT_MIN_DELIVERY_DATE,
          type: 'chip-list',
          props: {
            label: this.i18nService._('DeliveryDate'),
            choices: this.getMinDeliveryDate(),
            multiple: false,
          },
          className: 'input-item',
        },
        {
          key: SearchCriteria.LOT_ACTABLE,
          type: 'chip-list',
          props: {
            label: this.i18nService._('Txt_Checkbox_Actable'),
            choices: [{ id: 1, label: 'Txt_Checkbox_Actable' }],
          },
          className: 'input-item',
        },
        {
          key: SearchCriteria.PROGRAM_HAS_SPECIAL_OFFER,
          type: 'chip-list',
          props: {
            label: this.i18nService._('Txt_search_filter_tags_title'),
            choices: [{ id: 1, label: 'Txt_Special_Offer' }],
          },
          className: 'input-item input-item-fold',
        },
        {
          key: SearchCriteria.PROGRAM_NEW,
          type: 'chip-list',
          props: {
            choices: [{ id: 1, label: 'Title_Form_SearchForm_New' }],
          },
          className: 'input-item',
        },
      ],
    };
  }

  getMinDeliveryDate(): ChoiceItem[] {
    return this.deliveryDateService.getDeliveryDates();
  }

  async getLotOrientation(): Promise<ChoiceItem[]> {
    const lotOrientations: LotOrientationResponse[] = await this.lotOrientationService.getLotOrientations();
    return lotOrientations.map((lo) => ({ id: lo.id, label: lo.label }));
  }

  async getLotAreaType(): Promise<ChoiceItem[]> {
    const areaTypes: AreaTypeResponse[] = await this.areaTypeService.getAreaTypes();
    return areaTypes.sort((t1, t2) => t1.priority - t2.priority).map((lat) => ({ id: lat.id, label: lat.label }));
  }

  getLotFloors(): ChoiceItem[] {
    return [
      { id: 1, label: 'RDC' },
      { id: 2, label: '2' },
      { id: 3, label: '3' },
      { id: 4, label: '4' },
      { id: 'GT5', label: '+5' },
    ];
  }

  getLotRooms(): ChoiceItem[] {
    return [
      { id: 1, label: '1' },
      { id: 2, label: '2' },
      { id: 3, label: '3' },
      { id: 4, label: '4' },
      { id: 'GT5', label: '+5' },
    ];
  }

  async getLotProgramType(): Promise<ChoiceItem[]> {
    const programTypes: ProgramTypeResponse[] = await this.programTypeService.getProgramTypes();
    const hIcons = {
      HOUSE: 'HomeModernSolid',
      APARTMENT: 'BuildingOffice2Solid',
      TRADE: 'BuildingStorefrontSolid',
    };
    return programTypes
      .filter((t) => t.lotType)
      .sort((t1, t2) => t1.priority - t2.priority)
      .map((tax) => ({ id: tax.id, label: tax.label, icon: hIcons[tax.label] }));
  }

  async getTaxations(): Promise<ChoiceItem[]> {
    const taxations: TaxationResponse[] = await this.taxationService.getTaxations();
    return taxations.sort((t1, t2) => t1.position - t2.position).map((tax) => ({ id: tax.id, label: tax.label }));
  }

  async getTaxArea(): Promise<ChoiceItem[]> {
    const taxZones: TaxZoneResponse[] = await this.taxZoneService.getTaxZones();
    return taxZones.map((ta) => ({ id: ta.id, label: ta.label }));
  }

  async getForm(): Promise<FormlyFieldConfig[]> {
    const [priceAndTaxationForm, lotForm] = await Promise.all([this.getPriceAndTaxationForm(), this.getLotForm()]);
    return [this.getLocalisationForm(), priceAndTaxationForm, lotForm, this.getOtherCriterias()];
  }

  async onChange() {
    const dossierSearchPreferences: DossierSearchPreferenceResponseDto[] = [];
    for (const key in this.model) {
      const value = this.getCriteriaValue(key as SearchCriteria);
      if (value !== null && value !== undefined) {
        dossierSearchPreferences.push({ searchCriteriaCode: key as SearchCriteria, value: value });
      }
    }
    await this.dossierSearchPreferenceService.saveByDossierId(this._dossier.id, dossierSearchPreferences);

    // Update summarySearchPreferences from dossier
    this.dossier.summarySearchPreferences = [];
    for (const dossierSearchPreference of dossierSearchPreferences) {
      const summarySearchPreference = new SummarySearchPreferenceResponse();
      summarySearchPreference.searchCriteriaCode = dossierSearchPreference.searchCriteriaCode;
      summarySearchPreference.value = Array.isArray(dossierSearchPreference.value)
        ? dossierSearchPreference.value
        : [dossierSearchPreference.value];
      this.dossier.summarySearchPreferences.push(summarySearchPreference);
    }

    this.searchPreferencesUpdate.emit(false);
  }

  getCriteriaValue(key: SearchCriteria): any[] | any | string | number {
    let value: any[] | string | number;
    const val = this.model[key];
    if (Array.isArray(val)) {
      value = [];
      for (const v of val) {
        if (!v) continue;
        if (v.id !== null && v.id !== undefined) {
          value.push(v.id);
        } else {
          value.push(v);
        }
      }
      if (value.length == 0) return null;
    } else {
      value = val;
    }
    return value;
  }

  ngOnDestroy() {
    this.$destroy.next();
  }
}
