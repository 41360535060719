import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { isNil } from 'lodash';

import { DataLayerApiService } from './data-layer-api.service';
import { LotResponse } from '../utils/models/LotResponse';
import { LotsResponse } from './models/lot/lots.response';
import { LotParamRequest } from './models/lot/lot-request';

@Injectable({ providedIn: 'root' })
export class LotApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Get lots by params
   *
   * @returns {Observable<LotsResponse>}
   * @memberof DataLayerApiService
   */
  getLots(params: LotParamRequest): Observable<LotsResponse> {
    const path = this.BASE_URL + 'Lots';
    return this.getTableRessources(params, path);
  }

  /**+
   * Get lot by id
   *
   * @returns {Observable<LotResponse>}
   * @memberof DataLayerApiService
   */
  getById(id: number): Observable<LotResponse> {
    const path = this.BASE_URL + 'Lots/' + id;
    return this.get(path);
  }

  protected getQueryParams(paramsRequest: LotParamRequest): HttpParams {
    let params = super.getQueryParams(paramsRequest);
    if (!isNil(paramsRequest.programId)) {
      params = params.set('programId', paramsRequest.programId);
    }
    if (!isNil(paramsRequest.lotNumber)) {
      params = params.set('lotNumber', paramsRequest.lotNumber);
    }
    if (!isNil(paramsRequest.availableOnly)) {
      params = params.set('availableOnly', paramsRequest.availableOnly);
    }
    return params;
  }
}
