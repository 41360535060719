import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AbstractSearchFilter } from '../abstract-search-filter';

import { I18nService } from '../../../../utils/services/i18n.service';
import { AccountService } from '../../../../utils/services/account.service';
import { SnackbarService } from '../../../../utils/services/snackbar.service';
import { CompanyService } from '../../../../companies/services/company.service';
import { ValidateDeveloperCompanyName } from '../../../../companies/models/validate-developer-company-name';

@Component({
  selector: 'app-search-developer-company',
  templateUrl: './search-filter-developer-company.component.html',
  styleUrls: ['./search-filter-developer-company.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, MatAutocompleteModule, ReactiveFormsModule, NgFor, MatOptionModule],
})
export class SearchFilterDeveloperCompanyComponent extends AbstractSearchFilter {
  companyList: Array<ValidateDeveloperCompanyName> = [];
  companyListFiltered: Array<ValidateDeveloperCompanyName>;
  public companyFormControl: UntypedFormControl = new UntypedFormControl();

  constructor(
    public i18nService: I18nService,
    private readonly accountService: AccountService,
    private readonly _companyService: CompanyService,
    private readonly snackbarService: SnackbarService,
    private readonly _fb: UntypedFormBuilder,
  ) {
    super();
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    super.ngOnInit();
    this.initData();
    this.companyFormControl.valueChanges.pipe(takeUntil(this.$d)).subscribe((company: ValidateDeveloperCompanyName) => {
      this.setSelectedCompany(company);
    });
  }

  displayFn(value?: ValidateDeveloperCompanyName): string {
    return value ? value.corporateName : undefined;
  }

  setSelectedCompany(company: ValidateDeveloperCompanyName) {
    this.search.developerCompany = company.id;
    this._searchFormUtilsService.setSearchFilter({ where: this.search }, this.waitForSearch);
  }

  onInputCompanyChange(event): void {
    this.companyListFiltered = CompanyService.filterCompany(event.currentTarget.value, this.companyList);
  }

  protected initData() {
    this._companyService.getValidateDeveloperCompanies().subscribe(
      (companyList) => {
        this.companyList = companyList;

        this._initCompanyForm(this.companyList);
      },
      () => {
        this.snackbarService.sendErrorOccured();
      },
    );
  }

  private _initCompanyForm(companyList) {
    if (this.search && this.search.developerCompany) {
      const validateDeveloperCompanyName = new ValidateDeveloperCompanyName();
      validateDeveloperCompanyName.id = this.search.developerCompany;
      const validateDeveloperCompanyNameFiltered = CompanyService.filterCompany(validateDeveloperCompanyName, companyList);
      if (validateDeveloperCompanyNameFiltered && validateDeveloperCompanyNameFiltered.length === 1) {
        const company = validateDeveloperCompanyNameFiltered[0].corporateName;
        this.companyFormControl.setValue({ id: this.search.developerCompany, corporateName: company });
      }
    }
  }
}
