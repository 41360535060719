import { Component } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { Sitemap } from '../../../utils/models/Sitemap';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { AccountService } from '../../../utils/services/account.service';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { RecaptchaService } from '../../../utils/services/recaptcha.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { isEmailValidators } from '../../../utils/validators/is-email-validator.directive';
import { RecaptchaComponent } from '../../../utils/components/recaptcha/recaptcha.component';

@Component({
  selector: 'app-reinit-password-dialog',
  templateUrl: './reinit-password-dialog.component.html',
  styleUrls: ['./reinit-password-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
    RecaptchaComponent,
  ],
})
export class ReinitPasswordDialogComponent {
  public emailForm: UntypedFormGroup;
  public emailControl: AbstractControl;

  constructor(
    public i18nService: I18nService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly accountService: AccountService,
    private readonly snackbarService: SnackbarService,
    private readonly router: Router,
    public dialogRef: MatDialogRef<ReinitPasswordDialogComponent>,
    private readonly reCaptchaService: RecaptchaService,
    private readonly appConfigService: AppConfigService,
  ) {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, isEmailValidators()]],
    });

    this.emailControl = this.emailForm.controls.email;
  }

  public isSubmitDisable(): boolean | ValidationErrors {
    return this.emailControl.errors;
  }

  public async submit(): Promise<void> {
    let reCAPTCHAToken;
    try {
      reCAPTCHAToken = await this.reCaptchaService.getToken();
    } catch (error) {
      const loginMessageError: SnackbarMessage = {
        text: this.i18nService._('Error_SnackBar_RecaptchaLoading'),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(loginMessageError);
      throw error;
    }
    this.accountService.sendMailForPasswordInitialisation(this.emailForm.value.email, reCAPTCHAToken).subscribe(
      () => {
        this.displaySuccessSpanAndCloseDialog();
      },
      () => {
        this.router.navigate([Sitemap.utils.login.path]);
      },
    );
  }

  private displaySuccessSpanAndCloseDialog(): void {
    this.dialogRef.close();
    const message: SnackbarMessage = {
      text: this.i18nService._('Txt_Popin_Form_ResetPassword_EmailSent', [this.emailForm.value.email]),
      type: SnackbarMessageType.Info,
    };
    this.snackbarService.sendMessage(message);
  }
}
