<div class="grid grid-cols-2 gap-6 w-full">
  <div class="flex flex-col relative">
    <div class="mb-2">{{ field.props.postalCode.label }}<small *ngIf="field.props.displayOptionalLabel"> (facultatif)</small></div>
    <input
      [attr.type]="field.props.type"
      [formControl]="$any(localizationForm.get('postalCode'))"
      [required]="field.props.postalCode.required"
      [readonly]="field.props.postalCode.readonly"
      pInputText
    />

    <small *ngIf="localizationForm.get('postalCode').dirty && localizationForm.get('postalCode').invalid" class="p-error">
      {{ getErrorMessage($any(localizationForm.get('postalCode'))) }}
    </small>
  </div>
  <div class="flex flex-col relative">
    <div class="mb-2">{{ field?.props?.city.label }}<small *ngIf="field.props.displayOptionalLabel"> (facultatif)</small></div>
    <p-dropdown
      (onChange)="setFormlyFormValue()"
      [dropdownIcon]="'material-symbols-outlined arrow-drop-down'"
      [formControl]="$any(localizationForm.get('city'))"
      [options]="cityList"
      [placeholder]="' '"
      [required]="field.props.city.required"
      [readonly]="field.props.city.readonly"
      [showClear]="true"
      class="border-transparent rounded-10"
      optionLabel="label"
      optionValue="label"
    >
    </p-dropdown>
    <small *ngIf="localizationForm.get('city').dirty && localizationForm.get('city').invalid" class="p-error">
      {{ getErrorMessage($any(localizationForm.get('city'))) }}
    </small>
  </div>
</div>
