<div *ngIf="initialPrice" class="side-panel__info w-full bg-white p-8 shadow-custom rounded-10">
  <div class="mb-1 font-bold">
    {{ i18nService._('txt_signature_form_context_lot_price_initial') }}
  </div>
  <div class="my-1 text-xs">
    {{ i18nService._(taxation | i18nTVALabelPourcentage) }}
    {{ i18nService._('txt_signature_form_context_lot_price_initial_sub') }}
  </div>
  <div class="mt-1 text-xl font-bold">
    {{ initialPrice | currency: 'EUR' : 'symbol' : '1.2-2' }}
  </div>

  <hr class="my-6 border border-gray-400" />

  <div class="mb-1 font-bold">
    {{ i18nService._('txt_signature_form_context_lot_price_final') }}
  </div>
  <div class="my-1 text-xs">
    {{ i18nService._(taxation | i18nTVALabelPourcentage) }}
    {{ i18nService._('txt_signature_form_context_lot_price_initial_sub') }}
  </div>
  <div class="mt-1 text-xl font-bold text-orange-500">
    {{ finalPrice | currency: 'EUR' : 'symbol' : '1.2-2' }}
  </div>
</div>
