import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

import { AppIconsName } from '../../standalone/icon/utils/app-icon-names';
import { IconComponent } from '../../standalone/icon/icon.component';

@Component({
  selector: 'app-sider',
  templateUrl: './sider.component.html',
  styleUrls: ['./sider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, IconComponent],
})
export class SiderComponent {
  @Input() icon: AppIconsName;
}
