<div class="flex flex-col">
  <mat-card-title class="flex flex-row ml-4 text-base mx-auto mb-3">
    {{ translate('Title_Reservation_VendorInformation') }}
  </mat-card-title>

  <mat-card-content class="flex flex-col lg:flex-row xl:flex-col my-auto">
    <div class="grid grid-cols-3 gap-2">
      <div class="col-span-1 text-center break-all">
        <div class="inline-block mb-2">
          <span class="capitalize">
            {{ translate(reservationInformations.account.civility.abbreviation) }}
          </span>
          <span class="uppercase">
            {{ reservationInformations.account.lastName }}
          </span>
          <span class="capitalize">
            {{ reservationInformations.account.firstName }}
          </span>
        </div>
        <div class="inline-block">
          {{ reservationInformations.account.company.tradingName }}
        </div>
      </div>
      <div class="col-span-1 text-center break-all">
        <div class="inline-block mb-2">
          {{ reservationInformations.account.email }}
        </div>
        <div *ngIf="reservationInformations.account.mobilePhone" class="inline-block mb-2">
          {{ reservationInformations.account.mobilePhone }}
        </div>
        <div *ngIf="reservationInformations.account.deskPhone" class="block mb-2">
          {{ reservationInformations.account.deskPhone }}
        </div>
      </div>
      <div class="col-span-1 text-center break-all">
        <div class="inline-block mb-2">
          {{ reservationInformations.account.city }} -
          {{ reservationInformations.account.postalCode }}
        </div>
        <div class="block mb-2">
          <span *ngIf="reservationInformations.account.country">
            {{ translate(reservationInformations.account.country.label) }}
          </span>
        </div>
      </div>
    </div>
  </mat-card-content>
</div>
