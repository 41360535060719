import { DossierResponse } from '@commons-dto/dossier-prospect';

import { AccountResponse } from './AccountResponse';
import { DocumentResponse } from './DocumentResponse';
import { LotResponse } from './LotResponse';
import { ProspectResponse } from './ProspectResponse';
import { FileFormat } from './FileFormat';

export class ReservationCreate {
  lotId: number;
  sellerAccountId?: number;
  prospectId?: number;
  dossierProspectId?: number;
  reservationMotiveId: number;
  comments?: string;
  estimatedAppointmentDate?: Date;
  estimatedSaleDate?: Date;
  supportingDocument?: FileFormat;
  supportingDocumentId?: number;
  reservationStatusId?: number;
  reservationStatus?: {
    id: number;
    label: string;
    reservationPhaseId: number;
    reservationPhase?: {
      id: number;
      label: string;
    };
  };
  lot?: LotResponse;
  account?: AccountResponse;
  globalSellingPrice?: number;
  contractorFees?: number;
  prospect?: ProspectResponse;
  dossierProspect?: DossierResponse;
  valoReminderDate?: Date;
  valoComments?: string;
  reservationDocumentId?: number;
  document?: DocumentResponse;
  docs?: { [key: string]: DocumentResponse };
  logoDeveloperCompanyDocument?: DocumentResponse;
  advValoId?: number;
  advLastName?: string;
  advFirstName?: string;
  advEmail?: string;
  advPhone?: string;
  advValo?: AccountResponse;
  rpv?: AccountResponse;
  isManual?: boolean;
}
