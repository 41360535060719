/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs';

import { DirectionType, FieldType, MethodType, RegexType, RouteInterceptorData } from '../utils/models/RouteInterceptorData';

@Injectable()
export class HTMLEntitiesInterceptor implements HttpInterceptor {
  /**
   * List of API routes where the data must be coded or encoded.
   */
  private readonly routeToIntercept: Array<RouteInterceptorData> = [
    {
      method: MethodType.PUT,
      regex: /\/api\/Companies\/\d*$/,
      fields: [{ paramName: 'description' }, { paramName: 'boComment' }],
      direction: DirectionType.OUTGOING,
    },
    {
      method: MethodType.GET,
      regex: /\/api\/Companies\/informations\/\d*$/,
      fields: [{ paramName: 'description' }, { paramName: 'boComment' }],
      direction: DirectionType.INCOMING,
    },
    {
      method: MethodType.POST,
      regex: /\/api\/Companies$/,
      fields: [{ paramName: 'description' }, { paramName: 'boComment' }],
      direction: DirectionType.OUTGOING,
    },
    // Program API :
    {
      method: MethodType.POST,
      regex: /\/api\/Programs$/,
      fields: [{ paramName: 'programText' }, { paramName: '3dView', fieldType: FieldType.URL }],
      direction: DirectionType.OUTGOING,
    },
    {
      method: MethodType.PUT,
      regex: /\/api\/Programs\/\d*$/,
      fields: [{ paramName: 'programText' }, { paramName: '3dView', fieldType: FieldType.URL }],
      direction: DirectionType.OUTGOING,
    },
    {
      method: MethodType.GET,
      regex: /\/api\/Programs\/\d*\?flag=get-informations$/,
      fields: [{ paramName: 'programText' }, { paramName: '3dView', fieldType: FieldType.URL }],
      direction: DirectionType.INCOMING,
    },
    {
      method: MethodType.GET,
      regex: /\/api\/Programs\/details\/\d*$/,
      fields: [
        { paramName: 'programTextLongDescValo' },
        { paramName: 'programTextDescription' },
        { paramName: 'programTextServices' },
        { paramName: 'programTextQuality' },
        { paramName: 'programTextAccess' },
      ],
      direction: DirectionType.INCOMING,
    },
  ];

  /**
   * Matching list of characters to be replaced
   */
  private readonly escapeMap = {
    '"': '&quot;',
    '&': '&amp;',
    "'": '&#x27;',
    '<': '&lt;',
    '>': '&gt;',
    '`': '&#x60;',
    '‘': '&lsquo;',
    '’': '&rsquo;',
  };

  constructor(public sanitizer: DomSanitizer) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      // First thing to do : test if incoming OR outgoing request have to be modified.
      // We only can have one result for one incoming OR outgoing request
      const found = this.routeToIntercept.find((route) => route.method === request.method && route.regex.test(request.urlWithParams));

      // If we don't have to control demand - we don't do anything.
      if (!found) {
        return next.handle(request);
      }

      this.developerLog(found, request);
      // If it's an outgoing request (ex : POST/PUT): he have to modify (encode) the outgoing body.
      if (found.direction === DirectionType.OUTGOING) {
        // We clone the body - to modify the body
        const customBody = request.body;
        // For each of the objects to be encoded, we modify the customBody with encodeData function.
        found.fields.forEach((field) => (customBody[field.paramName] = this.encodeData(customBody[field.paramName], field.fieldType)));

        // Then, we return a copy of the request with a modified body.
        return next.handle(request.clone({ body: customBody }));
      }

      // If it's an incoming request (ex : GET): he have to mdoify (decode) the incoming body.
      if (found.direction === DirectionType.INCOMING) {
        return next.handle(request).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              // We clone the body - to modify the body
              const customBody = event.body;

              // For each of the objects to be decoded, we modify the customBody with decodeData function.
              found.fields.forEach(
                (field) => (customBody[field.paramName] = this.decodeData(customBody[field.paramName], field.fieldType)),
              );

              // Then, we return a copy of the event with a modified body.
              return event.clone({ body: customBody });
            }

            return event;
          }),
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Function for decoding the received object: be it an object or a simple string
   *
   * @param {Object} obj the input to analyze and to decode
   *
   * @returns a decode object/value.
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  decodeData(obj: Object, fieldType?: FieldType): any {
    // If it's an Object instance, we call the function recursivly
    if (obj instanceof Object) {
      const keys = Object.keys(obj);
      keys.forEach((key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          obj[key] = this.decodeData(obj[key]);
        }
      });

      return obj;
    }
    // If it's a string, he have to decode the value.
    if (typeof obj === 'string') {
      // If it's an URL field, we use decodeURIComponent function
      if (fieldType && fieldType === FieldType.URL) {
        return decodeURIComponent(obj);
      }

      // By default, we execute a decoding of string value
      return this.decodeString(obj);
    }

    // If it is a number, an undefined value, no need to decode
    return obj;
  }

  /**
   * Function for encoding the outgoing object: be it an object or a simple string
   *
   * @param {Object} obj the input to analyze and to decode
   *
   * @returns a decode object/value.
   */
  encodeData(obj: any, fieldType?: FieldType): any {
    // If it's an Object instance, we call the function recursivly
    if (obj instanceof Object) {
      const keys = Object.keys(obj);
      keys.forEach((key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          obj[key] = this.encodeData(obj[key], fieldType);
        }
      });

      return obj;
    }
    // If it's a string, he have to encode the value.
    if (typeof obj === 'string') {
      if (fieldType && fieldType === FieldType.URL) {
        // If it's an URL field, we use encodeURIComponent function
        return encodeURIComponent(obj);
      }

      // By default, we execute a decoding of string value
      return this.encodeString(obj);
    }

    // If it is a number, an undefined value, no need to encode
    return obj;
  }

  /**
   * Function allowing the replacement of special characters with HTMLEntities
   *
   * @param {String} str String value to encode with HTMLEntites
   */
  encodeString(str: string): string {
    return str.replace(this.getRegex(RegexType.STRING), ($0) => {
      return this.escapeMap[$0];
    });
  }

  /**
   * Function allowing the decoding of a value that uses HTMLEntites
   *
   * @param str String value to decode.
   */
  decodeString(str: string): string {
    return str.replace(this.getRegex(RegexType.ENTITIE), ($0) => {
      return Object.keys(this.escapeMap).find((key) => this.escapeMap[key] === $0);
    });
  }

  /**
   * Return a regex depending on searched values.
   *
   * @param type {RegexType}
   */
  getRegex(type: RegexType): RegExp {
    switch (type) {
      case RegexType.ENTITIE:
        return new RegExp(`${Object.values(this.escapeMap).join('|')}`, 'g');
      case RegexType.STRING:
      default:
        return new RegExp(`[${Object.keys(this.escapeMap).join('|')}]`, 'g');
    }
  }

  developerLog(routeInterceptorData: RouteInterceptorData, request: HttpRequest<any>): void {
    if (isDevMode()) {
      console.warn(`HTMLEntitiesInterceptor - Request ${routeInterceptorData.method}:${request.urlWithParams} \
has been intercepted with regex ${String(routeInterceptorData.regex)}`);
    }
  }
}
