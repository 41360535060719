<div *ngIf="lotAction.userHasOptionUntil" class="lot-option-message">
  {{ lotAction.userHasOptionUntil }}
</div>
<div *ngIf="lot.quotaReach" class="lot-option-message" [innerHTML]="i18nService._('Txt_comparator_reached_lot_quota') | safe: 'html'"></div>
<div class="app-program-page-lot-header-top">
  <div class="app-program-page-lot-header-top-tags">
    <ng-container *ngIf="(lotFiscalityTags && lotFiscalityTags.length) || (lotTags && lotTags.length)">
      <div class="app-program-page-lot-header-top-fiscality-tags" *ngIf="lotFiscalityTags && lotFiscalityTags.length">
        <app-tags *ngFor="let tag of lotFiscalityTags" [ngClass]="tag.ngClass" [tagOption]="tag.tagOption"></app-tags>
      </div>
      <div class="app-program-page-lot-header-top-special-offer-tags" *ngIf="lotTags && lotTags.length">
        <app-tags
          *ngFor="let tag of lotTags"
          [ngClass]="tag.ngClass"
          [tagOption]="tag.tagOption"
          class="cursor-pointer"
          (click)="openOffer()"
        ></app-tags>
      </div>
    </ng-container>
  </div>
  <app-program-page-lot-option
    class="app-program-page-lot-header-top-reservation"
    *ngIf="!canChangeReservationComponentPosition"
    (returnInfo)="returnInfoLot($event)"
  >
  </app-program-page-lot-option>
</div>
<div class="app-program-page-lot-header-middle">
  <app-strong-point-list [strongPoints]="formattedLot?.strongPoints"></app-strong-point-list>
  <span>
    {{ i18nService._('Txt_Table_Column_ProfitabilityET') }} :
    <b>{{ profitabilityValue }}</b>
  </span>
</div>
<div class="app-program-page-lot-header-bottom">
  <span class="lot-header-bottom-annexeTags">
    <app-tags *ngFor="let tag of annexeTags" [ngClass]="tag.ngClass" [tagOption]="tag.tagOption"></app-tags>
  </span>
  <span>
    {{ i18nService._('Txt_page_program_Delivery_Date') }} :
    <b>{{ formattedLot?.deliveryDate }}</b>
  </span>
</div>
<app-program-page-lot-option class="app-program-page-lot-header-top-reservation-small-screen" *ngIf="canChangeReservationComponentPosition">
</app-program-page-lot-option>
