<div *ngIf="lots.length" class="pt-5 pr-5 pb-5">
  <app-table
    [data]="lots"
    [columnTitles]="columnTitles"
    [allColumns]="allColumns"
    [displayedColumns]="displayedColumns"
    [sortedColumns]="sortedColumns"
    [columnFormats]="columnFormats"
    [activateColumnsToDisplay]="true"
    [activateFilter]="true"
    [activatePaginator]="true"
    [activateSort]="true"
  ></app-table>
</div>
