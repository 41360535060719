import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, firstValueFrom, Observable } from 'rxjs';

import { Documents } from '../models/FormResponse';

import { UploadFile } from '../../common/services/upload-file';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';
import { AppConfigService } from '../../utils/services/app-config.service';

export interface DocumentHttpResponse {
  id: number;
  fileName: string;
  mimeType: string;
  index: number;
  size?: number;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class SignatureDocumentService implements UploadFile {
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
  ) {}

  upload(dossierId: number, file: File, documentType: string): Observable<DocumentHttpResponse> {
    const url = `${this.appConfig.getDematApiUrl()}/documents/${dossierId}`;
    const formData = new FormData();
    formData.append('type', documentType);
    formData.append('file', file);
    return this.http
      .post<DocumentHttpResponse>(url, formData)
      .pipe(catchError(this.errorHandlerService.handleError<DocumentHttpResponse>('SignatureDocumentService', 'upload')));
  }

  delete(dossierId: number): Observable<void> {
    const url = `${this.appConfig.getDematApiUrl()}/documents/${dossierId}`;
    return this.http.delete<void>(url).pipe(catchError(this.errorHandlerService.handleError<void>('SignatureDocumentService', 'upload')));
  }

  getDocumentAsBlob(id: string) {
    const url = `${this.appConfig.getDematApiUrl()}/documents/${id}`;
    return this.http
      .get(url, { responseType: 'blob' })
      .pipe(catchError(this.errorHandlerService.handleError<Blob>('SignatureService', 'getDocumentAsBlob')));
  }

  async downloadBundleDocuments(signedContract: Documents) {
    const doc = await firstValueFrom(this.getDocumentAsBlob(signedContract.id));
    const blob = new Blob([doc], { type: signedContract.mimeType });
    const objectUrl = URL.createObjectURL(blob);
    window.open(objectUrl);
    window.addEventListener(
      'focus',
      () => {
        window.URL.revokeObjectURL(objectUrl);
      },
      { once: true },
    );
  }
}
