<app-alert class="mb-4" [body]="'Txt_alert_exclude_list'" [type]="alertEnum.INFO"></app-alert>
<ng-container *ngIf="!!itemsFiltered">
  <mat-button-toggle-group [(ngModel)]="showCheckboxSelection" (change)="filterList()">
    <mat-button-toggle [value]="showCheckboxSelectionALL">
      {{ i18nService._('Txt_ToggleButton_All') }}
    </mat-button-toggle>

    <mat-button-toggle [value]="showCheckboxSelectionSelected">
      <span
        [ngClass]="{ 'mr-4 matBadge-center': selectedItemIds?.length }"
        [matBadge]="selectedItemIds?.length + ''"
        matBadgeOverlap="false"
        [matBadgeHidden]="!selectedItemIds?.length"
      >
        {{ i18nService._('Txt_ToggleButton_Selected') }}
      </span>
    </mat-button-toggle>

    <mat-button-toggle [value]="showCheckboxSelectionNotSelected">
      {{ i18nService._('Txt_ToggleButton_NotSelected') }}
    </mat-button-toggle>
  </mat-button-toggle-group>
  <app-advanced-tracking-table-no-back-page
    [columns]="columns"
    [itemsWithSelection]="itemsFiltered"
    [selectable]="true"
    [selectedItems]="selectedItemIds"
    [pageSizeOptions]="[5]"
    [pageSize]="5"
    (checkedRowsChange)="checkedRowsChange($event)"
  ></app-advanced-tracking-table-no-back-page>
</ng-container>
<mat-spinner *ngIf="!itemsFiltered" [color]="'primary'" [diameter]="60" [strokeWidth]="6" class="mx-auto my-5"></mat-spinner>
