import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ProspectType } from 'libs/commons-dto/src/lib/dossier-prospect/prospect/prospect-type.enum';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe, NgIf } from '@angular/common';

import { DossierResponse } from './../../../signature/models/DossierResponse';
import { I18nService } from '../../../utils/services/i18n.service';
import { DossierProspectTakeOptionData } from '../../models/DossierProspectTakeOptionData';
import { SpinnerWithBackdropService } from '../../../utils/services/spinner-with-backdrop.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { DossierProspectAssociationService } from '../../services/dossier-prospect-association.service';
import { DossierProspectAssociationDialogData } from '../../models/DossierProspectAssociationDialogData';
import { ChipPopulatedAutocompleteComponent } from '../../../utils/components/chip-populated-autocomplete/chip-populated-autocomplete.component';
import { IconComponent } from '../../../common/standalone/icon/icon.component';

@Component({
  selector: 'app-prospect-select-dialog',
  templateUrl: './prospect-select-dialog.component.html',
  styleUrls: ['./prospect-select-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, IconComponent, NgIf, ChipPopulatedAutocompleteComponent, MatButtonModule, AsyncPipe],
})
export class ProspectSelectDialogComponent implements OnInit {
  /**
   * prospectSelectedForm attribute
   *
   * @type {FormGroup}
   * @memberof OptionDossierProspectSelectProspectDialogComponent
   */
  public dossierProspectSelectedForm: UntypedFormGroup;

  /**
   * prospectItems$ attribute
   *
   * @type {Observable<Map<string, any>>}
   * @memberof OptionDossierProspectSelectProspectDialogComponent
   */
  public dossierProspectItems$: Observable<Map<string, number>>;

  /**
   * prospectsDetails attribute
   *
   * @private
   * @type {Array<DossierProspectData>}
   * @memberof OptionDossierProspectSelectProspectDialogComponent
   */
  private dossierProspectsDetails: Array<DossierResponse>;

  /**
   * prospectDetails attribute
   *
   * @private
   * @type {DossierProspectData}
   * @memberof OptionDossierProspectSelectProspectDialogComponent
   */
  public dossierProspectDetails: DossierResponse;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly snackbarService: SnackbarService,
    private readonly dossierProspectAssociationService: DossierProspectAssociationService,
    private readonly spinnerWithBackdropService: SpinnerWithBackdropService,
    public readonly i18nService: I18nService,
    @Inject(MAT_DIALOG_DATA) public data: DossierProspectAssociationDialogData,
    public dialogRef: MatDialogRef<ProspectSelectDialogComponent>,
  ) {
    this.dossierProspectItems$ = undefined;
    this.dossierProspectsDetails = [];
    this.dossierProspectDetails = undefined;
  }

  public ngOnInit(): void {
    this.dossierProspectSelectedForm = this.formBuilder.group({});
    this.dossierProspectItems$ = this.dossierProspectAssociationService
      .getDossierProspects(ProspectType.PERSON)
      .pipe(map((dossierProspects) => this.mapDossierProspectsToItems(dossierProspects)));
  }

  private mapDossierProspectsToItems(dossierProspects): Map<string, number> {
    const items = new Map<string, number>();
    dossierProspects.items.forEach((dossierProspect) => {
      const name = this.formatProspectName(dossierProspect);
      items.set(name, dossierProspect.id);
    });
    this.dossierProspectsDetails = dossierProspects.items;
    return items;
  }

  private formatProspectName(dossierProspect): string {
    const names = dossierProspect.prospects.map((prospect) => `${prospect.lastName} ${prospect.firstName}`);
    return names.join(' - ');
  }

  dossierProspectSelected(newValue: Array<number>): void {
    this.dossierProspectDetails = this.dossierProspectsDetails.find((element) => element.id === newValue[0]);
  }

  submit(): void {
    if (this.data.requireOptionTake) {
      this.takeOption();
      return;
    }

    this.dialogRef.close({ dossierProspectId: this.dossierProspectSelectedForm.value.dossierProspectId[0] });
  }

  private takeOption() {
    const optionForm: DossierProspectTakeOptionData = {
      dossierProspectId: this.dossierProspectSelectedForm.value.dossierProspectId[0],
      lotId: this.data.lotId,
    };

    this.spinnerWithBackdropService.show();
    this.dossierProspectAssociationService.takeOption(optionForm).subscribe(
      (successTakeOption) => this.handleSuccessTakeOption(successTakeOption),
      () => this.spinnerWithBackdropService.hide(),
    );
  }

  private handleSuccessTakeOption(successTakeOption): void {
    this.spinnerWithBackdropService.hide();
    const message = this.formatSuccessMessage(successTakeOption);
    this.snackbarService.info(message, false, undefined, true);
    this.dialogRef.close(successTakeOption);
  }

  private formatSuccessMessage(successTakeOption): string {
    let message = `<p>${successTakeOption.libelleRetour}<p>`;
    if (successTakeOption.libelleRetourPaternity) {
      message += `<b>${successTakeOption.libelleRetourPaternity}</b>`;
    }
    return message;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
