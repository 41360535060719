import { IsString, IsNumber, IsOptional } from 'class-validator';

export class StrongPointResponseDto {
  @IsString()
  name: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsNumber()
  position: number;
}
