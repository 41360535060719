import { Injectable } from '@angular/core';

import { AreaTypeApiService } from '../adapters/area-type-api.service';
import { AreaTypeResponse } from '../utils/models/AreaTypeResponse';

@Injectable({
  providedIn: 'root',
})
export class AreaTypeService {
  areaTypes: AreaTypeResponse[];

  constructor(private readonly areaTypeApiService: AreaTypeApiService) {}

  public async getAreaTypes(): Promise<AreaTypeResponse[]> {
    if (!this.areaTypes) {
      await this.loadAreaTypes();
    }
    return this.areaTypes;
  }

  private async loadAreaTypes(): Promise<void> {
    this.areaTypes = await this.areaTypeApiService.getAreaTypes();
  }
}
