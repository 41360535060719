import { formatDate } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'quarterDate',
  standalone: true,
})
export class QuarterDatePipe implements PipeTransform {
  public transform(date: Date | string): string {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    return `${this.getQuarter(date)}T ${formatDate(date, 'yyyy', 'fr')}`;
  }

  private getQuarter(date: Date): string {
    const month = date.getMonth();
    if (month < 3) {
      return '1';
    } else if (month < 6) {
      return '2';
    } else if (month < 9) {
      return '3';
    } else {
      return '4';
    }
  }
}
