<div class="flex flex-row flex-1 w-full bg-graylight h-full d-table">
  <mat-drawer-container [hasBackdrop]="true" class="bg-white w-full">
    <mat-drawer
      #drawer
      class="shadow-xl overflow-visible visible border-l-4 border-orange-500 w-full lg:w-4/5 p-4"
      [opened]="false"
      mode="over"
      position="end"
    >
      <app-lot-details-panel [lotId]="lotId" [reservationId]="prereservationId" (closePanel)="drawer.close()"></app-lot-details-panel>
    </mat-drawer>

    <mat-drawer-content>
      <div class="flex flex-col w-full">
        <!-- Sticky subHeader form -->
        <app-sticky-header-form
          [titleText]="i18nService._('Title_PreReservationsTrackingTable')"
          (actionButtonClicked)="goToManualPreservation()"
          [actionButtonLabel]="i18nService._('Txt_Button_ReservationsCreateManualPrereservation')"
          [actionButtonIcon]="'icon-plus text-sm font-bold inline-block align-middle pl-lg-2'"
          [actionButtonIconMob]="'lg:hidden icon-plus text-sm font-bold pl-lg-2 inline-block align-middle'"
          [isActionButtonVisible]="isManualPreservationButtonVisible"
        ></app-sticky-header-form>

        <div class="pt-20 mx-3 mb-4 lg:mx-5 md:mx-5 sm:mx-4 px-2 lg:px-6 md:px-5 sm:px-3">
          <mat-spinner
            *ngIf="isLoadingAction"
            [color]="'primary'"
            [diameter]="60"
            [strokeWidth]="6"
            class="absolute z-50 inset-x-1/2 inset-y-1/2"
          >
          </mat-spinner>

          <div class="flex justify-between">
            <mat-button-toggle-group [(ngModel)]="statusFilter" (change)="statusFilterChange()" class="mb-4">
              <mat-button-toggle data-cy="resaTrackingTableToggleButtonAll" [value]="statusFilters.all">
                <span
                  [ngClass]="{ 'mr-4 matBadge-center': count.all }"
                  [matBadge]="count.all"
                  matBadgeOverlap="false"
                  [matBadgeHidden]="!count.all"
                >
                  <span class="pr-2">{{ i18nService._('Title_ResaTrackingTable_ToggleButton_All') }}</span>
                </span>
              </mat-button-toggle>

              <mat-button-toggle data-cy="preresaTrackingTableToggleButtonOngoing" [value]="statusFilters.ongoing">
                <span
                  [ngClass]="{ 'mr-4 matBadge-center': count.ongoing }"
                  [matBadge]="count.ongoing"
                  matBadgeOverlap="false"
                  [matBadgeHidden]="!count.ongoing"
                >
                  <span class="pr-2">{{ i18nService._('Title_PreresaTrackingTable_ToggleButton_Ongoing') }}</span>
                </span>
              </mat-button-toggle>

              <mat-button-toggle data-cy="preresaTrackingTableToggleButtonClosed" [value]="statusFilters.closed">
                <span
                  [ngClass]="{ 'mr-4 matBadge-center': count.closed }"
                  [matBadge]="count.closed"
                  matBadgeOverlap="false"
                  [matBadgeHidden]="!count.closed"
                >
                  <span class="pr-2">{{ i18nService._('Title_PreresaTrackingTable_ToggleButton_Closed') }}</span>
                </span>
              </mat-button-toggle>

              <mat-button-toggle data-cy="preresaTrackingTableTransformed" [value]="statusFilters.transformed">
                <span
                  [ngClass]="{ 'mr-4 matBadge-center': count.transformed }"
                  [matBadge]="count.transformed"
                  matBadgeOverlap="false"
                  [matBadgeHidden]="!count.transformed"
                >
                  <span class="pr-2">{{ i18nService._('Title_PreresaTrackingTable_ToggleButton_Transformed') }}</span>
                </span>
              </mat-button-toggle>
            </mat-button-toggle-group>

            <button
              *ngIf="isSignaturesButtonVisible"
              (click)="gotToDossierSignature()"
              mat-flat-button
              type="button"
              class="bg-orange-600 text-white h-10"
            >
              <span class="material-symbols-outlined"> stylus_note </span>
              {{ i18nService._('Txt_Button_ReservationsAccessSignatures') }}
            </button>
          </div>
          <div class="grid grid-cols-8 gap:4 lg:gap-6 mb-3">
            <div class="col-span-2">
              <mat-form-field class="fullW flex w-full">
                <mat-label>{{ i18nService._('Txt_Placeholder_Search') }}</mat-label>
                <input [(ngModel)]="filterValue" (ngModelChange)="textFilterSubject.next($event)" matInput />
              </mat-form-field>
            </div>
            <div class="flex h-full items-center col-span-2">
              <app-toggle-button (selectChange)="changeTeamFilter($event)" *ngIf="displayTeamButton" [checked]="isTeamMemberParam">
                <span class="font-bold">{{ i18nService._('Txt_TrackingTable_MY_TEAM') }}</span>
              </app-toggle-button>
              <app-toggle-button (selectChange)="changePartnerFilter($event)" *ngIf="displayPartnerButton" [checked]="isPartnerParam">
                <span class="font-bold">{{ i18nService._('Txt_TrackingTable_MY_PARTNERS') }}</span>
              </app-toggle-button>
            </div>
          </div>

          <div class="tracking-mat-table">
            <app-grid-tracking-table
              [tableLoader]="preReservationsTrackingTableService"
              [columnDefs]="columnDefs"
              [serviceUrl]="serviceUrl"
              [pageSize]="pageSize"
              [textFilter]="textFilterValue"
              [isPartner]="isPartnerParam"
              [isTeamMember]="isTeamMemberParam"
              [statusFilter]="statusFilter.code"
            >
            </app-grid-tracking-table>
          </div>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
