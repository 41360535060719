/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { tap } from 'rxjs/internal/operators/tap';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

import { AccountInfosResponse } from '../models/AccountInfosResponse';
import { CompanyData } from '../models/CompanyData';
import { CompanyResponse } from '../models/CompanyResponse';
import { MandatedDeveloperCompanyData } from '../models/MandatedDeveloperCompanyData';
import { AppConfigService } from './app-config.service';
import { TokenService } from './authorisation/token.service';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsCompanyService {
  private companyTypeList: any;

  constructor(
    private readonly http: HttpClient,
    private readonly appConfigService: AppConfigService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly tokenService: TokenService,
  ) {
    this.companyTypeList = undefined;
  }

  /**
   * getCompanyTypeList method, we call the back only once (we save value)
   *
   * @returns {Observable<any>}
   * @memberof UtilsCompanyService
   */
  getCompanyTypeList(): Observable<any> {
    if (!this.companyTypeList) {
      const url = `${this.appConfigService.getLoopbackApiUrl()}/CompanyTypes`;

      return this.http.get<any>(url).pipe(
        tap((response) => (this.companyTypeList = response)),
        catchError(this.errorHandlerService.handleError<any>('utilsCompanyService', 'getCompanyTypeList')),
      );
    }

    return of(this.companyTypeList);
  }

  /**
   * getActiveCompanyList method, get the list of active companies according to this
   * company type id
   *
   * @param {*} companyTypeId
   * @returns
   * @memberof UtilsCompanyService
   */
  getActiveCompanyList(companyTypeId): Observable<any> {
    const filter = {
      where: { companyTypeId, activeCompany: 1 },
      fields: ['corporateName', 'id'],
    };

    const url = `${this.appConfigService.getLoopbackApiUrl()}/Companies`;
    const params = new HttpParams().set('filter', JSON.stringify(filter));

    return this.http
      .get<any>(url, { params })
      .pipe(catchError(this.errorHandlerService.handleError<any>('utilsCompanyService', 'getCompanyList')));
  }

  /**
   * getCompanyName method, return the company name of the connected user
   *
   * @returns
   * @memberof UtilsCompanyService
   */
  getCompanyOfTheUser(): Observable<CompanyData> {
    const id = this.tokenService.getUserId();
    const url = `${this.appConfigService.getLoopbackApiUrl()}/Accounts/getAccountInfos/${id}`;

    return this.http.get<AccountInfosResponse>(url).pipe(
      map((res) => res.companyId),
      switchMap((companyId) => {
        return this.http.get<CompanyData>(`${this.appConfigService.getLoopbackApiUrl()}/Companies/informations/${companyId}`);
      }),
      catchError(this.errorHandlerService.handleError<any>('utilsCompanyService', 'getCompanyList')),
    );
  }

  /**
   * getDeveloperCompanyWhereUserAreMandatedAndItsPrograms method
   *
   * @returns {Observable<MandatedDeveloperCompanyData[]>}
   * @memberof UtilsCompanyService
   */
  getDeveloperCompanyWhereUserAreMandatedAndItsPrograms(): Observable<Array<MandatedDeveloperCompanyData>> {
    const url = `${this.appConfigService.getLoopbackApiUrl()}/Companies/developers-from-mandates`;

    return this.http
      .get<Array<MandatedDeveloperCompanyData>>(url)
      .pipe(
        catchError(
          this.errorHandlerService.handleError<Array<MandatedDeveloperCompanyData>>(
            'utilsCompanyService',
            'getDeveloperCompanyWhereUserAreMandatedAndItsPrograms',
          ),
        ),
      );
  }

  /**
   * getTrackingTablesCompanies method
   *
   * @returns {Observable<Array<any>>}
   * @memberof UtilsCompanyService
   */
  getTrackingTablesCompanies(): Observable<Array<CompanyResponse>> {
    const url = `${this.appConfigService.getLoopbackApiUrl()}/Companies/tracking-tables-companies`;

    return this.http
      .get<Array<CompanyResponse>>(url)
      .pipe(catchError(this.errorHandlerService.handleError<Array<CompanyResponse>>('companyService', 'getTrackingTablesCompanies')));
  }
}
