import { tagClass, TagsInterface, TagTypeEnum } from '../../../design-system/model/tag-option';

export const annexeTags: { [key: string]: TagsInterface } = {
  OUTDOOR_PARKING: {
    ngClass: tagClass[TagTypeEnum.SMALL_NO_BORDER],
    tagOption: {
      text: 'OUTDOOR_PARKING',
      prefixIcon: 'parking_custom',
      isCustomPrefixIcon: true,
      type: TagTypeEnum.SMALL_NO_BORDER,
    },
  },
  BASEMENT_PARKING_LOT: {
    ngClass: tagClass[TagTypeEnum.SMALL_NO_BORDER],
    tagOption: {
      text: 'BASEMENT_PARKING_LOT',
      prefixIcon: 'parking_custom',
      isCustomPrefixIcon: true,
      type: TagTypeEnum.SMALL_NO_BORDER,
    },
  },
  BOXES: {
    ngClass: tagClass[TagTypeEnum.SMALL_NO_BORDER],
    tagOption: {
      text: 'BOXES',
      prefixIcon: 'inventory_2',
      isCustomPrefixIcon: false,
      type: TagTypeEnum.SMALL_NO_BORDER,
    },
  },
  GARAGE: {
    ngClass: tagClass[TagTypeEnum.SMALL_NO_BORDER],
    tagOption: {
      text: 'GARAGE',
      prefixIcon: 'drive_eta',
      isCustomPrefixIcon: false,
      type: TagTypeEnum.SMALL_NO_BORDER,
    },
  },
  CELLAR: {
    ngClass: tagClass[TagTypeEnum.SMALL_NO_BORDER],
    tagOption: {
      text: 'CELLAR',
      prefixIcon: 'cellar_custom',
      isCustomPrefixIcon: true,
      type: TagTypeEnum.SMALL_NO_BORDER,
    },
  },
  CARPORT: {
    ngClass: tagClass[TagTypeEnum.SMALL_NO_BORDER],
    tagOption: {
      text: 'CARPORT',
      prefixIcon: 'carport_custom',
      isCustomPrefixIcon: true,
      type: TagTypeEnum.SMALL_NO_BORDER,
    },
  },
  LARDER: {
    ngClass: tagClass[TagTypeEnum.SMALL_NO_BORDER],
    tagOption: {
      text: 'LARDER',
      prefixIcon: 'larder_custom',
      isCustomPrefixIcon: true,
      type: TagTypeEnum.SMALL_NO_BORDER,
    },
  },
  ATTIC: {
    ngClass: tagClass[TagTypeEnum.SMALL_NO_BORDER],
    tagOption: {
      text: 'ATTIC',
      prefixIcon: 'attic_custom',
      isCustomPrefixIcon: true,
      type: TagTypeEnum.SMALL_NO_BORDER,
    },
  },
  TERRACE: {
    ngClass: tagClass[TagTypeEnum.SMALL_NO_BORDER],
    tagOption: {
      text: 'TERRACE',
      prefixIcon: 'deck',
      isCustomPrefixIcon: false,
      type: TagTypeEnum.SMALL_NO_BORDER,
    },
  },
  BALCONY: {
    ngClass: tagClass[TagTypeEnum.SMALL_NO_BORDER],
    tagOption: {
      text: 'BALCONY',
      prefixIcon: 'balcony',
      isCustomPrefixIcon: false,
      type: TagTypeEnum.SMALL_NO_BORDER,
    },
  },
  GARDEN: {
    ngClass: tagClass[TagTypeEnum.SMALL_NO_BORDER],
    tagOption: {
      text: 'GARDEN',
      prefixIcon: 'local_florist',
      isCustomPrefixIcon: false,
      type: TagTypeEnum.SMALL_NO_BORDER,
    },
  },
  LOGGIA: {
    ngClass: tagClass[TagTypeEnum.SMALL_NO_BORDER],
    tagOption: {
      text: 'LOGGIA',
      prefixIcon: 'loggia_custom',
      isCustomPrefixIcon: true,
      type: TagTypeEnum.SMALL_NO_BORDER,
    },
  },
};
const appTagColumn = 'app-tag-column';
export const annexeTagsSmall: { [key: string]: TagsInterface } = {
  PARKING: {
    ngClass: [tagClass[TagTypeEnum.XS_NO_BORDER], appTagColumn],
    tagOption: {
      text: 'PARKING',
      prefixIcon: 'parking_custom',
      isCustomPrefixIcon: true,
      type: TagTypeEnum.XS_NO_BORDER,
    },
  },
  BOXES: {
    ngClass: [tagClass[TagTypeEnum.XS_NO_BORDER], appTagColumn],
    tagOption: {
      text: 'BOXES',
      prefixIcon: 'inventory_2',
      isCustomPrefixIcon: false,
      type: TagTypeEnum.XS_NO_BORDER,
    },
  },
  GARAGE: {
    ngClass: [tagClass[TagTypeEnum.XS_NO_BORDER], appTagColumn],
    tagOption: {
      text: 'GARAGE',
      prefixIcon: 'drive_eta',
      isCustomPrefixIcon: false,
      type: TagTypeEnum.XS_NO_BORDER,
    },
  },
  CELLAR: {
    ngClass: [tagClass[TagTypeEnum.XS_NO_BORDER], appTagColumn],
    tagOption: {
      text: 'CELLAR',
      prefixIcon: 'cellar_custom',
      isCustomPrefixIcon: true,
      type: TagTypeEnum.XS_NO_BORDER,
    },
  },
  CARPORT: {
    ngClass: [tagClass[TagTypeEnum.XS_NO_BORDER], appTagColumn],
    tagOption: {
      text: 'CARPORT',
      prefixIcon: 'carport_custom',
      isCustomPrefixIcon: true,
      type: TagTypeEnum.XS_NO_BORDER,
    },
  },
  LARDER: {
    ngClass: [tagClass[TagTypeEnum.XS_NO_BORDER], appTagColumn],
    tagOption: {
      text: 'LARDER',
      prefixIcon: 'larder_custom',
      isCustomPrefixIcon: true,
      type: TagTypeEnum.XS_NO_BORDER,
    },
  },
  ATTIC: {
    ngClass: [tagClass[TagTypeEnum.XS_NO_BORDER], appTagColumn],
    tagOption: {
      text: 'ATTIC',
      prefixIcon: 'attic_custom',
      isCustomPrefixIcon: true,
      type: TagTypeEnum.XS_NO_BORDER,
    },
  },
  TERRACE: {
    ngClass: [tagClass[TagTypeEnum.XS_NO_BORDER], appTagColumn],
    tagOption: {
      text: 'TERRACE',
      prefixIcon: 'deck',
      isCustomPrefixIcon: false,
      type: TagTypeEnum.XS_NO_BORDER,
    },
  },
  BALCONY: {
    ngClass: [tagClass[TagTypeEnum.XS_NO_BORDER], appTagColumn],
    tagOption: {
      text: 'BALCONY',
      prefixIcon: 'balcony',
      isCustomPrefixIcon: false,
      type: TagTypeEnum.XS_NO_BORDER,
    },
  },
  GARDEN: {
    ngClass: [tagClass[TagTypeEnum.XS_NO_BORDER], appTagColumn],
    tagOption: {
      text: 'GARDEN',
      prefixIcon: 'local_florist',
      isCustomPrefixIcon: false,
      type: TagTypeEnum.XS_NO_BORDER,
    },
  },
  LOGGIA: {
    ngClass: [tagClass[TagTypeEnum.XS_NO_BORDER], appTagColumn],
    tagOption: {
      text: 'LOGGIA',
      prefixIcon: 'loggia_custom',
      isCustomPrefixIcon: true,
      type: TagTypeEnum.XS_NO_BORDER,
    },
  },
};
