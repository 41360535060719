import { Component } from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';

import { I18nService } from '../../../utils/services/i18n.service';
import { IconComponent } from '../../../common/standalone/icon/icon.component';

@Component({
  selector: 'app-update-dialog',
  templateUrl: './update-dialog.component.html',
  styleUrls: ['./update-dialog.component.scss'],
  standalone: true,
  imports: [IconComponent, MatDialogModule],
})
export class UpdateDialogComponent {
  constructor(
    public _i18nService: I18nService,
    private readonly dialogRef: MatDialogRef<UpdateDialogComponent>,
  ) {}

  close(shouldUpdate: boolean) {
    this.dialogRef.close(shouldUpdate);
  }
}
