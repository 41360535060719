import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { DesignSystemModule } from '../design-system/design-system.module';
import { DialogModule } from '../dialog/dialog.module';
import { PipesModule } from '../utils/pipes/pipes.module';
import { UtilsModule } from '../utils/utils.module';
import { ComparatorRoutingModule } from './comparator-routing.module';
import { ComparatorPreviewBlocComponent } from './components/comparator-preview-bloc/comparator-preview-bloc.component';
import { ComparatorPreviewButtonComponent } from './components/comparator-preview-button/comparator-preview-button.component';
import { ComparatorPreviewItemComponent } from './components/comparator-preview-item/comparator-preview-item.component';
import { LotComparatorAnnexesComponent } from './components/lot-comparator/lot-comparator-annexes/lot-comparator-annexes.component';
import { LotComparatorGlanceComponent } from './components/lot-comparator/lot-comparator-glance/lot-comparator-glance.component';
import { LotComparatorHeadComponent } from './components/lot-comparator/lot-comparator-head/lot-comparator-head.component';
import { LotComparatorInformationComponent } from './components/lot-comparator/lot-comparator-information/lot-comparator-information.component';
import { LotComparatorMetaLineComponent } from './components/lot-comparator/lot-comparator-meta-line/lot-comparator-meta-line.component';
import { LotComparatorPriceDetailsComponent } from './components/lot-comparator/lot-comparator-price-details/lot-comparator-price-details.component';
import { LotComparatorRentAndProfitablityComponent } from './components/lot-comparator/lot-comparator-rent-and-profitablity/lot-comparator-rent-and-profitablity.component';
import { LotComparatorComponent } from './components/lot-comparator/lot-comparator.component';
import { ManagementLotComparatorComponent } from './components/management-lot-comparator/management-lot-comparator.component';
import { StrongPointListComponent } from '../design-system/component/strong-point-list/strong-point-list.component';

@NgModule({
  imports: [
    ComparatorRoutingModule,
    CommonModule,
    UtilsModule,
    DialogModule,
    DesignSystemModule,
    MatIconModule,
    PipesModule,
    StrongPointListComponent,
    LotComparatorComponent,
    ComparatorPreviewButtonComponent,
    ComparatorPreviewBlocComponent,
    LotComparatorHeadComponent,
    ComparatorPreviewItemComponent,
    ManagementLotComparatorComponent,
    LotComparatorGlanceComponent,
    LotComparatorMetaLineComponent,
    LotComparatorRentAndProfitablityComponent,
    LotComparatorPriceDetailsComponent,
    LotComparatorInformationComponent,
    LotComparatorAnnexesComponent,
  ],
  exports: [ComparatorPreviewButtonComponent, ManagementLotComparatorComponent, ComparatorPreviewBlocComponent],
})
export class ComparatorModule {}
