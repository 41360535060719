// Angular Dependencies
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
// App Module Dependencies
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

// Components
import { MainMapComponent } from './components/main-map/main.map.component';
import { DesignSystemModule } from '../design-system/design-system.module';
import { UtilsModule } from '../utils/utils.module';

@NgModule({
  imports: [NgxMapboxGLModule, CommonModule, MatIconModule, DesignSystemModule, UtilsModule, MainMapComponent],
  providers: [],
  exports: [MainMapComponent, NgxMapboxGLModule],
})
export class MapModule {}
