/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Taxation } from '../../../signature/models/Taxation';
import { TVA } from '../../../utils/enums/tva.enum';

export type LotPriceOptions = {
  price?: number;
  annexesPrice?: number;
  discount?: number;
  taxation?: Taxation;
};

@Injectable({
  providedIn: 'root',
})
export class LotPriceService {
  private _price: number;
  private _annexesPrice: number;
  private _discount: number;
  private _taxation: Taxation;
  initialLotPrice: number;
  finalLotPrice: number;
  private readonly TVA_NOMINAL_VALUE = 1.2;
  private readonly TVA_NOMINAL_TAXATION = 20;
  lotPrice$: BehaviorSubject<any> = new BehaviorSubject(null);

  updateLotPrice({ price, annexesPrice, discount, taxation }: LotPriceOptions): void {
    this.price = price;
    this.annexesPrice = annexesPrice;
    this.discount = discount;
    this.taxation = taxation;

    const initialLotPrice = this.truncateToTwoDecimals(
      this.calculateTTCPrice({
        price: this.price,
        annexesPrice: this.annexesPrice,
        taxation: this.taxation,
      }),
    );
    const finalLotPrice = this.truncateToTwoDecimals(
      initialLotPrice -
        this.calculateDiscountWithTVA({
          discount: this.discount,
          taxation: this.taxation,
        }),
    );
    this.initialLotPrice = initialLotPrice;
    this.finalLotPrice = finalLotPrice;
    this.lotPrice$.next({ initialLotPrice, finalLotPrice, taxation: this.taxation });
  }

  private calculateTTCPrice({
    price,
    annexesPrice = 0,
    taxation = { label: TVA.NOMINAL_TAXATION, taxation: this.TVA_NOMINAL_TAXATION },
  }: LotPriceOptions): number {
    if (taxation.taxation !== this.TVA_NOMINAL_TAXATION) {
      return this.truncateToTwoDecimals(((price + annexesPrice) / this.TVA_NOMINAL_VALUE) * (1 + taxation.taxation / 100));
    }

    return this.truncateToTwoDecimals(price + annexesPrice);
  }

  private calculateDiscountWithTVA({
    discount,
    taxation = { label: TVA.NOMINAL_TAXATION, taxation: this.TVA_NOMINAL_TAXATION },
  }: LotPriceOptions): number {
    if (taxation.taxation !== this.TVA_NOMINAL_TAXATION && discount) {
      return this.truncateToTwoDecimals((discount / this.TVA_NOMINAL_VALUE) * (1 + taxation.taxation / 100));
    }

    return discount ? this.truncateToTwoDecimals(discount) : 0;
  }

  set price(price: number) {
    if (price) {
      this._price = price;
    }
  }

  get price(): number {
    return this._price;
  }

  set annexesPrice(annexesPrice: number) {
    if (annexesPrice !== undefined) {
      this._annexesPrice = annexesPrice;
    }
  }

  get annexesPrice(): number {
    return this._annexesPrice;
  }

  set discount(discount: number) {
    if (discount !== undefined) {
      this._discount = discount;
    }
  }

  get discount(): number {
    return this._discount;
  }

  set taxation(taxation: Taxation) {
    if (taxation !== undefined) {
      this._taxation = taxation;
    }
  }

  get taxation(): Taxation {
    return this._taxation;
  }

  private truncateToTwoDecimals(value: number): number {
    return Math.trunc(value * 100) / 100;
  }
}
