import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LastActivitiesComponent } from './components/last-activities/last-activities.component';
import { UtilsModule } from '../utils/utils.module';

@NgModule({
  imports: [CommonModule, UtilsModule, RouterModule, LastActivitiesComponent],
  exports: [LastActivitiesComponent],
})
export class ActivitiesModule {}
