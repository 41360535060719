<div class="main">
  <app-dossier-prospect-dashboard-status class="card_status" [(actualStep)]="actualStep"></app-dossier-prospect-dashboard-status>

  <div class="cards">
    <div class="cards_left">
      <app-dossier-prospect-dashboard-completion
        *ngIf="displayCompletion"
        [isDossierComplete]="isDossierComplete"
        [dossierId]="dossier.id"
      ></app-dossier-prospect-dashboard-completion>

      <app-dossier-prospect-dashboard-simulation
        *ngIf="displaySimulation"
        [dossier]="dossier"
        [simulationUpdated]="simulationUpdated"
      ></app-dossier-prospect-dashboard-simulation>

      <div *ngIf="displayOption && options">
        <div *ngIf="activeOptions && activeOptions.length > 0" class="cards_left">
          <app-dossier-prospect-dashboard-option
            *ngFor="let activeOption of activeOptions"
            [activeOption]="activeOption"
            [dossier]="dossier"
          ></app-dossier-prospect-dashboard-option>
        </div>
        <div *ngIf="!activeOptions || activeOptions.length === 0">
          <app-dossier-prospect-dashboard-option [options]="options" [dossier]="dossier"></app-dossier-prospect-dashboard-option>
        </div>
      </div>
    </div>

    <div class="cards_right">
      <app-dossier-prospect-dashboard-search
        *ngIf="displaySearch"
        [dossier]="dossier"
        [searchPreferencesUpdated]="searchPreferencesUpdated"
      ></app-dossier-prospect-dashboard-search>
      <app-dossier-prospect-dashboard-comment
        *ngIf="displayCommentary"
        [dossier]="dossier"
        [comment]="comment"
      ></app-dossier-prospect-dashboard-comment>
    </div>
  </div>
</div>
