<p-carousel
  [value]="config.items"
  [numVisible]="config.numVisibleDefault"
  [numScroll]="config.numScrollDefault"
  [circular]="config.circular"
  [showIndicators]="config.showIndicators"
  [responsiveOptions]="config.responsiveParams"
  *ngIf="ready"
>
  <ng-template let-item pTemplate="item">
    <ng-container [ngTemplateOutlet]="elementTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
  </ng-template>

  <ng-template pTemplate="previousicon">
    <ng-icon name="heroChevronLeftSolid" [size]="'20px'"></ng-icon>
  </ng-template>

  <ng-template pTemplate="nexticon">
    <ng-icon name="heroChevronRightSolid" [size]="'20px'"></ng-icon>
  </ng-template>
</p-carousel>
