import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

import { DropdownListPopulatedComponent } from '../dropdown-list-populated/dropdown-list-populated.component';

import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { ReferenceTableData } from '../../models/ReferenceTableData';
import { ReferenceTablesService } from '../../services/reference-tables.service';

@Component({
  selector: 'app-dropdown-list-populated-with-other',
  templateUrl: './dropdown-list-populated-with-other.component.html',
  styleUrls: ['./dropdown-list-populated-with-other.component.scss'],
  standalone: true,
  imports: [DropdownListPopulatedComponent, NgIf, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule],
})
export class DropdownListPopulatedWithOtherComponent implements OnInit {
  /**
   * referenceTable input, set this name to have an autocomplete liste
   *
   * @type {string}
   * @memberof DropdownListPopulatedWithOtherComponent
   */
  @Input() referenceTable: string;

  /**
   * searchable input, set it to have a filter
   *
   * @type {boolean}
   * @memberof DropdownListPopulatedWithOtherComponent
   */

  @Input() searchable: boolean;

  /**
   * Placeholder input
   *
   * @type {string}
   * @memberof DropdownListPopulatedWithOtherComponent
   */
  @Input() placeholder: string;

  /**
   * Parent form group
   *
   * @type {FormGroup}
   * @memberof DropdownListPopulatedWithOtherComponent
   */
  @Input() parentForm: UntypedFormGroup;

  /**
   * Field name where it is used
   *
   * @type {string}
   * @memberof DropdownListPopulatedWithOtherComponent
   */
  @Input() fieldName: string;

  /**
   * Get submit from parentForm
   *
   * @type {boolean}
   * @memberof DropdownListPopulatedWithOtherComponent
   */
  @Input() isSubmit: boolean;

  /**
   * Get if reference table should be translated or not
   *
   * @type {BehaviorSubject}
   * @memberof DropdownListPopulatedWithOtherComponent
   */
  @Input() notTranslated: boolean;

  /**
   * ParentForm to add controls to
   *
   * @type {FormGroup}
   * @memberof RadioButtonPopulatedComponent
   */

  @Input() dataToInit;

  /**
   * Get required from parentForm
   *
   * @type {BehaviorSubject}
   * @memberof DropdownListPopulatedComponent
   */
  @Input() required: boolean;

  /**
   * items attribute
   *
   * @type {ReferenceTableData}
   * @memberof DropdownListPopulatedWithOtherComponent
   */
  public items: ReferenceTableData[];

  /**
   * selectedItem attribute
   *
   * @type {ReferenceTableData}
   * @memberof DropdownListPopulatedWithOtherComponent
   */
  public selectedItem: ReferenceTableData;

  /**
   * Boolean know if user choosed 'Other' or not
   *
   * @type {boolean}
   * @memberof DropdownListPopulatedWithOtherComponent
   */
  public isOther: boolean;

  /**
   * Control on input field if 'OTHER' is selected
   *
   * @type {FormControl}
   * @memberof DropdownListPopulatedWithOtherComponent
   */
  public otherControl: FormControl;

  /**
   *  Field name for dropdown field
   *
   * @type {string}
   * @memberof DropdownListPopulatedWithOtherComponent
   */
  public fieldNameId: string;

  /**
   *  Field name for 'OTHER' input field
   *
   * @type {string}
   * @memberof DropdownListPopulatedWithOtherComponent
   */
  public fieldNameOther: string;

  /**
   * firstLoad attribute
   *
   * @type {boolean}
   * @memberof FileDropComponent
   */
  public firstLoad: boolean;

  /**
   * Creates an instance of DropdownListPopulatedWithOtherComponent.
   * @param {ReferenceTablesService} referenceTablesService
   * @param {I18nService} i18nService
   * @memberof DropdownListPopulatedWithOtherComponent
   */
  constructor(
    public i18nService: I18nService,
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly snackbarService: SnackbarService,
  ) {
    // Used to show input when 'OTHER' is selected
    this.isOther = false;
    // Used for autocomplete
    this.searchable = true;
    // Use this attribute to hide field required at the begining
    this.firstLoad = false;
  }

  /**
   * ngOnInit method
   *
   * @memberof DropdownListPopulatedWithOtherComponent
   */
  ngOnInit(): void {
    // Set dropdown field name
    this.fieldNameId = `${this.fieldName}Id`;
    // Set 'OTHER' input field name
    this.fieldNameOther = `${this.fieldName}Other`;

    // Add controls on fields
    this.parentForm.addControl(this.fieldNameOther, new UntypedFormControl('', []));
    this.otherControl = this.parentForm.controls[this.fieldNameOther] as FormControl;

    if (this.dataToInit && this.dataToInit[this.fieldNameOther]) {
      this.otherControl.setValue(this.dataToInit[this.fieldNameOther]);
    }
    // Get reference Table info
    if (this.notTranslated) {
      this.referenceTablesService.getTableReferenceInfo(this.referenceTable).subscribe(
        (itemsFound) => {
          this.items = itemsFound;
        },
        () => {
          this.snackbarService.sendErrorOccured();
        },
      );
    } else {
      this.referenceTablesService.getTableReferenceInfoTranslated(this.referenceTable).subscribe(
        (itemsFound) => {
          this.items = itemsFound;
        },
        () => {
          this.snackbarService.sendErrorOccured();
        },
      );
    }
  }

  /**
   * Check if 'OTHER' has been selected and setValue of the dropdown list
   * @param {*} event
   * @memberof DropdownListPopulatedWithOtherComponent
   */
  dropdownValueChanged(event): void {
    if (event.length !== 0 && event[0].label === this.i18nService._('OTHER')) {
      this.isOther = true;
      this.otherControl.setValidators([Validators.required]);
    } else {
      this.isOther = false;
      this.otherControl.setValidators([]);
      this.otherControl.reset();
    }
    this.firstLoad = true;
  }
}
