// bearer.interceptor.ts
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { isString } from 'lodash';

import { AccountService } from '../utils/services/account.service';
import { TokenService } from '../utils/services/authorisation/token.service';
import { I18nService } from '../utils/services/i18n.service';
import { TokenErrorEnum } from './token-error.enum';

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
  private readonly TOKEN_NOT_ACTIVE_MESSAGE = 'Token is not active';
  private readonly ACCESS_TOKEN_EXPIRE_DELAY_SECONDS = 50;

  private sessionExpiredErrorMessage: string;

  constructor(
    private readonly router: Router,
    private readonly tokenService: TokenService,
    private readonly accountService: AccountService,
    private readonly i18nService: I18nService,
  ) {
    this.sessionExpiredErrorMessage = this.i18nService._('TXT_session_expired_error');
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isApiRequest(request)) {
      return next.handle(request);
    }

    return this.handleRequest(request, next);
  }

  /**
   * The `handleRequest` function handles HTTP requests by adding an authorization header with a bearer
   * token, and handles any errors by checking if the token needs to be refreshed and making a refresh
   * token request if necessary.
   * @param request - The `request` parameter is an instance of `HttpRequest<unknown>`, which
   * represents an HTTP request made by the client. It contains information such as the request method,
   * URL, headers, and body.
   * @param {HttpHandler} next - The `next` parameter is an instance of the `HttpHandler` class. It
   * represents the next handler in the chain that will process the request.
   * @returns The function `handleRequest` returns an Observable of type `HttpEvent<unknown>`.
   */
  private handleRequest(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authorizedRequest = request.clone({
      setHeaders: { Authorization: `Bearer ${this.tokenService.getAccessToken()}` },
    });

    return next.handle(authorizedRequest).pipe(
      catchError((error: HttpErrorResponse) =>
        this.handleErrorResponse(error).pipe(
          switchMap((shouldRefresh: boolean) => {
            if (!shouldRefresh) {
              return throwError(error);
            }

            return this.accountService.refreshToken(request, next).pipe(
              catchError((error: HttpErrorResponse) => {
                this.navigateToLogin();
                return throwError(error);
              }),
            );
          }),
        ),
      ),
    );
  }

  /**
   * The function handles error responses from HTTP requests, checking for token errors and performing
   * appropriate actions based on the error type.
   * @param {HttpErrorResponse} error - The parameter `error` is of type `HttpErrorResponse`, which is
   * an object that represents an HTTP response error. It contains information about the error, such as
   * the status code, error message, and any additional error details.
   * @returns The function `handleErrorResponse` returns an Observable<boolean>. The boolean is true if
   * the app should refresh the auth tokens
   */
  private handleErrorResponse(error: HttpErrorResponse): Observable<boolean> {
    if (!this.isTokenError(error)) {
      console.warn('Erreur :', error);
      return throwError(error);
    }

    if (this.is401Html(error)) {
      return of(true);
    }

    const errorMessage = error.error.error_description || error.error.message || error.error.error.message;
    const errorName = error.error.name;

    if (this.isExpiredTokenError(errorMessage)) {
      return of(true);
    }

    if (this.isUnauthorizedError(errorMessage)) {
      this.tokenService.deleteAllToken();
      return this.navigateToLogin();
    }

    if ((this.isBadRequestError(errorMessage) || errorName === 'TokenValidatorError') && errorMessage !== TokenErrorEnum.INVALID_REQUEST) {
      this.tokenService.deleteAllToken();
      console.error('TokenValidatorError :', error);
      error.error.error_description = this.sessionExpiredErrorMessage;
      error.error.message = this.sessionExpiredErrorMessage;
      this.navigateToLogin();
      return throwError(error);
    }

    const accessToken = this.tokenService.getAccessToken();
    const refreshToken = this.tokenService.getRefreshToken();
    if (errorMessage == TokenErrorEnum.INVALID_REQUEST && !accessToken && !refreshToken) {
      error.error.error_description = this.sessionExpiredErrorMessage;
      error.error.message = this.sessionExpiredErrorMessage;
      return throwError(error);
    }

    return of(false);
  }

  private isApiRequest(request: HttpRequest<unknown>): boolean {
    return /\/api\/.*$/.test(request.urlWithParams);
  }

  private isTokenError(error: HttpErrorResponse): boolean {
    return error.status === 400 || error.status === 401 || error.status === 403;
  }

  private is401Html(error: HttpErrorResponse): boolean {
    return error.status === 401 && isString(error.error) && error.error.indexOf('<html') >= 0;
  }

  private isExpiredTokenError(errorMessage: string): boolean {
    return errorMessage.indexOf(TokenErrorEnum.EXPIRED_TOKEN) >= 0;
  }

  private isUnauthorizedError(errorMessage: string): boolean {
    return [TokenErrorEnum.INVALID_CLIENT, TokenErrorEnum.UNAUTHORIZED_CLIENT].includes(errorMessage as TokenErrorEnum);
  }

  private isBadRequestError(errorMessage: string): boolean {
    return (
      errorMessage === TokenErrorEnum.INVALID_GRANT ||
      errorMessage === this.TOKEN_NOT_ACTIVE_MESSAGE ||
      errorMessage === TokenErrorEnum.INVALID_REFRESH_TOKEN ||
      errorMessage === TokenErrorEnum.SESSION_NOT_ACTIVE ||
      errorMessage === TokenErrorEnum.SESSION_NOT_ACTIVE_ON_REFRESH_TOKEN
    );
  }

  private navigateToLogin(): Observable<boolean> {
    this.router.navigate(['/login']);
    return of(false);
  }
}
