import { AbstractControl, ValidatorFn } from '@angular/forms';

import { ReferenceTableData } from '../models/ReferenceTableData';

export const zipCodeValidator = (zipCodeList: Array<ReferenceTableData>): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } | undefined => {
    const isInclude = zipCodeList.map((zipCode) => zipCode.label).includes(control.value);

    if (isInclude) {
      return undefined;
    }

    return { zipCode: true };
  };
};
