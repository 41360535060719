import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgIf } from '@angular/common';

import { tagClass, TagOption } from '../../model/tag-option';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, MatIconModule],
})
export class TagsComponent {
  @Input() tagOption: TagOption;
  public tagClass = tagClass;
}
