import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Map, MapboxEvent } from 'mapbox-gl';

import { GoogleTagManagerService } from '../../utils/services/google-tag-manager.service';
import { ProgramDetailResponse } from '../../utils/models/ProgramDetailResponse';

@Injectable({
  providedIn: 'root',
})
export class ValorissimoMapService {
  private _mapEvents: BehaviorSubject<MapboxEvent>;

  constructor(private readonly _googleTagManagerService: GoogleTagManagerService) {
    this._mapEvents = new BehaviorSubject<MapboxEvent>(undefined);
  }

  get mapEvents(): BehaviorSubject<MapboxEvent> {
    return this._mapEvents;
  }

  set mapEvents(value: BehaviorSubject<MapboxEvent>) {
    this._mapEvents = value;
  }

  /**
   * Methode to dispatch a mapEvent to the stream
   *
   * @readonly
   * @type {*}
   * @memberof MapService
   */
  dispatchMapEvent(mapEvent: MapboxEvent): void {
    this._mapEvents.next(mapEvent);
  }

  /**
   * Methode to define MapEvents handlers
   *
   * @readonly
   * @type {*}
   * @memberof MapService
   */
  defineMapEventsHandlers(map: Map): void {
    map.on('click', (ev: MapboxEvent) => {
      console.log('Click Map Event avec bounds');
      this.dispatchMapEvent(ev);
    });
    map.on('styleimagemissing', (ev: MapboxEvent) => {
      console.log('styleimagemissing Map Event');
      console.log(ev);
    });
    map.on('zoom', (ev: MapboxEvent) => {
      console.log('zoom Map Event');
      console.log(ev);
    });
  }

  pushClicked(event: string, program?: ProgramDetailResponse) {
    let dataToPush: object;
    if (program) {
      dataToPush = {
        event: event,
        program: {
          name: program.programName,
          id: program.programId,
          postalCode: program.postalCode,
          city: program.city,
          department: program.postalCode ? program.postalCode.slice(0, 2) : '',
          region: null,
          promoteurName: null,
          actable: program.actable,
          dematEligibility: '',
          lotsAvailable: '',
          publicationDate: program.publishedDate,
        },
      };
    } else {
      dataToPush = {
        event: event,
      };
    }

    this._googleTagManagerService.pushTag(dataToPush);
  }
}
