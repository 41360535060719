import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, Subject, tap } from 'rxjs';

import { AccountAsResponse } from '../models/AccountAsResponse';
import { JwtToken, LoginResponse } from '../models/LoginResponse';
import { AccountService } from './account.service';
import { AppConfigService } from './app-config.service';
import { TokenService } from './authorisation/token.service';
import { CacheService } from './cache.service';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class AccountAsService {
  /**
   * Subject observable use for describe the state of the 'login as'
   * @see https://rxjs-dev.firebaseapp.com/guide/subject
   */
  private readonly isConnectedAs = new Subject<boolean>();
  /**
   * Do not expose the Subject object directly to components.
   * Instead, return a good old Observable version of it.
   * Subscribers will be able to disturb the subject object.
   */
  public readonly isConnectedAsEvent$ = this.isConnectedAs.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
    private readonly _cacheService: CacheService,
    private readonly _tokenService: TokenService,
    private readonly _accountService: AccountService,
  ) {}

  /**
   * login
   *
   * @param accountId
   * @memberof AccountAsService
   */
  public loginAs(accountId: number): Observable<JwtToken> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Accounts/login-as`;

    return this.http.post<JwtToken>(url, { accountId }).pipe(
      tap((res) => {
        this._cacheService.clear();
        this._tokenService.setToken(res, true);
      }),
      catchError(
        this.errorHandlerService.handleError<JwtToken>('accountAsService', 'login-as', {
          401: this.errorHandlerService.errorFnFactory[401]({
            snackbar: true,
            redirect: false,
          }),
          403: this.errorHandlerService.errorFnFactory[403]({
            snackbar: true,
            redirect: false,
          }),
        }),
      ),
    );
  }

  /**
   * logout
   *
   * @memberof AccountAsService
   */
  public logoutAs(): Observable<LoginResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Accounts/logout-as`;

    return this.http.post<LoginResponse>(url, { token: this._tokenService.getRefreshToken() }).pipe(
      tap(() => {
        this._tokenService.deleteToken(true);
      }),
    );
  }

  /**
   * Find user
   *
   * @memberof AccountAsService
   */
  public getAccountsAs(lastName: string): Observable<Array<AccountAsResponse>> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Accounts/get-accounts-as`;

    return this.http
      .post<Array<AccountAsResponse>>(url, { lastName })
      .pipe(catchError(this.errorHandlerService.handleError<Array<AccountAsResponse>>('accountAsService', 'getAccountsAs')));
  }

  /**
   * Function use to modify the state of <isConnectedAs> subject.
   * @param {boolean} state - The new state of the 'login as' feature.
   */
  public changeState(state: boolean): void {
    this.isConnectedAs.next(state);
  }
}
