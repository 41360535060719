import { Component, Input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';

import { Sitemap } from '../../../utils/models/Sitemap';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { ActivityCategories } from '../../enum/activity-categories.enum';
import { Activity } from '../../models/Activity';

@Component({
  selector: 'app-last-activities',
  templateUrl: './last-activities.component.html',
  styleUrls: ['./last-activities.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, NgClass, MatCardModule, RouterLink, DatePipe],
})
export class LastActivitiesComponent {
  @Input() category: ActivityCategories;
  @Input() activities: Array<Activity>;

  constructor(
    public i18nService: I18nService,
    private readonly appConfigService: AppConfigService,
    private readonly router: Router,
  ) {}

  getActivityURI = (activity: Activity) => {
    return activity.url.split('?')[0];
  };

  getActivityParams = (activity: Activity) => {
    let params = [];
    if (activity.url.split('?').length > 1) {
      params = activity.url.split('?')[1].split('&');
    }
    let parsedParams = {};

    const activityTypeDelivery = this.appConfigService.getAppConfig().activityLabels.deliveryDateLot;
    if (activity.activityTypeLabel === activityTypeDelivery) {
      parsedParams = { filter: activity.programName };
    }

    params.forEach((param) => {
      const [key, value] = param.split('=');
      parsedParams[key] = value;
    });

    return parsedParams;
  };

  isActionCategory = () => {
    return this.category === ActivityCategories.ACTION;
  };

  navigateToActivities(): void {
    this.router.navigate([this.isActionCategory() ? Sitemap.dashboards.action.path : Sitemap.dashboards.info.path]);
  }
}
