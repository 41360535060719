/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { FormlyFieldSelectProps, FormlySelectModule } from '@ngx-formly/core/select';
import { isObservable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';

interface SelectProps extends FormlyFieldProps, FormlyFieldSelectProps {
  multiple?: boolean;
  showClear?: boolean;
  optionLabel?: string;
  optionValue?: string;
  options?: any[];
  dropdownIcon?: string;
  showHeader?: boolean;
  maxSelectedLabels?: boolean;
}

@Component({
  selector: 'app-formly-dropdown-multiple',
  templateUrl: './formly-dropdown-multiple.component.html',
  styleUrls: ['./formly-dropdown-multiple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MultiSelectModule, FormsModule, ReactiveFormsModule, AsyncPipe, FormlySelectModule],
})
export class FormlyDropdownMultipleComponent extends FieldType<FieldTypeConfig<SelectProps>> {
  isObservable = isObservable;
}
