import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { NgClass, NgIf } from '@angular/common';

import { GenericActions } from '../../interfaces/GenericActions';
import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-generic-actions',
  templateUrl: './generic-actions.component.html',
  styleUrls: ['./generic-actions.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, MatMenuModule, MatProgressSpinnerModule],
})
export class GenericActionsComponent {
  /**
   * element attribute
   *
   * @type {any}
   * @memberof GenericActionsComponent
   */
  @Input() public element;

  /**
   * genericActions attribute
   *
   * @type {GenericActions}
   * @memberof GenericActionsComponent
   */
  @Input() public genericActions: GenericActions;

  /**
   * Display or not the actions in menu
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public isMenu = false;

  /**
   * Display or not the create span
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public isCreatable = false;

  /**
   * Display or not the delete span
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public isDeletable = false;

  /**
   * Display or not the edit span
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public isEditable = false;

  /**
   * validate tooltip text
   *
   * @type {string}
   * @memberof GenericActionsComponent
   */
  @Input() public validateToolTip: string;

  /**
   * edit tooltip text
   *
   * @type {string}
   * @memberof GenericActionsComponent
   */
  @Input() public editToolTip: string;

  /**
   * Display or not the edit span
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public isRedirected = false;

  /**
   * Display or not the accept span
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public isAccepted = false;

  /**
   * Display or not the accept span
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public isCompanyValidated = false;

  /**
   * Display or not the desktop publishing span
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public desktopPublishingEnable = false;

  /**
   * Display or not the refuse span
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public isRefused = false;

  /**
   * Display or not the check span
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public isChecked = false;

  /**
   * Disable or not
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public menuDisable = false;

  /**
   * redirect tooltip text
   *
   * @type {string}
   * @memberof GenericActionsComponent
   */
  @Input() public redirectToolTip: string;

  /**
   * can Cancel or not
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canCancel = false;

  /**
   * can DownloadContractualBundle or not
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canDownloadContractualBundle = false;

  /**
   * can ShowPreReservation or not
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canShowPreReservation = false;

  /**
   * can GotoReservation or not
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canGotoReservation = false;

  /**
   * can ShowReservation or not
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canShowReservation = false;

  /**
   * can OnlineSign or not
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canOnlineSign = false;
  @Input() public canContinueOnlineSign = false;

  /**
   * can OnlineSignValo or not
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canOnlineSignValo = false;
  @Input() public canContinueOnlineSignValo = false;

  /**
   * can ShowMotive or not
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canShowMotive = false;

  /**
   * can Remove or not
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canRemove = false;

  /**
   * can Extend or not
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canExtend = false;

  /**
   * can PreReserve or not
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canPreReserve = false;

  /**
   * Display or not the edit span for advanced table
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canUpdateProgram = false;

  /**
   * Display or not the redirect span for advanced table
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canRedirectToStrategies = false;

  /**
   * Display or not the validate span for advanced table
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canValidateProgram = false;

  /**
   * Display or not the publish span for advanced table
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canPublishProgram = false;

  /**
   * Display or not the unpublish span for advanced table
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canUnpublishProgram = false;

  /**
   * Display or not the accept paternity span for advanced table
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canAcceptPaternity = false;

  /**
   * Display or not the refuse paternity span for advanced table
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canRefusePaternity = false;

  /**
   * Display or not the check paternity span for advanced table
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canCheckPaternity = false;

  /**
   * Display or not the strategies button for advanced table
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public hasStrategies = false;

  /**
   * Display or not the strategies button for advanced table
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canEditSearch = false;

  /**
   * Display or not the strategies button for advanced table
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canDeleteSearch = false;

  /**
   * Display or not the DownloadReservationContrat link for advanced table
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canDownloadReservationContract = false;

  /**
   * Display or not the show lot details link for advanced table
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canShowLotDetails = false;
  @Input() public readonly = false;

  /**
   * Can modify special offer
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canUpdate = false;

  /**
   * Display or not the edit span for advanced table
   *
   * @type {boolean}
   * @memberof GenericActionsComponent
   */
  @Input() public canExclude = false;

  /**
   * templateRef attribute
   *
   * @type {TemplateRef<any>}
   * @memberof GenericActionsComponent
   */
  @ViewChild('templateRef', { static: true })
  public templateRef: TemplateRef<unknown>;

  constructor(public i18nService: I18nService) {}
}
