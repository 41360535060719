<div class="px-4">
  <h2 class="text-center" mat-dialog-title>
    {{ i18nService._('Title_Check_Paternity_Dialog') }}
  </h2>
  <p class="text-sm text-center">
    {{ i18nService._('Txt_Check_Paternity_Dialog') }}
  </p>
  <form class="flex flex-col" (submit)="check()" [formGroup]="checkPaternityFormGroup">
    <mat-dialog-content class="min-vh-30">
      <mat-tab-group>
        <mat-tab label="{{ i18nService._('Title_Check_Paternity_Email_Tab') }}">
          <mat-form-field class="flex w-full mb-2 mt-3 min-vh-30">
            <mat-label>{{ i18nService._('Txt_Placeholder_Email') }}</mat-label>
            <input [formControl]="emailControl" matInput maxlength="255" name="email" type="text" data-cy="email" />
            <mat-error *ngIf="emailControl.errors && !emailControl.errors.email && !emailControl.errors.required">
              <span>{{ i18nService._('Error_Field_IncorrectEmail') }}</span>
            </mat-error>
          </mat-form-field>
        </mat-tab>
        <mat-tab label="{{ i18nService._('Title_Check_Paternity_Phone_Tab') }}">
          <div class="flex flex-row w-full mb-2 mt-3 pt-3 min-vh-30">
            <app-phone-number-information [isSubmit]="isSubmit" [parentForm]="checkPaternityFormGroup" class="w-full">
            </app-phone-number-information>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-dialog-content>

    <mat-error
      class="flex w-full mb-3"
      *ngIf="checkPaternityFormGroup.touched && checkPaternityFormGroup.errors && checkPaternityFormGroup.errors.atLeastOne"
    >
      <span class="d-table">{{ i18nService._('Error_Field_EmailOrPhoneRequired') }}</span>
    </mat-error>

    <mat-dialog-actions class="flex flex-row w-full">
      <div class="flex flex-col md:flex-row justify-center items-center mb-3">
        <button
          (click)="cancel()"
          data-cy="cancel"
          class="mr-0 lg:mr-4 md:mr-4 sm:mr-4 mb-3"
          color="primary"
          mat-stroked-button
          type="reset"
        >
          {{ i18nService._('Txt_Button_Cancel') }}
        </button>
        <button data-cy="check" class="mb-3" color="primary" mat-flat-button>
          {{ i18nService._('Txt_Button_Check') }}
        </button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
