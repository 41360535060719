<ng-container>
  <!-- DESKTOP -->
  <button
    *ngIf="!isMobile && !menuConfig['mustBeDisabled']"
    [matMenuTriggerFor]="menu"
    #menuTrigger="matMenuTrigger"
    class="inline-flex flex-row items-center opacity-60 hover:opacity-100 transition-all ease-in-out mx-4"
  >
    <mat-icon [svgIcon]="menuConfig['icon']" class="h-5 w-5"></mat-icon>
    <span *ngIf="isMobile || menuConfig['showLabel']" class="text-sm -mb-px font-semibold text-black hidden lg:inline-block ml-2">
      {{ i18nService._(menuConfig['label']) }}
    </span>
    <mat-icon
      *ngIf="menuConfig['showLabel']"
      [svgIcon]="menuConfig['isExternalLink'] ? 'newTab' : 'dropdown'"
      class="h-2 w-2 ml-1 -mb-px hidden lg:inline-block"
    ></mat-icon>
  </button>

  <!-- MOBILE -->
  <button
    *ngIf="isMobile && !menuConfig['mustBeDisabled']"
    mat-menu-item
    [matMenuTriggerFor]="menu"
    #menuTrigger="matMenuTrigger"
    [ngClass]="isFirst ? '' : 'border-t border-gray-secondary'"
  >
    {{ i18nService._(menuConfig['label']) }}
  </button>

  <!-- MENU -->
  <mat-menu #menu="matMenu" [overlapTrigger]="false" [hasBackdrop]="true" addPanelClass="mt-30">
    <div class="talkbubble">
      <ng-container *ngFor="let item of menuConfig['items']; let last = last" class="valo_text_links">
        <a
          ariaLabel="{{ item.path }}"
          [routerLink]="item.path"
          *ngIf="!item.subMenu && !item.externalLink && !item.mustBeHidden"
          (click)="item.action ? callbackAction(item) : undefined"
          mat-menu-item
          class="valo_text_links dropdown"
          [ngClass]="item.class ? item.class : 'default-menu-item'"
        >
          {{ i18nService._(item.label) }}
        </a>
        <a
          [attr.href]="item.externalLink"
          *ngIf="!item.subMenu && item.externalLink && !item.mustBeHidden"
          target="_blank"
          mat-menu-item
          class="valo_text_links dropdown"
          [ngClass]="item.class ? item.class : 'default-menu-item'"
        >
          {{ i18nService._(item.label) }}
        </a>
        <div
          *ngIf="item.subMenu && !item.mustBeHidden"
          class="valo_text_links dropdown"
          [ngClass]="item.class ? item.class : 'submenu-item'"
        >
          {{ i18nService._(item.label) }}
          <div class="pt-2">
            <div *ngFor="let subMenuItem of item.subMenu" class="valo_text_links">
              <a [attr.href]="subMenuItem.externalLink" target="_blank" class="valo_navigation_dropdown_contact">
                {{ subMenuItem.label }}
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="!last" class="valo_separator"></div>
      </ng-container>
    </div>
  </mat-menu>
</ng-container>
