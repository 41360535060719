import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf } from '@angular/common';

import { I18nService } from '../../../utils/services/i18n.service';
import { CheckboxDialogData } from '../../models/CheckboxDialogData';
import { CheckboxDialogResponse } from '../../models/CheckboxDialogResponse';
import { SafePipe } from '../../../utils/pipes/safe.pipe';

@Component({
  selector: 'app-checkbox-dialog',
  templateUrl: './checkbox-dialog.component.html',
  styleUrls: ['./checkbox-dialog.component.scss'],
  standalone: true,
  imports: [NgIf, MatDialogModule, MatCheckboxModule, FormsModule, MatButtonModule, SafePipe],
})
export class CheckboxDialogComponent implements OnInit {
  /**
   * isChecked attribute
   *
   * @type {boolean}
   * @memberof CheckboxDialogComponent
   */
  public isChecked: boolean;

  /**
   * Creates an instance of CheckboxDialogComponent.
   * @param {I18nService} i18nService
   * @param {MatDialogRef<CheckboxDialogComponent>} dialogRef
   * @param {CheckboxDialogData} data
   * @memberof CheckboxDialogComponent
   */
  constructor(
    public i18nService: I18nService,
    public dialogRef: MatDialogRef<CheckboxDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CheckboxDialogData,
  ) {}

  ngOnInit(): void {
    this.isChecked = false;
  }

  public clickExitButton(): void {
    const checkboxResponse: CheckboxDialogResponse = {
      checked: this.isChecked,
    };
    this.dialogRef.close(checkboxResponse);
  }
}
