import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';

import { DocumentResponse } from '../../models/DocumentResponse';
import { FooterContact } from '../../models/footerContact';
import { LoggedFooterResponse } from '../../models/LoggedFooterResponse';
import { AppConfigService } from '../../services/app-config.service';
import { TokenService } from '../../services/authorisation/token.service';
import { ConfigurableParamService } from '../../services/configurable-param.service';
import { FileSystemService } from '../../services/file-system.service';
import { I18nService } from '../../services/i18n.service';
import { RoutingStateService } from '../../services/routing-state.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, NgClass, AsyncPipe],
})
export class FooterComponent implements OnDestroy {
  public contacts: Array<FooterContact> = new Array<FooterContact>();
  /**
   * isLoggedIn attribute.
   *
   * @type {boolean}
   *
   * @memberof FooterComponent
   */
  public isLoggedIn = false;

  /**
   * currentUrlAfterRedirectObservable$ attribute to know the current url after a redirection.
   *
   * @type {Observable<string>}
   *
   * @memberof FooterComponent
   */
  public currentUrlAfterRedirectObservable$: Observable<string>;

  /**
   * isLoggedInSubscription attribute
   *
   * @type {Subscription}
   * @memberof FooterComponent
   */
  private readonly isLoggedInSubscription: Subscription;

  /**
   * _destroy$
   *
   * @type {Subject}
   * @memberof FooterComponent
   */
  private readonly _destroy$: Subject<boolean> = new Subject<boolean>();

  /**
   * About us attribut
   */
  private readonly whoAreWeDocument: DocumentResponse;

  /**
   * Creates an instance of FooterComponent.
   *
   * @param {I18nService} i18nService
   * @param {AppConfigService} appConfigService
   * @param {TokenService} tokenService
   *
   * @memberof FooterComponent
   */
  constructor(
    public i18nService: I18nService,
    private readonly appConfigService: AppConfigService,
    private readonly tokenService: TokenService,
    private readonly configurableParam: ConfigurableParamService,
    private readonly routingStateService: RoutingStateService,
    private readonly fileSystemService: FileSystemService,
  ) {
    // Get if user is login or not.
    this.isLoggedInSubscription = this.tokenService.getLoggedInObservable().subscribe((isLoggedIn: boolean) => {
      this.isLoggedIn = isLoggedIn;
      // Get footer param according if the user is logged or not
      this.configurableParam.loggedFooterResponse$.pipe(takeUntil(this._destroy$)).subscribe((loggedFooterData: LoggedFooterResponse) => {
        if (loggedFooterData) {
          this.contacts = [loggedFooterData.developerContactInfo, loggedFooterData.contractorContactInfo];
        }
      });
    });

    // Allow to reload this component and display the content updated (css class + content for logged users)
    this.currentUrlAfterRedirectObservable$ = this.routingStateService.currentUrlAfterRedirectObservable;
  }

  openWhoAreWe(): void {
    this.fileSystemService.openDocumentInBrowser(this.whoAreWeDocument, this.whoAreWeDocument.title);
  }

  /**
   * ngOnDestroy method
   *
   * @memberof FooterComponent
   */
  ngOnDestroy(): void {
    this.isLoggedInSubscription.unsubscribe();
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
