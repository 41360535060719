import { FormlyFieldConfig } from '@ngx-formly/core';

import { Expression } from '../../utils/services/calcul-engine/meta-expression';
import { MetaExpressionService } from '../../utils/services/calcul-engine/meta-expression.service';

export class FormlyFieldPropertiesService {
  static computeMetaExpression(expression: Expression, data: unknown = undefined, key: string, field: FormlyFieldConfig): void {
    field.props[key] = MetaExpressionService.computeExpression(expression, data);
    if (field.type === 'localization') {
      field.props.city[key] = MetaExpressionService.computeExpression(expression, data);
      field.props.postalCode[key] = MetaExpressionService.computeExpression(expression, data);
    }
  }
}
