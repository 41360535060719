import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { I18nService } from '../services/i18n.service';

@Injectable()
export class I18nMatPaginatorIntl extends MatPaginatorIntl {
  /**
   * Custom MatPaginatorIntl using I18n service
   * @param {i18nService} I18nService translation service
   * @memberof I18nMatPaginatorIntl
   */
  constructor(private readonly i18nService: I18nService) {
    super();
    // Translate Paginator
    this.itemsPerPageLabel = this.i18nService._('Txt_Paginator_ItemsPerPageLabel');
    this.firstPageLabel = this.i18nService._('Txt_Paginator_FirstPageLabel');
    this.previousPageLabel = this.i18nService._('Txt_Paginator_PreviousPageLabel');
    this.nextPageLabel = this.i18nService._('Txt_Paginator_NextPageLabel');
    this.lastPageLabel = this.i18nService._('Txt_Paginator_LastPageLabel');
    this.changes.next();
  }

  /**
   * A label based on I18n for the range of items within the current page and the length of the whole list.
   * @param {page} number the current page of the paginator
   * @param {pageSize} number the current page size of the paginator
   * @param {length} number the length of th paginator
   * @memberof I18nMatPaginatorIntl
   */
  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.i18nService._('Txt_Paginator_RangeLabel')} ${length}`;
    }

    const lengthOrZero = Math.max(length, 0);

    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < lengthOrZero ? Math.min(startIndex + pageSize, lengthOrZero) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.i18nService._('Txt_Paginator_RangeLabel')} ${lengthOrZero}`;
  };
}
