<div [formGroup]="specialOfferForm">
  <div formGroupName="conditions">
    <div class="grid grid-cols-2 gap-16 mb-5">
      <!-- Program name -->
      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_specialOffer_register_step1_companyName') }}</mat-label>
        <input
          data-cy="specialOfferRegisterStep1CompanyName"
          type="text"
          formControlName="company"
          name="company"
          matInput
          maxlength="255"
          required
          [matAutocomplete]="companyAutoComplete"
        />

        <mat-autocomplete #companyAutoComplete="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let company of companyListFiltered" [value]="company" [attr.data-cy]="company.corporateName">
            {{ company.corporateName }}
          </mat-option>
        </mat-autocomplete>
        <mat-error
          *ngIf="
            formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.company, specialOfferForm) &&
            formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.company, specialOfferForm).errors?.required
          "
        >
          {{ i18nService._('Error_Field_Required') }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="grid grid-cols-2 gap-16 mb-5">
      <!-- Accounting program ref -->
      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_specialOffer_register_step1_OSRef') }}</mat-label>
        <input
          type="text"
          name="specialOfferRef"
          data-cy="specialOfferRegisterStep1OsRef"
          formControlName="specialOfferRef"
          maxlength="10"
          matInput
        />
        <mat-error
          *ngIf="
            formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.specialOfferRef, specialOfferForm) &&
            formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.specialOfferRef, specialOfferForm).errors?.specialOfferRefExist
          "
        >
          {{ i18nService._('Error_field_specialOfferRef') }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="grid grid-cols-2 gap-16 mb-5">
      <!-- Accounting program ref -->
      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_specialOffer_register_step1_OSType') }}</mat-label>
        <mat-select formControlName="specialOfferType" [required]="true" multiple data-cy="specialOfferRegisterStep1OSType">
          <mat-option *ngFor="let soType of soTypeList" [value]="soType.id" [attr.data-cy]="soType.label">
            {{ soType.label }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.specialOfferType, specialOfferForm) &&
            formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.specialOfferType, specialOfferForm).errors?.required
          "
        >
          {{ i18nService._('Error_Field_Required') }}
        </mat-error>
      </mat-form-field>

      <!-- Accounting program ref -->
      <mat-form-field
        class="col-span-1 js-test-special-offer-type-other"
        [hidden]="!isSoTypeOther(formUtils.getFormValue(SPECIAL_OFFER_CONTROL_PATH.specialOfferType, specialOfferForm))"
      >
        <mat-label>{{ i18nService._('Txt_specialOffer_register_step1_OSTypeOther') }}</mat-label>
        <input
          type="text"
          data-cy="Autre"
          formControlName="specialOfferTypeOther"
          name="specialOfferTypeOther"
          maxlength="255"
          matInput
          [required]="isSoTypeOther(formUtils.getFormValue(SPECIAL_OFFER_CONTROL_PATH.specialOfferType, specialOfferForm))"
        />

        <mat-error
          *ngIf="
            formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.specialOfferTypeOther, specialOfferForm) &&
            formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.specialOfferTypeOther, specialOfferForm).errors?.required
          "
        >
          {{ i18nService._('Error_Field_Required') }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="grid grid-cols-2 gap-16 mb-5" formGroupName="specialOfferText">
      <!-- Accounting program ref -->
      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_specialOffer_register_step1_label') }}</mat-label>
        <input
          type="text"
          name="title"
          data-cy="specialOfferRegisterStep1Label"
          formControlName="title"
          maxlength="255"
          required
          matInput
        />
        <mat-error
          *ngIf="
            formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.title, specialOfferForm) &&
            formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.title, specialOfferForm).errors?.required
          "
        >
          {{ i18nService._('Error_Field_Required') }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="grid grid-cols-2 gap-16 mb-5" formGroupName="specialOfferText">
      <mat-form-field class="col-span-2">
        <mat-label>{{ i18nService._('Txt_specialOffer_register_step1_description') }}</mat-label>
        <textarea
          matInput
          name="description"
          formControlName="description"
          data-cy="specialOfferRegisterStep1Description"
          maxlength="255"
          required
        >
        </textarea>
        <mat-error
          *ngIf="
            formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.description, specialOfferForm) &&
            formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.description, specialOfferForm).errors?.required
          "
        >
          {{ i18nService._('Error_Field_Required') }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="grid grid-cols-2 gap-16 mb-5" formGroupName="specialOfferText">
      <mat-form-field class="col-span-2">
        <mat-label>{{ i18nService._('Txt_specialOffer_register_step1_legalMention') }}</mat-label>
        <textarea
          matInput
          [cdkTextareaAutosize]="true"
          name="legalNotice"
          attr.data-cy="{{ i18nService._('Txt_specialOffer_register_step1_legalMention') }}"
          formControlName="legalNotice"
          required
          maxlength="2000"
        >
        </textarea>
        <mat-error
          *ngIf="
            formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.legalNotice, specialOfferForm) &&
            formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.legalNotice, specialOfferForm).errors?.required
          "
        >
          {{ i18nService._('Error_Field_Required') }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="grid grid-cols-2 gap-16 mb-5">
      <app-input-date
        class="col-span-2 lg:col-span-1"
        fieldName="startDate"
        [applyTimeZone]="true"
        [forcePick]="false"
        [required]="true"
        [parentForm]="formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.conditions, specialOfferForm)"
        [placeholder]="i18nService._('Txt_specialOffer_register_step1_startDate')"
        data-cy="specialOfferRegisterStep1StartDate"
      ></app-input-date>
      <app-input-date
        class="col-span-2 lg:col-span-1"
        fieldName="endDate"
        [forcePick]="false"
        [applyTimeZone]="true"
        [required]="true"
        [parentForm]="formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.conditions, specialOfferForm)"
        [endOfTheDay]="true"
        [placeholder]="i18nService._('Txt_specialOffer_register_step1_endDate')"
        data-cy="specialOfferRegisterStep1EndDate"
      >
      </app-input-date>
    </div>

    <div class="grid grid-cols-2 gap-16 mb-5">
      <app-file-drop
        [fieldNameKey]="'amendment'"
        [attachmentName]="i18nService._('Txt_specialOffer_register_step1_document')"
        data-cy="specialOfferRegisterStep1Document"
        [required]="true"
        [includingForm]="formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.conditions, specialOfferForm)"
        [isSubmit]="submit"
        [initialValue]="amendment"
        [isDisabled]="formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.amendment, specialOfferForm).disabled"
        [validExt]="['application/pdf', 'image/png', 'image/jpg', 'image/jpeg']"
        class="col-span-2 lg:col-span-1"
      >
      </app-file-drop>
      <app-file-drop
        [fieldNameKey]="'amendmentWithFusionFields'"
        [attachmentName]="i18nService._('Txt_specialOffer_register_step1_document_with_fusion')"
        attr.data-cy="{{ i18nService._('Txt_specialOffer_register_step1_document_with_fusion') }}"
        [includingForm]="formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.conditions, specialOfferForm)"
        [isSubmit]="submit"
        [initialValue]="amendmentWithFusionFields"
        [isDisabled]="formUtils.getControl(SPECIAL_OFFER_CONTROL_PATH.amendmentWithFusionFields, specialOfferForm).disabled"
        [validExt]="['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']"
        class="col-span-2 lg:col-span-1"
      >
      </app-file-drop>
    </div>
  </div>
</div>
