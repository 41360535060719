<app-dashboard-card icon="Calculator" title="Txt_DossierProspectSimulation" [complete]="complete">
  <div class="detail no-simulation" *ngIf="!complete">{{ 'Txt_DossierProspectSimulationNoSimulation' | translate }}</div>
  <div class="detail" *ngIf="complete">
    <div class="simulation">
      <div class="payement-capacity">
        <div class="title">{{ 'Txt_DossierProspectSimulationPurchasingCapacity' | translate }}:</div>
        <div class="value">{{ simulation?.purchasingCapacity | currency: 'EUR' : true : '1.0' }}</div>
      </div>
      <div class="loan-capacity">
        <div class="title">{{ 'Txt_DossierProspectSimulationLoanCapacity' | translate }}:</div>
        <div class="value">{{ simulation?.loanCapacity | currency: 'EUR' : true : '1.0' }}</div>
      </div>
      <div class="personel-funds">
        <div class="title">{{ 'Txt_DossierProspectSimulationPersonalFunds' | translate }}:</div>
        <div class="value">{{ simulation?.personalFunds | currency: 'EUR' : true : '1.0' }}</div>
      </div>
    </div>
  </div>
  <footer>
    <app-button [type]="ButtonType.PRIMARY" class="button-simulation" *ngIf="!complete" (click)="goToSimulation()">
      {{ 'Txt_DossierProspectSimulationButton' | translate }}
    </app-button>
    <app-button [type]="ButtonType.SECONDARY" class="button-simulation" *ngIf="complete" (click)="goToSimulation()">
      {{ 'Txt_DossierProspectDetailSimulationButton' | translate }}
    </app-button>
  </footer>
</app-dashboard-card>
