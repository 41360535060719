/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

import { DatesFilterEnum } from '../../../../utils/models/enums/dates-filter.enum';
import { CompanyStatusEnum } from './../../../../utils/enums/statusEnums/company-status.emun';
import { DesktopPublishingDialogComponent } from '../../../../dialog/components/desktop-publishing-dialog/desktop-publishing-dialog.component';
import { DesktopPublishingDialogData } from '../../../../dialog/models/DesktopPublishingDialogData';
import { ProfessionalCategoryTypeApiService } from '../../../../adapters/professional-category-type-api.service';
import { CompaniesTrackingTableService } from '../../../services/companies-tracking-table.service';
import { I18nRendererComponent } from '../../../renderers/i18n-renderer/i18n-renderer.component';
import { StatusTagColor } from '../../../../design-system/model/status-tag-color.enum';
import { CompanyType } from '../../../../utils/models/enums/CompanyType.enum';
import { Item } from '../../../models/Item';
import { I18nService } from '../../../../utils/services/i18n.service';
import { FilterEventOutput } from '../../../models/filter/filter-event-output';
import { AbstractTrackingTable } from '../../../abstract-tracking-table';
import { BooleanRendererComponent } from '../../../renderers/boolean-renderer/boolean-renderer.component';
import { ActionRendererComponent } from '../../../renderers/action-renderer/action-renderer.component';
import { Sitemap } from '../../../../utils/models/Sitemap';
import { StatusTagsRendererComponent } from '../../../renderers/status-tags-renderer/status-tags-renderer.component';
import { GridTrackingTableComponent } from '../../grid-tacking-table/grid-tracking-table.component';
import { DateRendererComponent } from '../../../renderers/date-renderer/date-renderer.component';
import { ExcludeListDialogData } from '../../../models/dialog/company/exclude-list-dialog-data';
import { ExcludeListDialogComponent } from '../../exclude-list-tracking-table/dialog/exclude-list-dialog.component';

const tagTypeByStatus: { [key in CompanyStatusEnum]: StatusTagColor } = {
  PENDING: StatusTagColor.IN_PROGRESS,
  VALID: StatusTagColor.SUCCESS,
  INCOMPLETE: StatusTagColor.IN_PROGRESS,
  DENIED: StatusTagColor.CRITICAL,
};

@Component({
  selector: 'app-companies-tracking-table-tab',
  templateUrl: './companies-tracking-table-tab.component.html',
  styleUrls: ['./companies-tracking-table-tab.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, NgIf, MatSelectModule, NgFor, MatOptionModule, GridTrackingTableComponent],
})
export class CompanyTrackingTableTabComponent extends AbstractTrackingTable implements OnInit {
  @Input() type: CompanyType;

  @ViewChild(GridTrackingTableComponent)
  gridTrackingTable: GridTrackingTableComponent;

  pageSize = 10;
  pageNumber = 1;
  sortDirection: 'ASC' | 'DESC' = 'DESC';
  sortColumn = 'id';
  columnDefs: ColDef[];
  filterValue: string;
  i18nFilter: any = {};
  profilFilterList: Item[];
  activeFilters: any[];
  tCardFilters: Item[];
  mandatsFilters: Item[];
  typeFilterList: any[];
  statuses: Item[] = [
    { id: 1, label: 'PENDING' },
    { id: 2, label: 'VALID' },
    { id: 3, label: 'INCOMPLETE' },
    { id: 4, label: 'DENIED' },
  ];
  filterUrlParam: FilterEventOutput = {};
  professionalCategories: Item[];
  CompanyType = CompanyType;
  textFilterSubject = new Subject<string>();
  textFilterValue: string;

  constructor(
    public readonly i18nService: I18nService,
    public readonly companiesTrackingTableService: CompaniesTrackingTableService,
    public readonly professionalCategoryTypeApiService: ProfessionalCategoryTypeApiService,
    private readonly _route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialog: MatDialog,
  ) {
    super(_route.snapshot.routeConfig.path, router);
    this.setFilterValue();
  }

  ngOnInit(): void {
    this.companiesTrackingTableService.type = this.type;
    this.columnDefs = [
      {
        field: 'id',
        hide: true,
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_Name'),
      },
      {
        field: 'siret',
        headerName: this.i18nService._('Txt_ContractorTrackingTable_Column_Siret'),
        minWidth: 105,
        maxWidth: 105,
        flex: 0,
        hide: this.type == CompanyType.INDEPENDANT,
        valueFormatter: (params) => {
          return params.value ?? '-';
        },
      },
      {
        field: 'companyTradingName',
        headerName: this.getCompanyTypeHeader(),
        minWidth: 120,
        flex: 0.5,
      },
      {
        field: 'professionalCategoryLabel',
        cellRenderer: I18nRendererComponent,
        headerName: this.i18nService._('Txt_Placeholder_ProfessionalCategory'),
        minWidth: 120,
        maxWidth: 120,
        flex: 0,
        hide: this.type == CompanyType.INDEPENDANT,
      },
      {
        field: 'companyHomeEntity',
        headerName: this.i18nService._('Txt_Placeholder_HomeEntity'),
        minWidth: 120,
        maxWidth: 120,
        flex: 0,
        hide: this.type == CompanyType.INDEPENDANT,
        valueFormatter: (params) => {
          return params.value ?? '-';
        },
      },
      {
        field: 'companyCity',
        headerName: this.i18nService._('Txt_Placeholder_City'),
        minWidth: 120,
        maxWidth: 120,
        flex: 0,
        hide: this.type == CompanyType.INDEPENDANT,
        valueFormatter: (params) => {
          return params.value ?? '-';
        },
      },
      {
        field: 'companyFinValiditeCarteT',
        headerName: this.i18nService._('Txt_ContractorTrackingTable_Column_TCardEnd'),
        cellRenderer: DateRendererComponent,
        minWidth: 80,
        maxWidth: 80,
        flex: 0,
        hide: this.type != CompanyType.CONTRACTOR,
        cellRendererParams: {
          format: 'DD/MM/yyyy',
        },
      },
      {
        field: 'mandatEnd',
        cellRenderer: DateRendererComponent,
        headerName: this.i18nService._('Txt_ContractorTrackingTable_Column_MandateEnd'),
        minWidth: 80,
        maxWidth: 80,
        flex: 0,
        hide: this.type == CompanyType.INDEPENDANT,
        valueFormatter: (params) => {
          return params.value ?? '-';
        },
      },
      {
        field: 'companyActiveCompany',
        cellRenderer: BooleanRendererComponent,
        headerName: this.i18nService._('Txt_ContractorTrackingTable_Column_Active'),
        minWidth: 40,
        maxWidth: 40,
        flex: 0,
      },
      {
        field: 'companyStatusLabel',
        headerName: this.i18nService._('Txt_Table_Column_Status'),
        minWidth: 80,
        maxWidth: 80,
        flex: 0,
        cellRenderer: StatusTagsRendererComponent,
        cellRendererParams: {
          tagsList: tagTypeByStatus,
          outline: true,
        },
      },
      {
        field: 'companyCorporateName',
        headerName: this.i18nService._('Txt_Placeholder_CorporateName'),
        minWidth: 120,
        flex: 0.5,
        hide: this.type == CompanyType.INDEPENDANT,
      },
      {
        field: 'legalRepresentativeContactFullName',
        headerName:
          this.type == CompanyType.INDEPENDANT
            ? this.i18nService._('Title_NetworkHeader')
            : this.i18nService._('Title_LegalRepresentative'),
        minWidth: 120,
        flex: 1,
        valueFormatter: (params) => {
          return params.value ?? '-';
        },
      },
      {
        field: 'responsibleFullName',
        headerName: this.i18nService._('Txt_ContractorTrackingTable_Column_ValoResponsible'),
        minWidth: 120,
        flex: 1,
        hide: this.type == CompanyType.INDEPENDANT,
        valueFormatter: (params) => {
          return params.value ?? '-';
        },
      },
      {
        field: 'actions',
        headerName: this.i18nService._('Txt_Table_Action_s'),
        cellRenderer: ActionRendererComponent,
        cellRendererParams: {
          actionItems: (item: any) => {
            return this.getActionItems(item);
          },
        },
        cellStyle: { paddingRight: '10px', 'justify-content': 'flex-end' },
        flex: 0,
        minWidth: 60,
        maxWidth: 60,
      },
    ];

    this.professionalCategoryTypeApiService.getProfessionalCategories().subscribe((professionalCategories) => {
      this.professionalCategories = professionalCategories;
    });

    this.activeFilters = [
      { value: true, label: this.i18nService._('Txt_Yes') },
      { value: false, label: this.i18nService._('Txt_No') },
    ];

    this.tCardFilters = [
      { id: DatesFilterEnum.ACTIF, label: this.i18nService._('Txt_Active') },
      { id: DatesFilterEnum.EXPIRE, label: this.i18nService._('Txt_Expire') },
      { id: DatesFilterEnum.EXPIRE_CE_MOIS, label: this.i18nService._('Txt_ExpireThisMonth') },
      { id: DatesFilterEnum.EXPIRE_CETTE_ANNEE, label: this.i18nService._('Txt_ExpireThisYear') },
    ];

    this.mandatsFilters = [
      { id: DatesFilterEnum.ACTIF, label: this.i18nService._('Txt_Active') },
      { id: DatesFilterEnum.EXPIRE, label: this.i18nService._('Txt_Expire') },
      { id: DatesFilterEnum.EXPIRE_CE_MOIS, label: this.i18nService._('Txt_ExpireThisMonth') },
      { id: DatesFilterEnum.EXPIRE_CETTE_ANNEE, label: this.i18nService._('Txt_ExpireThisYear') },
    ];

    this.onTextFilterChange();
  }

  getActionItems(item: any) {
    const actions = [];
    if (item?.actions?.isEditable) {
      actions.push({
        label: 'Txt_Tooltip_Edit',
        action: () => {
          this.router.navigate([Sitemap.companies.edit.path.replace(/:companyId/, item.id.toString())]);
        },
      });
    }
    if (item?.actions?.isCompanyValidated) {
      actions.push({
        label: 'Txt_Tooltip_ValidateCompany',
        action: () => {
          this.router.navigate([Sitemap.companies.validate.path.replace(/:companyId/, item.id.toString())]);
        },
      });
    }
    if (item?.actions?.desktopPublishingEnable) {
      actions.push({
        label: 'Title_Desktop_Publishing_Dialog',
        action: () => {
          // Open the desktop publish modal
          const dialogData: DesktopPublishingDialogData = {
            companyId: item.id,
            corporateName: item.companyCorporateName,
          };

          this.dialog.open(DesktopPublishingDialogComponent, {
            data: dialogData,
          });
        },
      });
    }

    if (item?.actions?.canExclude) {
      actions.push({
        label: 'Txt_Button_Exclude_List',
        action: () => {
          const dialogData: ExcludeListDialogData = {
            companyId: item.id,
            corporateName: item.companyCorporateName,
          };
          this.dialog.open(ExcludeListDialogComponent, {
            data: dialogData,
          });
        },
      });
    }

    if (item?.actions?.hasStrategies) {
      actions.push({
        label: 'Title_Button_ShowStrategies',
        action: () => {
          this.router.navigate([Sitemap.companies.mandateStrategiesContractor.path.replace(/:companyId/, item.id.toString())]);
        },
      });
    }

    return actions;
  }

  setFilterValue() {
    const queryParamMap = this._route.snapshot.queryParamMap;

    this.filterValue = queryParamMap.get('filter');
    this.textFilterValue = queryParamMap.get('filter');

    const companyStatuses = queryParamMap.get('CompanyStatuses')?.split(',').map(Number);
    if (companyStatuses) {
      this.i18nFilter.CompanyStatuses = companyStatuses;
    }

    const companyActive = queryParamMap
      .get('companyActive')
      ?.split(',')
      .map((v) => Boolean(JSON.parse(v)));
    if (companyActive) {
      this.i18nFilter.companyActive = companyActive;
    }

    const professionalCategories = queryParamMap.get('ProfessionalCategories')?.split(',').map(Number);
    if (professionalCategories) {
      this.i18nFilter.ProfessionalCategories = professionalCategories;
    }

    const valoResponsibleFilter = queryParamMap.get('responsibleFullName');
    if (valoResponsibleFilter) {
      this.i18nFilter.responsibleFullName = valoResponsibleFilter;
    }

    const tCardFilter = queryParamMap.get('tCard')?.split(',').map(Number);
    if (tCardFilter) {
      this.i18nFilter.tCard = tCardFilter;
    }

    const mandat = queryParamMap.get('mandat')?.split(',').map(Number);
    if (mandat) {
      this.i18nFilter.mandat = mandat;
    }

    this.filterUrlParam = {
      i18nFilter: this.i18nFilter,
      filter: this.filterValue,
    };
    super.filterEvent(this.filterUrlParam);
  }

  onTextFilterChange() {
    this.textFilterSubject.pipe(debounceTime(this.textFilterDebounceTimer), distinctUntilChanged()).subscribe((value) => {
      this.filterUrlParam.filter = value;
      super.filterEvent(this.filterUrlParam);
      this.textFilterValue = value;
    });
  }

  onProfessionalCategoryFilterChange(professionalCategories: any) {
    if (professionalCategories?.value.length) {
      this.i18nFilter.ProfessionalCategories = professionalCategories.value;
    } else {
      delete this.i18nFilter.ProfessionalCategories;
    }
    this.i18nFilter = { ...this.i18nFilter };
    this.filterUrlParam.i18nFilter = this.i18nFilter;
    super.filterEvent(this.filterUrlParam);
  }

  onActiveFilterChange(actifs: any) {
    if (actifs?.value.length) {
      this.i18nFilter.companyActive = actifs.value;
    } else {
      delete this.i18nFilter.companyActive;
    }
    this.i18nFilter = { ...this.i18nFilter };
    this.filterUrlParam.i18nFilter = this.i18nFilter;
    super.filterEvent(this.filterUrlParam);
  }

  onTCardFilterChange(tCards: number[]) {
    if (tCards?.length) {
      this.i18nFilter.tCard = tCards;
    } else {
      delete this.i18nFilter.tCard;
    }
    this.i18nFilter = { ...this.i18nFilter };
    this.filterUrlParam.i18nFilter = this.i18nFilter;
    super.filterEvent(this.filterUrlParam);
  }

  onMandatFilterChange(mandats: number[]) {
    if (mandats?.length) {
      this.i18nFilter.mandat = mandats;
    } else {
      delete this.i18nFilter.mandat;
    }
    this.i18nFilter = { ...this.i18nFilter };
    this.filterUrlParam.i18nFilter = this.i18nFilter;
    super.filterEvent(this.filterUrlParam);
  }

  onStatusFilterChange(statuses: number[]) {
    if (statuses?.length) {
      this.i18nFilter.CompanyStatuses = statuses;
    } else {
      delete this.i18nFilter.CompanyStatuses;
    }
    this.i18nFilter = { ...this.i18nFilter };
    this.filterUrlParam.i18nFilter = this.i18nFilter;
    super.filterEvent(this.filterUrlParam);
  }

  onValoResponsibleFilterChange(valoResponsibleFilter: string) {
    this.i18nFilter.responsibleFullName = valoResponsibleFilter;
    this.i18nFilter = { ...this.i18nFilter };
    this.filterUrlParam.i18nFilter = this.i18nFilter;
    super.filterEvent(this.filterUrlParam);
  }

  getCompanyTypeHeader(): string {
    switch (this.type) {
      case CompanyType.DEVELOPER:
        return this.i18nService._('Txt_Company_Type_Developer');
      case CompanyType.CONTRACTOR:
        return this.i18nService._('Txt_Company_Type_Contractor');
      case CompanyType.INDEPENDANT:
        return this.i18nService._('Txt_Company_Type_Independant');
      default:
        return this.i18nService._('Txt_Company_Type_Valorissimo');
    }
  }
}
