<ng-template #taxationsTemplateRef let-lotDetail>
  <ng-container>
    <span class="lot-comparator-taxation" *ngIf="lotDetail.taxationsLabels && lotDetail.taxationsLabels.length; else emptyData">
      {{ lotDetail.taxationsLabels.join(' | ').replace('_', ' ') }}
    </span>
  </ng-container>
</ng-template>
<ng-template #feesTemplateRef let-lotDetail>
  <ng-container>
    <span class="lot-comparator-taxation" *ngIf="lotDetail.fees && !lotDetail.isFeesHidden && !hideLotFees; else emptyData">
      {{ basicFormatsService.formatPercentage(lotDetail.fees / 100) }}
    </span>
  </ng-container>
</ng-template>

<ng-template #emptyData>
  <span class="empty-values">-</span>
</ng-template>

<div class="comparator-lot-separator separator-head" *ngIf="lotMetaInformation?.atLeastOneShow">
  <div>
    {{ i18nService._('Txt_page_program_Detail_Lot_information_block_title') }}
  </div>
</div>
<app-lot-comparator-meta-line
  *ngIf="lotMetaInformation"
  class="border-radius-bottom"
  [lotDetailToCompare]="lotDetailToCompare"
  [lotMetaWrapper]="lotMetaInformation"
></app-lot-comparator-meta-line>
