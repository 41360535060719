import { Injectable } from '@angular/core';

import { MandateStrategiesTrackingTableServiceResponse } from '../models/MandateStrategiesTrackingTableServiceResponse';
import { AbstractAdvancedTrackingTableService } from './abstract-advanced-tracking-table.service';

@Injectable({
  providedIn: 'root',
})
export class MandateStrategiesTrackingTableService extends AbstractAdvancedTrackingTableService<MandateStrategiesTrackingTableServiceResponse> {
  // protected url: string;

  setCompanyId(companyId?: number): void {
    this.url = `Companies/${companyId}/mandate-strategies`;
  }
}
