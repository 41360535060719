import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';

import { AppConfigService } from '../../utils/services/app-config.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class CheckTokenService {
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
  ) {}

  checkToken(token: string): Observable<void> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Accounts/checkToken/${token}`;

    return this.http.get<void>(url).pipe(catchError(this.errorHandlerService.handleError<void>('checkTokenService', 'checkPassword')));
  }
}
