import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParamRequest } from '@commons-dto/valo-back';

import { DataLayerApiService } from './data-layer-api.service';
import { SpecialOffersTrackingTableResponse } from '../utils/models/SpecialOffersTrackingTableResponse';
import { SpecialOffersTrackingTableItem } from '../tracking-tables/models/specialOffersTrackingTableItem';

@Injectable({ providedIn: 'root' })
export class SpecialOfferApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Get special offer by params
   *
   * @returns {Observable<SpecialOffersTrackingTableResponse>}
   * @memberof DataLayerApiService
   */
  getSpecialOffer(params: ParamRequest): Observable<SpecialOffersTrackingTableResponse> {
    const path = this.BASE_URL + 'SpecialOffers/tracking-table/SpecialOffers';
    return this.getTableRessources(params, path);
  }

  /**
   * Get special offer by id
   *
   * @returns {Observable<SpecialOffersTrackingTableItem>}
   * @memberof DataLayerApiService
   */
  getSpecialOfferById(id: number): Observable<SpecialOffersTrackingTableItem> {
    const path = this.BASE_URL + 'SpecialOffers/tracking-table/SpecialOffers';
    return this.getRessource(path, id);
  }
}
