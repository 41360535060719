<div class="flex flex-col max-w-full w-3xl px-9 py-6 relative">
  <app-icon
    name="XMark"
    size="1.5rem"
    class="w-6 h-6 text-gray-500 hover:text-gray-600 absolute right-0 top-0 cursor-pointer"
    (click)="close()"
  ></app-icon>
  <h1 mat-dialog-title class="text-3xl font-bold mb-8 text-center">{{ _i18nService._('prefill_title') }}</h1>
  <mat-dialog-content>
    <p>{{ _i18nService._('prefill_text') }}</p>
    <form [formGroup]="prefillForm" *ngIf="prefillForm" class="flex flex-col">
      <mat-checkbox *ngFor="let field of prefillableFields" color="primary" formControlName="{{ field.name }}"
        >{{ _i18nService._(field.label) }}
      </mat-checkbox>
    </form>
  </mat-dialog-content>
  <div class="flex justify-end gap-4">
    <button (click)="close()" class="rounded-button-grey">{{ _i18nService._('txt_signature_type_cancel') }}</button>
    <button type="button" class="rounded-button" (click)="prefill()">{{ _i18nService._('prefill_validate') }}</button>
  </div>
</div>
