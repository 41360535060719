<ng-container *ngIf="nominalTaxationValue">
  <ng-template #nominalTaxationTemplate let-dataItem let-labelClass="labelClass" let-valueClass="valueClass">
    <div class="item-label" [ngClass]="labelClass" [innerHTML]="i18nService._(dataItem?.label) | safe: 'html'"></div>
    <div class="item-value" [ngClass]="valueClass">
      <span class="tag-taxation-value-text" [innerHTML]="dataItem?.value | safe: 'html'"></span>
      <app-tags [tagOption]="taxationTags.taxations.tagOption" [ngClass]="taxationTags.taxations.ngClass"></app-tags>
    </div>
  </ng-template>

  <ng-template #reduceTaxationTemplate let-dataItem let-labelClass="labelClass" let-valueClass="valueClass">
    <div class="item-label" [ngClass]="labelClass" [innerHTML]="i18nService._(dataItem?.label) | safe: 'html'"></div>
    <div class="item-value" [ngClass]="valueClass">
      <span class="tag-taxation-value-text" [innerHTML]="dataItem?.value | safe: 'html'"></span>
      <app-tags
        [tagOption]="taxationTags.reducedTaxation.tagOption"
        [ngClass]="taxationTags.reducedTaxation.ngClass"
        (click)="openReduceTaxDialog()"
      ></app-tags>
    </div>
  </ng-template>

  <ng-template #panelTemplate>
    <app-item
      [item]="lotData?.price"
      [optionTemplate]="!isOtherTaxation ? nominalTaxationTemplate : undefined"
      [itemValueClass]="'taxation-item'"
    ></app-item>
    <ng-container *ngIf="reducedTaxationLot">
      <app-item
        [item]="lotData?.reducePrice"
        [optionTemplate]="!isOtherTaxation ? reduceTaxationTemplate : undefined"
        [itemValueClass]="'taxation-item'"
      ></app-item>
    </ng-container>
    <ng-container *ngIf="isLmnpLmp">
      <app-item [item]="lotData?.estatePriceET"></app-item>
      <app-item [item]="lotData?.housingPriceET"></app-item>
      <app-item [item]="lotData?.globalSellingPriceET"></app-item>
      <app-item [item]="lotData?.tangiblePriceET"></app-item>
      <app-item [item]="lotData?.sellingFees"></app-item>
      <app-item [item]="lotData?.loanFees"></app-item>
      <app-item [item]="lotData?.attorneyFees"></app-item>
    </ng-container>
    <ng-container *ngIf="isOtherTaxation">
      <app-item [item]="lotData?.estimatedWorkPrice"></app-item>
      <app-item [item]="lotData?.estimatedLandPrice"></app-item>
    </ng-container>
    <ng-container *ngIf="isNueProprieteTaxation">
      <app-item [item]="lotData?.usufruitValue"></app-item>
      <app-item [item]="lotData?.distributionKey"></app-item>
      <app-item [item]="lotData?.nueProprieteDuration"></app-item>
      <div
        *ngIf="
          lotData?.usufruitValue?.value === '-' || lotData?.distributionKey?.value === '-' || lotData?.nueProprieteDuration?.value === '-'
        "
        class="program-page-lot-price-nue-propriete-information"
      >
        {{ i18nService._('Txt_Page_Program_NueProprieteInformations') }}
      </div>
    </ng-container>
  </ng-template>

  <app-single-accordion-panel
    [title]="'Txt_page_program_Detail_Lot_information_block_price'"
    [optionTemplate]="panelTemplate"
    [open]="true"
  ></app-single-accordion-panel>
</ng-container>
