import { Injectable } from '@angular/core';
import { ValoRole } from '@commons-dto/valo-back';

import { AppConfigService } from './app-config.service';
import { TokenService } from './authorisation/token.service';

@Injectable({
  providedIn: 'root',
})
export class UserRoleService {
  public valoSysRoles = [];

  public valoAdminRoles = [];
  public valoDevRoles = [];

  public valoRcvRoles = [];

  public valoMarketerRoles = [];

  public valoBoRoles = [];

  public valoRoles = [];

  public developerRoles = [];

  public contractorRoles = [];

  public independantRoles = [];

  public isNotAuthorizedToManageOptionRoles = [];

  public authorizedOnlyForReservationRoles = [];

  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly tokenService: TokenService,
  ) {
    this.valoSysRoles = [this.appConfigService.appConfig.roles.valoSystem];

    this.valoAdminRoles = [this.appConfigService.appConfig.roles.valoAdminSuper, this.appConfigService.appConfig.roles.valoAdminSimple];
    this.valoDevRoles = [this.appConfigService.appConfig.roles.valoDevSuper, this.appConfigService.appConfig.roles.valoDevSimple];

    this.valoRcvRoles = [this.appConfigService.appConfig.roles.valoRcvSuper, this.appConfigService.appConfig.roles.valoRcvSimple];

    this.valoMarketerRoles = [
      this.appConfigService.appConfig.roles.valoMarketerSuper,
      this.appConfigService.appConfig.roles.valoMarketerSimple,
    ];

    this.valoBoRoles = [this.appConfigService.appConfig.roles.valoBoSuper, this.appConfigService.appConfig.roles.valoBoSimple];

    this.valoRoles = [
      ...this.valoSysRoles,
      ...this.valoAdminRoles,
      ...this.valoDevRoles,
      ...this.valoRcvRoles,
      ...this.valoMarketerRoles,
      ...this.valoBoRoles,
    ];

    this.developerRoles = [
      this.appConfigService.appConfig.roles.developerHyper,
      this.appConfigService.appConfig.roles.developerSuper,
      this.appConfigService.appConfig.roles.developerSimple,
    ];

    this.contractorRoles = [
      this.appConfigService.appConfig.roles.contractorHyper,
      this.appConfigService.appConfig.roles.contractorSuper,
      this.appConfigService.appConfig.roles.contractorSimple,
    ];

    this.independantRoles = [
      this.appConfigService.appConfig.roles.independantNetworkHeader,
      this.appConfigService.appConfig.roles.independantNetworkLeader,
      this.appConfigService.appConfig.roles.independant,
    ];

    this.isNotAuthorizedToManageOptionRoles = [
      this.appConfigService.appConfig.roles.valoMarketerSuper,
      this.appConfigService.appConfig.roles.valoMarketerSimple,
      this.appConfigService.appConfig.roles.valoBoSuper,
      this.appConfigService.appConfig.roles.valoBoSimple,
    ];

    this.authorizedOnlyForReservationRoles = [
      this.appConfigService.appConfig.roles.valoDevSuper,
      this.appConfigService.appConfig.roles.valoDevSimple,
    ];
  }

  /**
   * Return if the connected user's role is part of the given list.
   *
   * @param {Array<string>} roles list of roles.
   * @param roleToTest
   */
  public isInRoles(roles: Array<ValoRole>, roleToTest?: ValoRole): boolean {
    if (roleToTest) {
      return roles.includes(roleToTest);
    }
    if (!this.tokenService.checkIsLoggedIn()) return false;

    return roles.map((r) => r.toString()).includes(this.tokenService.getRoleName());
  }

  /**
   * Return if the user is a valo user
   *
   * @returns {boolean} - True if the connected user is a valo user; false else
   */
  public isValo(roleToTest?: ValoRole): boolean {
    return this.isInRoles(this.valoRoles, roleToTest);
  }

  /**
   * Return if the user is a valo rcv user
   *
   * @returns {boolean} - True if the connected user is a valo user; false else
   */
  public isValoRcv(roleToTest?: ValoRole): boolean {
    return this.isInRoles(this.valoRcvRoles, roleToTest);
  }

  public isValoAdminOrSys(roleToTest?: ValoRole): boolean {
    return this.isInRoles([...this.valoAdminRoles, ...this.valoSysRoles], roleToTest);
  }

  public isBo(roleToTest?: ValoRole): boolean {
    return this.isInRoles(this.valoBoRoles, roleToTest);
  }

  /**
   * Return if the user is a valo dev user
   *
   * @returns {boolean} - True if the connected user is a valo user; false else
   */
  public isValoDev(roleToTest?: ValoRole): boolean {
    return this.isInRoles(this.valoDevRoles, roleToTest);
  }

  /**
   * Return if the user is a valo marketer user
   *
   * @returns {boolean} - True if the connected user is a valo user; false else
   */
  public isValoMarketer(roleToTest?: ValoRole): boolean {
    return this.isInRoles(this.valoMarketerRoles, roleToTest);
  }

  /**
   * Return if the user is a developer (promoteur)
   *
   * @returns {boolean} - True if the connected user is a developer; false else
   */
  public isDeveloper(roleToTest?: ValoRole): boolean {
    return this.isInRoles(this.developerRoles, roleToTest);
  }

  /**
   * Return if the user is a contractor (prescripteur)
   *
   * @returns {boolean} - True if the connected user is a contractor; false else
   */
  public isContractor(roleToTest?: ValoRole): boolean {
    return this.isInRoles(this.contractorRoles, roleToTest);
  }

  /**
   * Return if the user is a contractor (prescripteur)
   *
   * @returns {boolean} - True if the connected user is a contractor; false else
   */
  public isIndependant(roleToTest?: ValoRole): boolean {
    return this.isInRoles(this.independantRoles, roleToTest);
  }

  /**
   * Return if the user is authorised to manage option on lot
   *
   * @returns {boolean} - True if the connected user is authorised; false else
   */
  public isAuthorizedToManageOption(): boolean {
    return this.tokenService.checkIsLoggedIn() && !this.isNotAuthorizedToManageOptionRoles.includes(this.tokenService.getRoleName());
  }

  /**
   * Return if the user is authorised to manage reservation on lot
   *
   * @returns {boolean} - True if the connected user is authorised; false else
   */
  public isAuthorizedToManageOnlyReservation(): boolean {
    return this.tokenService.checkIsLoggedIn() && this.authorizedOnlyForReservationRoles.includes(this.tokenService.getRoleName());
  }
}
