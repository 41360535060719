import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { DataLayerApiService } from './data-layer-api.service';
import { CityResponse } from '../utils/models/CityResponse';

@Injectable({ providedIn: 'root' })
export class CityApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Get cities
   *
   * @returns {CityResponse[]}
   * @memberof DataLayerApiService
   */
  async getCities(zipCode: string): Promise<CityResponse[]> {
    const path = this.BASE_URL + 'Cities/search';
    const httpParams: HttpParams = new HttpParams().set('zipCode', zipCode);
    return await lastValueFrom(this.get(path, httpParams));
  }

  /**
   * Get city by id
   *
   * @returns {CityResponse}
   * @memberof DataLayerApiService
   */
  async getCityById(inseeCode: string): Promise<CityResponse> {
    const path = this.BASE_URL + 'Cities/' + inseeCode;
    return await lastValueFrom(this.get(path));
  }
}
