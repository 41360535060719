export class SearchQuery {
  columns: string[];
  relations: string[];
  offset?: number;
  limit?: number;
  sortColumn?: string;
  sortDirection?: SortDirection;
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
