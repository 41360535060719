import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import moment from 'moment-timezone';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AppConfigService } from '../../services/app-config.service';
import { DateTimeService } from '../../services/date/date-time.service';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  providers: [],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, MatDatepickerModule, ReactiveFormsModule, NgIf],
})
export class InputDateComponent implements OnChanges, OnInit {
  /**
   * parentForm input
   *
   * @type {FormGroup}
   * @memberof InputDateComponent
   */
  @Input() parentForm: UntypedFormGroup;

  /**
   * forcePick input
   *
   * @type {boolean}
   * @memberof InputDateComponent
   */
  @Input() forcePick = false;

  /**
   * required input
   *
   * @type {boolean}
   * @memberof InputDateComponent
   */
  @Input() required = false;

  /**
   * fieldname input
   *
   * @type {string}
   * @memberof InputDateComponent
   */
  @Input() fieldName = 'date';

  /**
   * placeholder input
   *
   * @type {string}
   * @memberof InputDateComponent
   */
  @Input() placeholder: string;

  /**
   * requiredDateErrorI18nToken attribute
   * @type {string}
   * @memberof InputDateComponent
   */
  @Input() requiredDateErrorI18nToken = 'Error_Field_Required';

  /**
   * incorrectDateErrorI18nToken attribute
   * @type {string}
   * @memberof InputDateComponent
   */
  @Input() incorrectDateErrorI18nToken = 'Error_Field_IncorrectDate';

  /**
   * incorrectFieldErrorI18nToken attribute
   * @type {string}
   * @memberof InputDateComponent
   */
  @Input() incorrectFieldErrorI18nToken = 'Error_Field_IncorrectField';

  /**
   * readonly attribute
   * @type {boolean}
   * @memberof InputDateComponent
   */
  @Input() readonly = false;

  /**
   * set if the date must be set at the end of the day
   * default false
   * @type {boolean}
   * @memberof InputDateComponent
   */
  @Input() endOfTheDay = false;

  /**
   * set if the date is utc
   * default false
   * @type {boolean}
   * @memberof InputDateComponent
   */
  @Input() applyTimeZone = false;

  /**
   * onValueChange output
   *
   * @type {EventEmitter}
   * @memberof InputDateComponent
   */
  // eslint-disable-next-line
  @Output() readonly dateChange: EventEmitter<moment> = new EventEmitter();

  /**
   * dateControl attribute
   *
   * @type {FormControl}
   * @memberof InputDateComponent
   */
  public dateControl: FormControl;
  1;

  /**
   * Creates an instance of InputDateComponent.
   *
   * @param {I18nService} i18nService
   * @memberof InputDateComponent
   */
  constructor(
    public readonly i18nService: I18nService,
    private readonly appConfigService: AppConfigService,
    private readonly _dateTimeService: DateTimeService,
  ) {
    // Default value
    this.placeholder = this.i18nService._('Txt_Placeholder_Date');
  }

  /**
   * autocomplete getter
   *
   * @returns {"on"|"off"}
   * @memberof InputDateComponent
   */
  get autocomplete(): string {
    return this.forcePick ? 'off' : 'on';
  }

  /**
   * error getter
   *
   * @returns string
   * @memberof InputDateComponent
   */
  get error(): string {
    return this.dateControl.errors
      ? this.dateControl.errors.required
        ? this.i18nService._(this.requiredDateErrorI18nToken)
        : this.dateControl.errors.matDatepickerParse
        ? this.i18nService._(this.incorrectDateErrorI18nToken)
        : this.i18nService._(this.incorrectFieldErrorI18nToken)
      : '';
  }

  ngOnInit(): void {
    this._dateTimeService.transformTimeZone = this.applyTimeZone;
  }

  /**
   * ngOnChanges method
   *
   * @memberof InputDateComponent
   */
  ngOnChanges(): void {
    const validatorsTable = [];
    if (this.required) {
      validatorsTable.push(Validators.required);
    }
    if (!this.parentForm.contains(this.fieldName)) {
      this.parentForm.addControl(this.fieldName, new UntypedFormControl());
    }
    if (this.applyTimeZone && this.parentForm.controls[this.fieldName].value) {
      const date = moment(this.parentForm.controls[this.fieldName].value);
      date.tz(this.appConfigService.getAppConfig().timeZone);
      this.parentForm.controls[this.fieldName].setValue(date);
    }
    this.dateControl = this.parentForm.controls[this.fieldName] as FormControl;
    this.dateControl.setValidators(validatorsTable);
  }

  /**
   * prevent keydown event and open datepicker if forcePick is true
   *
   * @param {KeyboardEvent} $event
   * @param {MatDatepicker<Moment>} datePicker
   * @memberof InputDateComponent
   */
  onKeydown($event: KeyboardEvent, datePicker: MatDatepicker<moment>): void {
    if (this.forcePick) {
      $event.preventDefault();
      datePicker.open();
    }
  }

  /**
   * Open datePicker if forcePick is true.
   *
   * @param {MatDatepicker<Moment>} datePicker
   * @memberof InputDateComponent
   */
  onFocusin(datePicker: MatDatepicker<moment>): void {
    if (this.forcePick) {
      datePicker.open();
    }
  }

  /**
   * Emit dateChange event with a Moment parameter.
   *
   * @param {Moment} $event
   * @memberof InputDateComponent
   */
  onDateChange($event: MatDatepickerInputEvent<moment>): void {
    let date: moment = $event.value.clone();

    if (this.applyTimeZone) {
      date = moment.tz(date, this.appConfigService.getAppConfig().timeZone).startOf('day');
    }
    if (this.endOfTheDay) {
      date = date.endOf('day').set('millisecond', 0);
    }
    this.dateControl.setValue(date);
    this.dateChange.emit(date);
  }

  /**
   * Mark form controller as untouched when datepicker is opened.
   *
   * @memberof InputDateComponent
   */
  onOpen(): void {
    this.dateControl.markAsUntouched();
  }

  /**
   * Mark form controller as touched when datepicker is closed.
   *
   * @memberof InputDateComponent
   */
  onClose(): void {
    this.dateControl.markAsTouched();
  }
}
