import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AbstractSearchFilter } from '../abstract-search-filter';

import { ReferenceTableData } from '../../../../utils/models/ReferenceTableData';
import { TaxationResponse } from '../../../../utils/models/TaxationResponse';
import { I18nService } from '../../../../utils/services/i18n.service';
import { ReferenceTablesService } from '../../../../utils/services/reference-tables.service';
import { SnackbarService } from '../../../../utils/services/snackbar.service';
import { SearchFilterOption } from '../../../model/search-filter-option';

@Component({
  selector: 'app-search-filter-input',
  templateUrl: './search-filter-input.component.html',
  styleUrls: ['./search-filter-input.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    NgClass,
    NgIf,
    MatIconModule,
    MatTooltipModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatOptionModule,
    NgFor,
  ],
})
export class SearchFilterInputComponent extends AbstractSearchFilter {
  public tableItems: ReferenceTableData[];
  public selectFormControl: UntypedFormControl;
  @Input() public filterSelectOption: SearchFilterOption;
  @Input() public addClass: string[];
  public isDisable: boolean;
  private readonly initDataFunc: { input(); select() };

  constructor(
    public readonly i18nService: I18nService,
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly snackbarService: SnackbarService,
  ) {
    super();
    this.initDataFunc = {
      select: this.initSelectData,
      input: this.initInputData,
    };
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    this.selectFormControl = new UntypedFormControl();
    this.checkForDisable();
    if (this.filterSelectOption.type === 'select') {
      this.initSelectItems();
    } else {
      super.ngOnInit();
    }
    // Force data initialization outside of data-driven emitters
    // to handle the open/close popup mechanism
    this.initData();
  }

  selectedItem() {
    let value = this.selectFormControl.value;
    if (this.filterSelectOption.transformOutputValue) {
      value = this.filterSelectOption.transformOutputValue(value);
    }
    super.selectedItem(this.filterSelectOption.fieldName, value);
    if (this.filterSelectOption.mustInitCheckDisable) {
      this._searchFormUtilsService.checkDisableSearchInput(this.search);
    }
  }

  protected initData() {
    this.isDisable = false;
    if (this.filterSelectOption.disabled) {
      this.isDisable = this.filterSelectOption.disabled(this.search);
      this.isDisable ? this.selectFormControl.disable() : this.selectFormControl.enable();
      if (this.isDisable) {
        this.selectFormControl.setValue(undefined);
      }
    }

    this.initDataFunc[this.filterSelectOption.type].call(this);
  }

  private checkForDisable() {
    if (this.filterSelectOption.disabled) {
      this._searchFormUtilsService.checkDisableSubject.pipe(takeUntil(this.$d)).subscribe((where) => {
        this.isDisable = this.filterSelectOption.disabled(where);
        this.isDisable ? this.selectFormControl.disable() : this.selectFormControl.enable();
        if (this.isDisable) {
          this.selectFormControl.setValue(undefined);
          if (this.search) {
            this.selectedItem();
          }
        }
      });
    }
  }

  private initSelectItems() {
    if (this.filterSelectOption.referenceTable) {
      this.getReferenceTableItems();
    } else {
      this.tableItems = this.filterSelectOption.items;
      super.ngOnInit();
    }
  }

  private getReferenceTableItems() {
    const referenceTable = this.filterSelectOption.referenceTable;
    this.referenceTablesService.getTableReferenceInfo(referenceTable.referenceTableNames).subscribe(
      (items: Array<TaxationResponse>) => {
        this.tableItems = items
          .filter((item) => referenceTable.filter(item))
          .map((value) => {
            return {
              id: value.id,
              label: value.label.replace(/_+/g, ' '),
            };
          });

        super.ngOnInit();
      },
      () => {
        this.snackbarService.sendErrorOccured();
      },
    );
  }

  private initSelectData() {
    if (this.tableItems) {
      const item = this.tableItems.find((value) => value.id === this.search[this.filterSelectOption.fieldName]);
      this.selectFormControl.setValue(item ? item.id : undefined);
    }
  }

  private initInputData() {
    let value = this.search[this.filterSelectOption.fieldName];
    if (this.filterSelectOption.transformInputValue) {
      value = this.filterSelectOption.transformInputValue(this.search);
    }
    this.selectFormControl.setValue(value);
  }
}
