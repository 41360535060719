<div class="flex flex-row w-full h-full overflow-auto">
  <!-- Sticky subHeader form -->
  <app-sticky-header-form
    [titleText]="title"
    (actionButtonClicked)="createCompany()"
    [actionButtonLabel]="i18nService._('Title_CompanyCreation')"
    [actionButtonIcon]="'icon-plus text-sm font-bold inline-block align-middle pl-lg-2'"
    [actionButtonIconMob]="'lg:hidden icon-plus text-sm font-bold pl-lg-2 inline-block align-middle'"
    [isActionButtonVisible]="canCreateCompany"
    [goBackUrl]="goBackUrl"
  >
  </app-sticky-header-form>

  <div class="grid pt-16 w-full">
    <div class="col-span-12 lg:col-span-10 lg:col-start-2">
      <div *ngIf="displaySingleTab" class="my-4 ml-4 pr-4">
        <app-companies-tracking-table-tab *ngIf="displayDeveloperTab" [type]="companyType.DEVELOPER"></app-companies-tracking-table-tab>
        <app-companies-tracking-table-tab *ngIf="displayContractorTab" [type]="companyType.CONTRACTOR"></app-companies-tracking-table-tab>
        <app-companies-tracking-table-tab *ngIf="displayIndependantTab" [type]="companyType.INDEPENDANT"></app-companies-tracking-table-tab>
      </div>

      <div *ngIf="!displaySingleTab">
        <mat-tab-group
          [selectedIndex]="selectedTabIndex"
          (selectedIndexChange)="changeAndReloadTab($event)"
          class="mt-6 md:mt-3"
          dynamicHeight
          mat-align-tabs="center"
          mat-stretch-tabs
          animationDuration="0ms"
        >
          <mat-tab [label]="i18nService._('Title_TrackingTable_DeveloperCompaniesTab')" *ngIf="displayDeveloperTab">
            <div class="my-4 ml-4 pr-4" *ngIf="configTabs[selectedTabIndex].type === 'DEVELOPER'">
              <app-companies-tracking-table-tab [type]="companyType.DEVELOPER"></app-companies-tracking-table-tab>
            </div>
          </mat-tab>
          <mat-tab [label]="i18nService._('Title_TrackingTable_ContractorCompaniesTab')" *ngIf="displayContractorTab">
            <div class="my-4 ml-4 pr-4" *ngIf="configTabs[selectedTabIndex].type === 'CONTRACTOR'">
              <app-companies-tracking-table-tab [type]="companyType.CONTRACTOR"></app-companies-tracking-table-tab>
            </div>
          </mat-tab>
          <mat-tab [label]="i18nService._('Title_TrackingTable_IndependantCompaniesTab')" *ngIf="displayIndependantTab">
            <div class="my-4 ml-4 pr-4" *ngIf="configTabs[selectedTabIndex].type === 'INDEPENDANT'">
              <app-companies-tracking-table-tab [type]="companyType.INDEPENDANT"></app-companies-tracking-table-tab>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
