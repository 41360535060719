<div class="absolute cursor-pointer" style="top: -25px; right: -25px" (click)="cancel()">
  <div class="rounded-md bg-gray-500 w-12 h-12 flex items-center justify-center">
    <app-icon name="XMark" size="1.5rem" class="text-white flex items-center justify-center"></app-icon>
  </div>
</div>

<h2 class="text-center" mat-dialog-title>
  {{ i18nService._('Title_PreReservation_Creation') }}
</h2>

<form *ngIf="reservationMotiveItems$ | async as reservationMotiveItems" [formGroup]="formGroup">
  <div *ngIf="!isApproval || (reservationInformations$ | async) as reservationInformations">
    <div mat-dialog-content>
      <!-- Prospect information -->
      <div *ngIf="isApproval || (dossierProspectInformations$ | async)">
        <!-- Warning paternity -->
        <div *ngIf="warningPaternityMessage">
          <p>
            <strong>{{ warningPaternityMessage }}</strong>
          </p>
        </div>
        <div *ngIf="!isApproval">
          <span [innerHTML]="i18nService._(prospectTitle, [data.lotNumber, data.programName]) | safe: 'html'"> </span>
          <br /><br />
        </div>
        <div *ngIf="dossierProspect.prospects[0] && dossierProspect.prospects[0].prospectType === 'COMPANY'">
          <p>
            <strong
              >{{ i18nService._(dossierProspect.prospects[0].civility) }} {{ dossierProspect.prospects[0].firstName }}
              {{ dossierProspect.prospects[0].lastName }}</strong
            >
          </p>
          <p>
            <strong>{{ i18nService._('Txt_PreReservationSubmitionPopin_HeaderSocietyLabel') }}</strong>
            :
            {{ dossierProspect.prospects[0].companyName }} {{ dossierProspect.prospects[0].SIRET }}
          </p>
          <p>
            <strong>{{ i18nService._('Txt_PreReservationSubmitionPopin_HeaderEMailLabel') }}</strong>
            :
            {{ dossierProspect.prospects[0].email }}
          </p>
          <p>
            <strong>{{ i18nService._('Txt_PreReservationSubmitionPopin_HeaderPhoneLabel') }}</strong>
            :
            {{ dossierProspect.prospects[0].phoneNumber }}
          </p>
          <p>
            <strong>{{ i18nService._('Txt_PreReservationSubmitionPopin_HeaderAddressLabel') }}</strong>
            : {{ dossierProspect.prospects[0].postalCode }} {{ dossierProspect.prospects[0].city }}
          </p>
        </div>
        <div *ngIf="dossierProspect.prospects[0] && dossierProspect.prospects[0].prospectType === 'PERSON'">
          <p>
            <strong
              >{{ i18nService._(dossierProspect.prospects[0].civility) }} {{ dossierProspect.prospects[0].firstName }}
              {{ dossierProspect.prospects[0].lastName }}</strong
            >
          </p>
          <p>
            <strong>{{ i18nService._('Txt_PreReservationSubmitionPopin_HeaderEMailLabel') }}</strong>
            :
            {{ dossierProspect.prospects[0].email }}
          </p>
          <p>
            <strong>{{ i18nService._('Txt_PreReservationSubmitionPopin_HeaderPhoneLabel') }}</strong>
            :
            {{ dossierProspect.prospects[0].phoneNumber }}
          </p>
          <p>
            <strong>{{ i18nService._('Txt_PreReservationSubmitionPopin_HeaderAddressLabel') }}</strong>
            : {{ dossierProspect.prospects[0].postalCode }} {{ dossierProspect.prospects[0].city }}
          </p>
        </div>
        <br />
        <div *ngIf="dossierProspect.prospects[1]">
          <p>
            <strong
              >{{ i18nService._(dossierProspect.prospects[1].civility) }} {{ dossierProspect.prospects[1].firstName }}
              {{ dossierProspect.prospects[1].lastName }}</strong
            >
          </p>
          <p>
            <strong>{{ i18nService._('Txt_PreReservationSubmitionPopin_HeaderEMailLabel') }}</strong>
            :
            {{ dossierProspect.prospects[1].email }}
          </p>
          <p>
            <strong>{{ i18nService._('Txt_PreReservationSubmitionPopin_HeaderPhoneLabel') }}</strong>
            :
            {{ dossierProspect.prospects[1].phoneNumber }}
          </p>
          <p>
            <strong>{{ i18nService._('Txt_PreReservationSubmitionPopin_HeaderAddressLabel') }}</strong>
            : {{ dossierProspect.prospects[1].postalCode }} {{ dossierProspect.prospects[1].city }}
          </p>
        </div>
      </div>

      <hr class="hr-w-5 my-12" />

      <div class="mb-2">
        {{ i18nService._('Txt_PreReservationSubmitionPopin_FormInformationText') }}
      </div>

      <div class="grid grid-cols-2 gap-4 mb-3">
        <app-chip-populated-autocomplete
          (valueChanged)="onReservationMotiveChange($event)"
          [defaultId]="reservationInformations ? reservationInformations.reservationMotiveId : undefined"
          [id]="'reservationMotiveId'"
          [isDisabled]="isApproval"
          [items]="reservationMotiveItems"
          [max]="1"
          [parentForm]="formGroup"
          [required]="true"
          [title]="i18nService._('Txt_PreReservationSubmitionPopin_FormReservationMotiveLabel')"
          class="col-span-2 lg:col-span-1 flex items-end"
        >
        </app-chip-populated-autocomplete>
        <!-- Read only when isApproval && reservationInformations.estimatedAppointmentDate -->
        <div
          *ngIf="
            (selectedReservationMotive &&
              (this.selectedReservationMotive === this.contractSignedObject[0].id ||
                this.selectedReservationMotive === this.signingAppointmantProjectedObject[0].id)) ||
            (isApproval && reservationInformations.estimatedAppointmentDate)
          "
          class="col-span-2 lg:col-span-1 flex items-end"
        >
          <app-input-date
            [parentForm]="formGroup"
            [placeholder]="inputDateText"
            [required]="estimatedAppointmentDateRequired"
            class="input-date-w100-info flex-auto"
            fieldName="estimatedAppointmentDate"
            requiredDateErrorI18nToken="Error_Field_EstimatedAppointmentDateRequired"
          >
          </app-input-date>
          <span
            class="icon-info txt-gray pl-2 self-center"
            matSuffix
            matTooltip="{{ i18nService._('Txt_PreReservationSubmitionPopin_FormEstimatedAppointmentDateInformation') }}"
          >
          </span>
        </div>
      </div>

      <app-file-drop
        [attachmentName]="i18nService._('Txt_PreReservationSubmitionPopin_FormSupportingDocumentLabel')"
        [includingForm]="formGroup"
        [initialValue]="defaultFile"
        [isDisabled]="isApproval"
        [isSubmit]="isSubmit"
        [required]="supportingDocumentRequired"
        [tooltip]="i18nService._('Txt_PreReservationSubmitionPopin_FormSupportingDocumentInformation')"
        fieldNameKey="supportingDocument"
        fileRequiredErrorI18nToken="Error_Field_SupportingDocumentRequired"
      >
      </app-file-drop>

      <mat-form-field class="w-full">
        <mat-label>{{ i18nService._('Txt_PreReservationSubmitionPopin_FormCommentsLabel') }}</mat-label>
        <textarea
          [formControl]="commentsControl"
          [required]="commentsRequired"
          matAutosizeMaxRows="10"
          matAutosizeMinRows="5"
          matInput
          matTextareaAutosize
          maxlength="2000"
          name="comments"
        >
        </textarea>
        <mat-error *ngIf="commentsControl.errors && commentsControl.errors.required">
          {{ i18nService._('Error_Field_CommentsRequired') }}
        </mat-error>
      </mat-form-field>
      <div *ngIf="isApproval">
        <app-input-date
          [parentForm]="formGroup"
          [placeholder]="i18nService._('Txt_PreReservationSubmitionPopin_DatePickerPlaceholder')"
          class="input-date-w100-info mt-2"
          fieldName="valoReminderDate"
        >
        </app-input-date>

        <mat-form-field class="w-full">
          <mat-label>{{ i18nService._('Txt_PreReservationSubmitionPopin_ValoCommentPlaceholder') }}</mat-label>
          <textarea
            [formControl]="valoCommentsControl"
            matAutosizeMaxRows="10"
            matAutosizeMinRows="5"
            matInput
            matTextareaAutosize
            maxlength="2000"
            name="valoComments"
          >
          </textarea>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="isApproval || (canSubmit$ | async) as canSubmit" class="justify-center" mat-dialog-actions>
      <button (click)="cancel()" class="mr-3" color="primary" mat-stroked-button type="button">
        {{ i18nService._('Txt_Button_Cancel') }}
      </button>
      <button (click)="submit()" *ngIf="!isApproval && canSubmit" class="mr-3" color="primary" mat-flat-button>
        {{ i18nService._('Txt_Button_Valid') }}
      </button>
      <button
        (click)="validOrRejectPrereservation(true)"
        *ngIf="isApproval && isPending"
        class="mr-3"
        type="button"
        data-cy="validPrereservation"
        color="primary"
        mat-flat-button
        type="button"
      >
        {{ i18nService._('Txt_PreReservationSubmitionPopin_ValidPrereservation') }}
      </button>
      <button
        (click)="validOrRejectPrereservation(false)"
        *ngIf="isApproval && isPending"
        class="mr-3"
        color="primary"
        mat-flat-button
        type="button"
      >
        {{ i18nService._('Txt_PreReservationSubmitionPopin_RejectPrereservation') }}
      </button>
    </div>
  </div>
</form>
