import { Component, Input } from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

import { AdvancedTrackingTableColumn } from '../../../../models/AdvancedTrackingTableColumn';
import { I18nService } from '../../../../../utils/services/i18n.service';

@Component({
  selector: 'app-self-search-filters-tracking-table',
  templateUrl: './self-search-filters-tracking-table.component.html',
  styleUrls: ['./self-search-filters-tracking-table.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf, MatFormFieldModule, MatInputModule],
})
export class SelfSearchFiltersTrackingTableComponent {
  @Input() selfFilterForm: UntypedFormGroup;
  @Input() tableColumn: AdvancedTrackingTableColumn;

  constructor(public readonly i18nService: I18nService) {}
}
