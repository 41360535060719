/* eslint-disable @typescript-eslint/no-explicit-any */
export interface TableLoader<T> {
  get?(params?: any): Promise<TableResponse<T>>;

  getById?(id: number): Promise<T>;
}

export class TableResponse<T> {
  items: T[];
  nbItems?: number;
}
