<mat-form-field [hidden]="!length" class="w-1/2 block max-w-xl">
  <mat-label>{{ i18nService._(filterPlaceholderI18nToken) }}</mat-label>
  <input #filterInput matInput [(ngModel)]="filter" />
</mat-form-field>

<ng-container *ngIf="activateColumnsToDisplay">
  <mat-form-field class="mat-form-field-middleWidth" [hidden]="!length">
    <mat-label>{{ i18nService._('Txt_Placeholder_ColumnsToDisplay') }}</mat-label>
    <mat-select [(ngModel)]="selectedColumns" multiple>
      <mat-select-trigger>
        {{ selectedColumnsSummary }}
      </mat-select-trigger>
      <ng-container *ngFor="let column of columns">
        <mat-option *ngIf="column.key !== 'id'" [value]="column" (onSelectionChange)="onSelectedCheckboxChange($event)">
          {{ i18nService._(column.i18nTitle) }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</ng-container>

<div *ngIf="!isLoadingResults && !length" class="my-4 text-center">
  {{ i18nService._(noResultMessageI18nToken) }}
</div>
<div [hidden]="!length" class="shadow-sm overflow-auto">
  <table mat-table matSort [dataSource]="dataSource" [matSortActive]="columnSort" [matSortDirection]="columnSortOrder">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef class="text-left">
        <mat-checkbox
          color="primary"
          #masterCheckBox
          (change)="onCheckedChangeRow($event, 'master')"
          [checked]="selection.hasValue()"
          [disabled]="disabledSelection"
          [indeterminate]="selection.selected.length && selection.selected.length < dataSource.filteredData.length"
        >
        </mat-checkbox>
      </th>

      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          color="primary"
          [ngClass]="'js-test-cb-so'"
          [attr.data-cy]="row.programName"
          (click)="$event.stopPropagation()"
          (change)="onCheckedChangeRow($event, row)"
          [disabled]="row[disableRowField] || disabledSelection"
          [checked]="selection.isSelected(row.id)"
        >
        </mat-checkbox>
        <!--        <div  *ngIf="!!row[disableRowField]" class="bg-violet disable-icon">-->
        <!--        </div>-->
      </td>
    </ng-container>
    <ng-container *ngFor="let column of displayedColumns" matColumnDef="{{ column.key }}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="{{ column.key }}" [disabled]="!column.sortable">
        {{ i18nService._(column.i18nTitle) }}
      </th>

      <td mat-cell *matCellDef="let item">
        <div [ngClass]="getItemClass(column, item)">
          <ng-container *ngIf="isNgTemplate(item, column.key); else elseBlock" [ngTemplateOutlet]="item[column.key]"></ng-container>
          <ng-template #elseBlock>
            <span>
              {{ item[column.key] | advancedTrackingTablePipe: column }}
            </span>
          </ng-template>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsKeys"></tr>
    <tr
      mat-row
      [ngClass]="{ 'bg-graylight': !!row[disableRowField] }"
      [attr.data-js-row]="row.id"
      *matRowDef="let row; columns: displayedColumnsKeys"
    ></tr>
  </table>
</div>

<mat-spinner *ngIf="isLoadingResults" [color]="'primary'" [diameter]="60" [strokeWidth]="6" class="mx-auto my-5"></mat-spinner>

<mat-paginator [hidden]="!length" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>
