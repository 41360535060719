import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { NumberOfAvailableLotsData } from '../../../utils/models/NumberOfAvailableLotsData';
import { Sitemap } from '../../../utils/models/Sitemap';
import { AccountService } from '../../../utils/services/account.service';
import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-log-access-form',
  templateUrl: './log-access-form.component.html',
  styleUrls: ['./log-access-form.component.scss'],
  standalone: true,
})
export class LogAccessFormComponent implements OnInit {
  /**
   * availableLotsInformations$ attribute
   *
   * @type {Observable<NumberOfAvailableLotsData>}
   * @memberof LogAccessFormComponent
   */
  public availableLotsInformations$: Observable<NumberOfAvailableLotsData>;

  /**
   * Creates an instance of LogAccessFormComponent.
   * @param {I18nService} i18nService
   * @param {Router} router
   * @param {AccountService} accountService
   * @memberof LogAccessFormComponent
   */
  constructor(
    public i18nService: I18nService,
    private readonly router: Router,
    private readonly accountService: AccountService,
  ) {}

  ngOnInit(): void {
    this.availableLotsInformations$ = this.accountService.getNumberOfAvailableLots();
  }

  redirectionCompanyForm(): void {
    this.router.navigate([Sitemap.companies.register.path]);
  }
}
