import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

import { I18nService } from '../../../utils/services/i18n.service';
import { QuestionDialogData } from '../../models/QuestionDialogData';
import { QuestionDialogResponse } from '../../models/QuestionDialogResponse';
import { SafePipe } from '../../../utils/pipes/safe.pipe';
import { IconComponent } from '../../../common/standalone/icon/icon.component';

@Component({
  selector: 'app-question-dialog',
  templateUrl: './question-dialog.component.html',
  styleUrls: ['./question-dialog.component.scss'],
  standalone: true,
  imports: [IconComponent, NgIf, MatDialogModule, MatButtonModule, SafePipe],
})
export class QuestionDialogComponent implements OnInit {
  hasHTMLMessage = false;

  /**
   * Creates an instance of QuestionDialogComponent.
   *
   * @param i18nService
   * @param dialogRef
   * @param data
   */
  constructor(
    public i18nService: I18nService,
    public dialogRef: MatDialogRef<QuestionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QuestionDialogData,
  ) {}

  ngOnInit(): void {
    this.data.buttonCancel = this.data.buttonCancel ? this.data.buttonCancel : this.i18nService._('Txt_Button_No');
    this.data.buttonConfirm = this.data.buttonConfirm ? this.data.buttonConfirm : this.i18nService._('Txt_Button_Yes');
    this.data.buttonConfirmHidden = this.data.buttonConfirmHidden ? this.data.buttonConfirmHidden : false;
    this.data.buttonCancelHidden = this.data.buttonCancelHidden ? this.data.buttonCancelHidden : false;
    if (this.data.hasHTMLMessage || (this.data.message && /.*<.*/.test(this.data.message))) {
      this.hasHTMLMessage = true;
    }
  }

  /**
   * closeDialog method, answer in argument
   *
   * @param {boolean} answer
   * @memberof QuestionDialogComponent
   */
  closeDialog(answer: boolean, hasAnwered: boolean): void {
    const questionResponse: QuestionDialogResponse = {
      answer,
      hasAnwered,
    };
    this.dialogRef.close(questionResponse);
  }
}
