<div class="text-base font-bold mb-3 text-left">
  {{ i18nService._('Title_Form_SearchForm_Outside') }}
</div>
<div class="flex flex-col">
  <app-checkbox-populated
    class="grid grid-cols-2"
    [referenceTable]="'AreaTypes'"
    [sortMethod]="sortBy('priority')"
    [(checkedElements)]="search.areaTypeIds"
    (checkedElementsChange)="selectedItem('areaTypeIds', $event)"
    [numberColumnsToDisplay]="2"
  >
  </app-checkbox-populated>
</div>
