import { ProspectResponse, ProspectType } from '@commons-dto/dossier-prospect';

import { DossierProspectFormData } from './forms/dossier-prospect-form-data.model';
import { DossierProspectType } from './dossier-prospect-type.enum';

export class ProspectDataMapper {
  static fromProspectCreate(data: DossierProspectFormData): ProspectResponse[] {
    const prospects = [];
    const numberOfProspect = ProspectDataMapper.getNumberOfProspects(data);
    for (let i = 1; i <= numberOfProspect; i++) {
      prospects.push({
        id: data[`prospect_${i}_id`] || null,
        email: data[`prospect_${i}_email`],
        civility: data[`prospect_${i}_gender`],
        phoneNumber: data[`prospect_${i}_phone`]?.phoneE164 || null,
        lastName: data[`prospect_${i}_lastname`],
        firstName: data[`prospect_${i}_firstname`],
        companyName: data.company_name || null,
        SIRET: data.company_siret || null,
        country: data[`prospect_${i}_country`] || null,
        city: data[`prospect_${i}_localization`]?.city || null,
        postalCode: data[`prospect_${i}_localization`]?.postalCode?.toString() || null,
        prospectType: data.dossier_prospect_type === DossierProspectType.COMPANY ? 'COMPANY' : 'PERSON',
      });
    }
    return prospects;
  }

  static getNumberOfProspects(data: DossierProspectFormData): number {
    switch (data.dossier_prospect_type) {
      case DossierProspectType.SOLO:
      case DossierProspectType.COMPANY:
        return 1;
      case DossierProspectType.DUO:
        return 2;
      default:
        throw new Error(`Unknown prospect type ${data.dossier_prospect_type}`);
    }
  }

  static mapFormByProspect(form: DossierProspectFormData, prospect: ProspectResponse, pos: number) {
    if (prospect.prospectType == ProspectType.COMPANY) {
      form['company_name'] = prospect.companyName;
      form['company_siret'] = prospect.SIRET;
    }

    form['prospect_' + pos + '_id'] = prospect.id;
    form['prospect_' + pos + '_country'] = prospect.country;
    form['prospect_' + pos + '_email'] = prospect.email;
    form['prospect_' + pos + '_firstname'] = prospect.firstName;
    form['prospect_' + pos + '_lastname'] = prospect.lastName;
    form['prospect_' + pos + '_gender'] = prospect.civility;
    form['prospect_' + pos + '_localization'] = {
      postalCode: prospect.postalCode,
      city: prospect.city,
    };
    form['prospect_' + pos + '_phone'] = {
      phoneE164: prospect.phoneNumber,
    };
  }
}
