<div class="px-4">
  <h2 *ngIf="data.title" class="text-center" mat-dialog-title>
    {{ data.title }}
  </h2>
  <div mat-dialog-content>
    <p [innerHTML]="data.message | safe: 'html'"></p>
  </div>
  <form [formGroup]="popinForm">
    <mat-form-field class="flex">
      <mat-label>{{ data.placeholder ? data.placeholder : '' }}</mat-label>
      <textarea
        [formControl]="control"
        [maxlength]="data.sizeMessageMax ? data.sizeMessageMax : 2000"
        matAutosizeMaxRows="6"
        matAutosizeMinRows="3"
        matInput
        matTextareaAutosize
        name="text"
        required
        type="text"
      >
      </textarea>
      <mat-error *ngIf="control.errors">
        {{ i18nService._('Error_Field_Required') }}
      </mat-error>
    </mat-form-field>
  </form>
  <div class="mt-4 mb-3 justify-center" mat-dialog-actions>
    <button class="mr-0 lg:mr-4 md:mr-4 sm:mr-4 mb-3 lg:mb-0 sm:mb-0 md:mb-0" color="primary" mat-dialog-close mat-stroked-button>
      {{ i18nService._('Txt_Button_Cancel') }}
    </button>
    <button (click)="clickSaveButton()" cdkFocusInitial class="mb-3 lg:mb-0 sm:mb-0 md:mb-0" color="primary" mat-flat-button>
      {{ i18nService._('Txt_Button_Save') }}
    </button>
  </div>
</div>
