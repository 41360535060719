<div class="flex flex-col w-full">
  <!-- Sticky subHeader form -->
  <app-sticky-header-form [titleText]="i18nService._('Title_Salers')"></app-sticky-header-form>

  <div class="grid grid-cols-10 pt-20">
    <div class="col-span-10 lg:col-span-8 lg:col-start-2 xl:col-span-6 xl:col-start-3">
      <div class="grid grid-cols-2 gap-4">
        <div class="col-span-1 text-xxs">
          {{ i18nService._('Txt_Select_Year_Salers') }}
          <mat-form-field class="flex flex-row w-full lg:w-auto">
            <mat-select [value]="selectedYear" (selectionChange)="selectYear($event)">
              <mat-option *ngFor="let year of yearList" [value]="year">{{ year }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div
          class="col-span-2 lg:col-span-1 flex flex-row lg:flex-col flex-wrap r-6 z-30 text-xxs justify-end lg:justify-start items-end lg:items-start content-start"
          *ngIf="lastCronExecution$ | async as lastCronExecution"
        >
          {{ i18nService._('Txt_Select_Year_Informations') }}{{ lastCronExecution.executionDate | date: 'dd/LL/yyyy - HH:mm' }}
        </div>
      </div>
    </div>

    <app-advanced-tracking-table-no-back-page
      class="flex w-full"
      (resultEvent)="onResultEvent()"
      [columns]="columns"
      [service]="service"
      [noResultMessageI18nToken]="noResultMessageI18nToken"
      [serviceParams]="selectedYear"
      [filterPredicate]="filteringFunction"
    >
    </app-advanced-tracking-table-no-back-page>
  </div>
</div>
