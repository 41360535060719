import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { merge } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgFor, NgIf } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';

import { CompanyData } from '../../../utils/models/CompanyData';
import { CompanyContact } from '../../../utils/models/CompanyContact';
import { FieldControlToAdd } from '../../../utils/models/FieldControlToAdd';
import { FileFormat } from '../../../utils/models/FileFormat';
import { ReferenceTableData } from '../../../utils/models/ReferenceTableData';
import { ValoRolesResponse } from '../../../utils/models/ValoRolesResponse';
import { AccountService } from '../../../utils/services/account.service';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { ReferenceTablesService } from '../../../utils/services/reference-tables.service';
import { CompanyService } from '../../services/company.service';
import { TranslateListPipe } from '../../../utils/pipes/translate-list.pipe';
import { FileDropComponent } from '../../../utils/components/file-drop/file-drop.component';
import { PhoneNumberInformationComponent } from '../../../utils/components/phone-number-information/phone-number-information.component';
import { NameInformationComponent } from '../../../utils/components/name-information/name-information.component';
import { RadioButtonPopulatedComponent } from '../../../utils/components/radio-button-populated/radio-button-populated.component';
import { DecimalMaskDirective } from '../../../utils/directives/decimal-mask.directive';
import { InputDateComponent } from '../../../utils/components/input-date/input-date.component';
import { DropdownListPopulatedComponent } from '../../../utils/components/dropdown-list-populated/dropdown-list-populated.component';

@Component({
  selector: 'app-step-four-form-company',
  templateUrl: './step-four-form-company.component.html',
  styleUrls: ['./step-four-form-company.component.scss'],
  standalone: true,
  imports: [
    DropdownListPopulatedComponent,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    NgIf,
    MatFormFieldModule,
    MatInputModule,
    InputDateComponent,
    DecimalMaskDirective,
    MatTooltipModule,
    MatCheckboxModule,
    RadioButtonPopulatedComponent,
    NameInformationComponent,
    PhoneNumberInformationComponent,
    FileDropComponent,
    TranslateListPipe,
  ],
})
export class StepFourFormCompanyComponent implements OnInit {
  /**
   * Form Parent to add value and control
   *
   * @type {FormGroup}
   * @memberof StepFourFormCompanyComponent
   */
  @Input() parentForm: UntypedFormGroup;

  @Input() recoveredInfo: CompanyData;

  @Input() validatedCompany = false;

  /**
   * Display and control or not according to company type
   *
   * @type {boolean}
   * @memberof StepFourFormCompanyComponent
   */
  @Input() isDeveloper: boolean;

  /**
   * Submit final from register form
   *
   * @type {boolean}
   * @memberof StepFourFormCompanyComponent
   */
  @Input() isSubmit: boolean;

  /**
   * List of VALO responsible (RCV or DEV) to affiliate to company
   *
   * @type {ReferenceTableData}
   * @memberof StepFourFormCompanyComponent
   */
  public responsibleList: Array<ReferenceTableData>;

  /**
   * List of Company group according to company type
   *
   * @type {ReferenceTableData}
   * @memberof StepFourFormCompanyComponent
   */
  public groupCompanyList: Array<ReferenceTableData>;

  /**
   * Mandate Form group to push in the mandate form array
   *
   * @type {FormGroup}
   * @memberof StepFourFormCompanyComponent
   */
  public mandateForm: UntypedFormGroup;

  /**
   * All the following abstract controls are for control on fields
   *
   * @type {FormControl}
   * @memberof StepFourFormCompanyComponent
   */
  public isHyperControl: FormControl;

  public mandatNumberControl: FormControl;

  public mandatStartControl: FormControl;

  public mandatEndControl: FormControl;

  public signedMandatControl: FormControl;

  public remunerationControl: FormControl;

  public markupControl: FormControl;

  public activeCompanyControl: FormControl;

  public clubMemberControl: FormControl;

  public boostedFeesControl: FormControl;

  public onlyBIOfferControl: FormControl;

  public isAutomatedDeactivationControl: FormControl;

  public optionAutomaticValidationTypeIdControl: FormControl;

  public paternityAutomaticValidationTypeIdControl: FormControl;

  public paternityDurationControl: FormControl;

  public companyCategoryControl: FormControl;

  public descriptionTitleControl: FormControl;

  public descriptionControl: FormControl;

  public boCommentControl: FormControl;

  /**
   * Changing placeholder according to Company Type
   *
   * @type {string}
   * @memberof StepFourFormCompanyComponent
   */
  public placeholderAffiliate: string;

  /**
   * Admin roles used for admin profile
   *
   * @type {*}
   * @memberof StepFourFormCompanyComponent
   */
  public rolesAdmin: Array<ValoRolesResponse>;

  listControl = {};

  /**
   * List of Company Categories according to company type
   *
   * @type {*}
   * @memberof StepFourFormCompanyComponent
   */
  public companyCategories: Array<ReferenceTableData>;

  /**
   * List of validation type
   *
   * @type {*}
   * @memberof StepFourFormCompanyComponent
   */
  public validationTypes: Array<ReferenceTableData>;

  /**
   * Variable to check isAutomatedDeactivation if isKeyAccount
   *
   * @type {boolean}
   * @memberof StepFourFormCompanyComponent
   */
  public isKeyAccount: boolean;
  appConfig;
  fieldControls: Array<FieldControlToAdd>;
  optionalFieldControls: Array<FieldControlToAdd>;

  public indexSuperUser: number;
  public idSuperUser: number;

  /**
   * Attribute to prepare recovered files.
   *
   * @type {Array<FileFormat>}
   * @memberof StepFourFormCompanyComponent
   */
  public initFile: Array<FileFormat>;

  /**
   * textTooltipOnlyBIOffer attribute
   *
   * @type {string}
   * @memberof StepFourFormCompanyComponent
   */
  public textTooltipOnlyBIOffer: string;
  isClubMemberCheckboxDisabled = true;
  companySignatoryControl: UntypedFormGroup;
  isEligibleDigitalSigningControl: FormControl;
  /**
   * Fields to add to form is esignature is allowed
   *
   * @type {Array<FieldControlToAdd>}
   * @memberof StepFourFormCompanyComponent
   */
  private signatoryInfoFields: Array<FieldControlToAdd> = [
    {
      fieldlabel: 'civilityId',
      control: 'civilityIdControl',
      validators: [Validators.required],
    },
    {
      fieldlabel: 'lastName',
      control: 'lastNameControl',
      validators: [Validators.required],
    },
    {
      fieldlabel: 'firstName',
      control: 'firstNameControl',
      validators: [Validators.required],
    },
    {
      fieldlabel: 'email',
      control: 'emailControl',
      validators: [Validators.required],
    },
    {
      fieldlabel: 'mobilePhone',
      control: 'mobilePhoneControl',
      validators: [Validators.required],
    },
    {
      fieldlabel: 'mobilePhoneCountryCode',
      control: 'mobilePhoneCountryCodeControl',
      validators: [Validators.required],
    },
    {
      fieldlabel: 'mobilePhoneWithoutCountryCode',
      control: 'mobilePhoneWithoutCountryCodeControl',
      validators: [Validators.required],
    },
  ];
  /**
   * Backup of the current info when esignature allowed is unchecked, in case it is checked again
   *
   * @type {Array<FieldControlToAdd>}
   * @memberof StepFourFormCompanyComponent
   */
  private previousSignatory: CompanyContact;

  /**
   * Creates an instance of StepFourFormCompanyComponent.
   * @param {I18nService} i18nService
   * @param {CompanyService} companyService
   * @param {MatSnackBar} snackBar
   * @param {FormBuilder} formBuilder
   * @param {ReferenceTablesService} referenceTablesService
   * @param {AppConfigService} appConfigService
   * @param {AccountService} accountService
   *
   * @memberof StepFourFormCompanyComponent
   */
  constructor(
    public i18nService: I18nService,
    private readonly companyService: CompanyService,
    private readonly snackBar: MatSnackBar,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly appConfigService: AppConfigService,
    private readonly accountService: AccountService,
  ) {
    this.isKeyAccount = false;
    this.appConfig = this.appConfigService.appConfig;
  }

  /**
   * Init method
   *
   * @memberof StepFourFormCompanyComponent
   */
  ngOnInit(): void {
    this.initFile = [];

    if (this.recoveredInfo) {
      // Format the document to fit the format.
      this.recoveredInfo.documents.forEach((attachment) => {
        this.initFile[attachment.type.label] = attachment;
      });
    }

    let rolesToFind: Array<string>;
    let companyType: Array<string>;
    // Form Group for mandate form & controls
    this.mandateForm = this.formBuilder.group({
      mandatNumber: new UntypedFormControl(undefined, [Validators.required]),
      mandatStart: new UntypedFormControl(undefined, [Validators.required]),
      mandatEnd: new UntypedFormControl(undefined, [Validators.required]),
      signedMandat: new UntypedFormControl(undefined, [Validators.requiredTrue]),
    });
    this.mandatNumberControl = this.mandateForm.controls.mandatNumber as FormControl;
    this.mandatStartControl = this.mandateForm.controls.mandatStart as FormControl;
    this.mandatEndControl = this.mandateForm.controls.mandatEnd as FormControl;
    this.signedMandatControl = this.mandateForm.controls.signedMandat as FormControl;
    this.parentForm.addControl('mandat', this.mandateForm);
    // Controls on global adminValoForm fields

    this.fieldControls = [
      { fieldlabel: 'isHyper', control: 'isHyperControl', value: false },
      {
        fieldlabel: 'activeCompany',
        control: 'activeCompanyControl',
        validators: !this.validatedCompany ? [Validators.requiredTrue] : undefined,
      },
      {
        fieldlabel: 'companyCategoryId',
        control: 'companyCategoryControl',
        validators: !this.isDeveloper ? [Validators.required] : undefined,
      },
      { fieldlabel: 'boComment', control: 'boCommentControl' },
    ];

    // Controls and necessary variables is company type is contractor
    if (!this.isDeveloper) {
      this.optionalFieldControls = [
        { fieldlabel: 'clubMember', control: 'clubMemberControl' },
        { fieldlabel: 'boostedFees', control: 'boostedFeesControl' },
        { fieldlabel: 'onlyBIOffer', control: 'onlyBIOfferControl' },
        {
          fieldlabel: 'isAutomatedDeactivation',
          control: 'isAutomatedDeactivationControl',
        },
      ];

      this.mandateForm.addControl('remuneration', new UntypedFormControl('', [Validators.min(0), Validators.max(100)]));
      this.remunerationControl = this.mandateForm.controls.remuneration as FormControl;
      this.placeholderAffiliate = this.i18nService._('Txt_Placeholder_AffiliateRCV');
      rolesToFind = [this.appConfig.roles.valoRcvSuper, this.appConfig.roles.valoRcvSimple];
      companyType = [this.appConfig.companyType.contractor];
    } else {
      this.optionalFieldControls = [
        {
          fieldlabel: 'optionAutomaticValidationTypeId',
          control: 'optionAutomaticValidationTypeIdControl',
        },
        {
          fieldlabel: 'paternityAutomaticValidationTypeId',
          control: 'paternityAutomaticValidationTypeIdControl',
        },
        {
          fieldlabel: 'paternityDuration',
          control: 'paternityDurationControl',
          validators: [Validators.maxLength(3)],
        },
        {
          fieldlabel: 'descriptionTitle',
          control: 'descriptionTitleControl',
          validators: [Validators.required],
        },
        {
          fieldlabel: 'description',
          control: 'descriptionControl',
          validators: [Validators.required],
        },
        {
          fieldlabel: 'isAllowedToExtendOption',
          control: 'isAllowedToExtendOptionControl',
          value: true,
        },
        {
          fieldlabel: 'isAllowedToExtendOptionFromPreresa',
          control: 'isAllowedToExtendOptionFromPreresaControl',
          value: false,
        },
        {
          fieldlabel: 'isEligibleDigitalSigning',
          control: 'isEligibleDigitalSigningControl',
          value: false,
        },
        {
          fieldlabel: 'isValoMandateEsign',
          control: 'isValoMandateEsignControl',
          value: false,
        },
      ];
      this.mandateForm.addControl('markup', new UntypedFormControl('', [Validators.min(0), Validators.max(100)]));
      this.markupControl = this.mandateForm.controls.markup as FormControl;
      this.placeholderAffiliate = this.i18nService._('Txt_Placeholder_AffiliateDEV');
      rolesToFind = [this.appConfig.roles.valoDevSuper, this.appConfig.roles.valoDevSimple];
      companyType = [this.appConfig.companyType.developer];
    }

    this.fieldControls = this.fieldControls.concat(this.optionalFieldControls);
    this.fieldControls.forEach((fieldControl) => {
      this.parentForm.addControl(fieldControl.fieldlabel, new UntypedFormControl(fieldControl.value, fieldControl.validators));
      this[fieldControl.control] = this.parentForm.controls[fieldControl.fieldlabel];
    });

    /* Affiliate RPV or DEV */
    this.accountService.getAccountsWithRoles(rolesToFind).subscribe(
      (accountsFound) => {
        const accountsFoundActive = accountsFound.filter((item) => {
          return item.isActive;
        });
        this.responsibleList = accountsFoundActive.map((account) => {
          const item = new ReferenceTableData();
          item.id = account.id;
          item.label = `${account.lastName.charAt(0).toUpperCase()}${account.lastName.slice(1)}`;
          item.label += ` ${account.firstName.charAt(0).toUpperCase()}${account.firstName.slice(1)}`;

          return item;
        });
      },
      () => {
        this.snackBar.open(this.i18nService._('Error_SnackBar_ErrorOccured'));
      },
    );

    if (this.recoveredInfo) {
      const keys = Object.keys(this.parentForm.controls);
      keys.forEach((key) => {
        if (this.recoveredInfo[key] !== undefined) {
          if (key !== 'mandat') {
            this.parentForm.controls[key].setValue(this.recoveredInfo[key]);
            this.parentForm.controls[key].updateValueAndValidity();
            if (key === 'isHyper' && this.recoveredInfo[key]) {
              this.parentForm.controls[key].disable();
            }
            if (key === 'isEligibleDigitalSigning' && this.recoveredInfo[key]) {
              const signatory = this.recoveredInfo.contacts.find((contact) =>
                contact.companyContactTypes.find((contactType) => contactType.label === this.appConfig.companyContactType.E_SIGNATORY),
              );
              this.toggleEsignatoryInfo(true, signatory);
            }
          } else {
            const mandatKeys = Object.keys(this.mandateForm.controls);
            mandatKeys.forEach((mandatKey) => {
              this.mandateForm.controls[mandatKey].setValue(this.recoveredInfo[key][mandatKey]);
              this.mandateForm.controls[mandatKey].updateValueAndValidity();
            });
          }
        }
      });
      if (this.recoveredInfo['isEligibleDigitalSigning'] && this.recoveredInfo['isValoMandateEsign']) {
        this.toggleEsignatoryInfo(false);
      }
    }

    // Group Company
    this.companyService.getCompanyTypesId(companyType).subscribe((companyTypeObject) => {
      this.companyService.getGroupCompanyList(companyTypeObject).subscribe(
        (groupCompanyList) => (this.groupCompanyList = groupCompanyList),
        () => this.snackBar.open(this.i18nService._('Error_SnackBar_ErrorOccured')),
      );
    });

    // Company Category
    this.referenceTablesService.getTableReferenceInfoTranslated('CompanyCategories').subscribe(
      (companyCategories) => (this.companyCategories = companyCategories),
      () => this.snackBar.open(this.i18nService._('Error_SnackBar_ErrorOccured')),
    );

    // Validation types
    this.referenceTablesService.getTableReferenceInfo('ValidationTypes').subscribe(
      (validationTypes) => {
        this.validationTypes = validationTypes;
        const noneValidation = this.validationTypes.find(
          (validationType) => validationType.label === this.appConfigService._('validationType', 'none'),
        );
        if (this.paternityAutomaticValidationTypeIdControl && !this.paternityAutomaticValidationTypeIdControl.value && noneValidation) {
          this.paternityAutomaticValidationTypeIdControl.setValue(noneValidation.id);
        }
        if (this.optionAutomaticValidationTypeIdControl && !this.optionAutomaticValidationTypeIdControl.value && noneValidation) {
          this.optionAutomaticValidationTypeIdControl.setValue(noneValidation.id);
        }
      },
      () => this.snackBar.open(this.i18nService._('Error_SnackBar_ErrorOccured')),
    );

    merge(this['isEligibleDigitalSigningControl'].valueChanges, this['isValoMandateEsignControl'].valueChanges).subscribe(() => {
      const showCompanySignatory = this['isEligibleDigitalSigningControl'].value && !this['isValoMandateEsignControl'].value;
      this.toggleEsignatoryInfo(showCompanySignatory);
    });

    this.isClubMemberCheckboxDisabled = this.appConfig.disabledFeatures?.disabledClubMember;
  }

  /**
   * Control a number
   *
   * @param {*} evt
   * @returns
   * @memberof StepFourFormCompanyComponent
   */
  checkIsNumber(evt): string {
    let evtValue = '';
    evt.target.value.split('').forEach((char) => {
      evtValue += Number.isInteger(parseInt(char, 10)) ? char : '';

      return evtValue;
    });

    return (evt.target.value = evtValue);
  }

  /**
   * Check isAutomatedDeactivation if keyAccount (value = 1) is checked
   *
   * @param {*} event
   * @memberof StepFourFormCompanyComponent
   */
  companyCategoryChange(event): void {
    this.isKeyAccount = event.value === 1;
  }

  /**
   * Add or remove the Signatory part or the form
   *
   * @param {boolean} show - boolean - this is the flag to show or hide the form group
   * @param {CompanyContact} [signatory] - CompanyContact - this is the object that contains the data
   * that will be used to populate the form fields.
   */
  toggleEsignatoryInfo(show: boolean, signatory?: CompanyContact) {
    this.previousSignatory = signatory || this.previousSignatory;
    if (show) {
      const signatoryGroup = new UntypedFormGroup({});
      this.signatoryInfoFields.forEach((fieldControl) => {
        signatoryGroup.addControl(
          fieldControl.fieldlabel,
          new UntypedFormControl(
            this.previousSignatory ? this.previousSignatory[fieldControl.fieldlabel] : fieldControl.value,
            fieldControl.validators,
          ),
        );
        this[fieldControl.control] = signatoryGroup.controls[fieldControl.fieldlabel];
      });
      this.parentForm.addControl('companySignatory', signatoryGroup);
      this.companySignatoryControl = this.parentForm.controls['companySignatory'] as UntypedFormGroup;
    } else {
      this.previousSignatory = { ...this.parentForm.get('companySignatory').value };
      this.parentForm.removeControl('companySignatory');
      this.signatoryInfoFields.forEach((fieldControl) => {
        this[fieldControl.control] = undefined;
      });
      this.companySignatoryControl = undefined;
    }
    this.parentForm.updateValueAndValidity();
  }
}
