<ng-container *ngIf="lot">
  <div class="comparator-lot-head-perspective">
    <img [alt]="lot.perspective.title" [src]="documentPerspective" />
    <div class="comparator-lot-head-unavailable-info" *ngIf="lot.isUnvailable">
      <mat-icon class="material-icons-outlined size-18">error_outline</mat-icon>
      <span>{{ i18nService._('Txt_comparator_lot_unavailable') }}</span>
    </div>
  </div>
  <div class="comparator-lot-head-reservation-button">
    <app-lot-comparator-head-option-button [lot]="lot" *ngIf="!lot.status.sold"></app-lot-comparator-head-option-button>
  </div>
  <div class="comparator-lot-head-lot-number">
    <a class="no-style cursor-pointer" target="_blank" [routerLink]="programUrl" [queryParams]="{ lotId: lot.id }"
      >{{ i18nService._('Txt_Page_Program_ListLotNumber') }}{{ lot.lotNumber }}</a
    >
  </div>
  <div class="comparator-lot-head-program_name">{{ lot.programName }}</div>
  <div class="comparator-lot-head-city">
    <mat-icon class="material-icons-outlined size-20">place</mat-icon>
    <span>{{ lot.city }} ({{ lot.postalCode }})</span>
  </div>
  <div class="comparator-head-program-strong-points">
    <app-strong-point-list [strongPoints]="lot.programStrongPoints"></app-strong-point-list>
  </div>
</ng-container>
