<app-identity-information [isSubmit]="isSubmit" [parentForm]="parentForm" class="w-full"></app-identity-information>

<div class="grid grid-cols-2 gap-4">
  <mat-form-field class="mb-4">
    <mat-label>{{ i18nService._('Txt_Placeholder_Email') }}</mat-label>
    <input
      data-cy="email"
      [email]="true"
      [formControl]="emailControl"
      matInput
      maxlength="255"
      name="email"
      type="email"
      [required]="true"
    />
    <mat-error *ngIf="emailControl.errors">{{ i18nService._('Error_Field_IncorrectEmail') }}</mat-error>
  </mat-form-field>
</div>

<div class="grid grid-cols-2 gap-4">
  <app-phone-number-information [isSubmit]="isSubmit" [parentForm]="parentForm" [isRequired]="true"></app-phone-number-information>
</div>

<app-postal-address *ngIf="!prospectDetails" [isAddress]="false" [parentForm]="parentForm"></app-postal-address>

<app-postal-address *ngIf="prospectDetails" [isAddress]="false" [parentForm]="parentForm" [recoveredInfo]="prospectDetails">
</app-postal-address>
