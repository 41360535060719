export enum SecondaryLotType {
  OUTDOOR_PARKING = 1,
  BASEMENT_PARKING_LOT = 2,
  BOXES = 3,
  GARAGE = 4,
  CARPORT = 5,
  CELLAR = 6,
  LARDER = 7,
  ATTIC = 8,
}
