import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CardComponent } from '../card/card.component';

import { I18nService } from '../../../utils/services/i18n.service';
import { TranslatePipe } from '../../../utils/pipes/translate.pipe';

@Component({
  selector: 'app-button-card',
  templateUrl: './button-card.component.html',
  styleUrls: ['./button-card.component.scss'],
  standalone: true,
  imports: [CardComponent, TranslatePipe],
})
export class ButtonCardComponent {
  @Input() image: string;
  @Input() textButton: string;
  @Output() clickButton = new EventEmitter<void>();

  constructor(public i18nService: I18nService) {}
}
