import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { ProspectSelectDialogComponent } from '../prospect-select-dialog/prospect-select-dialog.component';

import { UtilsModule } from './../../../utils/utils.module';
import { DialogModule } from '../../dialog.module';
import { I18nService } from '../../../utils/services/i18n.service';
import { IconComponent } from '../../../common/standalone/icon/icon.component';

@Component({
  selector: 'app-secondary-lot-add-dialog',
  templateUrl: './secondary-lot-add-dialog.component.html',
  styleUrls: ['./secondary-lot-add-dialog.component.scss'],
  standalone: true,
  imports: [DialogModule, CommonModule, UtilsModule, IconComponent, InputNumberModule, InputTextModule, DropdownModule],
})
export class SecondaryLotAddDialogComponent implements OnInit {
  selectedSecondaryLotType: any;
  formGroup: UntypedFormGroup;

  constructor(
    public readonly i18nService: I18nService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProspectSelectDialogComponent>,
    private readonly formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      selectedNumber: new FormControl(),
      selectedReference: new FormControl(),
      selectedType: new FormControl(),
      selectedPrice: new FormControl(),
    });
  }

  submit() {
    if (this.formGroup.valid) {
      const secondaryLot = {
        refLot: this.formGroup.value.selectedNumber,
        numLot: this.formGroup.value.selectedReference,
        secondaryLotTypeId: this.formGroup.value.selectedType,
        lotSellingPriceIT: this.formGroup.value.selectedPrice,
      };
      this.dialogRef.close(secondaryLot);
    }
  }

  onClose() {
    this.dialogRef.close(false);
  }
}
