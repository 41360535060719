import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';

import { I18nService } from '../../../utils/services/i18n.service';
import { Sitemap } from '../../../utils/models/Sitemap';

/**
 * InitFormCompanyComponent - Component to instanciate the company form first step view
 * @export
 * @class InitFormCompanyComponent
 */
@Component({
  selector: 'app-init-form-company',
  templateUrl: './init-form-company.component.html',
  styleUrls: ['./init-form-company.component.scss'],
  standalone: true,
  imports: [MatRadioModule, FormsModule, NgFor, NgIf, MatListModule, MatButtonModule, RouterLink],
})
export class InitFormCompanyComponent {
  /**
   * Path to companies register route.
   *
   * @type {string}
   * @memberof InitFormCompanyComponent
   */
  readonly companiesRegisterPath = `/${Sitemap.companies.register.path}/`;

  /**
   * Current selected category
   * @type {string}
   * @memberof InitFormCompanyComponent
   */
  selectedCategory: string;

  /**
   * Labels and values for radio buttons
   * @type {{ label: string; value: string; }[]}
   * @memberof InitFormCompanyComponent
   */
  categories: Array<{ label: string; value: string }>;

  /**
   * Documents required for developer
   * @type {string[]}
   * @memberof InitFormCompanyComponent
   */
  documentsDev: Array<string>;

  /**
   * Documents required for contractor
   * @type {string[]}
   * @memberof InitFormCompanyComponent
   */
  documentsPres: Array<string>;

  /**
   * Creates an instance of InitFormCompanyComponent.
   * @param {Router} router Service Router
   * @param {I18nService} i18nService Service I18nService
   * @memberof InitFormCompanyComponent
   */
  constructor(
    private readonly router: Router,
    public i18nService: I18nService,
  ) {
    this.documentsPres = [
      this.i18nService._('Txt_Page_Form_InitCompany_KBIS'),
      this.i18nService._('Txt_Page_Form_InitCompany_Id'),
      this.i18nService._('Txt_Page_Form_InitCompany_TCard'),
      this.i18nService._('Txt_Page_Form_InitCompany_Insurance'),
      this.i18nService._('Txt_Page_Form_InitCompany_Guarantee'),
      this.i18nService._('Txt_Page_Form_InitCompany_MandateDelegation'),
      this.i18nService._('Txt_Page_Form_InitCompany_MandateId'),
    ];

    this.documentsDev = [
      this.i18nService._('Txt_Page_Form_InitCompany_KBIS'),
      this.i18nService._('Txt_Page_Form_InitCompany_Id'),
      this.i18nService._('Txt_Page_Form_InitCompany_Insurance'),
      this.i18nService._('Txt_Page_Form_InitCompany_MandateDelegation'),
      this.i18nService._('Txt_Page_Form_InitCompany_MandateId'),
    ];

    this.categories = [
      {
        label: this.i18nService._('Txt_RadioButton_Developer'),
        value: 'promoteur',
      },
      {
        label: this.i18nService._('Txt_RadioButton_Contractor'),
        value: 'prescripteur',
      },
    ];
  }

  /**
   * Go back to the last page
   * @memberof InitFormCompanyComponent
   */
  goBack(): void {
    this.router.navigate([Sitemap.utils.login.path]);
  }
}
