import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgFor, NgIf } from '@angular/common';

import { AccountUtilsService } from '../../../accounts/services/account-utils.service';
import { QuestionDialogComponent } from '../../../dialog/components/question-dialog/question-dialog.component';
import { QuestionDialogData } from '../../../dialog/models/QuestionDialogData';
import { QuestionDialogResponse } from '../../../dialog/models/QuestionDialogResponse';
import { DropdownListPopulatedComponent } from '../../../utils/components/dropdown-list-populated/dropdown-list-populated.component';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { I18nService } from '../../../utils/services/i18n.service';
import { SharedProgramService } from '../../../utils/services/shared-program.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { SecondaryLot } from '../../models/SecondaryLot';
import { SecondaryLotService } from '../../services/secondary-lot.service';
import { DecimalMaskDirective } from '../../../utils/directives/decimal-mask.directive';

@Component({
  selector: 'app-secondary-lot',
  templateUrl: './secondary-lot.component.html',
  styleUrls: ['./secondary-lot.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    DropdownListPopulatedComponent,
    DecimalMaskDirective,
    MatButtonModule,
  ],
})
export class SecondaryLotComponent implements OnInit {
  /**
   * Input to get secondary lot of lot Id
   *
   * @type {number}
   * @memberof SecondaryLotComponent
   */
  @Input() lotId: string;

  /**
   * Input to get program Id
   *
   * @type {number}
   * @memberof SecondaryLotComponent
   */
  @Input() programId: number;

  /**
   * Know if edit or creation
   *
   * @type {boolean}
   * @memberof SecondaryLotComponent
   */
  @Input() isEditLot: boolean;

  /**
   * Used to know if delete secondary lot icon can be shown
   *
   * @type {boolean}
   * @memberof SecondaryLotComponent
   */
  public isDeletable: boolean;

  /**
   * Used to know if edit secondary lot icon can be shown
   *
   * @type {boolean}
   * @memberof SecondaryLotComponent
   */
  public isEditable: boolean;

  /**
   * Know if a secondary lot is being edited
   *
   * @type {boolean}
   * @memberof SecondaryLotComponent
   */
  public isEditSecondaryLot: boolean;

  /**
   * Store secondary lot that is being edited
   *
   * @type {SecondaryLot}
   * @memberof SecondaryLotComponent
   */
  public editableSecondaryLot: SecondaryLot;

  refControl: AbstractControl;
  numberControl: AbstractControl;
  priceControl: AbstractControl;

  public addClicked: boolean;

  public secondaryLotForm: UntypedFormGroup;
  public secondaryLotsList: Array<SecondaryLot>;
  public typeItems;

  @ViewChild('dropdownType') dropdownType: DropdownListPopulatedComponent;

  @Output() readonly secondaryLotChange = new EventEmitter();

  /**
   * Creates an instance of SecondaryLotComponent.
   * @param {FormBuilder} formBuilder
   * @param {I18nService} i18nService
   * @param {SecondaryLotService} secondaryLotService
   * @param {SnackbarService} snackbarService
   * @param {SharedProgramService} sharedProgramService
   * @param {MatDialog} dialog
   * @memberof SecondaryLotComponent
   */
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    public i18nService: I18nService,
    private readonly secondaryLotService: SecondaryLotService,
    private readonly snackbarService: SnackbarService,
    private readonly sharedProgramService: SharedProgramService,
    private readonly dialog: MatDialog,
    private readonly accountUtilsService: AccountUtilsService,
  ) {
    this.addClicked = false;
    this.secondaryLotsList = [];
    this.isDeletable = false;
    this.isEditable = false;
    this.isEditSecondaryLot = false;
  }

  /**
   * ngOnInit method
   *
   * @memberof SecondaryLotComponent
   */
  ngOnInit(): void {
    this.secondaryLotForm = this.formBuilder.group({});

    // Set controls
    this.secondaryLotForm.addControl('refLot', new UntypedFormControl('', [this.notFilledValidator.bind(this)]));
    this.refControl = this.secondaryLotForm.controls.refLot;
    this.secondaryLotForm.addControl('numLot', new UntypedFormControl('', [this.notFilledValidator.bind(this)]));
    this.numberControl = this.secondaryLotForm.controls.numLot;
    this.secondaryLotForm.addControl('lotSellingPriceIT', new UntypedFormControl(undefined));
    this.priceControl = this.secondaryLotForm.controls.lotSellingPriceIT;

    if (this.isEditLot) {
      this.getProgramSecondaryLots();
      this.isDeletable = !this.accountUtilsService.isLoggedUserDeveloper();
      this.isEditable = true;
    }
  }

  /**
   * notFilledValidator method
   *
   * @param {NgControl} control
   * @returns {{ [key: string]: boolean }}
   * @memberof SecondaryLotComponent
   */
  notFilledValidator(control: NgControl): { [key: string]: boolean } {
    // check if the user click that the field is undefined
    if (!control.value && this.addClicked) {
      return { errorRequired: true };
    }

    return undefined;
  }

  /**
   * addSecondaryLot method
   *
   * @memberof SecondaryLotComponent
   */
  addOrEditSecondaryLot(): void {
    this.addClicked = true;
    this.updateValueAndValidityOfControls();
    // check that secondaryLotForm is valid
    if (this.secondaryLotForm.valid) {
      // Check unique reference by Developer on server
      const editableSecondaryLotId = this.editableSecondaryLot ? this.editableSecondaryLot.id : undefined;
      this.secondaryLotService
        .isValidRefAndNumberOfSecondaryLot(
          this.numberControl.value,
          this.refControl.value,
          editableSecondaryLotId,
          this.programId,
          this.secondaryLotForm.controls.secondaryLotTypeId.value,
        )
        .subscribe(
          (result) => {
            // eslint-disable-next-line max-len
            // We have to check unique ref and unique num by developer on secondary lot defined on this lot and not pushed yet on the server
            const localCheck = this.areRefAndNumberUniqueness(
              this.refControl.value,
              this.numberControl.value,
              this.isEditSecondaryLot,
              this.secondaryLotForm.controls.secondaryLotTypeId.value,
            );

            if (result && localCheck) {
              this.secondaryLotService.getSecondaryLotTypeLabel(this.secondaryLotForm.controls.secondaryLotTypeId.value).subscribe(
                (label) => {
                  // In case of edit, change value of the array by getting the index of the selected secondary lot
                  if (this.isEditSecondaryLot) {
                    const index: number = this.secondaryLotsList.indexOf(this.editableSecondaryLot);
                    if (index !== -1) {
                      this.secondaryLotsList[index] = {
                        refLot: this.refControl.value,
                        numLot: this.numberControl.value,
                        secondaryLotType: label,
                        secondaryLotTypeId: this.secondaryLotForm.controls.secondaryLotTypeId.value,
                        lotSellingPriceIT: this.priceControl.value !== null ? this.priceControl.value : 0,
                        id: this.secondaryLotsList[index].id,
                      };
                    }
                  } else {
                    // Add the secondary lot in the list because it is valid
                    this.secondaryLotsList.unshift({
                      refLot: this.refControl.value,
                      numLot: this.numberControl.value,
                      secondaryLotType: label,
                      secondaryLotTypeId: this.secondaryLotForm.controls.secondaryLotTypeId.value,
                      lotSellingPriceIT: this.priceControl.value !== null ? this.priceControl.value : 0,
                    });
                  }
                  // Reset the value and the controls
                  this.resetValuesSecondaryLotForm();
                  this.addClicked = false;
                  this.secondaryLotChange.emit(this.secondaryLotsList);
                  this.updateValueAndValidityOfControls();
                },
                () => {
                  this.snackbarService.sendErrorOccured();
                },
              );
            } else {
              const message: SnackbarMessage = {
                text: this.i18nService._('Error_SnackBar_UniquenessRefOrNumber'),
                type: SnackbarMessageType.Error,
              };
              this.snackbarService.sendMessage(message);
            }
          },
          () => {
            this.snackbarService.sendErrorOccured();
          },
        );
    }
  }

  /**
   * updateValueAndValidityOfControls
   *
   * @memberof SecondaryLotComponent
   */
  updateValueAndValidityOfControls(): void {
    this.refControl.updateValueAndValidity();
    this.numberControl.updateValueAndValidity();
    this.priceControl.updateValueAndValidity();
  }

  /**
   * Set new value of secondary Lot for edit lot form
   *
   * @param {string} lotId
   * @memberof SecondaryLotComponent
   */
  public setNewValue(lotId: string): void {
    this.secondaryLotsList = [];
    this.lotId = lotId;
    this.getProgramSecondaryLots();
  }

  /**
   * Delete a secondary lot
   *
   * @param element
   * @memberof LotsTableProgramComponent
   */
  public deleteSecondaryLot(secondaryLot: SecondaryLot): void {
    const dialogData: MatDialogConfig<QuestionDialogData> = {
      data: {
        message: this.i18nService._('Txt_Popin_Lot_Deletion'),
        title: this.i18nService._('Title_SecondaryLot_Deletion'),
      },
    };

    const dialogRef = this.dialog.open(QuestionDialogComponent, dialogData);

    dialogRef.afterClosed().subscribe((result: QuestionDialogResponse) => {
      if (result && result.answer) {
        if (secondaryLot.id) {
          this.secondaryLotService.deleteSecondaryLot(secondaryLot.id).subscribe(() => {
            this.removeSecondaryLotFromList(secondaryLot);
            const message: SnackbarMessage = {
              text: this.i18nService._('Success_SnackBar_Lot_Deleted'),
              type: SnackbarMessageType.Info,
            };
            this.snackbarService.sendMessage(message);
          });
        } else {
          this.removeSecondaryLotFromList(secondaryLot);
        }
      }
    });
  }

  /**
   * Fill data into form when editing a secondary lot
   * @param secondaryLot
   */
  public editSecondaryLot(secondaryLot: SecondaryLot): void {
    this.isEditSecondaryLot = true;
    this.editableSecondaryLot = secondaryLot;
    // Set all fields for form
    Object.keys(this.secondaryLotForm.controls).forEach((key) => {
      this.secondaryLotForm.controls[key].setValue(secondaryLot[key]);
    });
    // Set data in dropdown
    this.dropdownType.setNewValue(secondaryLot);
  }

  /**
   * Cancel edit secondary Lot
   */
  public cancelEditSecondaryLot(): void {
    this.resetValuesSecondaryLotForm();
  }

  /**
   * areRefAndNumberUniqueness method
   *
   * @private
   * @param {string} ref
   * @param {string} number
   * @returns {boolean}
   * @memberof SecondaryLotComponent
   */
  private areRefAndNumberUniqueness(ref: string, numberLot: string, isEditSecondaryLot: boolean, secondaryLotTypeId: number): boolean {
    let listOfNumberValue = this.secondaryLotsList;

    // Check that ref and num of secondary lots are valid between them
    let listOfRefValue = [];
    const withoutDuplicateLotNum = [];

    this.secondaryLotsList.forEach((item) => {
      // Populate listOfRef
      listOfRefValue.push(item.refLot);

      // Num of secondary lots are valid for different secondaryLotTypeId
      const i = withoutDuplicateLotNum.findIndex((x) => x.numLot === item.numLot && x.secondaryLotTypeId === item.secondaryLotTypeId);
      if (i <= -1) {
        withoutDuplicateLotNum.push(item);
      }

      return null;
    });

    // In case of edit, remove the editableSecondary lot form lists
    if (isEditSecondaryLot) {
      listOfRefValue = listOfRefValue.filter((element) => element !== this.editableSecondaryLot.refLot);
      listOfNumberValue = listOfNumberValue.filter(
        (element) =>
          element.numLot !== this.editableSecondaryLot.numLot &&
          element.secondaryLotTypeId !== this.editableSecondaryLot.secondaryLotTypeId,
      );
    }

    const isExistAlreadySecondaryLotNum = listOfNumberValue.some((givenSecondaryLot) => {
      return givenSecondaryLot.numLot === numberLot && givenSecondaryLot.secondaryLotTypeId === secondaryLotTypeId;
    });

    return !(listOfRefValue.includes(ref) || isExistAlreadySecondaryLotNum);
  }

  /**
   * Call database to get lots data
   *
   * @memberof SecondaryLotComponent
   */
  private getProgramSecondaryLots(): void {
    if (this.lotId) {
      this.sharedProgramService.getProgramSecondaryLotsInformation(this.lotId).subscribe(
        (itemsFound) => {
          itemsFound.forEach((secondaryLot) => {
            this.secondaryLotsList.push({
              id: secondaryLot.id,
              refLot: secondaryLot.refLot,
              numLot: secondaryLot.numLot,
              secondaryLotType: this.i18nService._(secondaryLot.secondaryLotType.label),
              secondaryLotTypeId: secondaryLot.secondaryLotTypeId,
              lotSellingPriceIT: String(secondaryLot.lotSellingPriceIT),
            });
            this.secondaryLotChange.emit(this.secondaryLotsList);
          });
        },
        () => {
          const message: SnackbarMessage = {
            text: this.i18nService._('Error_SnackBar_ErrorOccuredRetry'),
            type: SnackbarMessageType.Error,
          };
          this.snackbarService.sendMessage(message);
        },
      );
    }
  }

  /**
   * Remove secondary Lot from Secondary Lot List to update secondary lot board
   * @param secondaryLot
   */
  private removeSecondaryLotFromList(secondaryLot: SecondaryLot): void {
    this.resetValuesSecondaryLotForm();
    const index: number = this.secondaryLotsList.indexOf(secondaryLot);
    if (index !== -1) {
      this.secondaryLotsList.splice(index, 1);
    }
  }

  /**
   * Reset all values
   */
  private resetValuesSecondaryLotForm(): void {
    this.isEditSecondaryLot = false;
    this.editableSecondaryLot = undefined;
    this.dropdownType.resetValue();
    this.secondaryLotForm.reset();
  }
}
