import { IsArray, IsDate, IsNumber, IsOptional, IsString } from 'class-validator';

import { PaternityStatusCode } from './paternity-status-code.enum';

export class PaternityProspect {
  @IsString()
  firstName: string;

  @IsString()
  lastName: string;
}
export class SummaryPaternityResponse {
  @IsNumber()
  id: number;

  @IsString()
  companyName: string;

  @IsString()
  @IsOptional()
  programName?: string;

  @IsArray()
  statuses: PaternityStatusCode[];

  @IsDate()
  creationDate: Date;

  @IsDate()
  expirationDate: Date;

  @IsArray()
  prospects: PaternityProspect[];

  @IsNumber()
  programId: number;
}
