import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AdvancedTrackingTableComponent } from '../advanced-tracking-table/advanced-tracking-table.component';

import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { AdvancedTrackingTableColumnType } from '../../models/advanced-tracking-table-column-type.enum';
import { ProgramsSalesTrackingTableService } from '../../services/programs-sales-tracking-table.service';
import { Sitemap } from '../../../utils/models/Sitemap';
import { AdvancedTrackingTableColumn } from '../../models/AdvancedTrackingTableColumn';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';

@Component({
  selector: 'app-programs-sales-tracking-table',
  templateUrl: './programs-sales-tracking-table.component.html',
  styleUrls: ['./programs-sales-tracking-table.component.scss'],
  standalone: true,
  imports: [StickyHeaderFormComponent, AdvancedTrackingTableComponent],
})
export class ProgramsSalesTrackingTableComponent {
  public columns: Array<AdvancedTrackingTableColumn> = [
    {
      key: 'companyName',
      i18nTitle: 'Txt_ProgramsTrackingTable_Column_CompanyName',
      sortable: true,
      replaceFalsyWithDash: true,
      visibleFor: [
        AppConfigService.getAppConfig().roles.valoSystem,
        AppConfigService.getAppConfig().roles.valoAdminSuper,
        AppConfigService.getAppConfig().roles.valoAdminSimple,
        AppConfigService.getAppConfig().roles.valoDevSuper,
        AppConfigService.getAppConfig().roles.valoDevSimple,
        AppConfigService.getAppConfig().roles.valoRcvSuper,
        AppConfigService.getAppConfig().roles.valoRcvSimple,
      ], // TODO visibleFor handling in advanced-tracking-table-component
    },
    {
      key: 'programName',
      i18nTitle: 'Txt_ProgramsTrackingTable_Column_ProgramName',
      sortable: true,
      replaceFalsyWithDash: true,
    },
    {
      key: 'location',
      i18nTitle: 'Txt_ProgramsTrackingTable_Column_Location',
      sortable: true,
      replaceFalsyWithDash: true,
    },
    {
      key: 'nbProspects',
      i18nTitle: 'Txt_ProgramsTrackingTable_Column_Prospects',
      sortable: true,
      replaceFalsyWithDash: true,
      type: AdvancedTrackingTableColumnType.Number,
    },
    {
      key: 'nbOptions',
      i18nTitle: 'Txt_ProgramsTrackingTable_Column_InProgressOptions',
      sortable: true,
      replaceFalsyWithDash: true,
      type: AdvancedTrackingTableColumnType.Number,
    },
    {
      key: 'nbAllOptions',
      i18nTitle: 'Txt_ProgramsTrackingTable_Column_PosedOptions',
      sortable: true,
      replaceFalsyWithDash: true,
      type: AdvancedTrackingTableColumnType.Number,
    },
    {
      key: 'nbPrereservations',
      i18nTitle: 'Txt_ProgramsTrackingTable_Column_InProgressPreReservations',
      sortable: true,
      replaceFalsyWithDash: true,
      type: AdvancedTrackingTableColumnType.Number,
    },
    {
      key: 'nbStoredLots',
      i18nTitle: 'Txt_ProgramsTrackingTable_Column_StoredLots',
      sortable: true,
      replaceFalsyWithDash: true,
      type: AdvancedTrackingTableColumnType.Number,
    },
    {
      key: 'fees',
      i18nTitle: 'Txt_ProgramsTrackingTable_Column_FeesSales',
      sortable: true,
      replaceFalsyWithDash: true,
      type: AdvancedTrackingTableColumnType.Currency,
    },
    {
      key: 'nbReservations',
      i18nTitle: 'Txt_ProgramsTrackingTable_Column_NbReservations',
      sortable: true,
      replaceFalsyWithDash: true,
      type: AdvancedTrackingTableColumnType.Number,
    },
    {
      key: 'nbSales',
      i18nTitle: 'Txt_ProgramsTrackingTable_Column_NbSales',
      sortable: true,
      replaceFalsyWithDash: true,
      type: AdvancedTrackingTableColumnType.Number,
    },
  ];

  /**
   * Creates an instance of ProgramsSalesTrackingTableComponent.
   * @param {ProgramsSalesTrackingTableService} service
   * @param {I18nService} i18nService
   * @param {AppConfigService} appConfigService
   * @param _router
   * @memberof ProgramsSalesTrackingTableComponent
   */
  constructor(
    public readonly service: ProgramsSalesTrackingTableService,
    public readonly i18nService: I18nService,
    private readonly appConfigService: AppConfigService,
    private readonly _router: Router,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterEvent($event: any): void {
    this._router.navigate([Sitemap.dashboards.salesPrograms.path], {
      queryParams: $event ? { filter: $event.filter } : undefined,
      replaceUrl: true,
    });
  }
}
