import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { I18nService } from '../services/i18n.service';

@Pipe({
  name: 'boundary-price',
  standalone: true,
  pure: true,
})
export class BoundaryPricePipe implements PipeTransform {
  private currencyPipe = new CurrencyPipe('fr-FR');

  constructor(private readonly i18nService: I18nService) {}

  transform(min: string | null, max: string | null): string {
    const isMinValid = min !== null && min !== '';
    const isMaxValid = max !== null && max !== '';

    if (isMinValid && !isMaxValid) {
      return this.i18nService._('value_more_than', [this.formatPrice(min)]);
    }
    if (isMinValid && isMaxValid) {
      return this.i18nService._('value_from_to', [this.formatPrice(min), this.formatPrice(max)]);
    }
    if (isMaxValid) {
      return this.i18nService._('value_less_than', [this.formatPrice(max)]);
    }
    return null;
  }

  formatPrice(value: string) {
    return this.currencyPipe.transform(value, 'EUR', 'symbol', '1.0-2');
  }
}
