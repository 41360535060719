/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import moment from 'moment';

import { ValoCache } from '../models/valo-cache';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private readonly _cacheMaxDuration = 240;
  private _cache: { [key: string]: ValoCache } = {};

  get cache(): { [key: string]: ValoCache } {
    return this._cache;
  }

  addToCache(id: string, data: any): void {
    this._cache[id] = {
      endLife: moment().add(this._cacheMaxDuration, 'minutes'),
      data,
    };
  }

  deleteFromCache(id: string): void {
    delete this._cache[id];
  }

  addAnyToCache(data: Array<any>, key?: string): void {
    data.forEach((d) => {
      this.addToCache(d[key || 'id'], d);
    });
  }

  getData(id: string): any {
    const data = this._cache[id];
    if (!data) {
      return undefined;
    }
    if (moment().isAfter(data.endLife)) {
      return undefined;
    }

    return data.data;
  }

  clear(): any {
    this._cache = {};
  }
}
