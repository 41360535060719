export enum ReservationStatusEnum {
  VALIDATED_DEVELOPER = 'VALIDATED_DEVELOPER',
  NOTARY_SIGNATURE_OBTAINED = 'NOTARY_SIGNATURE_OBTAINED',
  LOAN_OFFER_OBTAINED = 'LOAN_OFFER_OBTAINED',
  RECEIVED_VALO = 'RECEIVED_VALO',
  VALIDATED_VALO = 'VALIDATED_VALO',
  SENT_DEVELOPER = 'SENT_DEVELOPER',
  SRU_NOTIF_SET = 'SRU_NOTIF_SET',
  AGREEMENT_OBTAINED = 'AGREEMENT_OBTAINED',
  NOTARY_MEETING_OBTAINED = 'NOTARY_MEETING_OBTAINED',
  CLIENT_DESISTED = 'CLIENT_DESISTED',
  RESERVATION_CANCELED = 'RESERVATION_CANCELED',
}
