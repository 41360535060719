import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

import { GridTrackingTableComponent } from '../grid-tacking-table/grid-tracking-table.component';

import { StatusTagColor } from './../../../design-system/model/status-tag-color.enum';
import { Sitemap } from '../../../utils/models/Sitemap';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { TokenService } from '../../../utils/services/authorisation/token.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SpecialOffersTrackingTableService } from '../../services/special-offers-tracking-table.service';
import { DateRendererComponent } from '../../renderers/date-renderer/date-renderer.component';
import { StatusTagsRendererComponent } from '../../renderers/status-tags-renderer/status-tags-renderer.component';
import { BooleanRendererComponent } from '../../renderers/boolean-renderer/boolean-renderer.component';
import { ActionRendererComponent } from '../../renderers/action-renderer/action-renderer.component';
import { FilterEventOutput } from '../../models/filter/filter-event-output';
import { AbstractTrackingTable } from '../../abstract-tracking-table';
import { SpecialOfferStatusEnum } from '../../../utils/enums/statusEnums/special-offer-status.enum';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';

const tagTypeBySpecialOfferrStatus: { [key in SpecialOfferStatusEnum]: StatusTagColor } = {
  ACTIVE: StatusTagColor.SUCCESS,
  TOCOMEUP: StatusTagColor.IN_PROGRESS,
  EXPIRED: StatusTagColor.CRITICAL,
};

@Component({
  selector: 'app-special-offers-tracking-table',
  templateUrl: './special-offers-tracking-table.component.html',
  styleUrls: ['./special-offers-tracking-table.component.scss'],
  standalone: true,
  imports: [StickyHeaderFormComponent, MatFormFieldModule, MatInputModule, FormsModule, GridTrackingTableComponent],
})
export class SpecialOffersTrackingTableComponent extends AbstractTrackingTable implements OnInit {
  displayNewSObutton = false;

  columnDefs: ColDef[];
  filterUrlParam: FilterEventOutput;
  filterValue: string;
  textFilterSubject = new Subject<string>();
  textFilterValue: string;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly appConfigService: AppConfigService,
    private readonly tokenService: TokenService,
    public readonly i18nService: I18nService,
    public readonly specialOffersTrackingTableService: SpecialOffersTrackingTableService,
  ) {
    super(Sitemap.specialOffer.admin.path, router);
    this.columnDefs = [
      {
        field: 'developerName',
        headerName: this.i18nService._('Txt_SpecialOffersTrackingTable_developer'),
        minWidth: 100,
        flex: 3,
      },
      {
        field: 'refSpecialOffer',
        headerName: this.i18nService._('Txt_SpecialOffersTrackingTable_reference'),
        minWidth: 80,
        flex: 2,
      },
      {
        field: 'label',
        headerName: this.i18nService._('Txt_SpecialOffersTrackingTable_label'),
        minWidth: 150,
        flex: 5,
      },
      {
        field: 'startDate',
        headerName: this.i18nService._('Txt_SpecialOffersTrackingTable_startDate'),
        cellRenderer: DateRendererComponent,
        cellRendererParams: {
          format: 'DD/MM/yyyy',
        },
        minWidth: 80,
        flex: 2,
      },
      {
        field: 'endDate',
        headerName: this.i18nService._('Txt_SpecialOffersTrackingTable_endDate'),
        cellRenderer: DateRendererComponent,
        cellRendererParams: {
          format: 'DD/MM/yyyy',
        },
        sort: 'desc',
        minWidth: 80,
        flex: 2,
      },
      {
        field: 'totalPrograms',
        headerName: this.i18nService._('Txt_SpecialOffersTrackingTable_totalPrograms'),
        minWidth: 80,
        flex: 2,
      },
      {
        field: 'totalLots',
        headerName: this.i18nService._('Txt_SpecialOffersTrackingTable_totalLots'),
        minWidth: 80,
        flex: 2,
      },
      {
        field: 'fromImport',
        headerName: this.i18nService._('Txt_SpecialOffersTrackingTable_fromImport'),
        cellRenderer: BooleanRendererComponent,
        minWidth: 80,
        flex: 2,
      },
      {
        field: 'status',
        headerName: this.i18nService._('Txt_SpecialOffersTrackingTable_status'),
        cellRenderer: StatusTagsRendererComponent,
        cellRendererParams: {
          tagsList: tagTypeBySpecialOfferrStatus,
          outline: true,
        },
        valueFormatter: (params) => {
          if (!params.value) return undefined;
          return 'SPECIAL_OFFER_' + params.value;
        },
        minWidth: 80,
        flex: 2,
      },
      {
        field: 'actions',
        headerName: this.i18nService._('Txt_Table_Action_s'),
        cellRenderer: ActionRendererComponent,
        cellRendererParams: {
          actionItems: (item: any) => {
            return this.getActionItems(item);
          },
        },
        minWidth: 60,
        maxWidth: 60,
        flex: 2,
        cellStyle: { paddingRight: '10px' },
        sortable: false,
      },
    ];
  }

  ngOnInit(): void {
    this.setFilterValue();
    const appConfig = this.appConfigService.appConfig;

    // Compute boolean button
    const rolesList = [
      appConfig.roles.valoSystem,
      appConfig.roles.valoAdminSuper,
      appConfig.roles.valoAdminSimple,
      appConfig.roles.valoDevSuper,
      appConfig.roles.valoDevSimple,
      appConfig.roles.valoMarketerSuper,
      appConfig.roles.valoMarketerSimple,
    ];

    // Check is the user is a valo user
    if (this.tokenService.checkIsLoggedIn() && rolesList.includes(this.tokenService.getRoleName())) {
      this.displayNewSObutton = true;
    }
    this.onTextFilterChange();
  }

  setFilterValue() {
    this.filterValue = this.route.snapshot.queryParamMap.get('filter');
    this.textFilterValue = this.route.snapshot.queryParamMap.get('filter');
    this.filterUrlParam = {
      filter: this.filterValue,
    };
    super.filterEvent(this.filterUrlParam);
  }

  getActionItems(specialOffer: any): any[] {
    const actions = [];

    if (specialOffer?.actions?.canUpdate) {
      actions.push({
        label: 'Txt_Button_Modify',
        dataCy: 'modify',
        action: () => {
          return this.router.navigate([Sitemap.specialOffer.edit.path.replace(/:specialOfferId/, specialOffer.id.toString())], {
            state: { readonly: false },
          });
        },
      });
    }
    if (specialOffer?.actions?.canVisualize) {
      actions.push({
        label: 'Txt_Button_Visualize',
        dataCy: 'visualize',
        action: () => {
          return this.router.navigate([Sitemap.specialOffer.edit.path.replace(/:specialOfferId/, specialOffer.id.toString())], {
            state: { readonly: true },
          });
        },
      });
    }
    return actions;
  }

  redirectionSpecialOfferCreation(): void {
    this.router.navigate([Sitemap.specialOffer.register.path]);
  }

  onTextFilterChange() {
    this.textFilterSubject.pipe(debounceTime(this.textFilterDebounceTimer), distinctUntilChanged()).subscribe((value) => {
      this.filterUrlParam.filter = value;
      super.filterEvent(this.filterUrlParam);
      this.textFilterValue = value;
    });
  }
}
