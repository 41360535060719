import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'daysFromNow',
  pure: true,
  standalone: true,
})
export class DaysFromNow implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value: unknown) {
    const daysFromNow = Math.ceil(DateTime.fromISO(value as string).diffNow('days').days);
    return daysFromNow;
  }
}
