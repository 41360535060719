import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DossierStatusCode } from '@commons-dto/dossier-prospect';

import { UtilsModule } from './../../../../utils/utils.module';
import { PipesModule } from './../../../../utils/pipes/pipes.module';

@Component({
  selector: 'app-dossier-prospect-dashboard-status',
  standalone: true,
  templateUrl: './dossier-prospect-dashboard-status.component.html',
  styleUrls: ['./dossier-prospect-dashboard-status.component.scss'],
  imports: [CommonModule, UtilsModule, PipesModule],
})
export class DossierProspectDashboardStatusComponent implements OnInit {
  stepDiscover: number;
  stepIdentification: number;
  stepClosing: number;
  stepBilling: number;

  /**
   * Actual step
   *
   * @type {DossierStatusCode}
   * @memberof DossierProspectDashboardStatusComponent
   */
  @Input() actualStep: DossierStatusCode;

  ngOnInit(): void {
    switch (this.actualStep) {
      case DossierStatusCode.DISCOVER:
        this.stepDiscover = 1;
        this.stepIdentification = 0;
        this.stepClosing = 0;
        this.stepBilling = 0;
        break;
      case DossierStatusCode.IDENTIFICATION:
        this.stepDiscover = 2;
        this.stepIdentification = 1;
        this.stepClosing = 0;
        this.stepBilling = 0;
        break;
      case DossierStatusCode.CLOSING:
        this.stepDiscover = 2;
        this.stepIdentification = 2;
        this.stepClosing = 1;
        this.stepBilling = 0;
        break;
      case DossierStatusCode.BILLING:
        this.stepDiscover = 2;
        this.stepIdentification = 2;
        this.stepClosing = 2;
        this.stepBilling = 1;
        break;
      case DossierStatusCode.DONE:
        this.stepDiscover = 2;
        this.stepIdentification = 2;
        this.stepClosing = 2;
        this.stepBilling = 2;
        break;
    }
  }
}
