import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { NewFastSearchPageComponent } from './components/new-fast-search-page/new-fast-search-page.component';
import { ActivitiesModule } from '../activities/activities.module';
import { UtilsModule } from '../utils/utils.module';
import { FastSearchFormComponent } from './components/fast-search-form/fast-search-form.component';
import { FastSearchPageComponent } from './components/fast-search-page/fast-search-page.component';
import { SearchFormButtonsComponent } from './components/search-form-buttons/search-form-buttons.component';
import { SearchFormProgramsComponent } from './components/search-form-programs/search-form-programs.component';
import { SearchPageComponent } from './components/search-page/search-page.component';
import { SearchResultsLotsComponent } from './components/search-results-lots/search-results-lots.component';
import { SearchResultsProgramsComponent } from './components/search-results-programs/search-results-programs.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { SearchRoutingModule } from './search-routing.module';
import { SearchLocalisationComponent } from './components/filters/search-localisation/search-localisation.component';
import { FilterMenuBadgeComponent } from './components/filters/filter-menu-badge/filter-menu-badge.component';
import { SearchFixMenuComponent } from './components/filters/search-fix-menu/search-fix-menu.component';
import { SearchRoomsComponent } from './components/filters/search-rooms/search-rooms.component';
import { SearchPanelTemplateDirective } from './directive/search-panel-template/search-panel-template.directive';
import { SearchTaxationComponent } from './components/filters/search-taxation/search-taxation.component';
import { SearchLocalisationListComponent } from './components/filters/search-localisation/search-localisation-list/search-localisation-list.component';
import { SearchLocationLabelComponent } from './components/filters/search-localisation/search-localisation-list/search-location-label/search-location-label.component';
import { SearchFilterDialogComponent } from './components/filters/search-filter-dialog/search-filter-dialog.component';
import { SearchFilterContentComponent } from './components/filters/search-filter-content/search-filter-content.component';
import { SearchFilterMorePanelComponent } from './components/filters/search-filter-more-panel/search-filter-more-panel.component';
import { SearchFilterFloorComponent } from './components/filters/search-filter-floor/search-filter-floor.component';
import { SearchFilterBudgetComponent } from './components/filters/search-filter-budget/search-filter-budget.component';
import { SearchFilterProgramTypeComponent } from './components/filters/search-filter-program-type/search-filter-program-type.component';
import { SearchFilterDeveloperCompanyComponent } from './components/filters/search-filter-developer-company/search-filter-developer-company.component';
import { SearchFilterOrientationComponent } from './components/filters/search-filter-orientation/search-filter-orientation.component';
import { SearchFilterOutsideComponent } from './components/filters/search-filter-outside/search-filter-outside.component';
import { SearchFilterDelivryDateComponent } from './components/filters/search-filter-delivry-date/search-filter-delivry-date.component';
import { SearchFilterActableComponent } from './components/filters/search-filter-actable/search-filter-actable.component';
import { SearchFilterTagsComponent } from './components/filters/search-filter-tags/search-filter-tags.component';
import { SearchFilterSelectComponent } from './components/filters/search-filter-select/search-filter-select.component';
import { SearchFilterInputComponent } from './components/filters/search-filter-input/search-filter-input.component';
import { SearchFilterReinitComponent } from './components/filters/search-filter-reinit/search-filter-reinit.component';
import { ComparatorModule } from '../comparator/comparator.module';
import { MapModule } from '../map/map.module';
import { PipesModule } from '../utils/pipes/pipes.module';
import { SharedModule } from '../common/shared/shared.module';
import { ButtonCardComponent } from '../common/shared/button-card/button-card.component';
import { LastConsultProspectListComponent } from './components/new-fast-search-page/last-consult-prospect-list/last-consult-prospect-list.component';
import { AppFormlyModule } from '../common/formly/app-formly.module';
import { CarouselComponent } from '../common/standalone/carousel/carousel.component';
import { IconComponent } from '../common/standalone/icon/icon.component';
import { SearchFilterAnnexesComponent } from './components/filters/search-filter-annexes/search-filter-annexes.component';
import { SearchResultProgramComponent } from './components/search-result-program/search-result-program.component';
import { StrongPointListComponent } from '../design-system/component/strong-point-list/strong-point-list.component';

@NgModule({
  imports: [
    CommonModule,
    SearchRoutingModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatAutocompleteModule,
    UtilsModule,
    ActivitiesModule,
    InfiniteScrollModule,
    ComparatorModule,
    MapModule,
    PipesModule,
    AppFormlyModule,
    AutoCompleteModule,
    SharedModule,
    CarouselComponent,
    IconComponent,
    NgxSliderModule,
    SearchFilterAnnexesComponent,
    ButtonCardComponent,
    SearchResultProgramComponent,
    StrongPointListComponent,
    SearchPageComponent,
    SearchResultsComponent,
    SearchFormButtonsComponent,
    SearchResultsProgramsComponent,
    SearchResultsLotsComponent,
    SearchFormProgramsComponent,
    FastSearchPageComponent,
    FastSearchFormComponent,
    SearchLocalisationComponent,
    FilterMenuBadgeComponent,
    SearchFixMenuComponent,
    SearchRoomsComponent,
    SearchPanelTemplateDirective,
    SearchTaxationComponent,
    SearchLocalisationListComponent,
    SearchLocationLabelComponent,
    SearchFilterDialogComponent,
    SearchFilterContentComponent,
    SearchFilterMorePanelComponent,
    SearchFilterFloorComponent,
    SearchFilterBudgetComponent,
    SearchFilterProgramTypeComponent,
    SearchFilterDeveloperCompanyComponent,
    SearchFilterOrientationComponent,
    SearchFilterOutsideComponent,
    SearchFilterDelivryDateComponent,
    SearchFilterActableComponent,
    SearchFilterTagsComponent,
    SearchFilterSelectComponent,
    SearchFilterInputComponent,
    SearchFilterReinitComponent,
    NewFastSearchPageComponent,
    LastConsultProspectListComponent,
  ],
  exports: [FastSearchPageComponent, SearchRoomsComponent, NewFastSearchPageComponent],
})
export class SearchModule {}
