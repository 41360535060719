<div class="flex flex-col items-start">
  <mat-button-toggle-group [(ngModel)]="showCheckboxSelection" (change)="filterProgramList()">
    <mat-button-toggle [value]="showCheckboxSelectionALL">
      {{ i18nService._('Txt_ToggleButton_All') }}
    </mat-button-toggle>

    <mat-button-toggle [value]="showCheckboxSelectionSelected">
      <span
        [ngClass]="{
          'mr-4 matBadge-center': programIdListControl.value && programIdListControl.value.length
        }"
        [matBadge]="programIdListControl.value && programIdListControl.value.length"
        matBadgeOverlap="false"
        [matBadgeHidden]="!programIdListControl.value?.length"
      >
        {{ i18nService._('Txt_ToggleButton_Selected') }}
      </span>
    </mat-button-toggle>

    <mat-button-toggle [value]="showCheckboxSelectionNotSelected">
      {{ i18nService._('Txt_ToggleButton_NotSelected') }}
    </mat-button-toggle>
  </mat-button-toggle-group>

  <app-advanced-tracking-table-no-back-page
    *ngIf="programListFiltered"
    [columns]="basicColumns"
    [itemsWithSelection]="programListFiltered"
    [noResultMessageI18nToken]="'Txt_Table_EmptyPrograms'"
    [selectable]="true"
    (checkedRowsChange)="checkedRowsChange($event)"
    [disabledSelection]="disabledForm"
    [selectedItems]="formUtilsService.getFormValue(SPECIAL_OFFER_CONTROL_PATH.programIdList, specialOfferForm)"
  ></app-advanced-tracking-table-no-back-page>

  <mat-error *ngIf="submit && this.programIdListControl.errors">
    {{ i18nService._('Error_field_programIdList') }}
  </mat-error>
</div>
