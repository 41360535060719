import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgFor, NgIf } from '@angular/common';

import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { LotResponse } from '../../../utils/models/LotResponse';
import { TaxationResponse } from '../../../utils/models/TaxationResponse';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { ReferenceTablesService } from '../../../utils/services/reference-tables.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { TaxationChecked } from '../../models/TaxationChecked';
import { TaxationTypeData } from '../../models/TaxationTypeData';
import { DecimalMaskDirective } from '../../../utils/directives/decimal-mask.directive';

@Component({
  selector: 'app-taxation-checkboxes-lot',
  templateUrl: './taxation-checkboxes-lot.component.html',
  styleUrls: ['./taxation-checkboxes-lot.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MatFormFieldModule,
    MatInputModule,
    DecimalMaskDirective,
    MatTooltipModule,
  ],
})
export class TaxationCheckboxesLotComponent implements OnInit, OnDestroy {
  /**
   * ParentForm to add controls to
   *
   * @type {FormGroup}
   * @memberof TaxationCheckboxesLotComponent
   */
  @Input() parentForm: UntypedFormGroup;

  /**
   * Init data from parent component to set
   *
   * @type {LotResponse}
   * @memberof TaxationCheckboxesLotComponent
   */
  @Input() dataToInit: LotResponse;

  /**
   * Boolean to show errors on submit
   *
   * @type {boolean}
   * @memberof TaxationCheckboxesLotComponent
   */
  @Input() isSubmit: boolean;

  /**
   * Data checkbox to be display in front with control on field
   *
   * @type {Array<TaxationTypeData>}
   * @memberof TaxationCheckboxesLotComponent
   */
  public taxationTypesData: Array<TaxationTypeData>;

  /**
   * Store taxation checked list for varRate and price fields
   *
   * @type {Array<TaxationChecked>}
   * @memberof TaxationCheckboxesLotComponent
   */
  public selectedTaxations: Array<TaxationChecked>;

  /**
   * Variable to show investor price fields and validators
   *
   * @type {boolean}
   * @memberof TaxationCheckboxesLotComponent
   */
  public hasInvestorTaxation: boolean;

  /**
   * Variable to show investor lmnpLmp price fields and validators
   *
   * @type {boolean}
   * @memberof TaxationCheckboxesLotComponent
   */
  public hasLmnpLmpTaxation: boolean;

  /**
   * Variable to show investor lmnpLmp price fields and validators
   *
   * @type {boolean}
   * @memberof TaxationCheckboxesLotComponent
   */
  public hasLmnpLmpNongereTaxation: boolean;

  /**
   * Variable to show investor Malraux or Deficit Foncier or Monuments Historiques price fields and validators
   *
   * @type {boolean}
   * @memberof TaxationCheckboxesLotComponent
   */
  public hasOtherTaxation: boolean;

  /**
   * Variable to show investor nue propriete price fields and validators
   *
   * @type {boolean}
   * @memberof TaxationCheckboxesLotComponent
   */
  public hasNueProprieteTaxation: boolean;

  /**
   * Variable to check MALRAUX taxation
   *
   * @type {boolean}
   * @memberof TaxationCheckboxesLotComponent
   */
  public hasMalrauxTaxation: boolean;

  /**
   * Variable to check Monuments Historiques taxation
   *
   * @type {boolean}
   * @memberof TaxationCheckboxesLotComponent
   */
  public hasDeficitFoncierTaxation: boolean;

  /**
   * Variable to check Monuments Historiques taxation
   *
   * @type {boolean}
   * @memberof TaxationCheckboxesLotComponent
   */
  public hasMonumentsHistoriquesTaxation: boolean;

  /**
   * List of investor taxation non required price name fields
   *
   * @type {Array<string>}
   * @memberof TaxationCheckboxesLotComponent
   */
  public investorTaxationFields: Array<string>;

  /**
   * List of LmnpLmp taxation required price name fields
   *
   * @type {Array<string>}
   * @memberof TaxationCheckboxesLotComponent
   */
  public requiredLmnpLmpTaxationFields: Array<string>;

  /**
   * List of Malraux or Deficit Foncier taxation required price name fields
   *
   * @type {Array<string>}
   * @memberof TaxationCheckboxesLotComponent
   */
  public requiredOtherTaxationFields: Array<string>;

  /**
   * List of LmnpLmp taxation non required price name fields
   *
   * @type {Array<string>}
   * @memberof TaxationCheckboxesLotComponent
   */
  public nonRequiredLmnpLmpTaxationFields: Array<string>;

  /**
   * List of non LmnpLmp Non gere taxation non required price name fields
   *
   * @type {Array<string>}
   * @memberof TaxationCheckboxesLotComponent
   */
  public nonRequiredNonLmnpLmpTaxationFields: Array<string>;

  /**
   * List of Nue propriete taxation non required price name fields
   *
   * @type {Array<string>}
   * @memberof TaxationCheckboxesLotComponent
   */
  public nonRequiredNueProprieteFields: Array<string>;

  /**
   * checkBoxControlValueChangesSubscription attribute
   *
   * @type {Subscription}
   * @memberof TaxationCheckboxesLotComponent
   */
  private checkBoxControlValueChangesSubscription: Subscription;

  /**
   * checkBoxControlValueChangesSubscription attribute
   *
   * @type {any}
   * @memberof TaxationCheckboxesLotComponent
   */
  public hTaxationsDisabled: any = {};

  /**
   * Creates an instance of TaxationCheckboxesLotComponent.
   * @param {I18nService} i18nService
   * @param {ReferenceTablesService} referenceTablesService
   * @param {SnackbarService} snackbarService
   * @param {FormBuilder} fb
   * @param {AppConfigService} appConfigService
   * @memberof TaxationCheckboxesLotComponent
   */
  constructor(
    public i18nService: I18nService,
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly snackbarService: SnackbarService,
    private readonly fb: UntypedFormBuilder,
    public readonly appConfigService: AppConfigService,
  ) {
    this.taxationTypesData = [];
    this.selectedTaxations = [];
    this.hasInvestorTaxation = false;
    this.hasLmnpLmpNongereTaxation = false;
    this.hasOtherTaxation = false;
    this.hasMalrauxTaxation = false;
    this.hasDeficitFoncierTaxation = false;
    this.hasMonumentsHistoriquesTaxation = false;
    this.hasNueProprieteTaxation = false;
    this.investorTaxationFields = ['estmatedMonthlyRentingPrice', 'estimatedProfitability'];
    this.requiredLmnpLmpTaxationFields = [
      'guaranteedMonthlyRentingPriceIT',
      'estatePriceET',
      'housingPriceET',
      'globalSellingPriceET',
      'tangiblePriceET',
    ];

    this.nonRequiredLmnpLmpTaxationFields = [
      'sellingFees',
      'loanFees',
      'attorneyFees',
      'monthlyRentingPriceET',
      'monthlyRentalLotPriceIT',
      'monthlyRentalSecondaryLotPriceIT',
    ];
    this.nonRequiredNonLmnpLmpTaxationFields = ['estimatedFurnishedMarketRent', 'estimatedFurnishedMarketYield'];

    this.requiredOtherTaxationFields = ['estimatedWorkPrice', 'estimatedLandPrice'];
    this.nonRequiredNueProprieteFields = ['usufruitValue', 'distributionKey', 'nueProprieteDuration'];
  }

  /**
   * Get lotHasTaxations FormArray
   *
   * @readonly
   * @type {FormArray}
   * @memberof TaxationCheckboxesLotComponent
   */
  get lotHasTaxationsForm(): UntypedFormArray {
    return this.parentForm.get('lotHasTaxations') as UntypedFormArray;
  }

  /**
   * Init method
   *
   * @memberof TaxationCheckboxesLotComponent
   */
  ngOnInit(): void {
    // Set form array validator for max one reduced taxation and at least one taxation
    this.lotHasTaxationsForm.setValidators(this.checkedValidator);

    // Set commercialLotSellingPriceIT and vatRate controls
    this.parentForm.addControl(
      'commercialLotSellingPriceIT',
      new UntypedFormControl('', [
        Validators.required,
        Validators.min(this.appConfigService.appConfig.minLotCommercialPriceTTC),
        Validators.max(this.appConfigService.appConfig.maxLotCommercialPriceTTC),
      ]),
    );
    this.parentForm.addControl(
      'vatRate',
      new UntypedFormControl({ disabled: true, value: undefined }, [Validators.required, Validators.min(0), Validators.max(100)]),
    );

    // Get Taxation from database
    this.referenceTablesService.getTableReferenceInfo('Taxations').subscribe(
      (taxationTypes: Array<TaxationResponse>) => {
        // Build form group for each taxation
        taxationTypes.forEach((taxationType) => {
          const formGroup = this.fb.group({
            id: new UntypedFormControl(taxationType.id, [Validators.required]),
            label: new UntypedFormControl(taxationType.label, [Validators.required]),
            taxation: new UntypedFormControl(taxationType.taxation, [Validators.required]),
            reducedTaxation: new UntypedFormControl(taxationType.reducedTaxation, [Validators.required]),
            checkBoxControl: new UntypedFormControl(false, [Validators.required]),
          });

          // Init onChange on taxation checked to display required fields and vatRate
          this.subscribeOnTaxationChanges(formGroup);

          // Push form group to form Array
          this.lotHasTaxationsForm.push(formGroup);

          // Data for html
          const taxationTypesData = {
            id: taxationType.id,
            label: taxationType.label,
            position: taxationType.position,
            checkBoxControl: formGroup.get('checkBoxControl'),
          };

          this.taxationTypesData.push(taxationTypesData);
        });
        // Sort for display
        this.taxationTypesData.sort((a, b) => (a.position < b.position ? -1 : 1));

        // Set prices fields controls
        const nonRequiredTaxationFields = this.investorTaxationFields
          .concat(this.nonRequiredLmnpLmpTaxationFields)
          .concat(this.nonRequiredNonLmnpLmpTaxationFields)
          .concat(this.nonRequiredNueProprieteFields);
        const requiredTaxationFields = this.requiredLmnpLmpTaxationFields.concat(this.requiredOtherTaxationFields);

        // Set Taxations and prices if update lot
        this.setDynamicTaxationFieldsControls(requiredTaxationFields, true);
        this.setDynamicTaxationFieldsControls(nonRequiredTaxationFields, false);
        this.rentingPriceVATexcludedOnChange();

        this.setTaxationsEditFormLot(this.dataToInit);
      },
      () => {
        this.snackbarService.sendErrorOccured();
      },
    );
  }

  /**
   * Destroy method
   * Remove all controls otherwise duplicates controls on next init
   *
   * @memberof TaxationCheckboxesLotComponent
   */
  ngOnDestroy(): void {
    const controlFieldsToRemove = this.investorTaxationFields.concat(
      this.requiredOtherTaxationFields,
      this.requiredLmnpLmpTaxationFields,
      this.nonRequiredLmnpLmpTaxationFields,
      this.nonRequiredNonLmnpLmpTaxationFields,
      this.nonRequiredNueProprieteFields,
    );
    controlFieldsToRemove.forEach((controlField) => {
      this.parentForm.removeControl(controlField);
    });
    this.parentForm.removeControl('commercialLotSellingPriceIT');
    this.parentForm.removeControl('vatRate');
    this.lotHasTaxationsForm.controls = [];
    if (this.checkBoxControlValueChangesSubscription) {
      this.checkBoxControlValueChangesSubscription.unsubscribe();
    }
  }

  /**
   * Validator to know if at least one tax and max one reduced tax
   *
   * @type {ValidatorFn}
   * @memberof TaxationCheckboxesLotComponent
   */
  checkedValidator: ValidatorFn = (): ValidationErrors | undefined => {
    const checkedCount = this.selectedTaxations.length;
    const checkedReducedTaxationCount = this.selectedTaxations.filter((x) => x.reducedTaxation).length;

    return checkedCount >= 1 && checkedReducedTaxationCount <= 1
      ? undefined
      : checkedReducedTaxationCount > 1
      ? { tooManyCheckedReducedTaxation: true }
      : { noChecked: true };
  };

  /**
   * Accept only number for field
   *
   * @param {*} evt
   * @returns {string}
   * @memberof TaxationCheckboxesLotComponent
   */
  checkIsNumber(evt): string {
    let evtValue = '';
    evt.target.value.split('').forEach((char) => {
      evtValue += Number.isInteger(parseInt(char, 10)) ? char : '';

      return evtValue;
    });

    return (evt.target.value = evtValue);
  }

  /**
   * Check min and max of commercialLotSellingPriceIT field
   *
   * @param {*} evt
   * @returns {string}
   * @memberof TaxationCheckboxesLotComponent
   */
  checkCommercialPriceTTC(evt): string {
    const nbrValue = this.parentForm.get('commercialLotSellingPriceIT').value;
    if (
      nbrValue < this.appConfigService.appConfig.minLotCommercialPriceTTC ||
      nbrValue > this.appConfigService.appConfig.maxLotCommercialPriceTTC
    ) {
      this.snackbarService.sendMessage({
        text: this.i18nService._('Error_SnackBar_ErrorOccuredRetry'),
        type: SnackbarMessageType.Error,
      });
    }

    return evt.target.value;
  }

  /**
   * Set Dynamic controls on price fields
   *
   * @param {*} taxationFieldList
   * @param {boolean} required
   * @memberof TaxationCheckboxesLotComponent
   */
  setDynamicTaxationFieldsControls(taxationFieldList: Array<string>, required: boolean): void {
    const requiredValidator = required ? [Validators.required, Validators.min(0.01)] : [];
    taxationFieldList.forEach((taxationField) => {
      this.parentForm.addControl(taxationField, new UntypedFormControl({ disabled: true, value: undefined }, requiredValidator));
    });
  }

  /**
   * Handle value change on taxation checkbox to display or not fields and validators
   *
   * @param {FormGroup} formGroup
   * @memberof TaxationCheckboxesLotComponent
   */
  subscribeOnTaxationChanges(formGroup: UntypedFormGroup): void {
    this.checkBoxControlValueChangesSubscription = formGroup.get('checkBoxControl').valueChanges.subscribe((checked) => {
      const taxationChecked = {
        id: formGroup.get('id').value,
        label: formGroup.get('label').value,
        taxation: formGroup.get('taxation').value,
        reducedTaxation: formGroup.get('reducedTaxation').value,
      };

      checked
        ? this.selectedTaxations.push(taxationChecked)
        : (this.selectedTaxations = this.selectedTaxations.filter((x) => x.label !== taxationChecked.label));

      this.setVatRateValue();

      this.setOrRemoveInvestorTaxationFields();

      this.setOrRemoveLmnpLmpTaxationFields();

      this.setOrRemoveTaxationFields(['hasOtherTaxation'], [formGroup.get('label').value], this.requiredOtherTaxationFields);

      this.setOrRemoveNueProprieteTaxationFields();

      this.setOrRemoveNonLmnpLmpTaxationFields();

      this.calculateTaxationsDisabled();
    });
  }

  private calculateTaxationsDisabled() {
    const hTaxationsDisabled = {};
    for (const taxation of this.taxationTypesData) {
      let disabled = false;
      switch (taxation.label) {
        case 'PINEL':
          disabled = this.isPinelPlusChecked(this.selectedTaxations);
          break;
        case 'PINEL PLUS':
          disabled = this.isPinelChecked(this.selectedTaxations);
          break;
        case 'LMNP NON GERE':
          disabled = this.isLmnpLmpChecked(this.selectedTaxations);
          break;
        case 'LMNP/LMP':
          disabled = this.isLmnpLmpNonGereChecked(this.selectedTaxations);
          break;
      }

      if (taxation.label !== 'NUE PROPRIETE') {
        if (this.isNueProprieteChecked(this.selectedTaxations)) {
          disabled = true;
        }
      }

      hTaxationsDisabled[taxation.label] = disabled;
      if (disabled) {
        if (taxation.checkBoxControl.enabled) taxation.checkBoxControl.disable();
        if (taxation.checkBoxControl.value) {
          taxation.checkBoxControl.setValue(false);
        }
      }
    }

    this.hTaxationsDisabled = hTaxationsDisabled;
  }
  /**
   * Set VatRate value with the taxation maximum of checked taxations
   *
   * @memberof TaxationCheckboxesLotComponent
   */
  setVatRateValue(): void {
    this.selectedTaxations.length
      ? this.parentForm.get('vatRate').setValue(Math.max(...this.selectedTaxations.map((x) => x.taxation)))
      : this.parentForm.get('vatRate').setValue(undefined);
  }

  /**
   * Enable Or Disable dynamically investor price fields according to checked taxation
   *
   * @memberof TaxationCheckboxesLotComponent
   */
  setOrRemoveInvestorTaxationFields(): void {
    this.investorTaxationFields.forEach((field) => {
      this.parentForm.get(field).enable();
    });
    this.hasInvestorTaxation = true;
  }

  /**
   * Enable Or Disable dynamically LMNP NON GERE price fields according to checked taxation
   *
   * @memberof TaxationCheckboxesLotComponent
   */
  setOrRemoveNonLmnpLmpTaxationFields(): void {
    if (this.isLmnpLmpChecked(this.selectedTaxations)) {
      this.hasLmnpLmpNongereTaxation = false;
      this.nonRequiredNonLmnpLmpTaxationFields.forEach((field) => {
        this.parentForm.get(field).disable();
      });
    } else {
      this.hasLmnpLmpNongereTaxation = true;
      this.nonRequiredNonLmnpLmpTaxationFields.forEach((field) => {
        this.parentForm.get(field).enable();
      });
    }
  }

  /**
   * Enable Or Disable dynamically LmnpLmp price fields according to checked taxation
   *
   * @memberof TaxationCheckboxesLotComponent
   */
  setOrRemoveLmnpLmpTaxationFields(): void {
    const taxationList = this.appConfigService.appConfig.taxationList;
    // TODO: nonRequiredNonLmnpLmpTaxationFields
    const lmnpLmpTaxationFieldsList = this.requiredLmnpLmpTaxationFields.concat(this.nonRequiredLmnpLmpTaxationFields);
    if (this.selectedTaxations.find((x) => x.label === taxationList.LMNPLMP)) {
      this.hasLmnpLmpTaxation = true;
      lmnpLmpTaxationFieldsList.forEach((field) => {
        if (field !== 'monthlyRentingPriceET') {
          this.parentForm.get(field).enable();
        }
      });
    } else {
      this.hasLmnpLmpTaxation = false;
      lmnpLmpTaxationFieldsList.forEach((field) => {
        this.parentForm.get(field).disable();
      });
    }
  }

  /**
   * Enable Or Disable dynamically Nue propriete price fields according to checked taxation
   *
   * @memberof TaxationCheckboxesLotComponent
   */

  setOrRemoveNueProprieteTaxationFields(): void {
    const taxationList = this.appConfigService.appConfig.taxationList;
    if (this.selectedTaxations.find((x) => x.label === taxationList['NUE PROPRIETE'])) {
      this.hasNueProprieteTaxation = true;
      this.nonRequiredNueProprieteFields.forEach((field) => {
        this.parentForm.get(field).enable();
      });
    } else {
      this.hasNueProprieteTaxation = false;
      this.nonRequiredNueProprieteFields.forEach((field) => {
        this.parentForm.get(field).disable();
      });
    }
  }

  /**
   * Enable Or Disable dynamically Malraux or Deficit Foncier price fields according to checked taxation
   *
   * @memberof TaxationCheckboxesLotComponent
   */
  setOrRemoveTaxationFields(taxationProperties: string[], taxationLabels: string[], requiredFields: string[]): void {
    const appConfig = this.appConfigService.getAppConfig();
    const taxationList = appConfig.taxationList;

    const hasMalrauxTaxation = this.hasCheckedTaxation(taxationList.MALRAUX);
    const hasDeficitFoncierTaxation = this.hasCheckedTaxation(taxationList['DEFICIT FONCIER']);
    const hasMonumentsHistoriquesTaxation = this.hasCheckedTaxation(taxationList['MONUMENTS HISTORIQUES']);
    const hasOtherTaxation = hasMalrauxTaxation || hasDeficitFoncierTaxation || hasMonumentsHistoriquesTaxation;

    this.setTaxationProperties(taxationProperties, hasOtherTaxation);
    this.hasMalrauxTaxation = hasMalrauxTaxation;
    this.hasDeficitFoncierTaxation = hasDeficitFoncierTaxation;
    this.hasMonumentsHistoriquesTaxation = hasMonumentsHistoriquesTaxation;

    this.toggleFormFields(hasOtherTaxation, requiredFields);
  }

  private hasCheckedTaxation(taxationLabel: string): boolean {
    return this.selectedTaxations.some((taxation) => taxation.label === taxationLabel);
  }

  private setTaxationProperties(taxationProperties: string[], hasOtherTaxation: boolean): void {
    taxationProperties.forEach((taxationProperty) => (this[taxationProperty] = hasOtherTaxation));
  }

  private toggleFormFields(hasOtherTaxation: boolean, requiredFields: string[]): void {
    const parentForm = this.parentForm;
    const estimatedWorkPrice = parentForm.get('estimatedWorkPrice');
    const estimatedLandPrice = parentForm.get('estimatedLandPrice');

    if (hasOtherTaxation) {
      estimatedWorkPrice.enable();
      estimatedLandPrice.enable();
      requiredFields.forEach((field) => parentForm.get(field).enable());
    } else {
      estimatedWorkPrice.disable();
      estimatedLandPrice.disable();
      requiredFields.forEach((field) => parentForm.get(field).disable());
    }
  }

  /**
   * Set init data if edit lot form
   *
   * @param {LotResponse} dataToInit
   * @memberof TaxationCheckboxesLotComponent
   */
  setTaxationsEditFormLot(dataToInit: LotResponse): void {
    if (dataToInit) {
      // Set Taxations checkbox values
      const dataLotTaxations = dataToInit.taxations;
      this.taxationTypesData.forEach((taxation) => {
        const dataTax = dataLotTaxations.find((x) => x.label === taxation.label);
        if (dataTax) {
          taxation.checkBoxControl.setValue(true);
        }
      });

      // Set prices values
      const priceFieldsToSet = this.investorTaxationFields.concat(
        this.requiredLmnpLmpTaxationFields,
        this.requiredOtherTaxationFields,
        this.nonRequiredLmnpLmpTaxationFields,
        this.nonRequiredNonLmnpLmpTaxationFields,
        this.nonRequiredNueProprieteFields,
        'commercialLotSellingPriceIT',
      );
      priceFieldsToSet.forEach((price) => {
        const dataPrice = Object.keys(dataToInit).find((x) => x === price);
        this.parentForm.get(dataPrice).setValue(dataToInit[dataPrice]);
      });
    }
  }

  private rentingPriceVATexcludedOnChange(): void {
    const sanitizeStringInput: (input: string) => number = (input: string) => Number(input.replace(',', '.').replace(' ', ''));
    this.parentForm.get('guaranteedMonthlyRentingPriceIT').valueChanges.subscribe((value) => {
      if (value) {
        // on create value is a string and contains spaces whereas on update value is a number
        const numberValue = typeof value === 'string' ? sanitizeStringInput(value) : value;
        // round to upper int value
        const rentingPriceVATexcluded = Math.ceil(numberValue / 1.1);
        this.parentForm.get('monthlyRentingPriceET').setValue(rentingPriceVATexcluded);
      }
    });
  }

  public isTaxationTypeValid(taxation: TaxationTypeData, selectedTaxations: Array<TaxationResponse>): boolean {
    const taxationList = this.appConfigService.appConfig.taxationList;

    if (taxation.label === taxationList['PINEL'] && this.isPinelPlusChecked(selectedTaxations)) {
      return false;
    }
    if (taxation.label === taxationList['PINEL PLUS'] && this.isPinelChecked(selectedTaxations)) {
      return false;
    }
    if (taxation.label === taxationList['LMNP NON GERE'] && this.isLmnpLmpChecked(selectedTaxations)) {
      taxation.checkBoxControl.setValue(false);
      return false;
    }
    if (taxation.label === taxationList['LMNPLMP'] && this.isLmnpLmpNonGereChecked(selectedTaxations)) {
      return false;
    }
    if (taxation.label !== taxationList['NUE PROPRIETE'] && this.isNueProprieteChecked(selectedTaxations)) {
      taxation.checkBoxControl.setValue(false);
      return false;
    }

    // reset nue propriété value when unselect to avoid lot compare issue
    if (taxation.label !== taxationList['NUE PROPRIETE'] && !this.isNueProprieteChecked(selectedTaxations)) {
      this.nonRequiredNueProprieteFields.forEach((field) => {
        this.parentForm.get(field).setValue(null);
      });
    }

    return true;
  }

  private isPinelPlusChecked(selectedTaxations: TaxationResponse[] | TaxationChecked[]): boolean {
    return selectedTaxations.some((taxation) => taxation.label === 'PINEL PLUS');
  }

  private isPinelChecked(selectedTaxations: TaxationResponse[] | TaxationChecked[]): boolean {
    return selectedTaxations.some((taxation) => taxation.label === 'PINEL');
  }

  private isLmnpLmpChecked(selectedTaxations: TaxationResponse[] | TaxationChecked[]): boolean {
    return selectedTaxations.some((taxation) => taxation.label === 'LMNP/LMP');
  }

  private isLmnpLmpNonGereChecked(selectedTaxations: TaxationResponse[] | TaxationChecked[]): boolean {
    return selectedTaxations.some((taxation) => taxation.label === 'LMNP NON GERE');
  }

  private isNueProprieteChecked(selectedTaxations: TaxationResponse[] | TaxationChecked[]): boolean {
    return selectedTaxations.some((taxation) => taxation.label === 'NUE PROPRIETE');
  }

  setDistributionKey(): void {
    const nueProprietePrice = this.parentForm.get('commercialLotSellingPriceIT').value;
    const usufruitValue = this.parentForm.get('usufruitValue').value;
    let value;
    if (nueProprietePrice && usufruitValue) {
      const ratio = Math.round((nueProprietePrice * 100) / (nueProprietePrice + usufruitValue));
      value = `${ratio}/${100 - ratio}`;
    } else {
      value = '-';
    }
    this.parentForm.get('distributionKey').setValue(value);
  }
}
