<div class="grid grid-cols-2 gap-16 mb-3">
  <!-- Developer -->
  <app-dropdown-list-populated
    *ngIf="!isDeveloper"
    [dataToInit]="recoveredInfos"
    [fieldName]="'company'"
    [isSubmit]="isSubmit"
    [items]="corporateNameItems"
    [parentForm]="parentForm"
    [placeholder]="i18nService._('Txt_Placeholder_DeveloperCompanyName')"
    (selectedItemChange)="onDevCompanyChange($event)"
    [required]="true"
    [searchable]="true"
    class="col-span-1"
  >
  </app-dropdown-list-populated>

  <mat-form-field *ngIf="isDeveloper" class="col-span-2 lg:col-span-1">
    <mat-label>{{ i18nService._('Txt_Placeholder_DeveloperCompanyName') }}</mat-label>
    <input [formControl]="developerCompanyNameControl" matInput name="company" />
  </mat-form-field>
</div>

<div class="grid grid-cols-2 gap-16 mb-3">
  <!-- Program name -->
  <mat-form-field class="col-span-2 lg:col-span-1">
    <mat-label>{{ i18nService._('Txt_Placeholder_ProgramName') }}</mat-label>
    <input type="text" [formControl]="programNameControl" name="programName" matInput data-cy="programName" maxlength="255" required />
    <mat-error *ngIf="programNameControl.errors && programNameControl.errors.required">
      {{ i18nService._('Error_Field_Required') }}
    </mat-error>
  </mat-form-field>

  <!-- Accounting program ref -->
  <mat-form-field class="col-span-2 lg:col-span-1">
    <mat-label>{{ i18nService._('Txt_Placeholder_AccountingProgramRef') }}</mat-label>
    <input
      type="text"
      name="accountingProgramRef"
      [formControl]="accountingProgramRefControl"
      maxlength="20"
      matInput
      data-cy="accountingProgramRef"
      required
    />
    <span class="icon-info txt-gray pr-2" matSuffix matTooltip="{{ i18nService._('Txt_Tooltip_AccountingProgramRef') }}"></span>
    <mat-error *ngIf="accountingProgramRefControl.errors && accountingProgramRefControl.errors.required">
      {{ i18nService._('Error_Field_Required') }}
    </mat-error>
    <mat-error *ngIf="accountingProgramRefControl.errors && accountingProgramRefControl.errors.programRefExist">
      {{ i18nService._('Error_Field_ProgramReference') }}
    </mat-error>
  </mat-form-field>
</div>

<div class="grid grid-cols-2 gap-16 mb-2">
  <!-- 3D view url -->
  <mat-form-field class="col-span-2 lg:col-span-1">
    <mat-label>{{ i18nService._('Txt_Placeholder_url3DView') }}</mat-label>
    <input type="text" [formControl]="url3DViewControl" name="3dView" matInput data-cy="url3DView" maxlength="255" />
    <span class="icon-info txt-gray pr-2" matSuffix matTooltip="{{ i18nService._('Txt_Tooltip_Url3DViewControl') }}"></span>
    <mat-error *ngIf="url3DViewControl.errors">
      {{ i18nService._('Error_Field_Url') }}
    </mat-error>
  </mat-form-field>
</div>

<div class="grid grid-cols-2 gap-16 mb-3">
  <!-- Fiscal zone -->
  <app-dropdown-list-populated
    class="col-span-2 lg:col-span-1"
    [parentForm]="parentForm"
    [searchable]="true"
    [items]="taxZoneItems"
    [placeholder]="i18nService._('Txt_Placeholder_taxZone')"
    data-cy="taxZone"
    [fieldName]="'taxZone'"
    [isSubmit]="isSubmit"
    [required]="true"
    [dataToInit]="recoveredInfos"
  >
  </app-dropdown-list-populated>

  <!-- Type -->
  <app-dropdown-list-populated
    class="col-span-2 lg:col-span-1"
    [parentForm]="parentForm"
    [searchable]="false"
    [referenceTable]="'ProgramTypes'"
    [placeholder]="i18nService._('Txt_Placeholder_programType')"
    [fieldName]="'programType'"
    [isSubmit]="isSubmit"
    [required]="true"
    [dataToInit]="recoveredInfos"
  >
  </app-dropdown-list-populated>
</div>

<div class="grid grid-cols-2 gap-16 mb-3">
  <!-- Lots number -->
  <mat-form-field class="col-span-2 lg:col-span-1">
    <mat-label>{{ i18nService._('Txt_Placeholder_lotsNumber') }}</mat-label>
    <input type="text" [formControl]="lotsNumberControl" name="nbLots" maxlength="3" matInput data-cy="lotsNumber" required />
    <mat-error *ngIf="lotsNumberControl.errors">
      <span *ngIf="lotsNumberControl.errors.required">
        {{ i18nService._('Error_Field_Required') }}
      </span>
      <span *ngIf="!lotsNumberControl.errors.required && lotsNumberControl.errors.isInteger">
        {{ i18nService._('Error_Field_IncorrectInteger') }}
      </span>
    </mat-error>
  </mat-form-field>
</div>

<!-- Actable -->
<div class="flex flex-row mb-5">
  <!-- Actable -->
  <mat-checkbox name="actable" [formControl]="actableControl" color="primary" class="col-span-1" data-cy="actable">
    {{ i18nService._('Txt_Checkbox_Actable') }}
  </mat-checkbox>
</div>

<hr class="w-5 mt-8 mb-12" />

<!-- Localisation -->
<div class="text-base my-4 font-bold">
  {{ i18nService._('Title_KeyDates') }}
</div>

<!-- Input Building Permit Start Date -->
<div class="grid grid-cols-2 gap-16 mb-3">
  <app-input-date
    class="col-span-2 lg:col-span-1"
    fieldName="buildingPermitStartedAt"
    [forcePick]="false"
    [parentForm]="parentForm"
    [placeholder]="i18nService._('Txt_Placeholder_BuildingPermitStartDate')"
    data-cy="buildingPermitStartDate"
  >
  </app-input-date>
</div>

<!-- Input Building Permit Expire Date -->
<div class="grid grid-cols-2 gap-16 mb-3">
  <app-input-date
    class="col-span-2 lg:col-span-1"
    fieldName="buildingPermitExpiredAt"
    [forcePick]="false"
    [parentForm]="parentForm"
    [placeholder]="i18nService._('Txt_Placeholder_BuildingPermitExpireDate')"
    data-cy="buildingPermitExpireDate"
  >
    >
  </app-input-date>
</div>

<!-- Input Working Date -->
<div class="grid grid-cols-2 gap-16 mb-3">
  <app-input-date
    class="col-span-2 lg:col-span-1"
    fieldName="workStartedAt"
    [forcePick]="false"
    [parentForm]="parentForm"
    [placeholder]="i18nService._('Txt_Placeholder_WorkingDate')"
    data-cy="workingDate"
  >
    >
  </app-input-date>
</div>

<hr class="w-5 mt-8 mb-12" />

<div class="text-base my-4 font-bold">
  {{ i18nService._('Title_ConstructionWork') }}
</div>

<div class="grid grid-cols-2 gap-16 mb-2">
  <mat-form-field>
    <mat-label>{{ i18nService._('Txt_Placeholder_ConstructionWorkRatio') }}</mat-label>
    <input
      [formControl]="constructionWorkRatioControl"
      appDecimalMask
      data-cy="constructionWorkRatio"
      class="text-right"
      matInput
      maxNumberDigitsBeforeSeparator="3"
      name="constructionWorkRatio"
    />
    <span class="text-black px-2 font-bold" matSuffix matTooltip="{{ i18nService._('Txt_Placeholder_ConstructionWorkRatio') }}">%</span>
    <mat-error *ngIf="constructionWorkRatioControl.errors">
      <span *ngIf="!constructionWorkRatioControl.errors.isInteger">
        {{ i18nService._('Error_Field_MustBeBetween0And100') }}
      </span>
      <span *ngIf="constructionWorkRatioControl.errors.isInteger">
        {{ i18nService._('Error_Field_IncorrectInteger') }}
      </span>
    </mat-error>
  </mat-form-field>
</div>

<hr class="w-5 mt-8 mb-12" />

<!-- Localisation -->
<div class="text-base my-4 font-bold">
  {{ i18nService._('Title_Location') }}
</div>

<!-- Domiciliation informations -->
<app-postal-address [parentForm]="parentForm" [recoveredInfo]="recoveredPostalAddress"></app-postal-address>

<!-- Coordinates -->
<app-coordinates [parentForm]="parentForm" [recoveredInfo]="recoveredCoordinates"></app-coordinates>

<!-- Poximity city -->
<div class="grid grid-cols-2 gap-16 mb-3">
  <mat-form-field *ngIf="parentForm.controls.isFrench.value" class="col-span-2 lg:col-span-1">
    <mat-label>{{ i18nService._('Txt_Placeholder_Proximity_City') }}</mat-label>
    <input
      [formControl]="proximityCityControl"
      [matAutocomplete]="auto"
      autocomplete="no-autocomplete"
      matInput
      data-cy="proximityCity"
      type="text"
    />
    <mat-autocomplete [displayWith]="proximityCityDisplay" #auto="matAutocomplete">
      <mat-option *ngFor="let city of cityList | async" [value]="city">
        {{ city.label }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>

<hr class="w-5 mt-8 mb-12" />

<!-- Biling address -->
<div class="text-base my-4 font-bold">
  {{ i18nService._('Title_BillingAddress') }}
</div>

<!-- Domiciliation informations -->
<app-postal-address [parentForm]="parentForm" [dbSuffix]="'invoice'" [recoveredInfo]="recoveredInvoicePostalAddress"></app-postal-address>

<hr class="w-5 mt-8 mb-12" />

<!-- Program Description Block -->
<div class="text-base my-4 font-bold">
  {{ i18nService._('Title_PropertyProgramDescription') }}
</div>

<ng-container *ngIf="isValo">
  <!-- Short Description Valo -->
  <div class="flex flex-col mb-5">
    <div class="text-base my-2 font-semibold">
      {{ i18nService._('Txt_Page_Form_RegisterProgram_ShortDescriptionValo') }}
    </div>
    <div class="text-sm mb-2 text-gray-400">
      {{ i18nService._('Txt_Page_Form_Note_ShortDescriptionValo') }}
    </div>
    <quill-editor
      name="shortDescrValo"
      data-cy="registerProgramShortDescriptionValo"
      [formControl]="shortDescriptionValoControl"
      placeholder=""
      (onContentChanged)="controlMaxLength($event)"
    ></quill-editor>
  </div>

  <!-- Long Description Valo -->
  <div class="flex flex-col mb-5">
    <div class="text-base my-2 font-semibold">
      {{ i18nService._('Txt_Page_Form_RegisterProgram_LongDescriptionValo') }}
    </div>
    <div class="text-sm mb-2 text-gray-400">
      {{ i18nService._('Txt_Page_Form_Note_LongDescriptionValo') }}
    </div>
    <quill-editor
      name="longDescrValo"
      [formControl]="longDescriptionValoControl"
      placeholder=""
      (onContentChanged)="controlMaxLength($event)"
    ></quill-editor>
  </div>

  <div *ngIf="recoveredInfos && recoveredDescrDeveloper" class="grid grid-cols-2 gap-16">
    <!-- Short Description Developer -->
    <mat-form-field class="col-span-2 lg:col-span-1">
      <mat-label>{{ i18nService._('Txt_Page_Form_RegisterProgram_ShortDescrDev') }}</mat-label>
      <textarea
        name="shortDescrDev"
        [value]="recoveredDescrDeveloper.shortDescrDeveloper"
        disabled
        matInput
        minRows="2"
        matTextareaAutosize
        matAutosizeMinRows="3"
        matAutosizeMaxRows="5"
        maxlength="2000"
        data-cy="registerProgramShortDescrDev"
      ></textarea>
    </mat-form-field>

    <!-- Long Description Developer -->
    <mat-form-field class="col-span-2 lg:col-span-1">
      <mat-label>{{ i18nService._('Txt_Page_Form_RegisterProgram_LongDescrDev') }}</mat-label>
      <textarea
        name="longDescrDev"
        [value]="recoveredDescrDeveloper.longDescrDeveloper"
        disabled
        matInput
        minRows="2"
        matTextareaAutosize
        matAutosizeMinRows="3"
        matAutosizeMaxRows="5"
        maxlength="2000"
        data-cy="registerProgramLongDescrDev"
      ></textarea>
    </mat-form-field>
  </div>
</ng-container>
<div class="grid grid-cols-2 gap-16">
  <!-- Description -->
  <div class="col-span-2">
    <mat-form-field class="w-full">
      <mat-label>{{ i18nService._('Txt_Page_Form_RegisterProgram_Description') }}</mat-label>
      <textarea
        matInput
        [formControl]="descriptionControl"
        matTextareaAutosize
        matAutosizeMinRows="5"
        matAutosizeMaxRows="10"
        maxlength="2000"
        name="description"
        data-cy="registerProgramDescription"
      ></textarea>
      <mat-hint class="text-sm mb-2 text-gray-400">
        {{ i18nService._('Txt_Page_Form_Note_Description') }}
      </mat-hint>
    </mat-form-field>
  </div>
</div>
<div class="grid grid-cols-2 gap-16">
  <!-- Situation -->
  <mat-form-field class="col-span-2 lg:col-span-1">
    <mat-label>{{ i18nService._('Txt_Page_Form_RegisterProgram_Situation') }}</mat-label>
    <textarea
      name="situation"
      [formControl]="situationControl"
      matInput
      minRows="2"
      matTextareaAutosize
      matAutosizeMinRows="3"
      matAutosizeMaxRows="5"
      maxlength="2000"
      data-cy="registerProgramSituation"
    ></textarea>
  </mat-form-field>

  <!-- Services -->
  <mat-form-field class="col-span-2 lg:col-span-1">
    <mat-label>{{ i18nService._('Txt_Page_Form_RegisterProgram_Services') }}</mat-label>
    <textarea
      name="services"
      [formControl]="servicesControl"
      matInput
      minRows="2"
      matTextareaAutosize
      matAutosizeMinRows="3"
      matAutosizeMaxRows="5"
      maxlength="2000"
      data-cy="registerProgramServices"
    ></textarea>
  </mat-form-field>
</div>

<!-- Quality -->
<div class="grid grid-cols-2 gap-16">
  <mat-form-field class="col-span-2 lg:col-span-1">
    <mat-label>{{ i18nService._('Txt_Page_Form_RegisterProgram_Quality') }}</mat-label>
    <textarea
      [formControl]="qualityControl"
      matInput
      minRows="2"
      matTextareaAutosize
      matAutosizeMinRows="3"
      matAutosizeMaxRows="5"
      maxlength="2000"
      data-cy="registerProgramQuality"
    ></textarea>
  </mat-form-field>

  <!-- Access -->
  <mat-form-field class="col-span-2 lg:col-span-1">
    <mat-label>{{ i18nService._('Txt_Page_Form_RegisterProgram_Access') }}</mat-label>
    <textarea
      [formControl]="accessControl"
      matInput
      minRows="2"
      matTextareaAutosize
      matAutosizeMinRows="3"
      matAutosizeMaxRows="5"
      maxlength="2000"
      data-cy="registerProgramAccess"
    ></textarea>
  </mat-form-field>
</div>

<app-dropdown-list-populated
  class="col-span-2 lg:col-span-1"
  [parentForm]="parentForm"
  [searchable]="true"
  [items]="highlightList"
  [placeholder]="i18nService._('Txt_page_program_highlights_title')"
  [maxSelectedItems]="3"
  [fieldName]="'highlights'"
  [isSubmit]="isSubmit"
  [required]="false"
  [dataToInit]="recoveredInfos"
>
</app-dropdown-list-populated>

<!-- End of program description block -->

<hr class="w-5 mt-8 mb-12" />

<!-- Description Documents -->
<div class="text-base my-4 font-bold">
  {{ i18nService._('Title_PresentationDocumentation') }}
</div>

<!-- Perspective Document -->
<app-file-drop
  [fieldNameKey]="'perspective'"
  [attachmentName]="i18nService._('Txt_Placeholder_Perspective')"
  [required]="true"
  [includingForm]="parentForm.controls.document"
  [isSubmit]="isSubmit"
  [validExt]="['image/png', 'image/jpg', 'image/jpeg']"
  [initialValue]="initFile['perspective']"
>
</app-file-drop>

<!-- Ground Plan Document -->
<app-file-drop
  [fieldNameKey]="'groundPlan'"
  [attachmentName]="i18nService._('Txt_Placeholder_GroundPlan')"
  [required]="true"
  [includingForm]="parentForm.controls.document"
  [isSubmit]="isSubmit"
  [initialValue]="initFile['groundPlan']"
>
</app-file-drop>

<!-- Commercial Brochure Document -->
<app-file-drop
  [fieldNameKey]="'CommercialBrochure'"
  [includingForm]="parentForm.controls.document"
  [attachmentName]="i18nService._('Txt_Placeholder_CommercialBrochure')"
  [isSubmit]="isSubmit"
  [validExt]="['application/pdf']"
  [initialValue]="initFile['CommercialBrochure']"
>
</app-file-drop>

<!-- Digital Commercial Brochure URL -->
<mat-form-field class="col-span-2 lg:col-span-1">
  <mat-label>{{ i18nService._('Txt_Placeholder_DigitalCommercialBrochureUrl') }}</mat-label>
  <input
    name="DigitalCommercialBrochureUrl"
    type="text"
    [formControl]="digitalCommercialBrochureUrlControl"
    matInput
    data-cy="digitalCommercialBrochureUrl"
    maxlength="255"
  />
  <span class="icon-info txt-gray pr-2" matSuffix matTooltip="{{ i18nService._('Txt_Tooltip_DigitalCommercialBrochureUrl') }}"></span>
  <mat-error *ngIf="digitalCommercialBrochureUrlControl.errors">
    <span>{{ i18nService._('Error_Field_Url') }}</span>
  </mat-error>
</mat-form-field>

<!-- Error message at least one uploaded -->
<mat-error class="w-full text-xs py-1 px-3" *ngIf="parentForm.errors?.oneRequired && isSubmit">
  {{ i18nService._('Error_Field_CommercialBrochure') }}
</mat-error>

<!-- Presentation of the Program Document -->
<app-file-drop
  [fieldNameKey]="'presentationOfTheProgram'"
  [attachmentName]="i18nService._('Txt_Placeholder_PresentationOfTheProgram')"
  [includingForm]="parentForm.controls.document"
  [isSubmit]="isSubmit"
  [validExt]="['application/pdf']"
  [initialValue]="initFile['presentationOfTheProgram']"
>
</app-file-drop>

<!-- Descriptive Notice Document -->
<app-file-drop
  [fieldNameKey]="'descriptiveNotice'"
  [attachmentName]="i18nService._('Txt_Placeholder_DescriptiveNotice')"
  [required]="true"
  [includingForm]="parentForm.controls.document"
  [isSubmit]="isSubmit"
  [validExt]="['application/pdf']"
  [initialValue]="initFile['descriptiveNotice']"
>
</app-file-drop>
<!-- End of Description Documents -->

<!-- Contractual Documents -->
<div class="text-base my-4 font-bold">
  {{ i18nService._('Title_ContractualDocuments') }}
</div>

<!-- Error message at least one uploaded -->
<mat-error class="w-full text-xs py-1 px-3" *ngIf="parentForm.controls.document.errors && isSubmit">
  {{ i18nService._('Error_Field_Contract') }}
</mat-error>

<!-- Apartment Contract -->
<!-- Change from Mo to Mio for maxFileSize (VALO-3657) -->
<app-file-drop
  [fieldNameKey]="'ApartmentReservationContract'"
  [attachmentName]="i18nService._('Txt_Placeholder_ApartmentReservationContract')"
  [includingForm]="parentForm.controls.document"
  [isSubmit]="isSubmit"
  [validExt]="acceptedFileType"
  [initialValue]="initFile['ApartmentReservationContract']"
>
</app-file-drop>

<!-- House Contract -->
<!-- Change from Mo to Mio for maxFileSize (VALO-3657) -->
<app-file-drop
  [fieldNameKey]="'HouseReservationContract'"
  [attachmentName]="i18nService._('Txt_Placeholder_HouseReservationContract')"
  [includingForm]="parentForm.controls.document"
  [isSubmit]="isSubmit"
  [validExt]="acceptedFileType"
  [initialValue]="initFile['HouseReservationContract']"
>
</app-file-drop>

<!-- Trade Contract -->
<!-- Change from Mo to Mio for maxFileSize (VALO-3657) -->
<app-file-drop
  [fieldNameKey]="'tradeReservationContract'"
  [attachmentName]="i18nService._('Txt_Placeholder_TradeReservationContract')"
  [includingForm]="parentForm.controls.document"
  [isSubmit]="isSubmit"
  [validExt]="acceptedFileType"
  [initialValue]="initFile['tradeReservationContract']"
>
</app-file-drop>

<!-- Parking map -->
<app-file-drop
  [fieldNameKey]="'ParkingMap'"
  [attachmentName]="i18nService._('Txt_Placeholder_ParkingMap')"
  [includingForm]="parentForm.controls.document"
  [isSubmit]="isSubmit"
  [validExt]="['application/pdf']"
  [initialValue]="initFile['ParkingMap']"
>
</app-file-drop>

<!-- Floor map -->
<app-file-drop
  [fieldNameKey]="'FloorMap'"
  [attachmentName]="i18nService._('Txt_Placeholder_FloorMap')"
  [includingForm]="parentForm.controls.document"
  [isSubmit]="isSubmit"
  [validExt]="['application/pdf']"
  [initialValue]="initFile['FloorMap']"
>
</app-file-drop>

<!-- ERP -->
<app-file-drop
  [fieldNameKey]="'ERPDocument'"
  [attachmentName]="i18nService._('Txt_Placeholder_ERPDocument')"
  [includingForm]="parentForm.controls.document"
  [isSubmit]="isSubmit"
  [validExt]="['application/pdf']"
  [initialValue]="initFile['ERPDocument']"
>
</app-file-drop>

<!-- guarantee formula -->
<app-file-drop
  [fieldNameKey]="'guaranteeFormula'"
  [attachmentName]="i18nService._('Txt_Placeholder_GuaranteeFormula')"
  [includingForm]="parentForm.controls.document"
  [isSubmit]="isSubmit"
  [validExt]="['application/pdf']"
  [maxFileSize]="104857600"
  [initialValue]="initFile['guaranteeFormula']"
>
</app-file-drop>

<!-- substantive timeLine -->
<app-file-drop
  [fieldNameKey]="'substantiveTimeLine'"
  [attachmentName]="i18nService._('Txt_Placeholder_SubstantiveTimeLine')"
  [includingForm]="parentForm.controls.document"
  [isSubmit]="isSubmit"
  [validExt]="['application/pdf']"
  [initialValue]="initFile['substantiveTimeLine']"
>
</app-file-drop>

<!-- End of Contractual Documents -->

<!-- Administrative Documents -->
<div class="text-base my-4 font-bold">
  {{ i18nService._('Title_AdministrativeDocuments') }}
</div>

<!-- KBIS -->
<app-file-drop
  [fieldNameKey]="'KBIS'"
  [attachmentName]="i18nService._('Txt_Placeholder_KBISExtract')"
  [includingForm]="parentForm.controls.document"
  [isSubmit]="isSubmit"
  [validExt]="['application/pdf']"
  [initialValue]="initFile['KBIS']"
>
</app-file-drop>

<!-- GFA Attestation -->
<app-file-drop
  [fieldNameKey]="'gfaAttestation'"
  [attachmentName]="i18nService._('Txt_Placeholder_GfaAttestation')"
  [includingForm]="parentForm.controls.document"
  [isSubmit]="isSubmit"
  [validExt]="['application/pdf']"
  [initialValue]="initFile['gfaAttestation']"
>
</app-file-drop>
<!-- End of Administrative Documents -->

<!-- Next Step -->
<div class="mt-1">
  <button type="button" class="mt-3" mat-stroked-button color="primary" data-cy="nextStep" matStepperNext (click)="submitStep()">
    {{ i18nService._('Txt_Button_NextStep') }}
  </button>
</div>
