import { AfterViewInit, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { groupBy } from 'lodash-es';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgIf } from '@angular/common';

import { LotComparatorMetaLineComponent } from '../lot-comparator-meta-line/lot-comparator-meta-line.component';

import { LotComparatorDetail } from '../../../../lot/model/lot-comparator-detail';
import { BasicFormatsService } from '../../../../utils/services/basic-formats.service';
import { FileSystemService } from '../../../../utils/services/file-system.service';
import { ComparatorMeta, ComparatorMetaWrapper } from '../../../models/comparator-meta';
import { ComparatorMetaPartial } from '../../../models/comparator-meta-partial';
import { AbstractComparatorComponent } from '../../abstract-comparator-component';
import { AsPipe } from '../../../../utils/pipes/as.pipe';

@Component({
  selector: 'app-lot-comparator-annexes',
  templateUrl: './lot-comparator-annexes.component.html',
  styleUrls: ['./lot-comparator-annexes.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, MatIconModule, LotComparatorMetaLineComponent, AsPipe],
})
export class LotComparatorAnnexesComponent extends AbstractComparatorComponent implements AfterViewInit {
  lotMetaAnnexes: ComparatorMetaWrapper;
  @Input() lotDetailToCompare: LotComparatorDetail[];
  @ViewChild('annexesBalconTemplateRef', { static: true })
  public annexesBalconTemplateRef: TemplateRef<unknown>;
  @ViewChild('annexesParkingTemplateRef', { static: true })
  public annexesParkingTemplateRef: TemplateRef<unknown>;
  @ViewChild('annexesGarageTemplateRef', { static: true })
  public annexesGarageTemplateRef: TemplateRef<unknown>;
  ComparatorMetaPartial = ComparatorMetaPartial;

  constructor(
    public basicFormatsService: BasicFormatsService,
    private readonly _fileSystemService: FileSystemService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.lotMetaAnnexes = this._comparatorPageService.comparatorMeta.annexes;

    (this.lotMetaAnnexes.BALCONY as ComparatorMeta).templateRef = this.annexesBalconTemplateRef;
    (this.lotMetaAnnexes.TERRACE as ComparatorMeta).templateRef = this.annexesBalconTemplateRef;
    (this.lotMetaAnnexes.GARDEN as ComparatorMeta).templateRef = this.annexesBalconTemplateRef;
    (this.lotMetaAnnexes.LOGGIA as ComparatorMeta).templateRef = this.annexesBalconTemplateRef;
    (this.lotMetaAnnexes.OUTDOOR_PARKING as ComparatorMeta).templateRef = this.annexesParkingTemplateRef;
    (this.lotMetaAnnexes.BASEMENT_PARKING_LOT as ComparatorMeta).templateRef = this.annexesParkingTemplateRef;
    (this.lotMetaAnnexes.BOXES as ComparatorMeta).templateRef = this.annexesGarageTemplateRef;
    (this.lotMetaAnnexes.GARAGE as ComparatorMeta).templateRef = this.annexesGarageTemplateRef;
    (this.lotMetaAnnexes.LARDER as ComparatorMeta).templateRef = this.annexesGarageTemplateRef;
    (this.lotMetaAnnexes.ATTIC as ComparatorMeta).templateRef = this.annexesGarageTemplateRef;
    (this.lotMetaAnnexes.CELLAR as ComparatorMeta).templateRef = this.annexesGarageTemplateRef;
    (this.lotMetaAnnexes.CARPORT as ComparatorMeta).templateRef = this.annexesGarageTemplateRef;
  }

  returnListeAnnexe(lotDetail) {
    if (lotDetail !== undefined && lotDetail.annexes && lotDetail.annexes.length !== 0) {
      return groupBy(lotDetail.annexes, (annexe) => {
        return annexe.label;
      });
    }
  }
}
