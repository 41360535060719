import { IsEnum, IsNumber, IsOptional, IsString } from 'class-validator';

import { ChargeTypeEnum } from './charge-type.enum';

export class ChargeRequestDto {
  @IsString()
  @IsOptional()
  id?: string;

  @IsNumber()
  amount: number;

  @IsEnum(ChargeTypeEnum)
  chargeTypeCode: ChargeTypeEnum;
}
