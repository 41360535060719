export enum TokenErrorEnum {
  EXPIRED_TOKEN = 'expired_token',
  NOT_FOUND = 'not_found',
  INVALID_REQUEST = 'invalid_request', // status = 400
  INVALID_CLIENT = 'invalid_client', // status = 401
  INVALID_GRANT = 'invalid_grant', // status = 403
  UNAUTHORIZED_CLIENT = 'unauthorized_client', // status = 403
  UNSUPPORTED_GRANT_TYPE = 'unsupported_grant_type', // status = 400
  INVALID_SCOPE = 'invalid_scope', // status = 400
  INVALID_REFRESH_TOKEN = 'invalid_refresh_token', // status = 400
  SESSION_NOT_ACTIVE = 'session_not_active', // status = 400
  SESSION_NOT_ACTIVE_ON_REFRESH_TOKEN = 'Session not active',
}
