import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';

import { CardComponent } from './card/card.component';
import { HorizontalCardComponent } from './horizontal-card/horizontal-card.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { BoxBackButtonComponent } from './box-back-button/box-back-button.component';
import { InfoBoxComponent } from './info-box/info-box.component';
import { TitleComponent } from './title/title.component';
import { VerticalProgressComponent } from './vertical-progress/vertical-progress.component';
import { ProgressStepComponent } from './progress-step/progress-step.componet';
import { PageHeaderComponent } from './page-header/page-header.component';
import { StatusTagComponent } from './status-tag/status-tag.component';
import { SiderComponent } from './sider/sider.component';
import { ProspectCardComponent } from './prospect-card/prospect-card.component';
import { IconComponent } from '../standalone/icon/icon.component';
import { ButtonCardComponent } from './button-card/button-card.component';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    IconComponent,
    CardComponent,
    HorizontalCardComponent,
    TooltipComponent,
    BoxBackButtonComponent,
    InfoBoxComponent,
    TitleComponent,
    VerticalProgressComponent,
    ProgressStepComponent,
    SiderComponent,
    PageHeaderComponent,
    StatusTagComponent,
    ProspectCardComponent,
    ButtonCardComponent,
  ],
  exports: [
    CardComponent,
    HorizontalCardComponent,
    TooltipComponent,
    BoxBackButtonComponent,
    InfoBoxComponent,
    TitleComponent,
    VerticalProgressComponent,
    ProgressStepComponent,
    SiderComponent,
    PageHeaderComponent,
    StatusTagComponent,
    ProspectCardComponent,
    ButtonCardComponent,
  ],
})
export class SharedModule {}
