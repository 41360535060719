<h2 class="text-center" mat-dialog-title>
  {{ i18nService._('TXT_lot_fees_history_dialog_title', [dialogData.lotNumber]) }}
</h2>

<mat-dialog-content class="px-5">
  <app-alert [type]="alertEnum.INFO" [body]="'Txt_alert_lot_fees_history'" class="prospect-alert mb-4"></app-alert>
  <ng-container *ngIf="lotFeesHistoryList && lotFeesHistoryList.length">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef>
          {{ i18nService._('TXT_lot_fees_history_dialog_table_header_date') }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.created }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="rate">
        <th mat-header-cell *matHeaderCellDef>{{ i18nService._('TXT_lot_fees_history_dialog_table_header_rate') }}</th>
        <td mat-cell *matCellDef="let element">{{ element.rate }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </ng-container>
</mat-dialog-content>
