import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AutoCompleteService } from '../../common/form/auto-complete.service';
import { ProspectApiService } from '../../adapters/prospect-api.service';

// FIXME: pas utile pour le moment mais le sera dans une prochaine US, comme je l'ai fait dans une V1 autant le garder =)
@Injectable({
  providedIn: 'root',
})
export class ProspectSearch implements AutoCompleteService {
  companies: { label: string; id: number }[];

  constructor(private prospectApiService: ProspectApiService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  findData(name: string, observers: any): Observable<{ label: string; id: number }[]> {
    return of([]);
    /*
    const accountId = observers['props.accountId'] ? observers['props.accountId'].value : undefined;
    const params: ProspectParamRequest = { accountId: accountId, name: name, offset: 0, nbResult: 200 };
    return this.prospectLegacyApiService.getProspects(params).pipe(
      map((accountsResponse: TableResponse<LegacyProspectSummaryResponse>) => {
        return accountsResponse.items.map((account: LegacyProspectSummaryResponse) => {
          return {
            id: account.id,
            label: `${account.firstName} ${account.lastName} (${account.companyName})`,
          };
        });
      }),
    );
    */
  }
}
