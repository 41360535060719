<div class="px-4">
  <h2 *ngIf="data.title" class="text-center" mat-dialog-title>
    {{ data.title }}
  </h2>
  <div mat-dialog-content>
    <p [innerHTML]="data.message | safe: 'html'"></p>
  </div>
  <mat-checkbox [(ngModel)]="isChecked">{{ data.checkbox }}</mat-checkbox>
  <div class="justify-center text-center" mat-dialog-actions>
    <button (click)="clickExitButton()" cdkFocusInitial class="mx-auto" data-cy="exit" color="primary" mat-flat-button>
      {{ i18nService._('Txt_Button_Exit') }}
    </button>
  </div>
</div>
