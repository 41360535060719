import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { GridOptions } from 'ag-grid-community';
import { lastValueFrom, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NgIf } from '@angular/common';

import { TableLoader, TableResponse } from '../../../table/interfaces/DataLoader';
import { GridTrackingTableComponent } from '../../../tracking-tables/components/grid-tacking-table/grid-tracking-table.component';
import { I18nService } from '../../../utils/services/i18n.service';
import { TrackingTablesModule } from '../../../tracking-tables/tracking-tables.module';
import { IconComponent } from '../../standalone/icon/icon.component';

@Component({
  selector: 'app-formly-table',
  templateUrl: './formly-table.component.html',
  styleUrls: ['./formly-table.component.scss'],
  standalone: true,
  imports: [NgIf, IconComponent, TrackingTablesModule],
})
export class FormlyTableComponent extends FieldType implements OnInit, AfterViewInit {
  pageSize = 10;
  tableHeight = 'auto';
  serverSide = false;
  gridOptions: GridOptions = {
    headerHeight: 45,
    rowHeight: 60,
    paginationPageSize: 90,
    paginationPageSizeSelector: false,
    suppressCellFocus: true,
    rowModelType: 'clientSide',
    domLayout: 'autoHeight',
    rowMultiSelectWithClick: true,
    enableCellTextSelection: true,
    context: {
      componentParent: this,
    },
  };
  tableLoader: TableLoader<any>;

  @ViewChild('table') table: GridTrackingTableComponent;

  constructor(
    public i18nService: I18nService,
    private readonly _dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    this.tableLoader = this.createTableLoader();
    this.formControl.valueChanges.subscribe((rows) => {
      this.table.setRowData(rows ? rows : []);
    });
  }

  ngAfterViewInit(): void {
    this.field.props.onFieldCreated(this.table, this.form, this.field);
  }

  createTableLoader(): TableLoader<any> {
    const data = this.model[this.field?.key.toString()];
    return {
      async get(): Promise<TableResponse<any>> {
        return { items: await lastValueFrom(of(data)) };
      },
    };
  }

  addRow() {
    const openSecondaryLotAddDialog = this._dialog.open(this.field?.props?.addDialog, {
      autoFocus: false,
      width: '40vw',
      panelClass: 'dialog-container-new',
      data: this.field?.props?.data,
    });

    openSecondaryLotAddDialog.afterClosed().subscribe((response: any) => {
      if (response) {
        const rows = this.field.formControl.value ?? [];
        rows.push(response);
        this.field.formControl.setValue(rows);
      }
    });
  }
}
