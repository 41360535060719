import { Component, Input } from '@angular/core';
import { NgFor } from '@angular/common';

import { MenuConfig } from '../header/header.component';
import { HeaderMenuEntryComponent } from '../header-menu-entry/header-menu-entry.component';

// export interface MenuConfig {
//   label: string;
//   icon: string;
//   mustBeDisabled?: boolean;
//   // Weight to ordering menu.
//   weight: number;
//   items: Array<{
//     label: string;
//     path?: string;
//     action?: string;
//     externalLink?: string;
//     data?: { roles: Array<string> };
//   }>;
// }

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
  standalone: true,
  imports: [NgFor, HeaderMenuEntryComponent],
})
export class HeaderMenuComponent {
  @Input() isMobile = false;
  /**
   * List of menu items.
   *
   * @memberof HeaderMenuComponent
   */
  @Input() menuConfigList: Array<MenuConfig>;
}
