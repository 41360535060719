import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: [],
  standalone: true,
  imports: [MatCardModule],
})
export class AppInformationComponent {
  @Input() title: string;
  @Input() content: string;
}
