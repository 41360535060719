import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SearchQuery } from '@commons-dto/search';
import { isNil } from 'lodash';
import { ListResponse, ParamRequest } from '@commons-dto/valo-back';

import { AppConfigService } from '../utils/services/app-config.service';
import { ErrorHandlerService } from '../utils/services/error-handler.service';

@Injectable({ providedIn: 'root' })
export class DataLayerApiService {
  protected SERVICE_URL: string;

  constructor(
    readonly appConfigService: AppConfigService,
    protected readonly errorHandlerService: ErrorHandlerService,
    protected readonly httpClient: HttpClient,
  ) {
    this.SERVICE_URL = appConfigService.getLoopbackApiUrl();
  }

  protected getBySearchQuery<T>(path: string, searchQuery?: SearchQuery): Observable<T> {
    const url = [this.SERVICE_URL, path].filter(Boolean).join('/');
    const queryParams = this.getQueryParamsBySearchQuery(searchQuery);
    return this.httpClient.get<T>(url, { params: queryParams }).pipe(catchError(this.errorHandlerService.handleError<T>('get')));
  }

  private getQueryParamsBySearchQuery(searchQuery?: SearchQuery): HttpParams {
    let queryParams: HttpParams = new HttpParams();
    if (searchQuery) {
      for (const key of Object.keys(searchQuery)) {
        queryParams = queryParams.set(key, searchQuery[key]);
      }
    }
    return queryParams;
  }

  /**
   * The function `getRessource` retrieves a resource from a specified path using an HTTP request and
   * returns it as an Observable.
   * @param {string} path - The `path` parameter is a string that represents the endpoint or URL path
   * where the resource is located. It is used to construct the HTTP request URL.
   * @param {number} id - The `id` parameter is a number that represents the identifier of the resource
   * you want to retrieve.
   * @returns an Observable of type T.
   */
  protected getRessource<T>(path: string, id?: number): Observable<T> {
    let queryParams = new HttpParams();
    if (id) queryParams = queryParams.set('id', id.toString());
    return this.requestTable<T>(path, queryParams, null).pipe(
      map((res) => {
        return res['items'][0];
      }),
    );
  }

  /**
   * The function `getTableRessources` retrieves table using the provided parameters and
   * path.
   * @param {ParamRequest} paramsRequest - An object containing the parameters for the request.
   * @param {string} path - The `path` parameter is a string that represents the endpoint or URL path
   * where the request will be sent to. It typically specifies the location of a resource on a server.
   * @returns An Observable of type ListResponse<T> is being returned.
   */
  protected getTableRessources<T>(paramsRequest: ParamRequest, path: string): Observable<ListResponse<T>> {
    const queryParams: HttpParams = this.getQueryParams(paramsRequest);
    const body = this.getBody(paramsRequest);
    return this.requestTable(path, queryParams, body);
  }

  /**
   * The function `get` is a protected method that sends an HTTP GET request to a specified path
   * and returns an Observable of an array of type T.
   * @param {string} path - The `path` parameter is a string that represents the endpoint or path of
   * the API that you want to make a request to.
   * @returns an Observable of an array of type T.
   */
  protected get<T>(path: string, queryParams?: HttpParams): Observable<T> {
    const url = [this.SERVICE_URL, path].filter(Boolean).join('/');
    return this.httpClient.get<T>(url, { params: queryParams }).pipe(catchError(this.errorHandlerService.handleError<T>('get')));
  }

  protected post<T>(path: string, body: unknown): Observable<T> {
    const url = [this.SERVICE_URL, path].filter(Boolean).join('/');
    return this.httpClient.post<T>(url, body);
  }

  protected put<T>(path: string, body: unknown): Observable<T> {
    const url = [this.SERVICE_URL, path].filter(Boolean).join('/');
    return this.httpClient.put<T>(url, body);
  }

  protected delete<T>(path: string, queryParams?: HttpParams): Observable<T> {
    const url = [this.SERVICE_URL, path].filter(Boolean).join('/');
    return this.httpClient.delete<T>(url, { params: queryParams }).pipe(catchError(this.errorHandlerService.handleError<T>('get')));
  }

  protected patch<T>(path: string, body: unknown): Observable<T> {
    const url = [this.SERVICE_URL, path].filter(Boolean).join('/');
    return this.httpClient.patch<T>(url, body);
  }

  private requestTable<T>(path: string, queryParams: HttpParams, body): Observable<ListResponse<T>> {
    const url = [this.SERVICE_URL, path].filter(Boolean).join('/');

    // POST
    if (body && Object.keys(body).length > 0) {
      return this.httpClient
        .post<ListResponse<T>>(url, body, { params: queryParams })
        .pipe(catchError(this.errorHandlerService.handleError<ListResponse<T>>('post')));
    } else {
      return this.httpClient
        .get<ListResponse<T>>(url, { params: queryParams })
        .pipe(catchError(this.errorHandlerService.handleError<ListResponse<T>>('get')));
    }
  }

  protected getQueryParams(paramsRequest: ParamRequest): HttpParams {
    let params = new HttpParams().set('offset', paramsRequest.offset.toString()).set('limit', paramsRequest.nbResult.toString());
    if (paramsRequest.sortColumn) {
      params = params.set('sortColumn', paramsRequest.sortColumn).set('sortDirection', paramsRequest.sortDirection);
    }
    if (!isNil(paramsRequest.isTeamMember)) {
      params = params.set('isTeamMember', `${paramsRequest.isTeamMember ? 1 : 0}`);
    }
    if (!isNil(paramsRequest.isPartner)) {
      params = params.set('isPartner', `${paramsRequest.isPartner ? 1 : 0}`);
    }
    if (!isNil(paramsRequest.filter)) {
      params = params.set('filter', paramsRequest.filter);
    }
    if (!isNil(paramsRequest.statusFilter)) {
      params = params.set('statusFilter', paramsRequest.statusFilter);
    }
    if (!isNil(paramsRequest.columns)) {
      params = params.set('columns', paramsRequest.columns.join(','));
    }
    return params;
  }

  protected getBody(paramsRequest: ParamRequest): { i18nFilters?: string; selfFilters?: string } {
    const param: { i18nFilters?: string; selfFilters?: string } = {};

    if (paramsRequest.i18nFilters) {
      param.i18nFilters = paramsRequest.i18nFilters;
    }
    if (paramsRequest.selfFilters) {
      param.selfFilters = paramsRequest.selfFilters;
    }
    return param;
  }
}
