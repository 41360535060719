import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, Observable, Subscription } from 'rxjs';

import { Sitemap } from '../models/Sitemap';

@Injectable({
  providedIn: 'root',
})
export class RoutingStateService {
  public previousRoutePath = new BehaviorSubject<string>('');
  /**
   * history attribute
   *
   * @private
   * @memberof RoutingStateService
   */
  private history: Array<string>;
  /**
   * Current Url after a url change
   *
   * @private
   * @memberof RoutingStateService
   */
  private readonly currentUrlAfterRedirect: BehaviorSubject<string>;
  private previousURL: any;
  private currentURL: any;

  /**
   * Creates an instance of RoutingStateService.
   * @param {Router} router
   * @param location
   * @memberof RoutingStateService
   */
  constructor(
    private readonly router: Router,
    private readonly location: Location,
  ) {
    this.history = [];
    this.currentUrlAfterRedirect = new BehaviorSubject('');
    this.previousRoutePath.next(undefined);

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.previousURL = this.currentURL;
        this.currentURL = event.url;

        this.previousRoutePath.next(this.previousURL);
        // Update the previous URL
      }
    });
  }

  /**
   * currentUrlAfterRedirectObservable method
   *
   * @returns {Observable<string>}
   * @memberof RoutingStateService
   */
  public get currentUrlAfterRedirectObservable(): Observable<string> {
    return this.currentUrlAfterRedirect.asObservable();
  }

  /**
   * loadRouting method
   *
   * @memberof RoutingStateService
   */
  public loadRouting(): Subscription {
    return this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(({ urlAfterRedirects }: NavigationEnd) => {
      this.setCurrentUrlAfterRedirect(urlAfterRedirects);
      this.addHistoryEntry(urlAfterRedirects);
    });
  }

  /**
   * Add url to history if not already at history's last position
   *
   * @param {string} url
   * @memberof RoutingStateService
   */
  public addHistoryEntry(url: string): void {
    if (this.history.length) {
      // Sometimes urls can haves slashes before or after that can lead to miscomparison, so we trim it.
      const trimSlashes = (str: string) => str.replace(/^\/|\/$/g, '');
      if (trimSlashes(this.history[this.history.length - 1]) !== trimSlashes(url)) {
        this.history.push(url);
      }
    } else {
      this.history = [url];
    }
  }

  /**
   * Remove last entry from history.
   *
   * @memberof RoutingStateService
   */
  public removeLastHistoryEntry(): void {
    this.history.pop();
  }

  /**
   * Returns previous url, or override url if provided, or homepage url if none exists.
   *
   * @param {string} overrideUrl url to "force return" if truthy.
   * @param defaultUrl
   * @returns {string}
   * @memberof RoutingStateService
   */
  public getPreviousUrl(overrideUrl?: string, defaultUrl = Sitemap.utils.homepage.path): string {
    if (overrideUrl) {
      return overrideUrl;
    }
    return this.previousURL ?? defaultUrl;
  }

  /**
   * setCurrentUrlAfterRedirect method
   *
   * @param {string} currentUrlAfterRedirect
   * @memberof RoutingStateService
   */
  private setCurrentUrlAfterRedirect(currentUrlAfterRedirect: string): void {
    this.currentUrlAfterRedirect.next(currentUrlAfterRedirect);
  }
}
