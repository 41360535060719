<ng-template #panelTemplate>
  <app-item [item]="lotData?.lotNumber"></app-item>
  <app-item [item]="lotData?.typeLot"></app-item>
  <app-item [item]="lotData?.room"></app-item>
  <app-item [item]="lotData?.space"></app-item>
  <app-item [item]="lotData?.lotFloor"></app-item>
  <app-item [item]="lotData?.lotOrientation"></app-item>
  <ng-container *ngIf="lotData">
    <ng-container *ngFor="let area of lotData.areas">
      <app-item [item]="area"></app-item>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="lotData">
    <ng-container *ngFor="let annexe of lotData.annexes">
      <app-item [item]="annexe"></app-item>
    </ng-container>
  </ng-container>
  <app-item *ngIf="lotData?.fees && feesCanBeSeen()" [item]="lotData?.fees"></app-item>
  <app-item [item]="lotData?.delivryDate"></app-item>
  <app-item [item]="lotData?.taxation"></app-item>
</ng-template>

<app-single-accordion-panel
  [title]="'Txt_page_program_Detail_Lot_information_block_title'"
  [optionTemplate]="panelTemplate"
  [open]="true"
></app-single-accordion-panel>
