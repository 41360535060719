/* eslint-disable @typescript-eslint/no-explicit-any */
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { map, Observable } from 'rxjs';
import { MatOptionModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';

import { HighlightPipe } from '../../pipes/highlight.pipe';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-chip-populated-autocomplete',
  templateUrl: './chip-populated-autocomplete.component.html',
  styleUrls: ['./chip-populated-autocomplete.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    MatFormFieldModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    MatAutocompleteModule,
    MatOptionModule,
    AsyncPipe,
    HighlightPipe,
  ],
})
/**
 * @example
 * TYPESCRIPT :
 *   private availableDepartment$: Observable<Map<string, any>>;
 *   ...
 *   this.availableDepartment$ = this.createAccountService.getDepartments().pipe(
 *     map(
 *       departments => {
 *         const items = new Map();
 *         departments.forEach(department => items.set(`${department.label} (${department.code})`, department));
 *
 *         return items;
 *       },
 *     ),
 *   );
 * HTML :
 *   <app-chip-populated-autocomplete
 *     *ngIf="(availableDepartment$ | async) as department"
 *     [id]="'hasDepartment'"
 *     [title]="i18nService._('Txt_Placeholder_Has_Department')"
 *     [required]="false"
 *     [max]="10"
 *     [parentForm]="secondFormGroup"
 *     [items]="department">
 *   </app-chip-populated-autocomplete>
 */
export class ChipPopulatedAutocompleteComponent implements OnInit, OnDestroy {
  @ViewChild('itemInput') itemInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @Input() id: string;
  @Input() default: string;
  @Input() defaultId: any;
  @Input() title: string;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() removable = true;
  @Input() selectable = false;
  @Input() addOnBlur = false;
  @Input() min: number;
  @Input() max: number;
  // Return 'value' instead of '[value]'.
  @Input() rawFormat = false;
  @Input() parentForm: UntypedFormGroup;
  @Input() items: Map<string, any>;
  @Input() sentenceIfNoItems: string;
  @Input() isDisabled: boolean;
  @Output() readonly valueChanged: EventEmitter<any>;

  public availableItems: Array<string>;
  public selectedItems: Array<string>;
  public filteredItems: Observable<Array<string>>;

  public separatorKeysCodes: Array<number> = [ENTER, COMMA];
  public itemCtrl = new UntypedFormControl();
  public chipCtrl: UntypedFormControl;
  public isEmpty: boolean;
  public fn: any;
  public value: string;
  public noDataFound = I18nService.__('Error_Field_Incorrect');

  /**
   * Creates an instance of ChipListComponent.
   *
   * @param {I18nService} i18nService
   * @memberof CreateAccountComponent
   */
  constructor(public i18nService: I18nService) {
    this.valueChanged = new EventEmitter<any>(undefined);
    this.sentenceIfNoItems = '';
    this.isEmpty = false;
    this.isDisabled = false;
  }

  ngOnInit(): void {
    this.selectedItems = [];
    this.availableItems = Array.from(this.items.keys()).sort();
    this.isEmpty = Boolean(this.availableItems.length);
    this.filteredItems = this.itemCtrl.valueChanges.pipe(map((input) => (input ? this.filter(input) : this.availableItems)));

    // show error when no result
    this.fn = (event: KeyboardEvent) => {
      if (event.key === '40' || (event.key === '38' && this.value.length === 1 && this.value[0] === this.noDataFound)) {
        event.stopPropagation();
      }
    };
    document.addEventListener('keydown', this.fn, true);

    const validators: Array<ValidatorFn> = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.min) {
      validators.push(Validators.minLength(this.min));
    }
    if (this.max) {
      validators.push(Validators.maxLength(this.max));
    }

    // eslint-disable-next-line no-prototype-builtins
    if (this.parentForm.controls.hasOwnProperty(this.id)) {
      this.chipCtrl = this.parentForm.controls[this.id] as UntypedFormControl;
      this.chipCtrl.clearValidators();
      this.chipCtrl.setValidators(validators);
    } else {
      this.chipCtrl = new UntypedFormControl(undefined, validators);
      this.parentForm.addControl(this.id, this.chipCtrl);
    }

    // Disable field if needed
    if (this.isDisabled) {
      this.chipCtrl.disable();
    }

    // Add default value if needed
    if (this.default) {
      this.selected(this.default);
    } else if (this.defaultId) {
      this.selectById(this.defaultId);
    } else if (this.chipCtrl.value) {
      this.selectById(this.chipCtrl.value);
    }
  }

  /**
   * Update values.
   */
  private update(): void {
    if (this.max === 1 && this.rawFormat) {
      this.chipCtrl.setValue(this.items.get(this.selectedItems[0]));
    } else {
      const values = [];
      this.selectedItems.forEach((selected) => values.push(this.items.get(selected)));
      this.chipCtrl.setValue(values);
    }
    this.valueChanged.emit(this.chipCtrl.value);
    this.availableItems.sort();
  }

  /**
   * Remove item.
   *
   * @param item
   */
  public remove(item: string): void {
    if (!this.chipCtrl.disabled && this.selectedItems.includes(item)) {
      this.selectedItems = this.selectedItems.filter((selected) => selected !== item);
      this.availableItems.push(item);
      this.update();

      if (this.max && this.selectedItems.length < this.max) {
        this.itemCtrl.enable();
      }
    }
  }

  /**
   * selectById method
   *
   * @param {number} id
   * @memberof ChipPopulatedAutocompleteComponent
   */
  selectById(id: number): void {
    this.items.forEach((value, key) => {
      if (value === id) {
        this.selected(key);

        return;
      }
    });
  }

  /**
   * Select item.
   *
   * @param item
   */
  public selected(item: any): void {
    if (item && this.availableItems.includes(item)) {
      this.availableItems = this.availableItems.filter((available) => available !== item);
      this.selectedItems.push(item);
      this.update();

      if (this.itemInput) {
        this.itemInput.nativeElement.value = '';
      }
      this.itemCtrl.setValue('');

      if (this.max && this.selectedItems.length >= this.max) {
        this.itemCtrl.disable();
      }
    } else if (item.length) {
      item.forEach((elm) => {
        this.availableItems = this.availableItems.filter((available) => available !== elm);
        this.selectedItems.push(elm);
      });
      this.update();
    }
  }

  /**
   * Return filtered items.
   *
   * @param value
   */
  private filter(value: string): Array<string> {
    const filterValue = value.trim().toLowerCase();

    const filteredValues = this.availableItems.filter((available) => available.toLowerCase().indexOf(filterValue) >= 0);

    if (filteredValues.find((filtered) => filtered.toLowerCase().indexOf(filterValue) === 0)) {
      filteredValues.sort((a, b) => a.toLowerCase().indexOf(filterValue) - b.toLowerCase().indexOf(filterValue));
    }

    return filteredValues.length ? filteredValues : [this.noDataFound];
  }

  ngOnDestroy(): void {
    document.removeEventListener('keydown', this.fn);
  }

  /**
   * Handles the focus-out event on the input. This method is a workaround for a known issue
   * with Angular Material Chips and Reactive Forms.
   * TODO: Remove this workaround once the issue (https://github.com/angular/components/issues/26358) is resolved.
   */
  onBlur() {
    setTimeout(() => this.restoreArrayOfObjects(), 0);
  }

  /**
   * Restores the array of selected items. This method is part of the workaround for the Angular Material Chips issue.
   * TODO: Remove this workaround once the issue (https://github.com/angular/components/issues/26358) is resolved.
   */
  private restoreArrayOfObjects(): void {
    if (!this.chipCtrl?.value || typeof this.chipCtrl.value[0] !== 'string') {
      return;
    }

    const values = this.selectedItems.map((selected) => this.items.get(selected));
    this.chipCtrl.setValue(values);
  }
}
