import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { some } from 'lodash-es';
import { NgIf } from '@angular/common';

import { SignatureDocumentComponent } from '../documents/signature-document.component';
import { CommentComponent } from '../comment/comment.component';
import { CategoryComponent } from '../categories/category.component';
import { FormHeaderComponent } from '../form-header/form-header.component';
import { SideInfoComponent } from '../side-info/side-info.component';

import { GoogleTagManagerService } from './../../../utils/services/google-tag-manager.service';
import { Documents, FormViewResponse } from '../../models/FormResponse';
import { I18nService } from '../../../utils/services/i18n.service';
import { SignatureService } from './../../services/signature.service';
import { SignatureDematDocumentTypeEnum } from '../../models/signature-demat-document-type.enum';
import { Sitemap } from '../../../utils/models/Sitemap';
import { BoxBackButtonComponent } from '../../../common/shared/box-back-button/box-back-button.component';

@Component({
  selector: 'app-signature-dashboard',
  templateUrl: './signature-dashboard.component.html',
  styleUrls: ['./signature-dashboard.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    BoxBackButtonComponent,
    SideInfoComponent,
    FormHeaderComponent,
    CategoryComponent,
    CommentComponent,
    SignatureDocumentComponent,
  ],
})
export class SignatureDashboardComponent implements OnInit, OnDestroy {
  dossierId: number;
  formView: FormViewResponse;
  dashboardDocuments: Documents[];
  private readonly _unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly signatureService: SignatureService,
    public readonly i18nService: I18nService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly _googleTagManagerService: GoogleTagManagerService,
  ) {}

  ngOnInit(): void {
    this.dossierId = this.route.snapshot.params.signatureId;
    this.refreshDossier(this.dossierId);
    this.signatureService.formViewDossier$.pipe(takeUntil(this._unsubscribe$)).subscribe((formViewResponse) => {
      if (formViewResponse) {
        this.formView = formViewResponse;
        this.dashboardDocuments = formViewResponse.documents.filter((doc) => doc.documentTypeCode === SignatureDematDocumentTypeEnum.SPEC);
      }
    });
  }

  isFormCompleted(form: FormViewResponse) {
    const { categories } = form;
    return !some(categories, (category) => category.remainedFields > 0);
  }

  ngOnDestroy() {
    this._unsubscribe$.next(true);
    this._unsubscribe$.complete();
  }

  accessSynthesisPage() {
    this._googleTagManagerService.pushTag({ event: 'esignature_validation' });
    this.router.navigate(['../synthesis'], { relativeTo: this.route });
  }

  refreshDossier(dossierId: number) {
    this.signatureService.getFormViewByDossierId(dossierId);
  }

  goBack(): () => void {
    return () => {
      this.router.navigate([Sitemap.dashboards.dossier.path.replace(/:signatureId/, this.dossierId.toString())]);
    };
  }
}
