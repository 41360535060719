import { Pipe, PipeTransform } from '@angular/core';

import { Taxation } from '../../signature/models/Taxation';

@Pipe({
  name: 'i18nTVALabelPourcentage',
  standalone: true,
})
export class I18nTVALabelPourcentagePipe implements PipeTransform {
  transform(taxation: Taxation): string {
    if (!taxation) {
      return 'NOMINAL_TAXATION_POURCENTAGE';
    }

    return `${taxation.label}_POURCENTAGE`;
  }
}
