import { DossierCreate, DossierCreateResponse, SearchDossierQuery } from '@commons-dto/dossier-prospect';
import { BehaviorSubject, catchError, filter, firstValueFrom, map, Observable, of, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DossierResponse, ProspectResponse, SimulationRequestDto, SimulationResponseDto } from 'libs/commons-dto/src/lib/dossier-prospect';
import { ProspectType } from 'libs/commons-dto/src/lib/dossier-prospect/prospect/prospect-type.enum';

import { DossierProspectFormData } from '../model/forms/dossier-prospect-form-data.model';
import { PaternityCreate } from '../model/paternity-create.model';
import { PaternityFormData } from '../model/forms/paternity-form-data.model';
import { DossierProspectRouteService } from '../components/dossier-prospect/dossier-prospect.route.service';
import { ProspectDataMapper } from '../model/prospect.mapper';

import { DossierProspectApiService } from './../../adapters/dossier-prospect-api.service';
import { UtilsCompanyService } from './../../utils/services/utils-company.service';
import { MandatedDeveloperCompanyData } from './../../utils/models/MandatedDeveloperCompanyData';
import { SnackbarTexts } from './../../utils/models/SnackbarMessage';
import { SnackbarService } from './../../utils/services/snackbar.service';
import { ErrorHandlerService } from './../../utils/services/error-handler.service';
import { AppIconsName } from '../../common/standalone/icon/utils/app-icon-names';
import { I18nService } from '../../utils/services/i18n.service';

@Injectable({
  providedIn: 'root',
})
export class DossierProspectService {
  private devCompanies: MandatedDeveloperCompanyData[];
  devCompaniesSub: BehaviorSubject<MandatedDeveloperCompanyData[]> = new BehaviorSubject<MandatedDeveloperCompanyData[]>(null);

  private dossiersForSearching: Partial<DossierResponse>[];
  dossiersForSearchingSub: BehaviorSubject<Partial<DossierResponse>[]> = new BehaviorSubject<Partial<DossierResponse>[]>(null);

  private currentDossier: DossierResponse;
  currentDossierSub: BehaviorSubject<DossierResponse> = new BehaviorSubject<DossierResponse>(null);

  constructor(
    private readonly _errorHandler: ErrorHandlerService,
    private readonly _snackBar: SnackbarService,
    private readonly _utilsCompany: UtilsCompanyService,
    private readonly dossierProspectApi: DossierProspectApiService,
    private readonly dossierProspectRouteService: DossierProspectRouteService,
    private readonly _i18n: I18nService,
  ) {}

  checkDuplicate(dossier: Partial<DossierResponse>): Observable<boolean> {
    return this.dossierProspectApi.canUpsert(dossier).pipe(
      map(() => false),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 409) {
          const dossierId: number = err.error;
          const message: SnackbarTexts = {
            title: 'Prospect_Create_Duplicate_Title',
            html: 'Prospect_Create_Duplicate_Message',
            linkTxt: 'Prospect_Create_Duplicate_Link',
            link: this.dossierProspectRouteService.getDashboardPath(dossierId),
          };
          this._snackBar.warningI18n(message);
          return of(true);
        } else {
          this._errorHandler.handleError('DossierProspectService', 'checkDuplicate');
          throw err;
        }
      }),
    );
  }

  createDossier(
    formData: DossierProspectFormData,
    prescriptorId: number,
    paternitiesFormData?: PaternityFormData[],
  ): Observable<DossierCreateResponse> {
    const request: DossierCreate = {
      prescriptorId,
      prospects: ProspectDataMapper.fromProspectCreate(formData),
      paternities: paternitiesFormData ? PaternityCreate.fromForm(paternitiesFormData) : null,
    };
    return this.dossierProspectApi
      .createDossier(request)
      .pipe(catchError(this._errorHandler.handleError<DossierCreateResponse>('DossierProspectService', 'createDossier')));
  }

  updateDossier(data: Partial<DossierResponse>) {
    if (!data.id) {
      throw this._errorHandler.handleError('DossierProspectService', 'updateDossier');
    }
    return this.dossierProspectApi.updateDossier(data).pipe(tap((dossier) => this.updateLocalDossier(dossier)));
  }

  getDevelopersFromMandate() {
    this._utilsCompany.getDeveloperCompanyWhereUserAreMandatedAndItsPrograms().subscribe((data: MandatedDeveloperCompanyData[]) => {
      this.devCompanies = data;
      this.devCompaniesSub.next(this.devCompanies);
    });
  }

  loadDossiersForSearching() {
    this.dossierProspectApi.loadDossiersForSearching().subscribe((dossiers) => {
      this.dossiersForSearching = dossiers.items;
      this.dossiersForSearchingSub.next(this.dossiersForSearching);
    });
  }

  /**
   * The function `getDossier` retrieves a dossier with a given ID, updates the local dossier if it
   * exists, and returns an observable of the current dossier.
   * @param {number} id - The `id` parameter is a number that represents the identifier of the dossier
   * to retrieve.
   * @param [skipIfExists=false] - The `skipIfExists` parameter is a boolean value that determines
   * whether to skip the API call if the current dossier already exists and has the same ID as the one
   * being requested.
   * @returns an Observable of the currentDossierSub, filtered to only emit values that are truthy
   * (i.e. not null or undefined).
   */
  getDossier(id: number, forConsult = false, skipIfExists = true) {
    if (!skipIfExists || this.currentDossier?.id !== id) {
      this.dossierProspectApi
        .getDossier(id, forConsult)
        .pipe(
          catchError(() => {
            throw this._errorHandler.handleError('DossierProspectService', 'getDossier');
          }),
        )
        .subscribe((dossier) => this.updateLocalDossier(dossier));
    }
    return this.currentDossierSub.pipe(filter((value) => !!value));
  }

  /**
   * Retrieves the dossiers based on a search query.
   * @param {SearchDossierQuery} searchQuery - The query to use for searching dossiers.
   * @returns An observable that emits an array of retrieved partial dossiers or throws an error if the retrieval fails.
   */
  getDossierProspectBySearchQuery(searchQuery: SearchDossierQuery): Observable<Partial<DossierResponse>[]> {
    return this.dossierProspectApi.getDossierProspect(searchQuery).pipe(
      catchError(() => {
        throw this._errorHandler.handleError('DossierProspectService', 'getDossierProspectBySearchQuery');
      }),
    );
  }

  updateLocalDossier(dossier: DossierResponse) {
    this.currentDossier = dossier;
    this.currentDossierSub.next(this.currentDossier);
  }

  getIcon(dossier: DossierResponse): AppIconsName {
    if (dossier.prospects.length == 0) return 'UserSolid';
    if (dossier.prospects[0].prospectType == ProspectType.COMPANY) return 'BuildingOffice2Solid';
    return dossier.prospects.length > 1 ? 'UsersSolid' : 'UserSolid';
  }

  getIconByProspect(prospect: ProspectResponse): AppIconsName {
    return prospect.prospectType == ProspectType.PERSON ? 'UserSolid' : 'BuildingOffice2Solid';
  }

  getHeader(): { header: string; icon: AppIconsName } {
    const icon = this.getIcon(this.currentDossier);
    if (this.currentDossier.prospects[0].prospectType == ProspectType.COMPANY) {
      return {
        header: this.currentDossier.prospects[0].companyName,
        icon: icon,
      };
    }
    const names = [];
    this.currentDossier.prospects.forEach((prospect) => {
      names.push(prospect.firstName + ' ' + prospect.lastName);
    });
    return {
      header: names.join(' & '),
      icon: icon,
    };
  }

  getCommonHeader(dossier: DossierResponse): string {
    const prospects = dossier.prospects;
    if (prospects.length === 0) {
      return '';
    }
    if (prospects[0].prospectType === ProspectType.COMPANY) {
      return prospects[0].companyName
        ? `<span class="text-black">${prospects[0].companyName}</span> ${this._i18n._(
            'Prospect_Represented_By',
          )} <span class="text-black">${this.getProspectFullName(prospects[0])}</span>`
        : `${this._i18n._('Prospect_Company_Prefix')} ${this._i18n._(
            'Prospect_Represented_By',
          )} <span class="text-black">${this.getProspectFullName(prospects[0])}</span>`;
    } else {
      return prospects.length === 1
        ? `<span class="text-black">${this.getProspectFullName(prospects[0])}</span>`
        : `<span class="text-black">${this.getProspectFullName(prospects[0])}</span> ${this._i18n._(
            'Prospect_And',
          )} <span class="text-black">${this.getProspectFullName(prospects[1])}</span>`;
    }
  }

  getDashboardHeader(dossier: DossierResponse): string {
    const dossierPrefix = this._i18n._('Prospect_Dossier_Dashboard_Prefix');
    return `${dossierPrefix} ${this.getCommonHeader(dossier)}`;
  }

  getEditHeader(dossier: DossierResponse): string {
    const dossierPrefix = `<span class='text-gray-300 font-bold'> ${this._i18n._('Prospect_Dossier_Edit_Prefix')}`;
    return `${dossierPrefix} ${this.getCommonHeader(dossier)} </span>`;
  }

  getProspectFullName(prospect: ProspectResponse): string {
    return `${prospect.firstName} ${prospect.lastName}`;
  }

  loadSimulation(id: number): Observable<SimulationResponseDto> {
    return this.dossierProspectApi.getSimulation(id).pipe(
      catchError(() => {
        throw this._errorHandler.handleError('DossierProspectService', 'loadSimulation');
      }),
    );
  }

  async saveSimulation(simulation: SimulationRequestDto): Promise<SimulationResponseDto> {
    try {
      return firstValueFrom(this.dossierProspectApi.saveSimulation(simulation));
    } catch (e) {
      throw this._errorHandler.handleError('DossierProspectService', 'saveSimulation');
    }
  }

  getCurrentDossier(): DossierResponse {
    return this.currentDossier;
  }
}
