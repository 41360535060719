<div class="flex items-center flex-col w-[600px]">
  <button class="closing-dialog" (click)="closeDialog(false)">
    <app-icon name="XMark"></app-icon>
  </button>
  <div *ngIf="data.imageSource">
    <img [src]="data.imageSource" />
  </div>
  <h2 class="text-center" mat-dialog-title *ngIf="data.title">
    {{ _i18nService._(data.title) }}
  </h2>
  <div mat-dialog-content>
    <p *ngIf="hasHTMLMessage; else translateMessage" [innerHTML]="data.message | safe: 'html'"></p>
    <ng-template #translateMessage>
      <p>{{ _i18nService._(data.message) }}</p>
    </ng-template>
  </div>
  <div *ngIf="data.canBeSkipped">
    <mat-checkbox class="text-xs font-semibold text-gray-900" color="primary" [checked]="skipNextTime" name="skipNextTime">
      <span class="text-xs font-semibold text-gray-900">{{ _i18nService._('dialog_skip_next_time') }}</span>
    </mat-checkbox>
  </div>
  <div class="justify-between dialog-actions-container" mat-dialog-actions>
    <button
      *ngIf="data.buttonCancel"
      (click)="closeDialog(false)"
      class="mr-0 lg:mr-4 md:mr-4 sm:mr-4 mb-3 lg:mb-0 sm:mb-0 md:mb-0"
      color="primary"
      mat-stroked-button
    >
      {{ _i18nService._(data.buttonCancel) }}
    </button>
    <button
      (click)="closeDialog(true)"
      cdkFocusInitial
      class="mb-3 lg:mb-0 sm:mb-0 md:mb-0"
      color="primary"
      data-cy="Confirmer"
      mat-flat-button
    >
      {{ _i18nService._(data.buttonConfirm) }}
    </button>
  </div>
</div>
