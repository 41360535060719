<ng-template #templateRef>
  <!-- Action icons (separated) -->
  <ng-template [ngIfElse]="menuBlock" [ngIf]="!isMenu">
    <span
      (click)="readonly || isEditable ? genericActions.edit(element) : undefined"
      *ngIf="isEditable || readonly"
      [title]="editToolTip ? editToolTip : i18nService._('Txt_Tooltip_Edit')"
      class="icon-document txt-orange mx-2 text-2xl cursor-pointer"
    >
    </span>

    <span [ngClass]="{ 'disabled-icon': readonly }">
      <span
        (click)="!readonly ? genericActions.delete(element) : undefined"
        *ngIf="isDeletable"
        [title]="i18nService._('Txt_Tooltip_Delete')"
        class="icon-cancel txt-orange mx-2 text-sm cursor-pointer pt-2 inline-block"
        data-cy="delete"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.redirect(element) : undefined"
        *ngIf="isRedirected"
        [title]="redirectToolTip || i18nService._('Txt_Detail')"
        class="icon-fleche txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.accept(element) : undefined"
        *ngIf="isAccepted"
        [title]="i18nService._('Txt_Tooltip_Accept')"
        class="icon-validate txt-orange pt-2 mx-2 text-sm cursor-pointer"
        data-cy="accept"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.refuse(element) : undefined"
        *ngIf="isRefused"
        [title]="i18nService._('Txt_Tooltip_Refuse')"
        class="icon-cancel txt-orange pt-2 mx-2 text-sm cursor-pointer"
        data-cy="refuse"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.check(element) : undefined"
        *ngIf="isChecked"
        [title]="i18nService._('Txt_Tooltip_Check')"
        class="icon-verify txt-orange pt-2 mx-2 text-sm cursor-pointer"
        data-cy="check"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.createAccount(element) : undefined"
        *ngIf="isCreatable"
        [title]="i18nService._('Txt_Tooltip_Create_Account')"
        class="icon-plus font-bold txt-orange mx-2 text-sm cursor-pointer"
        data-cy="createAccount"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.cancel(element) : undefined"
        *ngIf="canCancel"
        [title]="i18nService._('Txt_Tooltip_Cancel')"
        class="icon-cancel txt-orange pt-2 mx-2 text-sm cursor-pointer"
        data-cy="cancel"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.downloadContractualBundle($event, element) : undefined"
        *ngIf="canDownloadContractualBundle"
        [title]="i18nService._('Txt_Tooltip_DownloadContractualBundle')"
        class="icon-download txt-orange pt-1 mx-2 text-2xl cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.showPreReservation(element) : undefined"
        *ngIf="canShowPreReservation"
        [title]="i18nService._('Txt_Tooltip_ShowPreReservation')"
        class="icon-eye txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.gotoReservation(element) : undefined"
        *ngIf="canGotoReservation"
        [title]="i18nService._('Txt_Tooltip_GotoReservation')"
        class="icon-fleche txt-orange pt-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.showReservation(element) : undefined"
        *ngIf="canShowReservation"
        [title]="i18nService._('Txt_Tooltip_ShowReservation')"
        class="icon-eye txt-orange pt-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.onlineSign(element) : undefined"
        *ngIf="canOnlineSign"
        [title]="i18nService._('Txt_Tooltip_OnlineSign')"
        class="icon-validate txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>
      <span
        (click)="!readonly ? genericActions.continueOnlineSign(element) : undefined"
        *ngIf="canContinueOnlineSign"
        [title]="i18nService._('Txt_Tooltip_ContinueOnlineSign')"
        class="icon-validate txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.onlineSignValo(element) : undefined"
        *ngIf="canOnlineSignValo"
        [title]="i18nService._('Txt_Tooltip_OnlineSign')"
        class="icon-validate txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>
      <span
        (click)="!readonly ? genericActions.continueOnlineSignValo(element) : undefined"
        *ngIf="canContinueOnlineSignValo"
        [title]="i18nService._('Txt_Tooltip_ContinueOnlineSign')"
        class="icon-validate txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.remove(element) : undefined"
        *ngIf="canRemove"
        [title]="i18nService._('Txt_Tooltip_Remove')"
        class="icon-cancel txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.extend(element) : undefined"
        *ngIf="canExtend"
        [title]="i18nService._('Txt_Tooltip_Extend')"
        class="icon-arrow-right txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.preReserve(element) : undefined"
        *ngIf="canPreReserve"
        [title]="i18nService._('Txt_Tooltip_PreReserve')"
        class="icon-validate txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.validate(element) : undefined"
        *ngIf="canValidateProgram"
        [title]="i18nService._('Txt_Tooltip_ValidateProgram')"
        class="icon-validate txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.edit(element) : undefined"
        *ngIf="canUpdateProgram"
        [title]="i18nService._('Txt_Tooltip_EditProgram')"
        class="icon-document txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.redirect(element) : undefined"
        *ngIf="canRedirectToStrategies"
        [title]="i18nService._('Txt_Tooltip_RedirectToStrategies')"
        class="icon-menu txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.publish(element) : undefined"
        *ngIf="canPublishProgram"
        [title]="i18nService._('Txt_Tooltip_Publish')"
        class="icon-validate txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.unpublish(element) : undefined"
        *ngIf="canUnpublishProgram"
        [title]="i18nService._('Txt_Tooltip_Unpublish')"
        class="icon-cancel txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.cancelSchedule(element) : undefined"
        *ngIf="canCancelSchedulingProgram"
        [title]="i18nService._('Txt_Tooltip_Unschedule')"
        class="icon-cancel txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.accept(element) : undefined"
        *ngIf="canAccept"
        [title]="i18nService._('Txt_Tooltip_AcceptOption')"
        class="icon-validate txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.reject(element) : undefined"
        *ngIf="canReject"
        [title]="i18nService._('Txt_Tooltip_RejectOption')"
        class="icon-cancel txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.showMotive(element) : undefined"
        *ngIf="canShowMotive"
        [title]="i18nService._('Txt_Tooltip_Motive')"
        class="icon-eye txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.canUpdateAccount(element) : undefined"
        *ngIf="canUpdateAccount"
        [title]="i18nService._('Txt_Tooltip_UpdateAccount')"
        class="icon-document txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.acceptPaternity(element) : undefined"
        *ngIf="canAcceptPaternity"
        [title]="i18nService._('Txt_Tooltip_Accept')"
        class="icon-document txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.refusePaternity(element) : undefined"
        *ngIf="canRefusePaternity"
        [title]="i18nService._('Txt_Tooltip_Refuse')"
        class="icon-document txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <span
        (click)="!readonly ? genericActions.checkPaternity(element) : undefined"
        *ngIf="canCheckPaternity"
        [title]="i18nService._('Txt_Tooltip_Check')"
        class="icon-document txt-orange pt-2 mx-2 text-sm cursor-pointer"
      >
      </span>

      <a (click)="!readonly ? genericActions.showLotDetails(element) : undefined" *ngIf="canShowLotDetails" class="text-xs text-black">
        {{ element.lotNumber }}
      </a>

      <span *ngIf="!readonly ? genericActions.link : undefined" (click)="!readonly ? genericActions.link(element) : undefined">
        <a
          (click)="!readonly ? genericActions.link(element) : undefined"
          *ngIf="!readonly ? !!genericActions.linkRef(element) : undefined"
          class="text-xs text-black"
        >
          {{ genericActions.linkRef(element) }}
        </a>
        <span *ngIf="!readonly ? !genericActions.linkRef(element) : undefined">-</span>
      </span>
    </span>
  </ng-template>

  <!-- Action icons (grouped in a Menu with hamburger icon) -->
  <ng-template #menuBlock>
    <div class="menu-context-extra {{ menuDisable ? ' disabled' : '' }}">
      <span class="mat-button-wrapper">
        <button
          data-cy="cliquer"
          mat-menu-item
          [disabled]="menuDisable"
          [matMenuTriggerFor]="menu"
          class="button--no-style p-0 text-2xl mt-1"
        >
          <span
            class="material-icons-outlined txt-orange text-xl leading-none border-orange-500 border padding-btn-more-action rounded-full"
          >
            more_horiz
          </span>
        </button>

        <mat-menu #menu="matMenu">
          <button data-cy="edit" (click)="genericActions.edit(element)" *ngIf="isEditable" mat-menu-item>
            <span *ngIf="editToolTip">{{ editToolTip }}</span>
            <span *ngIf="!editToolTip">{{ i18nService._('Txt_Tooltip_Edit') }}</span>
          </button>

          <button data-cy="delete" (click)="genericActions.delete(element)" *ngIf="isDeletable" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_Delete') }}</span>
          </button>

          <button (click)="genericActions.redirect(element)" *ngIf="isRedirected" mat-menu-item>
            <span>{{ redirectToolTip || i18nService._('Txt_Detail') }}</span>
          </button>

          <button data-cy="accept" (click)="genericActions.accept(element)" *ngIf="isAccepted" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_Accept') }}</span>
          </button>

          <button data-cy="acceptCompany" (click)="genericActions.accept(element)" *ngIf="isCompanyValidated" mat-menu-item>
            <span>{{ i18nService._('Txt_Button_AcceptCompany') }}</span>
          </button>

          <button data-cy="publishingDialog" (click)="genericActions.desktopPublish(element)" *ngIf="desktopPublishingEnable" mat-menu-item>
            <span>{{ i18nService._('Title_Desktop_Publishing_Dialog') }}</span>
          </button>

          <button data-cy="showStrategies" (click)="genericActions.showStrategies(element)" *ngIf="hasStrategies" mat-menu-item>
            <span>{{ i18nService._('Title_Button_ShowStrategies') }}</span>
          </button>

          <button data-cy="refuse" (click)="genericActions.refuse(element)" *ngIf="isRefused" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_Refuse') }}</span>
          </button>

          <button data-cy="check" (click)="genericActions.check(element)" *ngIf="isChecked" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_Check') }}</span>
          </button>

          <button data-cy="createAccount" (click)="genericActions.createAccount(element)" *ngIf="isCreatable" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_Create_Account') }}</span>
          </button>

          <button data-cy="cancel" (click)="genericActions.cancel(element)" *ngIf="canCancel" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_Cancel') }}</span>
          </button>

          <div class="relative" *ngIf="canDownloadContractualBundle">
            <div class="spinner-container" *ngIf="element.downloading">
              <mat-progress-spinner color="primary" diameter="30" mode="indeterminate" value="50"></mat-progress-spinner>
            </div>
            <button (click)="genericActions.downloadContractualBundle($event, element)" [disabled]="element.downloading" mat-menu-item>
              <span>{{ i18nService._('Txt_Tooltip_DownloadContractualBundle') }}</span>
            </button>
          </div>

          <button
            (click)="genericActions.showPreReservation(element)"
            *ngIf="canShowPreReservation"
            mat-menu-item
            data-cy="showPreReservation"
          >
            <span>{{ i18nService._('Txt_Tooltip_ShowPreReservation') }}</span>
          </button>

          <button (click)="genericActions.gotoReservation(element)" *ngIf="canGotoReservation" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_GotoReservation') }}</span>
          </button>

          <button (click)="genericActions.showReservation(element)" *ngIf="canShowReservation" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_ShowReservation') }}</span>
          </button>

          <button (click)="genericActions.onlineSign(element)" *ngIf="canOnlineSign" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_OnlineSign') }}</span>
          </button>
          <button (click)="genericActions.continueOnlineSign(element)" *ngIf="canContinueOnlineSign" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_ContinueOnlineSign') }}</span>
          </button>

          <button (click)="genericActions.onlineSignValo(element)" *ngIf="canOnlineSignValo" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_OnlineSign') }}</span>
          </button>
          <button (click)="genericActions.continueOnlineSignValo(element)" *ngIf="canContinueOnlineSignValo" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_ContinueOnlineSign') }}</span>
          </button>

          <button data-cy="remove" (click)="genericActions.remove(element)" *ngIf="canRemove" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_Remove') }}</span>
          </button>

          <button data-cy="extend" (click)="genericActions.extend(element)" *ngIf="canExtend" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_Extend') }}</span>
          </button>

          <button data-cy="preReserve" (click)="genericActions.preReserve(element)" *ngIf="canPreReserve" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_PreReserve') }}</span>
          </button>

          <button data-cy="validateProgram" (click)="genericActions.validate(element)" *ngIf="canValidateProgram" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_ValidateProgram') }}</span>
          </button>

          <button data-cy="editProgram" (click)="genericActions.edit(element)" *ngIf="canUpdateProgram" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_EditProgram') }}</span>
          </button>

          <button data-cy="strategies" (click)="genericActions.redirect(element)" *ngIf="canRedirectToStrategies" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_RedirectToStrategies') }}</span>
          </button>
          <button (click)="genericActions.cancelSchedule(element)" *ngIf="canCancelSchedulingProgram" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_Unschedule') }}</span>
          </button>

          <button data-cy="publishProgram" (click)="genericActions.publish(element)" *ngIf="canPublishProgram" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_Publish') }}</span>
          </button>

          <button data-cy="unpublishProgram" (click)="genericActions.unpublish(element)" *ngIf="canUnpublishProgram" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_Unpublish') }}</span>
          </button>

          <button data-cy="acceptOption" (click)="genericActions.accept(element)" *ngIf="canAccept" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_AcceptOption') }}</span>
          </button>

          <button data-cy="rejectOption" (click)="genericActions.reject(element)" *ngIf="canReject" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_RejectOption') }}</span>
          </button>

          <button (click)="genericActions.showMotive(element)" *ngIf="canShowMotive" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_Motive') }}</span>
          </button>

          <button data-cy="updateAccount" (click)="genericActions.canUpdateAccount(element)" *ngIf="canUpdateAccount" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_UpdateAccount') }}</span>
          </button>

          <button data-cy="validateAccount" (click)="genericActions.canValidateAccount(element)" *ngIf="canValidateAccount" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_ValidateAccount') }}</span>
          </button>

          <button data-cy="accept" (click)="genericActions.acceptPaternity(element)" *ngIf="canAcceptPaternity" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_Accept') }}</span>
          </button>

          <button data-cy="refuse" (click)="genericActions.refusePaternity(element)" *ngIf="canRefusePaternity" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_Refuse') }}</span>
          </button>

          <button data-cy="check" (click)="genericActions.checkPaternity(element)" *ngIf="canCheckPaternity" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_Check') }}</span>
          </button>

          <button data-cy="edit" (click)="genericActions.editSearch(element)" *ngIf="canEditSearch" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_EditSearch') }}</span>
          </button>

          <button data-cy="delete" (click)="genericActions.deleteSearch(element)" *ngIf="canDeleteSearch" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_DeleteSearch') }}</span>
          </button>

          <button data-cy="modify" (click)="genericActions.modify(element)" *ngIf="canUpdate" mat-menu-item>
            <span>{{ i18nService._('Txt_Button_Modify') }}</span>
          </button>

          <button data-cy="visualize" (click)="genericActions.visualize(element)" *ngIf="canVisualize" mat-menu-item>
            <span>{{ i18nService._('Txt_Button_Visualize') }}</span>
          </button>

          <button data-cy="exclude" (click)="genericActions.exclude(element)" *ngIf="canExclude" mat-menu-item>
            <span>{{ i18nService._('Txt_Button_Exclude_List') }}</span>
          </button>

          <button data-cy="showResume" (click)="genericActions.showLotDetails(element)" *ngIf="canShowLotDetails" mat-menu-item>
            <span>{{ i18nService._('Txt_Tooltip_ShowOfferResume') }}</span>
          </button>
          <div class="relative" *ngIf="canDownloadReservationContract">
            <div class="spinner-container" *ngIf="element.downloading">
              <mat-progress-spinner color="primary" diameter="30" mode="indeterminate" value="50"> </mat-progress-spinner>
            </div>
            <button (click)="genericActions.downloadReservationDocuments($event, element)" [disabled]="element.downloading" mat-menu-item>
              <span>{{ i18nService._('Txt_Tooltip_DownloadReservationContrat') }}</span>
            </button>
          </div>
        </mat-menu>
      </span>
    </div>
  </ng-template>
</ng-template>
