import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

import { FormLinkComponent } from '../../form/link/link.component';

@Component({
  selector: 'app-formly-link',
  templateUrl: './formly-link.component.html',
  styleUrls: ['./formly-link.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormLinkComponent],
})
export class FormlyLinkComponent extends FieldType {}
