import { Directive, ElementRef, Input } from '@angular/core';

import { AnchorEnum } from '../models/enum/anchor.enum';

@Directive({
  selector: '[appAnchor]',
  standalone: true,
})
export class AnchorDirective {
  @Input() anchorName: AnchorEnum;

  constructor(private readonly _elementRef: ElementRef) {}

  scrollToElement() {
    this._elementRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
