import { Component, OnInit } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { I18nService } from 'apps/valo-front/src/app/utils/services/i18n.service';

import { AbstractProgramPageLot } from '../abstract-program-page-lot';

import { AppItem } from '../../../../../design-system/model/app-item';
import { LotDetailResponse } from '../../../../../utils/models/LotDetailResponse';
import { LotFormatDetailResponse } from '../../../../../utils/models/LotFormatDetailResponse';
import { SingleAccordionPanelComponent } from '../../../../../design-system/component/single-accordion-panel/single-accordion-panel.component';
import { ItemComponent } from '../../../../../design-system/component/item/item.component';

@Component({
  selector: 'app-program-page-lot-rent-and-profitability',
  templateUrl: './program-page-lot-rent-and-profitability.component.html',
  styleUrls: ['./program-page-lot-rent-and-profitability.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, ItemComponent, SingleAccordionPanelComponent],
})
export class ProgramPageLotRentAndProfitabilityComponent extends AbstractProgramPageLot implements OnInit {
  public appItems: AppItem[];
  public displayLegalMention: boolean;

  constructor(public readonly i18nService: I18nService) {
    super();
  }

  initData(lot?: LotDetailResponse, formattedLot?: LotFormatDetailResponse) {
    if (formattedLot) {
      if (!this._pageLotService.hasNueProprieteTaxation(formattedLot.taxations)) {
        this.appItems = this._pageLotService.getRentAndProfitablityItems(formattedLot);
        if (this.appItems.length) {
          this.displayLegalMention = true;
        }
      }
    }
  }
}
