import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { takeUntil } from 'rxjs';
import { Directionality } from '@angular/cdk/bidi';
import { ActivatedRoute, Router } from '@angular/router';
import { SignatureService } from 'apps/valo-front/src/app/signature/services/signature.service';
import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';

import { MyStepComponent } from './my-step/my-step.component';
import { LotDetailStepContextComponent } from './step-context-data/lot-detail-step-context/lot-detail-step-context.component';
import { PriceStepContextDataComponent } from './step-context-data/price-step-context-data/price-step-context-data.component';
import { StepContextDirective } from './step-context-data/step-context.directive';
import { StepContextComponent } from './step-context-data/step-context-component';
import { StepHeadTitleComponent } from './step-head-title/step-head-title.component';

import { RoutingStateService } from './../../../../utils/services/routing-state.service';
import { Sitemap } from '../../../../utils/models/Sitemap';
import { BoxBackButtonComponent } from '../../../shared/box-back-button/box-back-button.component';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
  standalone: true,
  imports: [BoxBackButtonComponent, NgClass, NgFor, NgIf, NgTemplateOutlet, StepContextDirective, StepHeadTitleComponent],
})
export class StepperComponent extends CdkStepper implements OnInit {
  @Input()
  stepperParam: any;
  @Input()
  activeClass = 'active';
  @Input() fields: FormlyFieldConfig[];
  @Input() model: any;
  @Input() currentStep: FormlyFieldConfig;
  /** Full list of steps inside the stepper, including inside nested steppers. */
  @ContentChildren(MyStepComponent, { descendants: true }) declare _steps: QueryList<MyStepComponent>;

  /** Steps that belong to the current stepper, excluding ones from nested steppers. */
  override readonly steps: QueryList<MyStepComponent> = new QueryList<MyStepComponent>();

  @ViewChild(StepContextDirective) contextDataContainer: StepContextDirective;

  public stepTitle: string;
  public showReload: boolean;
  public canReset: boolean | undefined = undefined;
  public showDuplicate: boolean;
  public showCustomAction: boolean;
  public customActionTitle: string;

  private signatureId: string;
  private stepContext = {
    lotDetailContext: LotDetailStepContextComponent,
    lotPriceContext: PriceStepContextDataComponent,
  };

  private stepContextTmp;

  screenSize: 'large' | 'small' | 'medium';

  constructor(
    _dir: Directionality,
    public readonly changeDetectorRef: ChangeDetectorRef,
    _elementRef: ElementRef<HTMLElement>,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly signatureService: SignatureService,
    private readonly routingStateService: RoutingStateService,
  ) {
    super(_dir, changeDetectorRef, _elementRef);
  }

  get selectedStep() {
    return this.selected as MyStepComponent;
  }

  ngOnInit() {
    this.signatureId = this.route.snapshot.params.signatureId;

    this.selectionChange.pipe(takeUntil(this._destroyed)).subscribe(() => {
      setTimeout(async () => {
        const step = this.steps['_results'][this.selectedIndex];
        if (this.stepperParam.stepTitle) {
          this.stepTitle = this.stepperParam.stepTitle;
        }
        if (step.field.props['formTitle']) {
          this.stepTitle = this.stepTitle || step.field.props['formTitle'];
        }
        this.showDuplicate = step.field.props['showDuplicate'];
        const field = this.fields.find((f) => f.props['id'] === step.field.props['headId']);
        this.stepTitle = this.stepTitle || field.props['formTitle'] || field.props.label;

        const dossierId = this.route.snapshot.params.signatureId;
        if (this.canReset === undefined && dossierId) {
          this.canReset = await this.signatureService.canResetDossier(dossierId);
        }

        this.showReload = field.props['showReload'] && this.canReset;

        this.showCustomAction = this.stepperParam.showCustomAction;
        this.customActionTitle = this.stepperParam.customActionTitle;

        this.stepContextTmp = step.field.props['stepContext'];
        this.setContextComponent(this.stepContextTmp);
        this.changeDetectorRef.detectChanges();
        if (!this.stepperParam.deactivateScrollTop) {
          this.scrollTop();
        }
      });
    });

    this.setScreenSize(window.screen.width);
  }

  private scrollTop() {
    const element = document.querySelector('app-step-head-title');
    element.scrollIntoView();
  }

  toggleSubStep(index: number) {
    this.fields[index].props['open'] = !this.fields[index].props['open'];
    for (let i = index + 1; i < this.fields.length; i++) {
      const field = this.fields[i];
      if (!field.props['isHead']) {
        field.props['expand'] = !field.props['expand'];
      } else {
        break;
      }
    }
  }

  goBack(): () => void {
    return () => {
      if (this.stepperParam?.goBackRoute === 'PreviousUrl') {
        this.router.navigateByUrl(this.routingStateService.getPreviousUrl());
      } else {
        this.router.navigateByUrl(this.getRoute());
      }
    };
  }

  /**
   * Redirecting to tracking table according to created user
   */
  getRoute(): string {
    return this.stepperParam?.goBackRoute || Sitemap.signature.dashboard.path.replace(/:signatureId/, this.signatureId);
  }

  private setContextComponent(stepContext: string) {
    if (!this.contextDataContainer) return;
    const viewContainerRef = this.contextDataContainer.viewContainerRef;
    viewContainerRef.clear();
    if (stepContext) {
      const componentRef = viewContainerRef.createComponent<StepContextComponent>(this.stepContext[stepContext]);
      componentRef.instance.setClass(this.contextDataContainer.myClass);
    }
  }

  setScreenSize(size: number) {
    if (size <= 836) {
      this.screenSize = 'small';
    } else if (size < 1280) {
      this.screenSize = 'medium';
    } else if (size >= 1280) {
      this.screenSize = 'large';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setScreenSize(event.currentTarget.innerWidth);
    this.setContextComponent(this.stepContextTmp);
  }
}
