import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { I18nService } from '../../../utils/services/i18n.service';
import { SignatureService } from './../../services/signature.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  standalone: true,
  imports: [FormsModule],
})
export class CommentComponent {
  @Input() comment: string;

  constructor(
    public readonly i18nService: I18nService,
    private readonly signatureService: SignatureService,
    private readonly route: ActivatedRoute,
  ) {}

  updateComment(comment: string) {
    const dossierId = this.route.snapshot.params.signatureId;
    const dossier = { id: dossierId, internalComments: comment };
    this.signatureService.updateDossierById(dossierId, dossier).subscribe();
  }
}
