import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';

import { InitFormCompanyComponent } from './components/init-form-company/init-form-company.component';
import { RegisterFormCompanyComponent } from './components/register-form-company/register-form-company.component';
import { Sitemap } from '../utils/models/Sitemap';
import { AuthGuard } from '../security/authguard';

const routes: Routes = [
  {
    path: Sitemap.companies.register.path,
    component: InitFormCompanyComponent,
  },
  {
    path: Sitemap.companies.registerDeveloper.path,
    component: RegisterFormCompanyComponent,
    data: {
      isDeveloper: true,
    },
  },
  {
    path: Sitemap.companies.registerContractor.path,
    component: RegisterFormCompanyComponent,
    data: {
      isDeveloper: false,
    },
  },
  {
    path: Sitemap.companies.registerById.path,
    component: RegisterFormCompanyComponent,
    data: {
      checkToken: true,
    },
  },
  {
    path: Sitemap.companies.validate.path,
    data: {
      roles: Sitemap.companies.validate.data.roles,
    },
    component: RegisterFormCompanyComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.companies.edit.path,
    data: {
      roles: Sitemap.companies.edit.data.roles,
    },
    component: RegisterFormCompanyComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
  declarations: [],
})
export class CompaniesRoutingModule {}
