import { BreakpointEnum } from './enums/breakpoint.enum';

export type BreakPoint = {
  [key in BreakpointEnum]?: {
    up: string;
    down: string;
  };
};

export const breakpoints: BreakPoint = {
  [BreakpointEnum.xs]: {
    down: `(max-width: ${BreakpointEnum.xs}px)`,
    up: `(min-width: ${BreakpointEnum.xs}px)`,
  },
  [BreakpointEnum.sm]: {
    down: `(max-width: ${BreakpointEnum.sm - 0.1}px)`,
    up: `(min-width: ${BreakpointEnum.sm}px)`,
  },
  [BreakpointEnum.md]: {
    down: `(max-width: ${BreakpointEnum.md - 0.1}px)`,
    up: `(min-width: ${BreakpointEnum.md}px)`,
  },
  [BreakpointEnum.lg]: {
    down: `(max-width: ${BreakpointEnum.lg - 0.1}px)`,
    up: `(min-width: ${BreakpointEnum.lg}px)`,
  },
  [BreakpointEnum.xl]: {
    down: `(max-width: ${BreakpointEnum.xl - 0.1}px)`,
    up: `(min-width: ${BreakpointEnum.xl}px)`,
  },
};
