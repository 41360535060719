import { UtilsModule } from 'apps/valo-front/src/app/utils/utils.module';
import { Component, Input, OnInit } from '@angular/core';
import { CompleteDossierResponse, SummarySimulationResponse } from '@commons-dto/dossier-prospect';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';

import { ButtonComponent } from './../../../../design-system/component/button/button.component';
import { DashboardCardComponent } from '../../../../design-system/component/dashboard-card/dashboard-card.component';
import { PipesModule } from '../../../../utils/pipes/pipes.module';
import { DossierProspectRouteService } from '../../dossier-prospect/dossier-prospect.route.service';
import { ButtonType } from '../../../../design-system/component/button/button.type.enum';

@Component({
  selector: 'app-dossier-prospect-dashboard-simulation',
  standalone: true,
  templateUrl: './dossier-prospect-dashboard-simulation.component.html',
  styleUrls: ['./dossier-prospect-dashboard-simulation.component.scss'],
  imports: [CommonModule, DashboardCardComponent, PipesModule, UtilsModule, ButtonComponent],
})
export class DossierProspectDashboardSimulationComponent implements OnInit {
  complete = false;

  @Input() simulationUpdated: Subject<void>;

  private _dossier: CompleteDossierResponse;

  ButtonType = ButtonType;

  @Input()
  get dossier(): CompleteDossierResponse {
    return this._dossier;
  }

  set dossier(value: CompleteDossierResponse) {
    this._dossier = value;
    if (value != null) {
      this.init();
    }
  }

  simulation: SummarySimulationResponse;

  constructor(private readonly dossierProspectRouteService: DossierProspectRouteService) {}

  ngOnInit() {
    if (!this.simulationUpdated) return;
    this.simulationUpdated.subscribe(() => {
      this.init();
    });
  }

  init(): void {
    this.simulation = this.dossier?.summarySimulations?.length > 0 ? this.dossier.summarySimulations[0] : null;
    this.complete = this.simulation != null && this.simulation.purchasingCapacity != null;
  }

  goToSimulation() {
    this.dossierProspectRouteService.goToSimulation(this.dossier.id);
  }
}
