/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

import { I18nRendererComponent } from './../../../renderers/i18n-renderer/i18n-renderer.component';
import { AccountStatus } from '../../../../utils/models/enums/account-status.enum';
import { StatusTagColor } from './../../../../design-system/model/status-tag-color.enum';
import { CompanyType } from './../../../../utils/models/enums/CompanyType.enum';
import { ValoRoleTypeApiService } from '../../../../adapters/valo-role-type-api.service';
import { ProfilApiService } from '../../../../adapters/profil-api.service';
import { Item } from '../../../models/Item';
import { AcccountTrackingTableService } from '../../../services/account-tracking-table.service';
import { UserRoleService } from '../../../../utils/services/user-role.service';
import { I18nService } from '../../../../utils/services/i18n.service';
import { FilterEventOutput } from '../../../models/filter/filter-event-output';
import { AbstractTrackingTable } from '../../../abstract-tracking-table';
import { BooleanRendererComponent } from '../../../renderers/boolean-renderer/boolean-renderer.component';
import { AppConfigService } from '../../../../utils/services/app-config.service';
import { ActionRendererComponent } from '../../../renderers/action-renderer/action-renderer.component';
import { AccountTrackingTableItem } from '../../../models/AccountTrackingTableItem';
import { Sitemap } from '../../../../utils/models/Sitemap';
import { StatusTagsRendererComponent } from './../../../renderers/status-tags-renderer/status-tags-renderer.component';
import { GridTrackingTableComponent } from '../../grid-tacking-table/grid-tracking-table.component';

const tagTypeByStatus: { [key in AccountStatus]: StatusTagColor } = {
  PENDING: StatusTagColor.IN_PROGRESS,
  VALIDATED: StatusTagColor.SUCCESS,
  REFUSED: StatusTagColor.CRITICAL,
  UNAUTHORIZED: StatusTagColor.CRITICAL,
  DEACTIVATED: StatusTagColor.CRITICAL,
  BLOCKED: StatusTagColor.CRITICAL,
};

@Component({
  selector: 'app-accounts-tracking-table-tab',
  templateUrl: './accounts-tracking-table-tab.component.html',
  styleUrls: ['./accounts-tracking-table-tab.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, MatSelectModule, NgFor, MatOptionModule, NgIf, GridTrackingTableComponent],
})
export class AccountTrackingTableTabComponent extends AbstractTrackingTable implements OnInit {
  @Input() type: CompanyType;

  @ViewChild(GridTrackingTableComponent)
  gridTrackingTable: GridTrackingTableComponent;

  pageSize = 10;
  pageNumber = 1;
  sortDirection: 'ASC' | 'DESC' = 'DESC';
  sortColumn = 'id';
  columnDefs: ColDef[];
  filterValue: string;
  i18nFilter: any = {};
  profilFilterList: Item[];
  activeFilterList: any[];
  typeFilterList: any[];
  statusFilterList: any[] = [
    { value: 1, label: 'PENDING' },
    { value: 2, label: 'VALIDATED' },
    { value: 3, label: 'BLOCKED' },
    { value: 4, label: 'DEACTIVATED' },
    { value: 5, label: 'UNAUTHORIZED' },
    { value: 6, label: 'REFUSED' },
  ];
  columnsToDisplayFilterList: any[];
  filterUrlParam: FilterEventOutput = {};
  columns: any[];
  selectedColumns: any[];
  textFilterSubject = new Subject<string>();
  textFilterValue: string;

  constructor(
    public readonly i18nService: I18nService,
    private readonly appConfigService: AppConfigService,
    private readonly userRoleService: UserRoleService,
    public readonly acccountTrackingTableService: AcccountTrackingTableService,
    private readonly _route: ActivatedRoute,
    private readonly router: Router,
    private readonly profilApiService: ProfilApiService,
    private readonly valoRoleTypeApiService: ValoRoleTypeApiService,
  ) {
    super(_route.snapshot.routeConfig.path, router);
    this.setFilterValue();
  }

  ngOnInit(): void {
    this.acccountTrackingTableService.type = this.type;
    const marketerRoles = [
      this.appConfigService.appConfig.roles.valoMarketerSuper,
      this.appConfigService.appConfig.roles.valoMarketerSimple,
    ];
    const adminRoles = [
      this.appConfigService.appConfig.roles.valoSystem,
      this.appConfigService.appConfig.roles.valoAdminSuper,
      this.appConfigService.appConfig.roles.valoAdminSimple,
      this.appConfigService.appConfig.roles.valoBoSimple,
      this.appConfigService.appConfig.roles.valoBoSuper,
    ];
    this.columnDefs = [
      {
        field: 'name',
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_Name'), // TODO: Vérifier si traduc differente entre Valorissimo et les autres onglets
        minWidth: 175,
        flex: 1,
      },
      {
        field: 'phone',
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_Phone'),
        minWidth: 90,
        maxWidth: 90,
        flex: 0,
        hide: this.type === 'VALORISSIMO',
      },
      {
        field: 'email',
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_Email'),
        minWidth: 120,
        flex: 1,
        hide: this.type === 'VALORISSIMO',
      },
      {
        field: 'city',
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_CityPostalCode'),
        minWidth: 130,
        flex: 1,
        hide: this.type === 'VALORISSIMO',
      },
      {
        field: 'manager',
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_Manager'),
        minWidth: 175,
        flex: 1,
        hide: this.type === 'VALORISSIMO',
      },
      {
        field: 'professionnalFunction',
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_FunctionType'),
        minWidth: 80,
        flex: 0.5,
        hide: this.type !== 'VALORISSIMO',
      },
      {
        field: 'associateManager',
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_AssociateManager'),
        minWidth: 175,
        flex: 1,
        hide: this.type !== 'VALORISSIMO' || !this.userRoleService.isInRoles(adminRoles),
      },
      {
        field: 'profil',
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_Profil'),
        cellRenderer: I18nRendererComponent,
        minWidth: 70,
        maxWidth: 70,
        flex: 0,
        hide: this.type === 'VALORISSIMO' && !this.userRoleService.isInRoles(adminRoles),
      },
      {
        field: 'responsible',
        headerName:
          this.type === 'DEVELOPER'
            ? this.i18nService._('Txt_AccountsTrackingTable_Column_ValoResponsible')
            : this.i18nService._('Txt_AccountsTrackingTable_Column_BiResponsible'),
        minWidth: 175,
        flex: 1,
        hide: this.type === 'VALORISSIMO' || this.type === 'INDEPENDANT',
      },
      {
        field: 'otherResponsible',
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_Responsible'),
        minWidth: 175,
        flex: 1,
        hide: this.type === 'VALORISSIMO' || this.type === 'DEVELOPER',
      },
      {
        field: 'valoRoleType',
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_Type'),
        cellRenderer: I18nRendererComponent,
        minWidth: 95,
        maxWidth: 95,
        flex: 0,
        hide: this.type !== 'VALORISSIMO' || !this.userRoleService.isInRoles(adminRoles),
      },
      {
        field: 'nbOptionsMax',
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_NumberOptions'),
        minWidth: 75,
        maxWidth: 75,
        flex: 0,
        hide: this.type !== 'DEVELOPER',
      },
      {
        field: 'isActive',
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_Active'),
        cellRenderer: BooleanRendererComponent,
        minWidth: 50,
        maxWidth: 50,
        flex: 0,
      },
      {
        field: 'departments',
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_Department'),
        minWidth: 50,
        flex: 0.5,
        hide: this.type !== 'VALORISSIMO' || this.userRoleService.isInRoles(marketerRoles),
        valueFormatter: (params) => {
          return params.value ?? '-';
        },
      },
      {
        field: 'bos',
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_Bo'),
        minWidth: 80,
        flex: 1,
        hide: this.type !== 'VALORISSIMO' || this.userRoleService.isInRoles(marketerRoles),
        valueFormatter: (params) => {
          return params.value ?? '-';
        },
      },
      {
        field: 'status',
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_Status'),
        minWidth: 80,
        maxWidth: 80,
        flex: 0,
        cellRenderer: StatusTagsRendererComponent,
        cellRendererParams: {
          tagsList: tagTypeByStatus,
          outline: true,
        },
        hide: this.type === 'VALORISSIMO',
      },
      {
        field: 'corporateName',
        headerName: this.i18nService._(
          this.type === 'INDEPENDANT' ? 'Txt_AccountsTrackingTable_Column_Network' : 'Txt_AccountsTrackingTable_Column_CorporateName',
        ),
        minWidth: 80,
        flex: 1,
        hide: this.type === 'VALORISSIMO' || (!this.userRoleService.isValo() && !this.userRoleService.isIndependant()),
      },
      {
        field: 'tradingName',
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_TradingName'),
        minWidth: 80,
        flex: 1,
        hide: this.type === 'VALORISSIMO' || !this.userRoleService.isValo(),
      },
      {
        field: 'siret',
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_Siret'),
        minWidth: 100,
        maxWidth: 100,
        flex: 0,
        hide: this.type === 'VALORISSIMO' || !this.userRoleService.isValo(),
      },
      {
        field: 'actions',
        headerName: this.i18nService._('Txt_AccountsTrackingTable_Column_Actions'),
        cellRenderer: ActionRendererComponent,
        cellRendererParams: {
          actionItems: (item: any) => {
            return this.getActionItems(item);
          },
        },
        cellStyle: { paddingRight: '10px', 'justify-content': 'flex-end' },
        flex: 0,
        minWidth: 60,
        maxWidth: 60,
        hide: !this.userRoleService.isValo(),
      },
    ];
    this.columns = this.columnDefs
      .filter((column) => !column.hide)
      .map((column) => {
        return {
          label: column.headerName,
          value: column.field,
        };
      });
    const selectedColumns = this.columns.map((column) => column.value);
    const result = selectedColumns.reduce((a, v) => ({ ...a, [v]: v }), {});
    switch (this.type) {
      case CompanyType.DEVELOPER:
        delete result['city'];
        delete result['nbOptionsMax'];
        delete result['tradingName'];
        delete result['siret'];
        break;
      case CompanyType.CONTRACTOR:
        delete result['city'];
        delete result['tradingName'];
        delete result['siret'];
        break;
      case CompanyType.VALORISSIMO:
        break;
      case CompanyType.INDEPENDANT:
        delete result['city'];
        delete result['tradingName'];
        delete result['siret'];
        break;
      default:
        console.log('Unknown type: ' + this.type);
        break;
    }
    this.selectedColumns = Object.keys(result);

    this.profilApiService.getProfils().subscribe((profils) => {
      this.profilFilterList = profils;
    });

    this.valoRoleTypeApiService.getValoRoleTypes().subscribe((valoRoleTypes) => {
      this.typeFilterList = valoRoleTypes;
    });

    this.activeFilterList = [
      { value: true, label: this.i18nService._('Txt_Yes') },
      { value: false, label: this.i18nService._('Txt_No') },
    ];

    this.onTextFilterChange();
  }

  getActionItems(item: any) {
    const actions = [];
    if (item?.actions?.canUpdateAccount) {
      actions.push({
        label: 'Txt_Tooltip_UpdateAccount',
        action: () => {
          this.router.navigate([Sitemap.accounts.updateById.path.replace(/:id/, item.id.toString())]);
        },
      });
    }
    if (item?.actions?.canValidateAccount) {
      actions.push({
        label: 'Txt_Tooltip_ValidateAccount',
        action: () => {
          this.router.navigate([Sitemap.accounts.validateById.path.replace(/:id/, item.id.toString())]);
        },
      });
    }
    if (item?.actions?.isCreatable) {
      actions.push({
        label: 'Txt_Tooltip_Create_Account',
        action: () => {
          this.createAccount(item);
        },
      });
    }
    return actions;
  }

  setFilterValue() {
    const queryParamMap = this._route.snapshot.queryParamMap;

    this.filterValue = queryParamMap.get('filter');
    this.textFilterValue = queryParamMap.get('filter');

    const profils = queryParamMap.get('Profils')?.split(',').map(Number);
    if (profils) {
      this.i18nFilter.Profils = profils;
    }

    const valoRoleTypes = queryParamMap.get('ValoRoleTypes')?.split(',').map(Number);
    if (valoRoleTypes) {
      this.i18nFilter.ValoRoleTypes = valoRoleTypes;
    }

    const isActive = queryParamMap
      .get('isActive')
      ?.split(',')
      .map((v) => Boolean(JSON.parse(v)));
    if (isActive) {
      this.i18nFilter.isActive = isActive;
    }

    const accountStatuses = queryParamMap.get('AccountStatuses')?.split(',').map(Number);
    if (accountStatuses) {
      this.i18nFilter.AccountStatuses = accountStatuses;
    }

    this.filterUrlParam = {
      i18nFilter: this.i18nFilter,
      filter: this.filterValue,
    };
    super.filterEvent(this.filterUrlParam);
  }

  get selectedColumnsSummary(): string {
    const nbSelectedColumns = this.selectedColumns.length;

    if (nbSelectedColumns) {
      const i18nTitle = this.columns.find((column) => column.value === this.selectedColumns[0]).label;
      switch (nbSelectedColumns) {
        case this.columns.length:
          return this.i18nService._('Txt_Select_Form_Table_All');
        case 1:
          return `${this.i18nService._(i18nTitle)}`;
        default:
          return `${this.i18nService._(i18nTitle)} ${this.i18nService._('Txt_Select_Form_Table_And_Other', [nbSelectedColumns - 1])}`;
      }
    }

    return '';
  }

  createAccount(element?: AccountTrackingTableItem): void {
    this.router.navigate([
      element ? Sitemap.accounts.registerById.path.replace(/:id/, element.id.toString()) : Sitemap.accounts.register.path,
    ]);
  }

  onTextFilterChange() {
    this.textFilterSubject.pipe(debounceTime(this.textFilterDebounceTimer), distinctUntilChanged()).subscribe((value) => {
      this.filterUrlParam.filter = value;
      super.filterEvent(this.filterUrlParam);
      this.textFilterValue = value;
    });
  }

  onProfilListChange(profilList: any) {
    if (profilList?.value.length) {
      this.i18nFilter.Profils = profilList.value;
    } else {
      delete this.i18nFilter.Profils;
    }
    this.i18nFilter = { ...this.i18nFilter };
    this.filterUrlParam.i18nFilter = this.i18nFilter;
    super.filterEvent(this.filterUrlParam);
  }

  onTypeListChange(typeList: any) {
    if (typeList?.value.length) {
      this.i18nFilter.ValoRoleTypes = typeList.value;
    } else {
      delete this.i18nFilter.ValoRoleTypes;
    }
    this.i18nFilter = { ...this.i18nFilter };
    this.filterUrlParam.i18nFilter = this.i18nFilter;
    super.filterEvent(this.filterUrlParam);
  }

  onActiveListChange(actifList: any) {
    if (actifList?.value.length) {
      this.i18nFilter.isActive = actifList.value;
    } else {
      delete this.i18nFilter.isActive;
    }
    this.i18nFilter = { ...this.i18nFilter };
    this.filterUrlParam.i18nFilter = this.i18nFilter;
    super.filterEvent(this.filterUrlParam);
  }

  onStatusListChange(statusList: any) {
    if (statusList?.value.length) {
      this.i18nFilter.AccountStatuses = statusList.value;
    } else {
      delete this.i18nFilter.AccountStatuses;
    }
    this.i18nFilter = { ...this.i18nFilter };
    this.filterUrlParam.i18nFilter = this.i18nFilter;
    super.filterEvent(this.filterUrlParam);
  }
}
