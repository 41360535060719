/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { NgIf, NgFor } from '@angular/common';

import { I18nService } from './../../../utils/services/i18n.service';

@Component({
  selector: 'app-action-renderer',
  templateUrl: './action-renderer.component.html',
  styleUrls: ['./action-renderer.component.scss'],
  standalone: true,
  imports: [NgIf, MatMenuModule, NgFor, MatProgressSpinnerModule],
})
export class ActionRendererComponent implements ICellRendererAngularComp {
  public cellValue: any;
  public items: any[];
  public row: any;
  public disableAction = false;

  constructor(public readonly i18nService: I18nService) {}

  // gets called once before the renderer is used
  async agInit(params: ICellRendererParams): Promise<void> {
    this.cellValue = this.getValueToDisplay(params);
    this.initItem(params);
  }

  // gets called whenever the cell refreshess
  refresh(params: ICellRendererParams): boolean {
    // set value into cell again
    this.initItem(params);
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  async initItem(params: ICellRendererParams) {
    this.row = params.data;
    let items = params['actionItems'](this.row);
    if (items instanceof Promise) {
      items = await items;
    }
    this.items = items;
    this.disableAction = this.items?.length === 0;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.value;
  }
}
