import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';

import { CitiesLocationTypeAcceptedData } from '../../../utils/models/CitiesLocationTypeAcceptedData';
import { CompanyData } from '../../../utils/models/CompanyData';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { MandatedDeveloperCompanyData } from '../../../utils/models/MandatedDeveloperCompanyData';
import { ProgramResponse } from '../../../utils/models/ProgramResponse';
import { ReferenceTableData } from '../../../utils/models/ReferenceTableData';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { TokenService } from '../../../utils/services/authorisation/token.service';
import { GoogleTagManagerService } from '../../../utils/services/google-tag-manager.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SharedProgramService } from '../../../utils/services/shared-program.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { SpinnerWithBackdropService } from '../../../utils/services/spinner-with-backdrop.service';
import { UtilsCompanyService } from '../../../utils/services/utils-company.service';
import { DropdownListPopulatedComponent } from '../../../utils/components/dropdown-list-populated/dropdown-list-populated.component';
import { FormLocationComponent } from '../../../utils/components/form-location/form-location.component';

@Component({
  selector: 'app-step-two-form-prospect',
  templateUrl: './step-two-form-prospect.component.html',
  styleUrls: ['./step-two-form-prospect.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FormLocationComponent,
    DropdownListPopulatedComponent,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatButtonModule,
  ],
})
export class StepTwoFormProspectComponent implements OnInit {
  /**
   * parentForm input
   *
   * @type {FormGroup}
   * @memberof StepTwoFormProspectComponent
   */
  @Input() parentForm: UntypedFormGroup;

  /**
   * nextStep input
   *
   * @memberof StepTwoFormProspectComponent
   */
  @Output() readonly nextStep = new EventEmitter();

  /**
   * isSubmit boolean
   *
   * @type {boolean}
   * @memberof StepTwoFormProspectComponent
   */
  public isSubmit: boolean;
  /**
   * isDeveloper attribute
   *
   * @type {boolean}
   * @memberof StepTwoFormProspectComponent
   */
  public isDeveloper: boolean;
  /**
   * developerCompanyNameControl attribute
   *
   * @type {AbstractControl}
   * @memberof StepTwoFormProspectComponent
   */
  public developerCompanyNameControl: AbstractControl;

  /**
   * programList attribute
   *
   * @type {ProgramResponse[]}
   * @memberof StepTwoFormProspectComponent
   */
  public programList: Array<ProgramResponse>;

  /**
   * programListByCompanyId attribute
   *
   * @type {Object}
   * @memberof StepTwoFormProspectComponent
   */
  public programListByCompanyId: Map<number, Array<ProgramResponse>>;

  /**
   * developerCompanyItems attribute
   *
   * @type {ReferenceTableData[]}
   * @memberof StepTwoFormProspectComponent
   */
  public developerCompanyItems: Array<ReferenceTableData>;
  /**
   * localisationsMapList attribute
   *
   * @type {Map<string, any>}
   * @memberof StepTwoFormProspectComponent
   */
  localisationsMapList: Map<string, { typeLocation; label }> = new Map();
  /**
   * filterLocalisationTypeAccepted attribute
   *
   * @type {CitiesLocationTypeAcceptedData}
   * @memberof StepTwoFormProspectComponent
   */
  filterLocalisationTypeAccepted: CitiesLocationTypeAcceptedData = {
    departments: true,
    regions: true,
  };
  /**
   * programListControl attribute
   *
   * @type {AbstractControl}
   * @memberof StepTwoFormProspectComponent
   */
  public programListControl: AbstractControl;
  /**
   * developerCompanyAvailable attribute
   *
   * @private
   * @type {Map<number, string>}
   * @memberof StepTwoFormProspectComponent
   */
  private readonly developerCompanyAvailable: Map<number, string>;
  /**
   * developerProgramAvailable attribute
   *
   * @private
   * @type {Map<number, Array<ProgramResponse>>}
   * @memberof StepTwoFormProspectComponent
   */
  private readonly developerProgramAvailable: Map<number, Array<ProgramResponse>>;

  /**
   * Creates an instance of StepTwoFormProspectComponent.
   * @param {TokenService} tokenService
   * @param {AppConfigService} appConfigService
   * @param {I18nService} i18nService
   * @param {UtilsCompanyService} utilsCompanyService
   * @param {SharedProgramService} sharedProgramService
   * @param {SnackbarService} snackbarService
   * @memberof StepTwoFormProspectComponent
   */
  constructor(
    private readonly tokenService: TokenService,
    public readonly appConfigService: AppConfigService,
    public readonly i18nService: I18nService,
    private readonly utilsCompanyService: UtilsCompanyService,
    private readonly sharedProgramService: SharedProgramService,
    private readonly snackbarService: SnackbarService,
    private readonly spinnerWithBackdropService: SpinnerWithBackdropService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
  ) {
    this.isDeveloper = false;
    this.developerCompanyItems = [];
    this.isSubmit = false;
    this.programListByCompanyId = new Map();
    this.developerCompanyAvailable = new Map();
    this.developerProgramAvailable = new Map();
  }

  ngOnInit(): void {
    this.parentForm.addControl('programList', new UntypedFormControl('', [Validators.required]));
    this.programListControl = this.parentForm.controls.programList;

    // List of developer role
    const developerAccountList = [
      this.appConfigService.appConfig.roles.developerHyper,
      this.appConfigService.appConfig.roles.developerSuper,
      this.appConfigService.appConfig.roles.developerSimple,
    ];

    // Check is the user is a developer
    if (developerAccountList.includes(this.tokenService.getRoleName())) {
      this.isDeveloper = true;

      // This control is not send with form
      this.parentForm.addControl('developerCompanyName', new UntypedFormControl({ value: '', disabled: true }));
      this.developerCompanyNameControl = this.parentForm.controls.developerCompanyName;

      // Get company for this user
      this.utilsCompanyService.getCompanyOfTheUser().subscribe((company: CompanyData) => {
        // Set the user company id and name for display
        this.parentForm.addControl('companyId', new UntypedFormControl(company.id));
        this.developerCompanyNameControl.setValue(company.corporateName);
        this.sharedProgramService.getHisPrograms().subscribe((programList) => {
          this.programList = programList;
        });
      });
    } else {
      // Get all developer company because the user is a valo user
      this.utilsCompanyService
        .getDeveloperCompanyWhereUserAreMandatedAndItsPrograms()
        .subscribe((companyData: Array<MandatedDeveloperCompanyData>) => {
          companyData.forEach((element: MandatedDeveloperCompanyData) => {
            if (!element.maintenance) {
              // Save all visible company for the user
              this.developerCompanyAvailable.set(element.id, element.corporateName);
              const uniquePrograms = [];
              const tmpMapForProgramIds = new Map();
              for (const currentProgram of element.programs) {
                if (!tmpMapForProgramIds.has(currentProgram.id)) {
                  tmpMapForProgramIds.set(currentProgram.id, true);
                  uniquePrograms.push(currentProgram);
                }
              }

              // Save all the program of the company visible
              this.developerProgramAvailable.set(element.id, uniquePrograms);
            }
          });
        });
    }
  }

  /**
   * localisationsChanged method, called when the location change
   *
   * @memberof StepTwoFormProspectComponent
   */
  public localisationsChanged(): void {
    this.spinnerWithBackdropService.show();

    const myMap = this.localisationsMapList;

    // Compute the company and the program corresponding to the location entered
    if (myMap.size) {
      const loc = myMap.values().next().value;
      const departmentType = this.appConfigService._('localisationType', 'department');
      const regionType = this.appConfigService._('localisationType', 'region');
      // Search if program is agreed with localisation
      this.developerProgramAvailable.forEach((programs, companyId) => {
        const matchingPrograms: Array<ProgramResponse> = [];
        programs.forEach((program: ProgramResponse) => {
          if (
            (loc.typeLocation === departmentType && program.departementName === loc.label) ||
            (loc.typeLocation === regionType && program.regionName === loc.label)
          ) {
            matchingPrograms.push(program);
          }
        });

        // Setting the dropdown list of companies
        // And prepare the dropdown list of program by companies
        if (matchingPrograms.length) {
          const newItem: ReferenceTableData = {
            id: companyId,
            label: this.developerCompanyAvailable.get(companyId),
          };
          this.developerCompanyItems.push(newItem);
          this.programListByCompanyId.set(companyId, matchingPrograms);
        }
      });

      // Change reference for input dropdown
      this.developerCompanyItems = this.developerCompanyItems.slice();
    } else {
      // If no localisation, clean programs and companies
      this.developerCompanyItems = [];
      this.programList = undefined;
      this.programListControl.setValue(undefined);
    }

    this.spinnerWithBackdropService.hide();
  }

  /**
   * developerCompanySelected method
   *
   * @param {ReferenceTableData[]} entrySelected
   * @memberof StepTwoFormProspectComponent
   */
  public developerCompanySelected(entrySelected: Array<ReferenceTableData>): void {
    if (entrySelected.length) {
      const id = entrySelected[0].id as number;
      this.programList = this.programListByCompanyId.get(id);
    } else {
      this.programList = undefined;
      this.programListControl.setValue(undefined);
    }
  }

  /**
   * submit method
   *
   * @memberof StepTwoFormProspectComponent
   */
  public submit(): void {
    if (this.parentForm.valid) {
      this._googleTagManagerService.pushTag({
        event: 'prospect_declaration_validation',
      });
      // We can go to next step now
      this.nextStep.emit();
    } else {
      // Step two form not valid
      const message: SnackbarMessage = {
        text: this.i18nService._('Error_SnackBar_FormIncomplete'),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
    }
    this.isSubmit = true;
  }
}
