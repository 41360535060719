<button class="closing-dialog" (click)="onClose()">
  <app-icon name="XMark"></app-icon>
</button>
<h1 class="text-center" mat-dialog-title>
  {{ i18nService._('OptionFrom_Modal_Title') }}
</h1>

<mat-dialog-content class="px-5">
  <div class="pb-6">
    <form *ngIf="fields" [formGroup]="form" class="w-full h-full">
      <formly-form #formlyForm (modelChange)="onChange($event)" [fields]="fields" [form]="form" [model]="model"></formly-form>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="justify-center mb-1">
  <button
    type="button"
    (click)="onClose()"
    class="my-3 mr-0 lg:mr-4 md:mr-4 sm:mr-4 lg:mb-0 sm:mb-0 md:mb-0"
    mat-stroked-button
    color="primary"
  >
    {{ i18nService._('OptionFrom_Modal_Cancel') }}
  </button>
  <button
    class="my-3 lg:mb-0 sm:mb-0 md:mb-0"
    [class.disabled]="!formValid"
    [disabled]="!formValid"
    (click)="submit()"
    mat-flat-button
    color="primary"
    data-cy="submit-button-prospect-association"
  >
    {{ i18nService._('OptionFrom_Modal_Valid') }}
  </button>
</mat-dialog-actions>
