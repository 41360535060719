import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { of, switchMap } from 'rxjs';

import { AdvancedTrackingTableComponent } from '../advanced-tracking-table/advanced-tracking-table.component';
import { AdvancedTrackingTablesNoBackPageComponent } from '../advanced-tracking-tables-no-back-page/advanced-tracking-tables-no-back-page.component';

import { QuestionDialogComponent } from '../../../dialog/components/question-dialog/question-dialog.component';
import { SearchFormService } from '../../../search/services/search-form.service';
import { Sitemap } from '../../../utils/models/Sitemap';
import { I18nService } from '../../../utils/services/i18n.service';
import { AdvancedTrackingTableColumn } from '../../models/AdvancedTrackingTableColumn';
import { AdvancedTrackingTableColumnType } from '../../models/advanced-tracking-table-column-type.enum';
import { SavedSearch } from '../../models/SavedSearch';
import { SaveSearchService } from '../../services/save-search.service';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';

@Component({
  selector: 'app-saved-searches-tracking-table',
  templateUrl: './saved-searches-tracking-table.component.html',
  styleUrls: ['./saved-searches-tracking-table.component.scss'],
  standalone: true,
  imports: [StickyHeaderFormComponent, AdvancedTrackingTablesNoBackPageComponent],
})
export class SavedSearchesTrackingTableComponent implements OnInit {
  /**
   * Columns of the array.
   *
   * @type {Array<AdvancedTrackingTableColumn>}
   * @memberof SavedSearchesTrackingTableComponent
   */
  public columns: Array<AdvancedTrackingTableColumn>;

  /**
   * i18n key of message to display if no results.
   *
   * @type {string}
   * @memberof SavedSearchesTrackingTableComponent
   */
  public noResultMessageI18nToken = 'Txt_No_Result_Saved_Search';

  @ViewChild(AdvancedTrackingTableComponent)
  advancedTableComponent: AdvancedTrackingTableComponent;

  constructor(
    public readonly service: SaveSearchService,
    public readonly i18nService: I18nService,
    public readonly searchFormService: SearchFormService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.columns = [
      {
        key: 'title',
        sortable: true,
        i18nTitle: 'Title_SavedSearches_Column_Title',
      },
      {
        key: 'description',
        sortable: false,
        i18nTitle: 'Title_SavedSearches_Column_Description',
      },
      {
        key: 'actions',
        sortable: false,
        i18nTitle: 'Txt_ProgramsTrackingTable_Actions',
        type: AdvancedTrackingTableColumnType.Actions,
        parameters: {
          actions: {
            editSearch: (element: SavedSearch) => {
              this.searchFormService.setSearchFilter(JSON.parse(element.criterias));
              this.searchFormService.setSavedSearchId(element.id);
              this.router.navigate([Sitemap.utils.search.path]);
            },
            deleteSearch: (element: SavedSearch) => {
              this.dialog
                .open(QuestionDialogComponent, {
                  data: {
                    title: this.i18nService._('Title_Delete_Search'),
                    message: this.i18nService._('Txt_Delete_Search'),
                  },
                })
                .afterClosed()
                .pipe(
                  switchMap(({ answer }) => {
                    if (answer === true) {
                      return this.service.deleteSavedSearch(element.id);
                    }

                    return of(true);
                  }),
                )
                .subscribe(() => {
                  this.advancedTableComponent.loadItems({});
                });
            },
          },
        },
      },
    ];
  }
}
