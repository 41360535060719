<mat-form-field [ngClass]="addClass">
  <mat-label [ngClass]="{ 'txt-disable': isDisable }">{{ i18nService._(filterSelectOption.placeholder) }}</mat-label>
  <mat-icon
    *ngIf="filterSelectOption.tooltip && isDisable"
    [matTooltip]="i18nService._(filterSelectOption.tooltip.text)"
    [ngClass]="{ 'txt-disable': isDisable }"
    class="text-base material-icons-outlined mr-1 filter-input-disable-icon"
    matPrefix
    >{{ filterSelectOption.tooltip.icon }}
  </mat-icon>
  <input
    (change)="selectedItem()"
    *ngIf="filterSelectOption.type === 'input'"
    [attr.type]="filterSelectOption.inputType"
    [formControl]="selectFormControl"
    matInput
  />
  <mat-select (selectionChange)="selectedItem()" *ngIf="filterSelectOption.type === 'select'" [formControl]="selectFormControl">
    <mat-option [value]="undefined">&nbsp;</mat-option>
    <mat-option *ngFor="let item of tableItems" [value]="item.id">
      {{ item.label }}
    </mat-option>
  </mat-select>
  <mat-icon
    *ngIf="filterSelectOption.suffixIcon"
    [ngClass]="{ 'txt-disable': isDisable }"
    class="material-icons-outlined text-base"
    matSuffix
    >{{ filterSelectOption.suffixIcon }}
  </mat-icon>
</mat-form-field>
