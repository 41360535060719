import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';

import { LotResponse } from '../models/LotResponse';
import { AppConfigService } from './app-config.service';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class SharedLotService {
  /**
   * Creates an instance of SharedLotService
   *
   * @param {AppConfigService} appConfigService
   * @param {ErrorHandlerService} errorHandlerService
   * @param {HttpClient} httpClient
   * @memberof SharedLotService
   */
  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly httpClient: HttpClient,
  ) {}

  /**
   * getLotDetails method
   *
   * @param {number} lotId
   * @param {number} optionLotPriceId
   * @param {number} reservationId
   * @returns {Observable<LotResponse>}
   * @memberof SharedLotService
   */
  getLotDetails(lotId: number, optionLotPriceId: number, reservationId: number): Observable<LotResponse> {
    const url = `${this.appConfigService.getLoopbackApiUrl()}/Lots/details/${lotId}`;
    let params = new HttpParams();
    if (optionLotPriceId) {
      params = params.set('optionLotPriceId', String(optionLotPriceId));
    }

    if (reservationId) {
      params = params.set('reservationId', String(reservationId));
    }

    return this.httpClient
      .get<LotResponse>(url, { params })
      .pipe(catchError(this.errorHandlerService.handleError<LotResponse>('SharedLotService', 'getLotDetails')));
  }
}
