<ng-container *ngIf="program.documents">
  <span class="app-program-documents-list-container-title">
    {{ i18nService._('Txt_page_program_documents_list_title') }}
  </span>
  <div class="app-program-documents-list-container">
    <div class="app-program-documents-list-content">
      <div class="app-program-documents-list">
        <span class="app-program-documents-list-title">{{ i18nService._('Title_BookingAndGuaranteesBook') }}</span>
        <div *ngFor="let contratDoc of contratsDocsArray" class="doc-checkbox-container">
          <div *ngIf="contratDoc?.code && contratDoc?.actions?.display" class="mb-2 commercial-program-text">
            <mat-checkbox
              (change)="isAtLeastOneChecked()"
              [(ngModel)]="contratDoc.actions.checked"
              [disabled]="contratDoc.actions.disabled"
              [value]="contratDoc.label"
              color="primary"
            >
              <a
                (click)="viewDocument($event, contratDoc)"
                [class.disabled]="contratDoc.actions.disabled"
                class="text-base hidden"
                download
                target="_blank"
              >
                {{ getFileName(contratDoc) }}
              </a>
            </mat-checkbox>
            <span>
              <a (click)="toggleCheckbox(contratDoc)" [class.disabled]="contratDoc.actions.disabled" class="text-base mb-2">
                {{ getFileName(contratDoc) }}</a
              >
            </span>
          </div>
          <div *ngIf="contratDoc?.code && contratDoc?.actions?.display" class="doc-checkbox">
            <button
              (click)="viewDocument($event, contratDoc)"
              [class.disabled-button]="contratDoc.actions.disabled"
              [disabled]="contratDoc.actions.disabled"
              class="size-18 button-size mb-2"
              mat-icon-button
            >
              <mat-icon class="material-icons-outlined size-20">visibility</mat-icon>
            </button>
            <button
              (click)="downloadDocument($event, contratDoc)"
              [class.disabled-button]="contratDoc.actions.disabled"
              [disabled]="contratDoc.actions.disabled"
              class="size-18 button-size mb-2"
              mat-icon-button
            >
              <mat-icon class="material-icons-outlined size-20">download</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="app-program-documents-list">
        <span class="app-program-documents-list-title">{{ i18nService._('Title_PresentationDocumentation') }}</span>
        <div *ngFor="let presentationDoc of presentationsDocsArray" class="doc-checkbox-container">
          <div *ngIf="presentationDoc.actions.display" class="mb-2 commercial-program-text">
            <mat-checkbox
              (change)="isAtLeastOneChecked()"
              [(ngModel)]="presentationDoc.actions.checked"
              [disabled]="presentationDoc.actions.disabled"
              [ngClass]="{ 'no-drop': presentationDoc.actions.disabled }"
              [value]="presentationDoc.label"
              color="primary"
            >
              <a
                (click)="viewDocument($event, presentationDoc)"
                [class.disabled]="presentationDoc.actions.disabled"
                [ngClass]="{ 'no-drop': presentationDoc.actions.disabled }"
                class="text-base hidden"
                download
                target="_blank"
              >
                {{ getFileName(presentationDoc) }}
              </a>
            </mat-checkbox>
            <span>
              <a
                (click)="toggleCheckbox(presentationDoc)"
                [class.disabled]="presentationDoc.actions.disabled"
                [ngClass]="{ 'no-drop': presentationDoc.actions.disabled }"
                class="text-base mb-2"
              >
                {{ getFileName(presentationDoc) }}
              </a>
            </span>
          </div>
          <div *ngIf="presentationDoc.actions.display" class="doc-checkbox">
            <button
              (click)="viewDocument($event, presentationDoc)"
              [class.disabled-button]="presentationDoc.actions.disabled"
              class="size-18 button-size mb-2"
              mat-icon-button
            >
              <mat-icon class="material-icons-outlined size-20">visibility</mat-icon>
            </button>

            <button
              (click)="downloadDocument($event, presentationDoc)"
              [class.disabled-button]="presentationDoc.actions.disabled"
              class="size-18 button-size mb-2"
              mat-icon-button
            >
              <mat-icon class="material-icons-outlined size-20">download</mat-icon>
            </button>
          </div>
        </div>
        <!-- Plaquette commerciale numérique -->
        <a
          *ngIf="program.digitalCommercialBrochureUrl"
          class="hover:text-orange-500 font:bold"
          href="{{ program.digitalCommercialBrochureUrl }}"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div class="flex justify-between align-center">
            <span class="text-base">{{ i18nService._('Txt_Placeholder_DigitalCommercialBrochureUrl') }}</span>
            <mat-icon class="material-icons-outlined size-20">visibility</mat-icon>
          </div>
        </a>
        <!-- Plaquette commerciale numérique -->
      </div>
    </div>

    <div *ngIf="externalDocumentsLink" class="other-docs flex items-center">
      <a class="other-docs-link text-base" href="{{ externalDocumentsLink }}" rel="noopener noreferrer" target="_blank">
        {{ i18nService._('Txt_Link_External_Documents_Utils') }}
      </a>
      <mat-icon class="material-icons-outlined size-18">navigate_next</mat-icon>
    </div>
    <div class="app-program-documents-button-container">
      <button
        (click)="downloadAndZipSelectedDocs()"
        [class.disabled-link]="downloadButtonDisabled || isDownloadingAsZip"
        [disabled]="downloadButtonDisabled"
        class="download-docs"
        mat-flat-button
      >
        <mat-icon *ngIf="!isDownloadingAsZip" class="material-icons-outlined size-18">download</mat-icon>
        <span *ngIf="isDownloadingAsZip" class="spinner-container">
          <mat-progress-spinner color="primary" diameter="30" mode="indeterminate" value="50"></mat-progress-spinner>
        </span>
        {{ i18nService._('Txt_page_program_documents_list_download_zip_label') }}
      </button>
      <button
        (click)="mailSelectedDocs()"
        [class.disabled-button]="downloadButtonDisabled || isDownloadingByEmail"
        [disabled]="downloadButtonDisabled"
        class="share-by-mail"
        mat-flat-button
      >
        <div class="flex items-center">
          <mat-icon *ngIf="!isDownloadingByEmail" class="material-icons-outlined size-18 mail-material-icons"> forward_to_inbox</mat-icon>
          <span *ngIf="isDownloadingByEmail" class="spinner-container">
            <mat-progress-spinner color="primary" diameter="30" mode="indeterminate" value="50"></mat-progress-spinner>
          </span>
          <span class="label_line">
            {{ i18nService._('Txt_page_program_documents_list_send_mail_label') }}
          </span>
        </div>
      </button>
    </div>
  </div>
</ng-container>
