import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonType } from './button.type.enum';

import { PipesModule } from '../../../utils/pipes/pipes.module';

@Component({
  selector: 'app-button',
  standalone: true,
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  imports: [CommonModule, PipesModule],
})
export class ButtonComponent {
  ButtonType = ButtonType;

  @Input()
  title = '';

  @Input()
  type: ButtonType = ButtonType.PRIMARY;

  @Output() clickButton = new EventEmitter<void>();

  click() {
    this.clickButton.emit();
  }
}
