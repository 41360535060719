import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AsPipe } from './as.pipe';
import { PhonePipe } from './phone.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { HighlightPipe } from './highlight.pipe';
import { SafePipe } from './safe.pipe';
import { SortPipe } from './sort.pipe';
import { ThousandsSeparatorPipe } from './thousands-separator.pipe';
import { DaysFromNow } from './days-from-now.pipe';
import { TranslatePipe } from './translate.pipe';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DaysFromNow,
    EllipsisPipe,
    HighlightPipe,
    SafePipe,
    SortPipe,
    AsPipe,
    PhonePipe,
    ThousandsSeparatorPipe,
    TranslatePipe,
  ],
  exports: [DaysFromNow, EllipsisPipe, HighlightPipe, SafePipe, SortPipe, AsPipe, PhonePipe, ThousandsSeparatorPipe, TranslatePipe],
})
export class PipesModule {}
