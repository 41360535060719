<app-dashboard-card icon="Funnel" title="Txt_DossierProspectSearch" [complete]="hasSearchPreferences" *ngIf="hasSearchPreferences !== null">
  <div class="detail no-search-preferences" *ngIf="!hasSearchPreferences">
    {{ 'Txt_DossierProspectDashboardNoSearchPreference' | translate }}
  </div>
  <div class="detail" *ngIf="hasSearchPreferences">
    <div class="search-preference-category" *ngFor="let item of hSearchPreferencesByCategory | keyvalue: sortOriginalOrder">
      <div class="title">{{ 'SearchPreference_' + item.key | translate }}</div>
      <div class="values">
        <app-chip *ngFor="let value of item.value">{{ value }}</app-chip>
      </div>
    </div>
  </div>

  <footer *ngIf="!hasSearchPreferences">
    <app-button [type]="ButtonType.PRIMARY" (clickButton)="goToSearchPreferences()">
      {{ 'Txt_DossierProspectButtonSetSearchPreferences' | translate }}
    </app-button>
    <app-button [type]="ButtonType.SECONDARY" (clickButton)="searchWithoutCriteria()">
      {{ 'Txt_DossierProspectSearchButton' | translate }}
    </app-button>
  </footer>
  <footer *ngIf="hasSearchPreferences">
    <app-button [type]="ButtonType.SECONDARY" (clickButton)="goToSearchPreferences()">
      {{ 'Txt_DossierProspectButtonEditSearchPreferences' | translate }}
    </app-button>
    <app-button [type]="ButtonType.PRIMARY" (clickButton)="searchWithCriteria()">
      {{ 'Txt_DossierProspectSearchButton' | translate }}
    </app-button>
  </footer>
</app-dashboard-card>
