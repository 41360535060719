import { IsEnum, IsNumber, IsOptional } from 'class-validator';

import { RevenueTypeEnum } from './revenu-type.enum';

export class RevenueRequestDto {
  @IsNumber()
  @IsOptional()
  id?: number;

  @IsNumber()
  amount: number;

  @IsEnum(RevenueTypeEnum)
  revenueTypeCode: RevenueTypeEnum;
}
