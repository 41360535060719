import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';

import { ActivityCategories } from '../activities/enum/activity-categories.enum';
import { AuthGuard } from '../security/authguard';
import { CompanyType } from '../utils/models/enums/CompanyType.enum';
import { Sitemap } from '../utils/models/Sitemap';
import { AccountsTrackingTableComponent } from './components/accounts-tracking-table/accounts-tracking-table.component';
import { ActivitiesTrackingTableComponent } from './components/activities-tracking-table/activities-tracking-table.component';
import { CompaniesTrackingTableComponent } from './components/companies-tracking-table/companies-tracking-table.component';
import { PreReservationsTrackingTableComponent } from './components/pre-reservations-tracking-table/pre-reservations-tracking-table.component';
import { ProgramsSalesTrackingTableComponent } from './components/programs-sales-tracking-table/programs-sales-tracking-table.component';
import { ProgramsTrackingTableComponent } from './components/programs-tracking-table/programs-tracking-table.component';
import { SpecialOffersTrackingTableComponent } from './components/special-offers-tracking-table/special-offers-tracking-table.component';
import { ReservationsTrackingTableComponent } from './components/reservations-tracking-table/reservations-tracking-table.component';
import { SalersTrackingTableComponent } from './components/salers-tracking-table/salers-tracking-table.component';
import { MandateStrategiesTrackingTableComponent } from './components/mandate-strategies-tracking-table/mandate-strategies-tracking-table.component';
import { SavedSearchesTrackingTableComponent } from './components/saved-searches-tracking-table/saved-searches-tracking-table.component';
import { DossierTrackingTableComponent } from './components/dossiers-tracking-table/dossier-tracking-table.component';
import { OptionsGridTrackingTableComponent } from './components/options-tracking-table/options-grid-tracking-table.component';
import { ProspectsGridTrackingTableComponent } from './components/prospects-tracking-table/prospects-grid-tracking-table.component';

const routes: Routes = [
  {
    path: Sitemap.companies.admin.path,
    data: {
      roles: Sitemap.companies.admin.data.roles,
    },
    component: CompaniesTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.companies.adminDeveloper.path,
    data: {
      roles: Sitemap.companies.adminDeveloper.data.roles,
      type: CompanyType.DEVELOPER,
    },
    component: CompaniesTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.companies.adminContractor.path,
    data: {
      roles: Sitemap.companies.adminContractor.data.roles,
      type: CompanyType.CONTRACTOR,
    },
    component: CompaniesTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.companies.adminIndependant.path,
    data: {
      roles: Sitemap.companies.adminIndependant.data.roles,
      type: CompanyType.INDEPENDANT,
    },
    component: CompaniesTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.programs.admin.path,
    data: {
      roles: Sitemap.programs.admin.data.roles,
    },
    component: ProgramsTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.specialOffer.admin.path,
    data: {
      roles: Sitemap.specialOffer.admin.data.roles,
    },
    component: SpecialOffersTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.accounts.admin.path,
    data: {
      roles: Sitemap.accounts.admin.data.roles,
      type: CompanyType.VALORISSIMO,
    },
    component: AccountsTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.accounts.adminDeveloper.path,
    data: {
      roles: Sitemap.accounts.adminDeveloper.data.roles,
      type: CompanyType.DEVELOPER,
    },
    component: AccountsTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.accounts.adminContractor.path,
    data: {
      roles: Sitemap.accounts.adminContractor.data.roles,
      type: CompanyType.CONTRACTOR,
    },
    component: AccountsTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.accounts.adminIndependant.path,
    data: {
      roles: Sitemap.accounts.adminIndependant.data.roles,
      type: CompanyType.INDEPENDANT,
    },
    component: AccountsTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.dashboards.prospects.path,
    data: {
      roles: Sitemap.dashboards.prospects.data.roles,
    },
    component: ProspectsGridTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.dashboards.salesPrograms.path,
    data: {
      roles: Sitemap.dashboards.salesPrograms.data.roles,
    },
    component: ProgramsSalesTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.dashboards.preReservations.path,
    component: PreReservationsTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.dashboards.reservations.path,
    component: ReservationsTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.dashboards.options.path,
    component: OptionsGridTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.dashboards.action.path,
    data: {
      category: ActivityCategories.ACTION,
    },
    component: ActivitiesTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.dashboards.info.path,
    data: {
      category: ActivityCategories.INFO,
    },
    component: ActivitiesTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.dashboards.salers.path,
    component: SalersTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.companies.mandateStrategiesContractor.path,
    component: MandateStrategiesTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.dashboards.savedSearches.path,
    component: SavedSearchesTrackingTableComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.dashboards.dossier.path,
    component: DossierTrackingTableComponent,
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
  declarations: [],
})
export class TrackingTablesRoutingModule {}
