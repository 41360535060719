<ng-template #annexesBalconTemplateRef let-lotDetail let-lotMetaTitle="lotMetaTitle">
  <ng-container *ngIf="lotDetail.areas && (lotMetaAnnexes[lotMetaTitle] | as: ComparatorMetaPartial)?.show; else emptyData">
    <ng-container *ngIf="returnListeAnnexe(lotDetail)[lotMetaTitle]; else emptyData">
      <ng-container *ngFor="let tag of returnListeAnnexe(lotDetail)[lotMetaTitle]">
        <span class="lot-comparator-annexes-detail" *ngIf="tag; else emptyData">
          <mat-icon class="material-icons-outlined size-22">check_circle</mat-icon>
          {{ basicFormatsService.formatArea(tag.area) }}
        </span>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #annexesParkingTemplateRef let-lotDetail let-lotMetaTitle="lotMetaTitle">
  <ng-container *ngIf="(lotMetaAnnexes[lotMetaTitle] | as: ComparatorMetaPartial)?.show">
    <ng-container *ngIf="lotDetail.areas; else emptyData">
      <ng-container *ngIf="returnListeAnnexe(lotDetail)[lotMetaTitle]; else emptyData">
        <ng-container *ngFor="let tag of returnListeAnnexe(lotDetail)[lotMetaTitle]">
          <span class="lot-comparator-annexes-detail" *ngIf="tag; else emptyData">
            <mat-icon class="material-icons-outlined size-22">check_circle</mat-icon>
            {{ basicFormatsService.formatCurrencyCeil(tag.lotSellingPriceIT, undefined, 0, 0, 0) }}
            <span class="num-lot-annexe">n°{{ tag.numLot }}</span>
          </span>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #annexesGarageTemplateRef let-lotDetail let-lotMetaTitle="lotMetaTitle">
  <ng-container *ngIf="lotDetail.areas && (lotMetaAnnexes[lotMetaTitle] | as: ComparatorMetaPartial)?.show; else emptyData">
    <ng-container *ngIf="returnListeAnnexe(lotDetail)[lotMetaTitle]; else emptyData">
      <ng-container *ngFor="let tag of returnListeAnnexe(lotDetail)[lotMetaTitle]">
        <span class="lot-comparator-annexes-detail" *ngIf="tag.lotSellingPriceIT; else emptyData">
          <mat-icon class="material-icons-outlined size-22">check_circle</mat-icon>
          {{ basicFormatsService.formatCurrencyCeil(tag.lotSellingPriceIT, undefined, 0, 0, 0) }}
        </span>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #emptyData>
  <span class="empty-values">-</span>
</ng-template>

<div class="comparator-lot-separator" *ngIf="lotMetaAnnexes?.atLeastOneShow">
  <div>{{ i18nService._('Title_Form_SearchForm_Annexes') }}</div>
</div>
<app-lot-comparator-meta-line
  *ngIf="lotMetaAnnexes"
  class="border-radius-bottom"
  [lotDetailToCompare]="lotDetailToCompare"
  [lotMetaWrapper]="lotMetaAnnexes"
></app-lot-comparator-meta-line>
