import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subject } from 'rxjs';

import { RadioCardComponent } from '../../form/radio-card/radio-card.component';

@Component({
  selector: 'app-formly-radio-cards',
  templateUrl: './formly-radio-cards.component.html',
  styleUrls: ['./formly-radio-cards.component.scss'],
  standalone: true,
  imports: [RadioCardComponent],
})
export class FormlyRadioCardsComponent extends FieldType {
  private readonly $destroy: Subject<void> = new Subject<void>();
}
