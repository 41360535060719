<div class="full-width">
  <div *ngFor="let f of field.fieldGroup; let i = index" class="full-width">
    <div class="col-sm-2 d-flex align-items-center float-right">
      <button class="btn btn-danger remove-action" type="button" (click)="remove(i)">{{ field.props.removeLabel }}</button>
    </div>
    <formly-field [field]="f"></formly-field>
  </div>
  <div *ngIf="!field.props.readonly">
    <button class="flex items-center mb-8" (click)="add()" type="button">
      <div class="action-icon w-7 h-7 flex justify-center items-center mr-3">
        <app-icon [name]="field.props.icon" size="1.25rem" class="w-5 h-5 text-white" *ngIf="field.props.icon"></app-icon>
      </div>
      <div [innerHTML]="field.props.addLabel" [style]="field.props.style" [class]="field.props.class"></div>
    </button>
  </div>
</div>
