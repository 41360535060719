import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterMultiSelectComponent } from '../filter-multiselect/filter-multiselect.component';
import { SortDropdownComponent } from '../sort-dropdown/sort-dropdown.component';
import { InputListOption } from './models/input-list-option';

@Component({
  selector: 'app-filter-sort-header-bar',
  standalone: true,
  imports: [CommonModule, SortDropdownComponent, FilterMultiSelectComponent],
  templateUrl: './filter-sort-header-bar.component.html',
  styleUrls: ['./filter-sort-header-bar.component.scss'],
})
export class FilterSortHeaderBarComponent {
  @Input() filterEnabled: boolean;
  @Input() filterOptions: InputListOption;
  @Output() filterChanged = new EventEmitter<any[]>();

  @Input() sortEnabled: boolean;
  @Input() sortOptions: InputListOption;
  @Output() sortChanged = new EventEmitter<any>();

  onFilterChanged(event: any) {
    this.filterChanged.emit(event);
  }

  onSortChanged(event: any) {
    this.sortChanged.emit(event);
  }
}
