import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgIf, PercentPipe } from '@angular/common';

import { AccountService } from '../../../utils/services/account.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { ToggleFeesService } from '../../../utils/services/toggle-fees.service';
import { UserRoleService } from '../../../utils/services/user-role.service';

@Component({
  selector: 'app-fees',
  templateUrl: './fees.component.html',
  styleUrls: ['./fees.component.scss'],
  standalone: true,
  imports: [NgIf, PercentPipe],
})
export class FeesComponent implements OnInit, OnDestroy {
  @Input() fees: number;
  @Input() hasFeesRange: boolean;
  @Input() maxFees?: number;
  public hideLotFees: boolean;
  public isValo: boolean;
  private readonly _tearDownSubscription: Subscription = new Subscription();

  constructor(
    private readonly _toggleFeesService: ToggleFeesService,
    public i18nService: I18nService,
    private readonly _accountService: AccountService,
    private readonly _userRoleService: UserRoleService,
  ) {}

  ngOnInit() {
    this.isValo = this._userRoleService.isValo();
    if (this._userRoleService.isContractor()) {
      this._accountService.checkFeesCantBeSeen().subscribe((cantSeeFees) => {
        this.hideLotFees = cantSeeFees;
        if (!cantSeeFees) {
          this._tearDownSubscription.add(this._toggleFeesService.toggleFeesState$.subscribe((state) => (this.hideLotFees = state)));
        }
      });
    } else {
      this._tearDownSubscription.add(this._toggleFeesService.toggleFeesState$.subscribe((state) => (this.hideLotFees = state)));
    }
  }

  ngOnDestroy(): void {
    this._tearDownSubscription.unsubscribe();
  }
}
