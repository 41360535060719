import { IsArray, IsNumber, IsOptional } from 'class-validator';

import { ChargeRequestDto } from './charge/charge.request.dto';
import { RevenueRequestDto } from './revenue/revenue.request.dto';

export class SimulationRequestDto {
  @IsNumber()
  id: number;

  @IsArray()
  @IsOptional()
  charges?: ChargeRequestDto[];

  @IsArray()
  @IsOptional()
  revenues?: RevenueRequestDto[];

  @IsNumber()
  @IsOptional()
  loanDuration?: number;

  @IsNumber()
  @IsOptional()
  insuranceRate?: number;

  @IsNumber()
  @IsOptional()
  loanRate?: number;

  @IsNumber()
  @IsOptional()
  defaultLoanDataId?: number;

  @IsNumber()
  @IsOptional()
  personalFunds?: number;
}
