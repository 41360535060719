import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe, NgIf } from '@angular/common';

import { StepOneFormProspectComponent } from '../../../prospects/components/step-one-form-prospect/step-one-form-prospect.component';
import { AlertEnum } from '../../../utils/models/enums/alert.enum';
import { PoseoptionResponse } from '../../../utils/models/PoseoptionResponse';
import { GoogleTagManagerService } from '../../../utils/services/google-tag-manager.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { SpinnerWithBackdropService } from '../../../utils/services/spinner-with-backdrop.service';
import { UserRoleService } from '../../../utils/services/user-role.service';
import { ProspectAssociation } from '../../models/ProspectAssociation';
import { ProspectData } from '../../models/ProspectData';
import { TakeOptionData } from '../../models/TakeOptionData';
import { ProspectAssociationService } from '../../services/prospect-association.service';
import { ProspectsModule } from '../../../prospects/prospects.module';
import { ChipPopulatedAutocompleteComponent } from '../../../utils/components/chip-populated-autocomplete/chip-populated-autocomplete.component';
import { AlertComponent } from '../../../utils/components/alert/alert.component';

@Component({
  selector: 'app-prospect-association-dialog',
  templateUrl: './prospect-association-dialog.component.html',
  styleUrls: ['./prospect-association-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    NgIf,
    AlertComponent,
    ChipPopulatedAutocompleteComponent,
    FormsModule,
    ReactiveFormsModule,
    ProspectsModule,
    MatButtonModule,
    AsyncPipe,
  ],
})
export class ProspectAssociationDialogComponent implements OnInit {
  /**
   * prospectForm viewchild
   *
   * @type {StepOneFormProspectComponent}
   * @memberof ProspectAssociationDialogComponent
   */
  @ViewChild('prospectForm') public prospectForm: StepOneFormProspectComponent;
  /**
   * prospectContactInformationForm vattribute
   *
   * @type {FormGroup}
   * @memberof ProspectAssociationDialogComponent
   */
  public prospectContactInformationForm: UntypedFormGroup;
  /**
   * prospectSelectedForm attribute
   *
   * @type {FormGroup}
   * @memberof ProspectAssociationDialogComponent
   */
  public prospectSelectedForm: UntypedFormGroup;
  /**
   * prospectItems$ attribute
   *
   * @type {Observable<Map<string, any>>}
   * @memberof ProspectAssociationDialogComponent
   */
  public prospectItems$: Observable<Map<string, number>>;
  /**
   * prospectDetails attribute
   *
   * @private
   * @type {ProspectData}
   * @memberof ProspectAssociationDialogComponent
   */
  public prospectDetails: ProspectData;
  public title: string;
  public alertEnum = AlertEnum;
  public isNotDeveloper: boolean;
  /**
   * prospectsDetails attribute
   *
   * @private
   * @type {Array<ProspectData>}
   * @memberof ProspectAssociationDialogComponent
   */
  private prospectsDetails: Array<ProspectData>;

  /**
   * Creates an instance of ProspectAssociationDialogComponent.
   * @param {I18nService} i18nService
   * @param {FormBuilder} formBuilder
   * @param {SnackbarService} snackbarService
   * @param {ProspectAssociationService} prospectAssociationService
   * @param spinnerWithBackdropService
   * @param {MatDialogRef<ProspectAssociationDialogComponent>} dialogRef
   * @param {ProspectAssociation} data
   * @param _googleTagManagerService
   * @memberof ProspectAssociationDialogComponent
   */
  constructor(
    public i18nService: I18nService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly snackbarService: SnackbarService,
    private readonly prospectAssociationService: ProspectAssociationService,
    private readonly spinnerWithBackdropService: SpinnerWithBackdropService,
    public dialogRef: MatDialogRef<ProspectAssociationDialogComponent>,
    public readonly userRoleService: UserRoleService,
    @Inject(MAT_DIALOG_DATA) public data: ProspectAssociation,
    private readonly _googleTagManagerService: GoogleTagManagerService,
  ) {
    this.prospectItems$ = undefined;
    this.prospectsDetails = [];
    this.prospectDetails = undefined;
  }

  /**
   * closeDialog
   *
   * @param {boolean} [refresh=false]
   * @memberof ProspectAssociationDialogComponent
   */
  public closeDialog(poseOption: PoseoptionResponse): void {
    // success CodeRetour of take option
    let lotIdUpdateToOptionned;
    if (poseOption) {
      lotIdUpdateToOptionned = poseOption.codeRetour === 200 || poseOption.codeRetour === 205 ? poseOption.option.lotId : undefined;
    }
    this.dialogRef.close(lotIdUpdateToOptionned);
  }

  /**
   * ngOnInit method
   *
   * @memberof ProspectAssociationDialogComponent
   */
  ngOnInit(): void {
    this.isNotDeveloper = this.userRoleService.isContractor() || this.userRoleService.isValo();
    this.prospectContactInformationForm = this.formBuilder.group({});
    this.prospectSelectedForm = this.formBuilder.group({});
    this.title = this.data.title || 'Title_ProspectAssociationPopin_PopinTitle';
    // Get prospects for dropdown list
    this.prospectItems$ = this.prospectAssociationService.getProspects().pipe(
      map((prospects) => {
        const items = new Map();
        prospects.forEach((prospect) => items.set(`${prospect.lastName} ${prospect.firstName} - ${prospect.postalCode}`, prospect.id));
        this.prospectsDetails = prospects;

        return items;
      }),
    );
  }

  /**
   * submit method
   *
   * @memberof ProspectAssociationDialogComponent
   */
  public submit(): void {
    const optionForm: TakeOptionData = {
      lotId: this.data.lotId,
    };
    // If prospect selected, so send prospect id

    if (this.prospectDetails) {
      // Call back method with prospect id
      if (this.prospectSelectedForm.value.prospectId && this.prospectSelectedForm.value.prospectId.length) {
        optionForm.prospectId = this.prospectSelectedForm.value.prospectId[0];
      } else {
        this.snackbarService.sendErrorOccured();

        return;
      }
    } else {
      // If no prospect selected, so send prospect object with all field
      if (this.prospectForm.checkStep()) {
        // Form is ok, so call back method with prospect form
        optionForm.prospect = this.prospectContactInformationForm.value;
      } else {
        return;
      }
    }

    this.spinnerWithBackdropService.show();
    this.prospectAssociationService.takeOption(optionForm).subscribe(
      (successTakeOption) => {
        this.spinnerWithBackdropService.hide();

        // extract information related to paternity and options
        const { libelleRetour, libelleRetourPaternity } = successTakeOption;
        // Format message in html mode for snackbar with html mode
        let formatResponseAsHtml = `<p>${libelleRetour}<p>`;

        if (libelleRetourPaternity) {
          formatResponseAsHtml = `${formatResponseAsHtml}<b>${libelleRetourPaternity}</b>`;
        }
        this.snackbarService.info(formatResponseAsHtml, false, undefined, true);
        this.closeDialog(successTakeOption);
      },
      () => {
        this.spinnerWithBackdropService.hide();
      },
    );
  }

  /**
   * prospectSelected method
   *
   * @param {Array<number>} newValue
   * @memberof ProspectAssociationDialogComponent
   */
  public prospectSelected(newValue: Array<number>): void {
    this.removeError();
    const fieldNames = ['city', 'civilityId', 'email', 'firstName', 'lastName', 'postalCode', 'phone'];
    if (newValue.length) {
      // Prospect selected, fill read-only fields and disable form
      this.prospectContactInformationForm.disable();
      this.prospectDetails = this.prospectsDetails.find((element) => element.id === newValue[0]);
      fieldNames.forEach((fieldName: string) => {
        const control = this.prospectContactInformationForm.controls[fieldName];
        control.setValue(this.prospectDetails[fieldName]);
      });
    } else {
      // Prospect unselected, clear read-only fields and enable form
      this.prospectDetails = undefined;
      this.prospectContactInformationForm.enable();
      fieldNames.forEach((fieldName: string) => {
        const control = this.prospectContactInformationForm.controls[fieldName];
        control.setValue('');
      });
    }
  }

  /**
   * removeError method
   *
   * @private
   * @memberof ProspectAssociationDialogComponent
   */
  private removeError(): void {
    this.prospectContactInformationForm.markAsUntouched();
  }
}
