import { Component, OnInit, ViewChild } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { OptionStatus, ValoRole } from '@commons-dto/valo-back';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

import { GridTrackingTableComponent } from '../grid-tacking-table/grid-tracking-table.component';

import { I18nService } from '../../../utils/services/i18n.service';
import { DateRendererComponent } from '../../renderers/date-renderer/date-renderer.component';
import { OptionsTrackingTableService } from '../../services/options-tracking-table.service';
import { StatusTagsRendererComponent } from '../../renderers/status-tags-renderer/status-tags-renderer.component';
import { StatusTagColor } from '../../../design-system/model/status-tag-color.enum';
import { PriceRendererComponent } from '../../renderers/price-renderer/price-renderer.component';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { UserRoleService } from '../../../utils/services/user-role.service';
import { ActionRendererComponent } from '../../renderers/action-renderer/action-renderer.component';
import { OptionsTrackingTableItem } from '../../models/OptionsTrackingTableItem';
import { PoseOptionsService } from '../../../utils/services/pose-options.service';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { PoseoptionResponse } from '../../../utils/models/PoseoptionResponse';
import { AbstractTrackingTable } from '../../abstract-tracking-table';
import { FilterEventOutput } from '../../models/filter/filter-event-output';
import { InformationRendererComponent } from '../../renderers/information/information-renderer.component';
import { FeatureFlagService } from '../../../feature-flag/feature-flag.service';
import { TransactionActionService } from '../../../common/services/transaction/transaction-action.service';
import { TranslatePipe } from '../../../utils/pipes/translate.pipe';
import { ToggleButtonComponent } from '../../../design-system/component/toggle-button/toggle-button.component';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';
import { LotDetailsPanelComponent } from '../../../utils/components/lot-details-panel/lot-details-panel.component';

const tagTypeByOptionStatus: { [key in OptionStatus]: StatusTagColor } = {
  ACCEPTED: StatusTagColor.SUCCESS,
  PENDING: StatusTagColor.IN_PROGRESS,
  EXPIRED: StatusTagColor.CRITICAL,
  REFUSED: StatusTagColor.CRITICAL,
};

@Component({
  selector: 'app-options-grid-tracking-table',
  templateUrl: './options-grid-tracking-table.component.html',
  styleUrls: ['./options-grid-tracking-table.component.scss'],
  standalone: true,
  imports: [
    MatSidenavModule,
    LotDetailsPanelComponent,
    StickyHeaderFormComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    NgIf,
    ToggleButtonComponent,
    GridTrackingTableComponent,
    TranslatePipe,
  ],
})
export class OptionsGridTrackingTableComponent extends AbstractTrackingTable implements OnInit {
  @ViewChild(GridTrackingTableComponent)
  gridTrackingTable: GridTrackingTableComponent;
  @ViewChild('drawer', { static: true }) drawer: MatDrawer;
  serviceUrl: any;
  pageSize = 10;
  pageNumber = 1;
  defaultSortColunm = 'id';
  defaultSortDirection: 'ASC' | 'DESC' = 'DESC';
  columnDefs: ColDef[];
  i18nFilter: any;
  filterValue: string;
  minimumFilterLength = 3;
  statusFilterList = [
    { value: 1, label: 'OPTION_PENDING', code: 'PENDING' },
    { value: 2, label: 'OPTION_REFUSED', code: 'REFUSED' },
    { value: 3, label: 'OPTION_ACCEPTED', code: 'ACCEPTED' },
    { value: 4, label: 'OPTION_EXPIRED', code: 'EXPIRED' },
  ];
  advancedTrackingTable: any;
  lotId: number;
  optionLotPriceId: number;
  selectedStatus: any;
  filterUrlParam: FilterEventOutput;
  queryParamMap: any;
  displayTeamButton: boolean;
  displayPartnerButton: boolean;
  isTeamMemberParam: boolean;
  isPartnerParam: boolean;
  isDossierProspectEnabled: boolean;
  columns: string[];
  textFilterSubject = new Subject<string>();
  textFilterValue: string;

  constructor(
    public readonly i18nService: I18nService,
    public readonly optionsTrackingTableService: OptionsTrackingTableService,
    private readonly appConfigService: AppConfigService,
    private readonly dialog: MatDialog,
    private readonly snackbarService: SnackbarService,
    private readonly userRoleService: UserRoleService,
    private readonly poseOptionService: PoseOptionsService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly transactionActionService: TransactionActionService,
    private readonly _route: ActivatedRoute,
    private readonly router: Router,
  ) {
    super(_route.snapshot.routeConfig.path, router);

    this.isDossierProspectEnabled = false;
  }

  ngOnInit(): void {
    this.columnDefs = [
      {
        field: 'lotNumber',
        headerName: this.i18nService._('Title_OptionsTrackingTable_Column_LotNumber'),
        minWidth: 80,
        flex: 2,
        cellRenderer: InformationRendererComponent,
        cellRendererParams: {
          picto: {
            iconName: 'informations',
            i18nTooltip: 'Title_TrackingTable_Column_LotNumber_Picto_Tooltip',
            class: 'text-sm',
          },
          action: (option: any) => {
            if (option.actions?.canShowLotDetails) {
              return this.showLotDetails(option);
            }
          },
        },
      },
      {
        field: 'optionStatusLabel',
        headerName: this.i18nService._('Txt_Table_Column_Status'),
        minWidth: 80,
        flex: 2,
        cellRenderer: StatusTagsRendererComponent,
        cellRendererParams: {
          tagsList: tagTypeByOptionStatus,
          outline: true,
        },
        valueFormatter: (params) => {
          return this.statusFilterList.find((status) => status.code === params.value)?.label ?? params.value;
        },
      },
      {
        field: 'programDetails',
        headerName: this.i18nService._('Title_OptionsTrackingTable_Column_ProgramDetails'),
        minWidth: 150,
        flex: 5,
      },
      {
        field: 'globalSellingPrice',
        headerName: this.i18nService._('Title_OptionsTrackingTable_Column_Price'),
        minWidth: 100,
        flex: 2,
        cellRenderer: PriceRendererComponent,
      },
      {
        field: 'startDate',
        headerName: this.i18nService._('Title_TrackingTable_Column_StartDate'),
        cellRenderer: DateRendererComponent,
        sort: 'desc',
        minWidth: 80,
        flex: 2,
        cellRendererParams: {
          format: 'DD/MM/yyyy HH:mm',
        },
      },
      {
        field: 'endDate',
        headerName: this.i18nService._('Title_TrackingTable_Column_EndDate'),
        cellRenderer: DateRendererComponent,
        minWidth: 80,
        flex: 2,
        cellRendererParams: {
          format: 'DD/MM/yyyy HH:mm',
        },
      },
      {
        field: 'prospectFullName',
        headerName: this.i18nService._('Title_OptionsTrackingTable_Column_Client'),
        minWidth: 100,
        flex: 4,
      },
      {
        field: 'accountFullname',
        headerName: this.i18nService._('Title_TrackingTable_Column_TakenBy'),
        minWidth: 100,
        flex: 4,
      },
      {
        field: 'companyTradingName',
        headerName: this.i18nService._('Title_OptionsTrackingTable_Column_CompanyTradingName'),
        hide: !this.userRoleService.isValo(),
      },
      {
        field: 'responsible',
        headerName: this.i18nService._('Title_ResaTrackingTable_Column_Responsible'),
        minWidth: 100,
        flex: 4,
        hide: !this.userRoleService.isValo(),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 60,
        maxWidth: 60,
        flex: 2,
        cellStyle: { paddingRight: '10px' },
        cellRenderer: ActionRendererComponent,
        hide: this.isHideActions(),
        cellRendererParams: {
          actionItems: (option: any) => {
            return this.transactionActionService.getActionItems(
              option,
              this.showLotDetails.bind(this, option),
              this.onItemUpdated.bind(this, option),
            );
          },
        },
        sortable: false,
      },
    ];

    this.featureFlagService.isEnabled('dossier-prospect').then((res) => {
      this.isDossierProspectEnabled = res;
    });

    this.columns = [
      'id',
      'optionId',
      'prospectFullName',
      'programName',
      'programDetails',
      'accountFullname',
      'responsible',
      'companyId',
      'companyTradingName',
      'lotId',
      'lotNumber',
      'globalSellingPrice',
      'startDate',
      'endDate',
      'salesRepId',
      'externalOption',
      'isAllowedToExtendOption',
      'developerMaintenance',
      'isFinancialStrategyFeesReached',
      'isFinancialStrategyLotSizeReached',
      'optionStatusLabel',
      'responsibleId',
      'accountCompanyTypeLabel',
      'isExtended',
      'externalSIRemoveOptionsUrl',
      'reservationStatusId',
      'reservationLotId',
      'reservationStatusLabel',
      'expirationDate',
      'isImportedOption',
      'actions',
    ];

    this.serviceUrl = this.optionsTrackingTableService.url;

    this.displayTeamButton = this.userRoleService.isInRoles([ValoRole.valoRcvSuper]);
    this.displayPartnerButton = this.userRoleService.isInRoles([ValoRole.valoRcvSimple]);
    this.setFilterValue();

    this.onTextFilterChange();
  }

  onItemUpdated(option: OptionsTrackingTableItem): void {
    this.gridTrackingTable.updateSingleDataRow(option.id);
  }

  isHideActions(): boolean {
    const appConfig = this.appConfigService.appConfig;
    const actionsValoRolesList = [
      appConfig.roles.valoSystem,
      appConfig.roles.valoAdminSuper,
      appConfig.roles.valoAdminSimple,
      appConfig.roles.valoRcvSuper,
      appConfig.roles.valoRcvSimple,
    ];
    if (
      this.userRoleService.isContractor() ||
      this.userRoleService.isIndependant() ||
      this.userRoleService.isDeveloper() ||
      (this.userRoleService.isValo() && this.userRoleService.isInRoles(actionsValoRolesList))
    ) {
      return false;
    }
    return true;
  }

  setFilterValue() {
    this.queryParamMap = this._route.snapshot.queryParamMap;
    this.selectedStatus = this.queryParamMap.get('OptionStatuses')?.split(',').map(Number);
    this.i18nFilter = this.selectedStatus?.length ? { OptionStatuses: this.selectedStatus } : null;
    this.filterValue = this.queryParamMap.get('filter');
    this.textFilterValue = this.queryParamMap.get('filter');

    const partner = JSON.parse(this._route.snapshot.queryParamMap.get('isPartner'));
    this.isPartnerParam = Boolean(this.displayPartnerButton && partner === null ? true : partner);
    this.isTeamMemberParam = Boolean(JSON.parse(this.queryParamMap.get('isTeamMember')));
    this.filterUrlParam = {
      isPartner: this.isPartnerParam,
      isTeamMember: this.isTeamMemberParam,
      i18nFilter: this.i18nFilter,
      filter: this.filterValue,
    };
    super.filterEvent(this.filterUrlParam);
  }

  onStatusListChange(statusList: any): void {
    this.i18nFilter = statusList?.value.length ? { OptionStatuses: statusList.value } : null;
    this.filterUrlParam.i18nFilter = this.i18nFilter;
    super.filterEvent(this.filterUrlParam);
  }

  onTextFilterChange() {
    this.textFilterSubject.pipe(debounceTime(this.textFilterDebounceTimer), distinctUntilChanged()).subscribe((value) => {
      this.filterUrlParam.filter = value;
      super.filterEvent(this.filterUrlParam);
      this.textFilterValue = value;
    });
  }

  errorUpdateOption(error: HttpErrorResponse, text: string): void {
    if (error && (error.status === 502 || error.status === 504)) {
      const message: SnackbarMessage = {
        type: SnackbarMessageType.Error,
        text,
      };
      this.snackbarService.sendMessage(message);
    }
  }

  public acceptOrRejectOption(option: OptionsTrackingTableItem, accept: boolean): void {
    this.poseOptionService.acceptOrRejectOption(option.optionId, accept).subscribe(
      (response: PoseoptionResponse) => this.responseUpdateOption.bind(this, option)(response),
      (error: HttpErrorResponse) => {
        this.errorUpdateOption(error, this.i18nService._('Error_SnackBar_UnavailableAcceptOrRejectOption'));
      },
    );
  }

  public showLotDetails(option: OptionsTrackingTableItem): void {
    this.lotId = option.lotId;
    this.optionLotPriceId = option.id;
    if (this.drawer) {
      this.drawer.open();
    }
  }

  responseUpdateOption(option: OptionsTrackingTableItem, response: PoseoptionResponse): void {
    const message: SnackbarMessage = {
      text: response.libelleRetour,
      type: SnackbarMessageType.Info,
    };
    this.snackbarService.sendMessage(message);
    this.gridTrackingTable.updateSingleDataRow(option.id);
  }

  changeTeamFilter(event: boolean): void {
    this.isTeamMemberParam = event;
    this.filterUrlParam.isTeamMember = this.isTeamMemberParam;
    super.filterEvent(this.filterUrlParam);
  }

  changePartnerFilter(event: boolean): void {
    this.isPartnerParam = event;
    this.filterUrlParam.isPartner = this.isPartnerParam;
    super.filterEvent(this.filterUrlParam);
  }
}
