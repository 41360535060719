import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';

import { ChipPopulatedAutocompleteComponent } from '../chip-populated-autocomplete/chip-populated-autocomplete.component';

import { SnackbarService } from '../../../utils/services/snackbar.service';
import { CityResponse } from '../../models/CityResponse';
import { PostalAdressFieldnameData } from '../../models/PostalAdressFieldnameData';
import { ReferenceTableData } from '../../models/ReferenceTableData';
import { I18nService } from '../../services/i18n.service';
import { PostalAddressService } from '../../services/postal-address.service';
import { ReferenceTablesService } from '../../services/reference-tables.service';
import { zipCodeValidator } from '../../validators/zipCode-validators.directive';

@Component({
  selector: 'app-postal-address',
  templateUrl: './postal-address.component.html',
  styleUrls: ['./postal-address.component.scss'],
  standalone: true,
  imports: [
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    ChipPopulatedAutocompleteComponent,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    NgFor,
    MatOptionModule,
    MatSelectModule,
    AsyncPipe,
  ],
})
export class PostalAddressComponent implements OnInit {
  zipCodeControl: FormControl;
  countryIdControl: FormControl;
  isFrenchControl: FormControl;
  addressControl: FormControl;
  cityControl: FormControl;
  cityInseeCodeControl: FormControl;
  cityInfosControl: FormControl;

  filteredZipCode: Observable<Array<ReferenceTableData>>;
  filteredCities: Observable<Array<CityResponse>>;
  countryList = new Map();
  frenchCountryId: number;
  cityList: Array<CityResponse>;

  @Input() parentForm: UntypedFormGroup;
  @Input() recoveredInfo;
  @Input() isAddress: boolean;

  /**
   * dbDuffix input, suffix for name in form
   *
   * @type {string}
   * @memberof PostalAddressComponent
   */
  @Input() dbSuffix: string;

  /**
   * fieldnameData attribute
   *
   * @type {PostalAdressFieldnameData}
   * @memberof PostalAddressComponent
   */
  public fieldnameData: PostalAdressFieldnameData;

  /**
   * Countries list
   * @type {CountryResponse[]}
   */
  countries: Array<ReferenceTableData>;
  zipCodeList: Array<ReferenceTableData>;
  minLength = 2;
  isloadCity = false;

  constructor(
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly snackbarService: SnackbarService,
    private readonly postalAdressService: PostalAddressService,
    public i18nService: I18nService,
  ) {
    this.isAddress = true;
  }

  ngOnInit(): void {
    // Add dbSuffix if exist to fieldname
    if (this.dbSuffix) {
      this.fieldnameData = {
        countryId: `${this.dbSuffix}CountryId`,
        postalCode: `${this.dbSuffix}PostalCode`,
        isFrench: `${this.dbSuffix}IsFrench`,
        address: `${this.dbSuffix}Address`,
        city: `${this.dbSuffix}City`,
        cityInseeCode: `${this.dbSuffix}CityInseeCode`,
        cityInfos: `${this.dbSuffix}CityInfos`,
      };
    } else {
      this.fieldnameData = {
        countryId: 'countryId',
        postalCode: 'postalCode',
        isFrench: 'isFrench',
        address: 'address',
        city: 'city',
        cityInseeCode: 'cityInseeCode',
        cityInfos: `${this.dbSuffix}CityInfos`,
      };
    }

    this.parentForm.addControl(this.fieldnameData.countryId, new UntypedFormControl(undefined, [Validators.required]));
    this.parentForm.addControl(this.fieldnameData.postalCode, new UntypedFormControl(undefined, [Validators.required]));
    this.parentForm.addControl(this.fieldnameData.isFrench, new UntypedFormControl(true, []));
    this.parentForm.addControl(this.fieldnameData.city, new UntypedFormControl(undefined, []));
    this.parentForm.addControl(this.fieldnameData.cityInseeCode, new UntypedFormControl(undefined, []));
    this.parentForm.addControl(this.fieldnameData.cityInfos, new UntypedFormControl(undefined, []));

    this.cityInfosControl = this.parentForm.controls[this.fieldnameData.cityInfos] as FormControl;

    this.zipCodeControl = this.parentForm.controls[this.fieldnameData.postalCode] as FormControl;
    this.isFrenchControl = this.parentForm.controls[this.fieldnameData.isFrench] as FormControl;
    this.countryIdControl = this.parentForm.controls[this.fieldnameData.countryId] as FormControl;
    this.cityControl = this.parentForm.controls[this.fieldnameData.city] as FormControl;
    this.cityInseeCodeControl = this.parentForm.controls[this.fieldnameData.cityInseeCode] as FormControl;

    if (this.isAddress) {
      this.parentForm.addControl(this.fieldnameData.address, new UntypedFormControl(undefined, [Validators.required]));
      this.addressControl = this.parentForm.controls[this.fieldnameData.address] as FormControl;
    }

    // Call reference Table zipCode;
    this.referenceTablesService.getTableReferenceInfo('Zipcodes').subscribe(
      (response) => {
        this.zipCodeList = response;
        this.filteredZipCode = this.zipCodeControl.valueChanges.pipe(
          startWith<string | ReferenceTableData>(''),
          map((value) => (typeof value === 'string' ? value : value && value.label)),
          map((label) => (label ? this._filterZipCode(label) : [].slice())),
        );
        this.zipCodeControl.setValidators(zipCodeValidator(this.zipCodeList));
        // Call reference Table countries;
        this.referenceTablesService.getTableReferenceInfoTranslated('Countries').subscribe(
          (itemsFound) => {
            itemsFound.forEach((country) => this.countryList.set(country.label, country.id));
            // we find the index position of France and we take the id
            this.frenchCountryId = this.countryList.get('France');
            this.countryIdControl.setValue(this.frenchCountryId);
            this.cityInfosControl.setValidators(Validators.required);

            this.isFrenchControl.setValue(true);

            // INIT DATA
            if (this.recoveredInfo) {
              this.cityInfosControl.clearValidators();

              this.zipCodeControl.clearValidators();
              if (this.recoveredInfo.countryId === this.frenchCountryId || this.recoveredInfo.invoiceCountryId === this.frenchCountryId) {
                this.isFrenchControl.setValue(true);
                this.postalAdressService.setIsFrenchCountry(true);
                if (this.recoveredInfo.countryId) {
                  this.countryIdControl.setValue(this.recoveredInfo.countryId);
                }
                if (this.recoveredInfo.invoiceCountryId) {
                  this.countryIdControl.setValue(this.recoveredInfo.invoiceCountryId);
                }
                // Remove zip Code Validator
                this.zipCodeControl.setValidators([zipCodeValidator(this.zipCodeList), Validators.required]);
                this.cityInfosControl.setValidators(Validators.required);

                this.zipCodeControl.setValue(this.recoveredInfo.postalCode);
                this.loadCity(this.recoveredInfo.postalCode);
              } else {
                this.recoveredInfo.country = itemsFound.find((country) => country.id === this.recoveredInfo[this.fieldnameData.countryId]);
                this.isFrenchControl.setValue(false);
                this.isFrenchControl.updateValueAndValidity();
                this.postalAdressService.setIsFrenchCountry(false);
                this.zipCodeControl.setValidators(Validators.required);
                this.zipCodeControl.setValue(this.recoveredInfo.postalCode);
                this.cityControl.setValidators(Validators.required);
              }
              /* Set postal address recovered info */

              this.countryIdControl.updateValueAndValidity();
              if (this.isAddress) {
                this.addressControl.setValue(this.recoveredInfo.address);
              }
            }
            this.cityInfosControl.updateValueAndValidity();
          },
          () => {
            this.snackbarService.sendErrorOccured();
          },
        );
      },
      () => {
        this.snackbarService.sendErrorOccured();
      },
    );
  }

  selectCountry(isFrench: boolean): void {
    if (!isFrench) {
      // Remove zip Code Validator
      this.zipCodeControl.clearValidators();
      // Add just required Validators
      this.zipCodeControl.setValidators(Validators.required);
      // Clear cityControleValidator, add required and clear value.
      this.cityControl.setValue('');
      this.cityControl.setValidators(Validators.required);
      this.countryIdControl.setValue(undefined);
      this.cityInfosControl.setValue(undefined);
      this.cityInfosControl.clearValidators();
      this.cityInfosControl.updateValueAndValidity();
      this.postalAdressService.setIsFrenchCountry(false);
    } else {
      this.countryIdControl.setValue(this.frenchCountryId);
      this.isFrenchControl.setValue(true);
      this.zipCodeControl.setValidators(zipCodeValidator(this.zipCodeList));
      this.zipCodeControl.updateValueAndValidity();
      this.cityInfosControl.setValidators(Validators.required);
      this.cityInfosControl.updateValueAndValidity();
      this.postalAdressService.setIsFrenchCountry(true);
    }
    // Clear zip
    this.zipCodeControl.setValue('');
  }

  loadCity(zipcodeValue): void {
    this.cityList = [];
    this.cityInfosControl.setValue(undefined);
    this.cityControl.setValue(undefined);
    this.cityInseeCodeControl.setValue(undefined);

    if ((this.zipCodeControl.valid || this.zipCodeControl.disabled) && this.zipCodeControl.value && this.isFrenchControl.value) {
      const zipcodeId = this.zipCodeList.find((zipCode) => zipCode.label === zipcodeValue).id as number;
      this.referenceTablesService.getFilterCity(zipcodeId).subscribe(
        (response) => {
          this.cityList = response;
          if (this.recoveredInfo) {
            this.cityInfosControl.setValue(this.cityList.find((city) => city.inseeCode === this.recoveredInfo.cityInseeCode));
            this.cityInseeCodeControl.setValue(this.recoveredInfo.cityInseeCode);
            this.cityControl.setValue(this.recoveredInfo.city);
          }
        },
        () => {
          this.snackbarService.sendErrorOccured();
        },
      );
    }
  }

  setCityLabel(city: CityResponse): void {
    this.cityControl.setValue(city.label);
    this.cityInseeCodeControl.setValue(city.inseeCode);
  }

  private _filterZipCode(label: string): Array<ReferenceTableData> {
    const filterValue = label.toLowerCase();
    // filter list just for french country
    if (label && label.length >= this.minLength && this.isFrenchControl.value) {
      return this.zipCodeList.filter((zipCodeList) => zipCodeList.label.toLowerCase().indexOf(filterValue) === 0);
    }

    return [];
  }
}
