/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})

/**
 * AppConfigService - Service to share the global configuration
 * @export
 * @class AppConfigService
 */
export class AppConfigService {
  /**
   * Creates an instance of AppConfigService.
   */
  constructor() {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.appConfig = window['appConfig'];
    AppConfigService._appConfig = this.appConfig;
    this.computDocumentType();
  }

  private static _appConfig: any = {};
  /**
   * Global configuration of the application
   * @type {*}
   */
  appConfig: any = {};

  public static getAppConfig() {
    return AppConfigService._appConfig;
  }

  /**
   * Production KeyPublic
   * @returns {boolean}
   */
  public static isDebug(): boolean {
    return environment.debug;
  }

  /**
   * Get a configuration value.
   *
   * @param {string} key
   * @param {string} arg
   * @returns {string}
   */
  public _(key: string, arg?: string): string {
    return this.getValue(key, arg) as string;
  }

  /**
   * Get a configuration value.
   *
   * @param {string} key
   * @param {string} arg
   * @returns {string}
   */
  public getValue(key: string, arg?: string): any {
    if (key in this.appConfig) {
      return arg in this.appConfig[key] ? this.appConfig[key][arg] : this.appConfig[key];
    }
    console.error('Missing key in global.js file : ', key);

    return key;
  }

  /**
   * Get the global parameter file
   * @returns {*}
   */
  public getAppConfig(): any {
    return this.appConfig;
  }

  /**
   * Production KeyPublic
   * @returns {boolean}
   */
  public isProdEnvironment(): boolean {
    return environment.production;
  }

  /**
   * getLoopbackApiUrl method
   *
   * @returns {string}
   * @memberof AppConfigService
   */
  public getLoopbackApiUrl(): string {
    return this.appConfig.loopbackApiUrl;
  }

  public getDematApiUrl(): string {
    return this.appConfig.DematApiUrl;
  }

  public getDossierProspectApiUrl(): string {
    return this.appConfig.DossierProspectApiUrl;
  }

  public getFileSystemUrl(): string {
    return this.appConfig.fileSystemUrl;
  }

  public getCompanyTypeLabels(): string {
    return this.appConfig.companyType;
  }

  public getLocale(): string {
    return this.appConfig.locale;
  }

  getPaginatorDefaultValue(): Array<number> {
    return this.appConfig.paginatorDefaultValue;
  }

  getDefaultCurrency(): string {
    return this.appConfig.currency;
  }

  getStatus(): any {
    return this.appConfig.status;
  }

  getDefaultAreaUnit(): string {
    return this.appConfig.areaUnit;
  }

  getAllowedBrowsersToOpenDocumentInNewTab(): Array<string> {
    return this.appConfig.allowedBrowsersToOpenDocumentInNewTab;
  }

  protected computDocumentType() {
    // eslint-disable-next-line no-prototype-builtins
    if (this.appConfig && this.appConfig.hasOwnProperty('documentTypeCode')) {
      const documentByCode = {};
      Object.keys(this.appConfig.documentTypeCode).forEach((key) => {
        documentByCode[this.appConfig.documentTypeCode[key]] = key;
      });

      this.appConfig.documentByCode = documentByCode;
    }
  }
}
