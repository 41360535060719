import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs';

import { SnackbarMessageType } from '../utils/models/enums/snackbar-message-type.enum';
import { Sitemap } from '../utils/models/Sitemap';
import { AccountService } from '../utils/services/account.service';
import { TokenService } from '../utils/services/authorisation/token.service';
import { I18nService } from '../utils/services/i18n.service';
import { SnackbarService } from '../utils/services/snackbar.service';

/**
 * AuthGuard class
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  /**
   * Creates an instance of AuthGuard.
   *
   * @param {Router} router
   * @param {AccountService} accountService
   * @param {I18nService} i18nService
   * @param {SnackbarService} snackbarService
   *
   * @memberof AuthGuard
   */
  constructor(
    private readonly router: Router,
    private readonly accountService: AccountService,
    private readonly i18nService: I18nService,
    private readonly snackbarService: SnackbarService,
    private readonly tokenService: TokenService,
  ) {}

  /**
   * Activate route if authorized or/and connected.
   *
   * @param {ActivatedRouteSnapshot} route
   * @returns {Observable<boolean>}
   *
   * @memberof AuthGuard
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.accountService.checkIsLoggedIn()) {
      if (route.data && route.data.roles && !route.data.roles.includes(this.tokenService.getToken().roleName)) {
        // Rights problem so snackbar error and redirection to login page with the return url.
        this.redirect('Error_SnackBar_RedirectPageNotAllowed', state, Sitemap.utils.homepage.path);

        return of(false);
      }

      return of(true);
    }

    this.tokenService.deleteToken();
    if (
      // eslint-disable-next-line no-prototype-builtins
      route.data && route.data.hasOwnProperty('snackbarIfNotConnected') ? route.data.snackbarIfNotConnected : true
    ) {
      this.redirect('Error_SnackBar_RedirectNotConnected', state, Sitemap.utils.login.path);
    } else {
      this.router.navigate([Sitemap.utils.login.path]);
    }

    return of(false);
  }

  /**
   * Send message and redirect.
   *
   * @param message
   */
  redirect(message: string, state: RouterStateSnapshot, url: string): void {
    this.snackbarService.sendMessage({
      text: this.i18nService._(message),
      type: SnackbarMessageType.Error,
    });
    this.router.navigate([url], {
      queryParams: { returnUrl: state.url },
    });
  }
}
