import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { I18nService } from '../../../utils/services/i18n.service';
import { SearchFormService } from '../../services/search-form.service';

@Component({
  selector: 'app-search-form-buttons',
  templateUrl: './search-form-buttons.component.html',
  styleUrls: ['./search-form-buttons.component.scss'],
  standalone: true,
  imports: [MatButtonModule],
})
export class SearchFormButtonsComponent implements OnInit {
  /**
   *
   * Input to disable edit search button
   * @type {boolean}
   * @memberof SearchFormButtonsComponent
   */
  @Input() enableEditSearch = false;

  /**
   * Emitter for search button click
   *
   * @memberof SearchFormButtonsComponent
   */
  @Output() readonly searchClick = new EventEmitter();

  /**
   * Emitter for reset button click
   *
   * @memberof SearchFormButtonsComponent
   */
  @Output() readonly resetClick = new EventEmitter();

  /**
   * Emitter for save search button click
   *
   * @memberof SearchFormButtonsComponent
   */
  @Output() readonly saveSearchClick = new EventEmitter();

  /**
   * Emitter for edit search button click
   *
   * @memberof SearchFormButtonsComponent
   */
  @Output() readonly editSearchClick = new EventEmitter();

  public isSavedSearch: boolean;

  constructor(
    public i18nService: I18nService,
    private readonly searchFromService: SearchFormService,
  ) {}

  ngOnInit(): void {
    this.isSavedSearch = Boolean(this.searchFromService.getSavedSearchId());
  }
}
