import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

import { StepThreeSpecialOfferFormComponent } from '../step-three-special-offer-form/step-three-special-offer-form.component';
import { StepTwoSpecialOfferFormComponent } from '../step-two-special-offer-form/step-two-special-offer-form.component';
import { StepOneSpecialOfferFormComponent } from '../step-one-special-offer-form/step-one-special-offer-form.component';

import { AbstractAppBack } from '../../../utils/autres/abstract-app-back';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { Sitemap } from '../../../utils/models/Sitemap';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { FormUtilsService } from '../../../utils/services/form/form-utils.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { SpinnerWithBackdropService } from '../../../utils/services/spinner-with-backdrop.service';
import { UserRoleService } from '../../../utils/services/user-role.service';
import { SpecialOffer } from '../../model/special-offer';
import { SPECIAL_OFFER_CONTROL_PATH, SpecialOfferService } from '../../service/special-offer.service';
import { MatVerticalStepperScrollerDirective } from '../../../utils/directives/mat-vertical-stepper-scroller.directive';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';

@Component({
  selector: 'app-register-special-offer',
  templateUrl: './register-special-offer.component.html',
  styleUrls: ['./register-special-offer.component.scss'],
  standalone: true,
  imports: [
    StickyHeaderFormComponent,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatVerticalStepperScrollerDirective,
    StepOneSpecialOfferFormComponent,
    MatButtonModule,
    NgClass,
    NgIf,
    MatFormFieldModule,
    StepTwoSpecialOfferFormComponent,
    StepThreeSpecialOfferFormComponent,
  ],
})
export class RegisterSpecialOfferComponent extends AbstractAppBack implements OnInit {
  public title: string;
  public textButton: string;
  public specialOfferForm: UntypedFormGroup = new UntypedFormGroup({});
  /**
   * matStepper child attribute
   *
   * @type {MatStepper}
   * @memberof RegisterFormProspectComponent
   */
  @ViewChild('stepper') public matStepper: MatStepper;
  stepOneSubmit = false;
  stepTwoSubmit: boolean;
  stepThreeSubmit: boolean;
  stepTwoEmptyData: boolean;
  specialOfferId: number;
  specialOffer: SpecialOffer;
  disabledForm: boolean;
  private _routeSnapshot: ActivatedRouteSnapshot;

  constructor(
    public readonly i18nService: I18nService,
    private readonly _route: ActivatedRoute,
    private readonly _specialOfferService: SpecialOfferService,
    private readonly snackbarService: SnackbarService,
    private readonly router: Router,
    private readonly spinnerWithBackdropService: SpinnerWithBackdropService,
    private readonly _userRoleService: UserRoleService,
  ) {
    super();
    this.goBackPath = Sitemap.specialOffer.admin.path;
  }

  ngOnInit(): void {
    this._routeSnapshot = this._route.snapshot;
    this.specialOfferId = Number(this._routeSnapshot.paramMap.get('specialOfferId'));
    this.title = this.specialOfferId ? 'Title_specialOffer_edit' : 'Title_specialOffer_creation';
    this.textButton = this.specialOfferId ? 'Txt_Button_SubmitModify' : 'Txt_Button_SubmitCreate';
    this.specialOfferForm = this._specialOfferService.generateForm();
    if (this.specialOfferId) {
      this._specialOfferService.findSpecialOffer(this.specialOfferId).subscribe((specialOffer) => {
        this.stepOneSubmit = true;
        this.stepTwoSubmit = true;
        this.specialOffer = specialOffer;
        this._specialOfferService.setDataToForm(specialOffer, this.specialOfferForm);
        if (this.specialOffer.isImported || this._userRoleService.isInRoles(this._routeSnapshot.data.readonlyRoles)) {
          this._specialOfferService.disableForm(this.specialOfferForm);
          if (this._userRoleService.isInRoles(this._routeSnapshot.data.readonlyRoles)) {
            this.disabledForm = true;
            FormUtilsService.disableControl(SPECIAL_OFFER_CONTROL_PATH.amendmentWithFusionFields, this.specialOfferForm, true);
            FormUtilsService.setFormValue(SPECIAL_OFFER_CONTROL_PATH.programFinished, this.specialOfferForm, this.disabledForm);
          }
        }
      });
    }
  }

  submitStepOne(): void {
    this.stepOneSubmit = true;
    this.nextStep('conditions');
  }

  submitStepTwo(): void {
    this.stepTwoSubmit = true;
    this.nextStep('programs');
  }

  onSubmit(): void {
    this.spinnerWithBackdropService.show();

    const handleCreateOrUpdateReservation = (specialOfferAction) => [
      () => {
        this.spinnerWithBackdropService.hide();
        // Show snackbar specialOffer updated/created
        const message: SnackbarMessage = {
          text: this.i18nService._(`Success_SnackBar_${specialOfferAction}`),
          type: SnackbarMessageType.Info,
        };
        this.snackbarService.sendMessage(message);

        this.router.navigate([Sitemap.specialOffer.admin.path]);
      },
      () => {
        this.spinnerWithBackdropService.hide();
        const message: SnackbarMessage = {
          text: this.i18nService._('Error_SnackBar_ErrorOccuredRetry'),
          type: SnackbarMessageType.Error,
        };
        this.snackbarService.sendMessage(message);
      },
    ];

    if (this.specialOfferId) {
      this._specialOfferService
        .update(this.specialOfferForm, this.specialOfferId)
        .subscribe(...handleCreateOrUpdateReservation('SpecialOfferUpdated'));
    } else {
      this._specialOfferService.create(this.specialOfferForm).subscribe(...handleCreateOrUpdateReservation('SpecialOfferCreated'));
    }
  }

  selectionChange($event: StepperSelectionEvent): void {
    const previousState: string = $event.previouslySelectedStep.state;
    const currentState: string = $event.selectedStep.state;

    if (currentState === 'lots') {
      FormUtilsService.setFormValue(SPECIAL_OFFER_CONTROL_PATH.programFinished, this.specialOfferForm, true);
    }
    if (previousState === 'conditions') {
      this.stepOneSubmit = true;
      if (currentState === 'lots') {
        this.stepTwoSubmit = true;
      }

      return;
    }
    if (previousState === 'programs') {
      this.stepTwoSubmit = currentState !== 'conditions';

      return;
    }
    if (previousState === 'lots') {
      this.stepTwoSubmit = currentState === 'programs';
      if (currentState === 'conditions' || currentState === 'programs') {
        this.stepTwoSubmit = false;
        FormUtilsService.setFormValue(SPECIAL_OFFER_CONTROL_PATH.programFinished, this.specialOfferForm, false);
      }

      return;
    }
  }

  emptyData($event: boolean): void {
    this.stepTwoEmptyData = $event;
  }

  private nextStep(form: string): void {
    let goNextStep = false;
    if (this.disabledForm) {
      goNextStep = true;
    } else {
      this.specialOfferForm.get(form).markAllAsTouched();
      if (this.specialOfferForm.get(form).valid) {
        goNextStep = true;
      }
    }

    if (goNextStep) {
      if (form === 'programs') {
        FormUtilsService.setFormValue(SPECIAL_OFFER_CONTROL_PATH.programFinished, this.specialOfferForm, true);
      }
      this.matStepper.selected.completed = true;
      this.matStepper.next();
    }
  }
}
