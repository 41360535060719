import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';

import { DataLayerApiService } from './data-layer-api.service';
import { ProgramTypeResponse } from '../utils/models/ProgramTypeResponse';

@Injectable({ providedIn: 'root' })
export class ProgramTypeApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Get programTypes
   *
   * @returns {ProgramTypeResponse[]}
   * @memberof DataLayerApiService
   */
  async getProgramTypes(): Promise<ProgramTypeResponse[]> {
    const path = this.BASE_URL + 'ProgramTypes';
    const res: Observable<ProgramTypeResponse[]> = await this.get(path);
    return await lastValueFrom(res);
  }
}
