import { DescriptionProgramResponse } from './DescriptionProgramResponse';
import { DocumentResponse } from './DocumentResponse';
import { LotAreaResponse } from './LotAreaResponse';
import { LotHasTaxations } from './LotHasTaxations';
import { LotsOrientationResponse } from './LotsOrientationResponse';
import { MandatStrategyResponse } from './MandatStrategyResponse';
import { OptionInformation } from './OptionInformation';
import { PinelPlsLmnPLmpNonGereTaxationResponse } from './PinelPlsLmnPLmpNonGereTaxationResponse';
import { ProgramResponse } from './ProgramResponse';
import { ProgramTypeResponse } from './ProgramTypeResponse';
import { SecondaryLotResponse } from './SecondaryLotResponse';
import { SpecialOfferResponse } from './SpecialOfferResponse';
import { TaxationResponse } from './TaxationResponse';
import { OptionLotPriceResponse } from './OptionLotPriceResponse';
import { ReservationResponse } from './ReservationResponse';

export class LotResponse {
  id: number;
  created: Date;
  modified: Date;
  attorneyFees: number;
  commercialLotSellingPriceIT: number;
  deliveryDate: Date;
  estatePriceET: number;
  estimatedProfitability: number;
  estmatedMonthlyRentingPrice: number;
  // LMNP NON GERE :
  estimatedFurnishedMarketRent: number;
  estimatedFurnishedMarketYield: number;
  // MALRAUX ou DEFICIT_FONCIER ou MONUMENTS_HISTORIQUES
  estimatedWorkPrice: number;
  estimatedLandPrice: number;
  // ---------------
  // NUE PROPRIETE :
  nueProprietePrice: number;
  usufruitValue: number;
  distributionKey: string;
  nueProprieteDuration: number;
  // ---------------
  floor: number;
  guaranteedMonthlyRentingPriceIT: number;
  housingPriceET: number;
  isPublished: boolean;
  livingSpace: number;
  loanFees: number;
  lotNumber: string;
  lotRef: string;
  monthlyRentingPriceET: number;
  profitabilityET: number;
  programId: number;
  programTypeId: number;
  lotOrientationId: number;
  reducedTotalSellingPriceIT: number;
  rooms: number;
  sellingFees: number;
  tangiblePriceET: number;
  globalSellingPriceET: number;
  monthlyRentalLotPriceIT: number;
  monthlyRentalSecondaryLotPriceIT: number;
  option: string;
  lotHasTaxations: Array<LotHasTaxations>;
  taxations: Array<TaxationResponse>;
  lotOrientation: LotsOrientationResponse;
  program?: ProgramResponse;
  mandatStrategy?: MandatStrategyResponse;
  mandatStrategyId?: number;
  isReducedTaxation?: boolean;
  vatPercent?: string;
  secondaryLots?: Array<SecondaryLotResponse>;
  secondaryLotPriceIncluded: boolean;
  programType: ProgramTypeResponse;
  lotAreas: Array<LotAreaResponse>;
  descriptionProgram?: DescriptionProgramResponse;
  documents?: Array<DocumentResponse>;
  fees: number;
  pinelTaxation: PinelPlsLmnPLmpNonGereTaxationResponse;
  plsTaxation: PinelPlsLmnPLmpNonGereTaxationResponse;
  lmnpLmpNonGereTaxation: PinelPlsLmnPLmpNonGereTaxationResponse;
  investmentProfitability?: number;
  investmentRentingPrice?: number;
  optionInformation?: OptionInformation;
  lmnpLmpProfitabilityET: number;
  status?: string;
  switchPublicationLotStateAutorized?: boolean;
  isImported?: boolean;
  lotTypeLabel?: string;
  isValorissimoExclusivity?: boolean;
  price?: number;
  isFeesHidden?: boolean;
  specialOffer?: Array<SpecialOfferResponse>;
  isComplete: boolean;
  isPublishedProgram?: boolean;
  contractorFeesAmount?: number;
  contractorFees?: number;
  optionsLotPrice?: Array<OptionLotPriceResponse>;
  reservations?: Array<ReservationResponse>;
}
