import { Router } from '@angular/router';
import { isNil } from 'lodash-es';

import { FilterEventOutput } from './models/filter/filter-event-output';

export class AbstractTrackingTable {
  private _path: string;
  private readonly _router: Router;
  public textFilterDebounceTimer = 400;

  constructor(path: string, router: Router) {
    this._path = path;
    this._router = router;
  }

  set path(path) {
    this._path = path;
  }

  get path(): string {
    return this._path;
  }

  filterEvent($event: FilterEventOutput): void {
    const queryParams = this.processQueryParams($event);
    this._router.navigate([this.path], {
      queryParamsHandling: '',
      queryParams,
      replaceUrl: true,
    });
  }

  public processQueryParams($event: FilterEventOutput) {
    if (!$event) {
      return undefined;
    }
    const queryParams: Record<string, unknown> = {};
    if ($event.filter) {
      queryParams.filter = $event.filter;
    }
    if ($event.i18nFilter) {
      Object.keys($event.i18nFilter).forEach((key) => {
        if ($event.i18nFilter[key]) {
          if ($event.i18nFilter[key] instanceof Array) {
            queryParams[key] = $event.i18nFilter[key].join(',');
          } else {
            queryParams[key] = $event.i18nFilter[key];
          }
        }
      });
    }
    if ($event.selfFilter) {
      Object.keys($event.selfFilter).forEach((key) => {
        if ($event.selfFilter[key]) {
          if ($event.i18nFilter[key] instanceof Array) {
            queryParams[key] = $event.i18nFilter[key].join(',');
          } else {
            queryParams[key] = $event.i18nFilter[key];
          }
        }
      });
    }
    if (!isNil($event.isTeamMember)) {
      queryParams.isTeamMember = $event.isTeamMember ? 1 : 0;
    }

    if (!isNil($event.isPartner)) {
      queryParams.isPartner = $event.isPartner ? 1 : 0;
    }

    if (!isNil($event.statusFilter)) {
      if (Array.isArray($event.statusFilter) && $event.statusFilter.length > 0) {
        queryParams.statusFilter = $event.statusFilter.join(',');
      } else {
        queryParams.statusFilter = $event.statusFilter;
      }
    }
    return queryParams;
  }
}
