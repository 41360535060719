<ng-container *ngIf="modalIsFullyLoaded">
  <div mat-dialog-title class="search-filter-dialog-title relative leading-3">
    <div class="title">
      <div>{{ i18nService._('Txt_Button_Advanced_Search_Hide') }}</div>
      <mat-icon class="material-icons-outlined close-panel-icon size-18 txt-disable" (click)="closeDialog()">close</mat-icon>
    </div>
    <app-search-filter-reinit class="text-sm txt-disable" [iconClass]="'size-18'"></app-search-filter-reinit>
  </div>
  <mat-dialog-content>
    <app-search-filter-content></app-search-filter-content>
  </mat-dialog-content>
  <mat-dialog-actions class="search-action" (click)="launchSearch()" data-cy="search-submit-button">
    <mat-icon>search</mat-icon>
    <div>{{ i18nService._('Txt_Placeholder_Search') }}</div>
  </mat-dialog-actions>
</ng-container>
