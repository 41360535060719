import { IsArray, IsObject } from 'class-validator';

import { StatusInsertPaternitiesResponse } from './status-insert-paternities.response.dto';
export class PaternityCreateResponse {
  @IsArray()
  paternitiesIds: number[];

  @IsObject()
  statusInsertPaternities: StatusInsertPaternitiesResponse;
}
