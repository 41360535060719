<div class="flex flex-col">
  <div *ngFor="let taxation of taxationTypesData" class="flex flex-row">
    <mat-checkbox
      attr.data-cy="{{ taxation.label }}"
      color="primary"
      [formControl]="taxation.checkBoxControl"
      [name]="taxation.label"
      [disabled]="hTaxationsDisabled[taxation.label]"
    >
      {{ i18nService._(taxation.label) }}
    </mat-checkbox>
  </div>
  <mat-error
    *ngIf="isSubmit && parentForm.get('lotHasTaxations').errors && parentForm.get('lotHasTaxations').errors.tooManyCheckedReducedTaxation"
  >
    {{ i18nService._('Error_Field_TooManyCheckedReducedTaxation') }}
  </mat-error>
  <mat-error *ngIf="isSubmit && parentForm.get('lotHasTaxations').errors && parentForm.get('lotHasTaxations').errors.noChecked">
    {{ i18nService._('Error_Field_Required') }}
  </mat-error>
</div>

<div class="grid grid-cols-2 gap-4 mb-2 mt-4">
  <!-- Input Commercial Lot Selling Price IT -->
  <mat-form-field class="col-span-1">
    <mat-label
      >{{
        hasLmnpLmpTaxation
          ? i18nService._('Txt_Placeholder_CommercialLotSellingPriceIT_lmnp')
          : hasNueProprieteTaxation
          ? i18nService._('Txt_Placeholder_CommercialLotSellingPriceIT_NuePropriete')
          : hasOtherTaxation
          ? i18nService._('Txt_Placeholder_CommercialLotSellingPriceIT_other_taxation')
          : i18nService._('Txt_Placeholder_CommercialLotSellingPriceIT')
      }}
    </mat-label>
    <input
      appDecimalMask
      maxNumberDecimals="2"
      maxNumberDigitsBeforeSeparator="8"
      class="text-right"
      suffix=" €"
      name="commercialLotSellingPriceIT"
      [formControl]="parentForm.get('commercialLotSellingPriceIT')"
      matInput
      data-cy="commercialLotSellingPriceIT"
      (blur)="checkCommercialPriceTTC($event)"
      (change)="setDistributionKey()"
      placeholder="0,00"
      required
    />
    <span class="icon-info txt-gray pr-2" matSuffix matTooltip="{{ i18nService._('Txt_Tooltip_CommercialLotSellingPriceIT') }}"></span>
    <mat-error
      *ngIf="
        parentForm.get('commercialLotSellingPriceIT').errors &&
        (parentForm.get('commercialLotSellingPriceIT').errors.min || parentForm.get('commercialLotSellingPriceIT').errors.max)
      "
    >
      {{ i18nService._('Info_SnackBar_LotCommercialPriceTTC') }}
    </mat-error>
    <mat-error
      *ngIf="parentForm.get('commercialLotSellingPriceIT').errors && parentForm.get('commercialLotSellingPriceIT').errors.required"
    >
      {{ i18nService._('Error_Field_Required') }}
    </mat-error>
  </mat-form-field>
  <!-- Input VAT Rate -->
  <mat-form-field class="col-span-1" *ngIf="!hasOtherTaxation">
    <mat-label>{{ i18nService._('Txt_Placeholder_VATRate') }}</mat-label>
    <input
      appDecimalMask
      maxNumberDigitsBeforeSeparator="3"
      class="text-right"
      name="vatRate"
      [formControl]="parentForm.get('vatRate')"
      matInput
      data-cy="vatRate"
      maxlength="6"
      [readonly]="true"
      required
    />
    <span class="text-black px-2" matSuffix>%</span>
    <mat-error *ngIf="parentForm.get('vatRate').errors && (parentForm.get('vatRate').errors.min || parentForm.get('vatRate').errors.max)">
      {{ i18nService._('Error_Field_ValueBetween', ['0', '100']) }}
    </mat-error>
    <mat-error *ngIf="parentForm.get('vatRate').errors && parentForm.get('vatRate').errors.required">
      {{ i18nService._('Error_Field_Required') }}
    </mat-error>
  </mat-form-field>
</div>

<!-- Inverstor taxations -->
<div *ngIf="hasInvestorTaxation">
  <hr class="w-5 my-12" />

  <div class="text-base mb-4 font-bold">
    {{ i18nService._('Txt_Page_Form_RegisterProgram_LotInvestorTaxationSection') }}
  </div>

  <div class="grid grid-cols-2 gap-4 mb-2">
    <!-- Input Estimated Market Renting Price -->
    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_EstmatedMonthlyRentingPrice') }}</mat-label>
      <input
        appDecimalMask
        class="text-right"
        name="estmatedMonthlyRentingPrice"
        [formControl]="parentForm.get('estmatedMonthlyRentingPrice')"
        matInput
        data-cy="estimatedMonthlyRentingPrice"
      />
      <span class="text-black px-2" matSuffix>€</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
    <!-- Input Estimated Market Profitability Rate -->
    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_EstimatedProfitability') }}</mat-label>
      <input
        appDecimalMask
        maxNumberDigitsBeforeSeparator="3"
        class="text-right"
        name="estimatedProfitability"
        [formControl]="parentForm.get('estimatedProfitability')"
        matInput
        data-cy="estimatedProfitability"
        maxlength="6"
      />
      <span class="text-black px-2" matSuffix>%</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
  </div>
</div>

<div *ngIf="hasLmnpLmpTaxation === false">
  <div class="grid grid-cols-2 gap-4 mb-2">
    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_EstimatedFurnishedMarketRent') }}</mat-label>
      <input
        appDecimalMask
        type="text"
        name="estimatedFurnishedMarketRent"
        class="text-right"
        [formControl]="parentForm.get('estimatedFurnishedMarketRent')"
        matInput
      />
      <span class="text-black px-2" matSuffix>€</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>

    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_estimatedFurnishedMarketYield') }}</mat-label>
      <input
        appDecimalMask
        type="text"
        name="estimatedFurnishedMarketYield"
        class="text-right"
        [formControl]="parentForm.get('estimatedFurnishedMarketYield')"
        matInput
      />
      <span class="text-black px-2" matSuffix>%</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
  </div>
</div>
<!-- LMNP/LMP taxations -->
<div *ngIf="hasLmnpLmpTaxation">
  <hr class="w-5 my-12" />

  <div class="text-base mb-4 font-bold">
    {{ i18nService._('Txt_Page_Form_RegisterProgram_LotLmnpLmpTaxationSection') }}
  </div>

  <!-- Input Guaranteed Monthly Renting Price IT -->
  <div class="flex flex-row justify-start items-center mt-2 mb-2">
    <mat-form-field class="flex w-1/2">
      <mat-label>{{ i18nService._('Txt_Placeholder_GuaranteedMonthlyRentingPriceIT') }}</mat-label>
      <input
        appDecimalMask
        class="text-right"
        name="guaranteedMonthlyRentingPriceIT"
        [formControl]="parentForm.get('guaranteedMonthlyRentingPriceIT')"
        matInput
        required
      />
      <span class="text-black px-2" matSuffix>€</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
  </div>

  <div class="grid grid-cols-2 gap-4 mb-2">
    <!-- Input Estate Price ET -->
    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_EstatePriceET') }}</mat-label>
      <input
        appDecimalMask
        type="text"
        name="estatePriceET"
        class="text-right"
        [formControl]="parentForm.get('estatePriceET')"
        matInput
        required
      />
      <span class="text-black px-2" matSuffix>€</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
    <!-- Input Housing Price ET | PRIX IMMO HT -->
    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_HousingPriceET') }}</mat-label>
      <input
        appDecimalMask
        maxNumberDecimals="2"
        maxNumberDigitsBeforeSeparator="8"
        class="text-right"
        [formControl]="parentForm.get('housingPriceET')"
        matInput
        required
      />
      <span class="text-black px-2" matSuffix>€</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
  </div>

  <div class="grid grid-cols-2 gap-4 mb-2">
    <!-- Input Reduced Selling Price IT -->
    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_GlobalSellingPriceET') }}</mat-label>
      <input
        appDecimalMask
        type="text"
        name="globalSellingPriceET"
        [formControl]="parentForm.get('globalSellingPriceET')"
        class="text-right"
        matInput
        required
      />
      <span class="text-black px-2" matSuffix>€</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
    <!-- Input Tangible Price ET -->
    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_TangiblePriceET') }}</mat-label>
      <input
        appDecimalMask
        type="text"
        name="tangiblePriceET"
        [formControl]="parentForm.get('tangiblePriceET')"
        class="text-right"
        matInput
        required
      />
      <span class="text-black px-2" matSuffix>€</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
  </div>

  <div class="grid grid-cols-2 gap-4 mb-2">
    <!-- Input Selling Fees -->
    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_SellingFees') }}</mat-label>
      <input appDecimalMask type="text" name="sellingFees" [formControl]="parentForm.get('sellingFees')" class="text-right" matInput />
      <span class="text-black px-2" matSuffix>€</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
    <!-- Input Loan Fees -->
    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_LoanFees') }}</mat-label>
      <input appDecimalMask type="text" name="loanFees" [formControl]="parentForm.get('loanFees')" class="text-right" matInput />
      <span class="text-black px-2" matSuffix>€</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
  </div>

  <div class="grid grid-cols-2 gap-4 mb-2">
    <!-- Input Attorney Fees -->
    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_AttorneyFees') }}</mat-label>
      <input appDecimalMask type="text" name="attorneyFees" [formControl]="parentForm.get('attorneyFees')" class="text-right" matInput />
      <span class="text-black px-2" matSuffix>€</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
    <!-- Input Monthly Renting Price ET -->
    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_MonthlyRentingPriceET') }}</mat-label>
      <input
        appDecimalMask
        type="text"
        name="monthlyRentingPriceET"
        class="text-right"
        [formControl]="parentForm.get('monthlyRentingPriceET')"
        matInput
      />
      <span class="text-black px-2" matSuffix>€</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
  </div>

  <div class="grid grid-cols-2 gap-4 mb-2">
    <!-- Input Monthly Rental Price IT Main Lot -->
    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_MonthlyRentalLotPriceIT') }}</mat-label>
      <input
        appDecimalMask
        type="text"
        name="monthlyRentalLotPriceIT"
        [formControl]="parentForm.get('monthlyRentalLotPriceIT')"
        class="text-right"
        matInput
      />
      <span class="text-black px-2" matSuffix>€</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
    <!-- Input Monthly Rental Price IT Secondary Lot -->
    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_MonthlyRentalSecondaryLotPriceIT') }}</mat-label>
      <input
        appDecimalMask
        type="text"
        name="monthlyRentalSecondaryLotPriceIT"
        [formControl]="parentForm.get('monthlyRentalSecondaryLotPriceIT')"
        class="text-right"
        matInput
      />
      <span class="text-black px-2" matSuffix>€</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
  </div>
</div>

<!-- Malraux or Deficit Foncier or Monuments historiques taxations -->
<div *ngIf="hasOtherTaxation">
  <hr class="w-5 my-12" />

  <div *ngIf="hasMonumentsHistoriquesTaxation" class="text-base mb-4 font-bold">
    {{ i18nService._('Txt_Page_Form_RegisterProgram_LotMonumentsHistoriquesTaxationSection') }}
  </div>

  <div *ngIf="hasMalrauxTaxation" class="text-base mb-4 font-bold">
    {{ i18nService._('Txt_Page_Form_RegisterProgram_LotMalrauxTaxationSection') }}
  </div>

  <div *ngIf="hasDeficitFoncierTaxation" class="text-base mb-4 font-bold">
    {{ i18nService._('Txt_Page_Form_RegisterProgram_LotDeficitFoncierTaxationSection') }}
  </div>

  <div class="grid grid-cols-2 gap-4 mb-2">
    <!-- Input Work Price ET -->
    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_EstimatedWorkPrice') }}</mat-label>
      <input
        appDecimalMask
        maxNumberDecimals="0"
        maxNumberDigitsBeforeSeparator="8"
        name="estimatedWorkPrice"
        [formControl]="parentForm.get('estimatedWorkPrice')"
        class="text-right"
        matInput
        required
      />
      <span class="text-black px-2" matSuffix>€</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>

    <!-- Input Land Price ET -->
    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_EstimatedLandPrice') }}</mat-label>
      <input
        appDecimalMask
        maxNumberDecimals="0"
        maxNumberDigitsBeforeSeparator="8"
        name="estimatedLandPrice"
        [formControl]="parentForm.get('estimatedLandPrice')"
        class="text-right"
        matInput
        required
      />
      <span class="text-black px-2" matSuffix>€</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
  </div>
</div>

<!-- Nue Propriete Taxation -->
<div *ngIf="hasNueProprieteTaxation">
  <hr class="w-5 my-12" />
  <div class="text-base mb-4 font-bold">
    {{ i18nService._('Txt_Page_Form_RegisterProgram_LotNueProprieteTaxationSection') }}
  </div>

  <div class="grid grid-cols-2 gap-4 mb-2">
    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_UsufruitValue') }}</mat-label>
      <input
        appDecimalMask
        type="text"
        name="usufruitValue"
        class="text-right"
        [formControl]="parentForm.get('usufruitValue')"
        matInput
        (change)="setDistributionKey()"
      />
      <span class="text-black px-2" matSuffix>€</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>

    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_DistributionKey') }}</mat-label>
      <input
        type="text"
        name="distributionKey"
        class="text-right"
        [formControl]="parentForm.get('distributionKey')"
        matInput
        [readonly]="true"
      />
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
  </div>

  <div class="grid grid-cols-2 gap-4 mb-2">
    <mat-form-field class="col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_NueProprieteDuration') }}</mat-label>
      <input
        appDecimalMask
        type="text"
        name="nueProprieteDuration"
        class="text-right"
        [formControl]="parentForm.get('nueProprieteDuration')"
        matInput
      />
      <span class="text-black px-2" matSuffix>{{ parentForm.get('nueProprieteDuration').value === 1 ? 'an' : 'ans' }}</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
  </div>
</div>
