<mat-tab-group *ngIf="programText && !canExplodeMatTabs" dynamicHeight>
  <mat-tab
    *ngIf="(programText.description && programText.description.length) || (programText.longDescrValo && programText.longDescrValo.length)"
  >
    <ng-template mat-tab-label>{{ i18nService._('Title_Description') }}</ng-template>
    <div [innerHTML]="(programText.description ? programText?.description : programText?.longDescrValo) | safe: 'html'"></div>
  </mat-tab>

  <mat-tab *ngIf="programText.services && programText.services.length">
    <ng-template mat-tab-label>{{ i18nService._('Title_CommercialServices') }}</ng-template>
    <div class="program-description-services" [innerHTML]="programText.services | safe: 'html'"></div>
  </mat-tab>

  <mat-tab *ngIf="programText.quality && programText.quality.length">
    <ng-template mat-tab-label>{{ i18nService._('Title_Qualities') }}</ng-template>
    <div [innerHTML]="programText.quality | safe: 'html'"></div>
  </mat-tab>
</mat-tab-group>

<ng-container *ngIf="programText && canExplodeMatTabs">
  <ng-container
    *ngIf="(programText.description && programText.description.length) || (programText.longDescrValo && programText.longDescrValo.length)"
  >
    <span class="app-program-page-description-title">{{ i18nService._('Title_Description') }}</span>
    <div
      class="app-program-page-description-content"
      [innerHTML]="(programText.description ? programText?.description : programText?.longDescrValo) | safe: 'html'"
    ></div>
  </ng-container>

  <ng-container *ngIf="programText.services && programText.services.length">
    <span class="app-program-page-description-title">{{ i18nService._('Title_CommercialServices') }}</span>
    <div class="app-program-page-description-content program-description-services" [innerHTML]="programText.services | safe: 'html'"></div>
  </ng-container>

  <ng-container *ngIf="programText.quality && programText.quality.length">
    <span class="app-program-page-description-title">{{ i18nService._('Title_Qualities') }}</span>
    <div class="app-program-page-description-content" [innerHTML]="programText.quality | safe: 'html'"></div>
  </ng-container>
</ng-container>
