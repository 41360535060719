<div class="grid grid-cols-2 gap-6 w-full">
  <div class="flex flex-col relative">
    <div class="mb-2">{{ field.props.postalCode.label }}<small *ngIf="field.props.displayOptionalLabel"> (facultatif)</small></div>
    <p-inputNumber
      [formControl]="$any(localizationForm.get('postalCode'))"
      [required]="field.props.postalCode.required"
      [readonly]="field.props.postalCode.readonly"
      [disabled]="field.props.postalCode.disabled"
      mode="decimal"
      inputId="withoutgrouping"
      [useGrouping]="false"
    ></p-inputNumber>
  </div>
  <div class="flex flex-col relative">
    <div class="mb-2">{{ field?.props?.city.label }}<small *ngIf="field.props.displayOptionalLabel"> (facultatif)</small></div>
    <p-dropdown
      (onChange)="setFormlyFormValue()"
      [dropdownIcon]="'material-symbols-outlined arrow-drop-down'"
      [formControl]="$any(localizationForm.get('city'))"
      [(ngModel)]="selectedCity"
      [options]="cities"
      [placeholder]="field.props.city.placeholder"
      [required]="field.props.city.required"
      [readonly]="field.props.city.readonly"
      [disabled]="field.props.city.disabled"
      [showClear]="true"
      [autoDisplayFirst]="false"
      class="border-transparent rounded-10"
      optionLabel="label"
    >
    </p-dropdown>
  </div>
</div>
