<app-small-stepper #stepper>
  <ng-container *ngFor="let step of field.fieldGroup; let index = index">
    <app-small-stepper-step *ngIf="!step.hide">
      <div class="flex w-full flex-col gap-8 xl:gap-10 h-full text-grey-800 max-w-2xl mx-auto">
        <div *ngIf="step.props.headerTitle" class="bg-white rounded-10 px-8 py-6">
          <p class="text-3xl text-black font-bold">{{ step.props.headerTitle }}</p>
        </div>

        <div class="rounded-10" [ngClass]="{ 'bg-white': !step.props.transparentBg }">
          <div *ngIf="step.props.formTitle" class="flex items-center border-b-2 border-b-grey-background border-solid h-full px-8 py-4">
            <span
              *ngIf="step.props.headIcon"
              class="inline-flex justify-center items-center bg-grey-background rounded-full w-12 h-12 mr-2"
            >
              <app-icon [name]="step.props.headIcon" class="w-5 h-5 text-grey-dark"></app-icon>
            </span>
            <span class="text-xl font-bold gap-2">{{ i18nService._(step.props.formTitle) }}</span>
          </div>
          <div class="px-10 pt-8 pb-12">
            <formly-field [field]="step"></formly-field>
          </div>
        </div>

        <div class="flex flex-1 items-center justify-between">
          <button (click)="index === 0 ? goBack() : stepper.previous()" class="rounded-button-grey" type="button">
            <span>{{ i18nService._('txt_stepper_previous') }}</span>
          </button>
          <div class="flex items-center flex-col">
            <p class="text-grey-text uppercase text-xs font-bold">
              {{ i18nService._('txt_stepper_step', [stepper.selectedIndex + 1, stepper.steps.length]) }}
            </p>
            <div class="overflow-hidden rounded-2xl w-28 h-2 relative bg-white">
              <div
                class="absolute w-[105%] h-full rounded-2xl bg-purple-primary"
                [style.right.%]="93 - (stepper.selectedIndex * 100) / stepper.steps.length"
              ></div>
            </div>
          </div>
          <button *ngIf="index !== lastStepIndex" [disabled]="!isValid(step)" (click)="next(step)" class="rounded-button" type="button">
            {{ i18nService._('txt_stepper_next') }}
          </button>
          <button *ngIf="index === lastStepIndex" [disabled]="!isValid(step)" class="rounded-button" type="submit">
            {{ i18nService._('txt_stepper_last') }}
          </button>
        </div>
      </div>
    </app-small-stepper-step>
  </ng-container>
</app-small-stepper>
