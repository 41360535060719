import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';

import { AppIconsName } from '../../../common/standalone/icon/utils/app-icon-names';
import { IconComponent } from '../../../common/standalone/icon/icon.component';

@Component({
  selector: 'app-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss'],
  standalone: true,
  imports: [IconComponent],
})
export class CircleProgressComponent implements AfterViewInit {
  @Input() progress: number;
  @Input() icon: AppIconsName;
  @Input() index: number;

  @ViewChild('progress') progressElement: ElementRef;

  constructor(private _renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.loadData();
  }

  loadData() {
    this._renderer.setStyle(
      this.progressElement.nativeElement,
      'background',
      `conic-gradient(${(this.progress === 100 ? '#4DCE6E ' : '#2A80B1 ') + this.progress}%, #E8E8E8 ${this.progress}%)`,
    );
  }
}
