<div class="flex flex-row flex-1 w-full bg-graylight h-full d-table">
  <mat-drawer-container [hasBackdrop]="true" class="bg-white w-full">
    <mat-drawer
      #drawer
      class="shadow-xl overflow-visible visible border-l-4 border-orange-500 w-full lg:w-4/5 p-4"
      [opened]="false"
      mode="over"
      position="end"
    >
      <app-lot-details-panel [lotId]="lotId" [reservationId]="reservationId" (closePanel)="drawer.close()"></app-lot-details-panel>
    </mat-drawer>

    <mat-drawer-content>
      <div class="flex flex-col w-full padding-subHeader w-screen-noXscroll">
        <!-- Sticky subHeader form -->
        <app-sticky-header-form [titleText]="i18nService._('Title_ReservationsTrackingTable')"></app-sticky-header-form>

        <div class="pt-20 mx-3 mb-4 lg:mx-5 md:mx-5 sm:mx-4 px-2 lg:px-6 md:px-5 sm:px-3">
          <mat-spinner *ngIf="isLoadingAction" [color]="'primary'" [diameter]="60" [strokeWidth]="6" class="mx-auto my-5 absolute z-50">
          </mat-spinner>

          <mat-button-toggle-group [(ngModel)]="statusFilter" (change)="statusFilterChange()" class="mb-4">
            <mat-button-toggle data-cy="resaTrackingTableToggleButtonAll" [value]="statusFilters.all">
              <span
                [ngClass]="{ 'mr-4 matBadge-center': count.all }"
                [matBadge]="count.all"
                matBadgeOverlap="false"
                [matBadgeHidden]="!count.all"
              >
                <span class="pr-2">{{ i18nService._('Title_ResaTrackingTable_ToggleButton_All') }}</span>
              </span>
            </mat-button-toggle>

            <mat-button-toggle data-cy="resaTrackingTableToggleButtonReservation" [value]="statusFilters.reservation">
              <span
                [ngClass]="{ 'mr-4 matBadge-center': count.reservation }"
                [matBadge]="count.reservation"
                matBadgeOverlap="false"
                [matBadgeHidden]="!count.reservation"
              >
                <span class="pr-2">{{ i18nService._('Title_ResaTrackingTable_ToggleButton_Reservation') }}</span>
              </span>
            </mat-button-toggle>

            <mat-button-toggle data-cy="resaTrackingTableToggleButtonSold" [value]="statusFilters.sold">
              <span
                [ngClass]="{ 'mr-4 matBadge-center': count.sold }"
                [matBadge]="count.sold"
                matBadgeOverlap="false"
                [matBadgeHidden]="!count.sold"
              >
                <span class="pr-2">{{ i18nService._('Title_ResaTrackingTable_ToggleButton_Sold') }}</span>
              </span>
            </mat-button-toggle>

            <mat-button-toggle data-cy="resaTrackingTableToggleButtonDesisted" [value]="statusFilters.desisted">
              <span
                [ngClass]="{ 'mr-4 matBadge-center': count.desisted }"
                [matBadge]="count.desisted"
                matBadgeOverlap="false"
                [matBadgeHidden]="!count.desisted"
              >
                <span class="pr-2">{{ i18nService._('Title_ResaTrackingTable_ToggleButton_Desisted') }}</span>
              </span>
            </mat-button-toggle>
          </mat-button-toggle-group>

          <div class="grid grid-cols-8 gap:4 lg:gap-6 mb-3">
            <div class="col-span-2">
              <mat-form-field class="fullW flex w-full">
                <mat-label>Recherche</mat-label>
                <input [(ngModel)]="filterValue" (ngModelChange)="textFilterSubject.next($event)" matInput />
              </mat-form-field>
            </div>
            <div class="flex h-full items-center col-span-2">
              <app-toggle-button (selectChange)="changeTeamFilter($event)" *ngIf="displayTeamButton" [checked]="isTeamMemberParam">
                <span class="font-bold">{{ i18nService._('Txt_TrackingTable_MY_TEAM') }}</span>
              </app-toggle-button>
              <app-toggle-button (selectChange)="changePartnerFilter($event)" *ngIf="displayPartnerButton" [checked]="isPartnerParam">
                <span class="font-bold">{{ i18nService._('Txt_TrackingTable_MY_PARTNERS') }}</span>
              </app-toggle-button>
            </div>
          </div>

          <div class="tracking-mat-table">
            <app-grid-tracking-table
              [tableLoader]="reservationsTrackingTableService"
              [columnDefs]="columnDefs"
              [pageSize]="pageSize"
              [textFilter]="textFilterValue"
              [isPartner]="isPartnerParam"
              [isTeamMember]="isTeamMemberParam"
              [statusFilter]="statusFilter.code"
            >
            </app-grid-tracking-table>
          </div>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
