<div class="search-results-container">
  <div class="px-5 pt-2">
    <div class="row flex border-0">
      <div class="col-12 w-full h-full bg-graylight">
        <div *ngIf="isResultData" class="w-full mt-3 mb-3 flex text-2xl items-center justify-between">
          <div class="justify-start">
            <div
              *ngIf="
                countResults && (countResults.lots || countResults.lots === 0) && (countResults.programs || countResults.programs === 0)
              "
              class="md:inline-block mr-3 text-center"
            >
              <span class="font-bold">
                {{ countResults.programs }}
              </span>
              <span *ngIf="countResults.programs <= 1"> {{ i18nService._('Txt_Page_SearchResults_Program') }}, </span>
              <span *ngIf="countResults.programs > 1"> {{ i18nService._('Txt_Page_SearchResults_Programs') }}, </span>
              <span class="font-bold">
                {{ countResults.lots }}
              </span>
              <span *ngIf="countResults.lots <= 1">
                {{ i18nService._('Txt_Page_SearchResults_Lot') }}
              </span>
              <span *ngIf="countResults.lots > 1">
                {{ i18nService._('Txt_Page_SearchResults_Lots') }}
              </span>
              <span>
                {{ i18nService._('Txt_Page_SearchResults_Prefix') }}
              </span>
            </div>
          </div>
          <app-comparator-preview-button [label]="'Txt_button_comparator_open_container_label'"></app-comparator-preview-button>
        </div>
        <div
          class="w-full mt-3 mb-3 flex text-2xl justify-start"
          *ngIf="!isResultData && !isSpinner"
          [innerHTML]="i18nService._('Error_Field_NoResult') | safe: 'html'"
        ></div>
        <div class="search-page-tab-group_buttons">
          <button
            data-cy="searchResultPrograms"
            mat-flat-button
            [ngClass]="{ active: route.snapshot.queryParams?.type === SearchPageDisplayType.Programme }"
            (click)="changeTypeOfSearch(SearchPageDisplayType.Programme)"
          >
            <mat-icon class="material-icons-outlined size-22" [svgIcon]="'picto_programmes'">format_list_bulleted</mat-icon>
            {{ i18nService._('Txt_search_result_tab_programs') }}
          </button>
          <button
            data-cy="cartography"
            mat-flat-button
            [ngClass]="{ active: route.snapshot.queryParams?.type === SearchPageDisplayType.Carte }"
            (click)="changeTypeOfSearch(SearchPageDisplayType.Carte)"
          >
            <mat-icon class="material-icons-outlined size-22" [svgIcon]="'picto_carte'">map</mat-icon>
            {{ i18nService._('Txt_search_result_tab_carte') }}
          </button>
          <button
            data-cy="mapboxLegend"
            mat-flat-button
            [ngClass]="{ active: route.snapshot.queryParams?.type === SearchPageDisplayType.Lot }"
            (click)="changeTypeOfSearch(SearchPageDisplayType.Lot)"
          >
            <mat-icon class="material-icons-outlined size-22" [svgIcon]="'picto_lots'">menu</mat-icon>
            {{ i18nService._('Txt_search_result_tab_lots') }}
          </button>
          <mat-form-field
            *ngIf="isResultData && route.snapshot.queryParams?.type === SearchPageDisplayType.Programme"
            class="d-md-inline-block text-center mat-form-field-middleWidth"
          >
            <mat-label>{{ i18nService._('Txt_SortBy') }}</mat-label>
            <mat-select [(ngModel)]="selectedOrderBy" (selectionChange)="onOrderChange()">
              <ng-container *ngFor="let orderBy of orderByFilters">
                <mat-option [value]="orderBy">
                  {{ orderBy.text }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="result-body">
          <div *ngIf="isResultData">
            <app-search-results-programs
              *ngIf="programResults && route.snapshot.queryParams?.type === SearchPageDisplayType.Programme"
              [programs]="programResults"
              class="lg:grid md:block sm:block xs:block mb-5"
            >
            </app-search-results-programs>
          </div>

          <div *ngIf="route.snapshot.queryParams?.type === SearchPageDisplayType.Carte" class="mt-3">
            <app-valorissimo-main-map [programs]="programResults" *ngIf="programResults"></app-valorissimo-main-map>
          </div>

          <app-search-results-lots
            *ngIf="isResultData && lotResults && route.snapshot.queryParams?.type === SearchPageDisplayType.Lot"
            [lots]="lotResults"
            [countResults]="countResults"
            (refreshLotsEvent)="refreshLots($event)"
            class="mb-4 mt-3 lg:grid md:block sm:block xs:block"
          >
          </app-search-results-lots>
        </div>
      </div>
    </div>
  </div>
</div>
