import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';

import { ProspectEditComponent } from './components/prospect-edit/prospect-edit.component';
import { DossierProspectComponent } from './components/dossier-prospect/dossier-prospect.component';
import { ProspectCreateComponent } from './components/prospect-create/prospect-create.component';
import { PaternityCreateComponent } from './components/paternity-create/paternity-create.component';
import { Sitemap } from '../utils/models/Sitemap';
import { DossierProspectTabType } from './model/dossier-prospect-tab-type.enum';

import { AuthGuard } from './../security/authguard';

const routes: Routes = [
  {
    path: Sitemap.dossierProspects.dashboard.path,
    component: DossierProspectComponent,
    canActivate: mapToCanActivate([AuthGuard]),
    data: { activeTab: DossierProspectTabType.DASHBOARD },
  },
  {
    path: Sitemap.dossierProspects.dashboardSimulation.path,
    component: DossierProspectComponent,
    canActivate: mapToCanActivate([AuthGuard]),
    data: { activeTab: DossierProspectTabType.SIMULATION },
  },
  {
    path: Sitemap.dossierProspects.dashboardPaternities.path,
    component: DossierProspectComponent,
    canActivate: mapToCanActivate([AuthGuard]),
    data: { activeTab: DossierProspectTabType.PATERNITIES },
  },
  {
    path: Sitemap.dossierProspects.dashboardLots.path,
    component: DossierProspectComponent,
    canActivate: mapToCanActivate([AuthGuard]),
    data: { activeTab: DossierProspectTabType.LOTS },
  },
  {
    path: Sitemap.dossierProspects.dashboardTransactions.path,
    component: DossierProspectComponent,
    canActivate: mapToCanActivate([AuthGuard]),
    data: { activeTab: DossierProspectTabType.TRANSACTIONS },
  },
  {
    path: Sitemap.dossierProspects.dashboardSearchPreferences.path,
    component: DossierProspectComponent,
    canActivate: mapToCanActivate([AuthGuard]),
    data: { activeTab: DossierProspectTabType.SEARCH_PREFERENCES },
  },
  {
    path: Sitemap.dossierProspects.create.path,
    component: ProspectCreateComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.dossierProspects.edit.path,
    component: ProspectEditComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.dossierProspects.paternity.path,
    component: PaternityCreateComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
  declarations: [],
})
export class DossierProspectRoutingModule {}
