<div class="flex flex-col w-full">
  <!-- @todo : merge in one with dynamic titleText calcul -->
  <app-sticky-header-form
    [goBackUrl]="programsDashboardPath"
    [titleText]="i18nService._('Title_CommercialOperation')"
    data-cy="commercialOperation"
    *ngIf="!isEditForm && !isValidateForm"
  >
  </app-sticky-header-form>

  <app-sticky-header-form
    [goBackUrl]="programsDashboardPath"
    [titleText]="i18nService._('Title_ModifyCommercialOperation')"
    *ngIf="isEditForm"
  >
  </app-sticky-header-form>

  <app-sticky-header-form
    [goBackUrl]="programsDashboardPath"
    [titleText]="i18nService._('Title_ValidateCommercialOperation')"
    *ngIf="isValidateForm"
  >
  </app-sticky-header-form>

  <div class="grid grid-cols-10 pt-16">
    <div class="col-span-10 lg:col-span-8 lg:col-start-2 xl:col-span-6 xl:col-start-3 flex flex-col pt-16">
      <form [formGroup]="creationProgramForm" (ngSubmit)="onSubmit()">
        <mat-stepper
          orientation="vertical"
          #stepper
          (selectionChange)="stepChanged($event)"
          [linear]="true"
          [@.disabled]="true"
          appMatVerticalStepper
          class="mb-5"
        >
          <ng-template matStepperIcon="edit">
            <span class="icon-validate-small"></span>
          </ng-template>

          <!-- Form Program -->
          <mat-step [stepControl]="programForm">
            <ng-template matStepLabel>
              {{ i18nService._('Title_PropertyProgram') }}
            </ng-template>
            <form [formGroup]="programForm">
              <app-step-one-form-program
                class="flex flex-col"
                [parentForm]="programForm"
                [isValo]="isValo"
                [recoveredInfos]="recoveredInfos"
                [acceptedFileType]="acceptedFileType"
                (devCompanyChanged)="onDevCompanyChanged($event)"
              >
              </app-step-one-form-program>
            </form>
          </mat-step>

          <!-- Form Lot -->
          <mat-step [completed]="false">
            <ng-template matStepLabel>
              {{ i18nService._('Title_PropertyLotOfProgram') }}
            </ng-template>
            <form [formGroup]="lotsOfProgramForm">
              <app-step-two-form-program
                class="flex flex-col"
                [parentForm]="lotsOfProgramForm"
                [programId]="programId"
                [isImported]="isImported"
                [isValo]="isValo"
                [isEditOrValidateForm]="isEditForm || isValidateForm"
                (stepCompleted)="onCompletedStep($event)"
              >
              </app-step-two-form-program>
            </form>
          </mat-step>

          <!-- Form Summary -->
          <mat-step [completed]="false">
            <ng-template matStepLabel>
              <h3>
                {{ i18nService._('Title_Summary') }}
              </h3>
            </ng-template>
            <form [formGroup]="summaryForm">
              <app-step-three-form-program
                class="flex flex-col"
                [programSummary]="programSummary"
                [isEditOrValidateForm]="isEditForm || isValidateForm"
                [isValo]="isValo"
                [programId]="programId"
                (stepCompleted)="onCompletedStep($event)"
              >
              </app-step-three-form-program>
            </form>
          </mat-step>

          <!-- Form Valorissimo -->
          <mat-step *ngIf="isValo">
            <ng-template matStepLabel>
              <h3>
                {{ i18nService._('Title_Valorissimo') }}
              </h3>
            </ng-template>
            <form [formGroup]="adminValoForm">
              <app-step-four-form-program
                class="flex flex-col"
                [parentForm]="adminValoForm"
                [isSubmit]="isSubmitFinal"
                [recoveredInfos]="recoveredInfos"
                [companyData]="company"
                [displaySignatureErrorMessage]="displaySignatureErrorMessage"
                #step4
              >
              </app-step-four-form-program>
            </form>
          </mat-step>
        </mat-stepper>

        <div class="flex flex-row" *ngIf="(isEditForm && isDraftOrIncompleteStatusProgram) || (!isValo && !isEditForm)">
          <div class="my-5 mr-4">
            <button data-cy="submit" mat-flat-button color="primary" type="submit">
              {{ i18nService._('Txt_Button_Submit') }}
            </button>
          </div>
        </div>

        <div class="flex flex-row flex-wrap mb-6 ml-5 pl-2">
          <ng-container *ngIf="isEditForm && !isDraftOrIncompleteStatusProgram">
            <button data-cy="modifyProgram" mat-flat-button class="mr-3 mb-3" color="primary" type="button" (click)="sendUpdateProgram()">
              {{ i18nService._('Txt_Button_ModifyProgram') }}
            </button>
          </ng-container>

          <ng-container *ngIf="(!isEditForm && isValo) || isValidateForm">
            <button
              data-cy="acceptProgram"
              mat-flat-button
              class="mr-3 mb-3"
              color="primary"
              type="button"
              (click)="sendUpdateProgram(appConfig.status.valid)"
            >
              {{ i18nService._('Txt_Button_AcceptProgram') }}
            </button>

            <button
              data-cy="returnProgram"
              mat-flat-button
              class="mr-3 mb-3"
              color="primary"
              type="button"
              (click)="openDialog(appConfig.status.incomplete)"
            >
              {{ i18nService._('Txt_Button_ReturnProgram') }}
            </button>

            <button
              data-cy="refuseProgram"
              mat-flat-button
              class="mr-3 mb-3"
              color="primary"
              type="button"
              (click)="openDialog(appConfig.status.denied)"
            >
              {{ i18nService._('Txt_Button_RefusedProgram') }}
            </button>
          </ng-container>
        </div>
      </form>
    </div>
  </div>
</div>
