import { Injectable } from '@angular/core';
import { SecondaryLotTypeEnum } from '@commons-dto/valo-back';

import { SecondaryLotTypeResponse } from '../utils/models/SecondaryLotTypeResponse';
import { SecondaryLotTypeApiService } from '../adapters/secondary-lot-type-api.service';

@Injectable({
  providedIn: 'root',
})
export class SecondaryLotTypeService {
  secondaryLotTypes: SecondaryLotTypeResponse[];

  constructor(private readonly secondaryLotTypeApiService: SecondaryLotTypeApiService) {}

  public async getSecondaryLotTypes(): Promise<SecondaryLotTypeResponse[]> {
    if (!this.secondaryLotTypes) {
      await this.loadSecondaryLotTypes();
    }
    return this.secondaryLotTypes;
  }

  public async getSecondaryLotTypesByAnnexeType(
    annexeType: SecondaryLotTypeEnum.PARKING | SecondaryLotTypeEnum.STORAGE,
  ): Promise<SecondaryLotTypeResponse[]> {
    return this.secondaryLotTypeApiService.getSecondaryLotTypesByAnnexeType(annexeType);
  }

  private async loadSecondaryLotTypes(): Promise<void> {
    this.secondaryLotTypes = await this.secondaryLotTypeApiService.getSecondaryLotTypes();
  }
}
