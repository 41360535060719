<div *ngIf="form" class="side-panel__info w-full bg-white p-8 shadow-custom rounded-10">
  <div class="flex flex-col">
    <h3 class="font-bold text-xl">{{ i18nService._('txt_lot_numero') }}{{ form?.contextData?.lot?.number }}</h3>
    <div class="text-sm text-gray-600 mb-3">
      <p>{{ form?.contextData?.program?.name }}</p>
      <p class="font-bold text-xs">{{ form?.contextData?.program?.city }}</p>
    </div>
    <ul class="text-sm leading-6 list-disc pl-4 text-gray-600">
      <li class="mb-1">{{ i18nService._(form?.contextData?.lot?.propertyType) }}</li>
      <li class="mb-1">{{ form?.contextData?.lot?.typology }}</li>
      <li class="mb-1">{{ form?.contextData?.lot?.floor }}</li>
      <li class="mb-1">{{ i18nService._('txt_delivery') }} : {{ form?.contextData?.program?.deliveryDate }}</li>
    </ul>
  </div>
  <hr class="my-6 border border-gray-400" />
  <div class="mt-6">
    <h3 class="font-bold text-xl">{{ i18nService._('txt_signature_form_context_lot_detail_housing_title') }}</h3>
    <div class="mt-4 mb-3">
      <div class="text-lg">{{ form?.contextData?.lot?.livingArea }} m2</div>
      <div class="text-xxxs font-bold uppercase text-gray-500">{{ i18nService._('txt_living_area') }}</div>
    </div>
    <div *ngFor="let area of form?.contextData?.lot?.areas" class="text-sm mt-4 mb-3">
      <div class="text-lg">{{ area.area }} m2</div>
      <div class="text-xxxs font-bold uppercase text-gray-500">{{ i18nService._('txt_' + area.type) }}</div>
    </div>
  </div>

  <button
    class="flex justify-center items-center mt-6 ml-auto bg-gradient-to-r from-gradientOrangeFrom to-gradientOrangeTo text-white text-sm rounded-3xl py-4 px-8 h-10 uppercase font-bold text-center w-full"
    type="button"
    (click)="fileSystemService.openDocumentInBrowser(lotPlanDocument, lotPlanDocument.fileName)"
  >
    <span class="flex-1">{{ i18nService._('txt_signature_form_context_lot_plan_button') }}</span>
  </button>
</div>
