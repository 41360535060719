import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { FileSystemFileEntry, NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { BasicFormatsService } from '../../../utils/services/basic-formats.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { DesktopPublishingDialogData } from '../../models/DesktopPublishingDialogData';
import { DesktopPublishingService } from '../../services/desktop-publishing.service';
import { SafePipe } from '../../../utils/pipes/safe.pipe';
import { EllipsisPipe } from '../../../utils/pipes/ellipsis.pipe';

@Component({
  selector: 'app-desktop-publishing-dialog',
  templateUrl: './desktop-publishing-dialog.component.html',
  styleUrls: ['./desktop-publishing-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    MatFormFieldModule,
    MatButtonModule,
    EllipsisPipe,
    SafePipe,
  ],
})
export class DesktopPublishingDialogComponent implements OnInit {
  public form: UntypedFormGroup;
  public fileControl: AbstractControl;
  public browserAvailable: boolean;
  public splitTypes: string;
  public extErrorMessage = '.docx';
  public readonly extWithoutDot = 'docx';
  public alreadyLoad = false;
  public errorOccured = false;
  private readonly validExt: Array<string> = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  private readonly maxFileSizeMo = 100;
  private fileName = '';

  /**
   * Creates an instance of DesktopPublishingDialogComponent.
   * @param {I18nService} i18nService
   * @param {FormBuilder} formBuilder
   * @param {SnackbarService} snackbarService
   * @param {MatDialogRef<DesktopPublishingDialogComponent>} dialogRef
   * @param {DesktopPublishingService} desktopPublishingService
   * @param {BasicFormatsService} basicFormatsService
   * @param {DesktopPublishingDialogData} data
   * @memberof DesktopPublishingDialogComponent
   */
  constructor(
    public i18nService: I18nService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<DesktopPublishingDialogComponent>,
    private readonly desktopPublishingService: DesktopPublishingService,
    private readonly basicFormatsService: BasicFormatsService,
    @Inject(MAT_DIALOG_DATA) public data: DesktopPublishingDialogData,
  ) {}

  ngOnInit(): void {
    // Form init
    this.form = this.formBuilder.group({
      file: [undefined, [Validators.required, this.fileType.bind(this), this.fileSize.bind(this)]],
    });
    this.fileControl = this.form.controls.file;

    // Check browser compatibility
    const myWindow = window;
    this.browserAvailable = Boolean(myWindow.File) && Boolean(myWindow.FileReader);
    if (!this.browserAvailable) {
      const message: SnackbarMessage = {
        text: this.i18nService._('Error_SnackBar_BrowserNotCompatibleWithAttachment'),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
    }
    // Varibale to set html authorized extension
    this.splitTypes = this.validExt.toString();
  }

  /**
   * fileType control method
   *
   * @param {NgControl} control
   * @returns {{ [key: string]: boolean }}
   * @memberof DesktopPublishingDialogComponent
   */
  fileType(control: NgControl): { [key: string]: boolean } {
    if (control.value && control.value.type && this.validExt.indexOf(control.value.type) !== -1) {
      return undefined;
    }

    if (control.value && control.value.name) {
      const split = control.value.name.split('.');
      if (split[split.length - 1] === this.extWithoutDot) {
        return undefined;
      }
    }

    return { fileType: true };
  }

  /**
   * fileSize method, to validate file size
   *
   * @param {NgControl} control
   * @returns {{ [key: string]: boolean }}
   * @memberof DesktopPublishingDialogComponent
   */
  fileSize(control: NgControl): { [key: string]: boolean } {
    // Change from Mo to Mio (VALO-3657)
    if (control.value && control.value.size && this.maxFileSizeMo * 1024 ** 2 > control.value.size) {
      return undefined;
    }

    if (control.value && control.value.size === 0) {
      return { fileEmpty: true };
    }

    return { fileSize: true };
  }

  /**
   * fileAdded method
   *
   * @param {*} files
   * @memberof DesktopPublishingDialogComponent
   */
  public fileAdded(files): void {
    // Check that only one file is selected
    if (files.length === 1) {
      const file = files[0];
      this.setNewFile(file);
    } else {
      const message: SnackbarMessage = {
        text: this.i18nService._('Error_SnackBar_ManyAttachmentsForbidden'),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
    }
  }

  /**
   * fileDropped method
   *
   * @param {UploadEvent} event
   * @memberof DesktopPublishingDialogComponent
   */
  public fileDropped(files: Array<NgxFileDropEntry>): void {
    // If many files, snackBar and ask only one file
    if (files.length !== 1) {
      const message: SnackbarMessage = {
        text: this.i18nService._('Error_SnackBar_ManyAttachmentsForbidden'),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
    } else {
      const droppedFile = files[0];
      if (droppedFile && droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.setNewFile(file);
        });
      } else {
        const message: SnackbarMessage = {
          text: this.i18nService._('Error_Field_DocumentFormat', [this.extErrorMessage]),
          type: SnackbarMessageType.Error,
        };
        this.snackbarService.sendMessage(message);
      }
    }
  }

  /**
   * setNewFile method
   *
   * @public
   * @param {*} file
   * @memberof DesktopPublishingDialogComponent
   */
  public setNewFile(file): void {
    if (file) {
      this.fileName = file.name.split(this.extErrorMessage)[0];
      this.fileControl.setValue(file);
      this.alreadyLoad = true;
    }
  }

  /**
   * removeAttachment method, called when user delete attachment
   *
   * @memberof DesktopPublishingDialogComponent
   */
  public removeAttachment(): void {
    this.fileControl.reset();
  }

  /**
   * submit method
   *
   * @memberof DesktopPublishingDialogComponent
   */
  public submit(): void {
    this.alreadyLoad = true;
    if (this.form.valid && this.fileControl.value) {
      this.desktopPublishingService.getDesktopPublishing(this.data.companyId, this.fileControl.value).subscribe(
        (response) => {
          const extDate = this.basicFormatsService.formatDate(new Date(), BasicFormatsService.fullDateHourFormat);
          const name = `${this.data.corporateName}_${this.fileName}_${extDate}.${this.extWithoutDot}`;
          saveAs(new Blob([response], { type: this.validExt[0] }), name);
          this.close();
        },
        () => {
          this.errorOccured = true;
        },
      );
    }
  }

  /**
   * close method
   *
   * @memberof DesktopPublishingDialogComponent
   */
  public close(): void {
    this.dialogRef.close();
  }
}
