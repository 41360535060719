<app-date
  [(date)]="field.props.date"
  [inputDateControl]="formControl"
  [ngClass]="{
    'ng-touched': formControl.touched,
    'ng-untouched': formControl.untouched,
    'ng-dirty': formControl.dirty,
    'ng-pristine': formControl.pristine,
    'ng-invalid': formControl.invalid,
    'ng-valid': formControl.valid
  }"
  [required]="field.props.required"
></app-date>
