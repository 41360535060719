import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf, AsyncPipe, DatePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AdvancedTrackingTablesNoBackPageComponent } from '../advanced-tracking-tables-no-back-page/advanced-tracking-tables-no-back-page.component';

import { AccountUtilsService } from '../../../accounts/services/account-utils.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { AdvancedTrackingTableColumn } from '../../models/AdvancedTrackingTableColumn';
import { CronExecutionResponse } from '../../models/CronExecutionResponse';
import { SalersTrackingTableItem } from '../../models/SalersTrackingTableItem';
import { SalersTrackingTableService } from '../../services/salers-tracking-table.service';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';

@Component({
  selector: 'app-salers-tracking-table',
  templateUrl: './salers-tracking-table.component.html',
  styleUrls: ['./salers-tracking-table.component.scss'],
  standalone: true,
  imports: [
    StickyHeaderFormComponent,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    NgIf,
    AdvancedTrackingTablesNoBackPageComponent,
    AsyncPipe,
    DatePipe,
  ],
})
export class SalersTrackingTableComponent implements OnInit {
  /**
   * Columns of the array.
   *
   * @type {Array<AdvancedTrackingTableColumn>}
   * @memberof SalersTrackingTableComponent
   */
  public columns: Array<AdvancedTrackingTableColumn>;

  /**
   * Current year.
   * @type {number}
   * @private
   * @memberof SalersTrackingTableComponent
   */
  private readonly currentYear = new Date(Date.now()).getFullYear();

  /**
   * First year of the mat-select.
   * RG_2424.2.2 : The first year is 2020 (Production Launch Year).
   *
   * @type {number}
   * @private
   * @memberof SalersTrackingTableComponent
   */
  private readonly launchingYear = 2020;

  /**
   * Currently selected year in mat-select.
   * RG_2424.2.2 : By default the selected year is the current year.
   * @type {number}
   * @memberof SalersTrackingTableComponent
   */
  public selectedYear: number;

  /**
   * List of years between the first year and the current year.
   *
   * @memberof SalersTrackingTableComponent
   */
  public yearList = [];

  /**
   * i18n key of message to display if no results.
   *
   * @type {string}
   * @memberof SalersTrackingTableComponent
   */
  public noResultMessageI18nToken = 'Txt_No_Result';

  /**
   * Control first loaded
   *
   * @type {Boolean}
   * @private
   * @memberof SalersTrackingTableComponent
   */
  private firstLoaded = false;

  /**
   *
   * Last cron execution
   * @type {Observable<CronExecutionResponse>}
   * @memberof SalersTrackingTableComponent
   */
  public lastCronExecution$: Observable<CronExecutionResponse>;

  @ViewChild(AdvancedTrackingTablesNoBackPageComponent)
  advancedTableComponent: AdvancedTrackingTablesNoBackPageComponent;

  constructor(
    public readonly service: SalersTrackingTableService,
    public readonly i18nService: I18nService,
    private readonly accountUtilsService: AccountUtilsService,
  ) {}

  ngOnInit(): void {
    this.columns = [
      {
        key: 'name',
        sortable: false,
        i18nTitle: 'Title_SalersTrackingTable_Column_Name',
      },
      {
        key: 'companyTradingName',
        sortable: false,
        i18nTitle: 'Title_SalersTrackingTable_Column_Company',
        hidden: !this.accountUtilsService.getParentAndLoggedUserInfos().loggedUserIsValo,
      },
      {
        key: 'nbDeclaredProspects',
        sortable: false,
        i18nTitle: 'Title_SalersTrackingTable_Column_DeclaredProspects',
      },
      {
        key: 'nbCurrentOptions',
        sortable: false,
        i18nTitle: 'Title_SalersTrackingTable_Column_CurrentOptions',
      },
      {
        key: 'nbTakenOptions',
        sortable: false,
        i18nTitle: 'Title_SalersTrackingTable_Column_TakenOptions',
      },
      {
        key: 'nbCurrentPrereservation',
        sortable: false,
        i18nTitle: 'Title_SalersTrackingTable_Column_CurrentPrereservations',
      },
      {
        key: 'nbCurrentReservation',
        sortable: false,
        i18nTitle: 'Title_SalersTrackingTable_Column_CurrentReservations',
      },
      {
        key: 'nbSells',
        sortable: false,
        i18nTitle: 'Title_SalersTrackingTable_Column_Sells',
      },
      // For the ngClass condition
      {
        key: 'superiorId',
        sortable: false,
        hidden: true,
        i18nTitle: 'Title_SalersTrackingTable_Column_SuperiorId',
      },
      // For filtering purpose
      {
        key: 'id',
        sortable: false,
        hidden: true,
        i18nTitle: 'Title_SalersTrackingTable_Column_SuperiorId',
      },
    ];

    const diffOfYears =
      this.currentYear > this.launchingYear
        ? this.currentYear - this.launchingYear
        : this.launchingYear > this.currentYear
        ? this.launchingYear - this.currentYear
        : 0;

    for (let year = 0; year <= diffOfYears; year++) {
      this.yearList.push(this.currentYear > this.launchingYear ? this.launchingYear + year : this.launchingYear - year);
    }

    // wait until the list of options is built to pre-select the current year
    this.selectedYear = this.currentYear;

    // Get last cron execution
    this.lastCronExecution$ = this.service.getLastCronExecution();
  }

  selectYear($event): void {
    this.selectedYear = $event.value;
    this.advancedTableComponent.loadItems(String(this.selectedYear));
  }

  /**
   * Custom filtering function, returns true if name matches or if has an subordinate that matches
   * @param {SalersTrackingTableItem} - data
   * @param {string} - filter
   * @memberof SalersTrackingTableComponent
   */
  filteringFunction = (data: SalersTrackingTableItem, filter: string) => {
    const matchingSubordinate = this.advancedTableComponent.items.find((account) => {
      return (
        account.superiorId === data.id &&
        account.companyTradingName === data.companyTradingName &&
        ((account.name && account.name.toLowerCase().includes(filter)) ||
          (account.companyTradingName && account.companyTradingName.toLowerCase().includes(filter)))
      );
    });

    const haveElement = Object.values(data).find((element) =>
      `${element}`
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(`${filter}`),
    );

    return (
      !filter ||
      (data.name && data.name.toLowerCase().includes(filter)) ||
      (data.companyTradingName && data.companyTradingName.toLowerCase().includes(filter)) ||
      Boolean(matchingSubordinate) ||
      haveElement
    );
  };

  onResultEvent(): void {
    // check if items has a hierarchy
    if (!this.firstLoaded && Boolean(this.advancedTableComponent.items.find((sup) => sup.superiorId))) {
      this.firstLoaded = true;
      // add a conditional ngClass
      this.columns.find((column) => column.key === 'name').ngClass = {
        condition: (item) => Boolean(this.advancedTableComponent.items.find((sup) => sup.id === item.superiorId)),
        classTrue: 'text-xs text-black pl-4 clone-box-decoration',
        classFalse: 'text-xs text-black font-bold',
      };
    }
  }
}
