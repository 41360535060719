import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Subject, Subscription, takeUntil } from 'rxjs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { CompanyData } from '../../../utils/models/CompanyData';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { LoggedFooterResponse } from '../../../utils/models/LoggedFooterResponse';
import { ReferenceTableData } from '../../../utils/models/ReferenceTableData';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { ConfigurableParamService } from '../../../utils/services/configurable-param.service';
import { FormUpdateService } from '../../../utils/services/form-update.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { PostalAddressService } from '../../../utils/services/postal-address.service';
import { ReferenceTablesService } from '../../../utils/services/reference-tables.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { isInteger } from '../../../utils/validators/is-integer';
import { siretValidator } from '../../../utils/validators/siret-validator.directive';
import { CompanyService } from '../../services/company.service';
import { DecimalMaskDirective } from '../../../utils/directives/decimal-mask.directive';
import { DropdownListPopulatedComponent } from '../../../utils/components/dropdown-list-populated/dropdown-list-populated.component';
import { InputDateComponent } from '../../../utils/components/input-date/input-date.component';
import { PostalAddressComponent } from '../../../utils/components/postal-address/postal-address.component';
import { ChipPopulatedComponent } from '../../../utils/components/chip-populated/chip-populated.component';
import { DropdownListPopulatedWithOtherComponent } from '../../../utils/components/dropdown-list-populated-with-other/dropdown-list-populated-with-other.component';

@Component({
  selector: 'app-step-one-form-company',
  templateUrl: './step-one-form-company.component.html',
  styleUrls: ['./step-one-form-company.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownListPopulatedWithOtherComponent,
    MatTooltipModule,
    ChipPopulatedComponent,
    PostalAddressComponent,
    NgIf,
    InputDateComponent,
    DropdownListPopulatedComponent,
    DecimalMaskDirective,
    MatButtonModule,
    MatStepperModule,
  ],
})
export class StepOneFormCompanyComponent implements OnInit, OnDestroy {
  /**
   * ParentForm to add controls to
   *
   * @type {FormGroup}
   * @memberof StepOneFormCompanyComponent
   */
  @Input() parentForm: UntypedFormGroup;

  @Input() isDeveloper = false;

  @Input() recoveredInfo: CompanyData;

  /**
   * Commercial Court items attribute
   *
   * @type {ReferenceTableData}
   * @memberof DropdownListPopulatedWithOtherComponent
   */
  public commercialCourtItems: ReferenceTableData;

  /**
   * CCI items attribute
   *
   * @type {ReferenceTableData}
   * @memberof DropdownListPopulatedWithOtherComponent
   */
  public cciItems: ReferenceTableData;

  /**
   * Naf items attribute
   *
   * @type {ReferenceTableData}
   * @memberof DropdownListPopulatedWithOtherComponent
   */
  public nafItems: ReferenceTableData;

  /**
   * isSubmit
   *
   * @type {boolean}
   * @memberof StepOneFormCompanyComponent
   */
  public isSubmit = false;

  /**
   * Control on corporateName
   *
   * @type {FormControl}
   * @memberof StepOneFormCompanyComponent
   */
  public corporateNameControl: FormControl;

  /**
   * Control on tradingName
   *
   * @type {FormControl}
   * @memberof StepOneFormCompanyComponent
   */
  public tradingNameControl: FormControl;

  /**
   * Control on homeEntityName
   *
   * @type {FormControl}
   * @memberof StepOneFormCompanyComponent
   */
  public homeEntityControl: FormControl;

  /**
   * Control on siret
   *
   * @type {FormControl}
   * @memberof StepOneFormCompanyComponent
   */
  public siretControl: FormControl;

  /**
   * Control on intracommunityVAT
   *
   * @type {FormControl}
   * @memberof StepOneFormCompanyComponent
   */
  public intraCommunityVatControl: FormControl;

  /**
   * Control on numCarteT
   *
   * @type {FormControl}
   * @memberof StepOneFormCompanyComponent
   */
  public numCarteTControl: FormControl;

  /**
   * Control on end date carte T
   *
   * @type {FormControl}
   * @memberof StepOneFormCompanyComponent
   */
  public carteTEndDateControl: FormControl;

  /**
   * Control on capital
   *
   * @type {FormControl}
   * @memberof StepOneFormCompanyComponent
   */
  public capitalControl: FormControl;

  /**
   * Control of cedex in company address
   *
   * @type {FormControl}
   * @memberof StepOneFormCompanyComponent
   */
  public cedexControl: FormControl;

  isFrenchCountryObservable: Subscription;
  isFrenchCountry: boolean;
  isFirstLoad = true;
  /**
   * Generate the error message in case of SIRET doubleon
   */
  public siretExistWarning: string;
  /**
   * previouslySelectedIndex$
   *
   * @type {Subscription}
   * @memberof StepOneFormCompanyComponent
   */
  private previouslySelectedIndex$: Subscription;
  /**
   * _destroy$
   *
   * @type {Subject}
   * @memberof StepOneFormCompanyComponent
   */
  private readonly _destroy$: Subject<boolean> = new Subject<boolean>();
  /**
   * Step index.
   *
   * @type {number}
   * @memberof StepOneFormCompanyComponent
   */
  private readonly index = 0;

  /**
   * Creates an instance of StepOneFormCompanyComponent.
   *
   * @param {I18nService} i18nService
   * @param {AppConfigService} appConfigService
   * @param {ReferenceTablesService} referenceTablesService
   * @param {CompanyService} companyService
   * @param {PostalAddressService} postalAdressService
   * @param {FormUpdateService} formUpdateService
   * @param {SnackbarService} snackbarService
   *
   * @memberof StepOneFormCompanyComponent
   */
  constructor(
    public i18nService: I18nService,
    public appConfigService: AppConfigService,
    private readonly route: ActivatedRoute,
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly companyService: CompanyService,
    private readonly postalAdressService: PostalAddressService,
    private readonly formUpdateService: FormUpdateService,
    private readonly snackbarService: SnackbarService,
    private readonly configurableParamService: ConfigurableParamService,
  ) {}

  /**
   * Init method
   * @memberof StepOneFormCompanyComponent
   */
  ngOnInit(): void {
    // Process submitStep when user click on stepper.
    this.previouslySelectedIndex$ = this.companyService.previouslySelectedIndex.subscribe((index: number) => {
      if (index === this.index) {
        this.submitStep();
      }
    });
    this.parentForm.addControl('corporateName', new UntypedFormControl('', [Validators.required]));
    this.corporateNameControl = this.parentForm.controls.corporateName as FormControl;
    this.parentForm.addControl('tradingName', new UntypedFormControl('', [Validators.required]));
    this.tradingNameControl = this.parentForm.controls.tradingName as FormControl;
    this.parentForm.addControl('homeEntity', new UntypedFormControl('', []));
    this.homeEntityControl = this.parentForm.controls.homeEntity as FormControl;
    this.parentForm.addControl('intracommunityVAT', new UntypedFormControl('', []));
    this.intraCommunityVatControl = this.parentForm.controls.intracommunityVAT as FormControl;
    this.parentForm.addControl(
      'siret',
      new UntypedFormControl('', [Validators.required, Validators.minLength(14), isInteger()], [siretValidator(this.companyService)]),
    );
    this.siretControl = this.parentForm.controls.siret as FormControl;
    this.parentForm.addControl('capital', new UntypedFormControl(undefined, [Validators.required, Validators.min(0)]));
    this.capitalControl = this.parentForm.controls.capital as FormControl;
    this.parentForm.addControl('cedex', new UntypedFormControl('', []));
    this.cedexControl = this.parentForm.controls.cedex as FormControl;

    if (!this.isDeveloper) {
      this.parentForm.addControl('numCarteT', new UntypedFormControl('', [Validators.required]));
      this.numCarteTControl = this.parentForm.controls.numCarteT as FormControl;
      this.parentForm.addControl('finValiditeCarteT', new UntypedFormControl(undefined, [Validators.required]));
      this.carteTEndDateControl = this.parentForm.controls.finValiditeCarteT as FormControl;
    }

    // In create view, update the flag frenchy
    if (this.route.snapshot.data && typeof this.route.snapshot.data.isDeveloper === 'boolean') {
      this.postalAdressService.setIsFrenchCountry(true);
    }

    this.isFrenchCountryObservable = this.postalAdressService.isFrenchCountryObservable.subscribe((isfrenchCountry) => {
      if (typeof isfrenchCountry === 'boolean') {
        this.isFrenchCountry = isfrenchCountry;
        // prevent 'isFrench' to be undefined when setting the values of parentForm controls keys
        // eslint-disable-next-line @typescript-eslint/dot-notation
        if (this.recoveredInfo) {
          this.recoveredInfo.isFrench = isfrenchCountry;
          if (this.isFirstLoad) {
            this.formUpdateService.valueChanged('stepOneFormCompany');
            if (isfrenchCountry) {
              this.siretControl.setAsyncValidators(siretValidator(this.companyService, this.recoveredInfo.siret));
            }
            // otherwise we remove the option representing legal
            const keys = Object.keys(this.parentForm.controls);
            keys.forEach((key) => {
              // We verify that the 2 forms have identical data
              this.parentForm.controls[key].setValue(this.recoveredInfo[key]);
              this.parentForm.controls[key].updateValueAndValidity();
            });
          }
        }
        if (!isfrenchCountry) {
          this.siretControl.clearValidators();
          this.siretControl.clearAsyncValidators();
          this.siretControl.updateValueAndValidity();
          if (!this.isDeveloper) {
            this.numCarteTControl.clearValidators();
            this.numCarteTControl.updateValueAndValidity();
            this.carteTEndDateControl.clearValidators();
            this.carteTEndDateControl.updateValueAndValidity();
          }
          if (this.parentForm.controls.cciId) {
            this.parentForm.controls.cciId.clearValidators();
            this.parentForm.controls.cciId.updateValueAndValidity();
            this.parentForm.controls.commercialCourtInseeCode.clearValidators();
            this.parentForm.controls.commercialCourtInseeCode.updateValueAndValidity();
            this.parentForm.controls.nafId.clearValidators();
            this.parentForm.controls.nafId.updateValueAndValidity();
          }
        } else if (!this.isDeveloper) {
          this.numCarteTControl.setValidators(Validators.required);
          this.numCarteTControl.setValidators(Validators.pattern('[0-9]*'));
          this.numCarteTControl.updateValueAndValidity();
          this.carteTEndDateControl.setValidators(Validators.required);
          this.carteTEndDateControl.updateValueAndValidity();
        }
      }
    });
    forkJoin([
      // Get Commercial Courts
      this.referenceTablesService.getTableReferenceInfoWithZipCodes('commercialCourt'),
      // Get CCI
      this.referenceTablesService.getTableReferenceInfoWithZipCodes('cci'),
      // Get Nafs
      this.referenceTablesService.getTableReferenceInfoWithZipCodes('nafs'),
    ]).subscribe(
      (itemsFound) => {
        this.commercialCourtItems = itemsFound[0];
        this.cciItems = itemsFound[1];
        this.nafItems = itemsFound[2];
      },
      () => {
        this.snackbarService.sendErrorOccured();
      },
    );

    // We retrieve developer or contractor phone number.
    // This data are available in the footer service.
    this.configurableParamService.loggedFooterResponse$
      .pipe(takeUntil(this._destroy$))
      .subscribe((loggedFooterData: LoggedFooterResponse) => {
        let paramValue = '';
        if (loggedFooterData) {
          paramValue = this.isDeveloper ? loggedFooterData.developerContactInfo.phone : loggedFooterData.contractorContactInfo.phone;
        }
        this.siretExistWarning = this.i18nService._('Error_Field_Siret', [paramValue]);
      });
  }

  /**
   * Get Submit on first step
   *
   * @memberof StepOneFormCompanyComponent
   */
  submitStep(): void {
    this.isSubmit = true;
    // Check if there is an error on the form
    if (!this.parentForm.valid) {
      const message: SnackbarMessage = {
        text: this.i18nService._('Error_SnackBar_CompanyIncomplete'),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
    }
  }

  ngOnDestroy(): void {
    this.previouslySelectedIndex$.unsubscribe();
    this.isFrenchCountryObservable.unsubscribe();
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  /**
   * Control a number
   *
   * @param {*} evt
   * @returns
   * @memberof StepOneFormCompanyComponent
   */
  checkIsNumber(evt): string {
    let evtValue = '';
    evt.target.value.split('').forEach((char) => {
      evtValue += Number.isInteger(parseInt(char, 10)) ? char : '';

      return evtValue;
    });

    return (evt.target.value = evtValue);
  }
}
