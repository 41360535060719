import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription, take } from 'rxjs';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgFor, NgIf } from '@angular/common';

import { PageProgramHelperService } from '../page-program-helper.service';
import { ProgramPageNavigationHeaderComponent } from '../program-page-navigation-header/program-page-navigation-header.component';

import { AlertEnum } from '../../../../utils/models/enums/alert.enum';
import { I18nService } from '../../../../utils/services/i18n.service';
import { ToggleFeesService } from '../../../../utils/services/toggle-fees.service';
import { AnchorEnum } from '../../../models/enum/anchor.enum';
import { ProgramPageService } from '../../../services/program-page.service';
import { ProgramDetail } from '../../../../utils/models/ProgramDetail';
import { RoutingStateService } from '../../../../utils/services/routing-state.service';
import { Sitemap } from '../../../../utils/models/Sitemap';
import { DEFAULT_SEARCH_PROGRAM_PARAMS } from '../../../../search/model/search-page-contantes';
import { FeesComponent } from '../../../../design-system/component/fees/fees.component';
import { TagsComponent } from '../../../../design-system/component/tags/tags.component';
import { AlertComponent } from '../../../../utils/components/alert/alert.component';

@Component({
  selector: 'app-program-page-header',
  templateUrl: './program-page-header.component.html',
  styleUrls: ['./program-page-header.component.scss'],
  standalone: true,
  imports: [ProgramPageNavigationHeaderComponent, NgIf, AlertComponent, MatIconModule, NgFor, TagsComponent, NgClass, FeesComponent],
})
export class ProgramPageHeaderComponent implements OnInit, OnDestroy {
  /**
   * Output to tell parent to scroll to specific element within AnchorEnum
   *
   * @type {EventEmitter<AnchorEnum>}
   * @memberof ProgramPageHeaderComponent
   */
  @Output() readonly $shouldScrollTo: EventEmitter<AnchorEnum>;

  public anchorEnum = AnchorEnum;
  public hideFees = true;
  public alertEnum = AlertEnum;

  private readonly tearDownSubscription: Subscription = new Subscription();
  program: ProgramDetail;
  private returnPath: string;

  constructor(
    private readonly toggleFeesService: ToggleFeesService,
    public i18nService: I18nService,
    private _programPageService: ProgramPageService,
    private pageProgramHelperService: PageProgramHelperService,
    private _routingStateService: RoutingStateService,
    private _router: Router,
  ) {
    this.$shouldScrollTo = new EventEmitter<AnchorEnum>();
  }

  get programTags() {
    return this._programPageService.programTags;
  }

  get fees() {
    return this._programPageService.fees;
  }

  get maxFees() {
    return this._programPageService.maxFees;
  }

  get hasFeesRange() {
    return this._programPageService.hasFeesRange;
  }

  ngOnInit() {
    this._routingStateService.previousRoutePath.pipe(take(1)).subscribe((path) => {
      this.returnPath = path;
    });
    this.program = this.pageProgramHelperService.program;
    this.tearDownSubscription.add(this.toggleFeesService.toggleFeesState$.subscribe((state) => (this.hideFees = state)));
  }

  ngOnDestroy(): void {
    this.tearDownSubscription.unsubscribe();
  }

  scrollTo(anchor: AnchorEnum) {
    this.$shouldScrollTo.emit(anchor);
  }

  goBack() {
    return () => {
      const previousUrl = this.returnPath ?? Sitemap.utils.search.path;
      const queryParams = this.returnPath ? undefined : DEFAULT_SEARCH_PROGRAM_PARAMS;
      this._router.navigate([previousUrl], { queryParams });
    };
  }
}
