<div [hidden]="dataSource.data.length" class="my-4 text-center">
  <span *ngIf="textTableEmpty">
    {{ textTableEmpty }}
  </span>
  <span *ngIf="!textTableEmpty">
    {{ i18nService._('Txt_Table_Empty') }}
  </span>
</div>
<div [hidden]="!dataSource.data.length">
  <div class="grid grid-cols-2 gap-16">
    <div class="mb-4">
      <div *ngIf="activateColumnsToDisplay">
        <mat-form-field *ngIf="activateFilter" class="w-full">
          <mat-label>{{ i18nService._('Txt_Placeholder_Filter') }}</mat-label>
          <input matInput (keyup)="applyFilter($any($event.target).value)" />
        </mat-form-field>
      </div>
      <div *ngIf="!activateColumnsToDisplay">
        <mat-form-field *ngIf="activateFilter" class="w-full">
          <mat-label>{{ i18nService._('Txt_Placeholder_Filter') }}</mat-label>
          <input matInput (keyup)="applyFilter($any($event.target).value)" />
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="activateColumnsToDisplay" class="mb-4">
      <mat-form-field class="mat-form-field-fullWidth">
        <mat-label>{{ i18nService._('Txt_Placeholder_ColumnsToDisplay') }}</mat-label>
        <mat-select [formControl]="columnsToDisplaySelector" [(ngModel)]="selectedOptions" multiple>
          <mat-select-trigger>
            {{ displaySelect() }}
          </mat-select-trigger>
          <mat-option *ngFor="let column of allColumns" [value]="column">
            {{ getColumnTitle(column) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div>
    <div class="shadow-sm overflow-auto">
      <div *ngIf="!dataSource.filteredData.length" class="my-4">
        <span *ngIf="textTableEmpty">{{ textTableEmpty }}</span>
        <span *ngIf="!textTableEmpty">{{ i18nService._('Txt_Table_Empty') }}</span>
      </div>

      <table
        *ngIf="dataSource.filteredData.length"
        mat-table
        [dataSource]="dataSource"
        matSort
        [matSortDisabled]="!activateSort"
        [matSortActive]="columnSort"
        [matSortDirection]="columnSortOrder"
      >
        <ng-container *ngIf="activateCheckboxSelection" matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="text-left">
            <mat-checkbox
              color="primary"
              (change)="onCheckedChangeRow($event, 'master')"
              [checked]="selection.hasValue()"
              [disabled]="readonly"
              [indeterminate]="selection.hasValue()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              color="primary"
              (click)="$event.stopPropagation()"
              [disabled]="readonly || row[disableField]"
              (change)="onCheckedChangeRow($event, row)"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{ column }}" *ngFor="let column of displayedColumns">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!isSortable(column)" class="text-xs text-black px-2 text-left">
            {{ getColumnTitle(column) }}
          </th>
          <td mat-cell *matCellDef="let element" class="px-2">
            <ng-container *ngIf="isNgTemplate(element, column); else elseBlock" [ngTemplateOutlet]="element[column]"></ng-container>
            <ng-template #elseBlock>
              <span
                class="text-xs"
                [ngClass]="{
                  'text-black': !element[disableField],
                  'txt-grey': element[disableField]
                }"
                [innerHTML]="getColumnFormat(column, element)"
              ></span>
            </ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="matColumnsDef; sticky: stickyHeader"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: matColumnsDef"
          class="{{ row?.isComple?.background }}"
          [ngClass]="{ 'bg-graylight': row[disableField] }"
          [matTooltip]="row.tooltip"
          [matTooltipPosition]="'above'"
        ></tr>
      </table>
    </div>
  </div>

  <mat-paginator
    class="bg-transparent"
    *ngIf="activatePaginator"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
