import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, catchError, Observable } from 'rxjs';

import { NeededInformationsForAcountCreation } from '../models/NeededInformationsForAcountCreation';
import { NeededInformationsForAcountUpdate } from '../models/NeededInformationsForAcountUpdate';

import { DepartmentResponse } from '../../utils/models/DepartmentResponse';
import { AppConfigService } from '../../utils/services/app-config.service';
import { TokenService } from '../../utils/services/authorisation/token.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class AccountUtilsService {
  private executeCounterProgramsLogin: BehaviorSubject<boolean>;

  private arrayValoSysRole = [];
  private arrayValoAdminRole = [];
  private arrayValoBoRole = [];
  private arrayValoRcvRole = [];
  private arrayValoDevRole = [];
  private arrayValoMarketerRole = [];

  private arrayValoRole = [];

  private arrayDeveloperRole = [];

  private arrayContractorOrIndependantRole = [];

  constructor(
    public readonly route: ActivatedRoute,
    public readonly appConfigService: AppConfigService,
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
    private readonly tokenService: TokenService,
  ) {
    this.executeCounterProgramsLogin = new BehaviorSubject<boolean>(true);
    this.arrayValoSysRole = [this.appConfigService._('roles', 'valoSystem')];
    this.arrayValoAdminRole = [this.appConfigService._('roles', 'valoAdminSuper'), this.appConfigService._('roles', 'valoAdminSimple')];
    this.arrayValoBoRole = [this.appConfigService._('roles', 'valoBoSuper'), this.appConfigService._('roles', 'valoBoSimple')];
    this.arrayValoRcvRole = [this.appConfigService._('roles', 'valoRcvSuper'), this.appConfigService._('roles', 'valoRcvSimple')];
    this.arrayValoDevRole = [this.appConfigService._('roles', 'valoDevSuper'), this.appConfigService._('roles', 'valoDevSimple')];
    this.arrayValoMarketerRole = [
      this.appConfigService._('roles', 'valoMarketerSuper'),
      this.appConfigService._('roles', 'valoMarketerSimple'),
    ];

    this.arrayValoRole = [
      ...this.arrayValoSysRole,
      ...this.arrayValoAdminRole,
      ...this.arrayValoBoRole,
      ...this.arrayValoRcvRole,
      ...this.arrayValoDevRole,
      ...this.arrayValoMarketerRole,
    ];

    this.arrayDeveloperRole = [
      this.appConfigService._('roles', 'developerHyper'),
      this.appConfigService._('roles', 'developerSuper'),
      this.appConfigService._('roles', 'developerSimple'),
    ];

    this.arrayContractorOrIndependantRole = [
      this.appConfigService._('roles', 'contractorHyper'),
      this.appConfigService._('roles', 'contractorSuper'),
      this.appConfigService._('roles', 'contractorSimple'),

      this.appConfigService._('roles', 'independantNetworkHeader'),
      this.appConfigService._('roles', 'independantNetworkLeader'),
      this.appConfigService._('roles', 'independant'),
    ];
  }

  get executeCounterProgramsLoginObservable(): Observable<boolean> {
    return this.executeCounterProgramsLogin.asObservable();
  }

  setExecuteCounterProgramsLogin(executeCounterProgramsLogin: boolean): void {
    this.executeCounterProgramsLogin.next(executeCounterProgramsLogin);
  }

  /**
   * Get all departments from database
   *
   * @returns {Observable<DepartmentResponse[]>}
   * @memberof AccountService
   */
  getDepartments(): Observable<Array<DepartmentResponse>> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Departements`;

    return this.http
      .get<Array<DepartmentResponse>>(url)
      .pipe(catchError(this.errorHandlerService.handleError<Array<DepartmentResponse>>('createAccountService', 'getDepartments')));
  }

  /**
   * We recover all the information needed to initialize the creation form
   *
   * @returns {Observable<DepartmentResponse[]>}
   * @memberof AccountService
   */
  getInfosForCreate(parentId): Observable<NeededInformationsForAcountCreation> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Accounts/getInfosForCreate/${parentId}`;

    return this.http
      .get<NeededInformationsForAcountCreation>(url)
      .pipe(
        catchError(this.errorHandlerService.handleError<NeededInformationsForAcountCreation>('createAccountService', 'getInfosForCreate')),
      );
  }

  /**
   * We recover all the information needed to initialize the creation form
   *
   * @returns {Observable<DepartmentResponse[]>}
   * @memberof AccountService
   */
  getInfosForUpdate(accountId): Observable<NeededInformationsForAcountUpdate> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Accounts/getInfosForUpdate/${accountId}`;

    return this.http
      .get<NeededInformationsForAcountUpdate>(url)
      .pipe(
        catchError(this.errorHandlerService.handleError<NeededInformationsForAcountUpdate>('createAccountService', 'getInfosForUpdate')),
      );
  }

  getParentAndLoggedUserInfos(accountId?: number): {
    parentId: number;
    loggedUserRoleName: string;
    loggedUserIsValo: boolean;
  } {
    const tokenInfos = this.tokenService.getToken();
    const loggedUserRoleName = tokenInfos.roleName;
    const parentId = accountId ? accountId : tokenInfos.userId;
    const loggedUserIsValo = this.arrayValoRole.includes(loggedUserRoleName);

    return {
      parentId,
      loggedUserRoleName,
      loggedUserIsValo,
    };
  }

  /**
   * Return if the logged user is valo
   *
   * @returns {boolean} If the logged user is valo
   * @memberof AccountService
   */
  isLoggedUserValo(): boolean {
    return this.arrayValoRole.includes(this.tokenService.getRoleName());
  }

  /**
   * Return if the logged user is developer
   *
   * @returns {boolean} If the logged user is developer
   * @memberof AccountService
   */
  isLoggedUserDeveloper(): boolean {
    return this.arrayDeveloperRole.includes(this.tokenService.getRoleName());
  }

  /**
   * Return if the logged user is contractor
   *
   * @returns {boolean} If the logged user is contractor
   * @memberof AccountService
   */
  isLoggedUserContractorOrIndependant(): boolean {
    return this.arrayContractorOrIndependantRole.includes(this.tokenService.getRoleName());
  }
}
