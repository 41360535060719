import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { DossierCreateResponse, PaternityCreateResponse, StatusInsertPaternitiesResponse } from '@commons-dto/dossier-prospect';
import { NgIf } from '@angular/common';

import { DossierProspectRouteService } from '../dossier-prospect/dossier-prospect.route.service';

import { Sitemap } from './../../../utils/models/Sitemap';
import { DossierProspectFormData } from './../../model/forms/dossier-prospect-form-data.model';
import { TokenService } from './../../../utils/services/authorisation/token.service';
import { DossierProspectFormlyService } from './../../services/dossier-prospect-formly.service';
import { DossierProspectService } from '../../services/dossier-prospect.service';
import { PaternityFormData } from '../../model/forms/paternity-form-data.model';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { DossierProspectAssociationService } from '../../../dialog/services/dossier-prospect-association.service';
import { DossierProspectTakeOptionData } from '../../../dialog/models/DossierProspectTakeOptionData';
import { PoseoptionResponse } from '../../../utils/models/PoseoptionResponse';
import { ValueLabelItem } from '../../../prospects/models/ValueLabelItem';
import { PaternityService } from '../../../prospects/services/paternity.service';

@Component({
  selector: 'app-paternity-create',
  templateUrl: './paternity-create.component.html',
  styleUrls: ['./paternity-create.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, FormlyModule],
})
export class PaternityCreateComponent implements OnInit {
  existingProspect: boolean;
  lotId: number;
  programId: number;
  companyId: number;
  fields: FormlyFieldConfig[];
  model = {};
  options: FormlyFormOptions = {};
  form = new UntypedFormGroup({});

  constructor(
    private readonly route: ActivatedRoute,
    private readonly _formlyGenerator: DossierProspectFormlyService,
    private readonly _tokenService: TokenService,
    private readonly _router: Router,
    private readonly prospectService: DossierProspectService,
    private readonly paternityService: PaternityService,
    private readonly dossierProspectAssociationService: DossierProspectAssociationService,
    private readonly snackbarService: SnackbarService,
    private readonly i18nService: I18nService,
    private readonly dossierProspectRouteService: DossierProspectRouteService,
  ) {}

  ngOnInit(): void {
    this.setupComponent();
  }

  private setupComponent(): void {
    this.initializeRouteParameters();
    this.initializeFormFields();
  }

  private initializeRouteParameters(): void {
    this.existingProspect = this.route.snapshot.params.existingProspect === 'true';
    this.lotId = +this.route.snapshot.queryParams.lotId;
    this.programId = +this.route.snapshot.queryParams.programId;
    this.companyId = +this.route.snapshot.queryParams.companyId;

    if (this.existingProspect) {
      this.prospectService.loadDossiersForSearching();
    }
    this.prospectService.getDevelopersFromMandate();
  }

  private initializeFormFields(): void {
    this.fields = this._formlyGenerator.getFormPaternity(!this.existingProspect, this.shouldHidePaternitySelection());
  }

  private shouldHidePaternitySelection(): boolean {
    return !!(this.lotId && this.programId && this.companyId);
  }

  async submit(): Promise<void> {
    if (!this.form.valid) {
      return;
    }

    const paternitiesFormData: PaternityFormData[] = this.createPaternitiesFromFormData(this.form.value.paternity_add_program);

    if (this.existingProspect) {
      this.createPaternity(paternitiesFormData);
    } else {
      this.createDossierPaternityAndOption(this.form.value, paternitiesFormData);
    }
  }

  private createPaternitiesFromFormData(paternities: ValueLabelItem[]): PaternityFormData[] {
    return paternities.map((paternity) => ({
      dossierId: this.form.value.paternity_create_existing_prospect ? this.form.value.paternity_create_existing_prospect.id : null,
      paternity_add_company: this.form.value.paternity_add_company.id,
      paternity_add_program: paternity.value,
    }));
  }

  private createPaternity(paternitiesFormData: PaternityFormData[]): void {
    this.paternityService.create(paternitiesFormData).subscribe((paternitiesCreateResponse: PaternityCreateResponse) => {
      const statusInsertPaternities = paternitiesCreateResponse.statusInsertPaternities;

      const message: SnackbarMessage = {
        type: this.getSnackbarMessageType(statusInsertPaternities),
        html: this.getSnackbarMessage(statusInsertPaternities),
        duration: 10000,
      };
      this.snackbarService.sendMessage(message);

      this.navigateToDossierProspectsDashboard(paternitiesFormData[0].dossierId);
    });
  }

  private getSnackbarMessageType(statusInsertPaternity: StatusInsertPaternitiesResponse): SnackbarMessageType {
    if (statusInsertPaternity.error > 0) {
      return statusInsertPaternity.success > 0 ? SnackbarMessageType.Warning : SnackbarMessageType.Error;
    }
    return SnackbarMessageType.Info;
  }

  private getSnackbarMessage(statusInsertPaternity: StatusInsertPaternitiesResponse): string {
    const { error, success } = statusInsertPaternity;
    const pluralSuffix = (count: number) => (count > 1 ? 'ies' : 'y');
    const errorText = error > 0 ? this.i18nService._(`Prospect_Paternit${pluralSuffix(error)}_Request_Error`, [error]) : '';
    const successText =
      success > 0
        ? `${this.i18nService._(`Prospect_Paternit${pluralSuffix(success)}_Request_Success`, [success])} 
          ${this.i18nService._(`Prospect_Paternit${pluralSuffix(success)}_Request_Detail_Outside_Paternity_Tab`, [success])}`
        : '';
    return [errorText, successText].filter(Boolean).join('<br>');
  }

  private async createDossierPaternityAndOption(
    dossierValue: DossierProspectFormData,
    paternitiesFormData: PaternityFormData[],
  ): Promise<void> {
    const response: DossierCreateResponse = await this.createDossier(dossierValue, paternitiesFormData);

    if (response.statusInsertPaternities) {
      const message: SnackbarMessage = {
        type: this.getSnackbarMessageType(response.statusInsertPaternities),
        html: this.getSnackbarMessage(response.statusInsertPaternities),
        duration: 10000,
      };
      this.snackbarService.sendMessage(message);
    }

    if (this.shouldTakeOption()) {
      this.takeOption(response.id);
    } else {
      this.navigateToDossierProspectsDashboard(response.id);
    }
  }

  private shouldTakeOption(): boolean {
    return !!(this.lotId && this.programId && this.companyId);
  }

  private async createDossier(
    dossierValue: DossierProspectFormData,
    paternitiesFormData: PaternityFormData[],
  ): Promise<DossierCreateResponse> {
    return firstValueFrom(this.prospectService.createDossier(dossierValue, this._tokenService.getUserId(), paternitiesFormData));
  }

  private takeOption(dossierId: number): void {
    const optionForm: DossierProspectTakeOptionData = {
      lotId: this.lotId,
      dossierProspectId: dossierId,
    };
    this.dossierProspectAssociationService.takeOption(optionForm).subscribe({
      next: (res) => {
        this.handleTakeOptionSuccess(res);
      },
      error: () => {
        this.handleTakeOptionError();
      },
    });
  }

  private handleTakeOptionSuccess(res: PoseoptionResponse): void {
    if (res) {
      this.snackbarService.sendMessage({
        text: this.i18nService._('Success_SnackBar_TakeOption'),
        type: SnackbarMessageType.Success,
      });
      this._router.navigate([Sitemap.programs.read.path.replace(':programId', this.programId.toString())], {
        queryParams: {
          lotId: this.lotId.toString(),
        },
      });
    }
  }

  private handleTakeOptionError(): void {
    this.snackbarService.sendMessage({
      text: this.i18nService._('Error_SnackBar_TakeOption'),
      type: SnackbarMessageType.Error,
    });
  }

  private navigateToDossierProspectsDashboard(dossierId: number): void {
    this.dossierProspectRouteService.goToDashboard(dossierId);
  }
}
