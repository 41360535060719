import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';

import { DataLayerApiService } from './data-layer-api.service';
import { TaxationResponse } from '../utils/models/TaxationResponse';

@Injectable({ providedIn: 'root' })
export class TaxationApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Get taxations
   *
   * @returns {TaxationResponse[]}
   * @memberof DataLayerApiService
   */
  async getTaxations(): Promise<TaxationResponse[]> {
    const path = this.BASE_URL + 'Taxations';
    const res: Observable<TaxationResponse[]> = await this.get(path);
    return await lastValueFrom(res);
  }
}
