import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { CountryResponse } from '../models/CountryResponse';
import { sanitize } from './utils/file-utils';

import { AutoCompleteService } from '../../common/form/auto-complete.service';
import { normalizedCountry } from '../../common/shared/model/countries.fr';

@Injectable({
  providedIn: 'root',
})
export class AppCountryService implements AutoCompleteService {
  findData(country: string): Observable<CountryResponse[]> {
    const filter = sanitize(country);
    const value = normalizedCountry.filter((country) => country.labelSanityzed.match(`^${filter}`));
    return of(value);
  }
}
