export enum ExternalDocumentLinkTypeEnum {
  DOCUMENTS_UTILS = 'DOCUMENTS_UTILS',
  START_WITH_VALORISSIMO = 'START_WITH_VALORISSIMO',
  FAQ = 'FAQ',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  HELP_CENTER = 'HELP_CENTER',
}

export interface ExternalDocumentLink {
  code: ExternalDocumentLinkTypeEnum;
  labelId: string;
  link: string;
}
