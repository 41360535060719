<app-program-page-navigation-header
  [goBack]="goBack()"
  [i18nService]="i18nService"
  [backLabel]="'Txt_page_program_header_back'"
  [showComparator]="true"
></app-program-page-navigation-header>
<app-alert
  *ngIf="program?.isFinancialStrategyAttained"
  [type]="alertEnum.INFO"
  [body]="'Txt_alert_financial_strategy'"
  class="mb-4"
></app-alert>
<div class="program-header-name">
  {{ program?.programName }}
</div>
<div *ngIf="program?.fullAdress" class="program-header-adress" (click)="scrollTo(anchorEnum.map)">
  <mat-icon fontSet="material-icons-outlined" inline="true">place_outline</mat-icon>
  <span
    >{{ program?.fullAdress }} - <span class="map-anchor whitespace-nowrap">{{ i18nService._('Txt_page_program_header_map') }}</span></span
  >
</div>

<div class="program-header-tags">
  <div class="program-header-tags-app">
    <app-tags *ngFor="let tag of programTags" [ngClass]="tag.ngClass" [tagOption]="tag.tagOption"></app-tags>
  </div>
  <div class="program-header-tags-app">
    <app-fees [fees]="fees" [hasFeesRange]="hasFeesRange" [maxFees]="maxFees" style="margin: 0 25px 5px 0"></app-fees>
  </div>
</div>
