import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { AgGridModule } from 'ag-grid-angular';

import { ProspectRendererComponent } from './renderers/prospect-renderer/prospect-renderer.component';
import { DossierProspectTrackingTableComponent } from './components/dossier-prospect-tracking-table/dossier-prospect-tracking-table';
import { LotsProgramTrackingTableComponent } from './components/lots-program-tracking-table/lots-program-tracking-table.component';
import { MandateCompaniesSelectionTableComponent } from './components/mandate-companies-selection-table/mandate-companies-selection-table.component';
import { AlotmentSelectionTableComponent } from './components/alotment-selection-table/alotment-selection-table.component';
import { ProgramFinancialStrategiesTrackingTableComponent } from './components/program-financial-stategy-tracking-table/program-financial-stategy-tracking-table.component';
import { ProgramMandateStrategiesTrackingTableComponent } from './components/program-mandate-strategies-tracking-table/program-mandate-strategies-tracking-table.component';
import { DesignSystemModule } from '../design-system/design-system.module';
import { PipesModule } from '../utils/pipes/pipes.module';
import { UtilsModule } from '../utils/utils.module';
import { AccountsTrackingTableComponent } from './components/accounts-tracking-table/accounts-tracking-table.component';
import { ActivitiesTrackingTableComponent } from './components/activities-tracking-table/activities-tracking-table.component';
import { AdvancedTrackingTableComponent } from './components/advanced-tracking-table/advanced-tracking-table.component';
import { BooleanFiltersTrackingTableComponent } from './components/advanced-tracking-table/filter/boolean-filters-tracking-table/boolean-filters-tracking-table.component';
import { DateFiltersTrackingTableComponent } from './components/advanced-tracking-table/filter/date-filters-tracking-table/date-filters-tracking-table.component';
import { I18nFiltersTrackingTableComponent } from './components/advanced-tracking-table/filter/i18n-filters-tracking-table/i18n-filters-tracking-table.component';
import { SelfSearchFiltersTrackingTableComponent } from './components/advanced-tracking-table/filter/self-search-filters-tracking-table/self-search-filters-tracking-table.component';
import { AdvancedTrackingTablesNoBackPageComponent } from './components/advanced-tracking-tables-no-back-page/advanced-tracking-tables-no-back-page.component';
import { CompaniesTrackingTableComponent } from './components/companies-tracking-table/companies-tracking-table.component';
import { ExcludeListDialogComponent } from './components/exclude-list-tracking-table/dialog/exclude-list-dialog.component';
import { ExcludeListTrackingTableComponent } from './components/exclude-list-tracking-table/exclude-list-tracking-table.component';
import { GridTrackingTableComponent } from './components/grid-tacking-table/grid-tracking-table.component';
import { MandateStrategiesTrackingTableComponent } from './components/mandate-strategies-tracking-table/mandate-strategies-tracking-table.component';
import { PreReservationsTrackingTableComponent } from './components/pre-reservations-tracking-table/pre-reservations-tracking-table.component';
import { ProgramsSalesTrackingTableComponent } from './components/programs-sales-tracking-table/programs-sales-tracking-table.component';
import { ProgramsTrackingTableComponent } from './components/programs-tracking-table/programs-tracking-table.component';
import { ReservationsTrackingTableComponent } from './components/reservations-tracking-table/reservations-tracking-table.component';
import { SalersTrackingTableComponent } from './components/salers-tracking-table/salers-tracking-table.component';
import { SavedSearchesTrackingTableComponent } from './components/saved-searches-tracking-table/saved-searches-tracking-table.component';
import { DossierTrackingTableComponent } from './components/dossiers-tracking-table/dossier-tracking-table.component';
import { SpecialOffersTrackingTableComponent } from './components/special-offers-tracking-table/special-offers-tracking-table.component';
import { AdvancedTrackingTablePipe } from './pipes/advanced-tracking-table.pipe';
import { TrackingTablesRoutingModule } from './tracking-tables-routing.module';
import { StatusTagsRendererComponent } from './renderers/status-tags-renderer/status-tags-renderer.component';
import { DateRendererComponent } from './renderers/date-renderer/date-renderer.component';
import { ActionRendererComponent } from './renderers/action-renderer/action-renderer.component';
import { PriceRendererComponent } from './renderers/price-renderer/price-renderer.component';
import { OptionsGridTrackingTableComponent } from './components/options-tracking-table/options-grid-tracking-table.component';
import { InformationRendererComponent } from './renderers/information/information-renderer.component';
import { ProspectsGridTrackingTableComponent } from './components/prospects-tracking-table/prospects-grid-tracking-table.component';
import { AccountTrackingTableTabComponent } from './components/accounts-tracking-table/accounts-tracking-table-tab/accounts-tracking-table-tab.component';
import { CompanyTrackingTableTabComponent } from './components/companies-tracking-table/companies-tracking-table-tab/companies-tracking-table-tab.component';
import { IconComponent } from '../common/standalone/icon/icon.component';
import { SelectionTrackingTableComponent } from './components/selection-tracking-table/selection-tracking-table.component';

@NgModule({
  imports: [
    CommonModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    TrackingTablesRoutingModule,
    UtilsModule,
    MatDatepickerModule,
    DesignSystemModule,
    PipesModule,
    AgGridModule,
    IconComponent,
    CompaniesTrackingTableComponent,
    ProgramsTrackingTableComponent,
    SpecialOffersTrackingTableComponent,
    AccountsTrackingTableComponent,
    PreReservationsTrackingTableComponent,
    AdvancedTrackingTableComponent,
    AdvancedTrackingTablePipe,
    ReservationsTrackingTableComponent,
    ActivitiesTrackingTableComponent,
    SalersTrackingTableComponent,
    ProgramsSalesTrackingTableComponent,
    MandateStrategiesTrackingTableComponent,
    SavedSearchesTrackingTableComponent,
    AdvancedTrackingTablesNoBackPageComponent,
    DossierTrackingTableComponent,
    ExcludeListDialogComponent,
    ExcludeListTrackingTableComponent,
    SelectionTrackingTableComponent,
    I18nFiltersTrackingTableComponent,
    SelfSearchFiltersTrackingTableComponent,
    BooleanFiltersTrackingTableComponent,
    DateFiltersTrackingTableComponent,
    GridTrackingTableComponent,
    StatusTagsRendererComponent,
    DateRendererComponent,
    ActionRendererComponent,
    PriceRendererComponent,
    OptionsGridTrackingTableComponent,
    InformationRendererComponent,
    ProspectsGridTrackingTableComponent,
    AccountTrackingTableTabComponent,
    CompanyTrackingTableTabComponent,
    ProgramMandateStrategiesTrackingTableComponent,
    ProgramFinancialStrategiesTrackingTableComponent,
    AlotmentSelectionTableComponent,
    MandateCompaniesSelectionTableComponent,
    LotsProgramTrackingTableComponent,
    DossierProspectTrackingTableComponent,
    ProspectRendererComponent,
  ],
  exports: [
    AdvancedTrackingTablesNoBackPageComponent,
    ProgramMandateStrategiesTrackingTableComponent,
    ProgramFinancialStrategiesTrackingTableComponent,
    AlotmentSelectionTableComponent,
    MandateCompaniesSelectionTableComponent,
    LotsProgramTrackingTableComponent,
    GridTrackingTableComponent,
  ],
  providers: [
    AdvancedTrackingTablePipe,
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'fr-FR',
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class TrackingTablesModule {}
