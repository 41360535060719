import { OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SpinnerWithBackdropAction } from '../../models/enums/spinner-with-backdrop-action.enum';
import { OverlayService } from '../../services/overlay.service';
import { SnackbarService } from '../../services/snackbar.service';
import { SpinnerWithBackdropService } from '../../services/spinner-with-backdrop.service';

@Component({
  selector: 'app-spinner-with-backdrop',
  templateUrl: './spinner-with-backdrop.component.html',
  styleUrls: ['./spinner-with-backdrop.component.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule],
})
export class SpinnerWithBackdropComponent implements OnInit, OnDestroy {
  /**
   * progressSpinnerRef attribute
   *
   * @private
   * @type {TemplateRef<any>}
   * @memberof SpinnerWithBackdropComponent
   */
  @ViewChild('progressSpinnerRef')
  private readonly progressSpinnerRef: TemplateRef<unknown>;
  /**
   * progressSpinnerOverlayConfig attribute
   *
   * @private
   * @type {OverlayConfig}
   * @memberof SpinnerWithBackdropComponent
   */
  private progressSpinnerOverlayConfig: OverlayConfig;
  /**
   * overlayRef attribute
   *
   * @private
   * @type {OverlayRef}
   * @memberof SpinnerWithBackdropComponent
   */
  private overlayRef: OverlayRef;
  /**
   * spinnerSubscription
   *
   * @private
   * @type {Subscription}
   * @memberof SpinnerWithBackdropComponent
   */
  private spinnerSubscription: Subscription;

  /**
   * Creates an instance of SpinnerWithBackdropComponent.
   * @param {ViewContainerRef} vcRef
   * @param {OverlayService} overlayService
   * @param {SpinnerWithBackdropService} spinnerWithBackdropService
   * @param {SnackbarService} snackbarService
   * @memberof SpinnerWithBackdropComponent
   */
  constructor(
    private readonly vcRef: ViewContainerRef,
    private readonly overlayService: OverlayService,
    private readonly spinnerWithBackdropService: SpinnerWithBackdropService,
    private readonly snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    // Config for Overlay Service
    this.progressSpinnerOverlayConfig = {
      hasBackdrop: true,
    };

    this.progressSpinnerOverlayConfig.positionStrategy = this.overlayService.positionGloballyCenter();

    // Create Overlay for progress spinner
    this.overlayRef = this.overlayService.createOverlay(this.progressSpinnerOverlayConfig);

    // Handle the subscription of the spinner service
    this.spinnerSubscription = this.spinnerWithBackdropService.spinnerAction.subscribe((subscriptionAction) => {
      switch (subscriptionAction) {
        case SpinnerWithBackdropAction.Show:
          this.showSpinnerWithBackdrop();
          break;
        case SpinnerWithBackdropAction.Hide:
          this.hideSpinnerWithBackdrop();
          break;
        default:
          this.snackbarService.sendErrorOccured();
          break;
      }
    });
  }

  ngOnDestroy(): void {
    // unsubscribe to ensure no memory leaks
    this.spinnerSubscription.unsubscribe();
  }

  /**
   * showSpinnerWithBackdrop method
   *
   * @memberof SpinnerWithBackdropComponent
   */
  showSpinnerWithBackdrop(): void {
    if (!this.overlayRef.hasAttached()) {
      this.overlayService.attachTemplatePortal(this.overlayRef, this.progressSpinnerRef, this.vcRef);
    }
  }

  /**
   * hideSpinnerWithBackdrop method
   *
   * @memberof SpinnerWithBackdropComponent
   */
  hideSpinnerWithBackdrop(): void {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}
