import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParamRequest } from '@commons-dto/valo-back';

import { DataLayerApiService } from './data-layer-api.service';
import { CompaniesTrackingTableItem } from '../tracking-tables/models/CompaniesTrackingTableItem';
import { CompaniesTrackingTableServiceResponse } from '../tracking-tables/models/CompaniesTrackingTableServiceResponse';
import { CompanyData } from '../utils/models/CompanyData';

@Injectable({ providedIn: 'root' })
export class CompanyApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Get companies by params
   *
   * @returns {Observable<CompaniesTrackingTableServiceResponse>}
   * @memberof DataLayerApiService
   */
  getCompanies(companyType: string, params: ParamRequest): Observable<CompaniesTrackingTableServiceResponse> {
    const path = this.BASE_URL + 'Companies/tracking-table/companies/' + companyType;
    return this.getTableRessources(params, path);
  }

  /**
   * Get company by id
   *
   * @returns {Observable<CompaniesTrackingTableItem>}
   * @memberof DataLayerApiService
   */
  getCompanyById(companyType: string, id: number): Observable<CompaniesTrackingTableItem> {
    // TODO: not implemented ?
    const path = this.BASE_URL + 'Companies/tracking-table/companies/' + companyType;
    return this.getRessource(path, id);
  }

  /**
   * Get company information by id
   *
   * @returns {Observable<CompaniesTrackingTableItem>}
   * @memberof DataLayerApiService
   */
  getCompanyInformationById(id: number): Observable<CompanyData> {
    const path = this.BASE_URL + 'Companies/informations/' + id;
    return this.get(path);
  }
}
