import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { findIndex } from 'lodash';
import { MatTabsModule } from '@angular/material/tabs';
import { NgIf } from '@angular/common';

import { AccountTrackingTableTabComponent } from './accounts-tracking-table-tab/accounts-tracking-table-tab.component';

import { UserRoleService } from './../../../utils/services/user-role.service';
import { CompanyType } from '../../../utils/models/enums/CompanyType.enum';
import { Sitemap } from '../../../utils/models/Sitemap';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { TokenService } from '../../../utils/services/authorisation/token.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { RoutingStateService } from '../../../utils/services/routing-state.service';
import { AbstractTrackingTable } from '../../abstract-tracking-table';
import { AccountTrackingTableItem } from '../../models/AccountTrackingTableItem';
import { AdvancedTrackingTableColumn } from '../../models/AdvancedTrackingTableColumn';
import { AccountsTrackingTableService } from '../../services/accounts-tracking-table.service';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';

export interface AccountTab {
  type: string;
  title: string;
  label: string;
  companyType: string;
  roles: Array<string>;
  columns: Array<AdvancedTrackingTableColumn>;
  serviceParams: string;
}

@Component({
  selector: 'app-accounts-management-tracking-table',
  templateUrl: './accounts-tracking-table.component.html',
  styleUrls: ['./accounts-tracking-table.component.scss'],
  standalone: true,
  imports: [StickyHeaderFormComponent, NgIf, AccountTrackingTableTabComponent, MatTabsModule],
})
export class AccountsTrackingTableComponent extends AbstractTrackingTable {
  companyType = CompanyType;
  /**
   * Default selected index tab.
   * @type {number}
   */
  public selectedTabIndex = 2;

  /**
   * Default tabs configurations.
   * @type {Array<AccountTab>}
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public configTabs: Array<any>;

  /**
   * tabs attribute
   * @type {Array<AccountTab>}
   */
  public tabs: Array<AccountTab>;

  /**
   * Tabs configuration.
   * @type {Array<string>}
   */
  public tabsTypes = [CompanyType.DEVELOPER, CompanyType.CONTRACTOR];

  /**
   * actionButtonTooltipText attribute
   * @type {string}
   */
  public actionButtonTooltipText: string;
  public rolesCanCreateAccounts = [
    'valoSystem',
    'valoAdminSuper',
    'valoBoSuper',
    'developerHyper',
    'developerSuper',
    'contractorHyper',
    'contractorSuper',
  ];
  /**
   * Display or not button to redirect to create account
   * @type {boolean}
   */
  public canCreateAccount: boolean;

  /**
   * Url to go to clicking on the goBack button.
   * @type {string}
   */
  public goBackUrl;

  public displayDeveloperTab: boolean;
  public displayContractorTab: boolean;
  public displayValorissimoTab: boolean;
  public displayIndependantTab: boolean;
  public displaySingleTab = false;

  /**
   * Creates an instance of AccountsTrackingTableComponent.
   * @param {AppConfigService} appConfigService
   * @param {AccountService} accountService
   * @param tokenService
   * @param {I18nService} i18nService
   * @param {AccountsTrackingTableService} service
   * @param {Router} router
   * @param _route
   * @param _reloadService
   */
  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly tokenService: TokenService,
    public readonly i18nService: I18nService,
    public readonly service: AccountsTrackingTableService,
    public readonly router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _routingStateService: RoutingStateService,
    private readonly userRoleService: UserRoleService,
  ) {
    super(_route.snapshot.routeConfig.path, router);

    const roles = {
      DEVELOPER: [
        appConfigService.appConfig.roles.valoSystem,
        appConfigService.appConfig.roles.valoAdminSuper,
        appConfigService.appConfig.roles.valoAdminSimple,
        appConfigService.appConfig.roles.valoBoSuper,
        appConfigService.appConfig.roles.valoBoSimple,
        appConfigService.appConfig.roles.valoDevSuper,
        appConfigService.appConfig.roles.valoDevSimple,
        appConfigService.appConfig.roles.developerHyper,
        appConfigService.appConfig.roles.developerSuper,
      ],
      CONTRACTOR: [
        appConfigService.appConfig.roles.valoSystem,
        appConfigService.appConfig.roles.valoAdminSuper,
        appConfigService.appConfig.roles.valoAdminSimple,
        appConfigService.appConfig.roles.valoBoSuper,
        appConfigService.appConfig.roles.valoBoSimple,
        appConfigService.appConfig.roles.valoRcvSuper,
        appConfigService.appConfig.roles.valoRcvSimple,
        appConfigService.appConfig.roles.contractorHyper,
        appConfigService.appConfig.roles.contractorSuper,
      ],
      VALORISSIMO: [
        appConfigService.appConfig.roles.valoSystem,
        appConfigService.appConfig.roles.valoAdminSuper,
        appConfigService.appConfig.roles.valoAdminSimple,
        appConfigService.appConfig.roles.valoBoSuper,
        appConfigService.appConfig.roles.valoBoSimple,
        appConfigService.appConfig.roles.valoDevSuper,
        appConfigService.appConfig.roles.valoRcvSuper,
        appConfigService.appConfig.roles.valoMarketerSuper,
      ],
      INDEPENDANT: [
        appConfigService.appConfig.roles.valoSystem,
        appConfigService.appConfig.roles.valoAdminSuper,
        appConfigService.appConfig.roles.valoAdminSimple,
        appConfigService.appConfig.roles.valoBoSuper,
        appConfigService.appConfig.roles.valoBoSimple,
        appConfigService.appConfig.roles.valoRcvSuper,
        appConfigService.appConfig.roles.independantNetworkHeader,
        appConfigService.appConfig.roles.independantNetworkLeader,
      ],
    };

    this.displayDeveloperTab = userRoleService.isInRoles(roles.DEVELOPER);
    this.displayContractorTab = userRoleService.isInRoles(roles.CONTRACTOR);
    this.displayValorissimoTab = userRoleService.isInRoles(roles.VALORISSIMO);
    this.displayIndependantTab = userRoleService.isInRoles(roles.INDEPENDANT);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const configTabs: Array<any> = [];
    if (this.displayDeveloperTab)
      configTabs.push({
        type: CompanyType.DEVELOPER,
        title: 'Title_TrackingTable_DeveloperAccounts',
        path: Sitemap.accounts.adminDeveloper.path,
      });
    if (this.displayContractorTab)
      configTabs.push({
        type: CompanyType.CONTRACTOR,
        title: 'Title_TrackingTable_ContractorAccounts',
        path: Sitemap.accounts.adminContractor.path,
      });
    if (this.displayValorissimoTab)
      configTabs.push({
        type: CompanyType.VALORISSIMO,
        title: 'Title_TrackingTable_ValorissimoAccounts',
        path: Sitemap.accounts.admin.path,
      });
    if (this.displayIndependantTab)
      configTabs.push({
        type: CompanyType.INDEPENDANT,
        title: 'Title_TrackingTable_IndependantAccounts',
        path: Sitemap.accounts.adminIndependant.path,
      });
    this.configTabs = configTabs;

    let nbTab = 0;
    if (this.displayDeveloperTab) nbTab++;
    if (this.displayContractorTab) nbTab++;
    if (this.displayValorissimoTab) nbTab++;
    if (this.displayIndependantTab) nbTab++;
    if (nbTab == 1) {
      this.displaySingleTab = true;
    }

    this._routingStateService.previousRoutePath.pipe(take(1)).subscribe((previousRoutePath) => {
      this.goBackUrl = previousRoutePath;
    });

    const loggedUserInfos = tokenService.getToken();
    if (nbTab === 1) {
      this.selectedTabIndex = 0;
    } else {
      this.selectedTabIndex = findIndex(this.configTabs, (tab) => tab.type === this._route.snapshot.data.type);
    }
    this.canCreateAccount = this.rolesCanCreateAccounts.includes(loggedUserInfos.roleName);
  }

  /**
   * Create an account.
   *
   * @param element
   * @memberof AccountsTrackingTableComponent
   */
  public createAccount(element?: AccountTrackingTableItem): void {
    this.router.navigate([
      element ? Sitemap.accounts.registerById.path.replace(/:id/, element.id.toString()) : Sitemap.accounts.register.path,
    ]);
  }

  /**
   * Change value of the title in the sub-header when changing tab.
   *
   * @param event
   */
  public changeAndReloadTab(event: number): void {
    this.selectedTabIndex = event;
    const newUrl = this._processUrl();
    this.router.navigate([newUrl], {
      queryParamsHandling: '',
      replaceUrl: true,
    });
  }

  private _processUrl() {
    return this.configTabs[this.selectedTabIndex].path;
  }
}
