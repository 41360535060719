<ng-container *ngIf="programData$ | async; else loading">
  <div class="layout-program-page layout-1-item program-header">
    <app-program-page-header ($shouldScrollTo)="scrollTo($event)"></app-program-page-header>
  </div>

  <!-- 1 row, 2 columns + 1 floating right in desktop -->
  <div class="w-100 d-none 3xl:block hidden">
    <div class="layout-program-page layout-prospective-info-item float-left mt-0">
      <app-program-page-prospective></app-program-page-prospective>
      <app-program-page-main-infos ($shouldScrollTo)="scrollTo($event)"></app-program-page-main-infos>
    </div>
    <app-program-page-rpv-card *ngIf="isRpvOrAdmin" class="float-left mt-0"></app-program-page-rpv-card>
  </div>
  <!-- 3 rows, 1 column in tablets and below -->
  <div class="3xl:hidden layout-program-page layout-prospective-info-item">
    <app-program-page-prospective></app-program-page-prospective>
    <app-program-page-main-infos ($shouldScrollTo)="scrollTo($event)"></app-program-page-main-infos>
  </div>
  <app-program-page-rpv-card *ngIf="isRpvOrAdmin" class="layout-program-page 3xl:hidden"></app-program-page-rpv-card>

  <div class="layout-program-page layout-1-item anchor-wrapper">
    <div [anchorName]="anchorEnum.highlight" appAnchor class="anchor"></div>
    <app-program-page-highlights></app-program-page-highlights>
  </div>

  <div class="layout-program-page layout-1-item program-lot anchor-wrapper">
    <div [anchorName]="anchorEnum.ficheLot" appAnchor class="anchor"></div>
    <app-program-page-lots-list ($shouldScrollTo)="scrollTo($event)" data-cy="seeAllLink"></app-program-page-lots-list>
  </div>
  <div class="layout-program-page layout-1-item anchor-wrapper">
    <div [anchorName]="anchorEnum.document" appAnchor class="anchor"></div>
    <app-program-page-documents-list></app-program-page-documents-list>
  </div>
  <div class="layout-program-page layout-1-item anchor-wrapper">
    <div [anchorName]="anchorEnum.map" appAnchor class="anchor"></div>
    <app-program-page-neighborhood-map></app-program-page-neighborhood-map>
  </div>
  <div class="layout-program-page layout-1-item anchor-wrapper">
    <div [anchorName]="anchorEnum.programDescription" appAnchor class="anchor"></div>
    <app-program-page-description></app-program-page-description>
  </div>
  <div class="layout-program-page layout-1-item anchor-wrapper">
    <div [anchorName]="anchorEnum.infoDeveloper" appAnchor class="anchor"></div>
    <app-program-page-promoter-infos></app-program-page-promoter-infos>
  </div>

  <app-target2sell-reco [target2SellRecoId]="target2SellRecoId" class="mb-5"></app-target2sell-reco>
</ng-container>
<ng-template #loading>
  <div class="layout-program-page layout-1-item anchor-wrapper">
    <div [anchorName]="anchorEnum.infoDeveloper" appAnchor class="anchor"></div>
    <div>je suis les info promotteur</div>
  </div>
</ng-template>
