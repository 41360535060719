import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgFor } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';

import { AbstractSearchFilter } from '../abstract-search-filter';

import { PROGRAM_TYPE_ICON } from '../../../../utils/models/app-constant';
import { ProgramTypeResponse } from '../../../../utils/models/ProgramTypeResponse';
import { ReferenceTableData } from '../../../../utils/models/ReferenceTableData';
import { I18nService } from '../../../../utils/services/i18n.service';
import { ReferenceTablesService } from '../../../../utils/services/reference-tables.service';
import { SnackbarService } from '../../../../utils/services/snackbar.service';

@Component({
  selector: 'app-search-filter-program-type',
  templateUrl: './search-filter-program-type.component.html',
  styleUrls: ['./search-filter-program-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatChipsModule, NgFor, MatIconModule],
})
export class SearchFilterProgramTypeComponent extends AbstractSearchFilter {
  public tableItems: ReferenceTableData[];
  public programTypeIcon = PROGRAM_TYPE_ICON;

  constructor(
    public i18nService: I18nService,
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly snackbarService: SnackbarService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    this.referenceTablesService.getTableReferenceInfo('ProgramTypes').subscribe(
      (items: Array<ProgramTypeResponse>) => {
        this.tableItems = items
          .filter((item: ProgramTypeResponse) => item.lotType)
          .sort((first: ProgramTypeResponse, second: ProgramTypeResponse) => {
            return first.priority < second.priority ? -1 : first.priority > second.priority ? 1 : 0;
          })
          .map((tableItem) => {
            const item = new ReferenceTableData();
            item.id = tableItem.id;
            item.label = this.i18nService._(tableItem.label);

            return item;
          });

        super.ngOnInit();
      },
      () => {
        this.snackbarService.sendErrorOccured();
      },
    );
  }

  selectItem(item: ReferenceTableData) {
    item.selected = !item.selected;
    this.search.programTypeIds = this.tableItems.filter((value) => value.selected).map((value) => value.id as number);
    this._searchFormUtilsService.setSearchFilter({ where: this.search }, this.waitForSearch);
    this._markForCheck(this.changeDetectorRef);
  }

  protected initData() {
    this.tableItems.forEach((value) => {
      value.selected = this.search.programTypeIds && this.search.programTypeIds.includes(value.id as number);
    });
    this._markForCheck(this.changeDetectorRef);
  }
}
