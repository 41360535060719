import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { AsyncPipe, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, map, Observable, take } from 'rxjs';
import { AcountTypeEnum } from '@commons-dto/valo-back';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AccountResponse } from '../../../utils/models/AccountResponse';
import { CompanyContact } from '../../../utils/models/CompanyContact';
import { DepartmentResponse } from '../../../utils/models/DepartmentResponse';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { PostalAddress } from '../../../utils/models/PostalAddress';
import { ReferenceTableData } from '../../../utils/models/ReferenceTableData';
import { Sitemap } from '../../../utils/models/Sitemap';
import { AccountService } from '../../../utils/services/account.service';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { ReferenceTablesService } from '../../../utils/services/reference-tables.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { AccountInfos } from '../../models/AccountInfos';
import { AccountUtilsService } from '../../services/account-utils.service';
import { RoutingStateService } from '../../../utils/services/routing-state.service';
import { ChipPopulatedAutocompleteComponent } from '../../../utils/components/chip-populated-autocomplete/chip-populated-autocomplete.component';
import { PostalAddressComponent } from '../../../utils/components/postal-address/postal-address.component';
import { ContactInformationComponent } from '../../../utils/components/contact-information/contact-information.component';
import { MatVerticalStepperScrollerDirective } from '../../../utils/directives/mat-vertical-stepper-scroller.directive';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';
import { ValoRole } from '../../../../../../../libs/commons-dto/src/lib/valo-back/valo-role.enum';

@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss'],
  standalone: true,
  imports: [
    StickyHeaderFormComponent,
    FormsModule,
    MatStepperModule,
    MatVerticalStepperScrollerDirective,
    ReactiveFormsModule,
    ContactInformationComponent,
    PostalAddressComponent,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatCheckboxModule,
    MatButtonModule,
    MatRadioModule,
    NgFor,
    ChipPopulatedAutocompleteComponent,
    TextFieldModule,
    AsyncPipe,
  ],
})
export class AccountFormComponent implements OnInit {
  // Set stepper when ElementRef is ready.
  @ViewChild('stepper') step: MatStepper;

  @Input() infosAboutAccount: AccountInfos;

  @Input() pageTitle: string;

  @Input() roleTypeName: string;

  @Input() loggedUserIsValo: boolean;

  @Output() readonly notify: EventEmitter<unknown> = new EventEmitter();

  /**
   * availableAffilation$
   *
   * @type {Observable<Map<string, any>>}
   * @memberOf CreateAccountComponent
   */
  public availableAffilation$: Observable<Map<string, AccountResponse>>;

  /**
   * otheravailableAffilation$ for developer tiers
   *
   * @type {Observable<Map<string, any>>}
   * @memberOf CreateAccountComponent
   */
  public otherAvailableAffilation$: Observable<Map<string, AccountResponse>>;

  /**
   * defaultAffilation
   *
   * @type {string}
   * @memberOf CreateAccountComponent
   */
  public defaultResponsible: string;

  /**
   * otherDefaultResponsible
   *
   * @type {string}
   * @memberOf CreateAccountComponent
   */
  public otherDefaultResponsible: string;

  public defaultHasBo: string[];

  public defaultHasDepartment: string[];

  /**s
   * availableDepartment$
   *
   * @type {Observable<Map<string, any>>}
   * @memberOf CreateAccountComponent
   */
  public availableDepartment$: Observable<Map<string, DepartmentResponse>>;
  accountPostalInfos: PostalAddress;
  accountPersonalData: CompanyContact;
  // boolean for knox if parent is Valo or not
  parentIsValorissimo: boolean;
  parentIsContractor: boolean;
  parentIsIndependant: boolean;
  isRpvOrDeveloper: boolean;
  companyTypeList: Array<ReferenceTableData> = [];
  profilList: Array<ReferenceTableData> = [];
  valoRoleTypeList: Array<ReferenceTableData> = [];
  public isSubmit = false;
  requestArray = [];
  responseArray = [];
  personnalDataForm: UntypedFormGroup;
  professionnalFunctionControl: AbstractControl;
  valorissimoPartForm: UntypedFormGroup;
  parentInfosControl: AbstractControl;
  companyTypeControl: AbstractControl;
  profilTypeControl: AbstractControl;
  valoRoleTypeControl: AbstractControl;
  isVisibleCommercialLotControl: AbstractControl;
  isForbiddenControl: AbstractControl;
  isFeesHiddenControl: AbstractControl;
  nbOptionsMaxControl: AbstractControl;
  isActiveControl: AbstractControl;
  loginControl: AbstractControl;
  commentBoControl: AbstractControl;
  suiviCollab = 'administration/utilisateurs';
  isModification: boolean;
  buttonText: string;
  fieldMaxLength: number;
  nbrOptionMaxValue: number;
  nbrOptionMinValue: number;
  responsibleTitle: string;
  // Item number of the current selected step.
  selectedIndex = 0;
  defaultGoBackUrl: string;
  /**
   * titleCasePipe
   *
   * @type {TitleCasePipe}
   * @memberOf AccountService
   */
  private readonly titleCasePipe: TitleCasePipe;
  isResponsibleRequired = true;
  isOtherResponsableDisabled = false;

  constructor(
    public readonly appConfigService: AppConfigService,
    public readonly i18nService: I18nService,
    public readonly referenceTablesService: ReferenceTablesService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly router: Router,
    public readonly route: ActivatedRoute,
    private readonly accountService: AccountService,
    private readonly accountUtilsService: AccountUtilsService,
    private readonly snackbarService: SnackbarService,
    private readonly routingStateService: RoutingStateService,
  ) {
    this.titleCasePipe = new TitleCasePipe();
    this.defaultGoBackUrl = Sitemap.accounts.admin.path;
  }

  ngOnInit(): void {
    this.fieldMaxLength = parseInt(this.appConfigService._('importantValueForField', 'fieldGeneralMaxLength'), 10);
    this.nbrOptionMaxValue = parseInt(this.appConfigService._('importantValueForField', 'nbrOptionsMaxValue'), 10);
    this.nbrOptionMinValue = parseInt(this.appConfigService._('importantValueForField', 'nbrOptionsMinValue'), 10);

    this.isModification = this.route.snapshot.routeConfig.path === 'administration/utilisateurs/modification-compte/:id';

    // We initialize some boolean which facilitates the reading of the code for the continuation
    this.parentIsValorissimo = this.infosAboutAccount.companyTypeLabel === this.appConfigService._('companyType', 'valorissimo');
    const parentIsValoSystem = this.infosAboutAccount.parentRoleName === this.appConfigService._('roles', 'valoSystem');
    this.parentIsContractor = this.infosAboutAccount.companyTypeLabel === this.appConfigService._('companyType', 'contractor');
    this.parentIsIndependant = this.infosAboutAccount.companyTypeLabel === this.appConfigService._('companyType', 'independant');
    this.responsibleTitle = this.getResponsibleTitle();

    this.accountPostalInfos = this.infosAboutAccount.adressInfos;
    this.accountPersonalData = this.infosAboutAccount.contactInfos;

    // We initialize the form that contains the personal data
    this.personnalDataForm = this.formBuilder.group({});
    this.personnalDataForm.addControl(
      'professionnalFunction',
      new UntypedFormControl(this.infosAboutAccount.professionnalFunction, [Validators.required, Validators.max(this.fieldMaxLength)]),
    );
    this.professionnalFunctionControl = this.personnalDataForm.controls.professionnalFunction;
    // We initialize the form that contains valo part infos
    this.valorissimoPartForm = this.formBuilder.group({
      parentInfos: [
        {
          value: this.infosAboutAccount.parentLastName
            ? this.infosAboutAccount.parentLastName.concat(' ', this.infosAboutAccount.parentFirstName)
            : undefined,
          disabled: true,
        },
        [Validators.required, Validators.max(this.fieldMaxLength)],
      ],
      companyType: [{ value: this.infosAboutAccount.companyTypeId, disabled: true }, [Validators.required]],
      profilType: [{ value: this.infosAboutAccount.profilTypeId, disabled: true }, [Validators.required]],
      valoRoleType: [
        {
          value: parentIsValoSystem ? undefined : this.infosAboutAccount.valoRoleTypeId,
          disabled: !parentIsValoSystem,
        },
        [Validators.required],
      ],
      isActive: [!!this.infosAboutAccount.isActive],
      login: [{ value: undefined, disabled: true }],
      nbOptionsMax: [undefined],
      commentBo: [this.infosAboutAccount.commentBo],
      otherResponsible: new FormControl(''),
    });

    this.parentInfosControl = this.valorissimoPartForm.controls.parentInfos;
    this.companyTypeControl = this.valorissimoPartForm.controls.companyType;
    this.profilTypeControl = this.valorissimoPartForm.controls.profilType;
    this.valoRoleTypeControl = this.valorissimoPartForm.controls.valoRoleType;
    this.isActiveControl = this.valorissimoPartForm.controls.isActive;
    this.loginControl = this.valorissimoPartForm.controls.login;
    this.nbOptionsMaxControl = this.valorissimoPartForm.controls.nbOptionsMax;
    this.commentBoControl = this.valorissimoPartForm.controls.commentBo;

    if (this.parentIsContractor) {
      this.valorissimoPartForm.addControl(
        'isVisibleCommercialLot',
        new UntypedFormControl(!!this.infosAboutAccount.isVisibleCommercialLot),
      );
      this.valorissimoPartForm.addControl('isFeesHidden', new UntypedFormControl(!!this.infosAboutAccount.isFeesHidden));
      this.valorissimoPartForm.addControl('isForbidden', new UntypedFormControl(!!this.infosAboutAccount.isForbidden));

      this.isVisibleCommercialLotControl = this.valorissimoPartForm.controls.isVisibleCommercialLot;
      this.isForbiddenControl = this.valorissimoPartForm.controls.isForbidden;
      this.isFeesHiddenControl = this.valorissimoPartForm.controls.isFeesHidden;
    } else {
      this.valorissimoPartForm.removeControl('isVisibleCommercialLot');
      this.valorissimoPartForm.removeControl('isFeesHidden');
      this.valorissimoPartForm.removeControl('isForbidden');
    }

    // get nedded Infos
    const companyTypeListRequest = this.referenceTablesService.getTableReferenceInfoTranslated('CompanyTypes');
    const profilListRequest = this.referenceTablesService.getTableReferenceInfoTranslated('Profils');
    const valoroleTypeListRequest = this.referenceTablesService.getTableReferenceInfoTranslated('ValoRoleTypes');

    this.requestArray.push(companyTypeListRequest, profilListRequest, valoroleTypeListRequest);

    forkJoin(this.requestArray).subscribe((results: Array<Array<ReferenceTableData>>) => {
      this.companyTypeList = this.companyTypeList.concat(results[0]);
      this.profilList = this.profilList.concat(results[1]);
      this.valoRoleTypeList = this.valoRoleTypeList.concat(results[2]).slice(1);

      if (this.loggedUserIsValo) {
        this.nbOptionsMaxControl.setValue(this.infosAboutAccount.nbOptionsMax);
        this.nbOptionsMaxControl.setValidators([
          Validators.required,
          Validators.min(this.nbrOptionMinValue),
          Validators.max(this.nbrOptionMaxValue),
        ]);
      }

      if (this.isModification) {
        this.buttonText = this.i18nService._('Txt_Button_SubmitUpdateAccount');
      } else {
        this.buttonText = this.loggedUserIsValo
          ? this.i18nService._('Txt_Button_SubmitValidateAccount')
          : this.i18nService._('Txt_Button_SubmitCreateAccount');
      }

      if (this.valoRoleTypeControl.value) {
        this.setIsRpvOrDeveloper(this.valoRoleTypeControl.value);
      }

      this.responseArray = results;
    });
    let affiliation = [];
    let otherAffiliation = [];
    if (this.parentIsValorissimo) {
      // Get departments and prepare it to app-chip-populated-autocomplete component.
      this.availableDepartment$ = this.accountUtilsService.getDepartments().pipe(
        map((departments) => {
          const items = new Map();
          departments.forEach((department) => items.set(`${department.label} (${department.code})`, department));
          const hasDepartement = this.infosAboutAccount.hasDepartement;
          if (hasDepartement) {
            this.defaultHasDepartment = [];
            hasDepartement.forEach((departement) => {
              this.defaultHasDepartment.push(`${departement.label} (${departement.code})`);
            });
          }

          return items;
        }),
      );

      affiliation = [ValoRole.valoBoSuper, ValoRole.valoBoSimple];
    } else if (this.parentIsContractor || this.parentIsIndependant) {
      // Set affiliation.
      affiliation = [ValoRole.valoRcvSuper, ValoRole.valoRcvSimple];
      otherAffiliation = [ValoRole.independantNetworkHeader, ValoRole.valoDevSimple, ValoRole.valoDevSuper];

      if (this.parentIsIndependant) {
        this.isOtherResponsableDisabled = true;
        this.isResponsibleRequired = false;
        this.valorissimoPartForm.addControl(
          'otherResponsible',
          this.valorissimoPartForm.controls['otherResponsible'] as UntypedFormControl,
        );
      }
    } else {
      // Set affiliation.
      affiliation = [ValoRole.valoDevSuper, ValoRole.valoDevSimple];
    }

    // Gets affiliation and prepare it to app-chip-populated-autocomplete component.
    if (affiliation) {
      this.availableAffilation$ = this.accountService.getAccountsWithRoles(affiliation).pipe(
        map((accounts) => {
          if (!this.parentIsValorissimo) {
            const responsable = this.infosAboutAccount.defaultResponsible;
            if (responsable) {
              this.defaultResponsible = this.titleCasePipe.transform(`${responsable.lastName} ${responsable.firstName}`);
            }
          }
          const hasBo = this.infosAboutAccount.hasBo;
          if (hasBo) {
            this.defaultHasBo = [];
            hasBo.forEach((bo) => {
              this.defaultHasBo.push(this.titleCasePipe.transform(`${bo.lastName} ${bo.firstName}`));
            });
          }
          const items = new Map();
          accounts.forEach((account) => items.set(this.titleCasePipe.transform(`${account.lastName} ${account.firstName}`), account));

          return items;
        }),
      );
    }
    if (otherAffiliation) {
      this.otherAvailableAffilation$ = this.accountService.getAccountsWithRoles(otherAffiliation).pipe(
        map((accounts) => {
          if (!this.parentIsValorissimo) {
            const responsable = this.infosAboutAccount.otherDefaultResponsible;
            if (responsable) {
              this.otherDefaultResponsible = this.titleCasePipe.transform(`${responsable.lastName} ${responsable.firstName}`);
            }
          }
          const items = new Map();
          accounts.forEach((account) => items.set(this.titleCasePipe.transform(`${account.lastName} ${account.firstName}`), account));
          return items;
        }),
      );
    }
  }

  getResponsibleTitle(): string {
    switch (this.infosAboutAccount.companyTypeLabel) {
      case this.appConfigService._('companyType', 'contractor'):
        return 'Txt_Placeholder_AffiliateRCV';
      case this.appConfigService._('companyType', 'developer'):
        return 'Txt_Placeholder_AffiliateDEV';
      default:
        return 'Txt_Placeholder_AffiliateRCV';
    }
  }

  valoRoleChange(event): void {
    this.setIsRpvOrDeveloper(event.value);
  }

  /**
   * Actions are performed if the ValoRole Type is a developer or a VPN.
   * Nottingly a boolean and loading the proper lists
   * @param {*} valoRoleTypeId
   */
  setIsRpvOrDeveloper(valoRoleTypeId): void {
    const valoRoleTypeLabel = this.valoRoleTypeList.find((valoRoleType) => valoRoleType.id === valoRoleTypeId).label;
    this.isRpvOrDeveloper = valoRoleTypeLabel === this.i18nService._('rpv') || valoRoleTypeLabel === this.i18nService._('developer');
    if (!this.isRpvOrDeveloper && this.valorissimoPartForm.controls.hasBo) {
      this.valorissimoPartForm.removeControl('hasBo');
      this.valorissimoPartForm.removeControl('hasDepartment');
    }
  }

  /**
   * We assign the value of the email field to the login when we change step on the stepper
   * @param {*} event
   */
  onSelectionChange(event: StepperSelectionEvent): void {
    this.loginControl.setValue(this.personnalDataForm.value.email);
    this.loginControl.updateValueAndValidity();
    this.selectedIndex = event.selectedIndex;
  }

  submitFirstStep(): void {
    if (this.step._steps.first.stepControl.invalid) {
      this.isSubmit = true;
      this.snackbarService.sendMessage({
        text: this.i18nService._('Error_SnackBar_FormIncomplete'),
        type: SnackbarMessageType.Error,
      });
    }
  }

  public submitAccount(): void {
    if (this.personnalDataForm.valid && this.valorissimoPartForm.valid) {
      const values = {
        personnalData: this.personnalDataForm.value,
        valoPartData: this.valorissimoPartForm.value,
      };
      this.notify.emit(values);
    } else {
      this.isSubmit = true;
      this.snackbarService.sendMessage({
        text: this.i18nService._('Error_SnackBar_FormIncomplete'),
        type: SnackbarMessageType.Error,
      });
    }
  }

  public cancel(): void {
    this.roleTypeName != undefined ? this.router.navigate([this.getRoute()]) : this.navigateToPreviousPath();
  }

  private getRoute(): string {
    return this.routeConfig[this.roleTypeName] || Sitemap.accounts.admin.path;
  }

  private routeConfig = {
    [this.appConfigService._('accountType', AcountTypeEnum.INDEPENDANT)]: Sitemap.accounts.adminIndependant.path,
    [this.appConfigService._('accountType', AcountTypeEnum.DEVELOPER)]: Sitemap.accounts.adminDeveloper.path,
    [this.appConfigService._('accountType', AcountTypeEnum.CONTRACTOR)]: Sitemap.accounts.adminContractor.path,
  };

  private navigateToPreviousPath(): void {
    this.routingStateService.previousRoutePath.pipe(take(1)).subscribe((previousRoutePath) => {
      this.routingStateService.removeLastHistoryEntry();
      this.router.navigateByUrl(this.routingStateService.getPreviousUrl(previousRoutePath, this.defaultGoBackUrl));
    });
  }
}
