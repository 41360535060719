import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, NgIf } from '@angular/common';

import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { FileFormat } from '../../../utils/models/FileFormat';
import { RecoveredDocuments } from '../../../utils/models/RecoverdedDocuments';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { I18nService } from '../../../utils/services/i18n.service';
import { PostalAddressService } from '../../../utils/services/postal-address.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { CompanyService } from '../../services/company.service';
import { FileDropComponent } from '../../../utils/components/file-drop/file-drop.component';

@Component({
  selector: 'app-step-three-form-company',
  templateUrl: './step-three-form-company.component.html',
  styleUrls: ['./step-three-form-company.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FileDropComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatStepperModule,
    AsyncPipe,
  ],
})
export class StepThreeFormCompanyComponent implements OnInit, OnDestroy {
  /**
   * ParentForm to add controls
   *
   * @type {FormGroup}
   * @memberof StepOneFormCompanyComponent
   */
  @Input() parentForm: UntypedFormGroup;

  /**
   * isMandatDelegation input
   *
   * @type {boolean}
   * @memberof StepThreeFormCompanyComponent
   */
  isMandatDelegation: boolean;

  /**
   * isDeveloper input
   *
   * @type {boolean}
   * @memberof StepThreeFormCompanyComponent
   */
  @Input() isDeveloper: boolean;

  /**
   * isValoRole input
   *
   * @type {boolean}
   * @memberof StepThreeFormCompanyComponent
   */
  @Input() isValoRole: boolean;

  /**
   * recoveredInfo input
   *
   * @type {*}
   * @memberof StepThreeFormCompanyComponent
   */
  @Input() recoveredInfo: RecoveredDocuments;

  /**
   * isSubmit attribute
   *
   * @type {boolean}
   * @memberof StepThreeFormCompanyComponent
   */
  public isSubmit: boolean;

  /**
   * attachmentCommentControl attribute
   *
   * @type {FormControl}
   * @memberof StepThreeFormCompanyComponent
   */
  public documentCommentControl: FormControl;

  public isReady: boolean;

  public initFile: Array<FileFormat>;
  /**
   * isfrenchCountry$
   *
   * @type {Observable<boolean>}
   * @memberOf StepThreeFormCompanyComponent
   */
  public isfrenchCountry$: Observable<boolean>;
  /**
   * previouslySelectedIndex$
   *
   * @type {Subscription}
   * @memberof StepThreeFormCompanyComponent
   */
  private previouslySelectedIndex$: Subscription;
  /**
   * isMandatDelegationSubscription
   *
   * @type {Subscription}
   * @memberof StepThreeFormCompanyComponent
   */
  private isMandatDelegationSubscription: Subscription;
  /**
   * Step index.
   *
   * @type {number}
   * @memberof StepOneFormCompanyComponent
   */
  private readonly index = 2;

  /**
   * Creates an instance of StepThreeFormCompanyComponent.
   *
   * @param {I18nService} i18nService
   * @param {CompanyService} companyService
   * @param {SnackbarService} snackbarService
   * @param {PostalAddressService} postalAdressService
   *
   * @memberof StepThreeFormCompanyComponent
   */
  constructor(
    public i18nService: I18nService,
    private readonly companyService: CompanyService,
    private readonly snackbarService: SnackbarService,
    private readonly postalAdressService: PostalAddressService,
  ) {
    this.isSubmit = false;
    this.isMandatDelegation = false;
    this.isReady = false;
    this.isfrenchCountry$ = this.postalAdressService.isFrenchCountryObservable;
  }

  /**
   * ngOnInit method
   *
   * @memberof StepThreeFormCompanyComponent
   */
  ngOnInit(): void {
    // Process submitStep when user click on stepper.
    if (this.isValoRole) {
      this.previouslySelectedIndex$ = this.companyService.previouslySelectedIndex.subscribe((index: number) => {
        if (index === this.index) {
          this.submitStep();
        }
      });
    }

    this.initFile = [];
    this.parentForm.addControl('documentComment', new UntypedFormControl('', undefined));
    this.documentCommentControl = this.parentForm.controls.documentComment as FormControl;

    if (this.recoveredInfo) {
      // format the document to fit the format
      this.recoveredInfo.documents.forEach((attachment) => {
        this.initFile[attachment.type.label] = attachment;
      });

      // Add document comment from Database
      this.documentCommentControl.setValue(this.recoveredInfo.documentComment);
    }
    this.isReady = true;
    // Observable to know if there is a delegation mandate
    this.isMandatDelegationSubscription = this.companyService.isMandatDelegation.subscribe((isMandatDelegation) => {
      this.isMandatDelegation = isMandatDelegation;
      if (!isMandatDelegation) {
        if (this.parentForm.controls.mandateDelegation) {
          this.parentForm.removeControl('mandateDelegation');
        }
        if (this.parentForm.controls.identityDocumentOfTheMandateSignatory) {
          this.parentForm.removeControl('identityDocumentOfTheMandateSignatory');
        }
      }
    });
  }

  /**
   * onSubmit method
   *
   * @memberof StepThreeFormCompanyComponent
   */
  submitStep(): void {
    this.isSubmit = true;
    // Check if there is an error on the form
    if (!this.parentForm.valid) {
      const message: SnackbarMessage = {
        text: this.i18nService._('Error_SnackBar_DocumentIncomplete'),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
    }
  }

  /**
   * Destroy observable
   */
  ngOnDestroy(): void {
    this.isMandatDelegationSubscription.unsubscribe();
    if (this.previouslySelectedIndex$) {
      this.previouslySelectedIndex$.unsubscribe();
    }
  }
}
