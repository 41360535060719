<div *ngIf="commercialContacts" class="border-b border-gray-300 py-2 text-sm">
  <h3 class="text-xxs text-cyan-700 font-bold uppercase mb-1">Assistance commerciale</h3>

  <div *ngFor="let contact of commercialContacts" class="mb-3">
    <p class="mb-1 text-xl leading-5 whitespace-nowrap font-bold">{{ contact.name }}</p>
    <p class="mb-1">{{ contact.phone }}</p>
    <a class="mb-1 lowercase" href="mailto:{{ contact.email }}">{{ contact.email }}</a>
  </div>
</div>

<div
  *ngFor="let contact of otherContacts"
  class="contact items-center border-b last:border-b-0 border-gray-300 uppercase font-semibold text-sm py-2"
>
  <h3 class="text-xxs leading-3 text-cyan-700 font-bold uppercase mb-1">{{ contact.label }}</h3>
  <p class="mb-1">{{ contact.phone }}</p>
  <a class="mb-1 lowercase" href="mailto:{{ contact.email }}">{{ contact.email }}</a>
</div>
