<div class="overflow-x-auto">
  <table [dataSource]="dataSource" [trackBy]="trackLots" class="w-full px-2" mat-table matSort multiTemplateDataRows>
    <ng-container matColumnDef="program" sticky>
      <th *matHeaderCellDef class="font-bold text-sm text-black capitalize w-64" mat-header-cell mat-sort-header>
        {{ getColumnTitle('program') }}
      </th>
      <td
        *matCellDef="let element"
        [ngClass]="{
          'optioned-by-me': element.lotIsOptionedByUser,
          'not-available': element.status !== 'FREE' && !element.lotIsOptionedByUser
        }"
        class="text-left p-3 container-info-lot"
        mat-cell
      >
        <div class="text-right font-bold text-xxs" *ngIf="element.isProximityCity">
          <span class="bg-yellow">
            {{ i18nService._('Txt_Placeholder_Proximity_City') }}
          </span>
        </div>
        <div class="flex flex-row justify-between items-end">
          <a
            class="no-style cursor-pointer"
            [routerLink]="redirectToProgramPage(element.programId)"
            [queryParams]="{ lotId: element.lotId }"
          >
            <div class="mb-1">
              <div *ngIf="hasSpecialOffer(element)" class="bg-orange inline-block mr-2 text-xxs text-white px-1 py-1">
                {{ i18nService._('Txt_Table_SpecialOffer') }}
              </div>
              <div
                *ngFor="let taxation of getTaxationsLabels(element)"
                class="border border-grey-secondary inline-block text-xxs txt-grey mr-2 px-1 py-1"
              >
                {{ i18nService._(taxation) }}
              </div>
            </div>
            <h3 class="m-0">
              {{ getColumnValue(element, 'program') }}
            </h3>
            <div class="text-xs font-bold">
              {{ getColumnValue(element, 'city') }}
            </div>
            <div class="text-xxs text-black">
              {{ getColumnValue(element, 'lotNumber') }}
            </div>
          </a>
        </div>
        <div class="text-xxs text-black">
          <app-management-lot-comparator [idLot]="element.lotId" [viewText]="true"></app-management-lot-comparator>
        </div>
      </td>
    </ng-container>

    <ng-container *ngFor="let column of simpleColumns" matColumnDef="{{ column }}">
      <th
        *matHeaderCellDef
        [disabled]="!isSortable(column)"
        class="table-title-center font-bold text-sm text-black capitalize"
        mat-header-cell
        mat-sort-header
      >
        {{ getColumnTitle(column) }}
      </th>
      <td
        *matCellDef="let element"
        [ngClass]="{
          'optioned-by-me': element.lotIsOptionedByUser,
          'not-available': element.status !== 'FREE' && !element.lotIsOptionedByUser
        }"
        class="text-center"
        mat-cell
      >
        <div class="flex flex-col justify-center items-center" *ngIf="column !== 'rooms'">
          {{ getColumnValue(element, column) }}

          <div
            (click)="clickOnTag(element)"
            *ngIf="isPriceWithReducedTaxation(column)"
            class="border border-grey-secondary inline-block text-xxs txt-grey mt-2 px-1 py-1 cursor-pointer"
          >
            {{ getPriceTag(element) }}
          </div>

          <!---To Do Dev file.ts for use just price TTC column -->
          <div
            *ngIf="isPriceWithSpecialOffer(column, element)"
            class="mr-2 my-1 py-1 px-2 text-white inline-flex text-xxs text-center bg-violet"
          >
            {{ i18nService._('Txt_Table_SpecialOffer') }}
          </div>
        </div>
        <div *ngIf="column === 'rooms'" class="flex justify-center items-center">
          {{ getColumnValue(element, column) }}
          <div (click)="openPlan(element, $event)" class="cursor-pointer">
            <span class="icon-plan txt-orange pl-2" title="{{ i18nService._('Txt_SpanTitle_SeePlan') }}"> </span>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="strongPoints">
      <th
        *matHeaderCellDef
        class="table-title-center font-bold text-sm text-black capitalize"
        [disabled]="true"
        mat-header-cell
        mat-sort-header
      >
        {{ getColumnTitle('strongPoints') }}
      </th>
      <td
        *matCellDef="let element"
        [ngClass]="{
          'optioned-by-me': element.lotIsOptionedByUser,
          'not-available': element.status !== 'FREE' && !element.lotIsOptionedByUser
        }"
        class="text-center"
        mat-cell
      >
        <app-strong-point-list *ngIf="element.strongPoints" [strongPoints]="element.strongPoints"></app-strong-point-list>
        <span *ngIf="!element.strongPoints">-</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="status" stickyEnd>
      <th *matHeaderCellDef class="font-bold text-sm text-black text-center capitalize w-40" mat-header-cell mat-sort-header>
        {{ getColumnTitle('status') }}
      </th>

      <td
        *matCellDef="let element"
        [ngClass]="{
          'optioned-by-me': element.lotIsOptionedByUser,
          'not-available': element.status !== 'FREE' && !element.lotIsOptionedByUser
        }"
        class="container-statut"
        mat-cell
      >
        <div class="menu-status">
          <div class="font-bold">
            <span>
              {{ getColumnValue(element, 'status') }}
            </span>
          </div>

          <div *ngIf="element.status === 'FREE'" class="status">
            <button *ngIf="!callCanOption[element.lotId]" mat-flat-button color="primary" type="button" (click)="checkValidity(element)">
              {{ i18nService._('Txt_Button_TakeOption') }}
            </button>
            <mat-spinner *ngIf="callCanOption[element.lotId]" [color]="'primary'" [diameter]="32" [mode]="'indeterminate'"></mat-spinner>
          </div>
        </div>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
    <tr *matRowDef="let element; columns: columnsToDisplay" mat-row></tr>
  </table>
</div>
<mat-paginator [pageSizeOptions]="pageSizeOptions" [length]="countResults.lots" class="bg-graylight" showFirstLastButtons></mat-paginator>
