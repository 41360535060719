import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { BROWSERS, DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';

import { DocumentResponse } from '../models/DocumentResponse';
import { AppConfigService } from './app-config.service';
import { I18nService } from './i18n.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class FileSystemService {
  /**
   * Creates an instance of FileSystemService.
   * @param {HttpClient} http
   * @param {ErrorHandlerService} errorHandlerService
   * @param {AppConfigService} appConfig
   * @param {SnackbarService} snackbarService
   * @param {I18nService} i18nService
   * @memberof FileSystemService
   */
  constructor(
    private readonly appConfig: AppConfigService,
    private readonly deviceService: DeviceDetectorService,
    private readonly http: HttpClient,
    private readonly snackbarService: SnackbarService,
    private readonly i18nService: I18nService,
  ) {}

  /**
   * Used to generate download url of document
   * @param {DocumentResponse} document
   * @memberof FileSystemService
   */

  public getDownloadUrlOfDocument(document: DocumentResponse, { programId, lotId }): string {
    // Check if all needed properties to generate url exists

    if (!document || !document.fileName) {
      // Return error we cant generate link
      this.snackbarService.sendErrorOccured();

      return this.i18nService._('Error_FileSystem_UnresolvedUrlOfDocument');
    }

    // All properties exist then generate url
    // /export-document/:table/:objectId/:documentType/:fileName
    if ((lotId && document.container === 'lotDocuments' && document.codeLabel === 'LP') || (document.type && document.type.code === 'LP')) {
      // Generate lotplan link
      return `${
        window.location.origin
      }${this.appConfig.getLoopbackApiUrl()}/Documents/export-document/lot/${lotId}/lotPlan/${encodeURIComponent(document.fileName)}`;
    }

    return `${window.location.origin}${this.appConfig.getLoopbackApiUrl()}/Documents/export-document/program/${programId}/${
      document.type.label
    }/${encodeURIComponent(document.fileName)}`;
  }

  /**
   * Download file method.
   *
   * @param {DocumentResponse} document
   * @return {Blob} document object
   * @memberof FileSystemService
   */
  public downloadFile(document: DocumentResponse): Observable<Blob> {
    if (document && document.container && document.fileName) {
      return this.http.get(
        `${this.appConfig.getLoopbackApiUrl()}/Containers/${document.container}/download/${encodeURIComponent(document.fileName)}`,
        { responseType: 'blob' },
      );
    }

    return new Observable((observer) => {
      observer.error('Unprocessable document provided');
    });
  }

  /**
   * Download document in the current browser
   *
   * @param {DocumentResponse} document DocumentResponse
   * @param {string} name string : file name downloaded with extension
   * @memberof AttachmentService
   */
  public downloadDocumentInBrowser(document: DocumentResponse, name: string): void {
    if (document && name) {
      this.downloadFile(document).subscribe(
        (response) => {
          saveAs(response, name);
        },
        () => {
          this.snackbarService.sendErrorOccured();
        },
      );
    }
  }

  /**
   * Open document in the current browser for mimeType application/pdf and image/*
   *
   * @param {DocumentResponse} document DocumentResponse
   * @param {string} name string : file name downloaded with extension
   * @memberof AttachmentService
   */
  public openDocumentInBrowser(document: DocumentResponse, name: string): void {
    if (
      this.appConfig
        .getAllowedBrowsersToOpenDocumentInNewTab()
        .some((allowedBrowser) => BROWSERS[allowedBrowser] === this.deviceService.browser) &&
      document &&
      name &&
      /^application\/pdf|image\/[a-z]+$/.test(document.mimeType)
    ) {
      this.downloadFile(document).subscribe(
        (response) => {
          const blob = new Blob([response], { type: document.mimeType });
          const objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
          window.addEventListener(
            'focus',
            () => {
              window.URL.revokeObjectURL(objectUrl);
            },
            { once: true },
          );
        },
        () => {
          this.snackbarService.sendErrorOccured();
        },
      );
    } else {
      this.downloadDocumentInBrowser(document, name);
    }
  }

  public getLogo(document: DocumentResponse): string {
    return document ? `${this.appConfig.getFileSystemUrl()}/${document.container}/${document.fileName}` : '';
  }
}
