import { Component } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';

import { AbstractSearchFilter } from '../abstract-search-filter';

import { I18nService } from '../../../../utils/services/i18n.service';

@Component({
  selector: 'app-search-filter-actable',
  templateUrl: './search-filter-actable.component.html',
  styleUrls: ['./search-filter-actable.component.scss'],
  standalone: true,
  imports: [MatChipsModule],
})
export class SearchFilterActableComponent extends AbstractSearchFilter {
  constructor(public readonly i18nService: I18nService) {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected initData() {}
}
