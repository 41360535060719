import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { PageProgramHelperService } from '../page-program-helper.service';

import { QuestionDialogData } from '../../../../dialog/models/QuestionDialogData';
import { I18nService } from '../../../../utils/services/i18n.service';
import { ScriptService } from '../../../../utils/services/script-loader.service';
import { ConfigurableParamService } from '../../../../utils/services/configurable-param.service';
import { ProgramTextResponse } from '../../../../utils/models/ProgramTextResponse';
import { SafePipe } from '../../../../utils/pipes/safe.pipe';

@Component({
  selector: 'app-program-page-neighborhood-map-dialog',
  templateUrl: './program-page-neighborhood-map-dialog.component.html',
  styleUrls: ['./program-page-neighborhood-map-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatIconModule, MatButtonModule, NgIf, SafePipe],
})
export class ProgramPageNeighborhoodMapDialogComponent implements OnInit {
  showLeftSide = true;

  @ViewChild('cityScanWidget', { static: true }) cityScanWidget: ElementRef;

  constructor(
    public i18nService: I18nService,
    public dialogRef: MatDialogRef<ProgramPageNeighborhoodMapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QuestionDialogData,
    private readonly scriptService: ScriptService,
    private readonly _configurableParamService: ConfigurableParamService,
    private pageProgramHelperService: PageProgramHelperService,
  ) {}

  programText: ProgramTextResponse;

  async ngOnInit() {
    this.programText = this.pageProgramHelperService.programText;
    try {
      this.showLeftSide =
        Boolean(this.programText.situation && this.programText.situation.length) ||
        Boolean(this.programText.access && this.programText.access.length);
      const scriptLoadingResult = await this.scriptService.loadScript('CITY_SCAN');
      if (
        !scriptLoadingResult ||
        !scriptLoadingResult.loaded ||
        (scriptLoadingResult.status !== 'Loaded' && scriptLoadingResult.status !== 'Already Loaded')
      ) {
        throw new Error('Script cant be loaded');
      }
      // Script loaded with success
      this.addElement();
      window.document.dispatchEvent(
        new Event('DOMContentLoaded', {
          bubbles: false,
          cancelable: true,
        }),
      );
    } catch (errorLoadingCityScanScript) {
      console.error('Cityscan script cant be loaded because', errorLoadingCityScanScript);
    }
  }

  addElement(): void {
    this.cityScanWidget.nativeElement.setAttribute('data-ext-address-id', `${this.pageProgramHelperService.program.programId}`);
    this._configurableParamService.getConfigParam('CITYSCAN_PUBLIC_KEY').subscribe((cityscanPublicKey) => {
      this.cityScanWidget.nativeElement.setAttribute('data-client-key', cityscanPublicKey['CITYSCAN_PUBLIC_KEY']);
    });
    return;
  }

  close(): void {
    this.dialogRef.close();
  }
}
