/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NgIf, UpperCasePipe } from '@angular/common';

import { I18nService } from './../../../utils/services/i18n.service';
import { StatusTagColor } from '../../../design-system/model/status-tag-color.enum';

@Component({
  selector: 'app-status-tags-renderer',
  templateUrl: './status-tags-renderer.component.html',
  styleUrls: ['./status-tags-renderer.component.scss'],
  standalone: true,
  imports: [NgIf, UpperCasePipe],
})
export class StatusTagsRendererComponent implements ICellRendererAngularComp {
  public cellValue: any;
  public class: string;
  public isUpperCase = false;

  constructor(public readonly i18nService: I18nService) {}

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
    const tagColor = params['tagsList'][params.value] ?? StatusTagColor.IN_PROGRESS.toString();
    const outline = params['outline'] ? ' status-outline' : '';
    this.isUpperCase = params['isUpperCase'];
    this.class = 'tags ' + tagColor + outline;
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    // set value into cell again
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.valueFormatted ? params?.valueFormatted : params?.value;
  }
}
