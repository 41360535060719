<ng-content></ng-content>
<div class="flex flex-col">
  <app-checkbox-populated
    class="grid grid-cols-2"
    [referenceTable]="'SecondaryLotTypes'"
    [sortMethod]="sortBy('priority')"
    [checkedElements]="getSecondaryLotTypeIdsByAnnexeType()"
    (checkedElementsChange)="selectedItem(getSecondaryLotTypeIdsField(), $event)"
    [numberColumnsToDisplay]="2"
    [filterMethod]="getFilterMethod('annexe_type')"
  >
  </app-checkbox-populated>
</div>
