import { Injectable } from '@angular/core';

import { TaxZoneApiService } from '../adapters/tax-zone-api.service';
import { TaxZoneResponse } from '../utils/models/TaxZoneResponse';

@Injectable({
  providedIn: 'root',
})
export class TaxZoneService {
  taxZones: TaxZoneResponse[];

  constructor(private readonly taxZoneApiService: TaxZoneApiService) {}

  public async getTaxZones(): Promise<TaxZoneResponse[]> {
    if (!this.taxZones) {
      await this.loadTaxZones();
    }
    return this.taxZones;
  }

  private async loadTaxZones(): Promise<void> {
    this.taxZones = await this.taxZoneApiService.getTaxZones();
  }
}
