<div class="flex flex-col w-full">
  <mat-form-field class="flex w-full rounded-lg" appearance="outline">
    <mat-chip-grid #chipList class="chip-autocomplete" [ngClass]="{ 'text-white': isNewHomepage }" [required]="required">
      <span class="text-sm">{{ itemsLabelKeyList }}</span>
      <input
        #chipInput
        *ngIf="!selectedItems[0]"
        [formControl]="chipCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        (keyup)="filter()"
        [placeholder]="placeholder"
        data-cy="loginAs"
        class="text-field-connected-as w-input-connected-as"
        [ngClass]="{ 'placeholder-white text-white': isNewHomepage }"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      />
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option class="text-sm w-select-connected-as" *ngFor="let item of filteredItems" [attr.data-cy]="item" [value]="item">
        {{ item }}
      </mat-option>
      <mat-option *ngIf="notEnoughLetters === true" disabled>
        {{ i18nService._('Txt_NotEnoughLetters_TwoLetters') }}
      </mat-option>
      <mat-option *ngIf="emptyResponse === true" disabled>
        {{ i18nService._('Txt_No_Result') }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <span *ngIf="chipList.errorState" class="login-as-error txt-red text-xs flex-1">
    {{ i18nService._('Error_Field_Required') }}
  </span>
</div>
