<div class="flex flex-col">
  <mat-chip-listbox>
    <mat-chip-option
      color="primary"
      name="fieldNameId"
      *ngFor="let referenceTableInfo of referenceTableInfos"
      [attr.data-cy]="referenceTableInfo.label"
      [selected]="referenceTableInfo.isSelected"
      (click)="chipSelected(referenceTableInfo.id)"
      [value]="referenceTableInfo.id"
      class="mt-3 mr-3"
    >
      {{ referenceTableInfo.label }}
    </mat-chip-option>
  </mat-chip-listbox>
  <mat-error *ngIf="chipControl.errors && (firstLoad || isSubmit)">
    {{ i18nService._('Error_Field_Required') }}
  </mat-error>
</div>
