import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { PipesModule } from '../utils/pipes/pipes.module';
import { ButtonBackComponent } from './component/app-button-back/button-back.component';
import { FeesComponent } from './component/fees/fees.component';
import { ItemComponent } from './component/item/item.component';
import { SingleAccordionPanelComponent } from './component/single-accordion-panel/single-accordion-panel.component';
import { TagsComponent } from './component/tags/tags.component';
import { ToggleButtonComponent } from './component/toggle-button/toggle-button.component';
import { StatusTagsComponent } from './component/status-tags/status-tags.component';
import { ActionListButtonComponent } from './component/action-list-button/action-list-button.component';

@NgModule({
  exports: [
    TagsComponent,
    SingleAccordionPanelComponent,
    ItemComponent,
    ButtonBackComponent,
    FeesComponent,
    ToggleButtonComponent,
    StatusTagsComponent,
    ActionListButtonComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatExpansionModule,
    PipesModule,
    MatMenuModule,
    TagsComponent,
    SingleAccordionPanelComponent,
    ItemComponent,
    ButtonBackComponent,
    FeesComponent,
    ToggleButtonComponent,
    StatusTagsComponent,
    ActionListButtonComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class DesignSystemModule {}
