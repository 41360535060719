<ng-container [formGroup]="selfFilterForm">
  <!-- <mat-form-field>
    <mat-label>{{i18nService._(tableColumn['i18nTitle'])}}</mat-label>
    <input matInput
           [satDatepicker]="picker5"
           [formControlName]="tableColumn['key']">
    <sat-datepicker #picker5 [rangeMode]="true" [selectFirstDateOnClose]="true">
    </sat-datepicker>
    <sat-datepicker-toggle matSuffix [for]="picker5"></sat-datepicker-toggle>
  </mat-form-field> -->
  <!-- <mat-form-field>
    <mat-date-range-input [rangePicker]="picker" [formControlName]="tableColumn['key']">
      <input matStartDate [placeholder]="i18nService._(tableColumn['i18nTitle'])">
      <input matEndDate [placeholder]="i18nService._(tableColumn['i18nTitle'])">
    </mat-date-range-input>

    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field> -->
  <app-input-date
    [forcePick]="false"
    [formControlName]="tableColumn['key']"
    [parentForm]="selfFilterForm"
    [placeholder]="i18nService._(tableColumn['i18nTitle'])"
    [required]="true"
    fieldName="tableColumn['key']"
  >
  </app-input-date>
</ng-container>
