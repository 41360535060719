import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormlyNavigationService {
  public submitSubject: Subject<void> = new Subject();
  public nextPageSubject: Subject<void> = new Subject();
  public onPageChanged: Subject<number> = new Subject();
  public previousPageSubject: Subject<void> = new Subject();

  changePage(page: number) {
    this.onPageChanged.next(page);
  }

  submit() {
    this.submitSubject.next();
  }

  nextPage() {
    this.nextPageSubject.next();
  }

  previousPage() {
    this.previousPageSubject.next();
  }
}
