<mat-card appearance="outlined" class="mb-4">
  <!-- Section Acquéreur -->
  <mat-card-title class="text-lg mx-auto mb-3">
    {{ i18nService._('Title_Buyer_Infos') }}
  </mat-card-title>

  <mat-card-content>
    <app-contact-information
      [parentForm]="acquirerForm"
      [chipFunctionDisplay]="false"
      [deskPhoneDisplay]="false"
      [emailRequired]="false"
      [mobilePhoneRequired]="false"
      [includeCivility]="false"
      [firstNameRequired]="false"
      [lastNameRequired]="!isCanceled"
      [lastNamePlaceholder]="i18nService._('Txt_Placeholder_LastNameAcquirer')"
      [firstNamePlaceholder]="i18nService._('Txt_Placeholder_FirstNameAcquirer')"
      [emailPlaceholder]="i18nService._('Txt_Placeholder_EmailAcquirer')"
      [mobilePhonePlaceholder]="i18nService._('Txt_Placeholder_PhoneAcquirer')"
      [lastNameTooltipText]="i18nService._('Txt_Tooltip_LastNameAcquirer')"
      [isSubmit]="isSubmit"
    >
    </app-contact-information>

    <app-postal-address [parentForm]="acquirerForm" [recoveredInfo]="recoveredPostalAddress" [isAddress]="false"></app-postal-address>

    <app-contact-information
      [parentForm]="coAcquirerForm"
      [chipFunctionDisplay]="false"
      [deskPhoneDisplay]="false"
      [emailRequired]="false"
      [mobilePhoneRequired]="false"
      [includeCivility]="false"
      [firstNameRequired]="false"
      [lastNameRequired]="false"
      [lastNamePlaceholder]="i18nService._('Txt_Co_Acquirer_LastName')"
      [firstNamePlaceholder]="i18nService._('Txt_Co_Acquirer_FirstName')"
      [emailPlaceholder]="i18nService._('Txt_Co_Acquirer_Email')"
      [mobilePhonePlaceholder]="i18nService._('Txt_Co_Acquirer_Phone')"
      [isSubmit]="isSubmit"
    >
    </app-contact-information>

    <div class="my-3">
      <mat-checkbox name="isElectronicSignature" [formControl]="isElectronicSignatureControl">
        {{ i18nService._('Txt_Checkbox_ElectronicallySignedFile') }}
      </mat-checkbox>
    </div>
  </mat-card-content>
</mat-card>

<!-- Section Financière -->
<mat-card appearance="outlined" class="mb-4">
  <mat-card-title class="text-lg mx-auto mb-3">
    {{ i18nService._('Title_Financial_Infos') }}
  </mat-card-title>

  <mat-card-content>
    <div class="grid grid-cols-2 gap-4 mb-2">
      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_Main_Lot') }}</mat-label>
        <input disabled type="text" name="lotNumber" [formControl]="lotNumberControl" matInput maxlength="20" />
        <mat-error *ngIf="lotNumberControl.errors">
          <span *ngIf="lotNumberControl.errors.required">
            {{ i18nService._('Error_Field_Required') }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_Secondary_Lot') }}</mat-label>
        <input type="text" name="refSecLots" [formControl]="refSecLotsControl" matInput maxlength="255" />
      </mat-form-field>
    </div>

    <div class="grid grid-cols-2 gap-4 mb-2">
      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_Customer_Qualification') }}</mat-label>
        <mat-select [formControl]="clientQualificationControl" name="clientQualification">
          <mat-option *ngFor="let clientQualification of clientQualifications" [value]="clientQualification.id">
            {{ i18nService._(clientQualification.label) }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ i18nService._('Error_Field_Required') }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_Tax_System') }}</mat-label>
        <mat-select [formControl]="reservationTaxationControl" name="reservationTaxation" (selectionChange)="changeVatRate($event.value)">
          <mat-option *ngFor="let reservationTax of taxList" [value]="reservationTax.id">
            {{ i18nService._(reservationTax.label) }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ i18nService._('Error_Field_Required') }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="grid grid-cols-2 gap-4 mb-2">
      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_VAT_Rate') }}</mat-label>
        <input type="number" name="vatRate" [formControl]="vatRateControl" matInput maxlength="5" />
        <span matSuffix>%</span>
      </mat-form-field>

      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_Amount_Of_Discounts') }}</mat-label>
        <input
          appDecimalMask
          maxNumberDecimals="0"
          maxNumberDigitsBeforeSeparator="5"
          name="discountAmount"
          [formControl]="discountAmountControl"
          matInput
        />
        <mat-error>
          {{ i18nService._('Error_Field_ValueBetween', [0, 99999]) }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="grid grid-cols-2 gap-4 mb-2">
      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_Special_Offer') }}</mat-label>
        <mat-select [formControl]="specialOfferControl" name="specialOffer" mutliple>
          <mat-option [value]="null"></mat-option>
          <mat-option *ngFor="let specialOffer of specialOfferList" [title]="formatSpecialOffer(specialOffer)" [value]="specialOffer.id">
            {{ formatSpecialOffer(specialOffer) }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_Selling_Price') }}</mat-label>
        <input
          appDecimalMask
          maxNumberDecimals="0"
          maxNumberDigitsBeforeSeparator="10"
          type="text"
          name="sellingPrice"
          [formControl]="sellingPriceControl"
          matInput
          (keyup)="computeMarkupAmount()"
          required
        />
        <span matSuffix>€</span>
        <mat-error *ngIf="sellingPriceControl.errors && sellingPriceControl.errors.required">
          {{ i18nService._('Error_Field_Required') }}
        </mat-error>
        <mat-error *ngIf="sellingPriceControl.errors && !sellingPriceControl.errors.required">
          {{ i18nService._('Error_Field_ValueBetween', [0, 9999999999]) }}
        </mat-error>
      </mat-form-field>
    </div>

    <ng-container *ngIf="userRoleService.isValo()">
      <div class="grid grid-cols-2 gap-4 mb-4">
        <mat-radio-group class="col-span-1" [formControl]="notaryFeesFreeControl">
          <div class="text-base font-semibold mb-2">
            {{ i18nService._('Txt_Notary_Fees_Free') }}
          </div>

          <div class="grid grid-cols-2">
            <mat-radio-button [value]="true" color="primary">
              {{ i18nService._('Txt_RadioButton_Yes') }}
            </mat-radio-button>
            <mat-radio-button [value]="false" color="primary">
              {{ i18nService._('Txt_RadioButton_No') }}
            </mat-radio-button>
          </div>
        </mat-radio-group>

        <mat-form-field class="col-span-1">
          <mat-label>{{ i18nService._('Txt_Markup') }}</mat-label>
          <input
            appDecimalMask
            maxNumberDigitsBeforeSeparator="2"
            name="markup"
            [formControl]="markupControl"
            matInput
            (keyup)="computeMarkupAmount()"
          />
          <span matSuffix>%</span>
          <mat-error *ngIf="markupControl.errors && markupControl.errors.required">
            {{ i18nService._('Error_Field_Required') }}
          </mat-error>
          <mat-error *ngIf="markupControl.errors && !markupControl.errors.required">
            {{ i18nService._('Error_Field_ValueBetween', [0, '99,99']) }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="grid grid-cols-2 gap-4 mb-2">
        <mat-form-field class="col-span-1">
          <mat-label>{{ i18nService._('Txt_Markup_Amount') }}</mat-label>
          <input
            appDecimalMask
            maxNumberDecimals="2"
            maxNumberDigitsBeforeSeparator="10"
            [formControl]="markupAmountVisibleControl"
            matInput
          />
          <span matSuffix>€</span>
        </mat-form-field>
      </div>
    </ng-container>

    <div fclass="grid grid-cols-2 gap-4 mb-2">
      <app-input-date
        class="col-span-1"
        fieldName="reservationDate"
        [parentForm]="stepOneForm"
        [placeholder]="i18nService._('Txt_Date_Of_Booking')"
        [required]="true"
      >
      </app-input-date>
    </div>

    <div class="grid grid-cols-2 gap-4 mb-2">
      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_Rates_Of_Pay') }}</mat-label>
        <input
          appDecimalMask
          maxNumberDecimals="2"
          maxNumberDigitsBeforeSeparator="10"
          name="contractorFees"
          [formControl]="contractorFeesControl"
          matInput
          required
        />
        <span matSuffix>%</span>
        <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
      </mat-form-field>
      <div>
        <span class="ml-2 font-bold text-xs underline cursor-pointer" (click)="openFeeHistory()">{{
          i18nService._('TXT_lot_fees_history_step_one_resa_link_title')
        }}</span>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4 mb-2">
      <mat-form-field class="col-span-1">
        <mat-label>{{ i18nService._('Txt_Effective_Amount_Of_Fees') }}</mat-label>
        <input
          appDecimalMask
          maxNumberDecimals="0"
          maxNumberDigitsBeforeSeparator="10"
          type="text"
          name="contractorFeesAmount"
          [formControl]="contractorFeesAmountControl"
          matInput
        />
        <span matSuffix>€</span>
        <mat-error>
          {{ i18nService._('Error_Field_ValueBetween', [0, 9999999999]) }}
        </mat-error>
      </mat-form-field>
    </div>

    <ng-container *ngIf="userRoleService.isValo()">
      <div class="grid grid-cols-2 gap-4 mb-4">
        <mat-radio-group class="col-span-1" [formControl]="specificContractorFeesControl">
          <div class="text-base font-semibold mb-2">
            {{ i18nService._('Txt_Specific_Contractor_Fees') }}
          </div>

          <div class="grid grid-cols-2">
            <mat-radio-button [value]="true" [checked]="this.resaInformation.specificContractorFees" color="primary">
              {{ i18nService._('Txt_RadioButton_Yes') }}
            </mat-radio-button>
            <mat-radio-button [value]="false" [checked]="!this.resaInformation.specificContractorFees" color="primary">
              {{ i18nService._('Txt_RadioButton_No') }}
            </mat-radio-button>
          </div>
        </mat-radio-group>

        <mat-form-field *ngIf="specificContractorFeesControl.value" class="col-span-1">
          <mat-label>{{ i18nService._('Txt_Specific_Contractor_Fees_Comment') }}</mat-label>
          <textarea
            matInput
            name="specificContractorFeesComment"
            [formControl]="specificContractorFeesCommentControl"
            maxlength="2000"
          ></textarea>
        </mat-form-field>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>

<!-- Section Administrateur des ventes -->
<mat-card appearance="outlined" class="mb-4">
  <mat-card-title class="text-lg mx-auto mb-4">
    {{ i18nService._('Title_Sales_Administrator') }}
  </mat-card-title>
  <mat-card-content>
    <div class="mb-4 text-base font-semibold">
      {{ i18nService._('Title_Sales_Administrator_Valorissimo') }}
    </div>

    <div class="grid grid-cols-2 gap-4 mb-3">
      <app-dropdown-list-populated
        class="col-span-1"
        [parentForm]="advForm"
        [searchable]="true"
        [items]="advValoList"
        [placeholder]="i18nService._('Txt_Placeholder_AdvValorissimo')"
        [fieldName]="'advValo'"
        [isSubmit]="isSubmit"
        [required]="true"
        [dataToInit]="{ advValoId: this.resaInformation.advValoId }"
      >
      </app-dropdown-list-populated>
    </div>

    <div class="flex flex-row mb-4 text-base font-semibold">
      {{ i18nService._('Title_Sales_Administrator_Developer') }}
    </div>

    <app-contact-information
      [parentForm]="advForm"
      [chipFunctionDisplay]="false"
      [deskPhoneDisplay]="false"
      [emailRequired]="false"
      [mobilePhoneRequired]="false"
      [includeCivility]="false"
      [isSubmit]="isSubmit"
      [firstNameRequired]="!isCanceled"
      [lastNameRequired]="!isCanceled"
      [lastNamePlaceholder]="i18nService._('Txt_Placeholder_LastNameADV')"
      [firstNamePlaceholder]="i18nService._('Txt_Placeholder_FirstNameADV')"
      [emailPlaceholder]="i18nService._('Txt_Placeholder_EmailADV')"
      [mobilePhonePlaceholder]="i18nService._('Txt_Placeholder_PhoneADV')"
    >
    </app-contact-information>
  </mat-card-content>
</mat-card>
