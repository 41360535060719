<div *ngIf="row" class="items-center h-auto menu-context-extra">
  <div class="menu-context-extra {{ disableAction ? ' disabled' : '' }}">
    <span class="mat-button-wrapper">
      <button
        data-cy="cliquer"
        mat-menu-item
        [disabled]="disableAction"
        [matMenuTriggerFor]="menu"
        class="button--no-style p-0 text-2xl mt-1"
      >
        <span class="material-icons-outlined txt-orange text-xl leading-none border-orange-500 border padding-btn-more-action rounded-full">
          more_horiz
        </span>
      </button>
    </span>
  </div>
</div>

<mat-menu #menu="matMenu">
  <div *ngFor="let item of items">
    <button *ngIf="item.isDownload" (click)="item.action($event, row)" [disabled]="row && row.downloading" mat-menu-item>
      <div class="spinner-container" *ngIf="row && row.downloading">
        <mat-progress-spinner color="primary" diameter="30" mode="indeterminate" value="50"></mat-progress-spinner>
      </div>
      <span>
        {{ i18nService._(item.label) }}
      </span>
    </button>

    <button *ngIf="!item.isDownload" (click)="item.action(row)" mat-menu-item [attr.data-cy]="item.dataCy">
      <span>
        {{ i18nService._(item.label) }}
      </span>
    </button>
  </div>
</mat-menu>
