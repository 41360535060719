import { Component, Input } from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AdvancedTrackingTableColumn } from '../../../../models/AdvancedTrackingTableColumn';
import { I18nService } from '../../../../../utils/services/i18n.service';

@Component({
  selector: 'app-i18n-filters-tracking-table',
  templateUrl: './i18n-filters-tracking-table.component.html',
  styleUrls: ['./i18n-filters-tracking-table.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule],
})
export class I18nFiltersTrackingTableComponent {
  @Input() i18nFiltersForm: UntypedFormGroup;
  @Input() tableColumn: AdvancedTrackingTableColumn;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() refenceTableToFilter: any;

  constructor(public readonly i18nService: I18nService) {}
}
