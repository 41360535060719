<div class="text-base font-bold text-left mb-3">
  {{ i18nService._('Title_Form_SearchForm_Orientation') }}
</div>
<div class="flex flex-col">
  <app-checkbox-populated
    class="grid grid-cols-2"
    [referenceTable]="'LotOrientationHasLotOrientations/cardinality'"
    [(checkedElements)]="search.lotOrientationIds"
    (checkedElementsChange)="selectedItem('lotOrientationIds', $event)"
    [sortMethod]="orderBy()"
    [numberColumnsToDisplay]="2"
  >
  </app-checkbox-populated>
</div>
