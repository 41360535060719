import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';

import { CitiesLocationData } from '../models/CitiesLocationData';
import { CitiesLocationResponse } from '../models/CitiesLocationResponse';
import { CityResponse } from '../models/CityResponse';
import { AppConfigService } from './app-config.service';
import { ErrorHandlerService } from './error-handler.service';

import { AutoCompleteService } from '../../common/form/auto-complete.service';
import { CityApiService } from '../../adapters/city-api.service';

@Injectable({
  providedIn: 'root',
})
export class LocalisationService implements AutoCompleteService {
  readonly localizationApi = '/Cities/localization';
  readonly getCityApi = '/Cities/getCity';

  constructor(
    public http: HttpClient,
    public errorHandlerService: ErrorHandlerService,
    public appConfig: AppConfigService,
    public cityApiService: CityApiService,
  ) {}

  async getCities(zipCode: string): Promise<CityResponse[]> {
    return this.cityApiService.getCities(zipCode);
  }

  async getCityById(inseeCode: string): Promise<CityResponse> {
    return this.cityApiService.getCityById(inseeCode);
  }

  searchLocalisation(data: CitiesLocationData): Observable<CitiesLocationResponse> {
    if (!data.filter) {
      return of(new CitiesLocationResponse());
    }
    data.language = navigator.languages[0] || navigator.language;
    const url = this.appConfig.getLoopbackApiUrl() + this.localizationApi;

    return this.http
      .post<CitiesLocationResponse>(url, data)
      .pipe(catchError(this.errorHandlerService.handleError<CitiesLocationResponse>('cityService', 'searchLocation')));
  }

  findData(city: string): Observable<CityResponse[]> {
    const url = this.appConfig.getLoopbackApiUrl() + this.getCityApi;

    return this.http
      .get<CityResponse[]>(url, { params: { city } })
      .pipe(catchError(this.errorHandlerService.handleError<CityResponse[]>('cityService', 'searchLocation')));
  }
}
