import { Component, Input } from '@angular/core';
import { NgFor } from '@angular/common';

import { SearchResultProgramComponent } from '../search-result-program/search-result-program.component';

import { ProgramResponse } from '../../../utils/models/ProgramResponse';

@Component({
  selector: 'app-search-results-programs',
  templateUrl: './search-results-programs.component.html',
  styleUrls: ['./search-results-programs.component.scss'],
  standalone: true,
  imports: [NgFor, SearchResultProgramComponent],
})
export class SearchResultsProgramsComponent {
  @Input() programs: Array<ProgramResponse>;
}
