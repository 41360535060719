/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, KeyValuePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

import { GridTrackingTableComponent } from '../grid-tacking-table/grid-tracking-table.component';

import { QuestionDialogComponent } from '../../../dialog/components/question-dialog/question-dialog.component';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { Sitemap } from '../../../utils/models/Sitemap';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { TokenService } from '../../../utils/services/authorisation/token.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { ProgramsTrackingTableService } from '../../services/programs-tracking-table.service';
import { DateRendererComponent } from '../../renderers/date-renderer/date-renderer.component';
import { StatusTagsRendererComponent } from '../../renderers/status-tags-renderer/status-tags-renderer.component';
import { ActionRendererComponent } from '../../renderers/action-renderer/action-renderer.component';
import { ProgramsTrackingTableItem } from '../../models/ProgramsTrackingTableItem';
import { StatusTagColor } from '../../../design-system/model/status-tag-color.enum';
import { ProgramStatusEnum } from '../../../utils/enums/statusEnums/program-status.enum';
import { BooleanRendererComponent } from '../../renderers/boolean-renderer/boolean-renderer.component';
import { FilterEventOutput } from '../../models/filter/filter-event-output';
import { AbstractTrackingTable } from '../../abstract-tracking-table';
import { SharedProgramService } from '../../../utils/services/shared-program.service';
import { QuestionDialogData } from '../../../dialog/models/QuestionDialogData';
import { QuestionDialogResponse } from '../../../dialog/models/QuestionDialogResponse';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';

const tagTypeByProgramStatus: { [key in ProgramStatusEnum]: StatusTagColor } = {
  DRAFT: StatusTagColor.IN_PROGRESS,
  PENDING: StatusTagColor.IN_PROGRESS,
  VALID: StatusTagColor.SUCCESS,
  INCOMPLETE: StatusTagColor.CRITICAL,
  DENIED: StatusTagColor.CRITICAL,
  PUBLISHED: StatusTagColor.SUCCESS,
  UNPUBLISHED: StatusTagColor.CRITICAL,
  SCHEDULED: StatusTagColor.IN_PROGRESS,
};

@Component({
  selector: 'app-programs-tracking-table',
  templateUrl: './programs-tracking-table.component.html',
  styleUrls: ['./programs-tracking-table.component.scss'],
  standalone: true,
  imports: [
    StickyHeaderFormComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    GridTrackingTableComponent,
    KeyValuePipe,
  ],
})
export class ProgramsTrackingTableComponent extends AbstractTrackingTable implements OnInit {
  columnDefs: ColDef[];
  pageSize = 10;
  filterValue: string;
  statusFilter: string[];
  filterUrlParam: FilterEventOutput = {};

  public ProgramStatusEnum = ProgramStatusEnum;

  /**
   * displayNewProgButton attribute
   *
   * @type {boolean}
   * @memberof ProgramsTrackingTableComponent
   */
  public displayNewProgButton: boolean;

  @ViewChild(GridTrackingTableComponent)
  gridTrackingTable: GridTrackingTableComponent;

  noResultMessageI18nToken = 'Txt_ProgramsTrackingTable_No_Result';
  textFilterSubject = new Subject<string>();
  textFilterValue: string;

  /**
   * Creates an instance of ProgramsTrackingTableComponent.
   * @param {I18nService} i18nService
   * @param {SharedProgramService} sharedProgramService
   * @param {AppConfigService} appConfigService
   * @param {TokenService} tokenService
   * @param {SnackbarService} snackbarService
   * @param {MatDialog} dialog
   * @param {ProgramsTrackingTableService} service
   * @param {Router} router
   * @param {ActivatedRoute} route
   * @memberof ProgramsTrackingTableComponent
   */
  constructor(
    public readonly i18nService: I18nService,
    public readonly sharedProgramService: SharedProgramService,
    private readonly appConfigService: AppConfigService,
    private readonly tokenService: TokenService,
    private readonly snackbarService: SnackbarService,
    private readonly dialog: MatDialog,
    public readonly service: ProgramsTrackingTableService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public readonly programsTrackingTableService: ProgramsTrackingTableService,
  ) {
    super(Sitemap.programs.admin.path, router);

    this.columnDefs = [
      {
        field: 'companyTradingName',
        headerName: this.i18nService._('Txt_ProgramsTrackingTable_CompanyTradingName'),
        flex: 1,
      },
      {
        field: 'programName',
        headerName: this.i18nService._('Txt_ProgramsTrackingTable_ProgramName'),
        flex: 1,
      },
      {
        field: 'lotsPublishedOnLotsStock',
        headerName: this.i18nService._('Txt_ProgramsTrackingTable_LotsPublishedOnLotsStock'),
        sortable: false,
        flex: 0,
        minWidth: 160,
        maxWidth: 160,
      },
      {
        field: 'optionedLots',
        headerName: this.i18nService._('Txt_ProgramsTrackingTable_OptionedLots'),
        flex: 0,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        field: 'deliveryDate',
        headerName: this.i18nService._('Txt_ProgramsTrackingTable_DeliveryDate'),
        cellRenderer: DateRendererComponent,
        cellRendererParams: {
          replaceFalsyWithDash: true,
        },
        flex: 0,
        minWidth: 100,
        maxWidth: 100,
      },
      {
        field: 'actable',
        headerName: this.i18nService._('Txt_ProgramsTrackingTable_Actability'),
        cellRenderer: BooleanRendererComponent,
        flex: 0,
        minWidth: 70,
        maxWidth: 70,
      },
      {
        field: 'cityPostalCode',
        headerName: this.i18nService._('Txt_ProgramsTrackingTable_CityPostalCode'),
        flex: 1,
        minWidth: 110,
      },
      {
        field: 'isFromFlux',
        headerName: this.i18nService._('Txt_ProgramTrackingTable_IsFromFlux'),
        cellRenderer: BooleanRendererComponent,
        flex: 0,
        minWidth: 65,
        maxWidth: 65,
      },
      {
        field: 'scheduledPublicationDate',
        headerName: this.i18nService._('Txt_ProgramTrackingTable_sheduledDate'),
        cellRenderer: DateRendererComponent,
        cellRendererParams: {
          replaceFalsyWithDash: true,
          format: 'DD/MM/YYYY à HH\\h',
        },
        flex: 0,
        minWidth: 130,
        maxWidth: 130,
      },
      {
        field: 'programStatusLabel',
        headerName: this.i18nService._('Txt_ProgramsTrackingTable_ProgramStatus'),
        cellRenderer: StatusTagsRendererComponent,
        cellRendererParams: {
          tagsList: tagTypeByProgramStatus,
          outline: true,
        },
        valueFormatter: (params) => {
          if (params.value) {
            return this.i18nService._('PROGRAM_' + params.value);
          }
          return '';
        },
        flex: 0,
        minWidth: 160,
        maxWidth: 160,
      },
      {
        field: 'actions',
        headerName: this.i18nService._('Txt_ProgramsTrackingTable_Actions'),
        sortable: false,
        cellStyle: { paddingRight: '10px', 'justify-content': 'flex-end' },
        cellRenderer: ActionRendererComponent,
        cellRendererParams: {
          actionItems: (program: ProgramsTrackingTableItem) => {
            return this.getActionItems(program);
          },
        },
        flex: 0,
        minWidth: 65,
        maxWidth: 65,
      },
    ];
  }

  getActionItems(program: ProgramsTrackingTableItem) {
    const actions = [];

    if (program?.actions?.canUpdateProgram) {
      actions.push({
        label: 'Txt_Tooltip_EditProgram',
        action: () => {
          this.router.navigate([Sitemap.programs.edit.path.replace(/:programId/, program.id.toString())]);
        },
      });
    }

    if (program?.actions?.canRedirectToStrategies) {
      actions.push({
        label: 'Txt_Tooltip_RedirectToStrategies',
        action: () => {
          this.router.navigate([Sitemap.programs.strategies.path.replace(/:programId/, program.id.toString())]);
        },
      });
    }

    if (program?.actions?.canValidateProgram) {
      actions.push({
        label: 'Txt_Tooltip_ValidateProgram',
        action: () => {
          this.router.navigate([Sitemap.programs.validate.path.replace(/:programId/, program.id.toString())]);
        },
      });
    }

    if (program?.actions?.canPublishProgram) {
      actions.push({
        label: 'Txt_Tooltip_Publish',
        action: () => {
          this.publish(program);
        },
      });
    }

    if (program?.actions?.canUnpublishProgram) {
      actions.push({
        label: 'Txt_Tooltip_Unpublish',
        action: () => {
          this.unpublish(program);
        },
      });
    }

    if (program?.actions?.canCancelSchedulingProgram) {
      actions.push({
        label: 'Txt_Tooltip_Unschedule',
        action: () => {
          this.cancelSchedule(program);
        },
      });
    }
    return actions;
  }

  onStatusListChange(statusList: { value: string[] }): void {
    this.statusFilter = statusList.value;
    this.filterUrlParam.statusFilter = this.statusFilter;
    super.filterEvent(this.filterUrlParam);
  }

  /**
   * Init method
   *
   * @memberof ProgramsTrackingTableComponent
   */
  ngOnInit(): void {
    this.setFilterValue();
    this.checkIfNewProgButtonIsDisplayed();
    this.onTextFilterChange();
  }

  onTextFilterChange() {
    this.textFilterSubject.pipe(debounceTime(this.textFilterDebounceTimer), distinctUntilChanged()).subscribe((value) => {
      this.filterUrlParam.filter = value;
      super.filterEvent(this.filterUrlParam);
      this.textFilterValue = value;
    });
  }

  checkIfNewProgButtonIsDisplayed() {
    const appConfig = this.appConfigService.appConfig;

    // Compute boolean button
    const rolesList = [
      appConfig.roles.valoSystem,
      appConfig.roles.valoAdminSuper,
      appConfig.roles.valoAdminSimple,
      appConfig.roles.valoDevSuper,
      appConfig.roles.valoDevSimple,
      appConfig.roles.valoMarketerSuper,
      appConfig.roles.valoMarketerSimple,
      appConfig.roles.developerHyper,
      appConfig.roles.developerSuper,
    ];

    // Check is the user is a valo user
    if (this.tokenService.checkIsLoggedIn() && rolesList.includes(this.tokenService.getRoleName())) {
      this.displayNewProgButton = true;
    }
  }

  setFilterValue() {
    this.filterValue = this.route.snapshot.queryParamMap.get('filter');
    this.textFilterValue = this.route.snapshot.queryParamMap.get('filter');
    this.filterUrlParam.filter = this.filterValue;

    const statusFilterParams = this.route.snapshot.queryParamMap.get('statusFilter');
    if (statusFilterParams) {
      this.statusFilter = statusFilterParams.split(',');
      this.filterUrlParam.statusFilter = this.statusFilter;
    }
  }

  /**
   * redirectionProgramCreation method
   *
   * @memberof ProgramsTrackingTableComponent
   */
  public redirectionProgramCreation(): void {
    this.router.navigate([Sitemap.programs.register.path]);
  }

  publish(program: ProgramsTrackingTableItem) {
    this.sharedProgramService.publishUnpublishProgram(program.id, true).subscribe(
      (successPublish: boolean) => {
        if (successPublish) {
          this.successPublishUnpublish(this.i18nService._('Info_SnackBar_ProgramAndLotsPublished'), program.id);
        }
      },
      (_err) => {
        this.snackbarService.sendErrorOccured();
      },
    );
  }

  unpublish(program: ProgramsTrackingTableItem) {
    const dialogData: MatDialogConfig<QuestionDialogData> = {
      data: {
        message: this.i18nService._('Txt_Popin_UnpublishProgram'),
        title: this.i18nService._('Title_UnpublishProgram'),
      },
    };

    const dialogRef = this.dialog.open(QuestionDialogComponent, dialogData);

    dialogRef.afterClosed().subscribe((result: QuestionDialogResponse) => {
      if (result && result.answer) {
        this.sharedProgramService.publishUnpublishProgram(program.id, false).subscribe(
          (successUnpublish: boolean) => {
            if (successUnpublish) {
              this.successPublishUnpublish(this.i18nService._('Info_SnackBar_ProgramAndLotsUnpublished'), program.id);
            }
          },
          (_err) => {
            this.snackbarService.sendErrorOccured();
          },
        );
      }
    });
  }

  cancelSchedule(program: ProgramsTrackingTableItem) {
    const dialogData: MatDialogConfig<QuestionDialogData> = {
      data: {
        message: this.i18nService._('Txt_Popin_UnscheduleProgram'),
        title: this.i18nService._('Title_Unschedule'),
      },
    };

    const dialogRef = this.dialog.open(QuestionDialogComponent, dialogData);

    dialogRef.afterClosed().subscribe((result: QuestionDialogResponse) => {
      if (result && result.answer) {
        this.sharedProgramService.unscheduleProgramPublication(program.id).subscribe(() => {
          this.successPublishUnpublish(this.i18nService._('Info_SnackBar_UnscheduleProgramPublicationSuccess'), program.id);
        });
      }
    });
  }

  /**
   * Actions to do on publish-unpublish success (snackbar info and reload advanced tracking table)
   *
   * @private
   * @param {string} infoMessage
   * @memberof ProgramsTrackingTableComponent
   */
  private successPublishUnpublish(infoMessage: string, id: number): void {
    this.snackBarInfoMessage(infoMessage);
    this.gridTrackingTable.updateSingleDataRow(id);
  }

  /**
   * Generic method to print snackBar Info message
   *
   * @private
   * @param {string} infoMessage
   * @memberof ProgramsTrackingTableComponent
   */
  private snackBarInfoMessage(infoMessage: string): void {
    this.snackbarService.sendMessage({
      text: infoMessage,
      type: SnackbarMessageType.Info,
    });
  }
}
