/* eslint-disable @typescript-eslint/no-unused-vars */
import { formatCurrency } from '@angular/common';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { LabelType, NgxSliderModule } from '@angular-slider/ngx-slider';

import { AbstractSearchFilter } from '../abstract-search-filter';

import { FLOOR_ITEMS } from '../../../../utils/models/app-constant';
import { AppConfigService } from '../../../../utils/services/app-config.service';
import { I18nService } from '../../../../utils/services/i18n.service';

@Component({
  selector: 'app-search-filter-budget',
  templateUrl: './search-filter-budget.component.html',
  styleUrls: ['./search-filter-budget.component.scss'],
  standalone: true,
  imports: [NgxSliderModule],
})
export class SearchFilterBudgetComponent extends AbstractSearchFilter {
  /**
   * RG_95.3.1 : Room items
   */
  readonly floorItems = FLOOR_ITEMS;
  public budgetSliderOptions: any;

  constructor(
    public readonly i18nService: I18nService,
    public appConfigService: AppConfigService,
  ) {
    super();
    const budgetFloor = appConfigService.getAppConfig().budgetFloor;
    const budgetCeil = appConfigService.getAppConfig().budgetCeil;

    this.budgetSliderOptions = {
      floor: budgetFloor,
      ceil: budgetCeil,
      step: 10000,
      hideLimitLabels: true,
      translate: (value: number, label: LabelType): string => {
        return `${value >= budgetCeil ? '+' : ''} ${formatCurrency(value, 'fr', '€', 'EUR', '1.0-0')}`;
      },
    };
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    super.ngOnInit();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected initData() {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  selectedItem(_$event) {}
}
