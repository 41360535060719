import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfigOption, FormlyModule } from '@ngx-formly/core';
import { MatStepperModule } from '@angular/material/stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { FormlyPrimeNGModule } from '@ngx-formly/primeng';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { InputNumberModule } from 'primeng/inputnumber';
import { AccordionModule } from 'primeng/accordion';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';

import { FormlyStepperComponent } from './formly-stepper/formly-stepper.component';
import { AppFormModule } from '../form/app-form.module';
import { FormlyRadioCardsComponent } from './formly-radio-cards/formly-radio-cards.component';
import { SharedModule } from '../shared/shared.module';
import { FormlyFieldClassExtention } from './formly-field-class-extention';
import { FormlyAutoCompleteComponent } from './formly-auto-complete/formly-auto-complete.component';
import { FormlyLocalizationComponent } from './formly-localization/formly-localization.component';
import { FormlyCityComponent } from './formly-city/formly-city.component';
import { FormlyDateComponent } from './formly-date/formly-date.component';
import { FormlyInputPhoneNumberComponent } from './formly-input-phone-number/formly-input-phone-number.component';
import { FormlyInfoBoxComponent } from './formly-info-box/formly-info-box.component';
import { FormlyFileDropComponent } from './formly-file-drop/formly-file-drop.component';
import { FormlyDropdownComponent } from './formly-dropdown/formly-dropdown.component';
import { FormlyWrapperLabelHtmlComponent } from './formly-wrapper-label-html/formly-wrapper-label-html.component';
import { FormlyInputNumberComponent } from './formly-input-number/formly-input-number.component';
import { FormlyTextComponent } from './formly-text/formly-text.component';
import { FormlyActionComponent } from './formly-action/formly-action.component';
import { FormlyRepeatComponent } from './formly-repeat/formly-repeat.component';
import { FormlyBoxHeaderComponent } from './formly-box-header/formly-box-header.component';
import { FormlyLinkComponent } from './formly-link/formly-link.component';
import { FormlyInputComponent } from './formly-input/formly-input.component';
import { FormlySmallStepperComponent } from './formly-small-stepper/formly-small-stepper.component';
import { FormlyAccordionComponent } from './formly-accordion/formly-accordion.component';
import { IconComponent } from '../standalone/icon/icon.component';
import { FormlyWrapperInlineLabelHtmlComponent } from './formly-wrapper-inline-label-html/formly-wrapper-inline-label-html.component';
import { FormlySimpleComponent } from './formly-simple/formly-simple.component';
import { FormlyPanelComponent } from './formly-panel/formly-panel.component';
import { FormlyFileComponent } from './formly-file/formly-file.component';
import { FormlyFileValueAccessorDirective } from './formly-file-value-accessor';
import { FormlyTextareaComponent } from './formly-textarea/formly-textarea.component';
import { FormlyCalendarComponent } from './formly-calendar/formly-calendar.component';
import { FormlyTableComponent } from './formly-table/formly-table.component';
import { FormlyDropdownMultipleComponent } from './formly-dropdown-multiple/formly-dropdown-multiple.component';
import { FileComponent } from '../form/file/file.component';
import { FormlyRadioComponent } from './formly-radio/formly-radio.component';
import { FormlyLocalisationCityAreaComponent } from './formly-localisation-city-area/formly-localisation-city-area.component';
import { FormlyChipListComponent } from './formly-chipList/formly-chip-list.component';

import { PipesModule } from '../../utils/pipes/pipes.module';
import { isEmailValidators } from '../../utils/validators/is-email-validator.directive';
import { TrackingTablesModule } from '../../tracking-tables/tracking-tables.module';

export const FORMLY_CONFIG: ConfigOption = {
  types: [
    { name: 'simple', component: FormlySimpleComponent, wrappers: [] },
    { name: 'stepper', component: FormlyStepperComponent, wrappers: [] },
    { name: 'small-stepper', component: FormlySmallStepperComponent, wrappers: [] },
    { name: 'radio-cards', component: FormlyRadioCardsComponent },
    { name: 'autocomplete', component: FormlyAutoCompleteComponent, wrappers: ['form-field'] },
    { name: 'localization', component: FormlyLocalizationComponent },
    { name: 'city', component: FormlyCityComponent },
    { name: 'radio', component: FormlyRadioComponent },
    { name: 'phone', component: FormlyInputPhoneNumberComponent, wrappers: ['form-field'] },
    { name: 'number', component: FormlyInputNumberComponent, wrappers: ['form-field'] },
    { name: 'date', component: FormlyDateComponent, wrappers: ['form-field'] },
    { name: 'info-box', component: FormlyInfoBoxComponent },
    { name: 'text', component: FormlyTextComponent },
    { name: 'file-drop', component: FormlyFileDropComponent },
    { name: 'action', component: FormlyActionComponent },
    { name: 'repeat', component: FormlyRepeatComponent },
    { name: 'select', component: FormlyDropdownComponent, wrappers: ['form-field'] },
    { name: 'select-multiple', component: FormlyDropdownMultipleComponent, wrappers: ['form-field'] },
    { name: 'box-header', component: FormlyBoxHeaderComponent },
    { name: 'link', component: FormlyLinkComponent },
    { name: 'input', component: FormlyInputComponent },
    { name: 'enum', extends: 'select' },
    { name: 'accordion', component: FormlyAccordionComponent, wrappers: [] },
    { name: 'panel', component: FormlyPanelComponent },
    { name: 'file', component: FormlyFileComponent, wrappers: ['form-field'] },
    { name: 'textarea', component: FormlyTextareaComponent },
    { name: 'calendar', component: FormlyCalendarComponent, wrappers: ['form-field'] },
    { name: 'table', component: FormlyTableComponent },
    { name: 'localisation-city-area', component: FormlyLocalisationCityAreaComponent },
    { name: 'chip-list', component: FormlyChipListComponent },
  ],
  validators: [{ name: 'email', validation: isEmailValidators() }],
  wrappers: [
    { name: 'form-field', component: FormlyWrapperLabelHtmlComponent },
    { name: 'inline-form-field', component: FormlyWrapperInlineLabelHtmlComponent },
    { name: 'panel', component: FormlyPanelComponent },
  ],
  validationMessages: [{ name: 'email', message: 'Email invalide' }],
  extensions: [
    {
      name: 'default-class',
      extension: FormlyFieldClassExtention,
    },
  ],
};

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AppFormModule,
    FormlyModule.forRoot(FORMLY_CONFIG),
    FormlyPrimeNGModule,
    MatStepperModule,
    CdkStepperModule,
    SharedModule,
    AutoCompleteModule,
    InputTextModule,
    DropdownModule,
    MultiSelectModule,
    FormlySelectModule,
    PipesModule,
    InputNumberModule,
    AccordionModule,
    TooltipModule,
    CalendarModule,
    IconComponent,
    TrackingTablesModule,
    FileComponent,
    FormlySimpleComponent,
    FormlyStepperComponent,
    FormlySmallStepperComponent,
    FormlyRadioCardsComponent,
    FormlyAutoCompleteComponent,
    FormlyLocalizationComponent,
    FormlyCityComponent,
    FormlyDateComponent,
    FormlyInputPhoneNumberComponent,
    FormlyInfoBoxComponent,
    FormlyFileDropComponent,
    FormlyDropdownComponent,
    FormlyDropdownMultipleComponent,
    FormlyWrapperLabelHtmlComponent,
    FormlyWrapperInlineLabelHtmlComponent,
    FormlyInputNumberComponent,
    FormlyTextComponent,
    FormlyActionComponent,
    FormlyRepeatComponent,
    FormlyBoxHeaderComponent,
    FormlyLinkComponent,
    FormlyInputComponent,
    FormlyAccordionComponent,
    FormlyPanelComponent,
    FormlyFileComponent,
    FormlyFileValueAccessorDirective,
    FormlyTextareaComponent,
    FormlyCalendarComponent,
    FormlyTableComponent,
    FormlyPanelComponent,
    FormlyFileComponent,
    FormlyFileValueAccessorDirective,
    FormlyLocalisationCityAreaComponent,
    FormlyChipListComponent,
  ],
  providers: [],
})
export class AppFormlyModule {}
