/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DossierResponse } from '@commons-dto/dossier-prospect';
import { NgClass, UpperCasePipe } from '@angular/common';

import { I18nService } from '../../../utils/services/i18n.service';
import { DossierProspectService } from '../../../dossier-prospect/services/dossier-prospect.service';
import { IconComponent } from '../../../common/standalone/icon/icon.component';

@Component({
  selector: 'app-prospect-renderer',
  templateUrl: './prospect-renderer.component.html',
  styleUrls: ['./prospect-renderer.component.scss'],
  standalone: true,
  imports: [NgClass, IconComponent, UpperCasePipe],
})
export class ProspectRendererComponent implements ICellRendererAngularComp {
  public cellValue: any;
  public prospectType: string;
  public icon: string;
  public click: any;
  public row: any;
  public isClickable = false;

  constructor(
    public readonly i18nService: I18nService,
    public readonly dossierProspectService: DossierProspectService,
  ) {}

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params) ?? '-';
    this.prospectType = this.cellValue ? this.cellValue[0].prospectType : null;
    this.row = params.data;
    this.click = params['click'];
    this.isClickable = !!this.click;
    const dossier: DossierResponse = {
      id: 0,
      prescriptorId: 0,
      dossierStatusCode: null,
      prospects: this.cellValue,
    };
    this.icon = this.dossierProspectService.getIcon(dossier);
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    // set value into cell again
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.value ? params.value : params.valueFormatted;
  }

  onClickAction() {
    if (this.isClickable) {
      this.click(this.row);
    }
  }
}
