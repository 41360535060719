import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { isNil } from 'lodash';
import { ParamRequest } from '@commons-dto/valo-back';

import { DataLayerApiService } from './data-layer-api.service';
import { AccountTrackingTableItem } from '../tracking-tables/models/AccountTrackingTableItem';
import { AccountTrackingTableServiceResponse } from '../tracking-tables/models/AccountTrackingTableServiceResponse';
import { CompanyType } from '../utils/models/enums/CompanyType.enum';
import { AccountParamRequest } from './models/account/account-request';
import { AccountResponse } from '../utils/models/AccountResponse';
import { AccountsResponse } from './models/account/accounts.response';

@Injectable({ providedIn: 'root' })
export class AccountApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Get accounts for tracking table by params
   *
   * @returns {Observable<AccountTrackingTableServiceResponse>}
   * @memberof DataLayerApiService
   */
  getAccountsTable(params: ParamRequest, type: CompanyType): Observable<AccountTrackingTableServiceResponse> {
    const path = this.BASE_URL + 'Accounts/tracking-table/accounts/' + type.toLowerCase();
    return this.getTableRessources(params, path);
  }

  /**+
   * Get account for tracking table by id
   *
   * @returns {Observable<AccountTrackingTableItem>}
   * @memberof DataLayerApiService
   */
  getAccountTableById(id: number, type: CompanyType): Observable<AccountTrackingTableItem> {
    const path = this.BASE_URL + 'Accounts/tracking-table/accounts/' + type.toLowerCase();
    return this.getRessource(path, id);
  }

  /**
   * Get accounts by params
   *
   * @returns {Observable<AccountTrackingTableServiceResponse>}
   * @memberof DataLayerApiService
   */
  getAccounts(params: AccountParamRequest): Observable<AccountsResponse> {
    const path = this.BASE_URL + 'Accounts';
    return this.getTableRessources(params, path);
  }

  /**+
   * Get account by id
   *
   * @returns {Observable<AccountTrackingTableItem>}
   * @memberof DataLayerApiService
   */
  getAccountById(id: number): Observable<AccountResponse> {
    const path = this.BASE_URL + 'Accounts/' + id;
    return this.get(path);
  }

  protected getQueryParams(paramsRequest: AccountParamRequest): HttpParams {
    let params = super.getQueryParams(paramsRequest);
    if (!isNil(paramsRequest.companyTypeId)) {
      for (const companyTypeId of paramsRequest.companyTypeId) {
        params = params.append('companyTypeId[]', companyTypeId);
      }
    }
    if (!isNil(paramsRequest.name)) {
      params = params.set('name', paramsRequest.name);
    }
    return params;
  }
}
