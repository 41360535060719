import { LotResponseDto } from '@commons-dto/dossier-prospect';
import { lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';

import { DataLayerApiService } from './data-layer-api.service';

@Injectable({ providedIn: 'root' })
export class DossierProspectLotApiService extends DataLayerApiService {
  BASE_URL = 'prospect/';

  /**
   * Get transactions by params
   *
   * @returns {Observable<LotResponseDto[]>}
   * @memberof DataLayerApiService
   */
  getLotsByDossierId(dossierProspectId: number): Promise<LotResponseDto[]> {
    const url = this.BASE_URL + 'dossiers/' + dossierProspectId + '/lots';
    return lastValueFrom(this.get(url));
  }
}
