import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable } from 'rxjs';

import { ProspectData } from '../models/ProspectData';
import { TakeOptionData } from '../models/TakeOptionData';

import { PoseoptionResponse } from '../../utils/models/PoseoptionResponse';
import { AppConfigService } from '../../utils/services/app-config.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';

export interface LotIdUpdateToOption {
  isAlert?: boolean;
  lotIdUpdateStatus?: number;
  lotIdUpdated?: number;
  isOptionned?: boolean;
  labelStatus?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProspectAssociationService {
  private readonly lotIdUpdateToOption: BehaviorSubject<LotIdUpdateToOption>;

  /**
   * Creates an instance of ProspectAssociationService.
   * @param {HttpClient} http
   * @param {ErrorHandlerService} errorHandlerService
   * @param {AppConfigService} appConfig
   * @memberof ProspectAssociationService
   */
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
  ) {
    this.lotIdUpdateToOption = new BehaviorSubject(undefined);
  }

  get lotIdUpdateToOptionObservable(): Observable<LotIdUpdateToOption> {
    return this.lotIdUpdateToOption.asObservable();
  }

  setLotIdUpdateToOption(lotIdUpdateToOption: LotIdUpdateToOption): void {
    this.lotIdUpdateToOption.next(lotIdUpdateToOption);
  }

  /**
   * getProspects method
   *
   * @returns {Observable<any>}
   * @memberof ProspectAssociationService
   */
  getProspects(): Observable<Array<ProspectData>> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Prospects/my-declared-prospects`;

    return this.http
      .get<Array<ProspectData>>(url)
      .pipe(catchError(this.errorHandlerService.handleError<Array<ProspectData>>('prospectAssociationService', 'getProspects')));
  }

  /**
   * takeOption method, need param option data
   *
   * @param {TakeOptionData} optionData
   * @returns {Observable<any>}
   * @memberof ProspectAssociationService
   */
  takeOption(optionData: TakeOptionData): Observable<PoseoptionResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Options/take`;

    return this.http
      .post<PoseoptionResponse>(url, optionData)
      .pipe(catchError(this.errorHandlerService.handleError<PoseoptionResponse>('prospectAssociationService', 'getProspects')));
  }
}
