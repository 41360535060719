import { Component, Input } from '@angular/core';
import { NgIf, DecimalPipe, CurrencyPipe } from '@angular/common';

import { I18nService } from '../../../utils/services/i18n.service';
import { FormViewResponse } from '../../models/FormResponse';
import { AppIconsName } from '../../../common/standalone/icon/utils/app-icon-names';
import { IconComponent } from '../../../common/standalone/icon/icon.component';
import { I18nTVALabelPourcentagePipe } from '../../../utils/pipes/i18n-tva-pourcentage.pipe';
import { Taxation } from '../../models/Taxation';

enum AcquisitionType {
  Individual = 1,
  Group = 2,
  Corporate = 3,
}

@Component({
  selector: 'app-side-info',
  templateUrl: './side-info.component.html',
  styleUrls: ['./side-info.component.scss'],
  standalone: true,
  imports: [NgIf, IconComponent, DecimalPipe, CurrencyPipe, I18nTVALabelPourcentagePipe],
})
export class SideInfoComponent {
  _formView: FormViewResponse;
  @Input()
  get formView(): FormViewResponse {
    return this._formView;
  }

  set formView(value: FormViewResponse) {
    this._formView = value;
    this.onContextDataUpdated();
  }

  acquisitionTypeIcon: AppIconsName;
  taxation: Taxation;

  constructor(public readonly i18nService: I18nService) {}

  onContextDataUpdated() {
    this.acquisitionTypeIcon = this.getAcquisitionTypeIcon();
    this.taxation = this.formView?.contextData?.lot?.taxation;
  }

  getAcquisitionTypeIcon(): AppIconsName {
    const acqType = this.formView?.contextData?.acqType;
    switch (acqType) {
      case AcquisitionType.Individual:
        return 'User';
      case AcquisitionType.Group:
        return 'Users';
      case AcquisitionType.Corporate:
        return 'BuildingOffice';
      default:
        return undefined;
    }
  }
}
