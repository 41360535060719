import { Component } from '@angular/core';
import { ILoadingOverlayParams } from 'ag-grid-community';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  standalone: true,
  templateUrl: './custom-loading-overlay.component.html',
  imports: [MatProgressSpinnerModule],
})
export class CustomLoadingOverlayComponent implements ILoadingOverlayAngularComp {
  constructor(public i18nService: I18nService) {}

  public params!: ILoadingOverlayParams & { loadingMessage: string };

  agInit(params: ILoadingOverlayParams & { loadingMessage: string }): void {
    this.params = params;
  }
}
