import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../security/authguard';
import { EditRegisterFormProgramComponent } from './components/edit-register-form-program/edit-register-form-program.component';
import { RegisterFormProgramComponent } from './components/register-form-program/register-form-program.component';
import { ValidateRegisterFormProgramComponent } from './components/validate-register-form-program/validate-register-form-program.component';
import { Sitemap } from '../utils/models/Sitemap';
import { ProgramPageV2Component } from './components/program-page-v2/program-page-v2.component';
import { ProgramPageResolver } from './components/program-page-v2/program-page.resolver';

const routes: Routes = [
  {
    path: Sitemap.programs.register.path,
    data: {
      roles: Sitemap.programs.register.data.roles,
    },
    component: RegisterFormProgramComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.programs.edit.path,
    data: {
      roles: Sitemap.programs.edit.data.roles,
    },
    component: EditRegisterFormProgramComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.programs.validate.path,
    data: {
      roles: Sitemap.programs.validate.data.roles,
    },
    component: ValidateRegisterFormProgramComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.programs.read.path,
    component: ProgramPageV2Component,
    canActivate: mapToCanActivate([AuthGuard]),
    resolve: { programData: ProgramPageResolver },
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
  declarations: [],
})
export class ProgramsRoutingModule {}
