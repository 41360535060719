import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CompleteDossierResponse } from '@commons-dto/dossier-prospect';
import { NgIf, NgFor } from '@angular/common';

import { PaternityQuickFormComponent } from './paternity-quick-form/paternity-quick-form.component';
import { PaternitiesListElementComponent } from './paternity-list-element/paternity-list-element.component';

import { DossierProspectService } from '../../services/dossier-prospect.service';

@Component({
  selector: 'app-paternities-list',
  templateUrl: './paternities-list.component.html',
  styleUrls: ['./paternities-list.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, PaternitiesListElementComponent, PaternityQuickFormComponent],
})
export class PaternitiesListComponent implements OnInit, OnDestroy {
  dossier: CompleteDossierResponse;
  private readonly _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private _prospect: DossierProspectService) {}

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  ngOnInit(): void {
    this._prospect.currentDossierSub.pipe(takeUntil(this._destroy$)).subscribe((dossier) => {
      this.dossier = dossier as CompleteDossierResponse;
    });
  }
}
