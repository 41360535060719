<mat-form-field class="w-100">
  <mat-label>{{ i18nService._('Title_Form_SearchForm_DeveloperName') }}</mat-label>
  <input
    (keyup)="onInputCompanyChange($event)"
    [formControl]="companyFormControl"
    [matAutocomplete]="companyAutoComplete"
    matInput
    maxlength="255"
    name="company"
    type="text"
  />

  <mat-autocomplete #companyAutoComplete="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let company of companyListFiltered" [value]="company">
      {{ company.corporateName }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
