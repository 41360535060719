/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

import { GridTrackingTableComponent } from '../grid-tacking-table/grid-tracking-table.component';

import { PaternityVerificationDialogComponent } from '../../../dialog/components/paternity-verification-dialog/paternity-verification-dialog.component';
import { QuestionDialogComponent } from '../../../dialog/components/question-dialog/question-dialog.component';
import { QuestionDialogData } from '../../../dialog/models/QuestionDialogData';
import { ProspectService } from '../../../prospects/services/prospect.service';
import { DeclaredProspectResponse } from '../../../utils/models/DeclaredProspectResponse';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { BasicFormatsService } from '../../../utils/services/basic-formats.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { AdvancedTrackingTableColumnType } from '../../models/advanced-tracking-table-column-type.enum';
import { AdvancedTrackingTablePipe } from '../../pipes/advanced-tracking-table.pipe';
import { ProspectsTrackingTableService } from '../../services/prospects-tracking-table.service';
import { StatusTagColor } from '../../../design-system/model/status-tag-color.enum';
import { ProspectStatusEnum } from '../../../utils/enums/statusEnums/prospect-status.enum';
import { UserRoleService } from '../../../utils/services/user-role.service';
import { DateRendererComponent } from '../../renderers/date-renderer/date-renderer.component';
import { StatusTagsRendererComponent } from '../../renderers/status-tags-renderer/status-tags-renderer.component';
import { ActionRendererComponent } from '../../renderers/action-renderer/action-renderer.component';
import { FilterEventOutput } from '../../models/filter/filter-event-output';
import { AbstractTrackingTable } from '../../abstract-tracking-table';
import { Sitemap } from '../../../utils/models/Sitemap';
import { TranslatePipe } from '../../../utils/pipes/translate.pipe';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';

const tagTypeByProspectStatus: { [key in ProspectStatusEnum]: StatusTagColor } = {
  ACCEPTED: StatusTagColor.SUCCESS,
  PENDING: StatusTagColor.IN_PROGRESS,
  REJECTED: StatusTagColor.CRITICAL,
};

@Component({
  selector: 'app-prospects-grid-tracking-table',
  templateUrl: './prospects-grid-tracking-table.component.html',
  styleUrls: ['./prospects-grid-tracking-table.component.scss'],
  standalone: true,
  imports: [
    StickyHeaderFormComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    NgIf,
    GridTrackingTableComponent,
    TranslatePipe,
  ],
})
export class ProspectsGridTrackingTableComponent extends AbstractTrackingTable implements OnInit {
  @ViewChild(GridTrackingTableComponent)
  gridTrackingTable: GridTrackingTableComponent;

  noResultMessageI18nToken = 'Txt_PaternitiesTrackingTable_No_Result';

  columns: ColDef[];
  serviceUrl: any;
  pageSize = 10;
  pageNumber = 1;
  sortDirection: 'ASC' | 'DESC';
  sortColumn: string;
  columnDefs: ColDef[];
  i18nFilter: any;
  filterValue: string;
  rvpSearch: string;
  minimumFilterLength = 3;
  statusFilterList = [
    { value: 1, label: 'PATERNITY_ACCEPTED', code: 'ACCEPTED' },
    { value: 2, label: 'PATERNITY_PENDING', code: 'PENDING' },
    { value: 3, label: 'PATERNITY_REJECTED', code: 'REJECTED' },
  ];
  selectedStatus: number[];
  filterUrlParam: FilterEventOutput;
  queryParamMap: any;
  displayRPV: boolean;
  textFilterSubject = new Subject<string>();
  textFilterValue: string;

  constructor(
    public readonly i18nService: I18nService,
    public readonly prospectsTrackingTableService: ProspectsTrackingTableService,
    private readonly dialog: MatDialog,
    private readonly prospectService: ProspectService,
    private readonly snackbarService: SnackbarService,
    private readonly appConfigService: AppConfigService,
    private readonly userRoleService: UserRoleService,
    private readonly _route: ActivatedRoute,
    private readonly router: Router,
    public readonly advancedTrackingTablePipe: AdvancedTrackingTablePipe,
  ) {
    super(Sitemap.dashboards.prospects.path, router);
    const dateFormat = BasicFormatsService.dateHourFormat;
    this.hideRPV();
    this.columnDefs = [
      {
        field: 'prospect',
        headerName: this.i18nService._('Txt_Table_Column_Prospect'),
        minWidth: 100,
        flex: 4,
      },
      {
        field: 'applicant',
        headerName: this.i18nService._('Title_Applicant'),
        minWidth: 100,
        flex: 4,
        hide: this.hideApplicant(),
      },
      {
        field: 'company',
        headerName: this.i18nService._('Title_Company'),
        minWidth: 100,
        flex: 4,
        hide: this.hideCompany(),
      },
      {
        field: 'city',
        headerName: this.i18nService._('Txt_ProspectTable_City'),
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'program',
        headerName: this.i18nService._('Title_PropertyProgram'),
        minWidth: 60,
        flex: 2,
      },
      {
        field: 'email',
        headerName: this.i18nService._('Txt_ProspectTable_Email'),
        minWidth: 100,
        flex: 4,
        hide: this.hideEmail(),
      },
      {
        field: 'phone',
        headerName: this.i18nService._('Txt_Placeholder_Phone'),
        minWidth: 80,
        flex: 2,
        hide: this.hidePhone(),
      },
      {
        field: 'paternityDateCreated',
        headerName: this.i18nService._('Txt_Table_Column_PaternityDateCreated'),
        minWidth: 80,
        flex: 2,
        cellRenderer: DateRendererComponent,
        cellRendererParams: {
          format: 'DD/MM/yyyy HH:mm',
        },
      },
      {
        field: 'paternityDateEnd',
        headerName: this.i18nService._('Txt_Table_Column_PaternityDateEnd'),
        minWidth: 80,
        flex: 2,
        cellRenderer: DateRendererComponent,
        cellRendererParams: {
          format: 'DD/MM/yyyy HH:mm',
        },
        valueFormatter: (params) => {
          return params?.data?.paternityStatus === this.i18nService._('PROSPECTS_REJECTED')
            ? ''
            : this.returnDate(params.value, dateFormat);
        },
      },
      {
        field: 'paternityStatus',
        headerName: this.i18nService._('Txt_Table_Column_PaternityStatus'),
        minWidth: 150,
        flex: 3,
        cellRenderer: StatusTagsRendererComponent,
        cellRendererParams: {
          tagsList: tagTypeByProspectStatus,
          outline: true,
        },
        valueFormatter: (params) => {
          return this.statusFilterList.find((status) => status.code === params.value)?.label ?? params.value;
        },
      },
      {
        field: 'paternityDateValidated',
        headerName: this.i18nService._('Txt_Table_Column_PaternityDateValidated'),
        minWidth: 80,
        flex: 2,
        cellRenderer: DateRendererComponent,
        cellRendererParams: {
          format: 'DD/MM/yyyy HH:mm',
        },
      },
      {
        field: 'rpvPrescFullname',
        headerName: this.i18nService._('Txt_ProspectTable_RPV_PARTNER'),
        minWidth: 100,
        flex: 4,
        hide: this.hideRPV(),
      },
      {
        field: 'actions',
        headerName: this.i18nService._('Title_TrackingTable_Column_Actions'),
        minWidth: 60,
        maxWidth: 60,
        flex: 2,
        hide: this.hideAction(),
        cellRenderer: ActionRendererComponent,
        cellRendererParams: {
          actionItems: (prospect: any) => {
            return this.getActionItems(prospect);
          },
        },
        sortable: false,
      },
    ];
  }

  ngOnInit(): void {
    this.serviceUrl = this.prospectsTrackingTableService.url;
    this.setFilterValue();
    this.onTextFilterChange();
  }

  setFilterValue() {
    this.queryParamMap = this._route.snapshot.queryParamMap;
    this.selectedStatus = this.queryParamMap.get('PaternityStatuses')?.split(',').map(Number);
    this.rvpSearch = this.queryParamMap.get('rvp') ? this.queryParamMap.get('rvp') : null;
    this.i18nFilter = {};
    if (this.selectedStatus?.length) this.i18nFilter.PaternityStatuses = this.selectedStatus;
    if (this.rvpSearch) this.i18nFilter.rvp = this.rvpSearch;
    this.filterValue = this.queryParamMap.get('filter');
    this.textFilterValue = this.queryParamMap.get('filter');
    this.filterUrlParam = {
      i18nFilter: this.i18nFilter,
      filter: this.filterValue,
    };
    super.filterEvent(this.filterUrlParam);
  }

  hideApplicant(): boolean {
    const appConfig = this.appConfigService.appConfig;
    const authorizedRoles = [
      appConfig.roles.valoSystem,
      appConfig.roles.valoAdminSuper,
      appConfig.roles.valoAdminSimple,
      appConfig.roles.valoRcvSuper,
      appConfig.roles.valoRcvSimple,
      appConfig.roles.developerHyper,
      appConfig.roles.developerSuper,
      appConfig.roles.developerSimple,
    ];
    return !this.userRoleService.isInRoles(authorizedRoles);
  }

  hideCompany(): boolean {
    const appConfig = this.appConfigService.appConfig;
    const authorizedRoles = [
      appConfig.roles.valoSystem,
      appConfig.roles.valoAdminSuper,
      appConfig.roles.valoAdminSimple,
      appConfig.roles.valoRcvSuper,
      appConfig.roles.valoRcvSimple,
    ];
    return !this.userRoleService.isInRoles(authorizedRoles);
  }

  hideEmail(): boolean {
    const appConfig = this.appConfigService.appConfig;
    const authorizedRoles = [
      appConfig.roles.valoSystem,
      appConfig.roles.valoAdminSuper,
      appConfig.roles.valoAdminSimple,
      appConfig.roles.valoRcvSuper,
      appConfig.roles.valoRcvSimple,
      appConfig.roles.contractorHyper,
      appConfig.roles.contractorSuper,
      appConfig.roles.contractorSimple,
    ];
    return !this.userRoleService.isInRoles(authorizedRoles);
  }

  hidePhone(): boolean {
    const appConfig = this.appConfigService.appConfig;
    const authorizedRoles = [
      appConfig.roles.valoSystem,
      appConfig.roles.valoAdminSuper,
      appConfig.roles.valoAdminSimple,
      appConfig.roles.valoRcvSuper,
      appConfig.roles.valoRcvSimple,
      appConfig.roles.contractorHyper,
      appConfig.roles.contractorSuper,
      appConfig.roles.contractorSimple,
    ];
    return !this.userRoleService.isInRoles(authorizedRoles);
  }

  hideRPV(): boolean {
    const appConfig = this.appConfigService.appConfig;
    const authorizedRoles = [
      appConfig.roles.valoSystem,
      appConfig.roles.valoAdminSuper,
      appConfig.roles.valoAdminSimple,
      appConfig.roles.valoRcvSuper,
      appConfig.roles.valoRcvSimple,
    ];
    this.displayRPV = this.userRoleService.isInRoles(authorizedRoles);
    return !this.displayRPV;
  }

  hideAction(): boolean {
    const appConfig = this.appConfigService.appConfig;
    const authorizedRoles = [
      appConfig.roles.valoSystem,
      appConfig.roles.valoAdminSuper,
      appConfig.roles.valoAdminSimple,
      appConfig.roles.valoRcvSuper,
      appConfig.roles.valoRcvSimple,
      appConfig.roles.developerHyper,
      appConfig.roles.developerSuper,
      appConfig.roles.developerSimple,
    ];
    return !this.userRoleService.isInRoles(authorizedRoles);
  }

  getActionItems(prospect: any): any[] {
    const actionList = [];
    if (prospect?.actions?.canAcceptPaternity) {
      actionList.push({
        label: 'Txt_Tooltip_Accept',
        action: () => {
          this.acceptProspect(prospect);
        },
      });
    }
    if (prospect?.actions?.canRefusePaternity) {
      actionList.push({
        label: 'Txt_Tooltip_Refuse',
        action: () => {
          this.refuseProspect(prospect);
        },
      });
    }
    if (prospect?.actions?.canCheckPaternity) {
      actionList.push({
        label: 'Txt_Tooltip_Check',
        action: () => {
          this.checkProspect(prospect);
        },
      });
    }
    if (prospect?.actions?.canShowMotive) {
      actionList.push({
        label: 'Txt_Tooltip_Motive',
        action: () => {
          this.showMotive(prospect);
        },
      });
    }

    return actionList;
  }

  /**
   * Accept a prospect.
   * @param {DeclaredProspectResponse} prospect
   */
  public acceptProspect(prospect: DeclaredProspectResponse): void {
    const data: MatDialogConfig<QuestionDialogData> = {
      data: {
        message: '',
        title: this.i18nService._('Txt_Popin_Accept_Paternity'),
        buttonConfirm: this.i18nService._('Txt_Button_Continue'),
        buttonCancel: this.i18nService._('Txt_Button_Cancel'),
      },
    };

    const dialogRef = this.dialog.open(QuestionDialogComponent, data);

    dialogRef.afterClosed().subscribe(({ answer }) => {
      if (answer) {
        this.prospectService.acceptPaternity(prospect.id).subscribe((updatedPaternity) => {
          if (updatedPaternity) {
            this.snackbarService.infoI18n('Txt_Accept_Paternity_Success');
            this.gridTrackingTable.updateSingleDataRow(prospect.id);
          }
        });
      }
    });
  }

  /**
   * Refuse a prospect.
   * @param {DeclaredProspectResponse} prospect
   */
  public refuseProspect(prospect: DeclaredProspectResponse): void {
    const data: MatDialogConfig<QuestionDialogData> = {
      data: {
        message: '',
        title: this.i18nService._('Txt_Popin_Reject_Paternity'),
        buttonConfirm: this.i18nService._('Txt_Button_Confirm'),
        buttonCancel: this.i18nService._('Txt_Button_Cancel'),
      },
    };

    const dialogRef = this.dialog.open(QuestionDialogComponent, data);

    dialogRef.afterClosed().subscribe(({ answer }) => {
      if (answer) {
        this.prospectService.rejectPaternity(prospect.id).subscribe((rejectedPaternity) => {
          if (rejectedPaternity) {
            this.snackbarService.infoI18n('Txt_Reject_Paternity_Success');
            this.gridTrackingTable.updateSingleDataRow(prospect.id);
          }
        });
      }
    });
  }

  /**
   * Check a prospect.
   * @param {DeclaredProspectResponse} prospect
   */
  public checkProspect(prospect: DeclaredProspectResponse): void {
    const checkDialogRef = this.dialog.open(PaternityVerificationDialogComponent);

    checkDialogRef.afterClosed().subscribe((values) => {
      if (values) {
        this.prospectService.checkPaternity(prospect.id, values).subscribe(({ message }) => {
          this.snackbarService.info(message);
          this.gridTrackingTable.updateSingleDataRow(prospect.id);
        });
      }
    });
  }

  /**
   * Display prospect ko motive dialog.
   * @param {DeclaredProspectResponse} prospect
   */
  public showMotive(prospect: DeclaredProspectResponse): void {
    const message =
      prospect.paternityDateEnd === null
        ? ''
        : `<strong>Echéance paternité Valorissimo :</strong>
                    ${this.returnDate(prospect.paternityDateEnd, BasicFormatsService.dateHourFormat)}
                    </p><p>`;
    const dialogData: MatDialogConfig<QuestionDialogData> = {
      data: {
        message: `${message} ${prospect.koMotive}`,
        title: this.i18nService._('Txt_Popin_Motive_Title'),
        buttonConfirmHidden: true,
        buttonCancel: this.i18nService._('Txt_Button_Quit'),
      },
    };

    this.dialog.open(QuestionDialogComponent, dialogData);
  }

  private returnDate(paternityDateEnd: any, dateFormat: string) {
    return this.advancedTrackingTablePipe.transform(paternityDateEnd, {
      key: 'paternityDateEnd',
      type: AdvancedTrackingTableColumnType.Date,
      parameters: { dateFormat },
    });
  }

  onTextFilterChange() {
    this.textFilterSubject.pipe(debounceTime(this.textFilterDebounceTimer), distinctUntilChanged()).subscribe((value) => {
      this.filterUrlParam.filter = value;
      super.filterEvent(this.filterUrlParam);
      this.textFilterValue = value;
    });
  }

  onRvpFilterChange(rvpSearch: string) {
    if (!this.i18nFilter) {
      this.i18nFilter = {};
    }
    // Employing the spread operator to modify the memory reference since i18nFilter is utilized as an input within another component
    this.i18nFilter = { ...this.i18nFilter, rpv: rvpSearch };

    this.filterUrlParam.i18nFilter = this.i18nFilter;
    super.filterEvent(this.filterUrlParam);
  }

  onStatusListChange(statusList: any): void {
    if (!this.i18nFilter) {
      this.i18nFilter = {};
    }
    const PaternityStatuses = statusList?.value.length ? statusList.value : null;

    // Employing the spread operator to modify the memory reference since i18nFilter is utilized as an input within another component
    this.i18nFilter = { ...this.i18nFilter, PaternityStatuses };

    this.filterUrlParam.i18nFilter = this.i18nFilter;
    super.filterEvent(this.filterUrlParam);
  }
}
