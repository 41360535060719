import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { ProgramMandatStrategyTrackingTableItem } from '../models/ProgramMandatStrategyTrackingTableItem';

import { MandateStrategyService } from './../../strategies/services/mandate-strategy.service';
import { TableLoader } from '../../table/interfaces/DataLoader';

@Injectable({
  providedIn: 'root',
})
export class ProgramMandateStrategiesTrackingTableService implements TableLoader<ProgramMandatStrategyTrackingTableItem> {
  constructor(private readonly mandateStrategyService: MandateStrategyService) {}

  public async getProgramMandateStrategies(id: number): Promise<ProgramMandatStrategyTrackingTableItem[]> {
    const response = await lastValueFrom(this.mandateStrategyService.getMandateStrategiesRecap(id));
    return response;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getById(id: number): Promise<ProgramMandatStrategyTrackingTableItem> {
    return null;
  }
}
