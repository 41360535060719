/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';

import { AbstractSearchFilter } from '../abstract-search-filter';

import { ROOM_ITEMS } from '../../../../utils/models/app-constant';
import { I18nService } from '../../../../utils/services/i18n.service';
import { CheckboxPopulatedComponent } from '../../../../utils/components/checkbox-populated/checkbox-populated.component';

@Component({
  selector: 'app-search-rooms',
  templateUrl: './search-rooms.component.html',
  styleUrls: ['./search-rooms.component.scss'],
  standalone: true,
  imports: [CheckboxPopulatedComponent],
})
export class SearchRoomsComponent extends AbstractSearchFilter {
  /**
   * RG_95.3.1 : Room items
   */
  readonly roomItems = ROOM_ITEMS;

  constructor(public readonly i18nService: I18nService) {
    super();
  }

  setSelectedRooms($event: any) {
    this.search.nbRooms = $event;
    this._searchFormUtilsService.setSearchFilter({ where: this.search }, this.waitForSearch);
  }

  protected initData() {
    const map = this.search.nbRooms.map((value) => {
      return this.roomItems.find((roomItem) => roomItem.id === value);
    });
    this.filterChangeEvent.next(map.map((room) => room.labelBadge));
  }

  public getRoom() {
    return this.search ? this.search.nbRooms : [];
  }
}
