import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

import { SearchPageDisplayType, SearchProgramData } from '../model/search-program-data';

import { GoogleTagManagerService } from '../../utils/services/google-tag-manager.service';

@Injectable({
  providedIn: 'root',
})
export class SearchFormService {
  private readonly filterSearch = 'filterSearch';
  private readonly searchProgramData: BehaviorSubject<SearchProgramData>;
  private readonly searchByLot: BehaviorSubject<boolean>;
  private readonly searchByCarte: BehaviorSubject<boolean>;
  private readonly searchFilter: BehaviorSubject<SearchProgramData>;
  private readonly countFinancialSelected: BehaviorSubject<number>;
  private readonly countHousingSelected: BehaviorSubject<number>;

  constructor(private readonly _googleTagManagerService: GoogleTagManagerService) {
    let defaultFilter;
    if (JSON.parse(sessionStorage.getItem(this.filterSearch))) {
      defaultFilter = JSON.parse(sessionStorage.getItem(this.filterSearch));
    }
    this.searchProgramData = new BehaviorSubject<SearchProgramData>(undefined);
    this.searchByLot = new BehaviorSubject<boolean>(false);
    this.searchByCarte = new BehaviorSubject<boolean>(false);
    this.searchFilter = new BehaviorSubject<SearchProgramData>(defaultFilter);
    this.countFinancialSelected = new BehaviorSubject<number>(0);
    this.countHousingSelected = new BehaviorSubject<number>(0);
  }

  public getSearchProgramData(): BehaviorSubject<SearchProgramData> {
    return this.searchProgramData;
  }

  public setSearchProgramData(searchProgramData: SearchProgramData): void {
    this.searchProgramData.next(searchProgramData);
  }

  public getCountFinancialSelected(): BehaviorSubject<number> {
    return this.countFinancialSelected;
  }

  public setCountFinancialSelected(countFinancialSelected: number): void {
    this.countFinancialSelected.next(countFinancialSelected);
  }

  public getCountHousingSelected(): BehaviorSubject<number> {
    return this.countHousingSelected;
  }

  public setCountHousingSelected(countHousingSelected: number): void {
    this.countHousingSelected.next(countHousingSelected);
  }

  public getSearchFilter(): BehaviorSubject<SearchProgramData> {
    return this.searchFilter;
  }

  public setSearchFilter(filter): void {
    sessionStorage.setItem(this.filterSearch, JSON.stringify(filter));
    this.searchFilter.next(filter);
  }

  public getSearchByLot(): BehaviorSubject<boolean> {
    return this.searchByLot;
  }

  public setSearchByLot(isSearchByLot): void {
    this.searchByLot.next(isSearchByLot);
  }

  public getSearchByCarte(): BehaviorSubject<boolean> {
    return this.searchByCarte;
  }

  public setSearchByCarte(isSearchByCarte): void {
    this.searchByCarte.next(isSearchByCarte);
  }

  setSearchByType(type: SearchPageDisplayType) {
    if (type === SearchPageDisplayType.Carte || type === SearchPageDisplayType.Programme) {
      this.setSearchByCarte(true);
      this.setSearchByLot(false);
    } else {
      this.setSearchByLot(true);
    }
  }

  pushClickedCarteLotToDatalayer(type: SearchPageDisplayType) {
    const dataToPush = {
      event: '',
    };
    switch (type) {
      case SearchPageDisplayType.Carte: {
        dataToPush.event = 'mapView';
        break;
      }
      case SearchPageDisplayType.Lot: {
        dataToPush.event = 'lotView';
        break;
      }
      default: {
        break;
      }
    }

    this._googleTagManagerService.pushTag(dataToPush);
  }

  public setSavedSearchId(savedSearchId: number): void {
    sessionStorage.setItem('savedSearchId', JSON.stringify(savedSearchId));
  }

  public getSavedSearchId(): number {
    return JSON.parse(sessionStorage.getItem('savedSearchId'));
  }
}
