import { Component } from '@angular/core';
import { MatListModule } from '@angular/material/list';

import { I18nService } from '../../../utils/services/i18n.service';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';

@Component({
  selector: 'app-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.scss'],
  standalone: true,
  imports: [StickyHeaderFormComponent, MatListModule],
})
export class LegalNoticeComponent {
  constructor(public i18nService: I18nService) {}
}
