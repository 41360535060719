<ng-container [formGroup]="i18nFiltersForm">
  <mat-form-field class="fullW flex w-full my-0">
    <mat-label>{{ i18nService._(tableColumn['i18nTitle']) }}</mat-label>
    <mat-select data-cy="Statut" [formControlName]="tableColumn['table']" multiple>
      <mat-option *ngFor="let ref of refenceTableToFilter[tableColumn.table]" [value]="ref.id" [attr.data-cy]="ref.label">
        {{ ref.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
