import { LotHasAreas } from './LotHasAreas';
import { LotHasTaxations } from './LotHasTaxations';
import { LotTaxation } from './LotTaxation';
import { SecondaryLot } from './SecondaryLot';

export class LotToCreate {
  created?: Date;
  modified?: Date;
  lotRef?: string;
  lotNumber?: string;
  livingSpace: number;
  rooms: number;
  floor: number;
  deliveryDate?: Date;
  estmatedMonthlyRentingPrice?: number;
  estimatedProfitability?: number;
  // MALRAUX ou DEFICIT_FONCIER ou MONUMENTS_HISTORIQUES
  estimatedWorkPrice?: number;
  estimatedLandPrice?: number;

  // LMNP
  guaranteedMonthlyRentingPriceIT?: number;
  estatePriceET?: number;
  housingPriceET?: number;
  tangiblePriceET?: number;
  commercialLotSellingPriceIT?: number;

  // NUE PROPRIETE
  nueProprietePrice?: number;
  usufruitValue?: number;
  distributionKey?: string;
  nueProprieteDuration?: number;

  secondaryLotPriceIncluded?: boolean;
  isValorissimoExclusivity?: boolean;
  vatRate?: number;
  globalSellingPriceET?: number;
  sellingFees?: number;
  loanFees?: number;
  attorneyFees?: number;
  monthlyRentingPriceET?: number;
  profitabilityET?: number;
  isPublished?: boolean;
  reducedTotalSellingPriceIT?: number;
  programId: number;
  programTypeId: number;
  lotOrientationId: number;
  lotAreaId?: number;
  mandatStrategyId?: number;
  monthlyRentalLotPriceIT?: number;
  monthlyRentalSecondaryLotPriceIT?: number;
  document: {
    lotPlan: File;
  };
  documentsId?: [number];
  secondaryLots: Array<SecondaryLot>;
  lotHasAreas: Array<LotHasAreas>;
  lotHasTaxations: Array<LotHasTaxations | LotTaxation>;
  isForManualReservation?: boolean;
}
