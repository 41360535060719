export const prospect = {
  //Popin search prospect
  Prospect_Confirm_Search_Prospect: 'Rechercher un dossier prospect',
  Prospect_Add_Favorites_Prospect: 'Ajouter aux favoris',
  //Popin confirm create
  Prospect_Confirm_Create_Dialog_Title: 'Déclarer un Prospect ?',
  Prospect_Confirm_Create_Dialog_Yes: 'Déclarer un Prospect',
  Prospect_Confirm_Create_Dialog_Message:
    'Déclarer un prospect vous permet d’enregistrer ses informations, de réaliser une simulation de prêt…',
  //Create Form
  Prospect_Form_Company_Step_Title: 'Informations Entreprise',
  Prospect_Form_Company_Name: 'Nom de la société',
  Prospect_Form_Company_SIRET: 'Numéro SIRET',
  Prospect_Form_Personal_Step_Title: 'Informations Personnelles',
  Prospect_Form_Personal_Gender: 'Civilité',
  Prospect_Form_Personal_Lastname: 'Nom',
  Prospect_Form_Personal_Lastname_For_Company: 'Nom du représentant',
  Prospect_Form_Personal_Firstname: 'Prénom',
  Prospect_Form_Personal_Firstname_For_Company: 'Prénom du représentant',
  Prospect_Form_Personal_Email: 'Adresse mail',
  Prospect_Form_Personal_Email_For_Company: 'Adresse mail du représentant',
  Prospect_Form_Personal_Phone: 'Téléphone',
  Prospect_Form_Personal_Phone_For_Company: 'Téléphone du représentant',
  Prospect_Form_Personal_Country: 'Pays',
  Prospect_Form_Personal_Postal_Code: 'Code Postal',
  Prospect_Form_Personal_City: 'Ville',
  Prospect_Form_Civility_M: 'Monsieur',
  Prospect_Form_Civility_F: 'Madame',
  Prospect_Form_Type_Company: 'Une société',
  Prospect_Form_Type_Solo: 'Une personne',
  Prospect_Form_Type_Duo: 'Deux personnes',
  Prospect_Stepper_Prospect_Type: 'Type de dossier',
  Prospect_Create_Company_Step: 'Informations Société',
  Prospect_Create_Prospect_Personal: 'Informations Prospect N°',
  Prospect_Create_Submit: 'Valider et créer',
  Prospect_Modify_Submit: 'Enregistrer',

  //Dashboard dossier prospect
  Prospect_Dossier_Dashboard_Prefix: 'Dossier de :',
  Prospect_Dossier_Edit_Prefix: 'Modification du dossier de :',
  Prospect_Company_Prefix: 'Société',
  Prospect_And: 'et',
  Prospect_Represented_By: 'représentée par',

  //Duplicate popin
  Prospect_Create_Duplicate_Title: 'Doublon Détecté',
  Prospect_Create_Duplicate_Message: `L'email, le numéro de téléphone ou le SIRET est déjà utilisé sur un autre dossier. Veuillez modifier ces informations, ou poursuivez sur le dossier existant en cliquant sur le lien suivant: `,
  Prospect_Create_Duplicate_Link: 'Accéder au dossier',

  //Paternity popin
  Prospect_Paternity_Add_Popin_Title: 'Déclarer une paternité ?',
  Prospect_Paternity_Add_Popin_Content:
    'Pour déclarer une paternité, vous devez la rattacher à un dossier prospect.</br>Si votre dossier existe déjà, cliquez sur “Déclarer pour un dossier existant”, sinon cliquez sur “Déclarer pour un nouveau dossier”',
  Prospect_Paternity_Add_Popin_Existing: 'Déclarer pour un dossier existant',
  Prospect_Paternity_Add_Popin_New: 'Déclarer pour un nouveau dossier',

  //Paternity Form
  Paternity_Stepper_Section: 'Paternité(s)',
  Prospect_Paternity_Form_Title: 'Déclaration de Paternité',
  Prospect_Paternity_Form_Choose_Prospect: 'Nom du prospect',
  Prospect_Paternity_Form_Add_Company: 'Nom de la société promotrice',
  Prospect_Paternity_Form_Add_Company_Help: 'Sélectionner une société promotrice',
  Prospect_Paternity_Form_Add_Program: 'Programme',
  Prospect_Paternity_Form_Add_Program_Help: 'Sélectionner un programme',
  Prospect_Paternity_Add_Side: 'Ajouter cette paternité',
  Prospect_Paternity_Form_Duplicate_Title: 'Erreur de création',
  Prospect_Paternity_Form_Duplicate_Text: 'Cette paternité existe déjà pour ce dossier',

  //Paternity with Existing Prospect
  Paternity_Create_Existing_Prospect_Step: 'Sélection du dossier',
  Paternity_Add_Company_And_Program_Step: 'Sélection du(es) programme(s) ',

  // Paternity status
  Prospect_Paternity_Status_PENDING: 'En attente',
  Prospect_Paternity_Status_ACCEPTED: 'Acceptée',
  Prospect_Paternity_Status_DENIED: 'Refusée',

  //Paternity Card
  Prospect_Paternity_Creation_Date: 'Date de déclaration : ',
  Prospect_Paternity_End_Date: 'Date de fin : ',

  Prospect_Stepper_Context_Title: 'Prospect(s)',
  Prospect_Stepper_Form_Title: "Création d'un dossier prospect",
  // Paternity request state
  Prospect_Paternity_Request_Success: '1 déclaration de paternité a bien été transmise.',
  Prospect_Paternities_Request_Success: '{0} déclarations de paternité ont bien été transmises.',
  Prospect_Paternity_Request_Detail: 'Pour plus de détail, vous pouvez consulter le statut de la demande dans la liste ci-dessous.',
  Prospect_Paternities_Request_Detail: 'Pour plus de détail, vous pouvez consulter le statut de chaque demande dans la liste ci-dessous.',
  Prospect_Paternity_Request_Detail_Outside_Paternity_Tab:
    "Pour plus de détail, vous pouvez consulter le statut de la demande dans l'onglet Paternités.",
  Prospect_Paternities_Request_Detail_Outside_Paternity_Tab:
    "Pour plus de détail, vous pouvez consulter le statut de chaque demande dans l'onglet Paternités.",
  Prospect_Paternity_Request_Error:
    '1 déclaration de paternité n’a pas pu être transmise au promoteur. Veuillez réessayer. Si le problème persiste, veuillez contacter votre interlocuteur Valorissimo.',
  Prospect_Paternities_Request_Error:
    '{0} déclarations de paternité n’ont pas pu être transmises au promoteur. Veuillez réessayer. Si le problème persiste, veuillez contacter votre interlocuteur Valorissimo.',

  Prospect_Return_Prospect_List: 'Retour à la liste des dossiers',
  Prospect_Return_Prospect_Dashboard: 'Retour au dossier prospect',
  Prospect_Modify_Prospect_Button: 'Modifier le dossier',
  Prospect_Dashboard_Tab: 'Dashboard',
  Prospect_Paternities_Tab: 'Paternités ({0})',
  Prospect_Transactions_Tab: 'Transactions',
  Prospect_Criteres_recherche_Tab: 'Critères de recherche',
  Prospect_Lots_Tab: 'Lots',

  Prospect_Paternity_All_Programs: 'Tous les programmes',
  Prospect_Dossier_Status_DISCOVER: 'Découverte',
  Prospect_Dossier_Status_IDENTIFICATION: 'Identification',
  Prospect_Dossier_Status_CLOSING: 'Closing',

  Prospect_Edit_Changes_Detected_Title: 'Souhaitez vous enregistrer vos modifications ?',
  Prospect_Edit_Changes_Detected_Txt:
    'Vous avez modifié des informations de votre dossier prospect sans les avoir sauvegardées. Cliquez sur “Enregistrer et quitter” pour sauvegarder vos changements, ou “Quitter sans sauvegarder”. Sinon refermez la fenêtre pour continuer vos changements.',
  Prospect_Edit_Changes_Detected_No_Save: 'Quitter sans sauvegarder',
  Prospect_Edit_Changes_Detected_Save: 'Sauvegarder et quitter',
  Prospect_Edit_Save_Errors_Title: "Impossible d'enregistrer",
  Prospect_Edit_Save_Errors_Txt: 'Le formulaire contient des erreurs, merci de les corriger avant de réessayer',
  Prospect_Edit_Critical_Change_Cancel: 'Annuler',
  Prospect_Edit_Critical_Change_Confirm: 'Valider les modifications',
  Prospect_Edit_Critical_Change_Txt:
    'Vous avez modifié des informations clés (type de dossier et/ou email et/ou téléphone et/ou numéro de SIRET) de votre dossier prospect.</br><b>En confirmant, vos demandes de paternité en cours et acceptées sur le(s) prospect(s) modifié(s) seront toutes annulées</b> et vous devrez les soumettre à nouveau. <b>Si vous avez réalisé une simulation sur le dossier, elle sera également réinitialisée.</br></b>Souhaitez vous continuer ?',
  Prospect_Edit_Critical_Change_Title: 'Souhaitez vous enregistrer vos modifications ?',

  //Prospect Card
  Prospect_card_email_label: 'Email',
  Prospect_card_email_city: 'Ville (CP)',

  // Dossier Prospect Tracking Table
  Title_DossierProspectTrackingTable: 'Dossiers Prospect',
  Txt_DossierProspectTrackingTable_Column_Prospect: 'Prospect',
  Txt_DossierProspectTrackingTable_Column_CreatedAt: 'Créé le',
  Txt_DossierProspectTrackingTable_Column_Email: 'Email',
  Txt_DossierProspectTrackingTable_Column_Phone: 'Téléphone',
  Txt_DossierProspectTrackingTable_Column_City: 'Ville (CP)',
  Txt_DossierProspectTrackingTable_Column_Partner: 'Partenaire',
  Txt_DossierProspectTrackingTable_Column_Status: 'Statut',
  Txt_DossierProspectTrackingTable_Action_View_Dossier: 'Accéder au dossier',

  // Dossier Prospect Tracking Table status
  Txt_DossierProspectTrackingTable_Status_All: 'Tous',
  Txt_DossierProspectTrackingTable_Status_Prospect_Discover: 'Découverte',
  Txt_DossierProspectTrackingTable_Status_Prospect_Identification: 'Identification',
  Txt_DossierProspectTrackingTable_Status_Prospect_Closing: 'Closing',

  // Simulation
  //Popin simulation confirm
  Prospect_Simulation_Confirm_Create_Dialog_Title: 'Faire une simulation',
  Prospect_Simulation_Confirm_Create_Dialog_Yes: 'Faire une simulation',
  Prospect_Simulation_New_Or_Existant_Folder:
    'Vous pouvez maintenant simuler la capacité d’achat de vos prospects pour trouver plus rapidement les lots qui correspondent à leur profil',
  Txt_Prospect_SimulationPopin_ExistingFolder: ' Simuler pour un dossier existant',
  Txt_Prospect_SimulationPopin_NewFolder: 'Simuler pour un nouveau dossier',
  Txt_Prospect_SimulationPopin_FindYourProspect: 'Trouver votre prospect',
  Txt_Prospect_SimulationPopin_Selection:
    'Sélectionnez un dossier parmi la liste de vos prospects. Notez que la simulation est actuellement possible uniquement pour les dossiers prospects personnes physiques (1 ou 2 personnes)',
  Txt_Prospect_Simulation_Button: 'Simuler',
  Prospect_Simulation_Existing_Folder: 'Sélectionnez le dossier auquel vous voulez ajouter au favoris ce lot',
  Prospect_Simulation_Tab: 'Simulation',
  Prospect_Simulation_Tab_toolTip_disabled: `La simulation n'est pas disponible pour les sociétés`,
  Prospect_Revenues_Simulation_Form_Title: 'Revenus',
  Prospect_Revenues_Simulation_Form_Subtitle: '{0} mensuels',
  Prospect_Simulation_Revenues_RENT: 'Revenus locatifs mensuels',
  Prospect_Simulation_Revenues_SALARY: 'Salaire mensuel net',
  Prospect_Simulation_Revenues_PRIME: 'Primes annuelles nettes',
  Prospect_Simulation_Revenues_SALARY_ACQ2: 'Salaire mensuel 2 net',
  Prospect_Simulation_Revenues_PRIME_ACQ2: 'Primes annuelles 2 nettes',
  Prospect_Simulation_Revenues_OTHER_MONTHLY: 'Autres revenus mensuels',
  Prospect_Simulation_Revenues_OTHER_ANNUALY: 'Autres revenus annuels',
  Prospect_Charges_Simulation_Form_Title: 'Charges',
  Prospect_Charges_Simulation_Form_Subtitle: '{0} mensuels',
  Prospect_Charges_Simulation_Is_Owner: 'Le prospect est-il propriétaire ou locataire de sa RP ?',
  Prospect_Charges_Simulation_Owner: 'Propriétaire',
  Prospect_Charges_Simulation_Renter: 'Locataire',
  Prospect_Charges_Simulation_Credit_On_Owner: 'Le prospect rembourse-t-il un crédit pour sa RP ?',
  Prospect_Charges_Simulation_Credit_On_Owner_True: 'Oui',
  Prospect_Charges_Simulation_Credit_On_Owner_False: 'Non',
  Prospect_Simulation_Charges_RENT: 'Loyer mensuel RP',
  Prospect_Simulation_Charges_ESTATE_LOAN: 'Prêt immobilier RP (remboursements mensuels)',
  Prospect_Simulation_Charges_CONSUMER_LOAN: 'Consommation',
  Prospect_Simulation_Charges_REVOLVING_LOAN: 'Revolving',
  Prospect_Simulation_Charges_STUDENT_LOAN: 'Etudiant',
  Prospect_Simulation_Charges_VEHICLE_LOAN: 'Crédit auto',
  Prospect_Simulation_Charges_LOA_LOAN: 'LOA',
  Prospect_Simulation_Charges_LDD_LOAN: 'LDD',
  Prospect_Simulation_Charges_OTHER_ESTATE_LOAN: 'Immobilier',
  Prospect_Simulation_Charges_OTHER_LOAN: 'Autre',
  Prospect_Simulation_Charges_Add: 'Ajouter un autre prêt',
  Prospect_Simulation_Charges_Remove: 'Retirer ce prêt',
  Prospect_Simulation_Charges_Loan_Type: 'Type de prêt',
  Prospect_Simulation_Charges_Monthly_Cost: 'Remboursement mensuel',
  Prospect_Simulation_Charges_OTHER_MONTHLY: 'Autres charges mensuelles',
  Prospect_Simulation_Charges_OTHER_ANNUALY: 'Autres charges annuelles',
  Prospect_Simulation_Tooltip_Other_Monthly_Charges: 'pension alimentaire, etc.',
  Prospect_Simulation_Tooltip_Before_Taxes: 'avant impôts',
  Prospect_Simulation_Tooltip_Other_Monthly: 'pension compensatoire, etc.',
  Prospect_Simulation_Loan_Properties: 'Caractéristiques',
  Prospect_Simulation_Loan_Properties_Subtitles: "{0} d'apport",
  Prospect_Simulation_Loan_Loan_Rate: "Taux d'emprunt",
  Prospect_Simulation_Loan_Insurance_Rate: "Taux d'assurance",
  Prospect_Simulation_Loan_Loan_Duration: 'Durée du prêt',
  Prospect_Simulation_Loan_Funds: 'Apport personnel',
  Prospect_Simulation_Debt_Ratio: "Taux d'endettement",
  Prospect_Simulation_Debt_Ratio_Helper: "Taux d'endettement d'après les données fournies, sans tenir compte de l'achat envisagé.",
  Prospect_Simulation_Remaining_Funds: 'Reste à vivre',
  Prospect_Simulation_Remaining_Funds_Helper:
    'Somme mensuelle restante une fois les charges et la mensualité-ci-dessous versée. Le reste à vivre minimum doit être de 800€ pour une personne seule, 1300€ pour un couple et de 400€ par enfant pour obtenir un prêt.',
  Prospect_Simulation_Payment_Capacity: 'Mensualité max',
  Prospect_Simulation_Loan_Capacity: 'Emprunt max',
  Prospect_Simulation_Loan_Capacity_Helper:
    "Emprunt maximum possible en respectant un taux d'endettement de 30%, sans tenir compte du reste à vivre",
  Prospect_Simulation_Purchasing_Capacity: "Capacité d'achat",
  Prospect_Simulation_Search: 'Rechercher',
  Prospect_Simulation_Save_To_SearchPreferences: 'Enregistrer pour la recherche',
  Prospect_Simulation_Save_Confirmation_To_SearchPreferences: `La capacité d'achat calculée a bien été ajoutée aux critères de recherche du dossier`,

  DOSSIER_PROSPECT_DISCOVER: 'Découverte',
  DOSSIER_PROSPECT_IDENTIFICATION: 'Identification',
  DOSSIER_PROSPECT_CLOSING: 'Closing',

  // snackbar
  Error_SnackBar_TakeOption: `Une erreur est survenue lors de La pose d'option`,
  Success_SnackBar_TakeOption: `La pose d'option a bien été enregistrée`,
  Error_SnackBar_Update_Comment: `Une erreur est survenue lors de la mise à jour du commentaire`,

  // Transaction
  transaction_status: 'Statut',
};
