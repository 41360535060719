<div class="flex flex-col w-full">
  <!-- Sticky subHeader form -->
  <app-sticky-header-form [titleText]="i18nService._('Title_Activities_And_Notifications')"></app-sticky-header-form>

  <mat-tab-group
    (selectedIndexChange)="changeTab($event)"
    [selectedIndex]="selectedTabIndex"
    class="pt-16"
    dynamicHeight
    mat-align-tabs="center"
    mat-stretch-tabs
  >
    <mat-tab *ngFor="let tab of tabs" [label]="i18nService._(tab.title)">
      <div class="mx-3 mb-4 lg:mx-5 md:mx-5 sm:mx-4 pt-4 px-2 lg:px-6 md:px-5 sm:px-3">
        <app-advanced-tracking-table-no-back-page
          [noResultMessageI18nToken]="tab.noResult"
          [columns]="basicColumns"
          [serviceParams]="tab.serviceParams"
          [items]="undefined"
          [service]="service"
        >
        </app-advanced-tracking-table-no-back-page>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
