import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';

import { DataLayerApiService } from './data-layer-api.service';
import { TaxZoneResponse } from '../utils/models/TaxZoneResponse';

@Injectable({ providedIn: 'root' })
export class TaxZoneApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Get tax zones
   *
   * @returns {TaxZoneResponse[]}
   * @memberof DataLayerApiService
   */
  async getTaxZones(): Promise<TaxZoneResponse[]> {
    const path = this.BASE_URL + 'TaxZones';
    const res: Observable<TaxZoneResponse[]> = await this.get(path);
    return await lastValueFrom(res);
  }
}
