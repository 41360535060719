import { AbstractControl, ValidatorFn } from '@angular/forms';

export const isURLValidator = (): ValidatorFn => {
  return (control: AbstractControl): { invalidUrl } | undefined => {
    // If no value, no error
    if (!control.value) {
      return undefined;
    }

    try {
      const urlObj = new URL(control.value);
      if (urlObj && /^http(s)?:\/\//.test(control.value)) {
        return undefined;
      }

      return { invalidUrl: true };
    } catch (error) {
      return { invalidUrl: error.message };
    }
  };
};
