import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../security/authguard';
import { Sitemap } from '../utils/models/Sitemap';
import { SearchPageComponent } from './components/search-page/search-page.component';

const routes: Routes = [
  {
    path: Sitemap.utils.search.path,
    component: SearchPageComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: `${Sitemap.utils.search.path}/:searchId`,
    component: SearchPageComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
  declarations: [],
})
export class SearchRoutingModule {}
