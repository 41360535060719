<ng-container [formGroup]="i18nFiltersForm">
  <mat-form-field class="fullW flex w-full my-0">
    <mat-label>{{ i18nService._(tableColumn['i18nTitle']) }}</mat-label>
    <mat-select [formControlName]="tableColumn['key']" multiple>
      <mat-option *ngFor="let ref of refenceTableToFilter[tableColumn['key']]" [value]="ref.value">
        {{ ref.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
