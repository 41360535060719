<div class="flex flex-col w-full bg-white shadow-md py-3 px-4">
  <div *ngIf="title" class="mb-3">
    <span class="icon-document text-3xl relative t-1 l-n2"></span>
    <span class="text-base font-bold">
      {{ title }}
    </span>
  </div>

  <div class="grid grid-cols-2 gap-4">
    <!-- Presentation documents -->
    <div class="col-span-2 md:col-span-1">
      <div class="text-xs font-bold mb-3">
        {{ i18nService._('Title_PresentationDocumentation') }}
      </div>
      <ng-container *ngFor="let presentationDoc of presentationsDocs">
        <div *ngIf="presentationDoc.actions.display" class="mb-2 mr-1">
          <mat-checkbox
            (change)="isAtLeastOneChecked()"
            [(ngModel)]="presentationDoc.actions.checked"
            [disabled]="presentationDoc.actions.disabled"
            [ngClass]="{ 'no-drop': presentationDoc.actions.disabled }"
            [value]="presentationDoc.label"
            color="primary"
          >
            <a
              (click)="downloadDocument(presentationDoc)"
              class="text-xs hidden"
              [ngClass]="{ 'no-drop': presentationDoc.actions.disabled }"
              download
              target="_blank"
            >
              {{ getFileName(presentationDoc) }}
            </a>
          </mat-checkbox>
          <span>
            <a
              (click)="downloadDocument(presentationDoc)"
              class="text-xs"
              [ngClass]="{ 'no-drop': presentationDoc.actions.disabled }"
              download
              target="_blank"
            >
              {{ getFileName(presentationDoc) }}
            </a>
          </span>
        </div>
      </ng-container>
    </div>

    <!-- Contracts documents -->
    <div class="col-span-2 md:col-span-1">
      <div class="text-xs font-bold mb-3">
        {{ i18nService._('Title_BookingAndGuaranteesBook') }}
      </div>
      <ng-container *ngFor="let contratDoc of contratsDocs">
        <div *ngIf="contratDoc.actions.display" class="mb-2 mr-1">
          <mat-checkbox
            (change)="isAtLeastOneChecked()"
            [(ngModel)]="contratDoc.actions.checked"
            [disabled]="contratDoc.actions.disabled"
            [value]="contratDoc.label"
            color="primary"
          >
            <a
              (click)="downloadDocument(contratDoc)"
              [class.disabled]="contratDoc.actions.disabled"
              class="text-xs hidden"
              download
              target="_blank"
            >
              {{ getFileName(contratDoc) }}
            </a>
          </mat-checkbox>
          <span>
            <a
              (click)="downloadDocument(contratDoc)"
              [class.disabled]="contratDoc.actions.disabled"
              class="text-xs"
              download
              target="_blank"
            >
              {{ getFileName(contratDoc) }}
            </a>
          </span>
        </div>
      </ng-container>
      <!-- Documents utils -->
      <div *ngIf="externalDocumentsLink">
        <a class="txt-orange no-underline text-xs" target="_blank" rel="noopener noreferrer" href="{{ externalDocumentsLink }}">
          {{ i18nService._('Txt_Link_External_Documents_Utils') }}
        </a>
      </div>
      <!-- Documents utils -->
    </div>
  </div>

  <div class="w-full text-right">
    <!-- download as zip -->
    <button
      (click)="downloadAndZipSelectedDocs()"
      [disabled]="downloadButtonDisabled || downloading"
      class="btn-round inline-block shadow-none"
      color="primary"
      mat-mini-fab
    >
      <div *ngIf="downloading" class="spinner-container">
        <mat-progress-spinner color="primary" diameter="30" mode="indeterminate" value="50"></mat-progress-spinner>
      </div>
      <span *ngIf="!downloading" class="icon-download"></span>
    </button>

    <!-- Send as email -->
    <button
      (click)="mailSelectedDocs()"
      [disabled]="downloadButtonDisabled || isDownloadingByEmail"
      class="btn-round inline-block shadow-none"
      color="primary"
      mat-mini-fab
    >
      <div *ngIf="isDownloadingByEmail" class="spinner-container">
        <mat-progress-spinner color="primary" diameter="30" mode="indeterminate" value="50"></mat-progress-spinner>
      </div>
      <span *ngIf="!isDownloadingByEmail" class="icon-mail"></span>
    </button>
  </div>
</div>
