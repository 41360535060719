<div class="page">
  <section class="section-header pt-20 px-20 bg-white">
    <h1 class="text-4xl font-bold mb-4">{{ i18nService._('Title_recommandation') }}</h1>
    <p style="font-weight: normal; color: #555; font-size: 18px; line-height: 28px">{{ i18nService._('SubTitle_recommandation') }}</p>
  </section>

  <section class="py-20 bg-white">
    <app-target2sell-reco [target2SellRecoId]="target2SellRecoIdSection1"></app-target2sell-reco>
  </section>

  <section class="py-20 bg-gray-50">
    <app-target2sell-reco [target2SellRecoId]="target2SellRecoIdSection2"></app-target2sell-reco>
  </section>

  <section class="py-20 bg-white">
    <app-target2sell-reco [target2SellRecoId]="target2SellRecoIdSection3"></app-target2sell-reco>
  </section>
</div>
