import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, pluck, Subscription } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';

import { ProgramPagePromoterInfosComponent } from './program-page-promoter-infos/program-page-promoter-infos.component';
import { ProgramPageDescriptionComponent } from './program-page-description/program-page-description.component';
import { ProgramPageNeighborhoodMapComponent } from './program-page-neighborhood-map/program-page-neighborhood-map.component';
import { ProgramPageDocumentsListComponent } from './program-page-documents-list/program-page-documents-list.component';
import { ProgramPageLotsListComponent } from './program-page-lot/program-page-lots-list/program-page-lots-list.component';
import { ProgramPageHighlightsComponent } from './program-page-highlights/program-page-highlights.component';
import { ProgramPageRpvCardComponent } from './program-page-rpv-card/program-page-rpv-card.component';
import { ProgramPageMainInfosComponent } from './program-page-main-infos/program-page-main-infos.component';
import { ProgramPageProspectiveComponent } from './program-page-prospective/program-page-prospective.component';
import { ProgramPageHeaderComponent } from './program-page-header/program-page-header.component';

import { AnchorDirective } from '../../directive/anchor.directive';
import { ProgramService } from '../../services/program.service';
import { ProgramDetail } from '../../../utils/models/ProgramDetail';
import { Target2SellEnum } from '../../../utils/models/enums/target-2-sell.enum';
import { UserRoleService } from '../../../utils/services/user-role.service';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { Target2SellRecoComponent } from '../../../utils/components/target2sell-reco/target2sell-reco.component';
import { AnchorEnum } from '../../models/enum/anchor.enum';

@Component({
  selector: 'app-program-page-v2',
  templateUrl: './program-page-v2.component.html',
  styleUrls: ['./program-page-v2.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ProgramPageHeaderComponent,
    ProgramPageProspectiveComponent,
    ProgramPageMainInfosComponent,
    ProgramPageRpvCardComponent,
    AnchorDirective,
    ProgramPageHighlightsComponent,
    ProgramPageLotsListComponent,
    ProgramPageDocumentsListComponent,
    ProgramPageNeighborhoodMapComponent,
    ProgramPageDescriptionComponent,
    ProgramPagePromoterInfosComponent,
    Target2SellRecoComponent,
    AsyncPipe,
  ],
})
export class ProgramPageV2Component implements OnInit, OnDestroy {
  @ViewChildren(AnchorDirective) anchorList: QueryList<AnchorDirective>;
  public anchorEnum = AnchorEnum;
  public programId: number;
  public lotId: number;
  public target2SellRecoId: string;
  public isRpvOrAdmin: boolean;
  public programData$: Observable<ProgramDetail>;

  private readonly lotSelectedSubscription: Subscription;

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _userRoleService: UserRoleService,
    private readonly _appConfigService: AppConfigService,
    programService: ProgramService,
  ) {
    this.lotSelectedSubscription = programService.selectedLotDetail.subscribe((lotDetailResponse) => {
      this.target2SellRecoId = lotDetailResponse ? Target2SellEnum.TARGET_2_SELL_LOT : Target2SellEnum.TARGET_2_SELL_PROGRAM;
    });
  }

  ngOnInit() {
    this.programData$ = this._route.data.pipe(pluck('programData'));
    this.isRpvOrAdmin = false;
    if (this._userRoleService.isValo) {
      const roles = this._appConfigService.appConfig.roles;
      const rpvRoles = [roles.valoRcvSuper, roles.valoRcvSimple, roles.valoAdminSuper, roles.valoAdminSimple, roles.valoSystem];
      this.isRpvOrAdmin = this._userRoleService.isInRoles(rpvRoles);
    }
  }

  ngOnDestroy(): void {
    this.lotSelectedSubscription.unsubscribe();
  }

  scrollTo(anchor: AnchorEnum) {
    const anchorDirective = this.anchorList.find((item) => item.anchorName === anchor);
    anchorDirective.scrollToElement();
  }
}
