/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';

import { LOCALISATION_LABELS } from '../../../../../../utils/models/app-constant';

@Component({
  selector: 'app-search-location-label',
  templateUrl: './search-location-label.component.html',
  styleUrls: ['./search-location-label.component.scss'],
  standalone: true,
})
export class SearchLocationLabelComponent implements OnInit {
  @Input() localisation: any;
  public label: string;

  ngOnInit() {
    this._processLocalisation(this.localisation);
  }

  private _processLocalisation(localisation: any) {
    switch (localisation.typeLocation) {
      case LOCALISATION_LABELS.agglomeration:
      case LOCALISATION_LABELS.cities: {
        this.label = `${localisation.label} (${localisation.zipcode})`;
        break;
      }
      case LOCALISATION_LABELS.departments: {
        this.label = `${localisation.label} (${localisation.code})`;
        break;
      }
      case LOCALISATION_LABELS.regions: {
        this.label = `${localisation.label}`;
        break;
      }
      default:
        break;
    }
  }
}
