import { DossierResponse } from './dossier.response.dto';
import { SummaryPaternityResponse } from '../paternity';
import { SummarySimulationResponse } from '../simulation/summary-simulation.response.dto';
import { SummarySearchPreferenceResponse } from './summary-search-preference.response.dto';

export class CompleteDossierResponse extends DossierResponse {
  summaryPaternities: SummaryPaternityResponse[] = [];
  summarySimulations: SummarySimulationResponse[] = [];
  summarySearchPreferences: SummarySearchPreferenceResponse[] = [];
  nbOptions?: number;
}
