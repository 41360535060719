import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AdvancedTrackingTableComponent } from '../advanced-tracking-table/advanced-tracking-table.component';

import { BasicFormatsService } from '../../../utils/services/basic-formats.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { AdvancedTrackingTableColumn } from '../../models/AdvancedTrackingTableColumn';
import { AdvancedTrackingTableColumnType } from '../../models/advanced-tracking-table-column-type.enum';
import { MandateStrategiesTrackingTableService } from '../../services/mandate-strategies-tracking-table.service';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';

@Component({
  selector: 'app-mandate-strategies-tracking-table',
  templateUrl: './mandate-strategies-tracking-table.component.html',
  styleUrls: ['./mandate-strategies-tracking-table.component.scss'],
  standalone: true,
  imports: [StickyHeaderFormComponent, AdvancedTrackingTableComponent],
})
export class MandateStrategiesTrackingTableComponent implements OnInit {
  /**
   * Columns of the array.
   *
   * @type {Array<AdvancedTrackingTableColumn>}
   * @memberof MandateStrategiesTrackingTableComponent
   */
  public columns: Array<AdvancedTrackingTableColumn>;

  /**
   * companyId attribute
   *
   * @type {number}
   * @memberof MandateStrategiesTrackingTableComponent
   */
  public companyId: number;

  @ViewChild(AdvancedTrackingTableComponent)
  advancedTableComponent: AdvancedTrackingTableComponent;

  constructor(
    public readonly i18nService: I18nService,
    private readonly route: ActivatedRoute,
    public readonly service: MandateStrategiesTrackingTableService,
  ) {}

  ngOnInit(): void {
    // get the companyId in url
    this.companyId = parseInt(this.route.snapshot.paramMap.get('companyId'), 10);
    this.service.setCompanyId(this.companyId);

    const dateFormat = BasicFormatsService.defaultDateFormat;

    this.columns = [
      {
        key: 'isGlobal',
        sortable: true,
        i18nTitle: 'Title_MandateStrategiesTable_Column_IsGlobal',
      },
      {
        key: 'mandatStrategyTitle',
        sortable: true,
        i18nTitle: 'Title_MandateStrategiesTable_Column_Name',
      },
      {
        key: 'companyName',
        sortable: true,
        i18nTitle: 'Title_MandateStrategiesTable_Column_Developer',
      },
      {
        key: 'programName',
        sortable: true,
        i18nTitle: 'Title_MandateStrategiesTable_Column_Program',
      },
      {
        key: 'rate',
        sortable: true,
        i18nTitle: 'Title_MandateStrategiesTable_Column_Rate',
        type: AdvancedTrackingTableColumnType.Percentage,
        replaceFalsyWithDash: true,
      },
      {
        key: 'startDate',
        sortable: true,
        i18nTitle: 'Title_MandateStrategiesTable_Column_StartDate',
        type: AdvancedTrackingTableColumnType.Date,
        parameters: { dateFormat },
      },
      {
        key: 'endDate',
        sortable: true,
        i18nTitle: 'Title_MandateStrategiesTable_Column_EndDate',
        type: AdvancedTrackingTableColumnType.Date,
        parameters: { dateFormat },
      },
    ];
  }
}
