import { UntypedFormGroup } from '@angular/forms';

import { ReferenceTableData } from '../../utils/models/ReferenceTableData';
import { CompanyContact } from '../../utils/models/CompanyContact';

export class ContactManager {
  id: number;
  type: ReferenceTableData;
  form = new UntypedFormGroup({});
  data: CompanyContact;
}
