/* eslint-disable @typescript-eslint/no-explicit-any */
export class CompanyContact {
  civilityId: number;
  lastName: string;
  firstName: string;
  email: string;
  mobilePhone?: string;
  deskPhone?: string;
  companyContactFunctionId?: number;
  companyContactFunctionOther?: string;
  companyContactTypeId?: number;
  id?: number;
  companyContactType?: any;
  companyContactTypes?: [any]; // Data receive about API
}
