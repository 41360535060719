/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
// eslint-disable-next-line no-restricted-imports
import { debounceTime, distinctUntilChanged, filter, merge, Subject, takeUntil } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatBadgeModule } from '@angular/material/badge';
import { NgClass, NgIf } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { TableCheckboxShow } from '../../../table/enums/table-checkbox-show.enum';
import { AdvancedTrackingTableColumn } from '../../../tracking-tables/models/AdvancedTrackingTableColumn';
import { AdvancedTrackingTableColumnType } from '../../../tracking-tables/models/advanced-tracking-table-column-type.enum';
import { Sitemap } from '../../../utils/models/Sitemap';
import { FormUtilsService } from '../../../utils/services/form/form-utils.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SharedProgramService } from '../../../utils/services/shared-program.service';
import { SPECIAL_OFFER_CONTROL_PATH } from '../../service/special-offer.service';
import { AdvancedTrackingTablesNoBackPageComponent } from '../../../tracking-tables/components/advanced-tracking-tables-no-back-page/advanced-tracking-tables-no-back-page.component';

@Component({
  selector: 'app-step-three-special-offer-form',
  templateUrl: './step-three-special-offer-form.component.html',
  styleUrls: ['./step-three-special-offer-form.component.scss'],
  standalone: true,
  imports: [
    MatButtonToggleModule,
    FormsModule,
    NgClass,
    NgIf,
    MatBadgeModule,
    AdvancedTrackingTablesNoBackPageComponent,
    MatFormFieldModule,
    MatProgressSpinnerModule,
  ],
})
export class StepThreeSpecialOfferFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() submit: boolean;
  @Input() specialOfferId: number;

  public lotList: Array<any> = [];
  public lotListFiltered: { data: Array<any>; clearSelection: boolean };
  public readonly showCheckboxSelectionALL: TableCheckboxShow = TableCheckboxShow.ALL;
  public readonly showCheckboxSelectionNotSelected: TableCheckboxShow = TableCheckboxShow.NOT_SELECTED;
  public readonly showCheckboxSelectionSelected: TableCheckboxShow = TableCheckboxShow.SELECTED;
  public showCheckboxSelection: TableCheckboxShow = TableCheckboxShow.ALL;
  public SPECIAL_OFFER_CONTROL_PATH = SPECIAL_OFFER_CONTROL_PATH;
  public formUtilsService = FormUtilsService;
  public basicColumns: Array<AdvancedTrackingTableColumn>;

  private readonly _destroy$ = new Subject<boolean>();
  private readonly _ngClassDisable: any;
  private _selectedLotIds: Array<any> = [];
  private disableStatus: Array<string>;
  private _specialOfferForm: UntypedFormGroup;
  private _disabledForm: boolean;
  private _initialLotIds: Array<any> = [];

  constructor(
    public i18nService: I18nService,
    private readonly _sharedProgramService: SharedProgramService,
    private readonly _router: Router,
  ) {
    this._ngClassDisable = {
      classFalse: 'text-xs text-black',
      classTrue: 'text-xs text-grey',
      condition: (item: any) => item.isOfferInConflict,
    };
    this.basicColumns = [
      {
        key: 'id',
        hidden: true,
      },
      {
        key: 'programId',
        hidden: true,
      },
      {
        key: 'isOfferInConflict',
        hidden: true,
      },
      {
        key: 'isSelectionDisable',
        hidden: true,
        type: AdvancedTrackingTableColumnType.Custom,
        customFormat: (row: any): any => {
          return row.isOfferInConflict || this.disableStatus.includes(this.i18nService._(`LOTS_${row.status}`));
        },
      },
      {
        key: 'specialOfferId',
        hidden: true,
      },
      {
        key: 'programName',
        sortable: true,
        i18nTitle: 'Txt_ProgramsTrackingTable_ProgramName',
        ngClass: this._ngClassDisable,
      },
      {
        key: 'town',
        sortable: true,
        i18nTitle: 'Txt_ProgramsTrackingTable_CityPostalCode',
        ngClass: this._ngClassDisable,
      },
      {
        key: 'lotNumber',
        sortable: false,
        i18nTitle: 'Txt_Page_Program_ListLotNumber',
        ngClass: this._ngClassDisable,
      },
      {
        key: 'lotTypeLabel',
        sortable: true,
        i18nTitle: 'Txt_Page_Program_ListLotType',
        type: AdvancedTrackingTableColumnType.I18nToken,
        ngClass: this._ngClassDisable,
        parameters: {
          i18nTokenPrefix: 'LOT_TYPE_',
        },
      },
      {
        key: 'rooms',
        sortable: true,
        type: AdvancedTrackingTableColumnType.Number,
        replaceFalsyWithDash: true,
        i18nTitle: 'Txt_Page_Program_ListRoomNumber',
        ngClass: this._ngClassDisable,
      },
      {
        key: 'livingSpace',
        sortable: true,
        i18nTitle: 'Txt_Page_Program_ListLivingSpace',
        type: AdvancedTrackingTableColumnType.Surface,
        ngClass: this._ngClassDisable,
      },
      {
        key: 'commercialLotSellingPriceIT',
        sortable: true,
        i18nTitle: 'Txt_Page_Program_ListTotalSellingPriceIT',
        type: AdvancedTrackingTableColumnType.CurrencyCeil,
        ngClass: this._ngClassDisable,
      },
      {
        key: 'reducedTotalSellingPriceIT',
        sortable: true,
        i18nTitle: 'Txt_Page_Program_ListReducedTotalSellingPriceIT',
        type: AdvancedTrackingTableColumnType.CurrencyCeil,
        ngClass: this._ngClassDisable,
        parameters: {
          noEmptyValue: true,
        },
      },
      {
        key: 'refSpecialOffer',
        sortable: true,
        i18nTitle: 'Txt_Table_Column_SpecialOfferConflict',
        ngClass: {
          classFalse: 'text-xs',
          classTrue: 'text-xs txt-grey text-underline',
          condition(item): boolean {
            return item.isOfferInConflict;
          },
        },
        type: AdvancedTrackingTableColumnType.Link,
        parameters: {
          actions: {
            link: (element: any) => {
              this._router.navigate([Sitemap.specialOffer.edit.path.replace(/:specialOfferId/, element.specialOfferId.toString())]);
            },
            linkRef(element: any): string {
              return element.isOfferInConflict ? element.refSpecialOffer : undefined;
            },
          },
        },
      },
      {
        key: 'status',
        sortable: true,
        i18nTitle: 'Txt_Table_Column_Status',
        type: AdvancedTrackingTableColumnType.I18nToken,
        ngClass: this._ngClassDisable,
        parameters: {
          i18nTokenPrefix: 'LOTS_',
        },
      },
      {
        key: 'isPublished',
        sortable: true,
        i18nTitle: 'Txt_Table_Column_IsPublished',
        type: AdvancedTrackingTableColumnType.Boolean,
        ngClass: this._ngClassDisable,
      },
    ];
  }

  @Input() set specialOfferForm(fg: UntypedFormGroup) {
    if (fg) {
      this._specialOfferForm = fg;
      this._initialLotIds = [...(this.lotIdListControl.value || [])];
      this.refreshLotsList();
    }
  }

  get specialOfferForm(): UntypedFormGroup {
    return this._specialOfferForm;
  }

  @Input() set disabledForm(disabledForm: boolean) {
    this._disabledForm = disabledForm;
  }

  get disabledForm(): boolean {
    return this._disabledForm;
  }

  get lotIdListControl(): AbstractControl {
    return this.specialOfferForm.get(SPECIAL_OFFER_CONTROL_PATH.lotIdList);
  }

  get programIdListControl(): AbstractControl {
    return this.specialOfferForm.get(SPECIAL_OFFER_CONTROL_PATH.programIdList);
  }

  get endDateControl(): AbstractControl {
    return this.specialOfferForm.get(SPECIAL_OFFER_CONTROL_PATH.endDate);
  }

  get startDateControl(): AbstractControl {
    return this.specialOfferForm.get(SPECIAL_OFFER_CONTROL_PATH.startDate);
  }

  get programIdList(): Array<string> {
    return this.programIdListControl.value;
  }

  ngOnInit(): void {
    this.disableStatus = [this.i18nService._('LOTS_SOLD')];
    this.refreshLotsList();
  }

  ngAfterViewInit(): void {
    merge(
      this.programIdListControl.valueChanges,
      this.endDateControl.valueChanges,
      this.startDateControl.valueChanges,
      this.formUtilsService.getControl(SPECIAL_OFFER_CONTROL_PATH.programFinished, this.specialOfferForm).valueChanges,
    )
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter(() => !!this.programIdList?.length && !!this.startDateControl.value && !!this.endDateControl.value),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this.refreshLotsList();
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  checkedRowsChange($event: Array<any>): void {
    this._selectedLotIds = $event.filter((lotId) => this.lotList.some((lot) => lot.id === lotId));
    this.lotIdListControl.setValue(this._selectedLotIds);
  }

  filterLotList(): void {
    switch (this.showCheckboxSelection) {
      case TableCheckboxShow.ALL:
        this.lotListFiltered = { data: [...this.lotList], clearSelection: false };
        break;
      case TableCheckboxShow.NOT_SELECTED:
        this.lotListFiltered = {
          data: this.lotList.filter((value) => !this._selectedLotIds.includes(value.id)),
          clearSelection: false,
        };
        break;
      case TableCheckboxShow.SELECTED:
        this.lotListFiltered = {
          data: this.lotList.filter((value) => this._selectedLotIds.includes(value.id)),
          clearSelection: false,
        };
        break;
    }
  }

  private refreshLotsList(): void {
    if (this.programIdList?.length) {
      this._sharedProgramService
        .getProgramsLotsInformation({
          programIds: this.programIdList,
          startDateSpecialOffer: this.startDateControl.value,
          endDateSpecialOffer: this.endDateControl.value,
          responseFields: this.basicColumns.map((value) => value.key),
          specialOfferId: this.specialOfferId,
        })
        .subscribe(
          (response) => {
            this.lotList = response.lots;
            this.filterLotsBySelectedPrograms();
          },
          (error) => {
            console.error('Error fetching lots', error);
          },
        );
    } else {
      this.lotList = [];
      this.filterLotsBySelectedPrograms();
    }
  }

  private filterLotsBySelectedPrograms(): void {
    const selectedProgramIds = new Set(this.programIdListControl.value || []);
    this.lotList = this.lotList.filter((lot) => selectedProgramIds.has(lot.programId));
    this._preselectLots(this.lotIdListControl.value);
    this.filterLotList();
  }

  private _preselectLots(lotIds: any): void {
    const selectedProgramIds = this.programIdListControl.value || [];
    this._selectedLotIds = lotIds?.filter((lotId) =>
      this.lotList.some((lot) => lot.id === lotId && selectedProgramIds.includes(lot.programId)),
    );
    this.lotIdListControl.setValue(this._selectedLotIds);
  }
}
