import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { ContractualDocumentPackages } from '../../../utils/models/ContractualDocumentPackages';
import { FileFormat } from '../../../utils/models/FileFormat';
import { ProgramResponseUpdateForm } from '../../../utils/models/ProgramResponseUpdateForm';
import { ReferenceTableData } from '../../../utils/models/ReferenceTableData';
import { AccountService } from '../../../utils/services/account.service';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { ProgramService } from '../../services/program.service';
import { CompanyData } from '../../../utils/models/CompanyData';
import { atLeastOne } from '../../../utils/validators/atLeastOne-validator.directive';
import { FileDropComponent } from '../../../utils/components/file-drop/file-drop.component';
import { DropdownListPopulatedComponent } from '../../../utils/components/dropdown-list-populated/dropdown-list-populated.component';

@Component({
  selector: 'app-step-four-form-program',
  templateUrl: './step-four-form-program.component.html',
  styleUrls: ['./step-four-form-program.component.scss'],
  standalone: true,
  imports: [
    DropdownListPopulatedComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FileDropComponent,
    MatCheckboxModule,
    NgIf,
  ],
})
export class StepFourFormProgramComponent implements OnInit, OnDestroy {
  /**
   * Global configuration of the application
   */
  appConfig;

  /**
   * Input adminValoForm
   *
   * @type {FormGroup}
   * @memberof StepFourFormProgramComponent
   */
  @Input() parentForm: UntypedFormGroup;

  /**
   * is Submit input for error message
   *
   * @type {boolean}
   * @memberof StepFourFormProgramComponent
   */
  @Input() isSubmit: boolean;

  /**
   * Recovered data from edit form
   *
   * @type {ProgramResponseUpdateForm}
   * @memberof StepFourFormProgramComponent
   */
  @Input() recoveredInfos: ProgramResponseUpdateForm;

  /**
   * Display signature error message from edit form
   *
   * @type {boolean}
   * @memberof StepFourFormProgramComponent
   */
  @Input() displaySignatureErrorMessage: boolean;

  /**
   * Company data to check eligibility for electronic signature
   *
   * @type {Partial<CompanyData>}
   * @memberof StepFourFormProgramComponent
   */
  @Input() companyData: Partial<CompanyData>;

  /**
   * List of VALO responsible RCV to affiliate to program
   *
   * @type {ReferenceTableData[]}
   * @memberof StepFourFormProgramComponent
   */
  public responsibleList: ReferenceTableData[] = [];

  /**
   * Controls intracommunityVAT
   *
   * @type {AbstractControl}
   * @memberof StepFourFormProgramComponent
   */
  public intracommunityVATControl: AbstractControl;

  /**
   * Controls accountingReference
   *
   * @type {AbstractControl}
   * @memberof StepFourFormProgramComponent
   */
  public accountingReferenceControl: AbstractControl;

  /**
   * Controls isElectronicSignatureBIControl
   *
   * @type {AbstractControl}
   * @memberof StepFourFormProgramComponent
   */
  public isElectronicSignatureBIControl: AbstractControl;

  /**
   * Controls isElectronicSignatureValorissimoControl
   *
   * @type {AbstractControl}
   * @memberof StepFourFormProgramComponent
   */
  public isElectronicSignatureValorissimoControl: AbstractControl;

  /**
   * Controls isHighStake
   *
   * @type {AbstractControl}
   * @memberof StepFourFormProgramComponent
   */
  public isHighStake: AbstractControl;

  /**
   * Recovered documents to init for update form
   *
   * @type {FileFormat[]}
   * @memberof StepFourFormProgramComponent
   */
  public initFile: FileFormat[] = [];

  /**
   * Boolean list to know which contractual document package is mandatory
   *
   * @type {ContractualDocumentPackages}
   * @memberof StepFourFormProgramComponent
   */
  public contractualDocumentPackagesBooleanList: ContractualDocumentPackages;

  /**
   * isElectronicSignatureValorissimo
   *
   * @type {boolean}
   * @memberof StepFourFormProgramComponent
   */
  public isElectronicSignatureValorissimo = false;

  private contractualDocumentPackagesObservableSubscription: Subscription;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  /**
   * Creates an instance of StepFourFormProgramComponent.
   * @param {I18nService} i18nService
   * @param {SnackbarService} snackbarService
   * @param {AppConfigService} appConfigService
   * @param {AccountService} accountService
   * @memberof StepFourFormProgramComponent
   */
  constructor(
    public i18nService: I18nService,
    private readonly snackbarService: SnackbarService,
    private readonly appConfigService: AppConfigService,
    private readonly accountService: AccountService,
    private readonly programService: ProgramService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.appConfig = this.appConfigService.appConfig;
  }

  /**
   * Init method
   *
   * @memberof StepFourFormProgramComponent
   */
  ngOnInit(): void {
    this.initFile = [];

    /* Controls for form fields */
    this.parentForm.addControl('intracommunityVAT', new UntypedFormControl('', []));
    this.intracommunityVATControl = this.parentForm.controls.intracommunityVAT;
    this.parentForm.addControl('accountingReference', new UntypedFormControl('', []));
    this.accountingReferenceControl = this.parentForm.controls.accountingReference;
    this.parentForm.addControl('isElectronicSignatureBI', new UntypedFormControl('', []));
    this.isElectronicSignatureBIControl = this.parentForm.controls.isElectronicSignatureBI;
    this.parentForm.addControl(
      'isElectronicSignatureValorissimo',
      new UntypedFormControl({ value: '', disabled: !this.canESignValo() }, []),
    );
    this.isElectronicSignatureValorissimoControl = this.parentForm.controls.isElectronicSignatureValorissimo;
    this.parentForm.addControl('isHighStake', new UntypedFormControl('', []));
    this.isHighStake = this.parentForm.controls.isHighStake;

    this.parentForm.addControl(
      'document',
      this.formBuilder.group({
        ApartmentReservationContractESignValo: [false],
        HouseReservationContractESignValo: [false],
        tradeReservationContractESignValo: [false],
        ApartmentReservationContract: [false],
        HouseReservationContract: [false],
        tradeReservationContract: [false],
        descriptiveNoticeReservationContract: [false],
      }),
    );

    this.isElectronicSignatureValorissimoControl.valueChanges
      .pipe(takeUntil(this.$destroy))
      .subscribe((isElectronicSignatureValorissimo) => {
        this.isElectronicSignatureValorissimo = isElectronicSignatureValorissimo;
        this.programService.setIsElectronicSignatureValorissimo(isElectronicSignatureValorissimo);

        if (isElectronicSignatureValorissimo) {
          this.isElectronicSignatureBIControl.setValue(false);
          this.removeRequired('ApartmentContractualDocumentPackage');

          this.parentForm
            .get('document')
            .setValidators(
              atLeastOne(
                ['ApartmentReservationContractESignValo', 'HouseReservationContractESignValo', 'tradeReservationContractESignValo'],
                this.i18nService._('Error_Field_Contract_ESign_VALO'),
              ),
            );
        } else {
          this.addRequired('ApartmentContractualDocumentPackage');
        }
      });

    this.getAssociateManagerList(this.recoveredInfos?.company.isBi);

    this.contractualDocumentPackagesObservableSubscription = this.programService.contractualDocumentPackagesObservable.subscribe(
      (contractualDocumentPackagesBooleanList) => {
        this.contractualDocumentPackagesBooleanList = contractualDocumentPackagesBooleanList;
      },
    );

    // Fill all input forms if this is an edit of the prefilled form
    if (this.recoveredInfos) {
      for (const propForm of Object.keys(this.parentForm.controls)) {
        if (propForm === 'document') {
          // format the document to fit the format
          this.recoveredInfos.documents.forEach((attachment) => {
            this.initFile[attachment.type.label] = attachment;
          });
        } else {
          this.parentForm.controls[propForm].setValue(this.recoveredInfos[propForm]);
        }
      }
    }
  }

  /**
   * Control a number
   *
   * @param {*} evt
   * @returns
   * @memberof StepFourFormProgramComponent
   */
  checkIsNumber(evt): string {
    let evtValue = '';
    evt.target.value.split('').forEach((char) => {
      evtValue += Number.isInteger(parseInt(char, 10)) ? char : '';
    });

    return (evt.target.value = evtValue);
  }

  /**
   * Prevent electronic signature to be selected for both BI and Valorissimo
   *
   * @param {MatCheckboxChange} value
   * @param {AbstractControl} control
   * @memberof StepFourFormProgramComponent
   */
  onElectronicSignatureChange(value: MatCheckboxChange, control: AbstractControl, isElectronicSignatureValorissimo: boolean): void {
    if (value.checked) {
      control.setValue(false);
      this.isElectronicSignatureValorissimo = isElectronicSignatureValorissimo;
    } else {
      this.isElectronicSignatureValorissimo = false;
    }
  }

  /**
   * ngOnDestroy method
   *
   * @memberof StepFourFormProgramComponent
   */
  ngOnDestroy(): void {
    this.contractualDocumentPackagesObservableSubscription.unsubscribe();
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private removeRequired(formControlName: string) {
    const documentControl = this.parentForm.get('document');
    const control = documentControl?.get(formControlName);

    if (control) {
      control.setErrors(null);
      control.setValidators(null);
      control.updateValueAndValidity();
    }
  }

  private addRequired(formControlName: string) {
    const documentControl = this.parentForm.get('document');
    const control = documentControl?.get(formControlName);

    if (control) {
      control.setValidators(Validators.required);
      control.markAsDirty();
      control.updateValueAndValidity();
    }
  }

  canESignValo(): boolean {
    const canIt = this.companyData ? this.companyData.isEligibleDigitalSigning : this.recoveredInfos?.company?.isEligibleDigitalSigning;
    if (!canIt && this.isElectronicSignatureValorissimo) {
      this.isElectronicSignatureValorissimoControl.setValue(false);
    }
    return canIt;
  }

  public getAssociateManagerList(companyIsBi: boolean): void {
    const rolesToShow = this.getAssociatedManagerRolesToDisplay(companyIsBi);
    /* Affiliate RPV or DEV */
    this.accountService.getAccountsWithRoles(rolesToShow).subscribe(
      (accountsFound) => {
        const accountsFoundActive = accountsFound.filter((item) => item.isActive);
        this.responsibleList = accountsFoundActive.map((account) => {
          const item = new ReferenceTableData();
          item.id = account.id;
          item.label = `${account.lastName.charAt(0).toUpperCase()}${account.lastName.slice(1)}`;
          item.label += ` ${account.firstName.charAt(0).toUpperCase()}${account.firstName.slice(1)}`;
          return item;
        });
      },
      () => {
        this.snackbarService.sendErrorOccured();
      },
    );
  }

  private getAssociatedManagerRolesToDisplay(isBi: boolean): Array<string> {
    const rolesToShow = [this.appConfig.roles.valoRcvSuper, this.appConfig.roles.valoRcvSimple];
    return isBi ? rolesToShow : [...rolesToShow, this.appConfig.roles.valoDevSimple, this.appConfig.roles.valoDevSuper];
  }
}
