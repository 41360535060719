<app-form-file
  [acceptedFilesLabel]="field.props.acceptedFilesLabel"
  [docType]="field.props.typeDoc"
  [files]="field.props.files"
  [formControlCustom]="$any(field.formControl)"
  [maxFileSize]="field.props.maxFileSize"
  [multiple]="field.props.multiple"
  [readonly]="field.props.readonly"
  [selectFileLabel]="field.props.label"
  [types]="field.props.types"
  fileRequiredErrorI18nToken="Error_Field_SupportingDocumentRequired"
></app-form-file>
