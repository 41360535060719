import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { ExcludeListTrackingTableComponent } from '../exclude-list-tracking-table.component';

import { ExcludeListDialogData } from '../../../models/dialog/company/exclude-list-dialog-data';
import { I18nService } from '../../../../utils/services/i18n.service';
import { SnackbarService } from '../../../../utils/services/snackbar.service';
import { ExcludeListTrackingTableService } from '../../../services/exclude-list-tracking-table.service';

@Component({
  selector: 'app-exclude-list-dialog',
  templateUrl: './exclude-list-dialog.component.html',
  styleUrls: ['./exclude-list-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, ExcludeListTrackingTableComponent, MatButtonModule],
})
export class ExcludeListDialogComponent implements OnInit {
  private _selectedExcludeIds: number[];

  constructor(
    public i18nService: I18nService,
    private readonly _snackbarService: SnackbarService,
    private readonly _excludeListTrackingTableService: ExcludeListTrackingTableService,
    public dialogRef: MatDialogRef<ExcludeListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExcludeListDialogData,
  ) {}

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  /**
   * close method
   *
   * @memberof DesktopPublishingDialogComponent
   */
  public close(): void {
    this.dialogRef.close();
  }

  submit() {
    this.close();
    this._excludeListTrackingTableService.saveExcludeList(this.data.companyId, this._selectedExcludeIds).subscribe(() => {
      this._snackbarService.infoI18n('Info_SnackBar_ExcludeListModified');
    });
  }

  setSelectedExcludeIds($event: number[]) {
    this._selectedExcludeIds = $event;
  }
}
