import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AfterContentChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf } from '@angular/common';

import { LocalStorageService } from './../../../utils/services/local-storage.service';
import { I18nService } from './../../../utils/services/i18n.service';
import { SafePipe } from '../../../utils/pipes/safe.pipe';
import { IconComponent } from '../../../common/standalone/icon/icon.component';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  standalone: true,
  imports: [IconComponent, NgIf, MatDialogModule, MatCheckboxModule, MatButtonModule, SafePipe],
})
export class ConfirmationDialogComponent implements OnInit, AfterContentChecked {
  skipNextTime = false;
  hasHTMLMessage = false;

  constructor(
    public _i18nService: I18nService,
    public readonly detectorRef: ChangeDetectorRef,
    private _storageService: LocalStorageService,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
  ) {}

  ngOnInit(): void {
    this.data.buttonConfirm = this.data.buttonConfirm ? this.data.buttonConfirm : this._i18nService._('Txt_Button_Yes');
    if (this.data.hasHTMLMessage || (this.data.message && /.*<.*/.test(this.data.message))) {
      this.hasHTMLMessage = true;
    }
  }

  ngAfterContentChecked(): void {
    this.detectorRef.detectChanges();
  }

  closeDialog(answer: boolean) {
    if (this.data.canBeSkipped) {
      this._storageService.setItem(`${this.data.title}_skip`, this.skipNextTime ? 'true' : 'false');
    }
    this.dialogRef.close(answer);
  }
}

export interface ConfirmationDialogData {
  title: string;
  buttonConfirm?: string;
  buttonCancel?: string;
  /* The message can contains html tags.
   *  It must be translated before passing
   */
  message?: string;
  hasHTMLMessage?: boolean;
  canBeSkipped?: boolean;
  imageSource?: string;
}
