<div *ngIf="row" class="flex items-center" (click)="action(row)">
  <div class="mr-1">
    {{ cellValue }}
  </div>
  <mat-icon
    *ngIf="picto"
    [ngClass]="picto.class"
    class="valign-picto"
    matTooltip="{{ picto.i18nTooltip ? i18nService._(picto.i18nTooltip) : '' }}"
  >
    {{ picto.iconName }}
  </mat-icon>
</div>
