import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { TransactionResponse } from '@commons-dto/dossier-prospect';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

import { DownloadUtils } from '../download.utils';

import { PreReservationSubmitionDialogData } from '../../../dialog/models/PreReservationSubmitionDialogData';
import { PreReservationSubmitionDialogComponent } from '../../../dialog/components/pre-reservation-submition-dialog/pre-reservation-submition-dialog.component';
import { QuestionDialogComponent } from '../../../dialog/components/question-dialog/question-dialog.component';
import { QuestionDialogData } from '../../../dialog/models/QuestionDialogData';
import { PoseOptionsService } from '../../../utils/services/pose-options.service';
import { PoseoptionResponse } from '../../../utils/models/PoseoptionResponse';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { OptionsTrackingTableItem } from '../../../tracking-tables/models/OptionsTrackingTableItem';
import { GridTrackingTableComponent } from '../../../tracking-tables/components/grid-tacking-table/grid-tracking-table.component';
import { FeatureFlagService } from '../../../feature-flag/feature-flag.service';
import { PreReservationDossierProspectSubmitionDialogComponent } from '../../../dialog/components/pre-reservation-dossier-prospect-submition-dialog/pre-reservation-dossier-prospect-submition-dialog.component';
import { DocumentResponse } from '../../../utils/models/DocumentResponse';
import { ReservationService } from '../../../reservations/services/reservation.service';
import { SignatureService } from '../../../signature/services/signature.service';
import { Sitemap } from '../../../utils/models/Sitemap';
import { GoogleTagManagerService } from '../../../utils/services/google-tag-manager.service';
import { OptionEntity } from '../../../utils/models/OptionResponse';

@Injectable({
  providedIn: 'root',
})
export class TransactionActionService {
  gridTrackingTable: GridTrackingTableComponent;
  private isDossierProspectEnabled: boolean;
  private readonly $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly poseOptionService: PoseOptionsService,
    public readonly i18nService: I18nService,
    private readonly snackbarService: SnackbarService,
    private readonly dialog: MatDialog,
    private readonly reservationService: ReservationService,
    private readonly downloadUtils: DownloadUtils,
    private readonly signatureService: SignatureService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly router: Router,
    private readonly featureFlagService: FeatureFlagService,
  ) {
    this.featureFlagService.isEnabled('dossier-prospect').then((res) => {
      this.isDossierProspectEnabled = res;
    });
  }

  getActionItemsFromTransaction(
    transaction: TransactionResponse,
    showLotDetailsCallback: (transaction: TransactionResponse) => void,
    onItemUpdated: (transaction: TransactionResponse) => void,
  ): any[] {
    const actions = [];

    this.manageOnlineValoSignStatus(transaction);

    if (transaction.possibleActions.canExtend) {
      actions.push(this.getExtendAction(transaction, onItemUpdated));
    }
    if (transaction.possibleActions.canRemove) {
      actions.push(this.getRemoveAction(transaction, onItemUpdated));
    }
    if (transaction.possibleActions.canPreReserve) {
      actions.push(this.getPreReserveAction(transaction, onItemUpdated));
    }
    if (transaction.possibleActions.canDownloadContractualBundle) {
      actions.push(this.getDownloadContractAction(transaction));
    }
    if (transaction.possibleActions.canOnlineSign) {
      actions.push(this.getOnlineSignAction(transaction));
    }
    if (transaction.possibleActions.canContinueOnlineSign) {
      actions.push(this.getContinueOnlineSignAction());
    }
    if (transaction.possibleActions.canOnlineSignValo) {
      actions.push(this.getOnlineSignValoAction(transaction));
    }
    if (transaction.possibleActions.canContinueOnlineSignValo) {
      actions.push(this.getContinueOnlineSignValoAction(transaction));
    }

    actions.push(this.getGoToDetailsAction(transaction, showLotDetailsCallback));
    return actions;
  }

  getExtendAction(
    entity: OptionsTrackingTableItem | TransactionResponse,
    onItemUpdated: (entity: OptionsTrackingTableItem | TransactionResponse) => void,
  ) {
    return {
      label: 'Txt_Tooltip_Extend',
      action: () => {
        this.poseOptionService.extendOption(entity.lotId).subscribe(
          (response: PoseoptionResponse) => this.onPoseOptionActionSuccess.bind(this)(entity, response, onItemUpdated),
          (error: HttpErrorResponse) => {
            this.errorUpdateOption(error, this.i18nService._('Error_SnackBar_UnavailableExtendOption'));
          },
        );
      },
    };
  }

  getRemoveAction(
    entity: OptionsTrackingTableItem | TransactionResponse,
    onItemUpdated: (entity: OptionsTrackingTableItem | TransactionResponse) => void,
  ): any {
    return {
      label: 'Txt_Tooltip_Remove',
      action: () => {
        return this.openRemoveOptionDialog(entity, onItemUpdated);
      },
    };
  }

  getPreReserveAction(
    entity: OptionsTrackingTableItem | TransactionResponse,
    onItemUpdated: (entity: OptionsTrackingTableItem | TransactionResponse) => void,
  ): any {
    return {
      label: 'Txt_Tooltip_PreReserve',
      action: () => {
        return this.openPreReservationPopin(entity, onItemUpdated);
      },
    };
  }

  getGoToDetailsAction(
    entity: OptionsTrackingTableItem | TransactionResponse,
    showLotDetailsCallback: (entity: OptionsTrackingTableItem | TransactionResponse) => void,
  ): any {
    return {
      label: 'Txt_Tooltip_ShowOfferResume',
      action: () => {
        showLotDetailsCallback(entity);
      },
    };
  }

  getDownloadContractAction(entity: OptionsTrackingTableItem | TransactionResponse): any {
    return {
      label: 'Txt_Tooltip_DownloadReservationContrat',
      action: () => {
        this.reservationService.getContractualDocumentPackage(entity.id).subscribe((documents: Array<DocumentResponse>) => {
          this.downloadUtils.downloadAndZipContractualDocuments(documents, entity);
        });
      },
      isDownload: true,
    };
  }

  getOnlineSignAction(entity: TransactionResponse): any {
    return {
      label: 'Txt_Tooltip_OnlineSign',
      action: () => {
        this.reservationService.signOnline(entity.id).subscribe(({ url }) => {
          window.open(url, '_blank');
        });
      },
    };
  }

  getContinueOnlineSignAction(): any {
    return {
      label: 'Txt_Tooltip_ContinueOnlineSign',
      action: () => {
        this.reservationService.accessElectronicSignatures().subscribe(({ url }) => {
          window.open(url, '_blank');
        });
      },
    };
  }

  getOnlineSignValoAction(entity: TransactionResponse): any {
    // continue online sign valo
    return {
      label: 'Txt_Tooltip_OnlineSign',
      action: () => {
        this._googleTagManagerService.pushTag({ event: 'esignature_start_process' });
        this.signatureService
          .createDossier({
            reservationId: entity.id,
            prescriptorId: entity.ownerUserId,
          })
          .subscribe((dossier) => {
            this.router.navigate([Sitemap.signature.dashboard.path.replace(/:signatureId/, dossier.id.toString())]);
          });
      },
    };
  }

  getContinueOnlineSignValoAction(entity: TransactionResponse): any {
    // online sign valo
    return {
      label: 'Txt_Tooltip_ContinueOnlineSign',
      action: () => {
        this.router.navigate([Sitemap.signature.dashboard.path.replace(/:signatureId/, entity.dossierSignatureId.toString())]);
      },
    };
  }

  getActionItems(
    option: any,
    showLotDetailsCallback: (option: OptionsTrackingTableItem) => void,
    onItemUpdated: (option: OptionsTrackingTableItem) => void,
  ) {
    const actionList = [];
    if (option?.actions?.canExtend) {
      actionList.push(this.getExtendAction(option, onItemUpdated));
    }
    if (option?.actions?.canRemove) {
      actionList.push(this.getRemoveAction(option, onItemUpdated));
    }
    if (option?.actions?.canPreReserve) {
      actionList.push(this.getPreReserveAction(option, onItemUpdated));
    }
    if (option?.actions?.canAccept) {
      actionList.push({
        label: 'Txt_Tooltip_AcceptOption',
        action: () => {
          return this.acceptOrRejectOption(option, true, onItemUpdated);
        },
      });
    }
    if (option?.actions?.canReject) {
      actionList.push({
        label: 'Txt_Tooltip_RejectOption',
        action: () => {
          return this.acceptOrRejectOption(option, false, onItemUpdated);
        },
      });
    }
    if (option?.actions?.canShowLotDetails) {
      actionList.push(this.getGoToDetailsAction(option, showLotDetailsCallback));
    }

    return actionList;
  }

  public errorUpdateOption(error: HttpErrorResponse, text: string): void {
    if (error && (error.status === 502 || error.status === 504)) {
      const message: SnackbarMessage = {
        type: SnackbarMessageType.Error,
        text,
      };
      this.snackbarService.sendMessage(message);
    }
  }

  public openRemoveOptionDialog(
    entity: OptionsTrackingTableItem | TransactionResponse,
    onItemUpdated: (entity: OptionsTrackingTableItem | TransactionResponse) => void,
  ): void {
    const dialogData: QuestionDialogData = {
      message: this.i18nService._('Txt_Popin_Remove_Option', [entity.lotNumber, entity.programName]),
      title: this.i18nService._('Txt_Popin_Remove_Option_Title'),
    };

    const dialogRef = this.dialog.open(QuestionDialogComponent, {
      data: dialogData,
    });

    // Add in form and update company status
    dialogRef.afterClosed().subscribe((result) => {
      if (result.answer) {
        this.poseOptionService.removeOption(entity.lotId).subscribe(
          (response: PoseoptionResponse) => this.onPoseOptionActionSuccess.bind(this)(entity, response, onItemUpdated),
          (error: HttpErrorResponse) => {
            this.errorUpdateOption(error, this.i18nService._('Error_SnackBar_UnavailableRemoveOption'));
          },
        );
      }
    });
  }

  public openPreReservationPopin(
    entity: OptionsTrackingTableItem | TransactionResponse | OptionEntity,
    onItemUpdated: (entity: OptionsTrackingTableItem | TransactionResponse | OptionEntity) => void,
  ): void {
    const data: MatDialogConfig<PreReservationSubmitionDialogData> = {
      autoFocus: false,
      data: {
        lotId: entity.lotId,
        lotNumber: entity.lotNumber,
        programName: entity.programName,
      },
    };

    const preReservationPopin = this.isDossierProspectEnabled
      ? this.dialog.open(PreReservationDossierProspectSubmitionDialogComponent, { ...data, panelClass: `dialog-container-new` })
      : this.dialog.open(PreReservationSubmitionDialogComponent, data);

    preReservationPopin.afterClosed().subscribe((success: boolean) => {
      if (success) {
        onItemUpdated(entity);
      }
    });
  }

  public acceptOrRejectOption(
    entity: OptionsTrackingTableItem,
    accept: boolean,
    onItemUpdated: (entity: OptionsTrackingTableItem) => void,
  ): void {
    this.poseOptionService.acceptOrRejectOption(entity.optionId, accept).subscribe(
      (response: PoseoptionResponse) => this.onPoseOptionActionSuccess.bind(this)(entity, response, onItemUpdated),
      (error: HttpErrorResponse) => {
        this.errorUpdateOption(error, this.i18nService._('Error_SnackBar_UnavailableAcceptOrRejectOption'));
      },
    );
  }

  public onPoseOptionActionSuccess(
    entity: OptionsTrackingTableItem | TransactionResponse | OptionEntity,
    response: PoseoptionResponse,
    onItemUpdated: (entity: OptionsTrackingTableItem | TransactionResponse | OptionEntity) => void,
  ): void {
    const message: SnackbarMessage = {
      text: response.libelleRetour,
      type: SnackbarMessageType.Info,
    };
    this.snackbarService.sendMessage(message);
    onItemUpdated(entity);
  }

  private manageOnlineValoSignStatus(transaction: TransactionResponse): void {
    if (transaction?.possibleActions?.canContinueOnlineSignValo || transaction?.possibleActions.canContinueOnlineSignValo) {
      if (transaction.dossierSignatureId != null) {
        transaction.possibleActions.canContinueOnlineSignValo = true;
        transaction.possibleActions.canOnlineSignValo = false;
      } else {
        transaction.possibleActions.canContinueOnlineSignValo = false;
        transaction.possibleActions.canOnlineSignValo = true;
      }
    }
  }
}
