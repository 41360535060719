<div class="flex flex-col">
  <mat-form-field *ngIf="isDeveloper" class="flex w-1/2">
    <mat-label>{{ i18nService._('Txt_Placeholder_DeveloperCompanyName') }}</mat-label>
    <input [disabled]="true" [formControl]="developerCompanyNameControl" matInput name="developerCompanyName" />
  </mat-form-field>

  <!-- Input research town -->
  <app-form-location
    #searchLocation
    *ngIf="!isDeveloper"
    [id]="'location'"
    [parentForm]="parentForm"
    [placeholder]="i18nService._('Txt_Page_Prospect_Location')"
    [(dataMapList)]="localisationsMapList"
    (localisationsChanged)="localisationsChanged()"
    [uniqueLocation]="true"
    [filterLocalisationTypeAccepted]="filterLocalisationTypeAccepted"
  >
  </app-form-location>
  <mat-error *ngIf="!isDeveloper && !localisationsMapList.size && isSubmit">
    {{ i18nService._('Error_Field_Required') }}
  </mat-error>

  <a *ngIf="!isDeveloper && !developerCompanyItems.length && localisationsMapList.size">{{
    i18nService._('Txt_Location_Filter_Prospect_Creation')
  }}</a>

  <app-dropdown-list-populated
    (selectedItemChange)="developerCompanySelected($event)"
    *ngIf="!isDeveloper && developerCompanyItems.length"
    [fieldName]="'company'"
    [isSubmit]="isSubmit"
    [items]="developerCompanyItems"
    [parentForm]="parentForm"
    [placeholder]="i18nService._('Txt_Placeholder_DeveloperCompanyName')"
    [required]="true"
    [searchable]="true"
    class="flex w-1/2"
  >
  </app-dropdown-list-populated>

  <mat-form-field *ngIf="programList" class="flex w-4/5">
    <mat-label>
      {{ i18nService._('Title_PropertyProgram') }}
    </mat-label>
    <mat-select data-cy="program" [formControl]="programListControl" [required]="true" multiple name="programList">
      <mat-option
        attr.data-cy="{{ program }}"
        *ngFor="let program of programList"
        [value]="program.id"
        attr.data-cy="{{ program.programName }}"
      >
        {{ program.programName }}
      </mat-option>
      <mat-option *ngIf="programList.length < 1" disabled class="checkbox-hidden">
        {{ i18nService._('Txt_NoResults') }}
      </mat-option>
    </mat-select>
    <mat-error>
      {{ i18nService._('Error_Field_Required') }}
    </mat-error>
  </mat-form-field>

  <div class="flex flex-row">
    <button
      mat-flat-button
      class="mt-5 mr-4"
      color="primary"
      type="submit"
      (click)="submit()"
      [disabled]="!this.programList || !this.programList.length"
      data-cy="declare"
    >
      {{ i18nService._('Txt_Button_Declare') }}
    </button>
  </div>
</div>
