import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { NgClass, NgIf } from '@angular/common';

import { GoogleTagManagerService } from '../../../utils/services/google-tag-manager.service';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { SignatureService } from '../../services/signature.service';
import { SignatureType } from '../../models/signature-type.enum';
import { IconComponent } from '../../../common/standalone/icon/icon.component';

@Component({
  selector: 'app-signature-choice',
  templateUrl: './signature-choice.component.html',
  styleUrls: ['./signature-choice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, MatDialogModule, NgClass, NgIf],
})
export class SignatureChoiceComponent {
  SignatureType = SignatureType;
  signatureType = SignatureType.REMOTE;

  constructor(
    public i18nService: I18nService,
    private readonly signatureService: SignatureService,
    private readonly dialogRef: MatDialogRef<SignatureChoiceComponent>,
    private readonly appConfig: AppConfigService,
    private readonly snackBarService: SnackbarService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    @Inject(MAT_DIALOG_DATA) public data: { dossierId: number; pdfId: number },
  ) {}

  setSignatureType(value: SignatureType) {
    if (this.signatureType !== value && this.isAllowedType(value)) {
      this.signatureType = value;
    }
  }

  isChosen(type: SignatureType) {
    return this.signatureType === type;
  }

  close() {
    this.dialogRef.close(null);
  }

  getConfirmLabel() {
    return this.i18nService._('txt_signature_type_confirm_' + this.signatureType.toLowerCase());
  }

  isAllowedType(type: SignatureType) {
    return this.appConfig.getAppConfig().allowedSignatureType[type.toLowerCase()];
  }

  confirmSignatureType() {
    this._googleTagManagerService.pushTag({ event: 'esignature_send_file' });
    this.signatureService.createSignature(this.data.dossierId, this.data.pdfId, this.signatureType).subscribe(() => {
      if (this.signatureType === SignatureType.REMOTE) {
        this.snackBarService.message(this.i18nService._('txt_signature_type_in_progress_info'), SnackbarMessageType.Info);
      }
      this.dialogRef.close(this.signatureType);
    });
  }
}
