import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { SpinnerWithBackdropAction } from '../models/enums/spinner-with-backdrop-action.enum';

@Injectable({
  providedIn: 'root',
})
export class SpinnerWithBackdropService {
  /**
   * _spinnerAction param
   *
   * @private
   * @type {Subject<any>}
   * @memberof SpinnerWithBackdropService
   */
  private readonly _spinnerAction: Subject<SpinnerWithBackdropAction> = new Subject<SpinnerWithBackdropAction>();

  /**
   * show method
   *
   * @memberof SpinnerWithBackdropService
   */
  show(): void {
    this._spinnerAction.next(SpinnerWithBackdropAction.Show);
  }

  /**
   * hide method
   *
   * @memberof SpinnerWithBackdropService
   */
  hide(): void {
    this._spinnerAction.next(SpinnerWithBackdropAction.Hide);
  }

  /**
   * get spinnerAction method
   *
   * @returns {Observable<SpinnerWithBackdropAction>}
   * @memberof SpinnerWithBackdropService
   */
  get spinnerAction(): Observable<SpinnerWithBackdropAction> {
    return this._spinnerAction.asObservable();
  }
}
