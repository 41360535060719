export enum Operator {
  GT = 'GT',
  LT = 'LT',
  LTE = 'LTE',
  GTE = 'GTE',
  EQ = 'EQ',
  NEQ = 'NEQ',
  IN = 'IN',
  DIVIDE = 'DIVIDE',
  MULTIPLE = 'MULTIPLE',
  ADD = 'ADD',
  SUBSTRACT = 'SUBSTRACT',
  AND = 'AND',
  OR = 'OR',
  SIZE = 'SIZE',
  EMPTY = 'EMPTY',
  NOT_EMPTY = 'NOT_EMPTY',
  NULL = 'NULL',
  NOT_NULL = 'NOT_NULL',
  GET = 'GET',
}

export class Expression {
  operator: Operator;
  firstMember: Member;
  secondMember?: Member | Member[];
}

export interface Member {
  value?: string | number | boolean | Array<unknown>;
  field?: string;
  expression?: Expression;
}
