<div class="flex flex-col register-form-prospect">
  <app-sticky-header-form [titleText]="i18nService._('Title_Form_Prospect_DeclareProspect')"></app-sticky-header-form>

  <div class="grid grid-cols-10 pt-16">
    <div class="col-span-10 lg:col-span-8 lg:col-start-2 xl:col-span-6 xl:col-start-3 flex flex-col pt-16">
      <app-alert
        *ngIf="isNotDeveloper"
        [type]="alertEnum.INFO"
        [body]="'Txt_alert_private_data_developer'"
        class="prospect-alert mb-4"
      ></app-alert>
      <form [formGroup]="prospectDeclarationForm">
        <mat-stepper orientation="vertical" #stepper [@.disabled]="true" [linear]="true" appMatVerticalStepper class="mb-5">
          <ng-template matStepperIcon="edit">
            <span class="icon-validate-small"></span>
          </ng-template>

          <!-- Contact information of Prospect -->
          <mat-step [stepControl]="prospectContactInformationForm">
            <ng-template matStepLabel>{{ i18nService._('Title_Form_Prospect_ContactInformationOfProspect') }}</ng-template>
            <form [formGroup]="prospectContactInformationForm" class="flex flex-col">
              <app-step-one-form-prospect #stepOne [parentForm]="prospectContactInformationForm" class="flex flex-col">
              </app-step-one-form-prospect>
              <!-- Next Step -->
              <div class="flex w-1/5">
                <button type="submit" class="mt-3" mat-stroked-button (click)="submitStepOne()" color="primary" data-cy="nextStep">
                  {{ i18nService._('Txt_Button_NextStep') }}
                </button>
              </div>
            </form>
          </mat-step>

          <!-- Programs choice -->
          <mat-step>
            <ng-template matStepLabel>{{ i18nService._('Title_Form_Prospect_AskForPaternity') }}</ng-template>
            <form [formGroup]="programsChoiceForm">
              <app-step-two-form-prospect (nextStep)="onSubmit()" [parentForm]="programsChoiceForm"></app-step-two-form-prospect>
            </form>
          </mat-step>
        </mat-stepper>
      </form>
    </div>
  </div>
</div>
