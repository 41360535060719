import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';

import { InputPhoneComponent } from '../../form/input-phone/input-phone.component';

@Component({
  selector: 'app-formly-input-phone-number',
  templateUrl: './formly-input-phone-number.component.html',
  styleUrls: ['./formly-input-phone-number.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [InputPhoneComponent],
})
export class FormlyInputPhoneNumberComponent extends FieldType<FieldTypeConfig<FormlyFieldProps>> {}
