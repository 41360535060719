export const environment = {
  production: false,
  debug: true,
  featureFlagCacheDurationInSec: 3600,
  /* eslint-disable no-template-curly-in-string */
  recaptchaV3_public_key: '${RECAPTCHAV3_PUBLIC_KEY}',
  cityscan_client_public_key: '${CITYSCAN_PUBLIC_KEY}',
  intercom_app_id: '${INTERCOM_APP_ID}',
  // Goto:  https://www.mapbox.com/account/access-tokens/ and get your own Mapbox token
  mapbox_token: `pk.eyJ1IjoiZGF2aWRnYXV0aWVyIiwiYSI6ImNrenFzNHJ0eTBjb3Mydm9oNGQ0ZzYyZTQifQ.YNrY92iEMQJcI1_PuHgkLg`,
  mapbox_style: 'mapbox://styles/davidgautier/cl06i9ewm000314ky495xwt1g', // Ex: "mapbox://styles/mapbox/streets-v9";
};
