import { AfterViewInit, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { DateTime } from 'luxon';
import { takeUntil } from 'rxjs';
import { NgIf } from '@angular/common';

import { LotComparatorMetaLineComponent } from '../lot-comparator-meta-line/lot-comparator-meta-line.component';

import { LotComparatorDetail } from '../../../../lot/model/lot-comparator-detail';
import { BasicFormatsService } from '../../../../utils/services/basic-formats.service';
import { FileSystemService } from '../../../../utils/services/file-system.service';
import { ToggleFeesService } from '../../../../utils/services/toggle-fees.service';
import { ComparatorMeta, ComparatorMetaWrapper } from '../../../models/comparator-meta';
import { AbstractComparatorComponent } from '../../abstract-comparator-component';

@Component({
  selector: 'app-lot-comparator-information',
  templateUrl: './lot-comparator-information.component.html',
  styleUrls: ['./lot-comparator-information.component.scss'],
  standalone: true,
  imports: [NgIf, LotComparatorMetaLineComponent],
})
export class LotComparatorInformationComponent extends AbstractComparatorComponent implements AfterViewInit {
  lotMetaInformation: ComparatorMetaWrapper;
  @Input() lotDetailToCompare: LotComparatorDetail[];
  @ViewChild('taxationsTemplateRef', { static: true })
  public taxationsTemplateRef: TemplateRef<unknown>;
  @ViewChild('feesTemplateRef', { static: true })
  public feesTemplateRef: TemplateRef<unknown>;
  public hideLotFees: boolean;

  constructor(
    public basicFormatsService: BasicFormatsService,
    private readonly toggleFeesService: ToggleFeesService,
    private readonly _fileSystemService: FileSystemService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.lotMetaInformation = this._comparatorPageService.comparatorMeta.information;
    (this.lotMetaInformation.commercialLotSellingPriceIT as ComparatorMeta).format = (value) => {
      return this.basicFormatsService.formatCurrencyCeil(value.commercialLotSellingPriceIT, undefined, 0, 0, 0);
    };
    (this.lotMetaInformation.deliveryDate as ComparatorMeta).format = (value) => {
      return DateTime.fromJSDate(new Date(value.deliveryDate)).toFormat(`q'T 'yyyy`);
    };
    (this.lotMetaInformation.livingSpace as ComparatorMeta).format = (value) => {
      return `${this.basicFormatsService.formatArea(value.livingSpace)}`;
    };
    (this.lotMetaInformation.specialOffer as ComparatorMeta).format = (value) => {
      return !value.specialOffer ? null : value.specialOffer.label;
    };
    (this.lotMetaInformation.typology as ComparatorMeta).format = (value) => {
      return this.i18nService._(value.typology);
    };
    (this.lotMetaInformation.fees as ComparatorMeta).templateRef = this.feesTemplateRef;
    (this.lotMetaInformation.orientation as ComparatorMeta).format = (value) => {
      return this.i18nService._(value.orientation);
    };
    (this.lotMetaInformation.taxations as ComparatorMeta).templateRef = this.taxationsTemplateRef;

    this.toggleFeesService.toggleFeesState$.pipe(takeUntil(this.$d)).subscribe((state) => {
      this.hideLotFees = state;
      if (this.lotMetaInformation) {
        (this.lotMetaInformation.fees as ComparatorMeta).show = state
          ? false
          : !this.lotDetailToCompare.find((lot) => lot && lot.isFeesHidden);
      }
    });
  }
}
