<ng-container *ngFor="let lotMetaTitle of lotMetaWrapper.titles">
  <div class="comparator-meta-line" *ngIf="lotMetaWrapper[lotMetaTitle]?.show" [ngClass]="{ 'without-title': withoutTitle }">
    <div *ngIf="!withoutTitle">
      <div class="lot-meta-line-titles text-sm">
        <span> {{ i18nService._(lotMetaWrapper[lotMetaTitle]['translationKey']) }}</span>
      </div>
    </div>
    <div
      *ngFor="let lotDetail of lotDetailToCompare"
      [ngClass]="{
        'comparator-content-empty': !lotDetail,
        'lot-is-unavailable': lotDetail?.isUnvailable,
        'comparator-optionned-by-me': lotDetail?.status.optionnedByMe && !lotDetail?.status.sold
      }"
    >
      <div class="lot-meta-line-values" *ngIf="lotDetail">
        <ng-container
          *ngIf="(lotMetaWrapper[lotMetaTitle] | as: ComparatorMetaPartial).templateRef"
          [ngTemplateOutlet]="(lotMetaWrapper[lotMetaTitle] | as: ComparatorMetaPartial).templateRef"
          [ngTemplateOutletContext]="{
            $implicit: lotDetail,
            lotMetaTitle: lotMetaTitle
          }"
        >
        </ng-container>
        <ng-container *ngIf="!(lotMetaWrapper[lotMetaTitle] | as: ComparatorMetaPartial).templateRef">
          <ng-container *ngIf="lotDetail">
            <ng-container *ngIf="formattedValues[lotDetail.id] && formattedValues[lotDetail.id][lotMetaTitle]; else noDataTemplateRef">
              <span [innerHTML]="formattedValues[lotDetail.id][lotMetaTitle]"></span>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="" *ngIf="!lotDetail">&nbsp;</div>
    </div>
  </div>
</ng-container>

<ng-template #noDataTemplateRef><span class="empty-values">-</span></ng-template>
