import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

import { TokenService } from './authorisation/token.service';

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  private userIdSentToSentry: number;

  constructor(private readonly tokenService: TokenService) {}

  /**
   * sendUserToSentry
   *
   * @memberof SentryService
   */
  public sendUserToSentry(): void {
    const userId = this.tokenService.getUserId();
    if (userId && this.userIdSentToSentry != userId) {
      console.log('sendUserToSentry', { id: userId });
      Sentry.setUser({ id: userId });
      this.userIdSentToSentry = userId;
    }
  }
}
