import { IsNumber, IsOptional } from 'class-validator';

export class SummarySimulationResponse {
  @IsNumber()
  @IsOptional()
  id?: number;

  @IsNumber()
  @IsOptional()
  purchasingCapacity?: number;

  @IsNumber()
  @IsOptional()
  loanCapacity?: number;

  @IsNumber()
  @IsOptional()
  personalFunds?: number;
}
