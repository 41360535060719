import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgFor, NgIf } from '@angular/common';

import { SearchResultsLotsComponent } from '../search-results-lots/search-results-lots.component';
import { SearchResultsProgramsComponent } from '../search-results-programs/search-results-programs.component';

import { ProgramService } from '../../../programs/services/program.service';
import { LotSearchResponse } from '../../../utils/models/LotSearchResponse';
import { I18nService } from '../../../utils/services/i18n.service';
import { ProgramSearchResponse } from '../../../utils/models/ProgramSearchResponse';
import { SearchFormUtilsService } from '../../services/search-form-utils.service';
import { SearchFormService } from '../../services/search-form.service';
import { SearchPageDisplayType, SearchProgramData } from '../../model/search-program-data';
import { SearchCountResponse } from '../../model/search-count-response';
import { SearchProgramDataOrderBy } from '../../model/search-program-data-order-by';
import { SafePipe } from '../../../utils/pipes/safe.pipe';
import { MapModule } from '../../../map/map.module';
import { ComparatorPreviewButtonComponent } from '../../../comparator/components/comparator-preview-button/comparator-preview-button.component';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ComparatorPreviewButtonComponent,
    MatButtonModule,
    NgClass,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    NgFor,
    MatOptionModule,
    SearchResultsProgramsComponent,
    MapModule,
    SearchResultsLotsComponent,
    SafePipe,
  ],
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  private _lotResults: Array<LotSearchResponse>;
  public SearchPageDisplayType = SearchPageDisplayType;

  /**
   * lots input
   *
   * @type {Array<LotResponse>}
   * @memberof SearchResultsLotsComponent
   */
  @Input()
  public set lotResults(lots: Array<LotSearchResponse>) {
    this._lotResults = lots;
  }

  public get lotResults(): Array<LotSearchResponse> {
    return this._lotResults;
  }

  @Output() private readonly refreshLotsEvent: EventEmitter<number> = new EventEmitter<number>();

  /**
   * programResults input
   *
   * @type {Array<ProgramResponse>}
   * @memberof SearchResultsComponent
   */
  @Input() public programResults: Array<ProgramSearchResponse>;

  /**
   * countResults input
   *
   * @type {SearchCountResponse}
   * @memberof SearchResultsComponent
   */
  @Input() public isResultData: boolean;
  @Input() public isSpinner: boolean;
  @Input() public countResults: SearchCountResponse;

  @Output() readonly orderByChanged: EventEmitter<SearchProgramDataOrderBy> = new EventEmitter(undefined);

  @Output() readonly typeByChanged: EventEmitter<SearchPageDisplayType> = new EventEmitter(false);

  public selectedOrderBy: SearchProgramDataOrderBy = undefined;
  readonly orderByFilters = [
    {
      label: 'price',
      direction: 'ASC',
      text: this.i18nService._('Txt_SortBy_Price_ASC'),
    },
    {
      label: 'price',
      direction: 'DESC',
      text: this.i18nService._('Txt_SortBy_Price_DESC'),
    },
    {
      label: 'deliveryDate',
      direction: 'ASC',
      text: this.i18nService._('Txt_SortBy_DeliveryDate_ASC'),
    },
    {
      label: 'deliveryDate',
      direction: 'DESC',
      text: this.i18nService._('Txt_SortBy_DeliveryDate_DESC'),
    },
    {
      label: 'publishedDate',
      direction: 'DESC',
      text: this.i18nService._('Txt_SortBy_PublishedDate_DESC'),
    },
  ];
  isFilterChange: SearchProgramData;

  constructor(
    public i18nService: I18nService,
    public programService: ProgramService,
    public searchFormService: SearchFormService,
    public readonly route: ActivatedRoute,
    public searchFormUtilsService: SearchFormUtilsService,
  ) {}

  ngOnInit(): void {
    let queryParams = this.route.snapshot.queryParams;
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      queryParams = { ...params };
    });
    const optionalParams = this.searchFormUtilsService.parseSearchProgramFromFilterChain(queryParams);
    this.selectedOrderBy = this.orderByFilters.find(
      (value) => value.label === optionalParams.order.label && value.direction === optionalParams.order.direction,
    );
  }

  ngOnDestroy(): void {
    this.programService.setFilterSearch(undefined);
  }

  onOrderChange(): void {
    this.orderByChanged.emit(this.selectedOrderBy);
  }

  changeTypeOfSearch(typeOfSearch: SearchPageDisplayType): void {
    if (typeOfSearch !== this.route.snapshot.queryParams.type) {
      this.typeByChanged.emit(typeOfSearch);
      this.searchFormService.setSearchByType(typeOfSearch);
      this.searchFormService.pushClickedCarteLotToDatalayer(typeOfSearch);
    }
  }

  refreshLots(pageIndex: number) {
    this.refreshLotsEvent.emit(pageIndex);
  }
}
