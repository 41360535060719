<ng-template #panelTemplate>
  <ng-container *ngFor="let item of appItems">
    <app-item [item]="item"></app-item>
  </ng-container>
  <ng-container *ngIf="displayLegalMention">
    <div class="program-page-lot-detail-legal-information">
      {{ i18nService._('Txt_Page_Program_ListInformationsLegalMention') }}
    </div>
  </ng-container>
</ng-template>

<app-single-accordion-panel
  [title]="'Txt_page_program_Detail_Lot_information_block_rent_and_profitability'"
  [optionTemplate]="panelTemplate"
  [open]="true"
></app-single-accordion-panel>
