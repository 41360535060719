<div class="w-full h-full text-center">
  <h1 class="text-6xl">
    {{ i18nService._('Title_NotFound') }}
  </h1>

  <h1 class="mat-headline-5 px-3 text-center">
    {{ i18nService._('Txt_Page_NotFound') }}
  </h1>

  <button data-cy="goBack" (click)="backToLoginPage()" class="my-4" color="primary" mat-flat-button>
    {{ i18nService._('Txt_Button_GoBack') }}
  </button>
</div>
