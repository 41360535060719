<div class="font-bold flex items-center">
  <div *ngIf="titleHtml" class="flex-1 text-2xl" [innerHTML]="titleHtml"></div>
  <div *ngIf="titleText" class="flex-1 text-2xl">{{ i18nService._(titleText) }}</div>
  <div *ngIf="showReload">
    <button
      class="text-orange-500 border-orange-500 border rounded-3xl py-2 px-4 h-fit uppercase flex items-center font-bold text-xxxs"
      type="button"
      (click)="openUpdateDialog()"
    >
      <app-icon name="ArrowPath" class="mr-2 text-orange-500" size="0.75rem"></app-icon>
      <span>{{ i18nService._('txt_signature_reload_data') }}</span>
    </button>
  </div>
  <div *ngIf="showDuplicate">
    <button
      type="button"
      class="text-orange-500 border-orange-500 border rounded-3xl py-2 px-4 h-fit uppercase flex items-center font-bold text-xxxs"
      (click)="prefillAcq2()"
    >
      <app-icon name="Users" class="mr-2 text-orange-500" size="0.75rem"></app-icon>
      <span>{{ i18nService._('prefill_button') }}</span>
    </button>
  </div>
</div>
