/* eslint-disable @typescript-eslint/no-explicit-any */

import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColDef } from 'ag-grid-community';

import { SelectionTrackingTableComponent } from '../selection-tracking-table/selection-tracking-table.component';

import { MandateStrategySelectionTableDataType } from '../../../strategies/components/enums/mandate-strategy-selection-table-data-type.enum';
import { BasicFormatsService } from './../../../utils/services/basic-formats.service';
import { AlotmentSelectionTableService } from './../../services/alotment-selection-table.service';
import { TableLoader, TableResponse } from './../../../table/interfaces/DataLoader';
import { I18nService } from './../../../utils/services/i18n.service';
import { DateRendererComponent } from '../../renderers/date-renderer/date-renderer.component';
import { PriceRendererComponent } from '../../renderers/price-renderer/price-renderer.component';
import { DEFAULT_CHECK_BOX_SELECTABLE_DEF } from '../../models/constants/tracking-table-item-definition.constants';

@Component({
  selector: 'app-alotment-selection-table',
  templateUrl: 'alotment-selection-table.component.html',
  styleUrls: ['alotment-selection-table.component.scss'],
  standalone: true,
  imports: [SelectionTrackingTableComponent],
})
export class AlotmentSelectionTableComponent implements OnInit {
  @Input() mandateStrategyForm: UntypedFormGroup;

  preSelectedRows: any[];
  pageSize = 10;
  pageNumber = 1;
  tableHeight = 'auto';
  serverSide = false;
  columnDefs: ColDef[];
  filterData: any = [];
  tableLoader: TableLoader<any>;

  rowsControl: AbstractControl;

  @ViewChild(SelectionTrackingTableComponent)
  selectionTrackingTableComponent: SelectionTrackingTableComponent;

  constructor(
    public readonly i18nService: I18nService,
    private readonly alotmentSelectionTableService: AlotmentSelectionTableService,
    private readonly route: ActivatedRoute,
    private readonly basicFormatsService: BasicFormatsService,
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.initializeGrid();
  }

  private initializeForm(): void {
    this.mandateStrategyForm.addControl(MandateStrategySelectionTableDataType.LOTS, new UntypedFormControl([], Validators.required));
    this.rowsControl = this.mandateStrategyForm.controls[MandateStrategySelectionTableDataType.LOTS];
    this.initializeSelectedRows();
  }

  private initializeSelectedRows(): void {
    this.mandateStrategyForm.controls[MandateStrategySelectionTableDataType.LOTS].valueChanges.subscribe((value) => {
      this.preSelectedRows = value;
    });
  }

  private initializeGrid(): void {
    const programId = this.getProgramId();
    this.tableLoader = this.createTableLoader(programId);
    this.columnDefs = this.createColumnDefs();
  }

  private getProgramId(): number {
    return parseInt(this.route.snapshot.paramMap.get('programId'), 10);
  }

  private createTableLoader(programId: number): TableLoader<any> {
    const alotmentSelectionTableService = this.alotmentSelectionTableService;
    return {
      async get(): Promise<TableResponse<any>> {
        const mandateStrategy = await alotmentSelectionTableService.getAlotment(programId);
        return { items: mandateStrategy };
      },
    };
  }

  private createColumnDefs(): ColDef[] {
    return [
      {
        field: 'id',
        hide: true,
      },
      DEFAULT_CHECK_BOX_SELECTABLE_DEF,
      {
        field: 'lotNumber',
        headerName: this.i18nService._('Txt_Table_Column_LotNumber'),
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'rooms',
        headerName: this.i18nService._('Txt_Table_Column_Rooms'),
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'livingSpace',
        headerName: this.i18nService._('Txt_Table_Column_LivingSpace'),
        valueFormatter: (params) => {
          return params.value
            ? `${this.basicFormatsService.formatDecimal(params.value)} ${this.i18nService._('Txt_Table_SquareMeter')}`
            : undefined;
        },
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'deliveryDate',
        headerName: this.i18nService._('Txt_Table_Column_Delivery'),
        cellRenderer: DateRendererComponent,
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'commercialLotSellingPriceIT',
        headerName: this.i18nService._('Txt_Table_Column_CommercialLotSellingPriceIT'),
        cellRenderer: PriceRendererComponent,
        minWidth: 80,
        flex: 3,
      },
    ];
  }

  reset(): void {
    this.selectionTrackingTableComponent.reset();
  }

  isRowSelectable(): boolean {
    return true;
  }
}
