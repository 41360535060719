import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { map, Observable, tap } from 'rxjs';

import { PageProgramHelperService } from './page-program-helper.service';

import { ProgramDetail } from '../../../utils/models/ProgramDetail';
import { SpinnerWithBackdropService } from '../../../utils/services/spinner-with-backdrop.service';
import { ProgramService } from '../../services/program.service';
import { ProgramPageService } from '../../services/program-page.service';

export const ProgramPageResolver: ResolveFn<ProgramDetail> = (routeSnapshot: ActivatedRouteSnapshot) => {
  const spinnerWithBackdropService: SpinnerWithBackdropService = inject<SpinnerWithBackdropService>(SpinnerWithBackdropService);
  const programService: ProgramService = inject<ProgramService>(ProgramService);
  const pageProgramHelperService: PageProgramHelperService = inject<PageProgramHelperService>(PageProgramHelperService);
  const _programPageService: ProgramPageService = inject<ProgramPageService>(ProgramPageService);

  const _getProgramDatalayerData = (program: ProgramDetail) => {
    programService.getProgramDatalayer(program.programId).subscribe((_programDataLayer) => {
      const documentPerspective = program.documents.find((doc) => doc.type.label === 'perspective');
      program.departementCode = _programDataLayer.department;
      program.perspectiveTitle = documentPerspective ? documentPerspective.title : null;
      program.perspectiveContainer = documentPerspective ? documentPerspective.container : null;
      program.perspectiveFileName = documentPerspective ? documentPerspective.fileName : null;
      _programPageService.pushProgramDatalayerData({
        program,
        lot: program.lots[0],
        _programDataLayer,
      });
    });
  };
  const _loadProgram = (programId: number): Observable<ProgramDetail> => {
    return programService.getProgram(programId).pipe(
      // Parse dates
      map((program) => {
        if (program.publishedDate) {
          program.publishedDate = new Date(program.publishedDate);
        }
        if (program.firstPublicationDate) {
          program.firstPublicationDate = new Date(program.firstPublicationDate);
        }

        if (program.lots) {
          for (const l of program.lots) {
            if (l.deliveryDate) {
              l.deliveryDate = new Date(l.deliveryDate);
            }
          }
        }

        return program;
      }),
      tap((programDetails) => {
        pageProgramHelperService.setProgramDetails(programDetails);
        _getProgramDatalayerData(programDetails);
        spinnerWithBackdropService.hide();
      }),
    );
  };

  const programId = routeSnapshot.params.programId as number;
  spinnerWithBackdropService.show();

  return _loadProgram(programId);
};
