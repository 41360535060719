import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { NgFor, NgIf } from '@angular/common';

import { AreaTypeResponse } from '../../../utils/models/AreaTypeResponse';
import { LotAreaResponse } from '../../../utils/models/LotAreaResponse';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { ReferenceTablesService } from '../../../utils/services/reference-tables.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { LotAreaTypeData } from '../../models/LotAreaTypeData';
import { DecimalMaskDirective } from '../../../utils/directives/decimal-mask.directive';

@Component({
  selector: 'app-area-input-form-program',
  templateUrl: './area-input-form-program.component.html',
  styleUrls: ['./area-input-form-program.component.scss'],
  standalone: true,
  imports: [NgFor, MatRadioModule, FormsModule, ReactiveFormsModule, NgIf, MatFormFieldModule, MatInputModule, DecimalMaskDirective],
})
export class AreaInputFormProgramComponent implements OnInit, OnDestroy {
  /**
   * ParentForm to add controls to
   *
   * @type {FormGroup}
   * @memberof AreaInputFormProgramComponent
   */
  @Input() parentForm: UntypedFormGroup;

  /**
   * Init data from parent component to set
   *
   * @type {Array<LotAreaResponse>}
   * @memberof AreaInputFormProgramComponent
   */
  @Input() dataToInit: Array<LotAreaResponse>;

  /**
   * Boolean to show errors on submit
   *
   * @type {boolean}
   * @memberof AreaInputFormProgramComponent
   */
  @Input() isSubmit: boolean;

  /**
   * Data radiobutton to be display in front with control on field
   *
   * @type {Array<LotAreaTypeData>}
   * @memberof AreaInputFormProgramComponent
   */
  public areaTypesData: Array<LotAreaTypeData>;

  /**
   * controlValueChangesSubscription attribute
   *
   * @type {Subscription}
   * @memberof AreaInputFormProgramComponent
   */
  private controlValueChangesSubscription: Subscription;

  /**
   * Creates an instance of AreaInputFormProgramComponent.
   * @param {I18nService} i18nService
   * @param {ReferenceTablesService} referenceTablesService
   * @param {SnackbarService} snackbarService
   * @param {FormBuilder} fb
   * @param {AppConfigService} appConfigService
   * @memberof AreaInputFormProgramComponent
   */
  constructor(
    public i18nService: I18nService,
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly snackbarService: SnackbarService,
    private readonly fb: UntypedFormBuilder,
    public readonly appConfigService: AppConfigService,
  ) {
    this.areaTypesData = [];
  }

  /**
   * Get lotHasAreas Form Array
   *
   * @readonly
   * @type {FormArray}
   * @memberof AreaInputFormProgramComponent
   */
  get lotHasAreasForm(): UntypedFormArray {
    return this.parentForm.get('lotHasAreas') as UntypedFormArray;
  }

  /**
   * Init method
   *
   * @memberof AreaInputFormProgramComponent
   */
  ngOnInit(): void {
    // Get AreaTypes from database
    this.referenceTablesService.getTableReferenceInfo('AreaTypes').subscribe(
      (areaTypes: Array<AreaTypeResponse>) => {
        areaTypes.forEach((areaType) => {
          // Build form group for each area
          const formGroup = this.fb.group({
            id: new UntypedFormControl(areaType.id, [Validators.required]),
            label: new UntypedFormControl(areaType.label, [Validators.required]),
            control: new UntypedFormControl(undefined, [Validators.required]),
            spaceControl: new UntypedFormControl(undefined, [Validators.required]),
          });

          // Subscribe on changes of control to display or not area space field
          this.controlValueChangesSubscription = formGroup
            .get('control')
            .valueChanges.subscribe((checked) =>
              checked ? formGroup.get('spaceControl').enable() : formGroup.get('spaceControl').disable(),
            );

          // Push form group to form Array
          this.lotHasAreasForm.push(formGroup);

          // Data for html
          const areaTypesData = {
            id: areaType.id,
            label: areaType.label,
            control: formGroup.get('control'),
            spaceControl: formGroup.get('spaceControl'),
          };

          this.areaTypesData.push(areaTypesData);
        });
        // Set Lot Areas and surface if update lot
        this.setLotAreasEditFormLot(this.dataToInit);
      },
      () => {
        this.snackbarService.sendErrorOccured();
      },
    );
  }

  /**
   * Destroy method
   * Remove all controls otherwise duplicates controls on next init
   *
   * @memberof AreaInputFormProgramComponent
   */
  ngOnDestroy(): void {
    this.lotHasAreasForm.controls = [];
    if (this.controlValueChangesSubscription) {
      this.controlValueChangesSubscription.unsubscribe();
    }
  }

  /**
   * Set init data if edit lot form
   *
   * @private
   * @param {Array<LotAreaResponse>} dataToInit
   * @memberof AreaInputFormProgramComponent
   */
  private setLotAreasEditFormLot(dataToInit: Array<LotAreaResponse>): void {
    if (dataToInit) {
      this.areaTypesData.forEach((areaType) => {
        const dataArea = dataToInit.find((x) => x.areaType.label === areaType.label);
        if (dataArea) {
          areaType.control.setValue(true);
          areaType.spaceControl.setValue(dataArea.area);
        } else {
          areaType.control.setValue(false);
          areaType.spaceControl.setValue(undefined);
        }
      });
    }
  }
}
