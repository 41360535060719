import { EventEmitter, Injectable } from '@angular/core';
import { groupBy } from 'lodash-es';
import { catchError, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { COMPARATOR_META, ComparatorMeta, ComparatorMetaWrapper, ComparatorPageAllDataMeta, hasAnnexes } from '../models/comparator-meta';
import { ComparatorStorage } from '../models/comparator-storage';

import { tagClass, TagOption, TagsInterface, TagTypeEnum } from '../../design-system/model/tag-option';
import { LotArea, LotComparatorDetail, LotStatus, SecondaryLot } from '../../lot/model/lot-comparator-detail';
import { LotService } from '../../lot/service/lot.service';
import { PageLotService } from '../../lot/service/page-lot.service';
import { annexeTagsSmall } from '../../programs/models/enum/annexe-tag.enum';
import { ProgramService } from '../../programs/services/program.service';
import { TokenService } from '../../utils/services/authorisation/token.service';
import { BasicFormatsService } from '../../utils/services/basic-formats.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';
import { GoogleTagManagerService } from '../../utils/services/google-tag-manager.service';
import { I18nService } from '../../utils/services/i18n.service';
import { LocalStorageService } from '../../utils/services/local-storage.service';
import { SpinnerWithBackdropService } from '../../utils/services/spinner-with-backdrop.service';
import { TaxationsService } from '../../utils/services/taxations/taxations.service';
import { LotDetailResponse } from '../../utils/models/LotDetailResponse';
import { AppConfigService } from '../../utils/services/app-config.service';
import { TVA } from '../../utils/enums/tva.enum';

@Injectable({
  providedIn: 'root',
})
export class ComparatorPageService {
  public $shouldOpenComparatorPreview: EventEmitter<boolean>;
  private readonly localStorageName = 'comparator_lot';
  private taxationLabels: string[];

  constructor(
    public basicFormatsService: BasicFormatsService,
    private readonly _tokenService: TokenService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _taxationsService: TaxationsService,
    private readonly _i18nService: I18nService,
    private readonly _programService: ProgramService,
    private readonly _pageLotService: PageLotService,
    private readonly _lotService: LotService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly _spinnerWithBackdropService: SpinnerWithBackdropService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly _appConfigService: AppConfigService,
  ) {
    this.$shouldOpenComparatorPreview = new EventEmitter<boolean>();
  }

  private _lotsToCompare: LotComparatorDetail[] = [];

  get lotsToCompare(): LotComparatorDetail[] {
    return this._lotsToCompare || [];
  }

  set lotsToCompare(value: LotComparatorDetail[]) {
    this._lotsToCompare = value;
    this._indexLots(this._lotsToCompare).then(() => {
      this._preprocessLotLineShow(this._lotsToCompare);
    });
  }

  private _lotById: { [key: number]: LotComparatorDetail };

  get lotById(): { [p: number]: LotComparatorDetail } {
    return this._lotById;
  }

  private _comparatorMeta: ComparatorPageAllDataMeta = new ComparatorPageAllDataMeta();

  get comparatorMeta(): ComparatorPageAllDataMeta {
    return this._comparatorMeta;
  }

  set comparatorMeta(value: ComparatorPageAllDataMeta) {
    this._comparatorMeta = value;
  }

  get lotsToCompareObservable(): Observable<LotComparatorDetail[]> {
    return this.getComparatorStorage().pipe(
      tap(() => {
        this._spinnerWithBackdropService.show();
      }),
      map((comparatorStorage) => [...this.getComparatorStorageForCurrentUser(comparatorStorage)]),
      switchMap((lotsToCompare) => {
        if (!lotsToCompare || !lotsToCompare.length) {
          return of([]);
        }

        return this._lotService.getlotDetailForComparator(lotsToCompare);
      }),
      map((lotsToCompare) => {
        const tab = [];
        for (let i = 0; i < 3; i++) {
          tab[i] = undefined;
        }
        lotsToCompare.forEach((value, index) => (tab[index] = value));
        this.lotsToCompare = [...tab];
        this._spinnerWithBackdropService.hide();

        return this.lotsToCompare;
      }),
      catchError((err) => {
        this._spinnerWithBackdropService.hide();

        return this.errorHandlerService.handleError<LotComparatorDetail[]>(
          'ComparatorResolver : LotService',
          'getlotDetailForComparator',
        )(err);
      }),
      take(1),
    );
  }

  public emitOpenComparatorPreview() {
    this.$shouldOpenComparatorPreview.emit(true);
  }

  addOneLotToCompare(comparatorStorage: ComparatorStorage, idLot: number): boolean {
    let userIdLotComparator;
    let lengthBefore = comparatorStorage ? comparatorStorage[this._tokenService.getUserId()].length : 0;
    let isInsert = true;
    if (comparatorStorage === null) {
      // eslint-disable-next-line no-param-reassign
      comparatorStorage = {};
      comparatorStorage[this._tokenService.getUserId()] = [idLot];
      this._localStorageService.setItem(this.localStorageName, comparatorStorage);
    } else {
      userIdLotComparator = comparatorStorage[this._tokenService.getUserId()];
      lengthBefore = userIdLotComparator.length;
      const existIdLot = comparatorStorage[this._tokenService.getUserId()].includes(idLot);
      if (existIdLot) {
        userIdLotComparator.splice(
          userIdLotComparator.findIndex((element) => element === idLot),
          1,
        );
        this._localStorageService.setItem(this.localStorageName, comparatorStorage);
        isInsert = false;
      } else {
        if (userIdLotComparator.length < 3) {
          userIdLotComparator.push(idLot);
          comparatorStorage[this._tokenService.getUserId()] = userIdLotComparator;
          this._localStorageService.setItem(this.localStorageName, comparatorStorage);
        }
      }
    }

    if (userIdLotComparator.length !== lengthBefore) {
      this.emitOpenComparatorPreview();
    }

    return isInsert;
  }

  getComparatorStorage(): Observable<ComparatorStorage> {
    return this._localStorageService.getItem<ComparatorStorage>(this.localStorageName);
  }

  getComparatorStorageForCurrentUser(comparatorStorage: ComparatorStorage): number[] {
    if (comparatorStorage && !comparatorStorage[this._tokenService.getUserId()]) {
      comparatorStorage[this._tokenService.getUserId()] = [];
    }

    return comparatorStorage ? comparatorStorage[this._tokenService.getUserId()] : [];
  }

  getLengthCompareStorage(comparatorStorage: ComparatorStorage): boolean {
    return this.getComparatorStorageForCurrentUser(comparatorStorage).length < 3;
  }

  findLotToCompare(comparatorStorage: ComparatorStorage, idLot: number): boolean {
    return this.getComparatorStorageForCurrentUser(comparatorStorage).includes(idLot);
  }

  removeLotToCompare(comparatorStorage: ComparatorStorage, idLot: number): void {
    const lotIds = this.getComparatorStorageForCurrentUser(comparatorStorage);
    lotIds.splice(
      lotIds.findIndex((element) => element === idLot),
      1,
    );
    comparatorStorage[this._tokenService.getUserId()] = [...lotIds];
    this._localStorageService.setItem<ComparatorStorage>(this.localStorageName, comparatorStorage);
  }

  clearLotsToCompareForCurrentUser(comparatorStorage: ComparatorStorage): void {
    comparatorStorage[this._tokenService.getUserId()] = [];
    this._localStorageService.setItem<ComparatorStorage>(this.localStorageName, comparatorStorage);
  }

  getTaxationsTag(taxations: string[]): TagsInterface[] {
    taxations = taxations.filter((val) => val !== TVA.NOMINAL_TAXATION);
    return this._taxationsService.getTaxationsTags(taxations);
  }

  extractAnnexTags(annexes: Array<LotArea | SecondaryLot>) {
    if (!annexes) {
      return [];
    }
    const annexeGroupByLabel = groupBy(annexes, (annexe) => {
      if (annexe.label.indexOf('PARKING') >= 0) {
        return 'PARKING';
      }

      return annexe.label;
    });
    const tags: TagsInterface[] = [];
    Object.keys(annexeGroupByLabel).forEach((key) => {
      const tag = { ...annexeTagsSmall[key] };
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      tag.tagOption = { ...annexeTagsSmall[key].tagOption } as TagOption;
      const baseText = this._i18nService._(annexeTagsSmall[key].tagOption.text);
      // eslint-disable-next-line prefer-template
      const nbAnnexe = annexeGroupByLabel[key].length > 1 ? `x${annexeGroupByLabel[key].length}` : '';
      tag.tagOption.text = `${baseText} ${nbAnnexe}`;
      tags.push(tag);
    });

    return tags;
  }

  pushToDatalayerComparatorChangeEvent({
    programId,
    lot,
    isInserted,
  }: {
    programId?: number;
    lot?: LotDetailResponse;
    isInserted: boolean;
  }) {
    const datalayerEvent = isInserted ? 'add_to_comparator' : 'remove_from_comparator';
    if (lot && programId) {
      const annexes = this.computeAnnexeForDatalayer(lot);
      this._googleTagManagerService.pushTag({
        program: {
          id: programId,
        },
        lot: this.getLotForDatalyer(lot, annexes),
        event: datalayerEvent,
      });
    } else {
      this._googleTagManagerService.pushTag({ event: datalayerEvent });
    }
  }

  pushToDatalayerComparatorLotOptionAction(lot: LotComparatorDetail, event: string) {
    this._googleTagManagerService.pushTag({
      program: {
        id: lot.programId,
      },
      lot: {
        Id: lot.id,
        roomNumber: lot.rooms,
        price: lot.commercialLotSellingPriceIT,
        taxation: lot.taxations.join(','),
        surface: lot.livingSpace,
        status: lot.status,
        type: lot.typology,
        tva: lot.taxations.map((value) => value.taxation).join(','),
        delivery: lot.deliveryDate,
        orientation: lot.orientation,
        profitability: lot.profitability,
        rent: lot.estmatedMonthlyRentingPrice,
        specialOffer: !!lot.specialOffer,
        excluValo: lot.isValorissimoExclusivity,
        terrace: hasAnnexes(lot, 'TERRACE'),
        balcony: hasAnnexes(lot, 'BALCONY'),
        garden: hasAnnexes(lot, 'GARDEN'),
        loggia: hasAnnexes(lot, 'LOGGIA'),
        annexIncluded: lot.secondaryLotPriceIncluded,
        feeRate: lot.fees,
      },
      event,
    });
  }

  pushToDatalayerAccessComparator() {
    this._googleTagManagerService.pushTag({ event: 'compare' });
  }

  pushToDatalayerClearComparator() {
    this._googleTagManagerService.pushTag({ event: 'clear_comparator' });
  }

  pushToDatalayerDropOneItem() {
    this._googleTagManagerService.pushTag({ event: 'remove_from_comparator' });
  }

  pushToDatalayerAccessPreview() {
    this._googleTagManagerService.pushTag({ event: 'preview_comparator' });
  }

  private _preprocessLotLineShow(lots: LotComparatorDetail[]) {
    const comparatorMeta = { ...COMPARATOR_META };
    Object.keys(comparatorMeta).forEach((key) => {
      const comparatorMetaElement: ComparatorMetaWrapper = comparatorMeta[key];
      comparatorMetaElement.atLeastOneShow = false;
      Object.keys(comparatorMetaElement).forEach((metaKey) => {
        const meta = comparatorMetaElement[metaKey] as ComparatorMeta;
        if (meta.field) {
          if (!meta.mandatory) {
            meta.show = this.processAtLeastOneLot(lots, meta);
          }
          if (meta.show) {
            comparatorMetaElement.atLeastOneShow = true;
          }
        }
      });
    });
    this._comparatorMeta = comparatorMeta;
  }

  private processAtLeastOneLot(lots: LotComparatorDetail[], meta) {
    let atLeastOne = false;
    lots.forEach((lot) => {
      if (lot) {
        const lotElement = lot[meta.field];
        if (meta.complexShow) {
          if (meta.complexShow(lot)) {
            atLeastOne = true;
          }
        } else {
          if (this._computeBasicShow(meta, lotElement)) {
            atLeastOne = true;
          }
        }
      }
    });

    return atLeastOne;
  }

  private _computeBasicShow(meta: ComparatorMeta, lotElement) {
    switch (meta.type) {
      case 'array': {
        if (lotElement && lotElement.length) {
          return true;
        }
        break;
      }
      case 'object': {
        if (lotElement && Object.keys(lotElement).length) {
          return true;
        }
        break;
      }
      case 'base': {
        if (lotElement) {
          return true;
        }
        break;
      }
      default:
    }

    return false;
  }

  private async _indexLots(value: LotComparatorDetail[]): Promise<boolean> {
    return new Promise((resolve) => {
      const index = {};
      value.forEach((lot) => {
        if (lot) {
          index[lot.id] = lot;
          lot.isUnvailable = this._isLotUnavailable(lot.status);
          lot.annexes = [];
          if (lot.areas) {
            lot.annexes = lot.annexes.concat(lot.areas);
          }
          if (lot.secondaryLots) {
            lot.annexes = lot.annexes.concat(lot.secondaryLots);
          }
          lot.priceFormatted = this._computePriceTTc(lot) ? this._computePriceTTc(lot) : null;
          lot.estimatedWorkPrice = this._computeBasiPriceWithCurrency(Number(lot.estimatedWorkPrice));
          lot.estimatedLandPrice = this._computeBasiPriceWithCurrency(Number(lot.estimatedLandPrice));
          lot.estimatedFurnishedMarketRent = this._computeBasiPriceWithCurrency(Number(lot.estimatedFurnishedMarketRent));
          lot.usufruitValue = this._computeBasiPriceWithCurrency(Number(lot.usufruitValue));
          lot.nueProprieteDuration = this._computeYears(Number(lot.nueProprieteDuration));
          lot.reducedPriceFormatted = this._computeReducePrice(lot) ? this._computeReducePrice(lot) : null;
          lot.fiscalityTags = this.getTaxationsTag(lot.taxationsLabels);
          if (lot.taxations.find((taxation) => taxation.label === 'NUE PROPRIETE')) {
            lot.taxationTags = this._extractTaxationsTags(lot, lot.taxations[0].taxation);
          } else {
            this._programService.getNominalTaxationValue().subscribe((data) => {
              lot.taxationTags = this._extractTaxationsTags(lot, data[0].taxation);
            });
          }
        }
        resolve(true);
      });
      this._lotById = index;
    });
  }

  private _extractTaxationsTags(
    lotToCompare: LotComparatorDetail,
    nominalTaxationValue: number,
  ): { taxations: TagsInterface; reducedTaxation?: TagsInterface } | null {
    const taxationLabels = this._appConfigService.getAppConfig().taxationList;

    if (
      lotToCompare.taxations.some((element) => element.label === taxationLabels.MALRAUX || element.label === taxationLabels.DEFICIT_FONCIER)
    ) {
      return null;
    }

    return {
      taxations: {
        ngClass: tagClass[TagTypeEnum.TAXATIONS],
        tagOption: {
          text: `TVA à ${this._handleLotVAT(lotToCompare, nominalTaxationValue)}`,
          type: TagTypeEnum.TAXATIONS,
        },
      },
      reducedTaxation: lotToCompare.taxations.find((element) => element.reducedTaxation)
        ? {
            ngClass: tagClass[TagTypeEnum.REDUCED_TAXATIONS],
            tagOption: {
              text: `TVA à ${this.basicFormatsService.formatPercentage(
                lotToCompare.taxations.find((element) => element.reducedTaxation).taxation / 100,
              )}`,
              type: TagTypeEnum.REDUCED_TAXATIONS,
              suffixIcon: 'navigate_next',
            },
          }
        : undefined,
    };
  }

  private _computeBasiPriceWithCurrency(value: number): string {
    if (!value) {
      return undefined;
    }

    return this.basicFormatsService.formatCurrencyCeil(value, undefined, 0, 0, 0);
  }

  private _computeYears(value: number): string {
    if (!value) {
      return undefined;
    }
    return value + ' ans';
  }

  private _computePriceTTc(lot: LotComparatorDetail): string {
    const price = this.basicFormatsService.formatCurrencyCeil(lot.commercialLotSellingPriceIT, undefined, 0, 0, 0);
    const isLmnpLmp = this._pageLotService.hasLmnpLmpTaxation(lot.taxations);
    const isSecondaryLotPriceIncluded = lot.secondaryLots && lot.secondaryLots.length && lot.secondaryLotPriceIncluded;
    let priceSuffix = '';
    if (isLmnpLmp && isSecondaryLotPriceIncluded) {
      priceSuffix = this._i18nService._('TXT_comparator_price_infos_lmnp_and_IncludedArea');
    } else if (!isLmnpLmp && isSecondaryLotPriceIncluded) {
      priceSuffix = this._i18nService._('Txt_Page_Program_IncludedArea');
    } else if (isLmnpLmp && !isSecondaryLotPriceIncluded) {
      priceSuffix = this._i18nService._('TXT_comparator_price_infos_lmnp');
    }

    return priceSuffix ? `${price} ${priceSuffix}` : price;
  }

  private _computeReducePrice(lot: LotComparatorDetail): string {
    const reducedPrice = this.basicFormatsService.formatCurrencyCeil(lot.reducedTotalSellingPriceIT, undefined, 0, 0, 2);
    const priceSuffix =
      lot.secondaryLots && lot.secondaryLots.length && lot.secondaryLotPriceIncluded
        ? this._i18nService._('Txt_Page_Program_IncludedArea')
        : '';

    return lot.taxations.find((element) => element.reducedTaxation) ? `${reducedPrice} ${priceSuffix}` : null;
  }

  private _handleLotVAT(lotDetails: LotComparatorDetail, nominalTaxationValue: number): string {
    if (lotDetails.taxations.length === 1 && lotDetails.isReducedTaxation) {
      return this.basicFormatsService.formatPercentage(lotDetails.taxations[0].taxation / 100, 0, 0, 2);
    }

    return this.basicFormatsService.formatPercentage(nominalTaxationValue / 100, 0, 0, 2);
  }

  private _isLotUnavailable(lotStatus: LotStatus): boolean {
    return !lotStatus.published || !lotStatus.mandated || lotStatus.sold || lotStatus.quotaReach;
  }

  private computeAnnexeForDatalayer(lot): string[] {
    let annexes = [];
    if (lot.lotAreas) {
      annexes = [...lot.lotAreas.map((value) => value.label)];
    }
    if (lot.secondaryLots) {
      annexes = [...annexes, ...lot.secondaryLots.map((value) => value.label)];
    }

    return annexes;
  }

  private getLotForDatalyer(lot, annexes: string[]) {
    return {
      Id: lot.lotId,
      roomNumber: lot.rooms ? lot.rooms : '',
      price: lot.price ? lot.price : '',
      taxation: lot.taxations ? lot.taxations.join(',') : lot.labelsProfitability ? lot.labelsProfitability : '',
      surface: lot.livingSpace ? lot.livingSpace : '',
      status: lot.status ? lot.status : '',
      type: lot.lotTypeLabel ? lot.lotTypeLabel : '',
      tva: lot.taxations ? lot.taxations.map((value) => value.taxation).join(',') : '',
      delivery: lot.deliveryDate ? lot.deliveryDate : '',
      orientation: lot.lotOrientationLabel ? lot.lotOrientationLabel : '',
      profitability: lot.profitability ? lot.profitability : '',
      rent: lot.estmatedMonthlyRentingPrice ? lot.estmatedMonthlyRentingPrice : '',
      specialOffer: lot.specialOffers && lot.specialOffers.length,
      excluValo: '',
      terrace: annexes.includes('TERRACE'),
      balcony: annexes.includes('BALCONY'),
      garden: annexes.includes('GARDEN'),
      loggia: annexes.includes('LOGGIA'),
      annexIncluded: lot.secondaryLotPriceIncluded ? lot.secondaryLotPriceIncluded : '',
      feeRate: lot.fees ? lot.fees : '',
    };
  }
}
