/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  ACCESS_TOKEN_EXPIRE_KEY,
  ACCESS_TOKEN_KEY,
  LOGIN_AS_ACCESS_TOKEN_EXPIRE_KEY,
  LOGIN_AS_ACCESS_TOKEN_KEY,
  LOGIN_AS_REFRESH_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from '../../models/app-constant';
import { JwtToken, LoginResponse } from '../../models/LoginResponse';
import { ValoRole } from '../../../../../../../libs/commons-dto/src/lib/valo-back/valo-role.enum';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private readonly isLoggedIn: BehaviorSubject<boolean>;
  private token: LoginResponse;

  constructor() {
    this.isLoggedIn = new BehaviorSubject(this.checkIsLoggedIn());
  }

  public getLoggedInObservable(): Observable<boolean> {
    return this.isLoggedIn.asObservable();
  }

  public checkIsLoggedIn(): boolean {
    return Boolean(this.getToken());
  }

  setOldToken(token: LoginResponse): void {
    this.token = token;
    this.isLoggedIn.next(this.checkIsLoggedIn());
  }

  setToken(token: JwtToken, loginAs = false): void {
    this.token = this._maptokenToLoginResponse(token);
    this._saveToLocalStorage(token, loginAs);
    this.isLoggedIn.next(this.checkIsLoggedIn());
  }

  deleteToken(loginAs = false): void {
    this.token = undefined;
    this.isLoggedIn.next(this.checkIsLoggedIn());
    this._removeFromLocalStorage(loginAs);
  }

  deleteAllToken(): void {
    this.deleteToken(false);
    this.deleteToken(true);
  }

  getToken(): LoginResponse {
    return this.token;
  }

  getAccessToken(): string {
    return localStorage.getItem(LOGIN_AS_ACCESS_TOKEN_KEY) || localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  getRefreshToken(): string {
    return localStorage.getItem(LOGIN_AS_REFRESH_TOKEN_KEY) || localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  /**
   * Checks if the access token has expired.
   * @param delay Number of seconds to subtract from the token expiration date to calculate if it has expired.
   * @returns True if the token has expired, false otherwise.
   */
  isAccessTokenExpired(delay = 0): boolean {
    const tokenExpireIsoDate = localStorage.getItem(LOGIN_AS_ACCESS_TOKEN_EXPIRE_KEY) || localStorage.getItem(ACCESS_TOKEN_EXPIRE_KEY);

    if (!tokenExpireIsoDate) {
      return true; // The token is considered expired if there is no expiration date available.
    }

    const currentDate = DateTime.now();
    const tokenExpireDate = DateTime.fromISO(tokenExpireIsoDate).minus({ seconds: delay });

    return currentDate >= tokenExpireDate;
  }

  /**
   * Checks if the access token has expired.
   * @param delay Number of seconds to subtract from the token expiration date to calculate if it has expired.
   * @returns True if the token has expired, false otherwise.
   */
  isRefreshTokenExpired(delay = 0): boolean {
    const tokenExpireIsoDate = localStorage.getItem(LOGIN_AS_REFRESH_TOKEN_KEY) || localStorage.getItem(REFRESH_TOKEN_KEY);

    if (!tokenExpireIsoDate) {
      return true; // The token is considered expired if there is no expiration date available.
    }

    const currentDate = DateTime.now();
    const tokenExpireDate = DateTime.fromISO(tokenExpireIsoDate).minus({ seconds: delay });

    return currentDate >= tokenExpireDate;
  }

  getPrincipalRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  getPrincipalAccessToken(): string {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  getLogAsRefreshToken(): string {
    return localStorage.getItem(LOGIN_AS_REFRESH_TOKEN_KEY);
  }

  getTokenData(key: string): any {
    const token = this.getToken();

    return token ? token[key] : undefined;
  }

  /**
   * Getter the user's role id
   * @returns {number} - Return the user's role id
   */
  getRoleId(): number {
    return this.getTokenData('roleId');
  }

  /**
   * Getter the user's firstname
   * @returns {string} - Return the user's firstname
   */
  getFirstName(): string {
    return this.getTokenData('firstName');
  }

  /**
   * Getter the user's lastname
   * @returns {string} - Return the user's lastname
   */
  getLastName(): string {
    return this.getTokenData('lastName');
  }

  /**
   * Getter the user's civility label
   * @returns {number} - Return the user's civility label
   */
  getCivilityLabel(): string {
    return this.getTokenData('civility');
  }

  /**
   * Getter the user's role name
   * @returns {string} - Return the user's role name
   */
  getRoleName(): string {
    return this.getTokenData('roleName');
  }

  /**
   * Getter the user's role
   * @returns {string} - Return the user's role
   */
  getRole(): ValoRole {
    return this.getTokenData('roleName') as ValoRole;
  }

  /**
   * Getter the user id
   * @returns {number} - Return the user id
   */
  getUserId(): number {
    return this.getTokenData('userId');
  }

  /**
   * Getter the user id
   * @returns {number} - Return the user id
   */
  getCompanyId(): number {
    return this.getTokenData('companyId');
  }

  /**
   * Getter if user is part or BI
   * @returns {number} - Return true if the user is part of BI
   */
  getIsBi(): number {
    return this.getTokenData('isBi');
  }

  /**
   * Get the company trading name
   * @returns {string} - Return the company trading name
   */
  getCompanyTradingName(): string {
    return this.getTokenData('companyTradingName');
  }

  /**
   * Getter the company label
   * @returns {string} - Return the company label
   */
  getCompanyLabel(): string {
    return this.getTokenData('companyTypeLabel');
  }

  /**
   * An indication of whether the user is in a 'login as' mode.
   * This information is in the LoginResponse.
   */
  isConnectionAs(): boolean {
    return Boolean(localStorage.getItem(LOGIN_AS_ACCESS_TOKEN_KEY));
  }

  /**
   * Getter the fullName of connected user
   * @returns {string} - Return fullName of connected user
   */
  getFullName(): string {
    return `${this.getTokenData('firstName')} ${this.getTokenData('lastName')}`;
  }

  /**
   * Getter email
   * @returns {string} - Return email
   */
  getEmail(): string {
    return this.getTokenData('email');
  }

  /**
   * Getter asUserEmail
   * @returns {string} - Return email
   */
  getAsUserEmail(): string {
    return this.getTokenData('asUserEmail');
  }

  /**
   * Getter asUserId
   * @returns {string} - Return email
   */
  getAsUserId(): string {
    return this.getTokenData('asUserId');
  }

  private _maptokenToLoginResponse(token: JwtToken): LoginResponse {
    return {
      userId: token.user.id,
      companyId: token.user.additionalInfo.companyId,
      roleName: token.user.roles.length > 0 ? token.user.roles[0] : null,
      civility: token.user.additionalInfo.civility,
      firstName: token.user.firstName,
      lastName: token.user.lastName,
      email: token.user.email,
      companyTypeLabel: token.user.additionalInfo.companyTypeLabel,
      companyTradingName: token.user.additionalInfo.companyTradingName,
      isBi: token.user.additionalInfo.isBi,
    };
  }

  private _removeFromLocalStorage(loginAs = false) {
    localStorage.removeItem(`${loginAs ? LOGIN_AS_ACCESS_TOKEN_KEY : ACCESS_TOKEN_KEY}`);
    localStorage.removeItem(`${loginAs ? LOGIN_AS_REFRESH_TOKEN_KEY : REFRESH_TOKEN_KEY}`);
    localStorage.removeItem(`${loginAs ? LOGIN_AS_ACCESS_TOKEN_EXPIRE_KEY : ACCESS_TOKEN_EXPIRE_KEY}`);
  }

  private _saveToLocalStorage(token: JwtToken, loginAs = false) {
    localStorage.setItem(`${loginAs ? LOGIN_AS_ACCESS_TOKEN_KEY : ACCESS_TOKEN_KEY}`, token.access_token);
    localStorage.setItem(`${loginAs ? LOGIN_AS_REFRESH_TOKEN_KEY : REFRESH_TOKEN_KEY}`, token.refresh_token);
    localStorage.setItem(`${loginAs ? LOGIN_AS_ACCESS_TOKEN_EXPIRE_KEY : ACCESS_TOKEN_EXPIRE_KEY}`, token.accessTokenExpired);
  }
}
