<div class="main">
  <button class="closing-dialog" (click)="close()">
    <app-icon name="XMark"></app-icon>
  </button>
  <h1 class="text-3xl font-bold mb-8 text-center">{{ i18nService._('txt_signature_type_title') }}</h1>
  <mat-dialog-content>
    <div class="flex justify-center flex-col lg:flex-row items-center lg:items-stretch w-full gap-8 mb-8 mx-0 py-1">
      <div
        (click)="setSignatureType(SignatureType.PHYSICAL)"
        class="outline-2 outline rounded-10 shadow-custom cursor-pointer flex flex-col items-center px-4 py-6 w-56"
        [ngClass]="{
          'outline-orange-500': isChosen(SignatureType.PHYSICAL),
          'outline-gray-200 bg-gray-200 cursor-not-allowed': !isAllowedType(SignatureType.PHYSICAL),
          'hover:outline-orange-300 bg-white': isAllowedType(SignatureType.PHYSICAL)
        }"
      >
        <div class="rounded-full bg-orange-500 w-8 h-8 flex items-center justify-center mb-4">
          <app-icon name="UserGroup" class="text-white" size="1.25rem"></app-icon>
        </div>
        <h2 class="text-xl font-bold mb-4">{{ i18nService._('txt_title_physical') }}</h2>
        <p *ngIf="isAllowedType(SignatureType.PHYSICAL); else notAvailable" class="text-center text-gray-600 font-semibold leading-5">
          {{ i18nService._('txt_description_physical_1') }}<br />
          {{ i18nService._('txt_description_physical_2') }}
        </p>
      </div>

      <div
        (click)="setSignatureType(SignatureType.REMOTE)"
        class="rounded-10 shadow-custom cursor-pointer flex flex-col items-center px-4 py-6 w-56 outline outline-2"
        [ngClass]="{
          'outline-orange-500': isChosen(SignatureType.REMOTE),
          'outline-gray-200 bg-gray-200 cursor-not-allowed': !isAllowedType(SignatureType.REMOTE),
          'hover:outline-orange-300 bg-white': isAllowedType(SignatureType.REMOTE)
        }"
      >
        <div class="rounded-full bg-orange-500 w-8 h-8 flex items-center justify-center mb-4">
          <app-icon name="ComputerDesktop" size="1.25rem" class="text-white"></app-icon>
        </div>
        <h2 class="text-xl font-bold mb-4">{{ i18nService._('txt_title_remote') }}</h2>
        <p *ngIf="isAllowedType(SignatureType.REMOTE); else notAvailable" class="text-center text-gray-600 font-semibold leading-5">
          {{ i18nService._('txt_description_remote') }}
        </p>
      </div>

      <div
        (click)="setSignatureType(SignatureType.PAPER)"
        class="outline-2 outline rounded-10 shadow-custom cursor-pointer flex flex-col items-center px-4 py-6 w-56"
        [ngClass]="{
          ' outline-orange-500': isChosen(SignatureType.PAPER),
          'outline-gray-200 bg-gray-200 cursor-not-allowed': !isAllowedType(SignatureType.PAPER),
          'hover:outline-orange-300 bg-white': isAllowedType(SignatureType.PAPER)
        }"
      >
        <div class="rounded-full bg-gray-100 w-8 h-8 flex items-center justify-center mb-4">
          <app-icon name="DocumentText" size="1.5rem" class="text-gray-500"></app-icon>
        </div>
        <h2 class="text-xl font-bold mb-4">{{ i18nService._('txt_title_paper') }}</h2>
        <p *ngIf="isAllowedType(SignatureType.PAPER); else notAvailable" class="text-center text-gray-600 font-semibold leading-5">
          {{ i18nService._('txt_description_paper') }}
        </p>
      </div>
    </div>
  </mat-dialog-content>
  <div class="flex justify-end gap-4">
    <button (click)="close()" class="bg-gray-300 w-fit h-12 mr-5 rounded-full uppercase font-bold px-8">
      {{ i18nService._('txt_signature_type_cancel') }}
    </button>
    <button type="button" class="rounded-button" (click)="confirmSignatureType()">{{ getConfirmLabel() }}</button>
  </div>
</div>

<ng-template #notAvailable>
  <p class="text-center italic text-gray-600 font-semibold leading-5">{{ i18nService._('txt_signature_type_not_available') }}</p>
</ng-template>
