import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { filter, forkJoin, map, take } from 'rxjs';

import { FormViewResponse } from '../models/FormResponse';
import { SignatureService } from './signature.service';
import { Dossier } from '../models/Dossier';
import { SignatureStatus } from '../models/signature-status.enum';

import { Sitemap } from '../../utils/models/Sitemap';

export const SignatureContractResolver: ResolveFn<{ formview: FormViewResponse; dossier: Dossier }> = (route: ActivatedRouteSnapshot) => {
  const signature: SignatureService = inject(SignatureService);
  const router: Router = inject(Router);
  const id = route.paramMap.get('signatureId');
  signature.getFormViewByDossierId(Number(id));
  return forkJoin([
    signature.formViewDossier$.pipe(
      filter((formview) => !!formview),
      take(1),
    ),
    signature.getDossier(Number(id)),
  ]).pipe(
    map(([formview, dossier]) => {
      if (dossier.status === SignatureStatus.DRAFT) {
        const nbRequiredRemaining = 0;
        formview.categories.reduce((prev, cur) => prev + cur.remainedFields, nbRequiredRemaining);
        if (nbRequiredRemaining > 0) {
          router.navigate([Sitemap.signature.dashboard.path.replace(':signatureId', id)]);
        }
      }
      return { formview, dossier };
    }),
  );
};
