<div
  class="program-main-infos-header"
  [ngClass]="{
    'big-logo': programFiscalityTags && programFiscalityTags.length <= 1
  }"
>
  <div class="program-main-infos-header-delivery-date">
    <div class="label-section">{{ i18nService._('Txt_page_program_Delivery_Date') }}</div>
    <div class="text-base font-bold" [innerHTML]="program?.deliveryDateAsString | safe: 'html'"></div>
  </div>
  <div class="program-main-infos-header-logo" *ngIf="program?.logoCompanyTitle">
    <img class="max-w-full" src="{{ companyLogo }}" alt="{{ program?.logoCompanyTitle }}" />
  </div>
</div>

<hr class="hr-gray" />
<div class="flex flex-col flex-wrap">
  <div class="program-main-infos-data">
    <!-- fiscalities program -->
    <span>
      <div class="label-section">{{ i18nService._('Txt_Page_Program_Fiscality') }}</div>
      <div class="program-main-infos-fiscalities">
        <app-tags *ngFor="let tag of programFiscalityTags" [ngClass]="tag?.ngClass" [tagOption]="tag?.tagOption"></app-tags>
        <app-tags
          [ngClass]="tagClass[tagTypeEnum.TRANSPARENT]"
          [tagOption]="{
            text: i18nService._('Txt_Page_Program_ProgramZone', [program.taxZoneLabel]),
            type: tagTypeEnum.TRANSPARENT
          }"
        ></app-tags>
      </div>
    </span>
    <!-- key date -->
    <span>
      <ul class="infos-key-date">
        <div class="label-section">{{ i18nService._('Txt_Page_Program_Rentability') }}</div>

        <li>
          <span> {{ i18nService._('Txt_Page_Program_Nue') }} : </span>
          <span class="infos-rentability" *ngIf="program?.minProfitabilityNue && program?.maxProfitabilityNue">
            <!-- Cas normal où les deux valeurs sont valides -->
            <ng-container *ngIf="isValidProfitability(program?.minProfitabilityNue) && isValidProfitability(program?.maxProfitabilityNue)">
              <span *ngIf="program?.minProfitabilityNue === program?.maxProfitabilityNue; else maxIsNotEqualTomin">
                <b>{{ program?.minProfitabilityNue / 100 | percent: '1.2-2' : 'fr' }}</b>
              </span>
              <ng-template #maxIsNotEqualTomin>
                <b
                  [innerHTML]="
                    i18nService._('Txt_page_program_infos_rentability', [
                      program?.minProfitabilityNue / 100 | percent: '1.2-2' : 'fr',
                      program?.maxProfitabilityNue / 100 | percent: '1.2-2' : 'fr'
                    ]) | safe: 'html'
                  "
                >
                </b>
              </ng-template>
            </ng-container>
            <!-- Cas où une ou les deux valeurs sont invalides (Infinity ou -Infinity) -->
            <ng-container
              *ngIf="!isValidProfitability(program?.minProfitabilityNue) || !isValidProfitability(program?.maxProfitabilityNue)"
            >
              <span><b>-</b></span>
            </ng-container>
          </span>
        </li>
        <li>
          <span> {{ i18nService._('Txt_Page_Program_Meuble') }} : </span>
          <span class="infos-rentability" *ngIf="program?.minProfitabilityMeuble && program?.maxProfitabilityMeuble">
            <!-- Cas normal où les deux valeurs sont valides -->
            <ng-container
              *ngIf="isValidProfitability(program?.minProfitabilityMeuble) && isValidProfitability(program?.maxProfitabilityMeuble)"
            >
              <span *ngIf="program?.minProfitabilityMeuble === program?.maxProfitabilityMeuble; else maxIsNotEqualTomin">
                <b>{{ program?.minProfitabilityMeuble / 100 | percent: '1.2-2' : 'fr' }}</b>
              </span>
              <ng-template #maxIsNotEqualTomin>
                <b
                  [innerHTML]="
                    i18nService._('Txt_page_program_infos_rentability', [
                      program?.minProfitabilityMeuble / 100 | percent: '1.2-2' : 'fr',
                      program?.maxProfitabilityMeuble / 100 | percent: '1.2-2' : 'fr'
                    ]) | safe: 'html'
                  "
                >
                </b>
              </ng-template>
            </ng-container>
            <!-- Cas où une ou les deux valeurs sont invalides (Infinity ou -Infinity) -->
            <ng-container *ngIf="!isValidProfitability(program?.minProfitability) || !isValidProfitability(program?.maxProfitability)">
              <span><b>-</b></span>
            </ng-container>
          </span>
        </li>
      </ul>
    </span>

    <!-- Txt_Page_Program_Key_Date -->
    <span>
      <div class="label-section">{{ i18nService._('Txt_Page_Program_Key_Date') }}</div>
      <ul class="infos-key-date">
        <li>
          <span>
            {{ i18nService._('Txt_Page_Program_Key_Date_BuildingPermitStartDate') }} :
            <b>{{ program?.buildingPermitStartedAt ? (program.buildingPermitStartedAt | date: 'dd/MM/yyyy') : 'NC' }}</b>
          </span>
        </li>
        <li>
          <span>
            {{ i18nService._('Txt_Page_Program_Key_Date_BuildingPermitExpireDate') }} :
            <b>{{ program?.buildingPermitExpiredAt ? (program.buildingPermitExpiredAt | date: 'dd/MM/yyyy') : 'NC' }}</b>
          </span>
        </li>
        <li>
          <span>
            {{ i18nService._('Txt_Page_Program_Key_Date_Actable') }} :
            <b>{{ program?.actable ? i18nService._('Txt_Yes') : i18nService._('Txt_No') }}</b>
          </span>
        </li>
        <li>
          <span>
            {{ i18nService._('Txt_Page_Program_Key_Date_WorkStarted') }} :
            <b>{{ program?.workStartedAt ? (program.workStartedAt | date: 'dd/MM/yyyy') : 'NC' }}</b>
          </span>
        </li>
      </ul>
    </span>

    <span *ngIf="programStrongPoints?.length">
      <div class="label-section">{{ i18nService._('Txt_Page_Program_Strong_Points') }}</div>
      <app-strong-point-list [strongPoints]="programStrongPoints"></app-strong-point-list>
    </span>

    <div *ngIf="program?.constructionWorkRatio">
      <span>{{ i18nService._('Txt_Placeholder_ConstructionWorkRatio') }} : </span>
      <span class="font-bold">{{ program?.constructionWorkRatio }} %</span>
    </div>
  </div>
</div>

<hr class="hr-gray" />

<div class="program-main-infos-actions-buttons" *ngIf="program?.documents">
  <app-tags
    *ngFor="let actionButton of programActionButtons"
    class="cursor-pointer"
    [ngClass]="actionButton.ngClass"
    [tagOption]="actionButton.tagOption"
    (click)="scrollTo(actionButton.anchor)"
  ></app-tags>
</div>

<div class="program-main-infos-more-link">
  <span
    class="cursor-pointer text-base text-right"
    (click)="scrollTo(anchorEnum.programDescription)"
    [innerHTML]="i18nService._('Txt_page_program_infos_more_infos') | safe: 'html'"
  >
  </span>
</div>
