import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { NgxFileDropModule } from 'ngx-file-drop';

import { RadioCardComponent } from './radio-card/radio-card.component';
import { SharedModule } from '../shared/shared.module';
import { MyStepComponent } from './stepper/stepper/my-step/my-step.component';
import { StepperComponent } from './stepper/stepper/stepper.component';
import { StepHeadTitleComponent } from './stepper/stepper/step-head-title/step-head-title.component';
import { LotDetailStepContextComponent } from './stepper/stepper/step-context-data/lot-detail-step-context/lot-detail-step-context.component';
import { PriceStepContextDataComponent } from './stepper/stepper/step-context-data/price-step-context-data/price-step-context-data.component';
import { StepContextDirective } from './stepper/stepper/step-context-data/step-context.directive';
import { DateComponent } from './date/date.component';
import { InputPhoneComponent } from './input-phone/input-phone.component';
import { FileDropComponent } from './file-drop/file-drop.component';
import { BoxHeaderComponent } from './box-header/box-header.component';
import { LotPriceService } from './services/lot-price.service';
import { FormLinkComponent } from './link/link.component';
import { SmallStepperComponent } from './stepper/small-stepper/small-stepper.component';
import { SmallStepperStepComponent } from './stepper/small-stepper/small-stepper-step/small-stepper-step.component';
import { IconComponent } from '../standalone/icon/icon.component';

import { SignatureService } from './../../signature/services/signature.service';
import { DesignSystemModule } from '../../design-system/design-system.module';
import { FileSystemService } from '../../utils/services/file-system.service';
import { I18nTVALabelPourcentagePipe } from '../../utils/pipes/i18n-tva-pourcentage.pipe';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    DesignSystemModule,
    MatIconModule,
    InputNumberModule,
    InputTextModule,
    FormsModule,
    NgxFileDropModule,
    IconComponent,
    DateComponent,
    RadioCardComponent,
    MyStepComponent,
    StepperComponent,
    StepHeadTitleComponent,
    LotDetailStepContextComponent,
    PriceStepContextDataComponent,
    StepContextDirective,
    InputPhoneComponent,
    FileDropComponent,
    BoxHeaderComponent,
    FormLinkComponent,
    SmallStepperComponent,
    SmallStepperStepComponent,
    I18nTVALabelPourcentagePipe,
  ],
  exports: [
    RadioCardComponent,
    MyStepComponent,
    StepHeadTitleComponent,
    StepperComponent,
    DateComponent,
    InputPhoneComponent,
    FileDropComponent,
    BoxHeaderComponent,
    FormLinkComponent,
    SmallStepperComponent,
    SmallStepperStepComponent,
  ],
  providers: [LotPriceService, SignatureService, FileSystemService],
})
export class AppFormModule {}
