import { Component } from '@angular/core';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';
import { NgClass, NgIf } from '@angular/common';

import { SafePipe } from '../../../utils/pipes/safe.pipe';

@Component({
  selector: 'app-formly-wrapper-inline-label-html',
  template: `
    <div class="p-inline-field" [ngClass]="{ 'p-invisible-field': props.invisibleField }">
      <label *ngIf="props.label && props.hideLabel !== true" [for]="id" class="inline mr-2">
        <span [innerHTML]="props.label | safe: 'html'"></span>
        <span *ngIf="props.required && props.hideRequiredMarker !== true" aria-hidden="true">*</span>
      </label>
      <ng-container #fieldComponent></ng-container>
      <small *ngIf="showError" class="p-error">
        <formly-validation-message class="ui-message-text" [field]="field"></formly-validation-message>
      </small>
    </div>
  `,
  styles: [
    `
      .ui-messages-error {
        margin: 0;
        margin-top: 4px;
      }
    `,
  ],
  standalone: true,
  imports: [NgClass, NgIf, FormlyModule, SafePipe],
})
export class FormlyWrapperInlineLabelHtmlComponent extends FieldWrapper {}
