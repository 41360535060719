import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

import { AbstractComparatorComponent } from '../abstract-comparator-component';

import { LotComparatorDetail } from '../../../lot/model/lot-comparator-detail';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { BasicFormatsService } from '../../../utils/services/basic-formats.service';
import { FileSystemService } from '../../../utils/services/file-system.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { ComparatorStorage } from '../../models/comparator-storage';

@Component({
  selector: 'app-comparator-preview-item',
  templateUrl: './comparator-preview-item.component.html',
  styleUrls: ['./comparator-preview-item.component.scss'],
  standalone: true,
  imports: [NgIf, MatIconModule],
})
export class ComparatorPreviewItemComponent extends AbstractComparatorComponent implements OnInit {
  @Input() lot: LotComparatorDetail;
  @Input() isEmpty?: boolean;
  documentPerspective: string;
  formattedLotPrice: string;
  private _comparatorStorage: ComparatorStorage;

  constructor(
    public basicFormatsService: BasicFormatsService,
    private readonly _fileSystemService: FileSystemService,
    private readonly snackBarService: SnackbarService,
    private readonly _router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this._comparatorPageService
      .getComparatorStorage()
      .pipe(takeUntil(this.$d))
      .subscribe((comparatorStorage) => {
        this._comparatorStorage = comparatorStorage;
        this.lotToCompare = this._comparatorPageService.getComparatorStorageForCurrentUser(comparatorStorage);
      });

    if (this.lot) {
      this.documentPerspective = this._fileSystemService.getLogo(this.lot.perspective);
      this.formattedLotPrice = this.basicFormatsService.formatCurrency(this.lot.commercialLotSellingPriceIT, undefined, 0, 0, 4);
    }
  }

  removeLot(lot: LotComparatorDetail) {
    this._comparatorPageService.removeLotToCompare(this._comparatorStorage, lot.id);
    this._comparatorPageService.pushToDatalayerDropOneItem();
    if (!this.lotToCompare.length) {
      this.snackBarService.sendMessage({
        type: SnackbarMessageType.Info,
        text: this.i18nService._('Txt_comparator_empty_sucess'),
      });
    }
  }
}
