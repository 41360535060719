import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';

import { HighlightResponse } from '../models/HighlightResponse';

import { AppConfigService } from '../../utils/services/app-config.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class HighlightService {
  readonly highlightApi = '/Highlights';

  constructor(
    public http: HttpClient,
    public errorHandlerService: ErrorHandlerService,
    public appConfig: AppConfigService,
  ) {}

  getHighlighs(): Observable<Array<HighlightResponse>> {
    return this.http
      .get<Array<HighlightResponse>>(this.appConfig.getLoopbackApiUrl() + this.highlightApi)
      .pipe(catchError(this.errorHandlerService.handleError<Array<HighlightResponse>>('highlightService', 'getHighlighs')));
  }
}
