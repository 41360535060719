import { IsObject } from 'class-validator';

import { ProspectResponse } from '../prospect';

export class SearchDossiersResponse {
  nbItems: number;
  items: SearchDossierResponse[];
}

export class SearchDossierResponse {
  id: number;

  @IsObject()
  prospects: ProspectResponse[];
}
