import { ActivatedRoute } from '@angular/router';
import { Component, Input } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';

import { GridTrackingTableComponent } from '../grid-tacking-table/grid-tracking-table.component';

import { ProgramFinancialStrategiesTrackingTableService } from './../../services/program-financial-strategies-tracking-table.service';
import { TableLoader, TableResponse } from './../../../table/interfaces/DataLoader';
import { ProgramGeneratedFeesAmounts } from './../../../utils/models/ProgramGeneratedFeesAmounts';
import { PriceRendererComponent } from './../../renderers/price-renderer/price-renderer.component';
import { I18nService } from './../../../utils/services/i18n.service';

@Component({
  selector: 'app-program-financial-stategy-tracking-table',
  templateUrl: './program-financial-stategy-tracking-table.component.html',
  styleUrls: ['./program-financial-stategy-tracking-table.component.scss'],
  standalone: true,
  imports: [GridTrackingTableComponent],
})
export class ProgramFinancialStrategiesTrackingTableComponent {
  @Input() programId: string;

  pageSize = 10;
  pageNumber = 1;
  tableHeight = 'auto';
  serverSide = false;
  gridOptions: GridOptions = {
    headerHeight: 45,
    rowHeight: 60,
    paginationPageSize: 90,
    paginationPageSizeSelector: false,
    suppressCellFocus: true,
    rowModelType: 'clientSide',
    domLayout: 'autoHeight',
  };
  columnDefs: ColDef[];
  tableLoader: TableLoader<ProgramGeneratedFeesAmounts>;

  constructor(
    private readonly i18nService: I18nService,
    private readonly programFinancialStrategiesTrackingTableService: ProgramFinancialStrategiesTrackingTableService,
    private readonly route: ActivatedRoute,
  ) {
    const programId = this.route.snapshot.paramMap.get('programId');
    this.tableLoader = {
      async get(): Promise<TableResponse<ProgramGeneratedFeesAmounts>> {
        const mandateStrategry: ProgramGeneratedFeesAmounts =
          await programFinancialStrategiesTrackingTableService.getProgramMandateStrategies(programId);
        return { items: [mandateStrategry] };
      },
    };

    this.columnDefs = [
      {
        field: 'preReservationsFees',
        headerName: i18nService._('Title_FinancialStrategyFeesAmountsTable_PreReservations'),
        cellRenderer: PriceRendererComponent,
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'reservationsFees',
        headerName: i18nService._('Title_FinancialStrategyFeesAmountsTable_Reservations'),
        cellRenderer: PriceRendererComponent,
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'salesFees',
        headerName: i18nService._('Title_FinancialStrategyFeesAmountsTable_Sales'),
        cellRenderer: PriceRendererComponent,
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'totalFees',
        headerName: i18nService._('Title_FinancialStrategyFeesAmountsTable_Total'),
        cellRenderer: PriceRendererComponent,
        minWidth: 80,
        flex: 3,
      },
    ];
  }
}
