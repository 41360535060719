/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AbstractSearchFilter } from '../abstract-search-filter';

import { ReferenceTableData } from '../../../../utils/models/ReferenceTableData';
import { TaxationResponse } from '../../../../utils/models/TaxationResponse';
import { I18nService } from '../../../../utils/services/i18n.service';
import { ReferenceTablesService } from '../../../../utils/services/reference-tables.service';
import { SnackbarService } from '../../../../utils/services/snackbar.service';
import { SearchFilterOption } from '../../../model/search-filter-option';

@Component({
  selector: 'app-search-filter-select',
  templateUrl: './search-filter-select.component.html',
  styleUrls: ['./search-filter-select.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    NgClass,
    NgIf,
    MatIconModule,
    MatTooltipModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    NgFor,
  ],
})
export class SearchFilterSelectComponent extends AbstractSearchFilter {
  public tableItems: ReferenceTableData[];
  public selectFormControl: UntypedFormControl;
  @Input() public filterSelectOption: SearchFilterOption;
  public isDisable: boolean;

  constructor(
    public readonly i18nService: I18nService,
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly snackbarService: SnackbarService,
  ) {
    super();
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    this.selectFormControl = new UntypedFormControl();
    if (this.filterSelectOption.disabled) {
      this._searchFormUtilsService.checkDisableSubject.pipe(takeUntil(this.$d)).subscribe((where) => {
        this.isDisable = this.filterSelectOption.disabled(where);
        this.isDisable ? this.selectFormControl.disable() : this.selectFormControl.enable();
      });
    }
    if (this.filterSelectOption.referenceTable) {
      this.getReferenceTableItems();
    } else {
      this.tableItems = this.filterSelectOption.items;
      super.ngOnInit();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  selectedItem() {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected initData() {}

  private getReferenceTableItems() {
    const referenceTable = this.filterSelectOption.referenceTable;
    this.referenceTablesService.getTableReferenceInfo(referenceTable.referenceTableNames).subscribe(
      (items: Array<TaxationResponse>) => {
        this.tableItems = items
          .filter((item: any) => referenceTable.filter(item))
          .map((value) => {
            return {
              id: value.id,
              label: value.label,
            };
          });

        super.ngOnInit();
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (errInfoFound) => {
        this.snackbarService.sendErrorOccured();
      },
    );
  }
}
