<div class="grid grid-cols-3 w-full gap-4 xl:gap-8">
  <ng-container *ngFor="let card of data">
    <div
      (blur)="onBlur($event)"
      (change)="onChange($event)"
      (click)="onRadioCardClick(card)"
      (focus)="onFocus($event)"
      [ngClass]="[card.isChecked ? 'border-orange-500' : 'border-white', card.className + '']"
      class="relative group bg-white shadow-custom h-48 rounded-10 border-2 cursor-pointer col-span-3 lg:col-span-1"
    >
      <div class="card-content h-full flex flex-col items-center justify-center p-2">
        <div class="h-12 w-12 text-orange-500 m-3">
          <app-icon [name]="card.icon" size="3rem" class="w-full"></app-icon>
        </div>
        <h2 class="text-xl leading-7 font-bold text-center">{{ card[labelProp] }}</h2>
      </div>
      <app-tooltip *ngIf="tooltip" class="absolute top-full w-full z-10 hidden group-hover:block"> {{ tooltip }}</app-tooltip>
    </div>
  </ng-container>
</div>
