import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { ValoRole } from '@commons-dto/valo-back';
import { MatTabsModule } from '@angular/material/tabs';

import { PublicationStrategiesDialogComponent } from './publication-strategies-dialog/publication-strategies-dialog.component';
import { RegisterFormFinancialStrategyComponent } from '../register-form-financial-strategy/register-form-financial-strategy.component';
import { RegisterFormMandateStrategyComponent } from '../register-form-mandate-strategy/register-form-mandate-strategy.component';

import { ActionButtonStategieService } from '../../../utils/components/sticky-header-form/action-button-stategie/service/action-button-stategie.service';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { Sitemap } from '../../../utils/models/Sitemap';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SharedProgramService } from '../../../utils/services/shared-program.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { UserRoleService } from '../../../utils/services/user-role.service';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';

@Component({
  selector: 'app-strategies',
  templateUrl: './strategies.component.html',
  styleUrls: ['./strategies.component.scss'],
  standalone: true,
  imports: [StickyHeaderFormComponent, MatTabsModule, RegisterFormMandateStrategyComponent, RegisterFormFinancialStrategyComponent],
})
export class StrategiesComponent implements OnInit, OnDestroy {
  /**
   * Path to programs dashboard route.
   *
   * @type {string}
   * @memberof RegisterFormProgramComponent
   */
  readonly programsDashboardPath = Sitemap.programs.admin.path;

  /**
   * isPublishButtonDisabled attribute
   *
   * @type {boolean}
   * @memberof StrategiesComponent
   */
  public isPublishButtonDisabled: boolean;
  /**
   * programId attribute
   *
   * @type {number}
   * @memberof StrategiesComponent
   */
  public programId: number;
  /**
   * publishedButtonTooltipText attribute
   *
   * @type {string}
   * @memberof StrategiesComponent
   */
  public publishedButtonTooltipText: string;
  /**
   * Program Status Label
   *
   * @type {string}
   * @memberof StrategiesComponent
   */
  public programStatusLabel: string;
  /**
   * Program Status Label
   *
   * @type {string}
   * @memberof StrategiesComponent
   */
  public unpublicationOriginLabel: string;
  /**
   * isPublished
   *
   * @type {boolean}
   * @memberof StrategiesComponent
   */
  public isPublished: boolean;
  /**
   * Hide or show isPublishButton
   *
   * @type {boolean}
   * @memberof StrategiesComponent
   */
  public isPublishButtonVisible: boolean;
  /**
   * Program Name
   *
   * @type {string}
   * @memberof StrategiesComponent
   */
  public programName = '';
  /**
   * Index of active tab, get from url query param tabMandat
   *
   * @type {number}
   * @memberof StrategiesComponent
   */
  public activeTab: number;
  public isActionButtonVisibleStategie = true;
  readonly: boolean;
  /**
   * isFinancialStrategy attribute
   *
   * @type {boolean}
   * @memberof StrategiesComponent
   */
  private isFinancialStrategy: boolean;
  /**
   * mandateStrategies attribute
   *
   * @type {Array<MandatStrategyResponse>}
   * @memberof StrategiesComponent
   */
  numberOfmandateStrategies: number;
  private _readonlyRoles: Array<ValoRole>;
  private readonly _planningSubscription: Subscription = new Subscription();
  private scheduledPublicationDate: string;

  /**
   * Creates an instance of StrategiesComponent.
   * @param {Router} router
   * @param {ActivatedRoute} route
   * @param {I18nService} i18nService
   * @param {SharedProgramService} sharedProgramService
   * @param {SnackbarService} snackbarService
   * @param appConfig
   * @memberof StrategiesComponent
   */
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public i18nService: I18nService,
    private readonly sharedProgramService: SharedProgramService,
    private readonly snackbarService: SnackbarService,
    public appConfig: AppConfigService,
    private readonly _userRoleService: UserRoleService,
    public actionButtonStategieService: ActionButtonStategieService,
    private readonly dialog: MatDialog,
  ) {
    this.isPublishButtonDisabled = true;
    this.publishedButtonTooltipText = this.i18nService._('Txt_Tooltip_CompleteCommercialStrategies');
    this.isFinancialStrategy = false;
    this.programStatusLabel = undefined;
    this.unpublicationOriginLabel = undefined;
    this.isPublishButtonVisible = true;
  }

  /**
   * Init component
   *
   * @memberof StrategiesComponent
   */
  public ngOnInit(): void {
    this._readonlyRoles = [ValoRole.valoRcvSimple, ValoRole.valoBoSuper, ValoRole.valoBoSimple];
    this.readonly = this._userRoleService.isInRoles(this._readonlyRoles);
    // get the programId in url
    this.programId = parseInt(this.route.snapshot.paramMap.get('programId'), 10);
    this.activeTab = parseInt(this.route.snapshot.queryParamMap.get('tabMandat'), 10);

    // Check program id validity
    if (!this.programId) {
      // Redirection to 404 because no program id in url
      this.errorRedirection();
    } else {
      // Get only needed information for publish button
      this.sharedProgramService.getProgramOnlyProgramStatusAndUnpublicationOrigin(this.programId).subscribe(
        (programStatusAndUnpublicationOrigin) => {
          if (programStatusAndUnpublicationOrigin) {
            this.programStatusLabel = programStatusAndUnpublicationOrigin.programStatus.label;
            this.scheduledPublicationDate = programStatusAndUnpublicationOrigin.scheduledPublicationDate;
            if (programStatusAndUnpublicationOrigin.unpublicationOriginId) {
              this.unpublicationOriginLabel = programStatusAndUnpublicationOrigin.unpublicationOrigin.label;
            }
          }
          this.programName = programStatusAndUnpublicationOrigin.programName;
          this.isPublished = this.programStatusLabel === this.appConfig.getStatus().published;
          this.checkIsPublishButtonVisible();
        },
        (error) => {
          this.snackbarService.sendMessage(error);
        },
      );
    }

    this._planningSubscription.add(
      this.actionButtonStategieService.$emitPublicationStrategies.subscribe((result) => {
        if (result) {
          this.schedulePublicationAction();
        } else {
          this.onPublishButtonClicked();
        }
      }),
    );
  }

  /**
   * onFinancialStrategyChange method
   *
   * @memberof StrategiesComponent
   */
  public onFinancialStrategyChange(isValid: boolean): void {
    this.isFinancialStrategy = isValid;
    this.verifyIsPublishButtonDisabled();
  }

  /**
   * onMandateStrategiesChange method
   *
   * @memberof StrategiesComponent
   */
  public onMandateStrategiesChange(event: number): void {
    this.numberOfmandateStrategies = event;
    this.verifyIsPublishButtonDisabled();
  }

  /**
   * onPublishButtonClicked method
   *
   * @memberof StrategiesComponent
   */
  public onPublishButtonClicked(): void {
    if (!this.isPublishButtonDisabled) {
      if (this.isPublished) {
        this.snackbarService.sendMessage({
          text: this.i18nService._('Error_SnackBar_AlreadyPublishedProgram'),
          type: SnackbarMessageType.Error,
        });
      } else {
        // Publish valid program, need to check strategies
        if (this.programStatusLabel === this.appConfig.getStatus().valid) {
          this.sharedProgramService.publishProgram(this.programId).subscribe(
            () => {
              this.successPublish(this.i18nService._('Success_SnackBar_ProgramPublished'));
            },
            ({ error }) => {
              if (error.error.code === 'ERROR_PROGRAM_INCOMPLETE_LOTS') {
                const initHtmlTagList = '\n <ul> \n';
                const endHtmlTagList = '</ul>';
                let lotsNumbersWithoutCompleteStatusHtmlTag = initHtmlTagList;
                let failedLots;

                try {
                  failedLots = JSON.parse(error.error.message);
                } catch (error) {
                  this.snackbarService.sendMessage({
                    type: SnackbarMessageType.Error,
                    text: this.i18nService._('Error_SnackBar_FormIncomplete'),
                  });

                  return;
                }

                failedLots.forEach((lotNotComplete) => {
                  lotsNumbersWithoutCompleteStatusHtmlTag = `${lotsNumbersWithoutCompleteStatusHtmlTag} <li> ${lotNotComplete} </li> \n`;
                });

                const errorMessageHTML = `<span> ${this.i18nService._('Error_SnackBar_lotsAreNotComplete_Publish')} </span>`;

                // => <ul> <li> A56 </li> </ul>
                lotsNumbersWithoutCompleteStatusHtmlTag = lotsNumbersWithoutCompleteStatusHtmlTag.concat(endHtmlTagList);

                this.snackbarService.message(
                  errorMessageHTML.concat(lotsNumbersWithoutCompleteStatusHtmlTag),
                  SnackbarMessageType.Error,
                  false,
                  [],
                  true,
                );

                return;
              }

              this.snackbarService.sendErrorOccured();
            },
          );
        }
      }
      // Publish unpublished program, no need to check strategies
      if (this.programStatusLabel === this.appConfig.getStatus().unpublished) {
        this.sharedProgramService.publishUnpublishProgram(this.programId, true).subscribe(
          (successPublish: boolean) => {
            if (successPublish) {
              this.successPublish(this.i18nService._('Info_SnackBar_ProgramAndLotsPublished'));
            }
          },
          () => {
            this.snackbarService.sendErrorOccured();
          },
        );
      }
    }
  }

  ngOnDestroy() {
    this._planningSubscription.unsubscribe();
  }

  private schedulePublicationAction() {
    const dialogData: MatDialogConfig = {
      data: this.scheduledPublicationDate ? DateTime.fromISO(this.scheduledPublicationDate) : false,
      panelClass: 'custom-dialog-overflow-visible',
    };
    const dialogRef = this.dialog.open(PublicationStrategiesDialogComponent, dialogData);
    dialogRef.afterClosed().subscribe((response) => {
      if (response && !this.isPublishButtonDisabled) {
        const datePlanning = new Date(response.planningDate);
        datePlanning.setHours(response.planningHourId);
        this.sharedProgramService.planningPublishProgram(this.programId, datePlanning).subscribe(
          () => {
            this.successPublish(this.i18nService._('Success_SnackBar_ProgramPlanning'));
          },
          () => {
            this.snackbarService.sendMessage({
              type: SnackbarMessageType.Error,
              text: this.i18nService._('Error_SnackBar_FormIncomplete'),
            });

            return;
          },
        );
      }
    });
  }

  /**
   * errorRedirection method
   *
   * @private
   * @memberof StrategiesComponent
   */
  private errorRedirection(): void {
    this.router.navigate([Sitemap.utils.notFound.path]);
  }

  /**
   * verifyIsPublishButtonDisabled method
   *
   * @private
   * @memberof StrategiesComponent
   */
  verifyIsPublishButtonDisabled(): void {
    if (this.programStatusLabel === this.appConfig.getStatus().valid) {
      this.isPublishButtonDisabled = !(this.numberOfmandateStrategies && this.isFinancialStrategy);
      this.publishedButtonTooltipText = this.isPublishButtonDisabled
        ? this.i18nService._('Txt_Tooltip_CompleteCommercialStrategies')
        : undefined;
    }
  }

  /**
   * Hide or show isPublish button according to program Status and unpublication Origin
   *
   * @private
   * @memberof StrategiesComponent
   */
  private checkIsPublishButtonVisible(): void {
    // Show and enable isPublish button for unpublished from MMI program
    if (
      this.programStatusLabel === this.appConfig.getStatus().unpublished &&
      this.unpublicationOriginLabel === this.appConfig.appConfig.unpublicationOrigin.MMI
    ) {
      this.isPublishButtonDisabled = false;
      this.publishedButtonTooltipText = undefined;
      this.isPublishButtonVisible = true;
    }
    // Hide isPublish button for published and unpublished from XML program
    if (
      this.programStatusLabel === this.appConfig.getStatus().published ||
      (this.programStatusLabel === this.appConfig.getStatus().unpublished &&
        this.unpublicationOriginLabel === this.appConfig.appConfig.unpublicationOrigin.XML)
    ) {
      this.isPublishButtonVisible = false;
    }
  }

  /**
   * Actions to do on publish success
   *
   * @private
   * @memberof StrategiesComponent
   */
  private successPublish(infoMessage: string): void {
    this.snackbarService.sendMessage({
      text: infoMessage,
      type: SnackbarMessageType.Info,
    });
    this.isPublished = true;
    this.router.navigate([this.programsDashboardPath]);
  }
}
