/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { isNil } from 'lodash';

import { AttachmentUploadData } from '../models/AttachmentUploadData';
import { AppConfigService } from './app-config.service';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  /**
   * Creates an instance of AttachmentService.
   * @param {HttpClient} http
   * @param {ErrorHandlerService} errorHandlerService
   * @param {AppConfigService} appConfig
   * @memberof AttachmentService
   */
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
  ) {}

  /**
   * uploadCompanyCreationDocuments
   *
   * @param {Array<AttachmentUploadData>} attachments
   * @param {string} dockerName
   * @param {string} commercialeName
   * @returns {Observable<any>}
   * @memberof AttachmentService
   */
  uploadCompanyCreationDocuments(attachments: Array<AttachmentUploadData>, dockerName: string, commercialeName: string): Observable<any> {
    return this.uploadFiles(attachments, dockerName, new HttpParams().set('commercialeName', commercialeName));
  }

  /**
   * uploadCompanyCreationDocuments in public mode
   *
   * @param {Array<AttachmentUploadData>} attachments
   * @param {string} dockerName
   * @param {string} commercialeName
   * @param {string} recaptchatoken
   * @returns {Observable<any>}
   * @memberof AttachmentService
   */

  uploadCompanyCreationDocumentsPublic(
    attachments: Array<AttachmentUploadData>,
    dockerName: string,
    commercialeName: string,
    recaptchatoken: string,
  ): Observable<any> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Containers/${dockerName}/public-upload`;
    const formData = new FormData();
    /* Pass documentType in original file name */
    for (const attachment of attachments) {
      formData.append(attachment.documentType, attachment.file);
    }
    const httpOptions = {
      params: new HttpParams().set('commercialeName', commercialeName),
      headers: new HttpHeaders({ recaptchatoken }),
    };

    const defaultErrorFn = this.errorHandlerService.errorFnFactory.default();

    return this.http.post(url, formData, httpOptions).pipe(
      catchError(
        this.errorHandlerService.handleError<any>('AttachmentsService', 'uploadFiles', {
          401: defaultErrorFn,
          403: defaultErrorFn,
        }),
      ),
    );
  }

  /**
   * uploadProgramDocumentsFiles
   *
   * @param {Array<AttachmentUploadData>} attachments
   * @param {string} dockerName
   * @returns {Observable<any>}
   * @memberof AttachmentService
   */
  uploadProgramDocumentsFiles(attachments: Array<AttachmentUploadData>, dockerName: string, programRef: string): Observable<any> {
    return this.uploadFiles(attachments, dockerName, new HttpParams().set('programRef', programRef));
  }

  /**
   * uploadLotDocumentsFiles
   *
   * @param {Array<AttachmentUploadData>} attachments
   * @param {string} dockerName
   * @returns {Observable<any>}
   * @memberof AttachmentService
   */
  uploadLotDocumentsFiles(
    attachments: Array<AttachmentUploadData>,
    dockerName: string,
    lotRef: string,
    programId: number,
  ): Observable<any> {
    const params = new HttpParams().set('lotRef', lotRef).set('programId', programId.toString());

    return this.uploadFiles(attachments, dockerName, params);
  }

  /**
   * uploadLotDocumentsFiles
   *
   * @param {Array<AttachmentUploadData>} attachments
   * @param {string} dockerName
   * @returns {Observable<any>}
   * @memberof AttachmentService
   */
  uploadReservationDocumentsFiles(
    attachments: Array<AttachmentUploadData>,
    dockerName: string,
    lotId: number,
    prospectId: number,
    dossierProspectId: number,
  ): Observable<any> {
    let params = new HttpParams().set('lotId', lotId.toString());

    if (!isNil(prospectId)) params = params.set('prospectId', prospectId.toString());
    else params = params.set('dossierProspectId', dossierProspectId.toString());

    return this.uploadFiles(attachments, dockerName, params);
  }

  /**
   * uploadFiles method
   *
   * @param {Array<AttachmentUploadData>} files
   * @param {string} dockerName
   * @param {string} customUrl
   * @returns
   * @memberof AttachmentService
   */
  uploadFiles(attachments: Array<AttachmentUploadData>, dockerName: string, params: HttpParams): Observable<any> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Containers/${dockerName}/upload`;

    if (!attachments.length) {
      return of(undefined);
    }

    const formData = new FormData();

    /* Pass documentType in original file name */
    for (const attachment of attachments) {
      formData.append(attachment.documentType, attachment.file);
    }

    return this.http.post(url, formData, { params }).pipe(
      // The redirection on errors 401 and 403 are voluntarily cancelled.
      // No need to redirect to the login page or homepage because the user is already not logged in.
      catchError(
        this.errorHandlerService.handleError<any>('AttachmentsService', 'uploadFiles', {
          401: this.errorHandlerService.errorFnFactory[401]({
            snackbar: true,
            snackbarMessage: 'Error_SnackBar_AttachmentSendingFailed',
            snackbarI18n: true,
            redirect: false,
          }),
          403: this.errorHandlerService.errorFnFactory[403]({
            snackbar: true,
            snackbarMessage: 'Error_SnackBar_AttachmentSendingFailed',
            snackbarI18n: true,
            redirect: false,
          }),
        }),
      ),
    );
  }
}
