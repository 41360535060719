import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { SearchPanelTypeEnum } from '../../model/search-panel-type-enum';

export type BehaviorData = {
  [key in SearchPanelTypeEnum]?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class FilterUtilsService {
  private readonly openPanelSubject: BehaviorSubject<BehaviorData>;
  public $openPanel: Observable<BehaviorData>;

  private readonly _searchPanelTypeEnumKeys = Object.keys(SearchPanelTypeEnum);
  private readonly _panelStates: BehaviorData = {};

  constructor() {
    this._searchPanelTypeEnumKeys.forEach((value) => (this._panelStates[value] = false));
    this.openPanelSubject = new BehaviorSubject<BehaviorData>(this._panelStates);
    this.$openPanel = this.openPanelSubject.asObservable();
  }

  showPanel(type: SearchPanelTypeEnum, show: boolean) {
    this._searchPanelTypeEnumKeys.forEach((value) => {
      this._panelStates[value] = value === type && show;
    });

    this.openPanelSubject.next(this._panelStates);
  }

  hidePanel(type: SearchPanelTypeEnum) {
    this._panelStates[type] = false;
    this.openPanelSubject.next(this._panelStates);
  }

  hideAll() {
    this._searchPanelTypeEnumKeys.forEach((value) => {
      this._panelStates[value] = false;
    });
  }
}
