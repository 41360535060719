import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonModule } from '@angular/common';

import { I18nService } from '../../../utils/services/i18n.service';
import { IconComponent } from '../../../common/standalone/icon/icon.component';

@Component({
  selector: 'app-button-renderer',
  templateUrl: './button-renderer.component.html',
  styleUrls: ['./button-renderer.component.scss'],
  standalone: true,
  imports: [IconComponent, CommonModule],
})
export class ButtonRendererComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams;

  constructor(public readonly i18nService: I18nService) {}

  async agInit(params: ICellRendererParams): Promise<void> {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
