import { Component, Input } from '@angular/core';
import { ArrayFilterCallbackFn, UtilsModule } from 'apps/valo-front/src/app/utils/utils.module';
import { SecondaryLotTypeEnum } from '@commons-dto/valo-back';
import { PipesModule } from 'apps/valo-front/src/app/utils/pipes/pipes.module';

import { AbstractSearchFilter } from '../abstract-search-filter';

@Component({
  standalone: true,
  selector: 'app-search-filter-annexes',
  templateUrl: './search-filter-annexes.component.html',
  styleUrls: ['./search-filter-annexes.component.scss'],
  imports: [UtilsModule, PipesModule],
})
export class SearchFilterAnnexesComponent extends AbstractSearchFilter {
  constructor() {
    super();
  }

  @Input()
  annexeType: SecondaryLotTypeEnum;

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    super.ngOnInit();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected initData() {}

  getSecondaryLotTypeIdsByAnnexeType(): Array<number> {
    return this.annexeType === SecondaryLotTypeEnum.PARKING
      ? this.search.parkingSecondaryLotTypeIds
      : this.search.storageSecondaryLotTypeIds;
  }

  getSecondaryLotTypeIdsField(): string {
    return this.annexeType === SecondaryLotTypeEnum.PARKING ? 'parkingSecondaryLotTypeIds' : 'storageSecondaryLotTypeIds';
  }

  getFilterMethod(fieldName: string): ArrayFilterCallbackFn {
    return super.getFilterMethod(fieldName, this.annexeType);
  }
}
