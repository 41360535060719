import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';

import { DataLayerApiService } from './data-layer-api.service';
import { AreaTypeResponse } from '../utils/models/AreaTypeResponse';

@Injectable({ providedIn: 'root' })
export class AreaTypeApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Get areaTypes
   *
   * @returns {TaxZoneResponse[]}
   * @memberof DataLayerApiService
   */
  async getAreaTypes(): Promise<AreaTypeResponse[]> {
    const path = this.BASE_URL + 'AreaTypes';
    const res: Observable<AreaTypeResponse[]> = await this.get(path);
    return await lastValueFrom(res);
  }
}
