/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';

import { OptionResponse } from '../models/OptionResponse';
import { PoseoptionResponse } from '../models/PoseoptionResponse';
import { ProspectResponse } from '../models/ProspectResponse';
import { AppConfigService } from './app-config.service';
import { ErrorHandlerService } from './error-handler.service';

import { ActionOnOptionResponse } from '../../utils/models/ActionOnOptionResponse';

@Injectable({
  providedIn: 'root',
})
export class PoseOptionsService {
  optionModifResponse: OptionResponse;

  constructor(
    public http: HttpClient,
    public errorHandlerService: ErrorHandlerService,
    public appConfig: AppConfigService,
  ) {}

  getActionOnOption(lotId: number): Observable<ActionOnOptionResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Options/getActionOnOption/${lotId}`;

    return this.http
      .get<ActionOnOptionResponse>(url)
      .pipe(catchError(this.errorHandlerService.handleError<ActionOnOptionResponse>('lotService', 'getActionOnOption')));
  }

  checkValidity(lotId: number): Observable<any> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Options/checkValidity/${lotId}`;

    return this.http.get<any>(url).pipe(catchError(this.errorHandlerService.handleError<any>('lotService', 'checkValidity')));
  }

  removeOption(lotId: number): Observable<PoseoptionResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Options/removeOption/${lotId}`;

    return this.http
      .post<PoseoptionResponse>(url, undefined)
      .pipe(catchError(this.errorHandlerService.handleError<PoseoptionResponse>('PoseOptionsService', 'removeOption')));
  }

  extendOption(lotId: number): Observable<PoseoptionResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Options/extendOption/${lotId}`;

    return this.http
      .post<PoseoptionResponse>(url, undefined)
      .pipe(catchError(this.errorHandlerService.handleError<PoseoptionResponse>('PoseOptionsService', 'extendedOption')));
  }

  acceptOrRejectOption(optionId: number, accept: boolean): Observable<PoseoptionResponse> {
    const action = accept ? 'acceptOption' : 'rejectOption';
    const url = `${this.appConfig.getLoopbackApiUrl()}/Options/${action}/${optionId}`;

    return this.http
      .post<PoseoptionResponse>(url, undefined)
      .pipe(catchError(this.errorHandlerService.handleError<PoseoptionResponse>('PoseOptionsService', 'acceptOrRejectOption')));
  }

  getUserProspectByLotId(lotId: number): Observable<ProspectResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Options/getUserProspectByLotId/${lotId}`;

    return this.http.get<any>(url).pipe(catchError(this.errorHandlerService.handleError<any>('lotService', 'getUserProspectByLotId')));
  }
}
