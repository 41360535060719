import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Directive, HostListener } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

@Directive({
  selector: '[appMatVerticalStepper]',
  standalone: true,
})
export class MatVerticalStepperScrollerDirective {
  constructor(private readonly stepper: MatStepper) {}

  @HostListener('selectionChange', ['$event']) onSelectionChange(selection: StepperSelectionEvent): void {
    const stepId = this.stepper._getStepLabelId(selection.selectedIndex - 1);
    const stepElement = document.getElementById(stepId);
    if (stepElement) {
      setTimeout(() => {
        stepElement.scrollIntoView({
          block: 'end',
          inline: 'nearest',
          behavior: 'smooth',
        });
      }, 250);
    }
  }
}
