import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';

import { SpecialOfferService } from '../../special-offer/service/special-offer.service';

/* eslint-disable */
export class ReferenceSpecialOfferAsyncValidator {
  public static referenceSpecialOfferExist = (specialOfferService: SpecialOfferService): AsyncValidatorFn => {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return new Observable((observer) => {
        const parentControl = control.parent;
        if (!parentControl) {
          observer.next(null);
          observer.complete();
        } else {
          const company = parentControl.controls['company'].value;
          const refSpecialOffer = parentControl.controls['specialOfferRef'].value;
          if (company && company instanceof Object && refSpecialOffer) {
            specialOfferService.checkIfDevelopersRefSpecialOfferExist(refSpecialOffer, company.id).subscribe(
              (valid) => {
                if (valid) {
                  observer.next({ specialOfferRefExist: true });
                } else {
                  observer.next();
                }
                observer.complete();
              },
              (err) => {
                observer.error(err);
                observer.complete();
              },
            );
          } else {
            observer.next();
            observer.complete();
          }
        }
      });
    };
  };
}
