/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import { allScriptStoreInApp } from './script.store';

declare let document: any;

@Injectable()
export class ScriptService {
  private readonly scripts: any = {};

  constructor() {
    allScriptStoreInApp.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }

  async load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));

    return Promise.all(promises);
  }

  async loadScript(name: string): Promise<{ script: string; loaded: boolean; status: string }> {
    return new Promise((resolve, _reject) => {
      // resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = undefined;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          // Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (_error: any) => {
          resolve({ script: name, loaded: false, status: 'Loaded' });

          return;
        };
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}
