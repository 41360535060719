import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';

import { PhoneNumberInformationComponent } from '../../../utils/components/phone-number-information/phone-number-information.component';
import { atLeastOne } from '../../../utils/validators/atLeastOne-validator.directive';
import { isEmailValidators } from '../../../utils/validators/is-email-validator.directive';
import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-paternity-verification-dialog',
  templateUrl: './paternity-verification-dialog.component.html',
  styleUrls: ['./paternity-verification-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    PhoneNumberInformationComponent,
    MatButtonModule,
  ],
})
export class PaternityVerificationDialogComponent implements OnInit {
  /**
   * Form Group for check paternity
   *
   * @private
   * @type {FormGroup}
   * @memberof PaternityVerificationDialogComponent
   */
  public checkPaternityFormGroup: UntypedFormGroup;

  /**
   * Email Control
   *
   * @type {FormControl}
   * @memberof PaternityVerificationDialogComponent
   */
  public emailControl: FormControl;

  public isSubmit: boolean;

  constructor(
    public readonly i18nService: I18nService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly dialogRef: MatDialogRef<PaternityVerificationDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.checkPaternityFormGroup = this.formBuilder.group({
      email: ['', [isEmailValidators()]],
    });

    this.emailControl = this.checkPaternityFormGroup.controls.email as FormControl;
    this.checkPaternityFormGroup.setValidators(atLeastOne(['email', 'phone']));
  }

  check(): void {
    this.isSubmit = true;
    this.checkPaternityFormGroup.updateValueAndValidity();

    if (this.checkPaternityFormGroup.valid) {
      this.dialogRef.close(this.checkPaternityFormGroup.value);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
