import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgIf } from '@angular/common';

import { LoginAsComponent } from '../login-as/login-as.component';

import { GetAccountInfosResponse } from '../../../accounts/models/getAccountInfosResponse';
import { SnackbarMessageType } from '../../models/enums/snackbar-message-type.enum';
import { Sitemap } from '../../models/Sitemap';
import { SnackbarMessage } from '../../models/SnackbarMessage';
import { AccountAsService } from '../../services/account-as.service';
import { AccountService } from '../../services/account.service';
import { TokenService } from '../../services/authorisation/token.service';
import { I18nService } from '../../services/i18n.service';
import { SnackbarService } from '../../services/snackbar.service';
import { SpinnerWithBackdropService } from '../../services/spinner-with-backdrop.service';
import { FeatureFlagService } from '../../../feature-flag/feature-flag.service';
import { SentryService } from '../../../utils/services/sentry.services';

@Component({
  selector: 'app-login-as-form',
  templateUrl: './login-as-form.component.html',
  styleUrls: ['./login-as-form.component.scss'],
  standalone: true,
  imports: [NgIf, LoginAsComponent, MatButtonModule, MatIconModule, NgClass],
})
export class LoginAsFormComponent implements OnInit {
  @ViewChild('searchAccount') searchAccount: LoginAsComponent;

  public parentForm: UntypedFormGroup;
  public formBuilder: UntypedFormBuilder = new UntypedFormBuilder();

  /**
   * Form attribut of the component
   *
   * @type {Array<number>}
   *
   * @memberof LoginAsFormComponent
   */
  public selectedAccountId: Array<number> = [];
  public isConnectionAs: boolean;
  public connectedUser: GetAccountInfosResponse;
  public connectedUserLabel: string;
  public isNewHomepage = false;

  constructor(
    public i18nService: I18nService,
    public accountAsService: AccountAsService,
    public tokenService: TokenService,
    public accountService: AccountService,
    private readonly router: Router,
    private readonly spinnerWithBackdropService: SpinnerWithBackdropService,
    private readonly snackbarService: SnackbarService,
    private readonly featureFlag: FeatureFlagService,
    private readonly sentryService: SentryService,
  ) {}

  ngOnInit(): void {
    this.parentForm = this.formBuilder.group({});
    // We're checking to see if we're in "connect as" mode.
    this.isConnectionAs = this.tokenService.isConnectionAs();
    // If yes, we get the information of the connected user (like firstname, etc)
    if (this.isConnectionAs) {
      this.setConnectedUser();
    }

    this.featureFlag.isEnabled('new-homepage').then((isNewHomepage) => {
      if (isNewHomepage) {
        this.isNewHomepage = isNewHomepage;
      }
    });
  }

  /**
   * Function called after a click on the login/logout button.
   * Depending on the connection status "as", we ask to log on or log off the user.
   */
  public logInLogOutAs(): void {
    if (this.isConnectionAs) {
      this.logoutAs();
    } else if (this.selectedAccountId[0]) {
      this.loginAs();
    } else {
      this.searchAccount.chipList.errorState = true;
    }
  }

  public isHomepage(): boolean {
    return this.router.url === `/${Sitemap.utils.homepage.path}`;
  }

  /**
   * Login As : Clear input value after click en clear button
   */
  public clearConnectedUserLabel(): void {
    this.searchAccount.mapListFilteredItems.clear();
    this.searchAccount.resetSeletedValues();
    this.selectedAccountId = [];
  }

  /**
   * Call the accountService to get data for the connected user.
   */
  private async setConnectedUser(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.accountService.getAccount(this.tokenService.getUserId()).subscribe((account) => {
        this.connectedUser = account;
        this.connectedUserLabel = `${this.connectedUser.firstName} ${this.connectedUser.lastName} (${this.connectedUser.company.label})`;
        resolve();
      }, reject);
    });
  }

  /**
   * Routine executed when the user asked for a connection "as".
   *
   * We do not deal with the case of the reject on the 'setConnectedUser' promise:
   * the error is catch in the accountService service.
   *
   */
  private loginAs(): void {
    this.spinnerWithBackdropService.show();
    this.accountAsService.loginAs(this.selectedAccountId[0]).subscribe(
      () => {
        this.setConnectedUser().then(() => {
          this.isConnectionAs = true;
          this.router.navigateByUrl(Sitemap.utils.homepage.path);
          this.spinnerWithBackdropService.hide();
          const message: SnackbarMessage = {
            text: this.i18nService._('Success_SnackBar_LoginAs', [
              this.connectedUser.firstName,
              this.connectedUser.lastName,
              this.connectedUser.company.label,
            ]),
            type: SnackbarMessageType.Info,
          };
          this.snackbarService.sendMessage(message);
          this.selectedAccountId = [];
          this.accountAsService.changeState(true);
          this.sentryService.sendUserToSentry();
        });
      },
      () => {
        this.searchAccount.resetSeletedValues();
        this.spinnerWithBackdropService.hide();
      },
    );
  }

  /**
   * Routine executed when the user asked for a logout.
   */
  private logoutAs(): void {
    this.spinnerWithBackdropService.show();
    this.accountAsService.logoutAs().subscribe(() => {
      this.afterLogoutAs();
    });
  }

  private afterLogoutAs() {
    this.accountService.getConnectedUser().subscribe(
      (loginResponse) => {
        this.tokenService.setOldToken(loginResponse);
        this.isConnectionAs = false;
        this.router.navigateByUrl(Sitemap.utils.homepage.path);
        this.spinnerWithBackdropService.hide();
        const message: SnackbarMessage = {
          text: this.i18nService._('Success_SnackBar_LogoutAs'),
          type: SnackbarMessageType.Info,
        };
        this.snackbarService.sendMessage(message);
        this.accountAsService.changeState(false);
      },
      () => {
        this.spinnerWithBackdropService.hide();
      },
    );
  }
}
