<ng-container attr.data-cy="{{ areaType }}" *ngFor="let areaType of areaTypesData">
  <div class="text-base font-semibold">{{ i18nService._(areaType.label) }} *</div>

  <div class="grid grid-cols-2 gap-4 mb-2 mt-2 min-h-80px">
    <mat-radio-group class="col-span-1 flex flex-col" [formControl]="areaType.control">
      <div class="flex flex-row">
        <mat-radio-button [value]="true" data-cy="yes" color="primary" class="flex">
          {{ i18nService._('Txt_RadioButton_Yes') }}
        </mat-radio-button>
        <mat-radio-button [value]="false" data-cy="no" color="primary" class="flex">
          {{ i18nService._('Txt_RadioButton_No') }}
        </mat-radio-button>
      </div>
      <mat-error *ngIf="isSubmit && areaType.control.errors && areaType.control.errors.required">
        {{ i18nService._('Error_Field_Required') }}
      </mat-error>
    </mat-radio-group>

    <mat-form-field *ngIf="areaType.control.value" class="col-span-1">
      <mat-label>{{ i18nService._('Title_Surface_Area') }} {{ i18nService._(areaType.label) }}</mat-label>
      <input
        appDecimalMask
        [formControl]="areaType.spaceControl"
        class="text-right"
        matInput
        attr.data-cy="{{ areaType.label }}"
        required
      />
      <span class="text-black px-2" matSuffix>m²</span>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
  </div>
</ng-container>
