/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DateTime } from 'luxon';
import { debounce, distinctUntilChanged, merge, Subject, takeUntil } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { interval } from 'rxjs/internal/observable/interval';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { tap } from 'rxjs/internal/operators/tap';
import { ListResponse, ParamRequest } from '@commons-dto/valo-back';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet } from '@angular/common';

import { I18nFiltersTrackingTableComponent } from './filter/i18n-filters-tracking-table/i18n-filters-tracking-table.component';
import { BooleanFiltersTrackingTableComponent } from './filter/boolean-filters-tracking-table/boolean-filters-tracking-table.component';
import { DateFiltersTrackingTableComponent } from './filter/date-filters-tracking-table/date-filters-tracking-table.component';
import { SelfSearchFiltersTrackingTableComponent } from './filter/self-search-filters-tracking-table/self-search-filters-tracking-table.component';

import { GenericActionsComponent } from '../../../table/components/generic-actions/generic-actions.component';
import { ArrayFilterFn } from '../../../utils/@types/ArrayFns';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { BasicFormatsService } from '../../../utils/services/basic-formats.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { ReferenceTablesService } from '../../../utils/services/reference-tables.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { UserRoleService } from '../../../utils/services/user-role.service';
import { AdvancedTrackingTableColumn } from '../../models/AdvancedTrackingTableColumn';
import { AdvancedTrackingTableColumnType } from '../../models/advanced-tracking-table-column-type.enum';
import { FilterEventOutput } from '../../models/filter/filter-event-output';
import { TrackingTableReloadOption } from '../../models/tracking-table-reload-option';
import { AbstractAdvancedTrackingTableService } from '../../services/abstract-advanced-tracking-table.service';
import { AdvancedTrackingTableReloadService } from '../../services/advanced-tracking-table-reload.service';
import { AdvancedTrackingTablePipe } from '../../pipes/advanced-tracking-table.pipe';
import { SafePipe } from '../../../utils/pipes/safe.pipe';
import { StatusTagsComponent } from '../../../design-system/component/status-tags/status-tags.component';
import { ToggleButtonComponent } from '../../../design-system/component/toggle-button/toggle-button.component';

@Component({
  selector: 'app-advanced-tracking-table',
  templateUrl: './advanced-tracking-table.component.html',
  styleUrls: ['./advanced-tracking-table.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    SelfSearchFiltersTrackingTableComponent,
    DateFiltersTrackingTableComponent,
    BooleanFiltersTrackingTableComponent,
    NgSwitchDefault,
    I18nFiltersTrackingTableComponent,
    MatSelectModule,
    MatOptionModule,
    ToggleButtonComponent,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    NgTemplateOutlet,
    StatusTagsComponent,
    MatIconModule,
    MatTooltipModule,
    MatPaginatorModule,
    SafePipe,
    AdvancedTrackingTablePipe,
  ],
})
export class AdvancedTrackingTableComponent implements OnInit, AfterViewInit, OnDestroy {
  private static readonly STORAGE_GLOBAL_PAGE_SIZE = 'globalPageSize';
  public static readonly DEBOUNCE_TIME = 800;

  referencesTables: Array<AdvancedTrackingTableColumn> = [];
  dataLoaded: boolean;
  /**
   * setup when you want the parent component to manage when the itms a loaded with the use of event (initEvent)
   */
  @Input() defferedLoading: boolean;
  /**
   * setup with defferedLoading if multiple <app-advanced-tracking-table></app-advanced-tracking-table> are in the parent componant
   */
  @Input() reloadId: string;
  /**
   * serviceParams : used to add params to url (optional)
   *
   * @type {AbstractAdvancedTrackingTableService}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() serviceParams;
  /**
   * filterPlaceholder : label for input to filter table (optional)
   *
   * @type {string}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() filterPlaceholderI18nToken = 'Txt_Placeholder_Search';
  /**
   * filterPlaceholder : label for input to filter table (optional)
   *
   * @type {string}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() noResultMessageI18nToken = 'Txt_No_Result';
  /**
   * pageSize : number of elements to display by page (optionnal)
   *
   * @type {number}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() pageSize;
  /**
   * pageSizeOptions : array of possible numbers of elements to display by page (optionnal)
   *
   * @type {Array<number>}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() pageSizeOptions: Array<number>;
  /**
   * snackBarMessageOnError : wether a snackBar should be displayed on error
   *
   * @type {boolean}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() snackBarMessageOnError = true;
  /**
   * errorI18nMessage : i18n token for message in case of error
   *
   * @type {string}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() errorI18nMessage = 'Error_SnackBar_ErrorOccured';
  /**
   * columnSort input (optional)
   *
   * @type {{ string }}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() columnSort: string;
  /**
   * columnSortOrder input (optional)
   *
   * @type {{ string }}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() columnSortOrder: 'asc' | 'desc';
  /**
   * Show or not column selection with checkbox
   *
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() activateColumnsToDisplay = false;
  /**
   * Custom filteringFunction
   * @type {Function}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() filterPredicate: (data: any, filter: string) => boolean;
  /**
   * resultEvent : Output to send data in the event of a result
   *
   * @type {EventEmitter<any>}
   * @memberof AdvancedTrackingTableComponent
   */
  @Output() readonly resultEvent: EventEmitter<any> = new EventEmitter();
  /**
   * filterEvent : Output to send filter event
   *
   * @type {EventEmitter<any>}
   * @memberof AdvancedTrackingTableComponent
   */
  @Output() readonly filterEvent: EventEmitter<FilterEventOutput> = new EventEmitter();
  /**
   * filterEvent : Output to send init event
   *
   * @type {EventEmitter<any>}
   * @memberof AdvancedTrackingTableComponent
   */
  @Output() readonly initEvent: EventEmitter<any> = new EventEmitter();
  /**
   * errorEvent : Output to send details in the event of an error
   *
   * @type {EventEmitter<any>}
   * @memberof AdvancedTrackingTableComponent
   */
  @Output() readonly errorEvent: EventEmitter<any> = new EventEmitter();
  /**
   * Instance that implements methods to filter, paginate and sort data
   *
   * @type {MatTableDataSource<Object>}
   * @memberof AdvancedTrackingTableComponent
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  dataSource: MatTableDataSource<Object>;
  haveElement: boolean;
  /**
   * isLoadingResults : private flag when loading
   *
   * @type {boolean}
   * @memberof AdvancedTrackingTableComponent
   */
  isLoadingResults = false;
  /**
   * Array of selected options
   *
   * @type {string[]}
   * @memberof AdvancedTrackingTableComponent
   */
  selectedColumns: Array<AdvancedTrackingTableColumn> = [];
  public refenceTableToFilter: any;
  public refenceTableToFilterBoolean: any;
  i18nFilters: { [keys: string]: UntypedFormControl };
  i18nFiltersForm: UntypedFormGroup = new UntypedFormGroup({});
  selfFilterForm: UntypedFormGroup = new UntypedFormGroup({});
  /**
   * paginator : private paginator element
   *
   * @type {MatPaginator}
   * @memberof AdvancedTrackingTableComponent
   */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  /**
   * paginator : private paginator element
   *
   * @type {MatPaginator}
   * @memberof AdvancedTrackingTableComponent
   */
  @ViewChild('filterInput', { static: true }) filterInput: MatInput;
  /**
   * sort : private sort element
   *
   * @type {MatSort}
   * @memberof AdvancedTrackingTableComponent
   */
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  filterTypes = {
    SELFSEARCH: 'selfSearch',
    DATE: 'date',
    BOOLEAN: 'boolean',
  };
  public teamFilterChecked: boolean;
  public partnerFilterChecked: boolean;
  private i18nFormFilterValues: any;
  private selfFilterFromValues: any;
  /**
   * filterEvent : Output to send filter event
   *
   * @type {EventEmitter<any>}
   * @memberof AdvancedTrackingTableComponent
   */
  @Output() private readonly filterEventDebounce: EventEmitter<string> = new EventEmitter();
  private reloadOption: TrackingTableReloadOption;
  private nbItems: number;
  /**
   * for unsubscribe all subscription on destroy componant
   * @private
   */
  private readonly _$destroy: Subject<boolean> = new Subject<boolean>();
  private queryParamMap: ParamMap;

  /**
   * Creates an instance of AdvancedTrackingTableComponent.
   * @param {BasicFormatsService} basicFormatsService
   * @param {ComponentFactoryResolver} componentFactoryResolver
   * @param {I18nService} i18nService
   * @param snackbarService
   * @param {UserRoleService} userRoleService
   * @param {ViewContainerRef} viewContainerRef
   * @param appConfigService
   * @param _activatedRoute
   * @param _reloadService
   * @param _referenceTablesService
   * @memberof AdvancedTrackingTableComponent
   */
  constructor(
    private readonly basicFormatsService: BasicFormatsService,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    public readonly i18nService: I18nService,
    private readonly snackbarService: SnackbarService,
    private readonly userRoleService: UserRoleService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly appConfigService: AppConfigService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _reloadService: AdvancedTrackingTableReloadService,
    private readonly _referenceTablesService: ReferenceTablesService,
  ) {
    this.dataSource = new MatTableDataSource();
    this.pageSizeOptions = this.appConfigService.getPaginatorDefaultValue();
    this._reloadService.$reload
      .pipe(
        debounce(() => interval(AdvancedTrackingTableComponent.DEBOUNCE_TIME)),
        tap((reloadOption: TrackingTableReloadOption) => {
          if (!reloadOption.reloadId || reloadOption.reloadId === this.reloadId) {
            this.pageSize = this.getGlobalPageSize();
            this.reloadOption = reloadOption;
            this.loadItems(
              {
                serviceParams: this.serviceParams,
                filter: this.filter,
                pageNum: this.paginator.pageIndex,
                nbResult: this.pageSize,
                sortColumn: this.sort.direction ? this.sort.active : undefined,
                sortDirection: this.sort.direction ? this.sort.direction : undefined,
              },
              this.reloadOption,
            );
          }
        }),
        takeUntil(this._$destroy),
        distinctUntilChanged(),
      )
      .subscribe();
  }

  public _teamFilter: boolean;

  get teamFilter(): boolean {
    return this._teamFilter;
  }

  /**
   * setup when you want the filter my teams
   */
  @Input() set teamFilter(teamFilter) {
    this.teamFilterChecked = false;
    this._teamFilter = teamFilter;
  }

  public _partnerFilter: boolean;

  get partnerFilter(): boolean {
    return this._partnerFilter;
  }

  /**
   * setup when you want the filter my partners
   */
  @Input() set partnerFilter(partnerFilter) {
    this.partnerFilterChecked = !!partnerFilter;
    this._partnerFilter = partnerFilter;
    this.changePartnerFilter(this._partnerFilter);
  }

  /**
   * columns : key, sortable & title parameters (required)
   *
   * @type {Array<{key: string, sortable: string, i18nTitle: string, type: string, parameters: any}>}
   * @memberof AdvancedTrackingTableComponent
   */
  private _columns: Array<AdvancedTrackingTableColumn>;

  get columns(): Array<AdvancedTrackingTableColumn> {
    return this._columns;
  }

  @Input() set columns(colums: Array<AdvancedTrackingTableColumn>) {
    this._columns = colums;
  }

  private _service: AbstractAdvancedTrackingTableService<any>;

  /**
   * service : used to fill table (required)
   *
   * @type {AbstractAdvancedTrackingTableService}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() set service(service: AbstractAdvancedTrackingTableService<any>) {
    this._service = service;
  }

  /**
   * items : private list of elements to display
   *
   * @type {Array<any>}
   * @memberof AdvancedTrackingTableComponent
   */
  private _items = [];

  get items(): Array<any> {
    return this._items;
  }

  @Input() set items(items: Array<any>) {
    this._items = items;
    this.refresh();
  }

  private _filter: string;

  get filter(): string {
    return this._filter;
  }

  @Input() set filter(filter: string) {
    this._filter = filter
      ? filter
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      : undefined;

    this.filterEventDebounce.emit(this._filter);
  }

  /**
   * displayedColumns getter, based on columns property
   *
   * @memberof AdvancedTrackingTableComponent
   * @returns {Array<string>}
   */
  get displayedColumns(): Array<AdvancedTrackingTableColumn> {
    return this.columns.filter((column) => {
      // Handle hide column
      if (column.hidden) {
        return false;
      }
      if (column.visibleFor) {
        return this.userRoleService.isInRoles(column.visibleFor);
      }

      return true;
    });
  }

  /**
   * displayedColumnsKeys getter, based on columns property
   *
   * @memberof AdvancedTrackingTableComponent
   * @returns {Array<string>}
   */
  get displayedColumnsKeys(): Array<string> {
    return this.displayedColumns.map((columns) => columns.key);
  }

  get length(): number {
    return this.nbItems;
  }

  get hasData(): boolean {
    return !!this.length || !!this._filter || (this.i18nFormFilterValues && !!Object.keys(this.i18nFormFilterValues).length);
  }

  /**
   * get computed selected columns summary
   *
   * @returns {string}
   * @memberof AdvancedTrackingTableComponent
   */
  get selectedColumnsSummary(): string {
    const nbselectedColumns = this.selectedColumns.length;

    if (nbselectedColumns) {
      const i18nTitle = this.selectedColumns[0].i18nTitle;
      switch (nbselectedColumns) {
        case this.columns.length - 1:
          return this.i18nService._('Txt_Select_Form_Table_All');
        case 1:
          return `${this.i18nService._(i18nTitle)}`;
        default:
          return `${this.i18nService._(i18nTitle)} ${this.i18nService._('Txt_Select_Form_Table_And_Other', [nbselectedColumns - 1])}`;
      }
    }

    return '';
  }

  /**
   * get global page size value from local Storage
   *
   * @returns {number}
   * @memberof AdvancedTrackingTableComponent
   */
  getGlobalPageSize(): number {
    const pageSize = localStorage.getItem(AdvancedTrackingTableComponent.STORAGE_GLOBAL_PAGE_SIZE);

    return pageSize && parseInt(pageSize, 10);
  }

  /**
   * set global page size value into local Storage
   * @argument pageSize {number}
   * @returns {void}
   * @memberof AdvancedTrackingTableComponent
   */
  setGlobalPageSize(pageSize: number): void {
    if (!pageSize) {
      return;
    }

    localStorage.setItem(AdvancedTrackingTableComponent.STORAGE_GLOBAL_PAGE_SIZE, pageSize.toString());
  }

  /**
   * ngOnInit method
   *
   * @memberof AdvancedTrackingTableComponent
   */
  ngOnInit(): void {
    this.pageSize = this.pageSize || this.getGlobalPageSize() || this.pageSizeOptions[0];
    this.setGlobalPageSize(this.pageSize);
    this.queryParamMap = this._activatedRoute.snapshot.queryParamMap;
    this.filter = this.queryParamMap.get('filter');
    this.selectedColumns = this.columns.filter(
      // eslint-disable-next-line no-prototype-builtins
      (element) => !(element.hasOwnProperty('hidden') && element.hidden),
    );
    this.isLoadingResults = this.defferedLoading;
    this.referencesTables = this.processRefTableFilter(this.columns);
    if (!this.items.length && !this.defferedLoading) {
      this.loadItems({
        serviceParams: this.serviceParams,
        filter: this.filter,
        pageNum: this.paginator.pageIndex,
        nbResult: this.pageSize,
      });
    }
  }

  ngOnDestroy(): void {
    this._$destroy.next(false);
    this._$destroy.complete();
  }

  ngAfterViewInit(): void {
    // subscribe to different search event in order to reset the pagination page to 0
    this.initEvent.emit(true);
    merge(this.sort.sortChange, this._reloadService.$reload, this.i18nFiltersForm.valueChanges, this.selfFilterForm.valueChanges)
      .pipe(takeUntil(this._$destroy))
      .subscribe(() => (this.paginator.pageIndex = 0));

    // debounce the search input so it don't launch one request for each character
    this.filterEventDebounce
      .pipe(
        debounce(() => interval(AdvancedTrackingTableComponent.DEBOUNCE_TIME)),
        takeUntil(this._$destroy),
        distinctUntilChanged(),
      )
      .subscribe((filter) => {
        this.filterEvent.emit({ filter });
        this.paginator.page.emit({
          length: this.paginator.length,
          pageIndex: 0,
          pageSize: this.paginator.pageSize,
        });
      });

    this.i18nFiltersForm.valueChanges
      .pipe(
        debounce(() => interval(AdvancedTrackingTableComponent.DEBOUNCE_TIME)),
        takeUntil(this._$destroy),
        distinctUntilChanged(),
      )
      .subscribe((formValue) => {
        this.i18nFormFilterValues = formValue;

        this.filterEvent.emit({
          filter: this.filter,
          i18nFilter: this.i18nFormFilterValues,
        });
        if (this.i18nFiltersForm.dirty) {
          this.paginator.page.emit({
            length: this.paginator.length,
            pageIndex: 0,
            pageSize: this.paginator.pageSize,
          });
        }
      });

    this.selfFilterForm.valueChanges
      .pipe(
        debounce(() => interval(AdvancedTrackingTableComponent.DEBOUNCE_TIME)),
        takeUntil(this._$destroy),
        distinctUntilChanged(),
      )
      .subscribe((formValue) => {
        if (formValue.mandatEnd) {
          formValue.mandatEnd = [
            DateTime.fromJSDate(new Date(formValue.mandatEnd.begin)).toISODate(),
            DateTime.fromJSDate(new Date(formValue.mandatEnd.end)).toISODate(),
          ];
        }

        if (this.selfFilterForm.dirty) {
          this.selfFilterFromValues = formValue;
          this.filterEvent.emit({
            filter: this.filter,
            i18nFilter: this.i18nFormFilterValues,
            selfFilter: this.selfFilterFromValues,
          });
        }
      });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        takeUntil(this._$destroy),
        distinctUntilChanged(),
        tap(() => {
          const currentPageSize = this.getGlobalPageSize();
          if (currentPageSize !== this.paginator.pageSize) {
            this.pageSize = this.paginator.pageSize;
            this.setGlobalPageSize(this.pageSize);
          }
        }),
        switchMap(() => {
          return this.loadItems$(
            {
              serviceParams: this.serviceParams,
              filter: this.filter,
              pageNum: this.paginator.pageIndex,
              nbResult: this.pageSize,
              sortColumn: this.sort.direction ? this.sort.active : undefined,
              sortDirection: this.sort.direction ? this.sort.direction : undefined,
            },
            this.reloadOption,
          );
        }),
      )
      .subscribe(
        (result: ListResponse<any>) => {
          this.processDataResult(result);
          this.processCompleteRequest();
        },
        (error) => {
          this.processDataError(error);
          this.processCompleteRequest();
        },
      );
  }

  /**
   * Detect selected/unseleted checkbox column
   *
   * @param {*} event
   * @memberof AdvancedTrackingTableComponent
   */
  onSelectedCheckboxChange(event): void {
    if (event.isUserInput) {
      const index = this.columns.findIndex((element) => element.key === event.source.value.key);
      this.columns[index].hidden = !event.source.selected;
      this.columns = this.columns.slice(0);
    }
  }

  /**
   * loadItems method to get data through service
   *
   * @memberof AdvancedTrackingTableComponent
   * @param paramsRequest
   * @param reloadOption
   */
  public loadItems(paramsRequest: ParamRequest, reloadOption?: TrackingTableReloadOption): void {
    this.loadItems$(paramsRequest, reloadOption).subscribe(
      (result: ListResponse<any>) => {
        this.processDataResult(result);
      },
      (error) => {
        this.processDataError(error);
      },
      () => {
        this.processCompleteRequest();
      },
    );
  }

  /**
   * loadItems method to get data through service
   *
   * @memberof AdvancedTrackingTableComponent
   * @param paramsRequest
   * @param reloadOption
   */
  public loadItems$(paramsRequest: ParamRequest, reloadOption?: TrackingTableReloadOption): Observable<any> {
    this.isLoadingResults = true;

    return this._service.getItems(this.processRequestParameters(paramsRequest, reloadOption));
  }

  getFilterValues(filterValues, selfFilter?): any {
    if (!filterValues) {
      return undefined;
    }
    let noNullIFilterValues;
    Object.keys(filterValues).forEach((key) => {
      const val = filterValues[key];
      if (val !== undefined && val !== null && val.length) {
        noNullIFilterValues = noNullIFilterValues || {};
        if (selfFilter) {
          this.referencesTables.forEach((element) => {
            if (element.selfSearch) {
              if ((element.selfSearch.type === 'input' || element.selfSearch.type === 'autocomplete') && element.key === key) {
                noNullIFilterValues[key] = {
                  valeur: val,
                  isLike: element.selfSearch.type === 'input',
                };
              } else if ((element.selfSearch.type === 'dateSimple' || element.selfSearch.type === 'dateRange') && element.key === key) {
                noNullIFilterValues[key] = {
                  startDate: val[0],
                  endDate: val[1],
                  isDate: true,
                };
              }
            }
          });
        } else {
          noNullIFilterValues[key] = val;
        }
      }
    });

    return noNullIFilterValues;
  }

  refresh(filterFn?: ArrayFilterFn): void {
    const data = filterFn ? this.items.filter(filterFn) : this.items;
    this.dataSource.data = this.prepareCells(data);
  }

  /**
   * Return if the column is a ng-template
   *
   * @memberof TableComponent
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  public isNgTemplate(element: Object, columnName: string): boolean {
    return element[columnName] instanceof TemplateRef;
  }

  /**
   * Format cells data
   *
   * @param {Array<any>} items
   * @returns {Array<any>}
   * @memberof AdvancedTrackingTableComponent
   */
  public prepareCells(items: Array<any>): Array<any> {
    const cells = [];
    items.forEach((item) => {
      const row = {};
      this.columns.forEach((column) => {
        const val = item[column.key];
        let cellContent;
        switch (column.type) {
          case AdvancedTrackingTableColumnType.I18nToken:
            // eslint-disable-next-line no-case-declarations
            let i18nTokenPrefix = '';
            if (column.parameters && column.parameters.i18nTokenPrefix) {
              i18nTokenPrefix = column.parameters.i18nTokenPrefix;
            }

            // eslint-disable-next-line no-case-declarations
            const cellContent1 = this.i18nService._(`${i18nTokenPrefix}${val}`);
            cellContent = cellContent1;
            break;
          case AdvancedTrackingTableColumnType.Boolean:
            // eslint-disable-next-line no-case-declarations
            let trueBooleanString = this.i18nService._('Txt_Yes');
            // eslint-disable-next-line no-case-declarations
            let falseBooleanString = this.i18nService._('Txt_No');
            if (column.parameters) {
              if (column.parameters.trueBooleanString && column.parameters.falseBooleanString) {
                trueBooleanString = column.parameters.trueBooleanString;
                falseBooleanString = column.parameters.falseBooleanString;
              }
              if (column.parameters.reverse) {
                trueBooleanString = this.i18nService._('Txt_No');
                falseBooleanString = this.i18nService._('Txt_Yes');
              }
            }
            val ? (cellContent = trueBooleanString) : (cellContent = falseBooleanString);
            break;
          case AdvancedTrackingTableColumnType.Link:
          case AdvancedTrackingTableColumnType.Actions:
            // eslint-disable-next-line no-case-declarations
            const actionsParameters = column.parameters || { actions: {} };
            // eslint-disable-next-line no-case-declarations
            const factory = this.componentFactoryResolver.resolveComponentFactory(GenericActionsComponent);
            // eslint-disable-next-line no-case-declarations
            const component = this.viewContainerRef.createComponent(factory);
            component.instance.genericActions = actionsParameters.actions;
            component.instance.isMenu = Boolean(column.type === AdvancedTrackingTableColumnType.Actions);
            component.instance.menuDisable = !item.actions || !Object.values(item.actions).find((element: any) => element === true);
            component.instance.element = item;
            Object.assign(component.instance, actionsParameters.condition || val);
            cellContent = component.instance.templateRef;
            break;
          case AdvancedTrackingTableColumnType.Custom:
            if (column.customFormat) {
              cellContent = column.customFormat(item);
            }
            break;
          default:
            cellContent = val;
            break;
        }
        if (column.statusTagOption) {
          row[`${column.key}_raw`] = val;
        }
        row[column.key] = cellContent;
      });
      cells.push(row);
    });

    return cells;
  }

  /**
   * Update single row data in dataSource
   *
   * @param {number} id
   * @returns {*}
   * @memberof AdvancedTrackingTableComponent
   */
  public updateSingleDataRow(id: number): any {
    this._service.getSingleItem(id).subscribe(
      (updatedData) => {
        // Access id in Object Array of dataSource.data
        const accessObjectId = 'id';
        // Find data to update row index in dataSource.data
        const index = this.dataSource.data.findIndex((data) => data[accessObjectId] === id);
        if (updatedData.items.length) {
          // Give formated new data to dataSource.data
          this.dataSource.data[index] = this.prepareCells(updatedData.items)[0];
        } else {
          // Handle removed data in bdd
          this.dataSource.data.splice(index, 1);
        }
        // Destructure array to see visual update
        this.dataSource.data = this.dataSource.data.slice(0);
      },
      () => {
        this.snackbarService.sendErrorOccured();
      },
    );
  }

  public getItemClass(column, item): string {
    const itemCSSPicto = column.picto ? 'flex flex-row' : '';
    if (!column.ngClass) {
      return `${itemCSSPicto} text-xs text-black`;
    }
    if (column.ngClass.condition(item)) {
      return `${itemCSSPicto} ${column.ngClass.classTrue}`;
    }

    return `${itemCSSPicto} ${column.ngClass.classFalse}`;
  }

  public getCellItemClass(column, item): string {
    return `${column?.cellClass?.condition(item)}`;
  }

  changeTeamFilter($event: boolean) {
    this.teamFilterChecked = $event;
    this.filterEvent.emit({ isTeamMember: this.teamFilterChecked });
    this.paginator.page.emit({
      length: this.paginator.length,
      pageIndex: 0,
      pageSize: this.paginator.pageSize,
    });
  }

  changePartnerFilter($event: boolean) {
    this.partnerFilterChecked = $event;
    this.filterEvent.emit({ isPartner: this.partnerFilterChecked });
    this.paginator.page.emit({
      length: this.paginator.length,
      pageIndex: 0,
      pageSize: this.paginator.pageSize,
    });
  }

  private processCompleteRequest(): void {
    this.dataLoaded = true;
    this.isLoadingResults = false;
  }

  private processDataError(error): void {
    if (this.snackBarMessageOnError) {
      this.snackbarService.errorI18n(this.errorI18nMessage);
    }
    this.errorEvent.emit(error);
  }

  private processDataResult(result: ListResponse<any>): void {
    this.nbItems = result.nbItems;
    this.items = result.items;
    this.resultEvent.emit(result);
  }

  private processRefTableFilter(columns: Array<AdvancedTrackingTableColumn>): Array<any> {
    const referencesTables = columns.filter((col) => col.table || col.selfSearch);
    if (referencesTables && referencesTables.length) {
      referencesTables.forEach((value) => {
        switch (value.type) {
          case AdvancedTrackingTableColumnType.I18nToken:
            value.showFilter = false;
            this._referenceTablesService.getTableReferenceInfo(value.table).subscribe((ref) => {
              this.refenceTableToFilter = this.refenceTableToFilter || {};
              const referenceTable = [];
              ref.forEach((refobj) => {
                const reference = { ...refobj };
                let i18nTokenPrefix = '';
                if (value.parameters && value.parameters.i18nTokenPrefix) {
                  i18nTokenPrefix = value.parameters.i18nTokenPrefix;
                }
                reference.label = this.i18nService._(`${i18nTokenPrefix}${reference.label}`);
                referenceTable.push(reference);
              });

              this.refenceTableToFilter[value.table] = referenceTable;
              const queryParamMapElement = this.queryParamMap.get(value.table);
              this.i18nFiltersForm.addControl(
                value.table,
                new UntypedFormControl(queryParamMapElement ? queryParamMapElement.split(',').map(Number) : null),
              );

              value.showFilter = true;
            });
            break;
          case AdvancedTrackingTableColumnType.Boolean:
            this.refenceTableToFilterBoolean = this.refenceTableToFilterBoolean || {};
            // eslint-disable-next-line no-case-declarations
            const referenceBoolean = [];
            referenceBoolean.push({
              label: this.i18nService._('Txt_Yes'),
              value: true,
            });
            referenceBoolean.push({
              label: this.i18nService._('Txt_No'),
              value: false,
            });
            this.refenceTableToFilterBoolean[value.key] = referenceBoolean;
            // eslint-disable-next-line no-case-declarations
            const queryParamMapElementBoolean = this.queryParamMap.get(value.key);
            this.i18nFiltersForm.addControl(
              value.key,
              new UntypedFormControl(queryParamMapElementBoolean ? queryParamMapElementBoolean.split(',').map(Boolean) : null),
            );
            value.showFilter = true;
            break;
          case AdvancedTrackingTableColumnType.selfSearch:
            if (value.selfSearch.type === 'input') {
              const queryParamMapElementSelfSearch = this.queryParamMap.get(value.key);
              this.selfFilterForm.addControl(
                value.key,
                new UntypedFormControl(queryParamMapElementSelfSearch ? queryParamMapElementSelfSearch : null),
              );
            }
            value.showFilter = true;
            break;
          case AdvancedTrackingTableColumnType.Date:
            // eslint-disable-next-line no-case-declarations
            const queryParamMapElementDate = this.queryParamMap.get(value.key);
            this.selfFilterForm.addControl(
              value.key,
              new UntypedFormControl(
                queryParamMapElementDate
                  ? {
                      begin: DateTime.fromJSDate(new Date(queryParamMapElementDate.split(',')[0])).toISODate(),
                      end: DateTime.fromJSDate(new Date(queryParamMapElementDate.split(',')[1])).toISODate(),
                    }
                  : null,
              ),
            );
            value.showFilter = true;
            break;
          default:
            break;
        }
      });
      this.i18nFormFilterValues = this.i18nFiltersForm.value;
      this.selfFilterFromValues = this.selfFilterForm.value;
      if (!!this.selfFilterFromValues.mandatEnd && this.selfFilterFromValues.mandatEnd !== null) {
        this.selfFilterFromValues.mandatEnd = [
          DateTime.fromJSDate(new Date(this.selfFilterFromValues.mandatEnd.begin)).toISODate(),
          DateTime.fromJSDate(new Date(this.selfFilterFromValues.mandatEnd.end)).toISODate(),
        ];
      }
    }

    return referencesTables;
  }

  private processRequestParameters(paramsRequest: ParamRequest, reloadOption: TrackingTableReloadOption): ParamRequest {
    const param = { ...paramsRequest };
    param.i18nFilters = this.getFilterValues(this.i18nFormFilterValues);
    param.selfFilters = this.getFilterValues(this.selfFilterFromValues, true);
    param.serviceParams =
      reloadOption && reloadOption.serviceParamsChange ? reloadOption.serviceParamsChange.join('/') : this.serviceParams;
    param.statusFilter = reloadOption && reloadOption.filterChange ? reloadOption.filterChange.statusFilter : undefined;
    param.isTeamMember = this.teamFilterChecked;
    param.isPartner = this.partnerFilterChecked;

    return param;
  }
}

// eslint-disable-next-line max-lines
