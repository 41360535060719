<form *ngIf="fields" [formGroup]="form" class="w-[66%]">
  <formly-form #formlyForm [fields]="fields" [form]="form" [model]="model" [options]="options"></formly-form>
</form>

<app-card class="px-8 py-7 items-start w-[30%] min-w-[270px]" *ngIf="loaded">
  <div *ngIf="simulationResultItems?.length > 0" class="w-full">
    <div *ngFor="let item of simulationResultItems" class="flex justify-between pb-1">
      <span class="text-sm text-black-light pb-2 inline-flex items-center">
        {{ item.label | translate }}
        <span *ngIf="item.hasTooltip" class="icon-info txt-gray pl-1 self-center" matTooltip="{{ item.tooltip | translate }}"> </span>
      </span>
      <span class="text-sm text-black-light font-semibold pb-2">
        {{ item.value | formatSimulationValue: item.format }}
      </span>
    </div>
  </div>
  <span class="font-bold text-xl mt-2 text-black-light">{{ 'Prospect_Simulation_Purchasing_Capacity' | translate }}</span>
  <span class="font-bold text-4xl text-purple-primary pb-2 text-right w-full inline-block">
    {{ simulation?.purchasingCapacity > 0 ? (simulation.purchasingCapacity | currency: 'eur' : '€' : '1.0-0') : '-- €' }}
  </span>
  <app-button [type]="ButtonType.SECONDARY" (clickButton)="saveToSearchPreferences()">
    {{ 'Prospect_Simulation_Save_To_SearchPreferences' | translate }}
  </app-button>
  <app-button [type]="ButtonType.PRIMARY" (clickButton)="search()">
    {{ 'Prospect_Simulation_Search' | translate }}
  </app-button>
</app-card>
