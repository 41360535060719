import { Injectable } from '@angular/core';

import { LotOrientationResponse } from '../utils/models/LotOrientationResponse';
import { LotOrientationApiService } from '../adapters/lot-orientation-api.service';

@Injectable({
  providedIn: 'root',
})
export class LotOrientationService {
  lotOrientations: LotOrientationResponse[];

  constructor(private readonly lotOrientationApiService: LotOrientationApiService) {}

  public async getLotOrientations(): Promise<LotOrientationResponse[]> {
    if (!this.lotOrientations) {
      await this.loadLotOrientations();
    }
    return this.lotOrientations;
  }

  private async loadLotOrientations(): Promise<void> {
    this.lotOrientations = await this.lotOrientationApiService.getLotOrientations();
  }
}
