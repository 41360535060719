import { Injectable } from '@angular/core';
import { ParamRequest } from '@commons-dto/valo-back';
import { lastValueFrom } from 'rxjs';

import { ProspectTrackingTableItem } from '../models/ProspectTrackingTableItem';
import { GridTrackingTableService } from './grid-tacking-table.service';
import { ProspecTrackingTableServiceResponse } from '../models/ProspectTrackingTableServiceResponse';

import { TableLoader, TableResponse } from '../../table/interfaces/DataLoader';
import { ProspectLegacyApiService } from '../../adapters/prospect-legacy-api.service';

@Injectable({
  providedIn: 'root',
})
export class ProspectsTrackingTableService implements TableLoader<ProspectTrackingTableItem> {
  public url = 'Paternities/paternity-tracking-tables';
  protected cacheName = 'tracking-prospects';

  constructor(
    private gridTrackingTableService: GridTrackingTableService,
    private prospectLegacyApiService: ProspectLegacyApiService,
  ) {}

  public async getProspects(params: ParamRequest): Promise<ProspecTrackingTableServiceResponse> {
    const response: { items: Array<ProspectTrackingTableItem>; nbItems: number } = await lastValueFrom(
      this.gridTrackingTableService.getTrackingTableBack(params, this.url),
    );
    return response;
  }

  get(params): Promise<TableResponse<ProspectTrackingTableItem>> {
    return lastValueFrom(this.prospectLegacyApiService.getProspects(params));
  }

  getById(id: number): Promise<ProspectTrackingTableItem> {
    return lastValueFrom(this.prospectLegacyApiService.getProspectById(id));
  }
}
