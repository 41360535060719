<h2 class="text-center" mat-dialog-title>
  {{ i18nService._('Title_Form_SearchForm_SpecialOffer') }}
</h2>

<!--TO DO DEV : replace and delete Text by BDD-->
<mat-dialog-content class="px-5">
  <div class="flex flex-col">
    <!--RG_1772.3.0-->
    <div class="flex w-full" class="mb-3">
      <h4 class="mt-4 mb-1">
        {{ i18nService._('TxT_Dialog_Offer_Reference') }}
      </h4>
      <div>{{ specialOffer.refSpecialOffer }}</div>
    </div>
    <!--RG_1772.3.1-->
    <div class="flex w-full" class="mb-3">
      <h4 class="mt-4 mb-1">{{ i18nService._('TxT_Dialog_Offer_Title') }}</h4>
      <div>{{ specialOffer.title }}</div>
    </div>
    <!--RG_1772.3.2-->
    <div class="flex w-full" class="mb-3">
      <h4 class="mt-4 mb-1">
        {{ i18nService._('TxT_Dialog_Offer_Descriptif') }}
      </h4>
      <div>{{ specialOffer.description }}</div>
    </div>
    <!--RG_1772.3.3-->
    <div class="flex w-full" class="mb-3">
      <h4 class="mt-4 mb-1">{{ i18nService._('Title_LegalNotice') }}</h4>
      <div class="text-xxs" innerHtml="{{ specialOffer.legalNotice }}"></div>
    </div>
    <!--RG_1772.3.4 / RG_1772.3.5-->
    <div class="flex w-full">
      {{ i18nService._('TxT_Dialog_Offer_Date', [formatDate(specialOffer.startDate), formatDate(specialOffer.endDate)]) }}
    </div>
  </div>
</mat-dialog-content>

<!--TO DO DEV : Check closeDialog (click)="closeDialog()" -->
<mat-dialog-actions class="justify-center mb-1">
  <button type="button" class="my-3 mx-auto" mat-stroked-button (click)="closeDialog()" color="primary">
    {{ i18nService._('Txt_Button_Exit') }}
  </button>
</mat-dialog-actions>
