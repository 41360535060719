import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToggleFeesService {
  private static readonly STORAGE_ITEM_LABEL = 'hideFees';

  private readonly _toggleFeesState$: Subject<boolean>;
  private _state: boolean;

  constructor() {
    const strVal = localStorage.getItem(ToggleFeesService.STORAGE_ITEM_LABEL);
    this._state = String(strVal).toLowerCase() === 'true';
    this._toggleFeesState$ = new BehaviorSubject(this._state);
  }

  toggle(): void {
    this._state = !this._state;
    localStorage.setItem(ToggleFeesService.STORAGE_ITEM_LABEL, this._state.toString());
    this._toggleFeesState$.next(this._state);
  }

  get toggleFeesState$(): Observable<boolean> {
    return this._toggleFeesState$;
  }
}
