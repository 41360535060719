import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { AccountTrackingTableServiceResponse } from '../models/AccountTrackingTableServiceResponse';
import { AbstractAdvancedTrackingTableService } from './abstract-advanced-tracking-table.service';
import { AccountTrackingTableItem } from '../models/AccountTrackingTableItem';

import { CompanyType } from '../../utils/models/enums/CompanyType.enum';
import { AccountApiService } from '../../adapters/account-api.service';
import { TableLoader, TableResponse } from '../../table/interfaces/DataLoader';
import { AppConfigService } from '../../utils/services/app-config.service';
import { CacheService } from '../../utils/services/cache.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class AcccountTrackingTableService
  extends AbstractAdvancedTrackingTableService<AccountTrackingTableServiceResponse>
  implements TableLoader<AccountTrackingTableItem>
{
  public type: CompanyType;

  constructor(
    public readonly appConfig: AppConfigService,
    public readonly errorHandlerService: ErrorHandlerService,
    public readonly http: HttpClient,
    public readonly cacheService: CacheService,
    private accountApiService: AccountApiService,
  ) {
    super(appConfig, errorHandlerService, http, cacheService);
  }

  async get(params): Promise<TableResponse<AccountTrackingTableItem>> {
    const res = this.accountApiService.getAccountsTable(params, this.type);
    return await lastValueFrom(res);
  }

  async getById(id: number): Promise<AccountTrackingTableItem> {
    const res = this.accountApiService.getAccountTableById(id, this.type);
    return await lastValueFrom(res);
  }
}
