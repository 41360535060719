export enum MethodType {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
}

export enum DirectionType {
  INCOMING,
  OUTGOING,
}

export enum RegexType {
  STRING,
  ENTITIE,
}

export enum FieldType {
  STRING,
  URL,
}

export interface Field {
  paramName: string;
  fieldType?: FieldType;
}

export interface RouteInterceptorData {
  method: MethodType;
  regex: RegExp;
  fields: Array<Field>;
  direction: DirectionType;
}
