import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { CountryResponse } from '../models/CountryResponse';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class PostalAddressService {
  private readonly isFrenchCountry: BehaviorSubject<boolean>;

  constructor(
    private readonly http: HttpClient,
    private readonly appConfig: AppConfigService,
  ) {
    this.isFrenchCountry = new BehaviorSubject(undefined);
  }

  get isFrenchCountryObservable(): Observable<boolean> {
    return this.isFrenchCountry.asObservable();
  }

  setIsFrenchCountry(isFrenchCountry: boolean): void {
    this.isFrenchCountry.next(isFrenchCountry);
  }

  getCountry(idCountry: number): Observable<CountryResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Countries/${idCountry.toString()}`;

    return this.http.get<CountryResponse>(url);
  }
}
