<div class="text-base font-bold text-left mb-3">
  {{ i18nService._('Txt_search_filter_actable_title') }}
</div>
<mat-chip-listbox>
  <mat-chip-option
    color="primary"
    class="mr-3 w-[130px]"
    [selected]="search.isActable"
    (click)="selectedItem('isActable', !search.isActable)"
  >
    <div class="filter-chip-label">
      {{ i18nService._('Title_Form_SearchForm_Actable') }}
    </div>
  </mat-chip-option>
</mat-chip-listbox>
