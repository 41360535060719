<app-selection-tracking-table
  [columnDefs]="columnDefs"
  [rowsControl]="rowsControl"
  [tableLoader]="tableLoader"
  [pageSize]="pageSize"
  [tableHeight]="tableHeight"
  [preSelectedRows]="preSelectedRows"
  [isRowSelectable]="isRowSelectable"
  (rows)="setCompanyIds()"
></app-selection-tracking-table>
