import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { NameInformationComponent } from '../name-information/name-information.component';
import { RadioButtonPopulatedComponent } from '../radio-button-populated/radio-button-populated.component';

import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-identity-information',
  templateUrl: './identity-information.component.html',
  styleUrls: ['./identity-information.component.scss'],
  standalone: true,
  imports: [RadioButtonPopulatedComponent, NameInformationComponent],
})
export class IdentityInformationComponent implements OnInit {
  /**
   * ParentForm to add controls to
   *
   * @type {FormGroup}
   * @memberof IdentityInformationComponent
   */
  @Input() parentForm: UntypedFormGroup;

  /**
   * Get submit from parentForm
   *
   * @type {boolean}
   * @memberof IdentityInformationComponent
   */
  @Input() isSubmit: boolean;

  /**
   * Control on last Name
   *
   * @type {AbstractControl}
   * @memberof IdentityInformationComponent
   */
  public lastNameControl: AbstractControl;

  /**
   * Control on first Name
   *
   * @type {AbstractControl}
   * @memberof IdentityInformationComponent
   */
  public firstNameControl: AbstractControl;

  constructor(public i18nService: I18nService) {}

  /**
   * Init method
   *
   * @memberof IdentityInformationComponent
   */
  ngOnInit(): void {
    this.parentForm.addControl('lastName', new UntypedFormControl('', [Validators.required]));
    this.lastNameControl = this.parentForm.controls.lastName;
    this.parentForm.addControl('firstName', new UntypedFormControl(''));
    this.firstNameControl = this.parentForm.controls.firstName;
  }
}
