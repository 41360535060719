import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompleteDossierResponse, SearchCriteria } from '@commons-dto/dossier-prospect';
import { SecondaryLotTypeResponse } from 'apps/valo-front/src/app/utils/models/SecondaryLotTypeResponse';
import { Subject } from 'rxjs';

import { ButtonComponent } from './../../../../design-system/component/button/button.component';
import { BoundaryPricePipe } from './../../../../utils/pipes/boundary-price.pipe';
import { ChoiceItem } from '../../../../common/formly/formly-chipList/formly-chip-list.component';
import { DeliveryDateService } from './../../../../delivery-date/delivery-date.service';
import { LotOrientationResponse } from './../../../../utils/models/LotOrientationResponse';
import { LotOrientationService } from './../../../../lot-orientation/lot-orientation.service';
import { RangePipe } from './../../../../utils/pipes/range.pipe';
import { ProgramTypeService } from './../../../../program-type/program-type.service';
import { TaxationResponse } from './../../../../utils/models/TaxationResponse';
import { TaxZoneService } from './../../../../tax-zone/tax-zone.service';
import { TaxationService } from './../../../../taxation/taxation.service';
import { I18nService } from './../../../../utils/services/i18n.service';
import { UtilsModule } from './../../../../utils/utils.module';
import { DashboardCardComponent } from '../../../../design-system/component/dashboard-card/dashboard-card.component';
import { PipesModule } from './../../../../utils/pipes/pipes.module';
import { TaxZoneResponse } from './../../../../utils/models/TaxZoneResponse';
import { ProgramTypeResponse } from './../../../../utils/models/ProgramTypeResponse';
import { SecondaryLotTypeService } from './../../../../secondary-lot-type/secondary-lot-type.service';
import { DossierProspectRouteService } from '../../dossier-prospect/dossier-prospect.route.service';
import { ChipComponent } from '../../../../design-system/component/chip/chip.component';
import { ButtonType } from '../../../../design-system/component/button/button.type.enum';

@Component({
  selector: 'app-dossier-prospect-dashboard-search',
  standalone: true,
  templateUrl: './dossier-prospect-dashboard-search.component.html',
  styleUrls: ['./dossier-prospect-dashboard-search.component.scss'],
  imports: [CommonModule, DashboardCardComponent, ButtonComponent, PipesModule, RangePipe, BoundaryPricePipe, UtilsModule, ChipComponent],
})
export class DossierProspectDashboardSearchComponent implements OnInit {
  hasSearchPreferences: boolean = null;
  hSearchPreferencesByCategory: any;
  ButtonType = ButtonType;

  @Input() searchPreferencesUpdated: Subject<void>;

  private readonly rangePipe: RangePipe = new RangePipe(this.i18nService);
  private readonly boundaryPricePipe: BoundaryPricePipe = new BoundaryPricePipe(this.i18nService);

  sortOriginalOrder = (): number => {
    return 0;
  };

  /**
   * dossier
   *
   * @type {CompleteDossierResponse}
   * @memberof DossierProspectDashboardSearchComponent
   */
  _dossier: CompleteDossierResponse;
  get dossier(): CompleteDossierResponse {
    return this._dossier;
  }

  @Input()
  set dossier(value: CompleteDossierResponse) {
    this._dossier = value;
    this.initSearchPreferences();
  }

  constructor(
    private readonly i18nService: I18nService,
    private readonly taxationService: TaxationService,
    private readonly taxZoneService: TaxZoneService,
    private readonly programTypeService: ProgramTypeService,
    private readonly secondaryLotTypeService: SecondaryLotTypeService,
    private readonly lotOrientationService: LotOrientationService,
    private readonly deliveryDateService: DeliveryDateService,
    private readonly dossierProspectRouteService: DossierProspectRouteService,
  ) {}

  ngOnInit() {
    if (!this.searchPreferencesUpdated) return;
    this.searchPreferencesUpdated.subscribe(() => {
      this.initSearchPreferences();
    });
  }

  private async initSearchPreferences(): Promise<void> {
    const hSearchPreferencesByCategory = {};
    if (this.dossier) {
      // Localisation
      this.fillHashSearchPreferenceLocalisation(hSearchPreferencesByCategory);

      // Price and fiscality
      await this.fillHashSearchPreferencePriceAndFiscality(hSearchPreferencesByCategory);

      // Lot
      await this.fillHashSearchPreferenceLot(hSearchPreferencesByCategory);

      // Other
      this.fillHashSearchPreferenceOther(hSearchPreferencesByCategory);
    }

    this.removeNullValues(hSearchPreferencesByCategory);
    this.hSearchPreferencesByCategory = hSearchPreferencesByCategory;

    let hasSearchPreferences = false;
    for (const category in this.hSearchPreferencesByCategory) {
      if (this.hSearchPreferencesByCategory[category] && this.hSearchPreferencesByCategory[category].length > 0) {
        hasSearchPreferences = true;
        break;
      }
    }
    this.hasSearchPreferences = hasSearchPreferences;
  }

  private fillHashSearchPreferenceLocalisation(hSearchPreferencesByCategory: any): void {
    hSearchPreferencesByCategory['Localisation'] = this.getValuesFromSearchCriteria(SearchCriteria.PROGRAM_LOCATION, (value) =>
      this.formatLocalisation(value.label),
    );
  }

  private async fillHashSearchPreferencePriceAndFiscality(hSearchPreferencesByCategory: any): Promise<void> {
    const taxations: TaxationResponse[] = await this.taxationService.getTaxations();
    const taxZones: TaxZoneResponse[] = await this.taxZoneService.getTaxZones();

    const lotMinPrice = this.getValuesFromSearchCriteria(SearchCriteria.LOT_MIN_PRICE, (value) => value);
    const lotMaxPrice = this.getValuesFromSearchCriteria(SearchCriteria.LOT_MAX_PRICE, (value) => value);
    hSearchPreferencesByCategory['Price_Taxation'] = [
      this.boundaryPricePipe.transform(lotMinPrice?.length > 0 ? lotMinPrice[0] : null, lotMaxPrice?.length > 0 ? lotMaxPrice[0] : null),
      this.getValuesFromSearchCriteria(SearchCriteria.LOT_TAXATION, (value) => this.getLabelFromTaxationId(value, taxations)),
      this.getValuesFromSearchCriteria(SearchCriteria.LOT_TAX_ZONE, (value) => this.getLabelFromTaxZoneId(value, taxZones)),
    ];
  }

  private async fillHashSearchPreferenceLot(hSearchPreferencesByCategory: any): Promise<void> {
    const programTypes = await this.programTypeService.getProgramTypes();
    const secondaryLotTypes = await this.secondaryLotTypeService.getSecondaryLotTypes();
    const lotOrientations = await this.lotOrientationService.getLotOrientations();
    hSearchPreferencesByCategory['Lot'] = [
      this.getValuesFromSearchCriteria(SearchCriteria.LOT_PROGRAM_TYPE, (value) => this.getLabelFromProgramType(value, programTypes)),
      this.formatRooms(this.getValuesFromSearchCriteria(SearchCriteria.LOT_ROOMS, (value) => value)),
      this.formatFloors(this.getValuesFromSearchCriteria(SearchCriteria.LOT_FLOORS, (value) => value)),
      this.getValuesFromSearchCriteria(SearchCriteria.LOT_ORIENTATION, (value) => this.getLabelFromOrientation(value, lotOrientations)),
      this.getValuesFromSearchCriteria(SearchCriteria.STORAGE_SECONDARY_LOT, (value) =>
        this.getLabelFromSecondaryType(value, secondaryLotTypes),
      ),
      this.getValuesFromSearchCriteria(SearchCriteria.PARKING_SECONDARY_LOT, (value) =>
        this.getLabelFromSecondaryType(value, secondaryLotTypes),
      ),
    ];
  }

  private fillHashSearchPreferenceOther(hSearchPreferencesByCategory: any): void {
    const deliveryDates = this.deliveryDateService.getDeliveryDates();
    hSearchPreferencesByCategory['Other'] = [
      this.getValuesFromSearchCriteria(SearchCriteria.LOT_MIN_DELIVERY_DATE, (value) =>
        this.getLabelFromDeliveryDateId(value, deliveryDates),
      ),
      this.getValuesFromSearchCriteria(SearchCriteria.LOT_ACTABLE, (value) =>
        value ? this.i18nService._('Title_Form_SearchForm_Actable') : null,
      ),
      this.getValuesFromSearchCriteria(SearchCriteria.PROGRAM_HAS_SPECIAL_OFFER, (value) =>
        value ? this.i18nService._('Title_Form_SearchForm_SpecialOffer') : null,
      ),
      this.getValuesFromSearchCriteria(SearchCriteria.PROGRAM_NEW, (value) =>
        value ? this.i18nService._('Title_Form_SearchForm_New') : null,
      ),
    ];
  }

  private removeNullValues(hSearchPreferencesByCategory: any): any {
    if (!hSearchPreferencesByCategory) return;
    for (const category in hSearchPreferencesByCategory) {
      if (hSearchPreferencesByCategory[category]) {
        hSearchPreferencesByCategory[category] = hSearchPreferencesByCategory[category].filter((entry) => entry != null);
      }

      if (!hSearchPreferencesByCategory[category] || hSearchPreferencesByCategory[category].length == 0) {
        delete hSearchPreferencesByCategory[category];
      }
    }
  }

  private getLabelFromDeliveryDateId(deliveryDateId: number, deliveryDates: ChoiceItem[]): string {
    const deliveryDate = deliveryDates.find((deliveryDate) => deliveryDate.id === deliveryDateId);
    return deliveryDate ? this.i18nService._(deliveryDate.label, [deliveryDate.labelParam]) : '';
  }

  private getLabelFromOrientation(orientationId: number, lotOrientations: LotOrientationResponse[]): string {
    const label = lotOrientations.find((lotOrientation) => lotOrientation.id === orientationId)?.label;
    return label ? this.i18nService._(label) : '';
  }

  private getLabelFromSecondaryType(secondaryLotTypeId: number, secondaryLotTypes: SecondaryLotTypeResponse[]): string {
    const label = secondaryLotTypes.find((secondaryLotType) => secondaryLotType.id === secondaryLotTypeId)?.label;
    return label ? this.i18nService._(label) : '';
  }

  private formatFloors(floors: string[]): string {
    if (!floors || floors.length === 0) return null;
    return this.i18nService._('nb_floors', [this.rangePipe.transform(floors)]);
  }

  private formatRooms(rooms: string[]): string {
    if (!rooms || rooms.length === 0) return null;
    return this.i18nService._('nb_rooms', [this.rangePipe.transform(rooms)]);
  }

  private getLabelFromProgramType(programTypeId: number, programTypes: ProgramTypeResponse[]): string {
    const label = programTypes.find((programType) => programType.id === programTypeId)?.label;
    return label ? this.i18nService._(label) : '';
  }

  private getLabelFromTaxationId(taxationId: number, taxations: TaxationResponse[]): string {
    return taxations.find((taxation) => taxation.id === taxationId)?.label;
  }

  private getLabelFromTaxZoneId(taxZoneId: number, taxZones: TaxZoneResponse[]): string {
    return taxZones.find((taxZone) => taxZone.id === taxZoneId)?.label;
  }

  private formatLocalisation(value: string) {
    if (!value) return '';
    return value.replace(/(\([^)]+\))/g, '').trim();
  }

  private getValuesFromSearchCriteria(searchCriteria: SearchCriteria, extractFunction: (value: any) => string): string[] {
    let values: string[] = [];
    const searchPreferences = this.getSearchPreferencesBySearchCriteriaCode(searchCriteria);
    if (searchPreferences) {
      values = searchPreferences.map((searchPreference) => extractFunction(searchPreference));
    }
    return values.length > 0 ? values : null;
  }

  private getSearchPreferencesBySearchCriteriaCode(searchCriteriaCode: string) {
    if (!this.dossier) return null;
    if (!this.dossier.summarySearchPreferences) return null;
    const searchPreference = this.dossier.summarySearchPreferences.find(
      (searchPreference) => searchPreference.searchCriteriaCode === searchCriteriaCode,
    );
    if (searchPreference) {
      return searchPreference.value;
    }
    return null;
  }

  searchWithCriteria() {
    this.dossierProspectRouteService.goToSearchWithDossierSearchPreferences(this.dossier);
  }

  searchWithoutCriteria() {
    this.dossierProspectRouteService.goToSearch();
  }

  goToSearchPreferences() {
    this.dossierProspectRouteService.goToSearchPreferences(this.dossier.id);
  }
}
