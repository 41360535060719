import { StrongPointListComponent } from 'apps/valo-front/src/app/design-system/component/strong-point-list/strong-point-list.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { zip } from 'lodash-es';
import { Subject, takeUntil, tap } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { I18nPluralPipe, JsonPipe, KeyValuePipe, NgClass, NgFor, NgIf } from '@angular/common';

import { ProgramePageLotLayoutComponent } from '../programe-page-lot-layout/programe-page-lot-layout.component';

import { ComparatorPageService } from '../../../../../comparator/services/comparator-page.service';
import { tagClass, TagsInterface, TagTypeEnum } from '../../../../../design-system/model/tag-option';
import { OfferSpecialDialogComponent } from '../../../../../dialog/components/offer-special-dialog/offer-special-dialog.component';
import { ProspectAssociationService } from '../../../../../dialog/services/prospect-association.service';
import { SearchFormUtilsService } from '../../../../../search/services/search-form-utils.service';
import { breakpoints } from '../../../../../utils/models/breakpoint';
import { BreakpointEnum } from '../../../../../utils/models/enums/breakpoint.enum';
import { LotDetailResponse } from '../../../../../utils/models/LotDetailResponse';
import { Sitemap } from '../../../../../utils/models/Sitemap';
import { SpecialOfferDetailResponse } from '../../../../../utils/models/SpecialOfferDetailResponse';
import { TaxationDetailResponse } from '../../../../../utils/models/TaxationDetailResponse';
import { AppConfigService } from '../../../../../utils/services/app-config.service';
import { BasicFormatsService } from '../../../../../utils/services/basic-formats.service';
import { GoogleTagManagerService } from '../../../../../utils/services/google-tag-manager.service';
import { I18nService } from '../../../../../utils/services/i18n.service';
import { AnchorEnum } from '../../../../models/enum/anchor.enum';
import { ProgramService } from '../../../../services/program.service';
import { PageProgramHelperService } from '../../page-program-helper.service';
import { ProgramPageService } from '../../../../services/program-page.service';
import { ProgramDetail } from '../../../../../utils/models/ProgramDetail';
import { RoutingStateService } from '../../../../../utils/services/routing-state.service';
import { SearchPageDisplayType } from '../../../../../search/model/search-program-data';
import { TagsComponent } from '../../../../../design-system/component/tags/tags.component';
import { ComparatorModule } from '../../../../../comparator/comparator.module';

interface LotDataSource {
  index: number;
  id: number;
  lot: string;
  surface: string;
  livingSpace: number;
  floor: string;
  price: string;
  priceAsNumber: number; // For sorting purposes
  vatPrice: string;
  workPrice: string;
  status: StatusObjectI;
  specialOffer: SpecialOfferDetailResponse;
  plan: {
    title: string;
    container: string;
    fileName: string;
    codeLabel: string;
    mimeType: string;
  };
  surfaceFloor: string;
}

export interface StatusObjectI {
  [key: number]: {
    label: string;
    icon: string;
    isOptionedByOther: boolean;
    isOptionedByMe: boolean;
    unavailable: boolean;
    isFree: boolean;
  };
}

const sortingDataAccessor = (item, property) => {
  switch (property) {
    case 'price':
      return item.priceAsNumber;
    case 'status':
      return item.status.label;
    case 'surface':
      return item.livingSpace;
    default:
      return item[property];
  }
};

@Component({
  selector: 'app-program-page-lots-list',
  templateUrl: './program-page-lots-list.component.html',
  styleUrls: ['./program-page-lots-list.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatIconModule,
    MatExpansionModule,
    NgClass,
    NgFor,
    MatTableModule,
    MatSortModule,
    ComparatorModule,
    MatTooltipModule,
    TagsComponent,
    ProgramePageLotLayoutComponent,
    I18nPluralPipe,
    KeyValuePipe,
    JsonPipe,
    StrongPointListComponent,
  ],
})
export class ProgramPageLotsListComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Output to tell parent to scroll to specific element within AnchorEnum
   *
   * @type {EventEmitter<AnchorEnum>}
   * @memberof ProgramPageLotsListComponent
   */
  @Output() readonly $shouldScrollTo = new EventEmitter<AnchorEnum>();

  /**
   * Colunms to display in the table header
   *
   * @type {string[]}
   * @memberof ProgramPageLotsListComponent
   */
  public displayedColumns: Array<string>;

  // Map[lotType][roomsNumber] = { lots: LotDetailResponse[], ds: MatTableDatasource }
  public dataSource: Map<string, Map<number, { lots: LotDetailResponse[]; dataSource: MatTableDataSource<LotDataSource> }>>;
  @ViewChildren(MatSort) sortList: QueryList<MatSort>;
  @ViewChildren(MatTable, { read: ElementRef })
  tableRefList: QueryList<ElementRef>;
  lotOpen: boolean;
  public expansionPanelHeaderHeight = '50px';
  private readonly destroyed$: Subject<boolean> = new Subject();
  private lotId: number;
  program: ProgramDetail;
  numberOfPointsToDisplay = 0;
  annexTypes = ['exteriorAnnexes', 'parkingAnnexes', 'storageAnnexes'];

  constructor(
    public i18nService: I18nService,
    public programService: ProgramService,
    public basicFormatsService: BasicFormatsService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly appConfigService: AppConfigService,
    private readonly _breakpointObserver: BreakpointObserver,
    private readonly searchFormUtilsService: SearchFormUtilsService,
    private readonly _prospectAssociationService: ProspectAssociationService,
    private readonly _comparatorPageService: ComparatorPageService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    public pageProgramHelperService: PageProgramHelperService,
    private _programPageService: ProgramPageService,
    private _appConfigService: AppConfigService,
    private _routingStateService: RoutingStateService,
  ) {}

  get tagSpecialOffer(): TagsInterface {
    return {
      ngClass: tagClass[TagTypeEnum.DEFAULT],
      tagOption: {
        prefixIcon: 'local_offer',
        text: 'offres spéciales',
        type: TagTypeEnum.DEFAULT,
      },
    };
  }

  get listLotsRecapTitle(): string {
    return this.programService
      .constructAvailableLotsString(this.program)
      .replace(/ /g, '&nbsp;')
      .replace(/&nbsp;\x2f&nbsp;/g, ' / ');
  }

  ngOnInit() {
    this.program = this.pageProgramHelperService.program;
    this._buildDataSource(this.program.lots);

    this._updateDisplayedColumns();

    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
      if (params.lotId) {
        this.lotId = Number(params.lotId);
        const selectedLot = this._programPageService.lotsIndexed[this.lotId];
        this.openDetailLotPanel(selectedLot);
      }
    });

    this._prospectAssociationService.lotIdUpdateToOptionObservable.pipe(takeUntil(this.destroyed$)).subscribe((lotIdUpdate) => {
      if (lotIdUpdate) {
        this._updateLot(lotIdUpdate);
      }
    });
  }

  ngAfterViewInit() {
    // Bind MatSort to datasources in the corresponding MatTables
    const tables = this.tableRefList.toArray();
    const sorts = this.sortList.toArray();
    for (const [table, sort] of zip(tables, sorts)) {
      const lotType = table.nativeElement.getAttribute('lotType');
      const nbRooms = +table.nativeElement.getAttribute('nbRooms');

      this.dataSource.get(lotType).get(nbRooms).dataSource.sort = sort;
    }
  }

  ngOnDestroy() {
    this.programService.setSelectedLotDetail(undefined);
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getPluralProgramTypeLabelMapping(programTypeLabel) {
    return {
      '=1': this.i18nService._(programTypeLabel).toLowerCase(),
      other: `${this.i18nService._(`PLURAL_${programTypeLabel}`)}`.toLowerCase(),
    };
  }

  getPluralRoomsMapping() {
    return {
      '=1': this.i18nService._('Txt_Page_Program_Room').toLowerCase(),
      other: this.i18nService._('Txt_Page_Program_Rooms').toLowerCase(),
    };
  }

  /**
   * Handle floor syntax to show 'RDC' instead of O
   *
   * @param {LotResponse} lots
   * @returns {string}
   * @memberof ProgramPageLotsListComponent
   */
  handleFloorSyntax(lots: LotDetailResponse): string {
    return Number(lots.floor) === 0 ? this.i18nService._('Txt_Page_Program_GroundFloor') : String(lots.floor);
  }

  /**
   * Route to open lot details
   *
   * @memberof ProgramPageLotsListComponent
   */
  public openLotDetails(programId: number): string {
    return `/${Sitemap.programs.read.path.replace(/:programId/, String(programId))}`;
  }

  /**
   * Emit selected lot id from table
   *
   * @param {MouseEvent} event
   * @param {number} selectedLotId
   * @memberof ProgramPageLotsListComponent
   */
  public showLotsInformations(event, selectedLotIndex: number, lotList: Array<LotDetailResponse>): void {
    this._programPageService.pushTagProgramPageLot(this.program, lotList, selectedLotIndex, 'click_lot');
    this._programPageService.pushTagProgramPageLot(this.program, lotList, selectedLotIndex, 'Lot_page');
    this.$shouldScrollTo.emit(AnchorEnum.ficheLot);
    event.stopImmediatePropagation();
    const selectedLot = lotList && lotList[selectedLotIndex];
    if (selectedLot && !event.ctrlKey) {
      this.router
        .navigate([this.openLotDetails(this.program.programId)], {
          replaceUrl: true,
          queryParams: { lotId: selectedLot.lotId },
        })
        .then(() => {
          this._routingStateService.removeLastHistoryEntry();
        });
    }
  }

  public accessLotsPrices(): void {
    sessionStorage.removeItem('savedSearchId');
    const searchParams = this.searchFormUtilsService.parseSearchProgramToFilterChain({
      where: {
        programId: this.program.programId,
      },
      order: {
        label: 'price',
        direction: 'ASC',
      },
    });
    searchParams.type = SearchPageDisplayType.Lot;
    const url = this.router.serializeUrl(
      this.router.createUrlTree([Sitemap.utils.search.path], {
        queryParams: searchParams,
      }),
    );
    this._googleTagManagerService.pushTag({ event: 'price_grid' });
    window.open(url, '_blank');
  }

  closePanel() {
    this.lotOpen = false;
    this.programService.setSelectedLotDetail(undefined);
    this.router.navigate([this.openLotDetails(this.program.programId)], {
      replaceUrl: true,
    });
    this.programService.setSelectedLotDetail(undefined);
  }

  public openOffer($event: Event, specialOffer: SpecialOfferDetailResponse): void {
    $event.stopImmediatePropagation();
    if (specialOffer) {
      const data: MatDialogConfig<SpecialOfferDetailResponse> = {
        autoFocus: false,
        data: specialOffer,
      };
      this.dialog.open(OfferSpecialDialogComponent, data);
    }
  }

  comparatorChange($event: boolean, element) {
    const lot = this._programPageService.lotsIndexed[element.id];
    this._comparatorPageService.pushToDatalayerComparatorChangeEvent({
      programId: this.program.programId,
      lot,
      isInserted: $event,
    });
  }

  /**
   * Create the datasource of lots to display in the page in the lot_type -> number_of_rooms -> lots[]
   * format from a flattened lot list
   * @param lots Flat list of lots
   */
  private _buildDataSource(lots: LotDetailResponse[]): void {
    this.dataSource = new Map();

    if (!lots || !lots.length) {
      return;
    }

    // Build nested type/rooms data structure
    for (const l of lots) {
      if (!this.dataSource.has(l.lotTypeLabel)) {
        this.dataSource.set(l.lotTypeLabel, new Map());
      }
      if (!this.dataSource.get(l.lotTypeLabel).has(l.rooms)) {
        this.dataSource.get(l.lotTypeLabel).set(l.rooms, { lots: [], dataSource: undefined });
      }
      this.dataSource.get(l.lotTypeLabel).get(l.rooms).lots.push(l);
    }

    // Generate data sources for MatTable
    for (const lotType of this.dataSource.values()) {
      for (const rooms of lotType.values()) {
        rooms.dataSource = this._getDataSource(rooms.lots);
      }
    }
  }

  private _updateLot(lotIdUpdate) {
    const lot = this._programPageService.lotsIndexed[lotIdUpdate.lotIdUpdateStatus];
    const lotDataSource = this.findLot(lotIdUpdate.lotIdUpdateStatus, this.dataSource.get(lot.lotTypeLabel).get(lot.rooms).dataSource);
    lot.status = lotIdUpdate.labelStatus;
    if (lotIdUpdate.labelStatus === this._appConfigService.getAppConfig().statusLotLabels.optioned && !lotIdUpdate.isAlert) {
      lot.isLotOptionedByUser = true;
    }
    lotDataSource.status = this._getStatusObject(lot);
  }

  private findLot(lotIdUpdateStatus, dataSource: MatTableDataSource<LotDataSource>): LotDataSource {
    return dataSource.data.find((lotVal) => lotVal.id === lotIdUpdateStatus);
  }

  private _getDataSource(lotsList): MatTableDataSource<LotDataSource> {
    const data = lotsList.map((lot, index) => {
      return {
        index,
        id: lot.lotId,
        lot: lot.lotNumber,
        surface: this.basicFormatsService.formatArea(lot.livingSpace),
        livingSpace: lot.livingSpace,
        floor: `${this.handleFloorSyntax(lot)} / ${this.i18nService._(`SHORT_${lot.lotOrientationLabel}`)}`,
        exteriorAnnexes: this.getExteriorAnnexesDescription(lot),
        parkingAnnexes: this.getParkingAnnexesDescription(lot),
        storageAnnexes: this.getStorageAnnexesDescription(lot),
        taxations: lot.taxations,
        strongPoints: lot.strongPoints,
        price: this._getFormatedLotPrice(lot),
        priceAsNumber: lot.commercialLotSellingPriceIT, // For sorting purposes
        vatPrice: this._checkReducedVAT(lot),
        workPrice: this.getWorkPrice(lot),
        status: this._getStatusObject(lot),
        specialOffer: lot.specialOffers && lot.specialOffers.length ? lot.specialOffers[0] : false,
        plan: this._getlotPlanDoc(lot),
        surfaceFloor: `${this.basicFormatsService.formatArea(lot.livingSpace)} / ${this.handleFloorSyntax(lot)}`,
      };
    });
    const dataSource = new MatTableDataSource<LotDataSource>(data);
    dataSource.sortingDataAccessor = sortingDataAccessor;

    return dataSource;
  }

  private getAnnexesDescription(lot, annexes, lotProperty): string {
    const counts = {};
    // Count occurrences of each annex in the lot
    lot[lotProperty]
      .filter((annex) => annexes.includes(annex.label || annex))
      .forEach((annex) => {
        const label = this.i18nService._(annex.label || annex);
        counts[label] = (counts[label] || 0) + 1;
      });

    // Format the counts into a string to display in the table cell [Ex: BALCONY, BALCONY * 2, BALCONY * 3 etc.]
    return Object.keys(counts)
      .map((label) => (counts[label] > 1 ? `${label} (*${counts[label]})` : label))
      .join('\r\n');
  }

  private getExteriorAnnexesDescription(lot): string {
    const exteriorAnnexes = ['TERRACE', 'BALCONY', 'LOGGIA', 'GARDEN'];
    return this.getAnnexesDescription(lot, exteriorAnnexes, 'areaAnnexes');
  }

  private getParkingAnnexesDescription(lot): string {
    const parkingAnnexes = ['GARAGE', 'OUTDOOR_PARKING', 'BASEMENT_PARKING_LOT', 'CARPORT'];
    return this.getAnnexesDescription(lot, parkingAnnexes, 'secondaryLots');
  }

  private getStorageAnnexesDescription(lot): string {
    const storageAnnexes = ['CELLAR', 'ATTIC', 'LARDER', 'BOXES'];
    return this.getAnnexesDescription(lot, storageAnnexes, 'secondaryLots');
  }

  private _updateDisplayedColumns() {
    const mdColumns = ['lot', 'surfaceFloor', 'price'];
    const lgColumns = ['comparator', 'lot', 'surface', 'plan', 'floor', 'taxations', 'price', 'status'];
    const xlColumns = ['comparator', 'lot', 'surface', 'plan', 'floor', 'annexes', 'strongPoints', 'taxations', 'price', 'status'];

    const updateColumns = (breakPoint, smallScreenColumns, largeScreenColumns) => {
      this.displayedColumns = breakPoint.matches ? smallScreenColumns : largeScreenColumns;
    };

    this._breakpointObserver
      .observe(breakpoints[BreakpointEnum.md].down)
      .pipe(
        tap((breakPoint) => updateColumns(breakPoint, mdColumns, lgColumns)),
        takeUntil(this.destroyed$),
      )
      .subscribe();

    this._breakpointObserver
      .observe(breakpoints[BreakpointEnum.xl].down)
      .pipe(
        tap((breakPoint) => updateColumns(breakPoint, lgColumns, xlColumns)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  /**
   * Check reduced Taxation from lot for Reduced VAT column
   *
   * For now (VALO-427), only one reduced Taxation
   *
   * @param {LotResponse} lots
   * @returns {string}
   * @memberof ProgramPageLotsListComponent
   */
  private _checkReducedVAT(lots: LotDetailResponse): string {
    const reducedTaxation: TaxationDetailResponse = lots && lots.taxations.find((element) => element.reducedTaxation);
    if (reducedTaxation) {
      // Show taxation pourcent on html
      lots.vatPercent = `${this.i18nService._('Txt_Page_Program_VAT')} ${this.basicFormatsService.formatPercentage(
        reducedTaxation.taxation / 100,
      )}`;
      // RG_427.8 : If multiple taxation, calculate reduced VAT price. Else show the commercialLotSellingPriceIT
      if (lots.taxations.length > 1) {
        return this.basicFormatsService.formatCurrencyCeil(lots.reducedTotalSellingPriceIT, undefined, 0, 0, 0);
      }

      return this.basicFormatsService.formatCurrencyCeil(lots.commercialLotSellingPriceIT, undefined, 0, 0, 0);
    }

    return '';
  }

  /**
   * Get works price from lot
   *
   * @param {LotResponse} lot
   * @returns {String}
   * @memberof ProgramPageLotsListComponent
   */
  public getWorkPrice(lot: LotDetailResponse): string {
    const shouldDisplayWorkPrice = lot?.taxations.find(
      (element) =>
        element.label.includes('MONUMENTS HISTORIQUES') || element.label.includes('MALRAUX') || element.label.includes('DEFICIT FONCIER'),
    );

    if (shouldDisplayWorkPrice && lot.estimatedWorkPrice) {
      const workPrice = lot.estimatedWorkPrice;
      return this.basicFormatsService.formatCurrencyCeil(workPrice, undefined, 0, 0, 0);
    }
    return '';
  }

  public formatStrongPoints(lot: LotDetailResponse) {
    return lot.strongPoints.map((strongPoint) => strongPoint.name);
  }

  private openDetailLotPanel(selectedLot: LotDetailResponse) {
    this.programService.setSelectedLotDetail(selectedLot);
    this.lotOpen = true;
  }

  private _getlotPlanDoc(lot) {
    return {
      title: lot.lotPlanTitle,
      container: lot.lotPlanContainer,
      fileName: lot.lotPlanFileName,
      codeLabel: lot.lotPlanCode,
      mimeType: lot.lotPlanMimeType,
    };
  }

  private _getFormatedLotPrice(lot) {
    return lot.taxations.length === 1 && Boolean(lot.taxations.find((element) => element.reducedTaxation))
      ? '-'
      : this.basicFormatsService.formatCurrency(lot.commercialLotSellingPriceIT, undefined, 0, 0, 4);
  }

  /**
   * _getStatusObject method
   *
   * @param lot
   * @memberof ProgramPageLotsListComponent
   */
  private _getStatusObject(lot: LotDetailResponse): StatusObjectI {
    const statusLotLabels = this.appConfigService.getAppConfig().statusLotLabels;
    const statusObject: StatusObjectI = {
      [statusLotLabels.free]: {
        label: this.i18nService._('Txt_Detail_Program_Status_Lot_Free'),
        icon: 'check_circle_outline',
        isOptionedByOther: false,
        isOptionedByMe: false,
        unavailable: false,
        isFree: true,
      },
      [statusLotLabels.optioned]: {
        label: this.i18nService._('Txt_Detail_Program_Status_Lot_Optioned'),
        icon: 'account_circle',
        isOptionedByOther: !lot.isLotOptionedByUser,
        isOptionedByMe: lot.isLotOptionedByUser,
        unavailable: false,
        isFree: false,
      },
      [statusLotLabels.unavailable]: {
        label: this.i18nService._('Txt_page_program_lots_status_unavailable'),
        icon: 'error_outline',
        isOptionedByOther: false,
        isOptionedByMe: false,
        unavailable: true,
        isFree: false,
      },
    };

    return statusObject[lot.status];
  }

  getTaxationsTooltip(taxations: Array<TaxationDetailResponse>): string {
    return taxations
      .slice(2)
      .map((taxation) => this.i18nService._(taxation.label))
      .join(', ');
  }
}
