import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';

import { LoginResponse } from './utils/models/LoginResponse';
import { AppConfigService } from './utils/services/app-config.service';
import { TokenService } from './utils/services/authorisation/token.service';
import { CacheService } from './utils/services/cache.service';

@Injectable()
export class AppInitService {
  constructor(
    private readonly appConfig: AppConfigService,
    private readonly http: HttpClient,
    private readonly _cacheService: CacheService,
    private readonly tokenService: TokenService,
  ) {}

  private static _token: LoginResponse;

  setToken(token: LoginResponse): void {
    AppInitService._token = token;
  }

  public deleteToken(): void {
    AppInitService._token = undefined;
  }

  public getToken(): LoginResponse {
    return AppInitService._token;
  }

  public async init(): Promise<LoginResponse> {
    return this.http
      .get<LoginResponse>(`${this.appConfig.getLoopbackApiUrl()}/Accounts/get-connected-user`)
      .pipe(
        tap((res) => {
          this.tokenService.setOldToken(res);
        }),
      )
      .toPromise();
  }
}
