<div class="flex flex-col w-full">
  <ng-select
    [searchable]="searchable"
    [items]="items | sortPipe: itemsSortBy : 'ASC'"
    bindLabel="label"
    [bindValue]="bindValue"
    [multiple]="true"
    [maxSelectedItems]="maxSelectedItems"
    [placeholder]="placeholder"
    name="fieldNameId"
    [(ngModel)]="values"
    [disabled]="readonly"
    [attr.data-cy]="placeholder"
    notFoundText="{{ i18nService._('Error_Field_Incorrect') }}"
    (change)="onSelectedItemChange($event)"
  >
  </ng-select>
  <mat-error *ngIf="dropdownControl && dropdownControl.errors && (firstLoad || isSubmit)" class="-mt-3">
    {{ i18nService._('Error_Field_Required') }}
  </mat-error>
</div>
