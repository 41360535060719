import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { NgClass, NgFor, NgIf } from '@angular/common';

import { I18nService } from '../../../utils/services/i18n.service';
import { IconComponent } from '../../standalone/icon/icon.component';

/**
 * Stepper props:
 * isHead: Big title on sidebar
 * subHead: small title on sidebar
 * headId: ref to an isHead element id
 * stepActionOnFieldKey: allow to change step with the field key
 * formTitle: header
 */
@Component({
  selector: 'app-formly-simple',
  templateUrl: './formly-simple.component.html',
  styleUrls: ['./formly-simple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, NgClass, NgIf, IconComponent],
})
export class FormlySimpleComponent extends FieldType<FieldTypeConfig> implements AfterViewInit, OnDestroy {
  private readonly $destroy: Subject<void> = new Subject<void>();

  constructor(
    public readonly i18nService: I18nService,
    private readonly detectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.detectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }

  isValid(field: FormlyFieldConfig): boolean {
    if (field.hide) {
      return true;
    }
    if (field.fieldGroup?.length) {
      return field.fieldGroup.every((f) => this.isValid(f));
    }

    if (field.key) {
      return !!field?.formControl?.valid;
    }
  }
}
