import { Component, OnInit } from '@angular/core';

import { Target2SellEnum } from '../../utils/models/enums/target-2-sell.enum';
import { I18nService } from './../../utils/services/i18n.service';
import { Target2SellRecoComponent } from '../../utils/components/target2sell-reco/target2sell-reco.component';

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss'],
  standalone: true,
  imports: [Target2SellRecoComponent],
})
export class RecommendationsComponent implements OnInit {
  public target2SellRecoIdSection1: string;
  public target2SellRecoIdSection2: string;
  public target2SellRecoIdSection3: string;

  constructor(public readonly i18nService: I18nService) {}

  ngOnInit(): void {
    this.target2SellRecoIdSection1 = Target2SellEnum.TARGET_2_SELL_RECO_PAGE_SECTION1;
    this.target2SellRecoIdSection2 = Target2SellEnum.TARGET_2_SELL_RECO_PAGE_SECTION2;
    this.target2SellRecoIdSection3 = Target2SellEnum.TARGET_2_SELL_RECO_PAGE_SECTION3;
  }
}
