<button
  [class.primary]="type === ButtonType.PRIMARY"
  [class.secondary]="type === ButtonType.SECONDARY"
  [class.tertiary]="type === ButtonType.TERTIARY"
  [class.quadrary]="type === ButtonType.QUADRARY"
  (click)="click()"
  [title]="title"
>
  <ng-content></ng-content>
</button>
