import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';

import { LotFeesHistoryResponse } from '../model/lot-fees-history-response';

import { AppConfigService } from '../../utils/services/app-config.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class HistoryLotFeesService {
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
  ) {}

  getFeesHistoryByLot(lotId: number): Observable<Array<LotFeesHistoryResponse>> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/LotFeesHistories/lot/${lotId}`;

    return this.http
      .get<Array<LotFeesHistoryResponse>>(url)
      .pipe(catchError(this.errorHandlerService.handleError<Array<LotFeesHistoryResponse>>('lotService', 'getLots')));
  }
}
