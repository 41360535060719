import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../security/authguard';
import { Sitemap } from '../utils/models/Sitemap';
import { LotComparatorComponent } from './components/lot-comparator/lot-comparator.component';
import { ComparatorResolver } from './components/comparator.resolver';

const routes: Routes = [
  {
    path: Sitemap.comparator.lot.path,
    component: LotComparatorComponent,
    canActivate: mapToCanActivate([AuthGuard]),
    resolve: { lotToCompare: ComparatorResolver },
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
  declarations: [],
})
export class ComparatorRoutingModule {}
