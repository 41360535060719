<div class="flex-col bg-white rounded-10 shadow-custom px-8 py-6 h-full">
  <h3 class="font-semibold text-xl mb-4">{{ i18nService._('txt_document_add_spe') }}</h3>
  <div (click)="openModalDocument()" class="flex items-center mb-6 hover:cursor-pointer">
    <div class="flex items-center justify-center w-5 h-5 rounded-full bg-orange-500 mr-2">
      <app-icon class="text-white flex" name="Plus"></app-icon>
    </div>
    <span class="font-medium hover:underline hover:underline-offset-2">{{ i18nService._('txt_document_add') }}</span>
  </div>
  <div class="flex flex-row flex-wrap h-fit gap-2">
    <div *ngFor="let document of documents" class="flex items-center border-2 h-8 border-slate-900 rounded-md w-fit max-w-full">
      <div class="flex items-center mx-2 h-fit truncate">
        <p
          (click)="downloadDoc(document)"
          class="text-xs font-semibold max-w-full truncate hover:cursor-pointer"
          title="{{ document?.title }}"
        >
          {{ document?.title }}
        </p>
      </div>
      <div
        (click)="deleteDoc(document.id)"
        class="flex items-center justify-center hover:cursor-pointer hover:bg-black min-w-[32px] h-8 rounded-md hover:text-white"
      >
        <app-icon name="XMark"></app-icon>
      </div>
    </div>
  </div>
</div>
