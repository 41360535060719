import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { NgFor, NgIf } from '@angular/common';

import { CompanyContact } from '../../../utils/models/CompanyContact';
import { CompanyData } from '../../../utils/models/CompanyData';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { ReferenceTableData } from '../../../utils/models/ReferenceTableData';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { ContactInformationService } from '../../../utils/services/contact-information.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { ReferenceTablesService } from '../../../utils/services/reference-tables.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { ContactManager } from '../../models/ContactManager';
import { CompanyService } from '../../services/company.service';
import { ContactInformationComponent } from '../../../utils/components/contact-information/contact-information.component';

@Component({
  selector: 'app-step-two-form-company',
  templateUrl: './step-two-form-company.component.html',
  styleUrls: ['./step-two-form-company.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    ContactInformationComponent,
    MatRadioModule,
    NgFor,
    MatFormFieldModule,
    MatButtonModule,
    MatStepperModule,
  ],
})
export class StepTwoFormCompanyComponent implements OnInit, OnDestroy {
  /**
   * ParentForm to add controls to
   *
   * @type {FormGroup}
   * @memberof StepTwoFormCompanyComponent
   */
  @Input() parentForm: UntypedFormGroup;
  @Input() recoveredInfo: CompanyData;
  @Input() validatedCompany = false;

  /**
   * isSubmit
   *
   * @type {boolean}
   * @memberof StepTwoFormCompanyComponent
   */
  public isSubmit: boolean;
  dataIsLoad = false;
  contactToCheckEmail: ContactManager;

  appConfig;

  showLegalBlock = false;
  showMandatoryBlock = false;
  isMandateDelegationValue = false;

  listAdminOptions: Array<ReferenceTableData> = [];

  contactAdminTypeIdControl: FormControl;
  /**
   * Step index.
   *
   * @type {number}
   * @memberof StepOneFormCompanyComponent
   */
  public readonly index = 1;
  /**
   * Contacts manager of three contact type : legal, applicant, mandate.
   *
   * @type {{[key:string]}: ContactManager}
   * @memberof StepOneFormCompanyComponent
   */
  public contacts = {
    legal: new ContactManager(),
    applicant: new ContactManager(),
    mandate: new ContactManager(),
  };
  /**
   * previouslySelectedIndex$
   *
   * @type {Subscription}
   * @memberof StepTwoFormCompanyComponent
   */
  private readonly previouslySelectedIndex$: Subscription;

  /**
   * Creates an instance of StepTwoFormCompanyComponent.
   * @todo listAdminOptions and booleans can be replacing by conditons process of parentForm forms.
   *
   * @param {I18nService} i18nService
   * @param {CompanyService} companyService
   * @param {ReferenceTablesService} referenceTablesService
   * @param {AppConfigService} appConfigService
   * @param {SnackbarService} snackbarService
   * @param {ContactInformationService} contactInformationService
   *
   * @memberof StepTwoFormCompanyComponent
   */
  constructor(
    public readonly i18nService: I18nService,
    private readonly companyService: CompanyService,
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly appConfigService: AppConfigService,
    private readonly snackbarService: SnackbarService,
    private readonly contactInformationService: ContactInformationService,
  ) {
    this.appConfig = this.appConfigService.getAppConfig();

    // Process submitStep when user click on stepper.
    this.previouslySelectedIndex$ = this.companyService.previouslySelectedIndex.subscribe((index: number) => {
      if (index === this.index) {
        this.submitStep();
      }
    });
  }

  ngOnInit(): void {
    this.contactAdminTypeIdControl = this.parentForm.parent.get('contactAdminTypeId') as FormControl;

    // Get list of companyContact List.
    this.referenceTablesService.getTableReferenceInfo('CompanyContactTypes').subscribe(
      (companyContactTypes) => {
        // set company contact type
        this.contacts.legal.type = companyContactTypes.find(
          (contact) => contact.label === this.appConfig.companyContactType.LEGAL_REPRESENTATIVE,
        );
        this.contacts.applicant.type = companyContactTypes.find((contact) => contact.label === this.appConfig.companyContactType.APPLICANT);
        this.contacts.mandate.type = companyContactTypes.find(
          (contact) => contact.label === this.appConfig.companyContactType.MANDATE_SIGNATORY,
        );

        // by default, add optionApplicant to admin list options
        this.listAdminOptions.push(this.contacts.applicant.type);

        // by default, add legal form to parent form
        this.parentForm.addControl('legal', this.contacts.legal.form);

        // if we have an Input we InitData
        if (this.recoveredInfo) {
          this.initData();
        } else {
          this.dataIsLoad = true;
        }

        this.companyService.changesDetected.next(true);
      },
      () => {
        this.snackbarService.sendErrorOccured();
      },
    );
  }

  /**
   * Check admin and control email.
   *
   * @memberof StepTwoFormCompanyComponent
   */
  public updateContactAdminTypeId(): void {
    // if we find contact type in admin UI list, update validator
    if (this.listAdminOptions.find((contactAdminType) => contactAdminType.id === this.contactAdminTypeIdControl.value)) {
      this.contactAdminTypeIdControl.updateValueAndValidity();
    } else {
      // else set default admin contact to applicant
      this.contactAdminTypeIdControl.setValue(this.contacts.applicant.type.id);
    }
  }

  /**
   * Is legal representative option process.
   *
   * @memberof StepTwoFormCompanyComponent
   */
  public isLegalRepresentative(isLegalRepresentative): void {
    if (isLegalRepresentative) {
      this.listAdminOptions.push(this.contacts.legal.type);
      this.contacts.applicant.form.reset();
      this.parentForm.addControl('applicant', this.contacts.applicant.form);
    } else {
      this.removeContactAdminType(this.contacts.legal.type.label);
      this.parentForm.removeControl('applicant');
    }

    this.showLegalBlock = isLegalRepresentative;

    this.updateContactAdminTypeId();
  }

  /**
   * To know if the applicant is the signatory of the mandate.
   *
   * @memberof StepTwoFormCompanyComponent
   */
  public isMandatorySignatory(isMandatorySignatory): void {
    if (isMandatorySignatory) {
      this.listAdminOptions.push(this.contacts.mandate.type);
      this.contacts.mandate.form.reset();
      this.parentForm.addControl('mandate', this.contacts.mandate.form);
    } else {
      this.removeContactAdminType(this.contacts.mandate.type.label);
      this.parentForm.removeControl('mandate');
    }

    this.showMandatoryBlock = isMandatorySignatory;

    this.updateContactAdminTypeId();
  }

  /**
   * If there is a delegation of mandate.
   *
   * @memberof StepTwoFormCompanyComponent
   */
  public isMandateDelegation(isMandateDelegation): void {
    if (!isMandateDelegation) {
      this.isMandatorySignatory(false);
    }
    // notify service
    this.companyService.isMandatDelegation.next(isMandateDelegation);
    this.isMandateDelegationValue = isMandateDelegation;
  }

  /**
   * Prepare contact values according to options and recovery data.
   *
   * @memberof StepTwoFormCompanyComponent
   */
  public prepareValue(): Array<CompanyContact> {
    const values = this.parentForm.value;

    if (this.contacts.legal.id) {
      values.legal.id = this.contacts.legal.id;
    }
    values.legal.companyContactTypeId = this.contacts.legal.type.id;

    if (!this.showLegalBlock) {
      values.applicant = { ...values.legal };
    }

    if (this.isMandateDelegationValue && !this.showMandatoryBlock) {
      values.mandate = { ...values.applicant };
    }

    this._setContact(values.applicant, this.contacts.applicant, this.contacts.legal, this.showLegalBlock);
    this._setContact(values.mandate, this.contacts.mandate, values.applicant, this.showMandatoryBlock);

    return Object.values(values);
  }

  ngOnDestroy(): void {
    this.previouslySelectedIndex$.unsubscribe();
  }

  private _setContact(value, contact, contactCompare, isBlockShow: boolean): void {
    if (value) {
      value.companyContactTypeId = contact.type.id;
      if (contact.id) {
        value.id = contact.id === contactCompare.id && isBlockShow ? null : contact.id;
      }
    }
  }

  /**
   * Init data
   *
   * @memberof StepTwoFormCompanyComponent
   */
  private initData(): void {
    // set initial value
    this.recoveredInfo.contacts.forEach((contact) => {
      contact.companyContactTypes.forEach((companyContactType) => {
        switch (companyContactType.id) {
          case this.contacts.legal.type.id:
            this.contacts.legal.data = { ...contact };
            this.contacts.legal.id = this.contacts.legal.data.id;
            delete this.contacts.legal.data.companyContactTypes;
            break;
          case this.contacts.applicant.type.id:
            this.contacts.applicant.data = { ...contact };
            this.contacts.applicant.id = this.contacts.applicant.data.id;
            delete this.contacts.applicant.data.companyContactTypes;
            break;
          case this.contacts.mandate.type.id:
            this.contacts.mandate.data = { ...contact };
            this.contacts.mandate.id = this.contacts.mandate.data.id;
            delete this.contacts.mandate.data.companyContactTypes;
            break;
          default:
            break;
        }
      });
    });

    // prepare applicant form
    if (this.contacts.applicant.data && this.contacts.applicant.id !== this.contacts.legal.id) {
      this.isLegalRepresentative(true);
    } else {
      this.contacts.applicant.data = undefined;
      this.isLegalRepresentative(false);
    }

    // prepare mandate form
    if (this.contacts.mandate.data) {
      this.isMandateDelegation(true);
      if (this.contacts.mandate.id !== this.contacts.applicant.id) {
        this.isMandatorySignatory(true);
      } else {
        this.contacts.mandate.data = undefined;
        this.isMandatorySignatory(false);
      }
    } else {
      this.isMandateDelegation(false);
    }

    // set contact type admin.
    this.contactAdminTypeIdControl.setValue(this.recoveredInfo.contactAdminTypeId);

    this.dataIsLoad = true;
  }

  /**
   * Remove contact admin type option.
   *
   * @param contact
   * @memberof StepTwoFormCompanyComponent
   */
  private removeContactAdminType(contact: string): void {
    this.listAdminOptions = this.listAdminOptions.filter((option) => option.label !== contact);
  }

  /**
   * Get Submit on second step.
   *
   * @memberof StepTwoFormCompanyComponent
   */
  private submitStep(): void {
    this.isSubmit = true;

    if (this.parentForm.invalid) {
      const message: SnackbarMessage = {
        text: this.i18nService._('Error_SnackBar_ContactIncomplete'),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
    } else {
      this.isSubmit = false;
    }
  }
}
