<ng-container *ngIf="!lotAction.hide">
  <button *ngIf="lotAction.checkIfOptionCanBePreReserved" mat-flat-button color="primary" type="button" (click)="openPreReservationPopin()">
    {{ i18nService._('Txt_Button_PreReserve') }}
  </button>

  <button
    *ngIf="lotAction.checkIfOptionCanBeTaken"
    mat-flat-button
    color="primary"
    data-cy="Demander une option"
    type="button"
    (click)="checkValidity()"
  >
    {{
      lotAction.checkIfOptionCanBeAutomaticallyTaken ? i18nService._('Txt_Button_TakeOption') : i18nService._('Txt_Button_RequestOption')
    }}
    <!-- Here is the button action -->
  </button>

  <button
    *ngIf="lotAction.checkIfOptionCanOnlyBeRemoved"
    mat-flat-button
    color="primary"
    type="button"
    (click)="callRemoveOption()"
    data-cy="Lever l'option"
  >
    {{ i18nService._('Txt_Button_RemoveOptionLong') }}
  </button>

  <button *ngIf="lotAction.checkIfAlertCanBeTaken" mat-flat-button color="primary" type="button" (click)="takeAlert()">
    {{ i18nService._('Txt_Button_TakeAlert') }}
  </button>

  <button *ngIf="message" mat-flat-button class="disabled-button" disabled type="button">
    {{ i18nService._('Txt_Button_TakeAlert') }}
  </button>

  <div class="lot-option-sub-action" *ngIf="lotAction.hasSubAction">
    <div *ngIf="lotAction.checkIfOptionCanBeRemoved" (click)="callRemoveOption()">
      {{ i18nService._('Txt_Button_RemoveOptionLong') }}
    </div>
    <div *ngIf="lotAction.checkIfOptionCanBeExtended" [ngClass]="{ disabled: ongoingCall }" (click)="callExtendedOption()">
      {{ i18nService._('Txt_Button_ExtendOption') }}
    </div>
  </div>
</ng-container>
