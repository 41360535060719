<div class="text-lg font-bold mb-3">
  {{ isActionCategory() ? i18nService._('Title_Activities') : i18nService._('Title_Notifications') }}
</div>

<div *ngIf="activities && activities.length; else noActivities">
  <div ngClass="isActionCategory() ? activite : notification" *ngFor="let activity of activities">
    <div class="mb-3">
      <mat-card
        appearance="outlined"
        routerLink="{{ getActivityURI(activity) }}"
        [queryParams]="getActivityParams(activity)"
        class="grid grid-cols-12 gap-4 mb-1 py-2 bg-white-trans75 cursor-pointer hover:bg-slate-50"
      >
        <div class="col-span-5">
          <mat-card-content class="mb-0">
            <span class="text-sm font-bold">
              {{ activity.creationDate | date: 'dd/LL/yyyy - HH:mm' }}
            </span>
          </mat-card-content>
        </div>

        <div class="col-span-7">
          <mat-card-content class="mb-0">
            <span class="text-sm">
              {{ activity.template }}
            </span>
          </mat-card-content>
        </div>
      </mat-card>
    </div>
  </div>
</div>

<!-- No activities -->
<ng-template #noActivities>
  <mat-card appearance="outlined" class="mb-1 py-2 bg-white-trans75 cursor-pointer hover:bg-slate-50">
    <mat-card-content>
      <span class="text-center text-sm">
        {{
          isActionCategory() ? i18nService._('Txt_FastSearchActivities_No_Result') : i18nService._('Txt_FastSearchNotifications_No_Result')
        }}
      </span>
    </mat-card-content>
  </mat-card>
</ng-template>

<div class="text-right">
  <a (click)="navigateToActivities()" class="txt-orange no-underline uppercase font-bold text-sm">
    {{ isActionCategory() ? i18nService._('Txt_Link_Activities') : i18nService._('Txt_Link_Notifications') }}
  </a>
</div>
