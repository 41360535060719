import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { takeUntil } from 'rxjs';
import { NgClass, NgFor, NgIf, UpperCasePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { LotComparatorMetaLineComponent } from '../lot-comparator-meta-line/lot-comparator-meta-line.component';

import { TagOption, TagsInterface } from '../../../../design-system/model/tag-option';
import { ProspectAssociationService } from '../../../../dialog/services/prospect-association.service';
import { LotComparatorDetail } from '../../../../lot/model/lot-comparator-detail';
import { PageLotService } from '../../../../lot/service/page-lot.service';
import { programTags } from '../../../../programs/models/enum/program-tag.enum';
import { DocumentResponse } from '../../../../utils/models/DocumentResponse';
import { BasicFormatsService } from '../../../../utils/services/basic-formats.service';
import { FileSystemService } from '../../../../utils/services/file-system.service';
import { ComparatorMeta, ComparatorMetaWrapper } from '../../../models/comparator-meta';
import { ComparatorMetaPartial } from '../../../models/comparator-meta-partial';
import { AbstractComparatorComponent } from '../../abstract-comparator-component';
import { AsPipe } from '../../../../utils/pipes/as.pipe';
import { StrongPointListComponent } from '../../../../design-system/component/strong-point-list/strong-point-list.component';
import { TagsComponent } from '../../../../design-system/component/tags/tags.component';

@Component({
  selector: 'app-lot-comparator-glance',
  templateUrl: './lot-comparator-glance.component.html',
  styleUrls: ['./lot-comparator-glance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    NgIf,
    TagsComponent,
    NgClass,
    NgFor,
    StrongPointListComponent,
    LotComparatorMetaLineComponent,
    UpperCasePipe,
    AsPipe,
  ],
})
export class LotComparatorGlanceComponent extends AbstractComparatorComponent implements OnInit, AfterViewInit {
  @Input() lot: LotComparatorDetail;
  @Input() lotDetailToCompare: LotComparatorDetail[];
  lotMeta: ComparatorMetaWrapper;
  specialOfferTags: TagsInterface;
  taxationsTag: TagsInterface[];
  annexesTags: TagsInterface[];
  delivryDate: string;
  ComparatorMetaPartial = ComparatorMetaPartial;

  @ViewChild('priceRef', { static: true }) public priceRef: TemplateRef<unknown>;
  @ViewChild('roomAreaPlanRef', { static: true })
  public roomAreaPlanRef: TemplateRef<unknown>;
  @ViewChild('specialofferRef', { static: true })
  public specialofferRef: TemplateRef<unknown>;
  @ViewChild('taxationRef', { static: true })
  public taxationRef: TemplateRef<unknown>;
  @ViewChild('profitabilityRef', { static: true })
  public profitabilityRef: TemplateRef<unknown>;
  @ViewChild('actableRef', { static: true })
  public actableRef: TemplateRef<unknown>;
  @ViewChild('annexeRef', { static: true }) public annexeRef: TemplateRef<unknown>;
  @ViewChild('lotStrongPointsRef', { static: true }) public lotStrongPointsRef: TemplateRef<unknown>;
  @ViewChild('delivryDateRef', { static: true })
  public delivryDateRef: TemplateRef<unknown>;

  constructor(
    private readonly _fileSystemService: FileSystemService,
    private readonly _router: Router,
    private readonly _pageLotService: PageLotService,
    public readonly basicFormatsService: BasicFormatsService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _prospectAssociationService: ProspectAssociationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this._prospectAssociationService.lotIdUpdateToOptionObservable.pipe(takeUntil(this.$d)).subscribe(() => {
      this._changeDetectorRef.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    this.lotMeta = this._comparatorPageService.comparatorMeta.glance;
    this.setTagSpecialOffer();

    (this.lotMeta.commercialLotSellingPriceIT as ComparatorMeta).templateRef = this.priceRef;
    (this.lotMeta.rooms as ComparatorMeta).templateRef = this.roomAreaPlanRef;
    (this.lotMeta.specialOffer as ComparatorMeta).templateRef = this.specialofferRef;
    (this.lotMeta.taxationsLabels as ComparatorMeta).templateRef = this.taxationRef;
    (this.lotMeta.profitability as ComparatorMeta).templateRef = this.profitabilityRef;
    (this.lotMeta.actable as ComparatorMeta).templateRef = this.actableRef;
    (this.lotMeta.deliveryDate as ComparatorMeta).templateRef = this.delivryDateRef;
    (this.lotMeta.annexes as ComparatorMeta).templateRef = this.annexeRef;
    (this.lotMeta.lotStrongPoints as ComparatorMeta).templateRef = this.lotStrongPointsRef;

    setTimeout(() => {
      this._changeDetectorRef.markForCheck();
    }, 0);
  }

  public getAnnexesTags(lot: LotComparatorDetail) {
    return this._comparatorPageService.extractAnnexTags(lot.annexes);
  }

  openDocumentInBrowser($event: Event, document: DocumentResponse, name?: string): void {
    if ($event) {
      $event.stopImmediatePropagation();
    }
    this._fileSystemService.openDocumentInBrowser(
      document,
      name ? name : this.i18nService._(`Txt_Commercial_Program_Sheet_${document.codeLabel}`),
    );
  }

  getDeliveryDate(lotDetail: LotComparatorDetail) {
    if (lotDetail.deliveryDate) {
      const delivryDate = DateTime.fromJSDate(new Date(lotDetail.deliveryDate)).toFormat(`q'T 'yyyy`);

      return this.i18nService._('TXT_comparator_glance_delivry_date', [delivryDate]);
    }

    return '-';
  }

  private setTagSpecialOffer() {
    const tag = { ...programTags.hasSpecialOffer };
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    tag.tagOption = { ...programTags.hasSpecialOffer.tagOption } as TagOption;
    tag.tagOption.text = this.i18nService._('Txt_Special_Offer');
    this.specialOfferTags = tag;
  }
}
