import { Component, Input } from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AdvancedTrackingTableColumn } from '../../../../models/AdvancedTrackingTableColumn';
import { I18nService } from '../../../../../utils/services/i18n.service';
import { InputDateComponent } from '../../../../../utils/components/input-date/input-date.component';

@Component({
  selector: 'app-date-filters-tracking-table',
  templateUrl: './date-filters-tracking-table.component.html',
  styleUrls: ['./date-filters-tracking-table.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputDateComponent],
})
export class DateFiltersTrackingTableComponent {
  @Input() selfFilterForm: UntypedFormGroup;
  @Input() tableColumn: AdvancedTrackingTableColumn;

  constructor(public readonly i18nService: I18nService) {}
}
