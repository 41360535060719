import { ReCaptchaV3Service } from 'ngx-captcha';
import { Injectable } from '@angular/core';

import { RecaptchaResponse } from '../models/RecaptchaResponse';
import { AppConfigService } from './app-config.service';
import { ConfigurableParamService } from './configurable-param.service';

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  private RECAPTCHA_PUBLIC_KEY: string;
  private readonly isProdEnvironment: boolean;
  #retryLoadingRecaptcha: string;

  constructor(
    private readonly reCaptchaV3Service: ReCaptchaV3Service,
    private readonly appConfigService: AppConfigService,
    private readonly configurableParamService: ConfigurableParamService,
  ) {
    this.setRecaptchaPublicKey().catch((error) => {
      console.error(error);
    });
    this.isProdEnvironment = this.appConfigService.isProdEnvironment();
    this.#retryLoadingRecaptcha = this.appConfigService._('actionNameRecaptchaV3', 'retry_loading');
  }

  public async setRecaptchaPublicKey(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.configurableParamService.getConfigParam('RECAPTCHA_PUBLIC_KEY').subscribe(
        (recaptchaPublicKey) => {
          const publicKey = recaptchaPublicKey ? recaptchaPublicKey['RECAPTCHA_PUBLIC_KEY'] : null;
          if (publicKey) {
            this.RECAPTCHA_PUBLIC_KEY = publicKey;
            resolve();
          } else {
            reject('Recaptcha public key not found');
          }
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  public async setRecaptchaTokeninit(action: string = this.#retryLoadingRecaptcha): Promise<RecaptchaResponse> {
    return new Promise((resolve, reject) => {
      if (!this.isProdEnvironment) {
        resolve({
          created: Date.now(),
          token: 'exempleToken',
        });
      } else {
        this.reCaptchaV3Service.execute(
          this.RECAPTCHA_PUBLIC_KEY,
          action,
          (token) => {
            resolve({
              created: Date.now(),
              token: btoa(token),
            });
          },
          {
            useGlobalDomain: false,
          },
          (error) => {
            console.error('setRecaptchaTokeninit: error when trying to set token', error);
            reject(error);
          },
        );
      }
    });
  }

  async getToken(): Promise<string> {
    return await (
      await this.getRecaptchaResponse()
    ).token;
  }

  async getRecaptchaResponse(): Promise<RecaptchaResponse> {
    return this.setRecaptchaTokeninit();
  }
}
