import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { DataLayerApiService } from './data-layer-api.service';
import { AppConfigService } from '../utils/services/app-config.service';
import { ErrorHandlerService } from '../utils/services/error-handler.service';

@Injectable({ providedIn: 'root' })
export class DossierApiService extends DataLayerApiService {
  constructor(
    readonly appConfigService: AppConfigService,
    readonly errorHandlerService: ErrorHandlerService,
    readonly httpClient: HttpClient,
  ) {
    super(appConfigService, errorHandlerService, httpClient);
    this.SERVICE_URL = appConfigService.getDematApiUrl();
  }

  /**
   * Return true if user can reset the dossier
   *
   * @returns {Observable<DossierResponse>}
   * @memberof DataLayerApiService
   */
  async canResetDossier(dossierId: number): Promise<boolean> {
    const path = `dossiers/${dossierId}/canReset`;
    const res: Observable<boolean> = this.get(path);
    return await lastValueFrom(res);
  }

  /**
   * Delete Kbis document from the dossier
   *
   * @returns {Observable<void>}
   * @memberof DataLayerApiService
   */
  async deleteKBISFromDossier(dossierId: number): Promise<void> {
    const path = `dossiers/${dossierId}/KBIS`;
    const res: Observable<void> = this.delete(path);
    return await lastValueFrom(res);
  }
}
