import { CdkStepper } from '@angular/cdk/stepper';
import { Component, QueryList } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

import { SmallStepperStepComponent } from './small-stepper-step/small-stepper-step.component';

@Component({
  selector: 'app-small-stepper',
  templateUrl: './small-stepper.component.html',
  styleUrls: ['./small-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: SmallStepperComponent }],
  standalone: true,
  imports: [NgTemplateOutlet],
})
export class SmallStepperComponent extends CdkStepper {
  override readonly steps: QueryList<SmallStepperStepComponent> = new QueryList<SmallStepperStepComponent>();
}
