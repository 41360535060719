<app-horizontal-card class="w-full">
  <div class="flex flex-col items-start">
    <span class="text-xl font-bold text-black-light">{{ paternity.companyName }}</span>
    <div class="mb-3 flex flex-wrap">
      <div class="mr-3 whitespace-nowrap">
        <span class="text-xs font-semibold text-black">{{ _i18n._('Prospect_Paternity_Creation_Date') }}</span>
        <span class="text-xs font-semibold text-purple-primary">{{ paternity.creationDate | date: "dd/MM/yyyy 'à' hh'h'mm" }}</span>
      </div>
      <div class="whitespace-nowrap" *ngIf="paternity.expirationDate">
        <span class="text-xs font-semibold text-black">{{ _i18n._('Prospect_Paternity_End_Date') }}</span>
        <span class="text-xs font-semibold text-purple-primary">{{ paternity.expirationDate | date: "dd/MM/yyyy 'à' hh'h'mm" }}</span>
      </div>
    </div>
    <span class="bg-grey-background inline-block text-xs py-[1px] px-1 mb-2 mr-2 rounded">
      {{ paternity.programName ? paternity.programName : _i18n._('Prospect_Paternity_All_Programs') }}
    </span>
  </div>
  <app-status-tag right-content [status]="statusEnum[paternityStatus]"
    >{{ _i18n._('Prospect_Paternity_Status_' + paternityStatus) }}
  </app-status-tag>
</app-horizontal-card>
