<div *ngIf="loggedUserIsValo" class="filter-content-layout layout-1-item column-md">
  <app-search-developer-company [waitForSearch]="true"></app-search-developer-company>
</div>
<div class="filter-content-layout layout-1-item">
  <app-search-localisation [open]="true" [titelUp]="true" [waitForSearch]="true"></app-search-localisation>
</div>
<div class="filter-content-layout layout-2-item column-md">
  <app-search-rooms [waitForSearch]="true"></app-search-rooms>
  <app-search-filter-floor [waitForSearch]="true" class="second-filter-item"></app-search-filter-floor>
</div>
<div class="filter-content-layout layout-1-item">
  <app-search-filter-budget></app-search-filter-budget>
</div>
<div class="filter-content-layout layout-1-item">
  <app-search-filter-program-type></app-search-filter-program-type>
</div>
<div class="filter-content-layout layout-2-item column-md">
  <app-search-filter-outside></app-search-filter-outside>
  <app-search-filter-orientation></app-search-filter-orientation>
</div>
<div class="filter-content-layout layout-2-item column-md">
  <app-search-filter-annexes [annexeType]="secondaryLotTypeEnum.PARKING">
    <div class="text-base font-bold mb-3 text-left">{{ 'Title_Form_SearchForm_Parking_Annexes' | translate }}</div>
  </app-search-filter-annexes>
  <app-search-filter-annexes [annexeType]="secondaryLotTypeEnum.STORAGE">
    <div class="text-base font-bold mb-3 text-left">{{ 'Title_Form_SearchForm_Storage_Annexes' | translate }}</div>
  </app-search-filter-annexes>
</div>

<hr />

<div class="filter-content-layout layout-1-item">
  <app-search-filter-delivry-date></app-search-filter-delivry-date>
</div>
<div class="filter-content-layout layout-2-item">
  <app-search-filter-actable></app-search-filter-actable>
  <app-search-filter-tags></app-search-filter-tags>
</div>

<hr />

<div class="filter-content-layout layout-2-item column-sm">
  <app-search-filter-input [addClass]="['w-11/12']" [filterSelectOption]="selectOptions.taxation"></app-search-filter-input>
  <app-search-filter-input [addClass]="['w-full']" [filterSelectOption]="selectOptions.taxationZone"></app-search-filter-input>
</div>

<div class="filter-content-layout layout-2-item column-sm">
  <app-search-filter-input [addClass]="['w-11/12']" [filterSelectOption]="selectOptions.profitability" [waitForSearch]="true">
  </app-search-filter-input>
  <app-search-filter-input [addClass]="['w-full']" [filterSelectOption]="selectOptions.rent" [waitForSearch]="true">
  </app-search-filter-input>
</div>
<div class="filter-content-layout layout-1-item" *ngIf="isContractor && !isFeesHidden">
  <app-search-filter-input [addClass]="['w-full']" [filterSelectOption]="selectOptions.fees" [waitForSearch]="true">
  </app-search-filter-input>
</div>
