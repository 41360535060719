import { CompleteDossierResponse } from '@commons-dto/dossier-prospect';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from 'apps/valo-front/src/app/utils/utils.module';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { DashboardCardComponent } from 'apps/valo-front/src/app/design-system/component/dashboard-card/dashboard-card.component';
import { PipesModule } from 'apps/valo-front/src/app/utils/pipes/pipes.module';

import { SnackbarMessageType } from '../../../../utils/models/enums/snackbar-message-type.enum';
import { SnackbarService } from './../../../../utils/services/snackbar.service';
import { DossierProspectService } from './../../../services/dossier-prospect.service';
import { I18nService } from '../../../../utils/services/i18n.service';

@Component({
  selector: 'app-dossier-prospect-dashboard-comment',
  standalone: true,
  templateUrl: './dossier-prospect-dashboard-comment.component.html',
  styleUrls: ['./dossier-prospect-dashboard-comment.component.scss'],
  imports: [CommonModule, FormsModule, DashboardCardComponent, UtilsModule, PipesModule],
})
export class DossierProspectDashboardCommentComponent {
  /**
   * Commentary
   *
   * @type {string}
   * @memberof DossierProspectDashboardCommentComponent
   */
  @Input() comment: string;

  /**
   * dossier
   *
   * @type {CompleteDossierResponse}
   * @memberof DossierProspectDashboardCommentComponent
   */
  @Input() dossier: CompleteDossierResponse;

  /**
   * subject for comment update
   *
   * @type {Subject<string>}
   * @memberof DossierProspectDashboardCommentComponent
   */
  commentUpdate = new Subject<string>();

  constructor(
    public readonly i18nService: I18nService,
    private readonly _dossierProspectService: DossierProspectService,
    private readonly snackbarService: SnackbarService,
  ) {
    this.commentUpdate.pipe(debounceTime(500), distinctUntilChanged()).subscribe((comment) => {
      const updated: Partial<CompleteDossierResponse> = {
        id: this.dossier.id,
        comment: comment,
      };
      this._dossierProspectService.updateDossier(updated).subscribe({
        error: () => {
          this.snackbarService.sendMessage({
            text: this.i18nService._('Error_SnackBar_Update_Comment'),
            type: SnackbarMessageType.Error,
          });
        },
      });
    });
  }
}
