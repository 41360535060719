<p-dropdown
  (onChange)="props.change && props.change(field, $event)"
  [dropdownIcon]="props.dropdownIcon"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [optionLabel]="props.optionLabel"
  [optionValue]="props.optionValue"
  [disabled]="props.disabled"
  [readonly]="props.readonly"
  [options]="!isObservable(this.props.options) ? props.options : (props.options | formlySelectOptions: field | async)"
  [placeholder]="props.placeholder"
  [showClear]="(props.showClear || !props.required) && !props.readonly"
>
</p-dropdown>
