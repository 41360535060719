import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgIf } from '@angular/common';

import { LotComparatorMetaLineComponent } from '../lot-comparator-meta-line/lot-comparator-meta-line.component';

import { LotComparatorDetail } from '../../../../lot/model/lot-comparator-detail';
import { BasicFormatsService } from '../../../../utils/services/basic-formats.service';
import { FileSystemService } from '../../../../utils/services/file-system.service';
import { ComparatorMetaWrapper } from '../../../models/comparator-meta';
import { AbstractComparatorComponent } from '../../abstract-comparator-component';

@Component({
  selector: 'app-lot-comparator-rent-and-profitablity',
  templateUrl: './lot-comparator-rent-and-profitablity.component.html',
  styleUrls: ['./lot-comparator-rent-and-profitablity.component.scss'],
  standalone: true,
  imports: [NgIf, LotComparatorMetaLineComponent],
})
export class LotComparatorRentAndProfitablityComponent extends AbstractComparatorComponent {
  lotMeta: ComparatorMetaWrapper;
  @Input() lotDetailToCompare: LotComparatorDetail[];
  @ViewChild('priceTTCTemplateRef', { static: true })
  public priceTTCTemplateRef: TemplateRef<unknown>;

  constructor(
    public basicFormatsService: BasicFormatsService,
    private readonly _fileSystemService: FileSystemService,
  ) {
    super();
    this._initComparatorMetaData();
  }

  private _initComparatorMetaData(): void {
    this.lotMeta = this._comparatorPageService.comparatorMeta.rentAndProfitability;

    this.castToComparatorMeta('estmatedMonthlyRentingPrice', this.lotMeta).format = (lot) => {
      return this.formatRent(
        this.castToComparatorMeta('estmatedMonthlyRentingPrice', this.lotMeta).complexShow(lot) && lot.estmatedMonthlyRentingPrice,
      );
    };
    this.castToComparatorMeta('estimatedProfitability', this.lotMeta).format = (lot) => {
      return this.formatProfitability(
        this.castToComparatorMeta('estimatedProfitability', this.lotMeta).complexShow(lot) && lot.estimatedProfitability,
      );
    };
    this.castToComparatorMeta('pinelTaxationRentingPrice', this.lotMeta).format = (lot) => {
      return this.formatRent(
        this.castToComparatorMeta('pinelTaxationRentingPrice', this.lotMeta).complexShow(lot) && lot.pinelTaxation.rentingPrice,
      );
    };
    this.castToComparatorMeta('pinelPlusTaxationRentingPrice', this.lotMeta).format = (lot) => {
      return this.formatRent(
        this.castToComparatorMeta('pinelPlusTaxationRentingPrice', this.lotMeta).complexShow(lot) && lot.pinelPlusTaxation.rentingPrice,
      );
    };
    this.castToComparatorMeta('pinelTaxationProfitability', this.lotMeta).format = (lot) => {
      return this.formatProfitability(
        this.castToComparatorMeta('pinelTaxationProfitability', this.lotMeta).complexShow(lot) && lot.pinelTaxation.profitability,
      );
    };
    this.castToComparatorMeta('pinelPlusTaxationProfitability', this.lotMeta).format = (lot) => {
      return this.formatProfitability(
        this.castToComparatorMeta('pinelPlusTaxationProfitability', this.lotMeta).complexShow(lot) && lot.pinelPlusTaxation.profitability,
      );
    };
    this.castToComparatorMeta('plsTaxationRentingPrice', this.lotMeta).format = (lot) => {
      return this.formatRent(
        this.castToComparatorMeta('plsTaxationRentingPrice', this.lotMeta).complexShow(lot) && lot.plsTaxation.rentingPrice,
      );
    };
    this.castToComparatorMeta('plsTaxationProfitability', this.lotMeta).format = (lot) => {
      return this.formatProfitability(
        this.castToComparatorMeta('plsTaxationProfitability', this.lotMeta).complexShow(lot) && lot.plsTaxation.profitability,
      );
    };
    this.castToComparatorMeta('monthlyRentalLotPriceIT', this.lotMeta).format = (lot) => {
      return this.formatRent(
        this.castToComparatorMeta('monthlyRentalLotPriceIT', this.lotMeta).complexShow(lot) && lot.monthlyRentalLotPriceIT,
      );
    };
    this.castToComparatorMeta('guaranteedMonthlyRentingPriceIT', this.lotMeta).format = (lot) => {
      return this.formatPriceCurrency(
        this.castToComparatorMeta('guaranteedMonthlyRentingPriceIT', this.lotMeta).complexShow(lot) && lot.guaranteedMonthlyRentingPriceIT,
      );
    };
    this.castToComparatorMeta('monthlyRentingPriceET', this.lotMeta).format = (lot) => {
      return this.formatRent(
        this.castToComparatorMeta('monthlyRentingPriceET', this.lotMeta).complexShow(lot) && lot.monthlyRentingPriceET,
      );
    };
    this.castToComparatorMeta('lmnpLmpTaxationProfitability', this.lotMeta).format = (lot) => {
      return this.formatProfitability(
        this.castToComparatorMeta('lmnpLmpTaxationProfitability', this.lotMeta).complexShow(lot) && lot.lmnpLmpTaxation.profitability,
      );
    };
    this.castToComparatorMeta('lmnpLmpNonGereTaxationRentingPrice', this.lotMeta).format = (lot) => {
      return this.formatRent(
        this.castToComparatorMeta('lmnpLmpNonGereTaxationRentingPrice', this.lotMeta).complexShow(lot) &&
          lot.lmnpLmpNonGereTaxation.rentingPrice,
      );
    };
    this.castToComparatorMeta('lmnpLmpNonGereTaxationProfitability', this.lotMeta).format = (lot) => {
      return this.formatProfitability(
        this.castToComparatorMeta('lmnpLmpNonGereTaxationProfitability', this.lotMeta).complexShow(lot) &&
          lot.lmnpLmpNonGereTaxation.profitability,
      );
    };
    this.castToComparatorMeta('lmnpLmpNonGereTaxationRentingPrice', this.lotMeta).format = (lot) => {
      return this.formatRent(
        this.castToComparatorMeta('estimatedFurnishedMarketRent', this.lotMeta).complexShow(lot) && lot.estimatedFurnishedMarketRent,
      );
    };
    this.castToComparatorMeta('lmnpLmpNonGereTaxationProfitability', this.lotMeta).format = (lot) => {
      return this.formatProfitability(
        this.castToComparatorMeta('estimatedFurnishedMarketYield', this.lotMeta).complexShow(lot) && lot.estimatedFurnishedMarketYield,
      );
    };
  }

  private formatProfitability(value) {
    return value ? this.basicFormatsService.formatPercentage(value / 100) : undefined;
  }

  private formatRent(value) {
    return value ? this.basicFormatsService.formatCurrencyCeil(value, undefined, 0, 0, 0) : undefined;
  }

  private formatPriceCurrency(price): string {
    return price ? this.basicFormatsService.formatCurrency(price, undefined, 0, 0, 2) : undefined;
  }
}
