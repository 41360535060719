<div class="mt-4 lg:mt-5 mx-auto mb-4 w-full max-w-md h-full">
  <h2 class="px-3">
    {{ i18nService._('Title_ResetPassword') }}
  </h2>

  <p class="mb-0 px-3">
    {{ i18nService._('Txt_Page_Form_ResetPassword_Rules') }}
  </p>

  <mat-list class="mb-3" role="list">
    <mat-list-item class="mb-2" role="listitem">
      <span class="text-xxs p-2 icon-circle"></span>
      {{ i18nService._('Txt_Page_Form_ResetPassword_CharNumber') }}
    </mat-list-item>
    <mat-list-item class="mb-2" role="listitem">
      <span class="text-xxs p-2 icon-circle"></span>
      {{ i18nService._('Txt_Page_Form_ResetPassword_CharCriteria') }}
    </mat-list-item>
    <mat-list-item class="mb-2" role="listitem">
      <span class="text-xxs p-2 icon-circle"></span>
      {{ i18nService._('Txt_Page_Form_ResetPassword_LastPasswords') }}
    </mat-list-item>
  </mat-list>

  <form (ngSubmit)="onSubmit()" [formGroup]="initForm" class="flex flex-col justify-center items-center mb-4 mx-4">
    <mat-form-field class="mb-4 w-full">
      <mat-label>{{ i18nService._('Txt_Page_Form_ResetPassword_NewPassword') }}</mat-label>
      <input
        [formControl]="newPasswordControl"
        [type]="hideNewPassword ? 'password' : 'text'"
        class="w-full"
        id="NewPassword"
        matInput
        name="NewPassword"
      />
      <span
        (click)="hideNewPassword = !hideNewPassword"
        [ngClass]="hideNewPassword ? 'icon-eye-shut' : 'icon-eye'"
        class="cursor-pointer"
        matSuffix
      ></span>
      <mat-error *ngIf="newPasswordControl.errors">
        <span *ngIf="newPasswordControl.errors.required">
          {{ i18nService._('Error_Field_Required') }}
        </span>
        <span *ngIf="newPasswordControl.errors.isRightPassword && !newPasswordControl.errors.required">
          {{ i18nService._('Error_Field_PasswordMustRespectRules') }}
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="mb-3 w-full">
      <mat-label>{{ i18nService._('Txt_Page_Form_ResetPassword_PasswordConfirmation') }}</mat-label>
      <input
        [formControl]="confirmControl"
        [type]="hideConfirmPassword ? 'password' : 'text'"
        id="ConfirmPassword"
        matInput
        name="ConfirmPassword"
      />
      <span
        (click)="hideConfirmPassword = !hideConfirmPassword"
        [ngClass]="hideConfirmPassword ? 'icon-eye-shut' : 'icon-eye'"
        class="cursor-pointer"
        matSuffix
      ></span>
      <mat-error *ngIf="confirmControl?.errors || initForm?.errors?.mustMatch">
        <span *ngIf="confirmControl?.errors?.required">
          {{ i18nService._('Error_Field_Required') }}
        </span>
        <span *ngIf="confirmControl?.errors?.isRightPassword && !confirmControl?.errors?.required">
          {{ i18nService._('Error_Field_PasswordMustRespectRules') }}
        </span>
        <span *ngIf="initForm?.errors?.mustMatch && !confirmControl?.errors?.required">
          {{ i18nService._('Error_Field_PasswordsMustMatch') }}
        </span>
      </mat-error>
    </mat-form-field>
    <mat-error class="mb-4">
      <span *ngIf="initForm?.errors?.mustMatch && !confirmControl?.errors?.required">{{
        i18nService._('Error_Field_PasswordsMustMatch')
      }}</span>
    </mat-error>
    <button color="primary" [disabled]="!initForm.valid" mat-flat-button type="submit">
      {{ i18nService._('Txt_Button_Send') }}
    </button>
  </form>
</div>
