import { Component, Input } from '@angular/core';

import { Target2SellEnum } from '../../models/enums/target-2-sell.enum';

/**
 * Adds an empty div tag with a precalculated ID that
 * Target2Sell will populate at runtime using GTM
 */
@Component({
  selector: 'app-target2sell-reco',
  templateUrl: './target2sell-reco.component.html',
  standalone: true,
})
export class Target2SellRecoComponent {
  /**
   * Account ID  Page ID Internal ID
   *       ↓         ↓    ↓
   * AAAAAAAAAAAAAA-BBBB-CC
   */
  @Input() target2SellRecoId = Target2SellEnum.TARGET_2_SELL_DEFAULT;
}
