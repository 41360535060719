import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';
import { ChipModule } from 'primeng/chip';

import { ChipAutocompletePopulatedComponent } from '../chip-autocomplete-populated/chip-autocomplete-populated.component';

import { propertiesLocalisation } from '../../models/app-constant';
import { CitiesLocationData } from '../../models/CitiesLocationData';
import { CitiesLocationResponse } from '../../models/CitiesLocationResponse';
import { CitiesLocationTypeAcceptedData } from '../../models/CitiesLocationTypeAcceptedData';
import { LocationData } from '../../models/LocationData';
import { LocationResponse } from '../../models/LocationResponse';
import { CityService } from '../../services/city.service';
import { I18nService } from '../../services/i18n.service';
import { ReferenceTablesService } from '../../services/reference-tables.service';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-form-location',
  templateUrl: './form-location.component.html',
  styleUrls: ['./form-location.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatFormFieldModule,
    MatChipsModule,
    NgFor,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatOptionModule,
    AutoCompleteModule,
    ChipModule,
  ],
})
export class FormLocationComponent extends ChipAutocompletePopulatedComponent implements OnInit {
  @Input() localisations: LocationData = new LocationData();

  @Input() design = 'default';

  /**
   * dataMapList: init localisations with a map list (key-> label ,value-> ville, région et departement)
   */
  @Input() dataMapList: Map<string, LocationResponse>;

  /**
   * uniqueLocation input, if this input is set, user can selected only once location
   *
   * @type {boolean}
   * @memberof FormLocationComponent
   */
  @Input() uniqueLocation: boolean;

  /**
   * filterLocalisationType input, if empty input, no type filtering
   *
   * @type {Array<string>}
   * @memberof FormLocationComponent
   */
  @Input() filterLocalisationTypeAccepted: CitiesLocationTypeAcceptedData;

  /**
   * placeholder input
   *
   * @type {string}
   * @memberof FormLocationComponent
   */
  // @Input() placeholder: string;

  @Output() readonly localisationsChanged = new EventEmitter<LocationData>(false);

  displayDropdownSummary = false;
  displayDropdownSelect = false;
  fastSearchLocation: any;
  fastSearchLocationSummay: any[] = [];

  constructor(
    referenceTablesService: ReferenceTablesService,
    snackbarService: SnackbarService,
    i18nService: I18nService,
    public cityService: CityService,
  ) {
    super(referenceTablesService, snackbarService, i18nService);
    this.uniqueLocation = false;
    this.filterLocalisationTypeAccepted = {};
    this.placeholder = this.i18nService._('Txt_Page_FastSearch_Location');
  }

  public initData(): void {
    if (this.dataMapList) {
      this.mapSelectedItems = new Map(this.dataMapList);
      this.refreshChipsItemList();
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initData();
  }

  public formatLocationResponseList(locationResponseList: CitiesLocationResponse): void {
    this.mapListFilteredItems.clear();
    Object.keys(locationResponseList).forEach((prop) => {
      let locationType = '';
      locationResponseList[prop].forEach((element) => {
        element.typeLocation = prop;
        if (prop === propertiesLocalisation.department) {
          locationType = ` (${element.code})`;
        } else if (prop === propertiesLocalisation.city) {
          locationType = ` (${element.zipcode})`;
        } else if (prop === propertiesLocalisation.agglomeration) {
          locationType = ` (${element.zipcode})`;
        }
        this.mapListFilteredItems.set(element.label.toString().concat(locationType), element);
      });
    });
  }

  public mapSelectedItemsToSelectedItems(): void {
    this.clearLocalisations();
    this.mapSelectedItems.forEach((value, key: string) => {
      this.dataMapList.set(key, value);
      switch (value.typeLocation) {
        case propertiesLocalisation.city:
          this.localisations.cities.push(value.label);
          break;
        case propertiesLocalisation.department:
          this.localisations.departments.push(value.id);
          break;
        case propertiesLocalisation.region:
          this.localisations.regions.push(value.id);
          break;
        case propertiesLocalisation.agglomeration:
          this.localisations.agglomerations.push(value.id);
          break;
        default:
          break;
      }
    });
    this.localisationsChanged.emit(this.localisations);
  }

  public _filter(event): Observable<Array<string>> {
    const value = event ? event : this.fastSearchLocation;
    clearTimeout(this.timeoutWork);
    this.timeoutWork = setTimeout((): Observable<Array<string>> => {
      if (value) {
        const valueTrim = value.trim();
        // If digit 2 caracts authorized, 3 for string
        if ((isNaN(parseInt(valueTrim, 10)) && valueTrim.length >= 3) || (!isNaN(parseInt(valueTrim, 10)) && valueTrim.length >= 2)) {
          this.displayDropdownSelect = true;
          const filterData = new CitiesLocationData();
          filterData.filter = value.trim();
          filterData.typeAccepted = this.filterLocalisationTypeAccepted;
          this.cityService.searchLocalisation(filterData).subscribe((itemsFound) => {
            this.formatLocationResponseList(itemsFound);
            this.filteredItems = Array.from(this.getMapListFilteredItems().keys());
          });

          return of(Array.from(this.getMapListFilteredItems().keys()));
        }
        this.displayDropdownSelect = false;
      }
    }, 100);

    return of([]);
  }

  /**
   * isLocalisationIsEmpty method returns true if no any localisation selected
   *
   * @returns {boolean}
   * @memberof FormLocationComponent
   */
  public isLocalisationsEmpty(): boolean {
    return !(
      this.localisations.cities.length +
      this.localisations.departments.length +
      this.localisations.regions.length +
      this.localisations.agglomerations.length
    );
  }

  public resetSeletedValues(): void {
    super.resetSeletedValues();
    this.clearLocalisations();
  }

  private clearLocalisations(): void {
    if (this.dataMapList) {
      this.dataMapList.clear();
    }
    Object.keys(this.localisations).forEach((prop) => {
      if (this.localisations && this.localisations[prop]) {
        this.localisations[prop].splice(0);
      }
    });
  }

  onClickOption(option): void {
    this.displayDropdownSelect = false;
    this.fastSearchLocationSummay.push(option);
    this.fastSearchLocation = null;
    this.displayDropdownSummary = true;
    const selectLocation = { value: option };
    this.selected(selectLocation);
  }

  onRemoveChip(item): void {
    const index = this.fastSearchLocationSummay.indexOf(item, 0);
    if (index > -1) {
      this.fastSearchLocationSummay.splice(index, 1);
    }
    this.displayDropdownSummary = !(this.fastSearchLocationSummay.length === 0);
    this.remove(item);
  }

  removeAllChips(): void {
    this.fastSearchLocationSummay.forEach((item) => this.remove(item));
    this.fastSearchLocationSummay = [];
    this.displayDropdownSummary = false;
  }
}
