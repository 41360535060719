import { AbstractControl, ValidatorFn } from '@angular/forms';

export const isInteger = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: { isIntegerValidate: boolean } } | undefined => {
    const passwordRegex = new RegExp('^[0-9]+$');
    const isIntegerValidate = control.value ? passwordRegex.test(control.value) : true;

    return isIntegerValidate ? undefined : { isInteger: { isIntegerValidate } };
  };
};
