import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DataLayerApiService } from './data-layer-api.service';
import { PaternityLegacyCreate } from '../utils/models/PaternityLegacyCreate';
import { PaternityResponse } from '../utils/models/PaternityResponse';

@Injectable({ providedIn: 'root' })
export class PaternityLegacyApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Create paternity
   *
   * @returns {Observable<ProspectsResponse>}
   * @memberof DataLayerApiService
   */
  create(body: PaternityLegacyCreate): Observable<PaternityResponse> {
    const path = this.BASE_URL + 'Paternities';
    return this.post(path, body);
  }
}
