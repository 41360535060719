import { Component, Input, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

import { AppVendorDetailsComponent } from '../vendors-details/vendor-details.component';
import { AppPersonDetailsComponent } from '../person-details/person-details.component';
import { AppInformationComponent } from '../information/information.component';
import { AppLogoComponent } from '../logo/logo.component';

import { ReservationCreate } from '../../../utils/models/ReservationCreate';
import { FileSystemService } from '../../../utils/services/file-system.service';
import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-details-of-associate-seller',
  templateUrl: './details-of-associate-seller.component.html',
  styleUrls: ['./details-of-associate-seller.component.scss'],
  providers: [FileSystemService, I18nService],
  standalone: true,
  imports: [MatCardModule, NgIf, AppLogoComponent, AppInformationComponent, AppPersonDetailsComponent, AppVendorDetailsComponent],
})
export class DetailsOfAssociateSellerComponent implements OnInit {
  @Input() reservationInformations: ReservationCreate;
  public logoCompanyUrl: string;

  constructor(
    private i18nService: I18nService,
    private fileSystemService: FileSystemService,
  ) {}

  ngOnInit(): void {
    this.initLogoUrl();
  }

  private initLogoUrl(): void {
    this.logoCompanyUrl = this.fileSystemService.getLogo(this.reservationInformations.logoDeveloperCompanyDocument);
  }

  translate(key: string): string {
    return this.i18nService._(key);
  }
}
