import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

import { breakpoints } from '../../../utils/models/breakpoint';
import { BreakpointEnum } from '../../../utils/models/enums/breakpoint.enum';
import { I18nService } from '../../../utils/services/i18n.service';
import { ComparatorPageService } from '../../services/comparator-page.service';
import { ComparatorStorage } from '../../models/comparator-storage';

@Component({
  selector: 'app-management-lot-comparator',
  templateUrl: './management-lot-comparator.component.html',
  styleUrls: ['./management-lot-comparator.component.scss'],
  standalone: true,
  imports: [NgIf, MatIconModule, MatTooltipModule],
})
export class ManagementLotComparatorComponent implements OnInit, OnDestroy {
  existLot: boolean;
  @Input() viewText: boolean;
  hide: boolean;
  @Output() readonly comparatorChangeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private comparatorStorage: ComparatorStorage;

  constructor(
    public i18nService: I18nService,
    private readonly _comparatorPageService: ComparatorPageService,
    private readonly _breakpointObserver: BreakpointObserver,
  ) {}

  private _idLot: number;

  get idLot(): number {
    return this._idLot;
  }

  @Input() set idLot(idLot: number) {
    this._idLot = idLot;
    this._controleIDlot();
  }

  ngOnInit() {
    this._comparatorPageService
      .getComparatorStorage()
      .pipe(takeUntil(this.destroy$))
      .subscribe((comparatorStorage) => {
        this.comparatorStorage = comparatorStorage;
        this._controleIDlot();
      });

    this._breakpointObserver
      .observe(breakpoints[BreakpointEnum.md].down)
      .pipe(
        tap((breakPoint) => (this.hide = breakPoint.matches)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  actionComparator($event) {
    $event.stopImmediatePropagation();

    const isInsert = this._comparatorPageService.addOneLotToCompare(this.comparatorStorage, this.idLot);
    this.comparatorChangeEvent.emit(isInsert);
  }

  existLotComparator(): boolean {
    return this._comparatorPageService.findLotToCompare(this.comparatorStorage, this.idLot);
  }

  getCompareMessage(): string {
    return this._comparatorPageService.getLengthCompareStorage(this.comparatorStorage)
      ? this.viewText
        ? null
        : this.i18nService._('Txt_Add_To_Comparator')
      : this.i18nService._('Txt_Message_To_Comparator');
  }

  getLengthCompareStorage(): boolean {
    return this._comparatorPageService.getLengthCompareStorage(this.comparatorStorage);
  }

  private _controleIDlot() {
    if (this.comparatorStorage) {
      this.existLot = this.existLotComparator();
      this.getLengthCompareStorage();
    }
  }
}
