/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ErrorFnFactoryOptions } from '../models/ErrorFnFactoryOptions';
import { ErrorHandlerServiceErrorFns } from '../models/ErrorHandlerServiceErrorFns';
import { Sitemap } from '../models/Sitemap';
import { AppConfigService } from './app-config.service';
import { TokenService } from './authorisation/token.service';
import { I18nService } from './i18n.service';
import { SnackbarService } from './snackbar.service';
import { SpinnerWithBackdropService } from './spinner-with-backdrop.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  public errorFnFactory;
  private readonly defaultErrorFns: ErrorHandlerServiceErrorFns;
  private readonly specificStatusCodeHandles = [400, 401, 403, 404, 500];

  constructor(
    public i18nService: I18nService,
    private readonly appConfigService: AppConfigService,
    private readonly router: Router,
    private readonly snackbarService: SnackbarService,
    private readonly tokenService: TokenService,
    private readonly _spinnerWithBackdropService: SpinnerWithBackdropService,
  ) {
    this.errorFnFactory = {
      default:
        (options: ErrorFnFactoryOptions = {}) =>
        (serviceName: string, operation: string, httpError: any) => {
          const o = {
            log: true,
            snackbar: true,
            spinner: true,
            snackbarMessage: httpError.error_description || httpError.message,
            snackbarI18n: httpError.isI18n,
            deleteToken: false,
            redirect: false,
            ...options,
          };

          if (o.log) {
            console.log(`${serviceName}:${operation} échoué : ${httpError.message}`);
          }
          if (o.snackbar && o.snackbarMessage) {
            this.snackbarService.error(o.snackbarMessage, o.snackbarI18n);
          }
          if (o.deleteToken) {
            this.tokenService.deleteToken();
          }
          if (o.spinner) {
            this._spinnerWithBackdropService.hide();
          }
          if (o.redirect && o.redirectUrl) {
            this.router.navigate([o.redirectUrl, o]);
          }
        },
      400:
        (options: ErrorFnFactoryOptions = {}) =>
        (serviceName: string, operation: string, httpError: any) => {
          if (httpError.name === 'TokenValidatorError') {
            this.errorFnFactory['401'](options)(serviceName, operation, httpError);
          } else {
            this.errorFnFactory.default(options)(serviceName, operation, httpError);
          }
        },
      401:
        (options: ErrorFnFactoryOptions = {}) =>
        (serviceName: string, operation: string, httpError: any) => {
          if (httpError.error_description === 'session_not_active') {
            httpError.message = 'TXT_session_expired_error';
          }

          const o = {
            snackbarMessage: httpError.message || 'Error_SnackBar_RedirectNotConnected',
            snackbarI18n: true,
            deleteToken: true,
            redirect: true,
            redirectUrl: Sitemap.utils.login.path,
            log: true,
            snackbar: true,
            ...options,
          };

          this.errorFnFactory.default(o)(serviceName, operation, httpError);
        },

      403:
        (options: ErrorFnFactoryOptions = {}) =>
        (serviceName: string, operation: string, httpError: any) => {
          const o = {
            snackbarMessage: 'Error_SnackBar_RedirectPageNotAllowed',
            snackbarI18n: true,
            redirect: true,
            redirectUrl: Sitemap.utils.homepage.path,
            log: true,
            snackbar: true,
            deleteToken: false,
            ...options,
          };
          this.errorFnFactory.default(o)(serviceName, operation, httpError);
        },

      404:
        (options: ErrorFnFactoryOptions = {}) =>
        (serviceName: string, operation: string, httpError: any) => {
          const o = {
            log: true,
            snackbar: true,
            snackbarMessage: httpError.message,
            snackbarI18n: false,
            deleteToken: false,
            redirect: false,
            ...options,
          };
          this.errorFnFactory.default(o)(serviceName, operation, httpError);
        },

      500:
        (options: ErrorFnFactoryOptions = {}) =>
        (serviceName: string, operation: string, httpError: any) => {
          const o = {
            snackbarMessage: 'Error_SnackBar_ErrorOccured',
            snackbarI18n: true,
            log: true,
            snackbar: true,
            deleteToken: false,
            redirect: false,
            ...options,
          };
          this.errorFnFactory.default(o)(serviceName, operation, httpError);
        },
    };

    this.defaultErrorFns = {};
    Object.keys(this.errorFnFactory).forEach((key) => {
      this.defaultErrorFns[key] = this.errorFnFactory[key]();
    });
  }

  public handleError<T>(
    serviceName = '',
    operation = 'operation',
    customErrorFns?: ErrorHandlerServiceErrorFns,
  ): (err: any) => Observable<T> {
    const errorFns = { ...this.defaultErrorFns, ...customErrorFns };

    return (response: any): Observable<T> => {
      const httpError: any = this.getErrorFromResponse(response);
      if (!httpError) throw response;

      if (httpError?.code === this.appConfigService._('errorCodes', 'poseoptionError')) {
        const message = httpError.libelleRetour || httpError.message;
        this.errorFnFactory.default()(serviceName, operation, { message });
      } else {
        const key =
          Object.prototype.hasOwnProperty.call(httpError, 'statusCode') && this.specificStatusCodeHandles.includes(httpError.statusCode)
            ? httpError.statusCode
            : 'default';
        errorFns[key](serviceName, operation, httpError);
      }
      throw response;
    };
  }

  private getErrorFromResponse(error: any): any {
    try {
      if (error?.error?.error && typeof error?.error?.error != 'string') {
        return error?.error?.error;
      } else if (error?.error && typeof error?.error != 'string') {
        return error?.error;
      }
      return error;
    } catch (e) {
      throw error;
    }
  }
}
