import { ActionOnOptionResponse } from '../../utils/models/ActionOnOptionResponse';

export class LotAction extends ActionOnOptionResponse {
  userHasOptionUntil: string;
  hide: boolean;

  get checkIfOptionCanOnlyBeRemoved(): boolean {
    return (
      this.checkIfOptionCanBeRemoved &&
      !this.checkIfOptionCanBePreReserved &&
      !this.checkIfOptionCanBeExtended &&
      !this.checkIfAlertCanBeTaken &&
      !this.checkIfOptionCanBeTaken
    );
  }

  get hasSubAction(): boolean {
    return this.checkIfOptionCanBeExtended || !this.checkIfOptionCanOnlyBeRemoved;
  }
}
