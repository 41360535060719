import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ParamRequest } from '@commons-dto/valo-back';

import { AutoCompleteService } from '../../common/form/auto-complete.service';
import { CompanyApiService } from '../../adapters/company-api.service';
import { CompanyType } from '../../utils/models/enums/CompanyType.enum';
import { CompaniesTrackingTableServiceResponse } from '../../tracking-tables/models/CompaniesTrackingTableServiceResponse';
import { CompaniesTrackingTableItem } from '../../tracking-tables/models/CompaniesTrackingTableItem';

@Injectable({
  providedIn: 'root',
})
export class DeveloperCompanySearch implements AutoCompleteService {
  constructor(private companyApiService: CompanyApiService) {}

  findData(filter: string): Observable<{ label: string; id: number }[]> {
    const params: ParamRequest = { filter: filter, offset: 0, nbResult: 200 };
    return this.companyApiService.getCompanies(CompanyType.DEVELOPER, params).pipe(
      map((companies: CompaniesTrackingTableServiceResponse) => {
        return companies.items.map((company: CompaniesTrackingTableItem) => {
          return {
            id: company.id,
            label: company.companyCorporateName,
            isBi: company.isBi,
          };
        });
      }),
    );
  }
}
