import { Component, OnInit } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DossierResponse, DossierStatusCode } from '@commons-dto/dossier-prospect';
import { NgIf } from '@angular/common';

import { DossierProspectRouteService } from '../dossier-prospect/dossier-prospect.route.service';

import { DossierProspectService } from './../../services/dossier-prospect.service';
import { TokenService } from './../../../utils/services/authorisation/token.service';
import { DossierProspectFormlyService } from '../../services/dossier-prospect-formly.service';
import { DossierProspectFormData } from '../../model/forms/dossier-prospect-form-data.model';
import { Sitemap } from '../../../utils/models/Sitemap';
import { FormType } from '../../../utils/enums/form-type.enum';

@Component({
  selector: 'app-prospect-create',
  templateUrl: './prospect-create.component.html',
  styleUrls: ['./prospect-create.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, FormlyModule],
})
export class ProspectCreateComponent implements OnInit {
  public isFromSimulation: boolean;
  public fields: FormlyFieldConfig[];
  public options: FormlyFormOptions;
  public form: UntypedFormGroup;
  public model: any;
  public dossier: DossierResponse;

  constructor(
    private _router: Router,
    private readonly _route: ActivatedRoute,
    private _token: TokenService,
    private _dossierProspect: DossierProspectService,
    private _dossierProspectFormly: DossierProspectFormlyService,
    private readonly dossierProspectRouteService: DossierProspectRouteService,
  ) {
    this.isFromSimulation = false;

    this.model = {};
    this.options = {};
    this.form = new UntypedFormGroup({});

    this.dossier = {
      prospects: [],
      id: null,
      prescriptorId: null,
      dossierStatusCode: DossierStatusCode.IDENTIFICATION,
    };
    this._dossierProspect.updateLocalDossier(this.dossier);
  }

  public ngOnInit(): void {
    this.isFromSimulation = Boolean(this._route.snapshot.queryParams.displaySimulation);
    this.fields = this._dossierProspectFormly.getFormProspect(undefined, false, !this.isFromSimulation, {
      backButtonLabel: 'Prospect_Return_Prospect_List',
      goBackRoute: Sitemap.dossierProspects.list.path,
      context: FormType.DOSSIER_PROSPECT,
    });
  }

  public goToList() {
    return () => this._router.navigateByUrl(Sitemap.dossierProspects.list.path);
  }

  public submit() {
    this._dossierProspect.createDossier(this.form.value as DossierProspectFormData, this._token.getUserId()).subscribe((response) => {
      this.isFromSimulation
        ? this.dossierProspectRouteService.goToSimulation(response.id)
        : this.dossierProspectRouteService.goToDashboard(response.id);
    });
  }
}
