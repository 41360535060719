/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

import { FormSubjectData } from '../models/FormSubjectData';

/**
 * @export
 * @class FormUpdateService
 */
@Injectable({
  providedIn: 'root',
})
export class FormUpdateService {
  /**
   * subject value
   * @private
   * @type {Subject<any>}
   */
  private readonly subjectTable: Array<FormSubjectData>;

  /**
   * Creates an instance of ModalService.
   */
  constructor() {
    this.subjectTable = [];
  }

  /**
   * valueChanged method, call this when a form has changed, with argument a name of the form,
   * all the subscriber with this name will be triggered
   */
  valueChanged(formName: string): void {
    for (const subject of this.subjectTable) {
      if (subject.formName === formName) {
        subject.subject.next();
        break;
      }
    }
  }

  /**
   * subscribeChanges method
   * @returns {Observable<any>}
   */
  subscribeChanges(formName: string): Observable<void> {
    for (const subject of this.subjectTable) {
      if (subject.formName === formName) {
        return subject.subject;
      }
    }
    const newSubject = new Subject<void>();
    const subjectToAdd: FormSubjectData = {
      formName,
      subject: newSubject,
    };
    this.subjectTable.push(subjectToAdd);

    return newSubject;
  }
}
