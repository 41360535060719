import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { I18nService } from '../../../utils/services/i18n.service';
import { IconComponent } from '../../../common/standalone/icon/icon.component';

@Component({
  selector: 'app-option-dossier-prospect-dialog',
  templateUrl: './option-dossier-prospect-dialog.component.html',
  styleUrls: ['./option-dossier-prospect-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, IconComponent, MatCheckboxModule, FormsModule, MatButtonModule],
})
export class OptionDossierProspectDialogComponent {
  public isChecked: boolean;

  constructor(
    public readonly i18nService: I18nService,
    public dialogRef: MatDialogRef<OptionDossierProspectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OptionDossierProspectDialogData,
  ) {
    this.isChecked = false;
  }

  onSubmit(): void {
    this.dialogRef.close({ action: `nextStep`, isDoNotShownPoseOptionAdvert: this.isChecked });
  }

  onClose(): void {
    this.dialogRef.close();
  }
}

export interface OptionDossierProspectDialogData {
  title: string;
  message: string;
  imageSource?: string;
  button?: string;
}
