import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { UtilsModule } from '../utils/utils.module';
import { FinancialStrategyNbLotByTypologyComponent } from './components/financial-strategy-nb-lot-by-typology/financial-strategy-nb-lot-by-typology.component';
import { RegisterFormFinancialStrategyComponent } from './components/register-form-financial-strategy/register-form-financial-strategy.component';
import { RegisterFormMandateStrategyComponent } from './components/register-form-mandate-strategy/register-form-mandate-strategy.component';
import { PublicationStrategiesDialogComponent } from './components/strategies/publication-strategies-dialog/publication-strategies-dialog.component';
import { StrategiesComponent } from './components/strategies/strategies.component';
import { StrategiesRoutingModule } from './strategies-routing.module';

import { TrackingTablesModule } from './../tracking-tables/tracking-tables.module';

@NgModule({
  imports: [
    CommonModule,
    StrategiesRoutingModule,
    UtilsModule,
    MatButtonToggleModule,
    MatBadgeModule,
    TrackingTablesModule,
    StrategiesComponent,
    RegisterFormMandateStrategyComponent,
    RegisterFormFinancialStrategyComponent,
    FinancialStrategyNbLotByTypologyComponent,
    PublicationStrategiesDialogComponent,
  ],
})
export class StrategiesModule {}
