import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, lastValueFrom, map, Observable, of, switchMap, tap } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { DomSanitizer } from '@angular/platform-browser';

import { SpecialOffer } from '../models/SpecialOffer';
import { SpecialOffersResponse } from '../models/SpecialOffersResponse';
import { ProgramTrackingTableData } from '../models/ProgramLotTableDisplayData';

import { AttachmentUploadData } from '../../utils/models/AttachmentUploadData';
import { AutoCompleteByProgram } from '../../utils/models/AutoCompleteByProgram';
import { CityResponse } from '../../utils/models/CityResponse';
import { ContractualDocumentPackages } from '../../utils/models/ContractualDocumentPackages';
import { ProgramStatus } from '../../utils/models/enums/program-status.enum';
import { SnackbarMessageType } from '../../utils/models/enums/snackbar-message-type.enum';
import { LotDetailResponse } from '../../utils/models/LotDetailResponse';
import { LotResponse } from '../../utils/models/LotResponse';
import { LotSearchQueryResponse } from '../../utils/models/LotSearchQueryResponse';
import { LotSummary } from '../../utils/models/LotSummary';
import { ProgramCreate } from '../../utils/models/ProgramCreate';
import { ProgramDetail } from '../../utils/models/ProgramDetail';
import { ProgramDatalayer, ProgramDetailResponse } from '../../utils/models/ProgramDetailResponse';
import { ProgramResponse } from '../../utils/models/ProgramResponse';
import { ProgramSearchQueryResponse } from '../../utils/models/ProgramSearchQueryResponse';
import { ProgramSearchResponse } from '../../utils/models/ProgramSearchResponse';
import { ProgramUpdate } from '../../utils/models/ProgramUpdate';
import { Sitemap } from '../../utils/models/Sitemap';
import { AppConfigService } from '../../utils/services/app-config.service';
import { AttachmentService } from '../../utils/services/attachment.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';
import { GoogleTagManagerService } from '../../utils/services/google-tag-manager.service';
import { I18nService } from '../../utils/services/i18n.service';
import { SnackbarService } from '../../utils/services/snackbar.service';
import { ProgramResponseUpdateForm } from '../../utils/models/ProgramResponseUpdateForm';
import { TaxationResponse } from '../../utils/models/TaxationResponse';
import { ProgramApiService } from '../../adapters/program-api.service';
import { SearchProgramData } from '../../search/model/search-program-data';

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  readonly containerDocuments = this.appConfig.getAppConfig().containerName.programDocuments;

  readonly searchProgramsApi = '/Programs/search';
  readonly searchLotsApi = '/Lots/search';

  private readonly programId: BehaviorSubject<number>;
  private readonly filterSearch: BehaviorSubject<SearchProgramData>;
  private readonly lotSummary: BehaviorSubject<LotSummary>;
  private readonly currency: BehaviorSubject<string>;
  private readonly contractualDocumentPackagesBooleanList: BehaviorSubject<ContractualDocumentPackages>;
  // pushes new value each time a lot is selected to see details (by searchLot or programDetails)
  private readonly selectLotDetails: BehaviorSubject<LotDetailResponse | undefined>;
  /**
   * locale attribute
   *
   * @type {string}
   * @memberof ProgramService
   */
  private readonly locale: string;

  /**
   * datePipe attribute
   *
   * @type {DatePipe}
   * @memberof ProgramService
   */
  private readonly datePipe: DatePipe;

  private isElectronicSignatureValorissimoSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
    private readonly attachmentService: AttachmentService,
    private readonly snackBarService: SnackbarService,
    private readonly router: Router,
    public i18nService: I18nService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly programApiService: ProgramApiService,
    private readonly sanitizer: DomSanitizer,
  ) {
    this.programId = new BehaviorSubject(undefined);
    this.lotSummary = new BehaviorSubject(undefined);
    this.currency = new BehaviorSubject(undefined);
    this.filterSearch = new BehaviorSubject(undefined);
    this.contractualDocumentPackagesBooleanList = new BehaviorSubject({
      isApartmentContractualDocumentPackages: false,
      isHouseContractualDocumentPackages: false,
      isTradeContractualDocumentPackages: false,
    });
    this.selectLotDetails = new BehaviorSubject(undefined);
    this.locale = this.appConfig.getLocale();
    this.datePipe = new DatePipe(this.locale);
  }

  get programIdObservable(): Observable<number> {
    return this.programId.asObservable();
  }

  /**
   * Behavior subject to get or update lot Summary in program Summary step 3 program creation
   *
   * @readonly
   * @type {*}
   * @memberof ProgramService
   */
  get lotSummaryObservable(): Observable<LotSummary> {
    return this.lotSummary.asObservable();
  }

  get currencyObservable(): Observable<string> {
    return this.currency.asObservable();
  }

  get filterSearchObservable(): Observable<SearchProgramData> {
    return this.filterSearch.asObservable();
  }

  /**
   * Behavior subject to get or set boolean for contractual document packages on step 4
   *
   * @readonly
   * @type {*}
   * @memberof ProgramService
   */
  get contractualDocumentPackagesObservable(): Observable<ContractualDocumentPackages> {
    return this.contractualDocumentPackagesBooleanList.asObservable();
  }

  get selectedLotDetail(): Observable<LotDetailResponse | undefined> {
    return this.selectLotDetails as Observable<LotDetailResponse | undefined>;
  }

  getProgramInformations(programId: number): Observable<ProgramResponseUpdateForm> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Programs/${programId}`;
    const params = new HttpParams().set('flag', 'get-informations');

    return this.http.get<ProgramResponseUpdateForm>(url, { params }).pipe(
      tap((res) => {
        this.setCurrency(res.company.currency.abbreviation);
      }),
      catchError(this.errorHandlerService.handleError<ProgramResponseUpdateForm>('programService', 'get')),
    );
  }

  getProgramByCompany(companyId: number): Observable<ProgramTrackingTableData[]> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Programs/${companyId}/tracking-table/programs`;

    return this.http
      .get<ProgramTrackingTableData[]>(url)
      .pipe(catchError(this.errorHandlerService.handleError<ProgramTrackingTableData[]>('programService', 'get')));
  }

  getProgram(id: number): Observable<ProgramDetailResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Programs/details/${id}`;

    return this.http
      .get<ProgramDetailResponse>(url)
      .pipe(catchError(this.errorHandlerService.handleError<ProgramDetailResponse>('programService', 'get')));
  }

  async getProgramById(id: number): Promise<ProgramResponse> {
    return lastValueFrom(this.programApiService.getProgramInformationById(id));
  }

  getProgramDatalayer(programId: number): Observable<ProgramDatalayer> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Programs/datalayer/${programId}`;

    return this.http
      .get<ProgramDatalayer>(url)
      .pipe(catchError(this.errorHandlerService.handleError<ProgramDatalayer>('programService', 'get')));
  }

  deleteLot(lotId: number): Observable<LotResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Lots/${lotId}`;

    return this.http.delete<LotResponse>(url);
  }

  createProgram(programToCreate: ProgramCreate): Observable<ProgramResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Programs`;
    const filesToUpload: Array<AttachmentUploadData> = Object.keys(programToCreate.document)
      .map((key) => ({ file: programToCreate.document[key], documentType: key }))
      .filter((attachment) => attachment.file);

    return this.attachmentService.uploadProgramDocumentsFiles(filesToUpload, this.containerDocuments, programToCreate.programRef).pipe(
      tap(({ result }) => (programToCreate.documentsId = result.files.file.map((file) => file.id))),
      switchMap(() => {
        return this.http.post<ProgramResponse>(url, programToCreate).pipe(
          tap((program) => {
            this.setIdProgramId(program.id);
            this.setCurrency(program.currency.abbreviation);
          }),
          catchError(this.errorHandlerService.handleError<ProgramResponse>('programService', 'post')),
        );
      }),
    );
  }

  updateProgram(programId: number, programToUpdate: ProgramUpdate, status?: string): Observable<ProgramResponse> {
    const filesToUpload: Array<AttachmentUploadData> = Object.keys(programToUpdate.document)
      .map((key) => ({ file: programToUpdate.document[key], documentType: key }))
      .filter((attachment) => attachment.file && !attachment.file.alreadySave);

    let url = `${this.appConfig.getLoopbackApiUrl()}/Programs/${programId}`;
    if (status) {
      url = `${this.appConfig.getLoopbackApiUrl()}/Programs/${status.toLowerCase()}/${programId}`;
    }
    if (filesToUpload.length) {
      return this.attachmentService.uploadProgramDocumentsFiles(filesToUpload, this.containerDocuments, programToUpdate.programRef).pipe(
        tap(({ result }) => (programToUpdate.documentsId = result.files.file.map((file) => file.id))),
        switchMap(() => {
          return this.http.put<ProgramResponse>(url, programToUpdate).pipe(catchError(this._catchErrorupdateProgram<ProgramResponse>()));
        }),
      );
    }

    return this.http.put<ProgramResponse>(url, programToUpdate).pipe(catchError(this._catchErrorupdateProgram<ProgramResponse>()));
  }

  setIdProgramId(programId: number): void {
    this.programId.next(programId);
  }

  search(data: SearchProgramData, path: string): Observable<ProgramSearchQueryResponse | LotSearchQueryResponse> {
    const url = this.appConfig.getLoopbackApiUrl().concat(path);
    const cleanData = JSON.parse(JSON.stringify(data));
    delete cleanData.where.localisationsList;

    return this.http
      .post<ProgramSearchQueryResponse | LotSearchQueryResponse>(url, cleanData)
      .pipe(
        catchError(this.errorHandlerService.handleError<ProgramSearchQueryResponse | LotSearchQueryResponse>('programService', 'search')),
      );
  }

  searchPrograms(data: SearchProgramData): Observable<ProgramSearchQueryResponse> {
    return this.search(data, this.searchProgramsApi) as Observable<ProgramSearchQueryResponse>;
  }

  searchLots(data: SearchProgramData): Observable<LotSearchQueryResponse> {
    return this.search(data, this.searchLotsApi) as Observable<LotSearchQueryResponse>;
  }

  updateProgramStatus(programId: number, status: ProgramStatus): Observable<unknown> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Programs/updateStatus/${programId}/${ProgramStatus[status]}`;

    return this.http
      .patch(url, { programStatusId: status })
      .pipe(catchError(this.errorHandlerService.handleError<ProgramResponse>('programService', 'patch')));
  }

  checkProgramReference(programRef: string, companyId: number, programId: number): Observable<boolean> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Programs/check-program-ref`;

    return this.http
      .post<boolean>(url, { companyId, programId, programRef })
      .pipe(catchError(this.errorHandlerService.handleError<boolean>('programService', 'get')));
  }

  getNominalTaxationValue(): Observable<TaxationResponse[]> {
    const filter = {
      where: {
        label: this.appConfig.getAppConfig().nominalTaxationLabel,
      },
      fields: {
        taxation: true,
      },
    };

    const params = new HttpParams().set('filter', JSON.stringify(filter));
    const url = `${this.appConfig.getLoopbackApiUrl()}/Taxations`;

    return this.http
      .get<TaxationResponse[]>(url, { params })
      .pipe(catchError(this.errorHandlerService.handleError<TaxationResponse[]>('programService', 'get')));
  }

  searchProgramsByName(value: string): Observable<Array<AutoCompleteByProgram>> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Programs/find-by-name/${value}`;

    return this.http
      .get<Array<AutoCompleteByProgram>>(url)
      .pipe(catchError(this.errorHandlerService.handleError<Array<AutoCompleteByProgram>>('programService', 'searchByName')));
  }

  getDeliveryDateOfProgram(lots: Array<LotResponse>): Date {
    // sort by delivery date and get delivery date of first element.
    // de-structure and create a new array, so reverse doesn't impact program.lots
    const sortedLotsByDeliveryDate = [...lots].sort(this.sortByDeliveryDate);
    if (sortedLotsByDeliveryDate && sortedLotsByDeliveryDate[0] && sortedLotsByDeliveryDate[0].deliveryDate) {
      return new Date(sortedLotsByDeliveryDate[0].deliveryDate);
    }

    return undefined;
  }

  sortByDeliveryDate(a: LotResponse, b: LotResponse): number {
    const aDate = new Date(a.deliveryDate).getTime();
    const bDate = new Date(b.deliveryDate).getTime();

    if (aDate < bDate) {
      return -1;
    }
    if (aDate > bDate) {
      return 1;
    }

    return 0;
  }

  /**
   * Check Lot fiscality and change status from draft to pending
   * Used if a dev create a program
   * @param programId
   * @param isValo
   */
  sendPendingValidationProgram(programId: number, isValo: boolean): void {
    this.updateProgramStatus(programId, ProgramStatus.PENDING).subscribe(
      () => {
        if (!isValo) {
          this.snackBarService.sendMessage({
            type: SnackbarMessageType.Info,
            text: this.i18nService._('TxT_Program_Form_Valid_Submission'),
          });
          this.router.navigate([Sitemap.programs.admin.path]);
        }
      },
      ({ error }) => {
        if (error.error.code === 'ERR_CHECK_FISCALITY') {
          this.snackBarService.sendMessage({
            type: SnackbarMessageType.Error,
            text: this.i18nService._('Error_Lot_Has_Too_Many_Reduced_Taxations'),
          });
        }
        this.snackBarService.sendMessage({
          type: SnackbarMessageType.Error,
          text: this.i18nService._('Error_SnackBar_FormIncomplete'),
        });
      },
    );
  }

  private sanitizeAndDecodeDescription(description: string): string {
    const encodedDescription = encodeURIComponent(description);
    const sanitized = this.sanitizer.sanitize(SecurityContext.HTML, encodedDescription) || '';
    return decodeURIComponent(sanitized);
  }

  public sanitizeFormFields(programTextsForm: [UntypedFormGroup]): void {
    try {
      // Assuming programTextsForm is an array with a single FormGroup
      const value = programTextsForm[0].value.length ? programTextsForm[0].value[0] : programTextsForm[0].value;

      // List of fields to sanitize
      const fieldsToSanitize = ['longDescrValo', 'shortDescrValo', 'description'];

      fieldsToSanitize.forEach((field) => {
        if (value[field]) {
          value[field] = this.sanitizeAndDecodeDescription(value[field]);
        }
      });
    } catch (error) {
      this.errorHandlerService.handleError('programService', 'sanitizeFormFields');
      throw error;
    }
  }

  getSpecialOffers(programId: number): Observable<Array<SpecialOffer>> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/SpecialOffers/get-special-offers-of-program/${programId}`;

    return this.http.get<Array<SpecialOffersResponse>>(url).pipe(
      map((specialOffers) =>
        specialOffers.map((specialOffer) => {
          const output: SpecialOffer = {
            id: specialOffer.id,
            refSpecialOffer: specialOffer.refSpecialOffer,
            startDate: specialOffer.startDate,
            endDate: specialOffer.endDate,
            amendmentId: specialOffer.amendmentId,
            description: specialOffer.description,
            title: specialOffer.title,
            legalNotice: specialOffer.legalNotice,
            nbLots: specialOffer.nbLots,
          };
          if (specialOffer.amendmentId) {
            output.amendment = {
              id: specialOffer.amendmentId,
              title: specialOffer.documentTitle,
              container: specialOffer.documentContainer,
              fileName: specialOffer.documentFileName,
              mimeType: specialOffer.documentMimeType,
              type: {
                code: specialOffer.documentTypeCode,
                label: specialOffer.documentTypeLabel,
              },
            };
          }

          return output;
        }),
      ),
      catchError(this.errorHandlerService.handleError<SpecialOffer[]>('programService', 'getSpecialOffers')),
    );
  }

  getCities(stringToSearch: string): Observable<Array<{ inseeCode: string; label: string; zipcodeId: number }>> {
    if (stringToSearch.length < 3) {
      return of([]);
    }

    return this.http
      .get<Array<CityResponse>>(
        `${this.appConfig.getLoopbackApiUrl()}/Cities?filter[where][label][regexp]=/^${stringToSearch
          .toUpperCase()
          .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')}.*$/&filter[include]=zipcodes`,
      )
      .pipe(
        map((cities) =>
          cities.reduce(
            (acc, city) => [
              ...acc,
              ...city.zipcodes.map((zipcode) => ({
                inseeCode: city.inseeCode,
                label: `${city.label} (${zipcode.label})`,
                zipcodeId: zipcode.id,
              })),
            ],
            [],
          ),
        ),
        catchError(
          this.errorHandlerService.handleError<Array<{ inseeCode: string; label: string; zipcodeId: number }>>(
            'programService',
            'getCities',
          ),
        ),
      );
  }

  setLotSummary(lotSummary: LotSummary): void {
    this.lotSummary.next(lotSummary);
  }

  setCurrency(currency: string): void {
    this.currency.next(currency);
  }

  setFilterSearch(filterSearch: SearchProgramData): void {
    this.filterSearch.next(filterSearch);
  }

  setContractualDocumentPackages(contractualDocumentPackagesBooleanList: ContractualDocumentPackages): void {
    this.contractualDocumentPackagesBooleanList.next(contractualDocumentPackagesBooleanList);
  }

  /**
   * Methode to formate delivery date to MM yyyy
   *
   * @param {Date} date
   * @returns {string}
   * @memberof ProgramService
   */
  public formatCustomDate(date: Date): string {
    return this.datePipe.transform(date, 'MM yyyy');
  }

  setSelectedLotDetail(selectedLotDetails: LotDetailResponse): void {
    this.selectLotDetails.next(selectedLotDetails);
  }

  /**
   * Method to construct available lots string
   *
   * @param program
   */
  public constructAvailableLotsString(program: ProgramDetail | ProgramSearchResponse): string {
    let text = '';

    if (program.nbLotsAvailable > 0) {
      text += `${program.nbLotsAvailable} ${
        program.nbLotsAvailable === 1
          ? this.i18nService._('Txt_Page_Program_ProgramAvailableLot').toLowerCase()
          : this.i18nService._('Txt_Page_Program_ProgramAvailableLots').toLowerCase()
      }`;
    }

    if (program.nbLotsOptionned > 0) {
      text += `${text ? ' / ' : ''}${program.nbLotsOptionned} ${
        program.nbLotsOptionned === 1
          ? this.i18nService._('Txt_Page_Program_ProgramOptionnedLot').toLowerCase()
          : this.i18nService._('Txt_Page_Program_ProgramOptionnedLots').toLowerCase()
      }`;
    }

    if (program.nbLotsNotAvailable > 0) {
      text += `${text ? ' / ' : ''}${program.nbLotsNotAvailable} ${
        program.nbLotsNotAvailable === 1
          ? this.i18nService._('Txt_Page_Program_ProgramNotAvailableLot').toLowerCase()
          : this.i18nService._('Txt_Page_Program_ProgramNotAvailableLots').toLowerCase()
      }`;
    }

    return text;
  }

  pushResultSearchToDatalayer(resultsData: ProgramSearchResponse[], resultCount: Record<string, number>) {
    const dataToPush = {
      programs: resultsData.slice(0, 10).map((value, index) => {
        return {
          id: value.programId,
          name: value.programName,
          position: index,
        };
      }),
      programResultsNumber: resultCount,
      lotResultsNumber: '',
      event: 'program_listing',
    };
    this._googleTagManagerService.pushTag(dataToPush);
  }

  pushClickedProgramToDatalayer(resultsData: ProgramSearchResponse, index: number) {
    const dataToPush = {
      program: {
        id: resultsData.programId,
        name: resultsData.programName,
        position: index,
      },
      event: 'program_click',
    };
    this._googleTagManagerService.pushTag(dataToPush);
  }

  private _catchErrorupdateProgram<T>(): (err) => Observable<T> {
    return (httpError): Observable<T> => {
      if (httpError && httpError.error && httpError.error.error && httpError.error.error.code === 'ERROR_PROGRAM_INCOMPLETE_LOTS') {
        return throwError(httpError);
      }

      return this.errorHandlerService.handleError<T>('programService', 'post')(httpError);
    };
  }

  setIsElectronicSignatureValorissimo(value: boolean) {
    this.isElectronicSignatureValorissimoSubject.next(value);
  }

  getIsElectronicSignatureValorissimo(): Observable<boolean> {
    return this.isElectronicSignatureValorissimoSubject.asObservable();
  }
}
