<ng-container *ngIf="program?.highlights && program?.highlights?.length">
  <span class="text-2xl font-medium">
    {{ 'Txt_page_program_highlights_title' | translate }}
  </span>
  <div class="shadow-custom shadow-gray-200 rounded-md p-8 mt-7 bg-white flex flex-col">
    <div class="gap-4 flex justify-around flex-col md:flex-row">
      <div *ngFor="let highlight of program.highlights" class="gap-4 flex flex-row items-center md:w-2/6 md:flex-col justify-start w-full">
        <div class="bg-gray-100 py-2 px-2 rounded-full inline-block w-14 h-14">
          <mat-icon class="material-icons-outlined text-4xl">{{ highlight.icon }}</mat-icon>
        </div>
        <span class="font-semibold text-left md:text-center"> {{ highlight.label }} </span>
      </div>
    </div>
  </div>
</ng-container>
