import { IsBoolean, IsInt, IsOptional } from 'class-validator';

export class PaternityCreateRequest {
  @IsInt()
  dossierId: number;

  @IsInt()
  developerCompanyId: number;

  @IsInt()
  @IsOptional()
  programId?: number;

  @IsBoolean()
  @IsOptional()
  returnSummary?: boolean = false;
}

export type PaternityCreateProperty = Omit<PaternityCreateRequest, 'dossierId'>;
