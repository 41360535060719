import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

import { Sitemap } from '../../models/Sitemap';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
  standalone: true,
  imports: [MatButtonModule],
})
export class NotFoundPageComponent {
  constructor(
    public i18nService: I18nService,
    private readonly router: Router,
  ) {
    // Redirecting encoded url properly to proper address
    const url = decodeURIComponent(this.router.url);

    if (url !== this.router.url) {
      this.router.navigateByUrl(url);
    }
  }

  backToLoginPage(): void {
    this.router.navigate([Sitemap.utils.login.path]);
  }
}
