import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';

import { TagOption, TagsInterface } from '../../../../design-system/model/tag-option';
import { programTags } from '../../../models/enum/program-tag.enum';
import { EMPTY_VALUE_NA } from '../../../../utils/models/app-constant';
import { BasicFormatsService } from '../../../../utils/services/basic-formats.service';
import { ProgramPageService } from '../../../services/program-page.service';
import { I18nService } from '../../../../utils/services/i18n.service';
import { TagsComponent } from '../../../../design-system/component/tags/tags.component';

@Component({
  selector: 'app-program-page-rpv-card',
  templateUrl: './program-page-rpv-card.component.html',
  styleUrls: ['./program-page-rpv-card.component.scss'],
  standalone: true,
  imports: [NgIf, TagsComponent, NgClass, RouterLink, MatIconModule],
})
export class ProgramPageRpvCardComponent implements OnInit {
  highStakeTag: TagsInterface;
  isHighStake: boolean;
  firstPublicationDate: string;
  publishedDate: string;
  associatedRpv: string;
  programId: number;

  constructor(
    private readonly _basicFormatsService: BasicFormatsService,
    private _programPageService: ProgramPageService,
    public i18nService: I18nService,
  ) {}

  ngOnInit() {
    this.isHighStake = !!this._programPageService.program.isHighStake;
    this.highStakeTag = { ...programTags.isHighStake };
    // tslint:disable-next-line:no-object-literal-type-assertion
    this.highStakeTag.tagOption = { ...programTags.isHighStake.tagOption } as TagOption;
    this.highStakeTag.tagOption.text = this.i18nService._('Txt_Table_HighStakeProgram');

    this.programId = this._programPageService.program.programId;

    this.firstPublicationDate = EMPTY_VALUE_NA;
    if (this._programPageService.program.firstPublicationDate) {
      this.firstPublicationDate = this._basicFormatsService.fromJsDate(this._programPageService.program.firstPublicationDate, 'dd/MM/yyyy');
    }

    this.publishedDate = EMPTY_VALUE_NA;
    if (this._programPageService.program.publishedDate) {
      this.publishedDate = this._basicFormatsService.fromJsDate(this._programPageService.program.publishedDate, 'dd/MM/yyyy');
    }

    this.associatedRpv = EMPTY_VALUE_NA;
    if (this._programPageService.program.associatedRpv) {
      this.associatedRpv = this._programPageService.program.associatedRpv;
    }
  }
}
