import { Component, OnInit } from '@angular/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-formly-input',
  templateUrl: './formly-input.component.html',
  styleUrls: ['./formly-input.component.scss'],
  standalone: true,
  imports: [FormsModule, InputTextModule, ReactiveFormsModule, FormlyModule],
})
export class FormlyInputComponent extends FieldType implements OnInit {
  constructor(private readonly i18nService: I18nService) {
    super();
  }

  ngOnInit(): void {
    if (this.field.props.i18n) {
      this.formControl.setValue(this.i18nService._(this.formControl.value), { onlySelf: true, emitEvent: false });
    }
  }
}
