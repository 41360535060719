<div class="mat-menu-dropdown" mat-menu-item [matMenuTriggerFor]="menu">
  <mat-icon class="size-26">arrow_drop_down</mat-icon>
  <span class="label">{{ buttonLabel }}</span>
</div>

<mat-menu #menu="matMenu">
  <div *ngFor="let action of actions; trackBy: trackActions" mat-menu-item (click)="action.action()">
    <span>
      {{ i18nService._(action.label) }}
    </span>
  </div>
</mat-menu>
