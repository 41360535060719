/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import { StringsProject } from '../i18n/fr_FR';

/**
 * I18nService class
 * @export
 * @class I18nService
 */
@Injectable()
export class I18nService {
  /**
   * strings value
   * @type {*}
   */
  strings: any = {};

  static _strings: any = {};

  /**
   * Creates an instance of I18nService.
   * @param {StringsProject} stringsProject
   */
  constructor(private readonly stringsProject: StringsProject) {
    this.strings = this.stringsProject.getStrings();
    I18nService._strings = this.strings;
  }

  /**
   * Getter the wording about key
   * @param {string} key
   * @param {*} [args]
   * @param log
   * @returns {string}
   */
  _(key?: string, args?: any, log = false): string {
    if (key in this.strings) {
      let value = this.strings[key];
      if (args && Array.isArray(args)) {
        // We look for a plural if there is only 1 argument and arg is a number > 1
        if (args.length === 1 && !isNaN(args[0]) && args[0] > 1 && `${key}_Plural` in this.strings) {
          value = this.strings[`${key}_Plural`];
        }

        return value.replace(/{(\d+)}/g, (match: any, numberArg: any): string => {
          return typeof args[numberArg] !== 'undefined' ? args[numberArg] : match;
        });
      }

      return value;
    }
    if (log && key) {
      console.warn('Missing key in translation file : ', key);
    }

    return key;
  }

  static __(key?: string, args?: any, log = false): string {
    if (key in this._strings) {
      let value = this._strings[key];
      if (args && Array.isArray(args)) {
        // We look for a plural if there is only 1 argument and arg is a number > 1
        if (args.length === 1 && !isNaN(args[0]) && args[0] > 1 && `${key}_Plural` in this._strings) {
          value = this._strings[`${key}_Plural`];
        }

        return value.replace(/{(\d+)}/g, (match: any, numberArg: any): string => {
          return typeof args[numberArg] !== 'undefined' ? args[numberArg] : match;
        });
      }

      return value;
    }
    if (log && key) {
      console.warn('Missing key in translation file : ', key);
    }

    return key;
  }
}
