import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment-timezone';
import { MatButtonModule } from '@angular/material/button';

import { SpecialOfferDetailResponse } from '../../../utils/models/SpecialOfferDetailResponse';
import { BasicFormatsService } from '../../../utils/services/basic-formats.service';
import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-offer-special-dialog',
  templateUrl: './offer-special-dialog.component.html',
  styleUrls: ['./offer-special-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
})
export class OfferSpecialDialogComponent {
  constructor(
    public readonly i18nService: I18nService,
    private readonly dialogRef: MatDialogRef<OfferSpecialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public specialOffer: SpecialOfferDetailResponse,
    private readonly basicFormatsService: BasicFormatsService,
  ) {}

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public formatDate(date: string | Date): string {
    return this.basicFormatsService.formatDate(moment(date), 'DD MMMM YYYY');
  }
}
