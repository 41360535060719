<div class="strong-points" *ngIf="strongPointsToDisplay?.length">
  <app-strong-point
    [isHoverable]="isHoverable"
    [tooltip]="strongPoint.tooltip"
    [customClass]="customClass"
    *ngFor="let strongPoint of strongPointsToDisplay"
  >
    {{ strongPoint.name }}
  </app-strong-point>
</div>
