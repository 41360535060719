import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';

import { ChipAutocompletePopulatedComponent } from '../chip-autocomplete-populated/chip-autocomplete-populated.component';

import { AccountAsResponse } from '../../models/AccountAsResponse';
import { AccountAsService } from '../../services/account-as.service';
import { I18nService } from '../../services/i18n.service';
import { ReferenceTablesService } from '../../services/reference-tables.service';
import { SnackbarService } from '../../services/snackbar.service';
import { FeatureFlagService } from '../../../feature-flag/feature-flag.service';

@Component({
  selector: 'app-login-as',
  templateUrl: './login-as.component.html',
  styleUrls: ['./login-as.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatChipsModule,
    NgClass,
    NgIf,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    NgFor,
    MatOptionModule,
  ],
})
export class LoginAsComponent extends ChipAutocompletePopulatedComponent implements OnInit {
  @ViewChild('chipList') chipList;
  // public declare timeoutWork;
  public emptyResponse;
  public notEnoughLetters;
  isNewHomepage = false;

  constructor(
    referenceTablesService: ReferenceTablesService,
    snackbarService: SnackbarService,
    public i18nService: I18nService,
    public accountAsService: AccountAsService,
    private readonly featureFlag: FeatureFlagService,
  ) {
    super(referenceTablesService, snackbarService, i18nService);
    this.featureFlag.isEnabled('new-homepage').then((isNewHomepage) => {
      if (isNewHomepage) {
        this.isNewHomepage = isNewHomepage;
      }
    });
  }

  /**
   * For each account received, it is added to the list in a predefined format.
   *
   * @param accountsAs : the array of received accounts.
   */
  public formatAccountAsResponseList(accountsAs: Array<AccountAsResponse>): void {
    this.mapListFilteredItems.clear();
    accountsAs.forEach((element) => {
      this.mapListFilteredItems.set(`${element.lastName} ${element.firstName} ${element.tradingName} ${element.email}`, element.accountId);
    });
  }

  public filter(): Observable<string[]> {
    clearTimeout(this.timeoutWork);
    this.timeoutWork = setTimeout((): Observable<Array<string>> => {
      const value = this.chipCtrl.value.trim();
      if (!value.length) {
        this.notEnoughLetters = false;
      } else if (value.length < 2) {
        this.notEnoughLetters = true;
        this.emptyResponse = false;
      } else {
        this.notEnoughLetters = false;
        this.chipList.errorState = this.chipCtrl.errors && this.chipCtrl.errors.required;
        this.accountAsService.getAccountsAs(value).subscribe((itemsFound) => {
          this.emptyResponse = itemsFound.length === 0;
          this.formatAccountAsResponseList(itemsFound);
          this.filteredItems = Array.from(this.getMapListFilteredItems().keys());
        });

        return of(Array.from(this.getMapListFilteredItems().keys()));
      }
    }, 200);

    return of([]);
  }
}
