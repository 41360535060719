import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';

import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { Sitemap } from '../../../utils/models/Sitemap';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { isPasswordValidators } from '../../../utils/validators/is-password.directive';
import { mustMatch } from '../../../utils/validators/must-match.directive';
import { ChangePasswordService } from '../../services/change-password.service';
import { CheckTokenService } from '../../services/check-token.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  standalone: true,
  imports: [MatListModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgClass, NgIf, MatButtonModule],
})
export class ChangePasswordComponent implements OnInit {
  /**
   * Create / reset password form
   */
  initForm: FormGroup<any>;

  /**
   * Control the new password field
   * @type {FormControl<string>}
   */
  newPasswordControl: FormControl<string>;
  /**
   * Control the confirm password field
   * @type {FormControl<string>}
   */
  confirmControl: FormControl<string>;

  /**
   * Control to hide or show new password
   * @type {boolean}
   */
  hideNewPassword: boolean;

  /**
   * Control to hide or show confirm password
   * @type {boolean}
   */
  hideConfirmPassword: boolean;

  /**
   * Creates an instance of ChangePasswordComponent.
   * @param {FormBuilder} fb
   * @param {I18nService} i18nService
   * @param {ChangePasswordService} changePasswordService
   * @param {Router} router
   * @param {ActivatedRoute} activatedRoute
   * @param {CheckTokenService} checkTokenService
   * @memberof ChangePasswordComponent
   */
  constructor(
    private readonly fb: FormBuilder,
    public i18nService: I18nService,
    private readonly changePasswordService: ChangePasswordService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly checkTokenService: CheckTokenService,
    private readonly snackbarService: SnackbarService,
  ) {
    this.initForm = this.fb.group(
      {
        newPassword: ['', [Validators.required, isPasswordValidators()]],
        confirmPassword: ['', [Validators.required, isPasswordValidators()]],
      },
      {
        validators: [mustMatch('newPassword', 'confirmPassword')],
      },
    );
    this.newPasswordControl = this.initForm.controls.newPassword as FormControl;
    this.confirmControl = this.initForm.controls.confirmPassword as FormControl;
    this.hideNewPassword = true;
    this.hideConfirmPassword = true;
  }

  /**
   * ngOnInit method
   *
   * @memberof ChangePasswordComponent
   */
  ngOnInit(): void {
    const token = this.activatedRoute.snapshot.queryParams.access_token;
    // Check if token is valid
    if (token) {
      this.checkTokenService.checkToken(token).subscribe(
        () => {
          // Do nothing
        },
        () => {
          // If token not valid, show error in message banner then redirect to login page
          const message: SnackbarMessage = {
            text: this.i18nService._('Error_SnackBar_TokenNotValidForPasswordChange'),
            type: SnackbarMessageType.Error,
          };
          this.snackbarService.sendMessage(message);
          this.redirectionLoginPage();
        },
      );
    } else {
      // If no token, show error in message banner then redirect to login page
      const message: SnackbarMessage = {
        text: this.i18nService._('Error_SnackBar_TokenNotValidForPasswordChange'),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
      this.redirectionLoginPage();
    }
  }

  /**
   * onSubmit method
   *
   * @memberof ChangePasswordComponent
   */
  onSubmit(): void {
    if (this.initForm.valid) {
      this.changePasswordService.resetPassword(this.initForm.value.newPassword).subscribe(
        () => {
          // Changement of password ok so show validation message in banner and redirection to login page
          const message: SnackbarMessage = {
            text: this.i18nService._('Success_SnackBar_PasswordChanged'),
            type: SnackbarMessageType.Info,
          };
          this.snackbarService.sendMessage(message);
          this.redirectionLoginPage();
        },
        (resetPasswordError) => {
          let wordingAlert = this.i18nService._('Error_SnackBar_ErrorOccured');
          const error = resetPasswordError.error;
          if (error) {
            const errorCode = error.code;
            switch (errorCode) {
              case 'INVALID_PASSWORD': {
                wordingAlert = this.i18nService._('Error_Field_PasswordMustRespectRules');
                break;
              }
              case 'ERROR_NEW_PASSWORD_ALREADY_USED_IN_HISTORY': {
                const nbPasswordNotPermit = error.additionalInfo.nbOldPasswordNotPermit;
                wordingAlert = this.i18nService._('Error_Field_PasswordUnusable', [nbPasswordNotPermit]);
                break;
              }
              case 'AUTHORIZATION_REQUIRED': {
                wordingAlert = this.i18nService._('ErrorAuthorization');
                break;
              }
              default:
                break;
            }

            this.initForm.reset();
            const message: SnackbarMessage = {
              text: wordingAlert,
              type: SnackbarMessageType.Error,
            };
            this.snackbarService.sendMessage(message);
          }
        },
      );
    }
  }

  /**
   * redirectionLoginPage method
   *
   * @private
   * @memberof ChangePasswordComponent
   */
  private redirectionLoginPage(): void {
    this.router.navigate([Sitemap.utils.login.path]);
  }
}
