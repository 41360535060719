<div class="div-filter-sort">
  <app-filter-multiselect
    [filterOptions]="filterOptions"
    (selectedOptionsChange)="onFilterChanged($event)"
    *ngIf="filterEnabled"
  ></app-filter-multiselect>
  <app-sort-dropdown
    [dropdownInformation]="sortOptions"
    (selectedOptionChange)="onSortChanged($event)"
    *ngIf="sortEnabled"
  ></app-sort-dropdown>
</div>
