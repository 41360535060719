<div class="flex flex-col w-full h-full overflow-auto">
  <!-- Sticky subHeader form -->
  <app-sticky-header-form [titleText]="i18nService._('Title_Paternity')"></app-sticky-header-form>

  <div class="pt-18 mx-3 lg:mx-5 md:mx-5 sm:mx-4 px-2 lg:px-6 md:px-5 sm:px-3">
    <div class="grid grid-cols-2 mb-3">
      <div class="grid grid-cols-6 gap:4 lg:gap-6">
        <div class="col-span-2">
          <mat-form-field class="fullW flex w-full">
            <mat-label>{{ i18nService._('Txt_Placeholder_Search') }}</mat-label>
            <input data-cy="Recherche" [(ngModel)]="filterValue" (ngModelChange)="textFilterSubject.next($event)" matInput />
          </mat-form-field>
        </div>

        <div class="col-span-2">
          <mat-form-field class="fullW flex w-full my-0">
            <mat-label>{{ i18nService._('Txt_Table_Column_Status') }}</mat-label>
            <mat-select [ngModel]="selectedStatus" (selectionChange)="onStatusListChange($event)" multiple data-cy="Statut">
              <mat-option *ngFor="let status of statusFilterList" [value]="status.value" attr.data-cy="{{ status.label | translate }}">
                {{ status.label | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-span-2">
          <mat-form-field *ngIf="displayRPV" class="fullW flex w-full">
            <mat-label>{{ i18nService._('Txt_ProspectTable_RPV') }}</mat-label>
            <input [(ngModel)]="rvpSearch" (ngModelChange)="onRvpFilterChange($event)" matInput />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="tracking-mat-table">
      <app-grid-tracking-table
        [tableLoader]="prospectsTrackingTableService"
        [columnDefs]="columnDefs"
        [pageSize]="pageSize"
        [defaultSortColunm]="sortColumn"
        [defaultSortDirection]="sortDirection"
        [textFilter]="textFilterValue"
        [i18nFilter]="i18nFilter"
        [minimumFilterLength]="minimumFilterLength"
      >
      </app-grid-tracking-table>
    </div>
  </div>
</div>
