/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { interval } from 'rxjs/internal/observable/interval';
import { debounce, Subject, takeUntil } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgIf } from '@angular/common';

import { EventUtilsService } from '../../../../utils/services/event/event-utils.service';
import { I18nService } from '../../../../utils/services/i18n.service';
import { SearchPanelTemplateDirective } from '../../../directive/search-panel-template/search-panel-template.directive';
import { SEARCH_PANEL_COMPONANT_BY_TYPE } from '../../../model/search-page-contantes';
import { SearchPanelTypeEnum } from '../../../model/search-panel-type-enum';
import { FilterUtilsService } from '../../../services/filter/filter-utils.service';

@Component({
  selector: 'app-filter-menu-badge',
  templateUrl: './filter-menu-badge.component.html',
  styleUrls: ['./filter-menu-badge.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, MatIconModule, SearchPanelTemplateDirective],
})
export class FilterMenuBadgeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() placeholder: string;
  @Input() limit = 2;
  @Input() panelComponant: SearchPanelTypeEnum;
  @Input() modal: boolean;
  private _selectedData: string[] | number[];
  showPlaceholder = true;
  showDataLength = false;
  dataToShow: string;
  showPanel = false;
  private readonly $d = new Subject();

  get selectedData(): string[] | number[] {
    return this._selectedData;
  }

  @Input() set selectedData(value: string[] | number[]) {
    this._selectedData = value;
    this.showPlaceholder = !(value && value.length);
    if (value) {
      this.dataToShow = value.join(', ');
      if (value.length > this.limit) {
        this.dataToShow = [...value.slice(0, this.limit), '...'].join(',');
        this.showDataLength = true;
      } else {
        this.showDataLength = false;
      }
    }
  }

  @ViewChild(SearchPanelTemplateDirective)
  panelRef: SearchPanelTemplateDirective;
  @Output() readonly filterChangeEvent: EventEmitter<string[]> = new EventEmitter();
  @Input() prefixIcon: string;

  constructor(
    public readonly i18nService: I18nService,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _filterUtilsService: FilterUtilsService,
    private readonly _eventUtilsService: EventUtilsService,
    private readonly eRef: ElementRef,
  ) {}

  ngOnInit() {
    this._eventUtilsService.documentClickedTarget
      .pipe(
        takeUntil(this.$d),
        debounce(() => interval(50)),
      )
      .subscribe((target) => {
        this.documentClickListener(target);
      });

    this._filterUtilsService.$openPanel.pipe(takeUntil(this.$d)).subscribe((value) => {
      this.showPanel = value[this.panelComponant];
    });
  }

  ngAfterViewInit(): void {
    if (!this.modal) {
      this.loadComponent();
    } else {
      this.loadModal();
    }
  }

  ngOnDestroy(): void {
    this.$d.next(true);
    this.$d.complete();
  }

  documentClickListener(target: any): void {
    if (!this.eRef.nativeElement.contains(target)) {
      // Clicked outside
      this._filterUtilsService.hidePanel(this.panelComponant);
      // eslint-disable-next-line no-empty
    } else {
    }
  }

  private loadComponent() {
    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(SEARCH_PANEL_COMPONANT_BY_TYPE[this.panelComponant]);

    const viewContainerRef = this.panelRef.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);

    componentRef.instance.filterChangeEvent.pipe(takeUntil(this.$d)).subscribe((filter: string[]) => {
      this.filterChangeEvent.next(filter);
    });
  }

  showSubPanel() {
    this._filterUtilsService.showPanel(this.panelComponant, !this.showPanel);
  }

  preventDefault($event: MouseEvent) {
    $event.stopImmediatePropagation();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private loadModal() {}
}
