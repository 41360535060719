<h3 class="text-xl font-bold pt-[2px]">{{ getLabel(step.title) }}</h3>
<span *ngIf="step.subTitleData" class="text-xs text-gray-500 font-semibold flex align-middle">
  <span [innerHTML]="getLabel(step.subTitleData.key, step.subTitleData.value)"></span>
  <app-icon
    [name]="step.subTitleData.icon"
    *ngIf="step.subTitleData.icon"
    class="ml-2 text-gray-500 {{ step.subTitleData.icon }}"
  ></app-icon>
</span>
<div *ngIf="step.events" class="mt-4">
  <div
    *ngFor="let event of step.events"
    class="border-t-2 border-t-solid border-t-gray-200 flex justify-between px-4 py-2 last:border-b-2 last:border-b-solid last:border-b-gray-200"
  >
    <span class="text-sm mr-4 text-gray-600">{{ getLabel(event.label.key, event.label.value) }}</span>
    <span *ngIf="event.value" class="text-sm text-gray-600">{{ getLabel(event.value.key, event.value.value) }}</span>
  </div>
</div>
<div class="w-full flex justify-end flex-wrap mt-2" *ngIf="step.action">
  <a *ngFor="let action of step.action" (click)="action.function()" class="text-orange-500 underline hover:text-orange-700 mr-4">
    {{ getLabel(action.key, action.value) }}
  </a>
</div>
