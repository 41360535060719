<ng-template #defaultTemplate let-dataItem let-labelClass="labelClass" let-valueClass="valueClass">
  <div class="item-label" [ngClass]="labelClass" [innerHTML]="i18nService._(dataItem?.label) | safe: 'html'"></div>
  <div class="item-value" [ngClass]="valueClass" [innerHTML]="dataItem?.value | safe: 'html'"></div>
</ng-template>

<div class="item-container text-base" [ngClass]="itemContainerClass">
  <ng-container
    [ngTemplateOutlet]="optionTemplate || defaultTemplate"
    [ngTemplateOutletContext]="{
      $implicit: item,
      labelClass: itemLabelClass,
      valueClass: itemValueClass
    }"
  >
  </ng-container>
</div>
