<mat-form-field *ngIf="design === 'default'" class="flex w-full">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-chip-grid #chipList class="chip-autocomplete">
    <mat-chip-row
      class="mt-1 mr-3 flex justify-center items-center"
      color="primary"
      *ngFor="let item of itemsLabelKeyList"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(item)"
    >
      <span matChipRemove *ngIf="removable" class="icon-cancel txt-orange text-xxs ml-0 mr-1 flex justify-center items-center"></span>
      {{ item }}
    </mat-chip-row>
    <input
      #chipInput
      [formControl]="chipCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
      [disabled]="uniqueLocation && !isLocalisationsEmpty()"
      data-cy="fastSearchLocation"
    />
  </mat-chip-grid>
  <mat-error *ngIf="chipCtrl.errors && chipCtrl.errors.required">
    {{ i18nService._('Error_Field_Required') }}
  </mat-error>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let item of filteredItems" [value]="item">
      {{ item }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<input
  *ngIf="design === 'homepage'"
  [(ngModel)]="fastSearchLocation"
  class="localisation-input"
  [placeholder]="i18nService._('Txt_placeholder_fast_search')"
  (keyup)="_filter()"
  data-cy="fastSearchLocation"
/>

<div *ngIf="displayDropdownSelect && design === 'homepage'" class="dropdown-localisation-select">
  <div *ngFor="let item of filteredItems" class="dropdown-option" (click)="onClickOption(item)">
    {{ item }}
  </div>
</div>

<div *ngIf="displayDropdownSummary && design === 'homepage'" class="dropdown-localisation-summary">
  <div class="flex flex-wrap gap-1">
    <div *ngFor="let item of fastSearchLocationSummay">
      <p-chip [label]="item" [removable]="true" (onRemove)="onRemoveChip(item)"></p-chip>
    </div>
  </div>
  <div class="flex justify-end font-bold cursor-pointer">
    <span (click)="removeAllChips()"> {{ i18nService._('Txt_Remove_All_Selection') }} </span>
  </div>
</div>
