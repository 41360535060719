<!-- List of secondary lot added -->
<table class="w-full mb-5" *ngIf="secondaryLotsList.length">
  <thead class="bg-graylight text-sm">
    <tr>
      <th class="p-3" scope="col">
        {{ i18nService._('Txt_Page_Form_RegisterProgram_SecondaryLotRef') }}
      </th>
      <th class="p-3" scope="col">
        {{ i18nService._('Txt_Page_Form_RegisterProgram_SecondaryLotNumber') }}
      </th>
      <th class="p-3" scope="col">
        {{ i18nService._('Txt_Page_Form_RegisterProgram_SecondaryLotType') }}
      </th>
      <th class="p-3" scope="col">
        {{ i18nService._('Txt_Page_Form_RegisterProgram_SecondaryLotPrice') }}
      </th>
      <th class="p-3" scope="col" *ngIf="isDeletable || isEditable">
        {{ i18nService._('Txt_Page_Form_RegisterProgram_Actions') }}
      </th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let secondaryLot of secondaryLotsList" class="bg-white shadow-md">
      <td class="p-2 text-center">
        {{ secondaryLot.refLot }}
      </td>
      <td class="p-2 text-center">
        {{ secondaryLot.numLot }}
      </td>
      <td class="p-2 text-center">
        {{ secondaryLot.secondaryLotType }}
      </td>
      <td class="p-2 text-center">
        {{ secondaryLot.lotSellingPriceIT ? secondaryLot.lotSellingPriceIT : 0 }}
        €
      </td>
      <td class="p-2 text-center flex" *ngIf="isDeletable || isEditable">
        <span
          *ngIf="isEditable"
          class="icon-document txt-orange float-left mr-3 text-2xl cursor-pointer"
          [title]="i18nService._('Txt_Tooltip_EditSecondaryLot')"
          (click)="editSecondaryLot(secondaryLot)"
        ></span>
        <span
          *ngIf="isDeletable"
          class="icon-cancel txt-orange float-left pt-2 text-sm cursor-pointer"
          [title]="i18nService._('Txt_Tooltip_Delete')"
          (click)="deleteSecondaryLot(secondaryLot)"
        ></span>
      </td>
    </tr>
  </tbody>
</table>
<form [formGroup]="secondaryLotForm" class="block mb-5" (ngSubmit)="addOrEditSecondaryLot()">
  <div class="grid grid-cols-2 gap-16 mb-2">
    <!-- secondary lot ref -->
    <mat-form-field class="col-span-1 pt-2">
      <mat-label>{{ i18nService._('Txt_Page_Form_RegisterProgram_SecondaryLotRef') + ' *' }}</mat-label>
      <input [formControl]="refControl" data-cy="registerProgramSecondaryLotRef" matInput maxlength="255" name="refLot" type="text" />
      <span [matTooltip]="i18nService._('Txt_Tooltip_LotRef')" class="icon-info txt-gray cursor-pointer" matSuffix></span>
      <mat-error *ngIf="refControl.errors">
        {{ i18nService._('Error_Field_Required') }}
      </mat-error>
    </mat-form-field>

    <!-- secondary lot number -->
    <mat-form-field class="col-span-1 pt-2">
      <mat-label>{{ i18nService._('Txt_Page_Form_RegisterProgram_SecondaryLotNumber') + ' *' }}</mat-label>
      <input [formControl]="numberControl" data-cy="registerProgramSecondaryLotNumber" matInput maxlength="20" name="numLot" type="text" />
      <mat-error *ngIf="numberControl.errors">
        {{ i18nService._('Error_Field_Required') }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="grid grid-cols-2 gap-16 mb-4">
    <!-- secondary lot type -->
    <app-dropdown-list-populated
      #dropdownType
      class="col-span-1"
      [fieldName]="'secondaryLotType'"
      [isSubmit]="addClicked"
      [parentForm]="secondaryLotForm"
      [placeholder]="i18nService._('Txt_Page_Form_RegisterProgram_SecondaryLotType')"
      [referenceTable]="'SecondaryLotTypes'"
      [required]="true"
      [searchable]="true"
    >
    </app-dropdown-list-populated>

    <!-- secondary lot price -->
    <mat-form-field class="col-span-1 pt-2">
      <mat-label>{{ i18nService._('Txt_Page_Form_RegisterProgram_SecondaryLotPrice') }}</mat-label>
      <input
        appDecimalMask
        maxNumberDigitsBeforeSeparator="5"
        [formControl]="priceControl"
        class="text-right"
        matInput
        name="lotSellingPriceIT"
        data-cy="registerProgramSecondaryLotPrice"
      />
      <span class="text-black px-2" matSuffix>€</span>
      <mat-error *ngIf="priceControl.errors">
        {{ i18nService._('Error_Field_Required') }}
      </mat-error>
    </mat-form-field>
  </div>
  <!-- add button -->
  <div class="flex">
    <button *ngIf="!isEditSecondaryLot" mat-flat-button color="primary" data-cy="add">
      {{ i18nService._('Txt_Button_Add') }}
      <span class="icon-plus text-sm font-bold"></span>
    </button>

    <ng-container *ngIf="isEditSecondaryLot">
      <button mat-stroked-button color="primary" class="mr-4" (click)="cancelEditSecondaryLot()">
        {{ i18nService._('Txt_Button_Cancel') }}
      </button>
      <button mat-flat-button color="primary">
        {{ i18nService._('Txt_Button_Save') }}
      </button>
    </ng-container>
  </div>
</form>
<hr class="hr-w-5 my-12" />
