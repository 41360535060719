import { ReservationApiService } from 'apps/valo-front/src/app/adapters/reservation-api.service';
import { TransactionActionService } from 'apps/valo-front/src/app/common/services/transaction/transaction-action.service';
import { CompleteDossierResponse } from '@commons-dto/dossier-prospect';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { LotApiService } from './../../../../adapters/lot-api.service';
import { ProgramApiService } from './../../../../adapters/program-api.service';
import { CompanyApiService } from './../../../../adapters/company-api.service';
import { PoseOptionsService } from './../../../../utils/services/pose-options.service';
import { PoseoptionResponse } from './../../../../utils/models/PoseoptionResponse';
import { ReservationStatusEnum } from './../../../../utils/enums/statusEnums/reservation-status.enum';
import { PreReservationStatusEnum } from './../../../../utils/enums/statusEnums/pre-reservation-status.enum';
import { PipesModule } from './../../../../utils/pipes/pipes.module';
import { UtilsModule } from './../../../../utils/utils.module';
import { DashboardCardComponent } from './../../../../design-system/component/dashboard-card/dashboard-card.component';
import { ButtonComponent } from './../../../../design-system/component/button/button.component';
import { OptionEntity, OptionForDossierProspectDashboard } from './../../../../utils/models/OptionResponse';
import { ButtonType } from '../../../../design-system/component/button/button.type.enum';
import { I18nService } from '../../../../utils/services/i18n.service';
import { DossierProspectRouteService } from '../../dossier-prospect/dossier-prospect.route.service';
import { TimePipe } from '../../../../common/pipe/time.pipe';

declare type RemainingTime = { total: number; percentage: number; inSecond: number };

@Component({
  selector: 'app-dossier-prospect-dashboard-option',
  standalone: true,
  templateUrl: './dossier-prospect-dashboard-option.component.html',
  styleUrls: ['./dossier-prospect-dashboard-option.component.scss'],
  imports: [CommonModule, FormsModule, ButtonComponent, DashboardCardComponent, UtilsModule, PipesModule, TimePipe],
})
export class DossierProspectDashboardOptionComponent implements OnInit, AfterViewInit {
  /**
   * Option
   *
   * @type {OptionForDossierProspectDashboard}
   * @memberof DossierProspectDashboardOptionComponent
   */
  @Input()
  activeOption: OptionForDossierProspectDashboard;

  /**
   * Options
   *
   * @type {OptionForDossierProspectDashboard[]}
   * @memberof DossierProspectDashboardOptionComponent
   */
  private _options: OptionForDossierProspectDashboard[];
  @Input()
  get options(): OptionForDossierProspectDashboard[] {
    return this._options;
  }

  set options(options: OptionForDossierProspectDashboard[]) {
    this._options = options;

    this.optionRefused = 0;
    this.optionExpired = 0;
    if (this.options) {
      for (const option of options) {
        if (option.optionStatusId === 2) this.optionRefused++;
        else if (option.optionStatusId === 4) this.optionExpired++;
      }
    }
  }

  /**
   * dossier
   *
   * @type {CompleteDossierResponse}
   * @memberof DossierProspectDashboardOptionComponent
   */
  @Input() dossier: CompleteDossierResponse;

  @ViewChild('progressBar')
  public progressBar: ElementRef;

  public isOptionComplete: boolean;
  public ButtonType = ButtonType;
  public timer: any;
  public hideActions: boolean;

  public optionRefused: number;
  public optionExpired: number;
  public preResaRefused: number;
  public preResaCanceled: number;
  public resaWithdrawn: number;
  public remainingTime: RemainingTime;

  constructor(
    public readonly i18nService: I18nService,
    private readonly lotService: LotApiService,
    private readonly programService: ProgramApiService,
    private readonly companyService: CompanyApiService,
    private readonly reservationService: ReservationApiService,
    private readonly transactionService: TransactionActionService,
    private readonly poseOptionService: PoseOptionsService,
    private readonly dossierProspectRouteService: DossierProspectRouteService,
  ) {
    this.isOptionComplete = false;
    this.hideActions = false;

    this.optionRefused = 0;
    this.optionExpired = 0;
    this.preResaRefused = 0;
    this.preResaCanceled = 0;
    this.resaWithdrawn = 0;
  }

  public ngOnInit(): void {
    if (this.activeOption) {
      this.isOptionComplete = this.activeOption.optionStatusId == 3;
    } else {
      this.reservationService.getReservationsByDossierProspectId(this.dossier.id).subscribe((reservations) => {
        reservations.forEach((reservation) => {
          switch (reservation.label) {
            case PreReservationStatusEnum.PRERESERVATION_CANCELED:
              this.preResaCanceled++;
              break;
            case PreReservationStatusEnum.PRERESERVATION_REJECTED:
              this.preResaRefused++;
              break;
            case ReservationStatusEnum.CLIENT_DESISTED:
              this.resaWithdrawn++;
              break;
          }
        });
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.activeOption) {
      setTimeout(() => this.initiateTimer(this.activeOption.startDate, this.activeOption.endDate));
    }
  }

  private initiateTimer(startTime: Date, endTime: Date): void {
    this.updateClock(startTime, endTime);
    this.timer = setInterval(() => this.updateClock(startTime, endTime), 1000);
  }

  private getRemainingTime(startTime: Date, endTime: Date): RemainingTime {
    // FIXME: bien que typé correctement dans l'objet reponse de l'API, le type n'est pas le bon
    if (startTime.constructor.name === 'String') startTime = new Date(startTime);
    if (endTime.constructor.name === 'String') endTime = new Date(endTime);

    const t = endTime.getTime() - new Date().getTime();

    const start = startTime.getTime(),
      end = endTime.getTime(),
      today = new Date().getTime();

    const q = Math.abs(today - start);
    const d = Math.abs(end - start);
    const percentage = Math.round((q / d) * 100);

    return {
      total: t,
      inSecond: Math.round(t / 1000),
      percentage,
    };
  }

  private updateClock(startTime: Date, endTime: Date): void {
    this.remainingTime = this.getRemainingTime(startTime, endTime);

    if (this.remainingTime.total <= 0) {
      this.hideActions = true;
      clearInterval(this.timer);
    }

    if (this.progressBar) {
      this.progressBar.nativeElement.setAttribute('style', `width: ${this.remainingTime.percentage}%`);
    }
  }

  private getOptionEntity(): OptionEntity {
    return {
      id: this.activeOption.id,
      lotId: this.activeOption.lotId,
      lotNumber: this.activeOption.lot.lotNumber,
      programName: this.activeOption.lot.program.programName,
    };
  }

  public goToTransaction(): void {
    this.dossierProspectRouteService.goToTransactions(this.dossier.id);
  }

  public preBook(): void {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.transactionService.openPreReservationPopin(this.getOptionEntity(), () => {});
  }

  public extend(): void {
    this.poseOptionService.extendOption(this.activeOption.lotId).subscribe(
      (response: PoseoptionResponse) =>
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.transactionService.onPoseOptionActionSuccess.bind(this)(this.getOptionEntity(), response, () => {}),
      (error: HttpErrorResponse) => {
        this.transactionService.errorUpdateOption(error, this.i18nService._('Error_SnackBar_UnavailableExtendOption'));
      },
    );
  }

  public seeTransactions(): void {
    this.dossierProspectRouteService.goToTransactions(this.dossier.id);
  }

  public searchLot(): void {
    this.dossierProspectRouteService.goToSearchWithDossierSearchPreferences(this.dossier);
  }
}
