import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { ComparatorPageService } from '../services/comparator-page.service';

import { LotComparatorDetail } from '../../lot/model/lot-comparator-detail';

export const ComparatorResolver: ResolveFn<LotComparatorDetail[]> = () => {
  const _comparatorPageService: ComparatorPageService = inject<ComparatorPageService>(ComparatorPageService);

  return _comparatorPageService.lotsToCompareObservable;
};
