import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

import { ActionButtonStategieService } from './service/action-button-stategie.service';

import { I18nService } from '../../../services/i18n.service';

@Component({
  selector: 'app-action-button-stategie',
  templateUrl: './action-button-stategie.component.html',
  styleUrls: ['./action-button-stategie.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, MatIconModule],
})
export class ActionButtonStategieComponent {
  @Input() actionButtonLabel = '';
  @Input() isActionButtonDisabled: boolean;

  constructor(
    public i18nService: I18nService,
    public actionButtonStategieService: ActionButtonStategieService,
  ) {}

  public publicationStrategies(publication) {
    this.actionButtonStategieService.emitPublicationStrategies(publication);
  }
}
