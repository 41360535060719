import { SearchProgramDataWhere } from './search-program-data-where';

export enum SearchPageDisplayType {
  Programme = 'P',
  Lot = 'L',
  Carte = 'C',
}

export interface SearchProgramorderBy {
  label: string;
  direction: string;
}

export class SearchProgramData {
  skip?: number;
  limit?: number;
  order?: SearchProgramorderBy;
  where?: SearchProgramDataWhere;
  waitForSearch?: boolean;

  constructor(data?: SearchProgramData) {
    if (data) {
      Object.assign(this, data);
    } else {
      this.skip = 0;
      this.limit = 50;
      this.order = {
        label: 'price',
        direction: 'ASC',
      };
      this.where = new SearchProgramDataWhere();
      this.waitForSearch = false;
    }
  }
}
