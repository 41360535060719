import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import { StatusTagColor } from '../../model/status-tag-color.enum';

const OUTLINE_CLASS_NAME = 'status-outline';
const TXT_WHITE_CLASS_NAME = 'txt-white';

@Component({
  selector: 'app-status-tags',
  templateUrl: './status-tags.component.html',
  styleUrls: ['./status-tags.component.scss'],
  standalone: true,
})
export class StatusTagsComponent implements OnInit {
  @Input() outline: boolean;
  @Input() statusColor: StatusTagColor = StatusTagColor.IN_PROGRESS;

  constructor(
    private readonly _ref: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit() {
    if (this.outline) {
      this.renderer.addClass(this._ref.nativeElement, OUTLINE_CLASS_NAME);
    } else {
      this.renderer.addClass(this._ref.nativeElement, TXT_WHITE_CLASS_NAME);
    }
    this.renderer.addClass(this._ref.nativeElement, this.statusColor.toString());
  }
}
