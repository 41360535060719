import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgFor, NgIf } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';

import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { ReferenceTableData } from '../../models/ReferenceTableData';
import { ReferenceTablesService } from '../../services/reference-tables.service';

@Component({
  selector: 'app-radio-button-populated',
  templateUrl: './radio-button-populated.component.html',
  styleUrls: ['./radio-button-populated.component.scss'],
  standalone: true,
  imports: [MatRadioModule, FormsModule, ReactiveFormsModule, NgFor, NgIf, MatFormFieldModule],
})
export class RadioButtonPopulatedComponent implements OnInit {
  /**
   * Input referenceTable to get tables informations
   *
   * @type {string}
   * @memberof RadioButtonPopulatedComponent
   */
  @Input() referenceTable: string;

  /**
   * ParentForm to add controls to
   *
   * @type {FormGroup}
   * @memberof RadioButtonPopulatedComponent
   */

  @Input() parentForm: UntypedFormGroup;

  /**
   * ParentForm to add controls to
   *
   * @type {FormGroup}
   * @memberof RadioButtonPopulatedComponent
   */

  @Input() dataToInit;

  /**
   * Parent form group
   *
   * @type {string}
   * @memberof RadioButtonPopulatedComponent
   */
  @Input() fieldName: string;

  /**
   * Get submit from parentForm
   *
   * @type {boolean}
   * @memberof RadioButtonPopulatedComponent
   */
  @Input() isSubmit: boolean;

  /**
   * Get if reference table should be translated or not
   *
   * @type {boolean}
   * @memberof RadioButtonPopulatedComponent
   */
  @Input() notTranslated: boolean;

  /**
   * Get required from parentForm
   *
   * @type {boolean}
   * @memberof RadioButtonPopulatedComponent
   */
  @Input() required: boolean;

  /**
   * Get disabled from parentForm
   *
   * @type {boolean}
   * @memberof RadioButtonPopulatedComponent
   */
  @Input() isDisabled: boolean;

  /**
   * Array to store reference table informations
   *
   * @type {Array<ReferenceTableData>}
   * @memberof RadioButtonPopulatedComponent
   */
  public referenceTableInfos: Array<ReferenceTableData>;

  /**
   *  Field name for radiobutton field
   *
   * @type {string}
   * @memberof RadioButtonPopulatedComponent
   */
  public fieldNameId: string;

  /**
   * Control on input field if 'OTHER' is selected
   *
   * @type {FormControl}
   * @memberof RadioButtonPopulatedComponent
   */
  public radioButtonControl: FormControl;

  /**
   * Creates an instance of RadioButtonPopulatedComponent.
   * @param referenceTablesService
   * @param snackBar
   * @param i18nService
   */
  constructor(
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly snackbarService: SnackbarService,
    public i18nService: I18nService,
  ) {}

  /**
   * Init component
   *
   * @memberof RadioButtonPopulatedComponent
   */
  ngOnInit(): void {
    // Set radio field name
    this.fieldNameId = `${this.fieldName}Id`;
    // Add controls on fields
    this.parentForm.addControl(this.fieldNameId, new UntypedFormControl('', []));
    this.radioButtonControl = this.parentForm.controls[this.fieldNameId] as FormControl;

    if (this.required) {
      this.radioButtonControl.setValidators([Validators.required]);
      this.radioButtonControl.reset();
    } else {
      this.radioButtonControl.clearValidators();
      this.radioButtonControl.reset();
    }

    // Get reference Table info
    if (this.notTranslated) {
      this.referenceTablesService.getTableReferenceInfo(this.referenceTable).subscribe(
        (itemsFound) => {
          this.referenceTableInfos = itemsFound;
        },
        () => {
          this.snackbarService.sendErrorOccured();
        },
      );
    } else {
      this.referenceTablesService.getTableReferenceInfoTranslated(this.referenceTable).subscribe(
        (itemsFound) => {
          this.referenceTableInfos = itemsFound;
        },
        () => {
          this.snackbarService.sendErrorOccured();
        },
      );
    }
    if (this.dataToInit && this.dataToInit[this.fieldNameId]) {
      this.radioButtonControl.setValue(this.dataToInit[this.fieldNameId]);
    }
  }
}
