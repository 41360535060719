/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment-timezone';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { Subject } from 'rxjs';
import { ColDef } from 'ag-grid-community';
import { ValoRole } from '@commons-dto/valo-back';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatBadgeModule } from '@angular/material/badge';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgClass } from '@angular/common';

import { AdvancedTrackingTableComponent } from '../advanced-tracking-table/advanced-tracking-table.component';
import { GridTrackingTableComponent } from '../grid-tacking-table/grid-tracking-table.component';

import { DocumentToDownloadInZip } from '../../../utils/models/DocumentToDownloadInZip';
import { Sitemap } from '../../../utils/models/Sitemap';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { UserRoleService } from '../../../utils/services/user-role.service';
import { ZipFileService } from '../../../utils/services/zip-file.service';
import { AbstractTrackingTable } from '../../abstract-tracking-table';
import { ReservationsTrackingTableItem } from '../../models/ReservationsTrackingTableItem';
import { ReservationsTrackingTableService } from '../../services/reservations-tracking-table.service';
import { StatusTagColor } from '../../../design-system/model/status-tag-color.enum';
import { ReservationStatusEnum } from '../../../utils/enums/statusEnums/reservation-status.enum';
import { DateRendererComponent } from '../../renderers/date-renderer/date-renderer.component';
import { PriceRendererComponent } from '../../renderers/price-renderer/price-renderer.component';
import { ActionRendererComponent } from '../../renderers/action-renderer/action-renderer.component';
import { InformationRendererComponent } from '../../renderers/information/information-renderer.component';
import { StatusTagsRendererComponent } from '../../renderers/status-tags-renderer/status-tags-renderer.component';
import { FilterEventOutput } from '../../models/filter/filter-event-output';
import { ToggleButtonComponent } from '../../../design-system/component/toggle-button/toggle-button.component';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';
import { LotDetailsPanelComponent } from '../../../utils/components/lot-details-panel/lot-details-panel.component';

const tagTypeByReservationStatus: { [key in ReservationStatusEnum]: StatusTagColor } = {
  VALIDATED_DEVELOPER: StatusTagColor.SUCCESS,
  NOTARY_SIGNATURE_OBTAINED: StatusTagColor.SUCCESS,
  LOAN_OFFER_OBTAINED: StatusTagColor.SUCCESS,
  RECEIVED_VALO: StatusTagColor.IN_PROGRESS,
  VALIDATED_VALO: StatusTagColor.IN_PROGRESS,
  SENT_DEVELOPER: StatusTagColor.IN_PROGRESS,
  SRU_NOTIF_SET: StatusTagColor.IN_PROGRESS,
  AGREEMENT_OBTAINED: StatusTagColor.IN_PROGRESS,
  NOTARY_MEETING_OBTAINED: StatusTagColor.IN_PROGRESS,
  CLIENT_DESISTED: StatusTagColor.CRITICAL,
  RESERVATION_CANCELED: StatusTagColor.CRITICAL,
};

@Component({
  selector: 'app-reservations-tracking-table',
  templateUrl: './reservations-tracking-table.component.html',
  styleUrls: ['./reservations-tracking-table.component.scss'],
  standalone: true,
  imports: [
    MatSidenavModule,
    LotDetailsPanelComponent,
    StickyHeaderFormComponent,
    NgIf,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    FormsModule,
    NgClass,
    MatBadgeModule,
    MatFormFieldModule,
    MatInputModule,
    ToggleButtonComponent,
    GridTrackingTableComponent,
  ],
})
export class ReservationsTrackingTableComponent extends AbstractTrackingTable implements OnInit, OnDestroy {
  @ViewChild(AdvancedTrackingTableComponent)
  advancedTrackingTable: AdvancedTrackingTableComponent;
  @ViewChild('drawer', { static: true }) drawer: MatDrawer;

  isSignaturesButtonVisible = false;
  isLoadingAction = false;
  noResultMessageI18nToken = 'Txt_ReservationsTrackingTable_No_Result';

  count = {
    all: 0,
    reservation: 0,
    sold: 0,
    desisted: 0,
  };

  statusFilters = {
    all: {
      code: 'all',
      label: 'all',
      status: [],
    },
    reservation: {
      code: 'reservation',
      label: 'reservation',
      status: [
        'RECEIVED_VALO',
        'VALIDATED_VALO',
        'SENT_DEVELOPER',
        'VALIDATED_DEVELOPER',
        'SRU_NOTIF_SET',
        'AGREEMENT_OBTAINED',
        'LOAN_OFFER_OBTAINED',
        'NOTARY_MEETING_OBTAINED',
      ],
    },
    sold: {
      code: 'sold',
      label: 'sold',
      status: ['NOTARY_SIGNATURE_OBTAINED'],
    },
    desisted: {
      code: 'desisted',
      label: 'desisted',
      status: ['CLIENT_DESISTED'],
    },
  };

  statusFilterList = [
    { label: 'VALIDATED_DEVELOPER', code: 'VALIDATED_DEVELOPER' },
    { label: 'NOTARY_SIGNATURE_OBTAINED', code: 'NOTARY_SIGNATURE_OBTAINED' },
    { label: 'LOAN_OFFER_OBTAINED', code: 'LOAN_OFFER_OBTAINED' },
    { label: 'RECEIVED_VALO', code: 'RECEIVED_VALO' },
    { label: 'VALIDATED_VALO', code: 'VALIDATED_VALO' },
    { label: 'SENT_DEVELOPER', code: 'SENT_DEVELOPER' },
    { label: 'SRU_NOTIF_SET', code: 'SRU_NOTIF_SET' },
    { label: 'AGREEMENT_OBTAINED', code: 'AGREEMENT_OBTAINED' },
    { label: 'NOTARY_MEETING_OBTAINED', code: 'NOTARY_MEETING_OBTAINED' },
    { label: 'CLIENT_DESISTED', code: 'CLIENT_DESISTED' },
    { label: 'RESERVATION_CANCELED', code: 'RESERVATION_CANCELED' },
  ];

  statusFilter = this.statusFilters.all;
  columnDefs: ColDef[];
  pageSize = 10;
  filterValue: string;
  filterUrlParam: FilterEventOutput;

  lotId: number;
  optionLotPriceId: number;
  reservationId: number;

  displayTeamButton: boolean;
  displayPartnerButton: boolean;
  isTeamMemberParam: boolean;
  isPartnerParam: boolean;
  textFilterSubject = new Subject<string>();
  textFilterValue: string;

  private readonly $destroy: Subject<boolean> = new Subject<boolean>();

  /**
   * Creates an instance of ReservationsTrackingTableComponent.
   * @param {ReservationsTrackingTableService} reservationsTrackingTableService
   * @param {I18nService} i18nService
   * @param {UserRoleService} userRoleService
   * @param {AppConfigService} appConfigService
   * @param {ZipFileService} zipFileService
   * @param {Router} router
   * @param route
   */
  constructor(
    public readonly reservationsTrackingTableService: ReservationsTrackingTableService,
    public readonly i18nService: I18nService,
    private readonly userRoleService: UserRoleService,
    private readonly appConfigService: AppConfigService,
    private readonly zipFileService: ZipFileService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {
    super(Sitemap.dashboards.reservations.path, router);
  }

  ngOnInit(): void {
    this.isSignaturesButtonVisible = this.userRoleService.isContractor();
    this.displayTeamButton = this.userRoleService.isInRoles([ValoRole.valoRcvSuper]);
    this.displayPartnerButton = this.userRoleService.isInRoles([ValoRole.valoRcvSimple]);

    this.columnDefs = [
      {
        field: 'isSRUDelayPurged',
        headerName: this.i18nService._('Title_ResaTrackingTable_Column_IsPurged'),
        sortable: false,
        minWidth: 15,
        flex: 1,
      },
      {
        field: 'lotNumber',
        headerName: this.i18nService._('Title_ResaTrackingTable_Column_LotNumber'),
        minWidth: 60,
        flex: 3,
        cellRenderer: InformationRendererComponent,
        cellRendererParams: {
          picto: {
            iconName: 'informations',
            i18nTooltip: 'Title_TrackingTable_Column_LotNumber_Picto_Tooltip',
            class: 'text-sm',
          },
          action: (reservation: ReservationsTrackingTableItem) => {
            if (reservation.actions?.canShowLotDetails) {
              this.showLotDetails(reservation);
            }
          },
        },
      },
      {
        field: 'statusLabel',
        headerName: this.i18nService._('Title_ResaTrackingTable_Column_Status'),
        minWidth: 170,
        flex: 4,
        cellRenderer: StatusTagsRendererComponent,
        cellRendererParams: {
          tagsList: tagTypeByReservationStatus,
          outline: true,
        },
        valueFormatter: (params) => {
          if (!params.value) return undefined;
          return 'RESERVATION_' + params.value;
        },
      },
      {
        field: 'statusDate',
        headerName: this.i18nService._('Title_ResaTrackingTable_Column_StatusDate'),
        minWidth: 80,
        flex: 3,
        cellRenderer: DateRendererComponent,
      },
      {
        field: 'programName',
        headerName: this.i18nService._('Title_ResaTrackingTable_Column_ProgramName'),
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'reservationSellingPriceIT',
        headerName: this.i18nService._('Title_ResaTrackingTable_Column_ReservationSellingPriceIT'),
        minWidth: 80,
        flex: 3,
        cellRenderer: PriceRendererComponent,
      },
      {
        field: 'acquirerFullname',
        headerName: this.i18nService._('Title_ResaTrackingTable_Column_AcquirerName'),
        minWidth: 150,
        flex: 6,
      },
      {
        field: 'sellerFullname',
        headerName: this.i18nService._('Title_ResaTrackingTable_Column_SellerName'),
        minWidth: 150,
        flex: 6,
      },
      {
        field: 'reservationDate',
        headerName: this.i18nService._('Title_ResaTrackingTable_Column_ReservationDate'),
        minWidth: 100,
        flex: 3,
        cellRenderer: DateRendererComponent,
      },
      {
        field: 'rcvFullname',
        headerName: this.i18nService._('Title_ResaTrackingTable_Column_ResponsableCommercial'),
        minWidth: 150,
        flex: 5,
      },
      {
        field: 'advFullname',
        headerName: this.i18nService._('Title_ResaTrackingTable_Column_AdvName'),
        minWidth: 150,
        flex: 5,
      },
      {
        field: 'reservationRef',
        headerName: this.i18nService._('Title_ResaTrackingTable_Column_ReservationRef'),
        sort: 'desc',
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'actions',
        headerName: this.i18nService._('Title_TrackingTable_Column_Actions'),
        sortable: false,
        minWidth: 60,
        maxWidth: 60,
        flex: 2,
        cellStyle: { paddingRight: '10px' },
        cellRenderer: ActionRendererComponent,
        cellRendererParams: {
          actionItems: (reservation: any) => {
            return this.getActionItems(reservation);
          },
        },
      },
    ];

    // Show developer column if the user is not a developer
    if (!this.userRoleService.isDeveloper()) {
      const objToAdd: ColDef = {
        field: 'developerCompanyName',
        headerName: this.i18nService._('Title_ResaTrackingTable_Column_DeveloperName'),
        minWidth: 80,
        flex: 3,
      };
      this.columnDefs.splice(4, 0, objToAdd);
    }

    this.getQueryParams();
    this.onTextFilterChange();
  }

  getQueryParams(): void {
    const partner = JSON.parse(this.route.snapshot.queryParamMap.get('isPartner'));
    this.isPartnerParam = Boolean(this.displayPartnerButton && partner === null ? true : partner);
    this.isTeamMemberParam = Boolean(JSON.parse(this.route.snapshot.queryParamMap.get('isTeamMember')));
    this.filterValue = this.route.snapshot.queryParamMap.get('filter');
    this.textFilterValue = this.route.snapshot.queryParamMap.get('filter');
    const statusFilterParams = this.route.snapshot.queryParamMap.get('statusFilter');
    this.statusFilter = statusFilterParams ? this.statusFilters[statusFilterParams] : this.statusFilters.all;

    this.filterUrlParam = {
      statusFilter: this.statusFilter.code,
      filter: this.filterValue,
      isPartner: this.isPartnerParam,
      isTeamMember: this.isTeamMemberParam,
    };

    this.updateCountReservations();
    super.filterEvent(this.filterUrlParam);
  }

  getActionItems(reservation: ReservationsTrackingTableItem): any[] {
    const actions = [];
    if (reservation?.actions?.canShowReservation) {
      actions.push({
        label: 'Txt_Tooltip_GotoReservation',
        action: () => {
          this.router.navigate([Sitemap.reservations.edit.path.replace(/:reservationId/, reservation?.id.toString())]);
        },
      });
    }
    if (reservation?.actions?.canShowLotDetails) {
      actions.push({
        label: 'Txt_Tooltip_ShowOfferResume',
        action: () => {
          this.showLotDetails(reservation);
        },
      });
    }
    actions.push({
      label: 'Txt_Tooltip_DownloadReservationContrat',
      action: ($event, reservationElement: any) => {
        $event.stopPropagation();
        reservationElement.downloading = true;
        this.downloadAndZipReservationContractDocuments(reservationElement);
      },
      isDownload: true,
    });
    return actions;
  }

  downloadAndZipReservationContractDocuments(element: any): void {
    if (!element) {
      element.downloading = false;

      return;
    }

    const timeZoneDefault = this.appConfigService.getAppConfig().timeZone;
    const date = moment(new Date()).tz(timeZoneDefault).format(this.appConfigService._('dateFormats', 'prereservationZipName'));
    const zipName = this.i18nService._('Zip_Name_ReservationContractualDocumentPackage', [
      `${element.programName}_${element.lotNumber}`,
      date,
    ]);
    const documentsToDownloadInZip: Array<DocumentToDownloadInZip> = [];
    if (element.reservationDocumentFileName && element.reservationDocumentContainer) {
      documentsToDownloadInZip.push({
        title: '',
        fileName: element.reservationDocumentFileName,
        container: element.reservationDocumentContainer,
        targetNameInZip: this.i18nService._('Txt_Reservation_FormSupportingDocumentLabel').replace(/ /g, '_'),
      });
    }

    if (element.reservationAmmendmentDocumentFileName && element.reservationAmmendmentDocumentContainer) {
      documentsToDownloadInZip.push({
        title: '',
        fileName: element.reservationAmmendmentDocumentFileName,
        container: element.reservationAmmendmentDocumentContainer,
        targetNameInZip: this.i18nService._('Txt_Reservation_Form_ammendment_document_label').replace(/ /g, '_'),
      });
    }

    this.zipFileService.inProgress$.pipe(takeUntil(this.$destroy)).subscribe((inProgress) => {
      element.downloading = inProgress;
    });
    this.zipFileService.createZip(documentsToDownloadInZip, zipName, 'Error_Snackbar_EmptyReservationDocumentPackageZip');
  }

  updateCountReservations(): void {
    this.reservationsTrackingTableService.countReservation().subscribe((result) => {
      this.count = {
        all: result.nbStatus.all,
        desisted: result.nbStatus.desisted,
        sold: result.nbStatus.sold,
        reservation: result.nbStatus.reservation,
      };
    });
  }

  showLotDetails(reservation: ReservationsTrackingTableItem): void {
    this.lotId = reservation.lotId;
    this.reservationId = reservation.id;
    if (this.drawer) {
      this.drawer.open();
    }
  }

  onTextFilterChange() {
    this.textFilterSubject.pipe(debounceTime(this.textFilterDebounceTimer), distinctUntilChanged()).subscribe((value) => {
      this.filterUrlParam.filter = value;
      super.filterEvent(this.filterUrlParam);
      this.textFilterValue = value;
    });
  }

  statusFilterChange(): void {
    this.filterUrlParam.statusFilter = this.statusFilter.code;
    super.filterEvent(this.filterUrlParam);
  }

  changeTeamFilter(event: boolean): void {
    this.isTeamMemberParam = event;
    this.filterUrlParam.isTeamMember = this.isTeamMemberParam;
    super.filterEvent(this.filterUrlParam);
  }

  changePartnerFilter(event: boolean): void {
    this.isPartnerParam = event;
    this.filterUrlParam.isPartner = this.isPartnerParam;
    super.filterEvent(this.filterUrlParam);
  }

  ngOnDestroy(): void {
    this.$destroy.next(false);
    this.$destroy.complete();
  }
}
