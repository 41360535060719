<app-card>
  <div class="prospect-card-header">
    <div class="prospect-card-header-info">
      <div *ngFor="let prospect of prospects" (click)="selectProspect(prospect)">
        <app-icon [name]="prospect.icon"></app-icon>
        <span [class.selected]="prospect === activeProspect">
          <div *ngIf="prospect.prospectType === ProspectType.PERSON">{{ prospect.firstName }} {{ prospect.lastName }}</div>
          <div *ngIf="prospect.prospectType === ProspectType.COMPANY">{{ prospect.companyName }}</div>
        </span>
      </div>
    </div>
    <app-status-tag [status]="getStatus()">{{ i18nService._('Prospect_Dossier_Status_' + dossier.dossierStatusCode) }}</app-status-tag>
  </div>
  <div class="prospect-card-info">
    <div>
      <div>{{ i18nService._('Prospect_card_email_label') }}</div>
      <div>{{ activeProspect.email }}</div>
    </div>
    <div>
      <div>{{ i18nService._('Prospect_Form_Personal_Phone') }}</div>
      <div>{{ activeProspect.phoneNumber }}</div>
    </div>
    <div>
      <div>{{ i18nService._('Prospect_card_email_city') }}</div>
      <div>
        {{ activeProspect?.city ? activeProspect.city : '-' }} {{ activeProspect?.postalCode ? '(' + activeProspect.postalCode + ')' : '' }}
      </div>
    </div>
  </div>
  <button class="rounded-button rounded-button-black" (click)="goToDossier()">Acceder au dossier</button>
  <div></div>
</app-card>
