import { Injectable } from '@angular/core';

import { fiscalityTags } from '../../../programs/models/enum/fiscality-tags.enum';
import { TagsInterface } from '../../../design-system/model/tag-option';

@Injectable({
  providedIn: 'root',
})
export class TaxationsService {
  getTaxationsTags(taxations: string[]): TagsInterface[] {
    if (!taxations) {
      return [];
    }
    return taxations.map((val) => fiscalityTags[val]);
  }
}
