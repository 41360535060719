/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/internal/operators/tap';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs';

import { SavedSearch } from '../models/SavedSearch';
import { AbstractAdvancedTrackingTableNoBackPageService } from './abstract-advanced-tracking-table-no-back-page.service';

import { AppConfigService } from '../../utils/services/app-config.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';
import { SnackbarService } from '../../utils/services/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class SaveSearchService extends AbstractAdvancedTrackingTableNoBackPageService<Array<SavedSearch>> {
  protected url = `Searches/get-searches-of-user`;

  constructor(
    public readonly appConfig: AppConfigService,
    public readonly errorHandlerService: ErrorHandlerService,
    public readonly http: HttpClient,
    private readonly snackBarService: SnackbarService,
  ) {
    super();
  }

  /**
   * saveSearch method
   *
   * @param {{title: string, description: string, criterias: object}} searchData
   * @returns {Observable<any>}
   * @memberof SaveSearchService
   */
  saveSearch(searchData): Observable<any> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Searches/save`;

    return this.http.post<any>(url, searchData).pipe(
      tap(() => {
        this.snackBarService.info('Txt_Success_Saved_Search', true);
      }),
      catchError(this.errorHandlerService.handleError<any>('saveSearchService', 'saveSearch')),
    );
  }

  /**
   * deleteSearch method
   *
   * @param {number} searchId
   * @returns {Observable<any>}
   * @memberof SaveSearchService
   */
  deleteSavedSearch(searchId: number): Observable<any> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Searches/delete/${searchId}`;

    return this.http.delete<any>(url).pipe(catchError(this.errorHandlerService.handleError<any>('saveSearchService', 'deleteSearch')));
  }

  /**
   * updateSearch method
   *
   * @param {{searchId: number, criterias: object}} params
   *
   * @returns {Observable<any>}
   * @memberof SaveSearchService
   */
  updateSavedSearch({ searchId, criterias }: { searchId: number; criterias: object }): Observable<SavedSearch> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Searches/update/${searchId}`;

    return this.http
      .post<any>(url, { criterias })
      .pipe(catchError(this.errorHandlerService.handleError<any>('saveSearchService', 'updateSavedSearch')));
  }

  /**
   * getSearch Method
   *
   * @param {number} searchId
   * @returns {Observable<SavedSearch>}
   * @memberof SaveSearchService
   */
  getSearch(searchId: number): Observable<SavedSearch> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Searches/${searchId}`;

    return this.http.get<any>(url).pipe(catchError(this.errorHandlerService.handleError<any>('saveSearchService', 'getSearch')));
  }
}
