import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

import { SafePipe } from '../../../utils/pipes/safe.pipe';
import { TextareaDialogData } from '../../models/TextareaDialogData';
import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-textarea-dialog',
  templateUrl: './textarea-dialog.component.html',
  styleUrls: ['./textarea-dialog.component.scss'],
  standalone: true,
  imports: [NgIf, MatDialogModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, SafePipe],
})
export class TextareaDialogComponent implements OnInit {
  public popinForm: UntypedFormGroup;
  public control: AbstractControl;

  /**
   * Creates an instance of TextareaDialogComponent.
   * @param {I18nService} i18nService
   * @param {FormBuilder} formBuilder
   * @param {MatDialogRef<TextareaDialogComponent>} dialogRef
   * @param {TextareaDialogData} data
   * @memberof TextareaDialogComponent
   */
  constructor(
    public i18nService: I18nService,
    private readonly formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<TextareaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TextareaDialogData,
  ) {}

  ngOnInit(): void {
    // Declaration of field text in form
    this.popinForm = this.formBuilder.group({
      text: [''],
    });
    this.control = this.popinForm.controls.text;
  }

  /**
   * clickSaveButton method
   *
   * @memberof TextareaDialogComponent
   */
  clickSaveButton(): void {
    // Check if form is valid
    if (this.popinForm.valid) {
      // Send text to component that instanciate the dialog
      this.dialogRef.close(this.control.value);
    } else {
      // Show field error
      this.control.markAsTouched();
    }
  }
}
