/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IGetRowsParams,
  IRowNode,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { ParamRequest } from '@commons-dto/valo-back';
import { AgGridModule } from 'ag-grid-angular';

import { GridTrackingTableService } from '../../services/grid-tacking-table.service';
import { TableLoader, TableResponse } from '../../../table/interfaces/DataLoader';
import { AgGridLocalFR } from '../../../utils/i18n/table/ag-grid-local-FR';
import { CustomLoadingOverlayComponent } from '../../renderers/custom-loading-overlay/custom-loading-overlay.component';

@Component({
  selector: 'app-grid-tracking-table',
  templateUrl: './grid-tracking-table.component.html',
  styleUrls: ['./grid-tracking-table.component.scss'],
  standalone: true,
  imports: [AgGridModule],
})
export class GridTrackingTableComponent implements OnChanges {
  public selectedPageSize = 10;

  /**
   * nbItems : emit the number of items retrevied from request
   * @type {number}
   */
  @Output() nbItems: EventEmitter<number> = new EventEmitter<number>();

  /**
   * selectedRows : emit all selected rows
   * @type {{ EventEmitter<any[]> }}
   */
  @Output() selectedRows: EventEmitter<any[]> = new EventEmitter<any[]>();

  /**
   * rows : emit all rows
   * @type {{ EventEmitter<any[]> }}
   */
  @Output() rows: EventEmitter<any[]> = new EventEmitter<any[]>();

  /**
   * pre select rows for edit
   * @type {{ number[] }}
   */
  @Input() preSelectedRows: number[];

  /**
   * pageSize : number of elements to display by page (optionnal)
   * @type {number}
   */
  @Input() pageSize;

  /**
   * tableHeight : height of the table (702 px by default)
   * @type {string}
   */
  @Input() tableHeight = '702px';

  /**
   * pageSizeOptions : array of possible numbers of elements to display by page (optionnal)
   * @type {Array<number>}
   */
  @Input() pageSizeOptions: Array<number>;

  /**
   * columnDefs input
   * @type {{ string }}
   */
  @Input() columnDefs: ColDef[];

  /**
   * defaultColDef input (optional)
   * @type {{ string }}
   */
  @Input() defaultColDef: ColDef = {
    sortable: true,
    wrapText: true,
    cellStyle: { 'word-break': 'break-word', padding: '0px; !important' },
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };

  /**
   * columnSort input (optional)
   * @type {{ string }}
   */
  @Input() defaultSortColunm: string;

  /**
   * columnSortOrder input (optional)
   * @type {{ string }}
   */
  @Input() defaultSortDirection: 'ASC' | 'DESC';

  /**
   * external filter for client side
   * @type {any}
   */
  @Input() externalFilter: any;

  /**
   * text filter on all column (optional)
   * @type {string}
   */
  @Input() textFilter: string;

  /**
   * i18n filter on specific col (optional)
   * @type {any}
   */
  @Input() i18nFilter: any;

  /**
   * status filter (optional)
   * @type {string}
   */
  @Input() statusFilter: any;

  /**
   * selfFilters (optional)
   * @type {string}
   */
  @Input() selfFilters: string;

  /**
   * isTeamMember (optional)
   * @type {boolean}
   */
  @Input() isTeamMember: boolean;

  /**
   * isPartner (optional)
   * @type {boolean}
   */
  @Input() isPartner: boolean;

  /**
   * minimum number of letter for text filter
   * @type {string}
   */
  @Input() minimumFilterLength: number;

  /**
   * service use to get table data
   * @type {TableLoader}
   */
  @Input() tableLoader: TableLoader<any>;

  /**
   * url use to get single row service
   * @type {string}
   */
  @Input() serviceUrl: string;

  /**
   * Columns
   * @type {string[]}
   */
  @Input() columns?: string[];

  /**
   * enable pagination
   * @type {boolean}
   */
  @Input() enablePagination = true;

  /**
   * Option for the grid (defaut value)
   * @type {GridOptions}
   */
  private _gridOptions: GridOptions = {
    headerHeight: 45,
    rowHeight: 60,
    cacheBlockSize: 0,
    paginationPageSize: 10,
    paginationPageSizeSelector: false,
    suppressCellFocus: true,
    rowModelType: 'infinite',
    domLayout: 'autoHeight',
    enableCellTextSelection: true,
    localeText: AgGridLocalFR,
  };

  get gridOptions(): GridOptions {
    return this._gridOptions;
  }

  /**
   * Option for the grid (defaut value)
   * @type {GridOptions}
   */
  @Input() set gridOptions(gridOptions: GridOptions) {
    gridOptions.autoSizeStrategy = gridOptions.autoSizeStrategy ?? { type: 'fitGridWidth' };
    gridOptions.onGridSizeChanged = (event) => {
      event.api.sizeColumnsToFit();
    };
    this._gridOptions = { ...this._gridOptions, ...gridOptions };
  }

  /**
   *
   * @type {boolean}
   */
  @Input() serverSide = true;

  /**
   * disable no selectable row
   * @type {fonction}
   */
  @Input() isRowSelectable: (params: IRowNode<any>) => undefined;

  /**
   * Use to set internal row id of the grid with our data id
   * @type {GetRowIdFunc}
   */
  @Input() getRowId: GetRowIdFunc = (params: GetRowIdParams) => params.data.id;

  /**
   * Grid api
   * @type {GridApi}
   */
  gridApi: GridApi;

  /**
   * Grid data
   * @type {any}
   */
  rowData: any;

  /**
   * unfiltered data
   * @type {any}
   */
  unfilteredData: any;

  /**
   * save selected Nodes for checkbox selection
   * @type {any}
   */
  saveSelectedNodes: any = [];

  /**
   * custom loading component
   * @type {any}
   */
  public loadingOverlayComponent: any = CustomLoadingOverlayComponent;

  constructor(private readonly gridTrackingTableService: GridTrackingTableService) {}

  /**
   * use to force data source request when research from parent change
   * @type {void}
   */
  ngOnChanges(): void {
    if (this.serverSide) {
      this.gridApi?.setGridOption('datasource', this.createDataSource());
    } else {
      this.applyFilters();
    }
    this.checkPreSelectedRows();
  }

  /**
   * fonction call when the grid is ready to avoid filling data or getting params before the grid is created
   * @type {void}
   */
  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.showLoadingOverlay();

    if (this.serverSide) {
      this.handleServerSideData(params);
    } else {
      this.handleClientSideData();
    }

    this.refreshVisibleColumns();
  }

  checkPreSelectedRows() {
    if (this.preSelectedRows) {
      this.gridApi.forEachNode((node) => {
        if (this.preSelectedRows?.indexOf(node.data.id) >= 0) {
          node.setSelected(true);
        }
      });
    }
  }

  private handleServerSideData(params: GridReadyEvent) {
    params.api!.setGridOption('datasource', this.createDataSource());
  }

  private async handleClientSideData() {
    this.tableLoader.get().then((tableResponse) => {
      this.setRowData(tableResponse?.items);
      this.gridApi.hideOverlay();
      this.emitDataInfo();
      this.applyFilters();
    });
  }

  private emitDataInfo() {
    this.nbItems.emit(this.rowData?.length);
    this.rows.emit(this.rowData);
  }

  addRow(item: any) {
    if (!this.rowData) this.rowData = [];
    this.rowData = [...this.rowData, item];

    if (!this.unfilteredData) this.unfilteredData = [];
    this.unfilteredData.push(item);
  }

  removeRow(rowIndex: any) {
    this.rowData.splice(rowIndex, 1);
    this.rowData = [...this.rowData];
    this.unfilteredData.splice(rowIndex, 1);
  }

  setRowData(items: any[]) {
    const dataArray = Array.isArray(items) ? [...items] : [items];
    this.rowData = dataArray;
    this.unfilteredData = dataArray;
  }

  onCellValueChanged() {
    this.rows.emit(this.rowData);
  }

  private applyFilters() {
    if (typeof this.textFilter === 'string') {
      this.gridApi.setGridOption('quickFilterText', this.textFilter);
    }
    if (this.externalFilter) {
      this.gridApi?.onFilterChanged();
    }
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    this.saveSelectedNodes = event.api.getSelectedNodes();
    this.selectedRows.emit(event.api.getSelectedNodes());
  }

  private _visibleColumns: string[];

  @Input() set visibleColumns(visibleColumns: string[]) {
    this._visibleColumns = visibleColumns;
    this.refreshVisibleColumns();
  }

  private refreshVisibleColumns() {
    if (this._visibleColumns && this.gridOptions && this.gridOptions.columnApi) {
      this.gridApi.setColumnsVisible(this._visibleColumns, true);
      const columnsToHide = this.columnDefs.filter((column) => !this._visibleColumns.includes(column.field)).map((column) => column.field);
      this.gridApi.setColumnsVisible(columnsToHide, false);
    }
  }

  /**
   * create a data source for valo-back which is call on sort, filter, ...
   * @type {any}
   */
  createDataSource(): any {
    return {
      getRows: (params: IGetRowsParams) => {
        this.gridApi?.showLoadingOverlay();
        if (this.tableLoader) {
          const sortColumn = params?.sortModel[0]?.colId ?? this.defaultSortColunm;
          let sortDirection = this.defaultSortDirection;
          if (params?.sortModel[0]) {
            sortDirection = params?.sortModel[0]?.sort.toLocaleUpperCase() === 'ASC' ? 'ASC' : 'DESC';
          }
          if (!this.columns) {
            this.columns = this.columnDefs.filter((columnDef) => columnDef?.field).map((columnDef) => columnDef?.field);
          }
          const paramsRequest: ParamRequest = {
            sortColumn: sortColumn,
            sortDirection: sortDirection,
            nbResult: params.endRow - params.startRow,
            offset: params.startRow,
            i18nFilters: this.i18nFilter,
            statusFilter: this.statusFilter,
            filter: this.textFilter,
            selfFilters: this.selfFilters,
            isTeamMember: this.isTeamMember,
            isPartner: this.isPartner,
            columns: this.columns,
          };
          this.tableLoader.get(paramsRequest).then((tableResponse: TableResponse<any>) => {
            const nbItems = tableResponse.nbItems ? tableResponse.nbItems : tableResponse.items?.length;
            this.nbItems.emit(nbItems);
            this.gridApi.hideOverlay();
            return params.successCallback(tableResponse.items, nbItems);
          });
        }
      },
    };
  }

  /**
   * Updates a single row in a grid with new data, either by
   * fetching the data from a service or using the provided data.
   * @param {number} rowId - The rowId parameter is a number that represents the unique identifier of
   * the row in the grid.
   * @param {any} [data] - The `data` parameter is an optional parameter that represents the updated
   * data for the row. If provided, it will be merged with the existing data of the row. If not
   * provided, the method will retrieve the updated data from the server using the `rowId` and update
   * the row with the new
   */
  async updateSingleDataRow(rowId: number, data?: any): Promise<void> {
    const rowNode = this.gridApi.getRowNode(rowId.toString());
    if (!data) {
      if (this.serviceUrl) {
        // Old method
        this.gridTrackingTableService.getSingleItem(rowId, this.serviceUrl).subscribe((res) => {
          const row = res?.items.length > 0 ? res.items[0] : null;
          rowNode.setData(row);
        });
      } else {
        const row = await this.tableLoader.getById(rowId);
        rowNode.setData(row);
      }
    } else {
      rowNode.setData({ ...rowNode.data, ...data });
    }
  }

  /**
   * Refresh all rows
   * @type {void}
   */
  refreshRows() {
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
      this.handleClientSideData();
      this.gridApi.refreshCells();
    }
  }
}
