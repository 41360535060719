import { Component, Input, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { NgFor, NgIf } from '@angular/common';

import { AppIconsName } from '../../standalone/icon/utils/app-icon-names';
import { TranslatePipe } from '../../../utils/pipes/translate.pipe';
import { IconComponent } from '../../standalone/icon/icon.component';

@Component({
  selector: 'app-formly-chip-list',
  templateUrl: './formly-chip-list.component.html',
  styleUrls: ['./formly-chip-list.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, IconComponent, TranslatePipe],
})
export class FormlyChipListComponent extends FieldType implements OnInit {
  @Input()
  possibleChoices: ChoiceItemSelect[];

  multiple = true;

  ngOnInit(): void {
    const selectedChoicesId = this.field.formControl.getRawValue() ?? [];
    const choices: ChoiceItem[] = [];
    if (this.field.props.allChoice) {
      choices.push({
        id: null,
        label: 'Tout',
      });
    }
    choices.push(...this.field.props.choices);
    this.possibleChoices = choices.map((choice: ChoiceItem) => {
      return { selected: selectedChoicesId.includes(choice.id), ...choice };
    });

    if (this.field.props.multiple === false) this.multiple = this.field.props.multiple;
  }

  onToggleChoice(choice: ChoiceItemSelect) {
    if (this.multiple) {
      choice.selected = !choice.selected;
      if (choice.id === null) {
        if (choice.selected) {
          this.possibleChoices.filter((c) => c !== choice).forEach((c) => (c.selected = false));
        }
      } else {
        this.possibleChoices.filter((c) => c.id === null).forEach((c) => (c.selected = false));
      }
    } else {
      choice.selected = !choice.selected;
      this.possibleChoices.filter((c) => c !== choice).forEach((c) => (c.selected = false));
    }
    this.setValue();
  }

  setValue() {
    const selectedChoicesId = this.possibleChoices.filter((choice) => choice.selected);
    this.formControl.setValue(selectedChoicesId);
  }
}

export class ChoiceItem {
  id: number | string | null;
  label: string;
  labelParam?: string;
  icon?: AppIconsName;
  type?: string;
}

class ChoiceItemSelect extends ChoiceItem {
  selected: boolean;
}
