<div class="flex flex-col w-full">
  <!-- Select Country -->
  <div class="text-base mb-6 font-semibold">{{ i18nService._('Txt_Page_Form_RegisterCompany_Country') }} *</div>
  <div class="grid grid-cols-2 gap-x-16 mb-8">
    <mat-radio-group
      (change)="selectCountry($event.value)"
      [formControl]="isFrenchControl"
      class="col-span-2 lg:col-span-1 flex flex-row mb-2"
    >
      <mat-radio-button [value]="true" color="primary" class="flex" name="this.fieldnameData.isFrench">
        {{ i18nService._('Txt_RadioButton_France') }}
      </mat-radio-button>
      <mat-radio-button [value]="false" class="flex" color="primary" name="this.fieldnameData.isFrench" data-cy="Txt_RadioButton_Other">
        {{ i18nService._('Txt_RadioButton_Other') }}
      </mat-radio-button>
    </mat-radio-group>

    <app-chip-populated-autocomplete
      *ngIf="isFrenchControl.value === false"
      [default]="recoveredInfo?.country?.label"
      [id]="fieldnameData.countryId"
      [items]="countryList"
      [max]="1"
      [parentForm]="parentForm"
      [rawFormat]="true"
      [required]="true"
      [title]="i18nService._('Txt_Page_Form_RegisterCompany_Other')"
      class="col-span-2 lg:col-span-1"
    >
    </app-chip-populated-autocomplete>
  </div>

  <!-- Select ZipCode and Ciy -->
  <div class="grid grid-cols-2 gap-x-16 mb-3">
    <mat-form-field class="col-span-2 lg:col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_ZipCode') }}</mat-label>
      <input
        (focusout)="loadCity(zipCodeControl.value)"
        [formControl]="zipCodeControl"
        [matAutocomplete]="auto"
        autocomplete="no-autocomplete"
        id="this.fieldnameData.postalCode"
        matInput
        maxlength="10"
        name="this.fieldnameData.postalCode"
        data-cy="zipCode"
        required
        type="text"
      />
      <mat-autocomplete #auto="matAutocomplete" (focuson)="loadCity(zipCodeControl.value)">
        <mat-option *ngFor="let zipCode of filteredZipCode | async" [value]="zipCode.label" data-cy="zipOption">
          {{ zipCode.label }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="zipCodeControl.errors">
        <ng-container *ngIf="zipCodeControl.errors.required">
          {{ i18nService._('Error_Field_Required') }}
        </ng-container>
        <ng-container *ngIf="zipCodeControl.errors.zipCode && !zipCodeControl.errors.required">
          {{ i18nService._('Error_Field_Wrong_ZipCode') }}
        </ng-container>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="!isFrenchControl.value" class="col-span-2 lg:col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_City') }}</mat-label>
      <input data-cy="city" [formControl]="cityControl" matInput maxlength="255" name="this.fieldnameData.cityOther" required type="text" />
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="isFrenchControl.value" class="col-span-2 lg:col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_City') }}</mat-label>
      <mat-select (selectionChange)="setCityLabel($event.value)" [formControl]="cityInfosControl" data-cy="city">
        <mat-option *ngFor="let city of cityList" [value]="city" data-cy="cityOption">
          {{ city.label }}
        </mat-option>
      </mat-select>
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
  </div>

  <div class="grid grid-cols-2 gap-x-16 mb-3">
    <!-- Input Address -->
    <mat-form-field *ngIf="isAddress" class="col-span-2 lg:col-span-1">
      <mat-label>{{ i18nService._('Txt_Placeholder_Address') }}</mat-label>
      <input
        [formControl]="addressControl"
        matInput
        maxlength="255"
        name="this.fieldnameData.addressName"
        data-cy="address"
        required
        type="text"
      />
      <mat-error> {{ i18nService._('Error_Field_Required') }}</mat-error>
    </mat-form-field>
  </div>
</div>
