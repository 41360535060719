import { Component, OnInit } from '@angular/core';
import { FormControl, FormRecord, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf, NgFor } from '@angular/common';

import { I18nService } from '../../../utils/services/i18n.service';
import { SignatureService } from '../../services/signature.service';
import { IconComponent } from '../../../common/standalone/icon/icon.component';

@Component({
  selector: 'app-prefill-dialog',
  templateUrl: './prefill-dialog.component.html',
  styleUrls: ['./prefill-dialog.component.scss'],
  standalone: true,
  imports: [IconComponent, MatDialogModule, NgIf, FormsModule, ReactiveFormsModule, NgFor, MatCheckboxModule],
})
export class PrefillDialogComponent implements OnInit {
  prefillableFields = [
    { name: 'acq_2_lastname', label: 'prefill_acq_2_lastname' },
    { name: 'acq_2_address', label: 'prefill_acq_2_address' },
    { name: 'acq_2_localization.postalCode', label: 'prefill_postalCode' },
    { name: 'acq_2_localization.city', label: 'prefill_city' },
    { name: 'acq_2_country', label: 'prefill_acq_2_country' },
    { name: 'acq_2_nationality', label: 'prefill_acq_2_nationality' },
    { name: 'acq_2_family_situation', label: 'prefill_acq_2_family_situation' },
    { name: 'acq_2_wedding_pacs_city', label: 'prefill_acq_2_wedding_pacs_city' },
    { name: 'acq_2_wedding_pacs_date', label: 'prefill_acq_2_wedding_pacs_date' },
    { name: 'acq_2_matrimonial_regime', label: 'prefill_acq_2_matrimonial_regime' },
  ];

  prefillForm = new FormRecord<FormControl<boolean>>({});

  constructor(
    public _i18nService: I18nService,
    private readonly _signature: SignatureService,
    private readonly dialogRef: MatDialogRef<PrefillDialogComponent>,
  ) {}

  ngOnInit() {
    this.prefillableFields.forEach((field) => {
      this.prefillForm.addControl(field.name, new FormControl(true, { nonNullable: true }));
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  prefill() {
    const choosenFields: string[] = [];
    const formValues = this.prefillForm.value;
    this.prefillableFields.forEach((field) => {
      if (formValues[field.name]) {
        choosenFields.push(field.name);
      }
    });
    this._signature.prefillAcq2(choosenFields);
    this.close();
  }
}
