/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, share, tap } from 'rxjs';
import { cloneDeep } from 'lodash-es';

import { ReferenceTableData } from '../models/ReferenceTableData';
import { AppConfigService } from './app-config.service';
import { ErrorHandlerService } from './error-handler.service';
import { I18nService } from './i18n.service';

@Injectable({
  providedIn: 'root',
})
export class ReferenceTablesService {
  private static readonly cachedData: Map<string, Array<any>> = new Map();
  private static readonly pendingObservables: Map<string, Observable<any>> = new Map();

  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    public i18nService: I18nService,
    private readonly appConfig: AppConfigService,
  ) {}

  getTableReferenceInfo(table: string): Observable<any> {
    const cachedData = ReferenceTablesService.cachedData.get(table);
    if (cachedData) {
      return of(JSON.parse(JSON.stringify(cachedData))); // cloning to keep cache clean from any modification
    }
    const pendingObservable = ReferenceTablesService.pendingObservables.get(table);
    if (pendingObservable) {
      return pendingObservable;
    }

    const observable = this.http.get<any>(`${this.appConfig.getLoopbackApiUrl()}/${table}`).pipe(
      tap((data: Array<ReferenceTableData>) => {
        ReferenceTablesService.cachedData.set(table, JSON.parse(JSON.stringify(data))); // cloning to keep cache clean from any modificat°
      }),
      share(),
    );

    ReferenceTablesService.pendingObservables.set(table, observable);

    return observable;
  }

  getTableReferenceInfoTranslated(table: string, prefix = ''): Observable<ReferenceTableData[]> {
    return this.getTableReferenceInfo(table).pipe(
      map((data: Array<ReferenceTableData>) => {
        return data.map((element: ReferenceTableData) => {
          const newElement = cloneDeep(element); // cloning to keep cache clean from any modification
          newElement.label = this.i18nService._(`${prefix}${newElement.label}`);

          return newElement;
        });
      }),
    );
  }

  getFilterCity(zipCodeLabel: number): Observable<any> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/ZipCodes/${zipCodeLabel}/cities`;

    return this.http.get<any>(url).pipe(
      tap((response: any) => response),
      catchError(this.errorHandlerService.handleError<any>('AttachmentsService', 'getCount')),
    );
  }

  getTableReferenceInfoWithZipCodes(wantingInfo: string): Observable<any> {
    let url = '';
    let params = new HttpParams();
    if (wantingInfo === 'commercialCourt') {
      params = params.set('filter[where][commercialCourt]', '1');
      url = `${this.appConfig.getLoopbackApiUrl()}/Cities`;
    } else if (wantingInfo === 'cci') {
      url = `${this.appConfig.getLoopbackApiUrl()}/Ccis`;
    } else if (wantingInfo === 'nafs') {
      url = `${this.appConfig.getLoopbackApiUrl()}/Nafs`;
    } else {
      return undefined;
    }

    return this.http.get<any>(url, { params }).pipe(
      map((value) => {
        if (wantingInfo === 'nafs') {
          value.forEach((element) => (element.label = `${element.code} -  ${element.label}`));
        } else {
          value.forEach((element) => (element.departement ? (element.label += ` (${element.departement.code})`) : element.label));
        }
        return value;
      }),
      catchError(this.errorHandlerService.handleError<any>('referenceTablesService', 'get')),
    );
  }
}
