import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
  pure: true,
  standalone: true,
})
export class PhonePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform<T>(num: string, _clss: new (...args: unknown[]) => T): T {
    num = num.replace(' ', '');
    let str = '';
    let i = 0;
    if (num.startsWith('+')) {
      str += num.substring(0, 3) + ' ';
      str += num.substring(3, 4) + ' ';
      i = 2;
    }
    for (; i < Math.floor(num.length / 2) - 1; i++) {
      str = str + num.substring(i * 2, (i + 1) * 2) + ' ';
    }
    return (str + num.substring(i * 2)) as T;
  }
}
