<div class="relative flex justify-center py-44 bg-new-homepage">
  <div class="absolute w-full bottom-0 h-32 gradient"></div>
  <app-login-as-form class="absolute right-8 top-8"></app-login-as-form>
  <div class="flex flex-col">
    <div class="flex items-start relative ml-12">
      <img *ngIf="isFastSearchEnable" class="absolute" [attr.src]="'/assets/img/icon/homepage/bg-program.svg'" alt="" />
      <span class="absolute z-10 font-semibold text-sm left-[18px] top-[2px]">{{ i18nService._('Txt_Search_Tab_Localisation') }}</span>
      <img *ngIf="!isFastSearchEnable" class="absolute left-[190px]" [attr.src]="'/assets/img/icon/homepage/bg-program.svg'" alt="" />
      <span class="absolute left-[204px] top-[2px] z-10 font-semibold text-sm">{{ i18nService._('Txt_Search_Tab_Program') }}</span>
      <img [attr.src]="'/assets/img/icon/homepage/rectangle.svg'" alt="" />
      <div class="absolute cursor-pointer w-[197px] h-[27px] z-20" (click)="onFastSearchClick()"></div>
      <div
        class="absolute cursor-pointer left-[198px] w-[190px] h-[27px] z-20"
        (click)="onProgramSearchClick()"
        data-cy="searchByProgram"
      ></div>
    </div>
    <div class="flex flex-row">
      <app-form-location
        *ngIf="isFastSearchEnable"
        [id]="'location'"
        [design]="'homepage'"
        [parentForm]="formGroup"
        [(localisations)]="search.localisations"
        [(dataMapList)]="localisationsMapList"
        (localisationsChanged)="updateSearch()"
      >
      </app-form-location>

      <app-search-form-programs
        *ngIf="!isFastSearchEnable"
        [(selectedItems)]="selectedProgramIds"
        [design]="'homepage'"
        [id]="'program'"
        [max]="1"
        [parentForm]="formGroup"
        [placeholder]="i18nService._('Txt_Placeholder_ProgramName')"
        (selectedItemsChanged)="updateSearch()"
        [required]="true"
      >
      </app-search-form-programs>

      <div class="flex items-center justify-center bg-white border-r border-t border-b rounded-r-full w-[218px] h-[68px]">
        <button
          class="flex justify-center items-center rounded-full bg-gradient-to-r from-gradientOrangeFrom to-gradientOrangeTo text-white font-bold text-center uppercase w-[202px] h-[48px] text-sm leading-4"
          (click)="onSearch()"
          data-cy="Rechercher"
        >
          Rechercher un bien
        </button>
      </div>
    </div>
    <div class="flex justify-end h-[52px]">
      <div *ngIf="isFastSearchEnable" class="flex items-center cursor-pointer text-white" (click)="openAdvancedModal()" data-cy="search">
        <span class="underline font-semibold text-base">{{ i18nService._('Txt_Advanced_Search') }}</span>
        <app-icon class="flex" name="ChevronRight"></app-icon>
      </div>
    </div>
  </div>
</div>

<app-last-consult-prospect-list
  class="current_dossiers_prospect"
  *ngIf="isDossierProspectEnabled && isLoggedUserContractorOrIndependant"
></app-last-consult-prospect-list>

<div class="flex flex-col my-20">
  <section class="w-full h-50 pt-20 bg-white">
    <app-target2sell-reco [target2SellRecoId]="target2SellSelection"></app-target2sell-reco>
  </section>

  <section class="w-full h-50 pt-8 bg-white">
    <app-target2sell-reco [target2SellRecoId]="target2SellNew"></app-target2sell-reco>
  </section>

  <section class="w-full h-50 pt-8 bg-white">
    <app-target2sell-reco [target2SellRecoId]="target2SellRegion"></app-target2sell-reco>
  </section>
</div>
