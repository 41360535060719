import { Component, HostListener, OnInit } from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';

import { AbstractComparatorComponent } from '../abstract-comparator-component';
import { ComparatorPreviewItemComponent } from '../comparator-preview-item/comparator-preview-item.component';

import { LotComparatorDetail } from '../../../lot/model/lot-comparator-detail';
import { LotService } from '../../../lot/service/lot.service';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { Sitemap } from '../../../utils/models/Sitemap';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { ComparatorStorage } from '../../models/comparator-storage';

@Component({
  selector: 'app-comparator-preview-bloc',
  templateUrl: './comparator-preview-bloc.component.html',
  styleUrls: ['./comparator-preview-bloc.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgFor,
    ComparatorPreviewItemComponent,
    MatProgressSpinnerModule,
    MatButtonModule,
    RouterLink,
    MatIconModule,
    AsyncPipe,
  ],
})
export class ComparatorPreviewBlocComponent extends AbstractComparatorComponent implements OnInit {
  private _comparatorStorage: ComparatorStorage;
  public comparatorUrl = Sitemap.comparator.lot.path;

  constructor(
    protected readonly _lotService: LotService,
    private readonly snackBarService: SnackbarService,
  ) {
    super();
  }

  listLotsToCompare$: Observable<LotComparatorDetail[]>;

  ngOnInit() {
    this._comparatorPageService
      .getComparatorStorage()
      .pipe(takeUntil(this.$d))
      .subscribe((comparatorStorage) => {
        this._comparatorStorage = comparatorStorage;
        this.lotToCompare = this._comparatorPageService.getComparatorStorageForCurrentUser(comparatorStorage);
        this.listLotsToCompare$ = this.lotToCompare.length ? this._lotService.getlotDetailForComparator(this.lotToCompare, true) : null;
      });
  }

  // eslint-disable-next-line
  @HostListener('click', ['$event'])
  clicked(event) {
    event.stopPropagation();
  }

  getComparatorPreviewBlocItemClass(): string {
    const ngClassArray = [
      'one-lot-preview-bloc-item',
      'one-lot-preview-bloc-item',
      'two-lot-preview-bloc-item',
      'three-lot-preview-bloc-item',
    ];

    return ngClassArray[this.lotToCompare.length];
  }

  clearLotsToCompareForCurrentUser(): void {
    this._comparatorPageService.clearLotsToCompareForCurrentUser(this._comparatorStorage);
    this.snackBarService.sendMessage({
      type: SnackbarMessageType.Info,
      text: this.i18nService._('Txt_comparator_empty_sucess'),
    });
    this._comparatorPageService.pushToDatalayerClearComparator();
  }

  accessComparator() {
    this._comparatorPageService.pushToDatalayerAccessComparator();
  }
}
