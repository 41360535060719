import { Component, OnInit } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

import { TagsInterfaceExtended } from '../program-page-main-infos/program-page-main-infos.component';
import { PageProgramHelperService } from '../page-program-helper.service';

import { tagClass, TagTypeEnum } from '../../../../design-system/model/tag-option';
import { I18nService } from '../../../../utils/services/i18n.service';
import { AnchorEnum } from '../../../models/enum/anchor.enum';
import { ProgramPageService } from '../../../services/program-page.service';
import { ProgramDetail } from '../../../../utils/models/ProgramDetail';
import { TagsComponent } from '../../../../design-system/component/tags/tags.component';

@Component({
  selector: 'app-program-page-prospective',
  templateUrl: './program-page-prospective.component.html',
  styleUrls: ['./program-page-prospective.component.scss'],
  standalone: true,
  imports: [NgIf, TagsComponent, NgClass],
})
export class ProgramPageProspectiveComponent implements OnInit {
  fallbackImage = '';
  program: ProgramDetail;
  documentPerspective: string;

  constructor(
    public i18nService: I18nService,
    private _programPageService: ProgramPageService,
    private pageProgramHelperService: PageProgramHelperService,
  ) {}

  ngOnInit(): void {
    this.program = this.pageProgramHelperService.program;
    this.documentPerspective = this.pageProgramHelperService.documentPerspective;
  }

  get tagNouveau(): TagsInterfaceExtended {
    return {
      ngClass: tagClass[TagTypeEnum.BIG_NO_BORDER],
      tagOption: {
        text: this.i18nService._('Txt_page_program_prospective_tag_label'),
        type: TagTypeEnum.SEMI_RADIUS,
      },
      anchor: AnchorEnum.map,
    };
  }

  get isNewProgram(): boolean {
    return this._programPageService.isNewProgram();
  }
}
