export enum ChargeTypeEnum {
  RENT = 'RENT',
  ESTATE_LOAN = 'ESTATE_LOAN',
  CONSUMER_LOAN = 'CONSUMER_LOAN',
  REVOLVING_LOAN = 'REVOLVING_LOAN',
  STUDENT_LOAN = 'STUDENT_LOAN',
  VEHICLE_LOAN = 'VEHICLE_LOAN',
  LOA_LOAN = 'LOA_LOAN',
  LDD_LOAN = 'LDD_LOAN',
  OTHER_ESTATE_LOAN = 'OTHER_ESTATE_LOAN',
  OTHER_LOAN = 'OTHER_LOAN',
  OTHER_MONTHLY = 'OTHER_MONTHLY',
  OTHER_ANNUALY = 'OTHER_ANNUALY',
}
