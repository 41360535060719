<div class="flex flex-col w-full">
  <!-- Mandate Strategies table -->
  <app-program-mandate-strategies-tracking-table
    #mandateStrategyRecap
    [programId]="programId"
    (deletedRow)="onMandateStrategyDeleted()"
    (numberOfmandateStrategies)="onMandateStrategiesChange($event)"
    (updateButtonClicked)="fillFormWithMandateStrategies($event)"
    (globalMandateStrategyFound)="onGlobalMandateStrategyFound($event)"
    class="mt-4 ml-4 pr-4"
  >
  </app-program-mandate-strategies-tracking-table>

  <hr class="w-5 my-12" />

  <form [formGroup]="mandateStrategyForm" class="flex flex-col">
    <div class="flex flex-col ml-4">
      <!-- Mandate strategy type -->
      <div class="text-base my-2 font-semibold">
        {{ i18nService._('Txt_MandateStrategies_Type_Title') }}
      </div>

      <div class="flex flex-row mt-3 min-h-80px">
        <mat-radio-group class="flex flex-col w-2/5" [formControl]="typeControl">
          <div class="flex flex-row">
            <mat-radio-button [value]="false" color="primary" class="flex">
              {{ i18nService._('Txt_MandateStrategies_SpecifiqueType') }}
            </mat-radio-button>
            <mat-radio-button [value]="true" color="primary" class="flex">
              {{ i18nService._('Txt_MandateStrategies_GlobalType') }}
            </mat-radio-button>
          </div>
        </mat-radio-group>
      </div>

      <!-- Mandate strategy information -->
      <div class="text-base my-2 font-semibold">
        {{ i18nService._('Title_MandateStrategyInformation') }}
      </div>

      <!-- Mandate strategy name -->
      <mat-form-field class="mb-3" data-cy="registerMandateStrategy">
        <mat-label>{{ i18nService._('Txt_Placeholder_RegisterMandateStrategy_Title') }}</mat-label>
        <input type="text" [formControl]="titleControl" name="title" matInput maxlength="255" required />
        <mat-error>
          {{ i18nService._('Error_Field_Required') }}
        </mat-error>
      </mat-form-field>

      <div class="grid grid-cols-2 gap-16 mb-3">
        <mat-form-field class="col-span-2 lg:col-span-1">
          <mat-label>{{ i18nService._('Txt_Placeholder_RegisterMandateStrategy_Honorary') }}</mat-label>
          <input
            appDecimalMask
            [maxNumberDigitsBeforeSeparator]="2"
            class="text-right"
            name="rate"
            [formControl]="rateControl"
            matInput
            data-cy="registerMandateStrategyHonorary"
            maxlength="5"
          />
          <span class="text-black px-2" matSuffix>%</span>
          <mat-error>
            {{ i18nService._('Error_Field_Required') }}
          </mat-error>
        </mat-form-field>

        <!-- End date -->
        <app-input-date
          class="col-span-2 lg:col-span-1"
          *ngIf="displayEndDate"
          fieldName="endDate"
          [parentForm]="mandateStrategyForm"
          [placeholder]="i18nService._('Txt_Placeholder_RegisterMandateStrategy_EndDate')"
        >
        </app-input-date>
      </div>
    </div>

    <!-- Stepper-->
    <div class="flex w-full">
      <div class="flex flex-col w-full pr-32">
        <mat-stepper orientation="vertical" #stepper appMatVerticalStepper [@.disabled]="true" class="mb-5" [linear]="true">
          <ng-template matStepperIcon="edit">
            <span class="icon-validate-small"></span>
          </ng-template>

          <!-- Create Alotement -->
          <mat-step [completed]="isAlotmentSelectionCompleted()">
            <ng-template matStepLabel>
              {{ i18nService._('Title_CreateAlotement') }}
            </ng-template>
            <app-alotment-selection-table data-cy="createAlotement" #mandateStrategyLots [mandateStrategyForm]="mandateStrategyForm">
            </app-alotment-selection-table>
            <button
              (click)="nextStep()"
              [disabled]="!isAlotmentSelectionCompleted()"
              type="submit"
              class="mt-4"
              mat-stroked-button
              color="primary"
              data-cy="nextStep"
            >
              {{ i18nService._('Txt_Button_NextStep') }}
            </button>
          </mat-step>

          <!-- Mandate Companies -->
          <mat-step>
            <ng-template matStepLabel>
              {{ i18nService._('Title_MandateCompanies') }}
            </ng-template>
            <app-mandate-companies-selection-table
              data-cy="mandateCompanies"
              #mandateStrategyCompanies
              [mandateStrategyForm]="mandateStrategyForm"
              (companyIds)="setcompanyIds($event)"
            >
            </app-mandate-companies-selection-table>
          </mat-step>
        </mat-stepper>

        <div class="flex flex-row mb-6 ml-5 pl-2">
          <button
            mat-flat-button
            class="mr-3 mb-3"
            data-cy="submitMandateStrategy"
            [disabled]="!mandateStrategyForm.valid || readonly"
            (click)="onSubmit()"
            color="primary"
            [ngStyle]="readonly && { cursor: 'not-allowed' }"
            type="submit"
          >
            {{ i18nService._(updatingElement ? 'Txt_Button_ModifyMandateStrategy' : 'Txt_Button_SubmitMandateStrategy') }}
          </button>

          <button
            *ngIf="updatingElement && !readonly"
            mat-stroked-button
            class="mr-3 mb-3"
            color="primary"
            (click)="cancelUpdate()"
            type="reset"
          >
            {{ i18nService._('Txt_Button_Cancel') }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
