import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParamRequest } from '@commons-dto/valo-back';
import { Observable, lastValueFrom, catchError } from 'rxjs';

import { ReservationsCountServiceResponse } from '../models/reservations-count-service-response';
import { GridTrackingTableService } from './grid-tacking-table.service';
import { ReservationsTrackingTableItem } from '../models/ReservationsTrackingTableItem';
import { ReservationsTrackingTableServiceResponse } from '../models/ReservationsTrackingTableServiceResponse';

import { AppConfigService } from '../../utils/services/app-config.service';
import { CacheService } from '../../utils/services/cache.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';
import { TableLoader, TableResponse } from '../../table/interfaces/DataLoader';

@Injectable({
  providedIn: 'root',
})
export class ReservationsTrackingTableService implements TableLoader<ReservationsTrackingTableItem> {
  protected url = 'Reservations/tracking-table/reservations';
  protected cacheName = 'tracking-reservations';

  constructor(
    public readonly appConfig: AppConfigService,
    public readonly errorHandlerService: ErrorHandlerService,
    public readonly http: HttpClient,
    public readonly cacheService: CacheService,
    private readonly gridTrackingTableService: GridTrackingTableService,
  ) {}

  public async getReservertions(params: ParamRequest): Promise<ReservationsTrackingTableServiceResponse> {
    const response: { items: Array<ReservationsTrackingTableItem>; nbItems: number } = await lastValueFrom(
      this.gridTrackingTableService.getTrackingTableBack(params, this.url),
    );
    return response;
  }

  get(params): Promise<TableResponse<ReservationsTrackingTableItem>> {
    return this.getReservertions(params);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getById(id: number): Promise<ReservationsTrackingTableItem> {
    // TODO: implement
    return null;
  }

  countReservation(): Observable<ReservationsCountServiceResponse> {
    return this.http
      .get<ReservationsCountServiceResponse>([this.appConfig.getLoopbackApiUrl(), this.url, 'status/count'].filter(Boolean).join('/'))
      .pipe(catchError(this.errorHandlerService.handleError<ReservationsCountServiceResponse>('countReservation')));
  }
}
