/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormUtilsService {
  static getFormValue(path: string | Array<string>, formGroup: UntypedFormGroup): any {
    if (!formGroup) {
      return undefined;
    }
    const control = FormUtilsService.getControl(path, formGroup);

    return control ? control.value : undefined;
  }

  static setFormValue(path: string | Array<string>, formGroup: UntypedFormGroup, value: any): void {
    formGroup.get(path).setValue(value);
  }

  static getControl<T extends AbstractControl>(path: string | Array<string>, formGroup: UntypedFormGroup): T {
    if (!formGroup) {
      return undefined;
    }

    return formGroup.get(path) as T;
  }

  static disableControl(path: string | Array<string>, formGroup: UntypedFormGroup, disable = false): void {
    const control = FormUtilsService.getControl(path, formGroup);
    if (control) {
      disable ? control.disable() : control.enable();
    }
  }

  static isValid(path: string | Array<string>, formGroup: UntypedFormGroup, defaultRes = false): boolean {
    const control = FormUtilsService.getControl(path, formGroup);
    if (control) {
      return control.valid;
    }

    return defaultRes;
  }
}
