export enum propertiesLocalisation {
  city = 'cities',
  department = 'departments',
  region = 'regions',
  agglomeration = 'agglomerations',
}

export enum FileExtention {
  PNG = `.png`,
  JPG = `.jpg`,
  JPEG = `.jpeg`,
  PDF = `.pdf`,
}

export enum MimeType {
  PNG = `image/png`,
  JPG = `image/jpg`,
  JPEG = `image/jpeg`,
  PDF = `application/pdf`,
}

export const LOCALISATION_LABELS = {
  cities: propertiesLocalisation.city.toString(),
  departments: propertiesLocalisation.department.toString(),
  regions: propertiesLocalisation.region.toString(),
  agglomeration: propertiesLocalisation.agglomeration.toString(),
};

// search filter constante
export const ROOM_ITEMS = [
  {
    id: '1',
    label: '1',
    labelBadge: 'T1',
  },
  {
    id: '2',
    label: '2',
    labelBadge: 'T2',
  },
  {
    id: '3',
    label: '3',
    labelBadge: 'T3',
  },
  {
    id: '4',
    label: '4',
    labelBadge: 'T4',
  },
  {
    id: 'GT5',
    label: '+5',
    labelBadge: 'T5+',
  },
];
export const FLOOR_ITEMS = [
  {
    id: '0',
    label: 'Txt_Checkbox_Form_SearchForm_Floor_1',
  },
  {
    id: '1',
    label: 'Txt_Checkbox_Form_SearchForm_Floor_2',
  },
  {
    id: '2',
    label: 'Txt_Checkbox_Form_SearchForm_Floor_3',
  },
  {
    id: '3',
    label: 'Txt_Checkbox_Form_SearchForm_Floor_4',
  },
  {
    id: 'GT4',
    label: 'Txt_Checkbox_Form_SearchForm_Floor_GT5',
  },
];
export const DELIVERY_DATE_ITEMS_LEGACY = [
  {
    id: 0,
    label: 'Txt_SelectOption_Form_SearchForm_Delivery_Now',
  },
  {
    id: 6,
    label: 'Txt_SelectOption_Form_SearchForm_Delivery_LT6Months',
  },
  {
    id: 12,
    label: 'Txt_SelectOption_Form_SearchForm_Delivery_LT12Months',
  },
  {
    id: 24,
    label: 'Txt_SelectOption_Form_SearchForm_Delivery_LT24Months',
  },
];

export const DELIVERY_DATE_ITEMS = [
  {
    id: -1,
    label: 'Txt_SelectOption_Form_SearchForm_Delivery_Now',
  },
  {
    id: 0,
    label: 'Txt_SelectOption_Form_SearchForm_Delivery_Current_Year',
  },
  {
    id: 1,
    label: 'Txt_SelectOption_Form_SearchForm_Delivery_Next_Year',
  },
  {
    id: 2,
    label: 'Txt_SelectOption_Form_SearchForm_Delivery_Next_Next_Year',
  },
];
export const MIN_PROFITABILITY_ITEMS = [
  {
    id: '2',
    label: '2%',
  },
  {
    id: '3',
    label: '3%',
  },
  {
    id: '4',
    label: '4%',
  },
  {
    id: 'GT5',
    label: '+5%',
  },
];
export const PROGRAM_TYPE_ICON = {
  2: 'house',
  3: 'corporate_fare',
  4: 'storefront',
};

export const PROGRAM_TYPES = [
  {
    id: '2',
    label: 'HOUSE',
  },
  {
    id: '3',
    label: 'APARTMENT',
  },
  {
    id: '4',
    label: 'TRADE',
  },
];

export const LOT_ORIENTATION = [
  {
    id: '1',
    label: 'N',
  },
  {
    id: '2',
    label: 'NE',
  },
  {
    id: '3',
    label: 'E',
  },
  {
    id: '4',
    label: 'SE',
  },
  {
    id: '5',
    label: 'S',
  },
  {
    id: '6',
    label: 'SW',
  },
  {
    id: '7',
    label: 'W',
  },
  {
    id: '8',
    label: 'NW',
  },
  {
    id: '9',
    label: 'TRAVERSING',
  },
];

export enum AreaType {
  TERRACE = 1,
  BALCONY = 2,
  GARDEN = 3,
  LOGGIA = 4,
}

export const FEES_ITEMS = [
  {
    id: 6,
    label: '+6%',
  },
  {
    id: 7,
    label: '+7%',
  },
  {
    id: 8,
    label: '+8%',
  },
  {
    id: 9,
    label: '+9%',
  },
];

export const EMPTY_VALUE_NA = 'N/A';

/**
 * generate groups of 4 random characters
 * @example getUniqueId(1) : 607f
 * @example getUniqueId(2) : 95ca-361a-f8a1-1e73
 */
export const getUniqueId = (parts: number): string => {
  const stringArr = [];
  for (let i = 0; i < parts; i++) {
    // eslint-disable-next-line no-bitwise
    const S4 = (((Math.random() + 1) * 0x10000) | 0).toString(16).substring(1);
    stringArr.push(S4);
  }

  return stringArr.join('-');
};

// localStorage key
export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const ACCESS_TOKEN_EXPIRE_KEY = 'access_token_expire_date';
export const LOGIN_AS_ACCESS_TOKEN_KEY = 'login_as_access_token';
export const LOGIN_AS_REFRESH_TOKEN_KEY = 'login_as_refresh_token';
export const LOGIN_AS_ACCESS_TOKEN_EXPIRE_KEY = 'login_as_access_token_expire_date';
