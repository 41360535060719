import { TagsInterface, TagTypeEnum } from '../../../design-system/model/tag-option';

export const programTags: { [key: string]: TagsInterface } = {
  hasElectronicSignature: {
    ngClass: 'electronic-signature-tag',
    tagOption: {
      text: 'SIGNATURE EN LIGNE',
      type: TagTypeEnum.DEFAULT,
    },
  },
  hasExclusiveOffer: {
    ngClass: 'exclusive-offer-tag',
    tagOption: {
      text: 'EXCLUSIVITÉ',
      type: TagTypeEnum.DEFAULT,
    },
  },
  hasSpecialOffer: {
    ngClass: 'special-offer-tag',
    tagOption: {
      text: 'Éligible offres spéciales',
      type: TagTypeEnum.SPECIAL_OFFER,
    },
  },
  isHighStake: {
    ngClass: 'high-stake-tag',
    tagOption: {
      text: 'Programme à enjeu',
      type: TagTypeEnum.DEFAULT,
    },
  },
};
