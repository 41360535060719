import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';

import { DataLayerApiService } from './data-layer-api.service';
import { FeatureFlag } from '../tracking-tables/models/FeatureFlag';

@Injectable({ providedIn: 'root' })
export class FeatureFlagApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Get feature flags
   *
   * @returns {FeatureFlag[]}
   * @memberof DataLayerApiService
   */
  async getFeatureFlags(): Promise<FeatureFlag[]> {
    const path = this.BASE_URL + 'feature-flags';
    const res: Observable<FeatureFlag[]> = await this.get(path);
    return await lastValueFrom(res);
  }
}
