import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { TaxationDetailResponse } from '../../../../../../utils/models/TaxationDetailResponse';
import { I18nService } from '../../../../../../utils/services/i18n.service';
import { SafePipe } from '../../../../../../utils/pipes/safe.pipe';

@Component({
  selector: 'app-reduce-taxation-dialog',
  templateUrl: './reduce-taxation-dialog.component.html',
  styleUrls: ['./reduce-taxation-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatIconModule, SafePipe],
})
export class ReduceTaxationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public reducedTaxationLot: TaxationDetailResponse,
    public dialogRef: MatDialogRef<ReduceTaxationDialogComponent>,
    public readonly i18nService: I18nService,
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
