<mat-card appearance="outlined">
  <!-- Section 1: Trois divisions principales: logo à gauche, informations au centre, détails de la personne à droite -->
  <div class="flex flex-row">
    <!-- J'ai ajouté 'flex-row' pour s'assurer que les éléments sont alignés horizontalement -->

    <!-- LOGO à gauche -->
    <mat-card-content class="flex justify-center items-center w-1/3">
      <app-logo *ngIf="logoCompanyUrl" [url]="logoCompanyUrl"></app-logo>
    </mat-card-content>

    <!-- Au centre: Information Promoteur et nom du programme -->
    <div class="flex flex-col justify-center items-center w-1/3">
      <app-information
        [title]="translate('Title_Reservation_DeveloperInformation')"
        [content]="reservationInformations.lot.program.programName"
      >
      </app-information>
    </div>

    <!-- À droite: Person details (empilé) -->
    <div class="flex flex-col justify-end items-center w-1/3" style="margin-left: auto">
      <app-person-details
        [title]="translate('Txt_Page_Program_ManagerCard_AssociatedManager')"
        [firstName]="reservationInformations.rpv?.firstName"
        [lastName]="reservationInformations.rpv?.lastName"
      >
      </app-person-details>

      <app-person-details
        [title]="translate('Title_Sales_Administrator_Valorissimo')"
        [firstName]="reservationInformations.advValo?.firstName"
        [lastName]="reservationInformations.advValo?.lastName"
      >
      </app-person-details>
    </div>
  </div>

  <!-- Ligne séparatrice -->
  <hr class="my-4" />

  <!-- Section 2: Information Vendeur -->
  <app-vendor-details [reservationInformations]="reservationInformations"></app-vendor-details>
</mat-card>
