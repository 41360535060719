import { ReservationStatus } from '../../valo-back/reservation-status.enum';
import { TransactionStatus } from './transaction-status.enum';
import { TransactionType } from './transaction-type.enum';

export class TransactionResponseValoBack {
  id: number;
  lotId: number;
  lotNumber: string;
  companyName: string;
  programName: string;
  programThumbnailPath: string;
  city: string;
  departementCode: string;
  rooms: number;
  livingSpace: number;
  transactionType: TransactionType;
  status: TransactionStatus;
  price: number;
  transactionStartDate: Date;
  optionEndDate: Date;
  lastStatusChangedDate: Date;
  ownerUserId: number;
  isElectronicSignatureBI: boolean;
  isElectronicSignatureValorissimo: boolean;
  valoReservationValidationDate: Date;
  acountCompanyTypeLabel: string;
  dossierSignatureId: number;

  expirationDate: Date;
  isExtended: boolean;
  isAllowedToExtendOption: boolean;
  companyId: number;
  salesRepId: number;
  externalSIRemoveOptionsUrl: string;
  isExternalOption: boolean;
  developerMaintenance: boolean;
  responsibleId: number;
  reservationStatus: ReservationStatus;
  isImportedOption: boolean;
  isFinancialStrategyFeesReached: boolean;
  isFinancialStrategyLotSizeReached: boolean;
}
