import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, NgIf } from '@angular/common';

import { AccountUtilsService } from '../../../accounts/services/account-utils.service';
import { ReservationService } from '../../../reservations/services/reservation.service';
import { ActionOnOptionResponse } from '../../../utils/models/ActionOnOptionResponse';
import { ApprovalData } from '../../../utils/models/ApprovalData';
import { FileFormat } from '../../../utils/models/FileFormat';
import { ProspectResponse } from '../../../utils/models/ProspectResponse';
import { ReservationCreate } from '../../../utils/models/ReservationCreate';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { PoseOptionsService } from '../../../utils/services/pose-options.service';
import { ReferenceTablesService } from '../../../utils/services/reference-tables.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { SpinnerWithBackdropService } from '../../../utils/services/spinner-with-backdrop.service';
import { PreReservationSubmitionDialogData } from '../../models/PreReservationSubmitionDialogData';
import { ReservationMotiveData } from '../../models/ReservationMotiveData';
import { SafePipe } from '../../../utils/pipes/safe.pipe';
import { FileDropComponent } from '../../../utils/components/file-drop/file-drop.component';
import { InputDateComponent } from '../../../utils/components/input-date/input-date.component';
import { ChipPopulatedAutocompleteComponent } from '../../../utils/components/chip-populated-autocomplete/chip-populated-autocomplete.component';

@Component({
  selector: 'app-pre-reservation-submition-dialog',
  templateUrl: './pre-reservation-submition-dialog.component.html',
  styleUrls: ['./pre-reservation-submition-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    ChipPopulatedAutocompleteComponent,
    InputDateComponent,
    MatFormFieldModule,
    MatTooltipModule,
    FileDropComponent,
    MatInputModule,
    MatButtonModule,
    AsyncPipe,
    SafePipe,
  ],
})
export class PreReservationSubmitionDialogComponent implements OnInit {
  /**
   * canSubmit$ observable attribute
   *
   * @type {Observable<boolean>}
   * @memberof PreReservationSubmitionDialogComponent
   */
  public canSubmit$: Observable<boolean>;

  /**
   * commentsControl
   *
   * @type {AbstractControl}
   * @memberof PreReservationSubmitionDialogComponent
   */
  public commentsControl: AbstractControl;

  /**
   * commentsRequired
   */
  public commentsRequired = false;

  /**
   * defaultFile attribute
   *
   * @type {FileFormat}
   * @memberof PreReservationSubmitionDialogComponent
   */
  public defaultFile: FileFormat;

  /**
   * estimatedAppointmentDateControl
   *
   * @type {AbstractControl}
   * @memberof PreReservationSubmitionDialogComponent
   */
  public estimatedAppointmentDateControl: AbstractControl;

  /**
   * estimatedAppointmentDateRequired
   */
  public estimatedAppointmentDateRequired = false;

  /**
   * Form Group for check paternity
   *
   * @private
   * @type {FormGroup}
   * @memberof PaternityVerificationDialogComponent
   */
  public formGroup: UntypedFormGroup;

  /**
   * isApproval attribute
   *
   * @type {boolean}
   * @memberof PreReservationSubmitionDialogComponent
   */
  public isApproval: boolean;

  /**
   * isPending attribute
   *
   * @type {boolean}
   * @memberof PreReservationSubmitionDialogComponent
   */
  public isPending: boolean;

  /**
   * isSubmit
   *
   * @type {boolean}
   * @memberof PreReservationSubmitionDialogComponent
   */
  public isSubmit = false;

  /**
   * Prospect to associate to prereservation.
   */
  public prospect: ProspectResponse;

  /**
   * prospectInformations observable attribute
   *
   * @type {Observable<ProspectResponse>}
   * @memberof PreReservationSubmitionDialogComponent
   */
  public prospectInformations$: Observable<ProspectResponse>;

  /**
   * reservationInformations observable attribute
   *
   * @type {Observable<any>}
   * @memberof PreReservationSubmitionDialogComponent
   */
  public reservationInformations$: Observable<ReservationCreate>;

  /**
   * reservationMotiveItems$ attribute
   *
   * @type {Observable<any>}
   * @memberof PreReservationSubmitionDialogComponent
   */
  public reservationMotiveItems$: Observable<Map<string, number>>;

  /**
   * supportingDocumentRequired
   */
  public supportingDocumentRequired = false;

  /**
   * valoCommentsControl attribute
   *
   * @type {AbstractControl}
   * @memberof PreReservationSubmitionDialogComponent
   */
  public valoCommentsControl: AbstractControl;

  /**
   * warningPaternityMessage attribute
   *
   * @type {string}
   * @memberof PreReservationSubmitionDialogComponent
   */
  public warningPaternityMessage: string;
  /**
   * inputDateText attribute
   *
   * @type {String}
   * @memberof PreReservationSubmitionDialogComponent
   */
  public inputDateText: string;
  /**
   * motivesReservations attribute
   *
   * @type {Array<ReservationMotiveData>}
   * @memberof PreReservationSubmitionDialogComponent
   */
  public motivesReservations: Array<ReservationMotiveData>;
  /**
   * selectedReservationMotive attribute
   *
   * @type {number}
   * @memberof PreReservationSubmitionDialogComponent
   */
  public selectedReservationMotive: number;
  /**
   * contractSignedObject attribute
   *
   * @type {Array<ReservationMotiveData>}
   * @memberof PreReservationSubmitionDialogComponent
   */
  public contractSignedObject: Array<ReservationMotiveData>;
  /**
   * signingAppointmantProjectedObject attribute
   *
   * @type {Array<ReservationMotiveData>}
   * @memberof PreReservationSubmitionDialogComponent
   */
  public signingAppointmantProjectedObject: Array<ReservationMotiveData>;
  /**
   * motiveReservationRules attribute
   *
   * @private
   * @type {Array<{ needSupportingDocument: boolean, needComment: boolean }>}
   * @memberof PreReservationSubmitionDialogComponent
   */
  private readonly motiveReservationRules: Array<{
    id: number;
    needSupportingDocument: boolean;
    needComment: boolean;
  }>;
  /**
   * valoReminderDateControl attribute
   *
   * @private
   * @type {AbstractControl}
   * @memberof PreReservationSubmitionDialogComponent
   */
  private valoReminderDateControl: AbstractControl;
  /**
   * formGroupDirective attribute
   *
   * @type {FormGroupDirective}
   * @memberof TableComponent
   */
  private formGroupDirective: FormGroupDirective;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PreReservationSubmitionDialogData,
    public readonly i18nService: I18nService,
    private readonly accountUtilsService: AccountUtilsService,
    private readonly appConfigService: AppConfigService,
    private readonly dialogRef: MatDialogRef<PreReservationSubmitionDialogComponent>,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly poseOptionsService: PoseOptionsService,
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly reservationService: ReservationService,
    private readonly snackbarService: SnackbarService,
    private readonly spinnerWithBackdropService: SpinnerWithBackdropService,
  ) {
    // This dialog is called either with reservationId or with (lotId, lotNumber, programName)
    this.isApproval = !!(data && data.reservationId);
    this.motiveReservationRules = [];
  }

  @ViewChild(FormGroupDirective) set setFormGroupDirective(formGroupDirective: FormGroupDirective) {
    this.formGroupDirective = formGroupDirective;
  }

  ngOnInit(): void {
    this.isSubmit = false;

    this.formGroup = this.formBuilder.group({
      comments: [''],
      estimatedAppointmentDate: [''],
    });

    this.commentsControl = this.formGroup.controls.comments;
    this.estimatedAppointmentDateControl = this.formGroup.controls.estimatedAppointmentDate;

    // Add valoReminderDate and valoComments in form if isApproval (isApproval if reservationId not null)
    if (this.isApproval) {
      this.formGroup.addControl('valoReminderDate', new UntypedFormControl(undefined, []));
      this.formGroup.addControl('valoComments', new UntypedFormControl(undefined, []));
      this.valoReminderDateControl = this.formGroup.controls.valoReminderDate;
      this.valoCommentsControl = this.formGroup.controls.valoComments;
    }

    this.reservationMotiveItems$ = this.referenceTablesService.getTableReferenceInfoTranslated('ReservationMotives').pipe(
      map((reservationMotives) => {
        const items = new Map();
        reservationMotives.forEach((reservationMotive) => items.set(reservationMotive.label, reservationMotive.id));
        reservationMotives.forEach((reservationMotive) => {
          const entry = {
            id: reservationMotive.id as number,
            needSupportingDocument: reservationMotive.needSupportingDocument,
            needComment: reservationMotive.needComment,
          };
          this.motiveReservationRules.push(entry);
        });

        return items;
      }),
    );

    this.referenceTablesService.getTableReferenceInfo('ReservationMotives').subscribe((items) => (this.motivesReservations = items));

    // This is subscribe only if isApproval
    this.reservationInformations$ = this.reservationService.getInfosApprovalPopin(this.data.reservationId).pipe(
      map((reservation: ReservationCreate) => {
        this.commentsControl.setValue(reservation.comments);
        this.commentsControl.disable();
        this.estimatedAppointmentDateControl.setValue(reservation.estimatedAppointmentDate);
        this.estimatedAppointmentDateControl.disable();

        // Display supporting document only if it exists
        if (reservation.supportingDocument) {
          this.defaultFile = reservation.supportingDocument;
        }

        this.prospect = reservation.prospect;
        this.data.lotId = reservation.lotId;
        this.data.lotNumber = reservation.lot.lotNumber;
        this.data.programName = reservation.lot.program.programName;

        this.isPending = reservation.reservationStatus.label === this.appConfigService._('reservationStatus', 'pending');

        // The user can't edit valoReminderDate and valoComment if the prereservation is not pending
        if (!this.isPending) {
          this.valoReminderDateControl.setValue(reservation.valoReminderDate);
          this.valoReminderDateControl.disable();
          this.valoCommentsControl.setValue(reservation.valoComments);
          this.valoCommentsControl.disable();
        }

        return reservation;
      }),
    );

    // Only called if not isApproval
    this.prospectInformations$ = this.poseOptionsService.getUserProspectByLotId(this.data.lotId).pipe(
      map((prospect: ProspectResponse) => {
        this.prospect = prospect;
        this.setWarningPaternityMessage();

        return this.prospect;
      }),
    );

    // Only called if not isApproval
    this.canSubmit$ = this.poseOptionsService.getActionOnOption(this.data.lotId).pipe(
      map((actionOnOption: ActionOnOptionResponse) => {
        return actionOnOption.checkIfOptionCanBePreReserved;
      }),
    );
  }

  // This method only called if not isApproval (isApproval if reservationId not null)
  submit(): void {
    this.isSubmit = true;

    if (this.prospect && this.formGroup.valid) {
      this.formGroup.updateValueAndValidity();

      const reservationCreate: ReservationCreate = {
        lotId: this.data.lotId,
        prospectId: this.prospect.id,
        reservationMotiveId: this.formGroup.get('reservationMotiveId').value,
      };

      const comments = this.formGroup.get('comments').value;
      if (comments) {
        reservationCreate.comments = comments;
      }

      const estimatedAppointmentDate = this.formGroup.get('estimatedAppointmentDate').value;
      if (estimatedAppointmentDate) {
        reservationCreate.estimatedAppointmentDate = estimatedAppointmentDate;
      }

      const supportingDocument = this.formGroup.get('supportingDocument').value;
      if (supportingDocument) {
        reservationCreate.supportingDocument = supportingDocument;
      }

      this.spinnerWithBackdropService.show();
      this.reservationService.createReservation(reservationCreate).subscribe(
        (response) => {
          this.spinnerWithBackdropService.hide();
          this.snackbarService.info(response);
          this.dialogRef.close(true);
        },
        () => {
          this.spinnerWithBackdropService.hide();
          this.snackbarService.errorI18n('Error_SnackBar_ErrorOccuredRetry');
        },
      );
    } else {
      const errorsI18nTokens = [];
      if (this.formGroup.controls.estimatedAppointmentDate.hasError('required')) {
        errorsI18nTokens.push('Error_Field_EstimatedAppointmentDateRequired');
      }

      if (this.formGroup.controls.supportingDocument.hasError('required')) {
        errorsI18nTokens.push('Error_Field_SupportingDocumentRequired');
      }

      if (this.formGroup.controls.comments.hasError('required')) {
        errorsI18nTokens.push('Error_Field_CommentsRequired');
      }

      if (!errorsI18nTokens.length) {
        errorsI18nTokens.push('Error_SnackBar_FormIncomplete');
      }

      errorsI18nTokens.forEach((errorsI18nToken) => {
        this.snackbarService.errorI18n(errorsI18nToken);
      });
    }
  }

  /**
   * on reservation motive select changes
   */
  onReservationMotiveChange(selectedReservationMotives: Array<number>): void {
    // Dont compute validators if isApproval (isApproval if reservationId not null) because form is disabled
    if (!this.isApproval) {
      this.isSubmit = false;

      if (this.formGroupDirective) {
        const commentsValue = this.commentsControl.value;
        const estimatedAppointmentDateValue = this.estimatedAppointmentDateControl.value;
        const supportingDocumentValue = this.formGroup.get('supportingDocument').value;

        this.formGroupDirective.resetForm();

        this.commentsControl.setValue(commentsValue);
        this.estimatedAppointmentDateControl.setValue(estimatedAppointmentDateValue);
        this.formGroup.get('supportingDocument').setValue(supportingDocumentValue);
        this.formGroup.get('reservationMotiveId').setValue(selectedReservationMotives || []);
      }

      this.commentsControl.markAsUntouched();
      this.estimatedAppointmentDateControl.markAsUntouched();
      this.formGroup.get('supportingDocument').markAsUntouched();

      this.commentsControl.clearValidators();
      this.estimatedAppointmentDateControl.clearValidators();
      this.formGroup.get('supportingDocument').clearValidators();

      this.estimatedAppointmentDateRequired = false;
      this.supportingDocumentRequired = false;
      this.commentsRequired = false;

      if (selectedReservationMotives) {
        this.selectedReservationMotive = selectedReservationMotives[0];

        this.contractSignedObject = this.motivesReservations.filter(
          (x) => x.label === this.appConfigService._('motiveReservationLabel', 'contractSigned'),
        );
        this.signingAppointmantProjectedObject = this.motivesReservations.filter(
          (x) => x.label === this.appConfigService._('motiveReservationLabel', 'signingAppointmentProjected'),
        );

        if (this.selectedReservationMotive === this.contractSignedObject[0].id) {
          this.inputDateText = this.i18nService._('Txt_PreReservationSubmitionPopin_FormSignatureDateLabel');
        }
        if (this.selectedReservationMotive === this.signingAppointmantProjectedObject[0].id) {
          this.inputDateText = this.i18nService._('Txt_PreReservationSubmitionPopin_FormEstimatedAppointmentDateLabel');
        }
        const motive = this.motiveReservationRules.find((rule) => rule.id === this.selectedReservationMotive);

        this.estimatedAppointmentDateRequired = Boolean(motive && motive.needSupportingDocument);
        this.supportingDocumentRequired = Boolean(motive && motive.needSupportingDocument);
        this.commentsRequired = Boolean(motive && motive.needComment);

        this.commentsControl.setValidators(motive && motive.needComment ? [Validators.required] : []);
        this.formGroup.get('supportingDocument').setValidators(motive && motive.needSupportingDocument ? [Validators.required] : []);
      }

      this.commentsControl.updateValueAndValidity();
      this.estimatedAppointmentDateControl.updateValueAndValidity();
      this.formGroup.get('supportingDocument').updateValueAndValidity();
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  /**
   * validOrRejectPrereservation method, only called if approval
   *
   * @memberof PreReservationSubmitionDialogComponent
   */
  validOrRejectPrereservation(status: boolean): void {
    const approvalData: ApprovalData = this.formGroup.value;
    approvalData.reservationId = this.data.reservationId;
    approvalData.prereservationApproved = status;
    this.spinnerWithBackdropService.show();
    this.reservationService.sendApproval(approvalData).subscribe(
      () => {
        this.spinnerWithBackdropService.hide();
        // Display success message then close the dialog
        this.snackbarService.infoI18n(
          status ? 'Txt_PreReservationSubmitionPopin_SuccessValid' : 'Txt_PreReservationSubmitionPopin_SuccessReject',
        );
        this.dialogRef.close(true);
      },
      () => {
        this.spinnerWithBackdropService.hide();
      },
    );
  }

  /**
   * setWarningPaternityMessage method
   *
   * @returns {string} The warning message for paternity
   * @memberof PreReservationSubmitionDialogComponent
   */
  private setWarningPaternityMessage(): string {
    if (!this.prospect) {
      return undefined;
    }

    if (
      this.prospect.isPaternityPending &&
      (this.accountUtilsService.isLoggedUserValo() || this.accountUtilsService.isLoggedUserContractorOrIndependant())
    ) {
      return (this.warningPaternityMessage = this.i18nService._('Txt_PreReservationSubmitionPopin_WarningPaternityMessagePending'));
    }

    if (this.prospect.isPaternityRejected && this.accountUtilsService.isLoggedUserValo()) {
      return (this.warningPaternityMessage = this.i18nService._('Txt_PreReservationSubmitionPopin_WarningPaternityMessageRejectedValo'));
    }

    if (this.prospect.isPaternityRejected && this.accountUtilsService.isLoggedUserContractorOrIndependant()) {
      return (this.warningPaternityMessage = this.i18nService._(
        'Txt_PreReservationSubmitionPopin_WarningPaternityMessageRejectedContractor',
      ));
    }

    return undefined;
  }
}
