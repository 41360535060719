import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ParamRequest } from '@commons-dto/valo-back';

import { OptionsTrackingTableServiceResponse } from '../models/OptionsTrackingTableServiceResponse';
import { GridTrackingTableService } from './grid-tacking-table.service';
import { OptionsTrackingTableItem } from '../models/OptionsTrackingTableItem';

import { AppConfigService } from '../../utils/services/app-config.service';
import { CacheService } from '../../utils/services/cache.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';
import { TableLoader, TableResponse } from '../../table/interfaces/DataLoader';

@Injectable({
  providedIn: 'root',
})
export class OptionsTrackingTableService implements TableLoader<OptionsTrackingTableItem> {
  public url = 'Options/tracking-table/options';
  protected cacheName = 'tracking-options';

  constructor(
    public readonly appConfig: AppConfigService,
    public readonly errorHandlerService: ErrorHandlerService,
    public readonly http: HttpClient,
    public readonly cacheService: CacheService,
    private readonly gridTrackingTableService: GridTrackingTableService,
  ) {}

  public async getOptions(params: ParamRequest): Promise<OptionsTrackingTableServiceResponse> {
    const response: { items: Array<OptionsTrackingTableItem>; nbItems: number } = await lastValueFrom(
      this.gridTrackingTableService.getTrackingTableBack(params, this.url),
    );
    return response;
  }

  get(params): Promise<TableResponse<OptionsTrackingTableItem>> {
    return this.getOptions(params);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getById(id: number): Promise<OptionsTrackingTableItem> {
    // TODO: implement
    return null;
  }
}
