/* eslint-disable @typescript-eslint/no-explicit-any */
import { TitleCasePipe } from '@angular/common';
import { HttpClient, HttpHandler, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, flatMap, lastValueFrom, map, Observable, of, tap, throwError } from 'rxjs';

import { AccountInfosResponse } from '../models/AccountInfosResponse';
import { AccountResponse } from '../models/AccountResponse';
import { AccountsOfUserResponse } from '../models/AccountsOfUserResponse';
import { BasicResponse } from '../models/BasicResponse';
import { JwtToken, LoginResponse } from '../models/LoginResponse';
import { NumberOfAvailableLotsData } from '../models/NumberOfAvailableLotsData';
import { ResponsiblesInfo } from '../models/ResponsibleInfo';
import { RoleResponse } from '../models/RoleResponse';
import { AppConfigService } from './app-config.service';
import { TokenService } from './authorisation/token.service';
import { CacheService } from './cache.service';
import { ErrorHandlerService } from './error-handler.service';

import { GetAccountInfosResponse } from '../../accounts/models/getAccountInfosResponse';
import { CreateAccountRequest } from '../../accounts/models/CreateAccountRequest';
import { AccountApiService } from '../../adapters/account-api.service';

export interface LoginForm {
  username: string;
  password: string;
  rememberMe: boolean;
  reCAPTCHAToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  /**
   * Logged user instance.
   *
   * @type {AccountResponse}
   * @memberOf AccountService
   */
  public logged: AccountResponse;

  /**
   * roleList
   *
   * @type {Array<RoleResponse>}
   * @memberOf AccountService
   */
  private roleList: Array<RoleResponse>;

  /**
   * titleCasePipe
   *
   * @type {TitleCasePipe}
   * @memberOf AccountService
   */
  private readonly titleCasePipe: TitleCasePipe;

  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
    private readonly _cacheService: CacheService,
    private readonly _tokenService: TokenService,
    private readonly accountApiService: AccountApiService,
  ) {
    this.titleCasePipe = new TitleCasePipe();
  }

  /**
   * login
   *
   * @param form
   * @memberof AccountService
   */
  public login(form: LoginForm): Observable<JwtToken> {
    const url = '/auth/token';

    const defaultErrorFn = this.errorHandlerService.errorFnFactory.default();

    return this.http.post<JwtToken>(url, form).pipe(
      tap(() => this._cacheService.clear()),
      // The redirection on errors 401 and 403 are voluntarily cancelled.
      // No need to redirect to the login page or homepage because the user is already not logged in.
      catchError((error) => {
        this.mapToErrorHandlerService(error);

        return this.errorHandlerService.handleError<JwtToken>('accountService', 'login', {
          401: defaultErrorFn,
          403: defaultErrorFn,
        })(error);
      }),
    );
  }

  /**
   * logout
   *
   * @memberof AccountService
   */
  public logout(): Observable<LoginResponse> {
    const url = `/auth/logout`;

    return this.http
      .post<LoginResponse>(url, {
        principalToken: this._tokenService.getPrincipalRefreshToken(),
        logAsToken: this._tokenService.getLogAsRefreshToken(),
        token: this._tokenService.getPrincipalAccessToken(),
      })
      .pipe(
        tap(() => {
          this.logged = undefined;
          this._cacheService.clear();
          this._tokenService.deleteToken();
        }),
        catchError((error) => {
          this.mapToErrorHandlerService(error);

          return this.errorHandlerService.handleError<LoginResponse>('accountService', 'logout')(error);
        }),
      );
  }

  /**
   * Used to rename the embedded property string 'error' to 'code' in the error object.
   * The purpose is to be able to use the ErrorHandlerService.
   * @param error
   */
  private mapToErrorHandlerService(error: any) {
    if (error?.error?.error && (typeof error.error.error === 'string' || error.error.error instanceof String)) {
      Object.defineProperty(error.error, 'code', Object.getOwnPropertyDescriptor(error.error, 'error'));
      delete error.error['error'];
    }
  }

  public refreshAuth(): Observable<JwtToken> {
    const url = '/auth/refresh';

    return this.http
      .post<JwtToken>(url, {
        refresh_token: this._tokenService.getRefreshToken(),
      })
      .pipe(
        tap(() => {
          this.logged = undefined;
          this._cacheService.clear();
        }),
        catchError((error) => {
          this.mapToErrorHandlerService(error);
          return throwError(error);
        }),
      );
  }

  refreshToken(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.refreshAuth().pipe(
      flatMap((token) => {
        this._tokenService.setToken(token, this._tokenService.isConnectionAs());
        // eslint-disable-next-line no-param-reassign
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this._tokenService.getAccessToken()}`,
          },
        });

        return next.handle(req);
      }),
      catchError((error) => {
        this._cacheService.clear();
        this._tokenService.deleteToken();
        this._tokenService.deleteToken(true);

        return throwError(error); // Lever une exception ici
      }),
    );
  }

  /**
   * sendMailForPasswordInitialisation
   *
   * @param email
   * @memberof AccountService
   */
  public sendMailForPasswordInitialisation(email: string, reCAPTCHAToken: string): Observable<LoginResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Accounts/reset`;

    return this.http
      .post<LoginResponse>(url, { email, reCAPTCHAToken })
      .pipe(catchError(this.errorHandlerService.handleError<LoginResponse>('accountService', 'reset')));
  }

  /**
   * Method for create a user account.
   *
   * @param {*} form
   * @returns {Observable<any>}
   * @memberof AccountService
   */
  public createAccount(form: CreateAccountRequest): Observable<CreateAccountRequest> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Accounts`;

    return this.http
      .post<CreateAccountRequest>(url, form)
      .pipe(catchError(this.errorHandlerService.handleError<any>('accountService', 'create')));
  }

  /**
   * Method to update/validate a user account.
   *
   * @param {*} form
   * @param {boolean} isValidation
   * @returns {Observable<any>}
   * @memberof AccountService
   */
  public updateAccount(form: any, isValidation: boolean): Observable<any> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Accounts/${isValidation ? 'validateAccount' : 'updateAccount'}`;

    return this.http
      .post<any>(url, form)
      .pipe(catchError(this.errorHandlerService.handleError<any>('accountService', isValidation ? 'validate' : 'update')));
  }

  /**
   * Get all roles.
   *
   * @returns {Observable<RoleResponse[]>}
   * @memberof AccountService
   */
  public getAllRoles(): Observable<Array<RoleResponse>> {
    if (!this.roleList) {
      const url = `${this.appConfig.getLoopbackApiUrl()}/ValoRoles`;

      return this.http.get<Array<RoleResponse>>(url).pipe(
        tap((response) => (this.roleList = response)),
        catchError(this.errorHandlerService.handleError<any>('accountService', 'getAllRoles')),
      );
    }

    return of(this.roleList);
  }

  /**
   * Get all roles by company type.
   *
   * @returns {Observable<Map<string, Array<string>>>}
   * @memberof AccountService
   */
  public getRolesByCompanyType(): Observable<Map<string, Array<string>>> {
    return this.getAllRoles().pipe(
      map((roles) => {
        const rolesByCompanyType = new Map();

        roles.forEach((role) => {
          if (role && role.companyType && role.companyType.label) {
            const roleNames = rolesByCompanyType.has(role.companyType.label) ? rolesByCompanyType.get(role.companyType.label) : [];
            roleNames.push(role.name);
            rolesByCompanyType.set(role.companyType.label, roleNames);
          }
        });

        return rolesByCompanyType;
      }),
      catchError(this.errorHandlerService.handleError<any>('accountService', 'getRolesByCompanyType')),
    );
  }

  /**
   * Get account.
   *
   * @returns {Observable<any>}
   */
  public getAccount(accountId: number): Observable<GetAccountInfosResponse> {
    return this.http
      .get<any>(`${this.appConfig.getLoopbackApiUrl()}/Accounts/getAccountInfos/${accountId}`)
      .pipe(catchError(this.errorHandlerService.handleError<GetAccountInfosResponse>('accountService', 'getAccount')));
  }

  public getResponsibles(): Observable<ResponsiblesInfo> {
    return this.http
      .get<ResponsiblesInfo>(`${this.appConfig.getLoopbackApiUrl()}/Accounts/get-responsibles`)
      .pipe(catchError(this.errorHandlerService.handleError<ResponsiblesInfo>('accountService', 'getResponsibles')));
  }

  /**
   * Calcul roles UI capabilities.
   *
   * @param companyTypeId
   * @param profileId
   * @param valoRoleTypeId
   */
  public getCanCreateRolesUICapabilities(companyTypeId: number, profileId: number, valoRoleTypeId?: number): Array<RoleResponse> {
    if (this.roleList) {
      // Define CanCreateRoles capabilities in UI (same company, profile level +1 hyper > super > simple).
      let canCreateRolesUI = this.roleList.filter(
        // @todo add a "depth" db field on profil table to cacul on it and not on id !
        (role) => role.companyTypeId === companyTypeId && role.profilId === profileId + 1,
      );
      if (valoRoleTypeId) {
        canCreateRolesUI = canCreateRolesUI.filter((role) => role.valoRoleTypeId === valoRoleTypeId);
      }

      return canCreateRolesUI;
    }

    return [];
  }

  /**
   * Get all accounts according to param role list
   *
   * @param {string} roles
   * @returns
   * @memberof AccountService
   */
  public getAccountsWithRoles(roles: Array<string>): Observable<Array<AccountResponse>> {
    const url = `/api/Accounts/getAccountsWithRoles`;
    const params = new HttpParams().set('requiredRoles', roles.join('+'));

    return this.http
      .get<Array<AccountResponse>>(url, { params })
      .pipe(catchError(this.errorHandlerService.handleError<any>('accountService', 'getAccountsWithRoles')));
  }

  /**
   * getAccountsOfUser method
   *
   * @returns {Observable<AccountsOfUserResponse>}
   * @memberof AccountService
   */
  public getAccountsOfUser(): Observable<AccountsOfUserResponse> {
    return this.http
      .get<AccountsOfUserResponse>(`${this.appConfig.getLoopbackApiUrl()}/Accounts/get-accounts-of-user`)
      .pipe(catchError(this.errorHandlerService.handleError<any>('accountService', 'getAccountsOfUser')));
  }

  /**
   * getNumberOfAvailableLots method
   *
   * @returns {Observable<NumberOfAvailableLotsData>}
   * @memberof AccountService
   */
  public getNumberOfAvailableLots(): Observable<NumberOfAvailableLotsData> {
    return this.http
      .get<NumberOfAvailableLotsData>(`${this.appConfig.getLoopbackApiUrl()}/Lots/getNumberOfAvailableLots`)
      .pipe(catchError(this.errorHandlerService.handleError<NumberOfAvailableLotsData>('accountService', 'getNumberOfAvailableLots')));
  }

  /**
   * getAccountCompanyIdAndLabel method
   *
   * @returns {Observable<BasicResponse>}
   * @memberof AccountService
   */
  public getAccountCompanyIdAndLabel(userId: number): Observable<BasicResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Accounts/getAccountInfos/${userId}`;

    return this.http.get<AccountInfosResponse>(url).pipe(
      map((accountInfos) => {
        return {
          id: accountInfos.companyId,
          label: accountInfos.companyType.label,
        };
      }),
      catchError(this.errorHandlerService.handleError<BasicResponse>('accountService', 'getAccountCompanyIdAndLabel')),
    );
  }

  /**
   * Check if contractor can see fees or not
   * /!\ return true if user cannot see fees /!\
   *
   * @returns {Observable<NumberOfAvailableLotsData>}
   * @memberof AccountService
   */
  public checkFeesCantBeSeen(): Observable<boolean> {
    return this.http
      .get<boolean>(`${this.appConfig.getLoopbackApiUrl()}/Accounts/check-fees`)
      .pipe(catchError(this.errorHandlerService.handleError<boolean>('accountService', 'checkFees')));
  }

  public getConnectedUser(): Observable<LoginResponse> {
    return this.http.get<LoginResponse>(`${this.appConfig.getLoopbackApiUrl()}/Accounts/get-connected-user`).pipe(
      tap((loginResponse) => {
        this._tokenService.setOldToken(loginResponse);
        this._cacheService.clear();
      }),
    );
  }

  public async getAccountById(id: number): Promise<AccountResponse> {
    return lastValueFrom(this.accountApiService.getAccountById(id));
  }

  checkIsLoggedIn() {
    if (this._tokenService.getToken()) {
      return this._tokenService.getToken();
    }
  }
}
