import { Component, Input } from '@angular/core';

import { I18nService } from '../../../utils/services/i18n.service';
import { FormViewResponse } from './../../models/FormResponse';
import { PageHeaderComponent } from '../../../common/shared/page-header/page-header.component';

@Component({
  selector: 'app-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss'],
  standalone: true,
  imports: [PageHeaderComponent],
})
export class FormHeaderComponent {
  @Input() formView: FormViewResponse;

  constructor(public readonly i18nService: I18nService) {}
}
