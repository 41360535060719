<a (click)="pushToDatalayer(program, index)" [routerLink]="redirectToProgramPage(program.programId)" class="no-style" target="_self">
  <div class="program-card">
    <div class="perspective">
      <div *ngIf="isNewProgram" class="new-program">{{ 'Txt_Page_SearchPrograms_New' | translate }}</div>
      <!-- IMG best size: 425x350px -->
      <img #imgElementProgramPerspectives alt="{{ programPerspectiveImage }}" loading="lazy" src="{{ programPerspectiveImage }}" />
    </div>

    <div class="infos">
      <div [style.visibility]="visibilityOfAvailableLotStyleBlock" class="lots-available">
        <span>{{ availableLotsFormatted }}</span>
      </div>

      <div class="top-infos">
        <span class="location">{{ program.city }} ({{ isCountryFr ? program.departementCode : (program.countryLabel | translate) }})</span>
        <span class="program-name">{{ program.programName }}</span>
        <span class="address">{{ program.address.length > 105 ? (program.address | slice: 0 : 102) + '...' : program.address }}</span>
      </div>
      <hr class="separator" />

      <div class="middle-infos">
        <span class="nb-rooms">{{ minMaxNbRoomsFormatted }}</span>
        <span class="program-type-price">
          <ng-container *ngIf="program.programTypeLabel">{{ program.programTypeLabel | translate }}</ng-container>
          <span *ngIf="program.price">
            {{ 'Txt_Page_SearchPrograms_Price' | translate }}
            <span class="price">{{ this.program.price | currency: 'EUR' : 'symbol' : '1.0-2' }}</span>
          </span>
        </span>
        <span>
          {{ 'Txt_Page_SearchPrograms_Delivery' | translate }} <span class="delivery-date">{{ deliveryDateFormatted }}</span>
        </span>
        <span *ngIf="program.constructionWorkRatio">
          {{ 'Txt_Placeholder_ConstructionWorkRatio' | translate }} :
          <span class="construction-work-ratio">{{ program.constructionWorkRatio }} %</span>
        </span>
      </div>

      <hr class="separator" />

      <div class="bottom-infos">
        <div class="taxations">
          <ng-container *ngIf="taxations">
            <div *ngFor="let taxation of taxations" class="taxation">
              {{ taxation | translate }}
            </div>
          </ng-container>
          <div *ngIf="program.zoneLabel" class="taxation-zone">Zone&nbsp;{{ program.zoneLabel }}</div>
        </div>
        <app-strong-point-list class="mt-2" [strongPoints]="program.strongPoints" [isHoverable]="false"></app-strong-point-list>
        <div class="special-offer" *ngIf="program.nbSpecialOffers">
          <ng-container>
            <div class="result-tag special-offer-tag">{{ 'Txt_Table_SpecialOffer' | translate }}</div>
          </ng-container>
        </div>

        <div class="high-stake" *ngIf="program.isHighStake && userIsValo">
          <ng-container>
            <div class="result-tag high-stake-tag">{{ 'Txt_Table_HighStakeProgram' | translate }}</div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</a>
