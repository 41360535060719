<div [formGroup]="form" class="flex items-center flex-col p-9">
  <button class="closing-dialog" (click)="closeDialog(false)">
    <app-icon name="XMark"></app-icon>
  </button>
  <div class="flex justify-between">
    <h1 class="mb-6 text-2xl font-bold">{{ i18nService._(data.keyForTitle) }}</h1>
  </div>

  <div>
    <p>{{ i18nService._(data.keyForMainMessage) }}</p>
    <div class="mt-2">
      <label>{{ i18nService._(data.keyForCommentLabel) }}</label>
      <textarea class="h-[150px]" formControlName="comment" name="comment" pInputTextarea rows="10"></textarea>
    </div>
  </div>

  <div class="flex justify-end items-end w-full mt-8">
    <button class="bg-gray-300 w-fit h-12 mr-5 rounded-full uppercase font-bold px-8" mat-dialog-close>
      {{ i18nService._(data.keyForCancelling) }}
    </button>
    <button (click)="data.cancelOnly ? cancel() : cancelAndModify()" [disabled]="form.invalid" class="rounded-button">
      {{ i18nService._(data.keyForConfirming) }}
    </button>
  </div>
</div>
