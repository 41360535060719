/* eslint-disable @typescript-eslint/no-explicit-any */

import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ColDef, GridOptions } from 'ag-grid-community';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { GridTrackingTableComponent } from '../grid-tacking-table/grid-tracking-table.component';

import { ActionRendererComponent } from './../../renderers/action-renderer/action-renderer.component';
import { StatusLotLabels } from './../../../programs/components/lots-table-program/lots-table.program.check';
import { ProgramLotsInformationResponse } from './../../../utils/models/ProgramLotsInformations';
import { LotsProgramTrackingTableService } from './../../services/lots-program-tacking-table.service';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { SnackbarMessage } from './../../../utils/models/SnackbarMessage';
import { QuestionDialogResponse } from './../../../dialog/models/QuestionDialogResponse';
import { QuestionDialogComponent } from './../../../dialog/components/question-dialog/question-dialog.component';
import { QuestionDialogData } from './../../../dialog/models/QuestionDialogData';
import { LotResponse } from './../../../utils/models/LotResponse';
import { DateRendererComponent } from './../../renderers/date-renderer/date-renderer.component';
import { LotService } from './../../../lot/service/lot.service';
import { AppConfigService } from './../../../utils/services/app-config.service';
import { BasicFormatsService } from './../../../utils/services/basic-formats.service';
import { ProgramService } from './../../../programs/services/program.service';
import { I18nService } from './../../../utils/services/i18n.service';
import { SnackbarService } from './../../../utils/services/snackbar.service';
import { TableLoader, TableResponse } from './../../../table/interfaces/DataLoader';
import { PriceRendererComponent } from '../../renderers/price-renderer/price-renderer.component';
import { BooleanRendererComponent } from '../../renderers/boolean-renderer/boolean-renderer.component';
import { canEditLot, canPublishImportedUnpublishlot } from '../../../programs/components/lots-table-program/lots-table.program.check';

@Component({
  selector: 'app-lots-program-tracking-table',
  templateUrl: './lots-program-tracking-table.component.html',
  styleUrls: ['./lots-program-tracking-table.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, MatSelectModule, NgFor, MatOptionModule, GridTrackingTableComponent],
})
export class LotsProgramTrackingTableComponent implements OnInit, OnDestroy {
  @ViewChild(GridTrackingTableComponent)
  gridTrackingTable: GridTrackingTableComponent;

  /*
   * Input used to know if program is from XML import
   */
  @Input() public isImported: boolean;
  /**
   * Input used to know if user is Valo or not
   */
  @Input() public isValo: boolean;
  @Output() readonly isDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly selectedLotForEdit: EventEmitter<LotResponse> = new EventEmitter<LotResponse>();

  pageSize = 10;
  pageNumber = 1;
  tableHeight = 'auto';
  serverSide = false;
  columnDefs: ColDef[];
  gridOptions: GridOptions = {
    headerHeight: 45,
    rowHeight: 60,
    paginationPageSize: 90,
    paginationPageSizeSelector: false,
    suppressCellFocus: true,
    rowModelType: 'clientSide',
    domLayout: 'autoHeight',
    enableCellTextSelection: true,
  };
  filterValue: string;
  tableLoader: TableLoader<LotResponse>;
  lotStatus: StatusLotLabels;
  columns: { label: string; value: string }[];
  selectedColumns: string[];
  rows: LotResponse[];

  constructor(
    private readonly snackbarService: SnackbarService,
    public i18nService: I18nService,
    private readonly programService: ProgramService,
    private readonly lotsProgramTrackingTableService: LotsProgramTrackingTableService,
    private readonly dialog: MatDialog,
    private readonly basicFormatsService: BasicFormatsService,
    private readonly appConfigService: AppConfigService,
    private readonly lotService: LotService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.initializeGrid();
  }

  private initializeGrid() {
    const programId = this.getProgramId();
    this.lotStatus = this.appConfigService.getAppConfig().statusLotLabels;
    this.tableLoader = this.createTableLoader(programId);
    this.columnDefs = this.createColumnDefs();
    this.setVisibleColumn();
  }

  private getProgramId(): string {
    return this.route.snapshot.paramMap.get('programId');
  }

  private createTableLoader(programId: string): TableLoader<LotResponse> {
    const lotsProgramTrackingTableService = this.lotsProgramTrackingTableService;
    return {
      async get(): Promise<TableResponse<LotResponse>> {
        const lotsProgram: ProgramLotsInformationResponse = await lotsProgramTrackingTableService.getLotsProgram(programId);
        return { items: lotsProgram.lots };
      },
    };
  }

  private createColumnDefs(): ColDef[] {
    return [
      {
        field: 'lotNumber',
        headerName: this.i18nService._('Txt_Table_Column_Number'),
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'lotTypeLabel',
        headerName: this.i18nService._('Txt_Table_Column_Typo'),
        valueFormatter: (params) => {
          return params.value ? this.i18nService._(`Txt_Table_Column_Typo_${params.value}`) : undefined;
        },
        minWidth: 60,
        flex: 2,
      },
      {
        field: 'rooms',
        headerName: this.i18nService._('Txt_Table_Column_Rooms'),
        minWidth: 60,
        flex: 2,
      },
      {
        field: 'livingSpace',
        headerName: this.i18nService._('Txt_Table_Column_LivingSpace'),
        valueFormatter: (params) => {
          return params.value
            ? `${this.basicFormatsService.formatDecimal(params.value)} ${this.i18nService._('Txt_Table_SquareMeter')}`
            : undefined;
        },
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'commercialLotSellingPriceIT',
        headerName: this.i18nService._('Txt_Table_Column_CommercialLotSellingPriceIT'),
        cellRenderer: PriceRendererComponent,
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'reducedTotalSellingPriceIT',
        headerName: this.i18nService._('Txt_Table_Column_ReducedSellingPriceIT'),
        cellRenderer: PriceRendererComponent,
        minWidth: 100,
        flex: 4,
      },
      {
        field: 'status',
        headerName: this.i18nService._('Txt_Table_Column_Status'),
        valueFormatter: (params) => {
          return this.getStatus(this.lotStatus, params.value);
        },
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'isPublished',
        headerName: this.i18nService._('Txt_Table_Column_IsPublished'),
        cellRenderer: BooleanRendererComponent,
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'taxations',
        headerName: this.i18nService._('Txt_Table_Column_Taxations'),
        cellRenderer: (params) => {
          if (!params.value) {
            return '';
          }

          return params.value
            .map((tax: any) => {
              return `<div style="white-space: nowrap;">${tax.label}</div>`;
            })
            .join('');
        },
        minWidth: 80,
        flex: 3,
        cellStyle: {
          'white-space': 'normal',
          'text-align': 'left',
          display: 'block',
        },
      },
      {
        field: 'deliveryDate',
        headerName: this.i18nService._('Txt_Table_Column_Delivery'),
        cellRenderer: DateRendererComponent,
        cellRendererParams: {
          format: 'DD/MM/yyyy',
          replaceFalsyWithDash: true,
        },
        hide: true,
        minWidth: 100,
        flex: 4,
      },
      {
        field: 'floor',
        headerName: this.i18nService._('Txt_Table_Column_Floor'),
        hide: true,
        minWidth: 80,
        flex: 3,
      },
      {
        field: 'actions',
        headerName: this.i18nService._('Txt_Table_Column_Actions'),
        cellRenderer: ActionRendererComponent,
        cellRendererParams: {
          actionItems: (item: any) => {
            return this.getActionItems(item);
          },
        },
        cellStyle: { paddingRight: '10px', 'justify-content': 'flex-end' },
        flex: 2,
        minWidth: 60,
        maxWidth: 60,
      },
      {
        field: 'isComplete',
        headerName: this.i18nService._('Txt_Table_Column_IsComplete'),
        cellRenderer: BooleanRendererComponent,
        minWidth: 80,
        flex: 3,
      },
    ];
  }

  private setVisibleColumn(): void {
    this.columns = this.columnDefs.map((column) => {
      return {
        label: column.headerName,
        value: column.field,
      };
    });
    const selectedColumns = this.columns.map((column) => column.value);
    const result = selectedColumns.reduce((a, v) => ({ ...a, [v]: v }), {});
    delete result['deliveryDate'];
    delete result['floor'];
    this.selectedColumns = Object.keys(result);
  }

  get selectedColumnsSummary(): string {
    const nbSelectedColumns = this.selectedColumns.length;

    if (nbSelectedColumns) {
      const i18nTitle = this.columns.find((column) => column.value === this.selectedColumns[0]).label;
      switch (nbSelectedColumns) {
        case this.columns.length:
          return this.i18nService._('Txt_Select_Form_Table_All');
        case 1:
          return `${this.i18nService._(i18nTitle)}`;
        default:
          return `${this.i18nService._(i18nTitle)} ${this.i18nService._('Txt_Select_Form_Table_And_Other', [nbSelectedColumns - 1])}`;
      }
    }

    return '';
  }

  private getStatus(lotStatusLabel: StatusLotLabels, lotElementLabel: string): string {
    const { free, unavailable, optioned, reserved, prereserved, sold } = lotStatusLabel;

    const statusLabelsTranslated = {
      [free]: this.i18nService._('Txt_Detail_Program_Status_Lot_Free'),
      [unavailable]: this.i18nService._('Txt_Detail_Program_Status_Lot_Unavailable'),
      [optioned]: this.i18nService._('Txt_Detail_Program_Status_Lot_Optioned'),
      [reserved]: this.i18nService._('Txt_Detail_Program_Status_Lot_Reserved'),
      [prereserved]: this.i18nService._('Txt_Detail_Program_Status_Lot_Prereserved'),
      [sold]: this.i18nService._('Txt_Detail_Program_Status_Lot_Sold'),
    };

    if (!statusLabelsTranslated[lotElementLabel]) {
      return '';
    }

    return statusLabelsTranslated[lotElementLabel];
  }

  getActionItems(item: LotResponse): any[] {
    const actions = [];

    if (canEditLot(this.isValo, this.isImported, this.lotStatus, item)) {
      actions.push({
        label: 'Txt_Tooltip_EditLot',
        action: () => {
          this.edit(item);
        },
      });
    }
    if (((!this.isValo && !this.isImported) || this.isValo) && !item.isPublished) {
      actions.push({
        label: 'Txt_Tooltip_Delete',
        action: () => {
          this.delete(item);
        },
      });
    }

    if (item.switchPublicationLotStateAutorized) {
      if (item.isPublished) {
        actions.push({
          label: 'Txt_Tooltip_Unpublish',
          action: () => {
            this.unpublish(item);
          },
        });
      } else {
        actions.push({
          label: 'Txt_Tooltip_Publish',
          action: () => {
            this.publish(item);
          },
        });
      }
    } else {
      if (canPublishImportedUnpublishlot(this.isValo, this.isImported, this.appConfigService.getAppConfig().statusLotLabels, item)) {
        actions.push({
          label: 'Txt_Tooltip_Publish',
          action: () => {
            this.publish(item);
          },
        });
      }
    }

    return actions;
  }

  edit(element: LotResponse): void {
    this.selectedLotForEdit.emit(element);
  }

  delete(element: LotResponse): void {
    const dialogData: MatDialogConfig<QuestionDialogData> = {
      data: {
        message: this.i18nService._('Txt_Popin_Lot_Deletion'),
        title: this.i18nService._('Title_Lot_Deletion'),
      },
    };

    const dialogRef = this.dialog.open(QuestionDialogComponent, dialogData);

    dialogRef.afterClosed().subscribe((result: QuestionDialogResponse) => {
      if (result && result.answer) {
        this.programService.deleteLot(element.id).subscribe({
          next: () => {
            const message: SnackbarMessage = {
              text: this.i18nService._('Success_SnackBar_Lot_Deleted'),
              type: SnackbarMessageType.Info,
            };
            this.snackbarService.sendMessage(message);
            this.updateLotTableData(element, 'delete');
            this.isDeleted.emit(true);
          },
          error: () => {
            const message: SnackbarMessage = {
              text: this.i18nService._('Error_SnackBar_Lot_Deleted'),
              type: SnackbarMessageType.Error,
            };
            this.snackbarService.sendMessage(message);
          },
        });
      }
    });
  }

  publish(element: LotResponse): void {
    this.lotService.publishLot(element.id).subscribe(() => {
      element.isPublished = true;
      const message: SnackbarMessage = {
        text: this.i18nService._('Info_SnackBar_LotPublished'),
        type: SnackbarMessageType.Info,
      };
      this.snackbarService.sendMessage(message);
      // Dont subscribe again for lots information to update table, just update lot data in local
      this.updateLotTableData(element, 'changePublicationState');
    });
  }

  unpublish(element: LotResponse): void {
    // Open modal
    const dialogData: MatDialogConfig<QuestionDialogData> = {
      data: {
        message: this.i18nService._('Txt_Popin_Lot_Unpublication'),
        title: this.i18nService._('Title_Lot_Unpublication'),
      },
    };
    const dialogRef = this.dialog.open(QuestionDialogComponent, dialogData);

    dialogRef.afterClosed().subscribe((result: QuestionDialogResponse) => {
      if (result && result.answer) {
        this.lotService.unpublishLot(element.id).subscribe(() => {
          element.isPublished = false;
          const message: SnackbarMessage = {
            text: this.i18nService._('Info_SnackBar_LotUnpublished'),
            type: SnackbarMessageType.Info,
          };
          this.snackbarService.sendMessage(message);
          // Dont subscribe again for lots information to update table, just update lot data in local
          this.updateLotTableData(element, 'changePublicationState');
        });
      }
    });
  }

  /**
   * Method to give to step 3 of program form the needed informations :
   * nbLots & the closest deliveryDate
   */
  private updateLotSummary(lots: Array<LotResponse>): void {
    const lotSummary = {
      nbLots: lots.length,
      deliveryDate: this.programService.getDeliveryDateOfProgram(lots),
    };
    this.programService.setLotSummary(lotSummary);
  }

  /**
   * Method to know which contractual document packages is needed
   * @param {Array<LotResponse>} lots
   */
  private lotTypeContractualDocumentPackages(lots: Array<LotResponse>): void {
    const isApartmentContractualDocumentPackages = Boolean(
      lots.find((lot) => {
        return lot.lotTypeLabel === this.appConfigService.appConfig.programTypes.APARTMENT;
      }),
    );
    const isHouseContractualDocumentPackages = Boolean(
      lots.find((lot) => {
        return lot.lotTypeLabel === this.appConfigService.appConfig.programTypes.HOUSE;
      }),
    );
    const isTradeContractualDocumentPackages = Boolean(
      lots.find((lot) => {
        return lot.lotTypeLabel === this.appConfigService.appConfig.programTypes.TRADE;
      }),
    );
    this.programService.setContractualDocumentPackages({
      isApartmentContractualDocumentPackages,
      isHouseContractualDocumentPackages,
      isTradeContractualDocumentPackages,
    });
  }

  getRows(rows: LotResponse[]) {
    this.rows = rows;
    // Update data to be shown on step 3 program form
    this.updateLotSummary(this.rows);
    // Tell lot type to step 4
    this.lotTypeContractualDocumentPackages(this.rows);
  }

  /**
   * Update Lot Table data depending to source
   * @param {lotTypeLabel} element
   * @param {string} source
   */
  public updateLotTableData(element: LotResponse, source: string): void {
    switch (source) {
      case 'delete':
        this.rows = this.rows.filter((lot) => lot.id !== element.id);
        this.gridTrackingTable.setRowData(this.rows);
        break;
      case 'create':
        this.rows.unshift(element);
        this.gridTrackingTable.setRowData(this.rows);
        break;
      default:
        // Modify the element without changing the index
        this.rows = this.rows.reduce((acc, lot) => (lot.id === element.id ? acc.concat(element) : acc.concat(lot)), []);
        this.gridTrackingTable.updateSingleDataRow(element.id, element);
    }
  }

  private;

  ngOnDestroy(): void {
    this.programService.setLotSummary(undefined);
    this.programService.setContractualDocumentPackages({
      isApartmentContractualDocumentPackages: false,
      isHouseContractualDocumentPackages: false,
      isTradeContractualDocumentPackages: false,
    });
  }
}
