import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

import { LotsTableProgramComponent } from '../lots-table-program/lots-table-program.component';
import { SecondaryLotComponent } from '../secondary-lot/secondary-lot.component';
import { TaxationCheckboxesLotComponent } from '../taxation-checkboxes-lot/taxation-checkboxes-lot.component';
import { AreaInputFormProgramComponent } from '../area-input-form-program/area-input-form-program.component';

import { LotService } from '../../../lot/service/lot.service';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { FileFormat } from '../../../utils/models/FileFormat';
import { LotAreaResponse } from '../../../utils/models/LotAreaResponse';
import { LotResponse } from '../../../utils/models/LotResponse';
import { LotToCreate } from '../../../utils/models/LotToCreate';
import { LotToUpdate } from '../../../utils/models/LotToUpdate';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SharedProgramService } from '../../../utils/services/shared-program.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { SecondaryLot } from '../../models/SecondaryLot';
import { FileDropComponent } from '../../../utils/components/file-drop/file-drop.component';
import { InputDateComponent } from '../../../utils/components/input-date/input-date.component';
import { DecimalMaskDirective } from '../../../utils/directives/decimal-mask.directive';
import { DropdownListPopulatedComponent } from '../../../utils/components/dropdown-list-populated/dropdown-list-populated.component';
import { TrackingTablesModule } from '../../../tracking-tables/tracking-tables.module';

@Component({
  selector: 'app-step-two-form-program',
  templateUrl: './step-two-form-program.component.html',
  styleUrls: ['./step-two-form-program.component.scss'],
  standalone: true,
  imports: [
    TrackingTablesModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    DropdownListPopulatedComponent,
    DecimalMaskDirective,
    AreaInputFormProgramComponent,
    InputDateComponent,
    MatCheckboxModule,
    TaxationCheckboxesLotComponent,
    FileDropComponent,
    MatRadioModule,
    SecondaryLotComponent,
    MatButtonModule,
    MatStepperModule,
  ],
})
export class StepTwoFormProgramComponent implements OnInit {
  /**
   * mandateStrategyRecap child attribute
   *
   * @type {MandateStrategiesRecapComponent}
   * @memberof RegisterFormMandateStrategyComponent
   */
  @ViewChild('lotsTableProgram')
  public lotsTableProgram: LotsTableProgramComponent;

  /**
   * ParentForm to add controls to
   *
   * @type {FormGroup}
   * @memberof StepTwoFormProgramComponent
   */
  @Input() parentForm: UntypedFormGroup;

  /**
   * Input for recovered data
   *
   * @type {number}
   * @memberof StepTwoFormProgramComponent
   */
  @Input() programId: number;

  /**
   * Input from edit form or validate form to know if consult or not
   *
   * @type {boolean}
   * @memberof StepTwoFormProgramComponent
   */
  @Input() isEditOrValidateForm: boolean;

  /**
   * Boolean to know if program is from XML import.
   *
   * @type {boolean}
   * @memberof StepTwoFormProgramComponent
   */
  @Input() isImported: boolean;

  /**
   * Boolean to know if user is Valo.
   *
   * @type {boolean}
   * @memberof StepTwoFormProgramComponent
   */
  @Input() isValo: boolean;

  /**
   * lotForm to add controls to
   *
   * @type {FormGroup}
   * @memberof StepTwoFormProgramComponent
   */
  public lotForm: UntypedFormGroup;

  /**
   * All controls on form fields
   *
   * @type {AbstractControl}
   * @memberof StepTwoFormProgramComponent
   */
  public lotRefControl: AbstractControl;
  public lotNumberControl: AbstractControl;
  public livingSpaceControl: AbstractControl;
  public roomsControl: AbstractControl;
  public floorControl: AbstractControl;
  public deliveryDateControl: AbstractControl;
  public documentControl: AbstractControl;
  public secondaryLotPriceIncludedControl: AbstractControl;
  public isValorissimoExclusivityControl: AbstractControl;

  /**
   * isSubmitLotCreation
   *
   * @type {boolean}
   * @memberof StepTwoFormProgramComponent
   */
  public isSubmitLotCreation = false;

  /**
   * isSubmitLotModification
   *
   * @type {boolean}
   * @memberof StepTwoFormProgramComponent
   */
  public isSubmitLotModification = false;

  /**
   * Reference table name for the lot type
   *
   * @type {string}
   * @memberof StepTwoFormProgramComponent
   */
  public lotTypeReferenceTable = '';
  /**
   * lotCreation boolean to know if the lot form has to be display
   *
   * @type {boolean}
   * @memberof StepTwoFormProgramComponent
   */
  public lotCreation = false;
  /**
   * Editable lot, data of the lot that we want to edit for a program
   *
   * @type {LotResponse}
   * @memberof StepTwoFormProgramComponent
   */
  public editableLot: LotResponse;
  /**
   * Know if the user is doing an edit on a lot
   *
   * @type {boolean}
   * @memberof StepTwoFormProgramComponent
   */
  public isEditLot = false;
  /**
   * Recovered documents to init for update form
   *
   * @type {FileFormat[]}
   * @memberof StepTwoFormProgramComponent
   */
  public initFile: Array<FileFormat>;
  /**
   * Lot Id to load secondary lot on edit lot form
   *
   * @private
   * @type {number}
   * @memberof StepTwoFormProgramComponent
   */
  public lotId: string;
  /**
   * Lot Area to init for edit lot form
   *
   * @private
   * @type {Array<LotAreaResponse>}
   * @memberof StepTwoFormProgramComponent
   */
  public lotAreas: Array<LotAreaResponse>;
  /**
   * Output to tell to inform that step is completed
   *
   * @type {EventEmitter<boolean>}
   * @memberof StepThreeFormProgramComponent
   */
  @Output() readonly stepCompleted: EventEmitter<boolean>;
  /**
   * taxationsChecked
   *
   * @type {Array<TaxationResponse>}
   * @memberof StepTwoFormProgramComponent
   */
  private secondaryLots: Array<SecondaryLot> = [];
  /**
   * Incomplete Lots of the program
   * @private
   * @type {Array<LotResponse>}
   * @memberof StepTwoFormProgramComponent
   */
  private incompleteLots: Array<LotResponse> = [];

  /**
   * Used to know if there is a form validation error
   * @private
   * @type {boolean}
   * @memberof StepTwoFormProgramComponent
   */
  private isEditOrUpdateLotFormsError = false;

  /**
   * Creates an instance of StepTwoFormProgramComponent.
   * @param {FormBuilder} formBuilder
   * @param {I18nService} i18nService
   * @param {SnackbarService} snackbarService
   * @param {LotService} lotService
   * @param {ProgramService} programService
   * @param {AppConfigService} appConfigService
   * @param {ChangeDetectorRef} ref
   * @memberof StepTwoFormProgramComponent
   */
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    public i18nService: I18nService,
    private readonly snackbarService: SnackbarService,
    private readonly lotService: LotService,
    private readonly sharedProgramService: SharedProgramService,
    public readonly appConfigService: AppConfigService,
    private readonly ref: ChangeDetectorRef,
  ) {
    // Init array for lot document
    this.initFile = [];

    this.lotAreas = undefined;

    this.lotId = '';

    this.editableLot = undefined;

    this.stepCompleted = new EventEmitter<boolean>();
  }

  /**
   * Init component
   *
   * @memberof StepTwoFormProgramComponent
   */
  ngOnInit(): void {
    // Reference Table for drop-down-populated-list of lot types
    // eslint-disable-next-line prefer-template
    this.lotTypeReferenceTable =
      'ProgramTypes?filter=' +
      JSON.stringify({
        where: {
          lotType: true,
        },
      });
    // Create all default controls
    this.lotForm = this.formBuilder.group({
      lotRef: new UntypedFormControl('', [Validators.required]),
      lotNumber: new UntypedFormControl('', [Validators.required]),
      livingSpace: new UntypedFormControl(undefined, [Validators.required]),
      rooms: new UntypedFormControl('', [Validators.required, Validators.min(1)]),
      floor: new UntypedFormControl('', [Validators.required, Validators.pattern(this.appConfigService.appConfig.regEx.floor)]),
      deliveryDate: new UntypedFormControl('', [Validators.required]),
      secondaryLotPriceIncluded: new UntypedFormControl(true, [Validators.required]),
      document: this.formBuilder.group({}),
      lotHasAreas: this.formBuilder.array([]),
      lotHasTaxations: this.formBuilder.array([]),
      isValorissimoExclusivity: new UntypedFormControl(false, [Validators.required]),
    });

    this.documentControl = this.lotForm.controls.document;
    this.lotRefControl = this.lotForm.controls.lotRef;
    this.lotNumberControl = this.lotForm.controls.lotNumber;
    this.livingSpaceControl = this.lotForm.controls.livingSpace;
    this.roomsControl = this.lotForm.controls.rooms;
    this.floorControl = this.lotForm.controls.floor;
    this.deliveryDateControl = this.lotForm.controls.deliveryDate;
    this.secondaryLotPriceIncludedControl = this.lotForm.controls.secondaryLotPriceIncluded;
    this.isValorissimoExclusivityControl = this.lotForm.controls.isValorissimoExclusivity;

    // Get incomplete Lots of program
    // TODO: use afterviewInit because we have the program
    this.checkIfIncompleteLots(true);
  }

  /**
   * Submit on lot creation
   *
   * @memberof StepTwoFormProgramComponent
   */
  submitLotCreation(): void {
    this.isSubmitLotCreation = true;
    // Check if there is an error on the form
    this.lotService.checkLotRefAndLotNumberUnicity(this.programId, this.lotRefControl.value, this.lotNumberControl.value).subscribe(
      (response) => {
        if (response) {
          this.doSubmitLotCreation();
          window.scrollTo(0, 0);
        } else {
          this.snackBarError('Error_SnackBar_UniquenessLotRefOrLotNumber', {
            localize: true,
            isHtml: false,
          });
        }
      },
      () => {
        this.snackBarError();
      },
    );
  }

  /**
   * Generic method to know if lot form is valid
   *
   * @returns {boolean}
   * @memberof StepTwoFormProgramComponent
   */
  isValidForm(): boolean {
    if (!this.lotForm.valid) {
      const message: SnackbarMessage = {
        text: this.i18nService._('Error_SnackBar_FormIncomplete'),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
      this.isEditOrUpdateLotFormsError = true;

      return false;
    }
    if (this.lotForm.get('lotHasTaxations').value.filter((taxation) => taxation.reducedTaxation && taxation.checkBoxControl).length > 1) {
      this.snackBarError('Error_Lot_Has_Too_Many_Reduced_Taxations', {
        localize: true,
        isHtml: false,
      });
      this.isEditOrUpdateLotFormsError = true;

      return false;
    }

    this.isEditOrUpdateLotFormsError = false;

    return true;
  }

  /**
   * Prepare form for new lot creation
   *
   * @memberof StepTwoFormProgramComponent
   */
  newLot(): void {
    this.cancelLotCreationOrModification(false);
    this.lotCreation = true;
    this.secondaryLotPriceIncludedControl.setValue(true);
  }

  /**
   * Pass secondary lot to be editted
   *
   * @param {Array<SecondaryLot>} secondaryLots
   * @memberof StepTwoFormProgramComponent
   */
  onSecondaryLotsChange(secondaryLots: Array<SecondaryLot>): void {
    this.secondaryLots = secondaryLots;
  }

  /**
   * Submit on lot modification
   *
   * @memberof StepTwoFormProgramComponent
   */
  submitLotModification(): void {
    this.isSubmitLotModification = true;
    // Do not need to check lot Ref and Number because disabled
    this.doSubmitLotModification();
    window.scrollTo(0, 0);
  }

  /**
   * Reset all variable and lot form
   * Detect changes to apply them
   *
   * @memberof StepTwoFormProgramComponent
   */
  cancelLotCreationOrModification(cancelFromButton: boolean): void {
    this.isSubmitLotCreation = false;
    this.isSubmitLotModification = false;
    this.isEditLot = false;
    this.lotAreas = undefined;
    this.lotCreation = false;
    this.lotId = '';
    this.editableLot = undefined;
    this.initFile = [];
    this.secondaryLots = [];
    this.lotForm.reset();
    // Enable lotRef and lotNumber
    this.lotForm.get('lotRef').enable();
    this.lotForm.get('lotNumber').enable();
    // Detect changes for ngIf booleans
    this.ref.detectChanges();
    this.isEditOrUpdateLotFormsError = false;
    if (cancelFromButton) {
      window.scrollTo(0, 0);
    }
  }

  /**
   * Clear form and variables on lot deletion
   *
   * @param {boolean} isDeleted
   * @memberof StepTwoFormProgramComponent
   */
  onLotDeletion(isDeleted: boolean): void {
    this.isEditOrUpdateLotFormsError = false;
    if (isDeleted) {
      this.cancelLotCreationOrModification(false);
    }
  }

  /**
   * Fill form with data from selected lot in table
   *
   * @param {LotResponse} selectedLot
   * @memberof StepTwoFormProgramComponent
   */
  onSelectedLotForEdit(selectedLot: LotResponse): void {
    if (selectedLot) {
      this.cancelLotCreationOrModification(false);
      this.editableLot = selectedLot;
      this.lotAreas = selectedLot.lotAreas;

      // Set document
      selectedLot.documents.forEach((attachment) => {
        this.initFile[attachment.type.label] = attachment;
      });

      // Set all fields except documents, lotHasAreas && lotHasTaxations
      Object.keys(this.lotForm.controls).forEach((key) => {
        if (key !== 'document' && key !== 'lotHasAreas' && key !== 'lotHasTaxations') {
          this.lotForm.controls[key].setValue(selectedLot[key]);
        }
      });
      // Transforme integer value from db in boolean value for secondaryLotPriceIncluded
      this.secondaryLotPriceIncludedControl.setValue(Boolean(this.secondaryLotPriceIncludedControl.value));
      // Give lotId for secondary Lot
      this.lotId = String(selectedLot.id);

      // Disable lotRef and lotNumber.
      this.lotForm.get('lotRef').disable();
      this.lotForm.get('lotNumber').disable();

      // Display lot Form consult the selected lot
      this.isEditLot = true;
    }
  }

  submitStep(): void {
    if (this.isEditOrUpdateLotFormsError) {
      this.stepCompleted.emit(false);
    }
    this.checkIfIncompleteLots();
  }

  prepareSnackbarMessageErrorIncompletesLots(arrayOfIncompletesLotsWithLotNumber): string {
    const initHtmlTagList = '\n <ul> \n';
    const endHtmlTagList = '</ul>';
    let lotsNumbersWithoutCompleteStatusHtmlTag = initHtmlTagList;

    const errorMessageHTML = `<span> ${this.i18nService._('Error_SnackBar_lotsAreNotComplete')} </span>`;

    arrayOfIncompletesLotsWithLotNumber.forEach((incompleteLot) => {
      lotsNumbersWithoutCompleteStatusHtmlTag = `${lotsNumbersWithoutCompleteStatusHtmlTag} <li> ${incompleteLot.lotNumber} </li> \n`;
    });

    // => <ul> <li> A56 </li> </ul>
    lotsNumbersWithoutCompleteStatusHtmlTag = lotsNumbersWithoutCompleteStatusHtmlTag.concat(endHtmlTagList);

    return errorMessageHTML.concat(lotsNumbersWithoutCompleteStatusHtmlTag);
  }

  checkIfIncompleteLots(onInit = false): Subscription {
    // In creation mode dont check
    if (this.programId) {
      return this.sharedProgramService.getProgramLotsInformation(`${this.programId}`).subscribe(
        (itemsFound) => {
          if (itemsFound.error) {
            const message: SnackbarMessage = {
              text: this.i18nService._('Error_SnackBar_ErrorOccuredRetry'),
              type: SnackbarMessageType.Error,
            };
            this.snackbarService.sendMessage(message);
          }

          this.incompleteLots = itemsFound.incompleteLots;

          if (!itemsFound.complete) {
            if (!onInit) {
              const snackbarMessageErrorIncompletesLots = this.prepareSnackbarMessageErrorIncompletesLots(this.incompleteLots);
              // Pop an error
              this.snackBarError(snackbarMessageErrorIncompletesLots, {
                localize: false,
                isHtml: true,
              });
            }

            this.stepCompleted.emit(false);

            return true;
          }
          if (!onInit) {
            this.stepCompleted.emit(true);
          }

          return false;
        },
        () => {
          const message: SnackbarMessage = {
            text: this.i18nService._('Error_SnackBar_ErrorOccuredRetry'),
            type: SnackbarMessageType.Error,
          };
          this.snackbarService.sendMessage(message);

          return false;
        },
      );
    }
  }

  private snackBarError(messageToShow = this.i18nService._('Error_SnackBar_ErrorOccuredRetry'), options?: Options): void {
    this.snackBar(messageToShow, SnackbarMessageType.Error, options);
  }

  private snackBarInfo(messageToShow: string, options: Options): void {
    this.snackBar(messageToShow, SnackbarMessageType.Info, options);
  }

  private snackBar(messageToShow: string, type: SnackbarMessageType = SnackbarMessageType.Info, options: Options): void {
    const { localize, isHtml } = options || {};
    this.snackbarService.message(messageToShow, type, localize, [], isHtml);
  }

  /**
   * Do Submit on lot creation
   *
   * @memberof StepTwoFormProgramComponent
   */
  private doSubmitLotCreation(): void {
    this.isValorissimoExclusivityControl.setValue(Boolean(this.isValorissimoExclusivityControl.value));
    if (this.isValidForm()) {
      // initialisation of lotToCreate
      const lotToCreate = new LotToCreate();
      this.lotService.formateLotDataToSubmit(lotToCreate, this.lotForm, this.secondaryLots, this.programId);
      this.lotService.createLot(lotToCreate).subscribe(
        (createLotResponse) => {
          createLotResponse.status = this.appConfigService.getAppConfig().statusLotLabels.free;
          createLotResponse.lotTypeLabel = createLotResponse.programType.label;
          this.cancelLotCreationOrModification(false);
          this.lotsTableProgram.updateLotTableData(createLotResponse, 'create');
          this.snackBarInfo('Info_SnackBar_LotCreated', {
            localize: true,
            isHtml: false,
          });
        },
        () => {
          this.snackBarError();
        },
      );
    }
  }

  /**
   * Submit lot modification
   *
   * @private
   * @memberof StepTwoFormProgramComponent
   */
  private doSubmitLotModification(): void {
    if (this.isValidForm()) {
      const lotToUpdate = new LotToUpdate();
      this.lotService.formateLotDataToSubmit(lotToUpdate, this.lotForm, this.secondaryLots, this.programId);

      lotToUpdate.id = this.editableLot.id;
      lotToUpdate.documentsId = [];

      this.lotService.updateLot(lotToUpdate, this.editableLot.id).subscribe(
        (updateLotResponse) => {
          updateLotResponse.status = this.editableLot.status;
          this.cancelLotCreationOrModification(false);
          this.lotsTableProgram.updateLotTableData(updateLotResponse, 'edit');
          this.snackBarInfo('Info_SnackBar_LotUpdated', {
            isHtml: false,
            localize: true,
          });
        },
        (error) => {
          this.snackBarError(error?.error?.error?.message);
        },
      );
    }
  }
}

/* eslint-disable max-classes-per-file */
class Options {
  localize = true;
  isHtml = false;
}
