import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegisterFormReservationComponent } from './components/register-form-reservation/register-form-reservation.component';
import { ReservationsRoutingModule } from './reservations-routing.module';
import { UtilsModule } from '../utils/utils.module';
import { StepOneFormReservationComponent } from './components/step-one-form-reservation/step-one-form-reservation.component';
import { StepTwoFormReservationComponent } from './components/step-two-form-reservation/step-two-form-reservation.component';
import { DetailsOfAssociateSellerComponent } from './components/details-of-associate-seller/details-of-associate-seller.component';
import { ReasonOfReservationCancellationComponent } from './components/reason-of-reservation-cancellation/reason-of-reservation-cancellation.component';
import { AppLogoComponent } from './components/logo/logo.component';
import { AppInformationComponent } from './components/information/information.component';
import { AppPersonDetailsComponent } from './components/person-details/person-details.component';
import { AppVendorDetailsComponent } from './components/vendors-details/vendor-details.component';

@NgModule({
  imports: [
    CommonModule,
    ReservationsRoutingModule,
    UtilsModule,
    RegisterFormReservationComponent,
    StepOneFormReservationComponent,
    StepTwoFormReservationComponent,
    DetailsOfAssociateSellerComponent,
    ReasonOfReservationCancellationComponent,
    AppLogoComponent,
    AppInformationComponent,
    AppPersonDetailsComponent,
    AppVendorDetailsComponent,
  ],
})
export class ReservationsModule {}
