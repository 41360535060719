<h2 class="text-center" mat-dialog-title>
  {{ i18nService._(title) }}
</h2>

<mat-dialog-content class="px-5">
  <p>
    {{ i18nService._('Txt_ProspectAssociationPopin_Information', [data.lotNumber, data.lotPrice]) }}
  </p>
  <app-alert *ngIf="isNotDeveloper" [type]="alertEnum.INFO" [body]="'Txt_alert_private_data_developer'"></app-alert>

  <h4 class="mt-4 mb-1">
    {{ i18nService._('Txt_ProspectAssociationPopin_InformationPart1') }}
  </h4>

  <app-chip-populated-autocomplete
    *ngIf="prospectItems$ | async as prospectItems"
    [id]="'prospectId'"
    [title]="i18nService._('Txt_ProspectAssociationPopin_LookingForProspect')"
    [required]="false"
    [max]="1"
    [parentForm]="prospectSelectedForm"
    [items]="prospectItems"
    (valueChanged)="prospectSelected($event)"
    [sentenceIfNoItems]="i18nService._('Txt_ProspectAssociationPopin_NoProspect')"
  >
  </app-chip-populated-autocomplete>

  <ng-container>
    <h4 class="mt-4 mb-1">
      {{ i18nService._('Txt_ProspectAssociationPopin_InformationPart2') }}
    </h4>
    <form [formGroup]="prospectContactInformationForm">
      <app-step-one-form-prospect
        #prospectForm
        [parentForm]="prospectContactInformationForm"
        [prospectDetails]="prospectDetails"
        class="flex flex-col"
      >
      </app-step-one-form-prospect>
    </form>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions class="justify-center mb-1">
  <button
    type="button"
    class="my-3 mr-0 lg:mr-4 md:mr-4 sm:mr-4 lg:mb-0 sm:mb-0 md:mb-0"
    mat-stroked-button
    (click)="closeDialog(undefined)"
    color="primary"
  >
    {{ i18nService._('Txt_Button_Cancel') }}
  </button>
  <button
    class="my-3 lg:mb-0 sm:mb-0 md:mb-0"
    mat-flat-button
    (click)="submit()"
    color="primary"
    data-cy="submit-button-prospect-association"
  >
    {{ i18nService._('Txt_Button_Valid') }}
  </button>
</mat-dialog-actions>
