import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LegalNoticeComponent } from './components/legal-notice/legal-notice.component';
import { Sitemap } from '../utils/models/Sitemap';

const routes: Routes = [
  {
    path: Sitemap.utils.legalNotice.path,
    component: LegalNoticeComponent,
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
  declarations: [],
})
export class LegalNoticeRoutingModule {}
