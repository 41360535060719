<!-- Civility -->
<div class="text-base my-2 font-semibold">{{ i18nService._('Txt_Page_Form_RegisterCompany_Civility') }} *</div>
<div class="flex flex-row justify-start items-center mb-2 mt-2 min-h-80px">
  <app-radio-button-populated
    class="w-1/2"
    [referenceTable]="'Civilities'"
    [parentForm]="parentForm"
    [fieldName]="'civility'"
    [isSubmit]="isSubmit"
    [required]="true"
  ></app-radio-button-populated>
</div>

<!-- LastName + FristName -->
<app-name-information [isSubmit]="isSubmit" [parentForm]="parentForm"></app-name-information>
