import { IsDate, IsNumber, IsOptional } from 'class-validator';

import { ProspectDto } from '../dossier';

export class ProspectResponse extends ProspectDto {
  @IsNumber()
  @IsOptional()
  id?: number;

  @IsDate()
  @IsOptional()
  createdAt?: Date;
}
