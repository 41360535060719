import { Injectable } from '@angular/core';

import { AbstractAdvancedTrackingTableService } from './abstract-advanced-tracking-table.service';
import { AccountTrackingTableItem } from '../models/AccountTrackingTableItem';

@Injectable({
  providedIn: 'root',
})
export class AccountsTrackingTableService extends AbstractAdvancedTrackingTableService<AccountTrackingTableItem> {
  protected url = 'Accounts/tracking-table/accounts';
  protected cacheName = 'AccountsTrackingTableService';
}
