import { Directive, ElementRef, Host, HostListener, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[appDecimalMask]',
  standalone: true,
})
export class DecimalMaskDirective implements OnInit {
  /**
   * List of allowed decimal separators
   *
   * @type {Array<string>}
   * @memberof DecimalMaskDirective
   */
  private readonly allowedDecimalSeparators: Array<string> = ['.', ','];

  /**
   * allowedDecimalSeparatorsRegex attribute
   *
   * @type {RegExp}
   * @memberof DecimalMaskDirective
   */
  private allowedDecimalSeparatorsRegex: RegExp;

  /**
   * control attribute
   *
   * @type {AbstractControl}
   * @memberof DecimalMaskDirective
   */
  private control: AbstractControl;

  /**
   * element attribute
   *
   * @type {HTMLInputElement}
   * @memberof DecimalMaskDirective
   */
  private readonly element: HTMLInputElement;

  /**
   * mainRegex attribute
   *
   * @type {RegExp}
   * @memberof DecimalMaskDirective
   */
  private mainRegex: RegExp;

  /**
   * specialKeys attribute
   *
   * @type {Array<string>}
   * @memberof DecimalMaskDirective
   */
  private readonly specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Alt'];

  /**
   * thousandSeparatorRegex attribute
   *
   * @type {RegExp}
   * @memberof DecimalMaskDirective
   */
  private thousandSeparatorRegex: RegExp;

  /**
   * Define decimal separator to force it, default : '' to force no separator
   *
   * @type {string}
   * @memberof DecimalMaskDirective
   */
  @Input() decimalSeparator = '';

  /**
   * formControl attribute
   *
   * @type {FormControl}
   * @memberof DecimalMaskDirective
   */
  @Input() formControl: UntypedFormControl;

  /**
   * formControlName attribute
   *
   * @type {string}
   * @memberof DecimalMaskDirective
   */
  @Input() formControlName: string;

  /**
   * Max number of decimals
   *
   * @type {number}
   * @memberof DecimalMaskDirective
   */
  @Input() maxNumberDecimals = 2;

  /**
   * Max number of digits before separator
   *
   * @type {number}
   * @memberof DecimalMaskDirective
   */
  @Input() maxNumberDigitsBeforeSeparator = 6;

  /**
   * Thousand separator
   *
   * @type {string}
   * @memberof DecimalMaskDirective
   */
  @Input() thousandSeparator = ' ';

  /**
   * Creates an instance of DecimalMaskDirective.
   *
   * @param {ElementRef} elementRef
   * @param {ControlContainer} controlContainer
   * @memberof DecimalMaskDirective
   */
  constructor(
    private readonly elementRef: ElementRef,
    @Optional()
    @Host()
    @SkipSelf()
    private readonly controlContainer: ControlContainer,
  ) {
    this.element = this.elementRef.nativeElement;
  }

  /**
   * escapeStr method
   *
   * @param str
   * @memberof DecimalMaskDirective
   */
  private static escapeStr(str: string): string {
    return str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  }

  /**
   * Format value with thousand serparator and decimal separator if needed
   *
   * @param value
   * @memberof DecimalMaskDirective
   */
  private formatValue(value: number | string): string {
    if (value === undefined || value === null) {
      return '';
    }

    let intPart: string;
    let decimalPart: string;
    let separatorValue = '';

    // Remove previous format to calculate the new one
    const unformattedValue = this.unformatValue(value);

    this.allowedDecimalSeparators.forEach((separator) => {
      if (unformattedValue.includes(separator)) {
        separatorValue = separator;
        [intPart, decimalPart] = unformattedValue.split(separator);
      }
    });

    if (!separatorValue || separatorValue === '') {
      intPart = unformattedValue;
    }

    let formattedValue: string;
    if (intPart.length <= 3) {
      formattedValue = `${intPart}${separatorValue}`;
    } else {
      let thousandChunks = [];
      if (intPart.length % 3 !== 0) {
        thousandChunks.push(intPart.substring(0, intPart.length % 3));
      }
      thousandChunks = thousandChunks.concat(intPart.slice(intPart.length % 3).match(/.{1,3}/g));
      formattedValue = `${thousandChunks.length > 1 ? thousandChunks.join(this.thousandSeparator) : thousandChunks[0]}${separatorValue}`;
    }

    if (decimalPart) {
      formattedValue = `${formattedValue}${decimalPart}`;
    }

    return this.decimalSeparator !== '' ? formattedValue.replace('.', this.decimalSeparator) : formattedValue;
  }

  /**
   * Get number value of the current value
   *
   * @param value
   * @memberof DecimalMaskDirective
   */
  private getNumberValue(value: number | string): number | string {
    if (value === undefined || value === null || value === '') {
      return null;
    }

    return Number(value.toString().replace(this.thousandSeparatorRegex, '').replace(this.allowedDecimalSeparatorsRegex, '.'));
  }

  /**
   * Unformat current value
   *
   * @param value
   * @memberof DecimalMaskDirective
   */
  private unformatValue(value: number | string): string {
    if (value === undefined || value === null) {
      return undefined;
    }

    const unformattedValue = value.toString().replace(this.thousandSeparatorRegex, '');

    return this.decimalSeparator !== '' ? unformattedValue.replace(this.decimalSeparator, '.') : unformattedValue;
  }

  /**
   * updateAndFormatValue method
   *
   * @param value
   * @memberof DecimalMaskDirective
   */
  private updateAndFormatValue(value: number | string): void {
    const oldValueLength = this.element.value.length;
    let position = this.element.selectionStart;
    // Set view value using DOM value property
    this.element.value = this.formatValue(value);
    // Calculate position for the selector
    position = position + (this.element.value.length - oldValueLength);
    position = position > 0 ? position : 0;
    this.element.setSelectionRange(position, position);
    // Update module without emitting event and without changing view model
    this.control.setValue(this.getNumberValue(value), {
      emitEvent: false,
      emitModelToViewChange: false,
    });
  }

  /**
   * Init component
   *
   * @memberof DecimalMaskDirective
   */
  ngOnInit(): void {
    const remainder = this.maxNumberDigitsBeforeSeparator % 3;
    const division = Math.floor(this.maxNumberDigitsBeforeSeparator / 3);
    let mainRegexPattern = '^';
    if (division > 0) {
      for (let i = remainder === 0 ? 1 : 0; i < division; i++) {
        mainRegexPattern =
          i === 0
            ? `${mainRegexPattern}(\\d{0,${remainder}}${this.thousandSeparator})?`
            : `${mainRegexPattern}(\\d{0,3}${this.thousandSeparator})?`;
      }
      mainRegexPattern = `${mainRegexPattern}\\d{0,3}`;
    } else {
      mainRegexPattern = `${mainRegexPattern}\\d{0,${this.maxNumberDigitsBeforeSeparator}}`;
    }

    if (this.maxNumberDecimals > 0) {
      mainRegexPattern = `${mainRegexPattern}([${DecimalMaskDirective.escapeStr(this.allowedDecimalSeparators.join(''))}]\\d{0,${
        this.maxNumberDecimals
      }})?`;
    }

    mainRegexPattern = `${mainRegexPattern}$`;

    this.mainRegex = new RegExp(mainRegexPattern, 'g');
    this.allowedDecimalSeparatorsRegex = new RegExp(`[${DecimalMaskDirective.escapeStr(this.allowedDecimalSeparators.join(''))}]`, 'g');
    this.thousandSeparatorRegex = new RegExp(`[${DecimalMaskDirective.escapeStr(this.thousandSeparator)}]`, 'g');

    if (this.formControl) {
      this.control = this.formControl;
    } else if (this.controlContainer && this.formControlName) {
      this.control = this.controlContainer.control.get(this.formControlName);
    }

    if (!this.allowedDecimalSeparators.includes(this.decimalSeparator)) {
      this.decimalSeparator = '';
    }

    this.updateAndFormatValue(this.element.value);

    // Listen to input changes
    this.control.valueChanges.subscribe((value) => {
      this.updateAndFormatValue(value);
    });
  }

  /**
   * On key down, check the regex and cancel the event if does not match
   *
   * @param event
   * @memberof DecimalMaskDirective
   */
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.element.value;
    const position = this.element.selectionStart;
    const next: string = this.formatValue([current.slice(0, position), event.key, current.slice(position)].join(''));
    if (next && !String(next).match(this.mainRegex)) {
      event.preventDefault();
    }
  }
}
