import { CompleteDossierResponse, PaternityCreateResponse, StatusInsertPaternitiesResponse } from '@commons-dto/dossier-prospect';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { Subject, takeUntil } from 'rxjs';
import { ValueLabelItem } from 'apps/valo-front/src/app/prospects/models/ValueLabelItem';
import { NgIf } from '@angular/common';

import { PaternityService } from './../../../../prospects/services/paternity.service';
import { I18nService } from './../../../../utils/services/i18n.service';
import { DossierProspectService } from './../../../services/dossier-prospect.service';
import { DossierProspectFormlyService } from '../../../services/dossier-prospect-formly.service';
import { PaternityFormData } from '../../../model/forms/paternity-form-data.model';
import { SnackbarMessage } from '../../../../utils/models/SnackbarMessage';
import { SnackbarMessageType } from '../../../../utils/models/enums/snackbar-message-type.enum';
import { SnackbarService } from '../../../../utils/services/snackbar.service';

@Component({
  selector: 'app-paternity-quick-form',
  templateUrl: './paternity-quick-form.component.html',
  styleUrls: ['./paternity-quick-form.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, FormlyModule],
})
export class PaternityQuickFormComponent implements OnInit, OnDestroy {
  dossier: CompleteDossierResponse;
  fields: FormlyFieldConfig[];
  model = {};
  options: FormlyFormOptions = {};
  form = new UntypedFormGroup({});
  private readonly _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly _i18n: I18nService,
    private _formlyProspect: DossierProspectFormlyService,
    private prospectService: DossierProspectService,
    private paternityService: PaternityService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  ngOnInit(): void {
    this.prospectService.currentDossierSub
      .pipe(takeUntil(this._destroy$))
      .subscribe((dossier) => (this.dossier = dossier as CompleteDossierResponse));
    this.prospectService.getDevelopersFromMandate();
    this.fields = [this._formlyProspect.getStepPaternityProgram()];
  }

  submit() {
    if (this.form.valid) {
      const paternitiesFormData = this.createPaternitiesFromFormData(this.form.value.paternity_add_program);
      this.addPaternities(paternitiesFormData);
    }
  }

  private createPaternitiesFromFormData(paternities: ValueLabelItem[]): PaternityFormData[] {
    return paternities.map((paternity) => ({
      dossierId: this.dossier.id,
      paternity_add_company: this.form.value.paternity_add_company.id,
      paternity_add_program: paternity.value,
    }));
  }

  private addPaternities(paternitiesFormData: PaternityFormData[]): void {
    this.paternityService.create(paternitiesFormData).subscribe((response: PaternityCreateResponse) => {
      this.paternityService.getByPaternitiesIds(response.paternitiesIds).subscribe((paternitiesSummary) => {
        this.options.resetModel();
        this.dossier.summaryPaternities = [...paternitiesSummary, ...this.dossier.summaryPaternities];
        this.prospectService.updateLocalDossier(this.dossier);

        const message: SnackbarMessage = {
          type: this.getSnackbarMessageType(response.statusInsertPaternities),
          html: this.getSnackbarMessage(response.statusInsertPaternities),
          duration: 10000,
        };
        this.snackbarService.sendMessage(message);
      });
    });
  }

  private getSnackbarMessageType(statusInsertPaternity: StatusInsertPaternitiesResponse): SnackbarMessageType {
    if (statusInsertPaternity.error > 0) {
      return statusInsertPaternity.success > 0 ? SnackbarMessageType.Warning : SnackbarMessageType.Error;
    }
    return SnackbarMessageType.Info;
  }

  private getSnackbarMessage(statusInsertPaternity: StatusInsertPaternitiesResponse): string {
    const { error, success } = statusInsertPaternity;
    const pluralSuffix = (count: number) => (count > 1 ? 'ies' : 'y');
    const errorText = error > 0 ? this._i18n._(`Prospect_Paternit${pluralSuffix(error)}_Request_Error`, [error]) : '';
    const successText =
      success > 0
        ? `${this._i18n._(`Prospect_Paternit${pluralSuffix(success)}_Request_Success`, [success])} 
          ${this._i18n._(`Prospect_Paternit${pluralSuffix(success)}_Request_Detail`, [success])}`
        : '';
    return [errorText, successText].filter(Boolean).join('<br>');
  }
}
