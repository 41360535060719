<ng-container *ngIf="currentUrlAfterRedirectObservable$ | async as currentUrl">
  <div [ngClass]="checkIsHomepage(currentUrl) ? ' ' : ' shadow-md'" class="w-full fixed top-0 left-0">
    <div class="nav fixed flex flex-col w-full h-22 bg-white shadow-xl header">
      <div *ngIf="isConnected && (!isValo || isConnectionAs)" class="preheader w-full h-6">
        <!-- Connected as -->
        <ng-container *ngIf="!isValo && !isConnectionAs">
          <div class="flex justify-center items-center h-full w-full bg-gradient-to-r from-gradientOrangeFrom to-gradientOrangeTo">
            <p class="text-white text-xs text-center">
              Bonjour <b>{{ userFirstName }} {{ userLastName }}</b>
              {{ isContractor ? '(' + userCompanyLabel + ')' : '' }}
            </p>
          </div>
        </ng-container>

        <!-- isValo Connected as -->
        <ng-container *ngIf="isConnectionAs">
          <div class="flex justify-center items-center h-full w-full bg-gradient-to-r from-cyan-light to-cyan-valo">
            <p class="text-black text-xs text-center" data-cy="header">
              <span> {{ i18nService._('Txt_Placeholder_LoginAs') }} </span>
              <b>{{ userFirstName }} {{ userLastName }}</b>
              <b> - {{ isContractor ? '(' + userCompanyLabel + ')' : '' }}</b>
              <b> - {{ userEmail }} </b>
              <b>
                <span> - </span>
                <span
                  data-cy="menuAccountDisconnect"
                  (click)="logoutAs()"
                  class="opacity-80 hover:opacity-100 transition-opacity underline cursor-pointer"
                >
                  {{ i18nService._('Title_MenuAccountDisconnect') }}
                </span>
              </b>
            </p>
          </div>
        </ng-container>
      </div>

      <div class="nav-container h-full flex flex-row justify-between items-center py-2 px-4">
        <div class="logo h-full w-40 flex-shrink-0 flex items-center">
          <img
            (click)="backToHomepage()"
            alt="Valorissimo"
            class="w-full cursor-pointer"
            data-cy="logo-valo"
            src="assets/img/logo/LOGO_VALO_long.svg"
          />
        </div>

        <div *ngIf="isLoggedIn" class="h-full flex flex-row items-center">
          <app-drop-menu [menuItems]="menuConfigList"></app-drop-menu>

          <div
            *ngIf="canSeeProspectDeclarationButton"
            class="ml-2 mr-2 lg:mr-0 flex justify-center items-center rounded-full lg:w-auto lg:px-4 h-9 bg-gradient-to-r from-gradientOrangeFrom to-gradientOrangeTo"
          >
            <button
              (click)="skipProspectPopin || !dossierProspectEnabled ? createProspect() : openProspectModal()"
              id="prospect-registration"
              title="{{ i18nService._('Txt_Button_RegisterProspect') }}"
              data-cy="registerProspect"
              class="pb-1 min-w-[150px] min-h-[30px]"
            >
              <span class="text-xs ml-1 text-white uppercase font-bold text-xxs whitespace-nowrap">
                {{
                  dossierProspectEnabled ? i18nService._('Title_MenuDeclareProspect') : i18nService._('Title_Form_Prospect_DeclareProspect')
                }}
              </span>
            </button>
            <ng-container *ngIf="dossierProspectEnabled">
              <div class="prospectOtherMenuConfigList">
                <app-drop-menu *ngIf="otherMenuConfigList.length" [menuItems]="otherMenuConfigList"></app-drop-menu>
              </div>
            </ng-container>
          </div>

          <button (click)="displayModalPanel()" class="mobile-menu-button h-10 w-10 sm:hidden flex justify-center items-center">
            <app-icon size="1.25rem" class="text-red-900" name="Bars3"></app-icon>
          </button>
        </div>
      </div>

      <app-mobile-nav [menuItems]="menuConfigList" [otherMenuItems]="otherMenuConfigList"></app-mobile-nav>
    </div>
  </div>
</ng-container>
