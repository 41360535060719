<div class="grid grid-cols-2 gap-16 my-3">
  <!-- Latitude -->
  <mat-form-field class="col-span-1">
    <mat-label>{{ i18nService._('Txt_Placeholder_Latitude') }}</mat-label>
    <input type="text" name="latitude" [formControl]="latitudeControl" matInput data-cy="latitude" maxlength="10" required />
    <span class="icon-info txt-gray pr-2" matSuffix matTooltip="{{ i18nService._('Txt_Tooltip_LatitudeFormat') }}"></span>
    <mat-error> {{ getErrorMessage(latitudeControl) }}</mat-error>
  </mat-form-field>

  <!-- Longitude -->
  <mat-form-field class="col-span-1">
    <mat-label>{{ i18nService._('Txt_Placeholder_Longitude') }}</mat-label>
    <input type="text" name="longitude" [formControl]="longitudeControl" matInput data-cy="longitude" maxlength="10" required />
    <span class="icon-info txt-gray pr-2" matSuffix matTooltip="{{ i18nService._('Txt_Tooltip_LongitudeFormat') }}"></span>
    <mat-error> {{ getErrorMessage(longitudeControl) }}</mat-error>
  </mat-form-field>
</div>
