<div [class.right-full]="modalPanelCollapsed" class="mobile-nav flex flex-col absolute top-0 right-0 h-screen w-full max-w-lg bg-white">
  <div class="mobile-nav__header w-full h-16 py-2 px-4 shadow-sm">
    <div class="nav-container h-full flex flex-row justify-between items-center">
      <div class="logo h-full w-40 flex-shrink-0 flex items-center">
        <img class="h-full" src="assets/img/logo/LOGO_VALO_long.svg" />
      </div>
      <button (click)="toggleModalPanelCollapsed()" class="mobile-menu-button h-10 w-10 flex justify-center items-center">
        <app-icon class="'w-5 h-5 text-red-900'" name="XMark"></app-icon>
      </button>
    </div>
  </div>

  <div class="mobile-nav__body block flex-1 overflow-x-hidden overflow-y-auto pt-6 pb-12">
    <mat-accordion [hideToggle]="false" [multi]="false" displayMode="flat">
      <ng-container *ngFor="let menuItem of menuItems">
        <mat-expansion-panel class="shadow-none">
          <mat-expansion-panel-header>
            <app-icon [name]="menuItem.icon" class="w-5 h-5 mr-1"></app-icon>
            <!-- add feature for altIcon if action = active -->
            <!-- <app-icon [name]="menuItem.altIcon" class="w-5 h-5 lg:mr-1 text-orange-600"></app-icon> -->
            <span>{{ i18nService._(menuItem.label) }}</span>
          </mat-expansion-panel-header>

          <div>
            <ng-container *ngFor="let dropItem of menuItem.dropItems">
              <!-- to adapt for in-app routes -->
              <ng-container *ngIf="dropItem.externalLink && !dropItem.mustBeHidden">
                <a
                  [attr.href]="dropItem.externalLink"
                  class="menu-subitem cursor-pointer flex items-center border-b last:border-b-0 border-gray-100 w-full whitespace-nowrap py-3 hover:text-orange-500"
                >
                  <span class="font-semibold text-sm" ngClass="{{ dropItem.twTxtColor }}"> {{ i18nService._(dropItem.label) }}</span>
                  <app-icon
                    *ngIf="dropItem.externalLink"
                    class="text-gray-400 w-4 h-4 ml-1"
                    name="ArrowTopRightOnSquare"
                    type="outline"
                  ></app-icon>
                </a>
              </ng-container>
              <ng-container *ngIf="dropItem.path && !dropItem.mustBeHidden">
                <a
                  (click)="dropItem.action ? dropItem.action() : undefined"
                  [routerLink]="dropItem.path"
                  class="menu-subitem cursor-pointer flex items-center border-b last:border-b-0 border-gray-100 w-full whitespace-nowrap py-3 hover:text-orange-500"
                >
                  <span class="font-semibold text-sm" ngClass="{{ dropItem.twTxtColor }}"> {{ i18nService._(dropItem.label) }}</span>
                  <app-icon
                    *ngIf="dropItem.externalLink"
                    class="text-gray-400 w-4 h-4 ml-1"
                    name="ArrowTopRightOnSquare"
                    type="outline"
                  ></app-icon>
                </a>
              </ng-container>
              <ng-container *ngIf="!dropItem.path && dropItem.action && !dropItem.mustBeHidden">
                <div
                  (click)="dropItem.action()"
                  class="menu-subitem cursor-pointer flex items-center border-b last:border-b-0 border-gray-300 w-full whitespace-nowrap py-2 hover:text-orange-500"
                >
                  <span class="font-semibold text-sm" ngClass="{{ dropItem.twTxtColor }}"> {{ i18nService._(dropItem.label) }}</span>
                  <app-icon
                    *ngIf="dropItem.externalLink"
                    class="text-gray-400 w-4 h-4 ml-1"
                    name="ArrowTopRightOnSquare"
                    type="outline"
                  ></app-icon>
                </div>
              </ng-container>
              <ng-container *ngIf="dropItem.contacts">
                <ng-container *ngFor="let contact of dropItem.contacts">
                  <div class="contact items-center border-b last:border-b-0 border-gray-300 uppercase font-bold">
                    <p>{{ i18nService._(contact.label) }}</p>
                    <p>{{ contact.phone }}</p>
                    <a href="mailto:{{ contact.email }}">{{ contact.email }}</a>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </mat-expansion-panel>
      </ng-container>

      <ng-container *ngFor="let menuItem of otherMenuItems">
        <mat-expansion-panel class="shadow-none">
          <mat-expansion-panel-header>
            <app-icon [name]="menuItem.icon" class="w-5 h-5 mr-1"></app-icon>
            <span>{{ i18nService._(menuItem.label) }}</span>
          </mat-expansion-panel-header>
          <div>
            <ng-container *ngFor="let dropItem of menuItem.dropItems">
              <ng-container>
                <div
                  (click)="dropItem.action()"
                  class="menu-subitem cursor-pointer flex items-center border-b last:border-b-0 border-gray-300 w-full whitespace-nowrap py-2 hover:text-orange-500"
                >
                  <span class="font-semibold text-sm" ngClass="{{ dropItem.twTxtColor }}"> {{ i18nService._(dropItem.label) }}</span>
                  <app-icon class="text-gray-400 w-4 h-4 ml-1" name="ArrowTopRightOnSquare"></app-icon>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
</div>
