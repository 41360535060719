<ng-template #defaultTemplate let-dataContent>{{ dataContent }}</ng-template>

<mat-accordion multi="true">
  <mat-expansion-panel class="rounded-none shadow-none border-t border-white" [expanded]="open">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ i18nService._(title) }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container [ngTemplateOutlet]="optionTemplate || defaultTemplate" [ngTemplateOutletContext]="{ $implicit: dataContent }">
    </ng-container>
  </mat-expansion-panel>
</mat-accordion>
