import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';
import { Observable, of } from 'rxjs';

import { DataCheckSecondaryLotRefNumberValidity } from '../models/DataCheckSecondaryLotRefNumberValidity';

import { ReferenceTableData } from '../../utils/models/ReferenceTableData';
import { SecondaryLotResponse } from '../../utils/models/SecondaryLotResponse';
import { AppConfigService } from '../../utils/services/app-config.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';
import { I18nService } from '../../utils/services/i18n.service';
import { SecondaryLotTypeApiService } from '../../adapters/secondary-lot-type-api.service';
import { SecondaryLotTypeResponse } from '../../utils/models/SecondaryLotTypeResponse';

@Injectable({
  providedIn: 'root',
})
export class SecondaryLotService {
  private secondaryLotTypes: SecondaryLotTypeResponse[];

  secondaryLotTypeList: ReferenceTableData;

  /**
   * Creates an instance of SecondaryLotService.
   * @param {HttpClient} http
   * @param {AppConfigService} appConfigService
   * @param {ErrorHandlerService} errorHandlerService
   * @param {I18nService} i18nService
   * @memberof SecondaryLotService
   */
  constructor(
    private readonly http: HttpClient,
    private readonly appConfigService: AppConfigService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly i18nService: I18nService,
    private readonly appConfig: AppConfigService,
    private readonly secondaryLotTypeApiService: SecondaryLotTypeApiService,
  ) {
    this.secondaryLotTypeList = undefined;
  }

  /**
   * getSecondaryLotTypeLabel method, put in argument the id in table, the method
   * get the list of id|label
   *
   * @param {*} id
   * @returns {Observable<string>}
   * @memberof SecondaryLotService
   */
  getSecondaryLotTypeLabel(id): Observable<string> {
    if (!this.secondaryLotTypeList) {
      const url = `${this.appConfigService.appConfig.loopbackApiUrl}/SecondaryLotTypes`;

      return this.http.get<ReferenceTableData>(url).pipe(
        tap((response) => (this.secondaryLotTypeList = response)),
        map(() => this.getLabel(id, this.secondaryLotTypeList)),
        catchError(this.errorHandlerService.handleError<string>('utilsCompanyService', 'getCompanyTypeList')),
      );
    }

    return of(this.getLabel(id, this.secondaryLotTypeList));
  }

  isValidRefAndNumberOfSecondaryLot(
    secondaryLotNumber: string,
    secondaryLotRef: string,
    secondaryLotId: number,
    programId: number,
    secondaryLotTypeId: number,
  ): Observable<boolean> {
    const url = `${this.appConfigService.appConfig.loopbackApiUrl}/Lots/isValidRefAndNumberOfSecondaryLot`;

    const data: DataCheckSecondaryLotRefNumberValidity = {
      secondaryLotNumber,
      secondaryLotRef,
      secondaryLotId,
      programId,
      secondaryLotTypeId,
    };

    return this.http
      .post<boolean>(url, data)
      .pipe(catchError(this.errorHandlerService.handleError<boolean>('secondaryLotService', 'isValidRefAndNumberOfSecondaryLot')));
  }

  /**
   * getLabel method, put in argument id and list of id|label
   *
   * @param {*} id
   * @returns {string}
   * @memberof SecondaryLotService
   */
  getLabel(id, secondaryLotTypeList): string {
    // loop on the secondaryLotTypeList
    for (const type of secondaryLotTypeList) {
      if (type.id === id) {
        return this.i18nService._(type.label);
      }
    }
    // if id not corresponding to a label

    return undefined;
  }

  /**
   * Delete a Secondary Lot
   * @param secondaryLotId
   */
  deleteSecondaryLot(secondaryLotId: number): Observable<SecondaryLotResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/SecondaryLots/${secondaryLotId}`;

    return this.http
      .delete<SecondaryLotResponse>(url)
      .pipe(catchError(this.errorHandlerService.handleError<SecondaryLotResponse>('secondaryLotService', 'deleteSecondaryLot')));
  }

  public async getSecondaryLotTypes(): Promise<SecondaryLotTypeResponse[]> {
    if (!this.secondaryLotTypes) {
      await this.loadSecondaryLotTypes();
    }
    return this.secondaryLotTypes;
  }

  private async loadSecondaryLotTypes(): Promise<void> {
    this.secondaryLotTypes = await this.secondaryLotTypeApiService.getSecondaryLotTypes();
  }
}
