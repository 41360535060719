<div>
  <div class="mb-3">
    <button data-cy="search" mat-flat-button color="primary" class="w-full" (click)="searchClick.emit()">
      {{ i18nService._('Txt_Button_Search') }}
    </button>
  </div>

  <div class="mb-3">
    <button mat-stroked-button color="primary" class="w-full" (click)="resetClick.emit()">
      {{ i18nService._('Txt_Button_ReinitiateCriteria') }}
    </button>
  </div>

  <!-- VALO-4029: temporary desactivation for saved search -->
  <!-- <div class="mb-3">
    <button mat-stroked-button color="primary" class="w-full text-sm" (click)="saveSearchClick.emit()">
      {{ i18nService._('Txt_Button_SaveSearch') }}
    </button>
  </div>

  <div *ngIf="isSavedSearch" class="mb-3">
    <button [disabled]="!enableEditSearch" mat-stroked-button color="primary" class="w-full text-sm" (click)="editSearchClick.emit()">
      {{ i18nService._('Txt_Button_EditSearch') }}
    </button>
  </div> -->
</div>
