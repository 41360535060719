import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterLinkActive, RouterLink } from '@angular/router';
import { catchError, interval, Observable, switchMap, takeWhile, tap } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';

import { FormHeaderComponent } from '../form-header/form-header.component';
import { SideInfoComponent } from '../side-info/side-info.component';

import { GoogleTagManagerService } from '../../../utils/services/google-tag-manager.service';
import { Step, StepStatus } from '../../../common/shared/model/Progress';
import { I18nService } from '../../../utils/services/i18n.service';
import { FormViewResponse, Status } from '../../models/FormResponse';
import { Signature } from '../../models/Signature';
import { SignatureService } from '../../services/signature.service';
import { UserRoleService } from '../../../utils/services/user-role.service';
import { Sitemap } from '../../../utils/models/Sitemap';
import { SignatureDocumentService } from '../../services/signature-document.service';
import { AccountUtilsService } from '../../../accounts/services/account-utils.service';
import { SignatureType } from '../../models/signature-type.enum';
import { DaysFromNow } from '../../../utils/pipes/days-from-now.pipe';
import { IconComponent } from '../../../common/standalone/icon/icon.component';
import { VerticalProgressComponent } from '../../../common/shared/vertical-progress/vertical-progress.component';
import { BoxBackButtonComponent } from '../../../common/shared/box-back-button/box-back-button.component';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';

@Component({
  templateUrl: './signature-progress.component.html',
  styleUrls: ['./signature-progress.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    BoxBackButtonComponent,
    SideInfoComponent,
    FormHeaderComponent,
    NgClass,
    VerticalProgressComponent,
    RouterLinkActive,
    RouterLink,
    IconComponent,
    MatProgressSpinnerModule,
    AsyncPipe,
    DaysFromNow,
  ],
})
export class SignatureProgressComponent implements OnInit {
  SignatureType = SignatureType;
  formView$: Observable<FormViewResponse>;
  dossierId: number;
  signatureProgress$: Observable<Signature>;
  signature: Signature;
  valoDone = false;
  developerDone = false;
  developerCurrent = false;
  buyerDone = false;
  allOK = false;
  notOK = false;
  sendStepDone = false;
  hasError: boolean;
  downloading = false;
  isExpired: boolean;
  showExpire = false;
  private currentStep: Step;
  private dossier: FormViewResponse;
  canCancel: boolean;

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _signatureService: SignatureService,
    public readonly _i18nService: I18nService,
    public readonly _userRoleService: UserRoleService,
    private readonly _documentService: SignatureDocumentService,
    public dialog: MatDialog,
    private readonly _account: AccountUtilsService,
    private readonly router: Router,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly snackBarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.dossierId = this._route.snapshot.params.signatureId;

    this.loadDossier();
    this.formView$ = this._signatureService.formViewDossier$;
    this.formView$.subscribe((dossier) => {
      if (!dossier) return; // FIXME: pourquoi on recoit un subscribe avec dossier = NULL avant de recevoir le bon
      this.dossier = dossier;
      this.isExpired = this.dossier.status === Status.EXPIRED;
    });

    this.signatureProgress$ = this.getSignatureSteps(false).pipe(
      tap(() => {
        if (!this.sendStepDone) {
          this.reloadSignatureSteps();
        }
      }),
    );
  }

  loadDossier(): void {
    this._signatureService.getFormViewByDossierId(this.dossierId);
  }

  mapSteps(signature: Signature) {
    this.allOK = true;
    this.notOK = false;
    signature.steps.forEach((step) => {
      if (step.status === StepStatus.INVALID || step.status === StepStatus.VALID) {
        switch (step.title) {
          case 'valo':
            this.valoDone = true;
            break;
          case 'buyer':
            this.buyerDone = true;
            if (step.status == StepStatus.INVALID && step?.events) {
              for (const event of step.events) {
                if (event.value.key == 'buyer_declined') {
                  this.showExpire = false;
                }
              }
            }
            break;
          case 'developer':
            this.developerDone = true;
            if (step.status == StepStatus.INVALID && step?.events) {
              for (const event of step.events) {
                if (event.value.key == 'developer_declined') {
                  this.showExpire = false;
                }
              }
            }
            break;
          default:
            break;
        }
      }
      if (step.status === StepStatus.CURRENT) {
        this.currentStep = step;
      }
      if (step.status !== StepStatus.VALID) {
        this.allOK = false;
      }
      if (step.status === StepStatus.INVALID) {
        this.notOK = true;
        this.showExpire = false;
      }
      this.addFunctionsToActions(step);

      this.formatStepNames(step);
    });
    this.developerCurrent = this.currentStep?.title === 'developer';
    return signature;
  }

  recalculateCancel(): boolean {
    return !this.allOK && this.isUserCanCancel() && this.isDossierCanBeCancel();
  }

  formatStepNames(step) {
    if (step.events) {
      for (const event of step.events) {
        if (event['label']) {
          for (const i in event['label']['value']) {
            event['label']['value'][i] = this.formatName(event['label']['value'][i]);
          }
        }
      }
    }
  }

  formatName(name) {
    if (typeof name != 'string') throw new Error('Input must be a string');
    const trimemedName = name.trim();
    if (trimemedName.length === 0) return '';

    return trimemedName
      .split(' ')
      .map((field) => {
        if (field.length > 0) {
          return field[0].toUpperCase() + field.slice(1).toLowerCase();
        }
        return field;
      })
      .join(' ');
  }

  addFunctionsToActions(step: Step) {
    if (step.action && step.action.length > 0) {
      for (const action of step.action) {
        switch (action.key) {
          case 'remind':
            action.function = this.getRemindAction();
            break;
          case 'info':
            action.function = this.getInfoAction();
        }
      }
    }
  }

  getRemindAction() {
    return null;
  }

  getInfoAction() {
    return null;
  }

  getSignatureSteps(reloadDossier: boolean) {
    this.hasError = true;
    return this._signatureService.getSignatureProgress(this.dossierId).pipe(
      tap((signature) => {
        this.sendStepDone = signature.steps[0].status.toLowerCase() !== StepStatus.CURRENT.toLowerCase();
        this.showExpire = signature.type !== SignatureType.PAPER;
        if (reloadDossier && this.sendStepDone) {
          this.loadDossier();
          if (signature.steps[0].status.toLowerCase() === StepStatus.VALID.toLowerCase()) {
            this.snackBarService.message(this._i18nService._('txt_signature_type_success'), SnackbarMessageType.Success);
          } else if (signature.steps[0].status.toLowerCase() === StepStatus.INVALID.toLowerCase()) {
            this.snackBarService.message(this._i18nService._('txt_signature_type_error'), SnackbarMessageType.Error);
          }
        }
        signature = this.mapSteps(signature);

        this.signature = signature;
        this.canCancel = this.recalculateCancel();
      }),
      catchError((err) => {
        this.hasError = true;
        throw err;
      }),
    );
  }

  reloadSignatureSteps() {
    interval(30000)
      .pipe(
        switchMap(() => this.getSignatureSteps(true)),
        takeWhile(() => !this.sendStepDone),
      )
      .subscribe(() => {
        return;
      });
  }

  openCancelAndModfifyModal() {
    this._googleTagManagerService.pushTag({ event: 'esignature_cancelation' });
    this._signatureService.openCancelAndModfifyModal(this.dossier.status, this.signature.id);
  }

  getSynthesisLink() {
    return `/${Sitemap.signature.synthesis.path.replace(':signatureId', this.dossierId.toString())}`;
  }

  async downloadSignedContract() {
    if (!this.downloading) {
      this._googleTagManagerService.pushTag({ event: 'esignature_download' });
      this.downloading = true;
      const signedContract = this.dossier.documents.find((doc) => doc.documentTypeCode === 'SC');
      await this._documentService.downloadBundleDocuments(signedContract);
      this.downloading = false;
    }
  }

  goBack(): () => void {
    return () => {
      this.router.navigate([Sitemap.dashboards.dossier.path.replace(/:signatureId/, this.dossierId.toString())]);
    };
  }

  private isUserCanCancel(): boolean {
    return this._userRoleService.isInRoles([
      ...this._userRoleService.valoSysRoles,
      ...this._userRoleService.valoAdminRoles,
      ...this._userRoleService.valoRcvRoles,
      ...this._userRoleService.contractorRoles,
      ...this._userRoleService.independantRoles,
    ]);
  }

  private isDossierCanBeCancel(): boolean {
    if (this.dossier.status === Status.SIGN_ACQ) {
      return !!this.signature?.contractId;
    }
    return [
      Status.SIGN_ACQ,
      Status.REJECT_ACQ,
      Status.COUNTERSIGN,
      Status.REJECT_DEV,
      Status.ERROR,
      Status.EXPIRED,
      Status.SIGN_PAPER,
    ].includes(this.dossier.status);
  }
}
