import { Pipe, PipeTransform } from '@angular/core';

import { I18nService } from '../services/i18n.service';

@Pipe({
  name: 'translate',
  standalone: true,
})
export class TranslatePipe implements PipeTransform {
  constructor(public i18nService: I18nService) {}

  transform(txt: string, param?: string): string {
    return this.i18nService._(txt, param && [param]);
  }
}
