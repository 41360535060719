import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

import { ChipPopulatedComponent } from '../chip-populated/chip-populated.component';

import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-chip-populated-with-other',
  templateUrl: './chip-populated-with-other.component.html',
  styleUrls: ['./chip-populated-with-other.component.scss'],
  standalone: true,
  imports: [ChipPopulatedComponent, NgIf, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule],
})
export class ChipPopulatedWithOtherComponent implements OnInit {
  /**
   * Input referenceTable to get tables informations
   * @type {string}
   * @memberof ChipPopulatedWithOtherComponent
   */
  @Input() referenceTable: string;

  /**
   * Parent field name
   *
   * @type {string}
   * @memberof ChipPopulatedWithOtherComponent
   */
  @Input() fieldName: string;

  /**
   * Field name where it is used
   *
   * @type {FormGroup}
   * @memberof ChipPopulatedWithOtherComponent
   */
  @Input() parentForm: UntypedFormGroup;

  /**
   * Get submit from parentForm
   *
   * @type {boolean}
   * @memberof ChipPopulatedWithOtherComponent
   */
  @Input() isSubmit: boolean;

  /**
   * ParentForm to add controls to
   *
   * @type {FormGroup}
   * @memberof RadioButtonPopulatedComponent
   */

  @Input() dataToInit;

  /**
   *  Field name for 'OTHER' input field
   *
   * @type {string}
   * @memberof ChipPopulatedWithOtherComponent
   */
  public fieldNameOther: string;

  /**
   * Control on input field if 'OTHER' is selected
   *
   * @type {FormControl}
   * @memberof ChipPopulatedWithOtherComponent
   */
  public otherControl: FormControl;

  /**
   * Boolean know if user choosed 'Other' or not
   *
   * @type {boolean}
   * @memberof ChipPopulatedWithOtherComponent
   */
  public isOther: boolean;

  /**
   * Creates an instance of ChipPopulatedWithOtherComponent.
   * @param {I18nService} i18nService
   * @memberof ChipPopulatedWithOtherComponent
   */
  constructor(public i18nService: I18nService) {}

  /**
   * Init method
   *
   * @memberof ChipPopulatedWithOtherComponent
   */
  ngOnInit(): void {
    // Set radio field name
    this.fieldNameOther = `${this.fieldName}Other`;
    // Add controls on fields
    this.otherControl =
      this.dataToInit && this.dataToInit[this.fieldNameOther]
        ? new UntypedFormControl(this.dataToInit[this.fieldNameOther], [Validators.required])
        : new UntypedFormControl();
    this.parentForm.addControl(this.fieldNameOther, this.otherControl);
  }

  /**
   * Check if 'OTHER' has been selected and setValue of the chip list
   *
   * @param {*} event
   * @memberof ChipPopulatedWithOtherComponent
   */
  chipValueChanged(event): void {
    if (event === this.i18nService._('OTHER')) {
      this.isOther = true;
      this.otherControl.setValidators([Validators.required]);
    } else {
      this.isOther = false;
      this.otherControl.setValidators([]);
      this.otherControl.reset();
    }
  }
}
