import { Component, Input, ViewChild } from '@angular/core';
import { AbstractControl, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatBadgeModule } from '@angular/material/badge';
import { NgClass } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ColDef, GridOptions, IRowNode, IsRowSelectable } from 'ag-grid-community';

import { GridTrackingTableComponent } from '../grid-tacking-table/grid-tracking-table.component';

import { TableLoader } from '../../../table/interfaces/DataLoader';
import { TableCheckboxShow } from '../../../table/enums/table-checkbox-show.enum';
import { I18nService } from '../../../utils/services/i18n.service';

@Component({
  selector: 'app-selection-tracking-table',
  templateUrl: './selection-tracking-table.component.html',
  styleUrls: ['./selection-tracking-table.component.scss'],
  standalone: true,
  imports: [MatButtonToggleModule, FormsModule, NgClass, MatBadgeModule, MatFormFieldModule, MatInputModule, GridTrackingTableComponent],
})
export class SelectionTrackingTableComponent {
  @Input()
  columnDefs: ColDef[];

  @Input()
  rowsControl: AbstractControl;

  @Input()
  tableLoader: TableLoader<any>;

  @Input()
  pageSize: number;

  @Input()
  tableHeight: number;

  @Input()
  preSelectedRows: any[];

  @Input()
  isRowSelectable: IsRowSelectable = () => {
    return true;
  };

  @ViewChild(GridTrackingTableComponent)
  gridTrackingTable: GridTrackingTableComponent;

  gridOptions: GridOptions = {
    headerHeight: 45,
    rowHeight: 60,
    paginationPageSize: 90,
    paginationPageSizeSelector: false,
    rowModelType: 'clientSide',
    domLayout: 'autoHeight',
    rowSelection: 'multiple',
    suppressCellFocus: true,
    rowMultiSelectWithClick: true,
    enableCellTextSelection: true,
    isExternalFilterPresent: this.isExternalFilterPresent.bind(this),
    doesExternalFilterPass: this.doesExternalFilterPass.bind(this),
  };

  showCheckboxSelection: TableCheckboxShow = TableCheckboxShow.ALL; // default value
  TableCheckboxShow = TableCheckboxShow;

  rowsIdChecked: number[] = [];
  rowsIdNotChecked: number[] = [];
  rowsId: number[] = [];

  externalFilter: any;
  textFilter: string;

  constructor(public readonly i18nService: I18nService) {}

  //get selected rows for filter
  setSelectedRows(rows: any): void {
    this.rowsIdChecked = rows.map((row) => row.data.id);
    this.refreshSelectedRows();
    this.gridTrackingTable.gridApi.onFilterChanged();
  }

  refreshSelectedRows() {
    this.rowsIdNotChecked = this.rowsId.filter((rowId) => !this.rowsIdChecked.find((id) => id == rowId));
    this.rowsControl.setValue(this.rowsIdChecked);
  }

  //get all rows for filter
  getRows(rows: any): void {
    this.rowsId = rows.map((row) => row.id);
    this.refreshSelectedRows();
  }

  onShowCheckboxSelectionChange(event: TableCheckboxShow): void {
    this.externalFilter = event;
  }

  doesExternalFilterPass(node: IRowNode<any>): boolean {
    switch (this.showCheckboxSelection) {
      case TableCheckboxShow.ALL:
        return true;
      case TableCheckboxShow.SELECTED:
        return this.rowsIdChecked.indexOf(node.data.id) >= 0;
      case TableCheckboxShow.NOT_SELECTED:
        return this.rowsIdChecked.indexOf(node.data.id) === -1;
      default:
        return true;
    }
  }

  isExternalFilterPresent(): boolean {
    return true;
  }

  public reset(): void {
    this.gridTrackingTable.gridApi.deselectAll();
  }
}
