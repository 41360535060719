import { Pipe, PipeTransform } from '@angular/core';
import { StatusTagColor } from 'apps/valo-front/src/app/design-system/model/status-tag-color.enum';
import { TransactionStatus } from '@commons-dto/dossier-prospect';

@Pipe({
  name: 'transactionStatusColor',
  standalone: true,
})
export class TransactionStatusColorPipe implements PipeTransform {
  transform(statusLabel: TransactionStatus): string {
    switch (statusLabel) {
      case TransactionStatus.OPTION_PENDING:
        return StatusTagColor.IN_PROGRESS;
      case TransactionStatus.OPTION_ACCEPTED:
        return StatusTagColor.SUCCESS;
      case TransactionStatus.OPTION_EXPIRED:
        return StatusTagColor.CRITICAL;
      case TransactionStatus.OPTION_REFUSED:
        return StatusTagColor.CRITICAL;
      case TransactionStatus.PRERESERVED:
        return StatusTagColor.SUCCESS;
      case TransactionStatus.PRERESERVATION_CANCELED:
        return StatusTagColor.CRITICAL;
      case TransactionStatus.PRERESERVATION_REJECTED:
        return StatusTagColor.CRITICAL;
      case TransactionStatus.RECEIVED_VALO:
        return StatusTagColor.IN_PROGRESS;
      case TransactionStatus.NOTARY_SIGNATURE_OBTAINED:
        return StatusTagColor.SUCCESS;
      case TransactionStatus.LOAN_OFFER_OBTAINED:
        return StatusTagColor.SUCCESS;
      case TransactionStatus.AGREEMENT_OBTAINED:
        return StatusTagColor.IN_PROGRESS;
      case TransactionStatus.REJECTED_DEVELOPER:
        return StatusTagColor.CRITICAL;
      case TransactionStatus.CLIENT_DESISTED:
        return StatusTagColor.CRITICAL;
      case TransactionStatus.PENDING:
        return StatusTagColor.IN_PROGRESS;
      case TransactionStatus.SENT_DEVELOPER:
        return StatusTagColor.IN_PROGRESS;
      case TransactionStatus.VALIDATED_VALO:
        return StatusTagColor.IN_PROGRESS;
      case TransactionStatus.VALIDATED_DEVELOPER:
        return StatusTagColor.SUCCESS;
      case TransactionStatus.NOTARY_MEETING_OBTAINED:
        return StatusTagColor.IN_PROGRESS;
      case TransactionStatus.RESERVATION_CANCELED:
        return StatusTagColor.CRITICAL;
      case TransactionStatus.NOTIFICATION_SRU_IN_PROGRESS:
        return StatusTagColor.IN_PROGRESS;
      case TransactionStatus.NOTIFICATION_SRU_PURGED:
        return StatusTagColor.IN_PROGRESS;
      case TransactionStatus.RESERVATION_IN_SIGNATURE:
        return StatusTagColor.IN_PROGRESS;
      default:
        return StatusTagColor.SUCCESS;
    }
  }
}
