import { Type } from '@angular/core';

import { SearchPanelTypeEnum } from './search-panel-type-enum';
import { SearchRoomsComponent } from '../components/filters/search-rooms/search-rooms.component';
import { SearchTaxationComponent } from '../components/filters/search-taxation/search-taxation.component';

export const SEARCH_PANEL_COMPONANT_BY_TYPE: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [keys in SearchPanelTypeEnum]?: Type<any>;
} = {
  [SearchPanelTypeEnum.ROOM]: SearchRoomsComponent,
  [SearchPanelTypeEnum.FISCALITY]: SearchTaxationComponent,
};

export const DEFAULT_SEARCH_PROGRAM_PARAMS = Object.freeze({
  bgtmi: 0,
  bgtma: 600000,
  bbox: '',
  priority: 'filters',
  order: 'price',
  direction: 1,
  type: 'P',
});
