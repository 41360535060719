<div class="lot-detail-navigation">
  <app-program-page-navigation-header
    [goBack]="goBack()"
    [i18nService]="i18nService"
    [backLabel]="'Txt_page_program_Detail_Lot_header_back'"
    [lot]="lot"
    [programId]="pageProgramHelperService.program.programId"
  >
  </app-program-page-navigation-header>
</div>
<hr />
<ng-container *ngIf="lot; else noLotTemplate">
  <div class="lot-detail-header">
    <app-program-page-lot-header></app-program-page-lot-header>
  </div>
  <div class="lot-detail-content">
    <app-program-page-lot-detail></app-program-page-lot-detail>
  </div>
</ng-container>
<ng-template #noLotTemplate>
  <div class="lot-not-available">
    {{ i18nService._('Txt_page_program_Detail_Lot_unavailable') }}
  </div>
</ng-template>
