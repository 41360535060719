import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { debounce, fromEvent, map, Observable, takeUntil } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { interval } from 'rxjs/internal/observable/interval';
import { ActivatedRoute } from '@angular/router';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';

import { AbstractSearchFilter } from '../abstract-search-filter';
import { SearchLocalisationListComponent } from './search-localisation-list/search-localisation-list.component';

import { SearchPanelTypeEnum } from '../../../model/search-panel-type-enum';
import { LOCALISATION_LABELS, propertiesLocalisation } from '../../../../utils/models/app-constant';
import { CitiesLocationData } from '../../../../utils/models/CitiesLocationData';
import { CitiesLocationResponse } from '../../../../utils/models/CitiesLocationResponse';
import { LocationData } from '../../../../utils/models/LocationData';
import { mapHttpParams } from '../../../../utils/enums/map.enum';
import { CityService } from '../../../../utils/services/city.service';
import { EventUtilsService } from '../../../../utils/services/event/event-utils.service';
import { I18nService } from '../../../../utils/services/i18n.service';
import { CityResponse } from '../../../../utils/models/CityResponse';
import { DepartmentLocationResponse } from '../../../../utils/models/DepartmentLocationResponse';
import { RegionLocationResponse } from '../../../../utils/models/RegionLocationResponse';
import { AgglomerationLocationResponse } from '../../../../utils/models/AgglomerationLocationResponse';

type baseType = CityResponse | DepartmentLocationResponse | RegionLocationResponse | AgglomerationLocationResponse;

type locationType = baseType & { key: string; typeLocation: string; id: string; zipcode: string; code: string };

@Component({
  selector: 'app-search-localisation',
  templateUrl: './search-localisation.component.html',
  styleUrls: ['./search-localisation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    MatOptionModule,
    SearchLocalisationListComponent,
    AsyncPipe,
  ],
})
export class SearchLocalisationComponent extends AbstractSearchFilter implements AfterViewInit {
  public selected: boolean;
  public locationLabelised = '';
  public locationSearchForm: UntypedFormControl;
  public mapListFilteredItems: Map<string, locationType> = new Map();
  public filteredItems: Array<string>;
  @Input() open: boolean;
  @ViewChild('inputElement', { static: true })
  inputElement: ElementRef<HTMLInputElement>;
  showPanel: boolean;
  private readonly searchLocationInputDataLabelId = 'searchLocationInputDataLabel';
  private _afterViewInit: boolean;

  constructor(
    public readonly i18nService: I18nService,
    private readonly _eventUtilsService: EventUtilsService,
    private readonly eRef: ElementRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly cityService: CityService,
    private readonly _route: ActivatedRoute,
  ) {
    super();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  public mapHttpParams = mapHttpParams;
  public isMapPrior$: Observable<boolean>;

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    super.ngOnInit();
    this.selected = !this.titelUp && (this.open || false);
    this.locationSearchForm = new UntypedFormControl();
    this.isMapPrior$ = this._route.queryParamMap.pipe(map((params) => params.get('priority') === mapHttpParams.MAP));
    this.locationSearchForm.valueChanges
      .pipe(
        debounce(() => interval(200)),
        takeUntil(this.$d),
      )
      .subscribe((value) => {
        if (value) {
          const valueTrim = value.trim();
          // If digit 2 caracts authorized, 3 for string
          if ((isNaN(parseInt(valueTrim, 10)) && valueTrim.length >= 3) || (!isNaN(parseInt(valueTrim, 10)) && valueTrim.length >= 2)) {
            const filterData = new CitiesLocationData();
            filterData.filter = value.trim();
            filterData.typeAccepted = {
              agglomerations: true,
              cities: true,
              departments: true,
              regions: true,
            };
            this.cityService.searchLocalisation(filterData).subscribe((itemsFound) => {
              this.formatLocationResponseList(itemsFound);
              this.filteredItems = Array.from(this.mapListFilteredItems.keys());
              this._markForCheck(this.changeDetectorRef);
            });
          }
        }
      });
  }

  public formatLocationResponseList(locationResponseList: CitiesLocationResponse): void {
    this.mapListFilteredItems.clear();
    Object.keys(locationResponseList).forEach((prop) => {
      let locationType = '';
      locationResponseList[prop].forEach((element) => {
        element.typeLocation = prop;
        if (prop === propertiesLocalisation.department) {
          locationType = ` (${element.code})`;
        } else if (prop === propertiesLocalisation.city) {
          locationType = ` (${element.zipcode})`;
        } else if (prop === propertiesLocalisation.agglomeration) {
          locationType = ` (${element.zipcode})`;
        }
        const key: string = element.label.toString().concat(locationType);
        element.key = key;
        this.mapListFilteredItems.set(key, element);
      });
    });
  }

  public selectLocation(event: MatAutocompleteSelectedEvent): void {
    // max = undefined ==> unlimited
    if (this.mapListFilteredItems.has(event.option.value)) {
      const localisationSelected = this.mapListFilteredItems.get(event.option.value);
      const localisation = this.search.localisationsList.find((local) => {
        switch (local.typeLocation) {
          case LOCALISATION_LABELS.agglomeration: {
            return localisationSelected.id === local.id;
          }
          case LOCALISATION_LABELS.cities: {
            return localisationSelected.label === local.label && localisationSelected.zipcode === local.zipcode;
          }
          case LOCALISATION_LABELS.departments: {
            return localisationSelected.code === local.code;
          }
          case LOCALISATION_LABELS.regions: {
            return localisationSelected.id === local.id;
          }
          default:
            break;
        }
      });
      if (!localisation) {
        this.search.localisationsList.push(localisationSelected);
        switch (localisationSelected.typeLocation) {
          case LOCALISATION_LABELS.agglomeration:
            this.search.localisations[localisationSelected.typeLocation].push(localisationSelected.id);
            break;
          case LOCALISATION_LABELS.cities: {
            this.search.localisations[localisationSelected.typeLocation].push(localisationSelected.label);
            break;
          }
          case LOCALISATION_LABELS.departments:
          case LOCALISATION_LABELS.regions: {
            this.search.localisations[localisationSelected.typeLocation].push(localisationSelected.id);
            break;
          }
          default:
            break;
        }

        this._searchFormUtilsService.setSearchFilter({ where: this.search }, this.waitForSearch);
      }
      this.filteredItems = [];
      this.locationSearchForm.setValue('');
      this._markForCheck(this.changeDetectorRef);
    }
  }

  ngAfterViewInit(): void {
    this._afterViewInit = true;
    this._eventUtilsService.documentClickedTarget
      .pipe(
        takeUntil(this.$d),
        debounce(() => interval(50)),
      )
      .subscribe((target) => {
        this.documentClickListener(target);
      });
    this._filterUtilsService.$openPanel.pipe(takeUntil(this.$d)).subscribe((value) => {
      const oldShowPanel = this.showPanel;
      this.showPanel = value[SearchPanelTypeEnum.LOCATION];
      if (this.showPanel !== !!oldShowPanel) {
        this._markForCheck(this.changeDetectorRef);
      }
    });

    this.breakpointObserver
      .observe(['(min-width: 1200px)', '(max-width: 1199.99px)'])
      .pipe(takeUntil(this.$d))
      .subscribe(() => {
        // set a timeout so the markForchek happen after the view is completly initiated
        this._markForCheck(this.changeDetectorRef);
      });

    fromEvent(window, 'resize')
      .pipe(
        debounce(() => interval(5)),
        takeUntil(this.$d),
      )
      .subscribe(() => {
        this._markForCheck(this.changeDetectorRef);
      });
  }

  documentClickListener(target: HTMLElement): void {
    if (!this.titelUp && !this.eRef.nativeElement.contains(target)) {
      // Clicked outside

      this.selected = !this.titelUp && (this.open || false);
      this._filterUtilsService.hidePanel(SearchPanelTypeEnum.LOCATION);
      this._markForCheck(this.changeDetectorRef);

      return;
    }

    if (this.titelUp && !this.inputElement.nativeElement.contains(target)) {
      this.selected = !this.titelUp && (this.open || false);
      this._markForCheck(this.changeDetectorRef);
    }
  }

  switchToEdit($event: MouseEvent) {
    $event.stopImmediatePropagation();
    if (!this.selected) {
      this.selected = true;
      const nativeElement = this.inputElement.nativeElement;
      setTimeout(() => {
        nativeElement.focus();
      });
    }

    this.showSubPanel();
  }

  showSubPanel() {
    if (!this.open) {
      this._filterUtilsService.showPanel(SearchPanelTypeEnum.LOCATION, true);
    }
  }

  /**
   * Check the element if it is text-overflow
   */
  isTextOverflow(): boolean {
    const elem = document.getElementById(this.searchLocationInputDataLabelId);
    if (elem) {
      return elem.offsetWidth < elem.scrollWidth;
    }

    return false;
  }

  deleteLocalisation() {
    this.search.localisations = new LocationData();
    this.search.localisationsList.forEach((localisation) => {
      switch (localisation.typeLocation) {
        case LOCALISATION_LABELS.agglomeration:
          this.search.localisations[localisation.typeLocation].push(localisation.id);
          break;
        case LOCALISATION_LABELS.cities: {
          this.search.localisations[localisation.typeLocation].push(localisation.label);
          break;
        }
        case LOCALISATION_LABELS.departments:
        case LOCALISATION_LABELS.regions: {
          this.search.localisations[localisation.typeLocation].push(localisation.id);
          break;
        }
        default:
          break;
      }
    });

    this._searchFormUtilsService.setSearchFilter({ where: this.search }, this.waitForSearch);
  }

  protected initData() {
    this.locationLabelised = this.search.localisationsList.map((value) => value.label).join(', ');
    if (this._afterViewInit) {
      this._markForCheck(this.changeDetectorRef);
    }
  }
}
