import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';

const SELECTED_CLASS_NAME = 'checked';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
  standalone: true,
})
export class ToggleButtonComponent implements OnInit {
  @Input() checked: boolean;
  @Output() readonly selectChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private readonly _ref: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.setSelectedClass(this.checked);
  }

  @HostListener('click')
  onToggleButtonClick() {
    this.checked = !this.checked;
    this.setSelectedClass(this.checked);
    this.selectChange.next(this.checked);
  }

  private setSelectedClass(checked) {
    checked
      ? this.renderer.addClass(this._ref.nativeElement, SELECTED_CLASS_NAME)
      : this.renderer.removeClass(this._ref.nativeElement, SELECTED_CLASS_NAME);
  }
}
