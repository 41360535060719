import { IsArray, IsDate, IsEnum, IsNumber, IsOptional, IsString } from 'class-validator';

import { DossierStatusCode } from './dossier-status-code.enum';
import { ProspectResponse } from '../prospect';

class Presciptor {
  @IsString()
  firstName: string;

  @IsString()
  lastName: string;
}
export class DossierResponse {
  @IsNumber()
  @IsOptional()
  id?: number;

  @IsNumber()
  prescriptorId: number;

  @IsDate()
  @IsOptional()
  createdAt?: Date;

  @IsEnum(DossierStatusCode)
  dossierStatusCode: DossierStatusCode;

  @IsOptional()
  prescriptor?: Presciptor;

  @IsArray()
  @IsOptional()
  prospects?: ProspectResponse[];

  @IsArray()
  @IsOptional()
  simulationIds?: number[];

  @IsString()
  @IsOptional()
  comment?: string;
}
