import { AbstractControl, ValidatorFn } from '@angular/forms';

import { AppConfigService } from '../services/app-config.service';

const passwordValidation = new AppConfigService().appConfig.passwordValidation;
const passwordRegex = new AppConfigService().appConfig.passwordRegex;

export const isPasswordValidators = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: { isPasswordValidate: boolean } } | undefined => {
    let isPasswordValidate = false;
    if (control.value && control.value.split('').length >= passwordValidation.minLength) {
      // regexPasswordList.filter will return an array containing all the matching rules
      isPasswordValidate = passwordRegex.filter((regex) => regex.test(control.value)).length >= passwordValidation.minRules;
    }

    return isPasswordValidate ? undefined : { isRightPassword: { isPasswordValidate } };
  };
};
