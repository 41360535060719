/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';

import { AbstractSearchFilter } from '../abstract-search-filter';

import { I18nService } from '../../../../utils/services/i18n.service';
import { CheckboxPopulatedComponent } from '../../../../utils/components/checkbox-populated/checkbox-populated.component';

@Component({
  selector: 'app-search-filter-outside',
  templateUrl: './search-filter-outside.component.html',
  styleUrls: ['./search-filter-outside.component.scss'],
  standalone: true,
  imports: [CheckboxPopulatedComponent],
})
export class SearchFilterOutsideComponent extends AbstractSearchFilter {
  constructor(public readonly i18nService: I18nService) {
    super();
  }

  /**
   * swap orientation to match with RG while kepp working with internationalisation
   * where ids are as following 1:North, 3:East, 5:South, 7:West
   */
  public swapOrientation(): (a: any, b: any) => number {
    return (a: any, b: any) => {
      let value = 0;
      if (a.id < b.id && a.id <= 3 && b.id !== 3) {
        value = 1;
      } else if (a.id > b.id && a.id >= 5 && b.id !== 5) {
        value = -1;
      }

      return value;
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected initData() {}
}
