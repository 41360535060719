import { PaternityCreateRequest } from '@commons-dto/dossier-prospect';

import { PaternityFormData } from './forms/paternity-form-data.model';

export class PaternityCreate {
  static fromForm(paternitiesFormData: PaternityFormData[], getSummary?: boolean): PaternityCreateRequest[] {
    return paternitiesFormData.map((paternityFormData) => ({
      dossierId: paternityFormData.dossierId,
      developerCompanyId: paternityFormData.paternity_add_company,
      programId: paternityFormData.paternity_add_program,
      returnSummary: getSummary,
    }));
  }
}
