import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { SharedModule } from 'primeng/api';
import { NgFor, NgIf } from '@angular/common';
import { AccordionModule } from 'primeng/accordion';

import { IconComponent } from '../../standalone/icon/icon.component';

@Component({
  selector: 'app-formly-accordion',
  styleUrls: ['./formly-accordion.component.scss'],
  templateUrl: './formly-accordion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AccordionModule, NgFor, SharedModule, IconComponent, NgIf, FormlyModule],
})
export class FormlyAccordionComponent extends FieldType<FieldTypeConfig> {
  constructor() {
    super();
  }
}
