import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { catchError, lastValueFrom, map, Observable, switchMap, tap } from 'rxjs';

import { LotAreaTypeEnum } from '../model/lot-area-type.enum';
import { LotComparatorDetail } from '../model/lot-comparator-detail';

import { DataCheckLotRefNumberUnicity } from '../../programs/models/DataCheckLotRefNumberUnicity';
import { SecondaryLot } from '../../programs/models/SecondaryLot';
import { AttachmentUploadData } from '../../utils/models/AttachmentUploadData';
import { LotAccountAlertResponse } from '../../utils/models/LotAccountAlertResponse';
import { LotDetailResponse } from '../../utils/models/LotDetailResponse';
import { LotResponse } from '../../utils/models/LotResponse';
import { LotToCreate } from '../../utils/models/LotToCreate';
import { LotToUpdate } from '../../utils/models/LotToUpdate';
import { AppConfigService } from '../../utils/services/app-config.service';
import { AttachmentService } from '../../utils/services/attachment.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';
import { LotApiService } from '../../adapters/lot-api.service';

@Injectable({
  providedIn: 'root',
})
export class LotService {
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
    private readonly attachmentService: AttachmentService,
    private readonly lotApiService: LotApiService,
  ) {}

  public static hasAreaSurface(lot: LotDetailResponse, areaType: LotAreaTypeEnum): boolean {
    if (!lot.lotAreas || !lot.lotAreas.length) {
      return false;
    }

    return !!lot.lotAreas.find((area) => area.label === areaType.toString());
  }

  createLot(lotToCreate: LotToCreate): Observable<LotResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Lots`;

    const filesToUpload: Array<AttachmentUploadData> = Object.keys(lotToCreate.document)
      .map((key) => ({ file: lotToCreate.document[key], documentType: key }))
      .filter((attachment) => attachment.file);

    return this.attachmentService
      .uploadLotDocumentsFiles(
        filesToUpload,
        this.appConfig.getAppConfig().containerName.lotDocuments,
        lotToCreate.lotRef,
        lotToCreate.programId,
      )
      .pipe(
        tap(({ result }) => (lotToCreate.documentsId = result.files.file.map((file) => file.id))),
        switchMap(() => {
          return this.http
            .post<LotResponse>(url, lotToCreate)
            .pipe(catchError(this.errorHandlerService.handleError<LotResponse>('lotService', 'createLot')));
        }),
      );
  }

  updateLot(lotToUpdate: LotToUpdate, lotToUpdateId: number): Observable<LotResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Lots/${lotToUpdateId}`;

    const filesToUpload: Array<AttachmentUploadData> = Object.keys(lotToUpdate.document)
      .map((key) => ({ file: lotToUpdate.document[key], documentType: key }))
      .filter((attachment) => attachment.file && !attachment.file.alreadySave);

    if (filesToUpload.length) {
      return this.attachmentService
        .uploadLotDocumentsFiles(
          filesToUpload,
          this.appConfig.getAppConfig().containerName.lotDocuments,
          lotToUpdate.lotRef,
          lotToUpdate.programId,
        )
        .pipe(
          tap(({ result }) => (lotToUpdate.documentsId = result.files.file.map((file) => file.id))),
          switchMap(() => {
            return this.http
              .put<LotResponse>(url, lotToUpdate)
              .pipe(catchError(this.errorHandlerService.handleError<LotResponse>('lotService', 'updateLot')));
          }),
        );
    }

    return this.http
      .put<LotResponse>(url, lotToUpdate)
      .pipe(catchError(this.errorHandlerService.handleError<LotResponse>('lotService', 'updateLot')));
  }

  async getLotById(id: number): Promise<LotResponse> {
    return lastValueFrom(this.lotApiService.getById(id));
  }

  getLots(filter: string): Observable<Array<LotResponse>> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Lots`;

    let params = new HttpParams();
    if (filter) {
      params = params.set('filter', filter);
    }

    return this.http
      .get<Array<LotResponse>>(url, { params })
      .pipe(catchError(this.errorHandlerService.handleError<Array<LotResponse>>('lotService', 'getLots')));
  }

  isUniqueRefAndNumber(data: DataCheckLotRefNumberUnicity): Observable<boolean> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Lots/isUniqueRefAndNumber`;

    return this.http
      .post<boolean>(url, data)
      .pipe(catchError(this.errorHandlerService.handleError<boolean>('lotService', 'isUniqueRefAndNumber')));
  }

  checkLotRefAndLotNumberUnicity(programId: number, lotRef: string, lotNumber: string): Observable<boolean> {
    const data: DataCheckLotRefNumberUnicity = {
      lotNumber,
      lotRef,
      programId,
    };

    return this.isUniqueRefAndNumber(data);
  }

  formateLotDataToSubmit(
    lotToSubmit: LotToCreate | LotToUpdate,
    lotForm: UntypedFormGroup,
    secondaryLots: Array<SecondaryLot>,
    programId: number,
  ): void {
    // transform to key-value object
    Object.keys(lotForm.controls).forEach((key) => (lotToSubmit[key] = lotForm.get(key).value));

    lotToSubmit.programId = programId;

    lotToSubmit.lotHasAreas = lotForm
      .get('lotHasAreas')
      .value.filter((lotHasArea) => lotHasArea.control)
      .map((lotHasArea) => {
        return {
          area: lotHasArea.spaceControl,
          areaTypeId: lotHasArea.id,
        };
      });

    lotToSubmit.secondaryLots = secondaryLots.map((secondaryLot) => {
      return {
        refLot: secondaryLot.refLot,
        numLot: secondaryLot.numLot,
        secondaryLotTypeId: secondaryLot.secondaryLotTypeId,
        lotSellingPriceIT: secondaryLot.lotSellingPriceIT,
        id: secondaryLot.id,
      };
    });

    lotToSubmit.lotHasTaxations = lotForm
      .get('lotHasTaxations')
      .value.filter((lotHasTaxation) => lotHasTaxation.checkBoxControl)
      .map((taxation) => {
        return {
          taxationId: taxation.id,
        };
      });
  }

  /**
   * Create an alert on the lot with the lotId given
   *
   * @param lotId - Id of the lot to take alert
   * @memberof LotService
   */
  createAlert(lotId: number): Observable<LotAccountAlertResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/LotAccountAlerts`;

    return this.http
      .post<LotAccountAlertResponse>(url, { lotId })
      .pipe(catchError(this.errorHandlerService.handleError<LotAccountAlertResponse>('lotService', 'createAlert')));
  }

  /**
   * Publish lot
   *
   * @param {number} lotId
   * @returns {Observable<any>}
   * @memberof LotService
   */
  publishLot(lotId: number): Observable<void> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Lots/publish/${lotId}`;

    return this.http.patch<void>(url, {}).pipe(catchError(this.errorHandlerService.handleError<void>('lotService', 'publishLot')));
  }

  /**
   * Unpublish lot
   *
   * @param {number} lotId
   * @returns {Observable<any>}
   * @memberof LotService
   */
  unpublishLot(lotId: number): Observable<void> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Lots/unpublish/${lotId}`;

    return this.http.patch<void>(url, {}).pipe(catchError(this.errorHandlerService.handleError<void>('lotService', 'unpublishLot')));
  }

  getlotDetailForComparator(lotIds: number[], forPreview = false): Observable<LotComparatorDetail[]> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Lots/${lotIds.join(',')}/comparator`;

    const params = new HttpParams().set('forPreview', String(forPreview));

    return this.http.get<LotComparatorDetail[]>(url, { params }).pipe(
      map((lotArray) =>
        lotArray.map((lot) => ({
          ...lot,
          isUnvailable: !lot.status.published || !lot.status.mandated || lot.status.sold || lot.status.quotaReach,
        })),
      ),
      catchError(this.errorHandlerService.handleError<LotComparatorDetail[]>('lotService', 'getlotDetailForComparator')),
    );
  }
}
