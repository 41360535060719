<div class="box" [class.complete]="isDossierComplete" [class.incomplete]="!isDossierComplete">
  <div class="header" *ngIf="!isDossierComplete">
    <svg class="icon" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#EB8C5B" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.4845 16.495C23.1581 15.3283 24.842 15.3283 25.5156 16.495L31.7943 27.37C32.4678 28.5367 31.6259 29.995 30.2787 29.995H17.7214C16.3742 29.995 15.5322 28.5367 16.2058 27.37L22.4845 16.495ZM24 19C24.4142 19 24.75 19.3358 24.75 19.75V23.25C24.75 23.6642 24.4142 24 24 24C23.5858 24 23.25 23.6642 23.25 23.25L23.25 19.75C23.25 19.3358 23.5858 19 24 19ZM24 28C24.5523 28 25 27.5523 25 27C25 26.4477 24.5523 26 24 26C23.4477 26 23 26.4477 23 27C23 27.5523 23.4477 28 24 28Z"
        fill="white"
      />
    </svg>
    <span class="title">{{ 'Txt_DossierProspectNotComplete' | translate }}</span>
  </div>
  <div class="header" *ngIf="isDossierComplete">
    <svg class="icon" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#41B8A0" />
      <path d="M17.75 24.625L22.75 29.625L30.25 18.375" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <span class="title">{{ 'Txt_DossierProspectComplete' | translate }}</span>
  </div>
  <div class="body-input" *ngIf="!isDossierComplete">
    <div class="detail">{{ 'Txt_DossierProspectNotCompleteDetail' | translate }}</div>
    <button (click)="updateDossier()">{{ 'Txt_DossierProspectNotCompleteButton' | translate }}</button>
  </div>
  <div class="body-input" *ngIf="isDossierComplete">
    <div class="detail">{{ 'Txt_DossierProspectCompleteDetail' | translate }}</div>
    <button (click)="updateDossier()">{{ 'Txt_DossierProspectCompleteButton' | translate }}</button>
  </div>
</div>
