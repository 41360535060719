export class ActionOnOptionResponse {
  checkIfOptionCanBeRemoved: boolean;
  checkIfOptionCanBeTaken: boolean;
  checkIfOptionCanBeExtended: boolean;
  checkIfOptionCanBePreReserved: boolean;
  checkIfOptionCanBeAutomaticallyTaken: boolean;
  checkIfAlertCanBeTaken: boolean;
  userHasAlreadyTakenAlert: boolean;
  checkRemainingTimeForOptionOwner: string;
  checkRemainingTimeForOptionOtherOwner: string;
  checkOwnerOptionSatusIsPending?: boolean;
  checkOtherOwnerOptionSatusIsPending?: boolean;
}
