<div class="grid grid-cols-10">
  <mat-form-field class="col-span-4 mb-2">
    <mat-label>{{ this.placeholder }}</mat-label>
    <input type="number" min="0" step="1" [max]="nbLotMax" [formControl]="roomControl" matInput (keyup)="checkIsValid()" />
  </mat-form-field>

  <div class="col-span-1 flex w-1/ txt-gray font-bold pb-3 pl-5 self-center">
    {{ nbLotMax }}
  </div>
</div>
