<div class="mb-4 mx-0 md:mx-4 lg:mx-5 xl:mx-6 inline-block w-full max-w-xl">
  <div class="bg-white py-5 px-6 sm:px-10 lg:px-18">
    <!-- Expand panel search quickly-->
    <mat-accordion>
      <mat-expansion-panel
        [expanded]="panelFastSearchOpened"
        (closed)="panelFastSearchOpened = false"
        (opened)="panelFastSearchOpened = true"
        [class.close-panelw]="!panelFastSearchOpened"
        [class.open-panelw]="panelFastSearchOpened"
        class="shadow-none rounded-none mb-6 text-left"
      >
        <mat-expansion-panel-header class="pl-0 pr-2 h-12">
          <mat-panel-title>
            <h2 class="text-xl font-bold">
              {{ i18nService._('Title_QuickSearch') }}
            </h2>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="flex flex-col mb-2">
          <!-- Input research town -->
          <app-form-location
            #searchLocation
            [id]="'location'"
            [parentForm]="formGroup"
            [(localisations)]="search.localisations"
            [(dataMapList)]="localisationsMapList"
            (localisationsChanged)="updateSearch()"
          >
          </app-form-location>

          <!-- Select Fiscality -->
          <app-dropdown-list-populated
            [parentForm]="formGroup"
            [searchable]="true"
            [items]="taxationItems"
            [placeholder]="i18nService._('Txt_Page_Form_RegisterProgram_LotTaxation')"
            class="flex w-full mb-3"
            [fieldName]="'taxation'"
          >
          </app-dropdown-list-populated>

          <mat-chip-listbox>
            <mat-chip-option
              color="primary"
              class="mr-3 w-[130px] filter-isActable"
              [selected]="search.isActable"
              (click)="selectItem('isActable', !search.isActable)"
            >
              <div class="filter-chip-label">
                {{ i18nService._('Title_Form_SearchForm_Actable') }}
              </div>
            </mat-chip-option>
          </mat-chip-listbox>

          <!-- checkbox -->
          <div class="mb-4">
            <div class="text-base font-bold text-left">
              {{ i18nService._('Title_Form_SearchForm_RoomNumber') }}
            </div>

            <!-- Checkbox Rounded Number checked and unchecked -->
            <div>
              <app-checkbox-populated
                [source]="roomItems"
                [notTranslated]="true"
                [sortMethod]="sortBy('id')"
                class="flex flex-row flex-wrap"
                [cssClassNames]="'mat-checkbox-rounded-number flex mt-3 mr-3'"
                [(checkedElements)]="search.nbRooms"
              >
              </app-checkbox-populated>
            </div>
          </div>
        </div>

        <!-- filter budget -->
        <div class="mb-4">
          <div class="text-base font-bold text-left">
            {{ i18nService._('Title_Form_SearchForm_Budget') }}
          </div>
          <ngx-slider
            [(highValue)]="search.budget.maxBudget"
            [(value)]="search.budget.minBudget"
            [manualRefresh]="refresh"
            [options]="budgetSliderOptions"
            class="ngx-slider-styles"
          >
          </ngx-slider>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel
        [expanded]="!panelFastSearchOpened"
        (closed)="onProgramClosed()"
        (opened)="onProgramOpened()"
        [class.close-panelw]="panelFastSearchOpened"
        [class.open-panelw]="!panelFastSearchOpened"
        class="shadow-none rounded-none mb-6 text-left"
      >
        <mat-expansion-panel-header class="pl-0 pr-2 h-12" data-cy="searchByProgram">
          <mat-panel-title>
            <h2 class="text-xl font-bold">
              {{ i18nService._('Title_SearchByProgram') }}
            </h2>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- Input Corporate Name -->
        <app-search-form-programs
          #searchPrograms
          [(selectedItems)]="selectedProgramIds"
          [id]="'program'"
          [max]="1"
          [parentForm]="formGroup"
          [placeholder]="i18nService._('Txt_Placeholder_ProgramName')"
          (selectedItemsChanged)="updateSearch()"
          [required]="true"
        >
        </app-search-form-programs>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="pt-3 pb-3 inline-block w-full">
    <button (click)="onSearch()" mat-flat-button color="primary" class="w-full rounded-none py-3 h-18" data-cy="search">
      {{ i18nService._('Txt_Button_Search') }}
    </button>
  </div>
</div>
