<div class="current_dossiers_prospect">
  <div class="list_dossiers_prospect_list">
    <span>{{ i18nService._('Txt_Current_Dossier_Prospect') }}</span>
    <button class="see_all_dossiers_prospect" (click)="goToDossierProspectList()">
      {{ i18nService._('Txt_See_All_Dossier_Prospect') }}
    </button>
  </div>
  <div class="list_dossiers_prospect">
    <app-button-card
      *ngIf="!dossiers.length"
      class="dossier_prospect_card"
      image="/assets/img/icon/no-prospect-image.svg"
      textButton="Title_Form_Prospect_DeclareProspect"
      (clickButton)="goToCreateProspect()"
    >
      {{ i18nService._('Txt_No_Dossier_Prospect') }}
    </app-button-card>
    <app-carousel *ngIf="dossiers.length" [config]="carouselConfig" [elementTemplate]="dossierCard">
      <ng-template #dossierCard let-item>
        <app-prospect-card [dossier]="item"></app-prospect-card>
      </ng-template>
    </app-carousel>
  </div>
</div>
