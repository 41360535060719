import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject, takeUntil, distinctUntilChanged, merge } from 'rxjs';
import { MatRadioModule } from '@angular/material/radio';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';

import { HistoryLotFeesDialogComponent } from '../../../dialog/components/history-lot-fees-dialog/history-lot-fees-dialog.component';
import { AccountResponse } from '../../../utils/models/AccountResponse';
import { ReferenceTableData } from '../../../utils/models/ReferenceTableData';
import { SpecialOfferResponse } from '../../../utils/models/SpecialOfferResponse';
import { AccountService } from '../../../utils/services/account.service';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { BasicFormatsService } from '../../../utils/services/basic-formats.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { ReferenceTablesService } from '../../../utils/services/reference-tables.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { UserRoleService } from '../../../utils/services/user-role.service';
import { ReservationInfos } from '../../models/ReservationInfos';
import { ReservationService } from '../../services/reservation.service';
import { TaxationResponse } from '../../../utils/models/TaxationResponse';
import { DropdownListPopulatedComponent } from '../../../utils/components/dropdown-list-populated/dropdown-list-populated.component';
import { InputDateComponent } from '../../../utils/components/input-date/input-date.component';
import { DecimalMaskDirective } from '../../../utils/directives/decimal-mask.directive';
import { PostalAddressComponent } from '../../../utils/components/postal-address/postal-address.component';
import { ContactInformationComponent } from '../../../utils/components/contact-information/contact-information.component';

interface OptionItem {
  id: number;
  label: string;
}

interface RecoveredPostalAddress {
  countryId: number;
  postalCode: string;
  city: string;
  cityInseeCode: string;
}

@Component({
  selector: 'app-step-one-form-reservation',
  templateUrl: './step-one-form-reservation.component.html',
  styleUrls: ['./step-one-form-reservation.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    ContactInformationComponent,
    PostalAddressComponent,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    DecimalMaskDirective,
    MatRadioModule,
    InputDateComponent,
    DropdownListPopulatedComponent,
  ],
})
export class StepOneFormReservationComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() resaInformation: ReservationInfos;

  @Input() isActiveStep: boolean;

  @Output() readonly formGroupChanged: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

  /**
   * isSubmit input for app-file-drop
   *
   * @type {boolean}
   * @memberof StepOneFormReservationComponent
   */
  @Input() isSubmit: boolean;

  /**
   * List of Valo BO Super and Simple
   *
   * @type {ReferenceTableData}
   * @memberof StepFourFormProgramComponent
   */
  public advValoList: Array<ReferenceTableData>;

  public stepOneForm: UntypedFormGroup;
  public isCanceled: boolean;
  public isElectronicSignatureControl: AbstractControl;
  public lotNumberControl: AbstractControl;
  public refSecLotsControl: AbstractControl;
  public clientQualificationControl: AbstractControl;
  public reservationTaxationControl: AbstractControl;
  public vatRateControl: AbstractControl;
  public discountAmountControl: AbstractControl;
  public specialOfferControl: AbstractControl;
  public sellingPriceControl: AbstractControl;
  public notaryFeesFreeControl: AbstractControl;
  public markupControl: AbstractControl;
  public markupAmountControl: AbstractControl;
  public markupAmountVisibleControl: AbstractControl;
  public contractorFeesControl: AbstractControl;
  public contractorFeesAmountControl: AbstractControl;
  public specificContractorFeesControl: AbstractControl;
  public specificContractorFeesCommentControl: AbstractControl;
  public clientQualifications: Array<OptionItem>;
  public advForm: UntypedFormGroup;
  public specialOfferList: Array<SpecialOfferResponse>;
  public taxList: Array<TaxationResponse>;
  public acquirerForm: UntypedFormGroup;
  public coAcquirerForm: UntypedFormGroup;
  public infosIsLoad: boolean;
  public recoveredPostalAddress: RecoveredPostalAddress;

  private readonly _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public formBuild: UntypedFormBuilder,
    public i18nService: I18nService,
    public userRoleService: UserRoleService,
    private readonly accountService: AccountService,
    private readonly appConfigService: AppConfigService,
    private readonly basicFormatsService: BasicFormatsService,
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly reservationService: ReservationService,
    private readonly snackbarService: SnackbarService,
    private readonly dialog: MatDialog,
  ) {
    this.recoveredPostalAddress = undefined;
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  public ngOnInit(): void {
    this.isCanceled =
      this.appConfigService.getAppConfig().reservationStatus.reservationCanceled === this.resaInformation.reservationStatus.label;
    this.referenceTablesService.getTableReferenceInfo('ClientQualifications').subscribe(
      (clientQualifications) => {
        this.clientQualifications = clientQualifications;
        this.infosIsLoad = true;
      },
      () => {
        this.snackbarService.errorI18n('Error_SnackBar_ErrorOccured');
      },
    );
    this.specialOfferList = this.resaInformation.lot.specialOffer;
    this.taxList = this.resaInformation.lot.taxations;

    let found = false;
    // Get Nominal Taxation from DB
    this.reservationService.getTaxations().subscribe((taxations) => {
      const nominalTaxation = taxations.filter((taxation) => taxation.label === this.appConfigService.getAppConfig().nominalTaxationLabel);
      for (const tax of this.taxList) {
        if (tax.id === nominalTaxation[0].id) {
          found = true;
          break;
        }
      }
      if (!found) {
        this.taxList.push(nominalTaxation[0]);
      }
    });

    /* Get Valo BO Super and Simple */
    this.accountService
      .getAccountsWithRoles([this.appConfigService.appConfig.roles.valoBoSuper, this.appConfigService.appConfig.roles.valoBoSimple])
      .subscribe(
        (accountsFound) => {
          this.advValoList = accountsFound.reduce((accumulator: Array<ReferenceTableData>, account: AccountResponse) => {
            if (!account.isActive) {
              return accumulator;
            }

            const item = new ReferenceTableData();
            item.id = account.id;
            item.label = `${account.lastName.charAt(0).toUpperCase()}${account.lastName.slice(1)}`;
            item.label += ` ${account.firstName.charAt(0).toUpperCase()}${account.firstName.slice(1)}`;

            return accumulator.concat(item);
          }, []);
        },
        () => {
          this.snackbarService.sendErrorOccured();
        },
      );
    this.advForm = this.formBuild.group({});
    this.acquirerForm = this.formBuild.group({});
    this.coAcquirerForm = this.formBuild.group({});

    this.stepOneForm = new UntypedFormGroup({
      isElectronicSignature: new UntypedFormControl(this.resaInformation.isElectronicSignature),
      lotNumber: new UntypedFormControl(this.resaInformation.lot.lotNumber, [Validators.maxLength(255)]),
      refSecLots: new UntypedFormControl(this.resaInformation.refSecLots, [Validators.maxLength(255)]),
      clientQualificationId: new UntypedFormControl(this.resaInformation.clientQualificationId, [Validators.required]),
      taxationId: new UntypedFormControl(this.resaInformation.taxationId, [Validators.required]),
      vatRate: new UntypedFormControl(undefined, [Validators.required]),
      discountAmount: new UntypedFormControl(this.resaInformation.discountAmount, [Validators.min(0), Validators.max(99999)]),
      specialOfferId: new UntypedFormControl(
        {
          value: this.resaInformation.specialOfferId,
          disabled: !this.specialOfferList || !this.specialOfferList.length,
        },
        [],
      ),
      reservationSellingPriceIT: new UntypedFormControl(
        this.resaInformation.reservationSellingPriceIT || this.resaInformation.globalSellingPrice,
        [Validators.required, Validators.min(0), Validators.max(9999999999)],
      ),
      contractorFees: new UntypedFormControl(this.resaInformation.contractorFees, [Validators.required]),
      notaryFeesFree: new UntypedFormControl(this.resaInformation.notaryFeesFree),
      markup: new UntypedFormControl(this.resaInformation.markup, [Validators.min(0), Validators.max(99.99), Validators.required]),
      markupAmount: new UntypedFormControl(this.resaInformation.markupAmount, [Validators.required]),
      contractorFeesAmount: new UntypedFormControl(
        this.resaInformation.contractorFeesAmount ??
          this.calculateFeesAmount(this.resaInformation.globalSellingPrice, this.resaInformation.contractorFees),
        [Validators.min(0), Validators.max(9999999999)],
      ),
      specificContractorFees: new UntypedFormControl(this.resaInformation.specificContractorFees),
      specificContractorFeesComment: new UntypedFormControl(this.resaInformation.specificContractorFeesComment),
    });

    this.isElectronicSignatureControl = this.stepOneForm.controls.isElectronicSignature;
    this.lotNumberControl = this.stepOneForm.controls.lotNumber;
    this.lotNumberControl.disable();
    this.refSecLotsControl = this.stepOneForm.controls.refSecLots;
    this.clientQualificationControl = this.stepOneForm.controls.clientQualificationId;
    this.reservationTaxationControl = this.stepOneForm.controls.taxationId;
    this.vatRateControl = this.stepOneForm.controls.vatRate;
    this.vatRateControl.disable();
    this.discountAmountControl = this.stepOneForm.controls.discountAmount;
    this.specialOfferControl = this.stepOneForm.controls.specialOfferId;
    this.sellingPriceControl = this.stepOneForm.controls.reservationSellingPriceIT;
    this.notaryFeesFreeControl = this.stepOneForm.controls.notaryFeesFree;
    this.markupControl = this.stepOneForm.controls.markup;
    this.markupAmountControl = this.stepOneForm.controls.markupAmount;
    this.markupAmountVisibleControl = new UntypedFormControl(this.markupAmountControl.value || 'NULL');
    this.markupAmountVisibleControl.disable();
    this.contractorFeesControl = this.stepOneForm.controls.contractorFees;
    this.contractorFeesAmountControl = this.stepOneForm.controls.contractorFeesAmount;
    this.contractorFeesAmountControl.disable();
    this.specificContractorFeesControl = this.stepOneForm.controls.specificContractorFees;
    this.specificContractorFeesCommentControl = this.stepOneForm.controls.specificContractorFeesComment;
    this.changeVatRate(this.resaInformation.taxationId);

    // Compute postal address if there is country id data (for example) in resa info
    if (this.resaInformation.countryId || this.resaInformation.prospect?.countryId) {
      const info = this.resaInformation.countryId ? this.resaInformation : this.resaInformation.prospect;
      this.recoveredPostalAddress = {
        countryId: info.countryId,
        postalCode: info.postalCode,
        city: info.city,
        cityInseeCode: info.cityInseeCode,
      };
    }
    this.computeMarkupAmount();

    this.formGroupChanged.emit(this.stepOneForm);
  }

  public ngAfterViewInit(): void {
    this.stepOneForm.addControl('acquirerLastName', this.acquirerForm.controls.lastName);
    this.stepOneForm.addControl('acquirerFirstName', this.acquirerForm.controls.firstName);
    this.stepOneForm.addControl('acquirerEmail', this.acquirerForm.controls.email);
    this.stepOneForm.addControl('acquirerPhone', this.acquirerForm.controls.mobilePhone);

    this.stepOneForm.addControl('countryId', this.acquirerForm.controls.countryId);
    this.stepOneForm.addControl('postalCode', this.acquirerForm.controls.postalCode);
    this.stepOneForm.addControl('cityInseeCode', this.acquirerForm.controls.cityInseeCode);
    this.stepOneForm.addControl('city', this.acquirerForm.controls.city);

    this.stepOneForm.controls.acquirerLastName.setValue(
      this.resaInformation.acquirerLastName?.length > 0 ? this.resaInformation.acquirerLastName : this.resaInformation.prospect?.lastName,
    );
    this.stepOneForm.controls.acquirerFirstName.setValue(
      this.resaInformation.acquirerFirstName?.length > 0
        ? this.resaInformation.acquirerFirstName
        : this.resaInformation.prospect?.firstName,
    );
    this.stepOneForm.controls.acquirerEmail.setValue(this.resaInformation.acquirerEmail ?? this.resaInformation.prospect?.email);
    this.stepOneForm.controls.acquirerPhone.setValue(this.resaInformation.acquirerPhone ?? this.resaInformation.prospect?.phone);

    this.stepOneForm.addControl('coAcquirerLastName', this.coAcquirerForm.controls.lastName);
    this.stepOneForm.addControl('coAcquirerFirstName', this.coAcquirerForm.controls.firstName);
    this.stepOneForm.addControl('coAcquirerEmail', this.coAcquirerForm.controls.email);
    this.stepOneForm.addControl('coAcquirerPhone', this.coAcquirerForm.controls.mobilePhone);

    this.stepOneForm.controls.coAcquirerLastName.setValue(this.resaInformation.coAcquirerLastName);
    this.stepOneForm.controls.coAcquirerFirstName.setValue(this.resaInformation.coAcquirerFirstName);
    this.stepOneForm.controls.coAcquirerEmail.setValue(this.resaInformation.coAcquirerEmail);
    this.stepOneForm.controls.coAcquirerPhone.setValue(this.resaInformation.coAcquirerPhone);

    this.stepOneForm.addControl('advValoId', this.advForm.controls.advValoId);
    this.stepOneForm.addControl('advLastName', this.advForm.controls.lastName);
    this.stepOneForm.addControl('advFirstName', this.advForm.controls.firstName);
    this.stepOneForm.addControl('advEmail', this.advForm.controls.email);
    this.stepOneForm.addControl('advPhone', this.advForm.controls.mobilePhone);

    this.stepOneForm.controls.advLastName.setValue(this.resaInformation.advLastName);
    this.stepOneForm.controls.advFirstName.setValue(this.resaInformation.advFirstName);
    this.stepOneForm.controls.advEmail.setValue(this.resaInformation.advEmail);
    this.stepOneForm.controls.advPhone.setValue(this.resaInformation.advPhone);

    this.stepOneForm.controls.reservationDate.setValue(this.resaInformation.reservationDate);

    if (this.isCanceled) {
      // need to disable all formgroup as some input can still be accessible if only stepOneForm is disabled
      this.acquirerForm.disable();
      this.coAcquirerForm.disable();
      this.advForm.disable();
      this.stepOneForm.disable();
    }

    this.formGroupChanged.emit(this.stepOneForm);

    const sellingPriceControl$ = this.sellingPriceControl.valueChanges;
    const contractorFeesControl$ = this.contractorFeesControl.valueChanges;
    merge(sellingPriceControl$, contractorFeesControl$)
      .pipe(takeUntil(this._destroy$), distinctUntilChanged())
      .subscribe(() => {
        this.computeMarkupAmount();
      });
  }

  /**
   * changeVatRate method
   *
   * @param taxationId - Taxation id
   * @memberof StepOneFormReservationComponent
   */
  public changeVatRate(taxationId: number): void {
    const newTax = this.taxList.find((tax) => tax.id === taxationId);
    this.vatRateControl.setValue(newTax ? newTax.taxation : undefined);
  }

  /**
   * computeMarkupAmount method
   *
   * @memberof StepOneFormReservationComponent
   */
  public computeMarkupAmount(): void {
    const originalSellingPrice = this.sellingPriceControl.value;

    const markupAmount =
      originalSellingPrice > 0 && this.markupControl.value > 0
        ? (originalSellingPrice * (this.markupControl.value / 100)).toFixed(2)
        : null;

    this.markupAmountControl.setValue(markupAmount);
    this.markupAmountVisibleControl.setValue(markupAmount || 'NULL');
    const contractorFeesRate = this.contractorFeesControl.value || 0;
    const contractorFeesAmount = this.calculateFeesAmount(originalSellingPrice, contractorFeesRate);
    this.contractorFeesAmountControl.setValue(contractorFeesAmount);
  }

  calculateFeesAmount(price: number, rate: number) {
    if (!!rate && !!price) {
      return ((rate / 100) * price).toFixed(2);
    }
    return null;
  }

  /**
   * Format text of special offer like : title - DD/MM/YYYY
   *
   * @param specialOffer - specialOffer to format
   * @memberof StepOneFormReservationComponent
   */
  public formatSpecialOffer(specialOffer: SpecialOfferResponse): string {
    const title =
      specialOffer && specialOffer.specialOfferText && specialOffer.specialOfferText.title ? specialOffer.specialOfferText.title : '';
    const endDate = specialOffer && specialOffer.endDate ? this.basicFormatsService.formatDate(specialOffer.endDate) : '';

    return `${title} - ${endDate}`;
  }

  openFeeHistory() {
    const data: MatDialogConfig<{ lotId: number; lotNumber: string }> = {
      autoFocus: false,
      data: {
        lotId: this.resaInformation.lotId,
        lotNumber: this.resaInformation.lot.lotNumber,
      },
    };
    this.dialog.open(HistoryLotFeesDialogComponent, data);
  }
}
