/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, AsyncPipe } from '@angular/common';

import { StepTwoFormReservationComponent } from '../step-two-form-reservation/step-two-form-reservation.component';
import { StepOneFormReservationComponent } from '../step-one-form-reservation/step-one-form-reservation.component';
import { ReasonOfReservationCancellationComponent } from '../reason-of-reservation-cancellation/reason-of-reservation-cancellation.component';
import { DetailsOfAssociateSellerComponent } from '../details-of-associate-seller/details-of-associate-seller.component';

import { TextareaDialogComponent } from '../../../dialog/components/textarea-dialog/textarea-dialog.component';
import { TextareaDialogData } from '../../../dialog/models/TextareaDialogData';
import { AttachmentUploadData } from '../../../utils/models/AttachmentUploadData';
import { ReservationCancel } from '../../../utils/models/ReservationCancel';
import { ReservationCreate } from '../../../utils/models/ReservationCreate';
import { Sitemap } from '../../../utils/models/Sitemap';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { AttachmentService } from '../../../utils/services/attachment.service';
import { FormUtilsService } from '../../../utils/services/form/form-utils.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { SpinnerWithBackdropService } from '../../../utils/services/spinner-with-backdrop.service';
import { GoogleTagManagerService } from '../../../utils/services/google-tag-manager.service';
import { ReservationService } from '../../services/reservation.service';
import { MatVerticalStepperScrollerDirective } from '../../../utils/directives/mat-vertical-stepper-scroller.directive';
import { StickyHeaderFormComponent } from '../../../utils/components/sticky-header-form/sticky-header-form.component';

@Component({
  selector: 'app-register-form-reservation',
  templateUrl: './register-form-reservation.component.html',
  styleUrls: ['./register-form-reservation.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    StickyHeaderFormComponent,
    DetailsOfAssociateSellerComponent,
    ReasonOfReservationCancellationComponent,
    MatStepperModule,
    MatVerticalStepperScrollerDirective,
    FormsModule,
    ReactiveFormsModule,
    StepOneFormReservationComponent,
    MatButtonModule,
    StepTwoFormReservationComponent,
    AsyncPipe,
  ],
})
export class RegisterFormReservationComponent implements OnInit {
  /**
   * reservationId attribute
   *
   * @type {string}
   * @memberof RegisterFormReservationComponent
   */
  reservationId: string;
  /**
   * stepOneForm attribute
   *
   * @type {FormGroup}
   * @memberof RegisterFormReservationComponent
   */
  public stepOneForm: UntypedFormGroup;
  /**
   * stepTwoForm attribute
   *
   * @type {FormGroup}
   * @memberof RegisterFormReservationComponent
   */
  public stepTwoForm: UntypedFormGroup;
  /**
   * isCancelButtonDisplayed attribute
   *
   * @type {boolean}
   * @memberof RegisterFormReservationComponent
   */
  public isCancelButtonDisplayed: boolean;
  /**
   * reservationInformations$ observable attribute
   *
   * @type {Observable<ReservationCreate>}
   * @memberof RegisterFormReservationComponent
   */
  public reservationInformations$: Observable<ReservationCreate>;

  /**
   * accordion child attribute
   *
   * @type {MatAccordion}
   * @memberof RegisterFormReservationComponent
   */
  public accordion: MatAccordion;
  /**
   * matStepper child attribute
   *
   * @type {MatStepper}
   * @memberof RegisterFormReservationComponent
   */
  @ViewChild('stepper') public matStepper: MatStepper;

  /**
   * selectedIndex attribute
   *
   * @type {number}
   * @memberof RegisterFormReservationComponent
   */
  selectedIndex = 0;
  /**
   * isSubmit for app-file-drop in step one
   *
   * @type {boolean}
   * @memberof RegisterFormReservationComponent
   */
  public isSubmit: boolean;
  /**
   * reservationInformations attribute
   *
   * @type {ReservationCreate}
   * @memberof RegisterFormReservationComponent
   */
  private reservationInformations: ReservationCreate;
  private _reservationDocs: any;
  private _reservationDocFileChanged = false;

  /**
   * Creates an instance of RegisterFormReservationComponent.
   * @param {I18nService} i18nService
   * @param {SnackbarService} snackbarService
   * @param {ActivatedRoute} route
   * @param {Router} router
   * @param {FormBuilder} formBuilder
   * @param {ReservationService} reservationService
   * @param {AppConfigService} appConfigService
   * @param {MatDialog} dialog
   * @param {ChangeDetectorRef} changeDetectorRef
   * @param {AttachmentService} attachmentService
   * @param {GoogleTagManagerService} _googleTagManagerService
   * @memberof RegisterFormReservationComponent
   */
  constructor(
    public i18nService: I18nService,
    private readonly snackbarService: SnackbarService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly reservationService: ReservationService,
    private readonly appConfigService: AppConfigService,
    private readonly dialog: MatDialog,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly attachmentService: AttachmentService,
    private readonly spinnerWithBackdropService: SpinnerWithBackdropService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
  ) {
    this.reservationId = undefined;
    this.isCancelButtonDisplayed = false;
  }

  ngOnInit(): void {
    this._reservationDocs = this.appConfigService.getValue('documentTypesByFunctional', 'reservation');
    // Get reservation id from url
    this.reservationId = this.route.snapshot.paramMap.get('reservationId');

    // Form init
    this.stepOneForm = this.formBuilder.group({});
    this.stepTwoForm = this.formBuilder.group({});

    // Compute isCancelButtonDisplayed
    const appConfig = this.appConfigService.getAppConfig();

    this.reservationInformations$ = this.reservationService.getInfosOfPageReservation(this.reservationId).pipe(
      map(
        (reservation: ReservationCreate) => {
          // Display the cancel button if the phase is 'reserved' and if the status is not 'rejected developer'  or 'reservation canceled'
          const phaseTab = [appConfig.reservationStatus.rejectedDeveloper, appConfig.reservationStatus.reservationCanceled];
          this.isCancelButtonDisplayed =
            reservation.reservationStatus.reservationPhase.label === appConfig.reservationPhase.reservation &&
            !phaseTab.includes(reservation.reservationStatus.label);
          this.reservationInformations = reservation;

          return reservation;
        },
        () => {
          this.redirect();
        },
      ),
    );
  }

  /**
   * onCancelReservationClicked method
   *
   * @memberof RegisterFormReservationComponent
   */
  public onCancelReservationClicked(): void {
    // Show cancel reservation dialog
    const dialogData: TextareaDialogData = {
      title: this.i18nService._('Title_Reservation_CancelTheReservation'),
      message: this.i18nService._('Txt_Reservation_CancelTheReservation'),
    };

    const dialogRef = this.dialog.open(TextareaDialogComponent, {
      data: dialogData,
    });

    // Add in form and update company status
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const data: ReservationCancel = {
          reservationId: this.reservationId,
          reservationCancelationMotive: result,
        };
        this.reservationService.cancelReservation(data).subscribe(() => {
          // Display success message and redirection to dashboards reservation
          this.snackbarService.infoI18n('Txt_Reservation_SuccessCancelTheReservation');
          this.router.navigate([Sitemap.dashboards.reservations.path]);
        });
      }
    });
  }

  /**
   * nextStep method
   *
   * @memberof RegisterFormReservationComponent
   */
  public nextStep(): void {
    this.isSubmit = true;
    if (this.stepOneForm.valid || this.stepOneForm.disabled) {
      this.matStepper.next();
    } else {
      // Display snackbar error
      this.snackbarService.error('Error_SnackBar_FormIncomplete', true);
    }
  }

  /**
   * onSelectionChange method
   *
   * @param selection
   * @memberof RegisterFormReservationComponent
   */
  public onSelectionChange(selection: { selectedIndex: number }): void {
    this.selectedIndex = selection.selectedIndex;
  }

  /**
   * submitReservation method
   *
   * @memberof RegisterFormReservationComponent
   */
  public submitReservation(): void {
    if (this.stepOneForm.valid && this.stepTwoForm.valid) {
      const reservationForm = {
        ...this.stepOneForm.value,
        ...this.stepTwoForm.value,
      };

      const notaryMeetingDate = new Date(reservationForm.notaryMeetingDate).getTime();
      const notarySignatureDate = new Date(reservationForm.notarySignatureDate).getTime();
      // Check dates
      if (reservationForm.notarySignatureDate && reservationForm.notaryMeetingDate && notarySignatureDate < notaryMeetingDate) {
        this.snackbarService.errorI18n('Error_SnackBar_NotarySignatureDateBeforeNotaryMeetingDate');

        return;
      }
      const loanOfferDate = new Date(reservationForm.loanOfferDate).getTime();
      if (reservationForm.notaryMeetingDate && reservationForm.loanOfferDate && notaryMeetingDate < loanOfferDate) {
        this.snackbarService.errorI18n('Error_SnackBar_NotaryMeetingDateBeforeLoanOfferDate');

        return;
      }
      const principleAgreementDate = new Date(reservationForm.principleAgreementDate).getTime();
      if (reservationForm.loanOfferDate && reservationForm.principleAgreementDate && loanOfferDate < principleAgreementDate) {
        this.snackbarService.errorI18n('Error_SnackBar_LoanOfferDateBeforePrincipleAgreementDate');

        return;
      }
      const sruResevationNotificationDate = new Date(reservationForm.sruResevationNotificationDate).getTime();
      if (
        reservationForm.principleAgreementDate &&
        reservationForm.sruResevationNotificationDate &&
        principleAgreementDate < sruResevationNotificationDate
      ) {
        this.snackbarService.errorI18n('Error_SnackBar_PrincipleAgreementDateBeforeSruResevationNotificationDate');

        return;
      }
      const valoReservationValidationDate = new Date(reservationForm.valoReservationValidationDate).getTime();
      const valoReservationFileReceiptDate = new Date(reservationForm.valoReservationFileReceiptDate).getTime();
      if (
        reservationForm.valoReservationValidationDate &&
        reservationForm.valoReservationFileReceiptDate &&
        valoReservationValidationDate < valoReservationFileReceiptDate
      ) {
        this.snackbarService.errorI18n('Error_SnackBar_ValoReservationValidationDateBeforeValoReservationFileReceiptDate');

        return;
      }

      if (reservationForm.withdrawMotive) {
        reservationForm.withdrawId = reservationForm.withdrawMotive;
        delete reservationForm.withdrawMotive;
      }
      this.updateReservation(reservationForm);
    } else {
      // Display snackbar error
      this.snackbarService.errorI18n('Error_SnackBar_FormIncomplete');
    }
  }

  /**
   * Redirect and notify.
   *
   * @memberof RegisterFormReservationComponent
   */
  redirect(): void {
    this.router.navigate([Sitemap.utils.login.path]);
  }

  public setParentForm(formGroup: UntypedFormGroup, formGroupLabel: string): void {
    this[formGroupLabel] = formGroup;
    this[formGroupLabel].updateValueAndValidity();
    this.changeDetectorRef.detectChanges();
  }

  private _computeUploadFile(reservationForm: any): {
    attachementToUploadList: AttachmentUploadData[];
    attachmentAlreadyUpload: any[];
  } {
    const attachementToUploadList: AttachmentUploadData[] = [];
    const attachmentAlreadyUpload: any[] = [];

    Object.keys(this._reservationDocs).forEach((key) => {
      const reseDoc = this._reservationDocs[key];
      const doc = FormUtilsService.getFormValue(reseDoc.form, this.stepTwoForm);
      if (doc && doc instanceof File) {
        attachementToUploadList.push({
          file: doc,
          documentType: reseDoc.documentType,
        });
        if (reseDoc.documentType === 'reservationFile') {
          this._reservationDocFileChanged = true;
        }
      } else if (doc && doc.id) {
        attachmentAlreadyUpload.push({
          id: reservationForm && reservationForm[reseDoc.form] ? reservationForm[reseDoc.form].id : undefined,
          field: reseDoc.documentType,
        });
      }
    });

    return { attachementToUploadList, attachmentAlreadyUpload };
  }

  private updateReservation(reservationForm: any): void {
    this.spinnerWithBackdropService.show();
    const { attachementToUploadList, attachmentAlreadyUpload } = this._computeUploadFile(reservationForm);
    if (attachementToUploadList && attachementToUploadList.length) {
      this.attachmentService
        .uploadReservationDocumentsFiles(
          attachementToUploadList,
          this.appConfigService._('containerName', 'reservationDocuments'),
          this.reservationInformations.lotId,
          this.reservationInformations.prospectId,
          this.reservationInformations.dossierProspectId,
        )
        .pipe(map((response: any) => response.result.files.file))
        .subscribe(
          (res) => {
            this._attachAllIds(res, attachmentAlreadyUpload, reservationForm);

            this._updateReservation(reservationForm);
          },
          (error: any) => {
            console.error('Unable to upload reservation documents', error);
            console.error('Unable to upload reservation documents', error);
            this.spinnerWithBackdropService.hide();
          },
        );
    } else {
      this._attachAllIds([], attachmentAlreadyUpload, reservationForm);
      this._updateReservation(reservationForm);
    }
  }

  private _attachAllIds(res: any[], attachmentAlreadyUpload: any[], reservationForm: any) {
    res.forEach((file) => {
      this._attachIdToRes(reservationForm, file.field, file.id);
    });
    attachmentAlreadyUpload.forEach((file) => {
      this._attachIdToRes(reservationForm, file.field, file.id);
    });
  }

  private _updateReservation(reservationForm: any) {
    this.reservationService.updateReservation(this.reservationId, reservationForm).subscribe(
      () => {
        this.spinnerWithBackdropService.hide();
        this.snackbarService.infoI18n('Info_SnackBar_UpdateReservationSuccess');
        if (this._reservationDocFileChanged) {
          this._googleTagManagerService.pushTag({
            event: 'reservation_contract',
            lot: { Id: this.reservationInformations.lotId },
            program: { name: this.reservationInformations.lot.program.programName },
          });
          this._reservationDocFileChanged = false;
        }
        this.router.navigate([Sitemap.dashboards.reservations.path]);
      },
      () => {
        this.spinnerWithBackdropService.hide();
      },
    );
  }

  private _attachIdToRes(reservationForm: any, field: string, id) {
    const resaDoc = this._reservationDocs[field];
    reservationForm[resaDoc.column] = id;
  }
}
