import { Injectable } from '@angular/core';
import {
  CompleteDossierResponse,
  DossierSearchPreferenceResponseDto,
  SearchCriteria,
  SearchDossierSearchPreferenceQuery,
} from '@commons-dto/dossier-prospect';

import { DossierSearchPreferenceApiService } from '../adapters/dossier-search-preference-api.service';

@Injectable({
  providedIn: 'root',
})
export class DossierSearchPreferenceService {
  constructor(private readonly dossierSearchPreferenceApiService: DossierSearchPreferenceApiService) {}

  /**
   * Retrieves search preferences for a given dossier by its ID.
   * The search query can be customized with various parameters to filter,
   * sort, and specify which data to fetch.
   *
   * Example of a customized search query:
   * ```
   * const searchQuery = new SearchDossierSearchPreferenceQuery();
   * searchQuery.columns = ['searchCriteria.category', 'dossier.prospects.email'];
   * searchQuery.offset = 1;
   * searchQuery.limit = 1;
   * searchQuery.excludedValue = '3';
   * searchQuery.sortColumn = 'id';
   * searchQuery.sortDirection = SortDirection.ASC;
   * searchQuery.relations = ['dossier.prospects', 'searchCriteria'];
   * ```
   *
   * @param dossierId - ID of dossier to fetch search preferences for.
   * @returns A promise of an array of DossierSearchPreferenceResponseDto.
   */
  public async getByDossierId(dossierId: number): Promise<DossierSearchPreferenceResponseDto[]> {
    const searchQuery: SearchDossierSearchPreferenceQuery = new SearchDossierSearchPreferenceQuery();
    return this.dossierSearchPreferenceApiService.getByDossierId(dossierId, searchQuery);
  }

  public async saveByDossierId(dossierId: number, dossierSearchPreferences: DossierSearchPreferenceResponseDto[]): Promise<void> {
    return this.dossierSearchPreferenceApiService.saveByDossierId(dossierId, dossierSearchPreferences);
  }

  public async upsertByDossierId(dossierId: number, dossierSearchPreferences: DossierSearchPreferenceResponseDto[]): Promise<void> {
    return this.dossierSearchPreferenceApiService.upsertByDossierId(dossierId, dossierSearchPreferences);
  }

  public getSearchPreferenceValue(dossier: CompleteDossierResponse, searchCriteria: SearchCriteria): any {
    const searchPreference = dossier.summarySearchPreferences.find(
      (searchPreference) => searchPreference.searchCriteriaCode === searchCriteria,
    );
    return searchPreference ? searchPreference.value : null;
  }
}
