import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { DossierProspectService } from './dossier-prospect.service';

import { sanitize } from '../../utils/services/utils/file-utils';
import { AutoCompleteService } from './../../common/form/auto-complete.service';

@Injectable({
  providedIn: 'root',
})
export class DevCompanySearch implements AutoCompleteService {
  companies: { label: string; id: number }[];

  constructor(private _prospect: DossierProspectService) {
    this._prospect.devCompaniesSub.subscribe((companies) => {
      if (companies) {
        this.companies = companies
          .map((company) => {
            return { id: company.id, label: company.corporateName };
          })
          .sort((a, b) => a.label.localeCompare(b.label, 'fr'));
      }
    });
  }

  findData(filter: string): Observable<{ label: string; id: number }[]> {
    filter = sanitize(filter);
    const results = this.companies.filter((company) => sanitize(company.label).match(`${filter}`));
    return of(results);
  }
}
