<div class="grid grid-cols-8 gap:4 lg:gap-6">
  <div class="col-span-4">
    <mat-form-field class="fullW flex w-full">
      <mat-label>{{ i18nService._('Txt_Placeholder_Filter') }}</mat-label>
      <input [(ngModel)]="filterValue" (ngModelChange)="textFilterSubject.next($event)" matInput data-cy="filterMandateStrategies" />
    </mat-form-field>
  </div>
</div>

<app-grid-tracking-table
  [columnDefs]="columnDefs"
  [tableLoader]="tableLoader"
  [pageSize]="pageSize"
  [tableHeight]="tableHeight"
  [serverSide]="serverSide"
  [gridOptions]="gridOptions"
  (nbItems)="nbItemsChange($event)"
  [textFilter]="textFilterValue"
>
</app-grid-tracking-table>
