import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import moment from 'moment-timezone';
import { finalize, Subscription } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgClass, NgFor, NgIf } from '@angular/common';

import { ProgramPageDocumentViewModel } from '../../../programs/models/ProgramPageDocumentViewModel';
import { ProgramService } from '../../../programs/services/program.service';
import { DocumentResponse } from '../../models/DocumentResponse';
import { LotDetailResponse } from '../../models/LotDetailResponse';
import { LotDetailsDocumentViewModel } from '../../models/LotDetailsDocumentViewModel';
import { AppConfigService } from '../../services/app-config.service';
import { TokenService } from '../../services/authorisation/token.service';
import { ConfigurableParamService } from '../../services/configurable-param.service';
import { FileSystemService } from '../../services/file-system.service';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-documents-box',
  templateUrl: './documents-box.component.html',
  styleUrls: ['./documents-box.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, MatCheckboxModule, FormsModule, NgClass, MatButtonModule, MatProgressSpinnerModule],
})
export class DocumentsBoxComponent implements OnChanges, OnDestroy, OnInit {
  @Input() public documents: Array<DocumentResponse>;
  @Input() public programName: string;
  @Input() public programTypeLabel: string;
  @Input() public lotId: number;
  @Input() public programId: number;
  @Input() public title: string;
  @Output() public readonly actionEvent: EventEmitter<string> = new EventEmitter<string>();

  public lotPlanDoc;
  public presentationsDocs: Array<LotDetailsDocumentViewModel> = [];
  public contratsDocs: Array<LotDetailsDocumentViewModel> = [];
  public downloadButtonDisabled: boolean;
  public counter: number;
  public externalDocumentsLink: string;
  /**
   * downloading status
   *
   * @type {string}
   */
  public downloading = false;
  /**
   * downloading email status
   *
   * @type {string}
   */
  public isDownloadingByEmail = false;
  private readonly emailString: string;
  /**
   * documentTypes attribute
   *
   * @type {object}
   */
  private readonly documentTypes: { [key: string]: string };
  /**
   * programTypes attribute
   *
   * @type {object}
   */
  private readonly programTypes: { [key: string]: string };
  /**
   * selectedLotDetailSubscription attribute
   *
   * @type {Subscription}
   */
  private selectedLotDetailSubscription: Subscription;
  /**
   * specialOfferCode attribute
   *
   * @type {string}
   */
  private readonly specialOfferCode: string;

  constructor(
    public readonly appConfigService: AppConfigService,
    public readonly fileSystemService: FileSystemService,
    public readonly i18nService: I18nService,
    public readonly tokenService: TokenService,
    public readonly programService: ProgramService,
    public readonly configurableParamService: ConfigurableParamService,
  ) {
    this.programTypes = this.appConfigService.getAppConfig().programTypes;
    this.documentTypes = this.appConfigService.getAppConfig().documentTypes;
    const documentTypeCode = this.appConfigService.getAppConfig().documentTypeCode;
    this.lotPlanDoc = new LotDetailsDocumentViewModel(this.documentTypes.lotPlan);
    this.presentationsDocs = [
      this.lotPlanDoc,
      new LotDetailsDocumentViewModel(this.documentTypes.CommercialBrochure),
      new LotDetailsDocumentViewModel(this.documentTypes.presentationOfTheProgram),
      new LotDetailsDocumentViewModel(this.documentTypes.descriptiveNotice),
      new LotDetailsDocumentViewModel(this.documentTypes.groundPlan),
    ];
    this.contratsDocs = [
      new LotDetailsDocumentViewModel(this.documentTypes.ApartmentContractualDocumentPackage),
      new LotDetailsDocumentViewModel(this.documentTypes.HouseContractualDocumentPackage),
      new LotDetailsDocumentViewModel(this.documentTypes.TradeContractualDocumentPackage),
      new LotDetailsDocumentViewModel(this.documentTypes.guaranteeFormula),
    ];
    this.specialOfferCode = documentTypeCode.SpecialOfferAmendment;

    // assign code document from global.js
    this.contratsDocs.concat(this.presentationsDocs).forEach((docViewModel) => {
      docViewModel.code = documentTypeCode[docViewModel.label];
    });
    this.downloadButtonDisabled = true;
  }

  /**
   * Used to buildMailTo with encoding all contents
   * @param address string
   * @param subject string
   * @param body string
   */
  static buildMailTo(address, subject, body): string {
    const encodedAddress = encodeURIComponent(address);
    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);

    return `mailto:${encodedAddress}?subject=${encodedSubject}&body=${encodedBody}`;
  }

  ngOnInit(): void {
    this.configurableParamService.externalDocumentsLink$.subscribe(() => {
      this.externalDocumentsLink = this.configurableParamService.getExternalLinkByLabelId('Txt_Link_External_Documents_Utils');
    });
  }

  /**
   * ngOnChanges method
   */
  ngOnChanges(): void {
    if (this.documents) {
      this.contratsDocs = this.contratsDocs.filter((val) => val.label !== this.documentTypes.SpecialOfferAmendment);
      const soDocument = this.documents.find((value) => value.type.code === this.specialOfferCode);
      if (soDocument) {
        const specialOfferDoc = new ProgramPageDocumentViewModel(this.documentTypes.SpecialOfferAmendment);
        specialOfferDoc.code = this.specialOfferCode;
        specialOfferDoc.document = soDocument;
        specialOfferDoc.textArguments = soDocument.parameterText;
        this.contratsDocs.push(specialOfferDoc);
      }
    }
    this.listenToSelectedLotDetails();
    this.initDocsViewModel(this.contratsDocs.concat(this.presentationsDocs));
    this.showOrHideCheckBoxesContractDocs();
    this.downloadButtonDisabled = true;
  }

  /**
   * On checkbox label click download document
   *
   * @param {LotDetailsDocumentViewModel} docViewModel
   */
  public downloadDocument(docViewModel: LotDetailsDocumentViewModel): void {
    this.fileSystemService.openDocumentInBrowser(docViewModel.document, this.getDocumentFileName(docViewModel));
  }

  /**
   * On click on download button generate zip folder
   */
  public downloadAndZipSelectedDocs(): void {
    const zip: JSZip = new JSZip();
    const selectedDocsCont = this.contratsDocs.filter((docViewModel) => docViewModel.actions.checked);
    const selectedDocsPres = this.presentationsDocs.filter((docViewModel) => docViewModel.actions.checked);
    const totalFiles: number = selectedDocsCont.length + selectedDocsPres.length;
    this.counter = 0;
    this.downloading = true;
    // folder "documents de presentation"
    this.folderZip(zip, selectedDocsPres, totalFiles, false);
    // folder "documents contractuels"
    this.folderZip(zip, selectedDocsCont, totalFiles, true);
    this.actionEvent.emit('download');
  }

  /**
   * Zip a folder.
   *
   * @param {JSZip} zip object
   * @param {Array<LotDetailsDocumentViewModel>} documents to zip
   * @param {number} totalFiles
   * @param {boolean} isContract
   */
  public folderZip(zip: JSZip, documents: Array<LotDetailsDocumentViewModel>, totalFiles: number, isContract: boolean): void {
    const folderName = this.i18nService._(isContract ? 'Title_BookingAndGuaranteesBook' : 'Title_PresentationDocumentation');
    const folder = zip.folder(folderName);
    documents.forEach((docViewModel) => {
      this.fileSystemService
        .downloadFile(docViewModel.document)
        .pipe(
          finalize(() => {
            this.counter++;
            if (this.counter === totalFiles) {
              this.downloadZip(zip);
            }
          }),
        )
        .subscribe((response) => {
          folder.file(this.getDocumentFileName(docViewModel), response);
        });
    });
  }

  /**
   * Disable button if at least one document is checked in contratsDocs or presentationsDocs
   */
  public isAtLeastOneChecked(): void {
    this.downloadButtonDisabled =
      !this.contratsDocs.find((docViewModel) => docViewModel.actions.checked) &&
      !this.presentationsDocs.find((docViewModel) => docViewModel.actions.checked);
  }

  /**
   * Get today
   *
   * @return {string} return string date with format 'YYYY-MM-DD-HHmmss'
   */
  public getDateStringFormat(): string {
    return moment().tz(this.appConfigService.getAppConfig().timeZone).format('YYYY-MM-DD-HHmmss');
  }

  /**
   * Translate filename.
   *
   * @param {LotDetailsDocumentViewModel} docViewModel
   * @return {string} formated filename
   */
  public getFileName(docViewModel: LotDetailsDocumentViewModel): string {
    if (docViewModel.textArguments) {
      return this.i18nService._(`Txt_Commercial_Program_Sheet_${docViewModel.code}`, docViewModel.textArguments);
    }

    return this.i18nService._(`Txt_Commercial_Program_Sheet_${docViewModel.code}`);
  }

  /**
   * On click prepare mail and open it in new page
   */
  public mailSelectedDocs(): void {
    // Show loader when prepare email
    this.isDownloadingByEmail = true;

    const enumReturnTranslateDocument = new Map([
      ['CommercialBrochure', this.i18nService._('Txt_Commercial_Program_Sheet_CB')],
      ['presentationOfTheProgram', this.i18nService._('Txt_Commercial_Program_Sheet_PP')],
      ['lotPlan', this.i18nService._('Txt_Commercial_Program_Sheet_LP')],
      ['descriptiveNotice', this.i18nService._('Txt_Commercial_Program_Sheet_DN')],
      ['ApartmentReservationContract', this.i18nService._('Txt_Commercial_Program_Sheet_ARC')],
      ['HouseReservationContract', this.i18nService._('Txt_Commercial_Program_Sheet_HRC')],
      ['tradeReservationContract', this.i18nService._('Txt_Commercial_Program_Sheet_TRC')],
      ['perspective', this.i18nService._('Txt_Commercial_Program_Sheet_CB')],
      ['gfaAttestation', this.i18nService._('Txt_Commercial_Program_Sheet_CB')],
      ['groundPlan', this.i18nService._('Txt_Commercial_Program_Sheet_GP')],
      ['Mandate', this.i18nService._('Txt_Commercial_Program_Sheet_CB')],
      ['ApartmentContractualDocumentPackage', this.i18nService._('Txt_Commercial_Program_Sheet_ACDP')],
      ['HouseContractualDocumentPackage', this.i18nService._('Txt_Commercial_Program_Sheet_HCDP')],
      ['TradeContractualDocumentPackage', this.i18nService._('Txt_Commercial_Program_Sheet_TCDP')],
      ['guaranteeFormula', this.i18nService._('Txt_Commercial_Program_Sheet_GF')],
      ['SpecialOfferAmendment', this.i18nService._('Txt_Commercial_Program_Sheet_SOAM_mail')],
    ]);

    const selectedDocsCont = this.contratsDocs.filter((docViewModel) => docViewModel.actions.checked);
    const selectedDocsPres = this.presentationsDocs.filter((docViewModel) => docViewModel.actions.checked);
    const loggedUserInfos = this.tokenService.getToken();
    let listOfDocument = '\u000A';

    const getLabelAndUrlOfDocument = (element: LotDetailsDocumentViewModel): string => {
      return `${enumReturnTranslateDocument.get(element.label)} : ${this.fileSystemService.getDownloadUrlOfDocument(element.document, {
        programId: this.programId,
        lotId: this.lotId,
      })} \u000A`;
    };

    selectedDocsPres.forEach((element) => {
      listOfDocument += getLabelAndUrlOfDocument(element);
    });

    selectedDocsCont.forEach((element) => {
      listOfDocument += getLabelAndUrlOfDocument(element);
    });

    const bodyMail = `Bonjour ${this.i18nService._(loggedUserInfos.civility)} ${loggedUserInfos.lastName},

    Je pense que le programme ${this.programName} peut vous intéresser, cliquez sur les liens ci-dessous pour en savoir plus :
    ${listOfDocument}`;

    const subject = `[VALORISSIMO] Découvrez le programme ${this.programName}`;

    const emailToSend = DocumentsBoxComponent.buildMailTo('nullepart@mozilla.org', subject, bodyMail);

    location.href = emailToSend;

    // Toggle loader
    this.isDownloadingByEmail = false;
    this.actionEvent.emit('email');

    return;
  }

  ngOnDestroy(): void {
    this.selectedLotDetailSubscription.unsubscribe();
    this.configurableParamService.externalDocumentsLink$.unsubscribe();
  }

  /**
   * onInit component populate Contracts / Presentation documents.
   *
   * @param {Array<LotDetailsDocumentViewModel>} docsViewModel
   */
  private initDocsViewModel(docsViewModel: Array<LotDetailsDocumentViewModel>): void {
    for (const docViewModel of docsViewModel) {
      docViewModel.document = this.documents.find(
        (doc) => doc.codeLabel === docViewModel.code || (doc.type && doc.type.code === docViewModel.code),
      );
      docViewModel.actions.disabled = !docViewModel.document;
      docViewModel.actions.display = true;
      docViewModel.actions.checked = false;
    }
  }

  /**
   * onInit check preconditions to show or hide contract documents (checkboxes)
   */
  private showOrHideCheckBoxesContractDocs(): void {
    for (const docViewModel of this.contratsDocs) {
      if (
        docViewModel.label !== this.documentTypes.guaranteeFormula &&
        docViewModel.label !== this.documentTypes.SpecialOfferAmendment &&
        ((docViewModel.label !== this.documentTypes.HouseContractualDocumentPackage && this.programTypeLabel === this.programTypes.HOUSE) ||
          (docViewModel.label !== this.documentTypes.ApartmentContractualDocumentPackage &&
            this.programTypeLabel === this.programTypes.APARTMENT) ||
          (docViewModel.label !== this.documentTypes.TradeContractualDocumentPackage && this.programTypeLabel === this.programTypes.TRADE))
      ) {
        docViewModel.actions.display = false;
      }
    }
  }

  /**
   * Download zip file
   *
   * @params {JSZip} zip object
   */
  private downloadZip(zip: JSZip): void {
    const today = this.getDateStringFormat();
    const filename = `valorissimo-${this.programName}-${today}.zip`;
    zip.generateAsync({ type: 'blob' }).then((content) => {
      this.downloading = false;
      saveAs(content, filename);
    });
  }

  /**
   * Get document filename.
   *
   * @param {LotDetailsDocumentViewModel} docViewModel
   * @return {string} formated filename
   */
  private getDocumentFileName(docViewModel: LotDetailsDocumentViewModel): string {
    const lastIndexOfDot = docViewModel.document.fileName.lastIndexOf('.');
    if (lastIndexOfDot > -1) {
      const extension = docViewModel.document.fileName.substr(lastIndexOfDot);
      // Max length extension is .jpeg (5)
      if (extension.length < 6) {
        return this.getFileName(docViewModel) + extension;
      }
    }

    return docViewModel.document.fileName;
  }

  /**
   * Listeners
   */
  private listenToSelectedLotDetails(): void {
    this.selectedLotDetailSubscription = this.programService.selectedLotDetail.subscribe((lotDetail: LotDetailResponse) => {
      if (!lotDetail) {
        return;
      }

      this.contratsDocs = this.contratsDocs.filter((docViewModel) => docViewModel.code !== this.specialOfferCode);

      this.lotPlanDoc.actions.checked = false;
      this.lotPlanDoc.actions.disabled = true;
      this.lotPlanDoc.document = undefined;

      this.lotId = lotDetail.lotId;

      this.lotPlanDoc.document = {
        title: lotDetail.lotPlanTitle,
        container: lotDetail.lotPlanContainer,
        fileName: lotDetail.lotPlanFileName,
        mimeType: lotDetail.lotPlanMimeType,
      };
      this.lotPlanDoc.actions.disabled = false;

      lotDetail.specialOffers.forEach((offer) => {
        // Display attachment of the special offer if it exist (the attachment of a special offer is not required)
        if (offer.amendment.fileName) {
          const specialOfferDoc = new ProgramPageDocumentViewModel(this.specialOfferCode);
          specialOfferDoc.code = this.specialOfferCode;
          specialOfferDoc.document = offer.amendment;
          specialOfferDoc.textArguments = [offer.title];
          this.contratsDocs.push(specialOfferDoc);
        }
      });
    });
  }
}
