import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis',
  standalone: true,
})
export class EllipsisPipe implements PipeTransform {
  public transform(val: string, args?: number): string {
    if (args === undefined) {
      return val;
    }

    if (val && val.length > args) {
      return `${val.substring(0, args)}...`;
    }

    return val;
  }
}
