import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';

import { LocalStorageService } from '../../../utils/services/local-storage.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { AppIconsName } from '../../../common/standalone/icon/utils/app-icon-names';
import { SafePipe } from '../../../utils/pipes/safe.pipe';
import { IconComponent } from '../../../common/standalone/icon/icon.component';

@Component({
  selector: 'app-choose-dialog',
  templateUrl: './choose-dialog.component.html',
  styleUrls: ['./choose-dialog.component.scss'],
  standalone: true,
  imports: [IconComponent, NgIf, MatDialogModule, NgFor, SafePipe],
})
export class ChooseDialogComponent implements OnInit {
  skipNextTime = false;
  hasHTMLMessage = false;

  constructor(
    public _i18nService: I18nService,
    private _storageService: LocalStorageService,
    private dialogRef: MatDialogRef<ChooseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChooseDialogData,
  ) {}

  ngOnInit(): void {
    if (this.data.hasHTMLMessage || (this.data.message && /.*<.*/.test(this.data.message))) {
      this.hasHTMLMessage = true;
    }
  }

  closeDialog(option?: ChooseDialogOption) {
    this.dialogRef.close(option);
  }

  chosen(option: ChooseDialogOption) {
    option.action();
    this.closeDialog(option);
  }
}

export interface ChooseDialogData {
  title: string;
  /* The message can contains html tags.
   * It must be translated before passing
   */
  message?: string;
  hasHTMLMessage?: boolean;
  imageSource?: string;
  options: ChooseDialogOption[];
}

interface ChooseDialogOption {
  icon: AppIconsName;
  title: string;
  action: () => void;
}
