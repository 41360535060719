import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroupDirective,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import moment from 'moment-timezone';
import { Subscription } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgStyle } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';

import { MandateStrategySelectionTableDataType } from '../enums/mandate-strategy-selection-table-data-type.enum';

import { AlotmentSelectionTableComponent } from './../../../tracking-tables/components/alotment-selection-table/alotment-selection-table.component';
import { MandateCompaniesSelectionTableComponent } from './../../../tracking-tables/components/mandate-companies-selection-table/mandate-companies-selection-table.component';
import { ProgramMandateStrategiesTrackingTableComponent } from './../../../tracking-tables/components/program-mandate-strategies-tracking-table/program-mandate-strategies-tracking-table.component';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { MandateStrategyCreate } from '../../../utils/models/MandateStrategyCreate';
import { MandatStrategyResponse } from '../../../utils/models/MandatStrategyResponse';
import { SnackbarMessage } from '../../../utils/models/SnackbarMessage';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { SpinnerWithBackdropService } from '../../../utils/services/spinner-with-backdrop.service';
import { MandateStrategyService } from '../../services/mandate-strategy.service';
import { MandateCompaniesSelectionTableComponent as MandateCompaniesSelectionTableComponent_1 } from '../../../tracking-tables/components/mandate-companies-selection-table/mandate-companies-selection-table.component';
import { AlotmentSelectionTableComponent as AlotmentSelectionTableComponent_1 } from '../../../tracking-tables/components/alotment-selection-table/alotment-selection-table.component';
import { MatVerticalStepperScrollerDirective } from '../../../utils/directives/mat-vertical-stepper-scroller.directive';
import { InputDateComponent } from '../../../utils/components/input-date/input-date.component';
import { DecimalMaskDirective } from '../../../utils/directives/decimal-mask.directive';
import { ProgramMandateStrategiesTrackingTableComponent as ProgramMandateStrategiesTrackingTableComponent_1 } from '../../../tracking-tables/components/program-mandate-strategies-tracking-table/program-mandate-strategies-tracking-table.component';

@Component({
  selector: 'app-register-form-mandate-strategy',
  templateUrl: './register-form-mandate-strategy.component.html',
  styleUrls: ['./register-form-mandate-strategy.component.scss'],
  standalone: true,
  imports: [
    ProgramMandateStrategiesTrackingTableComponent_1,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    DecimalMaskDirective,
    NgIf,
    InputDateComponent,
    MatStepperModule,
    MatVerticalStepperScrollerDirective,
    AlotmentSelectionTableComponent_1,
    MatButtonModule,
    MandateCompaniesSelectionTableComponent_1,
    NgStyle,
  ],
})
export class RegisterFormMandateStrategyComponent implements OnDestroy, OnInit {
  /**
   * endDateControl attribute
   *
   * @type {FormControl}
   * @memberof RegisterFormMandateStrategyComponent
   */
  public endDateControl: FormControl;

  /**
   * mandateStrategiesChange output
   *
   * @type {EventEmitter<number>>}
   * @memberof RegisterFormMandateStrategyComponent
   */
  @Output() public readonly numberOfmandateStrategies: EventEmitter<number> = new EventEmitter<number>();

  @Input() numberOfMandateStrategy: number;

  /**
   * mandateStrategyCompaniesType attribute
   *
   * @type {MandateStrategySelectionTableDataType}
   * @memberof RegisterFormMandateStrategyComponent
   */
  public mandateStrategyCompaniesType: MandateStrategySelectionTableDataType = MandateStrategySelectionTableDataType.COMPANIES;

  /**
   * mandateStrategyForm attribute
   *
   * @type {FormGroup}
   * @memberof RegisterFormMandateStrategyComponent
   */
  public mandateStrategyForm: UntypedFormGroup;

  /**
   * mandateStrategyLotsType attribute
   *
   * @type {MandateStrategySelectionTableDataType}
   * @memberof RegisterFormMandateStrategyComponent
   */
  public mandateStrategyLotsType: MandateStrategySelectionTableDataType = MandateStrategySelectionTableDataType.LOTS;

  /**
   * mandateStrategyRecap child attribute
   *
   * @type {MandateStrategiesRecapComponent}
   * @memberof RegisterFormMandateStrategyComponent
   */
  @ViewChild('mandateStrategyRecap')
  public mandateStrategyRecap: ProgramMandateStrategiesTrackingTableComponent;

  /**
   * mandateStrategyRecap child attribute
   *
   * @type {MandateStrategiesRecapComponent}
   * @memberof RegisterFormMandateStrategyComponent
   */
  @ViewChild('mandateStrategyLots')
  public mandateStrategyLots: AlotmentSelectionTableComponent;

  /**
   * mandateStrategyRecap child attribute
   *
   * @type {MandateStrategiesRecapComponent}
   * @memberof RegisterFormMandateStrategyComponent
   */
  @ViewChild('mandateStrategyCompanies')
  public mandateStrategyCompanies: MandateCompaniesSelectionTableComponent;

  /**
   * matStepper child attribute
   *
   * @type {MatStepper}
   * @memberof RegisterFormMandateStrategyComponent
   */
  @ViewChild('stepper') public matStepper: MatStepper;

  /**
   * programId input
   *
   * @type {number}
   * @memberof RegisterFormMandateStrategyComponent
   */
  @Input() public programId: number;
  @Input() public readonly: boolean;

  /**
   * rateControl attribute
   *
   * @type {FormControl}
   * @memberof RegisterFormMandateStrategyComponent
   */
  public rateControl: FormControl;

  /**
   * titleControl attribute
   *
   * @type {FormControl}
   * @memberof RegisterFormMandateStrategyComponent
   */
  public titleControl: FormControl;
  /**
   * typeControl attribute
   *
   * @type {FormControl}
   * @memberof RegisterFormMandateStrategyComponent
   */
  public typeControl: FormControl;
  /**
   * updatingElement attribute
   *
   * @type {MandatStrategyResponse}
   * @memberof RegisterFormMandateStrategyComponent
   */
  public updatingElement: MandatStrategyResponse;
  /**
   * displayEndDate attribute
   *
   * @type {boolean}
   * @memberof RegisterFormMandateStrategyComponent
   */
  public displayEndDate: boolean;
  /**
   * formGroupDirective attribute
   *
   * @type {FormGroupDirective}
   * @memberof TableComponent
   */
  private formGroupDirective: FormGroupDirective;
  /**
   * typeControlValueChangesSubscription attribute
   *
   * @type {Subscription}
   * @memberof RegisterFormMandateStrategyComponent
   */
  private typeControlValueChangesSubscription: Subscription;
  /**
   * list of company ids
   *
   * @type {number[]}
   * @memberof RegisterFormMandateStrategyComponent
   */
  private companyIds: number[];

  /**
   * Creates an instance of RegisterFormMandateStrategyComponent.
   *
   * @param {FormBuilder} formBuilder
   * @param {I18nService} i18nService
   * @param {MandateStrategyService} mandateStrategyService
   * @param {SnackbarService} snackbarService
   * @memberof RegisterFormMandateStrategyComponent
   */
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    public i18nService: I18nService,
    private readonly mandateStrategyService: MandateStrategyService,
    private readonly snackbarService: SnackbarService,
    private readonly spinnerWithBackdropService: SpinnerWithBackdropService,
    private readonly appConfigService: AppConfigService,
  ) {
    this.updatingElement = undefined;
    this.displayEndDate = true;
  }

  @ViewChild(FormGroupDirective, { static: true }) set setFormGroupDirective(formGroupDirective: FormGroupDirective) {
    this.formGroupDirective = formGroupDirective;
  }

  public onGlobalMandateStrategyFound(isGlobalFound: boolean): void {
    // If there is a global mandate strategy so disable choice about type of strategy and set it to specifique
    // else enable choice about type of strategy
    this.typeControl.setValue(false);
    if (isGlobalFound) {
      this.typeControl.disable();
    } else {
      this.typeControl.enable();
    }
  }

  /**
   * onMandateStrategyDeleted method
   *
   * @memberof RegisterFormMandateStrategyComponent
   */
  public onMandateStrategyDeleted(): void {
    this.cancelUpdate();
  }

  /**
   * Init component
   *
   * @memberof RegisterFormMandateStrategyComponent
   */
  public ngOnInit(): void {
    // Definition of form and its controls
    this.mandateStrategyForm = this.formBuilder.group({
      isGlobal: new UntypedFormControl(false),
      title: new UntypedFormControl(undefined),
      rate: new UntypedFormControl(undefined),
      endDate: new UntypedFormControl(undefined),
    });

    this.titleControl = this.mandateStrategyForm.controls.title as FormControl;
    this.rateControl = this.mandateStrategyForm.controls.rate as FormControl;
    this.endDateControl = this.mandateStrategyForm.controls.endDate as FormControl;
    this.typeControl = this.mandateStrategyForm.controls.isGlobal as FormControl;

    // If the type control change, hide/display the last step, and reset rate and endDate
    this.typeControlValueChangesSubscription = this.typeControl.valueChanges.subscribe((value) => {
      this.displayEndDate = !value;
      this.rateControl.setValue(undefined);
      this.endDateControl.setValue(undefined);
    });
    if (this.readonly) {
      Object.keys(this.mandateStrategyForm.controls).forEach((key) => {
        this.mandateStrategyForm.controls[key].disable();
      });
    }
  }

  /**
   * onMandateStrategiesChange method
   *
   * @memberof RegisterFormMandateStrategyComponent
   */
  public onMandateStrategiesChange(event: number): void {
    this.numberOfmandateStrategies.emit(event);
  }

  /**
   * onSubmit method
   *
   * @memberof RegisterFormMandateStrategyComponent
   */
  public onSubmit(): void {
    this.spinnerWithBackdropService.show();
    if (this.mandateStrategyForm.valid) {
      const mandateStrategyCreate: MandateStrategyCreate = this.mandateStrategyForm.value;
      mandateStrategyCreate.programId = this.programId;
      // Always send isGlobal even if the chip is disable
      mandateStrategyCreate.isGlobal = this.typeControl.value;
      const mandatStrategyToUpdateId: number = this.updatingElement ? this.updatingElement.id : undefined;

      // Mutates the original moment date by setting it to the end of a unit of time. (End of day)
      // * set millisecond to zero used to handle unexpected server round value
      if (mandateStrategyCreate.endDate) {
        const timeZoneDefault = this.appConfigService.getAppConfig().timeZone;

        const putEndDateOnEndDay = moment(mandateStrategyCreate.endDate).tz(timeZoneDefault).endOf('day').milliseconds(0);

        mandateStrategyCreate.endDate = putEndDateOnEndDay;
      }

      this.mandateStrategyService.createOrUpdateMandateStrategy(mandateStrategyCreate, mandatStrategyToUpdateId).subscribe(
        (response) => {
          this.spinnerWithBackdropService.hide();
          const message: SnackbarMessage = {
            text: this.i18nService._(
              this.updatingElement ? 'Success_SnackBar_MandateStrategyModified' : 'Success_SnackBar_MandateStrategyCreated',
            ),
            type: SnackbarMessageType.Info,
          };
          this.snackbarService.sendMessage(message);
          if (response.companiesWarning) {
            const warning: SnackbarMessage = {
              text: response.companiesWarning,
              type: SnackbarMessageType.Error,
              duration: undefined,
            };
            this.snackbarService.sendMessage(warning);
          }
          // Reset form
          this.resetForm();
          // Update mandate strategies table
          this.mandateStrategyRecap.refreshRows();
          this.updatingElement = undefined;
        },
        () => {
          this.spinnerWithBackdropService.hide();
        },
      );
    } else {
      const message: SnackbarMessage = {
        text: this.i18nService._('Error_SnackBar_FormIncomplete'),
        type: SnackbarMessageType.Error,
      };
      this.snackbarService.sendMessage(message);
      this.spinnerWithBackdropService.hide();
    }
  }

  public setcompanyIds(companyIds: number[]) {
    this.companyIds = companyIds;
  }

  public fillFormWithMandateStrategies(element: MandatStrategyResponse): void {
    this.updatingElement = element;
    this.populateCompanyIds();
    this.populateLotIds();
    this.disableAndSetTypeControl();
    this.populateFormFields(['endDate', 'rate', 'title']);
  }

  private populateCompanyIds(): void {
    const uniqueCompanyIds = this.getUniqueFilteredIds(this.updatingElement.companyIds);
    this.mandateStrategyForm.controls.companyIdsList.setValue(uniqueCompanyIds);
  }

  private populateLotIds(): void {
    const uniqueLotIds = this.getUniqueFilteredIds(this.updatingElement.lotIds);
    this.mandateStrategyForm.controls.lotIdsList.setValue(uniqueLotIds);
  }

  private getUniqueFilteredIds(ids: Array<number | null>): number[] {
    return [...new Set(ids.filter(Boolean))];
  }

  private disableAndSetTypeControl(): void {
    this.typeControl.disable();
    this.typeControl.setValue(!!this.updatingElement.isGlobal);
  }

  private populateFormFields(fields: string[]): void {
    fields.forEach((field) => {
      this.mandateStrategyForm.controls[field].setValue(this.updatingElement[field]);
    });
  }

  public cancelUpdate(): void {
    // First remove error message
    if (this.formGroupDirective) {
      this.formGroupDirective.resetForm();
    }
    // At this moment, typeControl is disable, enable it if there is no global mandate strategy yet
    if (!this.mandateStrategyRecap.getIsGlobalFound()) {
      this.typeControl.enable();
    }
    this.typeControl.setValue(false);

    this.updatingElement = undefined;
    // Reset classic fields
    ['endDate', 'rate', 'title'].forEach((controlName) => {
      this.mandateStrategyForm.controls[controlName].setValue(undefined);
    });
    // Reset value controls
    this.mandateStrategyForm.controls.companyIdsList.setValue([]);
    this.mandateStrategyForm.controls.lotIdsList.setValue([]);
    this.mandateStrategyLots.reset();
    this.mandateStrategyCompanies.reset();
  }

  /**
   * ngOnDestroy method
   *
   * @memberof RegisterFormMandateStrategyComponent
   */
  ngOnDestroy(): void {
    this.typeControlValueChangesSubscription.unsubscribe();
  }

  /**
   * resetForm method
   *
   * @memberof RegisterFormMandateStrategyComponent
   */
  private resetForm(): void {
    this.matStepper.reset();
    this.mandateStrategyForm.reset();
    if (this.formGroupDirective) {
      this.formGroupDirective.resetForm();
    }
    this.mandateStrategyLots.reset();
    this.mandateStrategyCompanies.reset();
  }

  isAlotmentSelectionCompleted(): boolean {
    const values = this.mandateStrategyForm.value;

    const isLotIdsListValid = Array.isArray(values.lotIdsList) && values.lotIdsList.length > 0;
    const isRateValid = !!values.rate;
    const isTitleValid = !!values.title && values.title.trim() !== '';

    const isGlobalStrategy = this.typeControl.value;

    if (isGlobalStrategy) {
      return isLotIdsListValid && isRateValid && isTitleValid;
    }
    const isEndDateValid = !!values.endDate;
    return isEndDateValid && isLotIdsListValid && isRateValid && isTitleValid;
  }

  nextStep() {
    if (this.isAlotmentSelectionCompleted()) {
      this.matStepper.next();
    }
  }
}
