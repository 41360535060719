<div class="relative h-full flex flex-col flex-1 w-full bg-graylight pt-32 md:pt-18">
  <app-search-fix-menu
    (searchEvent)="onSearch($event)"
    [orderBy]="orderBy"
    class="fixed top-22 left-0 w-full lg:h-18 bg-white shadow-md py-2 lg:py-0 px-2 lg:px-5 z-10"
  >
  </app-search-fix-menu>

  <div
    infinite-scroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [scrollWindow]="false"
    (scrolled)="onScrolled()"
    class="flex h-full overflow-y-auto"
  >
    <app-search-results
      (typeByChanged)="onTypeByChanged($event)"
      (orderByChanged)="onOrderByChanged($event)"
      (refreshLotsEvent)="refreshLots($event)"
      [countResults]="countResults"
      [lotResults]="lotResultList"
      [programResults]="programResultList"
      [isResultData]="isResults"
      [isResultData]="isResults"
      [isSpinner]="isSpinner"
      class="flex flex-col w-full"
    >
    </app-search-results>
  </div>
</div>
