/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, Subscription, switchMap, take, tap } from 'rxjs';
import { pick } from 'lodash';

import { FooterContactEnum } from '../models/enums/footer-contact.enum';
import { LoggedFooterResponse } from '../models/LoggedFooterResponse';
import { TokenService } from './authorisation/token.service';
import { AppConfigService } from './app-config.service';
import { ErrorHandlerService } from './error-handler.service';
import { ConfigParamEnum, ConfigParamKeysType } from './configurable-param.interface';
import { LocalStorageService } from './local-storage.service';
import { ExternalDocumentLink } from '../models/external-document-link';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurableParamService {
  public loggedFooterResponse$: BehaviorSubject<LoggedFooterResponse>;
  public readonly externalDocumentsLink$: BehaviorSubject<Array<ExternalDocumentLink>>;
  private googleTagManagerId: string;
  private featuresToBeEnabled: object;
  private readonly getFlippingFeaturesToBeEnabledCacheName = 'getFlippingFeaturesToBeEnabledCacheName';
  private readonly getConfigsParamsCacheName = 'getConfigsParamsCacheName';
  private externalDocumentsLink: Array<ExternalDocumentLink>;

  /**
   * isLoggedIn attribute.
   *
   * @type {boolean}
   *
   * @memberof ConfigurableParamService
   */
  public isLoggedIn = false;
  /**
   * isLoggedInSubscription attribute
   *
   * @type {Subscription}
   * @memberof ConfigurableParamService
   */
  private readonly isLoggedInSubscription: Subscription;

  constructor(
    private readonly http: HttpClient,
    private readonly tokenService: TokenService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
    private readonly _cacheService: CacheService,
    private readonly _localStorage: LocalStorageService,
  ) {
    this.loggedFooterResponse$ = new BehaviorSubject(null);
    this.externalDocumentsLink$ = new BehaviorSubject(null);
    this.getExternalLinkToDocumentsUtils();
    // Get if user is login or not.
    this.isLoggedInSubscription = this.tokenService.getLoggedInObservable().subscribe((isLoggedIn: boolean) => {
      this.isLoggedIn = isLoggedIn;
      this.getFooter();
    });
  }

  getFooter(): void {
    this.http
      .get<LoggedFooterResponse>(`${this.appConfig.getLoopbackApiUrl()}/ConfigurableParams/get-footer`)
      .pipe(
        map((response) => {
          const loggedFooterResponse = new LoggedFooterResponse();
          response.configParams.forEach((data) => {
            switch (data.name) {
              case FooterContactEnum.commercialPhone:
                loggedFooterResponse.commercialContact.phone = data.paramValue;
                break;
              case FooterContactEnum.comercialEmail:
                loggedFooterResponse.commercialContact.email = data.paramValue;
                break;
              case FooterContactEnum.mandatePhone:
                loggedFooterResponse.mandateContact.phone = data.paramValue;
                break;
              case FooterContactEnum.mandateEmail:
                loggedFooterResponse.mandateContact.email = data.paramValue;
                break;
              case FooterContactEnum.billingPhone:
                loggedFooterResponse.billingContact.phone = data.paramValue;
                break;
              case FooterContactEnum.billingEmail:
                loggedFooterResponse.billingContact.email = data.paramValue;
                break;
              case FooterContactEnum.developerEmail:
                loggedFooterResponse.developerContactInfo.email = data.paramValue;
                break;
              case FooterContactEnum.developerPhone:
                loggedFooterResponse.developerContactInfo.phone = data.paramValue;
                break;
              case FooterContactEnum.contractorEmail:
                loggedFooterResponse.contractorContactInfo.email = data.paramValue;
                break;
              case FooterContactEnum.contractorPhone:
                loggedFooterResponse.contractorContactInfo.phone = data.paramValue;
                break;
              default:
                break;
            }
          });
          loggedFooterResponse.documents = response.documents;
          loggedFooterResponse.commercialContact.title = 'Footer_Title_ContactCommercial';
          loggedFooterResponse.mandateContact.title = 'Footer_Title_ContactMandat';
          loggedFooterResponse.billingContact.title = 'Footer_Title_ContactFacturation';
          loggedFooterResponse.contractorContactInfo.title = 'Footer_Title_ContractorContact';
          loggedFooterResponse.developerContactInfo.title = 'Footer_Title_DeveloperContact';

          return loggedFooterResponse;
        }),
        catchError(this.errorHandlerService.handleError<any>('configurableParam', 'get')),
      )
      .subscribe((loggedFooterResp) => {
        this.loggedFooterResponse$.next(loggedFooterResp);
      });
  }

  getGoogleTagManagerId(forceReload: boolean): Observable<string> {
    if (!this.googleTagManagerId || forceReload) {
      return this.http.get<string>(`${this.appConfig.getLoopbackApiUrl()}/ConfigurableParams/get-google-tag-manager-id`).pipe(
        tap((response) => (this.googleTagManagerId = response)),
        catchError(this.errorHandlerService.handleError<any>('configurableParam', 'get')),
      );
    }

    return of(this.googleTagManagerId);
  }

  getFeaturesFlippingToBeEnabled(): Observable<any> {
    const data = this._cacheService.getData(this.getFlippingFeaturesToBeEnabledCacheName);
    if (data) {
      return of(data).pipe(take(1));
    }

    const urlToGetConfigFeaturesFlipping = `${this.appConfig.getLoopbackApiUrl()}/ConfigurableParams/features-flipping-enabled`;

    return this.http.get<string>(urlToGetConfigFeaturesFlipping).pipe(
      map((response: string) => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const thisClassContext = this;

        try {
          thisClassContext.featuresToBeEnabled = JSON.parse(response);

          thisClassContext._cacheService.addToCache(
            thisClassContext.getFlippingFeaturesToBeEnabledCacheName,
            thisClassContext.featuresToBeEnabled,
          );

          return thisClassContext.featuresToBeEnabled;
        } catch (error) {
          return {};
        }
      }),
      catchError(this.errorHandlerService.handleError<string>('configurableParam', 'get')),
    );
  }

  getExternalLinkToDocumentsUtils(): void {
    this.http
      .get<string>(`${this.appConfig.getLoopbackApiUrl()}/ConfigurableParams/external-documents-link`)
      .pipe(
        map((response: string) => {
          try {
            const externalResult = JSON.parse(response);
            return externalResult;
          } catch (error) {
            this.errorHandlerService.handleError<string>('configurableParam', 'get');
          }
        }),
        catchError(this.errorHandlerService.handleError<string>('configurableParam', 'get')),
      )
      .subscribe((response: Array<ExternalDocumentLink>) => {
        this.externalDocumentsLink = response;
        this.externalDocumentsLink$.next(this.externalDocumentsLink);
      });
  }

  getExternalLinkByLabelId(labelId: string): string {
    if (this.externalDocumentsLink && this.externalDocumentsLink.length > 0) {
      return this.externalDocumentsLink.filter((doc) => doc && doc.labelId === labelId)[0].link;
    }

    return null;
  }

  private getConfigsByKey(configs: Record<string, any>, keys: ConfigParamKeysType): Record<ConfigParamEnum, any> {
    const keysArray = Array.isArray(keys) ? keys : [keys];
    return pick(configs, keysArray);
  }

  public getConfigParam(keys: ConfigParamKeysType): Observable<string | Record<ConfigParamEnum, any>> {
    return this._localStorage.getItem<Record<string, any | null>>(this.getConfigsParamsCacheName, { withExpiration: true }).pipe(
      switchMap((dataFromLocalStorage: any) => {
        if (!dataFromLocalStorage) {
          // if no data in local storage, get it from server
          return this.http.get<Record<string, any>>(`${this.appConfig.getLoopbackApiUrl()}/ConfigurableParams/`).pipe(
            map((result: Record<ConfigParamEnum, any>) => {
              this._localStorage.setItem(this.getConfigsParamsCacheName, result, { withExpiration: true });
              return result;
            }),
            catchError(this.errorHandlerService.handleError<any>('configurableParam', 'get')),
          );
        }

        // if data in local storage, return it
        return of(dataFromLocalStorage);
      }),
      map((configs) => this.getConfigsByKey(configs, keys)),
      catchError(this.errorHandlerService.handleError<string>('configurableParam | getConfigParam', 'get')),
    );
  }
}
