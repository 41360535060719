export const SPECIAL_OFFER_TYPE_NONE = 'NONE';
export const SPECIAL_OFFER_TYPE_OTHER = 'OTHER_OFFER';
export const NO_DEVELOPPER_SPECIAL_OFER_FILTER = [SPECIAL_OFFER_TYPE_NONE, SPECIAL_OFFER_TYPE_OTHER];

export interface Offer {
  id: number;
  type: string;
  offer: string;
  index: number;
}
