<div class="program-navigation-header-fees" *ngIf="fees !== undefined && fees !== null && !hideLotFees">
  <span *ngIf="hasFeesRange; else valoFees">
    <ng-container *ngIf="fees !== maxFees">
      {{
        i18nService._('Txt_Page_Program_Min_Max_FeesFrom', [
          fees ? (fees | percent: '1.0-2' : 'fr') : ' -',
          maxFees ? (maxFees | percent: '1.0-2' : 'fr') : ' -'
        ])
      }}
    </ng-container>
    <ng-container *ngIf="fees === maxFees">
      {{ i18nService._('Txt_Page_Program_FeesFrom') }}
      {{ fees ? (fees | percent: '1.0-2' : 'fr') : ' -' }}
    </ng-container>
  </span>
  <ng-template #valoFees>
    <span *ngIf="!isValo">{{ i18nService._('Txt_Page_Program_ListFees') }} : {{ fees ? (fees | percent: '1.0-2' : 'fr') : ' -' }}</span>
    <span *ngIf="isValo">{{ i18nService._('Txt_Page_Program_ValoFees') }}</span>
  </ng-template>
</div>
