import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { Subject, takeUntil } from 'rxjs';
import { FormlyFieldSelectProps } from '@ngx-formly/core/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { I18nService } from '../../../utils/services/i18n.service';
import { SignatureDocumentService } from '../../../signature/services/signature-document.service';
import { SignatureService } from '../../../signature/services/signature.service';
import { DocumentResponse } from '../../../utils/models/DocumentResponse';
import { FileExtention } from '../../../utils/models/app-constant';
import { FileDropComponent } from '../../form/file-drop/file-drop.component';

interface SelectProps extends FormlyFieldProps, FormlyFieldSelectProps {
  docType?: string;
  acceptedFilesLabel?: string;
  typeDoc?: string;
  files: [DocumentResponse];
  maxFileSize: number;
  multiple: boolean;
  types: Array<FileExtention> | Array<string>;
}

@Component({
  selector: 'app-formly-file-drop',
  templateUrl: './formly-file-drop.component.html',
  styleUrls: ['./formly-file-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FileDropComponent, FormsModule, ReactiveFormsModule],
})
export class FormlyFileDropComponent extends FieldType<FieldTypeConfig<SelectProps>> implements OnInit, OnDestroy {
  public dossierId: number;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public readonly i18nService: I18nService,
    public readonly signatureDocumentService: SignatureDocumentService,
    private readonly signatureService: SignatureService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.signatureService.formDossier$.pipe(takeUntil(this.destroy$)).subscribe((dossier) => (this.dossierId = Number(dossier?.id)));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
