<div class="grid grid-cols-7 gap:4 lg:gap-6 my-4">
  <div class="col-span-2">
    <mat-form-field class="fullW flex w-full">
      <mat-label>{{ i18nService._('Txt_Placeholder_Search') }}</mat-label>
      <input [(ngModel)]="filterValue" (ngModelChange)="textFilterSubject.next($event)" matInput />
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="fullW flex w-full my-0">
      <mat-label>{{ i18nService._('Txt_AccountsTrackingTable_Column_Profil') }}</mat-label>
      <mat-select (selectionChange)="onProfilListChange($event)" multiple [value]="i18nFilter.Profils">
        <mat-option *ngFor="let profil of profilFilterList" [value]="profil.id">
          {{ i18nService._(profil.label) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="this.type === 'VALORISSIMO'">
    <mat-form-field class="fullW flex w-full my-0">
      <mat-label>{{ i18nService._('Txt_AccountsTrackingTable_Column_Type') }}</mat-label>
      <mat-select (selectionChange)="onTypeListChange($event)" multiple [value]="i18nFilter.ValoRoleTypes">
        <mat-option *ngFor="let type of typeFilterList" [value]="type.id">
          {{ i18nService._(type.label) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="fullW flex w-full my-0">
      <mat-label>{{ i18nService._('Txt_AccountsTrackingTable_Column_Active') }}</mat-label>
      <mat-select (selectionChange)="onActiveListChange($event)" multiple [value]="i18nFilter.isActive">
        <mat-option *ngFor="let active of activeFilterList" [value]="active.value">
          {{ i18nService._(active.label) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="this.type !== 'VALORISSIMO'">
    <mat-form-field class="fullW flex w-full my-0">
      <mat-label>{{ i18nService._('Txt_AccountsTrackingTable_Column_Status') }}</mat-label>
      <mat-select (selectionChange)="onStatusListChange($event)" multiple [value]="i18nFilter.AccountStatuses">
        <mat-option *ngFor="let status of statusFilterList" [value]="status.value">
          {{ i18nService._(status.label) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="fullW flex w-full my-0">
      <mat-label>{{ i18nService._('Txt_Placeholder_ColumnsToDisplay') }}</mat-label>

      <mat-select [(ngModel)]="selectedColumns" multiple>
        <mat-select-trigger>
          {{ selectedColumnsSummary }}
        </mat-select-trigger>
        <mat-option *ngFor="let column of columns" [value]="column.value">
          {{ i18nService._(column.label) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="tracking-mat-table">
  <app-grid-tracking-table
    [columnDefs]="columnDefs"
    [tableLoader]="acccountTrackingTableService"
    [pageSize]="pageSize"
    [defaultSortColunm]="sortColumn"
    [defaultSortDirection]="sortDirection"
    [textFilter]="textFilterValue"
    [visibleColumns]="selectedColumns"
    [i18nFilter]="i18nFilter"
    [tableHeight]="'inherit'"
  >
  </app-grid-tracking-table>
</div>
