<div *ngIf="programSummary">
  <div class="text-base font-medium mt-3">
    {{ i18nService._('Txt_Program_Form_Summary_Name', [programSummary.programName]) }}
  </div>
  <div class="text-base font-medium mt-3">
    {{ i18nService._('Txt_Program_Form_Summary_Address', [programSummary.address]) }}
  </div>
  <div class="text-base font-medium mt-3">
    {{ i18nService._('Txt_Program_Form_Summary_Nb_Lots', [programSummary.nbLots]) }}
  </div>
  <div class="text-base font-medium mt-3">
    {{ i18nService._('Txt_Program_Form_Summary_Delivery_Date', [programSummary.deliveryDate]) }}
  </div>

  <div *ngFor="let offer of programSummary.specialOffers">
    <hr class="w-5 my-12" />
    <!---TO DO : dev récapitulatif offre spéciale VALO-1727-->
    <div>
      <div class="text-base font-medium mt-3">
        {{ i18nService._('Txt_Program_Form_Reference_Special_Offer') + offer.refSpecialOffer }}
      </div>
      <div class="text-base font-medium mt-3">
        {{ i18nService._('Txt_Program_Form_Special_Offer_Label') + offer.title }}
      </div>
      <div class="text-base font-medium mt-3">
        {{ i18nService._('Txt_Program_Form_Special_Offer_Description') + offer.description }}
      </div>
      <div class="text-base font-medium mt-3">
        {{ i18nService._('Txt_Program_Form_Legal_Notice_Special_Offer') }}
        <div innerHtml="{{ offer.legalNotice }}"></div>
      </div>
      <div class="text-base font-medium mt-3">
        {{ i18nService._('Txt_Program_Form_Start_Date_Month_Special_Offer') }}
        {{ offer.startDate | date: 'yyyy-MM-dd HH:mm:ss' }}
      </div>
      <div class="text-base font-medium mt-3">
        {{ i18nService._('Txt_Program_Form_End_Date_Month_Special_Offer') }}
        {{ offer.endDate | date: 'yyyy-MM-dd HH:mm:ss' }}
      </div>
      <div class="text-base font-medium mt-3">
        {{ i18nService._('Txt_Program_Form_Amendment_Special_Offer') }}
        <app-file-drop
          [required]="false"
          [fieldNameKey]="offer.refSpecialOffer"
          [includingForm]="attachementsFormGroup"
          [validExt]="['application/pdf']"
          [initialValue]="offer.amendment"
        >
        </app-file-drop>
      </div>
      <div class="text-base font-medium mt-3">
        {{ i18nService._('Txt_Program_Form_No_Lots_Special_Offer') + offer.nbLots }}
      </div>
    </div>
  </div>

  <button
    (click)="sendPendingValidation()"
    *ngIf="isValo"
    [disabled]="submitDisabled"
    class="mt-4"
    color="primary"
    mat-stroked-button
    matStepperNext
    type="submit"
    data-cy="nextStep"
  >
    {{ i18nService._('Txt_Button_NextStep') }}
  </button>
</div>
