/* eslint-disable max-classes-per-file */
import { StrongPointResponseDto } from '@commons-dto/valo-back';

import { DocumentResponse } from '../../utils/models/DocumentResponse';
import { TaxationDetailResponse } from '../../utils/models/TaxationDetailResponse';
import { TagsInterface } from '../../design-system/model/tag-option';

export class LotStatus {
  id?: number;
  sold?: boolean;
  quotaReach?: boolean;
  optionned?: boolean;
  optionnedByMe?: boolean;
  mandated?: boolean;
  published?: boolean;
}

interface TaxationRentAndProfitablity {
  profitability: number;
  rentingPrice: number;
}

export interface LotArea {
  id: number;
  label: string;
  area: number;
}

export interface SecondaryLot {
  id: number;
  label: string;
  numLot: string;
  lotSellingPriceIT: number;
}

export class LotComparatorDetail {
  id: number;
  city: string;
  lotNumber: string;
  perspective: DocumentResponse;
  postalCode: string;
  programName: string;
  programId: string;
  programStrongPoints: Array<StrongPointResponseDto>;
  status: LotStatus;
  isUnvailable?: boolean;
  commercialLotSellingPriceIT: number;
  reducedTotalSellingPriceIT: number;
  secondaryLotPriceIncluded: boolean;
  rooms: string;
  livingSpace: number;
  lotPlan: DocumentResponse;
  specialOffer: boolean;
  isReducedTaxation: boolean;
  taxationsLabels: string[];
  taxations: Array<TaxationDetailResponse>;
  lotStrongPoints: Array<StrongPointResponseDto>;
  profitability: number;
  areas: LotArea[];
  secondaryLots: SecondaryLot[];
  annexes: Array<LotArea | SecondaryLot>;
  actable: boolean;
  isValorissimoExclusivity: boolean;
  deliveryDate: string;
  typology: string;
  orientation: string;
  pinelPlusTaxation: TaxationRentAndProfitablity;
  pinelTaxation: TaxationRentAndProfitablity;
  plsTaxation: TaxationRentAndProfitablity;
  lmnpLmpTaxation: TaxationRentAndProfitablity;
  lmnpLmpNonGereTaxation: TaxationRentAndProfitablity;
  estmatedMonthlyRentingPrice: number;
  estimatedProfitability: number;
  // LMNP NON GERE :
  estimatedFurnishedMarketRent: string;
  estimatedFurnishedMarketYield: number;
  // MALRAUX ou DEFICIT_FONCIER ou MONUMENTS_HISTORIQUES
  estimatedWorkPrice: string;
  estimatedLandPrice: string;
  // NUE PROPRIETE
  usufruitValue: string;
  distributionKey: string;
  nueProprieteDuration: string;
  // ---------
  monthlyRentingPriceET: number;
  monthlyRentalLotPriceIT: number;
  fees: number;
  formattedValue?: string;

  priceFormatted?: string;
  reducedPriceFormatted?: string;
  taxationTags?: { taxations: TagsInterface; reducedTaxation?: TagsInterface };
  fiscalityTags?: TagsInterface[];
  isFeesHidden: boolean;
  guaranteedMonthlyRentingPriceIT?: number;
}
