import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RecommendationsRoutingModule } from './recommendations-routing.module';
import { RecommendationsComponent } from './components/recommendations.component';
import { UtilsModule } from '../utils/utils.module';

@NgModule({
  imports: [CommonModule, RecommendationsRoutingModule, UtilsModule, RecommendationsComponent],
})
export class RecommendationsModule {}
