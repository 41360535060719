// app.component.ts
import { Component, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';
import primeLocalFR from 'primelocale/fr.json';

import { FeatureFlagService } from './feature-flag/feature-flag.service';
import { Sitemap } from './utils/models/Sitemap';
import { AccountService } from './utils/services/account.service';
import { TokenService } from './utils/services/authorisation/token.service';
import { EventUtilsService } from './utils/services/event/event-utils.service';
import { GoogleTagManagerService } from './utils/services/google-tag-manager.service';
import { RoutingStateService } from './utils/services/routing-state.service';
import { AccountAsService } from './utils/services/account-as.service';
import { UserRoleService } from './utils/services/user-role.service';
import { SentryService } from './utils/services/sentry.services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'valo-front';

  public isLoginRoute: boolean;
  private loadRoutingSubscription: Subscription;
  private routerSubscription: Subscription;
  public isConnectionAs: boolean;
  public isConnected: boolean;
  public isValo: boolean;

  constructor(
    private readonly googleTagManagerService: GoogleTagManagerService,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly routingStateService: RoutingStateService,
    private readonly tokenService: TokenService,
    private readonly eventUtilsService: EventUtilsService,
    private readonly accountService: AccountService,
    public accountAsService: AccountAsService,
    private readonly userRoleService: UserRoleService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly sentryService: SentryService,
    private primeNgConfig: PrimeNGConfig,
  ) {
    this.loadRoutingSubscription = new Subscription();
    this.routerSubscription = new Subscription();
    this.isLoginRoute = false;
  }

  ngOnInit(): void {
    this.primeNgConfig.setTranslation(primeLocalFR.fr);

    this.router.initialNavigation();

    this.accountAsService.isConnectedAsEvent$.subscribe((newState) => {
      this.isConnectionAs = newState;
    });
    this.tokenService.getLoggedInObservable().subscribe((newState) => {
      this.isConnected = newState;
      if (this.isConnected) {
        this.isValo = this.userRoleService.isValo();
      }
    });

    this.loadRoutingSubscription = this.routingStateService.loadRouting();
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLoginRoute = event.urlAfterRedirects.includes(`/${Sitemap.utils.login.path}`);
        this.updateBodyClass(event.urlAfterRedirects);
        this.sendPageEventToGoogleTagManager();
      }
    });

    this.isLoginRoute = this.router.url === `/${Sitemap.utils.login.path}`;
    this.updateBodyClass(this.router.url);
    this.sendPageEventToGoogleTagManager();
  }

  ngOnDestroy(): void {
    this.loadRoutingSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent): void {
    this.eventUtilsService.documentClickedTarget.next(event.target as HTMLElement);
  }

  private updateBodyClass(url: string): void {
    const body = document.body;
    body.className = '';
    if (url.includes(`/${Sitemap.utils.homepage.path}`)) {
      this.featureFlagService.isEnabled('new-homepage').then((isNewHomepage) => {
        if (!isNewHomepage) {
          this.renderer.addClass(body, 'bg-homepage');
        }
      });
    }
    if (url.includes(`/${Sitemap.utils.login.path}`)) {
      this.renderer.addClass(body, 'bg-login');
    }
    if (url.includes(`/${Sitemap.utils.search.path}`)) {
      this.renderer.addClass(body, 'bg-recherche');
    }
  }

  private sendPageEventToGoogleTagManager(): void {
    this.googleTagManagerService.pushTag({
      event: 'Page View',
      userId: this.tokenService.getUserId(),
      societeId: this.tokenService.getCompanyId(),
    });
    this.sentryService.sendUserToSentry();
  }
}
