import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatOptionModule,
  MatRippleModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { IntercomModule } from '@supy-io/ngx-intercom';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';

import { DesignSystemModule } from '../design-system/design-system.module';
import { TableModule } from '../table/table.module';
import { AlertComponent } from './components/alert/alert.component';
import { ChatbotComponent } from './components/chatbot/chatbot.component';
import { CheckboxPopulatedComponent } from './components/checkbox-populated/checkbox-populated.component';
import { ChipAutocompletePopulatedComponent } from './components/chip-autocomplete-populated/chip-autocomplete-populated.component';
import { ChipPopulatedAutocompleteComponent } from './components/chip-populated-autocomplete/chip-populated-autocomplete.component';
import { ChipPopulatedWithOtherComponent } from './components/chip-populated-with-other/chip-populated-with-other.component';
import { ChipPopulatedComponent } from './components/chip-populated/chip-populated.component';
import { CityscanMapComponent } from './components/cityscan-map/cityscan-map.component';
import { ContactInformationComponent } from './components/contact-information/contact-information.component';
import { CoordinatesComponent } from './components/coordinates/coordinates.component';
import { DocumentsBoxComponent } from './components/documents-box/documents-box.component';
import { DropdownListPopulatedWithOtherComponent } from './components/dropdown-list-populated-with-other/dropdown-list-populated-with-other.component';
import { DropdownListPopulatedComponent } from './components/dropdown-list-populated/dropdown-list-populated.component';
import { FileDropComponent } from './components/file-drop/file-drop.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormLocationComponent } from './components/form-location/form-location.component';
import { HeaderMenuEntryComponent } from './components/header-menu-entry/header-menu-entry.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { IdentityInformationComponent } from './components/identity-information/identity-information.component';
import { InputDateComponent } from './components/input-date/input-date.component';
import { LoginAsFormComponent } from './components/login-as form/login-as-form.component';
import { LoginAsComponent } from './components/login-as/login-as.component';
import { LotDetailsPanelComponent } from './components/lot-details-panel/lot-details-panel.component';
import { NameInformationComponent } from './components/name-information/name-information.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { PhoneNumberInformationComponent } from './components/phone-number-information/phone-number-information.component';
import { PostalAddressComponent } from './components/postal-address/postal-address.component';
import { RadioButtonPopulatedComponent } from './components/radio-button-populated/radio-button-populated.component';
import { RecaptchaComponent } from './components/recaptcha/recaptcha.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { SpinnerWithBackdropComponent } from './components/spinner-with-backdrop/spinner-with-backdrop.component';
import { ActionButtonStategieComponent } from './components/sticky-header-form/action-button-stategie/action-button-stategie.component';
import { StickyHeaderFormComponent } from './components/sticky-header-form/sticky-header-form.component';
import { Target2SellRecoComponent } from './components/target2sell-reco/target2sell-reco.component';
import { DecimalMaskDirective } from './directives/decimal-mask.directive';
import { MatVerticalStepperScrollerDirective } from './directives/mat-vertical-stepper-scroller.directive';
import { StringsProject } from './i18n/fr_FR';
import { I18nMatPaginatorIntl } from './i18n/i18n-mat-paginator-intl';
import { PipesModule } from './pipes/pipes.module';
import { CustomDateAdapter } from './services/date/custom-date-adapter';
import { DateTimeService } from './services/date/date-time.service';
import { I18nService } from './services/i18n.service';
import { ReferenceTablesService } from './services/reference-tables.service';
import { ScriptService } from './services/script-loader.service';
import { ConfigurableParamService } from './services/configurable-param.service';
import { DropMenuComponent } from './components/header/drop-menu/drop-menu.component';
import { MobileNavComponent } from './components/header/mobile-nav/mobile-nav.component';
import { MenuItemComponent } from './components/header/drop-menu/menu-item/menu-item.component';
import { InterlocutorContactComponent } from './components/interlocutor-contact/interlocutor-contact.component';
import { InterlocutorContactsComponent } from './components/interlocutor-contacts/interlocutor-contacts.component';
import { CommercialContactComponent } from './components/commercial-contact/commercial-contact.component';
import { IconComponent } from '../common/standalone/icon/icon.component';

registerLocaleData(localeFr, 'fr'); // TODO voir comment rendre ça plus générique.
export { ArraySortCompareFn, ArrayFilterCallbackFn } from './@types/ArrayFns';

export const snackBarProvider = {
  provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
  useValue: {
    duration: 5000,
    horizontalPosition: 'center',
    verticalPosition: 'top',
  },
};

@NgModule({
  imports: [
    CommonModule,
    NgxFileDropModule,
    FormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatRippleModule,
    NgxSliderModule,
    NgSelectModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    RouterModule,
    TableModule,
    DialogModule,
    FeatureToggleModule,
    IntercomModule.forRoot({
      appId: '',
      updateOnRouterChange: false, // will automatically run `update` on router event changes
    }),
    LayoutModule,
    MatButtonToggleModule,
    PipesModule,
    DesignSystemModule,
    AutoCompleteModule,
    IconComponent,
    NgxMatIntlTelInputComponent,
    CheckboxPopulatedComponent,
    ChipAutocompletePopulatedComponent,
    ChipPopulatedAutocompleteComponent,
    ChipPopulatedComponent,
    ChipPopulatedWithOtherComponent,
    ContactInformationComponent,
    CoordinatesComponent,
    DropdownListPopulatedComponent,
    DropdownListPopulatedWithOtherComponent,
    FileDropComponent,
    FooterComponent,
    HeaderComponent,
    HeaderMenuComponent,
    HeaderMenuEntryComponent,
    IdentityInformationComponent,
    InputDateComponent,
    MatVerticalStepperScrollerDirective,
    DecimalMaskDirective,
    NameInformationComponent,
    NotFoundPageComponent,
    PhoneNumberInformationComponent,
    PostalAddressComponent,
    RadioButtonPopulatedComponent,
    RecaptchaComponent,
    StickyHeaderFormComponent,
    Target2SellRecoComponent,
    SpinnerWithBackdropComponent,
    LoginAsComponent,
    LoginAsFormComponent,
    FormLocationComponent,
    LotDetailsPanelComponent,
    DocumentsBoxComponent,
    SnackbarComponent,
    AlertComponent,
    CityscanMapComponent,
    ChatbotComponent,
    ActionButtonStategieComponent,
    DropMenuComponent,
    MobileNavComponent,
    MenuItemComponent,
    InterlocutorContactComponent,
    InterlocutorContactsComponent,
    CommercialContactComponent,
  ],
  providers: [
    StringsProject,
    I18nService,
    snackBarProvider,
    CustomDateAdapter,
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'fr-FR',
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [DateTimeService, MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    ReferenceTablesService,
    {
      provide: MatPaginatorIntl,
      useClass: I18nMatPaginatorIntl,
    },
    ScriptService,
    ConfigurableParamService,
  ],
  exports: [
    CheckboxPopulatedComponent,
    ChipAutocompletePopulatedComponent,
    ChipPopulatedAutocompleteComponent,
    ChipPopulatedComponent,
    ChipPopulatedWithOtherComponent,
    CommonModule,
    ContactInformationComponent,
    CoordinatesComponent,
    DropdownListPopulatedComponent,
    DropdownListPopulatedWithOtherComponent,
    FileDropComponent,
    NgxFileDropModule,
    FooterComponent,
    FormsModule,
    HeaderComponent,
    IdentityInformationComponent,
    InputDateComponent,
    LotDetailsPanelComponent,
    MatRippleModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatVerticalStepperScrollerDirective,
    DecimalMaskDirective,
    NgxSliderModule,
    NgSelectModule,
    PhoneNumberInformationComponent,
    PostalAddressComponent,
    RadioButtonPopulatedComponent,
    ReactiveFormsModule,
    RecaptchaComponent,
    StickyHeaderFormComponent,
    TableModule,
    Target2SellRecoComponent,
    SpinnerWithBackdropComponent,
    FormLocationComponent,
    DocumentsBoxComponent,
    AlertComponent,
    CityscanMapComponent,
    FeatureToggleModule,
    ChatbotComponent,
    LoginAsFormComponent,
    NameInformationComponent,
  ],
})
export class UtilsModule {}
