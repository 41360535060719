import { Component } from '@angular/core';
import { NgFor } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';

import { AbstractSearchFilter } from '../abstract-search-filter';

import { DELIVERY_DATE_ITEMS } from '../../../../utils/models/app-constant';
import { I18nService } from '../../../../utils/services/i18n.service';

@Component({
  selector: 'app-search-filter-delivry-date',
  templateUrl: './search-filter-delivry-date.component.html',
  styleUrls: ['./search-filter-delivry-date.component.scss'],
  standalone: true,
  imports: [MatChipsModule, NgFor],
})
export class SearchFilterDelivryDateComponent extends AbstractSearchFilter {
  public deliveryDateItems = DELIVERY_DATE_ITEMS;

  constructor(public readonly i18nService: I18nService) {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected initData() {}

  selectedItem(fieldName: string, id: number) {
    this.search.deliveryDateMonth = this.search.deliveryDateMonth !== id ? id : undefined;
    super.selectedItem(fieldName, this.search.deliveryDateMonth);
  }

  getYearLabel(id: number): string {
    const currentDate = new Date();
    if (id < 0) {
      return this.i18nService._('Txt_SelectOption_Form_SearchForm_Delivery_Now');
    }

    return this.i18nService._('Txt_SelectOption_Form_SearchForm_Delivery_Date_Prefix') + (currentDate.getFullYear() + id).toString();
  }
}
