import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BoxHeaderComponent } from '../../form/box-header/box-header.component';

@Component({
  selector: 'app-formly-box-header',
  templateUrl: './formly-box-header.component.html',
  styleUrls: ['./formly-box-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [BoxHeaderComponent, FormsModule, ReactiveFormsModule],
})
export class FormlyBoxHeaderComponent extends FieldType<FieldTypeConfig> {}
