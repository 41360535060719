import { CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { DateTime } from 'luxon';

import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class BasicFormatsService {
  /**
   * unixTimestampMs constant to format datetime as unix timestamp with ms
   *
   * @type {string}
   * @memberof BasicFormatsService
   */
  public static readonly unixTimestampMs: string = 'unixTimestampMs';

  /**
   * defaultDateFormat constant to format date as French date notation
   *
   * @type {string}
   * @memberof BasicFormatsService
   */
  public static readonly defaultDateFormat: string = 'DD/MM/YYYY';

  /**
   * dateHourFormat constant to format date as French date and hour notation
   *
   * @type {string}
   * @memberof BasicFormatsService
   */
  public static readonly dateHourFormat: string = 'DD/MM/YYYY - HH:mm';

  /**
   * fullDateHourFormat constant to format date as French date and hour notation
   *
   * @type {string}
   * @memberof BasicFormatsService
   */
  public static readonly fullDateHourFormat: string = 'YYYY-MM-DD_HH:mm:ss';

  /**
   * defaultMinIntegerDigits attribute
   *
   * @type {number}
   * @memberof BasicFormatsService
   */
  private static readonly defaultMinIntegerDigits: number = 1;

  /**
   * defaultMinimumFractionDigits attribute
   *
   * @type {number}
   * @memberof BasicFormatsService
   */
  private static readonly defaultMinimumFractionDigits: number = 2;

  /**
   * defaultMinimumFractionDigitsCurrency attribute
   *
   * @type {number}
   * @memberof BasicFormatsService
   */
  private static readonly defaultMinimumFractionDigitsCurrency: number = 0;

  /**
   * defaultMaximumFractionDigits attribute
   *
   * @type {number}
   * @memberof BasicFormatsService
   */
  private static readonly defaultMaximumFractionDigits: number = 2;

  /**
   * defaultMaximumFractionDigitsCurrency attribute
   *
   * @type {number}
   * @memberof BasicFormatsService
   */
  private static readonly defaultMaximumFractionDigitsCurrency: number = 0;

  /**
   * currencyPipe attribute
   *
   * @type {CurrencyPipe}
   * @memberof BasicFormatsService
   */
  private readonly currencyPipe: CurrencyPipe;

  /**
   * datePipe attribute
   *
   * @type {DatePipe}
   * @memberof BasicFormatsService
   */
  private readonly datePipe: DatePipe;

  /**
   * decimalPipe attribute
   *
   * @type {DecimalPipe}
   * @memberof BasicFormatsService
   */
  private readonly decimalPipe: DecimalPipe;

  /**
   * defaultCurrencyCode attribute
   *
   * @type {string}
   * @memberof BasicFormatsService
   */
  private readonly defaultCurrencyCode: string;

  /**
   * locale attribute
   *
   * @type {string}
   * @memberof BasicFormatsService
   */
  private readonly locale: string;

  /**
   * percentagePipe attribute
   *
   * @type {PercentPipe}
   * @memberof BasicFormatsService
   */
  private readonly percentagePipe: PercentPipe;

  /**
   * defaultAreaUnit attribute
   *
   * @private
   * @type {string}
   * @memberof BasicFormatsService
   */
  private readonly defaultAreaUnit: string;

  /**
   * Creates an instance of BasicFormatsService.
   *
   * @param {AppConfigService} appConfigService
   */
  constructor(private readonly appConfigService: AppConfigService) {
    this.defaultAreaUnit = this.appConfigService.getDefaultAreaUnit();
    this.defaultCurrencyCode = this.appConfigService.getDefaultCurrency();
    this.locale = this.appConfigService.getLocale();
    this.currencyPipe = new CurrencyPipe(this.locale);
    this.datePipe = new DatePipe(this.locale);
    this.decimalPipe = new DecimalPipe(this.locale);
    this.percentagePipe = new PercentPipe(this.locale);
    moment.locale(this.locale);
  }

  /**
   * Transform value to currency format
   *
   * @param value
   * @param currencyCode
   * @param minIntegerDigits
   * @param minFractionDigits
   * @param maxFractionDigits
   * @memberof BasicFormatsService
   */
  public formatCurrency(
    value: number,
    currencyCode: string = this.defaultCurrencyCode,
    minIntegerDigits: number = BasicFormatsService.defaultMinIntegerDigits,
    minFractionDigits: number = BasicFormatsService.defaultMinimumFractionDigitsCurrency,
    maxFractionDigits: number = BasicFormatsService.defaultMaximumFractionDigitsCurrency,
  ): string {
    const digitsInfo = `${minIntegerDigits}.${minFractionDigits}-${maxFractionDigits}`;

    return this.currencyPipe.transform(value, currencyCode, 'symbol', digitsInfo);
  }

  /**
   * Transform value to currency format
   *
   * @param value
   * @param currencyCode
   * @param minIntegerDigits
   * @param minFractionDigits
   * @param maxFractionDigits
   * @memberof BasicFormatsService
   */
  public formatCurrencyCeil(
    value: number,
    currencyCode: string = undefined,
    minIntegerDigits = 0,
    minFractionDigits = 0,
    maxFractionDigits = 0,
  ): string {
    if (value === undefined) {
      return undefined;
    }
    if (value === null) {
      return null;
    }

    return this.formatCurrency(Math.ceil(value), currencyCode, minIntegerDigits, minFractionDigits, maxFractionDigits);
  }

  /**
   * Transform date to date time format
   *
   * @param {Date} value
   * @param hour
   * @memberof BasicFormatsService
   */
  public formatDateTime(date: Date | moment.Moment | string): string {
    return this.formatDate(date, BasicFormatsService.dateHourFormat);
  }

  /**
   * Transform date to given date format
   *
   * @param {Date | string} date
   * @param outputFormat
   * @param intputFormat
   * @memberof BasicFormatsService
   */
  public formatDate(
    date: Date | moment.Moment | string,
    outputFormat: string = BasicFormatsService.defaultDateFormat,
    intputFormat?: string,
  ): string {
    const momentDate = moment.isMoment(date) ? date.clone() : moment(date, intputFormat);
    if (momentDate.isValid()) {
      return momentDate.format(outputFormat);
    }

    return undefined;
  }

  /**
   * Transform value to decimal format
   *
   * @param value
   * @param minIntegerDigits
   * @param minFractionDigits
   * @param maxFractionDigits
   * @memberof BasicFormatsService
   */
  public formatDecimal(
    value: number,
    minIntegerDigits: number = BasicFormatsService.defaultMinIntegerDigits,
    minFractionDigits: number = BasicFormatsService.defaultMinimumFractionDigits,
    maxFractionDigits: number = BasicFormatsService.defaultMaximumFractionDigits,
  ): string {
    const digitsInfo = `${minIntegerDigits}.${minFractionDigits}-${maxFractionDigits}`;

    return this.decimalPipe.transform(value, digitsInfo);
  }

  /**
   * Transform value to percentage format
   *
   * @param value
   * @param minIntegerDigits
   * @param minFractionDigits
   * @param maxFractionDigits
   * @memberof BasicFormatsService
   */
  public formatPercentage(
    value: number,
    minIntegerDigits: number = BasicFormatsService.defaultMinIntegerDigits,
    minFractionDigits: number = BasicFormatsService.defaultMinimumFractionDigits,
    maxFractionDigits: number = BasicFormatsService.defaultMaximumFractionDigits,
  ): string {
    const digitsInfo = `${minIntegerDigits}.${minFractionDigits}-${maxFractionDigits}`;

    return this.percentagePipe.transform(value, digitsInfo);
  }

  /**
   * Custom format to add area unit
   *
   * @param {number} value
   * @param {number} minIntegerDigits
   * @param {number} minFractionDigits
   * @param {number} maxFractionDigits
   * @memberof BasicFormatsService
   */
  public formatArea(
    value: number,
    unit: string = this.defaultAreaUnit,
    minIntegerDigits: number = BasicFormatsService.defaultMinIntegerDigits,
    minFractionDigits: number = BasicFormatsService.defaultMinimumFractionDigits,
    maxFractionDigits: number = BasicFormatsService.defaultMaximumFractionDigits,
  ): string {
    return `${this.formatDecimal(value, minIntegerDigits, minFractionDigits, maxFractionDigits)} ${unit}`;
  }

  /**
   * Handle delivery Date syntax (trimester year)
   * Ex: 1T 2020
   *
   * @param {Date} deliveryDate
   * @returns {string}
   * @memberof BasicFormatsService
   */
  public handleDeliveryDate(deliveryDate: Date): string {
    // Return the matching trimester
    return `${Math.floor(new Date(deliveryDate).getMonth() / 3) + 1}T ${new Date(deliveryDate).getFullYear()}`;
  }

  public fromJsDate(jsDate: Date, format: string): string {
    return DateTime.fromJSDate(jsDate).toFormat(format);
  }

  public fromIsoTime(isoTime: string, format: string): string {
    return DateTime.fromISO(isoTime).toFormat(format);
  }
}
