import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../security/authguard';
import { RegisterFormProspectComponent } from './components/register-form-prospect/register-form-prospect.component';
import { Sitemap } from '../utils/models/Sitemap';

import { DossierProspectTrackingTableComponent } from './../tracking-tables/components/dossier-prospect-tracking-table/dossier-prospect-tracking-table';

const routes: Routes = [
  {
    path: Sitemap.prospects.register.path,
    component: RegisterFormProspectComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: Sitemap.dossierProspects.list.path,
    component: DossierProspectTrackingTableComponent,
    data: {
      roles: Sitemap.dossierProspects.list.data.roles,
    },
    canActivate: mapToCanActivate([AuthGuard]),
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
  declarations: [],
})
export class ProspectsRoutingModule {}
