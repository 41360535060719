/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';

import { FastSearchFormComponent } from '../fast-search-form/fast-search-form.component';

import { AccountUtilsService } from '../../../accounts/services/account-utils.service';
import { Target2SellEnum } from '../../../utils/models/enums/target-2-sell.enum';
import { AccountAsService } from '../../../utils/services/account-as.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { Target2SellRecoComponent } from '../../../utils/components/target2sell-reco/target2sell-reco.component';
import { LoginAsFormComponent } from '../../../utils/components/login-as form/login-as-form.component';

@Component({
  selector: 'app-fast-search-page',
  templateUrl: './fast-search-page.component.html',
  styleUrls: ['./fast-search-page.component.scss'],
  standalone: true,
  imports: [FastSearchFormComponent, NgIf, LoginAsFormComponent, Target2SellRecoComponent],
})
export class FastSearchPageComponent implements OnInit, OnDestroy {
  public isConnectionAs: boolean;
  public loggedUserIsValo: boolean;
  public target2SellRecoId: string;

  constructor(
    public i18nService: I18nService,
    public accountUtilsService: AccountUtilsService,
    public accountAsService: AccountAsService,
  ) {}

  ngOnInit(): void {
    this.target2SellRecoId = Target2SellEnum.TARGET_2_SELL_SEARCH;
    this.loggedUserIsValo = this.accountUtilsService.getParentAndLoggedUserInfos().loggedUserIsValo;
    this.accountAsService.isConnectedAsEvent$.subscribe((newState) => {
      this.isConnectionAs = newState;
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}
}
