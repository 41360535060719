<mat-form-field>
  <mat-label>{{ placeholder }}</mat-label>
  <input
    #inputDate
    matInput
    [autocomplete]="autocomplete"
    [formControl]="dateControl"
    [matDatepicker]="datePicker"
    [name]="fieldName"
    [attr.data-cy]="placeholder"
    [readonly]="readonly"
    [required]="required"
    (dateChange)="onDateChange($event)"
    (focusin)="onFocusin(datePicker)"
    (keydown)="onKeydown($event, datePicker)"
  />
  <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
  <mat-datepicker #datePicker (opened)="onOpen()" (closed)="onClose()"></mat-datepicker>
  <mat-error *ngIf="error">{{ error }}</mat-error>
</mat-form-field>
