<p-accordion [multiple]="field.props.multiple || true" [activeIndex]="[]">
  <p-accordionTab *ngFor="let panel of field.fieldGroup; let index = index">
    <ng-template pTemplate="header">
      <div class="flex justify-between items-center w-full">
        <div class="flex items-center">
          <span class="inline-flex w-10 h-10 justify-center items-center rounded-full mr-3 bg-grey-background-light">
            <app-icon class="text-grey-dark" size="1.5rem" [name]="panel.props.icon"></app-icon>
          </span>
          <span class="text-xl font-bold">{{ panel.props.formTitle }}</span>
        </div>
        <div *ngIf="panel.props.subTitle">
          <span class="text-base text-grey-dark">{{ panel.props.subTitle }}</span>
        </div>
      </div>
    </ng-template>
    <formly-field [field]="panel"></formly-field>
  </p-accordionTab>
</p-accordion>
