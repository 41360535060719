<button
  class="btn-small mr-2 md:mr-0 truncate public-button"
  mat-stroked-button
  color="primary"
  [disabled]="isActionButtonDisabled"
  (click)="publicationStrategies(false)"
  title="{{ actionButtonLabel }}"
>
  <span>{{ actionButtonLabel }}</span>
</button>
<button
  [disabled]="isActionButtonDisabled"
  class="btn-small mr-2 md:mr-0 truncate plannig-button"
  mat-stroked-button
  color="primary"
  [matMenuTriggerFor]="menu"
>
  <mat-icon class="material-icons-outlined">arrow_drop_down</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="publicationStrategies(true)">
    {{ i18nService._('Txt_Button_PlanningPublicationStrategies') }}
  </button>
</mat-menu>
