import { TemplateRef } from '@angular/core';

import { ComparatorMeta } from './comparator-meta';

import { LotComparatorDetail } from '../../lot/model/lot-comparator-detail';

export class ComparatorMetaPartial implements Partial<ComparatorMeta> {
  field: string;
  title?: string;
  mandatory: boolean;
  show: boolean;
  type: 'base' | 'array' | 'object';
  translationKey?: string;
  templateRef?: TemplateRef<unknown>;

  format?(value: LotComparatorDetail): string;

  complexShow?(value: LotComparatorDetail): boolean;
}
