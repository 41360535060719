import { FormlyFieldConfig } from '@ngx-formly/core';

import { Categories } from './Categories';
import { ContextData } from './ContextData';
import { FormData } from './FormData';

export interface FormResponse extends FormViewResponse {
  formData: FormData;
  template: FormlyFieldConfig[];
}

export interface FormViewResponse {
  id: string;
  prescriptorId: number;
  reservationId: number;
  title: string;
  description: string;
  developerId: number;
  version: string;
  status: Status;
  contextData: ContextData;
  categories: Categories[];
  documents: Documents[];
  cancelComment: string;
  internalComments: string;
  tags: FormViewTags[];
}

export enum Status {
  DRAFT = 'DRAFT',
  SEND = 'SEND',
  SENDING = 'SENDING',
  SIGN_ACQ = 'SIGN_ACQ',
  REJECT_ACQ = 'REJECT_ACQ',
  COUNTERSIGN = 'COUNTERSIGN',
  COUNTERSIGNED = 'COUNTERSIGNED',
  REJECT_DEV = 'REJECT_DEV',
  ERROR = 'ERROR',
  EXPIRED = 'EXPIRED',
  SIGN_PAPER = 'SIGN_PAPER',
}

export enum FormViewTags {
  SECONDARY_LOT_MODIFIED,
}

export interface Documents {
  id: string;
  name: string;
  title: string;
  documentTypeCode: string;
  mimeType: string;
}
