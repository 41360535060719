import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SearchModule } from '../search/search.module';
import { PipesModule } from '../utils/pipes/pipes.module';
import { UtilsModule } from '../utils/utils.module';
import { AccountsRoutingModule } from './accounts-routing.module';
import { AccountFormComponent } from './components/account-form/account-form.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { LogAccessFormComponent } from './components/log-access-form/log-access-form.component';
import { LogAccountComponent } from './components/log-account/log-account.component';
import { LogContainerComponent } from './components/log-container/log-container.component';
import { UpdateAccountComponent } from './components/update-account/update-account.component';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';

@NgModule({
  imports: [
    CommonModule,
    AccountsRoutingModule,
    UtilsModule,
    SearchModule,
    PipesModule,
    LogAccountComponent,
    ForgotPasswordComponent,
    UpdatePasswordComponent,
    HomepageComponent,
    LogContainerComponent,
    LogAccessFormComponent,
    ChangePasswordComponent,
    CreateAccountComponent,
    AccountFormComponent,
    UpdateAccountComponent,
  ],
  exports: [LogAccountComponent],
})
export class AccountsModule {}
