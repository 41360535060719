<div class="flex items-center flex-col w-[600px]">
  <button class="closing-dialog" (click)="closeDialog(false, false)">
    <app-icon name="XMark"></app-icon>
  </button>
  <div *ngIf="data.imageSource">
    <img [src]="data.imageSource" />
  </div>
  <h2 class="text-center" mat-dialog-title *ngIf="data.title">
    {{ i18nService._(data.title) }}
  </h2>
  <div mat-dialog-content>
    <p *ngIf="hasHTMLMessage; else translateMessage" [innerHTML]="i18nService._(data.message) | safe: 'html'"></p>
    <ng-template #translateMessage>
      <p>{{ i18nService._(data.message) }}</p>
    </ng-template>
  </div>
  <div class="justify-between" mat-dialog-actions>
    <button
      *ngIf="!data.buttonCancelHidden"
      (click)="closeDialog(false, true)"
      class="mr-0 lg:mr-4 md:mr-4 sm:mr-4 mb-3 lg:mb-0 sm:mb-0 md:mb-0"
      color="primary"
      mat-stroked-button
    >
      {{ i18nService._(data.buttonCancel) }}
    </button>
    <button
      *ngIf="!data.buttonConfirmHidden"
      (click)="closeDialog(true, true)"
      cdkFocusInitial
      class="mb-3 lg:mb-0 sm:mb-0 md:mb-0"
      color="primary"
      data-cy="Oui"
      mat-flat-button
    >
      {{ i18nService._(data.buttonConfirm) }}
    </button>
  </div>
</div>
