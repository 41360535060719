<div class="flex flex-col">
  <form *ngIf="fields" [formGroup]="form" class="form">
    <formly-form
      #formlyForm
      [fields]="fields"
      (modelChange)="onChange($event)"
      [form]="form"
      [model]="model"
      [options]="options"
    ></formly-form>

    <div class="footer-button">
      <div class="first-button">
        <button *ngIf="activePage > 1" (click)="previousPage()" type="button">
          {{ i18nService._('PreReservationCreateForm_Previous') }}
        </button>
      </div>
      <div class="second-button">
        <button *ngIf="activePage < nbPage" (click)="nextPage()" type="button">
          {{ i18nService._('PreReservationCreateForm_Next') }}
        </button>

        <button *ngIf="activePage === nbPage" [class.disabled]="!formValid" [disabled]="!formValid" (click)="submit()" type="button">
          {{ i18nService._('PreReservationCreateForm_Submit') }}
        </button>
      </div>
    </div>
  </form>
</div>
