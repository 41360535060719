import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, Observable, pluck, take, takeUntil, tap } from 'rxjs';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { AbstractComparatorComponent } from '../abstract-comparator-component';
import { LotComparatorAnnexesComponent } from './lot-comparator-annexes/lot-comparator-annexes.component';
import { LotComparatorRentAndProfitablityComponent } from './lot-comparator-rent-and-profitablity/lot-comparator-rent-and-profitablity.component';
import { LotComparatorPriceDetailsComponent } from './lot-comparator-price-details/lot-comparator-price-details.component';
import { LotComparatorInformationComponent } from './lot-comparator-information/lot-comparator-information.component';
import { LotComparatorGlanceComponent } from './lot-comparator-glance/lot-comparator-glance.component';
import { LotComparatorHeadComponent } from './lot-comparator-head/lot-comparator-head.component';

import { LotComparatorDetail } from '../../../lot/model/lot-comparator-detail';
import { breakpoints } from '../../../utils/models/breakpoint';
import { BreakpointEnum } from '../../../utils/models/enums/breakpoint.enum';
import { RoutingStateService } from '../../../utils/services/routing-state.service';
import { SpinnerWithBackdropService } from '../../../utils/services/spinner-with-backdrop.service';
import { ComparatorStorage } from '../../models/comparator-storage';
import { SafePipe } from '../../../utils/pipes/safe.pipe';
import { ButtonBackComponent } from '../../../design-system/component/app-button-back/button-back.component';

@Component({
  selector: 'app-lot-comparator',
  templateUrl: './lot-comparator.component.html',
  styleUrls: ['./lot-comparator.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    NgIf,
    ButtonBackComponent,
    NgFor,
    NgClass,
    LotComparatorHeadComponent,
    LotComparatorGlanceComponent,
    LotComparatorInformationComponent,
    LotComparatorPriceDetailsComponent,
    LotComparatorRentAndProfitablityComponent,
    LotComparatorAnnexesComponent,
    AsyncPipe,
    SafePipe,
  ],
})
export class LotComparatorComponent extends AbstractComparatorComponent implements OnInit {
  private goBackPath: string;
  lotDetailToCompare$: Observable<LotComparatorDetail[]>;
  lotDetailToCompare: LotComparatorDetail[];
  hide: boolean;
  private _comparatorStorage: ComparatorStorage;
  atLeastOneLot: boolean;

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _routingStateService: RoutingStateService,
    private readonly _spinnerWithBackdropService: SpinnerWithBackdropService,
    private readonly _breakpointObserver: BreakpointObserver,
  ) {
    super();
    this._routingStateService.previousRoutePath.pipe(take(1)).subscribe((previousRoutePath) => {
      this.goBackPath = previousRoutePath;
    });
    this.lotDetailToCompare$ = this._route.data.pipe(
      pluck('lotToCompare'),
      filter((val) => !!val),
      tap((lotDetailToCompare) => {
        if (!this.lotDetailToCompare) {
          this.lotDetailToCompare = lotDetailToCompare;
          this.computeAtLeastOneLot();
        }
      }),
    );
    this._breakpointObserver
      .observe(breakpoints[BreakpointEnum.md].down)
      .pipe(
        tap((breakPoint) => {
          this.hide = breakPoint.matches;
        }),
        takeUntil(this.$d),
      )
      .subscribe();
  }

  ngOnInit() {
    // To update lot list on comparator storage event like removeLot event
    window.scroll(0, 0);
    this._comparatorPageService
      .getComparatorStorage()
      .pipe(
        map((comparatorStorage) => {
          this._comparatorStorage = comparatorStorage;
          if (this.lotDetailToCompare) {
            this._comparatorPageService.lotsToCompareObservable.subscribe((lotsToCompare) => {
              this.lotDetailToCompare = lotsToCompare;
            });
            this.computeAtLeastOneLot();
          } else {
            this.atLeastOneLot = false;
          }
        }),
        takeUntil(this.$d),
      )
      .subscribe();
  }

  private computeAtLeastOneLot() {
    this.atLeastOneLot = false;
    this.lotDetailToCompare.forEach((lot) => {
      if (lot) {
        this.atLeastOneLot = true;
      }
    });
  }

  goBack(): () => void {
    return () => this._router.navigateByUrl(this.goBackPath);
  }

  removeLot(lot: LotComparatorDetail) {
    this._comparatorPageService.pushToDatalayerDropOneItem();
    this._comparatorPageService.removeLotToCompare(this._comparatorStorage, lot.id);
  }

  gotToSearch() {
    this._router.navigate(['recherche'], {
      queryParams: {
        bgtmi: 0,
        bgtma: 600000,
        order: 'price',
        direction: 1,
        type: 'P',
      },
    });
  }
}
