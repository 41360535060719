import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { SearchResultsComponent } from '../search-results/search-results.component';
import { SearchFixMenuComponent } from '../filters/search-fix-menu/search-fix-menu.component';

import { ProgramService } from '../../../programs/services/program.service';
import { SaveSearchService } from '../../../tracking-tables/services/save-search.service';
import { LotSearchQueryResponse } from '../../../utils/models/LotSearchQueryResponse';
import { LotSearchResponse } from '../../../utils/models/LotSearchResponse';
import { ProgramSearchQueryResponse } from '../../../utils/models/ProgramSearchQueryResponse';
import { ProgramSearchResponse } from '../../../utils/models/ProgramSearchResponse';
import { Sitemap } from '../../../utils/models/Sitemap';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { SpinnerWithBackdropService } from '../../../utils/services/spinner-with-backdrop.service';
import { ORDER_BY_MAP, SearchFormUtilsService } from '../../services/search-form-utils.service';
import { SearchFormService } from '../../services/search-form.service';
import { SearchCountResponse } from '../../model/search-count-response';
import { SearchProgramDataOrderBy } from '../../model/search-program-data-order-by';
import { SearchPageDisplayType, SearchProgramData } from '../../model/search-program-data';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
  standalone: true,
  imports: [SearchFixMenuComponent, InfiniteScrollModule, SearchResultsComponent],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SearchPageComponent implements OnInit, OnDestroy {
  /**
   * Used to know if user at least see one time the sidebar.
   *
   * @type {boolean}
   * @memberof SearchPageComponent
   */
  public isSeenSideBarSearchAtLeastOnce = true;
  public isResults = false;
  public isSpinner = false;
  public countResults: SearchCountResponse;
  public programResultList: Array<ProgramSearchResponse>;
  public lotResultList: Array<LotSearchResponse>;
  public orderBy: SearchProgramDataOrderBy;
  public searchData: SearchProgramData;
  public openDrawer = false;
  protected readonly $destroy = new Subject();
  /**
   * Locale to prevent an empty last call.
   *
   * @type {boolean}
   * @memberof SearchPageComponent
   */
  private searchSkipEnd: boolean;
  /**
   * Search data limit.
   *
   * @type {number}
   * @memberof SearchPageComponent
   */
  private readonly searchDataLimit: number;
  /**
   * Search data limit.
   *
   * @type {number}
   * @memberof SearchPageComponent
   */
  private readonly defaultOrderBy: SearchProgramDataOrderBy;
  /**
   * Scroll search subscription.
   *
   * @type {Subscription}
   * @memberof SearchPageComponent
   */
  private scrollSearchSubscribe: Subscription;
  /**
   * Default search is program.
   *
   * @type {boolean}
   * @memberof SearchPageComponent
   */
  private _searchFilter: SearchProgramData = new SearchProgramData();
  private queryParams;

  /**
   * Creates an instance of SearchPageComponent.
   *
   * @param {I18nService} i18nService
   * @param {AppConfigService} appConfig
   * @param {ProgramService} programService
   * @param {SnackbarService} snackbarService
   * @param {SearchFormService} searchFormService
   * @param route
   * @param saveSearchService
   * @param router
   * @param spinnerWithBackdropService
   * @memberof SearchPageComponent
   */
  constructor(
    public i18nService: I18nService,
    public appConfig: AppConfigService,
    private readonly programService: ProgramService,
    private readonly snackbarService: SnackbarService,
    private readonly searchFormService: SearchFormService,
    private readonly route: ActivatedRoute,
    private readonly saveSearchService: SaveSearchService,
    private readonly router: Router,
    private readonly spinnerWithBackdropService: SpinnerWithBackdropService,
    public searchFormUtilsService: SearchFormUtilsService,
  ) {
    this.searchDataLimit = this.appConfig.getAppConfig().limit;
    this.defaultOrderBy = {
      label: 'price',
      text: this.i18nService._('Txt_SortBy_Price_ASC'),
      direction: 'ASC',
    };
    this.orderBy = this.defaultOrderBy;
    this.searchData = {
      where: {},
      order: this.orderBy,
      skip: 0,
      limit: this.searchDataLimit,
    };
    this.openDrawer = Boolean(this.searchFormService.getSavedSearchId());

    const searchId = this.route.snapshot.paramMap.get('searchId');
    if (searchId) {
      this.saveSearchService.getSearch(parseInt(searchId, 10)).subscribe((search) => {
        this.searchFormService.setSearchFilter(JSON.parse(search.criterias));
        this.searchFormService.setSavedSearchId(search.id);
        this.router.navigate([Sitemap.utils.search.path]); // only way to make search fully reload with found search datas
      });
    }
  }

  ngOnInit(): void {
    this.queryParams = { ...this.route.snapshot.queryParams };
    this.route.queryParams.pipe(takeUntil(this.$destroy)).subscribe((params) => {
      this.queryParams = { ...params };
    });
    const searchProgramData = this.searchFormUtilsService.parseSearchProgramFromFilterChain(
      this.queryParams,
      this.defaultOrderBy,
      this.searchDataLimit,
    );
    this.searchFormService.setSearchProgramData(searchProgramData);
    this.searchFormService.setSearchFilter(searchProgramData.where);
    this.searchFormService.setSearchByLot(this.queryParams.type === SearchPageDisplayType.Lot);
    this.orderBy = searchProgramData.order;
    this._searchFilter = searchProgramData;
    this.search(this._searchFilter);
  }

  ngOnDestroy(): void {
    if (this.scrollSearchSubscribe) {
      this.scrollSearchSubscribe.unsubscribe();
    }

    this.$destroy.next(true);
    this.$destroy.complete();
  }

  /**
   * Change order of search request.
   *
   * @param order
   */
  public onOrderByChanged(order: SearchProgramDataOrderBy): void {
    this.orderBy = order || this.defaultOrderBy;
    this.searchFormUtilsService.pushSortChangeDatalayer(this.orderBy);
    this._searchFilter.order = this.orderBy;
    this._searchFilter.skip = 0;
    this.search(this._searchFilter);
  }

  /**
   * Toggle lot or program button.
   *
   * @param searchPageDisplayType
   */
  public onTypeByChanged(searchPageDisplayType: SearchPageDisplayType): void {
    const searchIsLot = searchPageDisplayType === SearchPageDisplayType.Lot;
    this.searchFormUtilsService.pushTypeChangeDatalayer(searchPageDisplayType);
    Object.assign(this.queryParams, {
      type: searchPageDisplayType,
      order: searchIsLot ? this.defaultOrderBy.label : this.orderBy.label,
      direction: searchIsLot ? ORDER_BY_MAP[this.defaultOrderBy.direction] : ORDER_BY_MAP[this.orderBy.direction],
      skip: 0,
    });
    this.router
      .navigate([Sitemap.utils.search.path], {
        queryParams: this.queryParams,
        replaceUrl: true,
      })
      .then(() => {
        const searchProgramData = this.searchFormUtilsService.parseSearchProgramFromFilterChain(
          this.queryParams,
          this.defaultOrderBy,
          this.searchDataLimit,
        );
        this.search(searchProgramData);
      });
  }

  /**
   * Search from filter form.
   *
   * @param filterData
   */
  public onSearch(filterData: SearchProgramData): void {
    if (!filterData || !filterData.where) {
      return;
    }
    this._searchFilter = filterData;
    // reset skip param
    this._searchFilter.skip = 0;
    // search programs and lots
    if (this.route.snapshot.queryParams.type !== SearchPageDisplayType.Lot) {
      this.searchFormUtilsService.pushDatalayer(filterData.where, true);
    }
    this.search(this._searchFilter);
  }

  /**
   * When scroll approach the scroll end of mat-drawer-content.
   */
  public onScrolled(): void {
    // only call if it's programs search and last call was full length of limit search
    if (this.route.snapshot.queryParams.type !== SearchPageDisplayType.Lot && !this.searchSkipEnd) {
      // increment skip param to search the next programs of search request
      // this._searchFilter = this.searchFormUtilsService.parseSearchProgramFromFilterChain(
      //   this.queryParams, this.defaultOrderBy, this.searchDataLimit);
      this._searchFilter.skip++;
      this.scrollSearchSubscribe = this.programService.searchPrograms(this._searchFilter).subscribe(
        (resultsData) => {
          this.searchSkipEnd = resultsData.programs.length < this.searchDataLimit;
          if (resultsData.programs.length) {
            this.programResultList = this.programResultList.concat(resultsData.programs);
          }
        },
        () => {
          this.snackbarService.sendErrorOccured();
        },
      );
    }
  }

  refreshLots(pageIndex: number) {
    if (!isNaN(pageIndex)) {
      this.queryParams = { ...this.route.snapshot.queryParams };
      this._searchFilter = this.searchFormUtilsService.parseSearchProgramFromFilterChain(
        this.queryParams,
        this.defaultOrderBy,
        this.searchDataLimit,
      );
      this._searchFilter.skip = pageIndex;
      this.search(this._searchFilter);
    }
  }

  private search(filterData: SearchProgramData) {
    this.spinnerWithBackdropService.show();
    this.isSpinner = true;
    const parseSearchProgramToFilterChain = this.searchFormUtilsService.parseSearchProgramToFilterChain(filterData);
    let searchResults: Observable<ProgramSearchQueryResponse | LotSearchQueryResponse>;
    parseSearchProgramToFilterChain.type = this.route.snapshot.queryParams.type;
    switch (this.queryParams.type) {
      case SearchPageDisplayType.Programme:
        searchResults = this.programService.searchPrograms(filterData);
        break;
      case SearchPageDisplayType.Carte:
        searchResults = this.programService.searchPrograms({ ...filterData, limit: 9999999 });
        break;
      default:
        searchResults = this.programService.searchLots(filterData);
    }
    this.router.navigate([Sitemap.utils.search.path], {
      queryParams: parseSearchProgramToFilterChain ? parseSearchProgramToFilterChain : undefined,
      replaceUrl: true,
    });
    searchResults.subscribe(
      (res) => {
        this.spinnerWithBackdropService.hide();
        this.isSpinner = false;
        this.countResults = {
          programs: res.numTotalPrograms,
          lots: res.numTotalLots,
        };

        if (this.route.snapshot.queryParams.type === SearchPageDisplayType.Lot) {
          const lotsResponse = res as LotSearchQueryResponse;
          this.lotResultList = lotsResponse.lots;
        } else {
          const programsResponse = res as ProgramSearchQueryResponse;
          this.programService.pushResultSearchToDatalayer(programsResponse.programs, {
            lots: res.numTotalLots,
            programs: res.numTotalPrograms,
          });
          this.programResultList = programsResponse.programs;
        }
        this.searchSkipEnd = this.countResults.programs < this.searchDataLimit;
        this.isResults = Boolean(this.countResults.programs);
        this.programService.setFilterSearch(filterData);
      },
      () => {
        this.spinnerWithBackdropService.hide();
        this.snackbarService.sendErrorOccured();
      },
    );
  }
}
