import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DossierResponse, ProspectResponse, SearchDossierQuery } from '@commons-dto/dossier-prospect';

import { DossierProspectService } from './dossier-prospect.service';

import { AutoCompleteService } from '../../common/form/auto-complete.service';

@Injectable({
  providedIn: 'root',
})
export class DossierProspectByPrescriptorSearch implements AutoCompleteService {
  dossiers: Partial<DossierResponse[]>;

  constructor(private dossierProspect: DossierProspectService) {}

  createLabel(dossier: Partial<DossierResponse>): string {
    if (dossier.prospects.length > 1) {
      return `${dossier.prospects[0].firstName} ${dossier.prospects[0].lastName} & ${dossier.prospects[1].firstName} ${dossier.prospects[1].lastName}`;
    } else {
      const prospect = dossier.prospects[0];
      if (prospect.companyName) {
        return `${prospect.companyName}${prospect.SIRET ? ` (SIRET: ${prospect.SIRET})}` : ''} — ${prospect.firstName} ${
          prospect.lastName
        }`;
      } else {
        return `${prospect.firstName} ${prospect.lastName} (${prospect.email})`;
      }
    }
  }

  findData(filter: string, observers: string): Observable<{ id: number; label: string; prospects: ProspectResponse[] }[]> {
    const prescriptorId = observers['props.prescriptorId'] ? parseInt(observers['props.prescriptorId'].value) : undefined;
    const searchQuery: SearchDossierQuery = {
      columns: [
        'id',
        'dossierStatusCode',
        'prospects.SIRET',
        'prospects.city',
        'prospects.civility',
        'prospects.postalCode',
        'prospects.country',
        'prospects.companyName',
        'prospects.email',
        'prospects.firstName',
        'prospects.lastName',
        'prospects.phoneNumber',
        'prospects.prospectType',
      ],
      relations: ['prospects'],
      prescriptorIds: [prescriptorId],
    };
    return this.dossierProspect.getDossierProspectBySearchQuery(searchQuery).pipe(
      map((dossierResponse: Partial<DossierResponse[]>) => {
        return dossierResponse.map((dossier: Partial<DossierResponse>) => {
          return {
            id: dossier.id,
            label: this.createLabel(dossier),
            prospects: dossier.prospects,
          };
        });
      }),
    );
  }
}
