import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chip',
  standalone: true,
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  imports: [],
})
export class ChipComponent {
  @Input() label: string;
}
