import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { NgIf } from '@angular/common';

import { RegisterFormProgramComponent } from '../register-form-program/register-form-program.component';

import { CompanyType } from '../../../utils/models/enums/CompanyType.enum';
import { ProgramStatus } from '../../../utils/models/enums/program-status.enum';
import { SnackbarMessageType } from '../../../utils/models/enums/snackbar-message-type.enum';
import { ProgramResponseUpdateForm } from '../../../utils/models/ProgramResponseUpdateForm';
import { Sitemap } from '../../../utils/models/Sitemap';
import { AccountService } from '../../../utils/services/account.service';
import { TokenService } from '../../../utils/services/authorisation/token.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { ProgramService } from '../../services/program.service';

@Component({
  selector: 'app-edit-register-form-program',
  templateUrl: './edit-register-form-program.component.html',
  styleUrls: ['./edit-register-form-program.component.scss'],
  standalone: true,
  imports: [NgIf, RegisterFormProgramComponent],
})
export class EditRegisterFormProgramComponent implements OnInit {
  /**
   * Data infos of the programs
   *
   * @type {ProgramResponseUpdateForm}
   * @memberof EditRegisterFormProgramComponent
   */
  public recoveredInfo: ProgramResponseUpdateForm;

  /**
   * Boolean used to load registerProgram Component at the end of program subscribe
   *
   * @type {boolean}
   * @memberof EditRegisterFormProgramComponent
   */
  public isReady: boolean;

  /**
   * Boolean used to identify if editing program status is DRAFT or INCOMPLETE
   *
   * @type {boolean}
   * @memberof EditRegisterFormProgramComponent
   */
  public isDraftOrIncompleteStatusProgram: boolean;

  /**
   * Boolean used to identify if editing program status is VALID
   *
   * @type {boolean}
   * @memberof EditRegisterFormProgramComponent
   */
  public isValidStatusProgram: boolean;

  /**
   * Creates an instance of EditRegisterFormProgramComponent.
   * @param {ActivatedRoute} route
   * @param {ProgramService} programService
   * @param {SnackbarService} snackbarService
   * @param {Router} router
   * @param {I18nService} i18nService
   * @param {TokenService} tokenService
   * @param {AccountService} accountService
   * @memberof EditRegisterFormProgramComponent
   */
  constructor(
    private readonly route: ActivatedRoute,
    private readonly programService: ProgramService,
    private readonly snackbarService: SnackbarService,
    private readonly router: Router,
    private readonly i18nService: I18nService,
    private readonly tokenService: TokenService,
    private readonly accountService: AccountService,
  ) {
    this.isReady = false;
  }

  /**
   * Init method
   */
  ngOnInit(): void {
    // Get UserId
    const userId: number = this.tokenService.getUserId();

    // Get ProgramId
    const programId = Number(this.route.snapshot.paramMap.get('programId'));

    // @todo : change to authguard.
    if (programId) {
      // Get program informations
      this.programService
        .getProgramInformations(programId)
        .pipe(
          switchMap((infos) => {
            return this.programService.getSpecialOffers(programId).pipe(map((specialOffers) => ({ ...infos, specialOffers })));
          }),
        )
        .subscribe(
          (infos) => {
            this.accountService.getAccountCompanyIdAndLabel(userId).subscribe((accountCompany) => {
              if (this.isEditableProgram(infos, accountCompany.id, accountCompany.label)) {
                this.recoveredInfo = infos;
                this.getProgramStatus(infos.programStatus.label);
                this.programService.setIdProgramId(programId);
                this.isReady = true;
              } else {
                this.snackbarService.sendMessage({
                  // TO DO CHANGE MESSAGE.
                  text: this.i18nService._('Error_SnackBar_RedirectPageNotAllowed'),
                  type: SnackbarMessageType.Error,
                });
                this.router.navigate([Sitemap.utils.login.path]);
              }
            });
          },
          () => {
            this.snackbarService.sendErrorOccured();
          },
        );
    }
  }

  /**
   * Function to know if user can edit program
   * VALO AND DEV can edit only VALID, INCOMPLETE, DRAFT and PUBLISHED programs
   * DEV cannot edit a program that is imported
   *
   * @private
   * @memberof EditRegisterFormProgramComponent
   */
  private readonly isEditableProgram = (
    programInfos: ProgramResponseUpdateForm,
    accountCompanyId: number,
    accountCompanyLabel: string,
  ): boolean => {
    if (
      programInfos &&
      ((programInfos.companyId === accountCompanyId && !programInfos.isImported) || CompanyType.VALORISSIMO === accountCompanyLabel) &&
      (programInfos.programStatus.label === ProgramStatus.VALID ||
        programInfos.programStatus.label === ProgramStatus.INCOMPLETE ||
        programInfos.programStatus.label === ProgramStatus.DRAFT ||
        programInfos.programStatus.label === ProgramStatus.PUBLISHED ||
        programInfos.programStatus.label === ProgramStatus.UNPUBLISHED)
    ) {
      return true;
    }

    return false;
  };

  /**
   * Get program Status to pass it to subcomponent
   * @param programStatusLabel
   */
  private getProgramStatus(programStatusLabel: string): void {
    this.isDraftOrIncompleteStatusProgram = programStatusLabel === ProgramStatus.DRAFT || programStatusLabel === ProgramStatus.INCOMPLETE;
    this.isValidStatusProgram = programStatusLabel === ProgramStatus.VALID;
  }
}
