import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';

import { ProspectsModule } from '../prospects/prospects.module';
import { UtilsModule } from '../utils/utils.module';
import { CheckboxDialogComponent } from './components/checkbox-dialog/checkbox-dialog.component';
import { OfferSpecialDialogComponent } from './components/offer-special-dialog/offer-special-dialog.component';
import { PaternityVerificationDialogComponent } from './components/paternity-verification-dialog/paternity-verification-dialog.component';
import { PreReservationSubmitionDialogComponent } from './components/pre-reservation-submition-dialog/pre-reservation-submition-dialog.component';
import { ProspectAssociationDialogComponent } from './components/prospect-association-dialog/prospect-association-dialog.component';
import { QuestionDialogComponent } from './components/question-dialog/question-dialog.component';
import { ReinitPasswordDialogComponent } from './components/reinit-password-dialog/reinit-password-dialog.component';
import { TextareaDialogComponent } from './components/textarea-dialog/textarea-dialog.component';
import { DesktopPublishingDialogComponent } from './components/desktop-publishing-dialog/desktop-publishing-dialog.component';
import { SaveSearchDialogComponent } from './components/save-search-dialog/save-search-dialog.component';
import { PipesModule } from '../utils/pipes/pipes.module';
import { HistoryLotFeesDialogComponent } from './components/history-lot-fees-dialog/history-lot-fees-dialog.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ChooseDialogComponent } from './components/choose-dialog/choose-dialog.component';
import { IconComponent } from '../common/standalone/icon/icon.component';
import { OptionDossierProspectDialogComponent } from './components/option-dossier-prospect-dialog/option-dossier-prospect-dialog.component';
import { SelectDialogComponent } from './components/select-dialog/select-dialog.component';
import { ProspectSelectDialogComponent } from './components/prospect-select-dialog/prospect-select-dialog.component';
import { PreReservationDossierProspectSubmitionDialogComponent } from './components/pre-reservation-dossier-prospect-submition-dialog/pre-reservation-dossier-prospect-submition-dialog.component';

@NgModule({
  imports: [
    UtilsModule,
    ProspectsModule,
    MatTabsModule,
    PipesModule,
    IconComponent,
    CheckboxDialogComponent,
    ReinitPasswordDialogComponent,
    TextareaDialogComponent,
    QuestionDialogComponent,
    ProspectAssociationDialogComponent,
    PaternityVerificationDialogComponent,
    PreReservationSubmitionDialogComponent,
    OfferSpecialDialogComponent,
    DesktopPublishingDialogComponent,
    SaveSearchDialogComponent,
    HistoryLotFeesDialogComponent,
    ConfirmationDialogComponent,
    ChooseDialogComponent,
    OptionDossierProspectDialogComponent,
    SelectDialogComponent,
    ProspectSelectDialogComponent,
    PreReservationDossierProspectSubmitionDialogComponent,
  ],
  exports: [CheckboxDialogComponent, OfferSpecialDialogComponent, DesktopPublishingDialogComponent, HistoryLotFeesDialogComponent],
})
export class DialogModule {}
