<ng-template #priceFormatted let-lotDetail>
  <ng-container>
    <span class="comparator-item-taxation" *ngIf="lotDetail.priceFormatted; else emptyData">
      {{ lotDetail['priceFormatted'] }}
      <span *ngIf="lotDetail.taxationTags && lotDetail.taxationTags.taxations" class="taxation-tag app-tag-taxation-comparator">{{
        lotDetail.taxationTags.taxations.tagOption.text
      }}</span>
    </span>
  </ng-container>
</ng-template>

<ng-template #reducedPriceFormatted let-lotDetail>
  <ng-container>
    <span class="comparator-item-taxation" *ngIf="lotDetail.reducedPriceFormatted; else emptyData">
      {{ lotDetail['reducedPriceFormatted'] }}
      <span
        *ngIf="lotDetail.taxationTags && lotDetail.taxationTags.reducedTaxation"
        class="taxation-tag app-tag-reduce-taxation-comparator"
        >{{ lotDetail.taxationTags.reducedTaxation.tagOption.text }}</span
      >
    </span>
  </ng-container>
</ng-template>

<ng-template #emptyData>
  <span class="empty-values">-</span>
</ng-template>

<div class="comparator-lot-separator" *ngIf="lotMetaPriceInfos?.atLeastOneShow">
  <div>
    {{ i18nService._('Txt_page_program_Detail_Lot_information_block_price') }}
  </div>
</div>
<app-lot-comparator-meta-line
  *ngIf="lotMetaPriceInfos"
  class="border-radius-bottom"
  [lotDetailToCompare]="lotDetailToCompare"
  [lotMetaWrapper]="lotMetaPriceInfos"
></app-lot-comparator-meta-line>
