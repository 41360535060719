import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, Observable } from 'rxjs';

import { ErrorHandlerService } from '../../utils/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class ChangePasswordService {
  constructor(
    private readonly http: HttpClient,
    private readonly route: ActivatedRoute,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {}

  resetPassword(newPassword: string): Observable<void> {
    const form = {
      newPassword,
    };
    const token = this.route.snapshot.queryParams.access_token;
    const url = `api/Accounts/reset-password`;
    const params = new HttpParams().set('access_token', token);

    return this.http.post<void>(url, form, { params }).pipe(
      catchError(
        this.errorHandlerService.handleError<void>('PasswordInitService', 'resetPassword', {
          400: this.errorHandlerService.errorFnFactory.default({
            snackbar: false,
          }),
        }),
      ),
    );
  }
}
