import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { NgIf } from '@angular/common';

import { IconComponent } from '../../standalone/icon/icon.component';

@Component({
  selector: 'app-formly-action',
  templateUrl: './formly-action.component.html',
  styleUrls: ['./formly-action.component.scss'],
  standalone: true,
  imports: [NgIf, IconComponent],
})
export class FormlyActionComponent extends FieldType {}
