import { RegionLocationResponse } from './RegionLocationResponse';
import { DepartmentLocationResponse } from './DepartmentLocationResponse';
import { CityResponse } from './CityResponse';
import { AgglomerationLocationResponse } from './AgglomerationLocationResponse';

export class CitiesLocationResponse {
  cities: Array<CityResponse>;
  departments: Array<DepartmentLocationResponse>;
  regions: Array<RegionLocationResponse>;
  agglomerations: Array<AgglomerationLocationResponse>;

  constructor() {
    this.cities = [];
    this.departments = [];
    this.regions = [];
    this.agglomerations = [];
  }
}
