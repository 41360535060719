import { DocumentResponse } from './DocumentResponse';

export class SpecialOfferDetailResponse {
  id: number;
  endDate: Date | string;
  startDate: Date | string;
  description: string;
  legalNotice: string;
  refSpecialOffer: string;
  title: string;
  amendment?: DocumentResponse;
}
