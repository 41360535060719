/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, debounceTime, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig, FormlyForm, FormlyModule } from '@ngx-formly/core';
import { get } from 'lodash';

import { TVA } from './../../../utils/enums/tva.enum';
import { SignatureService } from '../../services/signature.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { Categories } from '../../models/Categories';
import { LotPriceService } from '../../../common/form/services/lot-price.service';
import { NO_DEVELOPPER_SPECIAL_OFER_FILTER } from '../../models/Offer';
import { AppIconsName } from '../../../common/standalone/icon/utils/app-icon-names';
import { Taxation } from '../../models/Taxation';
import { Sitemap } from '../../../utils/models/Sitemap';
import { FormlyNavigationService } from '../../../common/formly/formly-navigation-service';

declare type Required = { [key: string]: number };
const regimeFiscalValue = {
  RESIDENCE_PRINCIPALE: 1,
  RESIDENCE_SECONDAIRE: 2,
  INVESTISSEMENT: 3,
};

@Component({
  selector: 'app-signature-form',
  templateUrl: './signature-form.component.html',
  styleUrls: ['./signature-form.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, FormlyModule],
})
export class SignatureFormComponent implements OnInit, OnDestroy, AfterViewInit {
  form = new UntypedFormGroup({});
  model;
  fields: FormlyFieldConfig[];
  setHideExpression;
  @ViewChild('formlyForm') formlyForm: FormlyForm;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  private catLabels: Record<string, { label: string; icon: AppIconsName }>;
  private taxations: { label: string; value: string }[] = [];
  private $taxationOptions = new BehaviorSubject<{ label: string; value: string }[]>([...this.taxations]);
  private taxationList: any[] = [];
  private isDiscountSubscribed = false;
  private isLotPriceofferTypeSubscribed = false;
  private isFiscalitySubscribed = false;
  private isTaxationSubscribed = false;
  private isAcqTypeSubscribed = false;
  private isFeesCommissionSubscribed = false;
  private creditsRepeatSubscribed = [];
  private signatureId: any;

  constructor(
    public readonly signatureService: SignatureService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly i18nService: I18nService,
    private readonly _lotPriceService: LotPriceService,
    private readonly formlyNavigationService: FormlyNavigationService,
  ) {
    this.signatureId = this.route.snapshot.params.signatureId;
  }

  ngAfterViewInit(): void {
    this.formValueChanges(this.signatureId);
  }

  ngOnInit(): void {
    this.getFormDossier(this.signatureId);
  }

  specialCheckForLocalisation(
    field,
    totalRequired: Required,
    groupByKey: string,
    formControl: AbstractControl,
    remainingRequired: Required,
  ) {
    if (field.props?.city?.required) {
      totalRequired[groupByKey]++;
    }
    if (field.props?.city?.required && (!formControl.value?.city || typeof formControl.value?.city !== 'string')) {
      remainingRequired[groupByKey]++;
    }
    if (field.props?.postalCode?.required) {
      totalRequired[groupByKey]++;
    }
    if (field.props?.postalCode?.required && (!formControl.value?.postalCode || typeof formControl.value?.postalCode !== 'string')) {
      remainingRequired[groupByKey]++;
    }
  }

  specialCheckForPhone(groupByKey: string, formControl: AbstractControl, remainingRequired: Required) {
    if (!formControl.value?.phoneNoCountry) {
      remainingRequired[groupByKey]++;
    }
  }

  ngOnDestroy() {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private getFormDossier(dossierId: number) {
    this.signatureService.getFormByDossierId(dossierId);
    this.signatureService.formDossier$.pipe(takeUntil(this.$destroy)).subscribe((form) => {
      if (form) {
        // set special offer behavior
        this.findSpecialOffer(form.contextData, form.template);

        // set options with BehaviorSubject
        this.getField('lot_price_fiscality', form.template).props.options = this.$taxationOptions;

        // set taxations list from context data
        this.taxationList = form.contextData.taxations ?? [];

        // set initial value for taxations base on regime selected
        this.setTaxations(this.taxationList, form.formData.model);

        // set fields of template
        this.fields = form.template;
        this.catLabels = this.findCategoryLabels(this.fields);

        // set value of field
        this.model = form.formData.model;
        for (const key in this.model) {
          const control = get(this.form.controls, key);
          const newVal = get(this.model, key);
          if (control && control.value !== newVal) {
            control.patchValue(newVal);
            control.updateValueAndValidity();
          }
        }

        // set Price of lot
        let annexesPrice = 0;
        if (!form.contextData.lot?.secondaryLotPriceIncluded) {
          form.contextData.secondaryLots.forEach((secondaryLot) => {
            if (secondaryLot && secondaryLot.price) {
              annexesPrice += secondaryLot.price;
            }
          });
        }

        let taxation: Taxation;
        if (this.model?.lot_price_fiscality?.value) {
          taxation = this.taxationList.find((taxation) => taxation.label === this.model?.lot_price_fiscality?.value);
        }

        this._lotPriceService.updateLotPrice({
          price: form?.contextData?.lot?.price,
          annexesPrice,
          discount: this.model?.lot_price_discount,
          taxation: taxation,
        });

        //set fees amount from fees commission and final price
        this.model.lot_fees_amount = this.getFeesAmount(this.model?.lot_fees_commission, this._lotPriceService.finalLotPrice);
      }
    });
  }

  private onDiscountChange(offers: any[] | null, discount: number | null) {
    if (offers === null) offers = this.form?.get('lot_price_offer_type')?.value;
    if (discount === null) discount = this.form?.get('lot_price_discount')?.value;
    if (offers?.length == 0 || !discount) {
      discount = 0;
    }
    this._lotPriceService.updateLotPrice({ discount: discount });
    this.signatureService.updataLotPriceAndTaxationInContextData(
      this._lotPriceService.initialLotPrice,
      this._lotPriceService.finalLotPrice,
      this._lotPriceService.taxation,
    );
  }

  private formValueChanges(dossierId: number) {
    this.form.valueChanges.pipe(takeUntil(this.$destroy), debounceTime(1000)).subscribe(() => {
      const creditRepeat = this.form.get('fnct_repeat') as unknown as FormArray;

      if (creditRepeat && creditRepeat.value) {
        for (let i = 0; i < creditRepeat.value.length; i++) {
          const creditRepeatForm = creditRepeat.at(i).get('fnct_credit_type');
          if (!this.creditsRepeatSubscribed.includes(creditRepeatForm)) {
            creditRepeatForm.valueChanges.pipe(takeUntil(this.$destroy)).subscribe((type) => {
              if (type === 'pret_1') {
                creditRepeat.at(i).get('fnct_pret_taux').setValue(1);
              } else if (type === 'PTZ') {
                creditRepeat.at(i).get('fnct_pret_taux').setValue(0);
              } else {
                creditRepeat.at(i).get('fnct_pret_taux').setValue(null);
              }
            });
            this.creditsRepeatSubscribed.push(creditRepeatForm);
          }
        }
      }

      if (!this.isDiscountSubscribed) {
        const discountForm = this.form?.get('lot_price_discount');
        if (discountForm) {
          discountForm.valueChanges.pipe(takeUntil(this.$destroy)).subscribe((discount) => {
            this.onDiscountChange(null, discount);
            const discountForm = this.form?.get('fees_commission');
            if (discountForm) {
              const feesAmount = this.getFeesAmount(discountForm.value, this._lotPriceService.finalLotPrice);
              this.form?.get('lot_fees_amount').setValue(feesAmount);
            }
          });
          this.isDiscountSubscribed = true;
        }
      }

      if (!this.isLotPriceofferTypeSubscribed) {
        const offerTypeForm = this.form?.get('lot_price_offer_type');
        if (offerTypeForm) {
          offerTypeForm.valueChanges.pipe(takeUntil(this.$destroy)).subscribe((offers) => {
            this.onDiscountChange(offers, null);
          });
          this.isLotPriceofferTypeSubscribed = true;
        }
      }

      if (!this.isFiscalitySubscribed) {
        const fiscalityForm = this.form?.get('lot_price_fiscality');
        if (fiscalityForm) {
          fiscalityForm.valueChanges.pipe(takeUntil(this.$destroy)).subscribe((fiscality) => {
            const taxation: Taxation = this.taxationList.find((taxation) => taxation.label === fiscality?.value) ?? {
              label: TVA.NOMINAL_TAXATION,
              taxation: 20,
            };
            this._lotPriceService.updateLotPrice({ taxation: taxation });
            this.signatureService.updataLotPriceAndTaxationInContextData(
              this._lotPriceService.initialLotPrice,
              this._lotPriceService.finalLotPrice,
              this._lotPriceService.taxation,
            );
          });
          this.isFiscalitySubscribed = true;
        }
      }

      if (!this.isFeesCommissionSubscribed) {
        const feesCommissionForm = this.form?.get('lot_fees_commission');
        if (feesCommissionForm) {
          feesCommissionForm.valueChanges.pipe(takeUntil(this.$destroy)).subscribe((feesCommission) => {
            const feesAmount = this.getFeesAmount(feesCommission, this._lotPriceService.finalLotPrice);
            this.form?.get('lot_fees_amount').setValue(feesAmount);
          });
          this.isFeesCommissionSubscribed = true;
        }
      }

      // update taxation options based on regime selected
      if (!this.isTaxationSubscribed) {
        const regimeForm = this.form?.get('lot_price_regime');
        if (regimeForm) {
          regimeForm.valueChanges.pipe(takeUntil(this.$destroy)).subscribe(() => {
            this.setTaxations(this.taxationList, this.model);
            this.form?.get('lot_price_fiscality').setValue(undefined);
          });
          this.isTaxationSubscribed = true;
        }
      }

      if (!this.isAcqTypeSubscribed) {
        const typeAcqForm = this.form?.get('acq_type');
        if (typeAcqForm) {
          typeAcqForm.valueChanges.pipe(takeUntil(this.$destroy)).subscribe(() => {
            this.signatureService.deleteKBISFromDossier(this.signatureId);
          });
          this.isAcqTypeSubscribed = true;
        }
      }

      this.signatureService.putFormData(dossierId, { categories: this.computeRequireField(), model: this.formlyForm.model }).subscribe();
    });
  }

  private getFeesAmount(fees: number, price: number) {
    let feesAmount;
    if (fees && price) {
      feesAmount = Math.round((fees / 100) * price * 100) / 100;
    }

    return feesAmount;
  }

  private findCategoryLabels(fields: FormlyFieldConfig[]): Record<string, { label: string; icon: AppIconsName }> {
    const ret: Record<string, { label: string; icon: AppIconsName }> = {};
    fields.forEach((field) => {
      field.fieldGroup.forEach((f) => {
        if (f?.props?.isHead) {
          ret[f.props.id] = {
            label: f.props.label,
            icon: f.props.headIcon,
          };
        }
      });
    });

    return ret;
  }

  private findSpecialOffer(contextData: any, fields: any[]) {
    for (const field of fields) {
      if (field.key === 'lot_price_offer_type') {
        field.props.options = contextData.offers;
      } else if (field.key === 'lot_price_offer_header') {
        if (contextData?.offers?.filter((so) => !NO_DEVELOPPER_SPECIAL_OFER_FILTER.includes(so.type)).length > 0) {
          field.props.subLabel = this.i18nService._('txt_special_offer_subLabel');
          field.props.tagLabel = this.i18nService._('txt_special_offer_tagLabel');
        }
      } else if (field.fieldGroup) {
        this.findSpecialOffer(contextData, field.fieldGroup);
      }
    }
  }

  // get a specific field
  private getField(key: string, fields: FormlyFieldConfig[]): FormlyFieldConfig {
    for (let i = 0, len = fields.length; i < len; i++) {
      const f = fields[i];
      if (f.key === key) {
        return f;
      }

      if (f.fieldGroup && !f.key) {
        const cf = this.getField(key, f.fieldGroup);
        if (cf) {
          return cf;
        }
      }
    }
  }

  public setTaxations(taxations: { label: string; taxation: string }[], model: any) {
    const regimeFiscal = model.lot_price_regime;

    this.taxations = taxations.map((taxation) => this.mapTaxation(taxation));

    switch (regimeFiscal) {
      case regimeFiscalValue.RESIDENCE_PRINCIPALE:
        this.taxations = this.taxations.filter((taxation) => taxation.value === TVA.ANRU || taxation.value === TVA.NOMINAL_TAXATION);
        break;
      case regimeFiscalValue.RESIDENCE_SECONDAIRE:
        this.taxations = [this.mapTaxation({ label: TVA.NOMINAL_TAXATION, taxation: '' })];
        break;
      case regimeFiscalValue.INVESTISSEMENT:
        this.taxations = this.taxations.filter((taxation) => taxation.value !== TVA.ANRU);
        break;
      default:
        this.taxations = [this.mapTaxation({ label: TVA.NOMINAL_TAXATION, taxation: '' })];
    }
    this.$taxationOptions.next(this.taxations);
  }

  private mapTaxation(taxation: { label: string; taxation: string }): any {
    return {
      label: this.i18nService._(taxation.label === TVA['LMNP/LMP'] ? 'LMNP' : taxation.label),
      value: taxation.label,
    };
  }

  private computeRequireField() {
    const totalRequiredFields: Required = {};
    const remainingRequiredFields: Required = {};
    const controls = this.form.controls;
    this.computeControleForRequired(controls, totalRequiredFields, remainingRequiredFields);
    const cateogories: Categories[] = [];
    Object.keys(this.catLabels).forEach((cat) => {
      cateogories.push({
        id: cat,
        label: this.catLabels[cat].label,
        icon: this.catLabels[cat].icon,
        requiredFields: totalRequiredFields[cat],
        remainedFields: remainingRequiredFields[cat],
      });
    });
    return cateogories;
  }

  private computeControleForRequired(controls: { [p: string]: AbstractControl }, totalRequired: Required, remainingRequired: Required) {
    Object.keys(controls).forEach((control) => {
      const groupByKey = control.split('_')[0];
      if (!(groupByKey in totalRequired)) {
        totalRequired[groupByKey] = 0;
      }
      if (!(groupByKey in remainingRequired)) {
        remainingRequired[groupByKey] = 0;
      }
      const formControl = controls[control];
      const fields = formControl['_fields'];
      if (fields?.length) {
        fields[0].type === 'repeat'
          ? this.addRepeatRequired(totalRequired, formControl, remainingRequired)
          : this.addSimpleRequired(fields[0], totalRequired, groupByKey, formControl, remainingRequired);
      }
    });
  }

  private addSimpleRequired(field, totalRequired: Required, groupByKey: string, formControl: AbstractControl, remainingRequired: Required) {
    if (field.props?.required) {
      totalRequired[groupByKey]++;
    }
    if (formControl?.errors) {
      remainingRequired[groupByKey]++;
    }
    if (field.type === 'localization') {
      this.specialCheckForLocalisation(field, totalRequired, groupByKey, formControl, remainingRequired);
    }
    if (field.props?.required && field.type === 'phone') {
      this.specialCheckForPhone(groupByKey, formControl, remainingRequired);
    }
  }

  private addRepeatRequired(totalRequired: Required, formControl: AbstractControl, remainingRequired: Required) {
    if (formControl instanceof UntypedFormArray) {
      formControl.controls.forEach((control) => {
        if (control instanceof UntypedFormGroup) {
          this.computeControleForRequired(control.controls, totalRequired, remainingRequired);
        }
      });
    }
  }

  submit(): void {
    const dashboardPath = Sitemap.signature.dashboard.path.replace(':signatureId', this.signatureId);
    this.router.navigate([dashboardPath]);
  }
}
