import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../security/authguard';
import { StrategiesComponent } from './components/strategies/strategies.component';
import { Sitemap } from '../utils/models/Sitemap';

const routes: Routes = [
  {
    path: Sitemap.programs.strategies.path,
    data: {
      roles: Sitemap.programs.strategies.data.roles,
    },
    component: StrategiesComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
  declarations: [],
})
export class StrategiesRoutingModule {}
