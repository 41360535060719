<app-form-file-drop
  [acceptedFilesLabel]="field.props.acceptedFilesLabel"
  [docType]="field.props.typeDoc"
  [documentForId]="dossierId"
  [files]="field.props.files"
  [formControl]="$any(field.formControl)"
  [maxFileSize]="field.props.maxFileSize"
  [multiple]="field.props.multiple"
  [readonly]="field.props.readonly"
  [selectFileLabel]="field.props.label"
  [types]="field.props.types"
  [uploadService]="signatureDocumentService"
  fileRequiredErrorI18nToken="Error_Field_SupportingDocumentRequired"
  [attr.data-cy]="field.props.label"
></app-form-file-drop>
