import { Injectable } from '@angular/core';

import { ProgramsSalesTrackingTableServiceResponse } from '../models/ProgramsSalesTrackingTableServiceResponse';
import { AbstractAdvancedTrackingTableService } from './abstract-advanced-tracking-table.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramsSalesTrackingTableService extends AbstractAdvancedTrackingTableService<ProgramsSalesTrackingTableServiceResponse> {
  protected url = 'Programs/tracking-table/sales';
}
