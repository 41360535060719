<div class="grid gap:4 lg:gap-6 mb-3" [ngClass]="teamFilter || partnerFilter ? 'grid-cols-8' : 'grid-cols-7'">
  <ng-container *ngIf="hasData">
    <div class="col-span-2">
      <mat-form-field class="fullW flex w-full">
        <mat-label>{{ i18nService._(filterPlaceholderI18nToken) }}</mat-label>
        <input #filterInput [(ngModel)]="filter" matInput />
      </mat-form-field>
    </div>
    <ng-container *ngIf="refenceTableToFilter">
      <ng-container *ngFor="let item of referencesTables">
        <ng-container *ngIf="item.showFilter" [ngSwitch]="item.type">
          <ng-container *ngSwitchCase="filterTypes.SELFSEARCH">
            <app-self-search-filters-tracking-table
              [selfFilterForm]="selfFilterForm"
              [tableColumn]="item"
            ></app-self-search-filters-tracking-table>
          </ng-container>
          <ng-container *ngSwitchCase="filterTypes.DATE">
            <app-date-filters-tracking-table [selfFilterForm]="selfFilterForm" [tableColumn]="item"></app-date-filters-tracking-table>
          </ng-container>
          <ng-container *ngSwitchCase="filterTypes.BOOLEAN">
            <app-boolean-filters-tracking-table
              [i18nFiltersForm]="i18nFiltersForm"
              [refenceTableToFilter]="refenceTableToFilterBoolean"
              [tableColumn]="item"
            ></app-boolean-filters-tracking-table>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <app-i18n-filters-tracking-table
              [i18nFiltersForm]="i18nFiltersForm"
              [refenceTableToFilter]="refenceTableToFilter"
              [tableColumn]="item"
            ></app-i18n-filters-tracking-table>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="activateColumnsToDisplay">
    <mat-form-field [hidden]="!hasData" class="mat-form-field-middleWidth">
      <mat-label>{{ i18nService._('Txt_Placeholder_ColumnsToDisplay') }}</mat-label>
      <mat-select [(ngModel)]="selectedColumns" multiple>
        <mat-select-trigger>
          {{ selectedColumnsSummary }}
        </mat-select-trigger>
        <ng-container *ngFor="let column of columns">
          <mat-option (onSelectionChange)="onSelectedCheckboxChange($event)" *ngIf="column.key !== 'id'" [value]="column">
            {{ i18nService._(column.i18nTitle) }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <div class="flex h-full items-center" *ngIf="teamFilter || partnerFilter">
    <app-toggle-button (selectChange)="changeTeamFilter($event)" *ngIf="teamFilter" [checked]="teamFilterChecked">
      <span class="font-bold">{{ i18nService._('Txt_TrackingTable_MY_TEAM') }}</span>
    </app-toggle-button>
    <app-toggle-button (selectChange)="changePartnerFilter($event)" *ngIf="partnerFilter" [checked]="partnerFilterChecked">
      <span class="font-bold">{{ i18nService._('Txt_TrackingTable_MY_PARTNERS') }}</span>
    </app-toggle-button>
  </div>
</div>

<ng-container *ngIf="!isLoadingResults && !hasData">
  <div class="my-4 text-center">
    {{ i18nService._(noResultMessageI18nToken) }}
  </div>
</ng-container>

<div class="tracking-mat-table">
  <div *ngIf="isLoadingResults" [ngClass]="{ 'no-background': !dataLoaded }" class="spinner-content">
    <mat-spinner [color]="'primary'" [diameter]="60" [strokeWidth]="6" class="mx-auto my-5"></mat-spinner>
  </div>
  <div [hidden]="!hasData || !dataLoaded" class="shadow-sm overflow-auto">
    <table [dataSource]="dataSource" [matSortActive]="columnSort" [matSortDirection]="columnSortOrder" mat-table matSort>
      <ng-container *ngFor="let column of displayedColumns" matColumnDef="{{ column.key }}">
        <th
          *matHeaderCellDef
          [disabled]="!column.sortable"
          [innerHTML]="i18nService._(column.i18nTitle) | safe: 'html'"
          mat-header-cell
          mat-sort-header="{{ column.key }}"
        ></th>

        <td *matCellDef="let item" [className]="getCellItemClass(column, item)" mat-cell>
          <div [className]="getItemClass(column, item)">
            <ng-container *ngIf="isNgTemplate(item, column.key); else elseBlock" [ngTemplateOutlet]="item[column.key]"></ng-container>
            <ng-template #elseBlock>
              <span *ngIf="!column.statusTagOption" [innerHTML]="item[column.key] | advancedTrackingTablePipe: column | safe: 'html'">
              </span>
              <app-status-tags *ngIf="column.statusTagOption" [outline]="true" [statusColor]="column.statusTagOption.computeTagType(item)">
                <span
                  [innerHTML]="item[column.key] | advancedTrackingTablePipe: column | safe: 'html'"
                  class="text-center"
                  style="margin-bottom: -1px"
                ></span>
              </app-status-tags>
            </ng-template>
            <mat-icon
              *ngIf="column.picto"
              [ngClass]="column.picto.class"
              class="valign-picto"
              matTooltip="{{ column.picto.i18nTooltip ? i18nService._(column.picto.i18nTooltip) : '' }}"
            >
              {{ column.picto.iconName }}
            </mat-icon>
          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumnsKeys" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumnsKeys" mat-row></tr>
    </table>
  </div>
  <mat-paginator
    [hidden]="!hasData || !dataLoaded"
    [length]="length"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
