import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

import { InfoBoxComponent } from '../../shared/info-box/info-box.component';

@Component({
  selector: 'app-formly-info-box',
  templateUrl: './formly-info-box.component.html',
  styleUrls: ['./formly-info-box.component.css'],
  standalone: true,
  imports: [InfoBoxComponent],
})
export class FormlyInfoBoxComponent extends FieldType {}
