import { IsEnum } from 'class-validator';

import { SearchCriteria } from './search-criteria.enum';

export class DossierSearchPreferenceSaveQueryDto {
  @IsEnum(SearchCriteria)
  searchCriteriaCode: SearchCriteria;

  value: any;
}
