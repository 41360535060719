import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable } from 'rxjs';
import { ProspectType } from 'libs/commons-dto/src/lib/dossier-prospect/prospect/prospect-type.enum';
import { DossierResponse } from '@commons-dto/dossier-prospect';

import { LotIdUpdateToOption } from './prospect-association.service';
import { DossierProspectTakeOptionData } from '../models/DossierProspectTakeOptionData';

import { PoseoptionResponse } from '../../utils/models/PoseoptionResponse';
import { AppConfigService } from '../../utils/services/app-config.service';
import { ErrorHandlerService } from '../../utils/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class DossierProspectAssociationService {
  private readonly lotIdUpdateToOption: BehaviorSubject<LotIdUpdateToOption>;

  /**
   * Creates an instance of ProspectAssociationService.
   * @param {HttpClient} http
   * @param {ErrorHandlerService} errorHandlerService
   * @param {AppConfigService} appConfig
   * @memberof ProspectAssociationService
   */
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly appConfig: AppConfigService,
  ) {
    this.lotIdUpdateToOption = new BehaviorSubject(undefined);
  }

  get lotIdUpdateToOptionObservable(): Observable<LotIdUpdateToOption> {
    return this.lotIdUpdateToOption.asObservable();
  }

  setLotIdUpdateToOption(lotIdUpdateToOption: LotIdUpdateToOption): void {
    this.lotIdUpdateToOption.next(lotIdUpdateToOption);
  }

  /**
   * getProspects method
   *
   * @returns {Observable<any>}
   * @memberof DossierProspectAssociationService
   */
  getDossierProspects(prospectType?: ProspectType): Observable<{ items: Array<DossierResponse> }> {
    let url = `${this.appConfig.getDossierProspectApiUrl()}/dossiers/list?limit=100000`;
    if (prospectType) {
      url += `&prospectType=${prospectType}`;
    }

    return this.http
      .get<{ items: Array<DossierResponse> }>(url)
      .pipe(
        catchError(
          this.errorHandlerService.handleError<{ items: Array<DossierResponse> }>(
            'dossierProspectAssociationService',
            'getDossierProspects',
          ),
        ),
      );
  }

  /**
   * takeOption method, need param option data
   *
   * @param {DossierProspectTakeOptionData} optionData
   * @returns {Observable<any>}
   * @memberof ProspectAssociationService
   */
  takeOption(optionData: DossierProspectTakeOptionData): Observable<PoseoptionResponse> {
    const url = `${this.appConfig.getLoopbackApiUrl()}/Options/dossier-prospect-take`;

    return this.http
      .post<PoseoptionResponse>(url, optionData)
      .pipe(catchError(this.errorHandlerService.handleError<PoseoptionResponse>('dossierProspectAssociationService', 'takeOption')));
  }
}
