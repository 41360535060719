import { TemplateRef } from '@angular/core';

import { LotComparatorDetail } from '../../lot/model/lot-comparator-detail';
import { AppConfigService } from '../../utils/services/app-config.service';

const hasTaxation = (lot: LotComparatorDetail, taxationToTest: string[]): boolean => {
  let hasTestTaxation = false;
  taxationToTest.forEach((t) => {
    if (lot.taxationsLabels && lot.taxationsLabels.length && lot.taxationsLabels.includes(t)) {
      hasTestTaxation = true;
    }
  });

  return hasTestTaxation;
};
export const hasAnnexes = (lot: LotComparatorDetail, annexeLabel: string): boolean => {
  if (!lot.annexes || !lot.annexes.length) {
    return false;
  }

  return !!lot.annexes.find((element) => element.label === annexeLabel);
};

export class ComparatorMeta {
  field: string;
  translationKey?: string;
  title?: string;
  mandatory: boolean;
  show: boolean;
  type: 'base' | 'array' | 'object';
  templateRef?: TemplateRef<unknown>;

  format?(value): string;

  complexShow?(lot: LotComparatorDetail): boolean;
}

export interface ComparatorMetaWrapper {
  titles?: string[];
  atLeastOneShow?: boolean;

  [key: string]: ComparatorMeta | string[] | unknown;
}

// eslint-disable-next-line max-classes-per-file
export class ComparatorPageAllDataMeta {
  glance: ComparatorMetaWrapper;
  rentAndProfitability: ComparatorMetaWrapper;
  priceInfos: ComparatorMetaWrapper;
  information: ComparatorMetaWrapper;
  annexes: ComparatorMetaWrapper;
}

export const COMPARATOR_META: ComparatorPageAllDataMeta = {
  glance: {
    titles: [
      'commercialLotSellingPriceIT',
      'rooms',
      'specialOffer',
      'taxationsLabels',
      'profitability',
      'actable',
      'deliveryDate',
      'annexes',
      'lotStrongPoints',
    ],
    commercialLotSellingPriceIT: {
      field: 'commercialLotSellingPriceIT',
      translationKey: 'Txt_Table_Column_CommercialLotSellingPriceIT',
      mandatory: true,
      show: true,
      type: 'base',
    },
    rooms: {
      field: 'rooms',
      translationKey: 'Txt_Table_Column_Rooms',
      mandatory: true,
      show: true,
      type: 'base',
    },
    specialOffer: {
      field: 'specialOffer',
      translationKey: 'Txt_Table_SpecialOffer',
      mandatory: false,
      show: true,
      type: 'base',
    },
    taxationsLabels: {
      field: 'taxationsLabels',
      translationKey: 'Txt_Page_Form_RegisterProgram_LotTaxationSection',
      mandatory: false,
      show: true,
      type: 'array',
    },
    profitability: {
      field: 'profitability',
      translationKey: 'Txt_Table_Column_ProfitabilityET',
      mandatory: false,
      show: true,
      type: 'base',
    },
    annexes: {
      field: 'annexes',
      translationKey: 'Title_Form_SearchForm_Annexes',
      mandatory: false,
      show: true,
      type: 'array',
    },
    lotStrongPoints: {
      field: 'lotStrongPoints',
      translationKey: 'Title_Form_SearchForm_StrongPoints',
      mandatory: false,
      show: true,
      type: 'array',
    },
    actable: {
      field: 'actable',
      translationKey: 'Title_Form_SearchForm_Actable',
      mandatory: false,
      show: true,
      type: 'base',
    },
    deliveryDate: {
      field: 'deliveryDate',
      translationKey: 'Txt_ProgramsTrackingTable_DeliveryDate',
      mandatory: false,
      show: true,
      type: 'base',
    },
  },
  rentAndProfitability: {
    titles: [
      'estmatedMonthlyRentingPrice',
      'estimatedProfitability',
      'pinelTaxationRentingPrice',
      'pinelPlusTaxationRentingPrice',
      'pinelTaxationProfitability',
      'pinelPlusTaxationProfitability',
      'plsTaxationRentingPrice',
      'plsTaxationProfitability',
      'guaranteedMonthlyRentingPriceIT',
      'monthlyRentingPriceET',
      'lmnpLmpTaxationProfitability',
      'lmnpLmpNonGereTaxationProfitability',
      'lmnpLmpNonGereTaxationRentingPrice',
      'estimatedFurnishedMarketRent',
      'estimatedFurnishedMarketYield',
    ],
    estmatedMonthlyRentingPrice: {
      field: 'estmatedMonthlyRentingPrice',
      translationKey: 'Txt_Placeholder_EstmatedMonthlyRentingPrice',
      mandatory: false,
      show: true,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels.PINEL, taxationLabels.PLS, taxationLabels['PINEL PLUS'], taxationLabels['LMNP NON GERE']])) {
          return false;
        }

        return lot.estmatedMonthlyRentingPrice !== undefined && lot.estmatedMonthlyRentingPrice !== null;
      },
    },
    estimatedProfitability: {
      field: 'estimatedProfitability',
      translationKey: 'Title_ProfitabilityLegal',
      mandatory: false,
      show: true,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels.PINEL, taxationLabels.PLS, taxationLabels['PINEL PLUS'], taxationLabels['LMNP NON GERE']])) {
          return false;
        }

        return lot.estimatedProfitability !== undefined && lot.estimatedProfitability !== null;
      },
    },
    pinelTaxationRentingPrice: {
      field: 'pinelTaxation',
      translationKey: 'Txt_Page_Lot_Details_PinelRentingPrice',
      mandatory: false,
      show: true,
      type: 'object',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels.PINEL])) {
          return false;
        }
        if (!lot.pinelTaxation || !Object.keys(lot.pinelTaxation).length) {
          return false;
        }

        return lot.pinelTaxation.rentingPrice !== undefined && lot.pinelTaxation.rentingPrice !== null;
      },
    },
    pinelPlusTaxationRentingPrice: {
      field: 'pinelPlusTaxation',
      translationKey: 'Txt_Page_Lot_Details_PinelPlusRentingPrice',
      mandatory: false,
      show: true,
      type: 'object',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels['PINEL PLUS']])) {
          return false;
        }
        if (!lot.pinelPlusTaxation || !Object.keys(lot.pinelPlusTaxation).length) {
          return false;
        }

        return lot.pinelPlusTaxation.rentingPrice !== undefined && lot.pinelPlusTaxation.rentingPrice !== null;
      },
    },
    pinelTaxationProfitability: {
      field: 'pinelTaxation',
      translationKey: 'Txt_Page_Lot_Details_PinelProfitability',
      mandatory: false,
      show: true,
      type: 'object',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels.PINEL])) {
          return false;
        }
        if (!lot.pinelTaxation || !Object.keys(lot.pinelTaxation).length) {
          return false;
        }

        return lot.pinelTaxation.profitability !== undefined && lot.pinelTaxation.profitability !== null;
      },
    },
    pinelPlusTaxationProfitability: {
      field: 'pinelPlusTaxation',
      translationKey: 'Txt_Page_Lot_Details_PinelPlusProfitability',
      mandatory: false,
      show: true,
      type: 'object',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels['PINEL PLUS']])) {
          return false;
        }
        if (!lot.pinelPlusTaxation || !Object.keys(lot.pinelPlusTaxation).length) {
          return false;
        }

        return lot.pinelPlusTaxation.profitability !== undefined && lot.pinelPlusTaxation.profitability !== null;
      },
    },
    lmnpLmpNonGereTaxationProfitability: {
      field: 'lmnpLmpNonGereTaxation',
      translationKey: 'Txt_Page_Lot_Details_LmnpLmpNonGereProfitability',
      mandatory: false,
      show: true,
      type: 'object',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels['LMNP NON GERE']])) {
          return false;
        }
        if (!lot.lmnpLmpNonGereTaxation || !Object.keys(lot.lmnpLmpNonGereTaxation).length) {
          return false;
        }

        return lot.lmnpLmpNonGereTaxation.profitability !== undefined && lot.lmnpLmpNonGereTaxation.profitability !== null;
      },
    },
    lmnpLmpNonGereTaxationRentingPrice: {
      field: 'lmnpLmpNonGereTaxation',
      translationKey: 'Txt_Page_Lot_Details_LmnpLmpNonGereRentingPrice',
      mandatory: false,
      show: true,
      type: 'object',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels['LMNP NON GERE']])) {
          return false;
        }
        if (!lot.lmnpLmpNonGereTaxation || !Object.keys(lot.lmnpLmpNonGereTaxation).length) {
          return false;
        }

        return lot.lmnpLmpNonGereTaxation.rentingPrice !== undefined && lot.lmnpLmpNonGereTaxation.rentingPrice !== null;
      },
    },
    estimatedFurnishedMarketRent: {
      field: 'estimatedFurnishedMarketRent',
      translationKey: 'Txt_Page_Program_EstimatedFurnishedMarketRent',
      mandatory: false,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels['LMNP NON GERE']])) {
          return false;
        }

        if (!lot.lmnpLmpNonGereTaxation || !Object.keys(lot.lmnpLmpNonGereTaxation).length) {
          return false;
        }

        return lot.estimatedFurnishedMarketRent !== undefined && lot.estimatedFurnishedMarketRent !== null;
      },
    },
    estimatedFurnishedMarketYield: {
      field: 'estimatedFurnishedMarketYield',
      translationKey: 'Txt_Page_Program_EstimatedFurnishedMarketYield',
      mandatory: false,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels['LMNP NON GERE']])) {
          return false;
        }

        if (!lot.lmnpLmpNonGereTaxation || !Object.keys(lot.lmnpLmpNonGereTaxation).length) {
          return false;
        }

        return lot.estimatedFurnishedMarketYield !== undefined && lot.estimatedFurnishedMarketYield !== null;
      },
    },
    plsTaxationRentingPrice: {
      field: 'plsTaxation',
      translationKey: 'Txt_Page_Lot_Details_PlsRentingPrice',
      mandatory: false,
      show: true,
      type: 'object',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels.PLS])) {
          return false;
        }
        if (!lot.plsTaxation || !Object.keys(lot.plsTaxation).length) {
          return false;
        }

        return lot.plsTaxation.rentingPrice !== undefined && lot.plsTaxation.rentingPrice !== null;
      },
    },
    plsTaxationProfitability: {
      field: 'plsTaxation',
      translationKey: 'Txt_Page_Lot_Details_PlsProfitability',
      mandatory: false,
      show: true,
      type: 'object',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels.PLS])) {
          return false;
        }
        if (!lot.plsTaxation || !Object.keys(lot.plsTaxation).length) {
          return false;
        }

        return lot.plsTaxation.profitability !== undefined && lot.plsTaxation.profitability !== null;
      },
    },
    monthlyRentalLotPriceIT: {
      field: 'monthlyRentalLotPriceIT',
      translationKey: 'Txt_Page_Lot_Details_GuaranteedMonthlyRentingPriceIT',
      mandatory: false,
      show: true,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels.LMNPLMP])) {
          return false;
        }

        return lot.monthlyRentalLotPriceIT !== undefined && lot.monthlyRentalLotPriceIT !== null;
      },
    },
    guaranteedMonthlyRentingPriceIT: {
      field: 'guaranteedMonthlyRentingPriceIT',
      translationKey: 'Txt_Page_Lot_Details_GuaranteedMonthlyRentingPriceIT',
      mandatory: false,
      show: true,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels.LMNPLMP])) {
          return false;
        }

        return lot.guaranteedMonthlyRentingPriceIT !== undefined && lot.guaranteedMonthlyRentingPriceIT !== null;
      },
    },
    monthlyRentingPriceET: {
      field: 'monthlyRentingPriceET',
      translationKey: 'Txt_Page_Lot_Details_MonthlyRentingPriceET',
      mandatory: false,
      show: true,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels.LMNPLMP])) {
          return false;
        }

        return lot.monthlyRentingPriceET !== undefined && lot.monthlyRentingPriceET !== null;
      },
    },
    lmnpLmpTaxationProfitability: {
      field: 'lmnpLmpTaxation',
      translationKey: 'Txt_Page_Lot_Details_LmnpLmpProfitability',
      mandatory: false,
      show: true,
      type: 'object',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels.LMNPLMP])) {
          return false;
        }

        if (!lot.lmnpLmpTaxation || !Object.keys(lot.lmnpLmpTaxation).length) {
          return false;
        }

        return lot.lmnpLmpTaxation.profitability !== undefined && lot.lmnpLmpTaxation.profitability !== null;
      },
    },
  },
  priceInfos: {
    titles: [
      'priceFormatted',
      'reducedPriceFormatted',
      'estimatedWorkPrice',
      'estimatedLandPrice',
      'usufruitValue',
      'distributionKey',
      'nueProprieteDuration',
      'estimatedFurnishedMarketRent',
      'estimatedFurnishedMarketYield',
    ],
    priceFormatted: {
      field: 'priceFormatted',
      translationKey: 'Txt_Page_Program_ListTotalSellingPriceIT',
      mandatory: true,
      show: true,
      type: 'base',
    },
    estimatedWorkPrice: {
      field: 'estimatedWorkPrice',
      translationKey: 'Txt_Page_Program_ListEstimatedWorkPrice',
      mandatory: false,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels.MALRAUX, taxationLabels['DEFICIT FONCIER'], taxationLabels['MONUMENTS HISTORIQUES']])) {
          return false;
        }

        return lot.estimatedWorkPrice !== undefined && lot.estimatedWorkPrice !== null;
      },
    },

    usufruitValue: {
      field: 'usufruitValue',
      translationKey: 'Txt_Page_Program_UsufruitValue',
      mandatory: false,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels['NUE PROPRIETE']])) {
          return false;
        }

        return lot.usufruitValue !== undefined && lot.usufruitValue !== null;
      },
    },
    distributionKey: {
      field: 'distributionKey',
      translationKey: 'Txt_Page_Program_DistributionKey',
      mandatory: false,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels['NUE PROPRIETE']])) {
          return false;
        }

        return lot.distributionKey !== undefined && lot.distributionKey !== null;
      },
    },
    nueProprieteDuration: {
      field: 'nueProprieteDuration',
      translationKey: 'Txt_Page_Program_NueProprieteDuration',
      mandatory: false,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels['NUE PROPRIETE']])) {
          return false;
        }

        return lot.nueProprieteDuration !== undefined && lot.nueProprieteDuration !== null;
      },
    },
    estimatedLandPrice: {
      field: 'estimatedLandPrice',
      translationKey: 'Txt_Page_Program_ListEstimatedLandPrice',
      mandatory: false,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        const taxationLabels = AppConfigService.getAppConfig().taxationList;
        if (!hasTaxation(lot, [taxationLabels.MALRAUX, taxationLabels['DEFICIT FONCIER'], taxationLabels['MONUMENTS HISTORIQUES']])) {
          return false;
        }

        return lot.estimatedLandPrice !== undefined && lot.estimatedLandPrice !== null;
      },
    },
    reducedPriceFormatted: {
      field: 'reducedPriceFormatted',
      translationKey: 'Txt_Page_Program_ListReducedTotalSellingPriceIT',
      mandatory: false,
      show: true,
      type: 'base',
    },
  },
  information: {
    commercialLotSellingPriceIT: {
      field: 'commercialLotSellingPriceIT',
      translationKey: 'TXT_comparator_information_price',
      mandatory: true,
      show: true,
      type: 'base',
    },
    rooms: {
      field: 'rooms',
      translationKey: 'TXT_comparator_information_rooms',
      mandatory: true,
      show: true,
      type: 'base',
    },
    livingSpace: {
      field: 'livingSpace',
      translationKey: 'TXT_comparator_information_livingSpace',
      mandatory: true,
      show: true,
      type: 'base',
    },
    taxations: {
      field: 'taxationsLabels',
      translationKey: 'TXT_comparator_information_taxations',
      mandatory: false,
      show: true,
      type: 'array',
    },
    typology: {
      field: 'typology',
      translationKey: 'TXT_comparator_information_typology',
      mandatory: false,
      show: true,
      type: 'base',
    },
    floor: {
      field: 'floor',
      translationKey: 'TXT_comparator_information_floor',
      mandatory: false,
      show: true,
      type: 'base',
    },
    orientation: {
      field: 'orientation',
      translationKey: 'TXT_comparator_information_orientation',
      mandatory: false,
      show: true,
      type: 'base',
    },
    deliveryDate: {
      field: 'deliveryDate',
      translationKey: 'TXT_comparator_information_deliveryDate',
      mandatory: false,
      show: true,
      type: 'base',
    },
    fees: {
      field: 'fees',
      translationKey: 'TXT_comparator_information_fees',
      mandatory: false,
      show: true,
      type: 'base',
    },
    specialOffer: {
      field: 'specialOffer',
      translationKey: 'TXT_comparator_information_specialOffer',
      mandatory: false,
      show: true,
      type: 'base',
    },
    titles: [
      'commercialLotSellingPriceIT',
      'rooms',
      'livingSpace',
      'taxations',
      'typology',
      'floor',
      'orientation',
      'deliveryDate',
      'fees',
      'specialOffer',
    ],
  },
  annexes: {
    TERRACE: {
      field: 'TERRACE',
      translationKey: 'TXT_comparator_annexe_Terrace',
      mandatory: false,
      show: true,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        return hasAnnexes(lot, 'TERRACE');
      },
    },
    BALCONY: {
      field: 'BALCONY',
      translationKey: 'TXT_comparator_annexe_Balcony',
      mandatory: false,
      show: true,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        return hasAnnexes(lot, 'BALCONY');
      },
    },
    GARDEN: {
      field: 'GARDEN',
      translationKey: 'TXT_comparator_annexe_Garden',
      mandatory: false,
      show: true,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        return hasAnnexes(lot, 'GARDEN');
      },
    },
    LOGGIA: {
      field: 'LOGGIA',
      translationKey: 'TXT_comparator_annexe_Loggia',
      mandatory: false,
      show: true,
      type: 'array',
      complexShow: (lot: LotComparatorDetail): boolean => {
        return hasAnnexes(lot, 'LOGGIA');
      },
    },
    OUTDOOR_PARKING: {
      field: 'OUTDOOR_PARKING',
      translationKey: 'TXT_comparator_annexe_OUTDOOR_PARKING',
      mandatory: false,
      show: true,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        return hasAnnexes(lot, 'OUTDOOR_PARKING');
      },
    },
    BASEMENT_PARKING_LOT: {
      field: 'BASEMENT_PARKING_LOT',
      translationKey: 'TXT_comparator_annexe_BASEMENT_PARKING_LOT',
      mandatory: false,
      show: true,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        return hasAnnexes(lot, 'BASEMENT_PARKING_LOT');
      },
    },
    GARAGE: {
      field: 'GARAGE',
      translationKey: 'TXT_comparator_annexe_garage',
      mandatory: false,
      show: true,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        return hasAnnexes(lot, 'GARAGE');
      },
    },
    BOXES: {
      field: 'BOXES',
      translationKey: 'TXT_comparator_annexe_boxes',
      mandatory: false,
      show: true,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        return hasAnnexes(lot, 'BOXES');
      },
    },
    LARDER: {
      field: 'LARDER',
      translationKey: 'TXT_comparator_annexe_larder',
      mandatory: false,
      show: true,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        return hasAnnexes(lot, 'LARDER');
      },
    },
    ATTIC: {
      field: 'ATTIC',
      translationKey: 'TXT_comparator_annexe_attic',
      mandatory: false,
      show: true,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        return hasAnnexes(lot, 'ATTIC');
      },
    },
    CELLAR: {
      field: 'CELLAR',
      translationKey: 'TXT_comparator_annexe_cellar',
      mandatory: false,
      show: true,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        return hasAnnexes(lot, 'CELLAR');
      },
    },
    CARPORT: {
      field: 'CARPORT',
      translationKey: 'TXT_comparator_annexe_carport',
      mandatory: false,
      show: true,
      type: 'base',
      complexShow: (lot: LotComparatorDetail): boolean => {
        return hasAnnexes(lot, 'CARPORT');
      },
    },
    titles: [
      'BALCONY',
      'TERRACE',
      'GARDEN',
      'LOGGIA',
      'OUTDOOR_PARKING',
      'BASEMENT_PARKING_LOT',
      'GARAGE',
      'BOXES',
      'LARDER',
      'ATTIC',
      'CELLAR',
      'CARPORT',
    ],
  },
};
