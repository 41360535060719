import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AutoCompleteService } from '../../common/form/auto-complete.service';
import { LotApiService } from '../../adapters/lot-api.service';
import { LotsResponse } from '../../adapters/models/lot/lots.response';
import { LotResponse } from '../../adapters/models/lot/lot.response';
import { LotParamRequest } from '../../adapters/models/lot/lot-request';

@Injectable({
  providedIn: 'root',
})
export class ProgramLotSearch implements AutoCompleteService {
  constructor(private lotApiService: LotApiService) {}

  findData(lotNumber: string, observers: any): Observable<{ label: string; id: number }[]> {
    const programId = observers['props.programId'] ? observers['props.programId'].value : undefined;
    const params: LotParamRequest = { programId: programId, lotNumber: lotNumber, availableOnly: true, offset: 0, nbResult: 200 };
    return this.lotApiService.getLots(params).pipe(
      map((lotsResponse: LotsResponse) => {
        return lotsResponse.items.map((lot: LotResponse) => {
          return {
            id: lot.id,
            label: lot.lotNumber,
          };
        });
      }),
    );
  }
}
