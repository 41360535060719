import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DataLayerApiService } from './data-layer-api.service';
import { Item } from '../tracking-tables/models/Item';

@Injectable({ providedIn: 'root' })
export class ProfessionalCategoryTypeApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Get getProfessionalCategories
   *
   * @returns {Observable<Array<Item>}
   * @memberof DataLayerApiService
   */
  getProfessionalCategories(): Observable<Array<Item>> {
    const path = this.BASE_URL + 'ProfessionalCategories';
    return this.get(path);
  }
}
