/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectionModel } from '@angular/cdk/collections';
/* eslint-disable no-case-declarations */
import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Subject, takeUntil, throttleTime } from 'rxjs';
import { ListResponse } from '@commons-dto/valo-back';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor, NgClass, NgTemplateOutlet } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AdvancedTrackingTableComponent } from '../advanced-tracking-table/advanced-tracking-table.component';

import { GenericActionsComponent } from '../../../table/components/generic-actions/generic-actions.component';
import { ArrayFilterFn } from '../../../utils/@types/ArrayFns';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { BasicFormatsService } from '../../../utils/services/basic-formats.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { UserRoleService } from '../../../utils/services/user-role.service';
import { AdvancedTrackingTableColumn } from '../../models/AdvancedTrackingTableColumn';
import { AdvancedTrackingTableColumnType } from '../../models/advanced-tracking-table-column-type.enum';
import { AbstractAdvancedTrackingTableNoBackPageService } from '../../services/abstract-advanced-tracking-table-no-back-page.service';
import { AdvancedTrackingTablePipe } from '../../pipes/advanced-tracking-table.pipe';

@Component({
  selector: 'app-advanced-tracking-table-no-back-page',
  templateUrl: './advanced-tracking-tables-no-back-page.component.html',
  styleUrls: ['./advanced-tracking-tables-no-back-page.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    NgIf,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    NgClass,
    NgTemplateOutlet,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    AdvancedTrackingTablePipe,
  ],
})
export class AdvancedTrackingTablesNoBackPageComponent implements OnInit, OnDestroy {
  /**
   * columns : key, sortable & title parameters (required)
   *
   * @type {Array<{key: string, sortable: string, i18nTitle: string, type: string, parameters: any}>}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() columns: Array<AdvancedTrackingTableColumn>;
  /**
   * columns : key, sortable & title parameters (required)
   *
   * @type {Array<{key: string, sortable: string, i18nTitle: string, type: string, parameters: any}>}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() disableRowField: string;
  /**
   * service : used to fill table (required)
   *
   * @type {AbstractAdvancedTrackingTableService}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() service: AbstractAdvancedTrackingTableNoBackPageService<any>;
  /**
   * filterPlaceholder : label for input to filter table (optional)
   *
   * @type {string}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() filterPlaceholderI18nToken = 'Txt_Placeholder_Filter';
  /**
   * filterPlaceholder : label for input to filter table (optional)
   *
   * @type {string}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() noResultMessageI18nToken = 'Txt_No_Result';
  /**
   * pageSize : number of elements to display by page (optionnal)
   *
   * @type {number}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() pageSize;
  /**
   * pageSizeOptions : array of possible numbers of elements to display by page (optionnal)
   *
   * @type {Array<number>}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() pageSizeOptions: Array<number>;
  /**
   * snackBarMessageOnError : wether a snackBar should be displayed on error
   *
   * @type {boolean}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() snackBarMessageOnError = true;
  /**
   * errorI18nMessage : i18n token for message in case of error
   *
   * @type {string}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() errorI18nMessage = 'Error_SnackBar_ErrorOccured';
  /**
   * columnSort input (optional)
   *
   * @type {{ string }}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() columnSort: string;
  /**
   * columnSortOrder input (optional)
   *
   * @type {{ string }}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() columnSortOrder: 'asc' | 'desc';
  /**
   * Show or not column selection with checkbox
   *
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() activateColumnsToDisplay = false;
  /**
   * Custom filteringFunction
   * @type {Function}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() filterPredicate: (data: any, filter: string) => boolean;
  /**
   * Custom filteringFunction
   * @type {Function}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() selectable: boolean;
  /**
   * resultEvent : Output to send data in the event of a result
   *
   * @type {EventEmitter<any>}
   * @memberof AdvancedTrackingTableComponent
   */
  @Output() readonly resultEvent: EventEmitter<any> = new EventEmitter();
  /**
   * errorEvent : Output to send details in the event of an error
   *
   * @type {EventEmitter<any>}
   * @memberof AdvancedTrackingTableComponent
   */
  @Output() readonly errorEvent: EventEmitter<any> = new EventEmitter();
  /**
   * Emitter of checkedRows changings
   *
   * @type {EventEmitter<any[]>}
   * @memberof TableComponent
   */
  @Output() public readonly checkedRowsChange = new EventEmitter<Array<any>>();
  /**
   * Instance that implements methods to filter, paginate and sort data
   *
   * @type {MatTableDataSource<Object>}
   * @memberof AdvancedTrackingTableComponent
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  dataSource: MatTableDataSource<Object>;
  haveElement: boolean;
  /**
   * SelectionModel instance
   *
   * @type {SelectionModel<any>}
   * @memberof TableComponent
   */
  public selection: SelectionModel<any> = new SelectionModel<any>(true, []);
  /**
   * isLoadingResults : private flag when loading
   *
   * @type {boolean}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() isLoadingResults: boolean;
  /**
   * Array of selected options
   *
   * @type {string[]}
   * @memberof AdvancedTrackingTableComponent
   */
  selectedColumns: Array<AdvancedTrackingTableColumn> = [];
  /**
   * paginator : private paginator element
   *
   * @type {MatPaginator}
   * @memberof AdvancedTrackingTableComponent
   */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  /**
   * sort : private sort element
   *
   * @type {MatSort}
   * @memberof AdvancedTrackingTableComponent
   */
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() public readonly inDialog = false;
  private readonly _destroy$: Subject<boolean> = new Subject<boolean>();
  /**
   * serviceParams : used to add params to url (optional)
   *
   * @type {AbstractAdvancedTrackingTableService}
   * @memberof AdvancedTrackingTableComponent
   */
  @Input() serviceParams: string;
  private _dirtyCheckBox: boolean;

  /**
   * Creates an instance of AdvancedTrackingTableComponent.
   * @param {BasicFormatsService} basicFormatsService
   * @param {ComponentFactoryResolver} componentFactoryResolver
   * @param {I18nService} i18nService
   * @param snackbarService
   * @param {UserRoleService} userRoleService
   * @param {ViewContainerRef} viewContainerRef
   * @param appConfigService
   * @param cd
   * @param _advancedTrackingTablesUtilsService
   * @memberof AdvancedTrackingTableComponent
   */
  constructor(
    private readonly basicFormatsService: BasicFormatsService,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    public readonly i18nService: I18nService,
    private readonly snackbarService: SnackbarService,
    private readonly userRoleService: UserRoleService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly appConfigService: AppConfigService,
    private readonly cd: ChangeDetectorRef,
  ) {
    this.dataSource = new MatTableDataSource();
    this.pageSizeOptions = this.appConfigService.getPaginatorDefaultValue();
  }

  private _disabledSelection: boolean;

  get disabledSelection(): boolean {
    return this._disabledSelection;
  }

  @Input() set disabledSelection(disabledSelection: boolean) {
    this._disabledSelection = disabledSelection;
  }

  private _selectedItems: Array<any>;

  get selectedItems(): Array<any> {
    return this._selectedItems;
  }

  @Input() set selectedItems(selectedItems: Array<any>) {
    this._selectedItems = selectedItems;
    this.updateSelection();
  }

  /**
   * items : private list of elements to display
   *
   * @type {Array<any>}
   * @memberof AdvancedTrackingTableComponent
   */
  private _items = [];

  get items(): Array<any> {
    return this._items;
  }

  @Input() set items(items: Array<any>) {
    this._items = items;
    if (items) {
      this.resultEvent.emit(this.items);
      this.refresh();
    }
  }

  @Input() set itemsWithSelection(items: { data: Array<any>; clearSelection: boolean }) {
    this.items = items.data;
    if (items.clearSelection) {
      this.selection.clear();
    }
    this.updateSelection();
  }

  private _filter: string;

  get filter(): string {
    return this._filter;
  }

  @Input() set filter(filter: string) {
    this._filter = filter;
    this.dataSource.filter = filter
      ? filter
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      : undefined;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * displayedColumns getter, based on columns property
   *
   * @memberof AdvancedTrackingTableComponent
   * @returns {Array<string>}
   */
  get displayedColumns(): Array<AdvancedTrackingTableColumn> {
    return this.columns.filter((column) => {
      // Handle hide column
      if (column.hidden) {
        return false;
      }
      if (column.visibleFor) {
        return this.userRoleService.isInRoles(column.visibleFor);
      }

      return true;
    });
  }

  /**
   * displayedColumnsKeys getter, based on columns property
   *
   * @memberof AdvancedTrackingTableComponent
   * @returns {Array<string>}
   */
  get displayedColumnsKeys(): Array<string> {
    let displayedColumnsKeys = [];
    if (this.selectable) {
      displayedColumnsKeys.push('select');
    }
    displayedColumnsKeys = [...displayedColumnsKeys, ...this.displayedColumns.map((columns) => columns.key)];

    return displayedColumnsKeys;
  }

  get length(): number {
    return this.dataSource.data.length;
  }

  /**
   * get computed selected columns summary
   *
   * @returns {string}
   * @memberof AdvancedTrackingTableComponent
   */
  get selectedColumnsSummary(): string {
    const nbselectedColumns = this.selectedColumns.length;

    if (nbselectedColumns) {
      const i18nTitle = this.selectedColumns[0].i18nTitle;
      switch (nbselectedColumns) {
        case this.columns.length - 1:
          return this.i18nService._('Txt_Select_Form_Table_All');
        case 1:
          return `${this.i18nService._(i18nTitle)}`;
        default:
          return `${this.i18nService._(i18nTitle)} ${this.i18nService._('Txt_Select_Form_Table_And_Other', [nbselectedColumns - 1])}`;
      }
    }

    return '';
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  /**
   * ngOnInit method
   *
   * @memberof AdvancedTrackingTableComponent
   */
  ngOnInit(): void {
    this.pageSize = this.pageSize || this.pageSizeOptions[0];
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.filterPredicate) {
      this.dataSource.filterPredicate = this.filterPredicate;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-types
      this.dataSource.filterPredicate = (data: Object, filter: string) => {
        this.haveElement = Object.values(data).find((element) =>
          `${element}`
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(`${filter}`),
        );

        return !filter || this.haveElement;
      };
    }

    this.selection.changed
      .pipe(
        throttleTime(AdvancedTrackingTableComponent.DEBOUNCE_TIME, undefined, { leading: true, trailing: true }),
        // debounce(() => interval(AdvancedTrackingTableComponent.DEBOUNCE_TIME)),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        if (this._dirtyCheckBox) {
          this.checkedRowsChange.emit(this.selection.selected);
        }
      });

    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string | number => {
      const column = this.columns.find((col) => col.key === sortHeaderId);
      const value = data[column.key];
      switch (column.type) {
        case AdvancedTrackingTableColumnType.Number:
        case AdvancedTrackingTableColumnType.Currency:
        case AdvancedTrackingTableColumnType.Date:
          return value;
        case AdvancedTrackingTableColumnType.Link:
          return value._parentView.component.element[column.key];
        default:
          return String(value).toUpperCase();
      }
    };
    this.selectedColumns = this.columns.filter(
      // eslint-disable-next-line no-prototype-builtins
      (element) => !(element.hasOwnProperty('hidden') && element.hidden),
    );
    if (!this.items) {
      this.loadItems(this.serviceParams);
    }
  }

  /**
   * Detect selected/unseleted checkbox column
   *
   * @param {*} event
   * @memberof AdvancedTrackingTableComponent
   */
  onSelectedCheckboxChange(event): void {
    if (event.isUserInput) {
      const index = this.columns.findIndex((element) => element.key === event.source.value.key);
      this.columns[index].hidden = !event.source.selected;
      this.columns = this.columns.slice(0);
    }
  }

  /**
   * loadItems method to get data through service
   *
   * @param {string} serviceParams
   * @memberof AdvancedTrackingTableComponent
   */
  public loadItems(serviceParams?: string): void {
    this.isLoadingResults = true;
    this.service.getItems(serviceParams).subscribe(
      (result: ListResponse<any>) => {
        this.items = result.items;
        if (this.selectedItems && this.selectedItems.length) {
          this.selection.clear();
          this.selection.select(this.items.filter((value) => this.selectedItems.includes(value.id)));
        }
      },
      (error) => {
        if (this.snackBarMessageOnError) {
          this.snackbarService.errorI18n(this.errorI18nMessage);
        }
        this.errorEvent.emit(error);
      },
      () => {
        this.isLoadingResults = false;
      },
    );
  }

  refresh(filterFn?: ArrayFilterFn): void {
    const data = filterFn ? this.items.filter(filterFn) : this.items;
    this.dataSource.data = this.prepareCells(data);
  }

  /**
   * Return if the column is a ng-template
   *
   * @memberof TableComponent
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  public isNgTemplate(element: Object, columnName: string): boolean {
    return element[columnName] instanceof TemplateRef;
  }

  /**
   * Format cells data
   *
   * @param {Array<any>} items
   * @returns {Array<any>}
   * @memberof AdvancedTrackingTableComponent
   */
  public prepareCells(items: Array<any>): Array<any> {
    const cells = [];
    items.forEach((item) => {
      const row = {};
      this.columns.forEach((column) => {
        const val = item[column.key];
        let cellContent = this._formatCell(item, column, val);
        if (
          column.parameters &&
          column.parameters.noEmptyValue &&
          (cellContent === undefined || cellContent === null || cellContent === '')
        ) {
          cellContent = column.parameters.noEmptyString || '-';
        }
        row[column.key] = cellContent;
      });
      cells.push(row);
    });

    return cells;
  }

  /**
   * Update single row data in dataSource
   *
   * @param {number} id
   * @returns {*}
   * @memberof AdvancedTrackingTableComponent
   */
  public updateSingleDataRow(id: number): any {
    this.service.getSingleItem(id).subscribe(
      (updatedData) => {
        // Access id in Object Array of dataSource.data
        const accessObjectId = 'id';
        // Find data to update row index in dataSource.data
        const index = this.dataSource.data.findIndex((data) => data[accessObjectId] === id);
        if (updatedData.items.length) {
          // Give formated new data to dataSource.data
          this.dataSource.data[index] = this.prepareCells(updatedData.items)[0];
        } else {
          // Handle removed data in bdd
          this.dataSource.data.splice(index, 1);
        }
        // Destructure array to see visual update
        this.dataSource.data = this.dataSource.data.slice(0);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (_err) => {
        this.snackbarService.sendErrorOccured();
      },
    );
  }

  public getItemClass(column, item): string | string[] | { [key: string]: boolean } {
    return !column.ngClass ? 'text-xs' : column.ngClass.condition(item) ? column.ngClass.classTrue : column.ngClass.classFalse;
  }

  /**
   * Call when a checkbox has been checked or unchecked
   *
   * @param event
   * @param row
   * @memberof TableComponent
   */
  public onCheckedChangeRow(event: MatCheckboxChange, row: any): void {
    if (event) {
      this._dirtyCheckBox = true;
      if (row === 'master') {
        this.handleMasterRowSelection(event);
      } else {
        this.handleRowSelection(event, row);
      }
    }
  }

  private _formatCell(item, column: AdvancedTrackingTableColumn, val): string {
    let cellContent;
    switch (column.type) {
      case AdvancedTrackingTableColumnType.I18nToken:
        // eslint-disable-next-line no-case-declarations
        let i18nTokenPrefix = '';
        if (column.parameters && column.parameters.i18nTokenPrefix) {
          i18nTokenPrefix = column.parameters.i18nTokenPrefix;
        }
        cellContent = this.i18nService._(`${i18nTokenPrefix}${val}`);
        break;
      case AdvancedTrackingTableColumnType.Boolean:
        let trueBooleanString = this.i18nService._('Txt_Yes');
        let falseBooleanString = this.i18nService._('Txt_No');
        if (column.parameters) {
          if (column.parameters.trueBooleanString && column.parameters.falseBooleanString) {
            trueBooleanString = column.parameters.trueBooleanString;
            falseBooleanString = column.parameters.falseBooleanString;
          }
          if (column.parameters.reverse) {
            trueBooleanString = this.i18nService._('Txt_No');
            falseBooleanString = this.i18nService._('Txt_Yes');
          }
        }
        val ? (cellContent = trueBooleanString) : (cellContent = falseBooleanString);
        break;
      case AdvancedTrackingTableColumnType.Link:
      case AdvancedTrackingTableColumnType.Actions:
        const actionsParameters = column.parameters || { actions: {} };
        const factory = this.componentFactoryResolver.resolveComponentFactory(GenericActionsComponent);
        const component = this.viewContainerRef.createComponent(factory);
        component.instance.genericActions = actionsParameters.actions;
        component.instance.isMenu = Boolean(column.type === AdvancedTrackingTableColumnType.Actions);
        component.instance.menuDisable = !item.actions || !Object.values(item.actions).find((element: any) => element === true);
        component.instance.element = item;
        Object.assign(component.instance, actionsParameters.condition || val);
        cellContent = component.instance.templateRef;
        break;
      case AdvancedTrackingTableColumnType.Surface:
        cellContent = this.basicFormatsService.formatArea(val);
        break;
      case AdvancedTrackingTableColumnType.CurrencyCeil:
        cellContent = this.basicFormatsService.formatCurrencyCeil(val, undefined, 0, 0, 0);
        break;
      case AdvancedTrackingTableColumnType.Custom:
        if (column.customFormat) {
          cellContent = column.customFormat(item);
        }
        break;
      default:
        cellContent = val;
        break;
    }

    return cellContent;
  }

  private _selectAllNonDisableData(data: Array<any>): Array<string> {
    return data.filter((value) => !value[this.disableRowField]).map((value) => value.id);
  }

  private updateSelection(): void {
    if (this.hasItems() && this.hasSelectedItems()) {
      const selectedIds = this.items.filter((item) => this.selectedItems.includes(item.id)).map((item) => item.id);
      this.selection.select(...selectedIds);
    }
  }

  private hasItems(): boolean {
    return this.items && this.items.length > 0;
  }

  private hasSelectedItems(): boolean {
    return this.selectedItems && this.selectedItems.length > 0;
  }

  private handleMasterRowSelection(event: MatCheckboxChange): void {
    this.selection.clear();
    this.selection.select(...(event.checked ? this._selectAllNonDisableData(this.dataSource.filteredData) : []));
    if (!event.checked) {
      this.selectedItems = [];
    }
    this.cd.detectChanges();
  }

  private handleRowSelection(event: MatCheckboxChange, row: any): void {
    this.selection.toggle(row.id);
    if (!event.checked) {
      this._dirtyCheckBox = false;
      this.selection.selected.filter((value) => value != row.id);
      this.selectedItems = this.selectedItems.filter((value) => value != row.id);
      this.checkedRowsChange.emit(this._selectedItems);
    }
  }
}
