export enum RoleTypeEnum {
  DEVELOPER_HYPER = 'developerHyper',
  DEVELOPER_SUPER = 'developerSuper',
  CONTRACTOR_HYPER = 'contractorHyper',
  CONTRACTOR_SUPER = 'contractorSuper',
  INDEPENDANT_NETWORK_HEADER = 'independantNetworkHeader',
}

export enum AcountTypeEnum {
  DEVELOPER = 'developer',
  CONTRACTOR = 'contractor',
  INDEPENDANT = 'independant',
  VALORISSIMO = 'valorissimo',
}

export enum UserTypeLabelEnum {
  DEVELOPER = 'DEVELOPER',
  CONTRACTOR = 'CONTRACTOR',
  INDEPENDANT = 'INDEPENDANT',
  VALORISSIMO = 'VALORISSIMO',
}
