import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParamRequest } from '@commons-dto/valo-back';

import { DataLayerApiService } from './data-layer-api.service';
import { PreReservationsTrackingTableServiceResponse } from '../tracking-tables/models/PreReservationsTrackingTableServiceResponse';
import { PreReservationsTrackingTableItem } from '../tracking-tables/models/PreReservationsTrackingTableItem';

@Injectable({ providedIn: 'root' })
export class PreReservationApiService extends DataLayerApiService {
  BASE_URL = '';

  /**
   * Get pre-reservations by params
   *
   * @returns {Observable<PreReservationsTrackingTableServiceResponse>}
   * @memberof DataLayerApiService
   */
  getPrereservations(params: ParamRequest): Observable<PreReservationsTrackingTableServiceResponse> {
    const path = this.BASE_URL + 'Reservations/tracking-table/pre-reservations';
    return this.getTableRessources(params, path);
  }

  /**+
   * Get pre-reservation by id
   *
   * @returns {Observable<PreReservationsTrackingTableItem>}
   * @memberof DataLayerApiService
   */
  getPrereservationById(id: number): Observable<PreReservationsTrackingTableItem> {
    const path = this.BASE_URL + 'Reservations/tracking-table/pre-reservations';
    return this.getRessource(path, id);
  }
}
