/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgClass, NgFor } from '@angular/common';

import { ArrayFilterCallbackFn, ArraySortCompareFn } from '../../@types/ArrayFns';
import { ReferenceTableData } from '../../models/ReferenceTableData';
import { I18nService } from '../../services/i18n.service';
import { ReferenceTablesService } from '../../services/reference-tables.service';

@Component({
  selector: 'app-checkbox-populated',
  templateUrl: './checkbox-populated.component.html',
  styleUrls: ['./checkbox-populated.component.scss'],
  standalone: true,
  imports: [NgFor, MatCheckboxModule, NgClass],
})
export class CheckboxPopulatedComponent implements OnInit {
  /**
   * Input referenceTable to get tables informations
   *
   * @type {string}
   * @memberof CheckboxPopulatedComponent
   */
  @Input() referenceTable: string;

  /**
   * Input referenceTable to get tables informations
   *
   * @type {Array<any>}
   * @memberof CheckboxPopulatedComponent
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() checkedElements: Array<any>;
  @Output() readonly checkedElementsChange = new EventEmitter();

  /**
   * Input css classes for checkbox styles
   *
   * @type {Array<any>}
   * @memberof CheckboxPopulatedComponent
   */
  @Input() cssClassNames: Array<string>;

  /**
   * Get if reference table should be translated or not
   *
   * @type {BehaviorSubject}
   * @memberof CheckboxPopulatedComponent
   */
  @Input() notTranslated: boolean;

  /**
   * Sort method to apply on ReferenceTableData
   *
   * @type {ArraySortCompareFn}
   * @memberof CheckboxPopulatedComponent
   */
  @Input() sortMethod: ArraySortCompareFn;

  /**
   * Filter method to apply on ReferenceTableData
   *
   * @type {ArrayFilterCallbackFn}
   * @memberof CheckboxPopulatedComponent
   */
  @Input() filterMethod: ArrayFilterCallbackFn;

  /**
   * Input source for informations
   *
   * @type {Array<ReferenceTableData>}
   * @memberof CheckboxPopulatedComponent
   */
  @Input() source: Array<ReferenceTableData>;

  @Input() numberColumnsToDisplay: number;

  /**
   * Array to store reference table informations
   *
   * @type {Array<ReferenceTableData>}
   * @memberof CheckboxPopulatedComponent
   */
  public items: Array<ReferenceTableData>;

  /**
   * Control on input field if 'OTHER' is selected
   *
   * @type {AbstractControl}
   * @memberof CheckboxPopulatedComponent
   */
  public checkboxControl: AbstractControl;
  divClass: string;

  /**
   * Creates an instance of CheckboxPopulatedComponent.
   * @param referenceTablesService
   * @param snackBar
   * @param i18nService
   */
  constructor(
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly snackBar: MatSnackBar,
    public i18nService: I18nService,
  ) {}

  /**
   * Init component
   *
   * @memberof CheckboxPopulatedComponent
   */
  ngOnInit(): void {
    this.divClass = this.getDivClassName();
    if (this.referenceTable) {
      // Get reference Table info
      const getTableReferenceInfoMethod = this.notTranslated ? 'getTableReferenceInfo' : 'getTableReferenceInfoTranslated';

      this.referenceTablesService[getTableReferenceInfoMethod](this.referenceTable).subscribe(
        (itemsFound: Array<any>) => {
          this.setData(itemsFound);
        },
        () => {
          this.snackBar.open(this.i18nService._('Error_SnackBar_ErrorOccured'));
        },
      );
    } else if (this.source) {
      if (!this.notTranslated) {
        this.source.forEach((item) => {
          item.label = this.i18nService._(item.label);
        });
      }
      this.setData(this.source);
    }
  }

  setData(data: Array<any>): void {
    if (this.sortMethod) {
      data.sort(this.sortMethod);
    }

    if (this.filterMethod) {
      this.items = data.filter(this.filterMethod);

      return;
    }

    this.items = data;
  }

  public isChecked(id: any): boolean {
    return this.checkedElements?.includes(id);
  }

  public onChange($event, id: any): void {
    this.checkedElements = this.checkedElements.filter((item) => item !== id);
    if ($event.checked) {
      this.checkedElements.push(id);
    }
    this.checkedElementsChange.emit(this.checkedElements);
  }

  public getDivClassName(): string {
    return this.numberColumnsToDisplay ? `d-grid checkbox-${this.numberColumnsToDisplay}-col` : '';
  }
}
