import { CountryResponse } from '../../../utils/models/CountryResponse';
import { sanitize } from '../../../utils/services/utils/file-utils';

export const countriesFr = {
  af: `Afghanistan`,
  za: `Afrique du Sud`,
  al: `Albanie`,
  dz: `Algérie`,
  de: `Allemagne`,
  ad: `Andorre`,
  ao: `Angola`,
  ai: `Anguilla`,
  aq: `Antarctique`,
  ag: `Antigua-et-Barbuda`,
  an: `Antilles néerlandaises`,
  sa: `Arabie saoudite`,
  ar: `Argentine`,
  am: `Arménie`,
  aw: `Aruba`,
  au: `Australie`,
  at: `Autriche`,
  az: `Azerbaïdjan`,
  bs: `Bahamas`,
  bh: `Bahreïn`,
  bd: `Bangladesh`,
  bb: `Barbade`,
  by: `Bélarus`,
  be: `Belgique`,
  bz: `Belize`,
  bj: `Bénin`,
  bm: `Bermudes`,
  bt: `Bhoutan`,
  bo: `Bolivie`,
  ba: `Bosnie-Herzégovine`,
  bw: `Botswana`,
  br: `Brésil`,
  bn: `Brunéi Darussalam`,
  bg: `Bulgarie`,
  bf: `Burkina Faso`,
  bi: `Burundi`,
  kh: `Cambodge`,
  cm: `Cameroun`,
  ca: `Canada`,
  cv: `Cap-Vert`,
  ea: `Ceuta et Melilla`,
  cl: `Chili`,
  cn: `Chine`,
  cy: `Chypre`,
  co: `Colombie`,
  km: `Comores`,
  cg: `Congo-Brazzaville`,
  kp: `Corée du Nord`,
  kr: `Corée du Sud`,
  cr: `Costa Rica`,
  ci: `Côte d’Ivoire`,
  hr: `Croatie`,
  cu: `Cuba`,
  dk: `Danemark`,
  dg: `Diego Garcia`,
  dj: `Djibouti`,
  dm: `Dominique`,
  eg: `Égypte`,
  sv: `El Salvador`,
  ae: `Émirats arabes unis`,
  ec: `Équateur`,
  er: `Érythrée`,
  es: `Espagne`,
  ee: `Estonie`,
  va: `État de la Cité du Vatican`,
  fm: `États fédérés de Micronésie`,
  us: `États-Unis`,
  et: `Éthiopie`,
  fj: `Fidji`,
  fi: `Finlande`,
  fr: `France`,
  ga: `Gabon`,
  gm: `Gambie`,
  ge: `Géorgie`,
  gs: `Géorgie du Sud et les îles Sandwich du Sud`,
  gh: `Ghana`,
  gi: `Gibraltar`,
  gr: `Grèce`,
  gd: `Grenade`,
  gl: `Groenland`,
  gp: `Guadeloupe`,
  gu: `Guam`,
  gt: `Guatemala`,
  gg: `Guernesey`,
  gn: `Guinée`,
  gq: `Guinée équatoriale`,
  gw: `Guinée-Bissau`,
  gy: `Guyana`,
  gf: `Guyane française`,
  ht: `Haïti`,
  hn: `Honduras`,
  hu: `Hongrie`,
  bv: `Île Bouvet`,
  cx: `Île Christmas`,
  cp: `Île Clipperton`,
  ac: `Île de l'Ascension`,
  im: `Île de Man`,
  nf: `Île Norfolk`,
  ax: `Îles Åland`,
  ky: `Îles Caïmans`,
  ic: `Îles Canaries`,
  cc: `Îles Cocos - Keeling`,
  ck: `Îles Cook`,
  fo: `Îles Féroé`,
  hm: `Îles Heard et MacDonald`,
  fk: `Îles Malouines`,
  mp: `Îles Mariannes du Nord`,
  mh: `Îles Marshall`,
  um: `Îles Mineures Éloignées des États-Unis`,
  sb: `Îles Salomon`,
  tc: `Îles Turks et Caïques`,
  vg: `Îles Vierges britanniques`,
  vi: `Îles Vierges des États-Unis`,
  in: `Inde`,
  id: `Indonésie`,
  iq: `Irak`,
  ir: `Iran`,
  ie: `Irlande`,
  is: `Islande`,
  il: `Israël`,
  it: `Italie`,
  jm: `Jamaïque`,
  jp: `Japon`,
  je: `Jersey`,
  jo: `Jordanie`,
  kz: `Kazakhstan`,
  ke: `Kenya`,
  kg: `Kirghizistan`,
  ki: `Kiribati`,
  kw: `Koweït`,
  la: `Laos`,
  ls: `Lesotho`,
  lv: `Lettonie`,
  lb: `Liban`,
  lr: `Libéria`,
  ly: `Libye`,
  li: `Liechtenstein`,
  lt: `Lituanie`,
  lu: `Luxembourg`,
  mk: `Macédoine`,
  mg: `Madagascar`,
  my: `Malaisie`,
  mw: `Malawi`,
  mv: `Maldives`,
  ml: `Mali`,
  mt: `Malte`,
  ma: `Maroc`,
  mq: `Martinique`,
  mu: `Maurice`,
  mr: `Mauritanie`,
  yt: `Mayotte`,
  mx: `Mexique`,
  md: `Moldavie`,
  mc: `Monaco`,
  mn: `Mongolie`,
  me: `Monténégro`,
  ms: `Montserrat`,
  mz: `Mozambique`,
  mm: `Myanmar`,
  na: `Namibie`,
  nr: `Nauru`,
  np: `Népal`,
  ni: `Nicaragua`,
  ne: `Niger`,
  ng: `Nigéria`,
  nu: `Niue`,
  no: `Norvège`,
  nc: `Nouvelle-Calédonie`,
  nz: `Nouvelle-Zélande`,
  om: `Oman`,
  ug: `Ouganda`,
  uz: `Ouzbékistan`,
  pk: `Pakistan`,
  pw: `Palaos`,
  pa: `Panama`,
  pg: `Papouasie-Nouvelle-Guinée`,
  py: `Paraguay`,
  nl: `Pays-Bas`,
  pe: `Pérou`,
  ph: `Philippines`,
  pn: `Pitcairn`,
  pl: `Pologne`,
  pf: `Polynésie française`,
  pr: `Porto Rico`,
  pt: `Portugal`,
  qa: `Qatar`,
  hk: `R.A.S. chinoise de Hong Kong`,
  mo: `R.A.S. chinoise de Macao`,
  qo: `régions éloignées de l’Océanie`,
  cf: `République centrafricaine`,
  cd: `République démocratique du Congo`,
  do: `République dominicaine`,
  cz: `République tchèque`,
  re: `Réunion`,
  ro: `Roumanie`,
  gb: `Royaume-Uni`,
  ru: `Russie`,
  rw: `Rwanda`,
  eh: `Sahara occidental`,
  bl: `Saint-Barthélémy`,
  kn: `Saint-Kitts-et-Nevis`,
  sm: `Saint-Marin`,
  mf: `Saint-Martin`,
  pm: `Saint-Pierre-et-Miquelon`,
  vc: `Saint-Vincent-et-les Grenadines`,
  sh: `Sainte-Hélène`,
  lc: `Sainte-Lucie`,
  ws: `Samoa`,
  as: `Samoa américaines`,
  st: `Sao Tomé-et-Principe`,
  sn: `Sénégal`,
  rs: `Serbie`,
  cs: `Serbie-et-Monténégro`,
  sc: `Seychelles`,
  sl: `Sierra Leone`,
  sg: `Singapour`,
  sk: `Slovaquie`,
  si: `Slovénie`,
  so: `Somalie`,
  sd: `Soudan`,
  lk: `Sri Lanka`,
  se: `Suède`,
  ch: `Suisse`,
  sr: `Suriname`,
  sj: `Svalbard et Île Jan Mayen`,
  sz: `Swaziland`,
  sy: `Syrie`,
  tj: `Tadjikistan`,
  tw: `Taïwan`,
  tz: `Tanzanie`,
  td: `Tchad`,
  tf: `Terres australes françaises`,
  io: `Territoire britannique de l'océan Indien`,
  ps: `Territoire palestinien`,
  th: `Thaïlande`,
  tl: `Timor oriental`,
  tg: `Togo`,
  tk: `Tokelau`,
  to: `Tonga`,
  tt: `Trinité-et-Tobago`,
  ta: `Tristan da Cunha`,
  tn: `Tunisie`,
  tm: `Turkménistan`,
  tr: `Turquie`,
  tv: `Tuvalu`,
  ua: `Ukraine`,
  eu: `Union européenne`,
  uy: `Uruguay`,
  vu: `Vanuatu`,
  ve: `Venezuela`,
  vn: `Viêt Nam`,
  wf: `Wallis-et-Futuna`,
  ye: `Yémen`,
  zm: `Zambie`,
  zw: `Zimbabwe`,
};

export const normalizedCountry: CountryResponse[] = Object.keys(countriesFr).map((countryCode, index) => {
  const country = countriesFr[countryCode];
  return {
    id: index,
    label: country,
    labelSanityzed: sanitize(country),
    code: countryCode,
  };
});
